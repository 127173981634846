import { getCentroid } from 'components/Products/solarpvSimple/b2c/SolarpvSimpleMap';
import { AREA_TYPES, HOUSING_TYPES_B2B, spvSimpleActions, STEPS_OPEN_AREA, STEPS_RESERVED_AREA } from 'constants/products/solarpvSimple';
import { BASENAME_URL_OAREA_PPC_B2B } from 'constants/settings';
import clone from 'fast-copy';
import { array, bool, number, string } from 'prop-types';
import { calcTotalPanelsB2C, getPayloadToProposalB2C, getPolygonCoordinates } from 'services/products/solarpvSimple';
import { getHeadTitle, isOpenArea } from 'services/settings';
import { getCompanyProfileIds } from 'services/user';
import { useBusinessModelsStore } from 'store/businessModels';

function initStep() {
    return isOpenArea() ? 0 /* LandingPage */ : STEPS_RESERVED_AREA.HOUSING_TYPE;
}

export const initialSpvSimpleReducerB2B = {
    isMounting: true,
    resetForm: () => {},
    step: initStep(),
    isDetectRoof: true,
    isSuccessfulRequest: false,
    inputs: {
        housing_type: null,
        area_type: null,
        facility: {
            id: number,
            phases_number_id: number,
            location: {
                latitude: number,
                longitude: number,
                address: string,
                county_id: string,
                postal_code: string,
                city: string,
            },
            inputs_consumo: {
                annual_consumption: number,
                equipments: array,
                energy_price: number,
            },
        },
        areas: [{}],
        range_id: number,
        panel_id: number,
        coordinates_avg: {
            lat: number,
            long: number,
        },
        exclusions: [],
        remuneration_type_id: number,
        is_kits: bool,
        contractedPower: number,
        roofCoordinates: [],
    },
    simulations: [],
    solutions: {},
    proposal: {},
    proposalDetails: null,
    options: {},
    isOArea: false,
    productID: number,
    companyProfileId: number,
    isRequestingSims: false,
};

export const spvSimpleReducerB2B = (state = initialSpvSimpleReducerB2B, action) => {
    switch (action.type) {
        case spvSimpleActions.SET_INIT: {
            let {
                isOArea,
                pInputs,
                productID,
                companyProfileId,
                facilityID,
                contractedPower,
                phasesNumberId,
                equipmentsOpts,
                remunerationOpts,
                battery,
                rangesOpts,
                mountingStructures,
                pvOptions,
            } = action.payload;

            if (isOArea) {
                //Force COMPANY_ID PPC
                companyProfileId = getCompanyProfileIds().PPC;
            }

            //TODO: criar um service para fazer parse dos inputs.
            let equipments = pInputs?.equipments ?? [];
            let newEquipments = equipmentsOpts
                .sort((a, b) => a.id - b.id)
                .map((el) => {
                    el.value = equipments.includes(el.id);
                    el.equipmentId = el.id;
                    return el;
                });

            let rangeSelected = rangesOpts?.find((el) => el.is_default);
            let panelSelected = rangeSelected?.paineis?.find((el) => el.is_default);

            let inputs = {
                //defaultsValues
                isDetectRoof: true,
                facility: {
                    id: !isOArea ? facilityID : null,
                    isDetectRoof: true,
                    phases_number_id: !isOArea ? phasesNumberId : null,
                    location: {
                        //Dummy
                        latitude: null,
                        longitude: null,
                        address: '',
                        county_id: '',
                        postal_code: '',
                        city: '',
                    },
                    inputs_consumo: {
                        annual_consumption: '',
                        equipments: newEquipments,
                        energy_price: '',
                    },
                },
                areas: [],
                range_id: rangeSelected?.id,
                panel_id: panelSelected?.id,
                coordinates_avg: null,
                exclusions: [],
                remuneration_type_id: parseInt(remunerationOpts?.find((item) => item.is_default)?.id) ?? null,
                is_kits: true,
                roofCoordinates: [],
                contractedPower,
            };
            return {
                ...state,
                inputs,
                isDetectRoof: true,
                isOArea,
                productID,
                companyProfileId,
                options: {
                    rangeSelected,
                    remunerationOpts,
                    battery,
                    equipments: newEquipments,
                    structures: mountingStructures,
                },
                pvOptions,
                isMounting: false,
                isRequestingSims: false,
            };
        }
        case spvSimpleActions.SET_RESET_FORM: {
            let { reset } = action.payload;
            return {
                ...state,
                isSuccessfulRequest: false,
                resetForm: reset,
            };
        }
        case spvSimpleActions.SET_STEP_POPSTATE: {
            let {
                payload: { goBackStep, history },
            } = action;
            const { step } = state;
            if (step === 0 && goBackStep > 1) {
                //start_over force landingpage when  step === 0
                history.push(`/${BASENAME_URL_OAREA_PPC_B2B}`);
                goBackStep = 0;
                document.title = getHeadTitle();
            }

            return {
                ...state,
                isSuccessfulRequest: false,
                step: goBackStep,
            };
        }
        case spvSimpleActions.SET_STEP: {
            let { payload: step } = action;
            return {
                ...state,
                step,
                isRequestingSims: false,
            };
        }
        case spvSimpleActions.SET_HOUSING_TYPE: {
            let { isOArea } = state;
            let { housing_type, area_type } = action.payload;

            return {
                ...state,
                isDetectRoof: [HOUSING_TYPES_B2B.INDEPENDENT].includes(housing_type) && [AREA_TYPES.ROOF].includes(area_type),
                inputs: {
                    ...state.inputs,
                    housing_type,
                    area_type,
                    areas: [],
                },
                step: !isOArea ? STEPS_RESERVED_AREA.ADDRESS : STEPS_OPEN_AREA.ADDRESS, //next step
            };
        }
        case spvSimpleActions.SET_ADDRESS: {
            let { isOArea, inputs, isDetectRoof } = state;
            let { facility } = inputs;
            let { formData, polygon } = action.payload;

            let roofCoordinates = [];
            let polygonAddress = {};
            if (!isDetectRoof) {
                //include TOWN HOUSING
                roofCoordinates = getPolygonCoordinates(polygon);
                polygonAddress = { lat: getCentroid(polygon).lat(), lng: getCentroid(polygon).lng() };
            }

            return {
                ...state,
                inputs: {
                    ...inputs,
                    roofCoordinates,
                    facility: {
                        ...facility,
                        location: {
                            ...formData.address,
                            ...polygonAddress,
                            // FIXME: use only 'latitude' and 'longitude' in the future!
                            latitude: formData?.address?.lat ?? 0,
                            longitude: formData?.address?.lng ?? 0,
                        },
                    },
                },
                step: !isOArea ? STEPS_RESERVED_AREA.ROOF_IDENTIFICATION : STEPS_OPEN_AREA.ROOF_IDENTIFICATION, //next step
            };
        }
        case spvSimpleActions.SET_ROOF_IDENTIFICATION: {
            let { isOArea } = state;
            return {
                ...state,
                inputs: {
                    ...state.inputs,
                },
                step: !isOArea ? STEPS_RESERVED_AREA.ROOF_DETAILS : STEPS_OPEN_AREA.ROOF_DETAILS, //next step
            };
        }
        case spvSimpleActions.SET_ROOF_IDENTIFICATION_SUCCESS: {
            let {
                isOArea,
                inputs,
                options: { rangeSelected },
            } = state;
            let { panel_id, area_type } = inputs;
            let { data, image, image_name, center } = action.payload;

            let { roof, areas, structure } = data;

            let coordinates_avg = {
                lat: center.lat,
                long: center.lng,
            };

            areas?.forEach((group, idx) => {
                areas[idx] = {
                    ...calcTotalPanelsB2C(group, {
                        id: idx + 1,
                        structure,
                        rangeSelected,
                        panel_id,
                    }),
                    coordinates_avg,
                };
            });

            let step = STEPS_OPEN_AREA.ROOF_DETAILS;
            if (area_type === AREA_TYPES.GROUND) step = isOArea ? STEPS_OPEN_AREA.CONSUMPTION_DETAILS : STEPS_RESERVED_AREA.SIMULATION;
            else step = isOArea ? STEPS_OPEN_AREA.ROOF_DETAILS : STEPS_RESERVED_AREA.ROOF_DETAILS;

            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    areas,
                    area_projectada: data?.area_projectada,
                    total_max_panels: areas?.reduce((acc, currentValue) => acc + currentValue.panels_max_number, 0),
                    roofCoordinates: roof[0],
                    image,
                    image_name,
                    center,
                },
                step,
            };
        }
        case spvSimpleActions.SET_STRUCTURES_OPTIONS: {
            let { structures } = action.payload;
            return {
                ...state,
                options: {
                    ...state.options,
                    structures,
                },
            };
        }
        case spvSimpleActions.SET_COORDINATES_AVG: {
            let {
                inputs: { areas },
            } = state;
            let { centroid } = action.payload;
            let coordinates_avg = {
                lat: centroid.lat,
                long: centroid.lng,
            };
            areas.forEach((_, idx) => {
                areas[idx].coordinates_avg = coordinates_avg;
            });
            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    areas,
                    coordinates_avg,
                },
            };
        }
        case spvSimpleActions.SET_ROOF_DETAILS: {
            let {
                isOArea,
                inputs: { areas, coordinates_avg },
                options: { structures },
            } = state;
            let { mounting_structure_id } = action.payload;

            let default_roof_slope = structures.find((el) => el.id === mounting_structure_id)?.default_roof_slope;
            // coordinates_avg
            areas.forEach((_, idx) => {
                areas[idx].coordinates_avg = coordinates_avg;
                areas[idx].slope = default_roof_slope;
                areas[idx].mounting_structure_id = mounting_structure_id;
                areas[idx].mounting_structure_type_id = structures.find((el) => el.id === mounting_structure_id)?.structure_id;
            });

            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    areas,
                },
                isRequestingSims: !isOArea,
                step: !isOArea ? STEPS_RESERVED_AREA.SIMULATION : STEPS_OPEN_AREA.CONSUMPTION_DETAILS, //next step
            };
        }
        case spvSimpleActions.DRAW_ROOF_MANUALLY: {
            return {
                ...state,
                isDetectRoof: false,
                inputs: {
                    ...state.inputs,
                    roofCoordinates: [], //reset roof
                },
                step: STEPS_OPEN_AREA.ADDRESS,
            };
        }
        case spvSimpleActions.SET_CONSUMPTION_DETAILS: {
            let { phases_number_id, energy_price, annual_consumption, equipments } = action.payload;

            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    facility: {
                        ...state.inputs.facility,
                        phases_number_id,

                        inputs_consumo: {
                            ...state.inputs?.inputs_consumo,
                            annual_consumption,
                            equipments: equipments?.filter((eq) => eq.value).map((eq) => eq.id),
                            energy_price: parseFloat(energy_price),
                        },
                    },
                },
                isRequestingSims: true,
                solutions: [],
                isSuccessfulRequest: false,
                step: STEPS_OPEN_AREA.SIMULATION, //next step
            };
        }
        case spvSimpleActions.SET_IS_REQUESTING_SIMS: {
            return {
                ...state,
                isRequestingSims: action?.payload,
            };
        }
        case spvSimpleActions.SET_SIMULATION_SUCCESS: {
            let { simulations } = action.payload;

            if (simulations.length === 0) return state;

            return {
                ...state,
                solutions: simulations,
                isRequestingSims: false,
            };
        }
        case spvSimpleActions.SUCCESSFUL_REQUEST: {
            return {
                ...state,
                proposalDetails: action.payload,
                isSuccessfulRequest: true,
            };
        }
        case spvSimpleActions.SET_REDUX_INPUTS: {
            let { formData, solution } = action.payload;
            const {
                actions: { setBusinessModelsStore },
            } = useBusinessModelsStore.getState();

            let { remuneration_type_id } = formData;
            let { isOArea, inputs } = state;

            let productInputs = getPayloadToProposalB2C(
                {},
                {
                    isOArea,
                    solution,
                    inputs: {
                        ...inputs,
                        remuneration_type_id: parseInt(remuneration_type_id),
                    },
                }
            );

            setBusinessModelsStore({ productInputs });

            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    ...productInputs,
                },
            };
        }
        case spvSimpleActions.SET_SIMULATION: {
            let { isOArea } = state;
            return {
                ...state,
                step: !isOArea ? STEPS_RESERVED_AREA.BUSINESS_MODELS : state.step, //next step
            };
        }
        case spvSimpleActions.SET_BUSINESS_MODELS: {
            let {
                areas,
                coordinates_avg,
                housing_type,
                is_kits,
                panel_id,
                range_id,
                total_panels,
                remuneration_type_id,
                roofCoordinates,
                center,
                has_battery,
            } = state.inputs;
            let { battery } = state.options;

            let res = {
                ...state,
                proposal: {
                    ...state.proposal,
                    facility: {
                        id: state.inputs.facility.id,
                    },
                    inputs: {
                        areas,
                        coordinates_avg,
                        battery: has_battery ? battery : null,
                        housing_type,
                        is_kits,
                        panel_id,
                        range_id,
                        total_panels,
                        remuneration_type_id,
                    },
                    tipo_modelo_negocio_id: action.payload.bmId,
                    img_contract_filename: null,
                    mapData: {
                        roofCoordinates,
                        center,
                        image_name: 'img-contract',
                        counties: state.options.counties,
                    },
                },
                step: STEPS_RESERVED_AREA.DOWNLOAD, //next step
            };

            if (action.payload.offer_edition) {
                res = {
                    ...res,
                    proposal: {
                        ...res.proposal,
                        inputs: {
                            ...res.proposal.inputs,
                            offer_edition: action.payload.offer_edition,
                        },
                    },
                };
            }

            return res;
        }
        case spvSimpleActions.RESET_PROPOSAL: {
            return {
                ...state,
                isSuccessfulRequest: false,
                proposal: {},
            };
        }
        case spvSimpleActions.START_OVER: {
            let {
                inputs,
                options: { equipments },
                resetForm,
            } = state;
            const cEquipments = clone(equipments);

            resetForm({
                address: {
                    latitude: null,
                    longitude: null,
                    description: '',
                    address: '',
                    county_id: '',
                    postal_code: '',
                    city: '',
                },
                annual_consumption: '',
                equipments: cEquipments,
                housing_type: '',
                energy_price: '',
                mounting_structure_id: '',
                phases_number_id: '',
                remuneration_type_id: '',
            });
            return {
                ...state,
                isSuccessfulRequest: false,
                proposal: {},
                inputs: {
                    ...inputs,
                    facility: {
                        ...inputs.facility,
                        phases_number_id: null,

                        location: {
                            latitude: null,
                            longitude: null,
                            address: '',
                            county_id: '',
                            postal_code: '',
                            city: '',
                        },
                        inputs_consumo: {
                            annual_consumption: '',
                            equipments: cEquipments,
                            energy_price: '',
                        },
                    },
                    housing_type: '',
                    center: null,
                    areas: [],
                    coordinates_avg: null,
                    exclusions: [],
                    image_name: null,
                    image: null,
                    total_areas: null,
                    total_panels: null,
                    is_kits: true,
                },
                simulations: [],
                solutions: [],
                step: state.isOArea ? 0 : STEPS_RESERVED_AREA.HOUSING_TYPE,
            };
        }
        default:
            return state;
    }
};
