import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IntlMessages from 'components/util/IntlMessages';
import { intlMessages } from 'services/util/auxiliaryUtils';
import { GrantsAmmountsType } from 'interfaces/businessModels/upfront';
import CustomUnit from 'components/util/CustomUnit';
import { getCompanyProfileIds } from 'services/user';
import { PRODUCT_IDS } from 'constants/products';
import { IconButton } from '@save2compete/efz-design-system';

export const GRANTS_TABLE_CONFIG = [
    {
        id: 'description',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={'label.description'} />
                </span>
            </div>
        ),
        minWidth: 25,
        width: 25,
        accessor: 'description',
        Cell: ({ row, handleChangeDescription }) => {
            return (
                <div>
                    <OutlinedInput
                        className="table-input"
                        value={row.original.description}
                        onChange={(e) => handleChangeDescription(row.original.id, e.target.value)}
                        placeholder={intlMessages('label.insertValue')}
                    />
                </div>
            );
        },
    },
    {
        id: 'amount',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={'page.bm.grants.amount'} />
                </span>
            </div>
        ),
        minWidth: 50,
        width: 50,
        accessor: 'amount',
        Cell: ({ row, handleChangeAmmount }) => {
            return (
                <div className="d-flex align-items-center bm-grants-table-ammounts">
                    <OutlinedInput
                        className="table-input"
                        value={row.original.ammounts.prc}
                        onChange={(e) => handleChangeAmmount(GrantsAmmountsType.PERCENTAGE, row.original.id, e.target.value)}
                        placeholder={intlMessages('label.insertValue')}
                        type="number"
                        // @ts-ignore
                        endAdornment={
                            <InputAdornment className="ms-2" position="end">
                                <CustomUnit unit={'percentage'} />
                            </InputAdornment>
                        }
                    />
                    <span className="ps-3 pe-3">
                        <IntlMessages id="page.bm.grants.or" />
                    </span>
                    <OutlinedInput
                        className="table-input"
                        value={row.original.ammounts.currency}
                        onChange={(e) => handleChangeAmmount(GrantsAmmountsType.CURRENCY, row.original.id, e.target.value)}
                        placeholder={intlMessages('label.insertValue')}
                        type="number"
                        // @ts-ignore
                        endAdornment={
                            <InputAdornment className="ms-2" position="end">
                                <CustomUnit unit={'currency'} />
                            </InputAdornment>
                        }
                    />
                </div>
            );
        },
    },
    {
        id: 'delete',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={'label.delete'} />
                </span>
            </div>
        ),
        minWidth: 10,
        width: 10,
        accessor: 'description',
        Cell: ({ row, handleDelete, matomoDeleteHandler }) => {
            return (
                <IconButton
                    variant="tertiary"
                    icon="deleteBin"
                    onClick={() => {
                        handleDelete(row.original.id);
                        matomoDeleteHandler();
                    }}
                    dataTestId="delete-grant"
                />
            );
        },
    },
];

export const MAX_GRANTS = 5;

export const INITIAL_EVENT = {
    name: null,
};

export const GRANTS_INFO_URL = {
    [getCompanyProfileIds().PROFILE_BASE_UK]: {
        [PRODUCT_IDS.HP]: 'https://www.gov.uk/apply-boiler-upgrade-scheme',
    },
    [getCompanyProfileIds().EVOLO]: {
        [PRODUCT_IDS.HP]: 'https://www.gov.uk/apply-boiler-upgrade-scheme',
    },
    [getCompanyProfileIds().PROFILE_BASE_USA]: {
        [PRODUCT_IDS.HP]: 'https://www.gov.uk/apply-boiler-upgrade-scheme',
    },
};
