import { getDomain } from 'services/settings';
import { isDefined } from 'services/util/auxiliaryUtils';
import { PRODUCT_IDS } from './products';
import { ENVIRONMENTS_FE, URL_BUSINESS_MODELS } from './settings';

export const CONFIG_HEADERS = {
    'Content-Type': 'application/json; charset=utf-8',
    cancellable: true,
    timeout: 90000,
};

/**
 * getBaseURL
 *
 * @return {*}
 */
export function getBaseURL(apiUrl = true) {
    let ENV = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_ENV : ENVIRONMENTS_FE.TST;
    let protocol = ENV === ENVIRONMENTS_FE.DEV ? 'http://' : 'https://';
    return `${protocol}${getDomain(ENV)}/${apiUrl ? 'api/' : ''}`;
}

/**
 * getURL
 *
 * @param {*} name
 */
export const getEndpoint = (name) => {
    switch (name) {
        case URL_BASE:
            return getBaseURL();
        case URL_AUTH:
            return getBaseURL() + URL_AUTH;
        case URL_LOGOUT_ADFS:
            return getBaseURL() + URL_LOGOUT_ADFS;
        case URL_USERS:
            return getBaseURL() + URL_USERS;
        case URL_USERS_INFO:
            return getBaseURL() + URL_USERS_INFO;
        case URL_USERS_HISTORY:
            return getBaseURL() + `${URL_USERS}/${URL_USERS_HISTORY}/${URL_CLIENT}`;
        case URL_THEME:
            return getBaseURL() + `${URL_USERS}/${URL_THEME}`;
        case URL_CLIENT:
            return getBaseURL() + URL_CLIENT;
        case URL_SIMULATIONS:
            return getBaseURL() + URL_SIMULATIONS;
        case URL_INVERTER_COMBINATION:
            return getBaseURL() + URL_INVERTER_COMBINATION;
        case URL_FACILITIES:
            return getBaseURL() + URL_FACILITIES;
        case URL_CONSUMPTIONS:
            return getBaseURL() + URL_CONSUMPTIONS;
        case URL_COUNTIES:
            return getBaseURL() + URL_COUNTIES;
        case URL_NUTS:
            return getBaseURL() + URL_NUTS;
        case URL_PRODUCTS:
            return getBaseURL() + URL_PRODUCTS;
        case URL_WORK_SCHEDULES:
            return getBaseURL() + URL_WORK_SCHEDULES;
        case URL_PV_PARAMETERS:
            return getBaseURL() + URL_PV_PARAMETERS;
        case URL_CLIENT_MANAGERS:
            return getBaseURL() + URL_CLIENT_MANAGERS;
        case URL_SERVICES_MANAGERS:
            return getBaseURL() + URL_SERVICES_MANAGERS;
        case URL_SEGMENTS:
            return getBaseURL() + URL_SEGMENTS;
        case URL_VOLTAGE_LEVELS:
            return getBaseURL() + URL_VOLTAGE_LEVELS;
        case URL_CONNECTION_VOLTAGE:
            return getBaseURL() + URL_CONNECTION_VOLTAGE;
        case URL_PV_AREA_REDUCTION_COEFFICIENTS:
            return getBaseURL() + URL_PV_AREA_REDUCTION_COEFFICIENTS;
        case URL_REGIONS_GD:
            return getBaseURL() + URL_REGIONS_GD;
        case URL_REGION_RADIATIONS:
            return getBaseURL() + URL_REGION_RADIATIONS;
        case URL_PRODUCTIONS_OLD:
            return getBaseURL() + `${URL_FACILITIES}/${URL_PRODUCTIONS}`;
        case URL_PRODUCTIONS:
            return getBaseURL() + `${URL_PRODUCTIONS}`;
        case URL_PRODUCTIONS_CONTRACT:
            return getBaseURL() + `${URL_PRODUCTIONS}/${URL_PRODUCTIONS_CONTRACT}`;
        case URL_DISTRIBUTORS:
            return getBaseURL() + URL_DISTRIBUTORS;
        case URL_GRID_TARIFFS:
            return getBaseURL() + URL_GRID_TARIFFS;
        case URL_GRID_TARIFFS_SOURCES:
            return getBaseURL() + URL_GRID_TARIFFS_SOURCES;
        case URL_TARIFF_OPTIONS:
            return getBaseURL() + URL_TARIFF_OPTIONS;
        case URL_HOURLY_OPTIONS:
            return getBaseURL() + URL_HOURLY_OPTIONS;
        case URL_USED_POWER_TARIFFS:
            return getBaseURL() + URL_USED_POWER_TARIFFS;
        case URL_SUPPLIERS:
            return getBaseURL() + URL_SUPPLIERS;
        case URL_PV_EQUIPMENTS:
            return getBaseURL() + URL_PV_EQUIPMENTS;
        case URL_ENERGY_RISKS:
            return getBaseURL() + URL_ENERGY_RISKS;
        case URL_ENERGY_TARIFFS:
            return getBaseURL() + URL_ENERGY_TARIFFS;
        case URL_CLASSES:
            return getBaseURL() + URL_CLASSES;
        case URL_OWNERS:
            return getBaseURL() + `${URL_USERS}/${URL_OWNERS}`;
        case URL_PARTNERS:
            return getBaseURL() + `${URL_USERS}/${URL_PARTNERS}`;
        case URL_PV_OPTIONS:
            return getBaseURL() + URL_PV_OPTIONS;
        case URL_IMG_CONTRACT:
            return getBaseURL() + URL_IMG_CONTRACT;
        case URL_VALID_PANELS:
            return getBaseURL() + URL_VALID_PANELS;
        case URL_BUSINESS_MODEL_GROUPS:
            return getBaseURL() + URL_BUSINESS_MODEL_GROUPS;
        case URL_FIRST_ARRIVALS:
            return getBaseURL() + URL_FIRST_ARRIVALS;
        case URL_WORK_DAYS_OCCUPATION_PERIOD:
            return getBaseURL() + URL_WORK_DAYS_OCCUPATION_PERIOD;
        case URL_WEEKEND_IN_HOUSE:
            return getBaseURL() + URL_WEEKEND_IN_HOUSE;
        case URL_EQUIPMENT:
            return getBaseURL() + URL_EQUIPMENT;
        case URL_BO_SOLARPV_CMP_PANELS:
            return getBaseURL() + `${URL_PRODUCTS}/1/${URL_COMPONENTS}/${URL_BO_SOLARPV_CMP_PANELS}`;
        case URL_BO_SOLARPV_CMP_INVERTERS:
            return getBaseURL() + `${URL_PRODUCTS}/1/${URL_COMPONENTS}/${URL_BO_SOLARPV_CMP_INVERTERS}`;
        case URL_BO_SOLARPV_CMP_STRUCTURES:
            return getBaseURL() + `${URL_PRODUCTS}/1/${URL_COMPONENTS}/${URL_BO_SOLARPV_CMP_STRUCTURES}`;
        case URL_BO_SOLARPV_CMP_STRUCTURES_IMAGES:
            return getBaseURL() + `${URL_PRODUCTS}/1/${URL_COMPONENTS}/${URL_BO_SOLARPV_CMP_STRUCTURES_IMAGES}`;
        case URL_BO_SOLARPV_TIERS:
            return getBaseURL() + `${URL_PRODUCTS}/1/${URL_COMPONENTS}/${URL_BO_SOLARPV_TIERS}`;
        case URL_BO_SOLARPV_COSTS_PANELS:
            return getBaseURL() + `${URL_PRODUCTS}/1/${URL_COSTS}/${URL_BO_SOLARPV_CMP_PANELS}`;
        case URL_INVERTERS:
            return getBaseURL() + `${URL_PRODUCTS}/1/${URL_COMPONENTS}/${URL_INVERTERS}`;
        case URL_AUDIT_FILES:
            return getBaseURL() + `${URL_SIMULATIONS}/${URL_AUDIT_FILES}`;
        case URL_REMUNERATIONS:
            return getBaseURL() + URL_REMUNERATIONS;
        case URL_RR_REMOVAL_OPTIONS:
            return getBaseURL() + URL_RR_REMOVAL_OPTIONS;
        case URL_RR_INSTALL_OPTIONS:
            return getBaseURL() + URL_RR_INSTALL_OPTIONS;
        case URL_INJECTION_TARIF_DISTRIBUTOR:
            return getBaseURL() + `${URL_INJECTION_TARIF_DISTRIBUTOR}`;
        case URL_COSTS:
            return getBaseURL() + `${URL_COSTS}`;
        case URL_ME_TRENCH_OPTIONS:
            return getBaseURL() + `${URL_ME_TRENCH_OPTIONS}`;
        case URL_ME_PARTNER_LIST:
            return getBaseURL() + `${URL_ME_PARTNER_LIST}`;
        case URL_BO_HEAT_PUMPS:
            return getBaseURL() + `${URL_PRODUCTS}/3/${URL_BO_HEAT_PUMPS}`;
        case URL_BO_IMPORT_HEAT_PUMPS:
            return getBaseURL() + `${URL_BO_IMPORT_HEAT_PUMPS}`;
        case URL_BO_HP_PRODUCT_BASE:
            return getBaseURL() + URL_BO_HP_PRODUCT_BASE;
        case URL_BO_HP_GET_BRANDS:
            return getBaseURL() + URL_BO_HP_GET_BRANDS;
        case URL_BO_HP_FAST_MODULE_QUESTIONS:
            return getBaseURL() + URL_BO_HP_FAST_MODULE_QUESTIONS;
        case URL_BO_HP_FAST_MODULE_COMBINATIONS:
            return getBaseURL() + URL_BO_HP_FAST_MODULE_COMBINATIONS;
        case URL_BO_HP_FAST_MODULE_RESET:
            return getBaseURL() + URL_BO_HP_FAST_MODULE_RESET;
        case URL_BO_HP_BUSINESS_MODELS:
            return getBaseURL() + URL_BUSINESS_MODELS;
        case URL_BO_EMITTERS:
            return getBaseURL() + `${URL_PRODUCTS}/3/${URL_BO_EMITTERS}`;
        case URL_BO_EXTRAS:
            return getBaseURL() + `${URL_PRODUCTS}/3/${URL_BO_EXTRAS}`;
        case URL_BO_EXTRAS_TYPES:
            return getBaseURL() + `${URL_PRODUCTS}/3/${URL_BO_EXTRAS_TYPES}`;
        case URL_BO_INSTALLATION_PRICES:
            return getBaseURL() + `${URL_PRODUCTS}/3/${URL_BO_INSTALLATION_PRICES}`;
        case URL_PRODUCTION_SOURCES:
            return getBaseURL() + URL_PRODUCTION_SOURCES;
        case URL_DS:
            return getBaseURL() + URL_DS;
        case URL_GRANTS:
            return getBaseURL() + URL_GRANTS;
        case URL_IMPORT_EXTERNAL:
            return getBaseURL() + `${URL_CLIENT}/${URL_IMPORT_EXTERNAL}`;
        case URL_INPUTS_UPLOADS:
            return getBaseURL() + URL_INPUTS_UPLOADS;
        case URL_BO_SOLAR_PANEL_BRANDS:
            return getBaseURL() + `${URL_PRODUCTS}/1/${URL_BO_SOLAR_PANEL_BRANDS}`;
        case URL_BO_SOLAR_INVERTER_BRANDS:
            return getBaseURL() + `${URL_PRODUCTS}/1/${URL_BO_SOLAR_INVERTER_BRANDS}`;
        case URL_BO_SOLAR_COMPONENTS_PANELS:
            return getBaseURL() + `${URL_PRODUCTS}${URL_BO_SOLAR_COMPONENTS_PANELS}`;
        case URL_BO_SOLAR_COMPONENTS_INVERTERS:
            return getBaseURL() + `${URL_PRODUCTS}${URL_BO_SOLAR_COMPONENTS_INVERTERS}`;
        case URL_PROJECTS:
            return getBaseURL() + URL_PROJECTS;
        case URL_EDIT_RISKS:
            return getBaseURL() + `${URL_USERS}/${URL_USERS_CONFIG}/${URL_EDIT_RISKS}`;
        case URL_EDIT_TNW:
            return getBaseURL() + `${URL_USERS}/${URL_USERS_CONFIG}/${URL_EDIT_TNW}`;
        default: //URL_BASE
            console.log('An endpoint might not be mapped in function getEndpoint');
            return getBaseURL();
    }
};

/**
 * getStaticFiles
 * @description ficheiros estaticos que encontra-se na AzrStorage
 *
 * @param {*} filename
 */
export function getStaticFiles(filename, dir1 = '', dir2 = '', dir3 = '') {
    return isDefined(filename) ? `${getBaseURL()}${process.env.REACT_APP_API_URL_STATIC_FILES}${dir1}${dir2}${dir3}${filename}` : undefined;
}

// Debouncing time - time that a user has to input something else before a request is sent in milliseconds
export const DEBOUNCE_TIME = 300;
// Const for requests
export const CANCELED_REQUEST = 'CANCELED_REQUEST';

// * ACTION TYPES * \\
export const URL_BASE = '';
export const URL_AUTH = 'auth';
export const URL_LOGOUT_ADFS = 'auth/adfs';
export const URL_USERS = 'users';
export const URL_USERS_INFO = 'users/info';
export const URL_USERS_CONFIG = 'user-config';
export const URL_AUTH_MODE = 'auth-modes';
export const URL_USERS_HISTORY = 'historical';
export const URL_THEME = 'theme';
export const URL_CLIENT = 'clients';
export const URL_SIMULATIONS = 'simulations';
export const URL_FACILITIES = 'facilities';
export const URL_NUTS = 'territorial-units';
export const URL_COUNTIES = 'counties';
export const URL_PRODUCTS = 'products';
export const URL_WORK_SCHEDULES = 'work-schedules';
export const URL_PV_PARAMETERS = 'pv-parameters';
export const URL_CLIENT_MANAGERS = 'client-managers';
export const URL_SERVICES_MANAGERS = 'services-managers';
export const URL_SEGMENTS = 'segments';
export const URL_BUSINESS_MODEL_GROUPS = 'business-model-groups';
export const URL_VOLTAGE_LEVELS = 'voltage-levels';
export const URL_CONNECTION_VOLTAGE = 'connection-voltages';
export const URL_PV_AREA_REDUCTION_COEFFICIENTS = 'pv-area-reduction-coefficients';
export const URL_REGIONS_GD = 'regions-gd';
export const URL_REGION_RADIATIONS = 'region-radiations';
export const URL_PRODUCTIONS = 'productions';
export const URL_PRODUCTIONS_OLD = 'productions_old';
export const URL_PRODUCTIONS_CONTRACT = 'contract';
export const URL_CONSUMPTIONS = 'consumptions';
export const URL_DISTRIBUTORS = 'distributors';
export const URL_GRID_TARIFFS = 'grid-tariffs';
export const URL_GRID_TARIFFS_SOURCES = 'grid-tariff-sources';
export const URL_TARIFF_OPTIONS = 'tariff-options';
export const URL_HOURLY_OPTIONS = 'hourly-options';
export const URL_USED_POWER_TARIFFS = 'used-power-tariffs';
export const URL_SUPPLIERS = 'suppliers';
export const URL_PV_EQUIPMENTS = 'pv-equipments';
export const URL_ENERGY_RISKS = 'energy-risks';
export const URL_ENERGY_TARIFFS = 'energy-tariffs';
export const URL_EDIT_RISKS = 'edita_risco';
export const URL_EDIT_TNW = 'edita_tnw';
export const URL_CLASSES = 'classes';
export const URL_OWNERS = 'owner';
export const URL_PARTNERS = 'partner';
export const URL_PV_OPTIONS = 'pv-options';
export const URL_INVERTERS = 'inverters';
export const URL_INVERTER_COMBINATION = 'inverter_combination';
export const URL_IMG_CONTRACT = 'img-contract';
export const URL_VALID_PANELS = 'getValidPanels';
export const URL_FIELD_DATA_SS = 'field-data-ss';
export const URL_AUDIT_FILES = 'audit-files';
export const URL_FIRST_ARRIVALS = 'first-arrivals';
export const URL_WORK_DAYS_OCCUPATION_PERIOD = 'work-days-occupation-period';
export const URL_WEEKEND_IN_HOUSE = 'weekend-in-house';
export const URL_EQUIPMENT = 'equipments';
export const URL_REMUNERATIONS = 'remunerations';
export const URL_ME_CPE_DETAILS_FILE = 'cpe-details-file';
export const URL_ME_TRENCH_OPTIONS = URL_PRODUCTS + '/' + PRODUCT_IDS.EVC + '/trench-options';
export const URL_ME_PARTNER_LIST = URL_PRODUCTS + '/' + PRODUCT_IDS.EVC + '/partner-list';
export const URL_INJECTION_TARIF_DISTRIBUTOR = 'products/1/injection-tariff-distributor';
export const URL_PRODUCTION_SOURCES = 'production-sources';
export const URL_DS = 'ds';
export const URL_GRANTS = 'products/1/grants';
export const URL_IMPORT_EXTERNAL = 'import';
export const URL_INPUTS_UPLOADS = 'inputs-uploads';
//#region Backoffice
export const URL_COMPONENTS = 'components';
export const URL_BO_SOLARPV_CMP_PANELS = 'panels';
export const URL_BO_SOLARPV_CMP_INVERTERS = 'inverters';
export const URL_BO_SOLARPV_CMP_STRUCTURES = 'structures';
export const URL_BO_SOLARPV_CMP_STRUCTURES_IMAGES = 'structures-images';
export const URL_BO_SOLARPV_TIERS = 'ranges';
export const URL_COSTS = 'costs';
export const URL_BO_SOLARPV_COSTS_PANELS = 'panels_costs';
export const URL_BO_HEAT_PUMPS = 'heat-pumps';
export const URL_BO_COMPANY_LIST = 'company-list';
export const URL_BO_IMPORT_HEAT_PUMPS = URL_PRODUCTS + '/3/' + URL_BO_HEAT_PUMPS + '/' + URL_BO_COMPANY_LIST;
export const URL_BO_HP_PRODUCT_BASE = URL_PRODUCTS + '/3/' + URL_BO_HEAT_PUMPS;
export const URL_BO_HP_BRANDS = 'heat-pump-brands';
export const URL_BO_HP_GET_BRANDS = URL_PRODUCTS + '/3/' + URL_BO_HP_BRANDS;
export const URL_BO_HP_FAST_MODULE_QUESTIONS = URL_PRODUCTS + '/3/' + 'fast-module-questions';
export const URL_BO_HP_FAST_MODULE_COMBINATIONS = URL_PRODUCTS + '/3/' + 'fast-module-answer-combinations';
export const URL_BO_HP_FAST_MODULE_RESET = URL_PRODUCTS + '/3/' + 'fast-module-reset';
export const URL_BO_HP_BUSINESS_MODELS = URL_BUSINESS_MODELS;
export const URL_BO_BUSINESS_MODEL_GROUPS = URL_BUSINESS_MODEL_GROUPS;
export const URL_BO_EMITTERS = 'emitters';
export const URL_BO_EMITTERS_PIPE_SIZES = 'ufh-pipe-sizes';
export const URL_BO_RADIATOR_TYPES = 'radiator-types';
export const URL_BO_FANCOIL_TYPES = 'fancoil-types';
export const URL_BO_EXTRAS = 'extras';
export const URL_BO_EXTRAS_TYPES = 'extras-types';
export const URL_BO_INSTALLATION_PRICES = 'installation-prices';
export const URL_BO_SOLAR_PANEL_BRANDS = 'panel-brands';
export const URL_BO_SOLAR_INVERTER_BRANDS = 'inverter-brands';
export const URL_BO_SOLAR_COMPONENTS_PANELS = '/1/' + URL_BO_SOLARPV_CMP_PANELS;
export const URL_BO_SOLAR_COMPONENTS_INVERTERS = '/1/' + URL_BO_SOLARPV_CMP_INVERTERS;
export const URL_PROJECTS = 'projects';
export const URL_FACILITY = 'facility';
//#endregion
//#region Product RR
export const URL_RR_STRUCTURES = 'roof_structures';
export const URL_RR_REMOVAL_OPTIONS = URL_PRODUCTS + '/32/' + URL_RR_STRUCTURES + '/removal';
export const URL_RR_INSTALL_OPTIONS = URL_PRODUCTS + '/32/' + URL_RR_STRUCTURES + '/installation';
//#endregion Product RR
