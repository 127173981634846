import IntlMessages from 'components/util/IntlMessages';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront, UpFrontEvents } from 'interfaces/businessModels/upfront';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { isFieldDefined, removeEmptyInObj } from 'services/util/auxiliaryUtils';
import { Button } from '@save2compete/efz-design-system';

const ProposalGenerationButtonUpFront = () => {
    const { setBMEventHandler, bmSelected, isRequestingBM } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TUpFront
    >;

    const { watch } = useFormContext();

    const conditionToDisable =
        isRequestingBM || bmSelected.values?.grants?.totalGrants?.currency > 0 ?
            bmSelected.values?.grants?.grants.some((grant) => !isFieldDefined(grant.description)) ||
            bmSelected.values?.grants?.totalGrants?.prc > 100
        :   false;

    const onProposalGenerationClick = () => {
        const formInputs = watch();

        const valuesToUpdate = {
            clientContactInfo: null,
            id_crm: null,
        };
        if (formInputs?.contact_info) valuesToUpdate.clientContactInfo = formInputs.contact_info;
        if (formInputs?.id_crm) valuesToUpdate.id_crm = formInputs.id_crm;

        const hasValuesToUpdate = !!valuesToUpdate.clientContactInfo || !!valuesToUpdate.id_crm;
        if (hasValuesToUpdate) setBMEventHandler(UpFrontEvents.UPDATE_ADDITIONAL_DATA, removeEmptyInObj(valuesToUpdate));

        setTimeout(() => {
            setBMEventHandler(UpFrontEvents.SET_UPFRONT_PROPOSAL, null);
        }, 100);
    };

    return (
        <Button
            className="bm-proposal-generation-button"
            onClick={onProposalGenerationClick}
            disabled={conditionToDisable}
            dataTestId="bm-proposal-generation"
        >
            <IntlMessages id="page.bm.proposalGeneral" />
        </Button>
    );
};

export default ProposalGenerationButtonUpFront;
