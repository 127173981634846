/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description API requests to the context of Proposal
 *
 * @version 20190209
 * @version 20190203
 * @version 20191202
 * @version 20191129
 * @since 20191113 Initial release
 *
 */

import axios from 'axios';
import { getMessageNetworkError } from 'services/statusCodeHttp';
import { getEndpoint, URL_SIMULATIONS } from 'constants/endpoints';

const CancelToken = axios.CancelToken;
// Cancel executor variables
let postPIDCancelExecutor;
let headSDCancelExecutor;
let getSDCancelExecutor;
let postSendEmailMEARCancelExecutor;

export const postProposalID = (payload) => {
    postPIDCancelExecutor && postPIDCancelExecutor();

    return axios
        .post(getEndpoint(URL_SIMULATIONS), payload, {
            cancelToken: new CancelToken(function executor(c) {
                postPIDCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getProposalInformation = (payload, abort) => {
    return axios
        .request({
            url: `${getEndpoint(URL_SIMULATIONS) + '/' + payload.processId}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const headSupportDocs = (payload) => {
    headSDCancelExecutor && headSDCancelExecutor();

    return axios
        .head(getEndpoint(URL_SIMULATIONS) + '/' + payload + '/support-docs', {
            cancelToken: new CancelToken(function executor(c) {
                headSDCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getSupportDocs = (payload) => {
    getSDCancelExecutor && getSDCancelExecutor();

    return axios
        .get(getEndpoint(URL_SIMULATIONS) + '/' + payload.id + '/support-docs', {
            cancelToken: new CancelToken(function executor(c) {
                getSDCancelExecutor = c;
            }),
            responseType: 'arraybuffer',
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getSimulationsByID = (proposalID) => {
    getSDCancelExecutor && getSDCancelExecutor();

    return axios
        .get(`${getEndpoint(URL_SIMULATIONS)}/${proposalID}`, {
            cancelToken: new CancelToken(function executor(c) {
                getSDCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postSendEmailMEAR = (proposalID, payload) => {
    postSendEmailMEARCancelExecutor && postSendEmailMEARCancelExecutor();

    return axios
        .post(`${getEndpoint(URL_SIMULATIONS)}/${proposalID}/send-email-me-ar`, payload, {
            cancelToken: new CancelToken(function executor(c) {
                postSendEmailMEARCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
