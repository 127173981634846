//PropTypes
import PropTypes from 'prop-types';

export const PROPTYPES_CUSTOM_STYLES = {
    customStyles: PropTypes.shape({
        className: PropTypes.string,
        idName: PropTypes.string,
        styles: PropTypes.object,
    }),
};

//DYNAMIC FORM
//gc =  generic
export const DEFAULT_DF_CLASSNAME = {
    dynamicFormGC: 'dynamic-form-gc',
    inputGC: 'input-gc',
    inputTextGC: 'input-text-gc',
    listInputGC: 'input-list-gc',
    datePickerGC: 'date-picker-gc',
    radioButtonGC: 'radio-button-gc',
    selectGC: 'select-gc',
    cardGC: 'card-gc',
    sliderGC: 'slider-gc',
    buttonGC: 'button-gc',
    submitGC: 'submit-gc',
    warningGC: 'warning-gc',
    listLightsGC: 'list-lights-gc',
    listsZonesGC: 'list-zones-gc',
    listsChargersGC: 'list-chargers-gc',
    listInputRowsGC: 'list-input-rows-gc',
    energyConsumptionGC: 'energy-consumption-gc',
    workingHoursPerYearGC: 'working-hours-per-year-gc',
    listCPEGC: 'list-cpe-gc',
    daysOfWeekGC: 'days-of-week-gc',
    listIconGC: 'list-icon-gc',
    scheduleGC: 'schedule-gc',
    singleLineGridGC: 'single-line-grid-gc',
    tooltipGC: 'tooltip-gc',
    tableTextGC: 'table-text-gc',
    autocompleteGC: 'autocomplete-gc',
    sectionCardGC: 'section-card-gc',
    periodTariffsGC: 'period-tariffs-gc',
    titleGC: 'title-gc',
};

//Empty Object
export const DEFAULT_CUSTOM_STYLES = {
    className: null,
    idName: null,
    styles: null,
};
//Default Class Setups
//2 Coluns
export const DEFAULT_CLASSNAME = {
    twoColumns: ' col-lg-6 mb-5',
    threeColumns: ' col-sm-12 col-md-6 col-lg-4 mb-5',
    fourColumns: ' col-sm-6 col-md-4 col-lg-3 mb-3',
    confirmationDialogGC: 'confirmation-dialog-gc',
};
