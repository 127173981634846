import clone from 'fast-copy';
import validate from 'services/util/validate';
import { intlMessages } from 'services/util/auxiliaryUtils';
import { getBaseURL } from 'constants/endpoints';
import { GA_LABEL_FACILITY } from 'constants/gAnalytics';
import { PRODUCT_IDS } from 'constants/products';
import { isInvalidCAE } from 'services/facility';
import { getCompanyProfileIds } from 'services/user';

/* action types */
export const SET_FACILITY = 'SET_FACILITY';
export const REQUEST_GET_FACILITY_STATS = 'REQUEST_GET_FACILITY_STATS';
export const REQUEST_GET_FACILITY_STATS_SUCCESS = 'REQUEST_GET_FACILITY_STATS_SUCCESS';
export const REQUEST_GET_FACILITY = 'REQUEST_GET_FACILITY';
export const REQUEST_GET_FACILITY_SUCCESS = 'REQUEST_GET_FACILITY_SUCCESS';
export const REQUEST_GET_FACILITY_FAIL = 'REQUEST_GET_FACILITY_FAIL';
export const REQUEST_GET_FACILITY_SIMPLE = 'REQUEST_GET_FACILITY_SIMPLE';
export const REQUEST_GET_FACILITY_SIMPLE_SUCCESS = 'REQUEST_GET_FACILITY_SIMPLE_SUCCESS';
export const REQUEST_GET_FACILITY_SIMPLE_FAIL = 'REQUEST_GET_FACILITY_SIMPLE_FAIL';
export const REQUEST_GET_CREATE_FACILITY = 'REQUEST_GET_CREATE_FACILITY';
export const REQUEST_GET_CREATE_FACILITY_SUCCESS = 'REQUEST_GET_CREATE_FACILITY_SUCCESS';
export const REQUEST_GET_CREATE_FACILITY_FAIL = 'REQUEST_GET_CREATE_FACILITY_FAIL';
export const RESET_FACILITY = 'RESET_FACILITY';
export const SET_IS_OPEN_FACILITY_CARD = 'SET_IS_OPEN_FACILITY_CARD';
export const REQUEST_SAVE_CHANGE_FACILITY = 'REQUEST_SAVE_CHANGE_FACILITY';
export const REQUEST_SAVE_CHANGE_FACILITY_SUCCESS = 'REQUEST_SAVE_CHANGE_FACILITY_SUCCESS';
export const REQUEST_SAVE_CHANGE_FACILITY_FAIL = 'REQUEST_SAVE_CHANGE_FACILITY_FAIL';
export const RESET_FACILITY_OPTIONS = 'RESET_FACILITY_OPTIONS';
export const REQUEST_GET_FACILITY_TYPES = 'REQUEST_GET_FACILITY_TYPES';
export const REQUEST_GET_FACILITY_TYPES_SUCCESS = 'REQUEST_GET_FACILITY_TYPES_SUCCESS';
export const REQUEST_GET_FACILITY_TYPES_FAIL = 'REQUEST_GET_FACILITY_TYPES_FAIL';
export const REQUEST_SAVE_LOAD_CURVE_FACILITY = 'REQUEST_SAVE_LOAD_CURVE_FACILITY';
export const REQUEST_SAVE_LOAD_CURVE_FACILITY_SUCCESS = 'REQUEST_SAVE_LOAD_CURVE_FACILITY_SUCCESS';
export const REQUEST_SAVE_LOAD_CURVE_FACILITY_FAIL = 'REQUEST_SAVE_LOAD_CURVE_FACILITY_FAIL';
export const REQUEST_DISABLE_FACILITY = 'REQUEST_DISABLE_FACILITY';
export const REQUEST_DISABLE_FACILITY_SUCCESS = 'REQUEST_DISABLE_FACILITY_SUCCESS';
export const REQUEST_DISABLE_FACILITY_FAIL = 'REQUEST_DISABLE_FACILITY_FAIL';
export const REQUEST_WEIGHTS_BY_CAE = 'REQUEST_WEIGHTS_BY_CAE';
export const REQUEST_WEIGHTS_BY_CAE_SUCCESS = 'REQUEST_WEIGHTS_BY_CAE_SUCCESS';
export const REQUEST_WEIGHTS_BY_CAE_FAIL = 'REQUEST_WEIGHTS_BY_CAE_FAIL';
export const REQUEST_WEIGHTS_BY_CAE_NO_INFORMATION = 'REQUEST_WEIGHTS_BY_CAE_NO_INFORMATION';
export const REQUEST_WEIGHTS_BY_CAE_WITH_PONDERADOR = 'REQUEST_WEIGHTS_BY_CAE_WITH_PONDERADOR';
export const REQUEST_ANNUAL_CONSUMPTION = 'REQUEST_ANNUAL_CONSUMPTION';
export const REQUEST_ANNUAL_CONSUMPTION_SUCCESS = 'REQUEST_ANNUAL_CONSUMPTION_SUCCESS';
export const REQUEST_ANNUAL_CONSUMPTION_FAIL = 'REQUEST_ANNUAL_CONSUMPTION_FAIL';
export const REQUEST_MONTHLY_CONSUMPTION = 'REQUEST_MONTHLY_CONSUMPTION';
export const REQUEST_MONTHLY_CONSUMPTION_SUCCESS = 'REQUEST_MONTHLY_CONSUMPTION_SUCCESS';
export const REQUEST_MONTHLY_CONSUMPTION_FAIL = 'REQUEST_MONTHLY_CONSUMPTION_FAIL';
export const REQUEST_UPLOAD_LOAD_CURVE = 'REQUEST_UPLOAD_LOAD_CURVE';
export const REQUEST_UPLOAD_LOAD_CURVE_SUCCESS = 'REQUEST_UPLOAD_LOAD_CURVE_SUCCESS';
export const REQUEST_UPLOAD_LOAD_CURVE_FAIL = 'REQUEST_UPLOAD_LOAD_CURVE_FAIL';
export const REQUEST_DOWNLOAD_LOAD_CURVE = 'REQUEST_DOWNLOAD_LOAD_CURVE';
export const REQUEST_DOWNLOAD_LOAD_CURVE_SUCCESS = 'REQUEST_DOWNLOAD_LOAD_CURVE_SUCCESS';
export const REQUEST_DOWNLOAD_LOAD_CURVE_FAIL = 'REQUEST_DOWNLOAD_LOAD_CURVE_FAIL';
export const REQUEST_DISTRIBUTORS_BY_NUT = 'REQUEST_DISTRIBUTORS_BY_NUT';
export const REQUEST_DISTRIBUTORS_BY_NUT_SUCCESS = 'REQUEST_DISTRIBUTORS_BY_NUT_SUCCESS';
export const REQUEST_DISTRIBUTORS_BY_NUT_FAIL = 'REQUEST_DISTRIBUTORS_BY_NUT_FAIL';
export const SET_IS_DIRTY_FORM_FACILITY = 'SET_IS_DIRTY_FORM_FACILITY';
export const SET_FACILITY_BY_TOKEN = 'SET_FACILITY_BY_TOKEN';
export const REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS = 'REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS';
export const REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_SUCCESS = 'REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_SUCCESS';
export const REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_FAIL = 'REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_FAIL';
export const SET_FACILITY_OPCAO_TARIFARIO_ID = 'SET_FACILITY_OPCAO_TARIFARIO_ID';
export const REQUEST_SET_GRID_TARIFF_SOURCES = 'REQUEST_SET_GRID_TARIFF_SOURCES';
export const REQUEST_SET_GRID_TARIFF_SOURCES_SUCCESS = 'REQUEST_SET_GRID_TARIFF_SOURCES_SUCCESS';
export const REQUEST_SET_GRID_TARIFF_SOURCES_FAIL = 'REQUEST_SET_GRID_TARIFF_SOURCES_FAIL';
export const SET_CHANGE_ADDRESS_FACILITY = 'SET_CHANGE_ADDRESS_FACILITY';

/* default objects */
export const INPUT_NAMES = {
    ID: 'id',
    CPE: 'cpe',
    METER_ID: 'meter_id',
    DESCRICAO: 'descricao',
    FORNECEDOR_ID: 'fornecedor_id',
    POWER_FEE: 'power_fee',
    TARIFA_KW_DIA: 'tarifa_kw_dia',
    MORADA: 'morada',
    TIPO_INSTALACAO_ID: 'tipo_instalacao_id',
    MUNICIPIO_ID: 'municipio_id',
    MUNICIPIO_DESCRICAO: 'municipio_descricao',
    COUNTY: 'county',
    NUT: 'nuts_i_id',
    COMPLEMENTO: 'complemento',
    NUMERO: 'numero',
    BAIRRO: 'bairro',
    LOCALIDADE: 'localidade',
    CODIGO_POSTAL: 'codigo_postal',
    POTENCIA_CONTRATADA: 'potencia_contratada',
    POTENCIA_CONTRATADA_PONTA: 'potencia_ponta',
    NUM_FASES: 'num_fases',
    CLASSE_ID: 'classe_id',
    DATA_IDADE_RESPOSTA_CLIENTE: 'data_idade_resposta_cliente',
    NUM_TRABALHADORES: 'num_trabalhadores',
    AREA: 'area',
    AREA_COBERTA: 'area_coberta',
    AREA_UTIL: 'area_util',
    DAYS_OF_WORK: 'days_of_work',
    TRABALHA_2A: 'trabalha_2a',
    TRABALHA_3A: 'trabalha_3a',
    TRABALHA_4A: 'trabalha_4a',
    TRABALHA_5A: 'trabalha_5a',
    TRABALHA_6A: 'trabalha_6a',
    WORKING_DAYS: 'working_days',
    TRABALHA_SAB: 'trabalha_sab',
    TRABALHA_DOM: 'trabalha_dom',
    TRABALHA_FERIADO: 'trabalha_feriado',
    HORARIO_DIAS_SEMANA: 'horario_dias_semana',
    HORARIO_DIAS_SEMANA_TIME: 'horario_dias_semana_time',
    HORARIO_DOMINGO_E_FERIADO: 'horario_domingo_e_feriado',
    HORARIO_SABADO: 'horario_sabado',
    TOTAL_TARIFFS: 'total_tariffs',
    CONSUMO_ANUAL: 'consumo_anual',
    CONSUMO_ANUAL_MWH: 'consumo_anual_mwh',
    REGIAO_GD_ID: 'regiao_gd_id',
    SAVING_ESTIMATE_SUM: 'saving_estimate_sum',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
    POTENCIA_INSTALADA: 'potencia_instalada',
    POTENCIA_REQUISITADA: 'potencia_requisitada',
    SUPPLIED_BY_COMPANY: 'supplied_by_company',
    GAS_SUPPLIED_BY_COMPANY: 'gas_supplied_by_company',
    CONSUMO_ANUAL_GAS: 'consumo_anual_gas',
    CONSUMO_ANUAL_GAS_MWH: 'consumo_anual_gas_mwh',
    TARIFA_FIXA_GAS: 'tarifa_fixa_gas',
    TARIFA_FIXA_GAS_MWH: 'tarifa_fixa_gas_mwh',
    TARIFA_MEDIA_GAS: 'tarifa_media_gas',
    TARIFA_MEDIA_GAS_MWH: 'tarifa_media_gas_mwh',
    NIVEL_TENSAO_ID: 'nivel_tensao_id',
    TENSAO_CONEXAO_ID: 'tensao_conexao_id',
    FORNECEDOR_GAS_ID: 'fornecedor_gas_id',
    DISTRIBUIDOR_ID: 'distribuidor_id',
    OPCAO_TARIFARIO_ID: 'opcao_tarifario_id',
    OPCAO_HORARIO_ID: 'opcao_horario_id',
    FONTE_TARIFA_REDES_ID: 'fonte_tarifa_redes_id',
    ENERGY_TARIFFS: 'energy_tariffs',
    OPCAO_CURVA_CARGA_ID: 'opcao_curva_carga_id',
    OPCAO_CURVA_CARGA_SELECIONADA: 'opcao_curva_carga_seleccionada',
    ACTIVITY_CODE: 'activity_code',
    CONSUMPTION_WEIGHTS: 'consumption_weights',
    HORARIO_TEM_IMPACTO: 'horario_tem_impacto',
    TEM_TELECONTAGEM: 'tem_telecontagem',
    TEM_DADOS_INTEGRACAO_REAIS: 'tem_dados_integracao_reais',
    TEM_DADOS_INTEGRACAO_ESTATISTICOS: 'tem_dados_integracao_estatisticos',
    TIPO_CICLO_ID: 'tipo_ciclo_id',
    ADJUST_WORK_DAYS: 'adjust_work_days',
    TEM_DADOS_PONDERADOR_ESTIMATIVA: 'tem_dados_ponderador_estimativa_simples',
    TEM_DADOS_PONDERADOR_ESTIMATIVA_SIMPLES_POR_PERIODO: 'tem_dados_ponderador_estimativa_simples_por_periodo',
    TEM_DADOS_PONDERADOR_INTEGRACAO: 'tem_dados_ponderador_integracao_personalizada',
    DETAILED_TARIFFS: 'detailed_tariffs', //only FE
    NOME_FICHEIRO_CURVA_CARGA: 'nome_ficheiro_curva_carga',
    TARIFA_PERIODO_1: 'tarifa_periodo_1',
    TARIFA_PERIODO_2: 'tarifa_periodo_2',
    LOAD_PROFILE: 'load_profile',
    WORK_DAYS_OCCUPIED: 'work_days_occupied',
    WORK_DAYS_OCCUPATION_PERIOD_ID: 'work_days_occupation_period_id',
    WORK_DAYS_FIRST_ARRIVALS_ID: 'work_days_first_arrivals_id',
    WEEKENDS_OCCUPIED: 'weekends_occupied',
    WEEKEND_OCCUPATION_ID: 'weekend_occupation_id',
    WEEKENDS_FIRST_ARRIVALS_ID: 'weekends_first_arrivals_id',
};

export const LOAD_PROFILE = {
    SIMPLE_ESTIMATION: '1',
    CUSTOMIZED_CURVES: '2',
};

export const NUMBER_DECIMALS_TARIFFS = 6;
export const EMPTY_NIVEL_TENSAO_ID = 19;

export const CONTRACTED_POWER_LIMITS = {
    EDP_PT: { max: 1000000, min: 1.15 },
    DEFAULT: { max: 1000000, min: 0 },
};

export const ANNUAL_CONSUMPTION_LIMITS = {
    //Max Included and Min Not Included
    DEFAULT: { max: 1000000, minNI: 0 },
};

export const CAE_LENGTH_LIMITS = {
    //Max and Min Included
    DEFAULT: { max: 8, min: 2 },
};

export const VALID_CAES = [
    '00',
    '01',
    '02',
    '03',
    '05',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '35',
    '36',
    '37',
    '38',
    '39',
    '41',
    '42',
    '43',
    '45',
    '46',
    '47',
    '49',
    '50',
    '51',
    '52',
    '53',
    '55',
    '56',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '77',
    '78',
    '79',
    '80',
    '81',
    '82',
    '84',
    '85',
    '86',
    '87',
    '88',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
];

export const INPUTS_DEFAULTS_FACILITY = {
    [INPUT_NAMES.ID]: null,
    [INPUT_NAMES.CPE]: null,
    [INPUT_NAMES.METER_ID]: null,
    [INPUT_NAMES.DESCRICAO]: null,
    [INPUT_NAMES.FORNECEDOR_ID]: null,
    [INPUT_NAMES.POWER_FEE]: null,
    [INPUT_NAMES.TARIFA_KW_DIA]: null,
    [INPUT_NAMES.MORADA]: null,
    [INPUT_NAMES.TIPO_INSTALACAO_ID]: null,
    [INPUT_NAMES.MUNICIPIO_ID]: null,
    [INPUT_NAMES.MUNICIPIO_DESCRICAO]: null,
    [INPUT_NAMES.LOCALIDADE]: null,
    [INPUT_NAMES.CODIGO_POSTAL]: null,
    [INPUT_NAMES.POTENCIA_CONTRATADA]: null,
    [INPUT_NAMES.POTENCIA_CONTRATADA_PONTA]: null,
    [INPUT_NAMES.POTENCIA_REQUISITADA]: null,
    [INPUT_NAMES.NUM_FASES]: null,
    [INPUT_NAMES.CLASSE_ID]: null,
    [INPUT_NAMES.DATA_IDADE_RESPOSTA_CLIENTE]: null,
    [INPUT_NAMES.NUM_TRABALHADORES]: null,
    [INPUT_NAMES.AREA]: null,
    [INPUT_NAMES.AREA_COBERTA]: null,
    [INPUT_NAMES.AREA_UTIL]: null,
    [INPUT_NAMES.TRABALHA_2A]: null,
    [INPUT_NAMES.TRABALHA_3A]: null,
    [INPUT_NAMES.TRABALHA_4A]: null,
    [INPUT_NAMES.TRABALHA_5A]: null,
    [INPUT_NAMES.TRABALHA_6A]: null,
    [INPUT_NAMES.TRABALHA_SAB]: null,
    [INPUT_NAMES.TRABALHA_DOM]: null,
    [INPUT_NAMES.TRABALHA_FERIADO]: null,
    [INPUT_NAMES.HORARIO_DIAS_SEMANA]: [],
    [INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]: [],
    [INPUT_NAMES.HORARIO_SABADO]: [],
    [INPUT_NAMES.TOTAL_TARIFFS]: [],
    [INPUT_NAMES.CONSUMO_ANUAL_MWH]: null,
    [INPUT_NAMES.REGIAO_GD_ID]: null,
    [INPUT_NAMES.SAVING_ESTIMATE_SUM]: null,
    [INPUT_NAMES.LATITUDE]: null,
    [INPUT_NAMES.LONGITUDE]: null,
    [INPUT_NAMES.POTENCIA_INSTALADA]: null,
    [INPUT_NAMES.POTENCIA_REQUISITADA]: null,
    [INPUT_NAMES.SUPPLIED_BY_COMPANY]: null,
    [INPUT_NAMES.GAS_SUPPLIED_BY_COMPANY]: null,
    [INPUT_NAMES.CONSUMO_ANUAL_GAS]: null,
    [INPUT_NAMES.CONSUMO_ANUAL_GAS_MWH]: null,
    [INPUT_NAMES.TARIFA_FIXA_GAS]: null,
    [INPUT_NAMES.TARIFA_FIXA_GAS_MWH]: null,
    [INPUT_NAMES.TARIFA_MEDIA_GAS]: null,
    [INPUT_NAMES.TARIFA_MEDIA_GAS_MWH]: null,
    [INPUT_NAMES.NIVEL_TENSAO_ID]: null,
    [INPUT_NAMES.TENSAO_CONEXAO_ID]: null,
    [INPUT_NAMES.DISTRIBUIDOR_ID]: null,
    [INPUT_NAMES.OPCAO_TARIFARIO_ID]: null,
    [INPUT_NAMES.OPCAO_CURVA_CARGA_ID]: null,
    [INPUT_NAMES.OPCAO_CURVA_CARGA_SELECIONADA]: null,
    [INPUT_NAMES.HORARIO_TEM_IMPACTO]: null,
    [INPUT_NAMES.TEM_TELECONTAGEM]: null,
    [INPUT_NAMES.TEM_DADOS_INTEGRACAO_REAIS]: null,
    [INPUT_NAMES.TEM_DADOS_INTEGRACAO_ESTATISTICOS]: null,
    [INPUT_NAMES.TIPO_CICLO_ID]: null,
    [INPUT_NAMES.ADJUST_WORK_DAYS]: null,
};

export const FACILITY_OPTIONS = {
    types: null,
    voltageLevels: null,
    conectionVoltages: null,
    distributors: null,
    gridTariffs: null,
    tariffOptions: null,
    suppliers: null,
    graphs: {
        monthly: null,
        daily: null,
    },
    classes: null,
    monthlyConsumption: null,
    consumptionWeightsSum: null,
    loadingEstimates: false,
    loadingInputsCurveLoad: false,
};

export const INITIAL_FACILITY = {
    info: {
        ...clone(INPUTS_DEFAULTS_FACILITY),
    },
    isRequesting: true,
    facilityTypes: null,
    isRequestingSave: false,
    isOpenFacilityCard: false,
    isRequestingCAEWeights: false,
    isRequestingAnnualConsumption: false,
    isRequestingMonthlyConsumption: false,
    isCAEWithNoData: false,
    isRequestUpload: false,
    isRequestDownload: false,
    options: {
        ...clone(FACILITY_OPTIONS),
    },
    forwardFormID: null,
};

export const DAYS_OF_WORK = ['working_days', 'trabalha_sab', 'trabalha_dom', 'trabalha_feriado'];

export const INPUTS_TARIFFS_GAS = ['tarifa_media_gas_mwh', 'consumo_anual_gas_mwh', 'tarifa_fixa_gas_mwh'];

export const FACILITY_QUESTIONS_SECTIONS = {
    CLIENT: 1,
    FACILITY: 2,
    ELECTRICITY: 3,
    GAS: 4,
    OTHER: 5,
    CURVE_LOAD: 7,
    SCHEDULES: 6,
};

export const FACILITY_QUESTIONS_SECTIONS_B2C = {
    ADDRESS: 1,
    TECHNICAL_DATA: 2,
    SUPPLY_TARIFFS: 3,
    LOAD_PROFILE: 4,
    CONSUMPTION_PROFILE: 5,
};

export const QUESTIONS_SECTION_NAMES = {
    FACILITY: 'facility',
    ELECTRICITY: 'electric',
    GAS: 'gas',
    OTHER: 'other',
    CURVE_LOAD: 'curveload',
};

export const QUESTIONS_SECTION_NAMES_B2C = {
    ADDRESS: 'address',
    TECHNICAL_DATA: 'technical_data',
    SUPPLY_TARIFFS: 'supply_tariffs',
    LOAD_PROFILE: 'load_profile',
    CONSUMPTION_PROFILE: 'consumption_profile',
};

export const OPTIONS_LOAD_CURVE = {
    INTEGRATION_DATA: 1,
    SIMPLE_ESTIMATION: 2,
    DETAILED_ESTIMATION: 3,
    WORKING_SCHEDULE: 4,
    UPLOAD_CURVE: 5,
};

export const TIPO_CICLO = {
    SEMANAL: 1,
    SEMANAL_OPCIONAL: 2,
    DIARIA: 3,
    FERIADO: 4,
};

export const DEFAULT_CONSUMPTION_VALUE = 0;

export const DEFAULT_WEIGHTS_VALUE = [
    {
        month: 1,
        weight: 0,
    },
    {
        month: 2,
        weight: 0,
    },
    {
        month: 3,
        weight: 0,
    },
    {
        month: 4,
        weight: 0,
    },
    {
        month: 5,
        weight: 0,
    },
    {
        month: 6,
        weight: 0,
    },
    {
        month: 7,
        weight: 0,
    },
    {
        month: 8,
        weight: 0,
    },
    {
        month: 9,
        weight: 0,
    },
    {
        month: 10,
        weight: 0,
    },
    {
        month: 11,
        weight: 0,
    },
    {
        month: 12,
        weight: 0,
    },
];

export const MONTHS = [
    {
        label: 'label.jan',
        value: 'jan',
    },
    {
        label: 'label.feb',
        value: 'feb',
    },
    {
        label: 'label.mar',
        value: 'mar',
    },
    {
        label: 'label.apr',
        value: 'apr',
    },
    {
        label: 'label.may',
        value: 'may',
    },
    {
        label: 'label.jun',
        value: 'jun',
    },
    {
        label: 'label.jul',
        value: 'jul',
    },
    {
        label: 'label.aug',
        value: 'aug',
    },
    {
        label: 'label.sep',
        value: 'sep',
    },
    {
        label: 'label.oct',
        value: 'oct',
    },
    {
        label: 'label.nov',
        value: 'nov',
    },
    {
        label: 'label.dec',
        value: 'dec',
    },
];
//Default map options
export const CHART_CONSUMPTION_DAILY_DEFAULTS = {
    loadingConsumption: true,
    type: 'Line',
    data: {
        labels: [],
        datasets: [
            {
                data: [],
                fill: false,
                hidden: false,
                borderColor: 'rgba(0, 90, 139, 1)',
                backgroundColor: 'rgba(0, 90, 139, 1)',
                borderWidth: 2,
                label: intlMessages('label.consumption'),
                keyword: 'label.consumption',
            },
            {
                data: [],
                fill: 2,
                hidden: false,
                borderColor: 'rgba(0, 90, 139, 0)',
                backgroundColor: '#d4ecff',
                label: intlMessages('label.maximumConsumption'),
                keyword: 'label.maximumConsumption',
            },
            {
                data: [],
                fill: false,
                hidden: false,
                borderColor: 'rgba(0, 90, 139, 0)',
                label: intlMessages('label.minimumConsumption'),
                keyword: 'label.minimumConsumption',
            },
        ],
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 0,
            },
            line: {
                tension: 0,
            },
        },
        legend: {
            position: 'top',
            display: true,
            align: 'end',
            onClick: null,
            labels: {
                padding: 10,
                usePointStyle: true,
                boxWidth: 5,
                filter: function (legendItem) {
                    return legendItem?.datasetIndex === 0 || legendItem?.datasetIndex === 3;
                },
            },
        },
        hover: {
            onHover: function (e) {
                let point = this.getElementAtEvent(e);
                if (point.length) e.target.style.cursor = 'pointer';
                else e.target.style.cursor = 'default';
            },
        },
        scales: {
            yAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.optimalPower_kW'),
                        keyword: 'label.optimalPower_kW',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        suggestedMin: 0,
                        padding: 5,
                        fontSize: 10,
                        maxTicksLimit: 8,
                    },
                },
            ],
            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.hour'),
                        keyword: 'label.hour',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        suggestedMin: 0,
                        padding: 5,
                        fontSize: 10,
                    },
                    scaleFontSize: 5,
                },
            ],
        },
        tooltips: {
            enabled: false,
        },
        plugins: {
            filler: {
                propagate: true,
            },
        },
    },
    plugins: [
        {
            beforeUpdate: (chart) => {
                let isHidden = !!chart.getDatasetMeta(0).hidden;
                chart.getDatasetMeta(1).hidden = isHidden;
                chart.getDatasetMeta(2).hidden = isHidden;
            },
            beforeInit: function (chart) {
                // Adds margin between legend and chart
                chart.legend.afterFit = function () {
                    this.height += 5; // must use `function` and not => because of `this`
                };
            },
        },
    ],
};
//Default map options
export const CHART_CONSUMPTION_MONTHLY_DEFAULTS = {
    loadingConsumption: true,
    type: 'horizontalBar',
    data: {
        datasets: [
            {
                data: [],
                fill: false,
                hidden: false,
                borderColor: 'rgba(0, 90, 139, 1)',
                backgroundColor: 'rgba(0, 90, 139, 1)',
                borderWidth: 2,
                label: intlMessages('label.consumptionMWh'),
                keyword: 'label.consumptionMWh',
            },
        ],
        labels: [],
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            onClick: null,
            display: false,
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem) {
                    return intlMessages('label.consumption') + ': ' + tooltipItem.xLabel + ' MWh';
                },
            },
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        drawOnChartArea: false,
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        override: true,
                        callback: function (value) {
                            return value;
                        },
                    },
                    scaleLabel: {
                        display: true,
                        keyword: 'label.consumptionMWh',
                        labelString: intlMessages('label.consumptionMWh'),
                    },
                },
            ],
        },
    },
};

/* CAE LINKS */
export const AVAILABLE_LINKS_CAE = {
    [getCompanyProfileIds().EDP_ES]:
        'https://www.ine.es/dyngs/INEbase/es/operacion.htm?c=Estadistica_C&cid=1254736177032&menu=ultiDatos&idp=1254735976614',
    [getCompanyProfileIds().EDP_PL]: 'https://www.biznes.gov.pl/pl/tabela-pkd',
    [getCompanyProfileIds().EDP_IT]:
        'https://www.agenziaentrate.gov.it/portale/web/guest/strumenti/codici-attivita-e-tributo/codici-attivita-ateco',
    [getCompanyProfileIds().EDP_BR]:
        'https://cnae.ibge.gov.br/?option=com_cnae&view=estrutura&Itemid=6160&tipo=cnae&versao_classe=7.0.0&versao_subclasse=10.1.0',
    [getCompanyProfileIds().ESB]: 'https://www.cro.ie/Portals/0/Notices/Nace%20code%202%20%20Sept%202019.pdf',
    [getCompanyProfileIds().NRG]: 'https://www.cro.ie/Portals/0/Notices/Nace%20code%202%20%20Sept%202019.pdf',
    [getCompanyProfileIds().SUNSEAP]: 'https://www.singstat.gov.sg/standards/standards-and-classifications/ssic',
    [getCompanyProfileIds().ROMANDE]: 'https://business-monitor.ch/fr',
};

export const EQUIPMENTS_NAMES = {
    //general
    FRIDGE: 'fridge',
    WASHING_MACHINE: 'washing_machine',
    DISHWASHER_MACHINE: 'dishwasher_machine',
    TUMBLE_DRYER: 'tumble_dryer',
    ELECTRIC_OVEN: 'electric_oven',
    ELECTRIC_PLATE: 'electric_plate',
    AIR_CONDITIONING: 'air_conditioning',
    ELECTRIC_HEATER: 'electric_heater',
    WATER_HEATER: 'water_heater',
    POOL_PUMP: 'pool_pump',
    ELECTRIC_CAR_CHARGER: 'electric_car_charger',
    //Specifc
    FRIDGE_QUANTITY: 'fridge_quantity',
    WASHING_MACHINE_FREQUENCY: 'washing_machine_frequency',
    WASHING_MACHINE_PERIOD: 'washing_machine_period',
    DISHWASHER_MACHINE_FREQUENCY: 'dishwasher_machine_frequency',
    DISHWASHER_MACHINE_PERIOD: 'dishwasher_machine_period',
    TUMBLE_DRYER_FREQUENCY: 'tumble_dryer_frequency',
    TUMBLE_DRYER_PERIOD: 'tumble_dryer_period',
    ELECTRIC_OVEN_FREQUENCY: 'electric_oven_frequency',
    ELECTRIC_OVEN_PERIOD_MEAL: 'electric_oven_period_meal',
    ELECTRIC_PLATE_FREQUENCY: 'electric_plate_frequency',
    ELECTRIC_PLATE_PERIOD_MEAL: 'electric_plate_period_meal',
    AIR_CONDITIONING_FREQUENCY: 'air_conditioning_frequency',
    AIR_CONDITIONING_PERIOD: 'air_conditioning_period',
    AIR_CONDITIONING_SEASON: 'air_conditioning_season',
    ELECTRIC_HEATER_FREQUENCY: 'electric_heater_frequency',
    ELECTRIC_HEATER_PERIOD: 'electric_heater_period',
    WATER_HEATER_PERIOD: 'water_heater_period',
    POOL_PUMP_PERIOD: 'pool_pump_period',
    POOL_PUMP_SEASON: 'pool_pump_season',
    ELECTRIC_CAR_CHARGER_FREQUENCY: 'electric_car_charger_frequency',
    ELECTRIC_CAR_CHARGER_PERIOD: 'electric_car_charger_period',
};

/* questions */
export const QUESTIONS_FACILITY = {
    //facilitySection
    [INPUT_NAMES.CPE]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.CPE,
        label: 'label.cpe',
        placeholder: 'question.placeholder.cpe',
        tooltip: null,
        disabled: true,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 30 }),
        optValidation: { maxLength: 30 },
    },
    [INPUT_NAMES.METER_ID]: {
        name: INPUT_NAMES.METER_ID,
        label: 'label.meter_id',
        placeholder: 'question.placeholder.meter_id',
        tooltip: null,
        disabled: false,
        visible: false,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 30 }),
        optValidation: { maxLength: 30 },
    },
    [INPUT_NAMES.DESCRICAO]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.DESCRICAO,
        label: 'question.label.SocialDescription',
        placeholder: 'question.placeholder.SocialDescription',
        tooltip: null,
        disabled: true,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 256 }),
        optValidation: { maxLength: 256 },
    },
    [INPUT_NAMES.MORADA]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.MORADA,
        label: 'label.address',
        placeholder: 'question.placeholder.address',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 256 }),
        optValidation: { maxLength: 256 },
    },
    [INPUT_NAMES.TIPO_INSTALACAO_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.TIPO_INSTALACAO_ID,
        label: 'question.label.facilityType',
        placeholder: 'question.placeholder.facilityType',
        tooltip: null,
        disabled: false,
        visible: true,
        options: [],
        type: 'select',
        validation: validate(['required']),
    },
    [INPUT_NAMES.NUT]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.NUT,
        label: 'label.nut',
        placeholder: 'placeholder.nut',
        tooltip: null,
        disabled: false,
        visible: true,
        options: [],
        type: 'select',
        validation: validate(['required']),
    },
    [INPUT_NAMES.CLASSE_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.CLASSE_ID,
        label: 'question.label.class_id',
        placeholder: 'question.placeholder.classe_id',
        tooltip: null,
        disabled: false,
        visible: true,
        options: [],
        type: 'select',
        validation: validate(['required']),
    },
    [INPUT_NAMES.MUNICIPIO_DESCRICAO]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.MUNICIPIO_DESCRICAO,
        label: 'label.county',
        placeholder: 'placeholder.countySearch',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        inputType: 'string',
        type: 'autocomplete',
        validation: validate(['required']),
    },
    [INPUT_NAMES.LOCALIDADE]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.LOCALIDADE,
        label: 'label.locality',
        placeholder: 'placeholder.locality',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        inputType: 'string',
        validation: validate(['maxLength'], { maxLength: 64 }),
        optValidation: { maxLength: 64 },
    },
    [INPUT_NAMES.CODIGO_POSTAL]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.CODIGO_POSTAL,
        label: 'label.postalCode',
        placeholder: 'placeholder.postalCode',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 8 }),
        optValidation: { maxLength: 8 },
    },
    [INPUT_NAMES.NIVEL_TENSAO_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.NIVEL_TENSAO_ID,
        label: 'question.label.voltageLevels',
        placeholder: 'question.placeholder.voltageLevels',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'select',
        validation: validate(['required', 'notNumber'], {
            number: EMPTY_NIVEL_TENSAO_ID,
            message: 'yup.message.required',
        }),
    },
    [INPUT_NAMES.TENSAO_CONEXAO_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.TENSAO_CONEXAO_ID,
        label: 'question.label.connectionVoltage',
        placeholder: 'question.placeholder.connectionVoltage',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'select',
        validation: validate(['required'], { message: 'yup.message.required' }),
    },
    [INPUT_NAMES.POTENCIA_CONTRATADA]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.POTENCIA_CONTRATADA,
        label: 'label.subscribedPowerWithUnit',
        placeholder: 'question.placeholder.subscribedPower',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        options: { limits: CONTRACTED_POWER_LIMITS.DEFAULT },
        type: 'input',
        step: 0.01,
        validation: validate(['required', 'number', 'min', 'maxNotIncluded'], CONTRACTED_POWER_LIMITS.DEFAULT),
    },
    [INPUT_NAMES.POTENCIA_CONTRATADA_PONTA]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.POTENCIA_CONTRATADA_PONTA,
        label: 'label.subscribedPeakPower',
        placeholder: 'question.placeholder.subscribedPeakPower',
        tooltip: null,
        disabled: false,
        visible: false,
        inputType: 'number',
        options: { limits: CONTRACTED_POWER_LIMITS.DEFAULT },
        type: 'input',
        step: 0.01,
        validation: validate(['required', 'number', 'min', 'maxNotIncluded'], CONTRACTED_POWER_LIMITS.DEFAULT),
    },
    [INPUT_NAMES.POTENCIA_REQUISITADA]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.POTENCIA_REQUISITADA,
        label: 'label.requestedPower',
        placeholder: 'question.placeholder.requestedPower',
        tooltip: null,
        disabled: false,
        visible: false,
        inputType: 'number',
        options: { limits: CONTRACTED_POWER_LIMITS.DEFAULT },
        type: 'input',
        step: 0.01,
        validation: validate(['required', 'number', 'min', 'maxNotIncluded'], CONTRACTED_POWER_LIMITS.DEFAULT),
    },
    [INPUT_NAMES.NUM_FASES]: {
        section: FACILITY_QUESTIONS_SECTIONS.FACILITY,
        name: INPUT_NAMES.NUM_FASES,
        label: 'label.numberOfPhases',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: false,
        options: {
            [getCompanyProfileIds().EDP_PT]: [
                {
                    value: '1',
                    description: 'label.monophasic',
                },
                {
                    value: '3',
                    description: 'label.threephasic',
                },
            ],
            [getCompanyProfileIds().EDP_BR]: [
                {
                    value: '1',
                    description: 'label.monophasic',
                },
                {
                    value: '2',
                    description: 'label.biphasic',
                },
                {
                    value: '3',
                    description: 'label.threephasic',
                },
            ],
        },
        type: 'radio',
        validation: validate(['required']),
    },
    [INPUT_NAMES.NUMERO]: {
        name: INPUT_NAMES.NUMERO,
        label: 'page.client.label.numero',
        placeholder: 'page.client.placeholder.numero',
        type: 'number',
        defaultValue: '',
        visible: false,
        validation: validate(['maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.COMPLEMENTO]: {
        name: INPUT_NAMES.COMPLEMENTO,
        label: 'label.complement',
        placeholder: 'placeholder.complement',
        type: 'string',
        defaultValue: '',
        visible: false,
        validation: validate(['maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.BAIRRO]: {
        name: INPUT_NAMES.BAIRRO,
        label: 'label.district',
        placeholder: 'placeholder.district',
        type: 'string',
        defaultValue: '',
        visible: false,
        validation: validate(['maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    //electricitySection
    [INPUT_NAMES.SUPPLIED_BY_COMPANY]: {
        section: FACILITY_QUESTIONS_SECTIONS.ELECTRICITY,
        name: INPUT_NAMES.SUPPLIED_BY_COMPANY,
        label: 'question.label.suppliedByCompany',
        placeholder: null,
        tooltip: null,
        disabled: true,
        visible: true,
        inputType: 'text',
        value: 'label.yes',
        options: [
            { value: true, label: 'label.yes' },
            { value: false, label: 'label.no' },
        ],
        type: 'input',
        validation: null,
    },
    [INPUT_NAMES.DISTRIBUIDOR_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS.ELECTRICITY,
        name: INPUT_NAMES.DISTRIBUIDOR_ID,
        label: 'question.label.distributor',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'select',
        validation: validate(['required']),
    },
    [INPUT_NAMES.OPCAO_TARIFARIO_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS.ELECTRICITY,
        name: INPUT_NAMES.OPCAO_TARIFARIO_ID,
        label: 'question.label.tariffOption',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'select',
        validation: validate(['required']),
    },
    [INPUT_NAMES.OPCAO_HORARIO_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS.ELECTRICITY,
        name: INPUT_NAMES.OPCAO_HORARIO_ID,
        label: 'question.label.hourlyOption',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'select',
        validation: validate(['required']),
    },
    [INPUT_NAMES.FONTE_TARIFA_REDES_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS.ELECTRICITY,
        name: INPUT_NAMES.FONTE_TARIFA_REDES_ID,
        label: 'question.label.gridTarifSources',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'select',
        validation: validate(['required']),
    },
    [INPUT_NAMES.POWER_FEE]: {
        section: FACILITY_QUESTIONS_SECTIONS.ELECTRICITY,
        name: INPUT_NAMES.POWER_FEE,
        label: 'question.label.powerFee',
        placeholder: 'question.placeholder.powerFee',
        tooltip: 'question.tooltip.powerFee',
        disabled: true,
        visible: false,
        type: 'input',
        inputType: 'number',
        step: null,
        validation: null,
    },
    [INPUT_NAMES.TARIFA_KW_DIA]: {
        section: FACILITY_QUESTIONS_SECTIONS.ELECTRICITY,
        name: INPUT_NAMES.TARIFA_KW_DIA,
        label: 'question.label.usedPowerTariff',
        placeholder: 'question.placeholder.usedPowerTariff',
        tooltip: 'question.tooltip.usedPowerTariff',
        disabled: true,
        visible: false,
        type: 'input',
        inputType: 'number',
        step: null,
        validation: null,
    },
    [INPUT_NAMES.CONSUMO_ANUAL_MWH]: {
        section: FACILITY_QUESTIONS_SECTIONS.ELECTRICITY,
        name: INPUT_NAMES.CONSUMO_ANUAL_MWH,
        label: 'question.label.annualConsumption',
        placeholder: 'question.placeholder.annualConsumption',
        tooltip: null,
        disabled: false,
        visible: false,
        type: 'input',
        inputType: 'number',
        validation: validate(['required', 'number', 'positive', 'max', 'min'], ANNUAL_CONSUMPTION_LIMITS),
    },
    [INPUT_NAMES.DETAILED_TARIFFS]: {
        section: FACILITY_QUESTIONS_SECTIONS.ELECTRICITY,
        name: INPUT_NAMES.DETAILED_TARIFFS,
        label: null,
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        inputType: 'number',
        step: 0.01,
        validation: null,
    },
    //gasSection
    [INPUT_NAMES.GAS_SUPPLIED_BY_COMPANY]: {
        section: FACILITY_QUESTIONS_SECTIONS.GAS,
        name: INPUT_NAMES.GAS_SUPPLIED_BY_COMPANY,
        label: 'question.label.suppliedByCompany',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: false,
        options: [
            { value: true, label: 'label.yes' },
            { value: false, label: 'label.no' },
        ],
        type: 'input',
        validation: null,
    },
    [INPUT_NAMES.TARIFA_MEDIA_GAS_MWH]: {
        section: FACILITY_QUESTIONS_SECTIONS.GAS,
        name: INPUT_NAMES.TARIFA_MEDIA_GAS_MWH,
        label: 'question.label.avgTariff',
        placeholder: 'question.placeholder.avgTariff',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        type: 'input',
        validation: validate(['required', 'number', 'positive']),
    },
    [INPUT_NAMES.CONSUMO_ANUAL_GAS_MWH]: {
        section: FACILITY_QUESTIONS_SECTIONS.GAS,
        name: INPUT_NAMES.CONSUMO_ANUAL_GAS_MWH,
        label: 'question.label.annualConsumption',
        placeholder: 'question.placeholder.annualConsumption',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        type: 'input',
        validation: validate(['required', 'number', 'positive']),
    },
    [INPUT_NAMES.TARIFA_FIXA_GAS_MWH]: {
        section: FACILITY_QUESTIONS_SECTIONS.GAS,
        name: INPUT_NAMES.TARIFA_FIXA_GAS_MWH,
        label: 'question.label.fixedPrice',
        placeholder: 'question.placeholder.fixedPrice',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'number',
        validation: validate(['required', 'number', 'positive']),
    },
    //otherFacilitySection
    [INPUT_NAMES.DATA_IDADE_RESPOSTA_CLIENTE]: {
        section: FACILITY_QUESTIONS_SECTIONS.OTHER,
        name: INPUT_NAMES.DATA_IDADE_RESPOSTA_CLIENTE,
        label: 'question.label.data_idade_resposta_cliente',
        placeholder: 'question.placeholder.data_idade_resposta_cliente',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'number',
        validation: null,
    },
    [INPUT_NAMES.NUM_TRABALHADORES]: {
        section: FACILITY_QUESTIONS_SECTIONS.OTHER,
        name: INPUT_NAMES.NUM_TRABALHADORES,
        label: 'question.label.num_trabalhadores',
        placeholder: 'question.placeholder.num_trabalhadores',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        type: 'input',
        validation: null,
    },
    [INPUT_NAMES.AREA]: {
        section: FACILITY_QUESTIONS_SECTIONS.OTHER,
        name: INPUT_NAMES.AREA,
        label: 'question.label.area',
        placeholder: 'question.placeholder.area',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        type: 'input',
        validation: null,
    },
    [INPUT_NAMES.AREA_COBERTA]: {
        section: FACILITY_QUESTIONS_SECTIONS.OTHER,
        name: INPUT_NAMES.AREA_COBERTA,
        label: 'question.label.area_coberta',
        placeholder: 'question.placeholder.area_coberta',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        type: 'input',
        validation: null,
    },
    [INPUT_NAMES.AREA_UTIL]: {
        section: FACILITY_QUESTIONS_SECTIONS.OTHER,
        name: INPUT_NAMES.AREA_UTIL,
        label: 'question.label.area_util',
        placeholder: 'question.placeholder.area_util',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'number',
        validation: null,
    },

    //loadSection
    [INPUT_NAMES.OPCAO_CURVA_CARGA_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS.CURVE_LOAD,
        name: INPUT_NAMES.OPCAO_CURVA_CARGA_ID,
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        options: [
            {
                value: OPTIONS_LOAD_CURVE.INTEGRATION_DATA,
                description: 'label.integrationData',
                subtitle: 'label.integrationData.subtitle',
                disabled: false,
                visible: true,
                gAnalyticsEvt: GA_LABEL_FACILITY.INTEGRATION_DATA_OPTION,
            },
            {
                value: OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION,
                description: 'label.simpleEstimation',
                subtitle: 'label.simpleEstimation.subtitle',
                disabled: false,
                visible: true,
                gAnalyticsEvt: GA_LABEL_FACILITY.SIMPLE_ESTIMATION_OPTION,
            },
            {
                value: OPTIONS_LOAD_CURVE.DETAILED_ESTIMATION,
                description: 'label.detailedEstimation',
                subtitle: 'label.detailedEstimation.subtitle',
                disabled: true,
                visible: true,
                gAnalyticsEvt: null,
            },
            {
                value: OPTIONS_LOAD_CURVE.WORKING_SCHEDULE,
                description: 'label.workingSchedule',
                subtitle: 'label.workingSchedule.subtitle',
                disabled: false,
                visible: true,
                gAnalyticsEvt: GA_LABEL_FACILITY.WORKING_SCHEDULE_OPTION,
            },
            {
                value: OPTIONS_LOAD_CURVE.UPLOAD_CURVE,
                description: 'label.uploadLoadCurve',
                subtitle: 'label.uploadLoadCurve.subtitle',
                disabled: false,
                visible: true,
                gAnalyticsEvt: GA_LABEL_FACILITY.UPLOAD_FILE_OPTION,
            },
        ],
        type: 'radio',
        validation: validate(['required']),
    },
    //loadSection - Section Integration Data
    [INPUT_NAMES.HORARIO_TEM_IMPACTO]: {
        section: FACILITY_QUESTIONS_SECTIONS.CURVE_LOAD,
        name: INPUT_NAMES.HORARIO_TEM_IMPACTO,
        label: 'question.label.horario_tem_impacto',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        options: [
            { value: true, label: 'label.yes' },
            { value: false, label: 'label.no' },
        ],
        type: 'radio',
        validation: validate(['required']),
    },
    //loadSection - Section Simple Estimation
    [INPUT_NAMES.ACTIVITY_CODE]: {
        section: FACILITY_QUESTIONS_SECTIONS.CURVE_LOAD,
        name: INPUT_NAMES.ACTIVITY_CODE,
        label: 'label.activityCode',
        placeholder: 'question.placeholder.activity_code',
        tooltip: 'label.extraInformation',
        tooltipLink: '#',
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'string',
        validation: {
            validate: {
                minLength: (v) => v?.length >= CAE_LENGTH_LIMITS.DEFAULT.min || 'page.facility.error.notValidCAE',
                maxLength: (v) => v?.length <= CAE_LENGTH_LIMITS.DEFAULT.max || 'page.facility.error.notValidCAE',
                validCae: (v) => !isInvalidCAE(v) || 'page.facility.error.notValidCAE',
            },
        },
    },
    [INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA]: {
        section: FACILITY_QUESTIONS_SECTIONS.CURVE_LOAD,
        name: INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA,
        label: 'question.label.consumption_weights',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'text',
        value: 'label.yes',
        endAdornment: '',
        options: [
            {
                value: false,
                label: 'page.facility.tem_dados_ponderador_estimativa_simples.false',
                gAnalyticsEvt: null,
            },
            {
                value: true,
                label: 'page.facility.tem_dados_ponderador_estimativa_simples.true',
            },
        ],
        type: 'input',
        validation: null,
    },
    [INPUT_NAMES.TEM_DADOS_PONDERADOR_INTEGRACAO]: {
        section: FACILITY_QUESTIONS_SECTIONS.CURVE_LOAD,
        name: INPUT_NAMES.TEM_DADOS_PONDERADOR_INTEGRACAO,
        label: 'question.label.consumption_weights',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'text',
        value: 'label.yes',
        endAdornment: '',
        options: [
            {
                value: false,
                label: 'page.facility.tem_dados_ponderador_integracao_personalizada.false',
                gAnalyticsEvt: null,
            },
            {
                value: true,
                label: 'page.facility.tem_dados_ponderador_integracao_personalizada.true',
                gAnalyticsEvt: GA_LABEL_FACILITY.CUSTOMIZE,
            },
        ],
        type: 'input',
        validation: null,
    },
    [INPUT_NAMES.CONSUMPTION_WEIGHTS]: {
        section: FACILITY_QUESTIONS_SECTIONS.CURVE_LOAD,
        name: INPUT_NAMES.CONSUMPTION_WEIGHTS,
        label: 'question.label.consumption_weights',
        tooltip: null,
        disabled: false,
        visible: true,
        validation: {},
        options: {
            months: MONTHS,
        },
    },
    //loadSection - Section Detailed Estimation
    //loadSection - Section Working Schedule
    //loadSection - Section Upload Curve
    //operationSection
    [INPUT_NAMES.DAYS_OF_WORK]: {
        section: FACILITY_QUESTIONS_SECTIONS.SCHEDULES,
        name: INPUT_NAMES.DAYS_OF_WORK,
        label: 'question.label.workingDays',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'checkbox',
        options: [
            { value: INPUT_NAMES.WORKING_DAYS, label: 'label.workingDays' },
            { value: INPUT_NAMES.TRABALHA_SAB, label: 'label.dayWeek.sat' },
            { value: INPUT_NAMES.TRABALHA_DOM, label: 'label.dayWeek.sun' },
            { value: INPUT_NAMES.TRABALHA_FERIADO, label: 'label.holidays' },
        ],
        validation: null,
    },
    [INPUT_NAMES.HORARIO_DIAS_SEMANA]: {
        section: FACILITY_QUESTIONS_SECTIONS.SCHEDULES,
        name: INPUT_NAMES.HORARIO_DIAS_SEMANA,
        label: 'question.label.horario_dias_semana',
        placeholder: 'question.placeholder.horario_dias_semana',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'schedule',
        validation: validate(['required']),
    },
    [INPUT_NAMES.HORARIO_SABADO]: {
        section: FACILITY_QUESTIONS_SECTIONS.SCHEDULES,
        name: INPUT_NAMES.HORARIO_SABADO,
        label: 'question.label.horario_sabado',
        placeholder: 'question.placeholder.horario_sabado',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'schedule',
        validation: validate(['required']),
    },
    [INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]: {
        section: FACILITY_QUESTIONS_SECTIONS.SCHEDULES,
        name: INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO,
        label: 'question.label.horario_domingo_e_feriado',
        placeholder: 'question.placeholder.horario_domingo_e_feriado',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'schedule',
        validation: validate(['required']),
    },
};

export const QUESTIONS_FACILITY_B2C = {
    //addressSection
    [INPUT_NAMES.CPE]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.ADDRESS,
        name: INPUT_NAMES.CPE,
        label: 'label.cpe',
        placeholder: 'question.placeholder.cpe',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['maxLength'], { maxLength: 30 }),
        optValidation: { maxLength: 30 },
    },
    [INPUT_NAMES.MORADA]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.ADDRESS,
        name: INPUT_NAMES.MORADA,
        label: 'label.address',
        placeholder: 'question.placeholder.address',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 256 }),
        optValidation: { maxLength: 256 },
    },
    [INPUT_NAMES.NUMERO]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.ADDRESS,
        name: INPUT_NAMES.NUMERO,
        label: 'page.client.label.numero',
        placeholder: 'page.client.placeholder.numero',
        type: 'number',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.COMPLEMENTO]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.ADDRESS,
        name: INPUT_NAMES.COMPLEMENTO,
        label: 'label.complement',
        placeholder: 'placeholder.complement',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.CODIGO_POSTAL]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.ADDRESS,
        name: INPUT_NAMES.CODIGO_POSTAL,
        label: 'label.postalCode',
        placeholder: 'placeholder.postalCode',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 8 }),
        optValidation: { maxLength: 8 },
    },
    [INPUT_NAMES.BAIRRO]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.ADDRESS,
        name: INPUT_NAMES.BAIRRO,
        label: 'label.district',
        placeholder: 'placeholder.district',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['maxLength'], { maxLength: 500 }),
        optValidation: { maxLength: 500 },
    },
    [INPUT_NAMES.LOCALIDADE]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.ADDRESS,
        name: INPUT_NAMES.LOCALIDADE,
        label: 'label.locality',
        placeholder: 'placeholder.locality',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        inputType: 'string',
        validation: validate(['maxLength'], { maxLength: 64 }),
        optValidation: { maxLength: 64 },
    },
    [INPUT_NAMES.NUT]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.FACILITY,
        name: INPUT_NAMES.NUT,
        label: 'label.nut',
        placeholder: 'placeholder.nut',
        tooltip: null,
        disabled: false,
        visible: true,
        options: [],
        type: 'select',
        validation: validate(['required']),
    },
    [INPUT_NAMES.MUNICIPIO_DESCRICAO]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.ADDRESS,
        name: INPUT_NAMES.MUNICIPIO_DESCRICAO,
        label: 'label.county',
        placeholder: 'placeholder.countySearch',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        inputType: 'string',
        type: 'autocomplete',
        validation: validate(['required']),
    },
    //technicalDataSection
    [INPUT_NAMES.POTENCIA_CONTRATADA]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.TECHNICAL_DATA,
        name: INPUT_NAMES.POTENCIA_CONTRATADA,
        label: 'label.contractedPower',
        placeholder: 'question.placeholder.subscribedPower',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        options: { limits: CONTRACTED_POWER_LIMITS.DEFAULT },
        type: 'input',
        step: 0.01,
        validation: validate(['required', 'number', 'min', 'maxNotIncluded'], CONTRACTED_POWER_LIMITS.DEFAULT),
    },
    //supplyTariffsSection
    [INPUT_NAMES.TARIFA_PERIODO_1]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.SUPPLY_TARIFFS,
        name: INPUT_NAMES.TARIFA_PERIODO_1,
        label: null,
        placeholder: 'label.value',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        inputType: 'number',
        step: 0.01,
        validation: validate(['required', 'number', 'min'], { min: 0 }),
    },
    [INPUT_NAMES.TARIFA_PERIODO_2]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.SUPPLY_TARIFFS,
        name: INPUT_NAMES.TARIFA_PERIODO_2,
        label: null,
        placeholder: 'label.value',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        inputType: 'number',
        step: 0.01,
        validation: validate(['required', 'number', 'min'], { min: 0 }),
    },
    //loadProfileSection
    // TODO: missing inputs
    [INPUT_NAMES.LOAD_PROFILE]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.LOAD_PROFILE,
        name: INPUT_NAMES.LOAD_PROFILE,
        label: null,
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        options: null,
        answers: [
            { label: 'label.simpleEstimation', value: 1 },
            { label: 'label.customizedCurves', value: 2 },
        ],
        type: 'input',
        step: 0.01,
        validation: validate(['required']),
    },
    [INPUT_NAMES.WORK_DAYS_OCCUPIED]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.LOAD_PROFILE,
        name: INPUT_NAMES.WORK_DAYS_OCCUPIED,
        label: `label.${INPUT_NAMES.WORK_DAYS_OCCUPIED}`,
        placeholder: 'question.placeholder.subscribedPower',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        options: null,
        answers: [
            { label: 'label.yes', value: true },
            { label: 'label.no', value: false },
        ],
        type: 'input',
        step: 0.01,
        validation: validate(['required']),
    },
    [INPUT_NAMES.WORK_DAYS_OCCUPATION_PERIOD_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.LOAD_PROFILE,
        name: INPUT_NAMES.WORK_DAYS_OCCUPATION_PERIOD_ID,
        label: `label.${INPUT_NAMES.WORK_DAYS_OCCUPATION_PERIOD_ID}`,
        tooltip: `page.spv.question.${INPUT_NAMES.WORK_DAYS_OCCUPATION_PERIOD_ID}.tooltip`,
        disabled: false,
        answers: null,
        visible: false,
        validation: validate(['required']),
    },
    [INPUT_NAMES.WORK_DAYS_FIRST_ARRIVALS_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.LOAD_PROFILE,
        name: INPUT_NAMES.WORK_DAYS_FIRST_ARRIVALS_ID,
        label: `label.${INPUT_NAMES.WORK_DAYS_FIRST_ARRIVALS_ID}`,
        tooltip: `page.spv.question.${INPUT_NAMES.WORK_DAYS_FIRST_ARRIVALS_ID}.tooltip`,
        disabled: false,
        visible: true,
        validation: validate(['required']),
    },
    /* [INPUT_NAMES.WEEKENDS_OCCUPIED]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.LOAD_PROFILE,
        name: INPUT_NAMES.WEEKENDS_OCCUPIED,
        label: `label.${INPUT_NAMES.WEEKENDS_OCCUPIED}`,
        placeholder: 'question.placeholder.subscribedPower',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        options: null,
        answers: [{ label: "label.yes", value: true }, { label: "label.no", value: false }],
        type: 'input',
        step: 0.01,
        validation: validate(['required']),
    }, */
    [INPUT_NAMES.WEEKEND_OCCUPATION_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.LOAD_PROFILE,
        name: INPUT_NAMES.WEEKEND_OCCUPATION_ID,
        label: `label.${INPUT_NAMES.WEEKENDS_OCCUPIED}`,
        disabled: false,
        answers: null,
        visible: true,
        validation: validate(['required']),
    },
    /* [INPUT_NAMES.WEEKENDS_FIRST_ARRIVALS_ID]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.LOAD_PROFILE,
        name: INPUT_NAMES.WEEKENDS_FIRST_ARRIVALS_ID,
        label: `label.${INPUT_NAMES.WORK_DAYS_FIRST_ARRIVALS_ID}`,
        tooltip: `page.spv.question.${INPUT_NAMES.WEEKENDS_FIRST_ARRIVALS_ID}.tooltip`,
        disabled: false,
        visible: true,
        validation: validate(['required']),
    }, */
    [INPUT_NAMES.CONSUMO_ANUAL]: {
        section: FACILITY_QUESTIONS_SECTIONS_B2C.LOAD_PROFILE,
        name: INPUT_NAMES.CONSUMO_ANUAL,
        label: 'question.label.annualConsumption',
        placeholder: 'question.placeholder.annualConsumption',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'number',
        validation: validate(['required', 'number', 'positive', 'max', 'min'], ANNUAL_CONSUMPTION_LIMITS),
    },
    //consumptionProfileSection
    [INPUT_NAMES.CONSUMPTION_WEIGHTS]: {
        section: FACILITY_QUESTIONS_SECTIONS.CURVE_LOAD,
        name: INPUT_NAMES.CONSUMPTION_WEIGHTS,
        label: 'question.label.consumption_weights',
        tooltip: null,
        disabled: false,
        visible: true,
        validation: {},
        options: {
            months: MONTHS,
        },
    },
    //loadSection - Section Detailed Estimation
    //loadSection - Section Working Schedule
    //loadSection - Section Upload Curve
    //operationSection
    [INPUT_NAMES.DAYS_OF_WORK]: {
        section: FACILITY_QUESTIONS_SECTIONS.SCHEDULES,
        name: INPUT_NAMES.DAYS_OF_WORK,
        label: 'question.label.workingDays',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'checkbox',
        options: [
            { value: INPUT_NAMES.WORKING_DAYS, label: 'label.workingDays' },
            { value: INPUT_NAMES.TRABALHA_SAB, label: 'label.dayWeek.sat' },
            { value: INPUT_NAMES.TRABALHA_DOM, label: 'label.dayWeek.sun' },
            { value: INPUT_NAMES.TRABALHA_FERIADO, label: 'label.holidays' },
        ],
        validation: null,
    },
    [INPUT_NAMES.HORARIO_DIAS_SEMANA]: {
        section: FACILITY_QUESTIONS_SECTIONS.SCHEDULES,
        name: INPUT_NAMES.HORARIO_DIAS_SEMANA,
        label: 'question.label.horario_dias_semana',
        placeholder: 'question.placeholder.horario_dias_semana',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'schedule',
        validation: validate(['required']),
    },
    [INPUT_NAMES.HORARIO_SABADO]: {
        section: FACILITY_QUESTIONS_SECTIONS.SCHEDULES,
        name: INPUT_NAMES.HORARIO_SABADO,
        label: 'question.label.horario_sabado',
        placeholder: 'question.placeholder.horario_sabado',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'schedule',
        validation: validate(['required']),
    },
    [INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]: {
        section: FACILITY_QUESTIONS_SECTIONS.SCHEDULES,
        name: INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO,
        label: 'question.label.horario_domingo_e_feriado',
        placeholder: 'question.placeholder.horario_domingo_e_feriado',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'schedule',
        validation: validate(['required']),
    },
};

/**
 * getTemplateCurveLoadURL
 *
 * @param {*} type
 */
export const getTemplateCurveLoadURL = (type) => {
    switch (type) {
        case URL_TEMPLATE_15MIN_CURVE_LOAD:
            return getBaseURL() + process.env.REACT_APP_API_URL_TEMPLATE_15MIN_CURVE_LOAD;
        case URL_TEMPLATE_30MIN_CURVE_LOAD:
            return getBaseURL() + process.env.REACT_APP_API_URL_TEMPLATE_30MIN_CURVE_LOAD;
        case URL_TEMPLATE_HOURLY_CURVE_LOAD:
            return getBaseURL() + process.env.REACT_APP_API_URL_TEMPLATE_HOURLY_CURVE_LOAD;
        case URL_TEMPLATE_30MIN_HH_MATRIX_CURVE_LOAD:
            return getBaseURL() + process.env.REACT_APP_API_URL_TEMPLATE_30MIN_HH_MATRIX_CURVE_LOAD;
        case URL_TEMPLATE_15MIN_KW_CURVE_LOAD:
            return getBaseURL() + process.env.REACT_APP_API_URL_TEMPLATE_15MIN_KW_CURVE_LOAD;
        default:
            return getBaseURL();
    }
};

//TEMPLATES
export const URL_TEMPLATE_15MIN_CURVE_LOAD = 'template_15min_curve_load';
export const URL_TEMPLATE_30MIN_CURVE_LOAD = 'template_30min_curve_load';
export const URL_TEMPLATE_30MIN_HH_MATRIX_CURVE_LOAD = '30min_HH_matrix';
export const URL_TEMPLATE_HOURLY_CURVE_LOAD = 'template_hourly_curve_load';
export const URL_TEMPLATE_15MIN_KW_CURVE_LOAD = 'template_15min_kw';

/**
 * subscribedPowerUnit
 *
 * @param {*} companyProfileId
 * @returns
 */
export function subscribedPowerUnit(companyProfileId) {
    let unit = 'kVA';

    switch (companyProfileId) {
        case getCompanyProfileIds().EDP_IT:
        case getCompanyProfileIds().EDP_PL:
        case getCompanyProfileIds().EDP_BR:
        case getCompanyProfileIds().ESB:
        case getCompanyProfileIds().NRG:
            unit = 'kW';
            break;
        default:
            break;
    }
    return { unitByCompanyID: unit };
}

export const PRODUCTS_WITH_TEASER_GENERATION = {
    [getCompanyProfileIds()?.EDP_PT]: {
        [PRODUCT_IDS.SPV]: {
            busModelID: 3,
        },
    },
    [getCompanyProfileIds().EDP_ES]: {
        [PRODUCT_IDS.SPV]: {
            busModelID: 59,
        },
    },
};
