import { TBMKpi } from 'interfaces/businessModels';
import IntlMessages from 'components/util/IntlMessages';
import FormatNumber from 'components/util/FormatNumber';

const KPI = ({ label, value, unit, numberOfDecimalPlaces }: TBMKpi) => {
    return (
        <div className="business-models-card-section">
            <label className="m-0">
                <IntlMessages id={label} />
            </label>
            <span className="return">
                {/* @ts-ignore */}
                {<FormatNumber unit={unit} number={value} numberOfDecimalPlaces={numberOfDecimalPlaces} />}
            </span>
        </div>
    );
};

export default KPI;
