import { useContext, useState } from 'react';

import { postSimulation } from 'api/product';
import { getSupportDocs, headSupportDocs } from 'api/proposal';
import { OK } from 'constants/statusCodeHttp';
import { notify } from 'services/@efz/notify';
import IntlMessages from 'components/util/IntlMessages';
import { useHistory } from 'react-router';
import { isDefined } from 'services/util/auxiliaryUtils';
import { PROPOSAL_STATUS_IDS } from 'constants/efficiencyMeasures';
import { postSendToCRM } from 'api/processes';
import { SolutionsContext } from 'contexts/solutions/solutionsContext';
import { STATUS_CRM } from 'constants/proposal';

const useQuickTeaser = (setIsQuickTeaserDialogOpen) => {
    const { solutions } = useContext(SolutionsContext);

    const [isRequesting, setIsRequesting] = useState(true);
    const [isRequestingEM, setIsRequestingEM] = useState(true);
    const [isRequestingSendCRM, setIsRequestingSendCRM] = useState(false);
    const [showhasProposalWarning, setShowHasProposalWarning] = useState(false);
    const [payloadQT, setPayloadQT] = useState(null);
    const [sentToCRM, setSentToCRM] = useState(null);
    const [proposalID, setProposalID] = useState(null);

    const history = useHistory();

    //Function to return the date in the correct formart
    //This functions is used to construct the file name
    const parseDate = (dt) => {
        let day = dt.getDate();
        let month = dt.getMonth() + 1;
        let year = dt.getFullYear();

        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        return year + '-' + month + '-' + day;
    };

    const generateQuickTeaser = async (payload, checkActiveProposals) => {
        let facilityID = isDefined(payload) ? payload.facilityID : payloadQT.facilityID;
        let busModelID = isDefined(payload) ? payload.busModelID : payloadQT.busModelID;
        let hasProposal;

        if (checkActiveProposals) {
            setShowHasProposalWarning(false);
            setIsRequestingEM(true);
            // const EMResponse = await getSolutions(facilityID);

            const efficiencyMeasure = solutions[0];

            hasProposal =
                efficiencyMeasure?.sims_count?.length > 0 &&
                isDefined(efficiencyMeasure?.sims_count?.find((item) => item.id !== PROPOSAL_STATUS_IDS.CANCELED && item.sims_count > 0));
            setPayloadQT(payload);

            setIsRequestingEM(false);
        }

        if (hasProposal) {
            setShowHasProposalWarning(hasProposal);
        }

        if (!checkActiveProposals || !hasProposal) {
            setIsRequesting(true);
            setShowHasProposalWarning(false);
            let simulationResponse = await postSimulation({
                payload: {
                    tipo_modelo_negocio_id: busModelID,
                    facility: {
                        id: facilityID,
                    },
                },
            });

            if (simulationResponse?.status !== OK) {
                notify(<IntlMessages id="page.error.403.unexpected" />, 'error');
                setIsQuickTeaserDialogOpen(false);
                setIsRequesting(false);
                return;
            }

            let proposalID = simulationResponse?.data?.data?.sim_id;
            setProposalID(proposalID);
            let date = new Date(Date.now());
            let fileName = parseDate(date);
            fileName += '_support_docs_' + proposalID + '.zip';

            let headDocsResponse;
            let supportDocsResponse;

            let oldPage = history.location.pathname;

            const callGetSupportDocs = async () => {
                if (oldPage !== history.location.pathname) return;

                headDocsResponse = await headSupportDocs(proposalID);

                if (headDocsResponse?.status !== OK) {
                    setTimeout(callGetSupportDocs, 3000);
                } else {
                    supportDocsResponse = await getSupportDocs({
                        id: proposalID,
                        file_name: fileName,
                    });

                    if (supportDocsResponse?.status === OK && isDefined(supportDocsResponse?.data)) {
                        let url = URL.createObjectURL(new Blob([supportDocsResponse?.data]));
                        let a = document.createElement('a');

                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();

                        sendToCRM(proposalID);
                    }
                }
            };

            setTimeout(callGetSupportDocs, 3000);
        }
    };

    const resetQTData = () => {
        setPayloadQT(null);
        setIsRequesting(true);
        setIsRequestingEM(true);
        setShowHasProposalWarning(false);
        setSentToCRM(null);
        setIsRequestingSendCRM(false);
        setProposalID(null);
    };

    const sendToCRM = async (processId) => {
        setIsRequesting(false);
        setIsRequestingSendCRM(true);
        const dataFetched = await postSendToCRM({ processId });

        if (dataFetched?.status === OK && dataFetched?.data?.message === STATUS_CRM.SUBMITTED) {
            setSentToCRM(dataFetched?.data?.data);

            setIsRequestingSendCRM(false);
        } else {
            setSentToCRM('error');
            setIsRequesting(false);
            setIsRequestingSendCRM(false);
            notify(<IntlMessages id={`${dataFetched?.data?.message ?? 'page.error.403.unexpected'}`} />, 'error');
        }
    };

    return {
        isRequestingQuickTeaser: isRequesting,
        isRequestingSendCRM,
        proposalID,
        isRequestingEM,
        generateQuickTeaser,
        showhasProposalWarning,
        resetQTData,
        sentToCRM,
    };
};

export default useQuickTeaser;
