import { lazy } from 'react';
import lazyLoader from 'services/lazyLoader';

const UserPage = lazy(() => lazyLoader(() => import('pages/Restricted/User/Edit/EditUser')));

/**
 * @context Route to Users
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EditUser = () => <UserPage />;

export default EditUser;
