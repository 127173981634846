import IntlMessages from 'components/util/IntlMessages';
import { BmDetailedActions } from 'constants/businessModelsPro/index';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { Button } from '@save2compete/efz-design-system';

const OfferSummaryActionsPaPDetailed = () => {
    const {
        bmState,
        bmState: { loadings },
        setBMEventHandler,
    } = useContext(OfferEditionContext);

    const {
        formState: { errors },
    } = useFormContext();

    return (
        <div className="bm-detailed-offer-summary-actions">
            <Button
                className="btn-upt-values"
                variant="secondary"
                onClick={() => setBMEventHandler(DetailedOfferEditionEvents.SET_DETAILED_ACTION, BmDetailedActions.SIMPLE_SIMULATION)}
                disabled={
                    loadings.general ||
                    isFieldDefined(errors['extraCost']) ||
                    bmState?.sliderReady ||
                    isFieldDefined(errors['annualEvoFee'])
                }
                type="submit"
                loading={loadings.simpleSimulation}
                dataTestId="update-values"
            >
                <IntlMessages id="label.updateValues" />
            </Button>
        </div>
    );
};

export default OfferSummaryActionsPaPDetailed;
