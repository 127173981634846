import * as yup from 'yup';
import { setLocale } from 'yup';
import { intlMessages } from 'services/util/auxiliaryUtils';

/**
 *
 * see docs : https://github.com/jquense/yup#using-a-custom-locale-dictionary
 */
setLocale({
    mixed: {
        required: intlMessages('yup.message.required'),
        notType: function notType(_ref) {
            switch (_ref.type) {
                case 'number':
                case 'string':
                    return `${intlMessages('yup.message.notType.generic')} ${intlMessages('yup.type.' + _ref.type)}`;
                case 'boolean':
                    return `${intlMessages('yup.message.required')}`;
                case 'date':
                    return `${intlMessages('yup.message.notType.date')}`;
                default:
                    return `${intlMessages('yup.message.notType.default')} ({${intlMessages('yup.type.' + _ref.type)}})`;
            }
        },
    },
    number: {
        min: `${intlMessages('yup.message.number.min')} $\{min}`,
        max: `${intlMessages('yup.message.number.max')} $\{max}`,
        positive: intlMessages('yup.message.number.positive'),
    },
    string: {
        email: intlMessages('yup.message.email'),
        min: (_ref) => {
            return `${intlMessages('yup.message.string.min')} ${_ref.min} ${intlMessages('yup.message.string.min.characters')}`;
        },
    },
});

export const YUP_CUSTOM = yup;
