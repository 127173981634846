import React from 'react';
import IntlMessages from 'components/util/IntlMessages';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as CheckIcon } from 'assets/@efz/icons/success.svg';
import { ReactComponent as WarningIcon } from 'assets/@efz/icons/warning.svg';
import { ReactComponent as ErrorIcon } from 'assets/@efz/icons/error.svg';
import { ReactComponent as InfoIcon } from 'assets/@efz/icons/info.svg';

type Ttype = (
    message: React.ReactChild,
    type: 'success' | 'error' | 'warning' | 'info' | 'update',
    title?: React.ReactChild,
    options?: {
        notifyId?: string;
        autoClose?: number;
    }
) => void;
const DEFAULT_AUTOCLOSE = 2500;

const content = (type: 'success' | 'error' | 'warning' | 'info' | 'update', message: React.ReactChild, title?: React.ReactChild) => (
    <div className="">
        <div className="toast-title">{title ? title : <IntlMessages id={`label.${type}`} />}</div>
        <span className="toast-content">{message}</span>
    </div>
);

export const notify: Ttype = (message, type, title = undefined, options = { notifyId: undefined, autoClose: DEFAULT_AUTOCLOSE }) => {
    switch (type) {
        case 'success':
            toast.success(content(type, message, title), {
                icon: <CheckIcon height={20} />,
                toastId: options.notifyId,
                autoClose: options.autoClose,
            });
            break;
        /* INFO */
        case 'warning':
            toast.warn(content(type, message, title), {
                icon: <WarningIcon height={20} />,
                toastId: options.notifyId,
                autoClose: options.autoClose,
            });
            break;
        case 'error':
            toast.error(content(type, message, title), {
                icon: <ErrorIcon height={20} />,
                toastId: options.notifyId,
                autoClose: options.autoClose,
            });
            break;
        case 'info':
            toast.info(content(type, message, title), {
                icon: <InfoIcon height={20} />,
                toastId: options.notifyId,
                autoClose: options.autoClose,
            });
            break;
        case 'update':
            toast.info(content('info', 'The application was successfully updated', title), {
                icon: <InfoIcon height={20} />,
                toastId: options.notifyId,
                autoClose: options.autoClose,
            });
            break;
        default:
            toast.info(content('info', message, title), {
                icon: <InfoIcon height={20} />,
                toastId: options.notifyId,
                autoClose: options.autoClose,
            });
    }
};

export const dismissNotifiy: () => void = () => toast.dismiss();
