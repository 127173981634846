import { useContext } from 'react';

// Components
import ProjectSummaryServiceV2 from 'components/BusinessModels/ProjectSummary/ProjectSummaryServiceV2';
import ProjectSummaryPap from 'components/BusinessModels/ProjectSummary/ProjectSummaryPaP';
import ProjectSummaryUpFront from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront';
import ProjectSummaryServicePPa from 'components/BusinessModels/ProjectSummary/ProjectSummaryServicePPa';
import ProjectSummaryESolar from 'components/BusinessModels/ProjectSummary/ProjectSummaryESolar';
import ProjectSummaryLEC from 'components/BusinessModels/ProjectSummary/ProjectSummaryLEC';
import ProjectSummaryPPa from './ProjectSummaryPPa';
import ProjectSummaryInstallments from './ProjectSummaryInstallments';
// Constants
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TBMs } from 'interfaces/businessModels/reducer';

const ProjectSummary = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TBMs>;

    const projectSummary = () => {
        switch (bmSelected.paymentModelID) {
            case PAYMENT_MODELS_IDS.UP_FRONT:
                return <ProjectSummaryUpFront />;
            case PAYMENT_MODELS_IDS.INSTALLMENTS:
                return <ProjectSummaryInstallments />;
            case PAYMENT_MODELS_IDS.PAY_AS_PRODUCED:
                return <ProjectSummaryPap />;
            case PAYMENT_MODELS_IDS.LEC:
                return <ProjectSummaryLEC />;
            case PAYMENT_MODELS_IDS.EASY_SOLAR:
                return <ProjectSummaryESolar />;
            // case PAYMENT_MODELS_IDS.SERVICE:
            case PAYMENT_MODELS_IDS.SERVICE_SECOND_VERSION:
                return <ProjectSummaryServiceV2 />;
            case PAYMENT_MODELS_IDS.SERVICE_PPA:
                return <ProjectSummaryServicePPa />;
            case PAYMENT_MODELS_IDS.PPA:
                return <ProjectSummaryPPa />;
            default:
                <div>{`Something went wrong :(`}</div>;
        }
    };

    return <div className="bm-project-summary">{projectSummary()}</div>;
};

export default ProjectSummary;
