import IntlMessages from 'components/util/IntlMessages';

const ProjectSummarySubTitle = ({ label }: { label: string }) => {
    return (
        <>
            <span className="offer-details-subtitle">
                <IntlMessages id={label} />
            </span>
        </>
    );
};

export default ProjectSummarySubTitle;
