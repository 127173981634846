import { defineMessages } from 'react-intl';
import enMessages from '../locales/en_SG.json';

const enSGLang = {
    messages: {
        ...enMessages,
    },
    locale: 'en-SG',
    data: defineMessages,
};
export default enSGLang;
