// * DEFAULT OBJECTS * \\
export const INITIAL_PRODUCT = {
    info: {
        id: null,
        tp_tag: null,
        estimativa_investimento: null,
        estimativa_poupanca: null,
        tem_potencial: null,
        motivo_sem_potencial: null,
        module: null,
        subProduct: null,
        sub_product_id: null,
    },
    inputs: null,
    offerEdition: null,
    proposal: null,
    tipo_modulo: null,
    loading: true,
    subProducts: null,
    modules: null,
    productInputs: null,
    isSPVPro: false,
    options: {
        spv: {
            // FIXME: falta fazer uma actions para limpar este obj apos a criação da proposta.
            stepMount: 0,
            consumptions: null,
            pvAreaReductionCoefficients: null,
            solarSensibilityAnalysis: null,
            regionsGD: null,
            hasProductInputs: false,
            regionRadiations: null,
            simulation: null,
            loadingSPVData: false,
            loadingSavingInputs: false, // FIXME: mudar o nome para algo do genero isRequestCleanProductInputs
            isRequestingSPVConsumption: false,
            isRequestingSPVSensibility: false,
            isRequestingSPVProduction: false,
            responseTags: [],
        },
        cfp: {
            batteryPowers: null,
            base: null,
        },
        ce: {
            facilityStats: null,
            facilityTypes: null,
            facilityGroups: null,
        },
        me: {
            isMounting: true,
        },
        gns: {
            isMounting: true,
        },
        utils: {
            workSchedules: null,
        },
        productions: null,
    },
    isRequesting: false,
    hasProductInputs: false,
};

// * ACTION TYPES * \\
export const SET_PRODUCT_MODULES = 'SET_PRODUCT_MODULES';
export const SET_PRODUCT_INPUTS = 'SET_PRODUCT_INPUTS';
export const SET_PRODUCT_INFO = 'SET_PRODUCT_INFO';
export const SET_MODULES_AND_SUB_PRODUCTS = 'SET_MODULES_AND_SUB_PRODUCTS';
export const SET_PRODUCT_LOADING = 'SET_PRODUCT_LOADING';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const REQUEST_GET_PRODUCT_INPUTS = 'REQUEST_GET_PRODUCT_INPUTS';
export const REQUEST_GET_PRODUCT_INPUTS_SUCCESS = 'REQUEST_GET_PRODUCT_INPUTS_SUCCESS';
export const REQUEST_GET_PRODUCT_INPUTS_FAIL = 'REQUEST_GET_PRODUCT_INPUTS_FAIL';
export const RESET_PRODUCT_BUILD = 'RESET_PRODUCT_BUILD';
export const REQUESTS_PUT_FACILITY_PRODUCT_INPUTS = 'REQUESTS_PUT_FACILITY_PRODUCT_INPUTS';
export const REQUESTS_PUT_FACILITY_PRODUCT_INPUTS_SUCCESS = 'REQUESTS_PUT_FACILITY_PRODUCT_INPUTS_SUCCESS';
export const REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA = 'REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA';
export const REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_SUCCESS = 'REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_SUCCESS';
export const REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_FAIL = 'REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_FAIL';
export const RESET_PRODUCT_OPTIONS = 'RESET_PRODUCT_OPTIONS';
export const SET_INCREASE_POWER_OPTIONS = 'SET_INCREASE_POWER_OPTIONS';
export const RESET_PRODUCT_INPUTS = 'RESET_PRODUCT_INPUTS';
export const SET_PRODUCT_BY_TOKEN = 'SET_PRODUCT_BY_TOKEN';
export const SET_IS_SPV_PRO = 'SET_IS_SPV_PRO';
// * CONSTANTS * \\
