/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description API requests to the context of Simulation
 *
 * @version 20190724
 * @since 20190724 Initial release
 *
 */

// import axios from 'axios';
import axios from 'services/@efz/axios';
import { URL_INPUTS_UPLOADS, URL_SIMULATIONS, getEndpoint } from 'constants/endpoints';

import { PROCESSES_LOADING_ACTIONS } from 'constants/processes';
import { getMessageNetworkError } from 'services/statusCodeHttp';

/**
 * getProcesses
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getProcesses = (params, abort) => {
    if (!abort) {
        abort && abort.abort();
        abort = new AbortController();
    }

    return axios
        .get(getEndpoint(URL_SIMULATIONS), {
            params: params,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getTotalSimsList = (params, abort) => {
    if (!abort) {
        abort && abort.abort();
        abort = new AbortController();
    }
    // delete limit and page
    delete params.page;
    delete params.limit;

    return axios
        .get(`${getEndpoint(URL_SIMULATIONS)}/filtered-counters`, {
            params: params,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * getFileFromProcess
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getFileFromProcess = (payload) => {
    let url = getEndpoint(URL_SIMULATIONS) + '/' + payload.processId;

    if (payload.type === PROCESSES_LOADING_ACTIONS.DOWNLOAD_CSV) {
        url += '/pvdata';
    } else if (payload.type === PROCESSES_LOADING_ACTIONS.DOWNLOAD_PROPOSAL) {
        url += '/support-docs';
    } else if (payload.type === PROCESSES_LOADING_ACTIONS.DOWNLOAD_CONTRACT) {
        url += '/signed-contract';
    } else if (payload.type === PROCESSES_LOADING_ACTIONS.DOWNLOAD_IMAGES) {
        url += '/' + URL_INPUTS_UPLOADS;
    }

    return axios
        .get(url, {
            responseType: 'arraybuffer',
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * postUploadProcessDocs
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postUploadProcessDocs = (payload) => {
    let url = getEndpoint(URL_SIMULATIONS) + '/' + payload.processId;

    if (payload.type === PROCESSES_LOADING_ACTIONS.UPLOAD_PROPOSAL) {
        url += '/attachment-docs';
    } else if (payload.type === PROCESSES_LOADING_ACTIONS.UPLOAD_CONTRACT) {
        url += '/signed-contract';
    }

    const { has_file, ...rest } = payload;

    return axios
        .post(url, rest.formData, { params: has_file && { has_file } })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * getEditingProcessDetails
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getEditingProcessDetails = (payload) => {
    return axios
        .get(getEndpoint(URL_SIMULATIONS) + '/' + payload + '/requests')
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * postSendToCRM
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postSendToCRM = (payload) => {
    return axios
        .post(getEndpoint(URL_SIMULATIONS) + '/' + payload.processId + '/support-docs/crm', null)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * putChangeOwner
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const putChangeOwner = (payload) => {
    return axios
        .put(getEndpoint(URL_SIMULATIONS) + '/' + payload.processId, {
            owner_id: payload.owner_id,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * putChangeDescription
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const putProposalDescription = (proposalID, payload) => {
    return axios
        .put(getEndpoint(URL_SIMULATIONS) + '/' + proposalID, {
            descricao: payload,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//* PROCESSES STATUS *\\

/**
 * getAllSimsStatus
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getAllSimsStatus = (params) => {
    return axios
        .get(getEndpoint(URL_SIMULATIONS) + '/status', { params: params })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * getSimStatus
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getSimStatus = (payload) => {
    return axios
        .get(getEndpoint(URL_SIMULATIONS) + '/' + payload + '/status')
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * postUpdateSimStatus
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postUpdateSimStatus = (payload) => {
    return axios
        .post(getEndpoint(URL_SIMULATIONS) + '/' + payload.id + '/status', payload.data)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * postSendSimToField
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postSendSimToField = (payload) => {
    return axios
        .post(getEndpoint(URL_SIMULATIONS) + '/' + payload.processID + '/field', payload.data)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
