import { TSPVProposal } from '../products/solarpv';
import { TSPV } from 'interfaces/products/solarpv';
import { ServiceEvents, TService, TServiceValues } from './service';
import { TInstallmentsValues } from 'interfaces/businessModels/installments';
import { InstallmentsEvents, TInstallments } from './installments';
import { TUpFront, UpFrontEvents, TUpFrontValues } from './upfront';
import { Nullable } from './../../types/utils';
import { TInputs } from './index';
import { IBusinessModel } from 'interfaces/businessModels';
import { ServiceV2Events, TServiceV2, TServiceV2Values } from 'interfaces/businessModels/serviceV2';
import { PaPEvents, TPaP, TPaPValues } from 'interfaces/businessModels/pap';
import { LECEvents, Tlec, TLECValues } from 'interfaces/businessModels/lec';
import { ESolarEvents, TESolar, TESolarValues } from 'interfaces/businessModels/eSolar';
import { PPaEvents, TPPa, TPpAValues } from 'interfaces/businessModels/ppa';
import { TElements, TUpFrontElements } from './detailed';

export enum BusinessModelsActions {
    SET_BUSINESS_MODELS = 'SET_BUSINESS_MODELS',
    SET_INITIAL_PAYLOAD = 'SET_INITIAL_PAYLOAD',
    SELECT_BUSINESS_MODEL = 'SELECT_BUSINESS_MODEL',
    SET_BUSINESS_MODEL_EVT = 'SET_BUSINESS_MODEL_EVT',
    SET_SELECTED_BUSINESS_MODEL = 'SET_SELECTED_BUSINESS_MODEL',
    SET_PROPOSAL = 'SET_PROPOSAL',
    SET_BUSINESS_MODEL_BY_NEW_COST = 'SET_BUSINESS_MODEL_BY_NEW_COST',
}

export interface IBusinessModelsAction {
    type: BusinessModelsActions;
    payload: any;
}

export interface IBusinessModelsState<T, BM> {
    businessModels: IBusinessModel[];
    simulation: TInputs<T>;
    selected: TSelectedBM<BM>;
    proposal: T extends TSPV ? Nullable<TSPVProposal<BM>> : any;
}

export type TSelectedBM<BM> = {
    id?: number | undefined;
    paymentModelID: Nullable<number>;
    isDiscount: Nullable<boolean>;
    options: Nullable<IBusinessModel>;
    elements: BM extends TUpFront ? TUpFrontElements
    :   {
            monthlyPayments: Nullable<TElements>;
            annualUpdateMonthlyFee: Nullable<TElements>;
            uniqueAllowance: Nullable<TElements>;
            yearlyAllowance: Nullable<TElements>;
        };
    values: BM extends TUpFront ? TUpFrontValues
    : BM extends TInstallments ? TInstallmentsValues
    : BM extends TService ? TServiceValues
    : BM extends TServiceV2 ? TServiceV2Values
    : BM extends TPPa ? TPpAValues
    : BM extends TPaP ? TPaPValues
    : BM extends Tlec ? TLECValues
    : BM extends TESolar ? TESolarValues
    : null;
};
export type TBMs = TUpFront | TInstallments | TServiceV2 | TPaP | Tlec | TService | TPPa | TESolar | null;

export type TBMEvents<T> =
    T extends TUpFront ? UpFrontEvents
    : T extends TInstallments ? InstallmentsEvents
    : T extends TPaP ? PaPEvents
    : T extends TServiceV2 ? ServiceV2Events
    : T extends TPPa ? PPaEvents
    : T extends Tlec ? LECEvents
    : T extends TService ? ServiceEvents
    : T extends TESolar ? ESolarEvents
    : null;
