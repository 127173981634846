import { useContext } from 'react';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';

// Components
import GrantsKpiPaP from './Kpi';
import { TPaP } from 'interfaces/businessModels/pap';

const GrantsKpisPaP = () => {
    const { state, bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;

    // const currentInvestment = bmSelected?.values?.marginSelected.cost;
    const currentInvestment = bmSelected?.values?.totalCost;
    const annualSaving = state?.businessModels?.[0]?.kpis_previews?.[0]?.kpis_per_margin?.[0]?.annual_saving;
    const grants = state?.selected?.values?.grants;
    const investmentWithGrant = currentInvestment - grants?.totalGrants?.currency;
    const payback = investmentWithGrant / annualSaving;

    return (
        <div className="bm-kpis">
            <GrantsKpiPaP
                label="page.bm.grants.originalInvestment"
                value={{ number: currentInvestment, unit: 'currency', numberOfDecimalPlaces: 0 }}
            />
            <GrantsKpiPaP
                label="page.bm.grants.grant"
                value={{ number: grants.totalGrants.currency, unit: 'currency', numberOfDecimalPlaces: 2 }}
            />
            <GrantsKpiPaP
                label="page.bm.grants.investmentWithGrant"
                value={{ number: investmentWithGrant, unit: 'currency', numberOfDecimalPlaces: 0 }}
            />
            <GrantsKpiPaP label="label.payback" value={{ number: payback, unit: 'year', numberOfDecimalPlaces: 1 }} />
        </div>
    );
};

export default GrantsKpisPaP;
