import { lazy } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Error403 from 'components/errors/Error403';
//selectors
import { getClientID } from 'redux/selectors/client';
import { getFacilityID } from 'redux/selectors/facility';
import PropTypes from 'prop-types';
import { BASENAME_URL_APP } from 'constants/settings';
import lazyLoader from 'services/lazyLoader';
import { withTracker } from 'services/withTracker';
import { FacilityProProvider } from 'contexts/facilityPro/facilityProContext';
import { SolutionsProvider } from 'contexts/solutions/solutionsContext';

const FacilityProPage = lazy(() => lazyLoader(() => import('pages/Restricted/facilityPro/FacilityProPage')));

/**
 * @context Route to Facility
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const FacilityPro = (props) => {
    const hasAccess = !!props.clientID && !!props.facilityID;
    return hasAccess ?
            <SolutionsProvider>
                <FacilityProProvider>
                    <FacilityProPage />
                </FacilityProProvider>
            </SolutionsProvider>
        :   <Error403
                props={{
                    url: BASENAME_URL_APP + 'solutions',
                    buttonText: 'page.404.button.goSolutions',
                    message: 'page.error.403.EfficiencyMeasures',
                }}
            />;
};

FacilityPro.propTypes = {
    clientID: PropTypes.string.isRequired,
    facilityID: PropTypes.string.isRequired,
};

const mapStateToProps = ({ client, facility }) => {
    return { clientID: getClientID(client), facilityID: getFacilityID(facility) };
};
export default withRouter(connect(mapStateToProps)(withTracker(FacilityPro, 'Edit Facility')));
