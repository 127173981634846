import clone from 'fast-copy';
import { INPUT_NAMES_ODATA as INPUT_NAMES, QUESTIONS_ODATA_B2B, QUESTIONS_ODATA_B2C } from 'constants/facilityPro/otherData';
import { getCompanyProfileIds } from 'services/user';

//#region /** B2B **/

/* Methods Private */

/* Methods Public */
export function getOtherDataB2B(inputs) {
    return {
        facilityId: inputs?.[INPUT_NAMES.ID],
        [INPUT_NAMES.DATA_IDADE_RESPOSTA_CLIENTE]: inputs?.[INPUT_NAMES.DATA_IDADE_RESPOSTA_CLIENTE],
        [INPUT_NAMES.NUM_TRABALHADORES]: inputs?.[INPUT_NAMES.NUM_TRABALHADORES],
        [INPUT_NAMES.AREA]: inputs?.[INPUT_NAMES.AREA],
        [INPUT_NAMES.AREA_COBERTA]: inputs?.[INPUT_NAMES.AREA_COBERTA],
        [INPUT_NAMES.AREA_UTIL]: inputs?.[INPUT_NAMES.AREA_UTIL],
    };
}

export function getQuestionsOtherDataB2B(options) {
    let questions = clone(QUESTIONS_ODATA_B2B);

    const { companyProfileId, isAddFacility } = options;

    if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId)) {
        //create & edit
        questions[INPUT_NAMES.DATA_IDADE_RESPOSTA_CLIENTE].visible = false; //OTHER
        questions[INPUT_NAMES.NUM_TRABALHADORES].visible = false; //OTHER
        questions[INPUT_NAMES.AREA].visible = false; //OTHER
        questions[INPUT_NAMES.AREA_COBERTA].visible = false; //OTHER
        questions[INPUT_NAMES.AREA_UTIL].visible = false; //OTHER
    }

    return { questions, isVisible: isOtherDataVisible(companyProfileId, { isAddFacility }) };
}

export function isOtherDataVisible(companyProfileId, { isAddFacility }) {
    switch (companyProfileId) {
        case getCompanyProfileIds().EDP_ES:
        case getCompanyProfileIds().EDP_PT:
        case getCompanyProfileIds().EFZ:
            return true;
        case getCompanyProfileIds().EDP_PL:
            return !isAddFacility;

        default:
            return false;
    }
}
//#endregion /** B2B **/

//#region /** B2C **/
/* Methods Private */

/* Methods Public */
export function getOtherDataB2C(inputs) {
    return {
        facilityId: inputs?.[INPUT_NAMES.ID] ?? null,
    };
}

export function getQuestionsOtherDataB2C() {
    let questions = clone(QUESTIONS_ODATA_B2C);

    return { questions, isVisible: false };
}
//#endregion /** B2C **/
