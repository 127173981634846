export enum SPV_MAINTENANCE_ACTIONS {
    MOUNTING = 'mounting',
    SUBMIT_FORM = 'submit_form',
}

export const INPUT_NAMES = {
    PANELS_NUMBER: 'panels_number',
    PEAK_POWER: 'peak_power',
    INSTALLATION_TYPE_ID: 'installation_type_id',
    HAS_ACCESS_TO_ROOF: 'has_access_to_roof',
    HAS_OFFER_FOR_OM_RENEWAL: 'has_offer_for_om_renewal',
    HAS_MONITORING: 'has_monitoring',
    ENV_TYPE_ID: 'env_type_id',
    ANNUAL_WASHES_NUMBER: 'annual_washes_number',
    CONTRACT_DURATION_YEARS: 'contract_duration_years',
};

export const INSTALATION_TYPE_OPTIONS = [
    {
        id: 1,
        value: '1',
        label: 'Roof',
    },
    {
        id: 2,
        value: '2',
        label: 'Ground',
    },
    {
        id: 3,
        value: '3',
        label: 'Carpark',
    },
];

export const ENV_TYPE_OPTIONS = [
    {
        id: 1,
        value: '1',
        label: 'envType1',
        no_washes: 4,
    },
    {
        id: 2,
        value: '2',
        label: 'envType2',
        no_washes: 2,
    },
    {
        id: 3,
        value: '3',
        label: 'envType3',
        no_washes: 1,
    },
];

export const NUMBER_OF_WASHES = [
    {
        id: 1,
        value: 1,
        label: '1',
    },
    {
        id: 2,
        value: 2,
        label: '2',
    },
    {
        id: 3,
        value: 4,
        label: '4',
    },
];
