import { useEffect, useRef, useState } from 'react';
import ReactPlaceholder from 'react-placeholder/lib';
import PropTypes from 'prop-types';

const LazyImage = ({ src, alt, sizeImg, customClassName, ...props }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const imgRef = useRef();

    useEffect(() => {
        // If the image is loaded before component mount, we update the state to loaded
        if (imgRef.current && imgRef.current.complete) {
            setIsImageLoaded(true);
        }
    }, []);

    return (
        <>
            <ReactPlaceholder showLoadingAnimation ready={isImageLoaded} type="rect" className={customClassName} style={{ margin: 0 }}>
                {null}
            </ReactPlaceholder>
            <img
                {...props}
                src={src}
                alt={alt}
                onLoad={() => setIsImageLoaded(true)}
                className={`${isImageLoaded ? `mw-${sizeImg} mh-${sizeImg}` : `mw-${sizeImg} mh-${sizeImg} d-none`}`}
                aria-hidden={isImageLoaded ? 'false' : 'true'}
                ref={imgRef}
            />
        </>
    );
};

//defaultProps
LazyImage.defaultProps = {
    sizeImg: '100',
    customClassName: '',
};

LazyImage.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string.isRequired,
    customClassName: PropTypes.string,
};

export default LazyImage;
