/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description API requests to the context of Solutions
 *
 * @version 20191114
 * @since 20191114 Initial release
 *
 */

// import axios from 'axios';
import { PRODUCT_IDS } from 'constants/products';
import axios, { axiosOpenAreaInstance } from 'services/@efz/axios';
import { getMessageNetworkError } from 'services/statusCodeHttp';

import {
    getEndpoint,
    URL_FACILITIES,
    URL_PV_AREA_REDUCTION_COEFFICIENTS,
    URL_REGIONS_GD,
    URL_REGION_RADIATIONS,
    URL_SIMULATIONS,
    URL_PRODUCTIONS,
    URL_PV_EQUIPMENTS,
    URL_PRODUCTS,
    URL_PRODUCTIONS_OLD,
    URL_INJECTION_TARIF_DISTRIBUTOR,
    URL_COSTS,
} from 'constants/endpoints';

const CancelToken = axios.CancelToken;
// Cancel executor variables
let getPVARCancelExecutor;
let getRGDCancelExecutor;
let getConsumptionsCancelExecutor;
let getRRCancelExecutor;
let putFAPICancelExecutor;
let getFSPVDCancelExecutor;
let getPVEquipmentsCancelExecutor;
let getZoneTypesCancelExecutor;
let getLightingQualitiesCancelExecutor;
let getEquipmentsOptionsCancelExecutor;
let getIEBrHeightOptionsCancelExecutor;
let getIEBrControlTypeOptionsCancelExecutor;
let getExtraEquipmentCancelExecutor;
let postBatterySimulationCancelExecutor;
let getMeasurementCodeCancelExecutor;
let getMeasurementsCancelExecutor;
let getEstimatedCableDistanceCancelExecutor;
let getARMeasurementsBySimIDCancelExecutor;
let distributorInjectionTariffCancelExecutor;

export const getProductInputs = (payload, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/${payload?.facilityID}/product-inputs/${payload.productID}`,
            method: 'GET',
            signal: abortController.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get PV Area Reducion Coefficients
export const getPVAreaReducionCoefficients = () => {
    getPVARCancelExecutor && getPVARCancelExecutor();
    return axios
        .get(getEndpoint(URL_PV_AREA_REDUCTION_COEFFICIENTS), {
            cancelToken: new CancelToken(function executor(c) {
                getPVARCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get PV Equipment Ranges
export const getPVEquipments = () => {
    getPVEquipmentsCancelExecutor && getPVEquipmentsCancelExecutor();
    return axios
        .get(getEndpoint(URL_PV_EQUIPMENTS) + '/ranges', {
            cancelToken: new CancelToken(function executor(c) {
                getPVEquipmentsCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get PV Equipment Ranges with Panels
export const getPVEquipmentsWithPanels = (facilityPower, isOArea = false, productID = 1, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    if (isOArea) {
        return axiosOpenAreaInstance
            .request({
                url: `${getEndpoint(URL_PV_EQUIPMENTS)}/ranges?new_ranges=true&product_type_id=${productID}`,
                method: 'GET',
                signal: abortController.signal,
            })
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request({
                url: `${getEndpoint(URL_PV_EQUIPMENTS)}/ranges?new_ranges=true&facility_power=${facilityPower}&product_type_id=${productID}`,
                method: 'GET',
                signal: abortController.signal,
            })
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

//Get PV Equipment Ranges with Remuneration
export const getPVEquipmentsWithRemuneration = (
    facilityPower,
    remuneration_by_company_id,
    isOArea = false,
    productID = 1,
    abortController
) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    if (isOArea) {
        return axiosOpenAreaInstance
            .request({
                url: `${getEndpoint(URL_PV_EQUIPMENTS)}/ranges?new_ranges=true&facility_power=${facilityPower}&remuneration_by_company_id=${remuneration_by_company_id}&product_type_id=${productID}`,
                method: 'GET',
                signal: abortController.signal,
            })
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request({
                url: `${getEndpoint(URL_PV_EQUIPMENTS)}/ranges?new_ranges=true&facility_power=${facilityPower}&remuneration_by_company_id=${remuneration_by_company_id}&product_type_id=${productID}`,
                method: 'GET',
                signal: abortController.signal,
            })
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

//Get PV Equipment Estimated Structures - Without Carto
export const postPVEquipmentsEstStructuresAspect = (payload, isOArea = false, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    let requestCfg = {
        url: `${getEndpoint(URL_PV_EQUIPMENTS)}/estimated-structures-aspect`,
        method: 'POST',
        data: payload,
        signal: abortController.signal,
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

//Get Region GD
export const getRegionGD = () => {
    getRGDCancelExecutor && getRGDCancelExecutor();

    return axios
        .get(getEndpoint(URL_REGIONS_GD), {
            cancelToken: new CancelToken(function executor(c) {
                getRGDCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Consumptions
export const getConsumptions = (payload, abortController = getConsumptionsCancelExecutor) => {
    // AbortControllers
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }
    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/${payload?.facilityID}/consumptions`,
            method: 'GET',
            signal: abortController.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getConsumptionMonthly = (payload, abortController) => {
    // AbortControllers
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }
    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/${payload?.facilityID}/consumptions?agg_type=monthly`,
            method: 'GET',
            signal: abortController.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Solar Sensibility
export const getSolarSensibility = (payload, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/${payload?.facilityID}/solar-sensibility-analysis`,
            method: 'GET',
            params: payload?.params,
            signal: abortController.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Post Solar Sensibility (with battery)
export const postSolarSensibilityBattery = (payload, postSBBAbortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/${payload?.facilityID}/solar-batteries-sensibility-analysis`,
            method: 'POST',
            params: payload?.params,
            signal: postSBBAbortController.signal,
            data: payload?.body,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get getRegionRadiations
export const getRegionRadiations = (payload) => {
    getRRCancelExecutor && getRRCancelExecutor();

    return axios
        .get(getEndpoint(URL_REGION_RADIATIONS) + '?region_gd_id=' + payload.region_gd_id, {
            cancelToken: new CancelToken(function executor(c) {
                getRRCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Post SimulationSPV
let genericAbortController;
export const postSimulation = ({ payload }, hasAbortController = false, abortController = genericAbortController) => {
    if (!hasAbortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    return axios
        .request({
            url: `${getEndpoint(URL_SIMULATIONS)}`,
            method: 'POST',
            data: payload,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
//Post SimulationSPV
export const postBatterySimulation = (payload) => {
    postBatterySimulationCancelExecutor && postBatterySimulationCancelExecutor();

    return axios
        .post(getEndpoint(URL_SIMULATIONS), payload, {
            cancelToken: new CancelToken(function executor(c) {
                postBatterySimulationCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
//Post Injection Tariff Distributor
export const getDistributorInjectionTariff = (payload) => {
    distributorInjectionTariffCancelExecutor && distributorInjectionTariffCancelExecutor();

    return axios
        .get(`${getEndpoint(URL_INJECTION_TARIF_DISTRIBUTOR)}`, {
            cancelToken: new CancelToken(function executor(c) {
                distributorInjectionTariffCancelExecutor = c;
            }),
            params: {
                distributor_code: payload?.distributor_code,
                peak_power: payload?.peak_power,
            },
        })
        .then((response) => response?.data)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Post EFZ costs
export const postEfzCosts = (productID, payload, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_COSTS)}/${productID}`,
            method: 'POST',
            data: payload,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Categories Costs
export const getCategoriesCosts = (productID, params = { customized_profile_id: 1 }, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS)}/${productID}/categories`,
            method: 'GET',
            params,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get customized costs
export const getCustomizedCosts = (productID, facilityID, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS)}/${productID}/${URL_COSTS}/customized/${facilityID}`,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Post customized costs
export const postCustomizedCosts = (productID, payload, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTS)}/${productID}/${URL_COSTS}/customized`,
            method: 'POST',
            data: payload,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Post Productions
export const postProductionsOld = ({ payload }, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTIONS_OLD)}`,
            method: 'POST',
            data: payload,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Post Productions
export const postProductions = ({ payload, pid }, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_PRODUCTIONS)}`,
            method: 'POST',
            data: payload,
            signal: abortController?.signal,
            params: { pid },
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Facility Configs
export const getFacilityConfigs = (perfil_empresa_id, abortController) => {
    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/configs/${perfil_empresa_id}`,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Put Facility And Product Inputs
export const putFacilityAndProductInputs = (payload) => {
    putFAPICancelExecutor && putFAPICancelExecutor.abort();
    putFAPICancelExecutor = new AbortController();
    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/${payload.facilityId}/product-inputs/${payload.productId}`,
            method: 'PUT',
            data: payload.body,
            signal: putFAPICancelExecutor?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get File PV Data
export const getFileSPVData = (payload, getFileSPVDatAbortController) => {
    getFSPVDCancelExecutor && getFSPVDCancelExecutor();

    return axios
        .get(getEndpoint(URL_FACILITIES) + '/' + payload.facilityId + '/pvdata', {
            params: {
                production: true,
                has_battery: payload?.has_battery,
            },
            signal: getFileSPVDatAbortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//#region IEBR
//Get zone types
export const getZoneTypesOptions = (productID) => {
    getZoneTypesCancelExecutor && getZoneTypesCancelExecutor();
    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${productID}/zone-type-options`, {
            cancelToken: new CancelToken(function executor(c) {
                getZoneTypesCancelExecutor = c;
            }),
        })
        .then((response) => response?.data)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
export const getLightingQualitiesOptions = (productID) => {
    getLightingQualitiesCancelExecutor && getLightingQualitiesCancelExecutor();
    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${productID}/lighting-quality-options`, {
            cancelToken: new CancelToken(function executor(c) {
                getLightingQualitiesCancelExecutor = c;
            }),
        })
        .then((response) => response?.data)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getEquipmentOptions = (productID) => {
    getEquipmentsOptionsCancelExecutor && getEquipmentsOptionsCancelExecutor();
    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${productID}/equipment-options`, {
            cancelToken: new CancelToken(function executor(c) {
                getEquipmentsOptionsCancelExecutor = c;
            }),
        })
        .then((response) => response?.data)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getHeightOptions = (productID) => {
    getIEBrHeightOptionsCancelExecutor && getIEBrHeightOptionsCancelExecutor();
    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${productID}/height-options`, {
            cancelToken: new CancelToken(function executor(c) {
                getIEBrHeightOptionsCancelExecutor = c;
            }),
        })
        .then((response) => response?.data)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getControlType = (productID) => {
    getIEBrControlTypeOptionsCancelExecutor && getIEBrControlTypeOptionsCancelExecutor();
    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${productID}/control-type-options`, {
            cancelToken: new CancelToken(function executor(c) {
                getIEBrControlTypeOptionsCancelExecutor = c;
            }),
        })
        .then((response) => response?.data)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
//#endregion IEBR

//#region HVAC
export const getExtraEquipmentOptions = (productID) => {
    getExtraEquipmentCancelExecutor && getExtraEquipmentCancelExecutor();
    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${productID}/extra-equipment-options`, {
            cancelToken: new CancelToken(function executor(c) {
                getExtraEquipmentCancelExecutor = c;
            }),
        })
        .then((response) => response?.data)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
//#endregion HVAC

//#region EVC
//Get Measurement Code
export const getMeasurementCode = (productID) => {
    getMeasurementCodeCancelExecutor && getMeasurementCodeCancelExecutor();

    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${productID}/ar/measurements/code`, {
            cancelToken: new CancelToken(function executor(c) {
                getMeasurementCodeCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get MeasurementsByCode
export const getMeasurements = (params) => {
    getMeasurementsCancelExecutor && getMeasurementsCancelExecutor();

    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${params.productID}/ar/measurements/${params.code}`, {
            cancelToken: new CancelToken(function executor(c) {
                getMeasurementsCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Estimated Cable Distance
export const getEstimatedCableDistance = (productID) => {
    getEstimatedCableDistanceCancelExecutor && getEstimatedCableDistanceCancelExecutor();

    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${productID}/estimated-cable-distance`, {
            cancelToken: new CancelToken(function executor(c) {
                getEstimatedCableDistanceCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// Get EVC AR Measurements by Sim ID
export const getARMeasurementsBySimID = (simID) => {
    getARMeasurementsBySimIDCancelExecutor && getARMeasurementsBySimIDCancelExecutor();

    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${PRODUCT_IDS.EVC}/ar/proposals/${simID}`, {
            cancelToken: new CancelToken(function executor(c) {
                getARMeasurementsBySimIDCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//#endregion EVC
