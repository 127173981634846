import { DetailedOfferEditionEvents } from './../../../interfaces/businessModels/detailed/index';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { useEffect, useContext, useCallback } from 'react';

// Interfaces
import { IBusinessModel, IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { BusinessModelsActions } from 'interfaces/businessModels/reducer';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { PPaEvents, TPPa } from 'interfaces/businessModels/ppa';

// Constants
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { isDefined } from 'services/util/auxiliaryUtils';

// Services

const usePPa2BM = (bm: IBusinessModel) => {
    const { state, bmEvent, dispatch, productPayload, setBMEventHandler } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TPPa
    >;

    const { setBMEventHandler: syncSetBMEventHandler } = useContext(OfferEditionContext);

    const ppAEvents = useCallback(() => {
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.PPA && isDefined(bmEvent?.name)) {
            switch (bmEvent?.name) {
                case PPaEvents.SET_PPA_BM: {
                    const syncKpis = productPayload?.productInputs?.syncKpis?.find((bm) => bm.tipo_modelo_negocio_id === state.selected.id);

                    if (isDefined(syncKpis)) {
                        const businessModelSelected = state.businessModels.find(
                            (bm) => bm.payment_model_id === state.selected.paymentModelID
                        );
                        syncSetBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_SELECTED, {
                            businessModelSelected: businessModelSelected,
                            businessModelBody: productPayload,
                            kpis: syncKpis,
                            offerEdition: productPayload?.productInputs?.offerEdition,
                            elements: {
                                monthlyPayments:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'monthly_payment_option'
                                    ) ?? null,
                                omContractDuration:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'om_contract_duration'
                                    ) ?? null,
                                annualUpdateMonthlyFee:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'tariff_annual_update'
                                    ) ?? null,
                                uniqueAllowance:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'unique_allowance'
                                    ) ?? null,
                                yearlyAllowance:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'yearly_allowance'
                                    ) ?? null,
                            },
                        });
                    }
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            syncKpis: syncKpis,
                        },
                    });

                    break;
                }
            }
        }
    }, [bmEvent]); // eslint-disable-line

    useEffect(() => {
        ppAEvents();
    }, [ppAEvents]);

    useEffect(() => {
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.PPA) {
            setBMEventHandler(PPaEvents.SET_PPA_BM, null);
        }
    }, [state.selected.paymentModelID]); // eslint-disable-line

    return {
        pitch: bm.pitch,
        projectSummary: bm.kpis_previews,
        selected: bm.selected,
        id: bm.payment_model_id,
        sizingTypeId: bm.sizing_type_id,
        syncKpis: productPayload?.productInputs?.syncKpis?.find((sincBm) => sincBm.tipo_modelo_negocio_id === bm.business_model_id),
    };
};

export default usePPa2BM;
