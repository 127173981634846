//Libraries
import { memo } from 'react';
import PropTypes from 'prop-types';
//Material UI Component
import FormHelperText from '@mui/material/FormHelperText';
import IntlMessages from 'components/util/IntlMessages';
//Component
const ErrorMessage = ({ error, values }) => {
    return (
        <FormHelperText className={`Mui-error`}>
            <IntlMessages id={error?.message ?? 'label.null'} values={values} />
        </FormHelperText>
    );
};

//PropTypes
ErrorMessage.propTypes = {
    error: PropTypes.object.isRequired,
    values: PropTypes.object,
};

//Export
export default memo(ErrorMessage);
