import { useContext } from 'react';

// Components
import CommercialMarginUpFront from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront/CommercialMargin';
import OfferDetailsUpFront from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront/OfferDetails';
import GrantsUpfront from './Grants';
import ContactInfoUpfront from './ContactInfo';
import InstallationPrices from './InstallationPrices/HP';

// Constants
import { PRODUCT_IDS } from 'constants/products';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront } from 'interfaces/businessModels/upfront';

const ProjectSummaryUpFront = () => {
    const {
        negotiationProfile,
        state: {
            businessModels,
            selected: {
                values: {
                    grants: { hasGrants },
                },
            },
        },
        bmSelected,
        productID,
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const showsContactInfo = negotiationProfile?.mostra_formulario && bmSelected?.options?.has_form;
    const has_offer_edition = businessModels?.find((bm) => bm.business_model_id === bmSelected.id)?.has_offer_edition;

    return (
        <>
            {[PRODUCT_IDS.HP].includes(productID) ?
                <InstallationPrices />
            :   <></>}
            {negotiationProfile?.acesso_resumo_custo && has_offer_edition ?
                <OfferDetailsUpFront />
            :   <></>}
            {negotiationProfile?.negoceia_proposta ?
                <CommercialMarginUpFront />
            :   <></>}
            {negotiationProfile?.mostra_subsidios && hasGrants ?
                <GrantsUpfront />
            :   <></>}
            {showsContactInfo ?
                <ContactInfoUpfront />
            :   <></>}
        </>
    );
};

export default ProjectSummaryUpFront;
