// API
import { createProjectHandler } from 'api/projects';

// Schemas
import { TCreateProjectPayload } from 'schemas/projects';

// Services
import { notify } from 'services/@efz/notify';
import { intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';

// Store
import { useBusinessModelsStore } from 'store/businessModels';

const useProposalProjects = () => {
    const {
        businessModels: {
            // @ts-ignore
            inputs,
            productInputs,
            facility_id,
        },
    } = useBusinessModelsStore();

    let createProjectAController: AbortController;

    const createShadowProject = async ({ productID, proposalID }: { productID: number; proposalID: number }) => {
        try {
            createProjectAController && createProjectAController.abort();
            createProjectAController = new AbortController();
            const newProjectPayload: TCreateProjectPayload = {
                description: `Proposal_${proposalID}`,
                inputs: {
                    ...inputs,
                    fe_version:
                        isFieldDefined(productInputs?.fromSpvV3) ?
                            productInputs?.fromSpvV3 === true ?
                                'v3'
                            :   'v2'
                        :   undefined,
                },
                visible: false,
                simulation_id: proposalID,
            };
            return await createProjectHandler(
                newProjectPayload,
                { facilityId: Number(facility_id), productId: productID },
                createProjectAController
            );
        } catch (error) {
            notify(intlMessages('page.error.403.unexpected'), 'error');
        }
    };

    return {
        createShadowProject,
    };
};

export default useProposalProjects;
