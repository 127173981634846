import { getBoolean } from 'services/util/auxiliaryUtils';

const useCookies = () => {
    const CONSENT_COOKIE = 'CookieConsent';
    const PLATFORM_CUSTOMIZATION_COOKIE = 'PlatformCustomizationCookie';
    const PERSONALIZED_ADVERTISING_COOKIE = 'PersonalizedAdvertisingCookie';

    const setCookie = (name, value, days) => {
        var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    };

    const getCookie = (name) => {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length).length ? getBoolean(c.substring(nameEQ.length, c.length)) : false;
            }
        }
        return null;
    };

    const removeGACookies = () => {
        if (getCookie('_gid') !== null) document.cookie = `_gid=;expires=Thu, 01 Jan 1970;path=/;domain=${document.domain}`;
        if (getCookie('_ga') !== null) document.cookie = `_ga=;expires=Thu, 01 Jan 1970;path=/;domain=${document.domain}`;
    };

    return {
        setCookie,
        getCookie,
        removeGACookies,
        CONSENT_COOKIE,
        PLATFORM_CUSTOMIZATION_COOKIE,
        PERSONALIZED_ADVERTISING_COOKIE,
    };
};

export default useCookies;
