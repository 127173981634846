import { setProposal } from 'redux/actions/proposal';
import ProposalContainer from 'components/Proposal/index';
import { useDispatch } from 'react-redux';
import { useEffect, useContext } from 'react';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { intlMessages } from 'services/util/auxiliaryUtils';
import { Helmet } from 'react-helmet';
import { getHeadTitle } from 'services/settings';

const Proposal = () => {
    const dispatch = useDispatch();

    const {
        spvSimpleState: { proposal },
    } = useContext(SPVSimpleContext);

    useEffect(() => {
        dispatch(setProposal(proposal));
    }, []); // eslint-disable-line

    return (
        <div className="proposal-container">
            <Helmet>
                <title>{`${getHeadTitle()} · ${intlMessages('label.proposal')}`}</title>
            </Helmet>
            <ProposalContainer />
        </div>
    );
};

export default Proposal;
