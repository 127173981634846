export const INPUT_NAMES_ODATA = {
    ID: 'id',
    DATA_IDADE_RESPOSTA_CLIENTE: 'data_idade_resposta_cliente',
    NUM_TRABALHADORES: 'num_trabalhadores',
    AREA: 'area',
    AREA_COBERTA: 'area_coberta',
    AREA_UTIL: 'area_util',
};

//#region /** B2B **/
export const QUESTIONS_ODATA_B2B = {
    //otherFacilitySection
    [INPUT_NAMES_ODATA.DATA_IDADE_RESPOSTA_CLIENTE]: {
        name: INPUT_NAMES_ODATA.DATA_IDADE_RESPOSTA_CLIENTE,
        label: 'question.label.data_idade_resposta_cliente',
        placeholder: 'question.placeholder.data_idade_resposta_cliente',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'input',
        validation: null,
    },
    [INPUT_NAMES_ODATA.NUM_TRABALHADORES]: {
        name: INPUT_NAMES_ODATA.NUM_TRABALHADORES,
        label: 'question.label.num_trabalhadores',
        placeholder: 'question.placeholder.num_trabalhadores',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'input',
        type: 'input',
        validation: null,
    },
    [INPUT_NAMES_ODATA.AREA]: {
        name: INPUT_NAMES_ODATA.AREA,
        label: 'question.label.area',
        placeholder: 'question.placeholder.area',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'input',
        type: 'input',
        validation: null,
    },
    [INPUT_NAMES_ODATA.AREA_COBERTA]: {
        name: INPUT_NAMES_ODATA.AREA_COBERTA,
        label: 'question.label.area_coberta',
        placeholder: 'question.placeholder.area_coberta',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'input',
        type: 'input',
        validation: null,
    },
    [INPUT_NAMES_ODATA.AREA_UTIL]: {
        name: INPUT_NAMES_ODATA.AREA_UTIL,
        label: 'question.label.area_util',
        placeholder: 'question.placeholder.area_util',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'input',
        validation: null,
    },
};

//#endregion /** B2B **/

//#region /** B2C **/

export const QUESTIONS_ODATA_B2C = {};

//#endregion /** B2C **/
