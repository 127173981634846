import clone from 'fast-copy';
import validate from 'services/util/validate';
import { GRUPO_CANAL_IDS, GRUPO_CANAL_IDS_PREFIXOS, USERS_TYPE_ID } from 'constants/user';
import {
    CREATE_CLIENT_FORM_QUESTIONS,
    CREATE_CLIENT_FORM_QUESTIONS_B2C,
    EDIT_CLIENT_FORM_QUESTIONS,
    INPUT_NAMES_B2C,
    INPUT_NAMES as INPUT_NAMES_CLIENT,
} from 'constants/client';
import { AVAILABLE_LINKS_CAE } from 'constants/facility';
import { isDefined } from 'services/util/auxiliaryUtils';
import { getCompanyProfileIds } from './user';

/**
 * isNipcEditAllowed
 *
 * @description  Determines if a certain user can edit the clients NIPC
 * @param {*} companyProfileId
 */
export const isNipcEditAllowed = (companyProfileId) =>
    [
        getCompanyProfileIds().NRG,
        getCompanyProfileIds().ROMANDE,
        getCompanyProfileIds().PROFILE_BASE_SWISS,
        getCompanyProfileIds().PPC,
        getCompanyProfileIds().PROFILE_BASE_UK,
        getCompanyProfileIds().EVOLO,
        getCompanyProfileIds().PROFILE_BASE_USA,
    ].includes(companyProfileId);

/**
 * clientQuestions
 */
export function clientQuestions(inputs, options) {
    //let _inputs = clone(buildInputs(inputs, options));
    let _questions = clone(buildClientQuestion(inputs, options));

    return {
        //inputs: _inputs,
        questions: _questions,
    };
}

const buildClientQuestion = (_inputs = null, options) => {
    const { isCreating, userChannelIDGroup, companyProfileId, userTypeID, configs = null } = options;

    const questions = isCreating ? clone(CREATE_CLIENT_FORM_QUESTIONS) : clone(EDIT_CLIENT_FORM_QUESTIONS);

    switch (userChannelIDGroup) {
        case GRUPO_CANAL_IDS.ESE:
            if (isCreating) {
                questions[INPUT_NAMES_CLIENT.NIPC].visible = true;
                questions[INPUT_NAMES_CLIENT.CAE5].validation = validate(['minLength', 'maxLength'], {
                    maxLength: 8,
                    minLength: 2,
                });
                questions[INPUT_NAMES_CLIENT.CAE5].optValidation = { maxLength: 8, minLength: 2 };
                questions[INPUT_NAMES_CLIENT.MORADA].validation = validate(['required', 'minLength', 'maxLength'], {
                    minLength: 2,
                    maxLength: 256,
                });
                questions[INPUT_NAMES_CLIENT.MORADA].optValidation = { minLength: 2, maxLength: 256 };
                questions[INPUT_NAMES_CLIENT.CODIGO_POSTAL].validation = validate(['required', 'minLength', 'maxLength'], {
                    minLength: 2,
                    maxLength: 8,
                });
                questions[INPUT_NAMES_CLIENT.CODIGO_POSTAL].optValidation = { minLength: 2, maxLength: 8 };
                questions[INPUT_NAMES_CLIENT.LOCALIDADE].validation = validate(['required', 'minLength', 'maxLength'], {
                    minLength: 2,
                    maxLength: 64,
                });
                questions[INPUT_NAMES_CLIENT.LOCALIDADE].optValidation = { minLength: 2, maxLength: 64 };
                questions[INPUT_NAMES_CLIENT.CONTACTO_NOME].validation = validate(['required', 'minLength', 'maxLength'], {
                    minLength: 2,
                    maxLength: 256,
                });
                questions[INPUT_NAMES_CLIENT.CONTACTO_NOME].optValidation = { minLength: 2, maxLength: 256 };
                questions[INPUT_NAMES_CLIENT.CONTACTO_NUMERO].validation = validate(['required', 'minLength', 'maxLength'], {
                    minLength: 2,
                    maxLength: 16,
                });
                questions[INPUT_NAMES_CLIENT.CONTACTO_NUMERO].optValidation = { minLength: 2, maxLength: 16 };
                questions[INPUT_NAMES_CLIENT.CONTACTO_EMAIL].validation = validate(['required', 'email', 'maxLength'], { maxLength: 64 });
                questions[INPUT_NAMES_CLIENT.CONTACTO_EMAIL].optValidation = { maxLength: 64 };
                questions[INPUT_NAMES_CLIENT.SEGMENTO].visible = false;
                questions[INPUT_NAMES_CLIENT.RISCO].visible = false;
            } else {
                // EDIT
                questions[INPUT_NAMES_CLIENT.DESCRICAO].disabled = false;
            }
            break;
        default:
            break;
    }

    // Gestão do tamanho do campo NIPC de acordo com os prefixos para os grupo canal ID 1-5
    if (
        [
            GRUPO_CANAL_IDS.LIBERBANK,
            GRUPO_CANAL_IDS.TELEFONICA,
            GRUPO_CANAL_IDS.BBVA,
            GRUPO_CANAL_IDS.ESE,
            GRUPO_CANAL_IDS.SELECTRA,
            GRUPO_CANAL_IDS.BANCOSABADELL,
            GRUPO_CANAL_IDS.OUTBOUND,
        ].includes(userChannelIDGroup) &&
        isDefined(GRUPO_CANAL_IDS_PREFIXOS[userChannelIDGroup])
    ) {
        questions[INPUT_NAMES_CLIENT.NIPC].validation = validate(['minLength', 'maxLength'], {
            minLength: 2,
            maxLength: 16 - GRUPO_CANAL_IDS_PREFIXOS[userChannelIDGroup].length,
        });
        questions[INPUT_NAMES_CLIENT.NIPC].optValidation = {
            minLength: 2,
            maxLength: 16 - GRUPO_CANAL_IDS_PREFIXOS[userChannelIDGroup].length,
        };
    }

    // EDPR BR exceptions
    if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId)) {
        questions[INPUT_NAMES_CLIENT.CONTRIBUINTE_ICMS].visible = true;

        questions[INPUT_NAMES_CLIENT.NIPC].mask = '99.999.999/9999-99';
        questions[INPUT_NAMES_CLIENT.NIPC].validation = [
            validate(['required', 'minLength', 'maxLength'], { minLength: 14, maxLength: 16 }),
        ];
        questions[INPUT_NAMES_CLIENT.NIPC].optValidation = { minLength: 14, maxLength: 16 };

        questions[INPUT_NAMES_CLIENT.CODIGO_POSTAL].validation = [
            validate(['maxLength', 'minLength'], { minLength: 9, maxLength: 9 }),
            validate(['maxLength', 'minLength'], { minLength: 8, maxLength: 8 }),
        ];
        questions[INPUT_NAMES_CLIENT.CODIGO_POSTAL].optValidation = { minLength: 8, maxLength: 8 };
        questions[INPUT_NAMES_CLIENT.CODIGO_POSTAL].mask = '99999-999';
    }

    questions[INPUT_NAMES_CLIENT.RISCO].visible = [USERS_TYPE_ID.MANAGER].includes(userTypeID);
    if ([getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
        questions[INPUT_NAMES_CLIENT.RISCO].visible = [USERS_TYPE_ID.MANAGER, USERS_TYPE_ID.CHANNEL].includes(userTypeID);
        questions[INPUT_NAMES_CLIENT.RISCO].disabled = [USERS_TYPE_ID.MANAGER, USERS_TYPE_ID.CHANNEL].includes(userTypeID);
    }

    if (companiesWithDetailledAddress(companyProfileId)) {
        questions[INPUT_NAMES_CLIENT.NUMERO].visible = true;
        questions[INPUT_NAMES_CLIENT.NUMERO].validation = null;

        questions[INPUT_NAMES_CLIENT.COMPLEMENTO].visible = true;
        questions[INPUT_NAMES_CLIENT.COMPLEMENTO].validation = null;

        questions[INPUT_NAMES_CLIENT.BAIRRO].visible = true;
        questions[INPUT_NAMES_CLIENT.BAIRRO].validation = null;

        questions[INPUT_NAMES_CLIENT.MUNICIPIO].visible = true;
        questions[INPUT_NAMES_CLIENT.MUNICIPIO].validation = null;

        questions[INPUT_NAMES_CLIENT.NUT].visible = true;
        questions[INPUT_NAMES_CLIENT.NUT].options = options?.nuts ?? [];
        questions[INPUT_NAMES_CLIENT.NUT].validation = null;
    }

    if (
        [
            getCompanyProfileIds().EDP_PT,
            getCompanyProfileIds().EDP_PL,
            getCompanyProfileIds().EDP_ES,
            getCompanyProfileIds().EDP_IT,
            getCompanyProfileIds().PROFILE_BASE_IT,
        ].includes(companyProfileId) &&
        [USERS_TYPE_ID.CHANNEL, USERS_TYPE_ID.MANAGER].includes(userTypeID)
    ) {
        questions[INPUT_NAMES_CLIENT.TNW].visible = true;
    }
    if (
        [getCompanyProfileIds().EDP_IT, getCompanyProfileIds().PROFILE_BASE_IT].includes(companyProfileId) &&
        [USERS_TYPE_ID.MANAGER].includes(userTypeID)
    ) {
        questions[INPUT_NAMES_CLIENT.TNW].disabled = false;
    }

    //Tooltip Link CAE
    let hasTooltipLinkCAE = Object.keys(AVAILABLE_LINKS_CAE).includes(`${companyProfileId}`);
    questions[INPUT_NAMES_CLIENT.CAE5].tooltipLink = hasTooltipLinkCAE ? AVAILABLE_LINKS_CAE[companyProfileId] : null;

    // SUNSP
    if ([getCompanyProfileIds().SUNSEAP].includes(companyProfileId)) {
        questions[INPUT_NAMES_CLIENT.CAE5].visible = true;
        questions[INPUT_NAMES_CLIENT.RISCO].visible = false;
    }

    // UK
    if ([getCompanyProfileIds().PPC, getCompanyProfileIds().PROFILE_BASE_UK, getCompanyProfileIds().EVOLO].includes(companyProfileId)) {
        questions[INPUT_NAMES_CLIENT.NIPC].disabled = false;
        questions[INPUT_NAMES_CLIENT.DESCRICAO].disabled = false;
        questions[INPUT_NAMES_CLIENT.RISCO].visible = false;
        questions[INPUT_NAMES_CLIENT.NUMERO_REGISTO_EMPRESA].visible = true;
        questions[INPUT_NAMES_CLIENT.CAE5].visible = true;
        questions[INPUT_NAMES_CLIENT.CAE5].defaultValue = '70';
        questions[INPUT_NAMES_CLIENT.CAE5].tooltipLink = AVAILABLE_LINKS_CAE[companyProfileId];
        questions[INPUT_NAMES_CLIENT.NUMERO_REGISTO_EMPRESA].validation = validate(['maxLength'], {
            maxLength: 16,
        });
        questions[INPUT_NAMES_CLIENT.NUMERO_REGISTO_EMPRESA].optValidation = { maxLength: 16 };
        questions[INPUT_NAMES_CLIENT.CAE5].validation = validate(['maxLength'], { maxLength: 8 });
        questions[INPUT_NAMES_CLIENT.NIPC].validation = validate(['maxLength'], { maxLength: 16 });
    }

    //ROMANDE
    if (
        [getCompanyProfileIds().ROMANDE, getCompanyProfileIds().PROFILE_BASE_SWISS, getCompanyProfileIds().PROFILE_BASE_USA].includes(
            companyProfileId
        )
    ) {
        questions[INPUT_NAMES_CLIENT.DESCRICAO].disabled = false;
        questions[INPUT_NAMES_CLIENT.DESCRICAO].validation = validate(['required']);
        questions[INPUT_NAMES_CLIENT.NIPC].disabled = false;
        questions[INPUT_NAMES_CLIENT.CAE5].visible = [
            getCompanyProfileIds().PROFILE_BASE_SWISS,
            getCompanyProfileIds().PROFILE_BASE_USA,
        ].includes(companyProfileId);
        questions[INPUT_NAMES_CLIENT.CAE5].validation = validate(['required', 'minLength', 'maxLength'], {
            minLength: 2,
            maxLength: 8,
        });
        questions[INPUT_NAMES_CLIENT.CAE5].defaultValue = '';
        questions[INPUT_NAMES_CLIENT.CONTACTO_NOME].validation = null;
        questions[INPUT_NAMES_CLIENT.CONTACTO_NUMERO].validation = null;
        questions[INPUT_NAMES_CLIENT.MORADA].validation = null;
        questions[INPUT_NAMES_CLIENT.CODIGO_POSTAL].validation = validate([]);
        questions[INPUT_NAMES_CLIENT.NIPC].validation = validate(['maxLength'], { maxLength: 16 });
        questions[INPUT_NAMES_CLIENT.RISCO].visible = false;
    }

    // DE
    if ([getCompanyProfileIds().PROFILE_BASE_DE, getCompanyProfileIds().PROFILE_EDP_FR].includes(companyProfileId)) {
        questions[INPUT_NAMES_CLIENT.RISCO].visible = false;
    }

    if (configs) {
        questions[INPUT_NAMES_CLIENT.RISCO].disabled = !configs.edita_risco;
        questions[INPUT_NAMES_CLIENT.TNW].disabled = !configs.edita_tnw;
    }
    return questions;
};

/**
 * clientQuestions (B2C)
 */
export function clientQuestionsB2C(inputs, options) {
    //let _inputs = clone(buildInputs(inputs, options));
    let _questions = clone(buildClientQuestionB2C(inputs, options));

    return {
        //inputs: _inputs,
        questions: _questions,
    };
}

const buildClientQuestionB2C = (_inputs = null, options) => {
    // const { isCreating, userChannelIDGroup, companyProfileId, userTypeID } = options;
    const { companyProfileId, configs = null } = options;

    const questions = clone(CREATE_CLIENT_FORM_QUESTIONS_B2C);

    // TODO: add specs per company HERE (if necessary)
    switch (companyProfileId) {
        case getCompanyProfileIds().PROFILE_BASE_UK:
        case getCompanyProfileIds().EVOLO: {
            questions[INPUT_NAMES_B2C.EMAIL].validation = validate(['email', 'maxLength'], { maxLength: 64 });
            break;
        }
        default:
            break;
    }
    if (configs) {
        questions[INPUT_NAMES_CLIENT.RISCO].disabled = !configs.edita_risco;
        questions[INPUT_NAMES_CLIENT.TNW].disabled = !configs.edita_tnw;
    }

    return questions;
};

/**
 * Method to check if Company has the feauture of detailled address
 * @param {bool} companyProfileId
 * @returns
 */
export const companiesWithDetailledAddress = (companyProfileId) => [getCompanyProfileIds().EDP_BR].includes(companyProfileId);

export const companiesWithoutAddressOnClient = (companyProfileId) =>
    [getCompanyProfileIds().PROFILE_BASE_UK, getCompanyProfileIds().EVOLO, getCompanyProfileIds().PROFILE_BASE_USA].includes(
        companyProfileId
    );

/**
 * Method to check if a user can be added in a given Company
 * @param {int} companyProfileId
 * @returns {bool}
 */
export const companyCanAddUser = (companyProfileId) =>
    [
        getCompanyProfileIds().PROFILE_BASE_SWISS,
        getCompanyProfileIds().PPC,
        getCompanyProfileIds().SUNSEAP,
        getCompanyProfileIds().PROFILE_BASE_UK,
        getCompanyProfileIds().EVOLO,
        getCompanyProfileIds().PROFILE_BASE_USA,
    ].includes(companyProfileId);

/**
 * Method to add format value based in question's validation max-length
 * @param {int} maxLength
 * @returns {string}
 */
export const formatLength = (maxLength) => {
    return maxLength ? '#'.repeat(maxLength) : null;
};
