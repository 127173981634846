import { memo, useContext, useEffect } from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import IntlMessages from 'components/util/IntlMessages';
import Alert from 'components/@efz/Alert';
import {
    SPV_SIMPLE_QUESTIONS as questions,
    SPV_SIMPLE_INPUTS as INPUT_NAMES,
    DEFAULTS_PROPS_MAP,
    spvSimpleActions,
} from 'constants/products/solarpvSimple';
import ErrorMessage from 'components/errors/ErrorMessage';
import { Controller, useFormContext } from 'react-hook-form';
import Skeleton from '@mui/material/Skeleton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import SolarpvSimpleMap from '../SolarpvSimpleMap';
import { intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';
import LazyImage from 'components/util/LazyImage';
import { getStaticFiles } from 'constants/endpoints';
import CustomUnit from 'components/util/CustomUnit';
import { Helmet } from 'react-helmet';
import { getHeadTitle } from 'services/settings';

const RoofDetails = () => {
    const {
        userLocale,
        spvSimpleState: { inputs, options, isDetectRoof },
        spvSimpleDispatchHandler: dispatch,
    } = useContext(SPVSimpleContext);

    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

    useEffect(() => {
        // set value (for safety)
        if (!isFieldDefined(watchAllFields?.[INPUT_NAMES.MOUNTING_STRUCTURE_ID]))
            setValue(INPUT_NAMES.MOUNTING_STRUCTURE_ID, inputs?.areas[0]?.[INPUT_NAMES.MOUNTING_STRUCTURE_ID]);
    }, [inputs.areas, watchAllFields?.[INPUT_NAMES.MOUNTING_STRUCTURE_ID]]); //eslint-disable-line

    return (
        <div className="roof-details-container">
            <Helmet>
                <title>{`${getHeadTitle()} · ${intlMessages('page.spvSimple.open.b2c.step.4')}`}</title>
            </Helmet>
            <h2>
                <IntlMessages id={'page.spvSimple.roofDetails.title'} />
                <hr />
            </h2>
            <div className="roof-details-map">
                <div className="map">
                    <div className="roof-details-map-area">
                        <span className="area-value">{inputs?.area_projectada ? inputs?.area_projectada?.toFixed(2) : '-'}&nbsp;</span>
                        <span className="area-unit">
                            <CustomUnit unit={'sm'} />
                        </span>
                    </div>
                    <Wrapper
                        apiKey={DEFAULTS_PROPS_MAP.settings.apiKey}
                        render={(status) => {
                            return (
                                [Status.LOADING, Status.FAILURE].includes(status) && (
                                    <Skeleton variant="rectangular" animation="wave" height={300} />
                                )
                            );
                        }}
                        version={DEFAULTS_PROPS_MAP.settings.version}
                        id={DEFAULTS_PROPS_MAP.mapOptions.id}
                        language={DEFAULTS_PROPS_MAP.country?.[userLocale].language}
                        region={DEFAULTS_PROPS_MAP.country?.[userLocale].region}
                        libraries={DEFAULTS_PROPS_MAP.settings.libraries}
                    >
                        <SolarpvSimpleMap
                            style={{ height: '300px' }}
                            center={inputs?.center ?? DEFAULTS_PROPS_MAP.country?.default}
                            location={inputs?.center}
                            handleOnChangeMarkerRoof={() => {}}
                            handleOnChangeMarkerAddress={() => {}}
                            handleOnCreatePolygon={() => {}}
                            {...DEFAULTS_PROPS_MAP.mapOptions}
                            zoom={inputs?.center?.zoom ?? DEFAULTS_PROPS_MAP.mapOptions.zoom}
                            zoomControl={false}
                            gestureHandling={'none'}
                        />
                    </Wrapper>
                </div>
                {isDetectRoof && (
                    <Alert severity="info" className="alert">
                        <div className="alert-text">
                            <span>
                                <IntlMessages id="page.spvSimple.roofDetails.alert.1" />
                            </span>
                            <span>
                                <IntlMessages id="page.spvSimple.roofDetails.alert.2.text" whitespaceEnd />
                                <span className="link" onClick={() => dispatch(spvSimpleActions.DRAW_ROOF_MANUALLY, true)}>
                                    <IntlMessages id="page.spvSimple.roofDetails.alert.2.link" />
                                </span>
                                {'.'}
                            </span>
                        </div>
                    </Alert>
                )}
            </div>
            <div className="roof-details-form">
                <div className="input-container">
                    <label className="input-label">
                        <IntlMessages id={questions[INPUT_NAMES.MOUNTING_STRUCTURE_ID].label} />*
                    </label>
                    <Controller
                        name={questions[INPUT_NAMES.MOUNTING_STRUCTURE_ID].name}
                        rules={questions[INPUT_NAMES.MOUNTING_STRUCTURE_ID]?.validation ?? {}}
                        control={control}
                        error={!!errors?.[INPUT_NAMES.MOUNTING_STRUCTURE_ID]}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { onChange, ref, ...field } }) => (
                            <ToggleButtonGroup
                                {...field}
                                value={watch(INPUT_NAMES.MOUNTING_STRUCTURE_ID)}
                                exclusive
                                onChange={(_, newValue) => onChange(newValue)}
                                aria-label="solarpv ROOF_TYPE"
                                className={`radio-with-label`}
                            >
                                {options?.structures?.map((el) => (
                                    <ToggleButton
                                        disabled={watchAllFields?.[INPUT_NAMES.MOUNTING_STRUCTURE_ID] === el.id}
                                        disableRipple
                                        key={`roof-type-${el.id}`}
                                        className={`roof-type-button ${watchAllFields?.[INPUT_NAMES.MOUNTING_STRUCTURE_ID] === el.id ? 'selected' : ''}`}
                                        value={el.id}
                                        aria-label={`${el.id} aligned`}
                                    >
                                        <div className="roof-type-button-icon">
                                            <LazyImage src={getStaticFiles(el.img_name, 'PV/')} alt={`${el.tag}`} />
                                        </div>
                                        <div className="roof-type-button-text">
                                            <IntlMessages id={`label.${el.tag}`} />
                                        </div>
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        )}
                    />
                    {!!errors?.[INPUT_NAMES.MOUNTING_STRUCTURE_ID] && <ErrorMessage error={errors[INPUT_NAMES.MOUNTING_STRUCTURE_ID]} />}
                </div>
            </div>
        </div>
    );
};

export default memo(RoofDetails);
