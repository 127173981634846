import { createContext, useContext, useReducer } from 'react';

// Constants
import { QUESTIONS, RRActions, RRMapActions } from 'constants/products/rr';

// Contexts
import { UserContext } from 'contexts/userContext';

// Hooks
import useMap from 'hooks/products/rr/useMap';

// Interfaces
import { IRRContext, TRRDispatcherValues } from 'interfaces/products/rr';
import { TRRMapDispatcherValues } from 'interfaces/products/rr/map';

// Reducers
import rrMapReducer, { initialRRMapReducerState } from './rrMapReducer';
import { initialRRReducer, rrReducer } from './rrReducer';

const RRContext = createContext<IRRContext>({
    rrState: initialRRReducer,
    rrDispatchHandler: () => {},
    mapState: initialRRMapReducerState,
    mapDispatchHandler: () => {},
    mapFunctions: null,
    questions: QUESTIONS.DEAFULT,
});
RRContext.displayName = 'efz-RRContext';

const RRProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [rrState, rrDispatch] = useReducer(rrReducer, initialRRReducer);
    const [mapState, mapDispatch] = useReducer(rrMapReducer, initialRRMapReducerState);
    const mapFunctions = useMap();

    const rrDispatchHandler = (type: RRActions, payload: TRRDispatcherValues<RRActions>) => {
        rrDispatch({
            type: type,
            payload: payload,
        });
    };

    const mapDispatchHandler = (type: RRMapActions, payload: TRRMapDispatcherValues<RRMapActions>) => {
        mapDispatch({
            type,
            payload,
        });
    };

    const { userTypeID, companyProfileId } = useContext(UserContext);

    const questions = QUESTIONS?.[companyProfileId]?.[userTypeID] ?? QUESTIONS.DEAFULT;

    const rrContextValues: IRRContext = {
        rrState,
        rrDispatchHandler,
        mapState,
        mapDispatchHandler,
        mapFunctions,
        questions,
    };

    return <RRContext.Provider value={rrContextValues}>{children}</RRContext.Provider>;
};

export { RRContext, RRProvider };
