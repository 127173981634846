/* eslint-disable no-unsafe-optional-chaining */
/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 */
import clone from 'fast-copy';
import { orderBy, uniqBy } from 'lodash';
import _maxBy from 'lodash.maxby';
import _minBy from 'lodash.minby';
import { notify } from 'services/@efz/notify';
import { isEnvDevFlag, kpisVisibility } from 'services/settings';

import { UNITS_AVAILABLE } from 'components/util/CustomUnit';
import IntlMessages from 'components/util/IntlMessages';
import { SpvActions } from 'constants/products/solarpv';
import { CostsOptions, CostsTypeOptions, CUSTOMIZE_DUMMY_AREA, RUBICS_BY_CATEGORY } from 'constants/products/spvCustomize';
import {
    BATTERY_INPUT_NAMES,
    DEFAULTS_PROPS_MAP,
    DEFAULT_PANEL_ALIGNMENT,
    DEFAULT_PANEL_ALIGNMENT_VERTICAL,
    DISPATCH_EVT,
    GROUP_INPUTS_NAME,
    INPUTS_NAME_SYS_COMPOSITION,
    KPIS_NPUTS_NAME,
    MODO_KITS_IDS,
    MOUNTING_STRUCTURES_TYPES,
    OPTIMIZATION_PRODUCT_IDS,
    ORIENTATION_DECIMAL_CFG,
    PANEL_GROUP_NAMES,
    PANEL_REPRESENTATION_INPUT_NAMES,
    POWER_LIMIT_MODES_WITH_BLOCKING,
    PRODUCT_LOSSES_CHART_LABELS,
    PROJECT_SUMMARY_KPIS,
    REMOTE_TABS_INPUTS,
    RESET_POLYGON_OPTIONS,
    SETTINGS,
    SETTINGS_PROJECT_SUMMARY,
    SETTINGS_SPV,
    SPV_ERROR_TAGS,
    SYSTEM_COMPOSITION,
    SYSTEM_SETTINGS_GROUPS,
    SYSTEM_SETTINGS_TABS,
} from 'constants/products/spvPro';
import { GRUPO_CANAL_IDS } from 'constants/user';
import { getCompanyProfileIds } from 'services/user';
import {
    generateRandomString,
    getNextID,
    intlMessages,
    isDefined,
    isEmpty,
    isEmptyArray,
    isFieldDefined,
    isNumberDefined,
    mathRadians,
    mathRound,
    parseBoolean,
    removeEmptyInObj,
    removeFieldEmptyInObj,
} from 'services/util/auxiliaryUtils';
import { optimizerPayloadSchema, simulationPayloadSchema } from 'schemas/products/spv';
import { useFeatureFlags } from 'store/featureFlags';

//Get payload
export function getPayload(inputs, options) {
    const { facilityID, productID } = options;
    let extraObject = {};
    if (inputs.is_power_increase) {
        extraObject = {
            is_power_increase: inputs.is_power_increase,
        };
    } else {
        delete inputs.is_power_increase;
    }
    return {
        facility: { id: facilityID },
        inputs: clone(inputs),
        is_scaling: true,
        tipo_produto_id: productID,
        ...extraObject,
    };
}

/* ******** START SETTINGS SPV ******* */

/**
 * calcRegionId
 * @param {*} regions
 * @param {*} lat
 * @param {*} lg
 */
export function calcRegionId(regions, lat, lg) {
    if (regions.length === 0) return;

    const MALHA_MATRIZ = SETTINGS_SPV.MALHA_MATRIZ; //1.5
    const MAX_DISTANCE_IN_KMS = SETTINGS_SPV.MAX_DISTANCE_IN_KMS; //25km
    lat = parseFloat(lat);
    lg = parseFloat(lg);

    let lat_up = (Math.ceil((lat / MALHA_MATRIZ) * 10) / 10) * MALHA_MATRIZ;
    let lat_down = (Math.floor((lat / MALHA_MATRIZ) * 10) / 10) * MALHA_MATRIZ;
    let lg_up = (Math.ceil((lg / MALHA_MATRIZ) * 10) / 10) * MALHA_MATRIZ;
    let lg_down = (Math.floor((lg / MALHA_MATRIZ) * 10) / 10) * MALHA_MATRIZ;

    let pt_lat = lat - lat_down < lat_up - lat ? lat_down : lat_up;
    let pt_lg = Math.abs(lat_down - lg_down) < Math.abs(lg_up - lg) ? lg_down : lg_up;

    pt_lat = mathRound(pt_lat, 2);
    pt_lg = mathRound(pt_lg, 2);

    let region_gd_distances_arr = regions?.map(function (x) {
        //x.distance = calcEuclideanDistance(pt_lat, x.latitude, pt_lg, x.longitude);
        x.distance_in_kms = calcCrow(pt_lat, pt_lg, x.latitude, x.longitude);
        return x;
    });

    let min_distance_kms_region_gd = region_gd_distances_arr?.reduce(function (prev, curr) {
        return prev.distance_in_kms < curr.distance_in_kms ? prev : curr;
    });

    return min_distance_kms_region_gd?.distance_in_kms < MAX_DISTANCE_IN_KMS ? parseInt(min_distance_kms_region_gd?.id) : null;
}

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export function calcCrow(lat1, lon1, lat2, lon2) {
    let RADIUS_EARTH = 6371; // km
    let dLat = toRad(lat2 - lat1);
    let dLon = toRad(lon2 - lon1);
    let lat1InRad = toRad(lat1);
    let lat2InRad = toRad(lat2);

    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1InRad) * Math.cos(lat2InRad);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = RADIUS_EARTH * c;
    return d;
}

// Converts numeric degrees to radians
const toRad = (Value) => (Value * Math.PI) / 180;

//Get Center of the polygon
const polygonCenter = function (polygon) {
    var bounds = new mainGoogle.maps.LatLngBounds();
    polygon?.getPath().forEach((e) => bounds.extend(e));
    return bounds?.getCenter();
};

export const mapCenter = (polygons) => {
    var bounds = new mainGoogle.maps.LatLngBounds();

    for (var i = 0; i < polygons.length; i++) {
        for (var j = 0; j < polygons[i].length; j++) {
            bounds.extend(polygons[i][j]);
        }
    }

    exclusionsMap.fitBounds(bounds);
    exclusionsMap.setCenter(bounds.getCenter());
};

export const getPayloadDAExclusions = (gMaxZoom, polygon, area) => {
    try {
        let boundsPolygon = new mainGoogle.maps.LatLngBounds();

        polygon.getPath().forEach(function (path) {
            boundsPolygon.extend(path);
        });
        exclusionsMap.fitBounds(boundsPolygon);
        exclusionsMap.setCenter(boundsPolygon.getCenter());
        exclusionsMap.setZoom(gMaxZoom);
        let containes = false;
        let maxZoom = gMaxZoom;
        while (!containes && maxZoom > 15) {
            // eslint-disable-next-line no-loop-func
            containes = polygon
                .getPaths()
                .getArray()
                .every(function (path) {
                    return path.getArray().every(function (coord) {
                        return exclusionsMap.getBounds().contains(coord);
                    });
                });
            maxZoom = !containes ? maxZoom - 1 : maxZoom;
            if (!containes) exclusionsMap.setZoom(maxZoom);
        }

        return removeEmptyInObj({
            center: {
                lat: boundsPolygon.getCenter().lat(),
                lng: boundsPolygon.getCenter().lng(),
                zoom: maxZoom,
            },
            image_name: `exclusions-${polygon.id}-${new Date().getTime()}.png`,
            roof: [area?.coordinates],
            angle: area?.aspect ?? null,
        });
    } catch (error) {
        // TODO: ADD SENTRY
        console.log('efz -> error', error);
    }
};

//Get RegionID
export function getRegionGDIdAndPointsPolygon(polygon, regionsGD) {
    let polygon_points = [];
    let avg = {
        lat: 0.0,
        long: 0.0,
        region_gd_id: null,
    };

    polygon
        .getPath()
        .getArray()
        .forEach(function (el) {
            let point = { lat: el.lat(), lng: el.lng() };
            //sum lat and lng
            avg.lat = avg.lat + el.lat();
            avg.long = (avg.lng ? avg.lng : avg.long) + el.lng();
            //save points
            polygon_points.push(point);
        });

    //avg lat and lng
    avg.lat = avg.lat / polygon_points.length;
    avg.long = (avg.lng ? avg.lng : avg.long) / polygon_points.length;
    avg.region_gd_id = calcRegionId(regionsGD, avg.lat, avg.lng ? avg.lng : avg.long);

    return {
        region_gd_id: avg.region_gd_id,
        lat: avg.lat,
        long: avg.long,
        coordinates: polygon_points,
        coordinates_avg: avg,
    };
}

/**
 * calcGlobalAvgGroupCoordinates
 * calc global average of group coordinates
 * @private
 *
 * @param {*} groups
 * @param {*} regionsGD
 */
export function calcGlobalAvgGroupCoordinates(groups = [], regionsGD = []) {
    var avg = {
        lat: 0,
        long: 0,
        region_gd_id: null,
    };

    // sum of lat and long
    groups.map((group) => {
        avg.lat += group.lat;
        avg.long += group.long;
        return group;
    });

    //avg lat and lng
    let nGroups = groups.length;
    avg.lat = avg.lat / nGroups;
    avg.long = avg.long / nGroups;

    // calc region_gd_id
    avg.region_gd_id = calcRegionId(regionsGD, avg.lat, avg.long);

    return avg;
}

export const getPolygonCoordinates = (polygon) => {
    let coordinates = [];

    polygon
        .getPath()
        .getArray()
        .forEach(function (el) {
            let point = [el.lat(), el.lng()];

            //save points
            coordinates.push(point);
        });

    return coordinates;
};

/* ******** END SETTINGS SPV ******* */

/* ******** START GMAP - SETTINGS || PROPS ******* */
let mainMap;
let mainGoogle;
let mainDrawingManager;
let mainIsEditOrientation;
let exclusionAreasDrawingManager;
let mainDrag;
let mainMapRef;
let polygonToCopy;
let maxZoomService;
let mainTabsOrientations = [];
let polylineDrawingManager;

//Init Map
export function INIT_MAP(
    mapProps,
    map,
    inputsData,
    polygonGroups,
    exclusionZonesPolygons,
    mapRef,
    setDispatchEvt,
    setIsOpenMap,
    setHasChangesInExclusions,
    setZoom
) {
    const { google } = mapProps;
    maxZoomService = new google.maps.MaxZoomService();
    //Set Map Object
    mainMap = map;
    //Set Google Object
    mainGoogle = google;
    mainMapRef = mapRef;
    // mainInfowindow = new google.maps.InfoWindow();
    //Set InfoWindow Object
    mainDrawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        map: mainMap,
    });
    //Drawing manager for the exclusion areas
    exclusionAreasDrawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        map: mainMap,
    });

    //Drawing manager for polylines (group split)
    polylineDrawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYLINE],
        },
        map: mainMap,
    });

    //Set Tilt / forceMaxZoom / zoom_changed
    mainMap.setTilt(DEFAULTS_PROPS_MAP.mapOptions.tilt);
    google.maps.event.addListenerOnce(mainMap, 'idle', function () {
        var zoomRangeModifier = mainMap.__proto__.__proto__.__proto__;
        var originalSetFunc = zoomRangeModifier.set;
        var hijackedSetFunc = function (name, value) {
            if (name === 'maxZoom') {
                value = 22; //forceMaxZoom
                setZoom({
                    current: mainMap.getZoom(),
                    max: value,
                });
                mainMap.maxZoom = value;
            }
            originalSetFunc.call(this, name, value);
        };
        zoomRangeModifier.set = hijackedSetFunc;
    });

    google.maps.event.addListener(mainMap, 'zoom_changed', function () {
        let zoom = mainMap.getZoom();
        let maxZoom = mainMap?.maxZoom ?? 19;

        maxZoomService.getMaxZoomAtLatLng(mainMap.getCenter(), function (response) {
            let maxZoomAtLatLng = response.zoom + 2;
            if (maxZoomAtLatLng < zoom) {
                mainMap.setZoom(maxZoomAtLatLng);
                // let message = `GMAP forceMaxZoom | (Sorry, we have no imagery here)`;
                // SentryCaptureException({
                //     level: 4,
                //     message,
                //     fingerprint: message,
                //     extrasContext: {
                //         maxZoomAtLatLng,
                //         clientZoom: zoom,
                //         centroidMap: {
                //             lat: mainMap.getCenter().lat(),
                //             lng: mainMap.getCenter().lng()
                //         },
                //     },
                //     tags: {
                //         GMAP_event: 'zoom_changed',
                //     }
                // });
            }
        });

        //#region orientation show/hide
        let hideOrientationTab = maxZoom - 3;
        // orientation hide
        if (zoom <= hideOrientationTab && mainTabsOrientations?.length > 0 && mainTabsOrientations?.[0]?.getVisible()) {
            mainTabsOrientations.forEach((el) => {
                el.setVisible(false);
            });
        }
        if (zoom > hideOrientationTab && mainTabsOrientations?.length > 0 && !mainTabsOrientations?.[0]?.getVisible()) {
            mainTabsOrientations.forEach((el) => {
                el.setVisible(true);
            });
        }
        //#endregion
    });

    // Add events listeners to map
    handleAddMapListeners(setDispatchEvt, exclusionZonesPolygons, setHasChangesInExclusions);

    // PANELS GROUP \\
    mainGoogle.maps.event.addListener(mainDrawingManager, 'polygoncomplete', (polygon) => {
        //Quando o utilizador desenha um elemento que não é um polígono
        let groupArea = mainGoogle.maps.geometry.spherical.computeArea(polygon.getPath());
        if (groupArea === 0 || polygon?.getPath()?.getArray()?.length === 2) {
            polygon.setMap(null);
            return notify(<IntlMessages id="page.spv.error.drawing" />, 'error');
        }

        // Get the current main polygon
        let mainPath = rewindRing(polygon.getPath().getArray(), true);
        //Quando o polígono foi criado
        let id = getNextID(polygonGroups, 0, 'id');
        polygon.setOptions({
            ref: mapRef,
            path: new mainGoogle.maps.MVCArray(mainPath),
            id,
            editable: true,
            draggable: true,
            zIndex: 1,
            strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.strokeOpacity,
            strokeWeight: DEFAULTS_PROPS_MAP.polygon.styles.strokeWeight,
            fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
            fillColor: DEFAULTS_PROPS_MAP.polygon.styles.fillColor.selected,
            strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected,
        });

        handleAddPolygonListeners(polygon, polygonGroups, setDispatchEvt);

        setDispatchEvt({
            evt: DISPATCH_EVT.NEW_GROUP,
            groupId: id,
            values: {
                polygon,
                centroid: getCentroidLine(polygon),
            },
        });
    });

    mainDrawingManager.setMap(mainMap);

    // EXCLUSION ZONE \\
    mainGoogle.maps.event.addListener(exclusionAreasDrawingManager, 'polygoncomplete', (polygon) => {
        // Deselect all other exclusion zones
        handlerDeselectAllPanelGroups(exclusionZonesPolygons, setDispatchEvt);

        // Get the current main polygon
        let mainPath = rewindRing(polygon.getPath().getArray(), true);

        // When the polygon is created
        let id = getNextID(exclusionZonesPolygons, 0, 'id');
        let isPolygon = polygon.getPath().getArray()?.length > 2;

        let isPoint = mainPath?.length === 1;
        let strokeWeight = isPoint ? 6 : DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeWeight;
        if (isPoint) {
            mainPath.push(new mainGoogle.maps.LatLng(mainPath[0].lat(), mainPath[0].lng()));
        }

        // get area standard panel characteristics with polygon
        polygon.setOptions({
            ref: mapRef,
            path: new mainGoogle.maps.MVCArray(mainPath),
            id,
            area: getGroupAreaByPolygon(polygon),
            isPolygon,
            isPoint,
            coordinates: isPolygon ? getPolygonCoordinates(polygon) : getLineCoordinates(polygon),
            selected: false,
            editable: false,
            draggable: false,
            visible: true,
            zIndex: 20,
            strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeOpacity,
            strokeWeight: strokeWeight,
            strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.notSelected,
            fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillOpacity,
            fillColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.notSelected,
        });

        handleAddExclusionPolygonListeners(polygon, exclusionZonesPolygons, setDispatchEvt, setHasChangesInExclusions);

        setHasChangesInExclusions(true);

        setDispatchEvt({
            evt: DISPATCH_EVT.EXCLUSION_MAP_POLYGONCOMPLETE,
            groupId: id,
            values: null,
        });
    });

    // Build panel groups from inputs data
    if (inputsData?.areas && inputsData.areas.length > 0) {
        inputsData.areas.map((group, idx) => {
            let isSelected = idx === 0;
            let polygon = new google.maps.Polygon({
                ref: mapRef,
                id: group.id,
                zIndex: 1,
                paths: [group.coordinates],
                editable: isSelected,
                draggable: true,
                strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.strokeOpacity,
                strokeWeight: DEFAULTS_PROPS_MAP.polygon.styles.strokeWeight,
                fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
                fillColor:
                    isSelected ?
                        DEFAULTS_PROPS_MAP.polygon.styles.fillColor.selected
                    :   DEFAULTS_PROPS_MAP.polygon.styles.fillColor.notSelected,
                strokeColor:
                    isSelected ?
                        DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected
                    :   DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.notSelected,
            });

            handleAddPolygonListeners(polygon, polygonGroups, setDispatchEvt, true /* , tabsOrientation */);
            return group;
        });

        // center e zoom on mounting
        if (polygonGroups.length > 0) handleCenterPolygon(polygonGroups.find((item) => item?.visible)?.id, polygonGroups);
    }

    // Build exclusion zones from inputs data
    if (inputsData?.exclusions && inputsData.exclusions.length > 0) {
        inputsData.exclusions.forEach((exclusion) => {
            let coordinates = [];

            if (exclusion?.id && exclusion?.coordinates?.length > 0) {
                let polygon = null;
                let isPoint =
                    !exclusion?.coordinates?.[2] ||
                    !exclusion?.coordinates?.[2][0] ||
                    exclusion?.coordinates?.[0]?.every((el) => exclusion?.coordinates?.[2]?.includes(el));
                if (!exclusion.isPolygon) {
                    //Line
                    coordinates = [
                        new google.maps.LatLng(exclusion.coordinates[0][0], exclusion.coordinates[0][1]),
                        new google.maps.LatLng(exclusion.coordinates[1][0], exclusion.coordinates[1][1]),
                        exclusion.coordinates[2][0] ?
                            new google.maps.LatLng(exclusion.coordinates[2][0], exclusion.coordinates[2][1])
                        :   new google.maps.LatLng(exclusion.coordinates[1][0], exclusion.coordinates[1][1]),
                    ];
                } else {
                    for (let i = 0; i < exclusion?.coordinates?.length; i++) {
                        coordinates[i] = new google.maps.LatLng(exclusion.coordinates[i][0], exclusion.coordinates[i][1]);
                    }
                }
                polygon = new google.maps.Polygon({
                    ...exclusion,
                    ref: mapRef,
                    id: exclusion.id,
                    isPoint,
                    zIndex: 20,
                    paths: [coordinates],
                    clickable: false,
                    visible: true,
                    editable: false,
                    draggable: false,
                    strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeOpacity,
                    strokeWeight: isPoint ? 6 : DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeWeight, //HEARE apicamos o tamanho da linha
                    fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillOpacity,
                    fillColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.notSelected,
                    strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.notSelected,
                });

                handleAddExclusionPolygonListeners(polygon, exclusionZonesPolygons, setDispatchEvt, setHasChangesInExclusions);
            }
        });
    }

    // Polyline (Split group)
    mainGoogle.maps.event.addListener(polylineDrawingManager, 'polylinecomplete', (polyline) => {
        // if (polyline.getPath().getArray()?.length !== 2) {
        //     notify(<IntlMessages id='page.spvPro.split.error.twoPoints' />, 'error')
        //     polyline.setMap(null)
        //     return
        // }

        polyline.setOptions({
            clickable: false,
            draggable: false,
            editable: false,
            strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.strokeOpacity,
            strokeWeight: DEFAULTS_PROPS_MAP.polygon.styles.strokeWeight,
            strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.selected,
            visible: true,
        });

        setDispatchEvt({
            evt: DISPATCH_EVT.SPLIT_POLYLINE_COMPLETE,
            values: { polyline },
        });
    });

    /**
     * docs:
     * - @deprecate: https://developers.google.com/maps/documentation/javascript/reference/event#event.addDomListener
     * - use: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
     */
    // if (isFieldDefined(document?.getElementById("gmap-section"))) {@disabled pq no autocomplete dispara o evento
    //     document.getElementById("gmap-section").addEventListener('keydown', e => {
    //         if (['Delete', 'Backspace'].includes(e.key)) {
    //             setDispatchEvt({
    //                 evt: DISPATCH_EVT.KEYCODE_DELETE_SELECTED_POLYGON,
    //                 groupId: null,
    //                 values: {
    //                     isGroup: isDefined(polygonGroups?.find(el => (isDefined(el.id) && el.editable && el?.visible)))
    //                 }
    //             });
    //         }
    //     });
    // }
    mainMap.setZoom(DEFAULTS_PROPS_MAP.mapOptions.zoom);
    setIsOpenMap(true);
}

//Init Map

let exclusionsMap;
let exclusionsDrawingManager;
let visibleAreasToExclude = [];
export function INIT_AUTO_EXCLUSIONS_MAP(mapProps, map, inputsData, ref, setMapLoaded) {
    const { google } = mapProps;
    exclusionsMap = map;
    // Set Map Ref
    // mainInfowindow = new google.maps.InfoWindow();
    //Set InfoWindow Object

    exclusionsDrawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        map: exclusionsMap,
    });

    // let bounds = new google.maps.LatLngBounds();

    // polygonGroups = polygonGroups.filter(polygon => isDefined(polygon?.id)); // It's here when we delete area
    // for (let i = 0; i < polygonGroups.length; i++) {
    //     polygonGroups[i].getPath().forEach(function (path, index) {
    //         bounds.extend(path);
    //     });
    //     exclusionsMap.fitBounds(bounds);
    // }

    //Set Tilt
    exclusionsMap.setTilt(DEFAULTS_PROPS_MAP.mapOptions.tilt);

    exclusionsDrawingManager.setMap(exclusionsMap);
    handleAddExclusionMapListeners(setMapLoaded, inputsData?.areas);
}

function rewindRing(ring, dir) {
    var area = 0;

    // Area is positive if polygon was drawn clockwise
    for (var i = 0, len = ring.length, j = len - 1; i < len; j = i++) {
        area += (ring[i].lng() - ring[j].lng()) * (ring[j].lat() + ring[i].lat());
    }

    if (area >= 0 === dir) ring.reverse();
    return ring;
}

export function calcPerimeterArea(polygon) {
    let paths = clone(polygon.getPath().getArray());
    paths.push(paths[0]);
    return mainGoogle.maps.geometry.spherical.computeLength(paths);
}

export function calcPerimeterAreaFromCoords(coordinates) {
    if (!isFieldDefined(mainGoogle)) return;

    const polygon = new mainGoogle.maps.Polygon({
        paths: [coordinates],
    });
    let paths = clone(polygon.getPath().getArray());
    paths.push(paths[0]);
    return mainGoogle.maps.geometry.spherical.computeLength(paths);
}

export const handleDrawPanels = (usefulAreaData, areas, drawnPanels, maxTecPanels, isEditingExclusions) => {
    let areasPanelsSum = areas.reduce((acc, area) => acc + area.panels_number_possible, 0);
    let invalidPanelColor =
        maxTecPanels >= areasPanelsSum ?
            DEFAULTS_PROPS_MAP.polygon.styles.fillColor.panelNotSelected
        :   DEFAULTS_PROPS_MAP.polygon.styles.fillColor.panelInvalid;

    // Remove previous panels from map
    drawnPanels?.map((panel) => {
        panel.setMap(null);

        return panel;
    });

    let allPanels = [];

    if (usefulAreaData?.length > 0 && isDefined(mainGoogle)) {
        usefulAreaData.map((data, aIdx) => {
            // row of panels
            data.panel_rows.forEach((row, rIdx) => {
                // each panel
                row.positions.forEach((panel, pIdx) => {
                    let paths = [];
                    panel.forEach((p) => {
                        paths.push({
                            lat: p[0],
                            lng: p[1],
                        });
                    });

                    let isSelected = areas?.find((el) => el.selected)?.id === data.id;
                    let isValid = row.valid_panels?.[pIdx] ?? true;

                    // options
                    let strokeColor =
                        isEditingExclusions ?
                            DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.panelNotSelected
                        :   DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.panelSelected;
                    let strokeWeight = 0.5;
                    let strokeOpacity = DEFAULTS_PROPS_MAP.polygon.styles.strokeOpacity;
                    let fillColor = isValid ? DEFAULTS_PROPS_MAP.polygon.styles.fillColor.panelSelected : invalidPanelColor;
                    let fillOpacity = isEditingExclusions ? 0.3 : 1;
                    let zIndex = 10;

                    let polygon = new mainGoogle.maps.Polygon({
                        map: mainMap,
                        key: generateRandomKeyPanels(aIdx, rIdx, pIdx),
                        areaID: data.id,
                        selected: isSelected,
                        isValid,
                        clickable: false,
                        editable: false,
                        dragable: false,
                        path: paths,
                        strokeOpacity,
                        strokeWeight,
                        fillOpacity,
                        fillColor,
                        strokeColor,
                        zIndex,
                    });

                    allPanels.push(polygon);
                });
            });

            return data;
        });
    }

    return allPanels;
};

function handleAddMapListeners(setDispatchEvt, exclusionZonesPolygons, setHasChangesInExclusions) {
    //Click no mapa (sem usar)
    mainMap.addListener('click', (mapsMouseEvent) => {
        if (mainIsEditOrientation) return;

        if (!polygonToCopy) {
            setDispatchEvt({
                evt: DISPATCH_EVT.SET_DESELECT_ALL,
                groupId: null,
                values: null,
            });
        } else {
            const newPolygonCentroid = {
                lat: mapsMouseEvent.latLng.lat(),
                lng: mapsMouseEvent.latLng.lng(),
            };
            advancedDuplicationHandler(newPolygonCentroid, setDispatchEvt, exclusionZonesPolygons, setHasChangesInExclusions);
        }
        // close infowindow
    });

    mainMap.addListener('drag', () => {
        // close infowindow
    });
}

function handleAddExclusionMapListeners(setMapLoaded, areas) {
    maxZoomService.getMaxZoomAtLatLng(exclusionsMap.getCenter(), function (response) {
        let maxZoomAtLatLng = response.zoom + 2;
        exclusionsMap.setZoom(maxZoomAtLatLng);
        mainGoogle.maps.event.addListenerOnce(exclusionsMap, 'idle', function () {
            setMapLoaded(response?.zoom + 1 ?? 19, areas);
        });
    });
}

/**
 * handleAddPolygonListeners
 *
 * @see Gmap events https://developers.google.com/maps/documentation/javascript/events
 * @param {*} polygon
 * @param {*} polygonGroups
 */
function handleAddPolygonListeners(
    polygon,
    polygonGroups,
    // fnc
    setDispatchEvt,
    isMounting = false
) {
    // Quando selecionamos o polygon
    mainGoogle.maps.event.addListener(polygon, 'click', () => {
        handleSelectPolygon(
            polygon.id,
            polygonGroups,
            //fnc
            setDispatchEvt
        );
    });

    //Quando começa a arrastar a zona
    polygon.addListener('dragstart', () => {
        mainDrag = true;
    });

    //Quando acaba de arrastar a zona
    polygon.addListener('dragend', () => {
        mainDrag = false;

        setDispatchEvt({
            evt: DISPATCH_EVT.SET_MAP_DRAGEND,
            groupId: polygon.id,
            values: {
                polygon,
            },
        });
    });

    //Quando é adicionado uma novo vertex no polygon selecionado
    mainGoogle.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
        // set polygonGroups
        polygonGroups = polygonGroups.filter((poly) => poly.id !== polygon.id);
        polygonGroups.push(polygon);

        setDispatchEvt({
            evt: DISPATCH_EVT.SET_MAP_INSERT_AT,
            groupId: polygon.id,
            values: {
                polygon,
            },
        });
    });

    //Quando editamos o polygon (selecionado com o click do mouse)
    mainGoogle.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        if (!mainDrag) {
            // set polygonGroups
            polygonGroups = polygonGroups.filter((poly) => poly.id !== polygon.id);
            polygonGroups.push(polygon);

            setDispatchEvt({
                evt: DISPATCH_EVT.SET_MAP_SET_AT,
                groupId: polygon.id,
                values: {
                    polygon,
                },
            });
        }
    });

    //Quando criamos uma nova vertex e clicamos o undo "retroceder" no polygon selecionado
    mainGoogle.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
        // set polygonGroups
        polygonGroups = polygonGroups.filter((poly) => poly.id !== polygon.id);
        polygonGroups.push(polygon);

        setDispatchEvt({
            evt: DISPATCH_EVT.SET_MAP_REMOVE_AT,
            groupId: polygon.id,
            values: {
                polygon,
            },
        });
    });

    // remover o poligono selecionado.
    // se não vier com o isSelected desabilitado (split shape)
    if (!isFieldDefined(polygon?.isSelected) || (isFieldDefined(polygon?.isSelected) && polygon?.isSelected)) {
        if (polygonGroups.length > 0) {
            // colocar o polygon editavel e selecionado
            polygonGroups.map((polygon) => {
                if (polygon?.map && !isMounting) {
                    polygon.setOptions({
                        strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.notSelected,
                        fillColor: DEFAULTS_PROPS_MAP.polygon.styles.fillColor.notSelected,
                        editable: false,
                    });
                }
                return polygon;
            });
        }
    }

    // add polygon on Map
    polygon.setMap(mainMap);
    polygonGroups.push(polygon);
}

/**
 * handleSelectPolygon
 *
 * @param {*} polygon
 * @param {*} polygonGroups
 */
export function handleSelectPolygon(polygonID, polygonGroups, setDispatchEvt) {
    if (mainIsEditOrientation || typeof mainDrawingManager?.setOptions !== 'function') return;

    // Set map drawing mode off
    mainDrawingManager.setOptions({
        drawingMode: null,
    });

    // select polygon on map
    let polygon = polygonGroups.find((item) => item.id === polygonID);

    if (!polygon) return;

    polygonGroups.forEach((group) => {
        let isSelected = polygonID === group.id;
        group.setOptions({
            editable: isSelected,
            strokeColor:
                isSelected ?
                    DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected
                :   DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.notSelected,
            fillColor:
                isSelected ? DEFAULTS_PROPS_MAP.polygon.styles.fillColor.selected : DEFAULTS_PROPS_MAP.polygon.styles.fillColor.notSelected,
        });
    });

    // Center map on polygon
    mainMap.panTo(polygonCenter(polygon));

    // Trigger an inputs state update
    setDispatchEvt({
        evt: DISPATCH_EVT.SET_SELECT_GROUP,
        groupId: polygonID,
        values: {
            polygon,
        },
    });
}

/**
 * handleCenterPolygon
 *
 * @param {*} polygon
 * @param {*} polygonsCurrent
 */
export function handleCenterPolygon(polygonID, polygonsCurrent) {
    // Set map drawing mode off
    mainDrawingManager.setOptions({
        drawingMode: null,
    });

    // select polygon on map
    let polygon = polygonsCurrent.find((item) => item.id === polygonID);

    if (!polygon) return;

    // Center map on polygon
    mainMap.setZoom(DEFAULTS_PROPS_MAP.mapOptions.zoom);
    mainMap.panTo(polygonCenter(polygon));
}

//#region orientations handlers

function getCentroidLine(line) {
    let bounds = new mainGoogle.maps.LatLngBounds();
    line?.getPath().forEach((e) => bounds.extend(e));
    return bounds.getCenter();
}

const areEdgePoints = (coordinates, edgesDS, isLastPoint) => {
    return (
        coordinates.point1.lat === edgesDS.points[isLastPoint ? 1 : 0].lat &&
        coordinates.point1.lng === edgesDS.points[isLastPoint ? 1 : 0].lng &&
        coordinates.point2.lat === edgesDS.points[isLastPoint ? 0 : 1].lat &&
        coordinates.point2.lng === edgesDS.points[isLastPoint ? 0 : 1].lng
    );
};

export function handleSetOrientation(area, polygonGroups, spvEventDispatchHandler, zoom) {
    let min_distance_kms_segment = 2.5; //meter
    mainMap.setZoom(zoom.max - 2);
    mainIsEditOrientation = true;

    let { id, orientation: areaOrientation, edges } = area;
    let polygon = polygonGroups?.find((poly) => poly?.id === id);
    mainMap.panTo(polygonCenter(polygon));

    polygon.setOptions({
        strokeColor: '#3885CD',
        fillColor: '#DCD586',
        strokeOpacity: 1,
        strokeWeight: 3,
        fillOpacity: 1,
        editable: false,
        draggable: false,
    });

    //Create the svg marker icon
    let markerId = -1;
    for (let idx = 0; idx < area.coordinates.length; idx++) {
        markerId++;
        let rootPoint = 0;
        let isLastPoint = idx === area.coordinates.length - 1;
        let point1 = area.coordinates[isLastPoint ? rootPoint : idx];
        let point2 = area.coordinates[isLastPoint ? idx : idx + 1];
        const edge = edges?.find((edge) => areEdgePoints({ point1, point2 }, edge, isLastPoint));

        let startPoint = new mainGoogle.maps.LatLng(point1);
        let endPoint = new mainGoogle.maps.LatLng(point2);
        let distance = mainGoogle.maps.geometry.spherical.computeDistanceBetween(startPoint, endPoint);

        if (Math.round(distance) <= min_distance_kms_segment) {
            continue;
        }
        const line = new mainGoogle.maps.Polyline({
            id: markerId,
            isline: true,
            path: [startPoint, endPoint],
            map: mainMap,
            fillColor: '#3885CD',
            strokeColor: '#3885CD',
            fillOpacity: 1,
            strokeWeight: 3,
            zIndex: 100,
        });
        mainTabsOrientations.push(line);
        let centroid = getCentroidLine(line);
        // eslint-disable-next-line no-unsafe-optional-chaining
        let orientation = isFieldDefined(edge?.aspect) ? parseFloat((edge?.aspect).toFixed(ORIENTATION_DECIMAL_CFG.decimal)) : 0;
        let isLineOrientation = areaOrientation === orientation;
        let iconCfg = {
            scale: 0.8,
            origin: new mainGoogle.maps.Point(0, 0),
            anchor: new mainGoogle.maps.Point(15, 25),
            rotation: -180 + orientation,
        };

        //#region Create Aresta/Tab
        let tabBackgroudUnSelected = new mainGoogle.maps.Marker({
            id: markerId,
            isBackgroud: true,
            isBGSelected: false,
            selected: false,
            position: centroid,
            map: mainMap,
            clickable: true,
            orientation,
            icon: {
                ...DEFAULTS_PROPS_MAP.oMarker.backgroundUnselected,
                ...iconCfg,
            },
        });

        mainGoogle.maps.event.addListener(tabBackgroudUnSelected, 'click', () => {
            tabBackgroudUnSelected.selected = !tabBackgroudUnSelected.selected;
            tabBackgroudUnSelected.clickable = false;
            let isSelected = clone(tabBackgroudUnSelected.selected);
            handleSetOTab(tabBackgroudUnSelected.id, isSelected);
            spvEventDispatchHandler(SpvActions.SET_ORIENTATION, {
                groupId: polygon?.id,
                [GROUP_INPUTS_NAME.ORIENTATION]: parseFloat(orientation),
                evt: DISPATCH_EVT.SET_ORIENTATION,
            });
        });
        mainTabsOrientations.push(tabBackgroudUnSelected);

        let tabBackgroudSelected = new mainGoogle.maps.Marker({
            id: markerId,
            isBackgroud: true,
            isBGSelected: true,
            position: centroid,
            selected: false,
            map: mainMap,
            clickable: true,
            orientation,
            icon: {
                ...DEFAULTS_PROPS_MAP.oMarker.backgroundSelected,
                ...iconCfg,
            },
        });

        mainGoogle.maps.event.addListener(tabBackgroudSelected, 'click', () => {
            tabBackgroudSelected.selected = !tabBackgroudSelected.selected;
            tabBackgroudSelected.clickable = false;
            let isSelected = clone(tabBackgroudSelected.selected);
            handleSetOTab(tabBackgroudSelected.id, isSelected);
            spvEventDispatchHandler(SpvActions.SET_ORIENTATION, {
                groupId: polygon?.id,
                [GROUP_INPUTS_NAME.ORIENTATION]: parseFloat(orientation),
                evt: DISPATCH_EVT.SET_ORIENTATION,
            });
        });

        if (isLineOrientation) {
            line.setOptions({
                fillColor: '#01173D',
                strokeColor: '#01173D',
                selected: true,
                clickable: false,
            });
        } else {
            tabBackgroudSelected.setMap(null);
        }
        mainTabsOrientations.push(tabBackgroudSelected);

        let tabArrow = new mainGoogle.maps.Marker({
            id: markerId,
            isBackgroud: false,
            selected: false,
            position: centroid,
            map: mainMap,
            clickable: true,
            orientation,
            icon: {
                ...DEFAULTS_PROPS_MAP.oMarker.arrow,
                ...iconCfg,
            },
        });
        mainGoogle.maps.event.addListener(tabArrow, 'click', () => {
            tabArrow.selected = !tabArrow.selected;
            tabArrow.clickable = false;
            let isSelected = clone(tabArrow.selected);
            handleSetOTab(tabArrow.id, isSelected);

            spvEventDispatchHandler(SpvActions.SET_ORIENTATION, {
                groupId: polygon?.id,
                [GROUP_INPUTS_NAME.ORIENTATION]: parseFloat(orientation),
                evt: DISPATCH_EVT.SET_ORIENTATION,
            });
        });
        mainTabsOrientations.push(tabArrow);
        //#endregion Create Aresta/Tab
    }
}

function handleSetOTab(markerId, isSelected) {
    let isChange = false;
    for (let i = 0; i < mainTabsOrientations.length; i++) {
        if (markerId === mainTabsOrientations[i].id && !isChange) {
            mainTabsOrientations[i].setOptions({
                fillColor: '#01173D',
                strokeColor: '#01173D',
                selected: true,
                clickable: false,
            });

            if (
                mainTabsOrientations?.[i + 1]?.setMap instanceof Function &&
                mainTabsOrientations?.[i + 2]?.setMap instanceof Function &&
                mainTabsOrientations?.[i + 3]?.setMap instanceof Function
            ) {
                mainTabsOrientations[i + 1].setMap(null);
                mainTabsOrientations[i + 1].clickable = false;
                mainTabsOrientations[i + 1].selected = true;
                mainTabsOrientations[i + 2].setMap(null);
                mainTabsOrientations[i + 2].clickable = false;
                mainTabsOrientations[i + 2].selected = true;
                mainTabsOrientations[i + 3].setMap(null);
                mainTabsOrientations[i + 3].clickable = false;
                mainTabsOrientations[i + 3].selected = true;

                mainTabsOrientations[i + 1].setMap(isSelected ? mainMap : null);
                mainTabsOrientations[i + 2].setMap(mainMap);
                mainTabsOrientations[i + 3].setMap(mainMap);
                isChange = true;
            }
        }

        let isChangeSelected = false;
        if (markerId !== mainTabsOrientations[i].id && mainTabsOrientations[i].selected && !isChangeSelected) {
            mainTabsOrientations[i].setOptions({
                fillColor: '#3885CD',
                strokeColor: '#3885CD',
                selected: false,
                clickable: false,
            });
            if (mainTabsOrientations[i + 1].setMap instanceof Function) {
                mainTabsOrientations[i + 1].setMap(null);
                mainTabsOrientations[i + 1].clickable = true;
                mainTabsOrientations[i + 1].selected = false;
                mainTabsOrientations[i + 2].setMap(null);
                mainTabsOrientations[i + 2].clickable = true;
                mainTabsOrientations[i + 2].selected = false;
                mainTabsOrientations[i + 3].setMap(null);
                mainTabsOrientations[i + 3].clickable = true;
                mainTabsOrientations[i + 3].selected = false;

                mainTabsOrientations[i + 1].setMap(mainMap);
                mainTabsOrientations[i + 2].setMap(null);
                mainTabsOrientations[i + 3].setMap(mainMap);
                isChangeSelected = true;
            }
        }
    }
}

export const handleOnChangeOrientation = (orientation) => {
    if (mainTabsOrientations.length > 0) {
        let oMarkerSelected = mainTabsOrientations?.find((el) => el.orientation === orientation)?.id ?? null;
        handleSetOTab(oMarkerSelected, true);
    }
};

export const deselectOrientation = (area, polygonGroups) => {
    mainIsEditOrientation = false;
    let { id } = area;
    let polygon = polygonGroups?.find((poly) => poly?.id === id);

    polygon.setOptions({
        strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected,
        fillColor: DEFAULTS_PROPS_MAP.polygon.styles.fillColor.selected,
        strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.strokeOpacity,
        strokeWeight: DEFAULTS_PROPS_MAP.polygon.styles.strokeWeight,
        fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
        editable: true,
        draggable: true,
    });

    mainTabsOrientations.forEach((marker) => {
        marker.setMap(null);
    });

    mainTabsOrientations = [];
};

//#endregion orientation handlers

export const handlerDeselectAllPanelGroups = (polygonGroups, setDispatchEvt) => {
    if (mainIsEditOrientation) return;
    // deselect polygons
    if (polygonGroups.length > 0) {
        polygonGroups.forEach((polygon) => {
            if (polygon?.map) {
                polygon.setOptions({
                    strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.notSelected,
                    fillColor: DEFAULTS_PROPS_MAP.polygon.styles.fillColor.notSelected,
                    editable: false,
                });
                let paths = polygon?.getPath().getArray();
                if (polygon?.isPoint && paths?.length === 1) {
                    paths.push(paths?.[0]);

                    polygon.setOptions({
                        paths: paths,
                        strokeWeight: 6,
                    });
                }
            }
        });
    }
    setDispatchEvt({ evt: DISPATCH_EVT.SET_DESELECT_ALL, groupId: null, values: null });
};

export const handleSetMapOptionsPolygons = (
    polygonGroups,
    exclusionZonesPolygons,
    solarpvDispatchHandler,
    panels,
    isExclusions = false,
    isSplitingShape = false
) => {
    // PanelsGroups
    if (polygonGroups.length > 0) {
        polygonGroups.forEach((polygon, idx) => {
            if (polygon?.map) {
                let strokeColor =
                    !isExclusions && !isSplitingShape && idx === 0 ?
                        DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected
                    :   DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.notSelected;
                let fillColor =
                    !isExclusions && !isSplitingShape && idx === 0 ?
                        DEFAULTS_PROPS_MAP.polygon.styles.fillColor.selected
                    :   DEFAULTS_PROPS_MAP.polygon.styles.fillColor.notSelected;

                let editable = !isExclusions && !isSplitingShape && idx === 0;
                let clickable = !isExclusions && !isSplitingShape;

                polygon.setOptions({
                    strokeColor,
                    fillColor,
                    editable,
                    clickable,
                });
            }
        });
    }

    panels?.forEach((polygon) => {
        let strokeColor =
            !isExclusions && !isSplitingShape ?
                DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.panelSelected
            :   DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.panelNotSelected;
        let fillOpacity = !isExclusions && !isSplitingShape ? 1 : 0.1;

        polygon.setOptions({
            strokeColor,
            fillOpacity,
        });
    });

    // exclusions
    if (exclusionZonesPolygons?.length > 0) {
        exclusionZonesPolygons.forEach((polygon, idx) => {
            if (polygon?.map) {
                let strokeColor =
                    isExclusions && idx === 0 ?
                        DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.selected
                    :   DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.notSelected;
                let fillColor =
                    isExclusions && idx === 0 ?
                        DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.selected
                    :   DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.notSelected;

                let editable = isExclusions && idx === 0;
                let clickable = isExclusions;
                let selected = isExclusions && idx === 0;

                polygon.setOptions({
                    strokeColor,
                    fillColor,
                    editable,
                    clickable,
                    selected,
                });

                if (!!polygon?.editable && polygon?.isPoint) {
                    let paths = polygon.getPath().getArray();
                    polygon.setOptions({
                        paths: [paths?.[0]],
                    });
                }
            }
        });
    }
};

export const handleSetDeselectAllPolygons = (polygonGroups, exclusionZonesPolygons) => {
    // PanelsGroups
    if (polygonGroups?.length > 0) {
        polygonGroups?.forEach((polygon) => {
            if (polygon?.map) {
                let strokeColor = DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.notSelected;
                let fillColor = DEFAULTS_PROPS_MAP.polygon.styles.fillColor.notSelected;

                polygon.setOptions({
                    strokeColor,
                    fillColor,
                    editable: false,
                });
            }
        });
    }

    // exclusions
    if (exclusionZonesPolygons?.length > 0) {
        exclusionZonesPolygons?.forEach((polygon) => {
            if (polygon?.map) {
                let strokeColor = DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.notSelected;
                let fillColor = DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.notSelected;

                polygon.setOptions({
                    strokeColor,
                    fillColor,
                    editable: false,
                    strokeWeight: polygon?.isPoint ? 6 : DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeWeight,
                });
            }
            let paths = polygon?.getPath().getArray();
            if (polygon?.isPoint && paths?.length === 1) {
                paths.push(paths?.[0]);

                polygon.setOptions({
                    paths: paths,
                });
            }
        });
    }
};

export const handleToggleOnlyPanels = (toShow, polygons, options) => {
    const { polygonGroups, exclusionZonesPolygons } = polygons;
    const { nextDispatcher, areas } = options;

    let areaSelected = areas?.find((el) => el.selected)?.id;
    if (polygonGroups?.length > 0) {
        polygonGroups?.forEach((polygon) => {
            if (polygon?.map) {
                let strokeOpacity = toShow ? DEFAULTS_PROPS_MAP.polygon.styles.strokeOpacity : 0;
                let fillOpacity = toShow ? DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity : 0;
                let strokeColor =
                    toShow ?
                        areaSelected === polygon.id ?
                            DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected
                        :   DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.notSelected
                    :   polygon.strokeColor;
                let fillColor =
                    toShow ?
                        areaSelected === polygon.id ?
                            DEFAULTS_PROPS_MAP.polygon.styles.fillColor.selected
                        :   DEFAULTS_PROPS_MAP.polygon.styles.fillColor.notSelected
                    :   polygon.fillColor;

                polygon.setOptions({
                    strokeOpacity,
                    fillOpacity,
                    strokeColor,
                    fillColor,
                    editable: toShow && areaSelected === polygon.id ? true : false,
                });
            }
        });
    }

    if (exclusionZonesPolygons?.length > 0) {
        exclusionZonesPolygons?.forEach((polygon) => {
            if (polygon?.map) {
                let strokeOpacity = toShow ? DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeOpacity : 0;
                let fillOpacity = toShow ? DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillOpacity : 0;
                let strokeColor = toShow ? DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.notSelected : polygon.strokeColor;
                let fillColor = toShow ? DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.notSelected : polygon.fillColor;
                polygon.setOptions({
                    strokeOpacity,
                    strokeColor,
                    fillColor,
                    fillOpacity,
                });
            }
        });
    }

    setTimeout(() => {
        // time to remove areas
        nextDispatcher();
    }, 500);
};

/**
 * toggleDrawingMode
 *
 * @param {*} drawPolygon
 * @param {*} setDrawPolygon
 */
export function toggleDrawingMode(drawPolygon, setDrawPolygon) {
    if (drawPolygon) {
        mainDrawingManager?.setOptions({
            drawingMode: null,
        });
    } else {
        mainDrawingManager?.setOptions({
            drawingMode: mainGoogle.maps.drawing.OverlayType.POLYGON,
            polygonOptions: {
                strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected,
            },
        });
    }

    setDrawPolygon(!drawPolygon);
}

export const stopMainDrawingManager = () => {
    // Set map drawing mode off
    mainDrawingManager?.setOptions({
        drawingMode: null,
    });
};

export const getGroupAreaByPolygon = (polygon) => {
    return mainGoogle.maps.geometry.spherical.computeArea(polygon.getPath());
};

export const getGroupAreaByPolygonCoords = (coordinates) => {
    if (!isFieldDefined(mainGoogle)) return;

    const polygon = new mainGoogle.maps.Polygon({
        paths: [coordinates],
    });
    return mainGoogle.maps.geometry.spherical.computeArea(polygon.getPath());
};

// * EXCLUSION ZONES HANDLERS * \\
//#region

const handleAddExclusionPolygonListeners = (
    polygon,
    exclusionZonesPolygons,
    setDispatchEvt,
    setHasChangesInExclusions,
    isDuplicate = false,
    isAuto = false
) => {
    // When selecting the exclusion zone
    mainGoogle.maps.event.addListener(polygon, 'click', () => {
        handleSelectExclusionPolygon(polygon.id, exclusionZonesPolygons, setDispatchEvt);
    });

    // When we START dragging the exclusion zone
    polygon.addListener('dragstart', () => {
        mainDrag = true;
    });

    // When we END dragging the exclusion zone
    polygon.addListener('dragend', () => {
        mainDrag = false;
        setHasChangesInExclusions(true);

        polygon.setOptions({
            area: getGroupAreaByPolygon(polygon),
            coordinates: getPolygonCoordinates(polygon),
        });
    });

    polygon.addListener('mouseover', () => {
        if (!polygon?.isPolygon) {
            polygon.setOptions({
                strokeWeight: 10,
            });
        }
    });

    polygon.addListener('mouseout', () => {
        if (!polygon?.isPolygon) {
            polygon.setOptions({
                strokeWeight: polygon?.isPoint && !polygon?.editable ? 6 : DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeWeight,
            });
        }
    });

    // When we insert a new vertex in the exclusion zone
    mainGoogle.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
        // set exclusionZonesPolygons
        exclusionZonesPolygons = exclusionZonesPolygons.filter((poly) => poly.id !== polygon.id);
        exclusionZonesPolygons.push(polygon);
        setHasChangesInExclusions(true);

        let isPolygon = polygon.getPath().getArray()?.length > 2;
        polygon.setOptions({
            isPolygon,
            area: getGroupAreaByPolygon(polygon),
            coordinates: isPolygon ? getPolygonCoordinates(polygon) : getLineCoordinates(polygon),
        });
    });

    // When we edit the polygon (by dragging one of the vertexes)
    mainGoogle.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        setHasChangesInExclusions(true);
        let isPolygon = polygon.getPath().getArray()?.length > 2;
        polygon.setOptions({
            isPolygon,
            area: getGroupAreaByPolygon(polygon),
            coordinates: isPolygon ? getPolygonCoordinates(polygon) : getLineCoordinates(polygon),
        });
    });

    // When we create a new vertex and after that we click on the UNDO button
    mainGoogle.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
        setHasChangesInExclusions(true);
        let isPolygon = polygon.getPath().getArray()?.length > 2;
        polygon.setOptions({
            isPolygon,
            area: getGroupAreaByPolygon(polygon),
            coordinates: isPolygon ? getPolygonCoordinates(polygon) : getLineCoordinates(polygon),
        });
    });

    // remover o poligono selecionado.
    if (exclusionZonesPolygons.length > 0) {
        exclusionZonesPolygons.map((polygon) => {
            if (polygon?.map) {
                polygon.setOptions({
                    strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.notSelected,
                    fillColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.notSelected,
                    editable: false,
                    draggable: false,
                });

                let paths = polygon?.getPath().getArray();
                if (polygon?.isPoint && paths?.length === 1) {
                    paths.push(paths?.[0]);

                    polygon.setOptions({
                        paths: paths,
                        strokeWeight: 6,
                    });
                }
            }
            return polygon;
        });
    }

    if (isAuto) {
        polygon.setOptions({
            area: getGroupAreaByPolygon(polygon),
        });
    }

    // Add polygon to Map
    polygon.setMap(mainMap);
    exclusionZonesPolygons.push(polygon);

    if (isDuplicate) {
        // Triggers
        setDispatchEvt({
            evt: DISPATCH_EVT.UPDATE,
            groupId: polygon?.id,
            values: {
                evt: DISPATCH_EVT.MAP_DUPLICATE_EXCLUSION_AREA,
            },
        });
    }
};

export const getLineCoordinates = (polygon) => {
    let lineCoordinates = [];
    let centerLineVertice = polygonCenter(polygon);
    lineCoordinates = [
        [polygon?.getPath().getArray()?.[0]?.lat(), polygon?.getPath().getArray()?.[0]?.lng()],
        [centerLineVertice?.lat(), centerLineVertice?.lng()],
        [
            polygon?.getPath().getArray()?.[1]?.lat() ?? polygon?.getPath().getArray()?.[0]?.lat(),
            polygon?.getPath().getArray()?.[1]?.lng() ?? polygon?.getPath().getArray()?.[0]?.lng(),
        ],
    ];

    return lineCoordinates;
};

export const toggleExclusionsDrawingMode = (isDrawingExclusion, setIsDrawingExclusion) => {
    // Stop drawing mode
    if (isDrawingExclusion) {
        exclusionAreasDrawingManager.setOptions({
            drawingMode: null,
        });
    }
    // Start drawing mode
    else {
        exclusionAreasDrawingManager.setOptions({
            drawingMode: mainGoogle.maps.drawing.OverlayType.POLYGON,
            polygonOptions: {
                strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.selected,
            },
        });
    }

    setIsDrawingExclusion(!isDrawingExclusion);
};

export const handleSelectExclusionPolygon = (polygonID, exclusionZonesPolygons, setDispatchEvt) => {
    // Set map drawing mode off
    mainDrawingManager.setOptions({
        drawingMode: null,
    });

    // Select polygon on map
    let polygon = exclusionZonesPolygons.find((item) => item.id === polygonID && item.visible);

    if (!isDefined(polygon)) return;

    // Change polygon colors
    exclusionZonesPolygons.map((group) => {
        let isSelected = polygonID === group.id;

        group.setOptions({
            editable: isSelected,
            draggable: isSelected,
            strokeColor:
                isSelected ?
                    DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.selected
                :   DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.notSelected,
            fillColor:
                isSelected ?
                    DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.selected
                :   DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.notSelected,
            strokeWeight: group?.isPoint ? 6 : DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeWeight,
        });
        if (!!isSelected && group?.isPoint) {
            let paths = polygon.getPath().getArray();
            group.setOptions({
                paths: [paths?.[0]],
            });
        }
        let paths = group?.getPath().getArray();
        if (group?.isPoint && !isSelected && paths?.length === 1) {
            paths.push(paths?.[0]);

            group.setOptions({
                paths: paths,
                strokeWeight: 6,
            });
        }

        return group;
    });

    // Center map on polygon
    mainMap.panTo(polygonCenter(polygon));

    // Triggers
    setDispatchEvt({
        evt: DISPATCH_EVT.UPDATE,
        groupId: polygonID,
        values: {
            evt: DISPATCH_EVT.MAP_SELECT_EXCLUSION_AREA,
        },
    });

    setDispatchEvt({
        evt: DISPATCH_EVT.UPDATE_EXCLUSION_SELECTION,
        values: {
            id: polygonID,
        },
    });
};

export const handlerDeselectAllExclusions = (exclusionZonesPolygons) => {
    // deselect polygons
    if (exclusionZonesPolygons.length > 0) {
        exclusionZonesPolygons.map((polygon) => {
            if (polygon?.map) {
                polygon.setOptions({
                    strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.notSelected,
                    fillColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.notSelected,
                    editable: false,
                    draggable: false,
                });
                let paths = polygon?.getPath().getArray();
                if (polygon?.isPoint && paths?.length === 1) {
                    paths.push(paths?.[0]);

                    polygon.setOptions({
                        paths: paths,
                        strokeWeight: 6,
                    });
                }
            }
            return polygon;
        });
    }
};

export const handleDeleteExclusionPolygon = (polygonID, exclusionZonesPolygons, setHasChangesInExclusions) => {
    let isDeleteAll = !isDefined(polygonID);
    setHasChangesInExclusions(true);

    // clean polygon on map
    exclusionZonesPolygons.map((polygon) => {
        if (polygon.id === polygonID || isDeleteAll) {
            polygon.setMap(null);
            polygon.setOptions({ visible: false, id: null });
        }
        return polygon;
    });
};

/**
 * Method to get coordinates for the duplicate polygon
 * @param {object} oldCentroid
 * @param {array} oldCoordinates
 * @param {object} newCentroid
 * @returns
 */
const newCoordinatesHandler = (oldCentroid, oldCoordinates, newCentroid) => {
    let newCoordinates = oldCoordinates.map((coordinate) => {
        return {
            lat: (coordinate.lat * newCentroid.lat) / oldCentroid.lat,
            lng: (coordinate.lng * newCentroid.lng) / oldCentroid.lng,
        };
    });
    return newCoordinates;
};

export const deselectDuplication = () => {
    polygonToCopy = null;
    mainMap.setOptions({ draggableCursor: 'default' });
};

export const handleDuplicateExclusionPolygon = (exclusioId, exclusionPolygons, setDispatchEvt, setHasChangesInExclusions) => {
    let polygonCurrent = exclusionPolygons?.find((poly) => poly?.visible && poly?.id === exclusioId);

    // duplicate
    let coordinates = [];
    let spacingDefault = -0.00001;
    let spacing = isDefined(polygonCurrent?.spacing) ? polygonCurrent?.spacing + spacingDefault : spacingDefault;
    let isPolygon = coordinates.length > 2;

    polygonCurrent
        ?.getPath()
        ?.getArray()
        ?.forEach((el) => coordinates.push(new mainGoogle.maps.LatLng(el.lat() + spacing, el.lng() + spacing)));

    // update current selecionado
    polygonCurrent = {
        ...polygonCurrent,
        spacing,
    };

    let { ref, zIndex, strokeOpacity, strokeWeight, fillOpacity, fillColor, strokeColor } = polygonCurrent;

    let polygon = new mainGoogle.maps.Polygon({
        // spacing,
        ref,
        id: getNextID(exclusionPolygons, 0, 'id'),
        zIndex,
        paths: [coordinates],
        clickable: true,
        visible: true,
        editable: true,
        draggable: true,
        strokeOpacity,
        strokeWeight,
        fillOpacity,
        fillColor,
        strokeColor,
        isPolygon,
    });

    //
    polygon.setOptions({
        coordinates: isPolygon ? getPolygonCoordinates(polygon) : getLineCoordinates(polygon),
    });

    handleAddExclusionPolygonListeners(polygon, exclusionPolygons, setDispatchEvt, setHasChangesInExclusions, true);

    setHasChangesInExclusions(true);
};

const advancedDuplicationHandler = (newPolygonCentroid, setDispatchEvt, exclusionZonesPolygons, setHasChangesInExclusions) => {
    const polygonCurrent = polygonToCopy.polygon;
    let oldCoordinates = [];
    const oldCentroid = {
        lat: polygonCenter(polygonCurrent)?.lat(),
        lng: polygonCenter(polygonCurrent)?.lng(),
    };

    // if (!isDefined(oldCentroid.lat) || !isDefined(oldCentroid.lng) || polygonCurrent) return;

    polygonCurrent
        ?.getPath()
        ?.getArray()
        ?.forEach((el) => oldCoordinates.push({ lat: el.lat(), lng: el.lng() }));
    let newCoordinates = newCoordinatesHandler(oldCentroid, oldCoordinates, newPolygonCentroid);

    newCoordinates.map((el) => new mainGoogle.maps.LatLng(el.lat, el.lng));

    let isPolygon = newCoordinates.length > 2;

    let { ref, zIndex, strokeOpacity, strokeWeight, fillOpacity, fillColor, strokeColor } = polygonCurrent;

    let polygon = new mainGoogle.maps.Polygon({
        // spacing,
        ref,
        id: getNextID(exclusionZonesPolygons, 0, 'id'),
        zIndex,
        paths: [newCoordinates],
        clickable: true,
        visible: true,
        editable: true,
        draggable: true,
        strokeOpacity,
        strokeWeight,
        fillOpacity,
        fillColor,
        strokeColor,
        isPolygon,
    });

    //
    polygon.setOptions({
        coordinates: isPolygon ? getPolygonCoordinates(polygon) : getLineCoordinates(polygon),
    });

    handleAddExclusionPolygonListeners(polygon, exclusionZonesPolygons, setDispatchEvt, setHasChangesInExclusions, true);

    setDispatchEvt({
        evt: DISPATCH_EVT.UPDATE,
        groupId: polygon?.id,
        values: {
            evt: DISPATCH_EVT.MAP_DUPLICATE_EXCLUSION_AREA,
        },
    });

    setHasChangesInExclusions(true);
};

export const startAdvancedDuplicationHandler = (exclusioId, exclusionPolygons) => {
    let polygonCurrent = exclusionPolygons?.find((poly) => poly?.visible && poly?.id === exclusioId);
    if (!isDefined(polygonCurrent)) return;

    if (polygonToCopy?.id === exclusioId) {
        polygonToCopy = null;
        mainMap.setOptions({ draggableCursor: 'default' });
    } else {
        polygonToCopy = { id: exclusioId, polygon: polygonCurrent };
        mainMap.setOptions({ draggableCursor: 'crosshair' });
    }
};

export const getDuplicateGroupPolygon = (polygonCurrent, polygonGroups, setDispatchEvt) => {
    // duplicate
    let coordinates = [];
    let spacingDefault = -0.00001;
    let spacing = isDefined(polygonCurrent?.spacing) ? polygonCurrent?.spacing + spacingDefault : spacingDefault;

    polygonCurrent
        ?.getPath()
        ?.getArray()
        ?.forEach((el) => coordinates.push(new mainGoogle.maps.LatLng(el.lat() + spacing, el.lng() + spacing)));

    // update current selecionado
    polygonCurrent = {
        ...polygonCurrent,
        spacing,
    };

    let { ref, zIndex, visible, editable, draggable, strokeOpacity, strokeWeight, fillOpacity, fillColor, strokeColor } = polygonCurrent;

    let polygon = new mainGoogle.maps.Polygon({
        spacing,
        ref,
        id: getNextID(polygonGroups, 0, 'id'),
        zIndex,
        paths: [coordinates],
        clickable: true,
        visible,
        editable,
        draggable,
        strokeOpacity,
        strokeWeight,
        fillOpacity,
        fillColor,
        strokeColor,
    });
    handleAddPolygonListeners(polygon, polygonGroups, setDispatchEvt, false);

    return polygon;
};

export const getPolygonPropertiesFromOriginal = (original, polygon) => {
    const { zIndex, visible, draggable } = original;

    polygon.setOptions({
        zIndex,
        visible,
        draggable,
    });

    return polygon;
};

export const onDiscardExclusionsChangesHandler = (exclusionZonesPolygons, exclusions, setDispatchEvt, setHasChangesInExclusions) => {
    // remove all
    exclusionZonesPolygons.map((polygon) => {
        mainGoogle.maps.event.clearInstanceListeners(polygon);
        polygon.setMap(null);
        polygon.setOptions(RESET_POLYGON_OPTIONS);
        return polygon;
    });

    //rever polygons
    exclusions.map((group) => {
        let coordinates = [];
        if (group?.id && group?.area > 0 && group?.coordinates?.length > 0) {
            for (let i = 0; i < group?.coordinates?.length; i++) {
                coordinates[i] = new mainGoogle.maps.LatLng(group.coordinates[i][0], group.coordinates[i][1]);
            }
            let polygon = new mainGoogle.maps.Polygon({
                ...group,
                ref: mainMapRef,
                id: group.id,
                zIndex: 20,
                paths: [coordinates],
                clickable: false,
                visible: true,
                editable: false,
                draggable: false,
                strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeOpacity,
                strokeWeight: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeWeight,
                fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillOpacity,
                fillColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.notSelected,
                strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.notSelected,
            });

            handleAddExclusionPolygonListeners(polygon, exclusionZonesPolygons, setDispatchEvt, setHasChangesInExclusions);
        }
        return group;
    });
};

export const getExclusionsByPolygons = (exclusionsPolygons) => {
    let exclusions = [];
    exclusionsPolygons = exclusionsPolygons.filter((item) => isDefined(item.id) && item.visible);

    for (let i = 0; i !== exclusionsPolygons.length; i++) {
        let isPolygon = exclusionsPolygons[i].isPolygon;
        exclusions.push({
            id: exclusionsPolygons[i].id,
            selected: exclusionsPolygons[i].selected,
            area: Math.round(exclusionsPolygons[i].area),
            coordinates: isPolygon ? getPolygonCoordinates(exclusionsPolygons[i]) : getLineCoordinates(exclusionsPolygons[i]),
            isPolygon: exclusionsPolygons[i].isPolygon,
        });
    }

    return exclusions;
};

export const blockAutoExclusionsMap = (isBlocking) => {
    exclusionsMap.gestureHandling = isBlocking ? 'none' : undefined;
    exclusionsMap.zoomControl = isBlocking ? false : undefined;
};

export const addAutoExclusions = async (
    exclusionsPolygons,
    exclusionsRefs,
    setDispatchEvt,
    setHasChangesInExclusions,
    solarpvDispatchHandler
) => {
    await exclusionsPolygons.forEach((exclusion, index) => {
        let coordinates = [];

        if (exclusion?.length > 1) {
            for (let i = 0; i < exclusion.length; i++) {
                coordinates.push({
                    lat: exclusion[i].lat,
                    lng: exclusion[i].lng,
                });
            }
            let polygon = null;

            polygon = new mainGoogle.maps.Polygon({
                ref: mainMapRef,
                id: index + 1,
                zIndex: 20,
                paths: exclusion,
                clickable: true,
                visible: true,
                editable: false,
                draggable: false,
                isPolygon: exclusion?.length > 2 ? true : false,
                strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeOpacity,
                strokeWeight: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeWeight, //HEARE apicamos o tamanho da linha
                fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillOpacity,
                fillColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.fillColor.notSelected,
                strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.notSelected,
            });

            handleAddExclusionPolygonListeners(polygon, exclusionsRefs, setDispatchEvt, setHasChangesInExclusions, false, true);
        }
    });
    await solarpvDispatchHandler(SpvActions.SET_DIALOG, { autoExclusions: false });
    setHasChangesInExclusions(true);
    blockAutoExclusionsMap(false);
};

export const getGroupAreaByPolygonAutoExclusions = (polygon) => {
    return exclusionsMap.maps.geometry.spherical.computeArea(polygon.getPath());
};

export const showPolygonOnMap = (show) => {
    visibleAreasToExclude.map((polygon) => {
        polygon.setOptions({
            visible: show,
        });
        return polygon;
    });
};

//#endregion

//#region Split group

export const togglePolylineDrawingMode = (isDrawingPolyline, setIsDrawingPolyline) => {
    // Stop drawing mode
    if (isDrawingPolyline) {
        polylineDrawingManager.setOptions({
            drawingMode: null,
        });
    }
    // Start drawing mode
    else {
        polylineDrawingManager.setOptions({
            drawingMode: mainGoogle.maps.drawing.OverlayType.POLYLINE,
            polylineOptions: {
                strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.exclusionZone.strokeColor.selected,
            },
        });
    }

    setIsDrawingPolyline(!isDrawingPolyline);
};

export const isPointInsidePolygon = (point, polygon) => {
    return mainGoogle.maps.geometry.poly.containsLocation(point, polygon);
};

export const polylineStartsAndEndsOutsidePolygon = (polyline, polygon) => {
    const polylinePath = polyline.getPath().getArray();

    if (polylinePath.length < 2) return false;

    if (isPointInsidePolygon(polylinePath?.at(0), polygon)) return false;
    if (isPointInsidePolygon(polylinePath?.at(-1), polygon)) return false;

    return true;
};

/**
 * Checks if 2 lines intersect.
 * @param {google.maps.LatLng} point1
 * @param {google.maps.LatLng} point2
 * @param {google.maps.LatLng} point3
 * @param {google.maps.LatLng} point4
 * @returns
 */
export const doLinesIntersect = (point1, point2, point3, point4) => {
    const a = point1?.lat();
    const b = point1?.lng();
    const c = point2?.lat();
    const d = point2?.lng();
    const p = point3?.lat();
    const q = point3?.lng();
    const r = point4?.lat();
    const s = point4?.lng();

    var det, gamma, lambda;
    det = (c - a) * (s - q) - (r - p) * (d - b);
    if (det === 0) {
        return false;
    } else {
        lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
        gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
        return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
    }
};

export const doesPolylineIntersectPolygon = (polyline, polygon) => {
    const polylinePoints = polyline?.getPath()?.getArray();
    const polygonPoints = polygon?.getPath()?.getArray();

    for (let i = 0; i < polygonPoints.length; i++) {
        const point1 = polygonPoints[i];
        const point2 = i === polygonPoints.length - 1 ? polygonPoints[0] : polygonPoints[i + 1];

        if (doLinesIntersect(polylinePoints[0], polylinePoints[1], point1, point2)) return true;
    }

    return false;
};

export const getSelectedPolygon = (polygonGroups) => {
    return polygonGroups.find((p) => p?.selected);
};

/**
 * Converts Drawing Manager overlays to GeoJson. https://stackoverflow.com/questions/37240211/google-maps-api-togeojson-returns-object-with-geometry-null/37240748#37240748
 * @param {*} overlay
 * @param {google.maps.drawing.OverlayType} overlayType
 */
export const convertToGeoJson = (overlay, overlayType) => {
    if (!mainGoogle) return null;
    const dataLayer = new mainGoogle.maps.Data();
    if (!dataLayer) return null;

    switch (overlayType) {
        case mainGoogle.maps.drawing.OverlayType.POLYGON:
            dataLayer.add(
                new mainGoogle.maps.Data.Feature({
                    geometry: new mainGoogle.maps.Data.Polygon([overlay.getPath().getArray()]),
                })
            );
            break;

        case mainGoogle.maps.drawing.OverlayType.POLYLINE:
            dataLayer.add(
                new mainGoogle.maps.Data.Feature({
                    geometry: new mainGoogle.maps.Data.LineString(overlay.getPath().getArray()),
                })
            );
            break;

        default:
            // TODO: Add remaining overlay types when needed
            break;
    }

    return new Promise(function (resolve, reject) {
        try {
            dataLayer.toGeoJson((a) => {
                if (a) resolve(a);
                reject(a);
            });
        } catch (e) {
            reject(e);
        }
    });
};

export const getPolygonFromGeoJson = (coordinates, isSelected, polygonGroups) => {
    return new mainGoogle.maps.Polygon({
        id: getNextID(polygonGroups, 0, 'id'),
        zIndex: 1,
        paths: coordinates,
        editable: isSelected,
        draggable: true,
        strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.strokeOpacity,
        strokeWeight: DEFAULTS_PROPS_MAP.polygon.styles.strokeWeight,
        fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
        fillColor:
            isSelected ? DEFAULTS_PROPS_MAP.polygon.styles.fillColor.selected : DEFAULTS_PROPS_MAP.polygon.styles.fillColor.notSelected,
        strokeColor:
            isSelected ? DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected : DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.notSelected,
        isSelected,
    });
};

export const handleSplitShape = (originalPolygon, splittedPolygons, polygonGroups, setDispatchEvt) => {
    polygonGroups = polygonGroups?.filter((p) => p?.id !== originalPolygon?.id);
    const newPolygons = [];

    splittedPolygons?.geometries?.forEach((geometry, index) => {
        if (geometry?.type !== 'Polygon') return;

        const _coords = geometry.coordinates?.at(0);
        const coordinates = [];
        for (let i = 0; i < _coords?.length; i++) {
            coordinates[i] = new mainGoogle.maps.LatLng(_coords[i][1], _coords[i][0]);
        }

        const _newPolygon = getPolygonFromGeoJson(coordinates, index === 0, polygonGroups, setDispatchEvt);

        newPolygons.push(_newPolygon);
        handleAddPolygonListeners(_newPolygon, polygonGroups, setDispatchEvt);
    });

    setDispatchEvt({
        evt: DISPATCH_EVT.SPLIT_POLYGON,
        originalPolygon,
        newPolygons,
    });
};

export const handleDeleteGeometries = (geometries, setGeometries) => {
    geometries.forEach((geometry) => geometry.setMap(null));

    setGeometries(() => []);
};

//#endregion Split group

/* ******** END GMAP - SETTINGS || PROPS ******* */

//Get KPIs Summary
export const getSummaryKPIs = (kpis, has_battery, companyProfileId, visibility_elements) => {
    let formattedKpis = clone(SETTINGS_PROJECT_SUMMARY(companyProfileId));

    return formattedKpis.map((item) => {
        if (item.name === KPIS_NPUTS_NAME.INJECTION_PERC) {
            item.invalid = kpis?.[item.name] > SETTINGS_SPV.MAX_INJECTION_PERCENTAGE;
        }

        if (item.name === KPIS_NPUTS_NAME.SPECIFIC_PRICE) {
            return {
                ...item,
                value: isDefined(kpis?.investment) && isDefined(kpis?.peak_power) ? Math.round(kpis.investment / kpis.peak_power) : null,
                visible: kpisVisibility(visibility_elements, item?.name)?.visible,
            };
        }
        if (item.name === KPIS_NPUTS_NAME.ANNUAL_PROD) {
            return {
                ...item,
                value: isDefined(kpis?.[item.name]) ? kpis?.[item.name] / 1000 : null,
                visible: kpisVisibility(visibility_elements, item?.name)?.visible,
            };
        }
        if ([PROJECT_SUMMARY_KPIS.BATTERY_POWER_KWH, PROJECT_SUMMARY_KPIS.BATTERY_CAPACITY_KWH].includes(item.name)) {
            return {
                ...item,
                visible: has_battery || kpisVisibility(visibility_elements, item?.name)?.visible,
                value: isDefined(kpis?.[item.name]) ? kpis?.[item.name] : null,
            };
        }
        if (item.name === PROJECT_SUMMARY_KPIS.CONSUMPTION_REDUCTION) {
            return {
                ...item,
                visible:
                    ![getCompanyProfileIds().EDP_BR].includes(companyProfileId) &&
                    (kpisVisibility(visibility_elements, item?.name)?.visible ?? item?.visible),
                value: kpis?.[item.name] ? kpis?.[item.name] : 0,
            };
        }
        if (item.name === PROJECT_SUMMARY_KPIS.REPRESENTATIVENESS) {
            return {
                ...item,
                visible:
                    kpisVisibility(visibility_elements, item?.name)?.visible ?? [getCompanyProfileIds().EDP_BR].includes(companyProfileId),
                value: kpis?.[item.name] ? kpis?.[item.name] : 0,
            };
        }
        if (item.unit === 'percentage') {
            return {
                ...item,
                value: isDefined(kpis?.[item.name]) ? kpis?.[item.name] * 100 : null,
                visible: kpisVisibility(visibility_elements, item?.name)?.visible ?? item?.visible,
            };
        }
        return {
            ...item,
            value: isDefined(kpis?.[item.name]) ? kpis?.[item.name] : null,
            visible: kpisVisibility(visibility_elements, item?.name)?.visible ?? item?.visible,
        };
    });
};

//Translate Graphs (alterar)
export const translateGraphs = (props, updateLocale, setUpdateLocale) => {
    //Change Make Legend Names translatable
    props.data.datasets.map((item) => {
        if (isDefined(item?.keyword)) item.label = intlMessages(item.keyword);
        return item;
    });
    props.options.scales.yAxes.map((item) => {
        if (isDefined(item?.scaleLabel?.keyword)) item.scaleLabel.labelString = intlMessages(item.scaleLabel.keyword);
        return item;
    });
    props.options.scales.xAxes.map((item) => {
        if (isDefined(item?.scaleLabel?.keyword)) item.scaleLabel.labelString = intlMessages(item.scaleLabel.keyword);
        return item;
    });
    //alterar as traduções
    setUpdateLocale(!updateLocale);

    return props;
};

/**
 * getRangeDescription
 *
 * @param {*} companyProfileId
 * @param {*} rangeID
 * @param {*} ranges
 */
export function getRangeDescription(companyProfileId = null, rangeID = null, ranges = []) {
    let rangeDescription = null;

    if (
        [getCompanyProfileIds().EDP_PT, getCompanyProfileIds().EFZ, getCompanyProfileIds().NRG, getCompanyProfileIds().ESB].includes(
            companyProfileId
        ) &&
        !isEmptyArray(ranges) &&
        isDefined(rangeID)
    ) {
        rangeDescription = ranges.find((range) => range.id === rangeID)?.descricao;
    }

    return rangeDescription;
}

/**
 * canBatteriesAddin
 *
 * @param {*} companyProfileId
 */
export function canBatteriesAddin(companyProfileId = null) {
    return [getCompanyProfileIds().EFZ].includes(companyProfileId);
}

/**
 * checkMountingStructureID
 *
 * @param {[]} groups
 * @param {[]} mountingStructures
 * @param {number} companyProfileId
 * @returns
 */
export function checkMountingStructureID(groups, mountingStructures) {
    let mountingStructure;

    groups.map((group) => {
        mountingStructure = getSelectedStructure(mountingStructures, group?.mounting_structure_id ?? null);

        // add mounting_structure_id
        group.mounting_structure_id = mountingStructure.id;

        // delete structure_id
        if (isDefined(group?.structure_id))
            //trash
            delete group.structure_id;

        // delete estrutura && has_structure
        if (isFieldDefined(group?.estrutura)) {
            //trash
            delete group.estrutura;
            delete group.has_structure;
        }
        return group;
    });

    return groups;
}

/**
 * Takes the current structure tree, the structure selected ID and returns:
 * - An array with the structure levels
 * - An array with the modified tags
 * - The selected structure ID (leaf)
 */

export const computeStructures = (allMountingStructures, currentStructuresArray, id = null, selectedStructureLevel = null) => {
    const defaultMountingStructures = clone(allMountingStructures); // We get the API structures object
    const isDefault = id === null; // If no id is provided, we know we want the structures with is_default to true
    const isProductInput = selectedStructureLevel === null && id !== null; // We get an ID from productInputs

    let levels = isDefault || isProductInput ? [] : currentStructuresArray.slice(0, selectedStructureLevel); // If no user input, we start with empty array
    let level = [];
    let currentID = id;
    let tags = [];

    // If group comes from productInputs
    if (isProductInput) {
        do {
            // eslint-disable-next-line
            let selectedLevelStructure = defaultMountingStructures.find((s) => s.id === currentID);

            if (selectedLevelStructure) {
                selectedLevelStructure.isSelected = true;
                tags.unshift(selectedLevelStructure.tag);
                levels.unshift(defaultMountingStructures.filter((structure) => structure.parent_id === selectedLevelStructure.parent_id));
            }

            currentID = selectedLevelStructure?.parent_id ?? null;
        } while (currentID !== null);
    }
    // If group is session new and doesn't come from productInputs or user is changing it
    else {
        if (!isDefault) {
            let selectedLevelStructure = defaultMountingStructures.find((s) => s.id === currentID);
            selectedLevelStructure.isSelected = true;
            tags.push(selectedLevelStructure.tag);
            levels.push(defaultMountingStructures.filter((structure) => structure.parent_id === selectedLevelStructure.parent_id));
            currentID = selectedLevelStructure.id;
        }

        do {
            // eslint-disable-next-line
            level = defaultMountingStructures.filter((structure) => structure.parent_id === currentID);
            let selectedLevelStructure = level.find((structure) => structure.is_default);
            if (selectedLevelStructure) {
                selectedLevelStructure.isSelected = true;
                tags.push(selectedLevelStructure.tag);
                currentID = selectedLevelStructure.id;
            } else if (level.length > 0) {
                levels.push([
                    {
                        error: 'page.backoffice.error.missingDefaultStructuralTypology',
                    },
                ]);

                return {
                    newStructureLevels: levels,
                    modifiedTags: tags,
                    lastChildID: currentID,
                };
            }

            if (level.length > 0) levels.push(level);
        } while (level.length > 0);
    }

    return {
        newStructureLevels: levels,
        modifiedTags: tags,
        lastChildID: currentID,
    };
};

// Returns the structure object that is selected either by ID or by default
export const getSelectedStructure = (structures, id = null) => {
    let isIDValid = structures.find((structure) => structure.id === id); // existe casos que o ID não é valido. se for, fallback para o default.
    let isIDValidWithCfg = structures.find((structure) => structure.id === id && structure.fe_elements_arr?.length > 0); // existe casos que o ID não é valido e se tem as configurações da representação do paineis.
    if (isDefined(id) && isDefined(isIDValid) && isIDValidWithCfg) return structures.find((structure) => structure.id === id);
    if (isDefined(id) && isDefined(isIDValid) && !isIDValidWithCfg) {
        //when there problem with hitoric edit proposal (fmjr)
        let haveChildren = true;
        let lastChildID = id;
        while (haveChildren) {
            // eslint-disable-next-line no-loop-func
            let lastChildData = structures.find((elm) => elm.parent_id === lastChildID && elm.is_default);
            haveChildren = lastChildData?.id ?? false;
            if (haveChildren) {
                lastChildID = lastChildData?.id;
            }
        }
        return structures.find((structure) => structure.id === lastChildID);
    }

    let selectedStructure = computeStructures(structures);

    return structures.find((structure) => structure.id === selectedStructure.lastChildID);
};

/**
 * getSysComposition
 *
 * @param {number} companyProfileId
 * @param {boolean} conditionUserChannelIDGroup
 * @returns {*}
 */
export function getSysComposition(companyProfileId = getCompanyProfileIds().EDP_PT, userChannelIDGroup = null) {
    //fallback EDP_PT

    const conditionUserChannelIDGroup = [
        GRUPO_CANAL_IDS.CGD,
        GRUPO_CANAL_IDS.SANTANDER,
        GRUPO_CANAL_IDS.NOVOBANCO,
        GRUPO_CANAL_IDS.BCP,
        GRUPO_CANAL_IDS.NOS,
    ].includes(userChannelIDGroup);

    if (conditionUserChannelIDGroup) {
        return {
            [INPUTS_NAME_SYS_COMPOSITION.SLOPE]: 0,
            [INPUTS_NAME_SYS_COMPOSITION.ORIENTATION]: 0,
        };
    }

    let defaultSysComposition = null;
    switch (companyProfileId) {
        case getCompanyProfileIds().EDP_BR:
            defaultSysComposition = {
                [INPUTS_NAME_SYS_COMPOSITION.SLOPE]: 30,
                [INPUTS_NAME_SYS_COMPOSITION.ORIENTATION]: 180,
            };

            break;
        case getCompanyProfileIds().EDP_PL:
            defaultSysComposition = {
                [INPUTS_NAME_SYS_COMPOSITION.SLOPE]: 10,
                [INPUTS_NAME_SYS_COMPOSITION.ORIENTATION]: 0,
            };

            break;

        default:
            defaultSysComposition = {
                [INPUTS_NAME_SYS_COMPOSITION.SLOPE]: 30,
                [INPUTS_NAME_SYS_COMPOSITION.ORIENTATION]: 0,
            };
            break;
    }

    return defaultSysComposition;
}

export function generateRandomKeyPanels(areaID, rowID, panelID) {
    return `KeyAid${areaID}RId${rowID}PId${panelID}AltId${generateRandomString()}`;
}

export function getDefaultPRepresentation(mStructureData) {
    return {
        [PANEL_REPRESENTATION_INPUT_NAMES.REFERENCE_DAY]: mStructureData[PANEL_REPRESENTATION_INPUT_NAMES.REFERENCE_DAY],
        [PANEL_REPRESENTATION_INPUT_NAMES.HOUR_WINDOW]: mStructureData[PANEL_REPRESENTATION_INPUT_NAMES.HOUR_WINDOW],
        [PANEL_REPRESENTATION_INPUT_NAMES.PANEL_FRAMING]: mStructureData[PANEL_REPRESENTATION_INPUT_NAMES.PANEL_FRAMING],
        [PANEL_REPRESENTATION_INPUT_NAMES.PANELS_UP]: mStructureData[PANEL_REPRESENTATION_INPUT_NAMES.PANELS_UP],
        [PANEL_REPRESENTATION_INPUT_NAMES.PANELS_WIDE]: mStructureData[PANEL_REPRESENTATION_INPUT_NAMES.PANELS_WIDE],
        [PANEL_REPRESENTATION_INPUT_NAMES.ROOF_SLOPE]: mStructureData[PANEL_REPRESENTATION_INPUT_NAMES.ROOF_SLOPE],
        [PANEL_REPRESENTATION_INPUT_NAMES.SETBACK]: mStructureData[PANEL_REPRESENTATION_INPUT_NAMES.SETBACK],
        [PANEL_REPRESENTATION_INPUT_NAMES.SPACE_BTW_SETS]: mStructureData[PANEL_REPRESENTATION_INPUT_NAMES.SPACE_BTW_SETS],
        [PANEL_REPRESENTATION_INPUT_NAMES.IS_ROW_SPACING_INSERT]: mStructureData[PANEL_REPRESENTATION_INPUT_NAMES.IS_ROW_SPACING_INSERT],
        [PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACE]: mStructureData[PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACE],
        [PANEL_REPRESENTATION_INPUT_NAMES.ALIGNMENT]: DEFAULT_PANEL_ALIGNMENT,
        [PANEL_REPRESENTATION_INPUT_NAMES.ALIGNMENT_HORIZONTAL]: DEFAULT_PANEL_ALIGNMENT,
        [PANEL_REPRESENTATION_INPUT_NAMES.ALIGNMENT_VERTICAL]: DEFAULT_PANEL_ALIGNMENT_VERTICAL,
        [PANEL_REPRESENTATION_INPUT_NAMES.REFERENCE_POINT]: [],
    };
}

/**
 * handlerMStructures
 * ajusta os valores defaults recebido.
 *
 * @param {*} rpsData
 * @returns
 */
export function handlerMStructures(rpsData) {
    rpsData.map((ms) => {
        if (ms?.fe_elements_arr?.length === 0) {
            return ms;
        }

        const feElements = (input) => ms?.fe_elements_arr?.find((element) => element?.designacao_fe === input);
        ms[PANEL_REPRESENTATION_INPUT_NAMES.PANELS_WIDE] = parseFloat(
            feElements(PANEL_REPRESENTATION_INPUT_NAMES.PANELS_WIDE)?.valor_default
        );
        ms[PANEL_REPRESENTATION_INPUT_NAMES.PANELS_UP] = parseFloat(feElements(PANEL_REPRESENTATION_INPUT_NAMES.PANELS_UP)?.valor_default);
        ms[PANEL_REPRESENTATION_INPUT_NAMES.SPACE_BTW_SETS] = parseFloat(
            feElements(PANEL_REPRESENTATION_INPUT_NAMES.SPACE_BTW_SETS)?.valor_default
        );
        ms[PANEL_REPRESENTATION_INPUT_NAMES.HOUR_WINDOW] = parseFloat(
            feElements(PANEL_REPRESENTATION_INPUT_NAMES.HOUR_WINDOW)?.valor_default
        );
        ms[PANEL_REPRESENTATION_INPUT_NAMES.ROOF_SLOPE] = ms?.default_roof_slope;
        ms[PANEL_REPRESENTATION_INPUT_NAMES.IS_ROW_SPACING_INSERT] = parseBoolean(
            feElements(PANEL_REPRESENTATION_INPUT_NAMES.IS_ROW_SPACING_INSERT)?.valor_default
        );
        ms[PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACE] = parseFloat(feElements(PANEL_REPRESENTATION_INPUT_NAMES.ROW_SPACE)?.valor_default);
        ms[PANEL_REPRESENTATION_INPUT_NAMES.SETBACK] = parseFloat(feElements(PANEL_REPRESENTATION_INPUT_NAMES.SETBACK)?.valor_default);
        ms[PANEL_REPRESENTATION_INPUT_NAMES.PANEL_FRAMING] = parseFloat(
            feElements(PANEL_REPRESENTATION_INPUT_NAMES.PANEL_FRAMING)?.valor_default
        );

        // REFERENCE_DAY
        let reference_day_default = new Date(Date.parse(feElements(PANEL_REPRESENTATION_INPUT_NAMES.REFERENCE_DAY)?.valor_default));
        ms[PANEL_REPRESENTATION_INPUT_NAMES.REFERENCE_DAY] = new Date(
            new Date().getFullYear(),
            reference_day_default.getMonth(),
            reference_day_default.getDate()
        );

        // delete
        delete ms.n_paineis_horizontal;
        delete ms.n_paineis_vertical;
        delete ms.distancia_sets;
        delete ms.horas_sem_sombra;
        delete ms.inclinacao_cobertura;
        delete ms.row_spacing_insert;
        delete ms.pv_painel_layout_id;
        return ms;
    });

    return rpsData;
}

export function cleanProductInputsData(rspData, defaultInputs, options) {
    let { isEditProposal, companyProfileId, mostra_pv_v2, mostra_menu_exclusoes, coordinates_avg, hasProjectFeature } = options;

    let sizing = null;
    if (!isEditProposal) {
        if (hasProjectFeature) {
            if (isEmpty(rspData)) return { ...defaultInputs, coordinates_avg };
            sizing = rspData;
        } else {
            if (isEmpty(rspData) || !isDefined(rspData?.SIMPLES_Dimensionamento)) return { ...defaultInputs, coordinates_avg };
            sizing = rspData?.SIMPLES_Dimensionamento?.inputs;
        }
    } else {
        if (isEmpty(rspData) || !isDefined(rspData?.inputs)) return { ...defaultInputs, coordinates_avg };
        sizing = rspData?.inputs;
    }

    if (
        !hasProjectFeature &&
        (!isDefined(sizing?.coordinates_avg) || !isDefined(sizing?.coordinates_avg?.lat) || !isDefined(sizing?.coordinates_avg?.long))
    )
        return { ...defaultInputs, coordinates_avg };

    if (mostra_menu_exclusoes && sizing?.exclusions?.length > 0) sizing.exclusions.map((area) => (area.selected = false));

    // cleanup inputs
    sizing = cleanUpInputs(sizing);

    // groups
    if (sizing?.areas.length > 0) {
        sizing.areas = sizing?.areas
            .sort((a, b) => a.id - b.id)
            .map((group, idx) => {
                let isSelected = idx === 0;
                group.selected = isSelected;

                // pv1.0
                if (
                    !mostra_pv_v2 &&
                    [getCompanyProfileIds().EDP_PT].includes(companyProfileId) &&
                    !isDefined(group?.area_reduction_elements)
                ) {
                    group.area_reduction_elements = {
                        chillers: false,
                        others: false,
                        platbands: false,
                        surrounding: false,
                    };
                }

                return group;
            });
    }

    sizing?.areas.forEach((area) => {
        if (isDefined(area?.coordinates_avg?.lng)) area.coordinates_avg.long = area?.coordinates_avg?.lng;
        delete area?.coordinates_avg?.lng;
        delete area?.is_kits;
        delete area?.kits_id;
    });

    if (sizing?.exclusions?.length > 0) {
        sizing?.exclusions.forEach((exc, idx) => {
            if (exc?.coordinates?.length < 4) {
                /* line or point */
                sizing.exclusions[idx].coordinates[0][0] = sizing?.exclusions[idx].coordinates[0][0] + 0.0000000000001;
                sizing.exclusions[idx].coordinates[0][1] = sizing?.exclusions[idx].coordinates[0][1] + 0.0000000000001;
                if (sizing.exclusions?.[idx]?.coordinates?.[1]) {
                    sizing.exclusions[idx].coordinates[1][0] = sizing?.exclusions[idx].coordinates[1][0] + 0.000000000001;
                    sizing.exclusions[idx].coordinates[1][1] = sizing?.exclusions[idx].coordinates[1][1] + 0.000000000001;
                }
                if (sizing.exclusions?.[idx]?.coordinates?.[2]) {
                    sizing.exclusions[idx].coordinates[2][0] = sizing?.exclusions[idx].coordinates[2][0] + 0.00000000002;
                    sizing.exclusions[idx].coordinates[2][1] = sizing?.exclusions[idx].coordinates[2][1] + 0.00000000002;
                }
                console.log();
            }
            return exc;
        });
    }

    let inputsData = {
        areas: sizing?.areas ?? [],
        coordinates_avg: sizing?.coordinates_avg,
        network_sale: sizing?.network_sale ?? null,
        limit_network_sale: sizing?.limit_network_sale ?? null,
        range_id: sizing?.range_id ?? null,
        panel_id: sizing?.panel_id ? sizing?.panel_id : null,
        battery: isFieldDefined(sizing?.battery?.id) ? sizing?.battery : null,
        exclusions: mostra_menu_exclusoes && sizing?.exclusions ? sizing?.exclusions : [],
        buildings: sizing?.buildings ?? null,
        max_tec_panels: sizing?.max_tec_panels ?? 0,
        min_tec_panels: sizing?.min_tec_panels ?? 0,
        total_areas: sizing?.total_areas ?? 0,
        total_panels: sizing?.total_panels ?? 0,
        injection_tariff: sizing?.injection_tariff ?? null,
        remuneration_type_id: sizing?.remuneration_type_id ?? null,
        coordinates_conversion_factor: sizing?.coordinates_conversion_factor ?? null,
        is_kits: sizing?.is_kits ?? null,
    };

    if (isDefined(sizing?.remote)) {
        inputsData.remote = sizing?.remote;
    }

    //#region inverters
    let invertersCombination = sizing?.inverters_combination ?? null;
    if (isDefined(invertersCombination)) {
        // use a different combination than the default one
        inputsData.inverters_combination = invertersCombination;
    }
    //#endRegion inverters
    return removeFieldEmptyInObj(inputsData, ['limit_network_sale']);
}

export function cleanUpInputs(inputs) {
    // areas
    inputs?.areas.map((group) => {
        delete group.peak_power;
        delete group.price_zone_id;
        delete group.holes_coordinates;

        return group;
    });

    // root
    delete inputs?.sales_segment;
    delete inputs?.has_lecs;
    delete inputs?.fin_kpis;
    delete inputs?.negotiation;
    delete inputs?.allowance;
    delete inputs?.allowance;
    delete inputs?.annual_evo_fee;
    delete inputs?.op_and_man_durantion;
    delete inputs?.service_energy_type_id;
    delete inputs?.monthly_payment_option;
    delete inputs?.op_and_man_duration;
    delete inputs?.service_energy_price;
    delete inputs?.pv_kit;
    delete inputs?.is_quick_proposal;
    delete inputs?.isChangeBattery;
    delete inputs?.annual_interest_rate;
    delete inputs?.sub_produto;
    delete inputs?.offer_id;
    delete inputs?.has_client_discount;
    // delete inputs?.offer_edition;
    delete inputs?.margin;
    delete inputs?.extra_cost;
    delete inputs?.subsidy_prc;
    delete inputs?.subsidy;
    delete inputs?.img_base64; // <-- This little guy slows down sooooo much. He's a no go. Serioulsy. DO NOT comment.
    delete inputs?.['img-contract']; // <-- This little guy slows down sooooo much. He's a no go. Serioulsy. DO NOT comment.
    delete inputs?.monthly_fee;
    delete inputs?.has_changed;
    delete inputs?.contact_info;
    delete inputs?.pap_tariff;
    delete inputs?.lec_tariff;
    delete inputs?.gas_supplied_by_company;
    delete inputs?.supplied_by_company;
    delete inputs?.annual_price;
    delete inputs?.annual_net_savings;
    delete inputs?._annual_interest_rate;
    delete inputs?.annual_interest_rate;
    delete inputs?._monthly_payment_option_id;
    delete inputs?.monthly_payment_option_id;
    delete inputs?.battery_subsidy_prc;
    delete inputs?.img_base64;
    delete inputs?.is_scaling;
    delete inputs?.is_fast_module;
    delete inputs?.tipo_modelo_negocio_id;
    delete inputs?.is_kits;
    delete inputs?.kits;

    return inputs;
}

export const getInputsSimulationPayload = (inputs, options) => {
    const { featureFlags } = useFeatureFlags.getState();
    let cInputs = clone(inputs);
    const { battery } = options;

    if (!isDefined(battery)) {
        delete cInputs.battery;
    }

    // delete trash
    delete cInputs.isChangeBattery;

    if (isEnvDevFlag(featureFlags['fe-2243'])) {
        delete cInputs?.buildings;
        delete cInputs?.exclusions;
        delete cInputs?.prod_params;
        delete cInputs?.coordinates_conversion_factor;
        delete cInputs?.customized;
        delete cInputs?.itc_rate_prc;
        delete cInputs?.fe_version;
        delete cInputs?.is_manual; //PVSimple
    }

    const inputsCleaned = {
        ...removeFieldEmptyInObj(
            {
                ...cInputs,
            },
            ['limit_network_sale']
        ),
    };

    //#region remove areas without panels
    const areasWithPanels = [];
    cInputs.areas.forEach((area) => {
        if (area.panels_number > 0) areasWithPanels.push(area);
        if (isEnvDevFlag(featureFlags['fe-2243'])) {
            delete area?.manual_removed_idxs;
            delete area?.manual_added_idxs;
            delete area?.autoSplitBuilding;
            delete area?.panel_representation;
            delete area?.area_reduction_elements;
            delete area?.panel_rows;
            delete area?.lat;
            delete area?.long;
            delete area?.edges;
            delete area?.coordinates;
            delete area?.max_type_area_panels;
            delete area?.peak_power;
            delete area?.uaErrorMessage;
            delete area?.centroid; // v2 only
        }
    });

    const inputsWithPanels = {
        ...inputsCleaned,
        areas: areasWithPanels,
    };
    //#endregion remove areas without panels
    if (isEnvDevFlag(featureFlags['fe-2243'])) {
        try {
            // Zod schema validation
            simulationPayloadSchema.shape.inputs.parse(inputsWithPanels);
        } catch (error) {
            console.error(
                'ERROR parsing the following inputs (in `/simulations` payload):',
                error?.issues?.map((i) => i?.path)
            );
            notify(intlMessages('server.error.500'), 'error');
        }
    }
    return inputsWithPanels;
};

export const getOptimizerPayload = (inputs, options) => {
    const { featureFlags } = useFeatureFlags.getState();
    let cInputs = clone(inputs);
    const { battery } = options;

    if (!isDefined(battery)) {
        delete cInputs.battery;
    }

    // delete trash
    delete cInputs.isChangeBattery;

    if (isEnvDevFlag(featureFlags['fe-2243'])) {
        delete cInputs?.buildings;
        delete cInputs?.exclusions;
        delete cInputs?.prod_params;
        delete cInputs?.coordinates_conversion_factor;
        delete cInputs?.customized;
        delete cInputs?.itc_rate_prc;
        delete cInputs?.fe_version;
        delete cInputs?.is_manual; //PVSimple
    }

    const inputsCleaned = {
        ...removeFieldEmptyInObj(
            {
                ...cInputs,
            },
            ['limit_network_sale']
        ),
    };

    //#region remove areas without panels
    const areasWithPanels = [];
    cInputs.areas.forEach((area) => {
        if (isEnvDevFlag(featureFlags['fe-2243'])) {
            delete area?.manual_removed_idxs;
            delete area?.manual_added_idxs;
            delete area?.autoSplitBuilding;
            delete area?.panel_representation;
            delete area?.area_reduction_elements;
            delete area?.panel_rows;
            delete area?.lat;
            delete area?.long;
            delete area?.edges;
            delete area?.coordinates;
            delete area?.max_type_area_panels;
            delete area?.uaErrorMessage;
            delete area?.centroid; // v2 only
        }
        areasWithPanels.push(area);
    });

    const inputsWithPanels = {
        ...inputsCleaned,
        areas: areasWithPanels,
    };
    //#endregion remove areas without panels
    if (isEnvDevFlag(featureFlags['fe-2243'])) {
        try {
            // Zod schema validation
            optimizerPayloadSchema.shape.inputs.parse(inputsWithPanels);
        } catch (error) {
            console.error(
                'ERROR parsing the following inputs (in `/simulations` payload):',
                error?.issues?.map((i) => i?.path)
            );
            notify(intlMessages('server.error.500'), 'error');
        }
    }
    return inputsWithPanels;
};

export const getReformulationPayload = (inputs, offerEdition, id) => {
    let cInputs = clone(inputs);

    return {
        ...cleanUpInputs(cInputs),
        offer_edition: offerEdition,
        reformulation: { id: id },
    };
};

export const getCustomizeInputs = (inputs, ranges, costs = false, isEfzCosts = false, prodCurveFilename) => {
    const rangeDefault = ranges?.find((range) => range.is_default);
    const panelDefault = rangeDefault?.paineis.find((panel) => panel.is_default);
    let simulationInputs = {
        production_contract_filename: inputs?.filename,
        is_customize: true,
    };
    if (!isEfzCosts) {
        simulationInputs = {
            ...simulationInputs,
            ...CUSTOMIZE_DUMMY_AREA(inputs?.panelNumbers, rangeDefault?.id),
            total_panels: inputs?.panelNumbers,
            peak_power: inputs?.peakPower,
            range_id: rangeDefault?.id ?? null,
            panel_id: panelDefault?.id ?? null,
        };
    } else {
        simulationInputs = {
            ...simulationInputs,
            ...inputs,
        };
    }

    if (costs) {
        simulationInputs.offer_edition = {
            ...simulationInputs.offer_edition,
            costs: costs,
        };
    }
    if (prodCurveFilename) {
        simulationInputs.offer_edition = {
            ...simulationInputs.offer_edition,
            production_from_file: prodCurveFilename,
        };
    }

    //#region remove areas without panels
    const areasWithPanels = [];
    simulationInputs.areas.forEach((area) => {
        if (area.panels_number > 0) areasWithPanels.push(area);
    });
    //#endregion remove areas without panels

    return getInputsSimulationPayload(
        {
            ...simulationInputs,
            areas: areasWithPanels,
        },
        { battery: simulationInputs?.battery }
    );
};

export const getCleanInputs = (inputs) => {
    const inputsCleaned = ['areas', 'coordinates_avg'];
    const newInputs = {
        ...inputs,
    };
    Object.keys(newInputs).forEach((key) => inputsCleaned.includes(key) || delete newInputs[key]);

    return newInputs;
};

export function getCommonTooltipByASensibility(getFormatNumber, commonInfo, data) {
    let commonTooltip = clone(commonInfo);
    commonTooltip[0] = `${intlMessages('label.peakPower')}: ${getFormatNumber({ number: data.x, unit: 'kwp' })}`;
    commonTooltip[1] = `${intlMessages('label.payback')}: ${getFormatNumber({ number: data.y, unit: 'year' })}`;
    return commonTooltip;
}

export function getBatteryPayload(inputs) {
    let cInputs = clone(inputs);
    for (let name in cInputs) {
        if (
            ![
                'battery_capacity_selected_kwh',
                'battery_recommendation_is_optimal',
                'bat_id',
                'id',
                'pv_kit_id',
                'pv_kit_bat_bronze_id',
                'injection_decrease_perc',
                'battery_power_selected_kw',
                'battery_selection_mode_id',
                'hybrid_inverter',
                'num_fases',
            ].includes(name)
        )
            cInputs[name] = cInputs[name] / 100;
    }
    return removeFieldEmptyInObj(cInputs);
}

export function isEnableModeAutomatically(errors) {
    return (
        !isDefined(errors?.[BATTERY_INPUT_NAMES.BATTERY_CHARGE_EFFICIENCY_PERC]) &&
        !isDefined(errors?.[BATTERY_INPUT_NAMES.BATTERY_DISCHARGE_EFFICIENCY_PERC]) &&
        !isDefined(errors?.[BATTERY_INPUT_NAMES.BATTERY_DOD_PERC]) &&
        !isDefined(errors?.[BATTERY_INPUT_NAMES.INJECTION_DECREASE_SELECTED_PERC])
    );
}

/**
 * getTotalPanelsNumberPossible
 *
 * @param {array} areas
 * @returns number
 */
export function isAtTechnicalLimitTotalAreas(areas) {
    let maxTecAreas = 10000;
    let totalAreas = areas.reduce((total, item) => (total += item.area), 0);

    return {
        maxTecAreas,
        totalAreas,
        isAtTecLimits: totalAreas <= maxTecAreas,
    };
}

/**
 * getAllPanelsNumberByKits
 * @param {Array} areas
 * @param {Number} range_id
 * @param {String} inputSearch
 * @param {Array} solarPanelsData
 * @returns {Array}
 */
export function getAllPanelsNumberByKits(areas, range_id, inputSearch, solarPanelsData = []) {
    //#region local vars
    let areasCurrent = clone(areas);
    let solarPanelsDataByRangeID = solarPanelsData?.filter((kit) => kit.range_id === range_id) ?? [];
    let maxNumberPanelsAllowed = solarPanelsDataByRangeID[solarPanelsDataByRangeID.length - 1]?.panels_number;
    let minNumberPanelsAllowed = solarPanelsDataByRangeID[0]?.panels_number;
    let totalPanelsNumberExcluded = 0;
    let newTotalPanelsNumber = 0;
    let totalNumberPanelsPossible = 0;
    areasCurrent.forEach((area) => (totalNumberPanelsPossible += area?.[inputSearch]));
    let hasExceededLimits = totalNumberPanelsPossible < minNumberPanelsAllowed || totalNumberPanelsPossible > maxNumberPanelsAllowed;
    //#endregion  local vars

    // se for inferior ao minimo do sistema permitido.
    if (solarPanelsDataByRangeID[0]?.panels_number > totalNumberPanelsPossible) return areasCurrent;
    let hasKitsPanels = isDefined(solarPanelsDataByRangeID.find((kit) => kit.panels_number === totalNumberPanelsPossible));

    // 3º Se não encontra no vetor, vai encontrar o panel menor mais próximo
    if (!hasKitsPanels && !hasExceededLimits) {
        for (var i = 0; i !== solarPanelsDataByRangeID.length; i++) {
            if (i === solarPanelsDataByRangeID.length - 1) {
                newTotalPanelsNumber = solarPanelsDataByRangeID[solarPanelsDataByRangeID.length - 1]; // Se exceder o ultimo kit, fica com o nr de painéis desse
            }
            if (
                totalNumberPanelsPossible > solarPanelsDataByRangeID[i]?.panels_number &&
                totalNumberPanelsPossible < solarPanelsDataByRangeID[i + 1]?.panels_number
            ) {
                newTotalPanelsNumber = solarPanelsDataByRangeID[i]?.panels_number;
                break;
            }
        }
        totalPanelsNumberExcluded = totalNumberPanelsPossible - newTotalPanelsNumber; // Total de paineis desenhados - nr de painéis do kit mais proximo
    } else if (hasExceededLimits) {
        //#region limites (max & min)
        if (totalNumberPanelsPossible > maxNumberPanelsAllowed) {
            totalPanelsNumberExcluded = totalNumberPanelsPossible - maxNumberPanelsAllowed; // Total de paineis desenhados - máximo de painéis permitidos
        }
        //#endregion limites (max & min)
    }

    areasCurrent.map((area) => {
        let panels_number = 0;
        let panels_number_excluded = 0;
        let panels_number_possible = area?.[inputSearch];

        if (totalPanelsNumberExcluded > 0) {
            //retira do grupo o total de panels ainda por excluir.

            // panels_number
            panels_number = totalPanelsNumberExcluded >= panels_number_possible ? 0 : panels_number_possible - totalPanelsNumberExcluded;
        } else {
            //drnão existir mais panels a excluir, o panels_number =  panels_number_possible
            panels_number = area.panels_number_possible;
        }

        //#region set values common a todos os grupos.
        // panels_number_excluded
        panels_number_excluded =
            totalPanelsNumberExcluded >= panels_number_possible ? panels_number_possible - panels_number : totalPanelsNumberExcluded;

        // totalPanelsNumberExcluded
        totalPanelsNumberExcluded -= panels_number_excluded;

        // set values
        area.panels_number = panels_number;
        area.panels_number_excluded = parseInt(panels_number_excluded.toString());
        //#endregion set values common a todos os grupos.

        return area;
    });

    return areasCurrent;
}

export const findClosestKit = (data, target) => {
    if (!data) return;
    let closest = null;
    for (const item of data) {
        if (item.panels_num <= target) {
            if (closest === null || item.panels_num > closest.panels_num) {
                closest = item;
            }
        }
    }
    return closest;
};

export const calculateValidPanels = (areas, minTecPanels, maxTecPanels) => {
    //#region local vars
    let inputSearch = (area) => (area?.panels_number_possible ? 'panels_number_possible' : 'panels_number');
    let areasCurrent = clone(areas);
    let totalPanelsExcluded = 0;
    let totalDrawnPanels = areasCurrent.reduce((total, area) => {
        return (total += isDefined(area?.max_tec_area_panels) ? area?.max_tec_area_panels : area?.[inputSearch(area)] ?? 0);
    }, 0);
    //#endregion  local vars

    // se for inferior ao minimo do sistema permitido.
    if (totalDrawnPanels < minTecPanels) return areasCurrent;

    if (totalDrawnPanels > maxTecPanels) {
        totalPanelsExcluded = totalDrawnPanels - maxTecPanels; // Total de paineis desenhados - máximo de painéis permitidos
    }

    areasCurrent.map((area) => {
        let panels_number = 0;
        let panels_number_excluded = 0;
        let panels_number_possible = area?.[inputSearch(area)];
        let panelsNumberMax = area?.['max_tec_area_panels'];

        if (totalPanelsExcluded > 0) {
            // Se o total de painéis excluídos >= ao nr de painéis desenhados do grupo, nr de painéis válidos é 0, senão é a diferença
            panels_number = totalPanelsExcluded >= panels_number_possible ? 0 : panels_number_possible - totalPanelsExcluded;
        } else {
            // se não existir mais panels a excluir, o panels_number = panels_number_possible
            panels_number = panels_number_possible;
        }

        if (isDefined(panelsNumberMax) && panels_number > panelsNumberMax) panels_number = panelsNumberMax;

        // panels_number_excluded in this area
        panels_number_excluded = panels_number_possible - panels_number;

        // totalPanelsNumberExcluded
        totalPanelsExcluded -= panels_number_excluded;

        // set values
        area.panels_number = panels_number;
        area.panels_number_excluded = parseInt(panels_number_excluded.toString());

        return area;
    });

    return areasCurrent;
};

export const calculateValidPanelsKits = (areas, options) => {
    let { kits } = options;

    if (kits.length === 0) return areas;

    //#region local vars
    let areasCurrent = clone(areas);
    let totalPanelsExcluded = 0;
    let totalDrawnPanels = areasCurrent.reduce(
        (total, area) => (total += isFieldDefined(area?.max_tec_area_panels) ? area?.max_tec_area_panels : area?.panels_number_possible),
        0
    );
    let exceededMaxPanelsByKits = totalDrawnPanels > _maxBy(kits, 'panels_num')?.panels_num;

    //#endregion  local vars
    let kitSelected = kits.find((kit) => kit.panels_num === totalDrawnPanels) ?? null;
    if (!isFieldDefined(kitSelected)) {
        if (totalDrawnPanels < _minBy(kits, 'panels_num')?.panels_num) {
            areasCurrent.forEach((area) => {
                area.kit_id = null;
                area.panels_number_kit = _minBy(kits, 'panels_num')?.panels_num;
            });
            return areasCurrent;
        }
        kitSelected = kits?.reduce((prev, curr) =>
            totalDrawnPanels - curr.panels_num < totalDrawnPanels - prev.panels_num && totalDrawnPanels - curr.panels_num >= 0 ? curr : prev
        );
    }
    let panelsNumToAllocate = kitSelected?.panels_num;

    if (exceededMaxPanelsByKits) {
        // se for inferior ao minimo do sistema permitido.
        if (totalDrawnPanels < kitSelected?.panels_num) totalPanelsExcluded = totalDrawnPanels;

        // Total de paineis desenhados - máximo de painéis permitidos
        if (totalDrawnPanels > kitSelected?.panels_num) totalPanelsExcluded = totalDrawnPanels - kitSelected?.panels_num;
    }

    //#region orderBy
    let iterate = isFieldDefined(areasCurrent.find((el) => isFieldDefined(el?.max_tec_area_panels))) ? 'max_tec_area_panels' : 'id';
    let order = iterate === 'id' ? 'desc' : 'asc';
    let hasMaxSize = false;
    //#endregion orderBy
    orderBy(areasCurrent, [iterate], order).map((area) => {
        let panels_number = 0;
        let panels_number_excluded = 0;
        let panels_number_possible = area?.panels_number_possible ?? area.panels_number;
        let panelsNumberMax = area?.['max_tec_area_panels'];

        //#region MaxSizeByGroup
        if (isFieldDefined(panelsNumberMax)) {
            area.kit_id = kitSelected.id;
            area.panels_number = panelsNumberMax > panels_number_possible ? panels_number_possible : panelsNumberMax;
            area.panels_number_possible = panels_number_possible ?? 0;
            area.panels_number_kit = kitSelected?.panels_num;
            area.panels_number_excluded = parseInt(
                (parseInt(panels_number_possible.toString()) - parseInt(panelsNumberMax.toString())).toString()
            );
            panelsNumToAllocate -= panelsNumberMax;
            hasMaxSize = true;
            return area;
        }
        //#endregion MaxSizeByGroup

        if (totalPanelsExcluded > 0) {
            // Se o total de painéis excluídos >= ao nr de painéis desenhados do grupo, nr de painéis válidos é 0, senão é a diferença
            panels_number = totalPanelsExcluded >= panels_number_possible ? 0 : panels_number_possible - totalPanelsExcluded;
        } else {
            // se não existir mais panels a excluir, o panels_number = panels_number_possible
            panels_number = area?.panels_number_possible <= panelsNumToAllocate ? panels_number_possible : panelsNumToAllocate;
        }

        // MaxSizeByGroup
        if (hasMaxSize && panels_number === 0 && panelsNumToAllocate > 0) {
            panels_number =
                panelsNumToAllocate >= panels_number_possible ? panels_number_possible : panels_number_possible - totalPanelsExcluded;
        }

        // panels_number_excluded in this area
        panelsNumToAllocate -= panels_number;
        panels_number_excluded = area?.panels_number_possible - panels_number;

        // totalPanelsNumberExcluded
        totalPanelsExcluded -= panels_number_excluded;

        // set values
        area.panels_number = panels_number;
        area.panels_number_possible = panels_number_possible;
        area.panels_number_kit = kitSelected?.panels_num;
        area.panels_number_excluded = parseInt(panels_number_excluded.toString());
        area.kit_id = kitSelected.id;
        return area;
    });

    return orderBy(areasCurrent, ['id'], 'desc');
};

/**
 * validateNumberOfPanels
 *
 * @param {Array} areas
 * @param {Number} range_id
 * @param {Number} companyProfileId
 * @param {Array} solarPanelsData
 * @returns {Array}
 */
export function validateNumberOfPanels(areas, range_id, solarPanelsData = [], maxTecPanels, minTecPanels, panel_id, options) {
    let { remuneration_type_id, pvKitsB2C, pv_modo_kits_id } = options;

    //#region calc panels by kits
    if (pv_modo_kits_id === MODO_KITS_IDS.KIT_PER_STRUCTURE) {
        // set mStructure

        let areasCurrent = [];
        let mStructureIDs = uniqBy(areas, (el) => el.mounting_structure_id);
        mStructureIDs.forEach((el) => {
            let areasBySetSM = areas.filter((area) => area.mounting_structure_id === el.mounting_structure_id);

            areasBySetSM = calculateValidPanelsKits(clone(areasBySetSM), {
                kits: pvKitsB2C.find((el) => parseInt(el.remuneration_id) === parseInt(remuneration_type_id))?.kits ?? [],
            });
            areasBySetSM.forEach((item) => areasCurrent.push(item)); //concat
        });

        let kitsIDs = uniqBy(areasCurrent, (el) => el.kit_id);
        let kits = [];
        mStructureIDs.forEach((ms) => {
            // one entry per structure
            kitsIDs.forEach((el) => {
                if (el?.kit_id) {
                    let areasByKits = areasCurrent
                        .filter((group) => group.mounting_structure_id === ms)
                        .filter((group) => group.kit_id === el?.kit_id);
                    if (areasByKits.length > 0) {
                        kits.push({
                            id: el.kit_id,
                            areas: [...new Set(areasByKits.map((item) => item.id))],
                        });
                    }
                }
            });
        });
        return {
            areas: orderBy(areasCurrent, ['id'], 'desc'),
            kits,
        };
    }

    if (pv_modo_kits_id === MODO_KITS_IDS.SINGLE_KITS) {
        // set mStructure
        let areasCurrent = calculateValidPanelsKits(clone(areas), {
            kits: pvKitsB2C.find((el) => parseInt(el.remuneration_id) === parseInt(remuneration_type_id))?.kits ?? [],
        });
        let kitsIDs = uniqBy(areasCurrent, (el) => el.kit_id);
        let kits = [];
        kitsIDs.forEach((el) => {
            if (el?.kit_id) {
                let areasByKits = areasCurrent.filter((group) => group.kit_id === el?.kit_id);
                if (areasByKits.length > 0) {
                    kits.push({
                        id: el.kit_id,
                        areas: [...new Set(areasByKits.map((item) => item.id))],
                    });
                }
            }
        });

        return {
            areas: orderBy(areasCurrent, ['id'], 'desc'),
            kits,
        };
    }
    //#endregion calc panels by kits

    //#region local vars
    let inputSearch = areas[0]?.panels_number_possible ? 'panels_number_possible' : 'panels_number'; //fallback+history

    //#region Range panel id
    if (isFieldDefined(panel_id)) {
        return { areas: calculateValidPanels(areas, minTecPanels, maxTecPanels) };
    }
    //#endregion Range panel id

    return { areas: getAllPanelsNumberByKits(areas, range_id, inputSearch, solarPanelsData) };
}

export const configGroupTabsOptions = (group, options) => {
    const modifiedGroup = clone(group);

    if (options?.mostra_pv_v2) {
        modifiedGroup[SYSTEM_SETTINGS_TABS.ENVIRONMENT].visible =
            (options?.mostra_menu_exclusoes || options?.mostra_menu_edificios) ?? modifiedGroup[SYSTEM_SETTINGS_TABS.ENVIRONMENT].visible;
    } else {
        modifiedGroup[SYSTEM_SETTINGS_TABS.ENVIRONMENT].visible = false;
    }

    return modifiedGroup;
};

export const configGroupButtonsOptions = (group, options) => {
    const modifiedGroup = clone(group);

    if (options?.mostra_pv_v2) {
        modifiedGroup[SYSTEM_SETTINGS_GROUPS.EXCLUSIONS].visible =
            options?.mostra_menu_exclusoes ?? modifiedGroup[SYSTEM_SETTINGS_GROUPS.EXCLUSIONS].visible;
        modifiedGroup[SYSTEM_SETTINGS_GROUPS.REMOTE].visible =
            options?.mostra_menu_ac_remoto ?? modifiedGroup[SYSTEM_SETTINGS_GROUPS.REMOTE].visible;
        modifiedGroup[SYSTEM_SETTINGS_GROUPS.BUILDINGS].visible =
            options?.mostra_menu_edificios ?? modifiedGroup[SYSTEM_SETTINGS_GROUPS.BUILDINGS].visible;
    } else {
        modifiedGroup[SYSTEM_SETTINGS_GROUPS.EXCLUSIONS].visible = false;
        modifiedGroup[SYSTEM_SETTINGS_GROUPS.REMOTE].visible = false;
        modifiedGroup[SYSTEM_SETTINGS_GROUPS.BUILDINGS].visible = false;
    }

    return modifiedGroup;
};

export const configPanelGroupOptions = (group, options) => {
    const modifiedGroup = clone(group);

    modifiedGroup[PANEL_GROUP_NAMES.STRUCTURE].allowEdit = options?.edita_estrutura ?? modifiedGroup[PANEL_GROUP_NAMES.STRUCTURE].allowEdit;
    modifiedGroup[PANEL_GROUP_NAMES.SLOPE].allowEdit = options?.edita_inclinacao ?? modifiedGroup[PANEL_GROUP_NAMES.SLOPE].allowEdit;
    modifiedGroup[PANEL_GROUP_NAMES.ORIENTATION].allowEdit =
        options?.edita_orientacao ?? modifiedGroup[PANEL_GROUP_NAMES.ORIENTATION].allowEdit;
    modifiedGroup[PANEL_GROUP_NAMES.REPRESENTATION].visible =
        options?.mostra_representacao_painel ?? modifiedGroup[PANEL_GROUP_NAMES.REPRESENTATION].visible;

    return modifiedGroup;
};

export const getProductionLosses = (prodLosses) => {
    if (!prodLosses) return {};

    let graphValues = [];
    let maxValue = parseFloat(prodLosses['theoretical_production_system']);

    Object.keys(prodLosses).forEach((key) => {
        if (!key.endsWith('_prc') && key !== 'default_total_loss') {
            graphValues.push({
                name: key,
                value: prodLosses[key],
                percentage: prodLosses[key + '_prc'] ?? null,
                legend: PRODUCT_LOSSES_CHART_LABELS[key],
                tooltip: `${PRODUCT_LOSSES_CHART_LABELS[key]}.tooltip`,
                widthRatio: parseFloat(prodLosses[key]) / maxValue,
            });
        }
    });

    // sort graph values based on a given order
    let barsOrder = ['theoretical_production_system', 'output_energy_panels', 'energy_after_clipping', 'final_energy'];
    graphValues.sort((a, b) => barsOrder.indexOf(a.name) - barsOrder.indexOf(b.name));

    let totalLosses = prodLosses['total_loss_prc'];
    let defaultLosses = prodLosses['default_total_loss'];

    return { graphValues, totalLosses, defaultLosses };
};

export const getRangesWithPanelTypes = (areas, ranges) => {
    let cRanges = clone(ranges);
    if (!ranges?.[0]?.paineis || !isDefined(areas?.length)) return ranges;

    for (let idx = 0; idx < cRanges?.length; idx++) {
        cRanges[idx]?.paineis.forEach((panel) => {
            panel.is_recommended = panel?.is_default ?? false;
        });
    }
    return cRanges;
};

export const adjustRecommendedPanelTypes = (systemTotalArea, range) => {
    if (!(range?.paineis?.length > 0)) return range;

    range?.paineis?.forEach((panel) => {
        panel.is_recommended = panel?.is_default ?? false;
    });

    return range;
};

export const simulatorBMWithData = (mqMessages, bmMapped) => {
    let bmWithData = [];
    if (mqMessages.length === bmMapped.length) {
        // don't have PP (only have simulators bm)
        for (let i = 0; i < mqMessages.length; i++) {
            if (mqMessages[i].data.length > 0) {
                bmWithData.push(mqMessages[i]);
            }
        }
        return bmWithData.length > 0;
    }
    return true;
};

export function getSysLimitsTecPanels(rangeId, rangePanelId, options) {
    const { ranges, solarPanelsData, pvOptions, pvKitsB2C, remuneration_type_id } = options;

    let maxTecPanels = 0;
    let minTecPanels = 0;

    if (pvKitsB2C?.length > 0 && isFieldDefined(remuneration_type_id)) {
        let pvKitSelected = pvKitsB2C.find((item) => parseInt(item.remuneration_id) === remuneration_type_id);
        //TODO: aplicar quando removemos os grupos.!!! permitir apenas um grupo

        let minTecPanels = _minBy(pvKitSelected?.kits, 'panels_num')?.panels_num;
        let maxTecPanels = _maxBy(pvKitSelected?.kits, 'panels_num')?.panels_num;

        return {
            minTecPanels,
            maxTecPanels,
        };
    }

    let rangeSelected = ranges?.find((range) => range.id === rangeId);
    let panelsKitsByRangeID = isDefined(rangeId) ? solarPanelsData.filter((item) => item?.range_id === rangeId) : [];
    if (rangeSelected?.paineis?.length > 0) {
        let potenciaPico = rangeSelected.paineis?.find((panel) => panel.id === rangePanelId)?.potencia_pico ?? 0;
        //#region minTecPanels
        let minKwp = rangeSelected?.min_kwp ?? 0;
        const minUserKwp = pvOptions?.limits?.user_limits?.min_kwp ?? null;
        let minTec =
            isFieldDefined(minUserKwp) && minUserKwp > minKwp ? Math.ceil(minUserKwp / potenciaPico) : Math.ceil(minKwp / potenciaPico);

        minTecPanels = minTec > 0 ? minTec : 1;
        //#endregion minTecPanels

        //#region maxTecPanels
        let maxKwp = rangeSelected?.max_kwp ?? 0;
        const maxUserKwp = pvOptions?.limits?.user_limits?.max_kwp ?? null;
        let maxTec = null;
        const mostRestrictiveValue = Math.min(...[maxKwp, maxUserKwp]?.filter((v) => isNumberDefined(v)));
        maxTec = Math.floor(mostRestrictiveValue / potenciaPico);

        maxTecPanels = maxTec > 0 ? maxTec : 1;
        //#endregion maxTecPanels
    } else {
        minTecPanels = panelsKitsByRangeID?.[0]?.panels_number ?? 1;
        maxTecPanels = _maxBy(panelsKitsByRangeID, 'panels_number')?.panels_number ?? null;
    }

    return {
        minTecPanels,
        maxTecPanels,
    };
}

export const getCumulativeTecLimits = (inputs, limit) => {
    let limitVar = `${limit}_tec_panels`;
    return !inputs.is_kits ?
            inputs?.[limitVar] // B2B
        :   inputs?.[limitVar] * uniqBy(inputs.areas, (el) => el.mounting_structure_id).length; // B2C
};

export const errorsWithDisabledForward = (errors, options = {}) => {
    let { pv_modo_limite_potencia_id } = options;
    if (isFieldDefined(pv_modo_limite_potencia_id) && !POWER_LIMIT_MODES_WITH_BLOCKING.includes(pv_modo_limite_potencia_id)) return false;

    for (let i = 0; i < errors.length; i++) {
        return [
            SPV_ERROR_TAGS.TOTAL_PANELS_NUMBER_EXCEED_LIMIT,
            SPV_ERROR_TAGS.TOTAL_PANELS_NUMBER_EXCEED_MIN_LIMIT,
            SPV_ERROR_TAGS.TOTAL_PANELS_NUMBER_EXCEED_MAX_LIMIT,
            SPV_ERROR_TAGS.INVERTER_POWER_EXCEEDED_CONTRACTED_POWER,
            SPV_ERROR_TAGS.INVERTER_POWER_EXCEEDED_REQUIRED_POWER,
            SPV_ERROR_TAGS.INVERTER_POWER_EXCEEDED_MIN_KIT_POWER,
            SPV_ERROR_TAGS.LECS_POWER_LIMIT_EXCEEDED,
        ].includes(errors[i].tag);
    }
};

export const warningsWithDisabledForward = (warnings, options = {}) => {
    let { pv_modo_limite_potencia_id } = options;
    if (isFieldDefined(pv_modo_limite_potencia_id) && !POWER_LIMIT_MODES_WITH_BLOCKING.includes(pv_modo_limite_potencia_id)) return false;

    for (let i = 0; i < warnings.length; i++) {
        return [
            // SPV_ERROR_TAGS.TOTAL_PANELS_NUMBER_EXCEED_MIN_LIMIT,
            // SPV_ERROR_TAGS.TOTAL_PANELS_NUMBER_EXCEED_MAX_LIMIT,
            SPV_ERROR_TAGS.INVERTER_POWER_EXCEEDED_CONTRACTED_POWER,
            SPV_ERROR_TAGS.INVERTER_POWER_EXCEEDED_REQUIRED_POWER,
        ].includes(warnings[i].tag);
    }
};

export const getStructureData = (structures, structureId) => {
    const structureData = structures.find((structureFromData) => structureFromData?.id === structureId);

    const isCoplanar = structureData?.tipo_estrutura_id === MOUNTING_STRUCTURES_TYPES.COPLANAR;
    const isTriangular = structureData?.tipo_estrutura_id === MOUNTING_STRUCTURES_TYPES.TRIANGULAR;
    const isGround = structureData?.tipo_estrutura_id === MOUNTING_STRUCTURES_TYPES.GROUND;
    const isEastWest = structureData?.tipo_estrutura_id === MOUNTING_STRUCTURES_TYPES.EAST_WEST;
    const isCarPark = structureData?.tipo_estrutura_id === MOUNTING_STRUCTURES_TYPES.CARPARK;

    return {
        structureData,
        isTriangular,
        isGround,
        isCoplanar,
        isEastWest,
        isCarPark,
    };
};

export const getDefaultSlope = (structures, structureId) => {
    const structureData = structures.find((structureFromData) => structureFromData?.id === structureId);
    const { isCoplanar } = getStructureData(structures, structureId);

    if (isCoplanar) {
        return structureData.default_roof_slope;
    } else {
        return structureData.default_panels_slope;
    }
};

export const checkStructureTypeChange = (oldStructure, newStructure) => {
    const oldStructureKeys = Object.keys(oldStructure);
    for (let key of oldStructureKeys) {
        if (oldStructure[key] !== newStructure[key]) {
            return true;
        }
    }
    return false;
};

export const calculateAreaPeakPower = (areaId, inputs, ranges) => {
    const currentArea = inputs?.areas?.find((area) => area.id === areaId);
    let hasPanelId = isFieldDefined(inputs?.panel_id);

    const panelsPeakPower =
        hasPanelId ?
            ranges?.find((range) => range.id === inputs.range_id)?.paineis?.find((panel) => panel.id === inputs.panel_id)?.potencia_pico
        :   ranges.find((range) => range.id === inputs.range_id)?.paineis_peak_power;

    return panelsPeakPower * currentArea?.panels_number;
};

export const checkMinSlope = (inputs, structures) => {
    const areas = inputs.areas.map((area) => {
        let structure = getSelectedStructure(structures, area.mounting_structure_id);
        if (!isDefined(area?.slope) || area.slope < structure?.min_panels_slope) {
            area.slope = structure?.min_panels_slope;
        }
        return area;
    });
    return areas;
};

export const customizeCostTotal = (costsInputs, systemInputs, unit) => {
    const { qty, cost } = costsInputs;
    const { panelsNumber, peakPower } = systemInputs;
    switch (unit) {
        case 'currency':
            return cost;
        case 'ypp':
            return cost * peakPower * qty;
        case 'panc':
            return cost * panelsNumber * qty;
        case 'mcc':
            return cost * qty;
        default:
            return cost;
    }
};

export const customizeCost = (costsInputs, systemInputs, newUnit) => {
    const { qty, cost, unit } = costsInputs;
    const { panelsNumber, peakPower } = systemInputs;

    // obtain base cost value (in function of unit)
    let baseVal;
    switch (unit) {
        case 'currency':
        default:
            baseVal = cost;
            break;
        case 'ypp':
            baseVal = cost * peakPower * qty;
            break;
        case 'panc':
            baseVal = cost * panelsNumber * qty;
            break;
        case 'mcc':
            baseVal = cost * qty;
            break;
    }

    // update cost based on baseVal
    switch (newUnit) {
        case 'currency':
            return baseVal;
        case 'ypp':
            return (baseVal / peakPower) * qty;
        case 'panc':
            return (baseVal / panelsNumber) * qty;
        case 'mcc':
            return baseVal * qty;
        default:
            return baseVal;
    }
};

export const getCustomizedCategory = (cost_type_id, cost_recurrence_id) => {
    /* TODO: remover fnc fe-1894  */
    if (cost_recurrence_id === CostsTypeOptions.OPEX) {
        // OPEX
        return CostsOptions.PREVENTIVE_MAINTENANCE;
    }
    switch (cost_type_id) {
        case 1:
            // OPEX
            return CostsOptions.PREVENTIVE_MAINTENANCE;
        case 2:
            // Panels
            return CostsOptions.PANELS;
        case 3:
            // Inverters
            return CostsOptions.INVERTERS;
        default:
            // Anything else is General CAPEX
            return CostsOptions.GENERAL;
    }
};

export const getCategoryByID = (cost_type_id) => {
    switch (cost_type_id) {
        case 1:
            return 'maintenance_costs';
        case 2:
            return 'panels_costs';
        case 3:
            return 'inverters_costs';
        case 4:
            return 'installation_costs';
        case 5:
        default:
            return 'others_costs';
        case 6:
            return 'monitoring_costs';
        case 7:
            return 'structures_costs';
        case 8:
            return 'others_applicants_costs';
        case 9:
            return 'inverters_warranty_costs';
        case 10:
            return 'battery_costs';
        case 11:
            return 'battery_maintenance_costs';
    }
};

export const detailedEfzCostObject = (values, { sizingEfzSystem, featureFlags }) => {
    // order rubrics by order_customized_id
    values.costs.capex = values?.costs?.capex.map((el, idx) => ({
        ...el,
        order_id: idx + 1,
        order_customized_id: idx + 1,
    }));
    let lastOrderIdCapex = values.costs.capex?.length;
    values.costs.opex = values?.costs?.opex.map((el) => {
        lastOrderIdCapex += 1;
        return { ...el, order_id: lastOrderIdCapex, order_customized_id: lastOrderIdCapex };
    });

    const formData = [...values?.costs?.capex, ...values?.costs?.opex];

    // deleted costs
    const newEfzCosts = clone(sizingEfzSystem.costs);
    const rubrics = Object.keys(newEfzCosts).filter((key) => !['opex', 'total_costs', 'capex', 'extra_cost'].includes(key));

    // efzCosts rubrics
    let final_capex = 0;
    let final_opex = 0;

    // reset rubrics
    rubrics.forEach((rubric) => {
        newEfzCosts[rubric].details = []; //reset
    });

    //create new rubrics
    if (isEnvDevFlag(featureFlags['fe-1894'])) {
        for (let idx = 0; idx < formData.length; idx++) {
            const elm = formData[idx];
            let newRubric = {
                ...elm,
                /* upd var -> api+sims  */
                final_cost: Number(elm.totalCost),
                total_costs: Number(elm.totalCost),
                cost_description: `${elm.description ?? ''}`,
                /* upd var -> only FE  */
                cost: Number(elm.cost),
                totalCost: Number(elm.totalCost),
            };

            // sum capex and opex
            if (newRubric.cost_recurrence_id === CostsTypeOptions.CAPEX) {
                final_capex += Number(newRubric.totalCost);
            }
            if (newRubric.cost_recurrence_id === CostsTypeOptions.OPEX) {
                final_opex += Number(newRubric.totalCost);
            }

            newEfzCosts[getCategoryByID(newRubric.cost_type_id)].details.push(newRubric);
        }
    } else {
        // @ts-ignore
        const efzCostsBase = clone(sizingEfzSystem?.costs); /* TODO: remover fe-1894 */
        Object.keys(RUBICS_BY_CATEGORY).forEach((category) => {
            const costs = formData.filter((el) => el.category === category);
            for (let idx = 0; idx < costs.length; idx++) {
                const elm = costs[idx];
                let newRubric = {
                    ...elm,
                    /* upd var -> api+sims  */
                    final_cost: Number(elm.totalCost),
                    total_costs: Number(elm.totalCost),
                    cost_description: `${elm.description ?? ''}`,
                    /* upd var -> only FE  */
                    cost: Number(elm.cost),
                    totalCost: Number(elm.totalCost),
                };

                // sum capex and opex
                if (newRubric.cost_recurrence_id === CostsTypeOptions.CAPEX) {
                    final_capex += Number(newRubric.totalCost);
                }
                if (newRubric.cost_recurrence_id === CostsTypeOptions.OPEX) {
                    final_opex += Number(newRubric.totalCost);
                }

                // case specific GENERAL
                if (category === CostsOptions.GENERAL) {
                    // installation_costs
                    if (efzCostsBase?.installation_costs?.details.find((el) => el.cost_id === newRubric?.cost_id)) {
                        newEfzCosts?.installation_costs?.details.push(newRubric);
                        continue;
                    }

                    // monitoring_costs
                    if (efzCostsBase?.monitoring_costs?.details.find((el) => el.cost_id === newRubric?.cost_id)) {
                        newEfzCosts?.monitoring_costs?.details.push(newRubric);
                        continue;
                    }

                    // others_costs
                    newEfzCosts?.[RUBICS_BY_CATEGORY[category]]?.details.push(newRubric);
                    continue;
                }

                newEfzCosts[RUBICS_BY_CATEGORY[category]].details.push(newRubric);
            }
        });
    }

    // sum capex and opex by rubrics
    rubrics.forEach((rubric) => {
        newEfzCosts[rubric].capex = newEfzCosts[rubric].details.reduce((accumulator, currentValue) => {
            if (currentValue.cost_recurrence_id === CostsTypeOptions.CAPEX) return Number(accumulator) + Number(currentValue.total_costs);
            return isNumberDefined(accumulator) ? accumulator : 0;
        }, 0);
        newEfzCosts[rubric].opex = newEfzCosts[rubric].details.reduce((accumulator, currentValue) => {
            if (currentValue.cost_recurrence_id === CostsTypeOptions.OPEX) return Number(accumulator) + Number(currentValue.total_costs);
            return isNumberDefined(accumulator) ? accumulator : 0;
        }, 0);
        newEfzCosts[rubric].total_costs = newEfzCosts[rubric].capex + newEfzCosts[rubric].opex;
    });

    // update values
    newEfzCosts.capex = final_capex;
    newEfzCosts.opex = final_opex;
    newEfzCosts.total_costs = final_capex + final_opex;

    return newEfzCosts;
};

export const getCustomizedCostsDataObject = (values, featureFlags) => {
    const { efzEditedCosts, efzCosts, option, productionCurve, productionEndpoint, designImage } = values;

    return {
        costs: detailedEfzCostObject(
            { costs: efzEditedCosts },
            {
                sizingEfzSystem: efzCosts,
                featureFlags,
            }
        ),
        option,
        productionCurve,
        productionEndpoint,
        designImage,
    };
};

export const getCustomizedCostsFromDataObject = (data) => {
    /* TODO: remover esta fnc  */
    // is in the old format?
    if (!Object.keys(data).includes('costs') && Object.keys(data).includes(RUBICS_BY_CATEGORY[CostsOptions.PANELS])) {
        return data;
    }

    return data?.costs ?? null;
};

export const getCustomizedCostsSizindAndProducrionValuesFromDataObject = (data) => {
    //if (!Object.keys(data).includes('costs')) return null

    const response = clone(data);
    if (Object.keys(data).includes('costs')) delete data.costs;

    return response;
};

export const customizeMappedUnits = [UNITS_AVAILABLE.CURRENCY, UNITS_AVAILABLE.YPP, UNITS_AVAILABLE.PANC, UNITS_AVAILABLE.MCC];

export const efzCustomizeMappedUnits = [UNITS_AVAILABLE.CURRENCY, UNITS_AVAILABLE.YPP, UNITS_AVAILABLE.PANC];

export const conditionsToMakeValidPanelsRequest = (payload) => {
    try {
        let centroidsCondition = true;
        let areasConditions = true;

        const { areas, isV3 } = payload;

        // for (const areaCentroid of areas_centroid) {
        //     if (
        //         !isFieldDefined(areaCentroid) ||
        //         isNaN(areaCentroid) ||
        //         !isNumber(areaCentroid)
        //     ) centroidsCondition = false;
        // }

        for (const area of areas) {
            if (area?.panel_rows?.length === 0) areasConditions = false;
            else
                for (const panelRow of area?.panel_rows) {
                    if (isV3) {
                        if (panelRow.position.length === 0) areasConditions = false;
                    } else {
                        if (panelRow.positions.length === 0) areasConditions = false;
                    }
                }
        }

        return centroidsCondition && areasConditions;
    } catch (error) {
        return false;
    }
};

export const peakPowerUnit = (companyProfileId) => {
    switch (companyProfileId) {
        case getCompanyProfileIds().ROMANDE:
        case getCompanyProfileIds().PROFILE_EDP_FR:
            return UNITS_AVAILABLE.KWC;
        case getCompanyProfileIds().PROFILE_BASE_USA:
            return UNITS_AVAILABLE.KWDC;
        default:
            return UNITS_AVAILABLE.KWP;
    }
};

export const panelPeakPowerUnit = (companyProfileId) => {
    switch (companyProfileId) {
        case getCompanyProfileIds().PROFILE_BASE_USA:
            return UNITS_AVAILABLE.WDC;
        default:
            return UNITS_AVAILABLE.WP;
    }
};

export const currencyPerPeakPowerUnit = (companyProfileId) => {
    switch (companyProfileId) {
        case getCompanyProfileIds().PROFILE_BASE_USA:
            return UNITS_AVAILABLE.YPDC;
        default:
            return UNITS_AVAILABLE.YPP;
    }
};

export const getKpiLabel = (optimizerTypeId) => {
    switch (optimizerTypeId) {
        case OPTIMIZATION_PRODUCT_IDS.MIN_CLIENT_TARIFF:
            return <IntlMessages id="label.minimizeClientTariff" />;
        case OPTIMIZATION_PRODUCT_IDS.MAX_CLIENT_SAVINGS:
            return <IntlMessages id="label.maximizeClientSavings" />;
        default:
            break;
    }
};

export const hasFinancialKpis = (configKpis) => {
    const { INVESTMENT, SAVINGS, SPECIFIC_PRICE, RETURN } = PROJECT_SUMMARY_KPIS;

    const financialKpis = configKpis.filter((kpi) => [INVESTMENT, SAVINGS, SPECIFIC_PRICE, RETURN].includes(kpi.name));

    return financialKpis.some((kpi) => kpi.visible);
};

export const hasTechnicalKpis = (configKpis) => {
    const { ESTIMATED_ANNUAL_PRODUCTION, CONSUMPTION_REDUCTION, INJECTION } = PROJECT_SUMMARY_KPIS;

    const technicalKpis = configKpis.filter((kpi) => [ESTIMATED_ANNUAL_PRODUCTION, CONSUMPTION_REDUCTION, INJECTION].includes(kpi.name));

    return technicalKpis.some((kpi) => kpi.visible);
};

export const companyProflesWithInjectionTariffBySims = (companyProfileId) =>
    [
        getCompanyProfileIds().EDP_IT,
        getCompanyProfileIds().EDP_ES,
        getCompanyProfileIds().ROMANDE,
        getCompanyProfileIds().PROFILE_BASE_IT,
    ].includes(companyProfileId);
//#region SPV1.0
//Calculate Total Panel

export function calcTotalPanels(areaGroup, slope, area_reduction_elements, options) {
    const { pvAreaReductionCoefficients, settingsCompanyByRange, mStructureData, panel_id } = options;

    const { area_util_coplanar } = settingsCompanyByRange;

    let paineis_area = settingsCompanyByRange?.paineis_area;
    if (settingsCompanyByRange?.paineis?.length > 0 && isFieldDefined(panel_id)) {
        let { altura, largura } = settingsCompanyByRange?.paineis.find((el) => el.id === panel_id);
        paineis_area = altura * largura;
    }

    // area uitl triagular <= 15
    let area_util_triangular = settingsCompanyByRange?.area_util_triangular;
    // se for estrutura a inclinaçao da estrutura faz com o que os proprios paineis façam sombra, o que reduz a area util
    const is_triangular = mStructureData?.tipo_estrutura_id === MOUNTING_STRUCTURES_TYPES.TRIANGULAR;
    const is_ground = mStructureData?.tipo_estrutura_id === MOUNTING_STRUCTURES_TYPES.GROUND;

    //find coeficiente_area_util
    let _coefficientsAreaUtil =
        isFieldDefined(area_reduction_elements) ?
            pvAreaReductionCoefficients?.find(function (x) {
                return (
                    x?.[SYSTEM_COMPOSITION.COEFFICIENTS_AREAS_UTILS.OTHERS] ===
                        area_reduction_elements?.[SYSTEM_COMPOSITION.COEFFICIENTS_AREAS_UTILS.OTHERS] &&
                    x?.[SYSTEM_COMPOSITION.COEFFICIENTS_AREAS_UTILS.CHILLERS] ===
                        area_reduction_elements?.[SYSTEM_COMPOSITION.COEFFICIENTS_AREAS_UTILS.CHILLERS] &&
                    x?.[SYSTEM_COMPOSITION.COEFFICIENTS_AREAS_UTILS.PLATBANS] ===
                        area_reduction_elements?.[SYSTEM_COMPOSITION.COEFFICIENTS_AREAS_UTILS.PLATBANS] &&
                    x?.[SYSTEM_COMPOSITION.COEFFICIENTS_AREAS_UTILS.SURROUNDING] ===
                        area_reduction_elements?.[SYSTEM_COMPOSITION.COEFFICIENTS_AREAS_UTILS.SURROUNDING]
                );
            })
        :   { coefficient: null };

    let totalPanelsNumber;
    const fator_ajuste = SETTINGS.FATOR_AJUSTE;

    //#region  is_triangular
    if (is_triangular || is_ground) {
        area_util_triangular =
            parseInt(slope) > SYSTEM_COMPOSITION.TRIANGULAR_GRADE_LIMIT ?
                settingsCompanyByRange?.area_util_triangular_apos_15_graus
            :   area_util_triangular;
    }
    //#endregion is_triangular

    // area ajuste representa o coeficiente de area util, tendo em conta os elementos no telhado e correadores de passagem
    let area_ajuste =
        !!area_reduction_elements && !!_coefficientsAreaUtil?.coefficient ? _coefficientsAreaUtil?.coefficient
        : is_triangular || is_ground ? area_util_triangular
        : area_util_coplanar; //coeficiente_area_util

    // converte ângulo para radianos
    let alpha = mathRadians(parseInt(slope));
    let percentage_util = 1;
    let cos_alpha = Math.cos(alpha);
    let sin_alpha = Math.sin(alpha);
    let area_util = areaGroup;

    // se for estrutura a inclinaçao da estrutura faz com o que os proprios paineis façam sombra, o que reduz a area util
    if (is_triangular || is_ground) {
        percentage_util = cos_alpha / (cos_alpha + fator_ajuste * sin_alpha);
        area_util = percentage_util * area_util;
    } else {
        // a area util sera mais reduzida por causa do angulo,  a hipotenusa é o que interessa, a area projectada será um dos catetos
        // isto significa que quanto maior o ângulo, maiar a area
        area_util = area_util / cos_alpha;
    }

    // acrescenta 20% a area util
    area_util = area_util * area_ajuste;

    totalPanelsNumber = mathRound(area_util / paineis_area, 0);

    // totalPanelsNumber = getNumberPanelsByArea(range_id, totalPanelsNumber);

    return totalPanelsNumber;
}

export const getRemotePayload = (inputs) => {
    return {
        [REMOTE_TABS_INPUTS.COEFFICIENT]:
            inputs?.[REMOTE_TABS_INPUTS.COEFFICIENT] ? parseFloat(inputs?.[REMOTE_TABS_INPUTS.COEFFICIENT]) : null,
        [REMOTE_TABS_INPUTS.NEIGHBORS]: inputs?.[REMOTE_TABS_INPUTS.NEIGHBORS].map((entry) => ({
            [REMOTE_TABS_INPUTS.DESCRIPTION]: entry?.[REMOTE_TABS_INPUTS.DESCRIPTION] ?? '',
            [REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION]:
                entry?.[REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION] ? parseFloat(entry?.[REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION]) : null,
            [REMOTE_TABS_INPUTS.COEFFICIENT]:
                entry?.[REMOTE_TABS_INPUTS.COEFFICIENT] ? parseFloat(entry?.[REMOTE_TABS_INPUTS.COEFFICIENT]) : null,
        })),
        [REMOTE_TABS_INPUTS.ENTERPRISES]: inputs?.[REMOTE_TABS_INPUTS.ENTERPRISES].map((entry) => ({
            [REMOTE_TABS_INPUTS.DESCRIPTION]: entry?.[REMOTE_TABS_INPUTS.DESCRIPTION] ?? '',
            [REMOTE_TABS_INPUTS.ACTIVITY_SECTOR]: entry?.[REMOTE_TABS_INPUTS.ACTIVITY_SECTOR] ?? '',
            [REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION]:
                entry?.[REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION] ? parseFloat(entry?.[REMOTE_TABS_INPUTS.ANNUAL_CONSUMPTION]) : null,
            [REMOTE_TABS_INPUTS.COEFFICIENT]:
                entry?.[REMOTE_TABS_INPUTS.COEFFICIENT] ? parseFloat(entry?.[REMOTE_TABS_INPUTS.COEFFICIENT]) : null,
        })),
    };
};
//#endregion SPV1.0
