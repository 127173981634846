import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';

// Components
import IntlMessages from 'components/util/IntlMessages';
import Tooltip from 'components/@efz/Tooltip';

// Contexts
import { SidebarContext } from 'contexts/sidebarContext';

const SidebarButton = ({ button }) => {
    const history = useHistory();

    const { setButtonSelected, buttonSelected, isSidebarPermanent, hasSolutionsButton, sidebarOpen, setShowErrorDevice } =
        useContext(SidebarContext);

    const isMobileDevice =
        /Mobile|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|webOS/i.test(navigator.userAgent) ||
        (/Macintosh/i.test(navigator.userAgent) && 'ontouchstart' in window);

    const clickHandler = () => {
        const clickFlag = !isMobileDevice;
        if (button.canBeAccessed(clickFlag)) {
            history.push(button.navigationLabel);
            setButtonSelected(button.name);
        } else {
            setShowErrorDevice({
                title: 'popup.backoffice.error.device.title',
                content: 'popup.backoffice.error.device.content',
            });
        }
    };
    return (
        <Tooltip
            title={<IntlMessages id={button.label} />}
            style={{ display: sidebarOpen ? 'none' : 'auto' }}
            type="default"
            placement="top-start"
            className="sidebar-button-tooltip"
            arrowPlacement="bottom-start"
        >
            <span>
                <IconButton
                    className={`sidebar-button sidebar-button${isSidebarPermanent ? '-permanent' : ''}${buttonSelected === button.name ? '-selected' : ''}${button.disabled(hasSolutionsButton) ? '-disabled' : ''} ${sidebarOpen ? 'open' : ''}`}
                    disabled={button.disabled(hasSolutionsButton)}
                    onClick={clickHandler}
                    disableRipple
                >
                    <div
                        className={`sidebar-icon sidebar-icon-${button.name}${buttonSelected === button.name ? '-selected' : ''}${button.disabled(hasSolutionsButton) ? '-disabled' : ''}`}
                    >
                        {button.icon}
                    </div>
                    <span>
                        <IntlMessages id={button.label} />
                    </span>
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default SidebarButton;
