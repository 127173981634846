import { useContext, useState, useEffect } from 'react';

// Components
import NegotiationPPa from './Negotiation';
import OfferSummaryPPa from './OfferSummary';

// Contexts
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';
import Loading from 'components/core/Loading';
import { BUSINESS_MODEL_ELIGIBLE, SIZING_TYPE_IDS } from 'constants/businessModels';
import { useFormContext, useWatch } from 'react-hook-form';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import IntlMessages from 'components/util/IntlMessages';
import Alert from 'components/@efz/Alert';
import { IBmDetailedForm } from 'components/BusinessModels';

const ProjectSummaryServicePPaDetailed = () => {
    const { bmState, setBMEventHandler } = useContext(OfferEditionContext);

    const [fullDirty, setFullDirty] = useState(false);

    const methods = useFormContext<IBmDetailedForm>();
    const hasErrorOnNetSavingsEligibility = bmState?.kpis?.negotiation?.hasErrorOnNetSavingsEligibility;
    const hasAuditProposal =
        (isFieldDefined(bmState?.businessModelSelected) &&
            bmState?.businessModelSelected?.sizing_type_id === SIZING_TYPE_IDS.DETAILED_FULL &&
            bmState?.kpis?.negotiation.status_risk_premium_id === BUSINESS_MODEL_ELIGIBLE.NO) ||
        hasErrorOnNetSavingsEligibility;

    const watchAllFields = methods.watch();
    const watchRubrics = useWatch({
        control: methods.control,
        name: 'rubrics',
    });

    const updateValues = () => {
        if (methods.formState.isDirty && !fullDirty) setFullDirty(true);
        setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_VALUES, {
            // @ts-ignore
            values: methods.getValues(),
            sliderReady: methods.formState.isDirty || fullDirty ? false : true,
        });
    };

    useEffect(() => {
        updateValues();
    }, [watchAllFields?.monthly_payment_option_id, watchAllFields?.annualEvoFee, watchAllFields?.extraCost, watchRubrics]); // eslint-disable-line

    if (!bmState.businessModelSelected) return <Loading />;
    return (
        <div className="bm-detailed">
            {!hasErrorOnNetSavingsEligibility && <OfferSummaryPPa />}
            {!hasAuditProposal && bmState?.sliderReady && isDefined(bmState.offerEditionValues.monthly_payment_option_id) && (
                <NegotiationPPa />
            )}
            {bmState?.sliderReady && hasAuditProposal && (
                <Alert severity={'error'}>
                    <IntlMessages
                        id={
                            hasErrorOnNetSavingsEligibility ?
                                'page.businessModels.notEligible.reason.not_net_savings_eligibility'
                            :   'page.businessModels.auditProposal.info'
                        }
                    />
                </Alert>
            )}
        </div>
    );
};

export default ProjectSummaryServicePPaDetailed;
