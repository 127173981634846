import { z } from 'zod';
import { PRODUCT_IDS } from 'constants/products';

const coordinatesAvgSchema = z.object({
    lat: z.number(),
    long: z.number(),
    region_gd_id: z.number().optional(),
});

const kitsSchema = z.object({
    id: z.number(),
    areas: z.array(z.number()),
});

const areaSchema = z.object({
    id: z.number(),
    area: z.number(),
    orientation: z.number(),
    coordinates_avg: coordinatesAvgSchema,
    panels_number: z.number(),
    region_gd_id: z.number().nullable().optional(),
    mounting_structure_id: z.number(),
    selected: z.boolean().optional(),
    perimeter_area: z.number(),
    panels_number_possible: z.number(),
    panels_number_kit: z.number().optional(),
    panels_number_excluded: z
        .number()
        .int()
        .transform((val) => parseInt(val.toString())),
    kit_id: z.number().optional(),
    slope: z.number(),
    panels_max_number: z.number().optional(),
    aspect: z.number(),
    range_id: z.number().optional(),
    region_radiation: z.any().optional(),
    price_zone_name: z.string().optional(),
    npv: z.number().optional(),
    total_loss: z.number().optional(),
    max_tec_area_panels: z.number().optional(),
    max_kwp_area: z.number().nullable().optional(),
    mounting_structure_type_id: z.number(),
});

export const simulationPayloadSchema = z.object({
    is_scaling: z.boolean(),
    tipo_produto_id: z.union([z.literal(PRODUCT_IDS.SPV), z.literal(PRODUCT_IDS.SPVSB)]),
    facility: z.object({
        id: z.union([z.string().regex(/^\d+$/), z.number()]).nullable(),
    }),
    inputs: z.object({
        areas: z.array(areaSchema),
        battery: z.record(z.any(), z.any()).optional(),
        coordinates_avg: coordinatesAvgSchema,
        injection_tariff: z.number().nullable().optional(),
        injection_tariff_distributor: z.any().optional(),
        is_customize: z.boolean().optional(),
        inverters_combination: z.array(z.any()),
        isChangeBattery: z.boolean().optional(),
        is_kits: z.boolean().optional(),
        isManualDrawing: z.boolean().optional(),
        isSimpleMode: z.boolean().optional(),
        max_tec_panels: z.number(),
        min_tec_panels: z.number(),
        network_sale: z.boolean(),
        limit_network_sale: z.union([z.number(), z.boolean()]),
        range_id: z.number(),
        panel_id: z.number(),
        total_areas: z.number(),
        total_panels: z.number(),
        remuneration_type_id: z.number().nullable().optional(),
        kits: z.array(kitsSchema).optional(),
        total_perimeter_areas: z.number().nullable(),
        remote: z.record(z.any(), z.any()).nullable().optional(),
        offer_edition: z.record(z.any(), z.any()).optional(),
        locationAddress: z.record(z.any(), z.any()).optional(),
        prod_params: z.record(z.any(), z.any()).optional(),
        // power increase
        is_power_increase: z.boolean().optional(),
        old_proposal_id: z.number().optional(),
        previous_inverter_power_kw: z.number().optional(),
        previous_peak_power: z.number().optional(),
        // reformulation
        reformulation: z
            .object({
                id: z.union([z.string().regex(/^\d+$/), z.number()]),
            })
            .optional(),
        user_id: z.union([z.string().regex(/^\d+$/), z.number()]).optional(),
    }),
});

export const optimizerPayloadSchema = z.object({
    is_scaling: z.boolean(),
    tipo_produto_id: z.union([z.literal(PRODUCT_IDS.SPV), z.literal(PRODUCT_IDS.SPVSB)]),
    facility: z.object({
        id: z.union([z.string().regex(/^\d+$/), z.number()]).nullable(),
    }),
    inputs: z.object({
        areas: z.array(areaSchema),
        battery: z.record(z.any(), z.any()).optional(),
        coordinates_avg: coordinatesAvgSchema,
        injection_tariff: z.number().nullable().optional(),
        injection_tariff_distributor: z.any().optional(),
        is_customize: z.boolean().optional(),
        inverters_combination: z.array(z.any()).optional(),
        isChangeBattery: z.boolean().optional(),
        is_kits: z.boolean().optional(),
        isManualDrawing: z.boolean().optional(),
        isSimpleMode: z.boolean().optional(),
        max_tec_panels: z.number(),
        min_tec_panels: z.number(),
        network_sale: z.boolean(),
        limit_network_sale: z.union([z.number(), z.boolean()]),
        range_id: z.number(),
        panel_id: z.number(),
        total_areas: z.number(),
        total_panels: z.number(),
        remuneration_type_id: z.number().nullable().optional(),
        kits: z.array(kitsSchema).optional(),
        total_perimeter_areas: z.number().nullable(),
        remote: z.record(z.any(), z.any()).nullable().optional(),
        prod_params: z.record(z.any(), z.any()).optional(),
    }),
});
