import { styled } from 'styled-components';
import { media } from 'styles/utils/mediaQueries';

export const StyledLogin = styled.div<{
    $isS2CVersion: boolean;
}>`
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    background-color: ${(props) => (!props.$isS2CVersion ? 'unset' : 'var(--background-color)')};

    background-image: ${(props) => (props.$isS2CVersion ? `url('assets/images/auth/s2c/s2c_background.png')` : 'unset')};
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
`;

export const StyledLoginSVGBG = styled.section`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-flow: row nowrap;
    flex: 1;

    .login-svg-ng {
        width: 100%;
        svg {
            height: calc(100vh - 3.25rem); // 3.25rem -> footer
            width: 100%;
        }
    }

    .login-svg-s2c {
        display: flex;
        width: 40vw;
        align-items: flex-start;
        justify-content: flex-start;
        background-image: url('assets/images/auth/s2c/s2c-logo-left.png');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: 29vw;
        margin-top: 40px;
    }
`;

export const StyledLoginContainer = styled.section`
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 100%;
`;

export const StyledLoginContent = styled.div`
    @include display-flex(flex, column, nowrap);
    background-color: var(--paper-color);
    border: 1px solid #f5f5f5;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    ${media.tablet`
        min-width: 70vw;
    `}

    ${media.mobile`
        min-width: 90vw;
    `}

    .login-header {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            max-width: 13.25rem;
        }
    }

    .login-header-s2c {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem 0;
        color: black;
        font-size: 1.875rem;
        font-family: 'Save2Compete-alt';

        ${media.desktop`
        padding: 2rem 0;`}

        img {
            width: 20rem;
        }
    }

    .login-body {
        display: flex;
        flex-flow: column nowrap;
        padding: 0 3.125rem 2rem 3.125rem;
        align-items: center;

        ${media.tablet`
            padding: 0 2.5rem 1.5rem 2.5rem;
        `}

        h4 {
            font-size: 0.875rem;
            line-height: 19.07px;
            color: var(--text-color);
            margin-bottom: 1.5rem;
        }

        .link-back-login {
            margin-top: 10px;
        }

        .login-button {
            padding: 0.625rem 2.125rem;

            &.isS2C {
                background-color: $efz-red-600 !important;

                &:hover {
                    background-color: $efz-red-400 !important;
                }
            }
        }
    }
`;

export const StyledLoginForm = styled.form`
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .reset-password-text {
        h4 {
            margin: 0;

            a {
                //default value for forgot your password link
                font-weight: $font-weight-light;
                font-size: 0.875rem !important;
            }
        }

        &.isS2C {
            h4 a {
                color: $efz-red-600 !important;
            }
        }
    }
`;

export const StyledLoginInputsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    > div {
        flex: 1;
    }
    .reset-form {
        font-weight: $font-weight-light;
        font-size: 0.875rem !important;
        margin-left: 1rem;
        color: var(--primary-color);

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`;
