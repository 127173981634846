import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { TMarginNegotiation } from 'interfaces/businessModels/upfront';
import { TRubric } from 'interfaces/rubric';

export type TInstallments = typeof PAYMENT_MODELS_IDS.INSTALLMENTS;

export type TInstallmentsValues = {
    kpis_per_margin: {
        annual_saving: number;
        discount: number;
        investment: number;
        margin: number;
        presentation_discount: number;
        presentation_margin: number;
        is_default: boolean;
        monthly_payments_fee: number;
        monthly_payments_number: number;
        price?: number;
    }[];
    margin_negotiation: TMarginNegotiation;
    marginSelected?: number;
    offerEdition: TInstallmentsOfferEdition;
    costs: {
        baseCost: number;
        offerDetailsCost: number;
        marginCost: number;
        grantsCost: number;
        totalCost: number;
    };
    rubrics: {
        cost: number;
        values: TRubric[];
    };
    syncKpis?: {
        [key: string]: any;
        negotiation: {
            [key: string]: any;
            hasErrorOnNetSavingsEligibility: boolean;
            status_risk_premium_id: number;
        };
    };
    clientContactInfo?: {
        name: string;
        email: string;
    };
    id_crm?: string;
};

export type TInstallmentsOfferEdition = {
    extra_cost?: number;
};

export enum InstallmentsEvents {
    FETCH_INSTALLMENTS_BM = 'FETCH_INSTALLMENTS_BM',
    SET_INSTALLMENTS_BM = 'SET_INSTALLMENTS_BM',
    SET_INSTALLMENTS_MARGIN = 'SET_INSTALLMENTS_MARGIN',
    SET_INSTALLMENTS_PROPOSAL = 'SET_INSTALLMENTS_PROPOSAL',
    SET_INSTALLMENTS_PROPOSAL_READY = 'SET_INSTALLMENTS_PROPOSAL_READY',
    UPDATE_INSTALLMENTS_ADDITIONAL_DATA = 'UPDATE_INSTALLMENTS_ADDITIONAL_DATA',
    SET_INSTALLMENTS_RUBRIC_COST = 'SET_INSTALLMENTS_RUBRIC_COST',
    SET_INSTALLMENTS_EXTRA_COST = 'SET_INSTALLMENTS_EXTRA_COST',
}

export type TInstallmentsEventsValues<Event> =
    Event extends typeof InstallmentsEvents.FETCH_INSTALLMENTS_BM ? null
    : Event extends typeof InstallmentsEvents.SET_INSTALLMENTS_BM ? TInstallmentsValues
    : Event extends typeof InstallmentsEvents.SET_INSTALLMENTS_BM ? number
    : Event extends typeof InstallmentsEvents.SET_INSTALLMENTS_PROPOSAL ? null
    : Event extends typeof InstallmentsEvents.SET_INSTALLMENTS_PROPOSAL_READY ? boolean
    : any;
