import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { TRubric } from 'interfaces/rubric';
import { GrantsAmmountsType, TGrants } from './upfront';

export type TPPa = typeof PAYMENT_MODELS_IDS.SERVICE_PPA;
export type TPpAValues = {
    energyPrice?: number;
    marginSelected: TPPaEnergyPriceIRRAnnualSaving;
    grants: { totalGrants: { prc: number; currency: number }; grants: TGrants[]; hasGrants: boolean };
    offerEdition: { extra_cost: number };
    rubrics: TRubric[];
    totalCost: number;
    energy_price_irr_annual_savings: TPPaEnergyPriceIRRAnnualSaving[];
    kpis_per_margin: {
        annual_saving: number;
        avg_tariff: number;
        energy_price: false;
        irr_prc: number;
        prc_of_current_price: number;
    }[];
    syncKpis?: object;
    id_crm?: string;
};

export enum PPaEvents {
    FETCH_PPA_BM = 'FETCH_PPA_BM',
    SET_PPA_BM = 'SET_PPA_BM',
    SET_PPA_MARGIN = 'SET_PPA_MARGIN',
    SET_PPA_PROPOSAL_READY = 'SET_PPA_PROPOSAL_READY',
    SET_PPA_PROPOSAL = 'SET_PPA_PROPOSAL',
    ADD_PPA_GRANT = 'ADD_PPA_GRANT',
    DELETE_PPA_GRANT = 'DELETE_PPA_GRANT',
    SET_DESCRIPTION_GRANT = 'SET_DESCRIPTION_GRANT',
    SET_AMMOUNT_GRANT = 'SET_AMMOUNT_GRANT',
    UPDATE_PPA_GRANTS = 'UPDATE_PPA_GRANTS',
    SET_EXTRA_COST = 'SET_EXTRA_COST',
    SET_PPA_RUBRIC_COST = 'SET_PPA_RUBRIC_COST',
    UPDATE_PPA_VALUES_BM = 'UPDATE_PPA_VALUES_BM',
    SET_PPA_ENERGY_PRICE = 'SET_PPA_ENERGY_PRICE',
}

export type TPPaEventsValues<Event> =
    Event extends typeof PPaEvents.FETCH_PPA_BM ? null
    : Event extends typeof PPaEvents.SET_PPA_BM ? null
    : Event extends typeof PPaEvents.SET_PPA_MARGIN ? TPPaEnergyPriceIRRAnnualSaving
    : Event extends typeof PPaEvents.ADD_PPA_GRANT ? null
    : Event extends typeof PPaEvents.DELETE_PPA_GRANT ? number
    : Event extends typeof PPaEvents.SET_DESCRIPTION_GRANT ? { description: string; id: number }
    : Event extends typeof PPaEvents.SET_AMMOUNT_GRANT ? { amount: number; ammountType: GrantsAmmountsType; id: number }
    : Event extends typeof PPaEvents.UPDATE_PPA_GRANTS ? null
    : Event extends typeof PPaEvents.SET_PPA_RUBRIC_COST ? { selected: boolean; id: number }
    : any;

export type TPPaEnergyPriceIRRAnnualSaving = {
    annual_saving: number;
    avg_tariff: number;
    energy_price: number;
    irr_prc: number;
    prc_of_current_price: number;
    cost: number;
    value: number;
};
