/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @see https://reactjs.org/docs/hooks-custom.html
 *
 * @version 20210712
 * @since 20210712 Initial release
 *
 */

import { useState, useEffect, useCallback, useContext } from 'react';
import { OK } from 'constants/statusCodeHttp';
import { postSendToCRM } from 'api/processes';
import { STATUS_CRM } from 'constants/proposal';
import { getSimulationsByID } from 'api/proposal';
import { isDefined } from 'services/util/auxiliaryUtils';
import { AUTO_SEND_CRM_ON_PROPOSAL_DOWNLOAD } from 'constants/processes';
import { notify } from 'services/@efz/notify';
import IntlMessages from 'components/util/IntlMessages';
import { UserContext } from 'contexts/userContext';
import { PRODUCT_IDS } from 'constants/products';
import { getCompanyProfileIds } from 'services/user';
import { Nullable, TIntl } from 'types/utils';
import { useUserStore } from 'store/user';

interface IDataSendToCRM {
    can_send_to_crm: boolean;
    data_envio_para_crm: string;
    status_crm: string;
    disabled: boolean;
}
const useProposalDetails = (proposalID, contractAvailable = true, productID = null) => {
    const isUserDemo = useUserStore((state) => state.user?.is_demo);

    const { userChannelIDGroup, companyProfileId } = useContext(UserContext);
    const [isRequesting, setIsRequesting] = useState(false);
    const [isRequestingSimID, setIsRequestingSimID] = useState(false);
    const [dataSendToCRM, setDataSendToCRM] = useState<Nullable<IDataSendToCRM>>(null);
    const [proposalDetails, setProposalDetails] = useState(null);
    const [caseSendingAutomatically, setCaseSendingAutomatically] = useState(false);
    const [errorAutomatically, setErrorAutomatically] = useState(null);

    const fetchGetSimulationsByID = useCallback(async () => {
        try {
            if (isDefined(proposalID) && contractAvailable) {
                const _caseSendingAutomatically =
                    !isUserDemo && PRODUCT_IDS.SPV === Number(productID) && [getCompanyProfileIds().EDP_ES].includes(companyProfileId);

                setCaseSendingAutomatically(_caseSendingAutomatically);

                setIsRequestingSimID(true);
                const rspDataSims = await getSimulationsByID(proposalID);
                if (rspDataSims?.data?.status !== OK) {
                    setIsRequestingSimID(false);
                    return;
                }

                if (rspDataSims?.data?.status === OK) {
                    setIsRequestingSimID(false);
                    if (_caseSendingAutomatically && !isDefined(rspDataSims.data.data?.status_crm)) autoSendToCRM(rspDataSims.data.data.id);
                }
                //#region caseSendingAutomatically

                //#endregion

                setProposalDetails(rspDataSims.data.data);
                if (rspDataSims.data.data?.status_crm) {
                    setDataSendToCRM({
                        can_send_to_crm: rspDataSims.data.data?.can_send_to_crm,
                        data_envio_para_crm: rspDataSims.data.data?.data_envio_para_crm,
                        status_crm: rspDataSims.data.data?.status_crm,
                        disabled: [STATUS_CRM.SUBMITTED, STATUS_CRM.COMPLETED].includes(rspDataSims.data.data.status_crm),
                    });
                }
            }
        } catch (error) {
            setIsRequestingSimID(false);
        }
    }, [proposalID, contractAvailable, companyProfileId]); // eslint-disable-line

    const fetchSendToCRM = async (_proposalID) => {
        try {
            if (!dataSendToCRM) return;
            if (isRequesting || [STATUS_CRM.COMPLETED, STATUS_CRM.SUBMITTED].includes(dataSendToCRM.status_crm)) return;
            setIsRequesting(true);
            const rspData = await postSendToCRM({ processId: _proposalID });
            if (rspData?.data?.message !== STATUS_CRM.SUBMITTED) {
                notify(<IntlMessages id={`${(rspData?.data?.message as TIntl) ?? 'page.error.403.unexpected'}`} />, 'error');
                setIsRequesting(false);
            }

            if (rspData?.data?.status !== OK) {
                setIsRequesting(false);
            }

            setDataSendToCRM({
                can_send_to_crm: rspData.data.data?.can_send_to_crm,
                data_envio_para_crm: rspData.data.data?.createdDateTime,
                status_crm: rspData.data.data?.status_crm,
                disabled: [STATUS_CRM.SUBMITTED, STATUS_CRM.COMPLETED].includes(rspData.data.data?.status_crm),
            });

            setIsRequesting(false);
        } catch (error) {
            setIsRequesting(false);
        }
    };

    const autoSendToCRM = async (_proposalID) => {
        try {
            setIsRequesting(true);
            const rspData = await postSendToCRM({ processId: _proposalID });
            if (rspData?.data?.message !== STATUS_CRM.SUBMITTED) {
                setErrorAutomatically(rspData?.data?.message);
                setIsRequesting(false);
            }

            if (rspData?.data?.status !== OK) {
                setIsRequesting(false);
            }

            setDataSendToCRM({
                can_send_to_crm: rspData.data.data?.can_send_to_crm,
                data_envio_para_crm: rspData.data.data?.createdDateTime,
                status_crm: rspData.data.data?.status_crm,
                disabled: [STATUS_CRM.SUBMITTED, STATUS_CRM.COMPLETED].includes(rspData.data.data?.status_crm),
            });

            setIsRequesting(false);
        } catch (error) {
            setIsRequesting(false);
        }
    };

    useEffect(() => {
        fetchGetSimulationsByID();
    }, [fetchGetSimulationsByID]);

    return {
        errorAutomatically,
        isRequesting,
        isRequestingSimID,
        fetchSendToCRM,
        dataSendToCRM,
        // @ts-ignore
        sendWhenDocsDownload: dataSendToCRM?.can_send_to_crm && AUTO_SEND_CRM_ON_PROPOSAL_DOWNLOAD.includes(Number(userChannelIDGroup)),
        proposalDetails,
        caseSendingAutomatically,
    };
};

export default useProposalDetails;
