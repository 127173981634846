import { MODULES } from 'constants/products';
import { YUP_CUSTOM } from 'services/yup';
import validate from 'services/util/validate';
//Constants
export const MAX_CE = {
    TEP: 2000,
    WORKERS: 100000,
    NET_AREA: 100000,
    NET_AREA_RECS: 20000,
    ANNUAL_CONSUMPTION: 2000,
    NUM_ANNUAL_CONSUMPTION: 100000000,
};

//InputsDefault
export const INPUTS_DEFAULTS = {
    certification_type_id: null,
    facilities_annual_consumptions: [],
    workers_number: null,
    net_area: null,
    type_id: null,
    has_past_certification_id: null,
    last_certification_date: null,
    has_past_audit_id: null,
    last_audit_date: null,
    activity_sector_id: null,
    is_for_sale_or_rental_id: null,
    facilities_to_propose_id: null,
    company_type_id: null,
    extra_picked_id: 1,
    arce_periodicity_id: 1,
    contact_info: {
        name: null,
        phone_number: null,
        email: null,
    },
    energy_sources_annual_consumptions: [
        { source: 'electric', annual_consumption: 0, unit: 'kWh', annual_consumption_in_toe: 0 },
        { source: 'fueloil', annual_consumption: 0, unit: 'kg', annual_consumption_in_toe: 0 },
        { source: 'diesel', annual_consumption: 0, unit: 'kg', annual_consumption_in_toe: 0 },
        { source: 'pellets', annual_consumption: 0, unit: 'kg', annual_consumption_in_toe: 0 },
        { source: 'natural_gas', annual_consumption: 0, unit: 'kWh', annual_consumption_in_toe: 0 },
        { source: 'propane', annual_consumption: 0, unit: 'kg', annual_consumption_in_toe: 0 },
        { source: 'lpg', annual_consumption: 0, unit: 'kg', annual_consumption_in_toe: 0 },
    ],
};

export const INPUT_NAMES = {
    COMPANY_TYPE_ID: 'company_type_id', //RECOMMENDATION
    FACILITIES_TO_PROPOSE_ID: 'facilities_to_propose_id', //RECOMMENDATION
    ACTIVITY_SECTOR_ID: 'activity_sector_id', //RECOMMENDATION
    CERTIFICATION_TYPE_ID: 'certification_type_id', //PORTFOLIO
    FACILITIES_ANNUAL_CONSUMPTIONS: 'facilities_annual_consumptions', //RECOMMENDATION && PORTFOLIO
    WORKERS_NUMBER: 'workers_number', //RECOMMENDATION && PORTFOLIO
    ENERGY_SOURCES_ANNUAL_CONSUMPTIONS: 'energy_sources_annual_consumptions', //RECOMMENDATION && PORTFOLIO
    NET_AREA: 'net_area', //RECOMMENDATION && PORTFOLIO
    TYPE_ID: 'type_id', //RECOMMENDATION && PORTFOLIO
    IS_FOR_SALE_OR_RENTAL_ID: 'is_for_sale_or_rental_id', //RECOMMENDATION
    HAS_PAST_CERTIFICATION_ID: 'has_past_certification_id', //RECOMMENDATION && PORTFOLIO
    LAST_CERTIFICATION_DATE: 'last_certification_date', //RECOMMENDATION && PORTFOLIO
    HAS_PAST_AUDIT_ID: 'has_past_audit_id', //RECOMMENDATION && PORTFOLIO
    LAST_AUDIT_DATE: 'last_audit_date', //RECOMMENDATION && PORTFOLIO
    CONTACT_INFO: 'contact_info', //RECOMMENDATION && PORTFOLIO
};

//QUESTIONS
export const QUESTIONS = {
    [INPUT_NAMES.COMPANY_TYPE_ID]: {
        watch: true,
        order: 1,
        module: MODULES.RECOMMENDATION,
        name: INPUT_NAMES.COMPANY_TYPE_ID,
        label: 'page.ce.question.company_type_id',
        tooltip: null,
        disabled: false,
        options: [
            {
                title: 'page.ce.card.pme.title',
                subtitle: 'page.ce.card.pme.subtitle',
                icon: require('assets/images/products/ce/pme.jpeg'),
                value: 1,
            },
            {
                title: 'page.ce.card.npme.title',
                subtitle: 'page.ce.card.npme.subtitle',
                icon: require('assets/images/products/ce/npme.jpeg'),
                value: 2,
            },
            {
                title: 'page.ce.card.ep.title',
                subtitle: 'page.ce.card.ep.subtitle',
                tooltip: 'page.ce.card.ep.tooltip',
                icon: require('assets/images/products/ce/ep.jpeg'),
                value: 3,
            },
        ],
        type: 'card',
        visible: false,
        validation: validate(['required']),
    },
    [INPUT_NAMES.FACILITIES_TO_PROPOSE_ID]: {
        watch: false,
        order: 2,
        module: MODULES.RECOMMENDATION,
        name: INPUT_NAMES.FACILITIES_TO_PROPOSE_ID,
        label: 'page.ce.question.facilities_to_propose_id',
        tooltip: null,
        dependsOnInput: {
            name: INPUT_NAMES.COMPANY_TYPE_ID,
            value: 2,
        },
        disabled: false,
        options: [
            {
                label: 'page.ce.question.facilities_to_propose_id.option_1',
                value: 1,
            },
            {
                label: 'page.ce.question.facilities_to_propose_id.option_2',
                value: 2,
            },
        ],
        type: 'radion',
        visible: false,
        validation: validate(['required']),
    },
    [INPUT_NAMES.ACTIVITY_SECTOR_ID]: {
        watch: true,
        order: 3,
        module: MODULES.RECOMMENDATION,
        name: INPUT_NAMES.ACTIVITY_SECTOR_ID,
        label: 'page.ce.question.activity_sector_id',
        tooltip: null,
        dependsOnInput: null,
        disabled: false,
        options: [
            {
                label: 'page.ce.question.activity_sector_id.option_1',
                value: 1,
            },
            {
                label: 'page.ce.question.activity_sector_id.option_2',
                value: 2,
            },
            {
                label: 'page.ce.question.activity_sector_id.option_3',
                value: 3,
            },
        ],
        type: 'radio',
        visible: false,
        validation: validate(['required']),
    },
    [INPUT_NAMES.CERTIFICATION_TYPE_ID]: {
        order: 1,
        module: MODULES.PORTFOLIO,
        name: INPUT_NAMES.CERTIFICATION_TYPE_ID,
        label: 'page.ce.question.certification_type_id',
        tooltip: null,
        disabled: false,
        options: [
            {
                title: 'page.ce.card.recs.title',
                subtitle: 'page.ce.card.recs.subtitle',
                icon: require('assets/images/products/ce/recs.png'),
                value: 1,
            },
            {
                title: 'page.ce.card.ae.title',
                subtitle: 'page.ce.card.ae.subtitle',
                icon: require('assets/images/products/ce/ae.png'),
                value: 2,
            },
            {
                title: 'page.ce.card.sgcie.title',
                subtitle: 'page.ce.card.sgcie.subtitle',
                icon: require('assets/images/products/ce/sgcie.png'),
                value: 3,
            },
        ],
        type: 'card',
        visible: false,
        validation: validate(['required']),
    },
    [INPUT_NAMES.FACILITIES_ANNUAL_CONSUMPTIONS]: {
        watch: false,
        order: 1,
        module: null,
        name: INPUT_NAMES.FACILITIES_ANNUAL_CONSUMPTIONS,
        label: 'page.ce.question.facilities_annual_consumptions',
        subtitleLabel: 'page.ce.question.facilities_annual_consumptions.subtitle',
        options: {
            unit: 'page.ce.question.facilities_annual_consumptions.unit',
            info: 'page.ce.question.facilities_annual_consumptions.info',
        },
        type: 'list',
        visible: false,
        inputType: 'number',
        validation: validate(['required', 'positive']),
    },
    [INPUT_NAMES.WORKERS_NUMBER]: {
        order: 1,
        module: null,
        name: INPUT_NAMES.WORKERS_NUMBER,
        label: 'page.ce.question.workers_number',
        tooltip: null,
        dependsOnInput: null,
        disabled: false,
        answers: null,
        type: 'input',
        inputType: 'number',
        options: null,
        visible: false,
        validation1: YUP_CUSTOM.number().positive().required().max(MAX_CE.WORKERS),
        validation: validate(['required', 'positive', 'max'], { max: MAX_CE.WORKERS }),
    },
    [INPUT_NAMES.ENERGY_SOURCES_ANNUAL_CONSUMPTIONS]: {
        order: 1,
        module: null,
        name: INPUT_NAMES.ENERGY_SOURCES_ANNUAL_CONSUMPTIONS,
        label: 'page.ce.question.energy_sources_annual_consumptions',
        tooltip: null,
        dependsOnInput: null,
        disabled: false,
        options: [
            {
                name: 'electric',
                options: [
                    {
                        label: 'kWh',
                        value: 'kWh',
                    },
                    {
                        label: 'MWh',
                        value: 'MWh',
                    },
                ],
            },
            {
                name: 'diesel',
                options: [
                    {
                        label: 'kg',
                        value: 'kg',
                    },
                    {
                        label: 'ton',
                        value: 'ton',
                    },
                    {
                        label: 'L',
                        value: 'L',
                    },
                ],
            },
            {
                name: 'fueloil',
                options: [
                    {
                        label: 'kg',
                        value: 'kg',
                    },
                    {
                        label: 'ton',
                        value: 'ton',
                    },
                ],
            },
            {
                name: 'propane',
                options: [
                    {
                        label: 'kg',
                        value: 'kg',
                    },
                    {
                        label: 'ton',
                        value: 'ton',
                    },
                ],
            },
            {
                name: 'pellets',
                options: [
                    {
                        label: 'kg',
                        value: 'kg',
                    },
                    {
                        label: 'ton',
                        value: 'ton',
                    },
                ],
            },
            {
                name: 'natural_gas',
                options: [
                    {
                        label: 'kWh',
                        value: 'kWh',
                    },
                    {
                        label: 'MWh',
                        value: 'MWh',
                    },
                ],
            },
            {
                name: 'lpg',
                options: [
                    {
                        label: 'kg',
                        value: 'kg',
                    },
                    {
                        label: 'ton',
                        value: 'ton',
                    },
                ],
            },
            {
                name: 'total',
                options: {
                    min: 0,
                    max: MAX_CE.NUM_ANNUAL_CONSUMPTION,
                },
            },
        ],
        type: 'energyConsumption',
        visible: false,
        validation: YUP_CUSTOM.array()
            .of(
                YUP_CUSTOM.object({
                    source: YUP_CUSTOM.string().required(),
                    annual_consumption: YUP_CUSTOM.number().required().max(MAX_CE.NUM_ANNUAL_CONSUMPTION),
                    unit: YUP_CUSTOM.string().required(),
                    annual_consumption_in_toe: YUP_CUSTOM.number().required(),
                })
            )
            .required(),
        optionsold: {
            maximum: MAX_CE.NUM_ANNUAL_CONSUMPTION,
            min: 0,
        },
    },
    [INPUT_NAMES.NET_AREA]: {
        order: 1,
        module: null,
        name: INPUT_NAMES.NET_AREA,
        label: 'page.ce.question.net_area',
        tooltip: 'page.ce.question.net_area.tooltip',
        disabled: false,
        options: null,
        type: 'input',
        inputType: 'number',
        visible: false,
        validation: validate(['required', 'positive', 'max'], { max: MAX_CE.NET_AREA }),
    },
    [INPUT_NAMES.TYPE_ID]: {
        order: 1,
        modules: null,
        name: INPUT_NAMES.TYPE_ID,
        label: 'page.ce.question.type_id',
        tooltip: null,
        dependsOnInput: null,
        disabled: false,
        options: null,
        type: 'card',
        visible: false,
        validation: validate(['required']),
    },
    [INPUT_NAMES.IS_FOR_SALE_OR_RENTAL_ID]: {
        order: 1,
        module: MODULES.RECOMMENDATION,
        name: INPUT_NAMES.IS_FOR_SALE_OR_RENTAL_ID,
        label: 'page.ce.question.is_for_sale_or_rental_id',
        tooltip: null,
        disabled: false,
        options: [
            {
                label: 'label.yes',
                value: 1,
            },
            {
                label: 'label.no',
                value: 2,
            },
        ],
        type: 'radio',
        visible: false,
        validation: validate(['required']),
    },
    [INPUT_NAMES.HAS_PAST_CERTIFICATION_ID]: {
        order: 1,
        module: null,
        name: INPUT_NAMES.HAS_PAST_CERTIFICATION_ID,
        label: 'page.ce.question.has_past_certification_id',
        tooltip: null,
        disabled: false,
        options: [
            {
                label: 'label.yes',
                value: 1,
            },
            {
                label: 'label.no',
                value: 2,
            },
            {
                label: 'label.dontKnow',
                value: 3,
            },
        ],
        type: 'radio',
        visible: false,
        validation: validate(['required']),
    },
    [INPUT_NAMES.LAST_CERTIFICATION_DATE]: {
        order: 1,
        module: null,
        name: INPUT_NAMES.LAST_CERTIFICATION_DATE,
        label: 'page.ce.question.last_certification_date',
        tooltip: null,
        dependsOnInput: {
            name: 'has_past_certification_id',
            value: 1,
        },
        disabled: false,
        type: 'datePicker',
        visible: false,
        validation: validate(['required']),
        options: {
            maxDate: Date.now(),
        },
    },
    [INPUT_NAMES.HAS_PAST_AUDIT_ID]: {
        order: 1,
        module: null,
        name: INPUT_NAMES.HAS_PAST_AUDIT_ID,
        label: 'page.ce.question.has_past_audit_id',
        tooltip: 'page.ce.question.has_past_audit_id.tooltip',
        disabled: false,
        options: [
            {
                label: 'label.yes',
                value: 1,
            },
            {
                label: 'label.no',
                value: 2,
            },
            {
                label: 'label.dontKnow',
                value: 3,
            },
        ],
        type: 'radio',
        visible: false,
        validation: validate(['required']),
    },
    [INPUT_NAMES.LAST_AUDIT_DATE]: {
        order: 1,
        module: null,
        name: INPUT_NAMES.LAST_AUDIT_DATE,
        label: 'page.ce.question.last_audit_date',
        tooltip: null,
        dependsOnInput: {
            name: 'has_past_audit_id',
            value: 1,
        },
        disabled: false,
        type: 'datePicker',
        visible: false,
        validation: validate(['required']),
        options: {
            maxDate: Date.now(),
        },
    },
    [INPUT_NAMES.CONTACT_INFO]: {
        order: 2,
        module: null,
        name: INPUT_NAMES.CONTACT_INFO,
        label: 'page.ce.question.contact_info_2',
        subtitleLabel: null,
        infoExtra: {
            warn: 'page.ce.question.contact_info_2',
            title: 'page.ce.question.contact_info.title',
            subtitle: 'page.ce.question.contact_info.subtitle',
        },
        disabled: false,
        options: [
            {
                icon: 'zmdi zmdi-account',
                name: 'name',
                label: 'label.name',
                inputType: 'string',
                validation: validate(['required']),
            },
            {
                icon: 'zmdi zmdi-phone',
                name: 'phone_number',
                label: 'label.phone',
                inputType: 'number',
                validation: validate(['required']),
            },
            {
                icon: 'zmdi zmdi-email',
                name: 'email',
                label: 'label.email',
                inputType: 'email',
                validation: validate(['required', 'email']),
            },
        ],
        type: 'fieldArray',
        visible: false,
    },
};

//CERTIFICATION TYPE
export const CERTIFICATION_TYPE = {
    RECS: 1,
    AE: 2,
    SGCIE: 3,
};

//COMPANY TYPE
export const COMPANY_TYPE = {
    PME: 1,
    NAO_PME: 2,
    PUBLIC_ENT: 3,
};

//ACTIVITY SECTOR
export const ACTIVITY_SECTOR = {
    COMERCE: 1,
    INDUSTRY: 2,
    OTHERS: 3,
};

//FACILITIES TO PROPOSE
export const FACILITIES_TO_PROPOSE = {
    ONE_INSTALLATION: 1,
    MORE_INSTALLATIONS: 2,
};

//FACILITY TYPE
export const FACILITY_TYPE = {
    WAREHOUSE: 2,
    SERVICES: 3,
    MANUFACTURING_UNIT: 4,
    COMMERCIAL_ACOMMODATION: 5,
    SHOPPING_CENTRE: 7,
    SUPERSTORE: 8,
    SUPERMARKET: 9,
    INDOOR_SWIMMING_POOL: 10,
    OFFICE: 11,
    SCHOOL: 12,
    FARM: 13,
    HOTEL: 14,
    HOSPITAL: 15,
    SPORTS_HALL: 16,
    KINDERGARTEN: 17,
};

export const AVAILABLE_FACILITY_TYPE = [
    FACILITY_TYPE.WAREHOUSE,
    FACILITY_TYPE.SERVICES,
    FACILITY_TYPE.MANUFACTURING_UNIT,
    FACILITY_TYPE.COMMERCIAL_ACOMMODATION,
    FACILITY_TYPE.SHOPPING_CENTRE,
    FACILITY_TYPE.SUPERSTORE,
    FACILITY_TYPE.SUPERMARKET,
    FACILITY_TYPE.INDOOR_SWIMMING_POOL,
    FACILITY_TYPE.OFFICE,
    FACILITY_TYPE.SCHOOL,
];

//EXTRA PICKED
export const EXTRA_PICKED = {
    WITH_ARCE: 1,
    NO_ARCE: 2,
};

//ARCE PERIODICY
export const ARCE_PERIODICY = {
    ANNUAL: 1,
    BIANNUAL: 2,
    NONE_SELECTED: 3,
};

//ARCE PERIODICY
export const ANSWERS = {
    YES: 1,
    NO: 2,
    DONT_KNOW: 3,
};
