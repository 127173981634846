// import axios from 'axios';
import axios, { axiosOpenAreaInstance } from 'services/@efz/axios';
import { getMessageNetworkError } from 'services/statusCodeHttp';
import {
    getEndpoint,
    URL_COUNTIES,
    URL_NUTS,
    URL_PRODUCTS,
    URL_WORK_SCHEDULES,
    URL_FACILITIES,
    URL_CLIENT_MANAGERS,
    URL_SERVICES_MANAGERS,
    URL_SEGMENTS,
    URL_VOLTAGE_LEVELS,
    URL_DISTRIBUTORS,
    URL_GRID_TARIFFS,
    URL_TARIFF_OPTIONS,
    URL_SUPPLIERS,
    URL_ENERGY_RISKS,
    URL_CONNECTION_VOLTAGE,
    URL_ENERGY_TARIFFS,
    URL_CLASSES,
    URL_OWNERS,
    URL_PARTNERS,
    URL_IMG_CONTRACT,
    URL_SIMULATIONS,
    URL_HOURLY_OPTIONS,
    URL_USED_POWER_TARIFFS,
    URL_GRID_TARIFFS_SOURCES,
    URL_FIRST_ARRIVALS,
    URL_WORK_DAYS_OCCUPATION_PERIOD,
    URL_WEEKEND_IN_HOUSE,
    URL_EQUIPMENT,
    URL_INPUTS_UPLOADS,
    URL_BUSINESS_MODEL_GROUPS,
} from 'constants/endpoints';

const CancelToken = axios.CancelToken;
// Cancel executor variables
let getBPCancelExecutor;
let getNutsCancelExecutor;
let getPWSCancelExecutor;
let getFGCancelExecutor;
let getCMSCancelExecutor;
let getSMSCancelExecutor;
let getDistributorsCancelExecutor;
let getGTCancelExecutor;
let getGTSourcesCancelExecutor;
let getTariffOptionsCancelExecutor;
let getHourlyOptionsCancelExecutor;
let getUsedPowerTariffsOptionsCancelExecutor;
let getSuppliersAbortController;
let getEnergyRisksCancelExecutor;
let getMountingStructuresCancelExecutor;
let getEnergyTariffsCancelExecutor;
let getClassesCancelExecutor;
let getOwnersCancelExecutor;
let getPartnersCancelExecutor;
let getImgContractCancelExecutor;

/**
 * getBatteryPowers
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getBatteryPowers = (productID, range_id = null, is_kits = false) => {
    getBPCancelExecutor && getBPCancelExecutor();
    let params = { range_id, is_kits };
    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${productID}/battery-powers`, {
            cancelToken: new CancelToken(function executor(c) {
                getBPCancelExecutor = c;
            }),
            params: params,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * getNuts
 *
 * @returns {Promise<AxiosResponse<T>>}
 */

export const getNuts = (params, abortController = getNutsCancelExecutor) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_NUTS)}/i`,
            method: 'GET',
            params,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * getClasses
 *
 * @returns {Promise<AxiosResponse<T>>}
 */

export const getClasses = (params, abortController = getClassesCancelExecutor) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_CLASSES)}`,
            method: 'GET',
            params,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * getCounties
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
let getCountiesAbortController;
export const getCounties = (params, abortController = getCountiesAbortController) => {
    if (!getCountiesAbortController) {
        getCountiesAbortController && getCountiesAbortController.abort();
        getCountiesAbortController = new AbortController();
    }
    return axios
        .request({
            url: `${getEndpoint(URL_COUNTIES)}`,
            method: 'GET',
            params,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Product Work Schedule
export const getProductWorkSchedules = (payload) => {
    getPWSCancelExecutor && getPWSCancelExecutor();

    return axios
        .get(getEndpoint(URL_WORK_SCHEDULES) + '/' + payload.productId, {
            cancelToken: new CancelToken(function executor(c) {
                getPWSCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Facility Groups
export const getFacilityGroups = (payload) => {
    getFGCancelExecutor && getFGCancelExecutor();

    return axios
        .get(getEndpoint(URL_FACILITIES) + '/' + payload.facilityId + '/group-facilities?page=1&search=', {
            cancelToken: new CancelToken(function executor(c) {
                getFGCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Client Managers
export const getClientManagersSearch = (params) => {
    getCMSCancelExecutor && getCMSCancelExecutor();
    return axios
        .get(getEndpoint(URL_CLIENT_MANAGERS), {
            cancelToken: new CancelToken(function executor(c) {
                getCMSCancelExecutor = c;
            }),
            params: params,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Services Managers
export const getServicesManagersSearch = (params) => {
    getSMSCancelExecutor && getSMSCancelExecutor();

    return axios
        .get(getEndpoint(URL_SERVICES_MANAGERS), {
            cancelToken: new CancelToken(function executor(c) {
                getSMSCancelExecutor = c;
            }),
            params: params,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Products
export const getProductsSearch = (params) => {
    return axios
        .get(getEndpoint(URL_PRODUCTS), {
            params: params,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Segments
export const getSegments = (params) => {
    return axios
        .get(getEndpoint(URL_SEGMENTS), {
            params: params,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Voltage Levels
let getVoltageLAbortController;
export const getVoltageLevelsSearch = (params, abortController = getVoltageLAbortController) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_VOLTAGE_LEVELS)}`,
            method: 'GET',
            params,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Connection Voltages
let getCVoltagesAbortController;
export const getConnectionVoltagesSearch = (params, abortController = getCVoltagesAbortController) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_CONNECTION_VOLTAGE)}`,
            method: 'GET',
            params,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Distributors
export const getDistributors = (params, abortController = getDistributorsCancelExecutor) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_DISTRIBUTORS)}`,
            method: 'GET',
            params,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get tariffOptions
export const getTariffOptions = (abortController = getTariffOptionsCancelExecutor, params = null) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_TARIFF_OPTIONS)}`,
            params,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get GridTariffs
export const getGridTariffs = (params, abortController = getGTCancelExecutor) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_GRID_TARIFFS)}`,
            method: 'GET',
            params,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get GridTariffs
export const getGridTariffsSources = (abortController = getGTSourcesCancelExecutor) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_GRID_TARIFFS_SOURCES)}`,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Suppliers
export const getSuppliers = (abortController = getSuppliersAbortController) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_SUPPLIERS)}`,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Hourly Options
export const getHourlyOptions = (abort = getHourlyOptionsCancelExecutor) => {
    abort && abort();
    return axios
        .request({
            url: `${getEndpoint(URL_HOURLY_OPTIONS)}`,
            method: 'GET',
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Used Power Tariffs
export const getUsedPowerTariffs = (params, abort = getUsedPowerTariffsOptionsCancelExecutor) => {
    abort && abort();
    return axios
        .request({
            url: `${getEndpoint(URL_USED_POWER_TARIFFS)}`,
            method: 'GET',
            params,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Energy Risks
export const getEnergyRisks = () => {
    getEnergyRisksCancelExecutor && getEnergyRisksCancelExecutor();

    return axios
        .get(getEndpoint(URL_ENERGY_RISKS), {
            cancelToken: new CancelToken(function executor(c) {
                getEnergyRisksCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * getMountingStructures
 *
 * @param {number} productID
 * @returns
 */
export const getMountingStructures = (productID) => {
    getMountingStructuresCancelExecutor && getMountingStructuresCancelExecutor();

    return axios
        .get(`${getEndpoint(URL_PRODUCTS)}/${productID}/mounting-structures?mode=raw`, {
            cancelToken: new CancelToken(function executor(c) {
                getMountingStructuresCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * getStructuresTree
 *
 * @param {number} productID
 * @returns
 */
export const getStructuresTree = (productID, isOArea = false, abortController = undefined) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    let requestCfg = {
        url: `${getEndpoint(URL_PRODUCTS)}/${productID}/components/structures-tree?mode=raw`,
        method: 'GET',
        signal: abortController.signal,
    };

    if (isOArea) {
        //TODO: colocar todos os enpoints no folder api/solarpvSimple
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

//Get Energy Tariffs
export const getEnergyTariffs = (abortController = getEnergyTariffsCancelExecutor) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_ENERGY_TARIFFS)}`,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Energy Tariffs
export const getOwners = (params) => {
    getOwnersCancelExecutor && getOwnersCancelExecutor();

    return axios
        .get(getEndpoint(URL_OWNERS), {
            cancelToken: new CancelToken(function executor(c) {
                getOwnersCancelExecutor = c;
            }),
            params: params,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get Partners
export const getPartners = (params) => {
    getPartnersCancelExecutor && getPartnersCancelExecutor();

    return axios
        .get(getEndpoint(URL_PARTNERS), {
            cancelToken: new CancelToken(function executor(c) {
                getPartnersCancelExecutor = c;
            }),
            params: params,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postFileUploadImgContract = (data, isOArea, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    let requestCfg = {
        url: `${getEndpoint(URL_IMG_CONTRACT)}`,
        method: 'POST',
        data,
        signal: abortController?.signal,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response.data)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response.data)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};
export const getBusinessModelGroups = () => {
    return axios
        .get(getEndpoint(URL_BUSINESS_MODEL_GROUPS))
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getImgContract = (proposalID) => {
    getImgContractCancelExecutor && getImgContractCancelExecutor();

    return axios
        .get(`${getEndpoint(URL_SIMULATIONS)}/${proposalID}/${URL_IMG_CONTRACT}`, {
            cancelToken: new CancelToken(function executor(c) {
                getImgContractCancelExecutor = c;
            }),
            responseType: 'arraybuffer',
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// Get first arrivals
export const getFirstArrivals = (abortController) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_FIRST_ARRIVALS)}`,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// Get work days occupation period
export const getWorkDaysOccupationPeriod = (abortController) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_WORK_DAYS_OCCUPATION_PERIOD)}`,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// Get weekend in house
export const getWeekendInHouse = (abortController) => {
    abortController && abortController.abort();
    return axios
        .request({
            url: `${getEndpoint(URL_WEEKEND_IN_HOUSE)}`,
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

// Get equipments (B2C facilities)
export const getEquipments = (abortController, isOArea = false) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    let requestCfg = {
        url: `${getEndpoint(URL_EQUIPMENT)}`,
        method: 'GET',
        signal: abortController?.signal,
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

export const getGeneralImgContract = (imageName, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }
    return axios
        .request({
            url: `${getEndpoint(URL_IMG_CONTRACT)}/${imageName}`,
            method: 'GET',
            responseType: 'blob',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postFileInputsUploads = (data, isOArea, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    let requestCfg = {
        url: `${getEndpoint(URL_INPUTS_UPLOADS)}`,
        method: 'POST',
        data,
        signal: abortController?.signal,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response.data)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response.data)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};

export const getInputsUploadsImage = (imageName, folder, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }
    return axios
        .request({
            url: `${getEndpoint(URL_INPUTS_UPLOADS)}/${folder}/${imageName}`,
            method: 'GET',
            responseType: 'blob',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
