import { useContext } from 'react';
import BusinessModelInstallments from 'components/BusinessModels/List/BusinessModels/BusinessModelInstallments/Card';
import BusinessModelPP from 'components/BusinessModels/List/BusinessModels/BusinessModelPP/Card/index';
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModel, IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import BusinessModelServiceV2 from 'components/BusinessModels/List/BusinessModels/BusinessModelServiceV2/Card';
import BusinessModelPaP from 'components/BusinessModels/List/BusinessModels/BusinessModelPaP/Card';
import { TBMs } from 'interfaces/businessModels/reducer';
import BusinessModelLEC from 'components/BusinessModels/List/BusinessModels/BusinessModelLEC/Card';
import BusinessModelServicePPa from 'components/BusinessModels/List/BusinessModels/BusinessModelServicePPa/Card';
import Caroussel from './Caroussel';
import Skeleton from '@mui/material/Skeleton';
import { businessModelsMapped } from 'services/businessModels/index';
import BusinessModelPPa from './BusinessModels/BusinessModelPPa/Card';
import BusinessModelESolar from 'components/BusinessModels/List/BusinessModels/BusinessModelESolar/Card';
import { useMediaQuery } from '@mui/material';
import BusinessModelService from 'components/BusinessModels/List/BusinessModels/BusinessModelService/Card';

interface IProps {
    isRequestingBM: boolean;
    postAuditHandler: (boolean, number) => void;
}
const BusinessModelsList = ({ isRequestingBM, postAuditHandler }: IProps) => {
    const show4Card = useMediaQuery('(min-width:1500px)');
    const isTabletOrMobile = useMediaQuery('(max-width:960px)');
    const numberCards = show4Card ? 4 : 3;

    const { bmCards } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TBMs>;

    const implementedBMCards = bmCards?.filter((bm) => businessModelsMapped.includes(bm.payment_model_id));

    const businessModel = (bm: IBusinessModel, key: string) => {
        switch (bm.payment_model_id) {
            case PAYMENT_MODELS_IDS.UP_FRONT:
                return <BusinessModelPP bm={bm} key={key} />;
            case PAYMENT_MODELS_IDS.INSTALLMENTS:
                return <BusinessModelInstallments bm={bm} key={key} postAuditHandler={postAuditHandler} />;
            case PAYMENT_MODELS_IDS.PAY_AS_PRODUCED:
                return <BusinessModelPaP bm={bm} key={key} postAuditHandler={postAuditHandler} />;
            case PAYMENT_MODELS_IDS.SERVICE:
                return <BusinessModelService bm={bm} key={key} />;
            case PAYMENT_MODELS_IDS.SERVICE_SECOND_VERSION:
                return <BusinessModelServiceV2 bm={bm} key={key} />;
            case PAYMENT_MODELS_IDS.LEC:
                return <BusinessModelLEC bm={bm} key={key} postAuditHandler={postAuditHandler} />;
            case PAYMENT_MODELS_IDS.SERVICE_PPA:
                return <BusinessModelServicePPa bm={bm} key={key} postAuditHandler={postAuditHandler} />;
            case PAYMENT_MODELS_IDS.PPA:
                return <BusinessModelPPa bm={bm} key={key} postAuditHandler={postAuditHandler} />;
            case PAYMENT_MODELS_IDS.EASY_SOLAR:
                return <BusinessModelESolar bm={bm} key={key} postAuditHandler={postAuditHandler} />;
            default:
                break;
        }
    };

    const createBMCards = () => {
        return implementedBMCards.map((bmCard) => businessModel(bmCard, `bm_pro_card_id_${bmCard.payment_model_id}`));
    };

    return (
        <div className="business-models-cards d-flex justify-content-center">
            {isRequestingBM ?
                <div className="business-models-cards-skeletons">
                    {new Array(numberCards).fill(null).map((_el) => {
                        return (
                            <div className="business-models-cards-skeleton">
                                <Skeleton variant="rectangular" width={324} height={208} className="skeleton-header" />
                                {new Array(10).fill(null).map((_el) => {
                                    return (
                                        <div className="mb-3 w-100">
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </div>
                                    );
                                })}
                                <Skeleton variant="rectangular" width={126} height={40} className="skeleton-btn mt-4" />
                            </div>
                        );
                    })}
                </div>
            :   <>
                    {implementedBMCards?.length > numberCards || isTabletOrMobile ?
                        <Caroussel>{createBMCards()}</Caroussel>
                    :   createBMCards()}
                </>
            }
        </div>
    );
};

export default BusinessModelsList;
