import { useCallback, useContext, useState } from 'react';
import { getFacilitiesSearch, getFacilitiesSearchByClient } from 'api/facilities';
import { StatusCodes } from 'http-status-codes';
// import { getRedirectToken } from 'selectors/client';
import { FacilitiesContext } from 'contexts/facilitiesContext';

const useSearchFacilities = () => {
    const { handleSetFacilitiesByToken, setFacilitiesList, facilitiesList } = useContext(FacilitiesContext);

    const [isRequestingFacilities, setIsRequestingFacilities] = useState(false);

    const handleFetchSearchFacilitiesByClient = useCallback(
        async (payload) => {
            setIsRequestingFacilities(true);
            const rps = await getFacilitiesSearchByClient(payload);
            if (rps?.status === StatusCodes.OK) {
                setFacilitiesList(rps?.data?.data);

                if (payload?.isRedirectToken) handleSetFacilitiesByToken(rps?.data?.data ?? []);
            }
            setIsRequestingFacilities(false);
        },
        [handleSetFacilitiesByToken, setFacilitiesList]
    );

    const handleFetchSearchFacilities = useCallback(
        async (payload) => {
            setIsRequestingFacilities(true);
            const rps = await getFacilitiesSearch(payload);
            if (rps?.status === StatusCodes.OK) {
                setFacilitiesList(rps?.data?.data);
            }
            setIsRequestingFacilities(false);
        },
        [setFacilitiesList]
    );

    return {
        handleFetchSearchFacilitiesByClient,
        handleFetchSearchFacilities,
        isRequestingFacilities,
        facilitiesList,
    };
};

export default useSearchFacilities;
