import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPaP } from 'interfaces/businessModels/pap';
import { useContext } from 'react';
import GrantsPaP from './Grants';
import OfferDetailsPap from './OfferDetails';
import SavingsSharePap from './SavingsShare';
import { isFieldDefined } from 'services/util/auxiliaryUtils';

const ProjectSummaryPaPSimple = () => {
    const { negotiationProfile, bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;
    return (
        <>
            <OfferDetailsPap />
            {negotiationProfile?.mostra_subsidios && isFieldDefined(bmSelected?.values?.grants) ?
                <GrantsPaP />
            :   <></>}
            {bmSelected?.values?.proposalReady && bmSelected?.values?.kpis_per_margin?.length > 0 && <SavingsSharePap />}
        </>
    );
};

export default ProjectSummaryPaPSimple;
