import IntlMessages from 'components/util/IntlMessages';
import {
    AREA_TYPES,
    DEFAULTS_PROPS_MAP,
    HOUSING_TYPES_B2C,
    NUMBER_PHASES_IDS,
    solarpvMapActions,
    spvSimpleActions,
    SPV_SIMPLE_INPUTS,
    STEPS_OPEN_AREA,
} from 'constants/products/solarpvSimple';
import {
    MOUNTING_STRUCTURES_TYPES,
    REMUNERATIONS_NAMES,
    REMUNERATION_MODELS_TYPE,
    SETTINGS,
    SYSTEM_COMPOSITION,
} from 'constants/products/spvPro';
import { BASENAME_URL_OAREA_PPC_B2B, BASENAME_URL_OAREA_PPC_B2C } from 'constants/settings';
import { initialSolarpvMapReducer } from 'contexts/products/solarpvSimple/solarpvMapReducer';
import clone from 'fast-copy';
import { orderBy } from 'lodash';
import { notify } from 'services/@efz/notify';
import { isDefined, isFieldDefined, mathRadians, mathRound, removeEmptyInObj } from 'services/util/auxiliaryUtils';

export const getDefaultValuesB2C = (inputs, isOArea) => {
    return {
        ...inputs,
        [SPV_SIMPLE_INPUTS.HOUSING_TYPE]: '',
        [SPV_SIMPLE_INPUTS.ADDRESS]: '',
        //[SPV_SIMPLE_INPUTS.MOUNTING_STRUCTURE_ID]: inputs?.mounting_structure_id ?? '', TODO: don't trigger this default!,
        [SPV_SIMPLE_INPUTS.PHASES_NUMBER_ID]: NUMBER_PHASES_IDS.THREEPHASIC,
        [SPV_SIMPLE_INPUTS.ENERGY_PRICE]: '0.232',
        [SPV_SIMPLE_INPUTS.ANNUAL_CONSUMPTION]: '6000',
        [SPV_SIMPLE_INPUTS.GRANT_ID]: '',
        [SPV_SIMPLE_INPUTS.GRANT_SP_BATTERY]: '',
        [SPV_SIMPLE_INPUTS.GRANT_SP_PV_STATION]: '',
        [SPV_SIMPLE_INPUTS.GRANT_SV_BATTERY]: '',
        [SPV_SIMPLE_INPUTS.GRANT_SV_PV_STATION]: '',
        [SPV_SIMPLE_INPUTS.EQUIPMENTS]: isOArea ? inputs.facility.inputs_consumo.equipments : [],
        //[SPV_SIMPLE_INPUTS.REMUNERATION_TYPE_ID]: inputs?.remuneration_type_id ?? '', TODO: don't trigger this default!,
    };
};

export const getDefaultValuesB2B = (inputs, isOArea) => {
    return {
        ...inputs,
        [SPV_SIMPLE_INPUTS.HOUSING_TYPE]: '',
        [SPV_SIMPLE_INPUTS.AREA_TYPE]: '',
        [SPV_SIMPLE_INPUTS.ADDRESS]: '',
        //[SPV_SIMPLE_INPUTS.MOUNTING_STRUCTURE_ID]: inputs?.mounting_structure_id ?? '', TODO: don't trigger this default!,
        [SPV_SIMPLE_INPUTS.PHASES_NUMBER_ID]: NUMBER_PHASES_IDS.THREEPHASIC,
        [SPV_SIMPLE_INPUTS.ENERGY_PRICE]: '0.232',
        [SPV_SIMPLE_INPUTS.ANNUAL_CONSUMPTION]: '6000',
        [SPV_SIMPLE_INPUTS.EQUIPMENTS]: isOArea ? inputs.facility.inputs_consumo.equipments : [],
        //[SPV_SIMPLE_INPUTS.REMUNERATION_TYPE_ID]: inputs?.remuneration_type_id ?? '', TODO: don't trigger this default!,
    };
};

export const getPayloadToSimsB2C = (options, is_scaling = true) => {
    let { isOArea, inputs, productID, battery, pvOptions } = options;

    let { facility, housing_type, areas, coordinates_avg, range_id, panel_id, is_kits, kits, remuneration_type_id, total_max_panels } =
        inputs;

    if (isOArea) {
        return {
            is_open_area: isOArea,
            is_scaling,
            tipo_produto_id: productID,
            inputs: {
                facility,
                property_type_id: housing_type,
                areas,
                coordinates_avg,
                range_id,
                panel_id,
                total_max_panels,
                is_kits,
                kits,
                remuneration_type_id,
                battery,
                prod_params: pvOptions?.prod_params,
            },
        };
    } else {
        return {
            facility: { id: facility.id },
            is_open_area: isOArea,
            is_scaling,
            tipo_produto_id: productID,
            inputs: {
                areas,
                coordinates_avg,
                range_id,
                panel_id,
                total_max_panels,
                is_kits,
                kits,
                remuneration_type_id,
                battery,
            },
        };
    }
};

export const getPayloadToSimsB2B = (options, is_scaling = true) => {
    let { isOArea, inputs, productID, battery, hasBattery, pvOptions } = options;

    let {
        facility,
        housing_type,
        area_type,
        areas,
        coordinates_avg,
        range_id,
        panel_id,
        is_kits,
        kits,
        remuneration_type_id,
        total_max_panels,
    } = inputs;

    if (isOArea) {
        return {
            is_open_area: isOArea,
            is_scaling,
            tipo_produto_id: productID,
            inputs: {
                facility,
                property_type_id: housing_type,
                area_type_id: area_type,
                areas,
                coordinates_avg,
                range_id,
                panel_id,
                total_max_panels,
                is_kits,
                kits,
                remuneration_type_id,
                battery: hasBattery ? battery : null,
                prod_params: pvOptions?.prod_params,
            },
        };
    } else {
        return {
            facility: { id: facility.id },
            is_open_area: isOArea,
            is_scaling,
            tipo_produto_id: productID,
            inputs: {
                areas,
                property_type_id: housing_type,
                area_type_id: area_type,
                coordinates_avg,
                range_id,
                panel_id,
                total_max_panels,
                is_kits,
                kits,
                remuneration_type_id,
                battery: hasBattery ? battery : null,
            },
        };
    }
};

export const getPayloadToPreSims = (options) => {
    let {
        // isOArea,
        structures,
        // productID,
    } = options;

    let coordinates_avg = {
        lat: options.inputs?.facility.location.lat,
        long: options.inputs?.facility.location.lng,
    };

    const areas = [
        {
            id: 1,
            area: 50,
            orientation: 0,
            slope: structures[0].default_roof_slope,
            coordinates_avg,
            mounting_structure_id: structures[0].id,
            panels_max_number: 5,
        },
        {
            id: 2,
            area: 50,
            orientation: 180,
            slope: structures[0].default_roof_slope,
            coordinates_avg,
            mounting_structure_id: structures[0].id,
            panels_max_number: 5,
        },
        {
            id: 3,
            area: 50,
            orientation: 0,
            slope: structures[1].default_roof_slope,
            coordinates_avg,
            mounting_structure_id: structures[0].id,
            panels_max_number: 5,
        },
        {
            id: 4,
            area: 50,
            orientation: 180,
            slope: structures[1].default_roof_slope,
            coordinates_avg,
            mounting_structure_id: structures[0].id,
            panels_max_number: 5,
        },
    ];

    // let inputs = {//only pre-sims
    //     facility: {
    //         id: null,
    //         inputs_consumo: {
    //             annual_consumption: "6000",
    //             equipments: [],
    //             energy_price: null
    //         },
    //         total_tariffs: [{//only pre-sims
    //             descricao: "Period 1",
    //             prc_periodo: 1,
    //             tarifa: 0.232,
    //             tipo_periodo_id: 48
    //         }],
    //         phases_number_id: 2
    //     },
    //     areas,
    //     coordinates_avg,
    //     range_id: options?.inputs?.range_id,
    //     panel_id: options?.inputs?.panel_id,
    //     is_kits: true,
    //     remuneration_type_id: null,
    //     total_max_panels: 20,
    // };

    return { areas };
    // if (isOArea) {
    // } else {

    //     return {
    //         facility: { id: options?.inputs?.facility.id },
    //         is_open_area: isOArea,
    //         is_scaling: true,
    //         tipo_produto_id: productID,
    //         inputs,
    //         type: 'pre-sims'
    //     }
    // }
};

export const getPayloadToProposalB2C = (contacts, options) => {
    let { inputs, isOArea, battery, solution, grant, isInstallments } = options;

    let { facility, areas, coordinates_avg, range_id, panel_id, is_kits, img_contract_filename, housing_type, cardProps } = inputs;

    // update areas
    let newAreas = clone(areas);
    newAreas?.forEach((group, idx) => {
        newAreas[idx] = {
            ...group,
            kit_id: solution?.kit_id,
            panels_number: group.id === 1 ? Math.ceil(solution?.n_paineis / 2) : solution?.n_paineis - newAreas[0].panels_number, //only 2 areas
        };
    });

    if (isOArea) {
        return {
            is_open_area: true,
            img_contract_filename,
            tipo_modelo_negocio_id: !isInstallments ? 165 : 254, // FIXME: hardcoded!
            is_scaling: false,
            inputs: {
                facility,
                areas: newAreas,
                coordinates_avg,
                battery: solution?.is_recomended_with_battery ? battery : null,
                is_kits,
                total_panels: solution?.n_paineis,
                range_id,
                panel_id,
                remuneration_type_id: solution.remuneration_type_id,
                contacts,
                ...grant,
                ...cardProps,
            },
        };
    } else {
        return {
            areas: newAreas,
            coordinates_avg,
            facility,
            housing_type,
            is_kits,
            panel_id,
            range_id,
            total_panels: solution?.n_paineis,
            facility_id: inputs?.facility?.id,
            module_type: 'SIMPLES',
            target_investment:
                solution?.is_recomended_with_battery ? solution?.investment_with_battery : solution?.investment_without_battery,
            annual_saving:
                solution?.is_recomended_with_battery ?
                    solution?.savings_with_battery
                :   solution?.savings_without_battery ?? solution.savings,
            remuneration_type_id: parseInt(solution.remuneration_type_id),
        };
    }
};

export const getPayloadToProposalB2B = (contacts, options) => {
    let { inputs, isOArea, battery, solution } = options;

    let { facility, areas, coordinates_avg, range_id, panel_id, is_kits, img_contract_filename, housing_type } = inputs;

    // update areas
    let newAreas = clone(areas);
    newAreas?.forEach((group, idx) => {
        newAreas[idx] = {
            ...group,
            kit_id: solution?.kits?.[0]?.id,
            panels_number:
                group.id === 1 ?
                    Math.ceil(solution?.n_paineis / 2) /* divide por  2 areas sempre */
                :   solution?.n_paineis - newAreas[0].panels_number, //only 2 areas
        };
    });

    if (isOArea) {
        return {
            is_open_area: true,
            img_contract_filename,
            tipo_modelo_negocio_id: solution?.business_model_id,
            is_scaling: false,
            inputs: {
                facility,
                areas: newAreas,
                coordinates_avg,
                battery: isFieldDefined(solution?.battery_id) ? battery : null,
                is_kits,
                kits: solution?.kits,
                total_panels: solution?.n_paineis,
                range_id,
                panel_id,
                remuneration_type_id: solution.remuneration_type_id,
                contacts,
            },
        };
    } else {
        return {
            areas: newAreas,
            coordinates_avg,
            facility,
            housing_type,
            is_kits,
            panel_id,
            range_id,
            total_panels: solution?.n_paineis,
            facility_id: inputs?.facility?.id,
            module_type: 'SIMPLES',
            target_investment: solution?.investment,
            annual_saving: solution?.savings,
            remuneration_type_id: parseInt(solution.remuneration_type_id),
        };
    }
};

const CARD_IDS = {
    NET_METERING: 0,
    NET_METERING_BATTERY: 1,
    FEED_IN_TARIFF: 2,
};
export const getSimulationRecommendedKitB2C = (kits) => {
    // let recommended = [];
    let availableSolutions = [];

    Object.values(REMUNERATION_MODELS_TYPE).forEach((remType) => {
        let kitsRecommended = kits.filter((kit) => kit.is_recomended_with_battery || kit.is_recomended_without_battery);
        kitsRecommended.forEach((kitRecommended) => {
            let remunerationSelected = kitRecommended?.remunerations?.find(
                (rem) => rem.id === remType && (rem.is_recomended_with_battery || rem.is_recomended_without_battery)
            );
            if (isFieldDefined(remunerationSelected)) {
                availableSolutions.push(
                    removeEmptyInObj({
                        ...kitRecommended,
                        remuneration_type_name: REMUNERATIONS_NAMES?.[remType] ?? null,
                        kit_id: kitRecommended.id,
                        remuneration_type_id: remType,
                        ...remunerationSelected,
                        remunerations: null,
                        id:
                            REMUNERATION_MODELS_TYPE.NET_METERING === parseInt(remType) ?
                                !remunerationSelected.is_recomended_with_battery ?
                                    CARD_IDS.NET_METERING
                                :   CARD_IDS.NET_METERING_BATTERY
                            :   CARD_IDS.FEED_IN_TARIFF, //order manual
                    })
                );
            }
        });
    });

    if (availableSolutions.length === 2) {
        let newAvailableSolutions = [];
        availableSolutions.forEach((solution) => {
            if (solution.remuneration_type_id === REMUNERATION_MODELS_TYPE.FEED_IN_TARIFF) {
                newAvailableSolutions.push(solution);
            } else {
                //NET_METERING
                newAvailableSolutions.push({
                    //is_recomended_with_battery
                    ...solution,
                    id: CARD_IDS.NET_METERING,
                    is_recomended_with_battery: false,
                    is_recomended_without_battery: true,
                });
                newAvailableSolutions.push({
                    //is_recomended_without_battery
                    ...solution,
                    id: CARD_IDS.NET_METERING_BATTERY,
                    is_recomended_with_battery: true,
                    is_recomended_without_battery: false,
                });
            }

            availableSolutions = newAvailableSolutions;
        });
    }
    availableSolutions = orderBy(availableSolutions, ['id'], ['asc']);

    return {
        remuneration_type_id: availableSolutions?.[0]?.remuneration_type_id, //default menor payback de todos os tipos de remunerations
        kits: kits.filter((el) => el.is_recomended_with_battery || el.is_recomended_without_battery), //only recommended
        solutions: availableSolutions,
    };
};

export const getPolygonCoordinates = (polygon) => {
    let coordinates = [];
    polygon
        .getPath()
        .getArray()
        .forEach(function (el) {
            //save points
            coordinates.push({
                lat: el.lat(),
                lng: el.lng(),
            });
        });

    return coordinates;
};

export function calcTotalPanelsB2C(group, options) {
    let { orientation, area_projectada_util } = group;
    const { id, structure, rangeSelected, panel_id } = options;

    let { area_util_coplanar, area_util_triangular } = rangeSelected;

    let paineis_area = rangeSelected?.paineis_area;
    if (rangeSelected?.paineis?.length > 0 && isFieldDefined(panel_id)) {
        let { altura, largura } = rangeSelected?.paineis.find((el) => el.id === panel_id) ?? { largura: null, altura: null };
        paineis_area = altura * largura;
    }

    // area uitl triagular <= 15
    // se for estrutura a inclinaçao da estrutura faz com o que os proprios paineis façam sombra, o que reduz a area util
    const is_triangular = structure?.tipo_estrutura_id === MOUNTING_STRUCTURES_TYPES.TRIANGULAR;
    const is_ground = structure?.tipo_estrutura_id === MOUNTING_STRUCTURES_TYPES.GROUND;

    const fator_ajuste = SETTINGS.FATOR_AJUSTE;
    let slope = structure?.default_roof_slope;

    //#region  is_triangular
    if (is_triangular || is_ground) {
        area_util_triangular =
            parseInt(slope) > SYSTEM_COMPOSITION.TRIANGULAR_GRADE_LIMIT ?
                rangeSelected?.area_util_triangular_apos_15_graus
            :   area_util_triangular;
    }
    //#endregion is_triangular

    // area ajuste representa o coeficiente de area util, tendo em conta os elementos no telhado e correadores de passagem
    let area_ajuste = is_triangular || is_ground ? area_util_triangular : area_util_coplanar;

    // converte ângulo para radianos
    let alpha = mathRadians(parseInt(slope));
    let percentage_util = 1;
    let cos_alpha = Math.cos(alpha);
    let sin_alpha = Math.sin(alpha);
    let area_util = area_projectada_util;

    // se for estrutura a inclinaçao da estrutura faz com o que os proprios paineis façam sombra, o que reduz a area util
    if (is_triangular || is_ground) {
        percentage_util = cos_alpha / (cos_alpha + fator_ajuste * sin_alpha);
        area_util = percentage_util * area_util;
    } else {
        // a area util sera mais reduzida por causa do angulo,  a hipotenusa é o que interessa, a area projectada será um dos catetos
        // isto significa que quanto maior o ângulo, maiar a area
        area_util = area_util / cos_alpha;
    }

    // acrescenta 20% a area util
    area_util = area_util * area_ajuste;

    let panels_max_number = mathRound(area_util / paineis_area, 0);

    return {
        id,
        area: area_projectada_util,
        orientation,
        slope,
        panels_max_number,
        mounting_structure_id: parseInt(structure?.id),
        coordinates_avg: null,
    };
}

function getCentroid(polygon, google) {
    let bounds = new google.maps.LatLngBounds();
    polygon?.getPath().forEach((e) => bounds.extend(e));
    return bounds.getCenter();
}

export const createLineHandler = (newVertex, state, mapOptions) => {
    const { vertexes } = state;
    const { google, map } = mapOptions;
    const polygonVertexes = [...vertexes, newVertex];
    let gLine;
    if (polygonVertexes.length >= 2) {
        const newLinePoints = [polygonVertexes[polygonVertexes.length - 2], polygonVertexes[polygonVertexes.length - 1]];

        const newLinePath = newLinePoints.map((point) => {
            return { lat: point.center.lat(), lng: point.center.lng() };
        });
        gLine = new google.maps.Polyline({
            isline: true,
            path: [newLinePath[0], newLinePath[1]],
            fillColor: '#3885CD',
            strokeColor: '#3885CD',
            fillOpacity: 1,
            strokeWeight: 3,
            zIndex: 1000,
        });
        gLine.setMap(map);
    }

    return {
        vertexes: polygonVertexes,
        lines: isDefined(gLine) ? [...state.lines, gLine] : state.lines,
    };
};

let mainDrag = false;
export const createPolygonHandler = (state, mapOptions) => {
    const { google, map, mapDispatch, handleOnCreatePolygon } = mapOptions;
    const { vertexes } = state;
    const newVertexes = [...vertexes, vertexes[0]];
    const path = newVertexes.map((vertex) => {
        return { lat: vertex.center.lat(), lng: vertex.center.lng() };
    });

    let polygon;
    if (vertexes.length > 2) {
        polygon = new google.maps.Polygon({
            map,
            id: 1,
            zIndex: 1,
            paths: [...path],
            editable: true,
            draggable: true,
            strokeOpacity: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeOpacity,
            strokeWeight: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeWeight,
            fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
            fillColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.fillColor.selected,
            strokeColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeColor.selected,
            clickable: true,
        });

        polygon.setOptions({
            area: google.maps.geometry.spherical.computeArea(polygon.getPath()),
        });

        polygon.addListener('dragstart', () => {
            mainDrag = true;
        });

        //Quando acaba de arrastar a zona
        polygon.addListener('dragend', () => {
            mainDrag = false;
        });

        // //Quando é adicionado uma novo vertex no polygon selecionado
        google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
            mapDispatch(solarpvMapActions.EVT_INSERT_AT, {
                polygon,
            });
        });

        // //Quando editamos o polygon (selecionado com o click do mouse)
        google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
            if (!mainDrag) {
                mapDispatch(solarpvMapActions.EVT_SET_AT, {
                    polygon,
                });
            }
        });

        // //Quando criamos uma nova vertex e clicamos o undo "retroceder" no polygon selecionado
        google.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
            mapDispatch(solarpvMapActions.EVT_REMOVE_AT, {
                polygon,
            });
        });

        const centroid = getCentroid(polygon, google);
        map.panTo(centroid);
        handleOnCreatePolygon(centroid);
    } else {
        notify(<IntlMessages id="page.spv.error.drawing" />, 'error');
    }

    return polygon;
};

export const resetVertexesAndLines = (vertexes, lines) => {
    vertexes.forEach((vertex) => {
        vertex.setMap(null);
    });
    lines.forEach((line) => {
        line.setMap(null);
    });

    return {
        vertexes: initialSolarpvMapReducer.vertexes,
        lines: initialSolarpvMapReducer.lines,
    };
};

export const resetPolygon = (polygon) => {
    polygon.setMap(null);
    return { polygon: initialSolarpvMapReducer.polygon };
};

export function setURLByPPCHandler({ type, payload, history, isB2C, housing_type }) {
    if (
        [
            spvSimpleActions.SET_RESET_FORM,
            spvSimpleActions.SET_COORDINATES_AVG,
            spvSimpleActions.RESET_PROPOSAL,
            spvSimpleActions.SET_IS_REQUESTING_SIMS,
            spvSimpleActions.SET_SIMULATION_SUCCESS,
            spvSimpleActions.SET_STEP_POPSTATE,
            // spvSimpleActions.SUCCESSFUL_REQUEST,
        ].includes(type)
    )
        return;
    let pathname = isB2C ? `/${BASENAME_URL_OAREA_PPC_B2C}` : `/${BASENAME_URL_OAREA_PPC_B2B}`;
    let goBackStep = 0;

    if ([spvSimpleActions.START_OVER].includes(type)) {
        // Clear the history using the HTML5 History API
        history.push(pathname);
        window.history.replaceState(null, '', pathname);
        return;
    }

    switch (type) {
        case spvSimpleActions.SET_STEP: {
            if (payload === 1) {
                pathname = `${pathname}/steps/property-type`;
                goBackStep = payload;
            }
            if (payload === 2) {
                pathname = `${pathname}/steps/address-area`;
                goBackStep = payload;
            }
            if (payload === 4) {
                pathname = `${pathname}/steps/roof-details`;
                goBackStep = 3;
            }
            if (payload === 5) {
                pathname = `${pathname}/steps/consumption-details`;
                goBackStep = 4;
            }

            break;
        }
        case spvSimpleActions.DRAW_ROOF_MANUALLY:
        case spvSimpleActions.SET_HOUSING_TYPE: {
            pathname = `${pathname}/steps/address-area`; //go to (ADDRESS)
            goBackStep = STEPS_OPEN_AREA.ADDRESS;
            break;
        }
        case spvSimpleActions.SET_ADDRESS: {
            pathname = `${pathname}/steps/analyze-area`; //go to (detect-roof)
            goBackStep = STEPS_OPEN_AREA.ROOF_IDENTIFICATION;
            break;
        }
        case spvSimpleActions.SET_ROOF_IDENTIFICATION_SUCCESS: {
            if (housing_type === (isB2C ? HOUSING_TYPES_B2C.GROUND : AREA_TYPES.GROUND)) {
                pathname = `${pathname}/steps/consumption-details`; //go to (consumption_details)
                goBackStep = STEPS_OPEN_AREA.CONSUMPTION_DETAILS;
            } else {
                pathname = `${pathname}/steps/roof-details`; //go to (roof-details)
                goBackStep = STEPS_OPEN_AREA.ROOF_DETAILS;
            }
            break;
        }
        case spvSimpleActions.SET_ROOF_DETAILS: {
            pathname = `${pathname}/steps/consumption-details`; //go to (consumption_details)
            goBackStep = STEPS_OPEN_AREA.CONSUMPTION_DETAILS;
            break;
        }
        case spvSimpleActions.SET_CONSUMPTION_DETAILS: {
            pathname = `${pathname}/steps/simulation`; //go to simulation success && show card
            goBackStep = STEPS_OPEN_AREA.SIMULATION;
            break;
        }
        case spvSimpleActions.SUCCESSFUL_REQUEST: {
            pathname = `${pathname}/steps/simulation/successful-request`; //go to successful-request success && show card
            goBackStep = STEPS_OPEN_AREA.SIMULATION;
            break;
        }
        default:
            break;
    }
    history.push(pathname, { goBackStep });
}

export const getLastStructureLevel = (inputArray) => {
    const parentSet = new Set(inputArray.map((obj) => obj.parent_id));

    const leafNodes = inputArray.filter((obj) => !parentSet.has(obj.id));
    return leafNodes;
};
