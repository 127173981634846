/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description redux Actions - BusinessModels
 *
 * @version 20191120
 * @since 20191113 Initial release
 *
 */

import {
    REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED,
    REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_FAIL,
    REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_SUCCESS,
    REQUEST_GET_BUSINESS_MODELS,
    REQUEST_GET_BUSINESS_MODELS_SUCCESS,
    RESET_BUSINESS_MODELS,
    RESET_BUSINESS_MODEL_INFO,
    SET_BUSINESS_MODEL_BODY,
    SET_BUSINESS_MODEL_BODY_INPUTS,
    SET_BUSINESS_MODEL_OFFER_EDITION,
    SET_BUSINESS_MODEL_LOADER,
    SET_BUSINESS_MODEL,
    SET_BUSINESS_MODEL_OPTIONS_ME,
    SET_BUSINESS_MODEL_BODY_COST,
    SET_BUSINESS_MODEL_SERVICE_PPA,
    SET_BUSINESS_MODEL_BODY_BATTERY_ADDIN,
    RESET_STEPS_COMPLETED_BATTERY_ADDIN,
    SET_BUSINESS_MODEL_NEGOTIATIONS,
} from 'constants/businessModels';

export function resetStepsCompletedBatteryAddin() {
    return {
        type: RESET_STEPS_COMPLETED_BATTERY_ADDIN,
    };
}

export function setBusinessModelBodyBatteryAddin(payload) {
    return {
        type: SET_BUSINESS_MODEL_BODY_BATTERY_ADDIN,
        payload,
    };
}

export function setBusinessModel(payload) {
    return {
        type: SET_BUSINESS_MODEL,
        payload,
    };
}

export function setBusinessModelBody(payload) {
    return {
        type: SET_BUSINESS_MODEL_BODY,
        payload,
    };
}

export function setBusinessModelBodyCost(payload) {
    return {
        type: SET_BUSINESS_MODEL_BODY_COST,
        payload,
    };
}

export function setBusinessModelBodyInputs(payload) {
    return {
        type: SET_BUSINESS_MODEL_BODY_INPUTS,
        payload,
    };
}

export function setBusinessModelOfferEdition(payload) {
    return {
        type: SET_BUSINESS_MODEL_OFFER_EDITION,
        payload,
    };
}
export function requestGetBusinessModelKpisSelected(payload) {
    return {
        type: REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED,
        payload,
    };
}

export function requestGetBusinessModelKpisSelectedFail() {
    return {
        type: REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_FAIL,
    };
}

export function requestGetBusinessModelKpisSelectedSuccess(payload) {
    return {
        type: REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_SUCCESS,
        payload,
    };
}

export function requestGetBusinessModels(payload) {
    return {
        type: REQUEST_GET_BUSINESS_MODELS,
        payload,
    };
}

export function requestGetBusinessModelsSuccess(payload) {
    return {
        type: REQUEST_GET_BUSINESS_MODELS_SUCCESS,
        payload,
    };
}

export function setBusinessModelsLoading(payload) {
    return {
        type: SET_BUSINESS_MODEL_LOADER,
        payload,
    };
}

export function resetBusinessModels() {
    return {
        type: RESET_BUSINESS_MODELS,
    };
}

export function resetBusinessModelKpisSelected() {
    return {
        type: RESET_BUSINESS_MODEL_INFO,
    };
}

export function setBusinessModelOptionsME(payload) {
    return {
        type: SET_BUSINESS_MODEL_OPTIONS_ME,
        payload,
    };
}

export function setBusinessModelServicePPA(payload) {
    return {
        type: SET_BUSINESS_MODEL_SERVICE_PPA,
        payload,
    };
}
export function setBusinessModelNegotiations(payload) {
    return {
        type: SET_BUSINESS_MODEL_NEGOTIATIONS,
        payload,
    };
}
