import { defineMessages } from 'react-intl';
import enMessages from '../locales/de_DE.json';

const chCHLang = {
    messages: {
        ...enMessages,
    },
    locale: 'de-DE',
    data: defineMessages,
};
export default chCHLang;
