import { PRODUCT_IDS } from 'constants/products';
import { useEffect } from 'react';
import { isDefined } from './util/auxiliaryUtils';

export const withTracker = (WrappedComponent, name) => {
    const trackPage = (title) => {
        /* if (window.gtag instanceof Function) {
            // Google Analytics
            window.gtag('event', 'page_view', {
                'page_location': window.location.origin,
                'page_path': window.location.pathname,
                'page_title': title
            });
        } */

        if (isDefined(window?._paq) && window._paq instanceof Array) {
            // Matomo
            window._paq.push(['setDocumentTitle', title]);
            window._paq.push(['setCustomUrl', window.location.href]);
            window._paq.push(['trackPageView']);
            window._paq.push(['enableLinkTracking']);
        }
    };

    const HOC = (props) => {
        let title = name;

        if (name === 'SPV' && props.productID === PRODUCT_IDS.SPVSB) title = 'SPVSB';

        useEffect(() => {
            trackPage(title);
        }, []); //eslint-disable-line

        return <WrappedComponent {...props} />;
    };

    return HOC;
};
