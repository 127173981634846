// * DEFAULT OBJECTS * \\

export const INITIAL_UTILS = {
    loading: false,
    counties: {
        list: [],
        requesting: false,
    },
    batteryPowers: null,
    workSchedules: [],
    facilityGroups: null,
    facilityTypes: [],
    segmentsList: [],
    risksList: [],
};
export const ICONS_NAME = {
    FACILITIES: 'facilities',
    FACILITY: 'facility',
};

// * ACTION TYPES * \\
export const REQUEST_GET_COUNTIES_WITH_SEARCH = 'REQUEST_GET_COUNTIES_WITH_SEARCH';
export const REQUEST_GET_COUNTIES_WITH_SEARCH_SUCCESS = 'REQUEST_GET_COUNTIES_WITH_SEARCH_SUCCESS';
export const REQUEST_GET_COUNTIES_FAIL = 'REQUEST_GET_COUNTIES_FAIL';
export const RESET_ALL_PRODUCT_STATES = 'RESET_ALL_PRODUCT_STATES';
export const REQUEST_GET_WORK_SCHEDULE = 'REQUEST_GET_WORK_SCHEDULE';
export const REQUEST_GET_WORK_SCHEDULE_SUCCESS = 'REQUEST_GET_WORK_SCHEDULE_SUCCESS';
export const REQUEST_GET_FACILITY_GROUPS = 'REQUEST_GET_FACILITY_GROUPS';
export const REQUEST_GET_FACILITY_GROUPS_SUCCESS = 'REQUEST_GET_FACILITY_GROUPS_SUCCESS';
export const REQUEST_GET_SEGMENTS = 'REQUEST_GET_SEGMENTS';
export const REQUEST_GET_SEGMENTS_SUCCESS = 'REQUEST_GET_SEGMENTS_SUCCESS';
export const REQUEST_GET_SEGMENTS_FAIL = 'REQUEST_GET_SEGMENTS_FAIL';
export const REQUEST_GET_RISKS = 'REQUEST_GET_RISKS';
export const REQUEST_GET_RISKS_SUCCESS = 'REQUEST_GET_RISKS_SUCCESS';
export const REQUEST_GET_RISKS_FAIL = 'REQUEST_GET_RISKS_FAIL';
