import { IBMPitch, IBusinessModel, TBMProjectSummary, IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import useLECBM from 'hooks/businessModelsPro/LEC/useLECBM';
import BusinessModelBodyLEC from 'components/BusinessModels/List/BusinessModels/BusinessModelLEC/Card/Body';
import BusinessModelProjectSummaryLEC from 'components/BusinessModels/List/BusinessModels/BusinessModelLEC/Card/Summary/Summary';
import BusinessModelTitleLEC from 'components/BusinessModels/List/BusinessModels/BusinessModelLEC/Card/Title';
import IntlMessages from 'components/util/IntlMessages';
import CardActionArea from '@mui/material/CardActionArea';
import { useContext } from 'react';
import { BusinessModelsProContext, BusinessModelsProWebstompContext } from 'contexts/businessModelsPro/businessModelsContext';
import { BusinessModelsActions } from 'interfaces/businessModels/reducer';
import { Tlec } from 'interfaces/businessModels/lec';
import { BUSINESS_MODEL_NON_ELIGIBLE_REASONS, SIZING_TYPE_IDS } from 'constants/businessModels';
import { conditionsToDisableDetailedBM } from 'services/businessModels/index';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { Button } from '@save2compete/efz-design-system';
import { TIntl } from 'types/utils';

interface IProps {
    bm: IBusinessModel;
    postAuditHandler: (boolean, number) => void;
}

interface IHook {
    pitch: IBMPitch;
    projectSummary: TBMProjectSummary[];
    selected: boolean;
    id: number;
    syncKpis: any;
    sizingTypeId: number;
}
const BusinessModelLEC = ({ bm, postAuditHandler }: IProps) => {
    const { pitch, projectSummary, selected, id, sizingTypeId, syncKpis }: IHook = useLECBM(bm);

    const { dispatch } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, Tlec>;

    const { bmState, negotiationProfile } = useContext(OfferEditionContext);

    const { isLoadingWebStomp } = useContext(BusinessModelsProWebstompContext);

    const clickHandler = () => {
        !selected &&
            !disabledCard.condition &&
            dispatch({
                type: BusinessModelsActions.SELECT_BUSINESS_MODEL,
                payload: id,
            });
    };

    const disabledCard =
        ![SIZING_TYPE_IDS.SIMPLE, SIZING_TYPE_IDS.BOTS].includes(sizingTypeId) ?
            conditionsToDisableDetailedBM(syncKpis?.negotiation)
        :   { condition: false, reason: null };

    const hasErrorOnNetSavingsEligibility = syncKpis?.negotiation?.hasErrorOnNetSavingsEligibility;

    return (
        <div className={`business-models-card ${selected ? 'selected' : ''}`}>
            {pitch.recommended && (
                <div className="recommended">
                    <IntlMessages id="label.recommended" />
                </div>
            )}
            <CardActionArea
                component="div"
                className="business-models-card-wrapper"
                disableRipple
                disabled={isLoadingWebStomp}
                onClick={clickHandler}
            >
                <div className={`business-models-card-header ${pitch.recommended ? 'isRecommended' : ''}`}>
                    <BusinessModelTitleLEC description={pitch.description} summary={pitch.summary} />
                    {/* ADICIONAR BOTÃO DE MAIS DETALHES */}
                </div>
                <div className="business-models-card-container">
                    {/* OFFER EDITION  ??? */}
                    {!disabledCard.condition ?
                        <>
                            <div className="business-models-card-body">
                                <BusinessModelBodyLEC ownership={pitch.ownership} sections={pitch.sections} />
                            </div>
                            <div className="business-models-card-summary">
                                <h5>
                                    <IntlMessages id="label.projectSummary" />
                                </h5>
                                <BusinessModelProjectSummaryLEC projectSummary={projectSummary} syncKpis={syncKpis} />
                            </div>
                        </>
                    :   <div className="business-models-card-not-eligile">
                            <span className="not-eligible-title">
                                <IntlMessages id="page.businessModels.notEligible" />
                            </span>
                            <hr />
                            <div className="d-flex flex-column">
                                {negotiationProfile?.audita_proposta && (
                                    <Button
                                        variant="secondary"
                                        onClick={() => postAuditHandler(false, bm.business_model_id)}
                                        disabled={bmState.loadings.general}
                                        className={`not-eligible-button mb-3`}
                                        dataTestId="bm-detailed-proposal-autit"
                                    >
                                        <IntlMessages id="page.businessModels.auditProposal" />
                                    </Button>
                                )}
                                {hasErrorOnNetSavingsEligibility ?
                                    [
                                        BUSINESS_MODEL_NON_ELIGIBLE_REASONS.CAN_NEGOTIATE,
                                        BUSINESS_MODEL_NON_ELIGIBLE_REASONS.NOT_NET_SAVINGS_ELIGIBILITY,
                                        // @ts-ignore
                                    ].includes(disabledCard?.reason) && (
                                        <span className="not-eligible-reason">
                                            <IntlMessages id={`page.businessModels.notEligible.reason.${disabledCard?.reason}` as TIntl} />
                                        </span>
                                    )
                                :   <span className="not-eligible-reason">
                                        <IntlMessages id="page.businessModels.notEligible.reason" />
                                    </span>
                                }
                            </div>
                        </div>
                    }
                </div>
            </CardActionArea>
        </div>
    );
};

export default BusinessModelLEC;
