import { defineMessages } from 'react-intl/index';
import czMessages from '../locales/cz_CZ.json';

const czCZLang = {
    messages: {
        ...czMessages,
    },
    locale: 'cs-CZ',
    data: defineMessages,
};

export default czCZLang;
