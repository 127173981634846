import { useDispatch } from 'react-redux';
import { TProductInputs } from './../../interfaces/businessModels/index';
import { TBMs } from './../../interfaces/businessModels/reducer';
import { useContext, useEffect, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { setProposal } from 'redux/actions/proposal';
import { useHistory } from 'react-router-dom';

// API
import { postBusinessModels } from 'api/businessModels';

// Contexts / Hooks
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModel, IBusinessModelsContext } from 'interfaces/businessModels';
import { BusinessModelsActions } from 'interfaces/businessModels/reducer';
import { isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';
import { fetchBMPayload } from 'services/businessModels/index';
import { UserContext } from 'contexts/userContext';
import { conditionsToDisableDetailedBM } from 'services/businessModelss';
import { SIZING_TYPE_IDS } from 'constants/businessModels';
import { useProposalStore } from 'store/proposal';
import { PRODUCT_IDS } from 'constants/products';
import { BASENAME_URL, BASENAME_URL_HOME } from 'constants/settings';
import { SIZING_BODY } from 'constants/sizing';
import { ReduxContext } from 'contexts/redux/reduxContext';
import { postSizing } from 'api/sizing';
import { useBusinessModelsStore } from 'store/businessModels';

const useBusinessModelsPro = () => {
    const history = useHistory();

    const reduxDispatch = useDispatch();

    const { productID, bmCards, state, dispatch, productPayload } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TBMs
    >;
    const {
        actions: { setBusinessModelsStore },
    } = useBusinessModelsStore();

    const { companyProfileId, appLocale } = useContext(UserContext);
    const { facility } = useContext(ReduxContext);

    const facilityId = state?.simulation?.facility?.id;

    const [isRequestingBM, setIsRequestingBM] = useState(false);

    const setLocalReducer = () => {
        if (!isDefined(productPayload.productInputs)) {
            fetchProductSizing();
        } else {
            const { productInputs } = productPayload;

            const syncKpis = productInputs?.syncKpis ?? [];
            //region Rubrics
            const kpis = productInputs?.kpis ?? null;
            let defaultRubricCost = 0;
            const defaultCosts: any[] = [];
            const rubricsPerBM: any[] = [];

            if (!!kpis && !!kpis?.system?.costs) defaultCosts.push({ ...kpis?.system?.costs, isSync: false });
            if (syncKpis.length > 0) {
                syncKpis.forEach((item) => {
                    if (item?.kpis?.system?.costs)
                        defaultCosts.push({
                            ...item?.kpis?.system?.costs,
                            isSync: true,
                            tipo_modelo_negocio_id: item?.tipo_modelo_negocio_id,
                        });
                });
            }

            if (defaultCosts.length > 0) {
                defaultCosts.forEach((cost) => {
                    let defaultRubric = [];
                    const costRubricsAll = Object.keys(cost);
                    costRubricsAll.forEach((item) => {
                        let rubrics = cost?.[item]?.details?.filter((el) => el?.editable_cost) ?? [];
                        if (rubrics?.length > 0) {
                            rubrics = rubrics.map((elm, key) => {
                                elm.key = key;
                                elm.name = item;
                                elm.default_included_cost = elm.included_cost;
                                elm[item] = elm.included_cost ? parseInt(elm.final_cost) : 0;
                                if (elm.default_included_cost) defaultRubricCost = defaultRubricCost + parseInt(elm.final_cost);
                                return elm;
                            });
                            //@ts-ignore
                            defaultRubric = [...defaultRubric, ...rubrics];
                        }
                    });
                    rubricsPerBM.push({
                        isSync: cost.isSync,
                        tipo_modelo_negocio_id: cost?.tipo_modelo_negocio_id,
                        rubrics: defaultRubric,
                        defaultRubricCost,
                    });
                    defaultRubricCost = 0;
                });
            }

            dispatch({
                type: BusinessModelsActions.SET_INITIAL_PAYLOAD,
                payload: { ...productInputs, rubricsCosts: rubricsPerBM },
            });
        }
    };
    const fetchBusinessModelsHandler = async () => {
        try {
            setIsRequestingBM(true);
            const { productInputs, ...rest } = productPayload;
            delete rest.target_investment;
            const payload = {
                language: appLocale,
                productID,
                body: {
                    ...fetchBMPayload(productID, productPayload, { companyProfileId }),
                },
            };

            if (
                [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB, PRODUCT_IDS.SPV_SIMPLE].includes(productID) &&
                !isFieldDefined(useProposalStore.getState().img_contract_filename)
            ) {
                throw new Error('SPV_MISSING_IMG_CONTRACT_FILENAME');
            }
            if (!isDefined(payload?.body?.cost) && !isDefined(payload.body?.target_investment)) throw new Error('SPV: Missing cost');
            const rspBM = await postBusinessModels(payload);

            if (rspBM?.status === StatusCodes.OK) {
                const syncBusinessModelsWithErrorStatus =
                    productInputs?.syncKpis
                        ?.filter((kpi) => kpi?.sim_response_status !== StatusCodes.OK)
                        ?.map((kpi) => kpi?.tipo_modelo_negocio_id) ?? [];
                const fetchedBusinessModels: IBusinessModel[] = rspBM?.data?.data
                    ?.filter((bm) => !syncBusinessModelsWithErrorStatus.includes(bm?.business_model_id))
                    ?.map((bm) => {
                        return { ...bm, selected: false };
                    });

                dispatch({
                    type: BusinessModelsActions.SET_BUSINESS_MODELS,
                    payload: fetchedBusinessModels,
                });

                if (!!fetchedBusinessModels && fetchedBusinessModels?.length === 1) {
                    let canDefaultSelect = true;
                    if (![SIZING_TYPE_IDS.SIMPLE, SIZING_TYPE_IDS.BOTS].includes(fetchedBusinessModels[0]?.sizing_type_id))
                        canDefaultSelect = !conditionsToDisableDetailedBM(productInputs?.syncKpis[0]?.negotiation).condition;
                    canDefaultSelect &&
                        dispatch({
                            type: BusinessModelsActions.SELECT_BUSINESS_MODEL,
                            payload: fetchedBusinessModels?.[0]?.payment_model_id,
                        });
                }

                setIsRequestingBM(false);
            }
        } catch (error) {
            //@ts-ignore
            history.push(error?.message === 'SPV_MISSING_IMG_CONTRACT_FILENAME' ? `${BASENAME_URL_HOME}` : `${BASENAME_URL}app/403`);

            setIsRequestingBM(false);
            console.log('error fetchBusinessModelsHandler', error);
        }
    };
    const fetchProductSizing = async () => {
        switch (productID) {
            case PRODUCT_IDS.CFP: {
                const rspSimulation = await postSizing({
                    body: {
                        ...SIZING_BODY,
                        tipo_produto_id: productID,
                        facility: { id: Number(facility.info.id) },
                    },
                });
                const kpis = rspSimulation?.data?.data?.kpis;
                const hasOfferEdition = !!kpis?.offer_edition;
                const productInputs = {
                    facility: {
                        id: Number(facility.info.id),
                    },
                    annual_saving: kpis.annual_saving,
                    cost: hasOfferEdition ? kpis?.offer_edition?.cost : undefined,
                    target_investment: !hasOfferEdition ? kpis?.investment : undefined,
                    facility_id: parseInt(facility.info.id),
                    module_type: 'SIMPLES',
                };
                setBusinessModelsStore({
                    productInputs: productInputs,
                    ...productInputs,
                });
                break;
            }
            default:
                history.push(`${BASENAME_URL}app/403`);
        }
    };

    useEffect(() => {
        setLocalReducer();
    }, [productPayload?.facility_id]); // eslint-disable-line

    useEffect(() => {
        if (isDefined(state?.simulation?.facility?.id) || state.simulation.facility_id) {
            fetchBusinessModelsHandler();
        }
    }, [facilityId, appLocale, state.simulation?.facility_id]); // eslint-disable-line

    useEffect(() => {
        if (isDefined(state.proposal?.facility?.id)) {
            reduxDispatch(setProposal(state.proposal));
        }
    }, [state.proposal]); // eslint-disable-line

    return {
        bmCards,
        isRequestingBM,
    };
};

export default useBusinessModelsPro;
