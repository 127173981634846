import Collapse from '@mui/material/Collapse';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPaP } from 'interfaces/businessModels/pap';
import { useContext, useState } from 'react';
import GrantsKpisPaP from './Kpis';
import GrantsTablePaP from './Table';
import GrantsTitle from './Title';
import { isFieldDefined } from 'services/util/auxiliaryUtils';

const GrantsPaP = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="bm-grants">
            <GrantsTitle label="page.bm.grants.title" isOpen={isOpen} collapseHandler={() => setIsOpen(!isOpen)} />
            <Collapse in={isOpen}>
                <GrantsTablePaP />
                {isFieldDefined(bmSelected?.values?.grants) && bmSelected?.values?.grants?.totalGrants?.prc <= 100 && <GrantsKpisPaP />}
            </Collapse>
        </div>
    );
};

export default GrantsPaP;
