import { SIZING_TYPE_IDS } from 'constants/businessModels';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { useContext } from 'react';
import MarginGoalSeek from './MarginGoalSeek';
import MarginNoGoalSeek from './MarginNoGoalSeek';

const MarginInstallmentsDetailed = () => {
    const {
        bmState: { businessModelSelected },
    } = useContext(OfferEditionContext);

    const marginToDisplay = () => {
        switch (businessModelSelected?.sizing_type_id) {
            case SIZING_TYPE_IDS.DETAILED_FULL:
            case SIZING_TYPE_IDS.DETAILED_RISK_FREE:
                return <MarginGoalSeek />;
            case SIZING_TYPE_IDS.DETAILED_GOAL_SEEK_FREE:
                return <MarginNoGoalSeek />;
            default:
                return <div>ERROR</div>;
        }
    };

    return <div className="bm-detailed-negotiation-margin new">{marginToDisplay()}</div>;
};

export default MarginInstallmentsDetailed;
