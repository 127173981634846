import BusinessModels from 'components/BusinessModels';
import { Helmet } from 'react-helmet';
import { getHeadTitle } from 'services/settings';
import { intlMessages } from 'services/util/auxiliaryUtils';

const BusinessModelsContainer = () => {
    return (
        <div className="business-models-container">
            <Helmet>
                <title>{`${getHeadTitle()} · ${intlMessages('page.spvSimple.reserved.step.6')}`}</title>
            </Helmet>
            <BusinessModels haveActions={false} />
        </div>
    );
};

export default BusinessModelsContainer;
