import { DeepPartialSkipArrayKey, FieldErrors, FieldValues, GlobalError, Path } from 'react-hook-form';

// Constants
import {
    CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL,
    CHARGER_SETTINGS_INPUT_NAMES_EXTRAS,
    CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS,
    DEFAULT_FORM_VALUES,
    ENTRANCE_TYPE_OPTIONS,
    EVCProFlows,
    INPUT_NAMES,
    INPUT_NAMES_CHARGERS,
    INPUT_NAMES_INSTALLATION_DETAILS,
    INPUT_NAMES_MANAGEMENT,
    INSTALLATION_LOCATION_OPTIONS,
    FACILITY_TYPE_IDS,
    SECTION_NAMES,
    SIZING_ERRORS,
    MANAGEMENT_OPTIONS,
} from 'constants/products/evcPro';
import { PRODUCT_IDS } from 'constants/products';

// Interfaces
import {
    ALL_INPUT_NAMES,
    TEVCProInputs,
    TEVCProInputs_OnCompany,
    IEVCProInputsCharging_OnCompany_Private,
    IEVCProInputsCharging_OnCompany_Public,
    IEVCProInputs_OnOther,
    TFormQuestions,
    IEVCProOptions,
    IEVCProInputsPublicCharging_Hotel,
    IEVCProInputsPublicCharging_Other,
    IEVCProInputsPublicCharging_Restaurant,
} from 'interfaces/products/evcPro';
import { SelectOption, TApplicableSections } from 'interfaces/products/evcPro/inputs';
import { Nullable } from 'types/utils';

// Services
import { isNumberDefined, isFieldDefined, parseBoolean, removeEmptyInObj } from 'services/util/auxiliaryUtils';
import { TFeatureFlag } from 'interfaces/settings';

export const isChargingOnCompany = (inputs: TEVCProInputs | INSTALLATION_LOCATION_OPTIONS): inputs is TEVCProInputs_OnCompany => {
    if (typeof inputs === 'number') return inputs === INSTALLATION_LOCATION_OPTIONS.COMPANY;

    return (
        inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.installation_location_option_id] ===
        INSTALLATION_LOCATION_OPTIONS.COMPANY
    );
};

export const isChargingOnCompanyPrivateAccess = (
    inputs: TEVCProInputs | TEVCProInputs_OnCompany | ENTRANCE_TYPE_OPTIONS
): inputs is IEVCProInputsCharging_OnCompany_Private => {
    if (typeof inputs === 'number') return inputs === ENTRANCE_TYPE_OPTIONS.PRIVATE;

    if (!isChargingOnCompany(inputs)) return false;

    return (
        inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.entrance_type_id] === ENTRANCE_TYPE_OPTIONS.PRIVATE
    );
};

export const isChargingOnCompanyPublicAccess = (
    inputs: TEVCProInputs | TEVCProInputs_OnCompany | ENTRANCE_TYPE_OPTIONS
): inputs is IEVCProInputsCharging_OnCompany_Public => {
    if (typeof inputs === 'number') return inputs === ENTRANCE_TYPE_OPTIONS.PUBLIC;

    if (!isChargingOnCompany(inputs)) return false;

    return (
        inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.entrance_type_id] === ENTRANCE_TYPE_OPTIONS.PUBLIC
    );
};

export const isChargingOnCompanyPublicAccessOther = (
    inputs: TEVCProInputs | TEVCProInputs_OnCompany | FACILITY_TYPE_IDS
): inputs is IEVCProInputsPublicCharging_Other => {
    if (typeof inputs === 'number') return inputs === FACILITY_TYPE_IDS.OTHERESTABLISHMENTS;

    return (
        inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.facility_type_id] ===
        FACILITY_TYPE_IDS.OTHERESTABLISHMENTS
    );
};

export const isChargingOnCompanyPublicAccessHotel = (
    inputs: TEVCProInputs | TEVCProInputs_OnCompany | FACILITY_TYPE_IDS
): inputs is IEVCProInputsPublicCharging_Hotel => {
    if (typeof inputs === 'number') return inputs === FACILITY_TYPE_IDS.HOTEL;

    return inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.facility_type_id] === FACILITY_TYPE_IDS.HOTEL;
};

export const isChargingOnCompanyPublicAccessRestaurant = (
    inputs: TEVCProInputs | TEVCProInputs_OnCompany | FACILITY_TYPE_IDS
): inputs is IEVCProInputsPublicCharging_Restaurant => {
    if (typeof inputs === 'number') return inputs === FACILITY_TYPE_IDS.RESTAURANT;

    return (
        inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.facility_type_id] === FACILITY_TYPE_IDS.RESTAURANT
    );
};

export const isChargingOnOther = (inputs: TEVCProInputs | INSTALLATION_LOCATION_OPTIONS): inputs is IEVCProInputs_OnOther => {
    if (typeof inputs === 'number') return inputs === INSTALLATION_LOCATION_OPTIONS.OTHER;

    return (
        inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.installation_location_option_id] ===
        INSTALLATION_LOCATION_OPTIONS.OTHER
    );
};

export const isChargingOnCompanyPublicAccessWithPartnership = (inputs: TEVCProInputs): boolean => {
    return inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership] === true;
};

export const hasPartneshipRequirments = (inputs: TEVCProInputs): boolean => {
    return inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.has_partnership_requirements] === true;
};

export const isPartnershipReadyToContinue = (inputs: TEVCProInputs): boolean => {
    return inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.ready_to_continue] === true;
};

export const wantsPartnership = (inputs: TEVCProInputs): boolean => {
    return inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership] === true;
};

export const hasInstallChargerDefined = (inputs: TEVCProInputs): boolean => {
    return isNumberDefined(inputs?.[SECTION_NAMES.CHARGERS]?.[INPUT_NAMES_CHARGERS.charger_points_number]);
};

export const isChargingOnCompanyPublicAccessWithoutPartnership = (inputs: TEVCProInputs): boolean => {
    if (!isChargingOnCompanyPublicAccess(inputs)) return false;

    return inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership] === false;
};

export const getFormInputName = <T extends FieldValues = TEVCProInputs>(name: ALL_INPUT_NAMES): Path<T> => {
    for (const [sectionName, sectionInputs] of Object.entries(INPUT_NAMES)) {
        for (const inputName of Object.values(sectionInputs)) {
            if (inputName === name) return `${sectionName}.${inputName}` as Path<T>;
        }
    }
    return name as Path<T>;
};

interface IGetFlowFromInputs {
    [SECTION_NAMES.INSTALLATION_DETAILS]?: {
        [INPUT_NAMES_INSTALLATION_DETAILS.entrance_type_id]?: Nullable<ENTRANCE_TYPE_OPTIONS>;
        [INPUT_NAMES_INSTALLATION_DETAILS.installation_location_option_id]?: Nullable<INSTALLATION_LOCATION_OPTIONS>;
    };
}

export const getFlowFromInputs = (inputs: IGetFlowFromInputs): EVCProFlows => {
    if (inputs?.[SECTION_NAMES.INSTALLATION_DETAILS] === undefined) return EVCProFlows.not_selected;

    const installation_location_option_id =
        inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.installation_location_option_id];
    if (installation_location_option_id) {
        if (isChargingOnOther(installation_location_option_id)) return EVCProFlows.on_other;
    }

    const entrance_type_id = inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.entrance_type_id];
    if (entrance_type_id) {
        if (isChargingOnCompanyPrivateAccess(entrance_type_id)) return EVCProFlows.on_company_private;

        const partnership_facility_type_id =
            inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.facility_type_id];
        if (partnership_facility_type_id) {
            if (hasPartneshipRequirments(inputs as TEVCProInputs) && !wantsPartnership(inputs as TEVCProInputs)) {
                if (isChargingOnCompanyPublicAccessHotel(partnership_facility_type_id))
                    return EVCProFlows.on_company_public_hotel_no_partnership;

                if (isChargingOnCompanyPublicAccessRestaurant(partnership_facility_type_id))
                    return EVCProFlows.on_company_public_restaurant_no_partnership;

                return EVCProFlows.on_company_public_no_partnership;
            } else {
                if (isChargingOnCompanyPublicAccessOther(partnership_facility_type_id)) return EVCProFlows.on_company_public_other;

                if (isChargingOnCompanyPublicAccessHotel(partnership_facility_type_id)) return EVCProFlows.on_company_public_hotel;

                if (isChargingOnCompanyPublicAccessRestaurant(partnership_facility_type_id))
                    return EVCProFlows.on_company_public_restaurant;
            }
        }

        if (isChargingOnCompanyPublicAccess(entrance_type_id)) return EVCProFlows.on_company_public;
    }

    return EVCProFlows.not_selected;
};

export const isVisibleOnFlow = (currentFlow: EVCProFlows, applicableSections: TApplicableSections = true): boolean => {
    if (!currentFlow) return false;

    if (typeof applicableSections === 'boolean') return applicableSections;

    if (Array.isArray(applicableSections)) return applicableSections.includes(currentFlow);

    return currentFlow === applicableSections;
};

export const isDisabledOnFlow = (currentFlow: EVCProFlows, applicableSections: TApplicableSections = false): boolean => {
    if (!currentFlow) return true;

    if (typeof applicableSections === 'boolean') return applicableSections;

    if (Array.isArray(applicableSections)) return applicableSections.includes(currentFlow);

    return currentFlow === applicableSections;
};

export const getFieldError = <T extends FieldValues = TEVCProInputs>(
    path: Path<T>,
    errors: FieldErrors<T>
): (GlobalError & { values?: { [key: string]: string | number | boolean } }) | undefined => {
    const parts = path.split('.');
    if (parts?.length !== 2) return undefined;

    const section = parts?.at?.(0);
    const name = parts?.at?.(1);

    if (!section || !name) return undefined;

    if (!errors?.[section]?.[name]) return undefined;

    return {
        ...errors?.[section]?.[name],
    };
};

type IGetSectionsVisibilityStateResponse = {
    // eslint-disable-next-line no-unused-vars
    [K in SECTION_NAMES]: boolean;
};

interface IGetSectionsVisibilityStateOptions {
    eligibleForPartnership?: boolean;
    featureFlags: TFeatureFlag[];
}

export const validatePlafondQuestions = (inputs: DeepPartialSkipArrayKey<TEVCProInputs>) => {
    const hasPlafond = inputs?.[SECTION_NAMES.MANAGEMENT]?.[INPUT_NAMES_MANAGEMENT.has_plafond];
    const plafondCardsSmart = inputs?.[SECTION_NAMES.MANAGEMENT]?.[INPUT_NAMES_MANAGEMENT.plafond_smart];
    const plafondCardsPremium = inputs?.[SECTION_NAMES.MANAGEMENT]?.[INPUT_NAMES_MANAGEMENT.plafond_premium];

    return (
        !isFieldDefined(hasPlafond) ? false
        : hasPlafond ? isNumberDefined(plafondCardsSmart) && isNumberDefined(plafondCardsPremium)
        : true
    );
};

const validateFlowOnOther = (inputs: DeepPartialSkipArrayKey<TEVCProInputs>) => {
    const isDPCManagementOption =
        inputs?.[SECTION_NAMES.MANAGEMENT]?.[INPUT_NAMES_MANAGEMENT.management_option_id] === MANAGEMENT_OPTIONS.DPC;

    return isDPCManagementOption ? true : validatePlafondQuestions(inputs);
};

const computeIsChargersSectionVisible = (
    inputs: DeepPartialSkipArrayKey<TEVCProInputs>,
    options?: IGetSectionsVisibilityStateOptions,
    currentFlow?: EVCProFlows,
    isInPartnership?: boolean
) => {
    if (!currentFlow) currentFlow = getFlowFromInputs(inputs);

    if (!isInPartnership) isInPartnership = isChargingOnCompanyPublicAccessWithPartnership(inputs as TEVCProInputs);

    if (
        [EVCProFlows.on_company_public_other, EVCProFlows.on_company_public_hotel, EVCProFlows.on_company_public_restaurant].includes(
            currentFlow
        ) &&
        isInPartnership &&
        inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.ready_to_continue]
    )
        return true;

    if (
        ([EVCProFlows.on_other].includes(currentFlow) || [EVCProFlows.on_company_private].includes(currentFlow)) &&
        validateFlowOnOther(inputs)
    )
        return true;

    if (
        ![EVCProFlows.on_other].includes(currentFlow) &&
        isFieldDefined(inputs?.[SECTION_NAMES.MANAGEMENT]?.[INPUT_NAMES_MANAGEMENT.management_option_id]) &&
        inputs?.[SECTION_NAMES.MANAGEMENT]?.[INPUT_NAMES_MANAGEMENT.management_option_id] !== MANAGEMENT_OPTIONS.EDP_CHARGE &&
        !isInPartnership
    )
        return true;

    return false;
};

export const getSectionsVisibilityState = (
    inputs: DeepPartialSkipArrayKey<TEVCProInputs>,
    options?: IGetSectionsVisibilityStateOptions
): IGetSectionsVisibilityStateResponse => {
    const currentFlow = getFlowFromInputs(inputs);
    const isInPartnership = isChargingOnCompanyPublicAccessWithPartnership(inputs as TEVCProInputs);

    const isInstallationDetailsVisible = true; // always true (its the starting point)

    const isManagementVisible =
        [EVCProFlows.on_company_private, EVCProFlows.on_other].includes(currentFlow) ||
        ([
            EVCProFlows.on_company_public_other,
            EVCProFlows.on_company_public_hotel,
            EVCProFlows.on_company_public_restaurant,
            EVCProFlows.on_company_public_no_partnership,
            EVCProFlows.on_company_public_hotel_no_partnership,
            EVCProFlows.on_company_public_restaurant_no_partnership,
        ].includes(currentFlow) &&
            !isInPartnership &&
            inputs?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.ready_to_continue]) ||
        ([EVCProFlows.on_company_public].includes(currentFlow) && !options?.eligibleForPartnership);

    const isChargersVisible = computeIsChargersSectionVisible(inputs, options, currentFlow, isInPartnership);

    return {
        [SECTION_NAMES.INSTALLATION_DETAILS]: isInstallationDetailsVisible,
        [SECTION_NAMES.MANAGEMENT]: isManagementVisible,
        [SECTION_NAMES.CHARGERS]: isChargersVisible,
        [SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL]: true,
        [SECTION_NAMES.CHARGER_SETTINGS_INSTALLATION_DETAILS]: true,
        [SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]: true,
    };
};

export const getDefaultValuesByChargeStations = (options: any) => {
    const { isDPC, isExterior, chargers_selected } = options;

    return {
        [SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL]: {
            [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.has_electrical_panel]:
                chargers_selected?.[0]?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL]?.[
                    CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.has_electrical_panel
                ] ?? null,
            [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_corrugated_pipe]:
                chargers_selected?.[0]?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL]?.[
                    CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_corrugated_pipe
                ] ?? false,
            [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_vd_tube]:
                chargers_selected?.[0]?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL]?.[
                    CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_vd_tube
                ] ?? false,
            [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor]:
                chargers_selected?.[0]?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL]?.[
                    CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor
                ] ?? false,
            [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor_distance]:
                chargers_selected?.[0]?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL]?.[
                    CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor_distance
                ] ?? null,
        },
        [SECTION_NAMES.CHARGER_SETTINGS_INSTALLATION_DETAILS]: {
            [CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.charging_type]: false,
            [CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.electric_panel_to_charging_station_distance]: null,
            [CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.electric_vehicle_panel_to_charging_station_distance]: null,
        },
        [SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]: {
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.vd_tube]: false,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cable_conveyor]: false,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cable_conveyor_distance]: null,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_massive]:
                chargers_selected?.[0]?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[
                    CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_massive
                ] ?? false,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.pedestal_massive]: false,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench]:
                chargers_selected?.[0]?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[
                    CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench
                ] ?? false,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_type]:
                chargers_selected?.[0]?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[
                    CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_type
                ] ?? undefined,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_distance]:
                chargers_selected?.[0]?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[
                    CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_distance
                ] ?? null,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cutting_panel]: isDPC ? true : false,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.mechanic_protection]: isExterior ? true : false,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.vertical_signs]: isDPC ? true : false,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.horizontal_signs]: isDPC ? true : false,
        },
    };
};

export const getFormattedInputsForPayload = (inputs: TEVCProInputs, isPreSizing = false) => {
    // Private Charging on Other
    if (isChargingOnOther(inputs)) {
        return removeEmptyInObj({
            ...inputs?.[SECTION_NAMES.INSTALLATION_DETAILS],
            ...inputs?.[SECTION_NAMES.MANAGEMENT],
            ...inputs?.[SECTION_NAMES.CHARGERS],
            //#region inputs needed so the API doesn't break
            [INPUT_NAMES_INSTALLATION_DETAILS.entrance_type_id]: 2,
            [INPUT_NAMES_CHARGERS.appliance_type_id]: 2,
            [INPUT_NAMES_CHARGERS.place_of_installation_id]: 1,
            distance_to_power_pole: 0,
            //#endregion inputs needed so the API doesn't break
        });
    }

    // Public Charging with Partnership
    if (isChargingOnCompanyPublicAccess(inputs) && isChargingOnCompanyPublicAccessWithPartnership(inputs)) {
        return removeEmptyInObj({
            ...inputs?.[SECTION_NAMES.INSTALLATION_DETAILS],
            ...inputs?.[SECTION_NAMES.MANAGEMENT],
            ...inputs?.[SECTION_NAMES.CHARGERS],
            //#region inputs needed so the API doesn't break
            [INPUT_NAMES_CHARGERS.selected_chargers]:
                inputs?.[SECTION_NAMES.CHARGERS]?.[INPUT_NAMES_CHARGERS.selected_chargers]?.length > 0 ?
                    inputs?.[SECTION_NAMES.CHARGERS]?.[INPUT_NAMES_CHARGERS.selected_chargers]
                :   undefined,
            [INPUT_NAMES_MANAGEMENT.management_option_id]: 2,
            //#endregion inputs needed so the API doesn't break
        });
    }

    // Private Charging on Company
    const qveInputs = inputs?.[SECTION_NAMES.CHARGERS]?.[INPUT_NAMES_CHARGERS.selected_chargers]?.find(
        (charger) => !!charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL]
    )?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL];

    const hasQve = parseBoolean(`${qveInputs?.[CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.has_electrical_panel]}`);
    const formattedQveInputs = {
        [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.has_electrical_panel]: hasQve,
        [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_corrugated_pipe]:
            hasQve ? parseBoolean(`${qveInputs?.[CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_corrugated_pipe]}`) : false,
        [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_vd_tube]:
            hasQve ? parseBoolean(`${qveInputs?.[CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_vd_tube]}`) : false,
        [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor]:
            hasQve ? parseBoolean(`${qveInputs?.[CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor]}`) : false,
        [CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor_distance]:
            hasQve ? parseFloat(`${qveInputs?.[CHARGER_SETTINGS_INPUT_NAMES_ELECTRICAL_PANEL.qve_cable_conveyor_distance]}`) : null,
    };

    const formattedSelectedChargers = inputs?.[SECTION_NAMES.CHARGERS]?.[INPUT_NAMES_CHARGERS.selected_chargers]?.map?.((charger) => ({
        ...charger,
        extras: {
            [CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.electric_panel_to_charging_station_distance]: parseFloat(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_INSTALLATION_DETAILS]?.[CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.electric_panel_to_charging_station_distance]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.electric_vehicle_panel_to_charging_station_distance]:
                hasQve ?
                    parseFloat(
                        `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_INSTALLATION_DETAILS]?.[CHARGER_SETTINGS_INPUT_NAMES_INSTALLATION_DETAILS.electric_vehicle_panel_to_charging_station_distance]}`
                    )
                :   undefined,
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cable_conveyor]: parseBoolean(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cable_conveyor]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cable_conveyor_distance]: parseFloat(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cable_conveyor_distance]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_massive]: parseBoolean(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_massive]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.pedestal_massive]: parseBoolean(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.pedestal_massive]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cutting_panel]: parseBoolean(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.cutting_panel]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench]: parseBoolean(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_type]: parseFloat(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_type]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_distance]: parseFloat(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_distance]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.mechanic_protection]: parseBoolean(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.mechanic_protection]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.vertical_signs]: parseBoolean(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.vertical_signs]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.horizontal_signs]: parseBoolean(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.horizontal_signs]}`
            ),
            [CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.vd_tube]: parseBoolean(
                `${charger?.settings?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.vd_tube]}`
            ),
        },
    }));

    return {
        ...inputs?.[SECTION_NAMES.INSTALLATION_DETAILS],
        ...inputs?.[SECTION_NAMES.MANAGEMENT],
        ...inputs?.[SECTION_NAMES.CHARGERS],
        pre_sizing: isPreSizing,
        qve: formattedQveInputs,
        [INPUT_NAMES_CHARGERS.selected_chargers]: formattedSelectedChargers,
    };
};

export const getPayloadForKPIsRequest = (inputs: TEVCProInputs, facilityID: string, isSmallBusiness = false) => {
    return {
        facility: {
            id: facilityID,
        },
        tipo_produto_id: isSmallBusiness ? PRODUCT_IDS.EVCSB : PRODUCT_IDS.EVC,
        is_scaling: true,
        inputs: getFormattedInputsForPayload(inputs, false),
    };
};

export const getPayloadForKPIsRequestOnOther = (inputs: TEVCProInputs, facilityID: string) => {
    const payload = {
        facility: {
            id: facilityID,
        },
        tipo_produto_id: PRODUCT_IDS.EVC,
        is_scaling: true,
        inputs: getFormattedInputsForPayload(inputs, false),
    };
    return payload;
};

export const getPayloadForKPIsRequestOnPublicAccess = (inputs: TEVCProInputs, facilityID: string) => {
    const payload = {
        facility: {
            id: facilityID,
        },
        tipo_produto_id: PRODUCT_IDS.EVC,
        is_scaling: true,
        inputs: getFormattedInputsForPayload(inputs, false),
    };
    return payload;
};

export const formatProductInputsResponseToFormStructure = (productInputsResponse) => {
    const sectionNames = Object.keys(SECTION_NAMES);
    // if it is in the right format, return it
    for (const key in productInputsResponse) {
        for (const section of sectionNames) {
            if (section === key) return productInputsResponse;
        }
    }

    return {
        [SECTION_NAMES.INSTALLATION_DETAILS]: {
            [INPUT_NAMES_INSTALLATION_DETAILS.facility_type_id]:
                productInputsResponse?.[INPUT_NAMES_INSTALLATION_DETAILS.facility_type_id] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.facility_type_id],
            [INPUT_NAMES_INSTALLATION_DETAILS.public_access_private_parking]:
                productInputsResponse?.[INPUT_NAMES_INSTALLATION_DETAILS.public_access_private_parking] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.public_access_private_parking],
            [INPUT_NAMES_INSTALLATION_DETAILS.ready_to_continue]:
                productInputsResponse?.[INPUT_NAMES_INSTALLATION_DETAILS.ready_to_continue] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.ready_to_continue],
            [INPUT_NAMES_INSTALLATION_DETAILS.number_of_rooms_greater_than_20]:
                productInputsResponse?.[INPUT_NAMES_INSTALLATION_DETAILS.number_of_rooms_greater_than_20] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[
                    INPUT_NAMES_INSTALLATION_DETAILS.number_of_rooms_greater_than_20
                ],
            [INPUT_NAMES_INSTALLATION_DETAILS.has_min_open_schedule]:
                productInputsResponse?.[INPUT_NAMES_INSTALLATION_DETAILS.has_min_open_schedule] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.has_min_open_schedule],
            [INPUT_NAMES_INSTALLATION_DETAILS.has_min_parking_spaces]:
                productInputsResponse?.[INPUT_NAMES_INSTALLATION_DETAILS.has_min_parking_spaces] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.has_min_parking_spaces],
            [INPUT_NAMES_INSTALLATION_DETAILS.charging_location_option_id]:
                productInputsResponse?.[INPUT_NAMES_INSTALLATION_DETAILS.charging_location_option_id] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.charging_location_option_id],
            [INPUT_NAMES_INSTALLATION_DETAILS.entrance_type_id]:
                productInputsResponse?.[INPUT_NAMES_INSTALLATION_DETAILS.entrance_type_id] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.entrance_type_id],
            [INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership]:
                productInputsResponse?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership],
            [INPUT_NAMES_INSTALLATION_DETAILS.sharing_model_id]:
                productInputsResponse?.[INPUT_NAMES_INSTALLATION_DETAILS.sharing_model_id] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.sharing_model_id],
            [INPUT_NAMES_INSTALLATION_DETAILS.installation_location_option_id]:
                productInputsResponse?.[INPUT_NAMES_INSTALLATION_DETAILS.installation_location_option_id] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[
                    INPUT_NAMES_INSTALLATION_DETAILS.installation_location_option_id
                ],
        },
        [SECTION_NAMES.MANAGEMENT]: {
            [INPUT_NAMES_MANAGEMENT.management_option_id]:
                productInputsResponse?.[INPUT_NAMES_MANAGEMENT.management_option_id] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.MANAGEMENT]?.[INPUT_NAMES_MANAGEMENT.management_option_id],
            [INPUT_NAMES_MANAGEMENT.has_plafond]:
                productInputsResponse?.[INPUT_NAMES_MANAGEMENT.has_plafond] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.MANAGEMENT]?.[INPUT_NAMES_MANAGEMENT.has_plafond],
            [INPUT_NAMES_MANAGEMENT.plafond_smart]:
                productInputsResponse?.[INPUT_NAMES_MANAGEMENT.plafond_smart] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.MANAGEMENT]?.[INPUT_NAMES_MANAGEMENT.plafond_smart],
            [INPUT_NAMES_MANAGEMENT.plafond_premium]:
                productInputsResponse?.[INPUT_NAMES_MANAGEMENT.plafond_premium] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.MANAGEMENT]?.[INPUT_NAMES_MANAGEMENT.plafond_premium],
        },
        [SECTION_NAMES.CHARGERS]: {
            [INPUT_NAMES_CHARGERS.appliance_type_id]:
                productInputsResponse?.[INPUT_NAMES_CHARGERS.appliance_type_id] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.CHARGERS]?.[INPUT_NAMES_CHARGERS.appliance_type_id],
            [INPUT_NAMES_CHARGERS.charger_points_number]:
                productInputsResponse?.[INPUT_NAMES_CHARGERS.charger_points_number] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.CHARGERS]?.[INPUT_NAMES_CHARGERS.charger_points_number],
            [INPUT_NAMES_CHARGERS.cpe_details_file]:
                productInputsResponse?.[INPUT_NAMES_CHARGERS.cpe_details_file] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.CHARGERS]?.[INPUT_NAMES_CHARGERS.cpe_details_file],
            [INPUT_NAMES_CHARGERS.install_charger_points]:
                productInputsResponse?.[INPUT_NAMES_CHARGERS.install_charger_points] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.CHARGERS]?.[INPUT_NAMES_CHARGERS.install_charger_points],
            [INPUT_NAMES_CHARGERS.place_of_installation_id]:
                productInputsResponse?.[INPUT_NAMES_CHARGERS.place_of_installation_id] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.CHARGERS]?.[INPUT_NAMES_CHARGERS.place_of_installation_id],
            [INPUT_NAMES_CHARGERS.selected_chargers]:
                productInputsResponse?.[INPUT_NAMES_CHARGERS.selected_chargers] ??
                DEFAULT_FORM_VALUES?.[SECTION_NAMES.CHARGERS]?.[INPUT_NAMES_CHARGERS.selected_chargers],
        },
    };
};

export const addOptionsToContextQuestions = (questions: TFormQuestions, options: IEVCProOptions) => {
    if (!options || (!!options && !options.trench)) return questions;
    if (!Array.isArray(options.trench) || options.trench.length === 0) return questions;

    //#region qve_trench_type
    if (
        !questions?.[SECTION_NAMES.CHARGER_SETTINGS_ELECTRICAL_PANEL] ||
        !questions?.[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS]?.[CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_type]
    )
        return questions;
    const trenchOptions: SelectOption[] = [];
    options.trench.forEach((option) => {
        trenchOptions.push({
            value: option.id,
            label: `evc.qve.qve_trench_type.${option.tag}`,
        });
    });
    questions[SECTION_NAMES.CHARGER_SETTINGS_EXTRAS][CHARGER_SETTINGS_INPUT_NAMES_EXTRAS.qve_trench_type].options = trenchOptions;
    //#endregion qve_trench_type

    return questions;
};

export const hasSizingRedWarning = (errors?: SIZING_ERRORS) => {
    return !!errors && [SIZING_ERRORS.REMOVE_ONE_OR_MORE_CHARGERS].includes(errors);
};
