import { useContext } from 'react';
import InstallmentsSimpleMarginKpi from 'components/BusinessModels/ProjectSummary/ProjectSummaryInstallments/simple/CommercialMargin/MarginKpi';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { isDefined } from 'services/util/auxiliaryUtils';
import { TInstallments } from 'interfaces/businessModels/installments';

const InstalmmentsSimpleMarginKpis = () => {
    const { bmSelected, isRequestingBM } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TInstallments>;

    const searchValue = bmSelected?.values?.margin_negotiation?.search;
    const marginSelected = bmSelected?.values?.marginSelected;
    const kpis_per_margin = bmSelected?.values?.kpis_per_margin;
    const kpisSelected = kpis_per_margin.find((el) => el?.[searchValue] === marginSelected);
    const currentInvestment = kpisSelected?.investment ?? kpisSelected?.price ?? 0;

    const annualSaving = kpisSelected?.annual_saving ?? null;
    const payback = isDefined(annualSaving) ? currentInvestment / annualSaving! : null;
    const monthlyFee = kpisSelected?.monthly_payments_fee ?? null;
    const numberOfMonthlyFee = kpisSelected?.monthly_payments_number ?? null;

    return (
        <div className="bm-kpis-margin">
            <InstallmentsSimpleMarginKpi
                isLoading={isRequestingBM}
                label={'label.investment'}
                value={{ unit: 'currency', number: currentInvestment, numberOfDecimalPlaces: 0 }}
            />
            {
                <InstallmentsSimpleMarginKpi
                    isLoading={isRequestingBM}
                    label="label.annualSaving"
                    value={{ unit: 'currency', number: annualSaving, numberOfDecimalPlaces: 0 }}
                />
            }
            {
                <InstallmentsSimpleMarginKpi
                    isLoading={isRequestingBM}
                    label="label.payback"
                    value={{ unit: 'year', number: payback, numberOfDecimalPlaces: 1 }}
                />
            }
            {
                <InstallmentsSimpleMarginKpi
                    isLoading={isRequestingBM}
                    label="label.monthlyPaymentsFee"
                    value={{ unit: 'mc', number: monthlyFee, numberOfDecimalPlaces: 0 }}
                />
            }
            {
                <InstallmentsSimpleMarginKpi
                    isLoading={isRequestingBM}
                    label="label.payback"
                    value={{ number: numberOfMonthlyFee, numberOfDecimalPlaces: 0 }}
                />
            }
        </div>
    );
};

export default InstalmmentsSimpleMarginKpis;
