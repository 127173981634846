import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront } from 'interfaces/businessModels/upfront';
import { useContext } from 'react';
import GrantsKpiUpFront from './Kpi';

const GrantsKpisUpfront = () => {
    const { state, bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const currentInvestment = bmSelected?.values?.costs?.marginCost;
    const searchValue = bmSelected?.values?.margin_negotiation?.search;
    const annualSaving =
        bmSelected?.values?.kpis_per_margin?.find((kpi) => kpi?.[searchValue] === bmSelected?.values?.marginSelected)?.annual_saving ??
        state?.businessModels?.[0]?.kpis_previews?.[0]?.kpis_per_margin?.[0]?.annual_saving;
    const grants = state?.selected?.values?.grants;
    // const grantsValue = grants?.reduce((accumulator, currentValue) => accumulator + currentValue.ammounts.currency, 0);
    const investmentWithGrant = currentInvestment - grants?.totalGrants?.currency;
    const payback = investmentWithGrant / annualSaving;

    return (
        <div className="bm-kpis">
            <GrantsKpiUpFront
                label="page.bm.grants.originalInvestment"
                value={{ number: currentInvestment, unit: 'currency', numberOfDecimalPlaces: 0 }}
            />
            <GrantsKpiUpFront
                label="page.bm.grants.grant"
                value={{ number: grants.totalGrants.currency, unit: 'currency', numberOfDecimalPlaces: 2 }}
            />
            <GrantsKpiUpFront
                label="page.bm.grants.investmentWithGrant"
                value={{ number: investmentWithGrant, unit: 'currency', numberOfDecimalPlaces: 0 }}
            />
            <GrantsKpiUpFront label="label.payback" value={{ number: payback, unit: 'year', numberOfDecimalPlaces: 1 }} />
        </div>
    );
};

export default GrantsKpisUpfront;
