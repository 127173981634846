import { useCallback, useContext, useEffect, useState } from 'react';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { getDetectRoofTop, getAnalyzeAreaB2C, postProductions } from 'api/products/solarpvSimple';
import { StatusCodes } from 'http-status-codes';
import { AREA_TYPES, HOUSING_TYPES_B2C, SPV_SIMPLE_INPUTS, spvSimpleActions, SpvSimpleError } from 'constants/products/solarpvSimple';

import { getPayloadToPreSims } from 'services/products/solarpvSimple';
import { isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';
import { SentryCaptureException } from 'services/@efz/sentry';
import { useFormContext } from 'react-hook-form';

//#region AbortControllers
let getDetectRooftopAbortController;
let getDetectRoofTypesB2CAbortController;
let postSimulationAbortController;

function cleanup() {
    getDetectRooftopAbortController && getDetectRooftopAbortController.abort();
    getDetectRoofTypesB2CAbortController && getDetectRoofTypesB2CAbortController.abort();
    postSimulationAbortController && postSimulationAbortController.abort();
}
//#endregion AbortControllers

const useRoofIdentification = () => {
    const { setValue } = useFormContext();

    const [isRequestRoofIFail, setIsRequestRoofIFail] = useState(false);

    const {
        isB2C,
        spvSimpleState: { inputs, isOArea, options, isDetectRoof, productID },
        spvSimpleDispatchHandler: dispatch,
    } = useContext(SPVSimpleContext);
    let {
        facility: { location },
        housing_type,
        areas,
        roofCoordinates,
    } = inputs;

    const fetchRoofIdentification = useCallback(
        async (payload) => {
            let payloadDRoofTypes;
            let rspDTTop = null;
            let rspAnalyseRoof;
            let roof = [];
            try {
                //#region pre-sizing
                postSimulationAbortController && postSimulationAbortController.abort();
                postSimulationAbortController = new AbortController();
                dispatch(spvSimpleActions.SET_COORDINATES_AVG, { centroid: payload?.center });
                postProductions(
                    getPayloadToPreSims({
                        coordinates_avg: payload?.center,
                        structures: options?.structures,
                        productID,
                        isOArea,
                        inputs,
                    }),
                    { mode: 'raw', pid: productID },
                    isOArea,
                    postSimulationAbortController
                );

                if (
                    (isB2C ? [HOUSING_TYPES_B2C.BUILDING, HOUSING_TYPES_B2C.INDEPENDENT] : [AREA_TYPES.ROOF]).includes(housing_type) &&
                    isDetectRoof
                ) {
                    // getDetectRoofTop
                    getDetectRooftopAbortController && getDetectRooftopAbortController.abort();
                    getDetectRooftopAbortController = new AbortController();

                    rspDTTop = await getDetectRoofTop(payload, isOArea, getDetectRooftopAbortController);

                    if (isFieldDefined(rspDTTop?.status) && rspDTTop?.status !== 0 && rspDTTop?.status !== StatusCodes.OK)
                        throw new Error(SpvSimpleError.DETECT_ROOF);
                    if (rspDTTop?.data?.data?.length === 0) throw new Error(SpvSimpleError.NO_ROOF);

                    roof = rspDTTop?.status === StatusCodes.OK ? rspDTTop?.data?.data : [];
                } else {
                    roof = [roofCoordinates];
                }

                // getdetectFacets
                if (roof?.length > 0 && roof?.[0]?.length > 0) {
                    payloadDRoofTypes = {
                        center: payload?.center,
                        image_name: payload?.image_name,
                        roof, // se for town, tem de enviar a coord inserida pelo user
                        structures: options?.structures,
                        is_manual: !isDetectRoof,
                    };
                    getDetectRoofTypesB2CAbortController && getDetectRoofTypesB2CAbortController.abort();
                    getDetectRoofTypesB2CAbortController = new AbortController();
                    rspAnalyseRoof = await getAnalyzeAreaB2C(payloadDRoofTypes, isOArea, getDetectRoofTypesB2CAbortController);

                    if (rspAnalyseRoof?.status !== StatusCodes.OK) throw new Error(SpvSimpleError.ANALYSE_ROOF);

                    // update mounting_structure_id
                    setValue(SPV_SIMPLE_INPUTS.MOUNTING_STRUCTURE_ID, rspAnalyseRoof?.data?.data?.mounting_structure_id ?? '');

                    dispatch(spvSimpleActions.SET_ROOF_IDENTIFICATION_SUCCESS, {
                        data: {
                            ...rspAnalyseRoof?.data?.data,
                            roof,
                        },
                        image_name: payload?.image_name,
                        center: payload?.center,
                    });
                } else {
                    throw new Error('roof empty');
                }
            } catch (error) {
                console.error('ERROR fetchRoofIdentification', error);
                setIsRequestRoofIFail(true);
                switch (error?.message) {
                    case SpvSimpleError.DETECT_ROOF: {
                        if (!rspDTTop?.status) break; /* when request cancelled  */
                        let message = `API-Warning | ${error.message} | endpoint: (api/ds/detect-rooftop)`;
                        SentryCaptureException({
                            level: 3,
                            message,
                            fingerprint: message,
                            extrasContext: {
                                payload: payload,
                                rsp: rspDTTop?.data?.data,
                                status: rspDTTop?.status,
                                error: error.message,
                                endpoint: 'api/ds/detect-rooftop',
                            },
                            tags: {
                                fnc: 'fetchRoofIdentification',
                                endpoint: 'api/ds/detect-rooftop',
                            },
                        });
                        break;
                    }
                    case SpvSimpleError.ANALYSE_ROOF: {
                        if (!rspAnalyseRoof?.status) break; /* when request cancelled  */
                        let message = `API-Warning | ${error.message} | endpoint: (api/ds/analyze-roof)`;
                        SentryCaptureException({
                            level: 3,
                            message,
                            fingerprint: message,
                            extrasContext: {
                                payload: payloadDRoofTypes,
                                rsp: rspAnalyseRoof?.data?.data,
                                status: rspAnalyseRoof?.status,
                                error: error.message,
                                endpoint: 'api/ds/analyze-roof',
                            },
                            tags: {
                                fnc: 'fetchRoofIdentification',
                            },
                        });
                        break;
                    }
                    default: {
                        if (!rspDTTop?.status) break; /* when request cancelled  */
                        let message = error?.message ?? 'ERROR default fetchRoofIdentification';
                        SentryCaptureException({
                            level: 4,
                            message,
                            fingerprint: message,
                            extrasContext: {
                                error: error,
                                housing_type,
                                isDetectRoof,
                                payload,
                                rspDTTop: {
                                    status: rspDTTop?.status,
                                    statusText: rspDTTop?.statusText,
                                    hasRoofData: rspDTTop?.data?.data?.length > 0,
                                },
                                roof,
                                roofCoordinates,
                            },
                            tags: {
                                fnc: 'fetchRoofIdentification',
                            },
                        });
                        console.error(error);
                    }
                }
            }
        },
        [inputs?.image, inputs?.center, inputs?.image_name, options?.structures, areas, housing_type] //eslint-disable-line
    );

    const handleAutoDetectRoof = useCallback(
        async (location) => {
            try {
                if (!isDefined(location.lat) || !isDefined(location.lng)) throw new Error('DS Detect Roof Analyser - No location');
                const payload = {
                    center: {
                        lat: location.lat,
                        lng: location.lng,
                        zoom: location.zoom,
                    },
                    image_name: `autodetect-roof-${new Date().getTime()}.png`,
                };
                fetchRoofIdentification(payload);
            } catch (error) {
                setIsRequestRoofIFail(true);
                let message = error?.message ?? 'Google Static 400 Error';
                SentryCaptureException({
                    level: 3,
                    message,
                    fingerprint: message,
                    extrasContext: {
                        location: location,
                    },
                    tags: {
                        fnc: 'handleAutoDetectRoof',
                    },
                });
            }
        },
        [roofCoordinates] // eslint-disable-line
    );

    useEffect(() => {
        if (isDefined(location.lat) && isDefined(location.lng)) handleAutoDetectRoof({ ...location, zoom: 20 });
        return () => {
            cleanup();
        };
    }, [location]); //eslint-disable-line

    return {
        isRequestRoofIFail,
    };
};

export default useRoofIdentification;
