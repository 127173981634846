/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @see https://reactjs.org/docs/hooks-custom.html
 *
 * @version 20210526
 * @since 20210526 Initial release
 *
 */

import { useState, useEffect, useRef } from 'react';
import webstomp from 'webstomp-client';
import { postSimulationsToWebstomp } from 'api/sizing';
import axios from 'services/@efz/axios';
import { OK } from 'constants/statusCodeHttp';
import { isDefined, isExistWord } from 'services/util/auxiliaryUtils';
import { LS_KEYS } from 'constants/settings';
import { SentryCaptureException } from 'services/@efz/sentry';

const RABBIT = {
    url: process.env.REACT_APP_WEBSTOMP_URL,
    user: process.env.REACT_APP_WEBSTOMP_USER,
    password: process.env.REACT_APP_WEBSTOMP_PWD,
    headers: { durable: false, auto_delete: true, exclusive: false, 'x-expires': 120000 },
};

const useWebstomp = () => {
    const clientWebstomp = useRef(null);
    const [isLoadingWebStomp, setIsLoadingWebStomp] = useState(false);
    const [subscribes, setSubscribes] = useState([]);
    const [mqQueues, setMqQueues] = useState([]);
    const [mqMessages, setMqMessages] = useState([]);

    useEffect(() => {
        if (mqQueues.length > 0) handleWebstomp();
    }, [mqQueues]); //eslint-disable-line

    useEffect(() => {
        if (mqQueues.length > 0 && mqQueues.length === mqMessages.length) {
            localStorage.setItem(LS_KEYS.WEBSTOMP.MQ_MESSAGE, JSON.stringify(mqMessages));
            subscribes.map((sub) => sub.unsubscribe(unsubscribeCallback));
            clientWebstomp.current.disconnect(disconnectCallback);
        }
    }, [mqMessages, mqQueues, subscribes]); //eslint-disable-line

    /**
     * disconnectCallback by webStomp
     * @description - reset das variaveis globais
     *
     */
    const disconnectCallback = () => {
        clientWebstomp.current = null;
        setIsLoadingWebStomp(false); //is Done!
    };

    const unsubscribeCallback = (evt) => console.log('unsubscribeCallback', evt);

    const onmessage = (message) => {
        let queue = mqQueues.find((item) => isExistWord(message.headers.destination, `${item.hashRandom}`));
        queue.data = JSON.parse(message.body);
        setMqMessages((data) => [...data, queue]);
    };

    const errorCallback = () => {
        if (isDefined(clientWebstomp.current)) clientWebstomp.current.disconnect(disconnectCallback);
        clientWebstomp.current = null;
    };

    /**
     * connectCallback by webStomp
     * @description subscribe ao cliente de webStomp com base a MQ_QUEUE enviada pela API e guarda o id na var global
     */
    const connectCallback = () => {
        let _subscribes = [];
        mqQueues.map((item) => _subscribes.push(clientWebstomp.current.subscribe(item.queue, onmessage, RABBIT.headers)));
        setSubscribes(_subscribes);
        return subscribes;
    };

    /**
     * fetchSimulations
     *
     * @param {[]} payloads required
     *
     */
    const fetchSimulations = async (payloads = []) => {
        setMqQueues([]);
        setMqMessages([]);
        if (payloads.length > 0) {
            localStorage.removeItem(LS_KEYS.WEBSTOMP.MQ_MESSAGE); //clean
            setIsLoadingWebStomp(true);
            let allRequests = [];

            payloads.map((payload) => allRequests.push(postSimulationsToWebstomp(payload)));

            // requests
            await axios
                .all(allRequests)
                .then(
                    axios.spread(async (...responses) => {
                        if (!isDefined(responses?.find((rps) => rps?.status !== OK))) {
                            let queues = [];
                            responses.map((resp) => {
                                let queueSplit = resp?.data.data.split('::');
                                queues.push({
                                    data: null,
                                    id: parseInt(queueSplit[queueSplit.length - 3]),
                                    queue: resp?.data.data,
                                    hashRandom: queueSplit[queueSplit.length - 1],
                                });
                                return resp;
                            });
                            setMqQueues(queues);
                        } else {
                            setIsLoadingWebStomp(false);
                        }
                    })
                )
                .catch((errors) => {
                    SentryCaptureException({
                        level: 2,
                        message: 'Erro-webstomp',
                        extrasContext: {
                            errors,
                            mqQueues,
                        },
                        tags: {
                            api: 'simulations',
                            timeout: true,
                        },
                    });
                    console.error('errors webstom', errors);
                    // react on errors.
                    setIsLoadingWebStomp(false);
                });
        } else {
            console.error('params missing webstom');
        }
    };

    const handleWebstomp = async () => {
        clientWebstomp.current = await webstomp.client(RABBIT.url, { debug: false });
        await clientWebstomp.current.connect(RABBIT.user, RABBIT.password, connectCallback, errorCallback);
    };

    return {
        isLoadingWebStomp,
        fetchSimulations,
        mqMessages,
        setMqMessages,
    };
};

export default useWebstomp;
