/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @see redux-sagas docs: https://redux-saga.js.org/docs/api/index.html
 *
 * @version 20191202 - removed proposal on blacklist
 * @version 20191115 - businessModels added on blacklist
 * @version 20190715
 * @since 20190715 Initial release
 *
 */

import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { LOGOUT_USER, SET_USER_AUTHENTICATED_FALSE } from '../constants/auth';
import reducers from './reducers';
import rootSaga from './sagas';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * persistConfig

 * @type {
 *    {
 *      blacklist: *[], Colocar todos os estados do reducer que não são necessários que sejam guardar "persistentes", exemplo: auth, router, settings
 *      storage: *,
 *      key: string
 *    }
 *  }
 */
const persistConfig = {
    key: 'rootSales',
    storage,
    blacklist: ['form', 'router', 'utils', 'cfp', 'sizing'],
};

// Logout logic -> If action is LOGOUT_USER, provides initial store and calls it's reducer
const resetEnhancer = (rootReducer) => (state, action) => {
    if ([LOGOUT_USER, 'RESET_STATE'].includes(action.type)) {
        const newState = rootReducer(undefined, {
            type: SET_USER_AUTHENTICATED_FALSE,
        });
        newState.settings = state.settings;
        return newState;
    } else {
        return rootReducer(state, action);
    }
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: (state) => {
        const { client, facility, product, proposal } = state;
        // Transform the state to remove information - reduce the size
        const transformedState = {
            _client: {
                id: client?.id,
                nipc: client?.nipc,
            },
            _facility: {
                cliente_id: facility?.info?.cliente_id ?? null,
                cpe: facility?.info?.cpe ?? null,
            },
            _productID: product?.info?.id ?? null,
            _proposalID: proposal?.id ?? null,
        };

        return transformedState;
    },
});

const persistedReducer = persistReducer(persistConfig, resetEnhancer(reducers(history)));

export default function configureStore(initialState) {
    const store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer));

    const persistor = persistStore(store);
    sagaMiddleware.run(rootSaga);

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers').default;
            store.replaceReducer(nextRootReducer);
        });
    }

    return { store, persistor };
}

export { history };
