import { PASSWORD_CHECK } from 'services/util/auxiliaryUtils';

export const INITIAL_USER = {
    id: null,
    nome: null,
    username: null,
    tipo_utilizador_id: null,
    tipo: null,
    idioma: null,
    negoceia_margem: false,
    empresa_id: null,
    perfil_empresa_id: null,
    companyName: null,
    currency: null,
};

// * CONSTANTS * \\

export const USERS_TYPE_ID = {
    MANAGER: 1, //PLATFORM
    CHANNEL: 2, //PLATFORM
    CLIENT: 3, //PLATFORM
    ADMIN: 4, //BO
    COMMERCIAL_SUPPORT: 5, //BO
};

export const USERS_TYPE_ALL = [USERS_TYPE_ID.MANAGER, USERS_TYPE_ID.CHANNEL, USERS_TYPE_ID.CLIENT];

export const STATE_IDS = {
    PLATFORM: 1,
};

export const SALES_SEGMENT_IDS = {
    B2B: 1,
    B2C: 2,
};

export const GRUPO_CANAL_IDS = {
    DEFAULT: null, //N/DEFAUL
    LIBERBANK: 1,
    TELEFONICA: 2,
    BBVA: 3,
    ESE: 4, //Edificación y Servicios Energéticos
    SELECTRA: 5,
    CGD: 15,
    SANTANDER: 18,
    EFZ: 20,
    EDF: 21,
    IZIVIA: 22,
    SPV_TEST: 23,
    NOVOBANCO: 24,
    BCP: 25,
    NOS: 26,
    SPV_TEST_BR: 27,
    SPV_TEST_ES: 28,
    SPV_TEST_IT: 29,
    SPV_TEST_PL: 30,
    BANCOSABADELL: 31,
    OUTBOUND: 32,
    SPV_TEST_ESB: 33,
    ROMANDE: 34,
    GRANGESOLAR: 35,
    ENERTERL: 43,
};

export const GRUPO_CANAL_IDS_PREFIXOS = {
    [GRUPO_CANAL_IDS.LIBERBANK]: 'LBB-',
    [GRUPO_CANAL_IDS.TELEFONICA]: 'TLF-',
    [GRUPO_CANAL_IDS.BBVA]: 'BBVA-',
    [GRUPO_CANAL_IDS.ESE]: 'ESE-',
    [GRUPO_CANAL_IDS.SELECTRA]: 'SL-',
    [GRUPO_CANAL_IDS.BANCOSABADELL]: 'SBDL-',
    [GRUPO_CANAL_IDS.OUTBOUND]: 'OUTB-',
};

/* default objects */
export const INPUT_NAMES = {
    ID: 'id',
    USER_EMAIL: 'contacto_email',
    USER_NAME: 'nome',
    USER_PHONE: 'contacto_numero',
    ALTERAR_PASSWORD: 'alterar_password',
    CURRENT_PASSWORD: 'old_password',
    NEW_PASSWORD: 'new_password',
    REPEAT_NEW_PASSWORD: 'reapeat_new_password',
};

// Inputs for the View/Edit USER form
export const EDIT_USER_FORM_QUESTIONS = {
    [INPUT_NAMES.USER_NAME]: {
        name: INPUT_NAMES.USER_NAME,
        label: 'page.user.name',
        placeholder: 'page.user.name',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: {
            required: 'yup.message.required',
            maxLength: {
                value: 32,
                message: 'yup.message.maxLength',
            },
        },
        optValidation: { maxLength: 32, required: true },
    },
    [INPUT_NAMES.USER_EMAIL]: {
        name: INPUT_NAMES.USER_EMAIL,
        label: 'label.email',
        placeholder: 'page.client.placeholder.contactEmail',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: {
            required: 'yup.message.required',
            maxLength: {
                value: 64,
                message: 'yup.message.maxLength',
            },
            pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'yup.message.email',
            },
        },
        // validation: validate(['email', 'maxLength', 'required'], { maxLength: 64, required: true }),
        optValidation: { maxLength: 64, required: true },
    },
    [INPUT_NAMES.USER_PHONE]: {
        name: INPUT_NAMES.USER_PHONE,
        label: 'label.phone',
        placeholder: 'page.client.placeholder.contactNumber',
        type: 'number',
        defaultValue: '',
        visible: true,
        validation: {
            // required: 'yup.message.required',
            maxLength: {
                value: 20,
                message: 'yup.message.maxLength',
            },
        },
        // validation: validate(['maxLength', 'required', 'number'], { maxLength: 16, required: true }),
        optValidation: { maxLength: 20, required: true },
    },
    [INPUT_NAMES.CURRENT_PASSWORD]: {
        name: INPUT_NAMES.CURRENT_PASSWORD,
        label: 'dialog.changePassword.label.currentPassword',
        placeholder: 'dialog.changePassword.label.currentPassword.placeholder',
        type: ['text', 'password'],
        defaultValue: '',
        visible: true,
        validation: {
            required: 'yup.message.required',
        },
        // validation: validate(['required'], { required: true }),
        optValidation: { required: 'yup.message.required' },
    },
    [INPUT_NAMES.NEW_PASSWORD]: {
        name: INPUT_NAMES.NEW_PASSWORD,
        label: 'dialog.changePassword.label.newPassword',
        placeholder: 'dialog.changePassword.label.newPassword.placeholder',
        type: ['text', 'password'],
        defaultValue: '',
        visible: true,
        validation: {
            required: 'yup.message.required',
            minLength: {
                value: 9,
                message: 'yup.message.string.minCharacters',
            },
            validate: {
                hasLowercase: (value) =>
                    PASSWORD_CHECK.PASSWORD_HAS_LOWERCASE_REGEXP.test(value) || 'yup.message.password.required.lowerCase',
                hasUppercase: (value) =>
                    PASSWORD_CHECK.PASSWORD_HAS_UPPERCASE_REGEXP.test(value) || 'yup.message.password.required.upperCase',
                hasDigit: (value) => PASSWORD_CHECK.PASSWORD_HAS_DIGIT_REGEXP.test(value) || 'yup.message.password.required.number',
                hasSpecialCharacter: (value) =>
                    PASSWORD_CHECK.PASSWORD_HAS_SPECIAL_CHARACTER.test(value) || 'yup.message.password.required.specialCharacter',
            },
        },
        optValidation: { required: 'yup.message.required', minLength: 9 },
    },
    [INPUT_NAMES.REPEAT_NEW_PASSWORD]: {
        name: INPUT_NAMES.REPEAT_NEW_PASSWORD,
        label: 'dialog.changePassword.label.repeatPassword',
        placeholder: 'dialog.changePassword.label.repeatPassword.placeholder',
        type: ['text', 'password'],
        defaultValue: '',
        visible: true,
        validation: {
            required: 'yup.message.required',
        },
        // validation: validate(['maxLength'], { maxLength: 64 }),
        optValidation: { maxLength: 64 },
    },
};

// REDUX default state for user subtree
export const INITIAL_USER_FORM = {
    [INPUT_NAMES.ID]: null,
    [INPUT_NAMES.USER_NAME]: null,
    [INPUT_NAMES.USER_EMAIL]: null,
    [INPUT_NAMES.USER_PHONE]: null,
};

export const USER_ERRORS_NAME = {
    OLD_PASSWORD_MISMATCH: 'old_password_mismatch',
};

export const USER_ERRORS_MESSAGE = {
    [USER_ERRORS_NAME.OLD_PASSWORD_MISMATCH]: 'User Unauthorized : Old Password mismatch',
};

export const AVAILABLE_USER_LOCALES = {
    ptPT: 'pt-PT',
    esES: 'es-ES',
    enGB: 'en-GB',
    enUS: 'en-US',
    czCZ: 'cz-CZ',
    itIT: 'it-IT',
    plPL: 'pl-PL',
    ptBR: 'pt-BR',
    enIE: 'en-IE',
    deCH: 'de-CH',
    elGR: 'el-GR',
    enSG: 'en-SG',
    frCH: 'fr-CH',
    frFR: 'fr-FR',
    deDE: 'de-DE',
};

/**
 * @docs:
 * - get (region & searchRestriction)  https://developers.google.com/maps/coverage
 * - get (lat,lng) https://developers.google.com/maps/documentation/javascript/localization
 * - https://developers.google.com/maps/documentation/javascript/place-autocomplete#place_autocomplete_service
 */

export const USER_COUNTRY_LatLng = {
    default: {
        center: {
            lat: 38.722252,
            lng: -9.139337,
        },
        searchRestriction: 'pt',
        language: 'pt',
        region: 'PT',
        carto: 'PT',
    },
    [AVAILABLE_USER_LOCALES.czCZ]: {
        center: {
            lat: 50.075539,
            lng: 14.4378,
        },
        searchRestriction: 'cz',
        language: 'cs',
        region: 'CZ',
        carto: 'CZ',
    },
    [AVAILABLE_USER_LOCALES.deCH]: {
        center: {
            lat: 46.948002,
            lng: 7.44813,
        },
        searchRestriction: 'ch',
        language: 'de',
        region: 'DE',
        carto: 'CH',
    },
    [AVAILABLE_USER_LOCALES.enGB]: {
        center: {
            lat: 51.506651,
            lng: -0.126694,
        },
        searchRestriction: 'gb',
        language: 'en-GB',
        region: 'GB',
        carto: 'UK',
    },
    [AVAILABLE_USER_LOCALES.esES]: {
        center: {
            lat: 40.416775,
            lng: -3.70379,
        },
        searchRestriction: 'es',
        language: 'es',
        region: 'ES',
        carto: 'ES',
    },
    [AVAILABLE_USER_LOCALES.itIT]: {
        center: {
            lat: 41.860101,
            lng: 12.465905,
        },
        searchRestriction: 'it',
        language: 'it',
        region: 'IT',
        carto: 'IT',
    },
    [AVAILABLE_USER_LOCALES.plPL]: {
        center: {
            lat: 52.233201,
            lng: 21.001987,
        },
        searchRestriction: 'pl',
        language: 'pl',
        region: 'PL',
        carto: 'PL',
    },
    [AVAILABLE_USER_LOCALES.ptBR]: {
        center: {
            lat: -15.794427,
            lng: -47.883166,
        },
        searchRestriction: 'br',
        language: AVAILABLE_USER_LOCALES.ptBR,
        region: 'PT',
        carto: 'BR',
    },
    [AVAILABLE_USER_LOCALES.ptPT]: {
        center: {
            lat: 38.722252,
            lng: -9.139337,
        },
        searchRestriction: 'pt',
        language: AVAILABLE_USER_LOCALES.ptPT,
        region: 'PT',
        carto: 'PT',
    },
    [AVAILABLE_USER_LOCALES.elGR]: {
        center: {
            lat: 37.9839,
            lng: 23.7294,
        },
        searchRestriction: 'gr',
        language: AVAILABLE_USER_LOCALES.elGR,
        region: 'GR',
        carto: 'GR',
    },
    [AVAILABLE_USER_LOCALES.enSG]: {
        center: {
            lat: 1.29027,
            lng: 103.851959,
        },
        searchRestriction: 'sg',
        language: AVAILABLE_USER_LOCALES.enSG,
        region: 'SG',
        carto: 'SG',
    },
    [AVAILABLE_USER_LOCALES.enIE]: {
        center: {
            lat: 53.41291,
            lng: -8.24389,
        },
        searchRestriction: 'ie',
        language: AVAILABLE_USER_LOCALES.enIE,
        region: 'IE',
        carto: 'IE',
    },
    [AVAILABLE_USER_LOCALES.frCH]: {
        center: {
            lat: 46.948002,
            lng: 7.44813,
        },
        searchRestriction: 'ch',
        language: AVAILABLE_USER_LOCALES.frCH,
        region: 'CH',
        carto: 'CH',
    },
    [AVAILABLE_USER_LOCALES.deDE]: {
        center: {
            lat: 52.531677,
            lng: 13.381777,
        },
        searchRestriction: 'de',
        language: 'de',
        region: 'DE',
        carto: 'DE',
    },
    [AVAILABLE_USER_LOCALES.enUS]: {
        center: {
            lat: 40.749823,
            lng: -73.987761,
        },
        searchRestriction: 'us',
        language: AVAILABLE_USER_LOCALES.enUS,
        region: 'US',
        carto: 'US',
    },
    [AVAILABLE_USER_LOCALES.frFR]: {
        center: {
            lat: 48.873674,
            lng: 2.294947,
        },
        searchRestriction: 'FR',
        language: AVAILABLE_USER_LOCALES.frFR,
        region: 'FR',
        carto: 'FR',
    },
};

export const COUNTRY_ID = {
    PT: 1,
    ES: 4,
    IT: 9,
    PL: 10,
    UK: 8,
    SG: 12,
    DE: 14,
    FR: 15,
    GR: 13,
    CH: 11,
    US: 16,
};

// * ACTION TYPES * \\
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REQUEST_PUT_REDEFINE_PASSWORD = 'REQUEST_PUT_REDEFINE_PASSWORD';
export const REQUEST_PUT_REDEFINE_PASSWORD_SUCCESS = 'REQUEST_PUT_REDEFINE_PASSWORD_SUCCESS';
export const UPDATE_USER_TERMS = 'UPDATE_USER_TERMS';
