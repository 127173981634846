import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

//Material UI
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';

//Our Components
import { getUserType } from 'services/user';
import { getLetterAvatar } from 'services/util/auxiliaryUtils';
import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as AvatarIcon } from 'assets/images/icons/svg/avatar.svg';
import { ReactComponent as GoToIcon } from 'assets/images/icons/svg/go-to.svg';
import { ReactComponent as GoToArrowIcon } from 'assets/images/icons/svg/go-to-arrow.svg';
import { gAnalyticsEvt } from 'services/gAnalytics';
import { GA_LABEL_TOP_LEVEL_NAVIGATION, GA_TYPE_EVT } from 'constants/gAnalytics';
import { BASENAME_URL_LOGOUT } from 'constants/settings';

const UserCard = ({ isOpen, handleToggle, userTypeID, username, setDarkTheme, handleGoTo }) => {
    const _getLetterAvatar = useCallback((userTypeID) => getLetterAvatar(getUserType(userTypeID), 2), []); //eslint-disable-line
    const history = useHistory();

    return (
        <Dropdown
            isOpen={isOpen}
            toggle={handleToggle}
            onMouseDown={() => gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.PROFILE, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION)}
        >
            <DropdownToggle className="header-dropdown-toggle" tag="span" data-toggle="dropdown">
                <div className="menu-item-content-last">
                    <div className={`default-svg${isOpen ? '--open' : ''}`}>
                        <AvatarIcon height={24} />
                    </div>
                </div>
            </DropdownToggle>

            <DropdownMenu end>
                <div className="dropdown-header-section user-card">
                    <div className="header-section-text ms-2">
                        <div className="client-info">
                            <div>
                                <h5 className="mb-0">{username}</h5>
                                <h6 className="mb-0 mt-1">{getUserType(userTypeID)}</h6>
                            </div>
                        </div>
                        <div className={'d-flex justify-content-between mb-0 btn-actions'}>
                            <div className="tertiary-button">
                                <span onClick={handleGoTo}>
                                    <GoToArrowIcon className="me-1" />
                                    <IntlMessages id="page.user" />
                                </span>
                            </div>
                            <div className="tertiary-button">
                                <span onClick={() => history.push(BASENAME_URL_LOGOUT)}>
                                    <GoToIcon className="me-1" />
                                    <IntlMessages id="label.logout" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="invisible d-none">
                            {/* Set Dark Theme */}
                            <Tooltip id="tooltip-setDarkTheme" title={<IntlMessages id={`label.setDarkTheme`} />} placement="bottom">
                                <div className="p-2" onClick={setDarkTheme}>
                                    <span>
                                        <i className="zmdi zmdi-lamp dark-mode-icon" />
                                    </span>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

//PropTypes
UserCard.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleUserSignOut: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    userTypeID: PropTypes.number.isRequired,
    handleGoTo: PropTypes.func.isRequired,
};
export default memo(UserCard);
