import { DISPATCH_EVT } from 'constants/facilityPro/main';
import clone from 'fast-copy';
import { getQuestionsOtherDataB2B, getOtherDataB2B, getOtherDataB2C, getQuestionsOtherDataB2C } from 'services/facilityPro/otherData';

export const initialOtherDataReducer = clone({
    dispatchOtherData: (_type = '', _payload = {}) => {},
    isMounting: true,
    isVisible: false,
    isAddFacility: false,
    isB2C: false,
    //#region inputs
    area: '',
    area_coberta: '',
    area_util: '',
    data_idade_resposta_cliente: '',
    num_trabalhadores: '',
    questions: {},
    //#endregion inputs
});
export const otherDataReducer = (state = initialOtherDataReducer, action) => {
    switch (action.type) {
        case DISPATCH_EVT.SET_OTHER: {
            let { payload } = action;
            let { inputs, options } = payload;
            let { isB2C, isAddFacility } = options;
            let newinputs = isB2C ? getOtherDataB2C(inputs) : getOtherDataB2B(inputs);
            let questions = isB2C ? getQuestionsOtherDataB2C(options) : getQuestionsOtherDataB2B(options);
            return {
                ...state,
                ...newinputs,
                ...questions,
                isAddFacility,
                isB2C,
                isMounting: false,
            };
        }
        default: {
            throw new Error(`Error on otherDataReducer type: ${action.type}`);
        }
    }
};
