import { TBMProjectSummary } from 'interfaces/businessModels';
import KPI from 'components/BusinessModels/List/BusinessModels/BusinessModelPaP/Card/Summary/Kpi';
import { paPProjectSummary } from 'services/businessModels/pap';
import { useContext } from 'react';
import { UserContext } from 'contexts/userContext';

interface IProps {
    projectSummary: TBMProjectSummary[];
    syncKpis?: any;
}

const BusinessModelProjectSummaryPaP = ({ projectSummary, syncKpis }: IProps) => {
    const { companyProfileId } = useContext(UserContext);

    const kpis = paPProjectSummary(projectSummary, syncKpis, companyProfileId);

    return <>{kpis.length > 0 ? kpis.map((kpi) => <KPI {...kpi} />) : <></>}</>;
};

export default BusinessModelProjectSummaryPaP;
