import { useContext, useState, useEffect } from 'react';

//Material UI
import Radio from '@mui/material/Radio';
import Slider from '@mui/material/Slider';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// Constants
import { DEFAULT_GRANT, SPV_GRANT, SPV_GRANT_NAME } from 'constants/businessModels';

// Interfaces
import { Nullable } from 'types/utils';
import { UserContext } from 'contexts/userContext';
import { getCompanyProfileIds } from 'services/user';
import { intlMessages, isDefined, parseBoolean } from 'services/util/auxiliaryUtils';
import IntlMessages from 'components/util/IntlMessages';

interface ISubsidy {
    [SPV_GRANT_NAME.SUBSIDY_PRC]: number;
    [SPV_GRANT_NAME.SUBSIDY]: Nullable<boolean>;
    [SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC]: number;
    [SPV_GRANT_NAME.BATTERY_SUBSIDY]: Nullable<boolean>;
}

interface IProps {
    subsidy: ISubsidy;
    setSubsidy: React.Dispatch<React.SetStateAction<ISubsidy | undefined>>;
}

const GrantsQuestionsServiceV2 = ({ subsidy, setSubsidy }: IProps) => {
    const { companyProfileId } = useContext(UserContext);

    const isEdpES = [getCompanyProfileIds().EDP_ES].includes(companyProfileId);
    const hasBattery = isDefined(subsidy?.[SPV_GRANT_NAME.BATTERY_SUBSIDY]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //Initialize variables
        const sendObject = {};

        // SUBSIDY
        if (e.target.name === SPV_GRANT_NAME.SUBSIDY) {
            if (isEdpES) {
                let payloadSubsidy = {
                    ...subsidy,
                    [SPV_GRANT_NAME.SUBSIDY]: parseBoolean(e.target.value),
                    [SPV_GRANT_NAME.SUBSIDY_PRC]: parseBoolean(e.target.value) ? DEFAULT_GRANT : 0,
                };

                // BATTERY_SUBSIDY
                if (hasBattery) {
                    payloadSubsidy = {
                        ...payloadSubsidy,
                        [SPV_GRANT_NAME.BATTERY_SUBSIDY]: parseBoolean(e.target.value),
                        [SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC]: parseBoolean(e.target.value) ? DEFAULT_GRANT : 0,
                    };
                }

                setSubsidy(payloadSubsidy);
            }

            //Value
            sendObject[SPV_GRANT_NAME.SUBSIDY] = parseBoolean(e.target.value);
            sendObject[SPV_GRANT_NAME.SUBSIDY_PRC] = parseBoolean(e.target.value) ? DEFAULT_GRANT / 100 : 0;

            // BATTERY_SUBSIDY
            if (hasBattery) {
                sendObject[SPV_GRANT_NAME.BATTERY_SUBSIDY] = parseBoolean(e.target.value);
                sendObject[SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC] = parseBoolean(e.target.value) ? DEFAULT_GRANT / 100 : 0;
            }

            //Call Get KPIs TODO: FIXME:
            // if ([getCompanyProfileIds().ESB, getCompanyProfileIds().NRG].includes(options?.companyProfileId)) {
            //     callGetKpis(sendObject);
            // }
        }

        // SUBSIDY_PRC
        if (e.target.name === SPV_GRANT_NAME.SUBSIDY_PRC) {
            // if ([getCompanyProfileIds().ESB, getCompanyProfileIds().NRG].includes(options?.companyProfileId)) { TODO: FIXME:
            //     setOfferPrices({
            //         ...offerPrices,
            //         [SPV_GRANT_NAME.SUBSIDY_PRC]: parseInt(e.target.value)
            //     })
            //     //Value
            //     sendObject[SPV_GRANT_NAME.SUBSIDY_PRC] = parseInt(e.target.value) / 100;
            //     callGetKpis(sendObject); //Call Get KPIs
            // }

            // EDP_ES
            if (isEdpES) {
                setSubsidy({
                    ...subsidy,
                    [SPV_GRANT_NAME.SUBSIDY_PRC]: parseInt(e.target.value),
                });
            }
        }

        // BATTERY_SUBSIDY_PRC
        if (isEdpES && e.target.name === SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC) {
            setSubsidy({
                ...subsidy,
                [SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC]: parseInt(e.target.value),
            });
        }
    };

    //State to control Subsidy Percentage
    const [subsidyPrc, setSubsidyPrc] = useState(DEFAULT_GRANT);
    const [batterySubsidyPrc, setBatterySubsidyPrc] = useState(DEFAULT_GRANT);

    //Format value shows
    const valuetext = (value: number) => {
        return `${value} %`;
    };

    useEffect(() => {
        if (!!subsidy?.[SPV_GRANT_NAME.SUBSIDY_PRC] && subsidy?.[SPV_GRANT_NAME.SUBSIDY_PRC] !== subsidyPrc) {
            setSubsidyPrc(subsidy?.[SPV_GRANT_NAME.SUBSIDY_PRC]);
        }
    }, [subsidy?.[SPV_GRANT_NAME.SUBSIDY_PRC]]); //eslint-disable-line

    useEffect(() => {
        if (!!subsidy?.[SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC] && subsidy?.[SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC] !== batterySubsidyPrc) {
            setBatterySubsidyPrc(subsidy?.[SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC]);
        }
    }, [subsidy?.[SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC]]); //eslint-disable-line

    return (
        <div className="edit-offer-grant">
            <div className="radio-edit-grant">
                <RadioGroup
                    row
                    aria-label={SPV_GRANT_NAME.SUBSIDY}
                    name={SPV_GRANT_NAME.SUBSIDY}
                    value={`${subsidy?.[SPV_GRANT_NAME.SUBSIDY] ?? ''}`}
                    onChange={(e) => handleChange(e)}
                >
                    {SPV_GRANT[SPV_GRANT_NAME.SUBSIDY]?.answers.map((item, index) => (
                        <FormControlLabel
                            key={index}
                            value={`${item.value}`}
                            control={<Radio />}
                            disabled={isEdpES && hasBattery}
                            label={<IntlMessages id={item.label} />}
                        />
                    ))}
                </RadioGroup>
            </div>
            {subsidy?.[SPV_GRANT_NAME.SUBSIDY] && (
                <div className="slider-edit-offer">
                    <h6>
                        <IntlMessages
                            id={'label.grandPercentage'}
                            values={{ bundle: `| ${intlMessages('product.name.SOLAR_PHOTOVOLTAIC')}` }}
                        />
                    </h6>
                    <Slider
                        color="primary"
                        disabled={SPV_GRANT[SPV_GRANT_NAME.SUBSIDY_PRC].disabled || (isEdpES && hasBattery)}
                        min={SPV_GRANT[SPV_GRANT_NAME.SUBSIDY_PRC].min}
                        max={SPV_GRANT[SPV_GRANT_NAME.SUBSIDY_PRC].max}
                        step={SPV_GRANT[SPV_GRANT_NAME.SUBSIDY_PRC].step}
                        valueLabelDisplay="on"
                        aria-labelledby="discrete-slider-restrict"
                        getAriaValueText={valuetext}
                        valueLabelFormat={valuetext}
                        onChange={(_, event) => {
                            setSubsidyPrc(parseInt(`${event}`));
                        }}
                        onChangeCommitted={(_, event) =>
                            // @ts-ignore
                            handleChange({ target: { name: SPV_GRANT_NAME.SUBSIDY_PRC, value: event } })
                        }
                        value={subsidyPrc}
                    />
                </div>
            )}

            {/* BATTERY_SUBSIDY_PRC */}
            {subsidy?.[SPV_GRANT_NAME.BATTERY_SUBSIDY] && hasBattery && (
                <div className="slider-edit-offer">
                    <h6>
                        <IntlMessages id={'label.grandPercentage'} values={{ bundle: `| ${intlMessages('label.battery')}` }} />
                    </h6>

                    <Slider
                        color="primary"
                        disabled={SPV_GRANT[SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC].disabled || (isEdpES && hasBattery)}
                        min={SPV_GRANT[SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC].min}
                        max={SPV_GRANT[SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC].max}
                        step={SPV_GRANT[SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC].step}
                        valueLabelDisplay="on"
                        aria-labelledby="discrete-slider-restrict"
                        getAriaValueText={(value) => `${value} %`}
                        valueLabelFormat={(value) => `${value} %`}
                        onChange={(_, event) => {
                            setBatterySubsidyPrc(parseInt(`${event}`));
                        }}
                        onChangeCommitted={(_, event) =>
                            // @ts-ignore
                            handleChange({
                                target: { name: SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC, value: event },
                            })
                        }
                        value={batterySubsidyPrc}
                    />
                </div>
            )}
        </div>
    );
};

export default GrantsQuestionsServiceV2;
