//Libraries
import PropTypes from 'prop-types';

//Material UI Component
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@save2compete/efz-design-system';
import { IconButton } from '@save2compete/efz-design-system';
//Constants
import { DEFAULT_CLASSNAME, PROPTYPES_CUSTOM_STYLES } from 'constants/customStyles';
// Utils
import IntlMessages from 'components/util/IntlMessages';

/**
 *  To use this component, you must pass in forwarding data the data you receive from the form or other source and that you want to submit after this dialog
 *  is confirmed.
 *  To take the best out of this component, a useState call must be assigned in the calling component. Set the data you want to confirm to this state, then pass the state
 *  to forwardingData. Set onClose to this state's reset, for instance.
 *  onConfirmationHandler passes the forwarded data to your handler of choice so that you can process it further, eg sending it to the api
 *
 */
const ConfirmationDialog = ({
    forwardingData,
    onCloseHandler,
    onConfirmationHandler,
    onCancelHandler,
    title,
    titleValues,
    contentText,
    contentTextValues,
    confirmButtonText,
    className,
    hideButtonCancel = false,
    closeIconClassnames = '',
}) => {
    const submitHandler = () => {
        onConfirmationHandler(forwardingData);
        onCloseHandler();
    };

    return (
        <Dialog open={!!forwardingData} onClose={onCloseHandler} className={className}>
            <div className="d-flex">
                <DialogTitle className="flex-fill">
                    <IntlMessages id={title} values={titleValues} />
                </DialogTitle>
                <IconButton
                    variant="tertiary"
                    icon="xMarkExit"
                    size="bg"
                    className={`float-end align-self-center me-3 mt-3 ${closeIconClassnames}`}
                    onClick={onCloseHandler}
                    dataTestId="close-confirmation-dialog"
                />
            </div>
            <DialogContent>
                {contentText ?
                    <DialogContentText className="confirmation-text-content  mb-3">
                        <IntlMessages id={contentText} values={contentTextValues} />
                    </DialogContentText>
                :   <></>}
                <DialogActions className="gap-3">
                    {!hideButtonCancel && (
                        <Button
                            variant="tertiary"
                            size="bg"
                            onClick={onCancelHandler ?? onCloseHandler}
                            className="confirmation-dialog-cancel-button"
                            dataTestId="cancel-confirmation-dialog"
                        >
                            <IntlMessages id="label.cancel" />
                        </Button>
                    )}
                    <Button variant="primary" size="bg" onClick={submitHandler} dataTestId="confirm-confirmation-dialog">
                        {confirmButtonText ?
                            <IntlMessages id={confirmButtonText} />
                        :   <IntlMessages id="label.confirm" />}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

ConfirmationDialog.defaultProps = {
    titleValues: null,
    contentTextValues: null,
    className: DEFAULT_CLASSNAME.confirmationDialogGC,
};
//PropTypes
ConfirmationDialog.propTypes = {
    forwardingData: PropTypes.any,
    onCloseHandler: PropTypes.func.isRequired,
    onConfirmationHandler: PropTypes.func.isRequired,
    title: PropTypes.string,
    titleValues: PropTypes.object,
    contentText: PropTypes.string,
    contentTextValues: PropTypes.object,
    confirmButtonText: PropTypes.string,
    options: PropTypes.shape({
        ...PROPTYPES_CUSTOM_STYLES,
    }),
};

//Export
export default ConfirmationDialog;
