import { memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorMessage from 'components/errors/ErrorMessage';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { AREA_TYPES_CONFIGS, HOUSING_TYPES_CONFIGS_B2B, SPV_SIMPLE_INPUTS } from 'constants/products/solarpvSimple';
import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as QuestionIcon } from 'assets/images/products/spv-simple/question.svg';
import HousingTypeDialog from './HousingTypeDialog';
import { Helmet } from 'react-helmet';
import { intlMessages } from 'services/util/auxiliaryUtils';
import { getHeadTitle } from 'services/settings';

const HousingType = () => {
    //UseForm
    const {
        watch,
        control,
        formState: { errors },
    } = useFormContext();

    // Watchs
    const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

    const [showHousingTypeDialog, setShowHousingTypeDialog] = useState(false);

    return (
        <div className="housing-type-wrapper">
            <Helmet>
                <title>{`${getHeadTitle()} · ${intlMessages('page.spvSimple.open.b2b.step.1')}`}</title>
            </Helmet>
            <div className="housing-type-container-b2b">
                <h2>
                    <IntlMessages id="page.spvSimple.reserved.b2b.step.1.title" />
                </h2>
                <Controller
                    control={control}
                    name={SPV_SIMPLE_INPUTS.HOUSING_TYPE}
                    rules={{ required: 'yup.message.required' }}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    render={({ field: { value, onChange, ref, ...field } }) => (
                        <ToggleButtonGroup
                            {...field}
                            value={value}
                            exclusive
                            onChange={(_, newValue) => onChange(newValue)}
                            aria-label="solarpv  HOUSING_TYPE"
                            className={`housing-type-buttons`}
                        >
                            {HOUSING_TYPES_CONFIGS_B2B.map((option) => (
                                <ToggleButton
                                    disableRipple
                                    disabled={watchAllFields?.housing_type === option.id}
                                    className={`housing-type-button ${watchAllFields?.housing_type === option.id ? 'selected' : ''}`}
                                    key={option.id}
                                    value={option.id}
                                    aria-label={`${option.id} aligned`}
                                >
                                    {watchAllFields?.housing_type === option.id ? option.iconSelected : option.icon}
                                    <label>{option.label}</label>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}
                />
                {!!errors?.[SPV_SIMPLE_INPUTS.HOUSING_TYPE] && <ErrorMessage error={errors?.[SPV_SIMPLE_INPUTS.HOUSING_TYPE]} />}
            </div>
            <div className="housing-type-faq">
                <QuestionIcon className="me-2" />
                <span>
                    <IntlMessages whitespaceEnd id="page.spvSimple.housingTypes.faq.1" />
                    <span className="link" onClick={() => setShowHousingTypeDialog(true)}>
                        <IntlMessages id="page.spvSimple.housingTypes.faq.2" />
                    </span>
                </span>
            </div>
            <div className="housing-type-container-b2b">
                <h2>
                    <IntlMessages id="page.spvSimple.reserved.step.1.area.title" />
                </h2>
                <Controller
                    control={control}
                    name={SPV_SIMPLE_INPUTS.AREA_TYPE}
                    rules={{ required: 'yup.message.required' }}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    render={({ field: { value, onChange, ref, ...field } }) => (
                        <ToggleButtonGroup
                            {...field}
                            value={value}
                            exclusive
                            onChange={(_, newValue) => onChange(newValue)}
                            aria-label="solarpv  AREA_TYPE"
                            className={`housing-type-buttons`}
                        >
                            {AREA_TYPES_CONFIGS.map((option) => (
                                <ToggleButton
                                    disableRipple
                                    disabled={watchAllFields?.area_type === option.id}
                                    className={`housing-type-button ${watchAllFields?.area_type === option.id ? 'selected' : ''}`}
                                    key={option.id}
                                    value={option.id}
                                    aria-label={`${option.id} aligned`}
                                >
                                    {watchAllFields?.area_type === option.id ? option.iconSelected : option.icon}
                                    <label>{option.label}</label>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}
                />
                {!!errors?.[SPV_SIMPLE_INPUTS.AREA_TYPE] && <ErrorMessage error={errors?.[SPV_SIMPLE_INPUTS.AREA_TYPE]} />}
            </div>
            {showHousingTypeDialog && (
                <HousingTypeDialog isOpen={showHousingTypeDialog} setIsOpen={() => setShowHousingTypeDialog(false)} />
            )}
        </div>
    );
};

export default memo(HousingType);
