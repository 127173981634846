/**
 * @author Miguel Cervera <miguel.cervera@caixamagica.pt>,
 *
 * @description Product Actions
 *
 * @version 20191120
 * @since 20191120 Creating Actions for Product
 *
 */

import {
    RESET_PRODUCT,
    SET_PRODUCT_LOADING,
    REQUEST_GET_PRODUCT_INPUTS,
    REQUEST_GET_PRODUCT_INPUTS_SUCCESS,
    SET_MODULES_AND_SUB_PRODUCTS,
    SET_PRODUCT_INFO,
    SET_PRODUCT_MODULES,
    SET_PRODUCT_INPUTS,
    RESET_PRODUCT_BUILD,
    REQUESTS_PUT_FACILITY_PRODUCT_INPUTS,
    REQUESTS_PUT_FACILITY_PRODUCT_INPUTS_SUCCESS,
    REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA,
    REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_SUCCESS,
    REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_FAIL,
    RESET_PRODUCT_OPTIONS,
    SET_INCREASE_POWER_OPTIONS,
    RESET_PRODUCT_INPUTS,
    REQUEST_GET_PRODUCT_INPUTS_FAIL,
    SET_PRODUCT_BY_TOKEN,
    SET_IS_SPV_PRO,
} from 'constants/product';

export function setProductByToken(payload) {
    return {
        type: SET_PRODUCT_BY_TOKEN,
        payload,
    };
}

export function resetProductBuild() {
    return {
        type: RESET_PRODUCT_BUILD,
    };
}
export function setProductModules(payload) {
    return {
        type: SET_PRODUCT_MODULES,
        payload: payload,
    };
}

export function setProductInputs(payload) {
    return {
        type: SET_PRODUCT_INPUTS,
        payload: payload,
    };
}

export function setProductInfo(payload) {
    return {
        type: SET_PRODUCT_INFO,
        payload: payload,
    };
}

export function requestGetProductInputs(payload) {
    return {
        type: REQUEST_GET_PRODUCT_INPUTS,
        payload: payload,
    };
}

export function requestGetProductInputsSuccess(payload) {
    return {
        type: REQUEST_GET_PRODUCT_INPUTS_SUCCESS,
        payload: payload,
    };
}
export function requestGetProductInputsFail() {
    return {
        type: REQUEST_GET_PRODUCT_INPUTS_FAIL,
    };
}

export function setModulesAndSubProducts(payload) {
    return {
        type: SET_MODULES_AND_SUB_PRODUCTS,
        payload: payload,
    };
}

export function setProductLoading(payload) {
    return {
        type: SET_PRODUCT_LOADING,
        payload: payload,
    };
}

export function resetProduct() {
    return {
        type: RESET_PRODUCT,
    };
}

export function requestPutFacilityAndProductInputs(payload) {
    return {
        type: REQUESTS_PUT_FACILITY_PRODUCT_INPUTS,
        payload: payload,
    };
}

export function requestPutFacilityAndProductInputsSuccess() {
    return {
        type: REQUESTS_PUT_FACILITY_PRODUCT_INPUTS_SUCCESS,
    };
}

export function requestsGetAllInitialsProductData(payload) {
    return {
        type: REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA,
        payload,
    };
}

export function requestsGetAllInitialsProductDataSuccess(payload) {
    return {
        type: REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_SUCCESS,
        payload,
    };
}

export function requestsGetAllInitialsProductDataFail(payload) {
    return {
        type: REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_FAIL,
        payload,
    };
}

export function setPVIncreasePowerOptions(payload) {
    return {
        type: SET_INCREASE_POWER_OPTIONS,
        payload,
    };
}

export function resetProductOptions() {
    return {
        type: RESET_PRODUCT_OPTIONS,
    };
}

export function resetProductInputs() {
    return {
        type: RESET_PRODUCT_INPUTS,
    };
}

export function resetEditProposal() {
    return {
        type: 'RESET_EDIT_PROPOSAL',
    };
}

export function setIsSPVPro(payload) {
    return {
        type: SET_IS_SPV_PRO,
        payload: payload,
    };
}
