import { useEffect } from 'react';
import { dismissNotifiy } from 'services/@efz/notify';
import LoginForm from 'components/Forms/Login';
import { isS2CPlatformVersion } from 'services/settings';
import { cleanupGTM } from 'services/util/auxiliaryUtils';
import IntlMessages from 'components/util/IntlMessages';

import { ReactComponent as PlatformLogo } from 'assets/@efz/logos/logo-sales-vertical.svg';
import { ReactComponent as NGBackground } from 'assets/images/auth/ng/login.svg';
// import { Helmet } from 'react-helmet';

// Containers
import FooterContainer from 'pages/Restricted/shared/Footer';
import ImageCustom from 'components/core/ImageCustom';
import { useAuthStore } from 'store/auth';
import { StyledLogin, StyledLoginContainer, StyledLoginContent, StyledLoginSVGBG } from 'pages/auth/LoginStyles';
import LoadingAlert from 'components/core/dialogs/DialogsAlert/LoadingAlert';

const Login = () => {
    const { isUserAuthenticated, redirectLogout, adfsLogout } = useAuthStore();
    const isS2CVersion = isS2CPlatformVersion();

    // componentWillUnmountWithUseEffect
    useEffect(() => {
        cleanupGTM();
        if (redirectLogout !== null) adfsLogout(redirectLogout);
        return () => {
            dismissNotifiy(); // dismiss all notifications
        };
    }, []); //eslint-disable-line

    useEffect(() => {
        if (!isUserAuthenticated) {
            (window as any)._paq?.push(['trackEvent', 'Current page', 'login']);
        }
    }, [isUserAuthenticated]); // eslint-disable-line

    return (
        <StyledLogin $isS2CVersion={!!isS2CVersion}>
            <StyledLoginSVGBG>
                {!isS2CVersion ?
                    <div className="login-svg-ng">
                        <NGBackground />
                    </div>
                :   <div className="login-svg-s2c" />}
            </StyledLoginSVGBG>

            {!redirectLogout && (
                <>
                    <StyledLoginContainer>
                        <StyledLoginContent>
                            {/* Header */}
                            {!isS2CVersion ?
                                <div className="login-header">
                                    <PlatformLogo />
                                </div>
                            :   <div className="login-header-s2c">
                                    <ImageCustom alt="save-to-compete-logo" src={`assets/images/auth/s2c/save-to-compete-logo.svg`} />
                                </div>
                            }
                            {/* Body */}
                            <div className="login-body">
                                <h4>
                                    <IntlMessages id={'page.login.subtitle'} />
                                </h4>
                                <LoginForm isS2CVersion={isS2CVersion} />
                            </div>
                        </StyledLoginContent>
                    </StyledLoginContainer>
                    <FooterContainer isUserAuthenticated={false} />
                </>
            )}
            <LoadingAlert isOpen={!!redirectLogout} title="page.logout.redictecting" />
        </StyledLogin>
    );
};

export default Login;
