import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';

interface IProps {
    label: string;
    value: {
        number: number;
        unit: string;
        numberOfDecimalPlaces: number;
    };
}
const GrantsKpiPPa = ({ label, value }: IProps) => {
    return (
        <div className="bm-kpis-kpi">
            <label>
                <IntlMessages id={label} />
            </label>
            <span>
                {/*  @ts-ignore */}
                {
                    value?.number <= 0 ?
                        '-'
                        // @ts-ignore
                    :   <FormatNumber unit={value.unit} number={value.number} numberOfDecimalPlaces={value.numberOfDecimalPlaces} />
                }
            </span>
        </div>
    );
};

export default GrantsKpiPPa;
