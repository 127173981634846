import BusinessModelTitlePP from 'components/BusinessModels/List/BusinessModels/BusinessModelPP/Card/Title';
import { IBMPitch, IBusinessModel, TBMProjectSummary, IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import BusinessModelBodyPP from 'components/BusinessModels/List/BusinessModels/BusinessModelPP/Card/Body';
import BusinessModelProjectSummary from 'components/BusinessModels/List/BusinessModels/BusinessModelPP/Card/Summary/Summary';
import useUpfrontBM from 'hooks/businessModelsPro/UpFront/useUpFrontBM';
import IntlMessages from 'components/util/IntlMessages';
import CardActionArea from '@mui/material/CardActionArea';
import { useContext } from 'react';
import { BusinessModelsProContext, BusinessModelsProWebstompContext } from 'contexts/businessModelsPro/businessModelsContext';
import { BusinessModelsActions } from 'interfaces/businessModels/reducer';
import { TUpFront } from 'interfaces/businessModels/upfront';

interface IProps {
    bm: IBusinessModel;
}

interface IHook {
    pitch: IBMPitch;
    projectSummary: TBMProjectSummary[];
    selected: boolean;
    id: number;
    paymentModelID: number;
}
const BusinessModelPP = ({ bm }: IProps) => {
    const { pitch, projectSummary, selected, paymentModelID }: IHook = useUpfrontBM(bm);

    const { dispatch } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const { isLoadingWebStomp } = useContext(BusinessModelsProWebstompContext);

    const clickHandler = () => {
        !selected &&
            dispatch({
                type: BusinessModelsActions.SELECT_BUSINESS_MODEL,
                payload: paymentModelID,
            });
    };

    return (
        <div className={`business-models-card ${selected ? 'selected' : ''}`}>
            {pitch.recommended && (
                <div className="recommended">
                    <IntlMessages id="label.recommended" />
                </div>
            )}
            <CardActionArea
                component="div"
                className="business-models-card-wrapper"
                disableRipple
                disabled={isLoadingWebStomp}
                onClick={clickHandler}
            >
                <div className={`business-models-card-header ${pitch.recommended ? 'isRecommended' : ''}`}>
                    <BusinessModelTitlePP description={pitch.description} summary={pitch.summary} />
                    {/* ADICIONAR BOTÃO DE MAIS DETALHES */}
                </div>
                <div className="business-models-card-container">
                    <div className="business-models-card-body">
                        <BusinessModelBodyPP ownership={pitch.ownership} sections={pitch.sections} />
                    </div>
                    <div className="business-models-card-summary">
                        <h5>
                            <IntlMessages id="label.projectSummary" />
                        </h5>
                        <BusinessModelProjectSummary projectSummary={projectSummary} />
                    </div>
                    {/* OFFER EDITION  ??? */}
                </div>
            </CardActionArea>
        </div>
    );
};

export default BusinessModelPP;
