import { BOActions, BOInitialState } from 'interfaces/backoffice';

export const initialBOLoadings = {
    templatesProductID: false,
    templatesBMs: false,
    templatesDocs: false,
    templatesDownloadDocs: null,
    templatesUploadDocs: false,
    templatesProposalDetails: false,
    templatesPostSimulation: false,
    templatesSupportDocs: false,
    templatesPutDoc: false,
    templatesProposalList: false,
    templatesProposalClients: false,
};

export const initialBOReducer: BOInitialState = {
    product: {
        productID: null,
        tag: null,
    },
    templates: {
        businessModels: null,
        selectedBM: null,
        docs: [],
        selectedDoc: null,
        originalUploadedDocName: null,
        uploadedDocAzureName: null,
        proposal: null,
        simId: null,
        proposalList: {
            clientList: [],
            original: [],
            filtered: [],
        },
    },
    loadings: initialBOLoadings,
};

export const boReducer = (state = initialBOReducer, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case BOActions.SET_LOADINGS: {
            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    [action.payload.name]: action.payload.value,
                },
            };
        }

        case BOActions.SET_PRODUCT_ID: {
            return {
                ...state,
                product: payload,
                templates: initialBOReducer.templates,
                loadings: {
                    ...state.loadings,
                    templatesProductID: false,
                },
            };
        }

        case BOActions.SET_BUSINESS_MODELS: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    businessModels: payload,
                    selectedBM: payload.length === 1 ? payload[0] : state.templates.selectedBM,
                },
                loadings: {
                    ...state.loadings,
                    templatesBMs: false,
                },
            };
        }

        case BOActions.SET_SELECTED_BM: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    selectedBM: payload,
                    proposalList: { ...initialBOReducer.templates.proposalList },
                },
            };
        }

        case BOActions.SET_DOCUMENT_TEMPLATES: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    docs: payload,
                },
            };
        }

        case BOActions.SET_SELECTED_DOC: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    selectedDoc: payload,
                },
            };
        }

        case BOActions.SET_UPLOAD_DOC_AZURE_NAME: {
            const { originalName, azureName } = payload;
            return {
                ...state,
                templates: {
                    ...state.templates,
                    originalUploadedDocName: originalName,
                    uploadedDocAzureName: azureName,
                },
            };
        }

        case BOActions.SET_TEMPLATE_PROPOSAL_DETAILS: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    proposal: payload,
                },
            };
        }

        case BOActions.SET_SIMULATION_ID: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    simId: payload,
                },
            };
        }

        case BOActions.SET_PROPOSAL_LIST: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    proposalList: {
                        ...state.templates.proposalList,
                        original: payload,
                    },
                },
            };
        }

        case BOActions.SET_FILTERED_PROPOSAL_LIST: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    proposalList: {
                        ...state.templates.proposalList,
                        filtered: payload,
                    },
                },
            };
        }

        case BOActions.SET_PROPOSAL_CLIENT_LIST: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    proposalList: {
                        ...state.templates.proposalList,
                        clientList: payload,
                    },
                },
            };
        }

        case BOActions.SET_RESET_TEMPLATES: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    originalUploadedDocName: initialBOReducer.templates.originalUploadedDocName,
                    proposal: initialBOReducer.templates.proposal,
                    simId: initialBOReducer.templates.simId,
                    uploadedDocAzureName: initialBOReducer.templates.uploadedDocAzureName,
                },
            };
        }

        case BOActions.SET_RESET_ALL: {
            return initialBOReducer;
        }

        default: {
            throw new Error('Error on BOReducer, no valid action ' + action);
        }
    }
};
