import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { UserContext } from 'contexts/userContext';
import { IBusinessModelsContext, TBMProjectSummary, TProductInputs } from 'interfaces/businessModels';
import { useContext } from 'react';
import KPI from 'components/BusinessModels/List/BusinessModels/BusinessModelPP/Card/Summary/Kpi';
import { upFrontProjectSummary } from 'services/businessModels/upfront';
import { TUpFront } from 'interfaces/businessModels/upfront';

interface IProps {
    projectSummary: TBMProjectSummary[];
}

const BusinessModelProjectSummary = ({ projectSummary }: IProps) => {
    const { productID, state } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const { companyProfileId } = useContext(UserContext);

    const kpis = upFrontProjectSummary(projectSummary, state, { companyProfileId, productID });

    return <>{kpis.length > 0 ? kpis.map((kpi) => <KPI {...kpi} />) : <></>}</>;
};

export default BusinessModelProjectSummary;
