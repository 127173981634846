import { memo } from 'react';
import { getCompanyProfileIds } from 'services/user';

const CompanyDescription = ({ companyProfileId }) => {
    const descriptionHandler = () => {
        switch (companyProfileId) {
            case getCompanyProfileIds().PPC:
                return 'PPC Group';
            default:
                return '';
        }
    };

    return <span className="company-description ms-3">{descriptionHandler()}</span>;
};

export default memo(CompanyDescription);
