import { SPV_MAINTENANCE_ACTIONS } from 'constants/products/spvMaintenance';

export const initialSpvMaintenanceReducer = {
    panels_number: null,
    peak_power: null,
    installation_type_id: null,
    has_access_to_roof: null,
    has_offer_for_om_renewal: null,
    has_monitoring: null,
    env_type_id: null,
    annual_washes_number: null,
    contract_duration_years: null,
};

export const SpvMaintenanceReducer = (state = initialSpvMaintenanceReducer, action) => {
    switch (action.type) {
        case SPV_MAINTENANCE_ACTIONS.MOUNTING: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case SPV_MAINTENANCE_ACTIONS.SUBMIT_FORM: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
