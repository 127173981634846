/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description ACTION TYPES AND DefaultObject, context -> BusinessModels
 *
 * @version 20191113
 * @since 20191113 Initial release
 *
 */
//Constants
import { getCompanyProfileIds } from 'services/user';
import { PRODUCT_IDS } from './products';
import validate from 'services/util/validate';
import { TIntl } from 'types/utils';
// * DEFAULT OBJECTS * \\
export const INITIAL_BUSINESS_MODELS = {
    data: [],
    body: {
        facility_id: null,
        module_type: 'SIMPLES',
    },
    kpisSelected: null,
    offerEdition: [],
    loading: true,
    loadingPreviewKpis: false,
    options: {
        me: null,
    },
    servicePPA: [], //remover
    negotiations: null, //TODO: aplica-se a PV quando é necessário ir a ferramenta
};
//
export const PAYMENT_MODELS_IDS = {
    UP_FRONT: 1,
    INSTALLMENTS: 2,
    SERVICE: 3,
    SERVICE_PPA: 4,
    NOT_APPLICABLE: 5,
    LEC: 7,
    SERVICE_SECOND_VERSION: 8,
    PAY_AS_PRODUCED: 9,
    PARTNERSHIP: 10,
    PPA: 11,
    EASY_SOLAR: 12 /* EASY SOLAR */,
} as const;

export const PAYMENT_MODELS_TAGS = {
    UP_FRONT: 'UP_FRONT',
    INSTALLMENTS: 'INSTALLMENTS',
    SERVICE: 'SERVICE',
    SERVICE_PPA: 'SERVICE_PPA',
    NOT_APPLICABLE: 'NOT_APPLICABLE',
    LEC: 'LEC',
    SERVICE_SECOND_VERSION: 'SERVICE_SECOND_VERSION',
    PAY_AS_PRODUCED: 'PAY_AS_PRODUCED',
    PARTNERSHIP: 'PARTNERSHIP',
};
//TYPE B MODEL PER KEYWORD
export const MODEL_PER_KEYWORD = {
    PPAGAMENTO: 'product.typeBusinessModel.UP_FRONT',
    PRESTACOES: 'product.typeBusinessModel.INSTALLMENTS',
    SERVICO: 'product.typeBusinessModel.SERVICE',
    NOT_APPLICABLE: 'product.typeBusinessModel.NOT_APPLICABLE',
    SERVICE_PPA: 'product.typeBusinessModel.SERVICE_PPA',
    LEC: 'product.typeBusinessModel.LEC',
    SERVICE_SECOND_VERSION: 'product.typeBusinessModel.SERVICE_SECOND_VERSION',
    PAY_AS_PRODUCED: 'product.typeBusinessModel.PAY_AS_PRODUCED',
    PAP: 'product.typeBusinessModel.PAY_AS_PRODUCED',
    PARTNERSHIP: 'product.typeBusinessModel.PARTNERSHIP',
    SERVICO_PPA: 'product.typeBusinessModel.PPA_2',
    EASY_SOLAR: 'product.typeBusinessModel.EASY_SOLAR',
};
//DEFAULT DISCOUNT
export const DEFAULT_DISCOUNT = 0;
//DEFAULT MARGIN DECIMAL HOUSES
export const DEFAULT_MARGIN_DECIMALS = 4;
//PERCENTAGE
export const PERCENTAGE = 100;
//DEFAULT MAXIMUM TOTAL
export const DEFAULT_MAXIMUM_COST = 500000;
//COMPANIES WITHOUT DEFAULT MAXIMUM COST
export const COMPANIES_WITHOUT_MAXIMUM_COST = [
    getCompanyProfileIds().EFZ,
    getCompanyProfileIds().EDP_PT,
    getCompanyProfileIds().EDP_PL,
    getCompanyProfileIds().EDP_BR,
];
//PRODUCTS WITH SUMMARY
export const PRODUCTS_WITH_SUMMARY = [PRODUCT_IDS.SPV, PRODUCT_IDS.CFP, PRODUCT_IDS.SPVSB, PRODUCT_IDS.EVC, PRODUCT_IDS.RR];
//PRODUCTS WITH GRANTS
export const PRODUCTS_WITH_GRANTS = [PRODUCT_IDS.SPV];
//DEFAULT GRANT
export const DEFAULT_GRANT = 1;

//EVC BR SUMMARY
export const ME_SUMMARY_TEMPLATE = {
    [getCompanyProfileIds().EDP_BR]: [
        {
            label: 'label.summaryCosts',
            items: [
                {
                    keyword: 'label.basePackage',
                    attribute: 'base',
                    editable: false,
                },
                {
                    keyword: 'page.businessModels.editOffer.structuralReport',
                    attribute: 'project_cost',
                    editable: false,
                },
                {
                    keyword: 'page.businessModels.editOffer.connectionCost',
                    attribute: 'connection_cost',
                    editable: false,
                },
                {
                    keyword: 'label.technicalResponsability',
                    attribute: 'technical_responsability',
                    editable: false,
                },
                {
                    keyword: 'label.equipmentsExtra',
                    attribute: 'equipments_extra',
                    editable: true,
                },
                {
                    keyword: 'label.servicesExtra',
                    attribute: 'services_extra',
                    editable: true,
                },
                {
                    keyword: 'label.extraCost',
                    attribute: 'extra_cost',
                    editable: true,
                },
            ],
            type: 'cost',
            options: {
                customStyles: {
                    className: 'edit-offer-summary',
                },
            },
        },
    ],
};

//SPV SUMMARY
export const SPV_SERVICE_SUMMARY_TEMPLATE = [
    {
        label: 'label.summaryCosts',
        items: [
            {
                keyword: 'label.basePackage',
                attribute: 'base_service',
                editable: false,
            },
            {
                keyword: 'label.costAdjustment',
                attribute: 'extra_cost',
                editable: true,
                tooltip: 'page.businessModels.costAdjustment.info',
            },
        ],
        type: 'cost',
        options: {
            customStyles: {
                className: 'summary_costs',
            },
        },
    },
    {
        label: 'label.hasClientDiscount',
        items: [
            {
                attribute: 'has_client_discount',
                type: 'radio',
                answers: [
                    {
                        label: 'label.yes',
                        value: true,
                    },
                    {
                        label: 'label.no',
                        value: false,
                    },
                ],
            },
        ],
        type: 'offer',
        options: {
            customStyles: {
                className: 'summary_offer',
            },
        },
    },
];

export const SPV_SUMMARY_TEMPLATE = [
    {
        label: 'label.SummaryOffer',
        items: [
            {
                keyword: 'label.basePackage',
                attribute: 'base',
                extraColumn: true,
                isInput: false,
                editable: false,
            },
            {
                keyword: 'label.preventive_maintenance',
                attribute: 'maintenance',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
            {
                keyword: 'page.spv.summary.gc_years',
                attribute: 'gc',
                extraColumn: true,
                isInput: true,
                editable: false,
                uncontrolled: true,
            },
            {
                keyword: 'page.spv.summary.inverters-warranty-years',
                attribute: 'inverters_warranty',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
            {
                keyword: 'page.spv.summary.panels-warranty-years',
                attribute: 'panels_warranty',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
            {
                keyword: 'label.costAdjustment',
                attribute: 'extra_cost',
                extraColumn: true,
                isInput: true,
                editable: true,
                tooltip: 'page.businessModels.costAdjustment.info',
            },
        ],
        type: 'cost',
        options: {
            customStyles: {
                className: 'summary_offer',
            },
        },
    },
];
export const SPV_SUMMARY_TEMPLATE_WITH_RUBRICS = [
    {
        label: 'label.SummaryOffer',
        items: [
            {
                keyword: 'label.basePackage',
                attribute: 'base',
                extraColumn: true,
                isInput: false,
                editable: false,
            },
            {
                keyword: 'label.additionalCosts',
                attribute: 'rubricsCosts',
                extraColumn: true,
                isInput: true,
                editable: true,
            },
            {
                keyword: 'label.maintenanceAndWarranties',
                attribute: 'maintenanceAndWarranties',
                className: 'title-rubrics',
                extraColumn: true,
                isInput: false,
                editable: false,
            },
            {
                keyword: 'label.preventive_maintenance',
                attribute: 'maintenance',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
            {
                keyword: 'page.spv.summary.gc_years',
                attribute: 'gc',
                extraColumn: true,
                isInput: true,
                editable: false,
                uncontrolled: true,
            },
            {
                keyword: 'page.spv.summary.inverters-warranty-years',
                attribute: 'inverters_warranty',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
            {
                keyword: 'page.spv.summary.panels-warranty-years',
                attribute: 'panels_warranty',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
            {
                keyword: 'label.costAdjustment',
                attribute: 'extra_cost',
                extraColumn: true,
                isInput: true,
                editable: true,
                tooltip: 'page.businessModels.costAdjustment.info',
            },
        ],
        type: 'cost',
        options: {
            customStyles: {
                className: 'summary_offer',
            },
        },
    },
];

export const SUMMARY_TEMPLATE_DEFAULT = [
    {
        label: 'label.summaryCosts',
        items: [
            {
                keyword: 'label.basePackage',
                attribute: 'base',
                editable: false,
            },
            {
                keyword: 'label.costAdjustment',
                attribute: 'extra_cost',
                editable: true,
                tooltip: 'page.businessModels.costAdjustment.info',
            },
        ],
        type: 'cost',
        options: {
            customStyles: {
                className: 'summary_costs',
            },
        },
    },
];

export const SPV_SB_SUMMARY_TEMPLATE = [
    {
        label: 'label.SummaryOffer',
        items: [
            {
                keyword: 'label.basePackage',
                attribute: 'base',
                extraColumn: true,
                isInput: false,
                editable: false,
            },
            {
                keyword: 'page.spv.summary.monitoring',
                attribute: 'monitoring_cost',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
            {
                keyword: 'page.spv.summary.inverters-warranty-years',
                attribute: 'inverters_warranty',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
            {
                keyword: 'page.spv.summary.panels-warranty-years',
                attribute: 'panels_warranty',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
        ],
        type: 'cost',
        options: {
            customStyles: {
                className: 'summary_offer',
            },
        },
    },
];

export const SPV_SB_SUMMARY_TEMPLATE_MAINTENANCE = [
    {
        label: 'label.SummaryOffer',
        items: [
            {
                keyword: 'label.basePackage',
                attribute: 'base',
                extraColumn: true,
                isInput: false,
                editable: false,
            },
            {
                keyword: 'page.spv.summary.monitoring',
                attribute: 'monitoring_cost',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
            {
                keyword: 'label.preventive_maintenance',
                attribute: 'maintenance',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
            {
                keyword: 'page.spv.summary.inverters-warranty-years',
                attribute: 'inverters_warranty',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
            {
                keyword: 'page.spv.summary.panels-warranty-years',
                attribute: 'panels_warranty',
                extraColumn: true,
                isInput: true,
                editable: false,
            },
        ],
        type: 'cost',
        options: {
            customStyles: {
                className: 'summary_offer',
            },
        },
    },
];

//SPV GRANT
export const SPV_GRANT_NAME = {
    SUBSIDY: 'subsidy',
    SUBSIDY_PRC: 'subsidy_prc',
    BATTERY_SUBSIDY: 'battery_subsidy',
    BATTERY_SUBSIDY_PRC: 'battery_subsidy_prc',
} as const;
export const SPV_GRANT = {
    [SPV_GRANT_NAME.SUBSIDY]: {
        name: SPV_GRANT_NAME.SUBSIDY,
        disabled: false,
        answers: [
            {
                label: 'label.yes',
                value: true,
            },
            {
                label: 'label.no',
                value: false,
            },
        ],
    },
    [SPV_GRANT_NAME.SUBSIDY_PRC]: {
        name: SPV_GRANT_NAME.SUBSIDY_PRC,
        min: 1,
        max: 100,
        step: 1,
        disabled: false,
    },
    [SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC]: {
        name: SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC,
        min: 1,
        max: 100,
        step: 1,
        disabled: false,
    },
};
//DEFAULT INVESTMENT KPI
const DEFAULT_INVESTMENT_KPI = {
    decimals: 0,
    unit: 'currency',
    name: 'investment',
    label: 'label.investment',
};
//DEFAULT ANNUAL SAVING KPI
const DEFAULT_ANNUAL_SAVING_KPI = {
    decimals: 0,
    unit: 'currency',
    name: 'annual_saving',
    label: 'label.annualSaving',
};
//DEFAULT IRR PRC
const DEFAULT_IRR_PRC_KPI = {
    decimals: 2,
    unit: 'percent',
    name: 'irr_prc',
    label: 'label.tir',
};
//DEFAULT IRR PRC
const DEFAULT_PPA_COST = {
    decimals: 0,
    unit: 'currency',
    name: 'cost',
    label: 'label.CAPEX',
};
//DEFAULT PAYBACK KPI
const DEFAULT_PAYBACK_KPI = {
    decimals: 1,
    unit: 'year',
    name: 'payback',
    label: 'label.payback',
};
//DEFAULT PAYBACK KPI
const DEFAULT_MONTHLY_PAYMENT_FEE_KPI = {
    decimals: 0,
    unit: 'mc',
    name: 'monthly_payments_fee',
    label: 'label.monthlyPaymentsFee',
};
// EVC EDP BR PAYBACK KPI
export const DEFAULT_MANAGEMENT_PLATFORM_MONTHLY_FEE_KPI_EVC_BR = {
    decimals: 0,
    unit: 'mc',
    name: 'total_platform_price_month_after_taxes',
    label: 'page.evc.resume.total_platform_price_month_companyID9',
};
//DEFAULT PAYBACK KPI
const DEFAULT_MONTHLY_PAYMENT_NUMBER_KPI = {
    decimals: 0,
    name: 'monthly_payments_number',
    label: 'label.monthlyPaymentsNumber',
};
const DEFAULT_DUMMY_KPI = {
    decimals: 0,
    unit: 'currency',
    name: 'price',
    label: 'label.NA',
};
//DEFAULT PRICE KPI
const DEFAULT_PRICE_KPI = {
    decimals: 0,
    unit: 'currency',
    name: 'price',
    label: 'label.price',
};
//DEFAULT LIQUID PRICE
const DEFAULT_NET_SAVINGS_KPI = {
    decimals: 0,
    unit: 'yc',
    name: 'net_savings',
    label: 'label.netSavings',
    options: {
        tooltip: 'label.netSavings.tooltip',
    },
};
//DEFAULT ANNUAL SAVING KPI
const DEFAULT_ANNUAL_PRICE_KPI = {
    decimals: 0,
    unit: 'yc',
    name: 'annual_price',
    label: 'label.price',
};
//DEFAULT ENERGY PRICE KPI
const DEFAULT_ENERGY_PRICE_KPI = {
    decimals: 0,
    unit: 'cmwp',
    name: 'energy_price',
    label: 'label.price',
};

//DEFAULT DEFAULT_MONTHLY_PAYMENT_FEE_KPI_EVC_ES KPI
const DEFAULT_MONTHLY_PAYMENT_FEE_KPI_EVC_ES = {
    decimals: 0,
    unit: 'mc',
    name: 'monthly_payments_fee',
    label: 'label.services',
};

//DEFAULT DEFAULT_MONTHLY_PAYMENT_FEE_KPI_EVC_ES KPI
const DEFAULT_MONTHLY_PAYMENT_FEE_ALT_KPI_EVC_ES = {
    decimals: 0,
    unit: 'mc',
    name: 'monthly_payments_fee_alt',
    label: 'label.chargerRental',
};

const DEFAULT_ANNUAL_PRICE_SPV_MAINTENANCE_KPI = {
    unit: 'currency',
    decimals: 0,
    name: 'annual_price',
    label: 'label.annualPrice',
};
const DEFAULT_NUMBER_YEARS_KPI = {
    decimals: 0,
    name: 'contract_duration_years',
    label: 'label.years',
};

const DEFAULT_PLAFOND_INCLUDED_KPI = {
    decimals: 0,
    name: 'plafond_hours',
    label: 'label.plafondIncluded',
};

//KPIS PER PAYMENT MODEL
export const KPIS_PER_PAYMENT_MODEL = {
    [PAYMENT_MODELS_IDS.PARTNERSHIP]: [DEFAULT_INVESTMENT_KPI, DEFAULT_ANNUAL_SAVING_KPI, DEFAULT_PAYBACK_KPI],
    [PAYMENT_MODELS_IDS.UP_FRONT]: [DEFAULT_INVESTMENT_KPI, DEFAULT_ANNUAL_SAVING_KPI, DEFAULT_PAYBACK_KPI],
    [PAYMENT_MODELS_IDS.INSTALLMENTS]: [
        DEFAULT_INVESTMENT_KPI,
        DEFAULT_ANNUAL_SAVING_KPI,
        DEFAULT_PAYBACK_KPI,
        DEFAULT_MONTHLY_PAYMENT_FEE_KPI,
        DEFAULT_MONTHLY_PAYMENT_NUMBER_KPI,
    ],
};
export const KPIS_PER_PAYMENT_MODEL_RR = {
    [PAYMENT_MODELS_IDS.UP_FRONT]: [DEFAULT_PRICE_KPI],
    [PAYMENT_MODELS_IDS.INSTALLMENTS]: [DEFAULT_PRICE_KPI, DEFAULT_MONTHLY_PAYMENT_FEE_KPI, DEFAULT_MONTHLY_PAYMENT_NUMBER_KPI],
};

export const KPIS_PER_BUSINMESS_MODEL_SPV_MAINTENANCE = {
    174: [/* ESP_PT */ DEFAULT_ANNUAL_PRICE_SPV_MAINTENANCE_KPI, DEFAULT_NUMBER_YEARS_KPI],
    177: [/* ESP_ES */ DEFAULT_ANNUAL_PRICE_SPV_MAINTENANCE_KPI, DEFAULT_NUMBER_YEARS_KPI],
    175: [/* ESP_PT */ DEFAULT_ANNUAL_PRICE_SPV_MAINTENANCE_KPI, DEFAULT_NUMBER_YEARS_KPI, DEFAULT_PLAFOND_INCLUDED_KPI],
    178: [/* EDP_ES */ DEFAULT_ANNUAL_PRICE_SPV_MAINTENANCE_KPI, DEFAULT_NUMBER_YEARS_KPI, DEFAULT_PLAFOND_INCLUDED_KPI],
};
export const KPIS_PER_PAYMENT_MODEL_ME = {
    [PAYMENT_MODELS_IDS.PARTNERSHIP]: [
        DEFAULT_DUMMY_KPI, //dummy
    ],
    [PAYMENT_MODELS_IDS.UP_FRONT]: [DEFAULT_PRICE_KPI],
    [PAYMENT_MODELS_IDS.INSTALLMENTS]: [DEFAULT_PRICE_KPI, DEFAULT_MONTHLY_PAYMENT_FEE_KPI, DEFAULT_MONTHLY_PAYMENT_NUMBER_KPI],
    [PAYMENT_MODELS_IDS.SERVICE]: [DEFAULT_MONTHLY_PAYMENT_FEE_KPI_EVC_ES, DEFAULT_MONTHLY_PAYMENT_FEE_ALT_KPI_EVC_ES],
};
export const KPIS_PER_PAYMENT_MODEL_PV = {
    [PAYMENT_MODELS_IDS.SERVICE]: [DEFAULT_ANNUAL_PRICE_KPI, DEFAULT_ANNUAL_SAVING_KPI, DEFAULT_NET_SAVINGS_KPI],
    [PAYMENT_MODELS_IDS.SERVICE_SECOND_VERSION]: [
        {
            decimals: 0,
            unit: 'currency',
            name: 'annual_price',
            label: 'label.annualPrice',
        },
        {
            decimals: 0,
            unit: 'currency',
            name: 'annual_saving',
            label: 'label.annualSaving',
        },
        {
            decimals: 0,
            unit: 'mc',
            name: 'annual_net_saving',
            label: 'label.netSavings',
        },
    ],
    SERVICES_AUX: [
        {
            decimals: 0,
            unit: 'currency',
            name: 'additional_value',
            label: 'label.additionalValue',
        },
        {
            decimals: 0,
            unit: 'mc',
            name: 'monthly_fee',
            label: 'label.price',
        },
        {
            decimals: 0,
            unit: 'mc',
            name: 'monthly_saving',
            label: 'label.saving',
        },
        {
            decimals: 0,
            unit: 'mc',
            name: 'monthly_net_saving',
            label: 'label.netSavings',
        },
    ],
};

//DEFAULT MARGIN ELEMENTS
export const DEFAULT_MARGIN_COMPONENTS_PARAMETERS = {
    name: null,
    tooltip: null,
    step: null,
    errors: {},
    register: () => {},
    loading: false,
};

//BUSINESSMODELS WITH KEYWORDS
export const BUSINESSMODELS_IDS_WITH_KEYWORDS = [82];

export const INPUT_NAMES_CONTACT_INFO = {
    CONTACT_INFO: 'contact_info',
};

export const QUESTIONS_CONTACT_INFO = {
    [INPUT_NAMES_CONTACT_INFO.CONTACT_INFO]: {
        name: INPUT_NAMES_CONTACT_INFO.CONTACT_INFO,
        label: null,
        subtitleLabel: null,
        disabled: false,
        options: [
            {
                icon: 'zmdi zmdi-account',
                name: 'name',
                label: 'label.name' as TIntl,
                type: 'string',
                placeholder: 'placeholder.name',
            },
            {
                icon: 'zmdi zmdi-email',
                name: 'email',
                label: 'label.email' as TIntl,
                type: 'email',
                placeholder: 'placeholder.email',
            },
        ],
        type: 'fieldArray',
        visible: false,
        validation: {
            name: null,
            // @ts-ignore
            email: validate(['email']),
            IDSalesforce: validate(['minLength', 'maxLength'], { minLength: 18, maxLength: 18 }),
        },
    },
};

export const KPIS_PER_PAYMENT_MODEL_PPA = {
    [PAYMENT_MODELS_IDS.UP_FRONT]: [DEFAULT_INVESTMENT_KPI, DEFAULT_ANNUAL_SAVING_KPI, DEFAULT_PAYBACK_KPI],
    [PAYMENT_MODELS_IDS.INSTALLMENTS]: [
        DEFAULT_INVESTMENT_KPI,
        DEFAULT_ANNUAL_SAVING_KPI,
        DEFAULT_PAYBACK_KPI,
        DEFAULT_MONTHLY_PAYMENT_FEE_KPI,
        DEFAULT_MONTHLY_PAYMENT_NUMBER_KPI,
    ],
    [PAYMENT_MODELS_IDS.SERVICE_PPA]: [DEFAULT_ENERGY_PRICE_KPI, DEFAULT_IRR_PRC_KPI, DEFAULT_ANNUAL_SAVING_KPI, DEFAULT_PPA_COST],
};

export const GAMAS_ANSWERS = [
    {
        title: `label.range.basic`,
        subtitle: null,
        value: 2,
        options: {},
    },
    {
        title: `label.range.investment`,
        subtitle: null,
        value: 1,
        options: {},
    },
];

export const GAMAS_ANSWERS_ESB = [
    {
        title: `label.range.standardPlus`,
        subtitle: null,
        value: 2,
        options: {},
    },
    {
        title: `label.range.premium`,
        subtitle: null,
        value: 1,
        options: {},
    },
];

export const BUSINESS_MODEL_IDS_BY_GEO = {
    [getCompanyProfileIds().EDP_PT]: {
        [PRODUCT_IDS.EVC]: {
            SERVICE: 54,
        },
    },
};
export const BUSINESS_MODEL_IDS = {
    [getCompanyProfileIds().EDP_PT]: {
        PP: 3,
        LEC_SB: 108,
        LEC: 151,
        SERVICE: 1,
        SERVICE_V2: 116,
        PAP: 142,
    },
    [getCompanyProfileIds().EDP_ES]: {
        SERVICE_V2: 57,
        PAP: 145,
    },
    [getCompanyProfileIds().EDP_IT]: {
        SERVICE_V2: 106,
        PAP: 143,
    },
    [getCompanyProfileIds().EDP_PL]: {
        SERVICE_V2: 107,
        PAP: 144,
    },
    [getCompanyProfileIds().NRG]: {
        PPA: 95,
    },
    [getCompanyProfileIds().NRG]: {
        PPA: 154,
    },
    [getCompanyProfileIds().SUNSEAP]: {
        PPA: 166,
    },
    [getCompanyProfileIds().ROMANDE]: {
        PPA: 169,
    },
    [getCompanyProfileIds().PROFILE_BASE_UK]: {
        PPA: 183,
    },
    [getCompanyProfileIds().EVOLO]: {
        PPA: 183,
    },
    [getCompanyProfileIds().PROFILE_BASE_USA]: {
        PPA: 183,
    },
};

export const SIZING_TYPE_IDS = {
    SIMPLE: 1,
    BOTS: 2,
    DETAILED_FULL: 3,
    DETAILED_RISK_FREE: 4,
    DETAILED_GOAL_SEEK_FREE: 5,
};

export const BUSINESS_MODEL_ELIGIBLE = {
    YES: 1,
    NO: 0,
};

export const BUSINESS_MODEL_NON_ELIGIBLE_REASONS = {
    RISK: 'risk',
    CAN_NEGOTIATE: 'can_negotiate',
    NOT_NET_SAVINGS_ELIGIBILITY: 'not_net_savings_eligibility',
};

export const INPUTS_NAME_NEGOCIATIONS = {
    SERVICE_AUX_KPIS_ARR: 'service_aux_kpis_arr',
    PAP_AUX_KPIS_ARR: 'pap_aux_kpis_arr',
    LEC_AUX_KPIS_ARR: 'lec_aux_kpis_arr',
};

export const KPIS_PER_PAYMENT_MODEL_PV_SERVICE_AUX = {
    [PAYMENT_MODELS_IDS.SERVICE]: [
        {
            decimals: 0,
            unit: 'currency',
            name: 'additional_value',
            label: 'label.additionalAmount',
            options: {
                visible: false,
                decimals: 0,
                unit: 'percent',
                name: 'prc_of_monthly_fee_increase',
            },
        },
        {
            decimals: 0,
            unit: 'mc',
            name: 'monthly_fee',
            label: 'label.finalMonthlyFee',
        },
        {
            decimals: 0,
            unit: 'mc',
            name: 'monthly_net_saving',
            label: 'label.monthlyNetSaving',
        },
        {
            decimals: 0,
            unit: 'yc',
            name: 'annual_net_saving',
            label: 'label.annualNetSaving',
        },
    ],
    [PAYMENT_MODELS_IDS.SERVICE_SECOND_VERSION]: [
        {
            decimals: 0,
            unit: 'currency',
            name: 'annual_price',
            label: 'label.annualPrice',
        },
        {
            decimals: 0,
            unit: 'currency',
            name: 'annual_saving',
            label: 'label.annualSaving',
        },
        {
            decimals: 0,
            unit: 'currency',
            name: 'annual_net_saving',
            label: 'label.annualNetSavings',
        },
    ],
    [PAYMENT_MODELS_IDS.PAY_AS_PRODUCED]: [
        {
            availableToBusinessModelsID: [
                BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_IT].PAP,
                BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_PL].PAP,
                BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_ES].PAP,
            ],
            decimals: 1,
            unit: 'currency',
            name: 'additional_value',
            label: 'label.additionalAmmount',
            options: {
                visible: true,
                numberOfDecimalPlaces: 1,
                unit: 'percent',
                name: 'additional_value_prc',
            },
        },
        {
            decimals: 1,
            unit: 'cmwp',
            name: 'pap_tariff',
            label: 'label.tariffAndPercDiscount',
            options: {
                visible: true,
                numberOfDecimalPlaces: 1,
                unit: 'percent',
                name: 'pap_tariff_discount',
            },
        },
        {
            decimals: 0,
            unit: 'currency',
            name: 'annual_estimated_price',
            label: 'label.annualEstimatedPrice',
        },
        {
            decimals: 0,
            unit: 'currency',
            name: 'annual_net_saving',
            label: 'label.annualNetSavings',
        },
    ],
    [PAYMENT_MODELS_IDS.LEC]: [
        {
            availableToBusinessModelsID: [],
            decimals: 1,
            unit: 'currency',
            name: 'additional_value',
            label: 'label.additionalAmmount',
            options: {
                visible: true,
                numberOfDecimalPlaces: 1,
                unit: 'percent',
                name: 'additional_value_prc',
            },
        },
        {
            decimals: 1,
            unit: 'cmwp',
            name: 'lec_tariff',
            label: 'label.tariffAndPercDiscount',
            options: {
                visible: true,
                numberOfDecimalPlaces: 1,
                unit: 'percent',
                name: 'lec_tariff_discount',
            },
        },
        {
            decimals: 0,
            unit: 'currency',
            name: 'annual_estimated_price',
            label: 'label.annualEstimatedPrice',
        },
        {
            decimals: 0,
            unit: 'currency',
            name: 'annual_net_saving',
            label: 'label.annualNetSavings',
        },
    ],
};

// * ACTION TYPES * \\
export const REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED = 'REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED';
export const REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_FAIL = 'REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_FAIL';
export const REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_SUCCESS = 'REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_SUCCESS';
export const REQUEST_GET_BUSINESS_MODELS = 'REQUEST_GET_BUSINESS_MODELS';
export const REQUEST_GET_BUSINESS_MODELS_SUCCESS = 'REQUEST_GET_BUSINESS_MODELS_SUCCESS';
export const SET_BUSINESS_MODEL_BODY = 'SET_BUSINESS_MODEL_BODY';
export const SET_BUSINESS_MODEL_BODY_COST = 'SET_BUSINESS_MODEL_BODY_COST';
export const SET_BUSINESS_MODEL_OFFER_EDITION = 'SET_BUSINESS_MODEL_OFFER_EDITION';
export const SET_BUSINESS_MODEL_LOADER = 'SET_BUSINESS_MODEL_LOADER';
export const RESET_BUSINESS_MODELS = 'RESET_BUSINESS_MODELS';
export const RESET_BUSINESS_MODEL_INFO = 'RESET_BUSINESS_MODEL_INFO';
export const SET_BUSINESS_MODEL = 'SET_BUSINESS_MODEL';
export const SET_BUSINESS_MODEL_BODY_INPUTS = 'SET_BUSINESS_MODEL_BODY_INPUTS';
export const SET_BUSINESS_MODEL_OPTIONS_ME = 'SET_BUSINESS_MODEL_OPTIONS_ME';
export const SET_BUSINESS_MODEL_SERVICE_PPA = 'SET_BUSINESS_MODEL_SERVICE_PPA';
export const SET_BUSINESS_MODEL_NEGOTIATIONS = 'SET_BUSINESS_MODEL_NEGOTIATIONS';
export const SET_BUSINESS_MODEL_BODY_BATTERY_ADDIN = 'SET_BUSINESS_MODEL_BODY_BATTERY_ADDIN';
export const RESET_STEPS_COMPLETED_BATTERY_ADDIN = 'RESET_STEPS_COMPLETED_BATTERY_ADDIN';
// DETAILLED ACTIONS
export const SET_BUSINESS_MODEL_SELECTED = 'SET_BUSINESS_MODEL_SELECTED';
export const SET_BUSINESS_MODEL_MARGIN = 'SET_BUSINESS_MODEL_MARGIN';
export const SET_BUSINESS_MODEL_KPIS = 'SET_BUSINESS_MODEL_KPIS';
export const SET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER = 'SET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER';
export const SET_BUSINESS_MODEL_VALUES = 'SET_BUSINESS_MODEL_VALUES';
export const SET_BUSINESS_MODEL_MONTHLY_PAYMENT = 'SET_BUSINESS_MODEL_MONTHLY_PAYMENT';
export const SET_BUSINESS_MODEL_OP_AND_MAN_DURATION = 'SET_BUSINESS_MODEL_OP_AND_MAN_DURATION';
export const SET_BUSINESS_MODEL_ANNUAL_FEE_UPDATE = 'SET_BUSINESS_MODEL_ANNUAL_FEE_UPDATE';
export const SET_BUSINESS_MODEL_EXTRA_COST = 'SET_BUSINESS_MODEL_EXTRA_COST';
export const SET_BUSINESS_MODEL_RUBRIC = 'SET_BUSINESS_MODEL_RUBRIC';
export const SET_BUSINESS_MODEL_SLIDER_READY = 'SET_BUSINESS_MODEL_SLIDER_READY';
export const SET_BUSINESS_MODEL_PROPOSAL_READY = 'SET_BUSINESS_MODEL_PROPOSAL_READY';
export const SET_BUSINESS_MODEL_FINAL_KPIS = 'SET_BUSINESS_MODEL_FINAL_KPIS';
export const RESET_BUSINESS_MODEL = 'RESET_BUSINESS_MODEL';
export const RESET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER = 'RESET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER';
