import { createContext, useCallback } from 'react';
import { notify } from 'services/@efz/notify';
import { func, string } from 'prop-types';
import { createFormDataWithImageBlob, isDefined } from 'services/util/auxiliaryUtils';
import { INTERNAL_SERVER_ERROR, OK } from 'constants/statusCodeHttp';
import { SentryCaptureException } from 'services/@efz/sentry';
import IntlMessages from 'components/util/IntlMessages';
import { postFileUploadImgContract } from 'api/utils';
import { SpvActions } from 'constants/products/solarpv';
import { useProposalStore } from 'store/proposal';

const UploadImgContractContext = createContext({
    imageName: string,
    fetchPostFileUploadImgContract: func,
});

const UploadImgContractProvider = ({ children }) => {
    const {
        img_contract_filename: imageName,
        actions: { setImgContractFilename },
    } = useProposalStore();

    const fetchPostFileUploadImgContract = useCallback(
        async (mapImageBlob, options) => {
            const { handleNextBusinessModels = null, inputs = null, solarpvDispatchHandler = null, goToBusinessModelsStep } = options;

            if (!isDefined(imageName)) {
                let formData = await createFormDataWithImageBlob(mapImageBlob, 'img-contract', 'gmap-img');
                let rspUploadData = await postFileUploadImgContract(formData);

                if (rspUploadData?.status === OK) {
                    setImgContractFilename(rspUploadData?.data);
                    if (isDefined(handleNextBusinessModels)) {
                        handleNextBusinessModels(inputs);
                    } else {
                        goToBusinessModelsStep(rspUploadData?.data);
                    }
                } else {
                    if (solarpvDispatchHandler) solarpvDispatchHandler(SpvActions.SET_MAP_PRINT_STATE, { mapPrintState: 'idle' });
                    if (rspUploadData?.status) {
                        /* when request cancelled  */
                        SentryCaptureException({
                            level: 3,
                            message: `Fatal - Fail uploading img-contract`,
                            tags: {
                                api: 'img-contract',
                                fnc: 'fetchPostFileUploadImgContract',
                            },
                            extrasContext: {
                                responseData: rspUploadData,
                            },
                        });
                        // status 500 esta ser apanhado pelo interceptor
                        if (rspUploadData?.status !== INTERNAL_SERVER_ERROR)
                            notify(<IntlMessages id="page.error.403.unexpected" />, 'error');
                    }
                }
            }
        },
        [imageName] //eslint-disable-line
    );

    const fetchPostFileUploadImgContractReformulation = useCallback(
        async (mapImageBlob) => {
            if (!isDefined(imageName)) {
                let formData = await createFormDataWithImageBlob(mapImageBlob, 'img-contract', 'gmap-img');
                let rspUploadData = await postFileUploadImgContract(formData);

                if (rspUploadData?.status === OK) {
                    setImgContractFilename(rspUploadData?.data);
                    return rspUploadData?.data;
                } else {
                    if (rspUploadData?.status) {
                        /* when request cancelled  */
                        SentryCaptureException({
                            level: 1,
                            message: `Fatal - Fail uploading img-contract`,
                            tags: {
                                api: 'img-contract',
                                fnc: 'fetchPostFileUploadImgContractReformulation',
                            },
                            extrasContext: {
                                responseData: rspUploadData,
                            },
                        });
                        // status 500 esta ser apanhado pelo interceptor
                        if (rspUploadData?.status !== INTERNAL_SERVER_ERROR)
                            notify(<IntlMessages id="page.error.403.unexpected" />, 'error');
                    }
                }
            }
        },
        [imageName] //eslint-disable-line
    );

    return (
        <UploadImgContractContext.Provider
            value={{
                imageName,
                fetchPostFileUploadImgContract,
                fetchPostFileUploadImgContractReformulation,
            }}
        >
            {children}
        </UploadImgContractContext.Provider>
    );
};

export { UploadImgContractContext, UploadImgContractProvider };
