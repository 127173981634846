import { defineMessages } from 'react-intl';
import esMessages from '../locales/es_ES.json';

const esESLang = {
    messages: {
        ...esMessages,
    },
    locale: 'es-ES',
    data: defineMessages,
};
export default esESLang;
