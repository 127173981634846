export const enum HpLoadings {
    GENERAL = 'general',
    SAVE = 'save',
    NEXT = 'next',
    RESET = 'reset',
    SIMULATION = 'simulation',
    INNER_WALLS = 'innerWalls',
    ELEMENT_TYPE_OPTIONS = 'elementTypeOptions',
}

export const initialHPLoadings = {
    general: false,
    next: false,
    save: false,
    simulation: false,
    elementTypeOptions: false,
};

export const hpLoadingsReducer = (state = initialHPLoadings, action) => {
    switch (action) {
        case HpLoadings.GENERAL:
            return {
                ...state,
                general: true,
            };
        case HpLoadings.INNER_WALLS:
            return {
                ...state,
                next: true,
                simulation: true,
            };
        case HpLoadings.NEXT:
            return {
                ...state,
                general: true,
                next: true,
            };
        case HpLoadings.SAVE:
            return {
                ...state,
                general: true,
                save: true,
            };
        case HpLoadings.RESET:
            return initialHPLoadings;
        default:
            return initialHPLoadings;
    }
};
