/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Reducers - Setting
 *
 * @version 20190715
 * @since 20190715 Initial release
 *
 */
import clone from 'fast-copy';
import {
    CHANGE_DIRECTION,
    CHANGE_NAVIGATION_STYLE,
    DARK_THEME,
    SWITCH_LANGUAGE,
    WINDOW_WIDTH,
    INITIAL_SETTINGS,
    REQUEST_THEME_SUCCESS,
    MAP_LANGUAGE,
} from 'constants/settings';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialSettings = clone(INITIAL_SETTINGS);

const reducersSettings = (state = initialSettings, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...state,
                navCollapsed: false,
            };
        case WINDOW_WIDTH:
            return {
                ...state,
                width: action.width,
            };
        case DARK_THEME:
            return {
                ...state,
                darkTheme: !state.darkTheme,
            };
        case SWITCH_LANGUAGE:
            localStorage.setItem('LOCALE_CODE', action?.payload?.locale ?? 'en'); // For intlMessages method

            return {
                ...state,
                locale: { ...action.payload },
            };
        case CHANGE_DIRECTION:
            return {
                ...state,
                isDirectionRTL: !state.isDirectionRTL,
            };

        case CHANGE_NAVIGATION_STYLE:
            return {
                ...state,
                navigationStyle: 'HORIZONTAL_NAVIGATION',
            };

        case REQUEST_THEME_SUCCESS:
            return {
                ...state,
                theme: action.payload,
            };
        case MAP_LANGUAGE:
            return {
                ...state,
                mapLocaleUser: action.payload,
            };

        default:
            return state;
    }
};

export default reducersSettings;
