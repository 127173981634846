import { createContext, useReducer } from 'react';
import { initialSettingsState, settingsReducer } from './settings/settingsReducer';
import { ISettingdContext } from 'interfaces/settings';

const SettingsContext = createContext<ISettingdContext>({
    settingsState: initialSettingsState,
    settingsDispatch: () => {},
});

const SettingsProvider = ({ children }) => {
    const [settingsState, settingsDispatch] = useReducer(settingsReducer, initialSettingsState);

    return <SettingsContext.Provider value={{ settingsState, settingsDispatch }}>{children}</SettingsContext.Provider>;
};

export { SettingsContext, SettingsProvider };
