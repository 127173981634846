import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { TGrants } from './upfront';
import { TRubric } from 'interfaces/rubric';

export type TPaP = typeof PAYMENT_MODELS_IDS.PAY_AS_PRODUCED;
export type TPaPValues = {
    kpis_per_margin: TMarginPap[];
    marginSelected?: number;
    grants: { totalGrants: { prc: number; currency: number }; grants: TGrants[]; hasGrants: boolean };
    offerEdition: TPaPOfferEdition;
    proposalReady: boolean;
    costs: {
        baseCost: number;
        offerDetailsCost: number;
        marginCost: number;
        grantsCost: number;
        totalCost: number;
    };
    rubrics: {
        cost: number;
        values: TRubric[];
    };
    syncKpis?: {
        [key: string]: any;
        negotiation: {
            [key: string]: any;
            hasErrorOnNetSavingsEligibility: boolean;
            status_risk_premium_id: number;
        };
    };
    clientContactInfo?: {
        name: string;
        email: string;
    };
    id_crm?: string;
};

export type TPaPOfferEdition = {
    extra_cost?: number;
};

export type TMarginPap = {
    annual_estimated_price: number;
    annual_net_saving: number;
    is_default: false;
    pap_tariff: number;
    pap_tariff_discount: number;
    saving_share: number;
    annual_saving: number;
    additional_value?: number;
    additional_value_prc?: number;
};

export enum PaPEvents {
    SET_PAP_BM = 'SET_PAP_BM',
    SET_PAP_MARGIN = 'SET_PAP_MARGIN',
    SET_PAP_EXTRA_COST = 'SET_PAP_EXTRA_COST',
    UPDATE_PAP_VALUES_BM = 'UPDATE_PAP_VALUES_BM',
    SET_PAP_PROPOSAL_READY = 'SET_PAP_PROPOSAL_READY',
    SET_PAP_PROPOSAL = 'SET_PAP_PROPOSAL',
    ADD_PAP_GRANT = 'ADD_PAP_GRANT',
    DELETE_PAP_GRANT = 'DELETE_PAP_GRANT',
    SET_DESCRIPTION_GRANT = 'SET_DESCRIPTION_GRANT',
    SET_AMMOUNT_GRANT = 'SET_AMMOUNT_GRANT',
    UPDATE_PAP_GRANTS = 'UPDATE_PAP_GRANTS',
    UPDATE_PAP_ADDITIONAL_DATA = 'UPDATE_PAP_ADDITIONAL_DATA',
    SET_PAP_RUBRIC_COST = 'SET_PAP_RUBRIC_COST',
}

export type TPaPEventsValues<Event> =
    Event extends typeof PaPEvents.SET_PAP_BM ? { kpis_per_margin: TMarginPap[]; proposalReady: true }
    : Event extends typeof PaPEvents.SET_PAP_MARGIN ? number
    : Event extends typeof PaPEvents.UPDATE_PAP_VALUES_BM ?
        {
            offerEdition: {
                extra_cost: number;
            };
        }
    : Event extends typeof PaPEvents.SET_PAP_PROPOSAL_READY ? boolean
    : Event extends typeof PaPEvents.SET_PAP_PROPOSAL ? null
    : any;
