import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { UserContext } from 'contexts/userContext';
import { TOfferEditionValues } from 'interfaces/businessModels/detailed';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { installmentsKpis } from 'services/businessModels/installmentsKpis';
import { useFeatureFlags } from 'store/featureFlags';
import { TIntl } from 'types/utils';

const MarginKpisInstallmentsDetailed = () => {
    const { bmState } = useContext(OfferEditionContext);
    const { companyProfileId } = useContext(UserContext);
    const { featureFlags } = useFeatureFlags();

    const { watch } = useFormContext<TOfferEditionValues>();

    const _monthlyPaymentNumber = watch('monthly_payment_option_id');

    const kpis = bmState?.kpis?.kpis;
    const monthlyPayment = bmState?.offerEditionValues?.monthlyPayment;
    const kpisToShow = installmentsKpis(kpis, _monthlyPaymentNumber, monthlyPayment, { companyProfileId, featureFlags });

    return (
        <div className="bm-detailed-negotiation-kpis mb-4">
            <div className="kpis-table">
                {kpisToShow.map((kpi, idx) => {
                    return (
                        <>
                            <div className="kpi">
                                <div className="label">
                                    <IntlMessages id={kpi.label as TIntl} />
                                </div>
                                <div className="value">
                                    {/* @ts-ignore */}
                                    <FormatNumber number={kpi.value} numberOfDecimalPlaces={kpi.numberOfDecimals} unit={kpi.unit ?? null} />
                                </div>
                            </div>
                            {idx !== kpisToShow.length - 1 && <div className="divider" />}
                        </>
                    );
                })}
            </div>
        </div>
    );
};

export default MarginKpisInstallmentsDetailed;
