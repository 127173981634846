import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IntlMessages from 'components/util/IntlMessages';
import NumberFormat from 'react-number-format';
import { intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';
import CustomUnit from 'components/util/CustomUnit';
import ErrorMessage from 'components/errors/ErrorMessage';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import useAddOnHelper from 'hooks/businessModelsPro/useAddOnHelper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormatNumber from 'components/util/FormatNumber';
import Tooltip from 'components/@efz/Tooltip';
import { StyledTariffHelperDialog } from './StyledTariffHelperDialog';
import FormControl from '@mui/material/FormControl';
import Alert from 'components/@efz/Alert';
import { Button } from '@save2compete/efz-design-system';
import { IconButton } from '@save2compete/efz-design-system';

const AddOnHelperDialog = ({ onOpendialoHandler, addOnHelperData, isOpen }) => {
    //Hooks
    const { onSubmitHandler, isRequesting, formData, setError, error, errorDefault } = useAddOnHelper({
        onOpendialoHandler,
    });
    const methods = useForm({
        defaultValues: {
            simulator_var: isFieldDefined(formData?.simulator_var) ? formData?.simulator_var : addOnHelperData?.[0]?.simulator_var ?? '',
            value: `${isFieldDefined(formData?.value) ? formData?.value : addOnHelperData?.[0]?.input_default ?? ''}`,
        },
        mode: 'onChange',
    });

    const {
        setValue,
        control,
        watch,
        trigger,
        formState: { errors },
    } = methods;

    const options = addOnHelperData?.find((el) => el.simulator_var === watch('simulator_var'));

    return (
        <StyledTariffHelperDialog
            onClose={isRequesting ? () => {} : onOpendialoHandler}
            open={isOpen}
            className={'add-on-helper'}
            maxWidth={false}
        >
            <FormProvider {...methods}>
                <form
                    id={'add-on-helper-form'}
                    className="add-on-helper-form"
                    key={'addOnHelperForm'}
                    onSubmit={methods.handleSubmit(onSubmitHandler)}
                >
                    <div className="add-on-helper-dialog-title">
                        <h2>
                            <IntlMessages id="page.bm.addOnHelper.title" />
                        </h2>
                        <hr />
                        <IconButton
                            icon="xMarkExit"
                            variant="tertiary"
                            className="close-button"
                            disabled={isRequesting}
                            aria-label="close"
                            onClick={onOpendialoHandler}
                            dataTestId={`close-add-on-helper-dialog`}
                        />
                    </div>

                    <DialogContent className="add-on-helper-dialog-content">
                        <FormControl className="dialog-input" error={isFieldDefined(errors?.['simulator_var'])}>
                            <label>
                                <IntlMessages id="page.bm.addOnHelper.text" /> *
                            </label>
                            <Controller
                                name={'simulator_var'}
                                control={control}
                                rules={{
                                    required: 'yup.message.required',
                                }}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ field: { ref, ...field } }) => (
                                    <Select
                                        {...field}
                                        disabled={isRequesting}
                                        error={isFieldDefined(errors?.['simulator_var'])}
                                        inputProps={{ className: 'd-flex align-items-center' }}
                                        displayEmpty={true}
                                        value={field.value || ''}
                                        onChange={(evt) => {
                                            setValue(
                                                'value',
                                                `${addOnHelperData?.find((el) => el.simulator_var === evt.target.value)?.input_default ?? ''}`
                                            );
                                            field.onChange(evt);
                                            setTimeout(() => {
                                                setError(errorDefault);
                                                trigger('value');
                                            }, 10);
                                        }}
                                    >
                                        <MenuItem value="" disabled={true}>
                                            <IntlMessages id={'label.selectOption'} />
                                        </MenuItem>
                                        {addOnHelperData.map((option) => {
                                            return (
                                                <MenuItem key={option.simulator_var} value={option.simulator_var}>
                                                    <IntlMessages id={option.tag} />
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                )}
                            />
                            {/* @ts-ignore */}
                            {errors?.['simulator_var'] && <ErrorMessage error={errors?.['simulator_var']} />}
                        </FormControl>

                        <FormControl className="dialog-input" error={isFieldDefined(errors?.['value'])}>
                            <label className="d-flex gap-2">
                                <IntlMessages id={'label.value'} /> *
                                {watch('value') !== `${options?.input_default}` /* reset default value */ && (
                                    <Tooltip
                                        title={
                                            // @ts-ignore
                                            <IntlMessages
                                                id="page.spvPro.injectionTooltip.reset"
                                                values={{ defaultValue: options?.input_default ?? '-' }}
                                            />
                                        }
                                        type="info"
                                    >
                                        <IconButton
                                            variant="action"
                                            icon="refreshArrows"
                                            size="sm"
                                            disabled={isRequesting}
                                            onClick={(e) => {
                                                setValue('value', `${options?.input_default}`);
                                                setTimeout(() => {
                                                    setError(errorDefault);
                                                    trigger('value');
                                                }, 10);
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                            dataTestId={`reset-add-on-helper-dialog`}
                                        />
                                    </Tooltip>
                                )}
                            </label>

                            <Controller
                                name="value"
                                control={control}
                                rules={{
                                    required: 'yup.message.required',
                                    min: {
                                        value: options?.input_min ?? 0,
                                        message: 'yup.message.number.min',
                                    },
                                    max: {
                                        value: options?.input_max ?? 0,
                                        message: 'yup.message.number.max',
                                    },
                                }}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ field: { ref, ...field } }) => (
                                    <NumberFormat
                                        {...field}
                                        disabled={isRequesting}
                                        error={isFieldDefined(errors?.['value'])}
                                        inputProps={{ inputMode: 'numeric' }}
                                        placeholder={intlMessages('label.insertValue')}
                                        customInput={OutlinedInput}
                                        onChange={(evt) => {
                                            setError(errorDefault);
                                            field.onChange(evt);
                                        }}
                                        endAdornment={
                                            <InputAdornment className="ms-2" position="end">
                                                {/* @ts-ignore */}
                                                <CustomUnit unit={options?.input_unity} />
                                            </InputAdornment>
                                        }
                                    />
                                )}
                            />
                            {errors?.['value'] && (
                                <ErrorMessage
                                    //  @ts-ignore
                                    error={errors?.['value']}
                                    values={{
                                        min: options.input_min,
                                        // @ts-ignore
                                        max: <FormatNumber number={options.input_max} numberOfDecimalPlaces={0} />,
                                    }}
                                />
                            )}
                        </FormControl>
                        {error?.text && (
                            <Alert severity={'error'}>
                                <IntlMessages
                                    id={error?.text}
                                    values={{
                                        newTariff: <strong>{error?.newTariff}</strong>,
                                        min_monthly_fee: <strong>{error?.min_monthly_fee}</strong>,
                                        max_monthly_fee: <strong>{error?.max_monthly_fee}</strong>,
                                    }}
                                />
                            </Alert>
                        )}
                    </DialogContent>

                    <DialogActions className="generic-dialog-actions d-flex justify-content-center w-100">
                        <Button
                            dataTestId={`btn-submit-add-on-helper-dialog`}
                            type="submit"
                            disabled={isRequesting || isFieldDefined(error?.text)}
                            loading={isRequesting}
                        >
                            <IntlMessages id={'label.applyCriteria'} />
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </StyledTariffHelperDialog>
    );
};

export default AddOnHelperDialog;
