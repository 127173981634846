import { useContext } from 'react';
import IconButton from '@mui/material/IconButton';

// Components
import IntlMessages from 'components/util/IntlMessages';
import Tooltip from 'components/@efz/Tooltip';

// Contexts
import { SidebarContext } from 'contexts/sidebarContext';

// Icons
import { ReactComponent as OpenIcon } from 'assets/@efz/icons/sidebar-open.svg';
import { ReactComponent as CloseIcon } from 'assets/@efz/icons/sidebar-close.svg';

const OpenSidebarButton = () => {
    const { isSidebarPermanent, setSidebarOpen, sidebarOpen } = useContext(SidebarContext);

    const clickHandler = () => {
        setSidebarOpen((sidebarOpen) => !sidebarOpen);
    };

    return (
        <Tooltip
            title={sidebarOpen ? <IntlMessages id="label.collapse" /> : <IntlMessages id="label.expand" />}
            type="default"
            placement="top-start"
            className="sidebar-button-tooltip"
            arrowPlacement="bottom-start"
        >
            <span>
                <IconButton
                    className={`sidebar-button sidebar-button${isSidebarPermanent ? '-permanent' : ''} ${sidebarOpen ? 'open' : ''} w-100`}
                    onClick={clickHandler}
                >
                    <div className={`sidebar-icon sidebar-icon-opener`}>
                        {sidebarOpen ?
                            <CloseIcon />
                        :   <OpenIcon />}
                    </div>
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default OpenSidebarButton;
