/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description redux Reducers - Proposal
 *
 * @version 20191203
 * @version 20191202
 * @version 20191127 added RESET_PROPOSAL
 * @version 20191113
 * @since 20191113 Initial release
 *
 */

import clone from 'fast-copy';
import {
    RESET_PROPOSAL,
    SET_PROPOSAL,
    SET_PROPOSAL_INPUTS,
    SET_PROPOSAL_PAYLOAD,
    REQUEST_GET_PROPOSAL_ID,
    REQUEST_GET_PROPOSAL_INFORMATION,
    REQUEST_GET_PROPOSAL_INFORMATION_SUCCESS,
    REQUEST_HEAD_SUPPORT_DOCS,
    REQUEST_HEAD_SUPPORT_DOCS_SUCCESS,
    REQUEST_GET_SUPPORT_DOCS,
    REQUEST_GET_SUPPORT_DOCS_SUCCESS,
    SET_NO_ACCESS_TO_PROPOSAL,
    REQUEST_GET_PROPOSAL_ID_SUCCESS,
} from 'constants/proposal';

import { INITIAL_PROPOSAL } from 'constants/proposal';
import { STATE_IDS } from 'constants/user';
import { showStateCurrent } from 'services/user';
import { isExistWord } from 'services/util/auxiliaryUtils';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = clone(INITIAL_PROPOSAL);

const reducersProposal = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROPOSAL:
            return {
                ...state,
                ...action.payload,
            };
        case SET_PROPOSAL_INPUTS:
            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    ...action.payload,
                },
            };
        case SET_PROPOSAL_PAYLOAD:
            return {
                ...state,
                payload: {
                    ...state.inputs,
                    ...action.payload,
                },
            };
        case REQUEST_GET_PROPOSAL_ID:
            return {
                ...state,
            };
        case REQUEST_GET_PROPOSAL_ID_SUCCESS:
            return {
                ...state,
                id: parseInt(action.payload?.data?.data.sim_id),
                has_proposal_description_feature: action.payload?.data.data?.has_proposal_description_feature,
                can_send_to_crm: action.payload?.data.data.can_send_to_crm,
            };
        case REQUEST_GET_PROPOSAL_INFORMATION:
            return {
                ...state,
            };
        case REQUEST_GET_PROPOSAL_INFORMATION_SUCCESS:
            return {
                ...state,
                information: action.payload.data,
            };
        case REQUEST_HEAD_SUPPORT_DOCS:
            return {
                ...state,
            };
        case REQUEST_HEAD_SUPPORT_DOCS_SUCCESS:
            return {
                ...state,
                contractAvailable: true,
            };
        case REQUEST_GET_SUPPORT_DOCS:
            return {
                ...state,
                contractDownloaded: true,
            };
        case REQUEST_GET_SUPPORT_DOCS_SUCCESS:
            return {
                ...state,
                contractDownloaded: false,
            };
        case SET_NO_ACCESS_TO_PROPOSAL:
            return {
                ...state,
                contractAvailable: true,
                contractDownloaded: false,
            };
        case RESET_PROPOSAL:
            return {
                ...clone(INITIAL_PROPOSAL),
            };
        case LOCATION_CHANGE: {
            const location = action.payload.location.pathname;
            return isExistWord(location, 'proposal') ? state : (
                    {
                        ...state,
                        id: null,
                    }
                );
        }

        default:
            return (
                showStateCurrent(STATE_IDS.PLATFORM) ?
                    !state ? clone(INITIAL_PROPOSAL)
                    :   state
                :   null
            );
    }
};

export default reducersProposal;
