import { useContext } from 'react';

// Components
import GrantsKpiPaP from './Kpi';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPaP } from 'interfaces/businessModels/pap';

const GrantsKpisESolar = () => {
    const { state } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;

    const currentInvestment = state?.selected?.values?.costs?.totalCost;
    // const searchValue = bmSelected?.values?.margin_negotiation?.search;
    // const annualSaving = bmSelected?.values?.kpis_per_margin.find(kpi => kpi?.[searchValue] === bmSelected?.values?.marginSelected)?.annual_saving ?? state.businessModels[0].kpis_previews[0].kpis_per_margin[0].annual_saving;
    const grants = state?.selected?.values?.grants;
    // const grantsValue = grants?.grants?.reduce((accumulator, currentValue) => accumulator + currentValue.ammounts.currency, 0);
    const investmentWithGrant = currentInvestment - grants?.totalGrants?.currency;
    // FIXME: ver se payback é para aparecer e com que dados é calculado
    // const payback = investmentWithGrant / annualSaving;

    return (
        <div className="bm-kpis">
            <GrantsKpiPaP
                label="page.bm.grants.originalInvestment"
                value={{ number: currentInvestment, unit: 'currency', numberOfDecimalPlaces: 0 }}
            />
            <GrantsKpiPaP
                label="page.bm.grants.grant"
                value={{ number: grants?.totalGrants?.currency, unit: 'currency', numberOfDecimalPlaces: 2 }}
            />
            <GrantsKpiPaP
                label="page.bm.grants.investmentWithGrant"
                value={{ number: investmentWithGrant, unit: 'currency', numberOfDecimalPlaces: 0 }}
            />
            {/* <GrantsKpiPaP label="label.payback" value={{ number: payback, unit: 'year', numberOfDecimalPlaces: 1 }} /> */}
        </div>
    );
};

export default GrantsKpisESolar;
