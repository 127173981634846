import { TInstallmentsValues } from './../../../interfaces/businessModels/installments';
import { UserContext } from 'contexts/userContext';
import { computeRubricCostsPayload, fetchBMPayload, simulationCost } from './../../../services/businessModels/index';
import { TProductInputs } from './../../../interfaces/businessModels/index';
import { InstallmentsEvents, TInstallments } from 'interfaces/businessModels/installments';
import { isDefined, isFieldDefined, isNumberDefined } from 'services/util/auxiliaryUtils';
import { StatusCodes } from 'http-status-codes';
import { getBusinessModelInfo } from 'api/businessModels';
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { useContext, useCallback, useEffect } from 'react';
import { IBusinessModel, IBusinessModelsContext } from 'interfaces/businessModels';
import { BusinessModelsActions } from 'interfaces/businessModels/reducer';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { ReduxContext } from 'contexts/redux/reduxContext';
import { getCompanyProfileIds } from 'services/user';
import { PRODUCT_IDS } from 'constants/products';
import { CANCELED_REQUEST } from 'constants/endpoints';

const useInstallmentsBM = (bm: IBusinessModel) => {
    const {
        client: { contacto_email: clientEmail, contacto_nome: clientName },
        product: { productInputs },
    } = useContext(ReduxContext);

    const { state, productID, productPayload, bmEvent, bmSelected, dispatch, setBMEventHandler, negotiationProfile } = useContext(
        BusinessModelsProContext
    ) as IBusinessModelsContext<TProductInputs, TInstallments>;

    const { companyProfileId } = useContext(UserContext);

    const { bmState, setBMEventHandler: syncSetBMEventHandler } = useContext(OfferEditionContext);

    const getEDPPTBaseCost = (duration: any = null) => {
        const annuity =
            {
                ...state.simulation?.offerEdition?.cost?.annuity,
                inverters_warranty_options: state.simulation?.offerEdition?.cost?.inverters_warranty_options,
            } ?? null;
        let otherTotal = 0;
        const newOffer = state.simulation.kpis?.offer_edition?.cost;
        const newDuration = isDefined(duration) ? duration : state.simulation.offerEdition?.duration;

        if (annuity) {
            otherTotal = Object.keys(annuity).reduce(
                (accumulator, currentProperty) => {
                    if (typeof annuity[currentProperty] === 'number') {
                        if (currentProperty !== 'maintenance')
                            return {
                                total:
                                    accumulator?.total -
                                    annuity?.[currentProperty] *
                                        (newDuration?.[currentProperty + '_years'].default -
                                            newDuration?.[currentProperty + '_years']?.included),
                            };
                        else if (currentProperty === 'maintenance') {
                            return {
                                total:
                                    accumulator?.total -
                                    annuity?.[currentProperty] *
                                        (newDuration?.[currentProperty + '_years']?.default -
                                            newDuration?.[currentProperty + '_years']?.included +
                                            1),
                            };
                        }
                    } else if (currentProperty === 'inverters_warranty_options' && isDefined(annuity?.[currentProperty])) {
                        return {
                            total:
                                accumulator?.total -
                                annuity?.[currentProperty]?.find(
                                    (option) => newDuration?.['inverters_warranty_years']?.default === option?.years_warranty
                                )?.cost,
                        };
                    }
                    return { total: accumulator?.total + 0 };
                },
                { total: state.simulation?.system?.costs?.total_costs }
            ).total;
            if (isNaN(otherTotal)) otherTotal = state.simulation?.system?.costs?.total_costs;

            return newOffer ?
                    Object.keys(newOffer)
                        .filter((attr) => !['base', 'base_service'].includes(attr) && typeof newOffer[attr] === 'number')
                        .reduce(
                            (accumulator, currentProperty) => {
                                return { total: accumulator?.total - newOffer?.[currentProperty] };
                            },
                            {
                                total: otherTotal,
                            }
                        ).total
                :   0;
        }
    };

    function getEDPPTTotalCost(duration: any = null) {
        const annuity =
            {
                ...state.simulation?.offerEdition?.cost?.annuity,
                inverters_warranty_options: state.simulation?.offerEdition?.cost?.inverters_warranty_options,
            } ?? null;
        let otherTotal = 0;
        const newOffer = state.simulation.kpis?.offer_edition?.cost;
        const newDuration = isDefined(duration) ? duration : state.simulation.offerEdition?.duration;

        const isReformulation = false;

        if (annuity) {
            otherTotal = Object.keys(annuity).reduce(
                (accumulator, currentProperty) => {
                    if (typeof annuity[currentProperty] === 'number') {
                        return {
                            total:
                                accumulator?.total +
                                annuity?.[currentProperty] *
                                    (newDuration?.[currentProperty + '_years']?.default -
                                        (!isReformulation ? newDuration?.[currentProperty + '_years']?.included : 0)),
                        };
                    } else if (currentProperty === 'inverters_warranty_options' && isDefined(annuity[currentProperty])) {
                        return {
                            total:
                                accumulator?.total +
                                annuity[currentProperty].find(
                                    (option) => newDuration?.['inverters_warranty_years']?.default === option?.years_warranty
                                )?.cost -
                                (!isReformulation ?
                                    annuity?.[currentProperty]?.find(
                                        (option) => newDuration?.['inverters_warranty_years']?.included === option?.years_warranty
                                    )?.cost
                                :   0),
                        };
                    }
                    return { total: accumulator?.total + 0 };
                },
                { total: 0 }
            ).total;
        }

        if (isNaN(otherTotal)) otherTotal = 0;

        return newOffer ?
                Object.keys(newOffer)
                    .filter((attr) => attr !== 'base_service' && typeof newOffer[attr] === 'number')
                    .reduce(
                        (accumulator, currentProperty) => {
                            return { total: accumulator?.total + newOffer?.[currentProperty] };
                        },
                        { total: 0 }
                    ).total + otherTotal
            :   0;
    }

    const installmentsBM = async () => {
        try {
            const payload = {
                businessModelId: state.selected.id,
                productID,
                body: {
                    ...fetchBMPayload(productID, productPayload, { companyProfileId }),
                },
            };
            const rspBM = await getBusinessModelInfo(payload);

            if (!isDefined(rspBM?.status)) throw new Error(CANCELED_REQUEST);
            if (rspBM?.status === StatusCodes.OK) {
                let baseCost = simulationCost(productID, state?.simulation);
                let totalCost = state?.simulation?.system.costs?.total_costs;

                if (
                    // FIXME: HAMMER TIME!
                    [getCompanyProfileIds().EDP_PT].includes(companyProfileId) &&
                    [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB].includes(productID)
                ) {
                    baseCost = getEDPPTBaseCost();
                    totalCost = getEDPPTTotalCost();
                }

                const { kpis_per_margin } = rspBM.data.data[0].kpis_previews[0];
                const kpisPerMarginSelected =
                    kpis_per_margin.length > 0 ? kpis_per_margin.find((el) => el?.is_default) : bm?.kpis_previews[0].kpis_per_margin[0];
                const kpis: TInstallmentsValues = {
                    ...rspBM.data.data[0]?.kpis_previews[0],
                    baseCost,
                    totalCost,
                    duration: state.simulation.offerEdition?.duration ?? null,
                    costs: {
                        baseCost,
                        offerDetailsCost: totalCost,
                        marginCost: kpisPerMarginSelected?.investment ?? kpisPerMarginSelected?.price,
                        totalCost,
                    },
                };
                setBMEventHandler(InstallmentsEvents.SET_INSTALLMENTS_BM, kpis);
            }
        } catch (error: any) {
            if (error?.message !== CANCELED_REQUEST) {
                console.error(error);
            }
        }
    };

    const installmentsEvents = useCallback(() => {
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.INSTALLMENTS && isDefined(bmEvent?.name)) {
            const currentValues = state.selected.values;
            const syncKpis = productPayload?.productInputs?.syncKpis?.find((bm) => bm.tipo_modelo_negocio_id === state.selected.id);

            switch (bmEvent?.name) {
                case InstallmentsEvents.FETCH_INSTALLMENTS_BM:
                    installmentsBM();
                    break;
                case InstallmentsEvents.SET_INSTALLMENTS_BM: {
                    let clientContactInfo;
                    if (negotiationProfile?.mostra_formulario && state?.selected?.options?.has_form) {
                        clientContactInfo =
                            (
                                !isDefined(productInputs?.SIMPLES_Dimensionamento?.inputs?.contact_info) &&
                                !isDefined(productInputs?.SIMPLES_Dimensionamento?.inputs?.contact_info?.name)
                            ) ?
                                {
                                    name: clientName,
                                    email: clientEmail,
                                }
                            :   productInputs?.SIMPLES_Dimensionamento?.inputs?.contact_info;
                    }

                    if (clientContactInfo) {
                        bmEvent.payload = {
                            ...bmEvent.payload,
                            clientContactInfo,
                        };
                    }

                    if (isDefined(syncKpis)) {
                        const show_capex = syncKpis?.kpis?.system?.configs?.show_capex ?? false;
                        const businessModelSelected = state.businessModels.find(
                            (bm) => bm.payment_model_id === state.selected.paymentModelID
                        );

                        const rubrics = state?.simulation?.rubricsCosts.find(
                            (rubric) => rubric.isSync && state.selected.id === rubric.tipo_modelo_negocio_id
                        );
                        const margin =
                            state.selected.values?.margin_negotiation?.default ??
                            state.businessModels[0].kpis_previews[0].kpis_per_margin.find((kpi) => kpi.is_default)?.margin ??
                            0;

                        const base_total_costs =
                            show_capex ? syncKpis?.kpis?.system?.costs?.capex : syncKpis?.kpis?.system?.costs?.total_costs;
                        const baseCost =
                            rubrics?.rubrics.length > 0 ?
                                base_total_costs - rubrics?.defaultRubricCost
                            :   simulationCost(productID, syncKpis, show_capex);
                        const extraCost = state?.simulation?.offerEdition?.cost?.extra ?? 0;
                        const rubricsCost = rubrics?.defaultRubricCost ?? 0;

                        const offerDetailsCost = baseCost + extraCost + rubricsCost;

                        const totalCost = offerDetailsCost;

                        syncSetBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_SELECTED, {
                            businessModelSelected: businessModelSelected,
                            businessModelBody: productPayload,
                            kpis: syncKpis,
                            offerEdition: {
                                ...productPayload?.productInputs?.offerEdition,
                                system: productPayload?.productInputs?.system,
                                is_customize: productPayload?.productInputs?.inputs?.is_customize ?? false,
                            },
                            elements: {
                                monthlyPayments:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'monthly_payment_option'
                                    ) ?? null,
                                omContractDuration:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'om_contract_duration'
                                    ) ?? null,
                                annualUpdateMonthlyFee:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'tariff_annual_update'
                                    ) ?? null,
                                uniqueAllowance:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'unique_allowance'
                                    ) ?? null,
                                yearlyAllowance:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'yearly_allowance'
                                    ) ?? null,
                            },
                        });

                        dispatch({
                            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                            payload: {
                                ...bmEvent.payload,
                                syncKpis: syncKpis,
                                clientContactInfo,
                                costs: {
                                    ...currentValues?.costs,
                                    offerDetailsCost,
                                    baseCost,
                                    marginCost:
                                        bmSelected.isDiscount ?
                                            offerDetailsCost - offerDetailsCost * (margin ?? 0)
                                        :   offerDetailsCost / (1 - margin),
                                    grantsCost: 0,
                                    totalCost,
                                },
                                grants: {
                                    hasGrants: state.selected.options?.has_subsidy ?? false,
                                    totalGrants: {
                                        currency: 0,
                                        prc: 0,
                                    },
                                    grants: [
                                        {
                                            id: 1,
                                            description: '',
                                            ammounts: {
                                                prc: 0,
                                                currency: 0,
                                            },
                                        },
                                    ],
                                },
                                rubrics: {
                                    values: rubrics?.rubrics ?? [],
                                    cost: rubrics?.defaultRubricCost ?? 0,
                                },
                            },
                        });
                    } else {
                        const rubrics = state?.simulation?.rubricsCosts.find((rubric) => !rubric.isSync) ?? [];
                        dispatch({
                            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                            payload: {
                                ...bmEvent.payload,
                                rubrics: {
                                    ...bmEvent.payload?.rubrics,
                                    rubrics: rubrics.rubrics,
                                },
                            },
                        });
                    }
                    break;
                }
                case InstallmentsEvents.SET_INSTALLMENTS_EXTRA_COST: {
                    const newOfferDetailsCost =
                        currentValues?.costs?.baseCost + bmEvent.payload.extra_cost + state.selected.values?.rubrics?.cost;

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            offerEdition: {
                                ...currentValues.offerEdition,
                                ...bmEvent.payload,
                            },
                            costs: {
                                ...currentValues.costs,
                                offerDetailsCost: newOfferDetailsCost,
                                totalCost: newOfferDetailsCost,
                            },
                        },
                    });
                    break;
                }
                case InstallmentsEvents.SET_INSTALLMENTS_RUBRIC_COST: {
                    const show_capex = syncKpis.kpis.system?.configs.show_capex;
                    const base_total_costs = show_capex ? syncKpis.kpis.system?.costs.capex : syncKpis.kpis.system?.costs.total_costs;

                    const newRubrics = state.selected.values.rubrics.values.map((rubric) => {
                        if (rubric.cost_id === bmEvent.payload.id) {
                            rubric.included_cost = bmEvent.payload.selected;
                        }
                        return rubric;
                    });

                    const rubricsCost = newRubrics?.reduce((acc, curr) => {
                        if (curr?.included_cost) return acc + parseInt(curr?.final_cost);
                        else return acc;
                    }, 0);

                    let newOfferDetailsCost = 0;
                    newOfferDetailsCost = base_total_costs + (state.selected.values?.offerEdition?.extra_cost ?? 0) + rubricsCost;

                    const costs = syncKpis.kpis.system?.costs;
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            rubrics: {
                                values: newRubrics,
                                cost: rubricsCost,
                            },
                            offerEdition: {
                                ...currentValues.offerEdition,
                                costs: computeRubricCostsPayload(costs, newRubrics),
                            },
                            costs: {
                                ...currentValues.costs,
                                offerDetailsCost: newOfferDetailsCost,
                                marginCost: newOfferDetailsCost,
                                totalCost: newOfferDetailsCost,
                            },
                            proposalReady: false,
                        },
                    });

                    break;
                }
                case InstallmentsEvents.SET_INSTALLMENTS_MARGIN: {
                    if (!isFieldDefined(currentValues)) break;

                    const search = bmSelected?.values?.margin_negotiation?.search ?? 'margin';
                    const kpisPerMarginSelected = bmSelected?.values?.kpis_per_margin?.find((el) => el?.[search] === bmEvent.payload);

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            marginSelected: bmEvent.payload,
                            costs: {
                                ...currentValues.costs,
                                marginCost: kpisPerMarginSelected?.investment ?? kpisPerMarginSelected?.price ?? bm,
                            },
                        },
                    });
                    break;
                }

                case InstallmentsEvents.SET_INSTALLMENTS_PROPOSAL_READY:
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: { kpis_per_margin: [], proposalReady: bmEvent.payload },
                    });
                    break;
                case InstallmentsEvents.SET_INSTALLMENTS_PROPOSAL: {
                    const marginNegotiationSearch = bmSelected?.values?.margin_negotiation?.search ?? 'margin';
                    const marginSelected = bmSelected?.values?.kpis_per_margin?.find(
                        (kpi) => kpi?.[marginNegotiationSearch] === bmSelected?.values?.marginSelected
                    )?.margin;

                    const payload = {
                        ...state.simulation,
                        inputs: {
                            ...state.simulation.inputs,
                            margin: marginSelected ?? undefined,
                            offer_edition: {
                                ...state.selected.values?.offerEdition,
                            },
                            contact_info: state.selected.values?.clientContactInfo,
                            id_crm: state.selected.values?.id_crm,
                        },
                        tipo_modelo_negocio_id: state.selected.id,
                    };

                    if (isDefined(syncKpis)) {
                        if (state.selected.values.rubrics.values.length === 0)
                            payload.inputs.offer_edition.costs = syncKpis.kpis.system?.costs;
                        payload.inputs = {
                            ...payload.inputs,
                            offer_edition: {
                                ...payload.inputs.offer_edition,
                                extra_cost:
                                    isNumberDefined(bmState.offerEditionValues?.extraCost) ?
                                        parseFloat(`${bmState.offerEditionValues?.extraCost}`)
                                    :   0,
                            },
                            annual_evo_fee:
                                isFieldDefined(parseFloat(`${bmState.offerEditionValues?.annualUpdateFee}`)) ?
                                    parseFloat(`${bmState.offerEditionValues?.annualUpdateFee}`) / 100
                                :   payload.inputs?.annual_evo_fee ?? undefined,
                            monthly_fee:
                                isFieldDefined(parseFloat(`${bmState.offerEditionValues?.monthlyPayment}`)) ?
                                    parseFloat(`${bmState.offerEditionValues?.monthlyPayment}`)
                                :   payload.inputs?.monthly_fee ?? undefined,
                            monthly_payment_option:
                                isFieldDefined(parseFloat(`${bmState.offerEditionValues?.monthly_payment_option_id}`)) ?
                                    parseInt(`${bmState.offerEditionValues?.monthly_payment_option_id}`)
                                :   payload.inputs?.monthly_payment_option_id ?? undefined,
                            op_and_man_duration:
                                isFieldDefined(parseFloat(`${bmState.offerEditionValues?.opAndManDuration}`)) ?
                                    parseInt(`${bmState.offerEditionValues?.opAndManDuration}`)
                                :   payload.inputs?.opAndManDuration ?? undefined,
                            fin_kpis:
                                isFieldDefined(bmState?.kpis?.finalKpis) ? bmState.kpis?.finalKpis : payload.inputs?.fin_kpis ?? undefined,
                            negotiation:
                                isFieldDefined(bmState?.kpis?.negotiation) ? bmState.kpis?.negotiation
                                : isFieldDefined(syncKpis?.negotiation) ? syncKpis?.negotiation
                                : payload.inputs?.negotiation ?? undefined,
                            isChangeBattery:
                                isFieldDefined(bmState?.businessModelBody?.inputs?.isChangeBattery) ?
                                    bmState?.businessModelBody?.inputs?.isChangeBattery
                                :   payload?.inputs?.isChangeBattery ?? undefined,
                            service_energy_price: 0, // TODO: FIXME: não consigo encontrar este valor, mas parece-me ir sempre a 0 (default)
                        };

                        const reqIDSet = new Set<string>();
                        syncKpis.req_id_arr?.forEach(reqIDSet.add, reqIDSet);
                        bmState?.reqsHash?.forEach(reqIDSet.add, reqIDSet);
                        const reqIDArr = Array.from(reqIDSet);
                        payload.req_id_arr = reqIDArr ?? [];
                    }

                    dispatch({ type: BusinessModelsActions.SET_PROPOSAL, payload });

                    break;
                }
                case InstallmentsEvents.UPDATE_INSTALLMENTS_ADDITIONAL_DATA:
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            clientContactInfo: {
                                ...currentValues?.clientContactInfo,
                                ...bmEvent.payload?.clientContactInfo,
                            },
                            id_crm: bmEvent.payload?.id_crm ? bmEvent.payload?.id_crm : currentValues?.id_crm ?? null,
                        },
                    });
                    break;
            }
        }
    }, [bmEvent]); // eslint-disable-line

    useEffect(() => {
        installmentsEvents();
    }, [installmentsEvents]);

    useEffect(() => {
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.INSTALLMENTS) {
            const syncKpis = productPayload?.productInputs?.syncKpis?.find((bm) => bm.tipo_modelo_negocio_id === state.selected.id);

            if (isDefined(syncKpis)) {
                // detailed
                setBMEventHandler(InstallmentsEvents.SET_INSTALLMENTS_BM, null);
            } else {
                // simple
                setBMEventHandler(InstallmentsEvents.FETCH_INSTALLMENTS_BM, null);
            }
        }
    }, [state.selected.paymentModelID]); // eslint-disable-line

    return {
        pitch: bm.pitch,
        projectSummary: bm.kpis_previews,
        selected: bm.selected,
        id: bm.business_model_id,
        paymentModelID: bm.payment_model_id,
        sizingTypeId: bm.sizing_type_id,
        syncKpis: productPayload?.productInputs?.syncKpis?.find((sincBm) => sincBm.tipo_modelo_negocio_id === bm.business_model_id),
    };
};

export default useInstallmentsBM;
