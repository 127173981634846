import { ReactComponent as SolutionIcon } from 'assets/@efz/icons/solutions.svg';
import { ReactComponent as ProcessesIcon } from 'assets/@efz/icons/processes.svg';
import { ReactComponent as BackofficeIcon } from 'assets/@efz/icons/backoffice.svg';
import { BASENAME_URL_BACKOFFICE, BASENAME_URL_PROCESSES, BASENAME_URL_SOLUTIONS } from './settings';

export const SIDEBAR_BUTTONS = {
    OPEN_SIDEBAR: 'openSidebar',
    SOLUTIONS: 'solutions',
    PROCESSES: 'processes',
    BACKOFFICE: 'backoffice',
};

export const SIDEBAR_BUTTONS_CONFIG = {
    // [getCompanyProfileIds().EFZ]:
    //     [
    //         {
    //             name: SIDEBAR_BUTTONS.SOLUTIONS,
    //             label: 'sidebar.solutions',
    //             icon: <SolutionIcon />,
    //             disabled: function (hasSolutionsButton = false) {
    //                 return false
    //             },
    //             navigationLabel: BASENAME_URL_SOLUTIONS,
    //             visible: true
    //         },
    //         {
    //             name: SIDEBAR_BUTTONS.PROCESSES,
    //             label: 'sidebar.processes',
    //             icon: <ProcessesIcon />,
    //             disabled: function (hasSolutionsButton = false) {
    //                 return false
    //             },
    //             navigationLabel: BASENAME_URL_PROCESSES,
    //             visible: true
    //         },
    //         {
    //             name: SIDEBAR_BUTTONS.BACKOFFICE,
    //             label: 'sidebar.backoffice',
    //             icon: <BackofficeIcon />,
    //             disabled: function (hasSolutionsButton = false) {
    //                 return false
    //             },
    //             navigationLabel: BASENAME_URL_BACKOFFICE,
    //             visible: function (bdFlag) {
    //                 return bdFlag
    //             },
    //         },
    //     ],
    DEFAULT: [
        {
            name: SIDEBAR_BUTTONS.SOLUTIONS,
            label: 'sidebar.solutions',
            icon: <SolutionIcon />,
            disabled: function () {
                return false;
            },
            navigationLabel: BASENAME_URL_SOLUTIONS,
            visible: true,
            canBeAccessed: () => true,
        },
        {
            name: SIDEBAR_BUTTONS.PROCESSES,
            label: 'sidebar.processes',
            icon: <ProcessesIcon />,
            disabled: function () {
                return false;
            },
            navigationLabel: BASENAME_URL_PROCESSES,
            visible: true,
            canBeAccessed: () => true,
        },
        {
            name: SIDEBAR_BUTTONS.BACKOFFICE,
            label: 'sidebar.backoffice',
            icon: <BackofficeIcon />,
            disabled: function (hasSolutionsButton = false) {
                return !hasSolutionsButton;
            },
            navigationLabel: BASENAME_URL_BACKOFFICE,
            visible: function (bdFlag) {
                return bdFlag;
            },
            canBeAccessed: (flag) => flag,
        },
    ],
};
