import { useContext } from 'react';

// Components
import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import ServicePPaMargin from './Margin';
import ServicePPaMarginKpis from './MarginKpis';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPPa } from 'interfaces/businessModels/ppa';

// Services
import { isDefined } from 'services/util/auxiliaryUtils';

const CommercialMarginServicePPa = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;
    return (
        <div className="bm-project-summary-card bm-project-summary-commercial-margin">
            <ProjectSummaryTitle label={'page.businessModels.negotiation.title.tariff'} />
            <ServicePPaMargin />
            {isDefined(bmSelected?.values?.marginSelected) && <ServicePPaMarginKpis />}
        </div>
    );
};

export default CommercialMarginServicePPa;
