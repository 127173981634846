import { memo } from 'react';
//Libraries
import PropTypes from 'prop-types';
import MUIAlert from '@mui/material/Alert';
import IntlMessages from 'components/util/IntlMessages';

// icons
import { ReactComponent as SuccessIcon } from 'assets/@efz/icons/success.svg';
import { ReactComponent as WarningIcon } from 'assets/@efz/icons/warning.svg';
import { ReactComponent as ErrorIcon } from 'assets/@efz/icons/error.svg';

const Alert = ({ severity, className, children, icon }) => (
    <MUIAlert
        severity={severity}
        className={`efz-alert-${severity} ${className}`}
        icon={icon}
        iconMapping={{
            success: <SuccessIcon />,
            warning: <WarningIcon />,
            error: <ErrorIcon />,
        }}
    >
        {children}
    </MUIAlert>
);

//defaultProps
Alert.defaultProps = {
    severity: 'warning',
    children: <IntlMessages id="efz.text.loremIpsum" />,
    className: '',
    icon: undefined,
};

// PropTypes
Alert.propTypes = {
    severity: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    className: PropTypes.string.isRequired,
    icon: PropTypes.any,
};

export default memo(Alert);
