import { DISPATCH_EVT } from 'constants/facilityPro/main';
import {
    calcWeightsSumAndMonthlyConsumption,
    getCalcWeightsConsumption,
    getFormWeightsConsumption,
    getLoadDataB2B,
    getLoadDataB2C,
    getOptions,
    getPayloadLoadProfileB2B,
    getPayloadLoadProfileB2C,
    getQuestionsLoadDataB2B,
    getQuestionsLoadDataB2C,
    initialLProfileInputsB2B,
    initialLProfileInputsB2C,
} from 'services/facilityPro/loadProfileData';
import clone from 'fast-copy';
import {
    CHART_CONSUMPTION_DAILY_DEFAULTS,
    CHART_CONSUMPTION_MONTHLY_DEFAULTS,
    INPUT_NAMES_LPDATA,
    OPTS_LOAD_CURVE_B2B,
    SIMPLE_ESTIMATION_IDS,
} from 'constants/facilityPro/loadProfileData';
import { convertToUnit, intlMessages, isFieldDefined, isNumberDefined } from 'services/util/auxiliaryUtils';
import { OPTIONS_LOAD_CURVE } from 'constants/facility';
import { getCompanyProfileIds } from 'services/user';
import { USERS_TYPE_ID } from 'constants/user';

export const initialLoadProfileDataReducer = clone({
    dispatchLoadProfileData: (_type = '', _payload = {}) => {},
    facilityId: null,
    companyProfileId: null,
    clientId: null,
    clientCAE5: null,
    isMounting: true,
    isVisible: false,
    isAddFacility: false,
    isB2C: false,
    questions: {},
    //#region inputs
    ...initialLProfileInputsB2C(),
    ...initialLProfileInputsB2B(),
    //#endregion inputs

    //#region options
    weekendInHouse: [],
    workDaysOccupationPeriod: [],
    firstArrivals: [],
    consumptionWeightsOpts: [],
    //#endregion options
    //#region Requestings
    isRequestingCGDaily: true,
    isRequestingCGMonthly: true,
    isRequestingCWeights: false,
    isDisabledCWeigths: false,
    isRequestingACode: false,
    isLoadingConsumoAnual: false,
    isRequestingCurveDownload: false,
    isRequestingCurveUpload: false,
    //#endregion Requestings
    //#region  Graphs
    rspConsumptionsDaily: {},
    consumptionsGraphDaily: clone(CHART_CONSUMPTION_DAILY_DEFAULTS),
    rspConsumptionsMonthly: {},
    consumptionsGraphMonthly: clone(CHART_CONSUMPTION_MONTHLY_DEFAULTS),
    validCurveUploaded: null,
    unit: 'mwh',
    isMWh: true,
    //#endregion
});

export function loadProfileDataReducer(state = initialLoadProfileDataReducer, action) {
    let { isAddFacility, facilityId } = state;

    switch (action.type) {
        case DISPATCH_EVT.SET_LOAD_DATA: {
            let { payload } = action;
            let { inputs, options, responses } = payload;
            let { isB2C, isAddFacility, companyProfileId, clientCAE5, clientId, userTypeID, is_efz, isDemo, configs } = options;
            // show and cal unit
            let unit = [getCompanyProfileIds().ROMANDE].includes(companyProfileId) ? 'kwh' : 'mwh';
            let isMWh = unit === 'mwh';

            let consumo_anual =
                isMWh ?
                    convertToUnit(parseFloat(inputs?.[INPUT_NAMES_LPDATA.CONSUMO_ANUAL]), 'fromKwToMw')
                :   parseFloat(inputs?.[INPUT_NAMES_LPDATA.CONSUMO_ANUAL]);

            let { equipmentsOpts, weekendInHouse, workDaysOccupationPeriod, firstArrivals, consumptionWeightsOpts } = getOptions(responses);

            //consumptionsWeights
            if (!isB2C && !isAddFacility) {
                consumptionWeightsOpts = calcWeightsSumAndMonthlyConsumption(consumptionWeightsOpts, {
                    unit,
                    consumo_anual,
                });
            }

            let newInputs =
                isB2C ?
                    getLoadDataB2C(inputs, {
                        equipmentsOpts,
                        isAddFacility,
                        companyProfileId,
                        configs: configs?.b2c ?? {},
                    })
                :   getLoadDataB2B(clone(inputs), {
                        isMWh,
                        equipmentsOpts,
                        isAddFacility,
                        companyProfileId,
                        clientCAE5,
                        consumptionWeightsOpts,
                        configs: configs?.b2b ?? {},
                    });

            let { questions } =
                isB2C ?
                    getQuestionsLoadDataB2C({ inputs, isAddFacility, companyProfileId })
                :   getQuestionsLoadDataB2B({
                        inputs,
                        isAddFacility,
                        companyProfileId,
                        userTypeID,
                        is_efz,
                        isDemo,
                    });

            let consumptionsGraphMonthly = clone(CHART_CONSUMPTION_MONTHLY_DEFAULTS);
            consumptionsGraphMonthly = {
                ...consumptionsGraphMonthly,
                data: {
                    ...consumptionsGraphMonthly.data,
                    datasets: consumptionsGraphMonthly?.data?.datasets?.map((el) => {
                        return {
                            ...el,
                            keyword: isMWh ? 'label.consumptionMWh' : 'label.consumptionkWh',
                            label: intlMessages(isMWh ? 'label.consumptionMWh' : 'label.consumptionkWh'),
                        };
                    }),
                },
                options: {
                    ...consumptionsGraphMonthly.options,
                    scales: {
                        ...consumptionsGraphMonthly.options.scales,
                        xAxes: consumptionsGraphMonthly?.options?.scales?.xAxes?.map((el) => {
                            return {
                                ...el,
                                scaleLabel: {
                                    ...el.scaleLabel,
                                    keyword: isMWh ? 'label.consumptionMWh' : 'label.consumptionkWh',
                                    labelString: intlMessages(isMWh ? 'label.consumptionMWh' : 'label.consumptionkWh'),
                                },
                            };
                        }),
                    },
                },
            };

            return {
                ...state,
                ...newInputs,
                companyProfileId,
                isMWh,
                unit,
                facilityId: !isAddFacility ? inputs.id : null,
                clientId: !isAddFacility ? clientId : null,
                clientCAE5,
                questions,
                isAddFacility,
                isB2C,
                // Graphs
                rspConsumptionsDaily: {},
                consumptionsGraphDaily: clone(CHART_CONSUMPTION_DAILY_DEFAULTS),
                rspConsumptionsMonthly: {},
                consumptionsGraphMonthly,
                //options
                equipmentsOpts,
                weekendInHouse,
                workDaysOccupationPeriod,
                firstArrivals,
                consumptionWeightsOpts /* B2B */,
                validCurveUploaded: isB2C || isAddFacility,
                isDisabledCWeigths:
                    [getCompanyProfileIds().EDP_PT].includes(companyProfileId) && [USERS_TYPE_ID.CHANNEL].includes(userTypeID),
            };
        }
        case DISPATCH_EVT.SET_OPCAO_CURVA_CARGA_ID_B2B: {
            let { isMWh, nome_ficheiro_curva_carga } = state;
            let { payload } = action;
            let isLoadingConsumoAnual = false;
            let {
                input,
                options,
                formData: { opcao_curva_carga_id, simple_estimation_id, activity_code },
            } = payload;
            let { handleUpdateLoadProfileData: handleUpdateLoadProfileDataB2B, setValue } = options;
            let newInputs = null;
            let requestWeights = false;
            let { name, value } = input;
            newInputs = {
                [name]: value,
            };
            opcao_curva_carga_id = value;
            requestWeights = [OPTIONS_LOAD_CURVE.INTEGRATION_DATA, OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION].includes(parseInt(value));

            if (!isNumberDefined(newInputs?.[INPUT_NAMES_LPDATA.CONSUMO_ANUAL])) {
                newInputs = {
                    ...newInputs,
                    [INPUT_NAMES_LPDATA.CONSUMO_ANUAL]: state.consumo_anual,
                };
            }

            //fetched PUT facility
            if (!isAddFacility && isFieldDefined(newInputs)) {
                if (
                    parseInt(opcao_curva_carga_id) !== OPTIONS_LOAD_CURVE.UPLOAD_CURVE ||
                    (parseInt(opcao_curva_carga_id) === OPTIONS_LOAD_CURVE.UPLOAD_CURVE && isFieldDefined(nome_ficheiro_curva_carga))
                ) {
                    handleUpdateLoadProfileDataB2B(getPayloadLoadProfileB2B(facilityId, { ...newInputs, isMWh }), {
                        opcao_curva_carga_id,
                        simple_estimation_id,
                        activity_code,
                        requestWeights,
                        setValue,
                    });
                }
            }

            return {
                ...state,
                ...newInputs,
                isLoadingConsumoAnual,
            };
        }
        case DISPATCH_EVT.SET_CONSUMO_ANUAL_B2B: {
            let { isMWh, unit } = state;
            let { payload } = action;
            let {
                input: { name, value },
                options,
                formData: { opcao_curva_carga_id, simple_estimation_id, activity_code, consumption_weights },
            } = payload;
            let { handleUpdateLoadProfileData: handleUpdateLoadProfileDataB2B, trigger, setValue } = options;
            let newInputs = null;
            //validate CONSUMO_ANUAL >0
            if (!isNumberDefined(value)) {
                return {
                    ...state,
                };
            }

            newInputs = {
                [name]: parseFloat(value),
            };

            //update monthly distribuition
            if (simple_estimation_id === SIMPLE_ESTIMATION_IDS.CUSTOM) {
                let { consumptionWeightsOpts } = state;
                let newConsumptionWeightsOpts = calcWeightsSumAndMonthlyConsumption(consumptionWeightsOpts, {
                    unit,
                    consumo_anual: newInputs?.[INPUT_NAMES_LPDATA.CONSUMO_ANUAL],
                });
                newConsumptionWeightsOpts.forEach((el) => {
                    consumption_weights[el.inputName] = isNumberDefined(el.monthly_consumption) ? el.monthly_consumption.toFixed(2) : '';
                });
                setValue('consumption_weights', consumption_weights);
                trigger('consumption_weights');
            }

            //TODO: atualizar os valores no form
            //fetched PUT facility
            if (!isAddFacility && isFieldDefined(newInputs)) {
                handleUpdateLoadProfileDataB2B(getPayloadLoadProfileB2B(state.facilityId, { ...newInputs, isMWh }), {
                    opcao_curva_carga_id,
                    simple_estimation_id,
                    activity_code,
                    requestWeights:
                        parseInt(opcao_curva_carga_id) === OPTS_LOAD_CURVE_B2B.INTEGRATION_DATA ||
                        (parseInt(opcao_curva_carga_id) === OPTS_LOAD_CURVE_B2B.SIMPLE_ESTIMATION &&
                            [SIMPLE_ESTIMATION_IDS.ACTIVITY_CODE, SIMPLE_ESTIMATION_IDS.CUSTOM].includes(parseInt(simple_estimation_id))),
                    setValue,
                });
            }
            return {
                ...state,
                ...newInputs,
            };
        }
        case DISPATCH_EVT.SET_SIMPLE_ESTIMATION_ID_B2B: {
            let { isMWh } = state;
            let { payload } = action;
            let {
                input: { name },
                options,
                formData: { opcao_curva_carga_id, simple_estimation_id, activity_code, id, rules },
            } = payload;
            let { handleUpdateLoadProfileData: handleUpdateLoadProfileDataB2B, setValue } = options;
            let newInputs = null;
            let requestWeights = false;
            newInputs = {
                [name]: id,
                ...rules,
                consumo_anual: state.consumo_anual,
            };
            simple_estimation_id = id;
            requestWeights = [SIMPLE_ESTIMATION_IDS.ACTIVITY_CODE, SIMPLE_ESTIMATION_IDS.CUSTOM].includes(parseInt(id));

            //fetched PUT facility
            if (!isAddFacility && isFieldDefined(newInputs)) {
                handleUpdateLoadProfileDataB2B(getPayloadLoadProfileB2B(state.facilityId, { ...newInputs, isMWh }), {
                    opcao_curva_carga_id,
                    simple_estimation_id,
                    activity_code,
                    requestWeights,
                    setValue,
                });
            }
            return {
                ...state,
                ...newInputs,
            };
        }
        case DISPATCH_EVT.SET_TEM_DADOS_PONDERADOR_INTEGRACAO_PERSONALIZADA_B2B: {
            let { isMWh } = state;
            let { payload } = action;
            let {
                input: { name, value },
                options,
                formData: { opcao_curva_carga_id, opcao_tarifario_id, nivel_tensao_id },
            } = payload;
            let { handleUpdateLoadProfileData: handleUpdateLoadProfileDataB2B, setValue } = options;
            let newInputs = null;
            newInputs = {
                [name]: value,
                consumo_anual: state.consumo_anual,
                opcao_curva_carga_id,
                opcao_tarifario_id,
                nivel_tensao_id,
            };

            //fetched PUT facility
            if (!isAddFacility && isFieldDefined(newInputs)) {
                handleUpdateLoadProfileDataB2B(getPayloadLoadProfileB2B(state.facilityId, { ...newInputs, isMWh }), {
                    opcao_curva_carga_id,
                    requestWeights: true,
                    setValue,
                });
            }
            return {
                ...state,
                ...newInputs,
            };
        }
        case DISPATCH_EVT.SET_ACTIVITY_CODE_B2B: {
            let { isMWh } = state;
            let { payload } = action;
            let {
                input,
                options: { handleUpdateLoadProfileData: handleUpdateLoadProfileDataB2B, setValue },
                formData: { opcao_curva_carga_id, simple_estimation_id },
            } = payload;
            let newInputs = null;
            let { name, value } = input;

            newInputs = {
                [name]: value,
            };

            if (!isAddFacility && isFieldDefined(newInputs)) {
                handleUpdateLoadProfileDataB2B(
                    getPayloadLoadProfileB2B(state.facilityId, {
                        consumo_anual: parseFloat(state.consumo_anual),
                        isMWh,
                    }),
                    {
                        activity_code: value,
                        hasUpdateClient: true,
                        opcao_curva_carga_id,
                        simple_estimation_id,
                        requestWeights: true,
                        setValue,
                    }
                );
            }
            return {
                ...state,
                ...newInputs,
                isRequestACode: true,
            };
        }
        case DISPATCH_EVT.SET_WORKING_SCHEDULE_B2B: {
            let { payload } = action;

            let {
                input: { name, value },
                options: { opcao_curva_carga_id, handleUpdateLoadProfileData: handleUpdateLoadProfileDataB2B },
            } = payload;

            let newInputs = {
                [name]: value,
            };

            //fetched PUT facility
            if (!isAddFacility && isFieldDefined(newInputs)) {
                handleUpdateLoadProfileDataB2B(getPayloadLoadProfileB2B(state.facilityId, newInputs), {
                    requestWeights: false,
                    opcao_curva_carga_id,
                });
            }
            return {
                ...state,
                ...newInputs,
            };
        }
        case DISPATCH_EVT.NEW_CONSUMPTION_WEIGHTS_B2B: {
            let { isMWh, unit } = state;
            let {
                payload: {
                    consumo_anual, //kWh
                    consumptionWeightsOpts,
                    setValue,
                },
            } = action;

            consumptionWeightsOpts = calcWeightsSumAndMonthlyConsumption(consumptionWeightsOpts, {
                unit,
                consumo_anual:
                    isMWh ?
                        convertToUnit(consumo_anual, 'fromKwToMw') //enviamos a API o consumo_anual vem em kWh
                    :   consumo_anual,
            });
            let consumption_weights = getFormWeightsConsumption(consumptionWeightsOpts);
            //update Form consumption_weights
            setValue('consumption_weights', consumption_weights);
            // update state
            return {
                ...state,
                consumptionWeightsOpts,
                isRequestingCWeights: false,
                isRequestingACode: false,
            };
        }
        case DISPATCH_EVT.SET_CONSUMPTION_WEIGHTS_B2B: {
            let { consumptionWeightsOpts, isMWh } = state;
            let { payload } = action;
            let {
                input,
                options,
                formData: { opcao_curva_carga_id },
            } = payload;
            let { handleUpdateLoadProfileData: handleUpdateLoadProfileDataB2B } = options;
            let newInputs = null;
            let newConsumptionWeights = [];

            let { setValue, trigger } = options;
            let consumption_weights = input.value;
            let { consumo_anual, newConsumptionWeightsOpts } = getCalcWeightsConsumption(consumption_weights, consumptionWeightsOpts);

            if (!isNumberDefined(consumo_anual) || consumo_anual <= 0) return state;

            newConsumptionWeights = newConsumptionWeightsOpts;

            setValue('consumo_anual', consumo_anual.toFixed(2));
            trigger('consumo_anual');
            newInputs = {
                [INPUT_NAMES_LPDATA.CONSUMO_ANUAL]: parseFloat(consumo_anual),
            };

            if (!isAddFacility && isFieldDefined(newInputs)) {
                handleUpdateLoadProfileDataB2B(getPayloadLoadProfileB2B(state.facilityId, { ...newInputs, isMWh }), {
                    opcao_curva_carga_id,
                    newConsumptionWeights,
                });
            }

            // update state
            return {
                ...state,
                ...newInputs,
                consumptionWeightsOpts: newConsumptionWeights,
            };
        }
        case DISPATCH_EVT.SET_IS_REQUESTINGCWEIGHTS_B2B: {
            let { payload } = action;

            // update state
            return {
                ...state,
                isRequestingCWeights: payload,
            };
        }
        case DISPATCH_EVT.SET_IS_LOADING_CONSUMO_ANUAL_B2B: {
            let { payload } = action;
            let { isRequestingCGDaily, isRequestingCGMonthly } = state;

            // is true?
            if (payload) {
                isRequestingCGDaily = payload;
                isRequestingCGMonthly = payload;
            }
            return {
                ...state,
                isLoadingConsumoAnual: payload,
                isRequestingCGDaily,
                isRequestingCGMonthly,
            };
        }
        case DISPATCH_EVT.SET_CURVE_LOAD_DATA_B2B: {
            let { payload } = action;
            let { consumo_anual, nome_ficheiro_curva_carga } = payload;

            return {
                ...state,
                consumo_anual,
                nome_ficheiro_curva_carga,
            };
        }
        case DISPATCH_EVT.SET_IS_REQUESTING_GRAPHS: {
            let { payload } = action;
            // update state
            return {
                ...state,
                isRequestingCGDaily: payload,
                isRequestingCGMonthly: payload,
            };
        }
        case DISPATCH_EVT.SET_REQUESTING_CURVE_LOAD: {
            return {
                ...state,
                isRequestingCurveDownload: action.payload,
            };
        }
        case DISPATCH_EVT.SET_REQUESTING_CURVE_UPLOAD: {
            return {
                ...state,
                isRequestingCurveUpload: action.payload,
                isRequestingCGDaily: true,
                isRequestingCGMonthly: true,
                isLoadingConsumoAnual: action.payload,
            };
        }
        case DISPATCH_EVT.SET_CONSUMPTIONS_GRAPH_DAILY: {
            let { payload } = action;
            let { consumptionsGraphDaily, rspConsumptionsDaily } = payload;
            return {
                ...state,
                isRequestingCGDaily: false,
                consumptionsGraphDaily,
                rspConsumptionsDaily,
            };
        }
        case DISPATCH_EVT.SET_CONSUMPTIONS_GRAPH_MONTHLY: {
            let { payload } = action;
            let { consumptionsGraphMonthly, rspConsumptionsMonthly } = payload;
            return {
                ...state,
                isRequestingCGMonthly: false,
                consumptionsGraphMonthly,
                rspConsumptionsMonthly,
            };
        }
        case DISPATCH_EVT.SET_CONSUMPTION_DATA_B2C: {
            let { payload } = action;

            let { input, handleUpdateLoadProfileData: handleUpdateLoadProfileDataB2C } = payload;

            let newInputs = null;

            switch (input.name) {
                case INPUT_NAMES_LPDATA.OPCAO_CURVA_CARGA_ID:
                case INPUT_NAMES_LPDATA.CONSUMO_ANUAL: {
                    newInputs = {
                        opcao_curva_carga_id: state.opcao_curva_carga_id,
                        consumo_anual: state.consumo_anual,
                        inputs_consumo: state.inputs_consumo,
                        [input.name]: input.value,
                    };
                    break;
                }
                case INPUT_NAMES_LPDATA.WORK_DAYS_OCCUPIED:
                case INPUT_NAMES_LPDATA.WORK_DAYS_OCCUPATION_PERIOD_ID: //if WORK_DAYS_OCCUPIED = true
                case INPUT_NAMES_LPDATA.WORK_DAYS_FIRST_ARRIVALS_ID: //if WORK_DAYS_OCCUPIED = false
                case INPUT_NAMES_LPDATA.WEEKEND_OCCUPATION_ID:
                case INPUT_NAMES_LPDATA.EQUIPMENTS: {
                    newInputs = {
                        opcao_curva_carga_id: state.opcao_curva_carga_id,
                        consumo_anual: state.consumo_anual,
                        inputs_consumo: {
                            ...state.inputs_consumo,
                            [input.name]: input.value,
                        },
                    };
                    break;
                }
                default:
                    break;
            }

            //fetched PUT facility
            if (!isAddFacility && isFieldDefined(newInputs)) {
                handleUpdateLoadProfileDataB2C(getPayloadLoadProfileB2C(state.facilityId, newInputs));
            }

            // update state
            return {
                ...state,
                opcao_curva_carga_id: newInputs.opcao_curva_carga_id,
                consumo_anual: newInputs.consumo_anual,
                inputs_consumo: {
                    ...state.inputs_consumo,
                    ...(newInputs?.inputs_consumo ?? {}),
                },
            };
        }
        case DISPATCH_EVT.SET_IS_VALID_CURVE_UPLOADED: {
            return {
                ...state,
                validCurveUploaded: action.payload,
            };
        }
        default: {
            throw new Error(`Error on loadProfileData Reducer type: ${action.type}`);
        }
    }
}
