import { getCompanyProfileIds } from 'services/user';
import { TService, TServiceValues } from 'interfaces/businessModels/service';
import { getInputWithTaxes } from 'services/products/evc';
import { parseNumberWithDecimals } from 'services/util/auxiliaryUtils';
import { PRODUCT_IDS } from 'constants/products';
import { IBusinessModelsState } from './../../interfaces/businessModels/reducer';
import { TBMKpi, TProductInputs } from './../../interfaces/businessModels/index';

export const serviceProjectSummary = (
    kpis: TServiceValues[],
    state: IBusinessModelsState<TProductInputs, TService>,
    configs: { companyProfileId: number; productID: number; businessModelID?: number }
) => {
    let projectSummary: TBMKpi[] = [];

    switch (configs.productID) {
        case PRODUCT_IDS.SPV:
        case PRODUCT_IDS.SPVSB:
            if (
                [getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_PL, getCompanyProfileIds().EDP_ES].includes(
                    configs?.companyProfileId
                )
            ) {
                projectSummary = [
                    {
                        label: 'label.monthlyFee',
                        value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].monthly_fee, 0),
                        unit: 'mc',
                    },
                    {
                        label: 'label.annualSaving',
                        value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].annual_saving, 0),
                        unit: 'yc',
                    },
                ];
            } else {
                projectSummary = [
                    {
                        label: 'label.annualPrice',
                        value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].annual_price, 0),
                        unit: 'currency',
                        numberOfDecimalPlaces: 0,
                    },
                    {
                        label: 'label.annualSaving',
                        value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].annual_saving, 0),
                        unit: 'currency',
                        numberOfDecimalPlaces: 0,
                    },
                ];
            }
            break;
        case PRODUCT_IDS.EVC:
        case PRODUCT_IDS.EVCSB:
            projectSummary = [
                {
                    label: 'label.services',
                    value: kpis[0].kpis_per_margin?.[0].service_monthly_fee,
                    unit: 'mc',
                    isServiceME_ES: false,
                },
            ];
            //#region EDP_ES
            if ([getCompanyProfileIds().EDP_ES].includes(configs?.companyProfileId) && configs.businessModelID === 105) {
                //EVC SERVICE
                projectSummary = [
                    {
                        isServiceME_ES: true,
                        label: 'label.services',
                        subLabel: 'page.evc.BModel.servicessubLabel1',
                        numberOfDecimalPlaces: 0,
                        value: kpis[0].kpis_per_margin?.[0].monthly_payments_fee,
                        unit: 'mc',
                    },
                    {
                        isServiceME_ES: true,
                        label: 'label.chargerRental',
                        subLabel: 'page.evc.BModel.servicessubLabel2',
                        numberOfDecimalPlaces: 0,
                        value: kpis[0].kpis_per_margin?.[0].monthly_payments_fee_alt,
                        unit: 'mc',
                    },
                ];

                if (kpis[0].kpis_per_margin?.[0].total_platform_price_month > 0) {
                    projectSummary.push({
                        isServiceME_ES: true,
                        label: 'label.managementPlatforms',
                        subLabel: 'page.evc.BModel.servicessubLabel2',
                        numberOfDecimalPlaces: 0,
                        value: getInputWithTaxes('total_platform_price_month', configs?.companyProfileId, state.simulation),
                        unit: 'mc',
                    });
                }
            }
            break;

        case PRODUCT_IDS.TRE:
            projectSummary = [{ label: 'label.price', value: kpis[0].kpis_per_margin?.[0].price }];
            break;

        case PRODUCT_IDS.CFP:
            projectSummary = [
                {
                    label: 'label.price',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].annual_price, 0),
                    unit: 'currency',
                    numberOfDecimalPlaces: 0,
                },
                {
                    label: 'label.annualSaving',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].annual_saving, 0),
                    unit: 'currency',
                    numberOfDecimalPlaces: 0,
                },
            ];
            break;
        case PRODUCT_IDS.HVAC_BR:
            projectSummary = [{ label: 'label.investment', value: kpis[0].kpis_per_margin?.[0]?.investment }];
            break;
        default:
            break;
    }

    return projectSummary;
};
