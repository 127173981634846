import { Button } from '@save2compete/efz-design-system';
import { IBmDetailedForm } from 'components/BusinessModels';
import IntlMessages from 'components/util/IntlMessages';
import { BUSINESS_MODEL_ELIGIBLE, SIZING_TYPE_IDS } from 'constants/businessModels';
import { BmDetailedActions } from 'constants/businessModelsPro/index';
import { USERS_TYPE_ID } from 'constants/user';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { UserContext } from 'contexts/userContext';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { isDefined, isEmpty, isFieldDefined } from 'services/util/auxiliaryUtils';

const ProposalGenerationPPaDetailed = () => {
    const {
        bmState,
        bmState: { loadings },
        lowNegotiationProfile,
        negotiationProfile,
        setBMEventHandler,
    } = useContext(OfferEditionContext);

    const { userTypeID } = useContext(UserContext);

    const methods = useFormContext<IBmDetailedForm>();

    const proposalDisableConditions = lowNegotiationProfile ? !bmState?.sliderReady : userTypeID === USERS_TYPE_ID.CLIENT;
    const hasErrorOnNetSavingsEligibility = bmState?.kpis?.negotiation?.hasErrorOnNetSavingsEligibility;
    const auditProposalConditions =
        lowNegotiationProfile ?
            negotiationProfile?.audita_proposta
        :   isDefined(bmState?.kpis?.finalKpis) && negotiationProfile?.audita_proposta;
    const hasAuditProposal =
        (isFieldDefined(bmState?.businessModelSelected) &&
            bmState?.businessModelSelected?.sizing_type_id === SIZING_TYPE_IDS.DETAILED_FULL &&
            bmState?.kpis?.negotiation.status_risk_premium_id === BUSINESS_MODEL_ELIGIBLE.NO) ||
        hasErrorOnNetSavingsEligibility;

    return (
        <div className="m-auto d-flex justify-content-center w-100 bm-detailed-proposal">
            {(auditProposalConditions || hasAuditProposal) && (
                <Button
                    variant="secondary"
                    onClick={() => setBMEventHandler(DetailedOfferEditionEvents.SET_DETAILED_ACTION, BmDetailedActions.AUDIT)}
                    disabled={!bmState?.sliderReady || loadings.general}
                    loading={loadings.auditFilesDownload}
                    dataTestId="bm-detailed-proposal-autit"
                >
                    <IntlMessages id="page.businessModels.auditProposal" />
                </Button>
            )}
            <Button
                disabled={
                    !bmState?.sliderReady ||
                    proposalDisableConditions ||
                    loadings.general ||
                    hasAuditProposal ||
                    !isEmpty(methods.formState.errors)
                }
                onClick={() => setBMEventHandler(DetailedOfferEditionEvents.SET_DETAILED_ACTION, BmDetailedActions.PROPOSAL)}
                loading={loadings.proposal || loadings.getProposalNoKpis}
                dataTestId="bm-detailed-proposal-generate"
            >
                <IntlMessages id="label.goToProposal" />
            </Button>
        </div>
    );
};

export default ProposalGenerationPPaDetailed;
