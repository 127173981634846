import { createContext, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { IBusinessModelsContext, TBMEvent, TInputs, TProductInputs } from 'interfaces/businessModels';
import { INITIAL_BUSINESS_MODELS } from 'constants/businessModelsPro';
import { businessModelReducer, initiaBusinessModelslState } from './businessModelsReducer';
import { TBMs } from 'interfaces/businessModels/reducer';
import { INITIAL_EVENT } from 'constants/businessModelsPro/grants';
import { useUserStore } from 'store/user';
import { useBusinessModelsStore } from 'store/businessModels';
import { isDefined } from 'services/util/auxiliaryUtils';

const BusinessModelsProWebstompContext = createContext({
    isLoadingWebStomp: false,
    fetchSimulations: () => {},
    mqMessages: [],
});

const BusinessModelsProContext = createContext<IBusinessModelsContext<TProductInputs, TBMs>>({
    productPayload: INITIAL_BUSINESS_MODELS,
    productID: {},
    language: {},
    bmCards: [],
    state: initiaBusinessModelslState,
    dispatch: () => {},
    bmEvent: {
        name: null,
    },
    setBmEvent: () => {},
    bmSelected: {
        id: undefined,
        paymentModelID: null,
        values: null,
        options: null,
        isDiscount: null,
        elements: {
            monthlyPayments: null,
            annualUpdateMonthlyFee: null,
            uniqueAllowance: null,
            yearlyAllowance: null,
            // @ts-ignore
            omContractDuration: null,
        },
    },
    setBMEventHandler: () => {},
    negotiationProfile: {},
    isRequestingBM: false,
    setIsRequestingBM: () => {},
    isReformulation: false,
});

const BusinessModelsProContextProvider = ({ children }) => {
    BusinessModelsProContext.displayName = 'BusinessModelContext';

    const { businessModels } = useBusinessModelsStore();

    const [state, dispatch] = useReducer(businessModelReducer, initiaBusinessModelslState);
    const [bmEvent, setBmEvent] = useState<TBMEvent<TBMs>>(INITIAL_EVENT);
    const { user } = useUserStore();

    const negotiationProfile = user?.negotiation_profile;
    const [isRequestingBM, setIsRequestingBM] = useState<boolean>(false);

    const productID = useSelector((state: any) => state?.product?.info?.id) ?? null;
    const productPayload: TInputs<typeof productID> = businessModels ?? INITIAL_BUSINESS_MODELS;
    const language = useSelector((state: any) => state.settings.locale.locale);

    function setBMEventHandler(type, payload) {
        setBmEvent({ name: type, payload: payload });
    }

    const businessModelsContextValues: IBusinessModelsContext<TProductInputs, TBMs> = {
        productPayload,
        productID,
        language,
        bmCards: state.businessModels,
        state,
        dispatch,
        bmEvent,
        setBmEvent,
        bmSelected: state.selected,
        setBMEventHandler,
        negotiationProfile,
        isRequestingBM,
        setIsRequestingBM,
        isReformulation: isDefined(productPayload?.reformulation),
    };
    return <BusinessModelsProContext.Provider value={businessModelsContextValues}>{children}</BusinessModelsProContext.Provider>;
};

export { BusinessModelsProContext, BusinessModelsProContextProvider, BusinessModelsProWebstompContext };
