import clone from 'fast-copy';
import { REMOTE_TABS, REMOTE_TABS_INPUTS, RemoteActions } from 'constants/products/spvPro';

export const initialRemoteReducer = {
    dialogOpen: false,
    tab: REMOTE_TABS.FACILITY,
    selectedEntry: {
        tab: null,
        index: null,
    },
    isAddEntry: false,
    coefficientSum: 0,
    defaultValues: {
        [REMOTE_TABS_INPUTS.COEFFICIENT]: '',
        [REMOTE_TABS_INPUTS.NEIGHBORS]: [],
        [REMOTE_TABS_INPUTS.ENTERPRISES]: [],
    },
    inputs: null,
};

export const remoteReducer = (state = initialRemoteReducer, action) => {
    switch (action.type) {
        case RemoteActions.OPEN_DIALOG: {
            return {
                ...state,
                dialogOpen: true,
                tab: action.payload?.tab ?? state.tab,
                selectedEntry: action.payload?.selectedEntry ?? state.selectedEntry,
                isAddEntry: action.payload?.isAddEntry ?? state.isAddEntry,
            };
        }
        case RemoteActions.CLOSE_DIALOG: {
            return {
                ...state,
                dialogOpen: false,
                tab: initialRemoteReducer.tab,
                selectedEntry: initialRemoteReducer.selectedEntry,
                isAddEntry: initialRemoteReducer.isAddEntry,
            };
        }
        case RemoteActions.SELECT_TAB: {
            return {
                ...state,
                tab: action.payload,
                isAddEntry: initialRemoteReducer.isAddEntry,
            };
        }
        case RemoteActions.SET_SELECTED_ENTRY: {
            return {
                ...state,
                selectedEntry: action.payload,
            };
        }
        case RemoteActions.SET_COEFFICIENT_SUM: {
            return {
                ...state,
                coefficientSum: action.payload,
            };
        }
        case RemoteActions.SET_INIT:
        case RemoteActions.SUBMIT_FORM: {
            return {
                ...state,
                inputs: action.payload,
            };
        }
        case RemoteActions.DELETE_ENTRY: {
            const { tab, index } = action.payload;

            const newState = clone(state.inputs?.[tab]);
            newState.splice(index, 1);

            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    [tab]: newState,
                },
            };
        }
        case RemoteActions.DELETE_ALL: {
            return initialRemoteReducer;
        }
        default:
            return state;
    }
};
