import { defineMessages } from 'react-intl';
import plMessages from '../locales/pl_PL.json';

const plPLLang = {
    messages: {
        ...plMessages,
    },
    locale: 'pl-PL',
    data: defineMessages,
};
export default plPLLang;
