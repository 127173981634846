import IntlMessages from 'components/util/IntlMessages';
import Dialog from '@mui/material/Dialog';
import { Button } from '@save2compete/efz-design-system';

const PopUpErrorDevice = ({ setShowErrorDevice, title, content }) => {
    const clickHandler = () => {
        setShowErrorDevice(null);
    };

    return (
        //@ts-ignore
        <Dialog open onClose={clickHandler} className="efz-popup-device-error">
            <div className="efz-popup-device-error-content">
                <h3>
                    <IntlMessages id={title} />
                </h3>
                <p>
                    <IntlMessages id={content} />
                </p>
                <Button onClick={clickHandler} dataTestId="close-popup">
                    <IntlMessages id="label.close" />
                </Button>
            </div>
        </Dialog>
    );
};

export default PopUpErrorDevice;
