import { Nullable } from 'types/utils';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IProposalStore {
    img_contract_filename: Nullable<string>;
    actions: {
        setImgContractFilename: (img_contract_filename: string) => void;
        resetAll: () => void;
    };
}

export const useProposalStore = create<IProposalStore>()(
    persist(
        (set) => ({
            img_contract_filename: null,
            actions: {
                setImgContractFilename: (img_contract_filename: string) => set({ img_contract_filename }),
                resetAll: () => {
                    set({ img_contract_filename: null });
                },
            },
        }),
        // @ts-ignore
        {
            name: 'proposal',
            partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => !['actions'].includes(key))),
        }
    )
);
