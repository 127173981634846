import InstallmentsSimpleMarginMarks from 'components/BusinessModels/ProjectSummary/ProjectSummaryInstallments/simple/CommercialMargin/MarkMargin';
import NonMarksMarginInstallmentsSimple from 'components/BusinessModels/ProjectSummary/ProjectSummaryInstallments/simple/CommercialMargin/NonMarkMargin';
import { PRODUCT_IDS } from 'constants/products';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { useContext } from 'react';

const MarginInstallmentsSimple = () => {
    const { productID } = useContext(BusinessModelsProContext);

    const marginToDisplay = () => {
        switch (productID) {
            case PRODUCT_IDS.SPVSB:
                return <InstallmentsSimpleMarginMarks />;
            default:
                return <NonMarksMarginInstallmentsSimple />;
        }
    };

    return marginToDisplay();
};

export default MarginInstallmentsSimple;
