import IntlMessages from 'components/util/IntlMessages';

// Icons
import { ReactComponent as CookieIcon } from 'assets/images/icons/svg/cookie.svg';
// import CookieConsent from 'components/OArea';
import { useState } from 'react';
import CookieConsentOArea from '../CookieConsentOArea';

const CookieBannerOArea = () => {
    const [policyTypeToShow, setPolicyTypeToShow] = useState(null);

    const showPolicyHandler = (type = null) => {
        if (type) {
            setPolicyTypeToShow(type);
        } else {
            setPolicyTypeToShow(null);
        }
    };

    return (
        <div className="cookie-banner-content">
            <div className="cookie-banner-title">
                <CookieIcon className="me-2" />
                <span>
                    <IntlMessages id="label.cookies.title" />
                </span>
            </div>
            <div className="cookie-banner-text">
                <IntlMessages id="label.cookies.content" whitespaceEnd />
                <span className="link" onClick={() => showPolicyHandler('cookies-policy')}>
                    <IntlMessages id="label.here" />
                </span>
                .
            </div>
            {!!policyTypeToShow && (
                <CookieConsentOArea type={policyTypeToShow} close={showPolicyHandler} showPolicyHandler={showPolicyHandler} />
            )}
        </div>
    );
};

export default CookieBannerOArea;
