import { getEndpoint, URL_PROJECTS } from 'constants/endpoints';
import { TCreateProjectPayload, TProjectResponse, TPutProjectPayload } from 'schemas/projects';
import axios from 'services/@efz/axios';

export const getProjectHandler = async (id: string, abortController: AbortController) => {
    try {
        const requestCfg = {
            url: `${getEndpoint(URL_PROJECTS)}/${id}`,
            method: 'GET',
            signal: abortController?.signal,
        };
        const response = await axios.request(requestCfg);
        return response.data as TProjectResponse;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const createProjectHandler = async (
    project: TCreateProjectPayload,
    url: { facilityId: number; productId: number },
    AbortController: AbortController
) => {
    try {
        const requestCfg = {
            url: `${getEndpoint(URL_PROJECTS)}/facility/${url.facilityId}/product/${url.productId}`,
            method: 'POST',
            data: project,
            signal: AbortController?.signal,
        };
        const response = await axios.request(requestCfg);
        return response.data as TProjectResponse;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const saveProjectHandler = async (project: TPutProjectPayload, id: string, AbortController: AbortController) => {
    try {
        const requestCfg = {
            url: `${getEndpoint(URL_PROJECTS)}/${id}`,
            method: 'PUT',
            data: project,
            signal: AbortController?.signal,
        };
        const response = await axios.request(requestCfg);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const saveAsProjectHandler = async (
    project: TPutProjectPayload,
    url: { facilityId: number; productId: number },
    AbortController: AbortController
) => {
    try {
        const requestCfg = {
            url: `${getEndpoint(URL_PROJECTS)}/facility/${url.facilityId}/product/${url.productId}`,
            method: 'POST',
            data: project,
            signal: AbortController?.signal,
        };
        const response = await axios.request(requestCfg);
        return response.data as TProjectResponse;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getFacilityProjectsHandler = async (abortController: AbortController, url: { facilityId: number; productId: string }) => {
    try {
        const requestCfg = {
            url: `${getEndpoint(URL_PROJECTS)}/facility/${url.facilityId}/product/${Number(url.productId)}`,
            method: 'GET',
            signal: abortController?.signal,
        };
        const response = await axios.request(requestCfg);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteProjectHandler = async (id: string, abortController: AbortController) => {
    try {
        const requestCfg = {
            url: `${getEndpoint(URL_PROJECTS)}/${id}`,
            method: 'DELETE',
            signal: abortController?.signal,
        };
        const response = await axios.request(requestCfg);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};
