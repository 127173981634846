import { useParams } from 'react-router-dom';

// Components
import InstallationPricesDetailed from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront/InstallationPrices/HP/detailed';
import InstallationPricesFast from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront/InstallationPrices/HP/fast';

// Constants
import { HpModules } from 'constants/products/hp';

const IntallationPricesHP = () => {
    const { module = null } = useParams();

    if (!module) return <></>;

    if (module === HpModules.DETAILED) return <InstallationPricesDetailed />;

    if (module === HpModules.FAST) return <InstallationPricesFast />;

    return <></>;
};

export default IntallationPricesHP;
