import { useContext } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';

// Components
import IntlMessages from 'components/util/IntlMessages';
import FormatNumber from 'components/util/FormatNumber';

// Constants

// Contexts
import { UserContext } from 'contexts/userContext';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';

// Services
import { isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';
import { getCompanyProfileIds } from 'services/user';

const TAG_MONITORING = 'MONITORING';

const Rubrics = () => {
    const { companyProfileId } = useContext(UserContext);

    const { bmState } = useContext(OfferEditionContext);

    const { control } = useFormContext();

    const duration =
        isFieldDefined(bmState?.offerEdition?.system?.configs?.offer_edition?.duration) ?
            bmState?.offerEdition?.system?.configs?.offer_edition?.duration
        :   null;

    const { fields } = useFieldArray({
        control,
        name: 'rubrics',
    });

    const watchRubrics = useWatch({
        control,
        name: 'rubrics',
    });
    const showRubrics = isFieldDefined(watchRubrics) && watchRubrics.length > 0;

    let maintenanceDisabled = [getCompanyProfileIds().EDP_PT].includes(companyProfileId) ? duration?.maintenance_years?.default > 0 : false;

    return showRubrics ?
            <>
                <TableRow>
                    <TableCell>
                        <IntlMessages id={'label.additionalCosts'} />:
                    </TableCell>
                </TableRow>
                {fields?.map((rubric, index) => (
                    <TableRow key={`rubric-${rubric?.cost_id}`}>
                        <TableCell className="px-4">
                            {isFieldDefined(rubric?.cost_tag) ?
                                <IntlMessages id={rubric?.cost_tag} />
                            :   rubric?.cost_description}
                        </TableCell>

                        <TableCell className={`controller-cell`}>
                            <FormGroup>
                                <Controller
                                    name={`rubrics.${index}.included_cost`}
                                    control={control}
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    render={({ field: { ref, ...field } }) => (
                                        <FormControlLabel
                                            {...field}
                                            control={
                                                <Switch
                                                    className={`switch-rubrics${rubric?.default_cost === 0 || (rubric.cost_tag === TAG_MONITORING && maintenanceDisabled) ? '-disabled' : ''}`}
                                                />
                                            }
                                            checked={field.value}
                                            disabled={rubric.cost_tag === TAG_MONITORING && maintenanceDisabled}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </TableCell>

                        {!rubric?.included_cost && (
                            <TableCell className="value-cell">
                                <FormatNumber
                                    styleFormat="currency"
                                    number={
                                        isDefined(watchRubrics) ?
                                            watchRubrics[index]?.included_cost ?
                                                parseInt(rubric?.final_cost)
                                            :   0
                                        : rubric.included_cost ?
                                            parseInt(rubric?.final_cost)
                                        :   0
                                    }
                                    numberOfDecimalPlaces={0}
                                />
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </>
        :   <></>;
};

export default Rubrics;
