export const GRAPH_INPUTS_DEFAULTS = {
    days_type: 'work_days',
    month: new Date().toLocaleString('en', { month: 'short' }).toLowerCase(),
};

export const GRAPH_QUESTIONS_DEFAULTS = [
    {
        name: 'days_type',
        label: 'label.weekday',
        disabled: false,
        answers: [
            {
                label: 'label.workDays',
                value: 'work_days',
            },
            {
                label: 'label.saturdays',
                value: 'saturdays',
            },
            {
                label: 'label.sundays',
                value: 'sundays',
            },
        ],
        type: 'select',
        options: {
            emptySelectedOption: 'component.select.simple',
            translateValues: true,
            notTranslateLabel: false,
        },
    },
    {
        name: 'month',
        label: 'label.months',
        disabled: false,
        answers: [
            {
                label: 'label.jan',
                value: 'jan',
            },
            {
                label: 'label.feb',
                value: 'feb',
            },
            {
                label: 'label.mar',
                value: 'mar',
            },
            {
                label: 'label.apr',
                value: 'apr',
            },
            {
                label: 'label.may',
                value: 'may',
            },
            {
                label: 'label.jun',
                value: 'jun',
            },
            {
                label: 'label.jul',
                value: 'jul',
            },
            {
                label: 'label.aug',
                value: 'aug',
            },
            {
                label: 'label.sep',
                value: 'sep',
            },
            {
                label: 'label.oct',
                value: 'oct',
            },
            {
                label: 'label.nov',
                value: 'nov',
            },
            {
                label: 'label.dec',
                value: 'dec',
            },
        ],
        type: 'select',
        options: {
            emptySelectedOption: 'component.select.simple',
            translateValues: true,
            notTranslateLabel: false,
        },
    },
];

export const MONTHS = [
    {
        label: 'label.jan',
        value: 'jan',
    },
    {
        label: 'label.feb',
        value: 'feb',
    },
    {
        label: 'label.mar',
        value: 'mar',
    },
    {
        label: 'label.apr',
        value: 'apr',
    },
    {
        label: 'label.may',
        value: 'may',
    },
    {
        label: 'label.jun',
        value: 'jun',
    },
    {
        label: 'label.jul',
        value: 'jul',
    },
    {
        label: 'label.aug',
        value: 'aug',
    },
    {
        label: 'label.sep',
        value: 'sep',
    },
    {
        label: 'label.oct',
        value: 'oct',
    },
    {
        label: 'label.nov',
        value: 'nov',
    },
    {
        label: 'label.dec',
        value: 'dec',
    },
];
