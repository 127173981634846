import { array, bool, number, object, string } from 'prop-types';
import { createContext, useState } from 'react';

import { getBoolean } from 'services/util/auxiliaryUtils';
import { useUserStore } from 'store/user';

const UserContext = createContext({
    companyProfileId: number | null,
    locale: string,
    userLocale: string,
    userTypeID: number | null,
    userUsername: string,
    userChannelIDGroup: number,
    userLanguage: string,
    configs: object,
    salesSegmentsList: array,
    hasUniqueSalesSegment: bool,
    isB2C: false,
    allowedToCreateFacility: bool,
    allowedToDeleteFacility: bool,
    allowedToCreateClient: bool,
    allowedToDeleteClient: bool,
    canGenerateQuickTeaser: bool,
    applyCompanyProfileId: bool,
    companyName: string | null,
    appLocale: string,
    isEfz: bool,
    isDemo: bool,
    is_efz: bool,
    setAppLocale: (_lang) => {},
    setConfigs: (_cfgs) => {},
    hasTermsAgreed: bool,
    hasSpvV3: bool,
    hasSpvV2: bool,
    isSupervisor: bool,
    configBackoffice: object,
    userChannelId: number,
});

const UserProvider = ({ children }) => {
    const { user, locale } = useUserStore();
    const [appLocale, setAppLocale] = useState(locale);
    const [configs, setConfigs] = useState({
        client: null,
        facility: null,
    });
    const salesSegment = localStorage.getItem('salesSegment');

    const configBackoffice = user?.configs_bo?.[salesSegment?.toLocaleLowerCase()];

    const getCompanyProfileId = (user) => {
        const empresa_id = user?.empresa_id ?? null;
        const perfil_empresa_id = user?.perfil_empresa_id ?? null;
        const applyCompanyProfileId = getBoolean(localStorage.getItem('applyCompanyProfileId'));
        return applyCompanyProfileId ? perfil_empresa_id : empresa_id;
    };

    const userContextValues = {
        companyProfileId: getCompanyProfileId(user),
        companyName: user?.short_name ?? 'Base efz',
        applyCompanyProfileId: localStorage.getItem('applyCompanyProfileId'),
        userLanguage: user?.idioma ?? 'pt',
        localeUser: locale?.appLocale ?? 'en-GB', //TODO: @deprecated remover
        locale,
        userTypeID: user?.tipo_utilizador_id ?? null,
        userUsername: user?.username ?? null,
        userChannelIDGroup: user?.grupo_canal_id ?? null,
        salesSegmentsList: user?.segmentos_mercado ?? [],
        hasUniqueSalesSegment: user?.segmentos_mercado?.length === 1,
        allowedToCreateFacility: user?.cria_instalacao ?? false,
        allowedToDeleteFacility: user?.elimina_instalacao ?? false,
        allowedToCreateClient: user?.cria_cliente ?? false,
        allowedToDeleteClient: user?.elimina_instalacao ?? false,
        canGenerateQuickTeaser: user?.channel_quick_teaser ?? false,
        isSupervisor: user?.supervisiona ?? false,
        isB2C: salesSegment === 'B2C',
        setAppLocale,
        appLocale,
        userLocale: user?.locale ?? 'en-GB',
        configs,
        setConfigs,
        isEfz: user?.username?.includes('efzdman'),
        isDemo: user?.is_demo ?? false,
        is_efz: user?.is_efz ?? false,
        hasTermsAgreed: !user?.shows_terms ?? true,
        hasSpvV2: user?.botao_velho_pv ?? false,
        hasSpvV3: user?.botao_novo_pv ?? false,
        configBackoffice,
        userChannelId: user?.grupo_canal_id,
        perfil_empresa_id: user?.perfil_empresa_id, //apenas para as configs getFacilityConfigs
    };

    return <UserContext.Provider value={userContextValues}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
