import clone from 'fast-copy';
import { intlMessages, isNumber } from 'services/util/auxiliaryUtils';
import { useUserStore } from 'store/user';

//Defaults styles available
const STYLES_FORMAT = {
    DECIMAL: 'decimal', //for plain number formatting.
    CURRENCY: 'currency', //for currency formatting.
    PERCENT: 'percent', //for percent formatting. (Range: [-1, 1])
    UNIT: 'unit', //for unit formatting.
    NOS: 'nos', //for no style
};
//Styles Available
const STYLES_AVAILABLE = [STYLES_FORMAT.DECIMAL, STYLES_FORMAT.CURRENCY, STYLES_FORMAT.PERCENT, STYLES_FORMAT.UNIT, STYLES_FORMAT.NOS];

//Defaults unit Display formats
const UNIT_DISPLAY = {
    LONG: 'long', //(e.g., 16 litres)
    SHORT: 'short', //(e.g., 16 l)
    NARROW: 'narrow', //(e.g., 16l)
};
//Defaults currency displays
const CURRENCY_DISPLAY = {
    SYMBOL: 'symbol', //$
    NARROW_SYMBOL: 'narrowSymbol', //"$100" rather than "US$100"
    CODE: 'code', //USD (Iso Code)
    NAME: 'name', //Dollar
};
//Defaults
const DEFAULTS_PROPS = {
    LOCALE: 'en-GB',
    CURRENCY: 'EUR',
    STYLE: STYLES_FORMAT.DECIMAL,
    UNIT_DISPLAY: UNIT_DISPLAY.SHORT,
    UNIT: 'liter',
    NUMBER_DECIMAL_PLACES: 1,
    DELIMITER: '.',
    CURRENCY_DISPLAY: CURRENCY_DISPLAY.NARROW_SYMBOL,
    NUMBER: 0,
};

//More Units Available
const UNITS_AVAILABLE = {
    CURRENCY: 'currency', //Currency                              (100 €)
    YEAR: 'year', //Years                                 (100 years)
    MONTH: 'month', //Months                                (100 months)
    YC: 'yc', //year & currency                       (100 €/year)
    SYMBOL: 'symbol', //Simbol                                (100 &)
    YP: 'yp', //year & percent                        (100 %/year)
    YPW: 'ypw', //year & power                          (100 €/kWh)
    YPP: 'ypp', //year & power peak                     (100 €/kWp)
    MC: 'mc', //month & currency                      (100 €/month)
    KW: 'kw', //power in K                            (100 kW)
    KWP: 'kwp', //peak power                            (100 €/kWp)
    CKWH: 'ckwh', //capacity                            (100 €/kWh)
    CMWH: 'cmwh', //capacity                            (100 €/MWh)
    KWH: 'kwh', //capacity                              (100 kWh)
    CMWP: 'cmwp', //Currency peak Power (M)               (100 €/MWh)
    MWH: 'mwh', //MegaWatts H                           (100 MWh)
    SM: 'sm', //Square Meters                         (100 m²)
    PAN: 'pan', //Panels                                (100 panels)
    DG: 'dg', //Degree                                (100 º)
    TEP: 'tep', //TEP                                   (100 tep)
    KVAR: 'kvar', //kVAr                                  (100 kVAr)
    CAE: 'cae', //CAE                                   (100 CAE)
    BTUH: 'btuh', //BTU H                                 (100 BTU/h)
    WP: 'wp', //Watt-pico                                (100 BTU/h)
};

const getUnit = ({ unit, symbol = '€', number }) => {
    switch (unit) {
        case UNITS_AVAILABLE.CAE:
            return 'CAE';
        case UNITS_AVAILABLE.CURRENCY:
        case UNITS_AVAILABLE.SYMBOL:
            return symbol;
        case UNITS_AVAILABLE.YEAR:
            return ` ${number === 1 ? intlMessages('label.year') : intlMessages('label.years')}`;
        case UNITS_AVAILABLE.MONTH:
            return ` ${number === 1 ? intlMessages('label.month') : intlMessages('label.months')}`;
        case UNITS_AVAILABLE.YC:
            return ` ${symbol}/${intlMessages('label.month')}`;
        case UNITS_AVAILABLE.YP:
            return ` %/${intlMessages('label.year')}`;
        case UNITS_AVAILABLE.YPW:
            return ` ${symbol}/kWh`;
        case UNITS_AVAILABLE.MC:
            return ` ${symbol}/${intlMessages('label.month')}`;
        case UNITS_AVAILABLE.KWP:
            return ` kWp`;
        case UNITS_AVAILABLE.KW:
            return ` kW`;
        case UNITS_AVAILABLE.KWH:
            return ` kWh`;
        case UNITS_AVAILABLE.MWH:
            return ` MWh`;
        case UNITS_AVAILABLE.TEP:
            return ` tep`;
        case UNITS_AVAILABLE.CKWH:
            return ` ${symbol}/kWh`;
        case UNITS_AVAILABLE.CMWH:
            return ` ${symbol}/MWh`;
        case UNITS_AVAILABLE.CMWP:
            return ` ${symbol}/MWh`;
        case UNITS_AVAILABLE.YPP:
            return ` ${symbol}/kWp`;
        case UNITS_AVAILABLE.SM:
            return ` m<sup>2</sup>`;
        case UNITS_AVAILABLE.KVAR:
            return ` kVAr`;
        case UNITS_AVAILABLE.BTUH:
            return ` BTU/h`;
        case UNITS_AVAILABLE.PAN:
            return ` ${intlMessages('label.panels')}`.toLowerCase();
        case UNITS_AVAILABLE.WP:
            return ` Wp`;
        case UNITS_AVAILABLE.DG:
            return `º`;
        default:
            return '';
    }
};

/**
 * getParseNumber
 *
 * @description Get Integer from a formatted string
 * @param {any} value - Number or String of a Number
 * @param {string} delimiter - Delimiter on the string
 * @returns {number}
 */
const getParseNumber = (number, delimiter) => {
    let value = clone(number);

    if (!isNumber(value)) {
        if (delimiter === '.' && !!value) {
            value = value.split(delimiter).join('');
            value = value.replace(',', '.');
        }
        if (delimiter === ',' && !!value) {
            value = value.split(delimiter).join('');
        }
    }

    return parseFloat(value);
};

/**
 * formatNumber
 * Link: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * @description Formats a number according to its type and locale
 * @param {string} locale - Locale from the user's token
 * @param {string} styleFormat - Styling method of the number
 * - "decimal" for plain number formatting.
 * - "currency" for currency formatting.
 * - "percent" for percent formatting. (Range: [-1, 1])
 * - "unit" for unit formatting.
 * @param {string} currency - Currency unit desired
 * @param {string} unitDisplay - Unit Format desired
 * - "long" (e.g., 16 litres)
 * - "short" (e.g., 16 l)
 * - "narrow" (e.g., 16l)
 * @param {string} unit - Unit desired
 * - Link: https://github.com/unicode-org/cldr/blob/master/common/validity/unit.xml
 * @param {number} numberOfDecimalPlaces - Number of decimals [0,21]
 * @param {string} currencyDisplay - Format of the currency
 * - "symbol" (e.g., $)
 * - "narrowSymbol" (e.g., "$" rather than "US$")
 * - "code" (e.g., USD Iso Code)
 * - "name" (e.g., Dollar)
 * @param {any} number - Value to be formated
 * @returns {string}
 */
const formatNumber = ({
    locale = DEFAULTS_PROPS.LOCALE,
    styleFormat = DEFAULTS_PROPS.STYLE,
    currency = DEFAULTS_PROPS.CURRENCY,
    unitDisplay = DEFAULTS_PROPS.UNIT_DISPLAY,
    unit = DEFAULTS_PROPS.UNIT,
    numberOfDecimalPlaces = DEFAULTS_PROPS.NUMBER_DECIMAL_PLACES,
    delimiter = DEFAULTS_PROPS.DELIMITER,
    number = DEFAULTS_PROPS.NUMBER,
}) => {
    let value = getParseNumber(number, delimiter);
    switch (styleFormat) {
        case STYLES_FORMAT.NOS:
            return number;
        case STYLES_FORMAT.CURRENCY:
            value = new Intl.NumberFormat(locale, {
                style: styleFormat,
                currency,
                unitDisplay,
                minimumFractionDigits: numberOfDecimalPlaces,
                maximumFractionDigits: numberOfDecimalPlaces,
                //currencyDisplay,
            }).format(value);
            break;
        case STYLES_FORMAT.UNIT:
            value = new Intl.NumberFormat(locale, {
                style: styleFormat,
                unit,
                unitDisplay,
                minimumFractionDigits: numberOfDecimalPlaces,
                maximumFractionDigits: numberOfDecimalPlaces,
            }).format(value);
            break;
        case STYLES_FORMAT.PERCENT:
        case STYLES_FORMAT.DECIMAL:
        default:
            value = new Intl.NumberFormat(locale, {
                style: styleFormat,
                unitDisplay,
                minimumFractionDigits: numberOfDecimalPlaces,
                maximumFractionDigits: numberOfDecimalPlaces,
            }).format(value);
            break;
    }

    return value;
};

const useFormatNumber = () => {
    const { user } = useUserStore();
    const currency = user?.currency?.code;
    const locale = user?.locale;
    const symbol = user?.currency?.symbol;

    function getFormatNumber({ number, unit, numberOfDecimalPlaces, styleFormat, delimiter }) {
        if (isNaN(parseFloat(number))) return 'N/A';

        //Fallback invalid style
        if (!STYLES_AVAILABLE.includes(styleFormat)) {
            styleFormat = 'decimal';
        }

        let _number = formatNumber({
            locale,
            styleFormat,
            currency,
            unit,
            numberOfDecimalPlaces,
            delimiter,
            number,
        });

        let _unit = getUnit({ unit, symbol, _number });
        return `${_number} ${_unit}`.trim();
    }

    return {
        getFormatNumber,
    };
};

export default useFormatNumber;
