import { BUSINESS_MODEL_IDS } from 'constants/businessModels';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPaP } from 'interfaces/businessModels/pap';
import { useContext } from 'react';
import { getCompanyProfileIds } from 'services/user';
import { isDefined } from 'services/util/auxiliaryUtils';
import PapMarginKpi from './MarginKpi';

const PapMarginKpis = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;

    const selectedMarginValues = bmSelected?.values?.kpis_per_margin.find((kpi) => kpi.saving_share === bmSelected?.values?.marginSelected);

    const papTariff = selectedMarginValues?.pap_tariff ?? undefined;
    const papTariffDiscount = selectedMarginValues?.pap_tariff_discount ?? undefined;
    const annualEstimatedPrice = selectedMarginValues?.annual_estimated_price ?? undefined;
    const annualNetSaving = selectedMarginValues?.annual_net_saving ?? undefined;

    const showAdditionalValue =
        [
            BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_IT].PAP,
            BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_PL].PAP,
            BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_ES].PAP,
        ].includes(bmSelected.id) ?? false;

    const additinalValue = selectedMarginValues?.additional_value;
    const additinalValuePrc = selectedMarginValues?.additional_value_prc;

    return (
        <div className="bm-kpis-margin">
            {showAdditionalValue && isDefined(additinalValue) && (
                <PapMarginKpi
                    label="label.additionalAmmount"
                    values={[
                        { unit: 'currency', number: additinalValue, numberOfDecimalPlaces: 1 },
                        { unit: 'percent', number: additinalValuePrc, numberOfDecimalPlaces: 1 },
                    ]}
                />
            )}
            {isDefined(papTariff) && (
                <PapMarginKpi
                    label="label.tariffAndPercDiscount"
                    values={[
                        { unit: 'cmwp', number: papTariff, numberOfDecimalPlaces: 1 },
                        { unit: 'percent', number: papTariffDiscount, numberOfDecimalPlaces: 1 },
                    ]}
                />
            )}
            {isDefined(annualEstimatedPrice) && (
                <PapMarginKpi
                    label="label.annualEstimatedPrice"
                    values={[{ unit: 'currency', number: annualEstimatedPrice, numberOfDecimalPlaces: 0 }]}
                />
            )}
            {isDefined(annualNetSaving) && (
                <PapMarginKpi
                    label="label.annualNetSavings"
                    values={[{ unit: 'currency', number: annualNetSaving, numberOfDecimalPlaces: 0 }]}
                />
            )}
        </div>
    );
};

export default PapMarginKpis;
