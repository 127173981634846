import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Lottie2 from 'components/@efz/Lottie/Lottie2';
import IntlMessages from 'components/util/IntlMessages';
import { HOUSING_TYPES_B2C, solarpvMapActions } from 'constants/products/solarpvSimple';
import useFormatNumber from 'hooks/formatNumber';
import DrawArea2 from 'assets/lottie/PPC_Draw_Area_2';
import { ReactComponent as HousePin } from 'assets/images/products/spv-simple/house.svg';
import { ReactComponent as GroundPin } from 'assets/images/products/solarpv/ground.svg';
import CustomUnit from 'components/util/CustomUnit';
import { Button } from '@save2compete/efz-design-system';

const DrawRoofArea = ({ isDrawing, mapDispatch, hasArea, area, housing_type }) => {
    const { getFormatNumber } = useFormatNumber();
    const isGround = [HOUSING_TYPES_B2C.GROUND].includes(housing_type);
    let textButton = () => {
        if (!hasArea) {
            return `${isDrawing ? 'label.stopDrawing' : 'label.startDrawing'}`;
        } else {
            return 'label.drawAgain';
        }
    };

    const handleOnClick = () => {
        let mapAction = solarpvMapActions.SET_IS_DRAWING;
        if (hasArea) {
            mapAction = solarpvMapActions.SET_DRAW_AGAIN;
        }
        mapDispatch(mapAction);
    };

    return (
        <>
            <div className="draw-roof-area">
                <div className="draw-roof-area-left">
                    <Typography>
                        <CardHeader
                            avatar={isGround ? <GroundPin /> : <HousePin />}
                            title={
                                <IntlMessages
                                    id={`${
                                        isGround ? 'page.spvSimple.address.DrawYourArea.title' : 'page.spvSimple.address.DrawYourRoof.title'
                                    }`}
                                />
                            }
                        />
                    </Typography>
                    <div className="hint">
                        <IntlMessages
                            id={`${
                                isGround ? 'page.spvSimple.address.DrawYourArea.subtitle' : 'page.spvSimple.address.DrawYourRoof.subtitle'
                            }`}
                        />
                    </div>
                    <Box className="start-drawing-container">
                        <Button variant="secondary" icon="startEditPencil" onClick={() => handleOnClick()} dataTestId="start-drawing">
                            <IntlMessages id={textButton()} />
                        </Button>
                    </Box>
                </div>
                <div className="draw-roof-area-right">
                    <Lottie2
                        options={{
                            animationData: DrawArea2,
                        }}
                        width={116.84}
                        height={85.6}
                    />
                    <span className="area-container">
                        <span className="area-value">{getFormatNumber({ number: area, numberOfDecimalPlaces: 0 })} </span>
                        <span className="area-unit">
                            <CustomUnit unit={'sm'} />
                        </span>
                    </span>
                </div>
            </div>
        </>
    );
};

export default DrawRoofArea;
