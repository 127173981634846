import { isDefined } from 'services/util/auxiliaryUtils';
import { TBMProjectSummary, TBMKpi } from '../../interfaces/businessModels/index';
import { parseNumberWithDecimals } from 'services/util/auxiliaryUtils';
import { getCompanyProfileIds } from 'services/user';
import { ppaTariffUnit } from './ppaKpis';

export const pPaProjectSummary = (kpis: TBMProjectSummary[], syncKpis, companyProfileId): TBMKpi[] => {
    let projectSummary: TBMKpi[] = [];
    if (isDefined(syncKpis)) return syncPPaProjectSummary(syncKpis, companyProfileId);

    const tariffValue = isDefined(syncKpis) ? syncKpis?.negotiation.default : kpis[0].kpis_per_margin?.[0].energy_price;
    projectSummary = [
        {
            label: 'label.tariff',
            value: parseNumberWithDecimals(tariffValue, 0),
            unit: 'cmwp',
            numberOfDecimalPlaces: 0,
        },
    ];
    return projectSummary;
};

const syncPPaProjectSummary = (syncKpis, companyProfileId) => {
    const step = `${syncKpis?.negotiation?.step ?? ''}`;
    switch (companyProfileId) {
        case getCompanyProfileIds().PROFILE_BASE_UK:
        case getCompanyProfileIds().EVOLO:
        case getCompanyProfileIds().ROMANDE: {
            const numberOfDecimalPlaces = step.split('.')?.[1]?.length ?? 4;
            return [
                {
                    label: 'label.tariff',
                    value: parseNumberWithDecimals(syncKpis?.negotiation.default, numberOfDecimalPlaces),
                    unit: ppaTariffUnit(companyProfileId),
                    numberOfDecimalPlaces,
                },
            ];
        }
        case getCompanyProfileIds().SUNSEAP: {
            const numberOfDecimalPlaces = step.split('.')?.[1]?.length ?? 4;
            return [
                {
                    label: 'label.tariff',
                    value: parseNumberWithDecimals(syncKpis?.negotiation.default, numberOfDecimalPlaces),
                    unit: 'cmwp',
                    numberOfDecimalPlaces,
                },
                {
                    label: 'label.annualSaving',
                    value: parseNumberWithDecimals(syncKpis.kpis.annual_saving, 0),
                    unit: 'currency',
                },
            ];
        }
        case getCompanyProfileIds().NRG: {
            const numberOfDecimalPlaces = step.split('.')?.[1]?.length ?? 2;
            return [
                {
                    label: 'label.tariff',
                    value: parseNumberWithDecimals(syncKpis?.negotiation.default, numberOfDecimalPlaces),
                    unit: 'cmwp',
                    numberOfDecimalPlaces,
                },
                {
                    label: 'label.annualSaving',
                    value: parseNumberWithDecimals(syncKpis.kpis.annual_saving, 0),
                    unit: 'currency',
                },
            ];
        }
        default: {
            const monthlyPayment = syncKpis?.negotiation?.pre_selected;
            const discount = 1 - monthlyPayment / (syncKpis?.kpis?.annual_saving / (syncKpis?.kpis?.annual_prod / 1000)); //MWh
            return [
                {
                    label: 'page.businessModels.kpis.discountAgainstarget',
                    value: parseNumberWithDecimals(discount, 2),
                    unit: 'percent',
                },
                {
                    label: 'label.annualSaving',
                    value: parseNumberWithDecimals(syncKpis.kpis.annual_saving, 0),
                    unit: 'currency',
                },
            ];
        }
    }
};
