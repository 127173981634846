import { Reducer } from 'react';
import { DEFAULT_FORM_VALUES, DEFAULT_LOADINGS, EVCProActionTypes, KPIS } from 'constants/products/evcPro';
import { IEVCProState, TEVCProAction, TEVCProInputs } from 'interfaces/products/evcPro';

export const initialEVCProReducer: IEVCProState = {
    mounted: false,
    inputs: DEFAULT_FORM_VALUES,
    options: {
        trench: [],
        eligibleForPartnership: false,
        is_proposal: false,
        proposal_info: { data_simulacao: null, id: null, gmn_tag: null },
    },
    kpis: null,
    loadings: DEFAULT_LOADINGS,
    functions: {
        handleDeleteFile: function (_filename: string, _inputs: TEVCProInputs): Promise<{ success: boolean; message: string }> {
            throw new Error('Function not implemented.');
        },
        handleDownloadFile: function (_filename: string): Promise<void> {
            throw new Error('Function handleDownloadFile not implemented.');
        },
        handleUploadFile: function (_file: File | File[], _inputs: TEVCProInputs): Promise<string | undefined> {
            throw new Error('Function handleUploadFile not implemented.');
        },
        handleRequestKPIs: function (_inputs: TEVCProInputs): void {
            throw new Error('Function handleRequestKPIs not implemented.');
        },
    },
};

export const evcProReducer: Reducer<IEVCProState, TEVCProAction> = (state = initialEVCProReducer, action) => {
    //console.log('EVCProReducer:', state, action);
    switch (action.type) {
        case EVCProActionTypes.SET_MOUNT_DATA: {
            const { functions, loadings, options, inputs } = action.payload;

            if (inputs?.CHARGERS?.chargers_selected) {
                // Update chargers_selected and reassign to inputs
                inputs.CHARGERS.chargers_selected = inputs.CHARGERS.chargers_selected.map((charger) => {
                    const updatedCharger = {
                        ...charger,
                        additional_info: {
                            ...(charger?.additional_info || {}),
                            partnership: charger.additional_info?.partnership ?? null,
                        },
                    };
                    return updatedCharger;
                });
            }

            const newState = {
                ...state,
                mounted: true,
                inputs: inputs,
                options,
                loadings: {
                    ...state.loadings,
                    ...loadings,
                },
                functions,
            };

            return newState as IEVCProState;
        }

        case EVCProActionTypes.SET_LOADINGS:
            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    ...action.payload,
                },
            } as IEVCProState;

        case EVCProActionTypes.SET_KPIS: {
            return {
                ...state,
                kpis: {
                    ...action.payload,
                    [KPIS.PRICE]: action.payload?.[KPIS.PRICE],
                    [KPIS.PRICE_MONTHLY_FEE]: action.payload?.[KPIS.PRICE_MONTHLY_FEE],
                    [KPIS.NR_MONTHLY_FEES]: action.payload?.[KPIS.NR_MONTHLY_FEES],
                },
                loadings: {
                    ...state.loadings,
                    general: false,
                    getting_kpis: false,
                },
            } as IEVCProState;
        }

        case EVCProActionTypes.SET_INPUTS: {
            return {
                ...state,
                inputs: action.payload,
            } as IEVCProState;
        }

        default:
            return state;
    }
};
