import { intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';

/**
 * getTagUploadContract
 *
 */
export function getTagUploadContract(type, message) {
    switch (type) {
        case 'uploadContract':
            return intlMessages('server.success.UPLOADED_SIGNED_CONTRACT_SUCCESS');
        case 'uploadProposal':
            return intlMessages('server.success.UPLOADED_ATTACHMENT_DOCS_SUCCESS');
        default:
            return message;
    }
}

/**
 * isDisabledDownloadProposal
 *
 * @param {*} row
 * @param {*} companyProfileId
 * @param {*} userCanAccessProposals
 */
export function isDisabledDownloadProposal(row, userCanAccessProposals) {
    return (
        (!row.original.nome_fich_apoio_proposta && !row.original.nome_fich_proposta) ||
        row.original.estado === 'CANCELADA' ||
        !userCanAccessProposals ||
        row.original.downloadProposalLoading
    );
}

export function hasFiltersApplied(processesFilters) {
    // others
    if (
        !isFieldDefined(processesFilters?.search) &&
        !isFieldDefined(processesFilters?.datefrom) &&
        !isFieldDefined(processesFilters?.dateto) &&
        !isFieldDefined(processesFilters?.cid) &&
        !isFieldDefined(processesFilters?.status_id) &&
        !isFieldDefined(processesFilters?.fid) &&
        !isFieldDefined(processesFilters?.gcid) &&
        !isFieldDefined(processesFilters?.gsid) &&
        !isFieldDefined(processesFilters?.ntid) &&
        !isFieldDefined(processesFilters?.oid) &&
        !isFieldDefined(processesFilters?.pid) &&
        !isFieldDefined(processesFilters?.sid) &&
        !isFieldDefined(processesFilters?.state) &&
        !isFieldDefined(processesFilters?.tpid) &&
        !isFieldDefined(processesFilters?.uid) &&
        !isFieldDefined(processesFilters?.sent_to_field) &&
        !isFieldDefined(processesFilters?.gbm_ids) &&
        !isFieldDefined(processesFilters?.send_status_crm) &&
        processesFilters?.limit === 50 &&
        processesFilters?.page === 1
    )
        return false;

    return true;
}
