import clone from 'fast-copy';

// Constants
import {
    INPUT_NAMES_LPDATA as INPUT_NAMES,
    OPTS_LOAD_CURVE_B2B,
    OPTS_LOAD_CURVE_B2C,
    QUESTIONS_LPDATA_B2B,
    QUESTIONS_LPDATA_B2C,
    AVAILABLE_LINKS_CAE,
    SIMPLE_ESTIMATION_IDS,
    DEFAULT_CONSUMPTION_WEIGHTS,
    INPUT_NAMES_LPDATA,
    MONTHS,
    CONSUMO_ANUAL_DEFAULT_VALUES_B2C,
} from 'constants/facilityPro/loadProfileData';

// Services
import {
    convertToUnit,
    getBoolean,
    isDefined,
    isEmpty,
    isFieldDefined,
    isNumberDefined,
    removeFieldEmptyInObj,
    removeFieldNaNInObj,
} from 'services/util/auxiliaryUtils';
import { getEstimateWeightsByActivityCode, getEstimateWeightsByCustom } from 'api/facility';
import { convertDateTimeToString, isInvalidCAE } from 'services/facility';
import { getCompanyProfileIds } from 'services/user';
import { USERS_TYPE_ID } from 'constants/user';

//#region  /** B2B **/

/* Methods private */

/* Methods public */
export function initialLProfileInputsB2B() {
    return {
        opcao_curva_carga_id: '',
        consumo_anual: '',
        activity_code: '',
        working_days: false, //match with (trabalha_2a, trabalha_3a, trabalha_4a, trabalha_5a, trabalha_6a)
        trabalha_sab: false,
        trabalha_dom: false,
        trabalha_feriado: false,
        horario_dias_semana: [],
        horario_domingo_e_feriado: [],
        horario_sabado: [],
        simple_estimation_id: null,
        consumption_weights: {},
        nome_ficheiro_curva_carga: '',
    };
}

export function getSimpleEstimationId(inputs) {
    let { tem_dados_ponderador_estimativa_simples, tem_dados_ponderador_estimativa_simples_por_periodo } = inputs;

    // ACTIVITY_CODE
    if (!tem_dados_ponderador_estimativa_simples && !tem_dados_ponderador_estimativa_simples_por_periodo)
        return SIMPLE_ESTIMATION_IDS.ACTIVITY_CODE;

    // CUSTOM
    if (tem_dados_ponderador_estimativa_simples && !tem_dados_ponderador_estimativa_simples_por_periodo)
        return SIMPLE_ESTIMATION_IDS.CUSTOM;

    // CUSTOM_PERIOD
    if (!tem_dados_ponderador_estimativa_simples && tem_dados_ponderador_estimativa_simples_por_periodo)
        return SIMPLE_ESTIMATION_IDS.CUSTOM_PERIOD;
}

export function getInputsWorkingSchedule(formData) {
    let inputs = removeFieldEmptyInObj({
        [INPUT_NAMES_LPDATA.TRABALHA_2A]: formData?.[INPUT_NAMES_LPDATA.WORKING_DAYS] ?? null,
        [INPUT_NAMES_LPDATA.TRABALHA_3A]: formData?.[INPUT_NAMES_LPDATA.WORKING_DAYS] ?? null,
        [INPUT_NAMES_LPDATA.TRABALHA_4A]: formData?.[INPUT_NAMES_LPDATA.WORKING_DAYS] ?? null,
        [INPUT_NAMES_LPDATA.TRABALHA_5A]: formData?.[INPUT_NAMES_LPDATA.WORKING_DAYS] ?? null,
        [INPUT_NAMES_LPDATA.TRABALHA_6A]: formData?.[INPUT_NAMES_LPDATA.WORKING_DAYS] ?? null,
        [INPUT_NAMES_LPDATA.HORARIO_DIAS_SEMANA]:
            isFieldDefined(formData[INPUT_NAMES_LPDATA.HORARIO_DIAS_SEMANA]) ?
                [
                    convertDateTimeToString(formData[INPUT_NAMES_LPDATA.HORARIO_DIAS_SEMANA]?.[0]),
                    convertDateTimeToString(formData[INPUT_NAMES_LPDATA.HORARIO_DIAS_SEMANA]?.[1]),
                ]
            :   null,
        [INPUT_NAMES_LPDATA.TRABALHA_SAB]: formData?.[INPUT_NAMES_LPDATA.TRABALHA_SAB] ?? null,
        [INPUT_NAMES_LPDATA.HORARIO_SABADO]:
            isFieldDefined(formData[INPUT_NAMES_LPDATA.HORARIO_SABADO]) ?
                [
                    convertDateTimeToString(formData[INPUT_NAMES_LPDATA.HORARIO_SABADO]?.[0]),
                    convertDateTimeToString(formData[INPUT_NAMES_LPDATA.HORARIO_SABADO]?.[1]),
                ]
            :   null,
        [INPUT_NAMES_LPDATA.TRABALHA_DOM]: formData?.[INPUT_NAMES_LPDATA.TRABALHA_DOM] ?? null,
        [INPUT_NAMES_LPDATA.TRABALHA_FERIADO]: formData?.[INPUT_NAMES_LPDATA.TRABALHA_FERIADO] ?? null,
        [INPUT_NAMES_LPDATA.HORARIO_DOMINGO_E_FERIADO]:
            isFieldDefined(formData[INPUT_NAMES_LPDATA.HORARIO_DOMINGO_E_FERIADO]) ?
                [
                    convertDateTimeToString(formData[INPUT_NAMES_LPDATA.HORARIO_DOMINGO_E_FERIADO]?.[0]),
                    convertDateTimeToString(formData[INPUT_NAMES_LPDATA.HORARIO_DOMINGO_E_FERIADO]?.[1]),
                ]
            :   null,
    });

    return inputs;
}

export function annualConsumptionDefault(companyProfileId, { isAddFacility }) {
    let consumo_anual;
    switch (companyProfileId) {
        case getCompanyProfileIds().PPC: {
            consumo_anual = 50000; //kWh
            break;
        }
        case getCompanyProfileIds().ROMANDE: {
            consumo_anual = isAddFacility ? null : 50000; //kWh
            break;
        }
        case getCompanyProfileIds().PROFILE_BASE_IT: {
            consumo_anual = 50000; //MWh
            break;
        }
        default:
            consumo_anual = 90000;
            break;
    }

    return consumo_anual;
}

export function getLoadDataB2B(inputs, options) {
    let { isMWh, isAddFacility, companyProfileId, clientCAE5, consumptionWeightsOpts, configs } = options;
    let defaultInputs = null;

    if (isAddFacility) {
        //#region AddFacility
        let consumo_anual =
            isDefined(configs?.new_facility_default_data) ?
                parseFloat(configs?.new_facility_default_data?.consumption_kwh)
            :   annualConsumptionDefault(companyProfileId, { isAddFacility });

        inputs[INPUT_NAMES.OPCAO_CURVA_CARGA_ID] = OPTS_LOAD_CURVE_B2B.SIMPLE_ESTIMATION;
        inputs[INPUT_NAMES.SIMPLE_ESTIMATION_ID] = SIMPLE_ESTIMATION_IDS.ACTIVITY_CODE;
        inputs[INPUT_NAMES.CONSUMO_ANUAL] =
            isMWh ?
                isNumberDefined(consumo_anual) ? convertToUnit(parseFloat(consumo_anual), 'fromKwToMw')
                :   null
            :   consumo_anual;
        inputs[INPUT_NAMES.WORKING_DAYS] = true;
        inputs[INPUT_NAMES.TRABALHA_SAB] = false;
        inputs[INPUT_NAMES.TRABALHA_DOM] = false;
        inputs[INPUT_NAMES.TRABALHA_FERIADO] = false;
        inputs[INPUT_NAMES.HORARIO_DIAS_SEMANA] = [formatDate(null, true), formatDate(null)];
        inputs[INPUT_NAMES.HORARIO_SABADO] = [formatDate(null, true), formatDate(null)];
        inputs[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO] = [formatDate(null, true), formatDate(null)];

        //#endregion AddFacility
    } else {
        //edit
        //#region editFacility
        inputs[INPUT_NAMES.CONSUMO_ANUAL] =
            isMWh ?
                convertToUnit(parseFloat(inputs?.[INPUT_NAMES.CONSUMO_ANUAL]), 'fromKwToMw')
            :   inputs?.[INPUT_NAMES.CONSUMO_ANUAL] ?? '';
        inputs[INPUT_NAMES.WORKING_DAYS] =
            inputs[INPUT_NAMES.TRABALHA_2A] &&
            inputs[INPUT_NAMES.TRABALHA_3A] &&
            inputs[INPUT_NAMES.TRABALHA_4A] &&
            inputs[INPUT_NAMES.TRABALHA_5A] &&
            inputs[INPUT_NAMES.TRABALHA_6A];

        inputs[INPUT_NAMES.HORARIO_DIAS_SEMANA] = [
            formatDate(inputs?.[INPUT_NAMES.HORARIO_DIAS_SEMANA]?.[0], true),
            formatDate(inputs?.[INPUT_NAMES.HORARIO_DIAS_SEMANA]?.[1]),
        ];
        inputs[INPUT_NAMES.HORARIO_SABADO] = [
            formatDate(inputs?.[INPUT_NAMES.HORARIO_SABADO]?.[0], true),
            formatDate(inputs?.[INPUT_NAMES.HORARIO_SABADO]?.[1]),
        ];
        inputs[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO] = [
            formatDate(inputs?.[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]?.[0], true),
            formatDate(inputs?.[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]?.[1]),
        ];

        inputs[INPUT_NAMES.SIMPLE_ESTIMATION_ID] = getSimpleEstimationId(inputs);
        //consumption_weights
        inputs[INPUT_NAMES.CONSUMPTION_WEIGHTS] = getFormWeightsConsumption(consumptionWeightsOpts);

        //#endregion editFacility
    }

    // common
    inputs[INPUT_NAMES.ACTIVITY_CODE] = clientCAE5; //default

    // defaults
    defaultInputs = clone({
        [INPUT_NAMES.OPCAO_CURVA_CARGA_ID]: `${inputs?.[INPUT_NAMES.OPCAO_CURVA_CARGA_ID]}`,
        [INPUT_NAMES.NOME_FICHEIRO_CURVA_CARGA]: inputs?.[INPUT_NAMES.NOME_FICHEIRO_CURVA_CARGA] ?? '',
        [INPUT_NAMES.CONSUMO_ANUAL]: `${isNumberDefined(inputs?.[INPUT_NAMES.CONSUMO_ANUAL]) ? inputs?.[INPUT_NAMES.CONSUMO_ANUAL].toFixed(2) : configs?.new_facility_default_data?.consumption_kwh ?? ''}`,
        [INPUT_NAMES.ACTIVITY_CODE]: `${inputs?.[INPUT_NAMES.ACTIVITY_CODE]}`,
        [INPUT_NAMES.WORKING_DAYS]: inputs[INPUT_NAMES.WORKING_DAYS],
        [INPUT_NAMES.TRABALHA_SAB]: inputs[INPUT_NAMES.TRABALHA_SAB],
        [INPUT_NAMES.TRABALHA_DOM]: inputs[INPUT_NAMES.TRABALHA_DOM],
        [INPUT_NAMES.TRABALHA_FERIADO]: inputs[INPUT_NAMES.TRABALHA_FERIADO],
        [INPUT_NAMES.HORARIO_DIAS_SEMANA]: inputs[INPUT_NAMES.HORARIO_DIAS_SEMANA],
        [INPUT_NAMES.HORARIO_SABADO]: inputs[INPUT_NAMES.HORARIO_SABADO],
        [INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]: inputs[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO],
        [INPUT_NAMES.SIMPLE_ESTIMATION_ID]: inputs?.[INPUT_NAMES.SIMPLE_ESTIMATION_ID] ?? null,
        [INPUT_NAMES.CONSUMPTION_WEIGHTS]: inputs?.[INPUT_NAMES.CONSUMPTION_WEIGHTS] ?? {},
        [INPUT_NAMES.TEM_DADOS_PONDERADOR_INTEGRACAO]: inputs?.[INPUT_NAMES.TEM_DADOS_PONDERADOR_INTEGRACAO] ?? '',
        [INPUT_NAMES.TEM_TELECONTAGEM]: inputs?.[INPUT_NAMES.TEM_TELECONTAGEM] ?? null,
        [INPUT_NAMES.ADJUST_WORK_DAYS]: inputs?.[INPUT_NAMES.ADJUST_WORK_DAYS] ?? null,
    });

    return defaultInputs;
}

export function getQuestionsLoadDataB2B(options) {
    const { companyProfileId, isAddFacility, inputs, userTypeID, is_efz, isDemo } = options;
    let questions = clone(QUESTIONS_LPDATA_B2B);

    questions[INPUT_NAMES.CONSUMO_ANUAL].visible = true;

    // CONSUMO_ANUAL
    if ([getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
        questions[INPUT_NAMES.CONSUMO_ANUAL].disabled = isDemo || is_efz ? false : true;

        // CHANNEL
        if ([USERS_TYPE_ID.CHANNEL].includes(userTypeID)) {
            questions[INPUT_NAMES.CONSUMO_ANUAL].disabled = true;
        }
    }

    questions[INPUT_NAMES.CONSUMO_ANUAL].unit = [getCompanyProfileIds().ROMANDE].includes(companyProfileId) ? 'kwh' : 'mwh';
    if (questions[INPUT_NAMES.CONSUMO_ANUAL].unit === 'kwh') {
        questions[INPUT_NAMES.CONSUMO_ANUAL].validation = {
            ...questions[INPUT_NAMES.CONSUMO_ANUAL].validation,
            max: {
                ...questions[INPUT_NAMES.CONSUMO_ANUAL].validation.max,
                value: convertToUnit(questions[INPUT_NAMES.CONSUMO_ANUAL].validation.max.value, 'fromMwToKw'),
            },
        };
    }

    // OPCAO_CURVA_CARGA_ID options visible by companyProfileId
    questions[INPUT_NAMES.OPCAO_CURVA_CARGA_ID].options.map((elm) => {
        // hide options INTEGRATION_DATA
        if (
            !isAddFacility &&
            elm.value === OPTS_LOAD_CURVE_B2B.INTEGRATION_DATA &&
            [getCompanyProfileIds().EDP_PT, getCompanyProfileIds().EDP_ES].includes(companyProfileId)
        ) {
            elm.disabled = !inputs?.tem_dados_integracao_estatisticos && !inputs?.tem_dados_integracao_reais;
            elm.visible = !elm.disabled;
        }

        if (isAddFacility && elm.value === OPTS_LOAD_CURVE_B2B.UPLOAD_CURVE) {
            elm.visible = false;
        }

        // CHANNEL rules
        if ([USERS_TYPE_ID.CHANNEL].includes(userTypeID)) {
            elm.disabled = [getCompanyProfileIds().EDP_PT].includes(companyProfileId);

            // options
            elm.options =
                elm?.options?.map((opt) => ({
                    ...opt,
                    disabled: [getCompanyProfileIds().EDP_PT].includes(companyProfileId),
                })) ?? [];
        }

        return elm;
    });

    questions[INPUT_NAMES.OPCAO_CURVA_CARGA_ID].disabled =
        [getCompanyProfileIds().EDP_PT].includes(companyProfileId) && [USERS_TYPE_ID.CHANNEL].includes(userTypeID);

    //Tooltip Link CAE
    let hasTooltipLinkCAE = Object.keys(AVAILABLE_LINKS_CAE).includes(`${companyProfileId}`);
    questions[INPUT_NAMES.ACTIVITY_CODE].tooltipLink = hasTooltipLinkCAE ? AVAILABLE_LINKS_CAE[companyProfileId] : null;
    questions[INPUT_NAMES.ACTIVITY_CODE].validation = {
        ...questions[INPUT_NAMES.ACTIVITY_CODE].validation,
        validate: {
            ...questions[INPUT_NAMES.ACTIVITY_CODE].validation.validate,
            validCae: (v) => !isInvalidCAE(v) || 'page.facility.error.notValidCAE',
        },
    };

    // DAYS_OF_WORK
    let opcao_curva_carga_id = inputs?.[INPUT_NAMES.OPCAO_CURVA_CARGA_ID];
    if (questions[INPUT_NAMES.DAYS_OF_WORK]?.conditionToShowDaysOfWord?.[opcao_curva_carga_id]?.[companyProfileId]) {
        let rules = questions[INPUT_NAMES.DAYS_OF_WORK].conditionToShowDaysOfWord[opcao_curva_carga_id][companyProfileId].rules;
        questions[INPUT_NAMES.DAYS_OF_WORK].conditionToShowDaysOfWord[opcao_curva_carga_id][companyProfileId].visible =
            rules?.userTypeId === userTypeID &&
            rules?.adjust_work_days === inputs?.adjust_work_days &&
            rules?.tem_telecontagem === inputs?.tem_telecontagem;
    }

    return { questions };
}

//#endregion /** B2B **/

export const formatDate = (date, isStart = false) => {
    let now = new Date();

    if (!date) date = isStart ? '09:00:00' : '18:00:00';

    let isDate = date instanceof Date;
    if (date && !isDate) {
        let [hour, minute] = date.split(':');

        now.setHours(hour);
        now.setMinutes(minute);
        now.setSeconds(0);
        return now;
    }

    return date;
};

export function getOptions(responses) {
    let RQST_IDX = {
        equipments: 0,
        weekendInHouse: 1,
        workDaysOccupationPeriod: 2,
        firstArrivals: 3,
        consumptionWeightsOpts: 4,
    };

    //#region options by B2C
    let equipmentsOpts = responses?.[RQST_IDX.equipments]?.data?.data ?? [];
    let weekendInHouse = responses?.[RQST_IDX.weekendInHouse]?.data?.data ?? [];
    let workDaysOccupationPeriod = responses?.[RQST_IDX.workDaysOccupationPeriod]?.data?.data ?? [];
    let firstArrivals = responses?.[RQST_IDX.firstArrivals]?.data?.data ?? [];
    let consumptionWeightsOpts = responses?.[RQST_IDX.consumptionWeightsOpts]?.data?.data ?? [];
    //#endregion options by B2C

    return {
        equipmentsOpts,
        weekendInHouse,
        workDaysOccupationPeriod,
        firstArrivals,
        consumptionWeightsOpts,
    };
}

export function getConsumptionWeightsOpts({ data, activity_code }, abortController) {
    let { id, opcao_curva_carga_id, tem_dados_ponderador_estimativa_simples, tem_dados_ponderador_estimativa_simples_por_periodo } = data;

    // getEstimateWeightsByCAE
    if (parseInt(opcao_curva_carga_id) === OPTS_LOAD_CURVE_B2B.SIMPLE_ESTIMATION) {
        let estimationByActivityCode = !tem_dados_ponderador_estimativa_simples && !tem_dados_ponderador_estimativa_simples_por_periodo;
        let EstimationByCustom = tem_dados_ponderador_estimativa_simples && !tem_dados_ponderador_estimativa_simples_por_periodo;
        // let EstimationByPeriods = (!tem_dados_ponderador_estimativa_simples && tem_dados_ponderador_estimativa_simples_por_periodo);

        if (estimationByActivityCode) {
            return getEstimateWeightsByActivityCode(activity_code, abortController);
        }

        if (EstimationByCustom) {
            return getEstimateWeightsByCustom(id, abortController);
        }
    }

    return null;
}
export const getFormWeightsConsumption = (consumptionWeightsOpts) => {
    let consumption_weights = {};
    consumptionWeightsOpts.forEach((el) => {
        consumption_weights = {
            ...consumption_weights,
            [el.inputName]: el.monthly_consumption.toFixed(2),
        };
    });

    return consumption_weights;
};

export const getCalcWeightsConsumptionDefault = () => {
    let consumptionWeightsDefault = [];
    let weightDefault = parseFloat((1 / 12).toFixed(4));
    MONTHS.forEach((item) => {
        consumptionWeightsDefault.push({
            ...item,
            weight: weightDefault,
        });
    });
    return consumptionWeightsDefault;
};
export const getCalcWeightsConsumption = (consumption_weights, consumptionWeightsOpts) => {
    if (!!consumptionWeightsOpts && Array.isArray(consumptionWeightsOpts)) {
        // consumo_anual
        let consumo_anual = 0;
        consumptionWeightsOpts.forEach((el, idx) => {
            let monthly_consumption = parseFloat(consumption_weights?.[el.inputName]);
            consumptionWeightsOpts[idx].monthly_consumption = isNumberDefined(monthly_consumption) ? parseFloat(monthly_consumption) : 0;
            consumo_anual += isNumberDefined(monthly_consumption) ? parseFloat(monthly_consumption) : 0;
        });

        consumo_anual = !isNumberDefined(consumo_anual) || consumo_anual === -1 ? 0 : consumo_anual;

        //newConsumptionWeightsOpts
        let newConsumptionWeightsOpts = clone(consumptionWeightsOpts).map((item) => {
            let monthly_consumption =
                isNumberDefined(consumption_weights?.[item.inputName]) ? parseFloat(consumption_weights?.[item.inputName]) : 0;
            let weight = monthly_consumption / consumo_anual;
            return {
                ...item,
                month: item.month,
                monthly_consumption,
                weight,
            };
        });

        return {
            consumo_anual: parseFloat(consumo_anual.toFixed(2)),
            newConsumptionWeightsOpts,
        };
    }
    return null;
};

export const calcWeightsSumAndMonthlyConsumption = (consumptionWeightsOpts, { unit, consumo_anual }) => {
    //TODO: Validar o editar a estimativa de consumo. e ver se mantem os valores da unidade!
    //TODO: Validar a romande no init não esta bem a distribuiçao por CAE
    //TODO: Validar a romande

    if (!Array.isArray(consumptionWeightsOpts)) return null;

    let defaultConsumptionWeights = clone(DEFAULT_CONSUMPTION_WEIGHTS);
    let consumptionWeights = clone(consumptionWeightsOpts);

    if (consumptionWeights.length === 0) {
        //create default array
        for (let month = 1; month <= 12; month++) {
            consumptionWeights.push({ month, weight: 0 });
        }
    }

    //Constant Weight Sum
    let weightsSum = consumptionWeights.reduce((accumulator, currentValue) => ({ weight: accumulator.weight + currentValue.weight }), {
        weight: 0,
    }).weight;

    consumptionWeights = consumptionWeights.map((item) => {
        consumo_anual = !consumo_anual && consumo_anual === -1 ? 0 : consumo_anual;
        let monthly_consumption = weightsSum > 0 ? (item.weight / weightsSum) * consumo_anual : 0;
        return {
            ...defaultConsumptionWeights[item.month],
            ...item,
            unit,
            monthly_consumption,
        };
    });

    return consumptionWeights;
};

export function getPayloadLoadProfileB2B(facilityId, inputs) {
    if (!isFieldDefined(inputs)) return { id: facilityId, body: null };

    let {
        isMWh,
        opcao_curva_carga_id,
        consumo_anual,
        opcao_tarifario_id,
        nivel_tensao_id,
        tem_dados_ponderador_estimativa_simples,
        tem_dados_ponderador_integracao_personalizada,
        tem_dados_ponderador_estimativa_simples_por_periodo,
    } = inputs;

    let _consumo_anual =
        isNumberDefined(parseFloat(consumo_anual)) ?
            isMWh ? convertToUnit(parseFloat(consumo_anual), 'fromMwToKw')
            :   parseFloat(consumo_anual)
        :   null;

    let body = {
        consumo_anual: isNumberDefined(parseFloat(_consumo_anual)) && _consumo_anual > 0 ? parseFloat(_consumo_anual) : null,
        opcao_curva_carga_id: isNumberDefined(opcao_curva_carga_id) ? parseInt(opcao_curva_carga_id) : null,
        opcao_tarifario_id: isNumberDefined(opcao_tarifario_id) ? parseInt(opcao_tarifario_id) : null,
        nivel_tensao_id: isNumberDefined(nivel_tensao_id) ? parseInt(nivel_tensao_id) : null,
        tem_dados_ponderador_integracao_personalizada:
            isFieldDefined(tem_dados_ponderador_integracao_personalizada) ? tem_dados_ponderador_integracao_personalizada : null,
        tem_dados_ponderador_estimativa_simples: tem_dados_ponderador_estimativa_simples ?? null,
        tem_dados_ponderador_estimativa_simples_por_periodo: tem_dados_ponderador_estimativa_simples_por_periodo ?? null,
        ...getInputsWorkingSchedule(inputs),
    };

    // cleanup
    body = removeFieldEmptyInObj(body);

    return {
        id: facilityId,
        body,
    };
}

//#region /** B2C **/

/* Methods Private */

/* Methods Public */
export function initialLProfileInputsB2C() {
    return {
        opcao_curva_carga_id: '',
        consumo_anual: '',
        inputs_consumo: {
            equipments: [],
            weekend_occupation_id: '',
            work_days_first_arrivals_id: '',
            work_days_occupation_period_id: '',
            work_days_occupied: '',
        },
    };
}

export function getLoadDataB2C(inputs, options) {
    let { equipmentsOpts, isAddFacility, companyProfileId, configs } = options;

    let defaultInputs = null;

    // INPUTS_CONSUMO
    let inputs_consumo = inputs?.[INPUT_NAMES.INPUTS_CONSUMO] ?? null;
    // EQUIPMENTS
    let equipments = inputs_consumo?.equipments ?? [];
    let newEquipments = equipmentsOpts
        .sort((a, b) => a.id - b.id)
        .map((el) => {
            el.value = equipments.includes(el.id);
            el.equipmentId = el.id;
            return el;
        });

    if (isAddFacility) {
        //defaults
        inputs[INPUT_NAMES.OPCAO_CURVA_CARGA_ID] = OPTS_LOAD_CURVE_B2C.SIMPLE_ESTIMATION;
        inputs[INPUT_NAMES.CONSUMO_ANUAL] =
            configs?.new_facility_default_data?.consumption_kwh ??
            CONSUMO_ANUAL_DEFAULT_VALUES_B2C?.[companyProfileId] ??
            CONSUMO_ANUAL_DEFAULT_VALUES_B2C?.DEFAULT;
    }

    // defaults
    defaultInputs = clone({
        facilityId: inputs?.id ?? null,
        [INPUT_NAMES.OPCAO_CURVA_CARGA_ID]: `${inputs?.[INPUT_NAMES.OPCAO_CURVA_CARGA_ID]}`,
        [INPUT_NAMES.CONSUMO_ANUAL]: `${inputs?.[INPUT_NAMES.CONSUMO_ANUAL]}`,
        [INPUT_NAMES.NOME_FICHEIRO_CURVA_CARGA]: `${inputs?.[INPUT_NAMES.NOME_FICHEIRO_CURVA_CARGA]}`,
        [INPUT_NAMES.INPUTS_CONSUMO]: {
            [INPUT_NAMES.EQUIPMENTS]: newEquipments ?? [],
            [INPUT_NAMES.WEEKEND_OCCUPATION_ID]: `${inputs_consumo?.[INPUT_NAMES.WEEKEND_OCCUPATION_ID] ?? ''}`,
            [INPUT_NAMES.WORK_DAYS_FIRST_ARRIVALS_ID]: `${inputs_consumo?.[INPUT_NAMES.WORK_DAYS_FIRST_ARRIVALS_ID] ?? ''}`,
            [INPUT_NAMES.WORK_DAYS_OCCUPATION_PERIOD_ID]: `${inputs_consumo?.[INPUT_NAMES.WORK_DAYS_OCCUPATION_PERIOD_ID] ?? ''}`,
            [INPUT_NAMES.WORK_DAYS_OCCUPIED]: `${inputs_consumo?.[INPUT_NAMES.WORK_DAYS_OCCUPIED] ?? ''}`,
        },
    });
    return defaultInputs;
}

export function getQuestionsLoadDataB2C(options) {
    let { inputs, isAddFacility } = options;

    let { opcao_curva_carga_id } = inputs;

    let questions = clone(QUESTIONS_LPDATA_B2C);

    //Disable first options Condition
    let hasCurveOptsSelected = isAddFacility;
    if (!isAddFacility) {
        //Hide/Show input if has value on options
        hasCurveOptsSelected = questions[INPUT_NAMES.OPCAO_CURVA_CARGA_ID].options?.find((el) => el.value === opcao_curva_carga_id);
    }

    questions[INPUT_NAMES.OPCAO_CURVA_CARGA_ID].options.map((item) => {
        if (item.value === OPTS_LOAD_CURVE_B2C.CUSTOMIZED_CURVES) {
            item.disabled = true;
            item.visible = !hasCurveOptsSelected;
        }
        return item;
    });

    return { questions };
}

export function getPayloadLoadProfileB2C(facilityId, inputs) {
    let { opcao_curva_carga_id, consumo_anual, inputs_consumo } = inputs;
    let body = {
        opcao_curva_carga_id: isFieldDefined(opcao_curva_carga_id) ? parseInt(opcao_curva_carga_id) : null,
        consumo_anual: isNumberDefined(consumo_anual) && consumo_anual > 0 ? parseFloat(consumo_anual) : null,
        inputs_consumo:
            !isEmpty(inputs_consumo) ?
                {
                    equipments: inputs_consumo?.equipments?.filter((el) => el.value === true).map((el) => el.id),
                    work_days_occupied: inputs_consumo?.work_days_occupied ? getBoolean(inputs_consumo?.work_days_occupied) : null,
                    work_days_first_arrivals_id:
                        inputs_consumo?.work_days_first_arrivals_id ? parseInt(inputs_consumo?.work_days_first_arrivals_id) : null,
                    work_days_occupation_period_id:
                        inputs_consumo?.work_days_occupation_period_id ? parseInt(inputs_consumo?.work_days_occupation_period_id) : null,
                    weekend_occupation_id: inputs_consumo?.weekend_occupation_id ? parseInt(inputs_consumo?.weekend_occupation_id) : null,
                }
            :   null,
    };

    // cleanup
    body = removeFieldEmptyInObj(removeFieldNaNInObj(body));
    body.inputs_consumo = removeFieldEmptyInObj(removeFieldNaNInObj(body.inputs_consumo));

    return {
        id: facilityId,
        body,
    };
}
//#endregion  /** B2C **/
