import { spvSimpleActions, STEPS_OPEN_AREA } from 'constants/products/solarpvSimple';
import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';

import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as Tick } from 'assets/images/products/spv-simple/landing-page/tick.svg';
import LandingImageB2B from 'assets/images/products/spv-simple/landing-page/gr-solarnet-1100x880.webp';
import LandingImageB2C from 'assets/images/products/spv-simple/landing-page/panels-ppc.webp';
import CompanyTitleB2C from 'components/Products/solarpvSimple/b2c/CompanyTitle';
import CompanyTitleB2B from 'components/Products/solarpvSimple/b2b/CompanyTitle';
import { isDefined } from 'services/util/auxiliaryUtils';
import { Button } from '@save2compete/efz-design-system';
import { useUserStore } from 'store/user';

const LandingPageTopSection = ({ isB2C }) => {
    const { theme: userTheme } = useUserStore();

    const { spvSimpleDispatchHandler: dispatch } = useContext(SPVSimpleContext);

    const theme = useTheme();

    const bannerImage = isB2C ? LandingImageB2C : LandingImageB2B;
    const translation = isB2C ? 'b2c' : 'b2b';

    const CompanyTitle = isB2C ? <CompanyTitleB2C hasMargin /> : <CompanyTitleB2B hasMargin />;

    return (
        <>
            <section className="landing-page-container-top-b2b lbc-top-b2b">
                <div className="top-menu-section">
                    {CompanyTitle}
                    <h3>
                        <IntlMessages id={`page.spvSimple.open.landing.${translation}.subTitle1`} />
                    </h3>
                    <h4>
                        <IntlMessages id={`page.spvSimple.open.landing.${translation}.subSubTitle.1`} />
                    </h4>
                    <div className="top-menu-bullets-container">
                        {Array.from({ length: 4 }, (_, i) => i + 1).map((el) => {
                            return (
                                <div className="top-menu-bullet" key={`bullet-${el}`}>
                                    <Tick />
                                    <div className="top-menu-bullet-content">
                                        {el === 2 && isDefined(theme?.configs) ?
                                            <IntlMessages
                                                id={`page.spvSimple.open.landing.${translation}.bullet.${el}`}
                                                values={{ date: theme?.configs?.date_discount }}
                                            />
                                        :   <IntlMessages id={`page.spvSimple.open.landing.${translation}.bullet.${el}`} />}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <h4>
                        <IntlMessages id={`page.spvSimple.open.landing.${translation}.subSubTitle.2`} />
                    </h4>
                    <Button
                        loading={!userTheme}
                        disabled={!userTheme}
                        size="bg"
                        type="submit"
                        onClick={() => dispatch(spvSimpleActions.SET_STEP, STEPS_OPEN_AREA.HOUSING_TYPE)}
                        dataTestId="start-here"
                    >
                        <IntlMessages id="page.spvSimple.open.landing.button" />
                    </Button>
                </div>
                <div className="lbc-top-b2b-image">
                    <img src={bannerImage} alt="landing_image" />
                </div>
            </section>
        </>
    );
};

export default LandingPageTopSection;
