import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { UserContext } from 'contexts/userContext';
import { IBusinessModelsContext, TBMProjectSummary, TProductInputs } from 'interfaces/businessModels';
import { useContext } from 'react';
import KPI from 'components/BusinessModels/List/BusinessModels/BusinessModelPP/Card/Summary/Kpi';
import { serviceProjectSummary } from 'services/businessModels/service';
import { TService } from 'interfaces/businessModels/service';

interface IProps {
    projectSummary: TBMProjectSummary[];
}

const BusinessModelProjectSummaryService = ({ projectSummary }: IProps) => {
    const { productID, state } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TService>;

    const { companyProfileId } = useContext(UserContext);

    const kpis = serviceProjectSummary(projectSummary, state, { companyProfileId, productID });

    return <>{kpis.length > 0 ? kpis.map((kpi) => <KPI {...kpi} />) : <></>}</>;
};

export default BusinessModelProjectSummaryService;
