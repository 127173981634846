import { getMessageIncorrectData, getMessageUnexpectedError, getMessageServerError502 } from 'services/statusCodeHttp';

// * ACTION TYPES * \\
export const CHECK_STATUS_HTTP = 'CHECK_STATUS_HTTP';
//STATUS Successful responses
export const OK = 200;
export const CREATE = 201;
export const ACCEPTED = 202;
export const NO_CONTENT = 204;

//STATUS Client error responses
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const CONFLICT = 409;
export const UNPROCESSABLE_ENTITY = 422;

//STATUS Server error responses
export const TOO_MANY_REQUESTS = 429;
export const INTERNAL_SERVER_ERROR = 500;
export const BAD_GATEWAY = 502;
export const SERVICE_UNAVAILABLE = 503;
export const SERVICE_TEMPORARILY_UNAVAILABLE = 515;
export const CANCELED_BY_USER = 600; //status internal
export const RESPONSE_CANCELED_BY_USER = { status: 600, message: 'Operation canceled by the user.' }; //status internal

/**
 * END ACTION TYPES
 */

/**
 * getMessageError
 *
 * @param response
 * @returns {*}
 */
export function getMessageError(response) {
    let messageError = null;
    switch (response?.status) {
        case NO_CONTENT:
            messageError = getMessageIncorrectData();
            break;
        case UNAUTHORIZED:
            messageError = response.data.message;
            break;
        case BAD_GATEWAY:
            messageError = getMessageServerError502();
            break;
        case BAD_REQUEST:
        case INTERNAL_SERVER_ERROR:
        case SERVICE_UNAVAILABLE:
        case SERVICE_TEMPORARILY_UNAVAILABLE:
        case UNPROCESSABLE_ENTITY:
            messageError = getMessageUnexpectedError();
            break;
        default:
            if (response?.statusText) messageError = response.statusText;
            else if (!!response?.data && !!response?.data?.message) messageError = response.data.message;
            else messageError = response;
            break;
    }

    return messageError;
}

export const TOAST_SESSION_EXPIRED_ID = 'session_expired_notification';
export const TOAST_MSG_SERVER_ERROR_ID = 'msg_server_error_notification';
export const TOAST_MSG_BAD_REQUEST_ID = 'msg_bad_request_notification';
export const TOAST_CHECK_STATUS_ERROR_ID = 'check_status_notification';
