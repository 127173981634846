import { getCompanyProfileIds } from 'services/user';

export const lecsKpis = (kpis, monthlyPayment, companyProfileId) => {
    const annualEstimatedPrice = monthlyPayment * (kpis?.annual_prod / 1000);

    const acTariff = kpis?.ac_tariff * 1000;
    const annualNetSaving = (acTariff - monthlyPayment) * (kpis?.annual_ac / 1000);
    const discount = (acTariff - monthlyPayment) / acTariff;

    let _kpis = [
        {
            label: 'label.discount',
            value: typeof monthlyPayment === 'string' ? '-' : discount,
            numberOfDecimals: 2,
            unit: 'percent',
        },
        {
            label: 'label.annualEstimatedPrice',
            value: typeof monthlyPayment === 'string' ? '-' : annualEstimatedPrice,
            numberOfDecimals: 0,
            unit: 'currency',
        },
        {
            label: 'label.annualNetSavings',
            value: typeof monthlyPayment === 'string' ? '-' : annualNetSaving,
            numberOfDecimals: 0,
            unit: 'currency',
        },
        {
            label: 'label.takers',
            value: typeof monthlyPayment === 'string' ? '-' : kpis?.local_energy_community?.num_takers,
            numberOfDecimals: 0,
        },
    ];

    if ([getCompanyProfileIds().EDP_IT].includes(companyProfileId)) {
        _kpis = _kpis.filter((kpi) => !['label.comercialDiscount'].includes(kpi?.label));
    }

    return _kpis;
};

export const detailedLECKpis = (negotiationProfile, finalKpis) => {
    return [
        {
            label: 'page.businessModels.tirWacc',
            value: finalKpis?.irr_wacc ?? 'N/A',
            numberOfDecimals: 2,
            visible: negotiationProfile?.slider_tir_wacc,
        },
        {
            label: 'page.businessModels.fixedComission',
            value: finalKpis?.fixed_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_fixa,
            unit: 'currency',
        },
        {
            label: 'page.businessModels.variableComission',
            value: finalKpis?.variable_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_variavel,
            unit: 'currency',
        },
        {
            label: 'page.businessModels.totalComission',
            value: finalKpis?.total_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_total,
            unit: 'currency',
        },
        {
            label: 'label.additionalValue',
            value: finalKpis?.additional_revenue ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.mostra_slider_valor_adicional,
            unit: 'currency',
        },
    ];
};

export const annualEvoFeeOptions = () => {
    const DEFAULT_FEE = 0;
    const step = 0.5;
    const maxFee = 2; //max 2.5 (2+0.5)
    let currentFee = 0;
    let options = [];

    while (currentFee <= maxFee + step) {
        options.push({
            key: `annual_evo_fee_${currentFee}`,
            default: currentFee === DEFAULT_FEE ? true : false,
            value: currentFee / 100,
        });
        currentFee = currentFee + step;
    }

    return options;
};

export const LECTariffUnit = (companyProfileId) => {
    switch (companyProfileId) {
        case getCompanyProfileIds().PROFILE_BASE_USA: {
            return 'ckwh';
        }
        default: {
            return 'cmwp';
        }
    }
};
