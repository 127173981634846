/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Reducers - ALL
 * @see redux reducers docs: (basic) https://redux.js.org/basics/reducers  || (combinereducers) https://redux.js.org/api/combinereducers
 *
 * @version 20191220 - added WebStomp
 * @version 20191126 - added Sizing
 * @version 20191115 - added Facilities
 * @version 20190715
 * @since 20190715 Initial release
 *
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Settings from './settings';
import Client from './client';
import Facility from './facility';
import Utils from './utils';
import Product from './product';
import BusinessModel from './businessModels';
import Proposal from './proposal';
import Sizing from './sizing';

const combinedReducers = (history) =>
    combineReducers({
        router: connectRouter(history),
        settings: Settings,
        client: Client,
        facility: Facility,
        product: Product,
        sizing: Sizing,
        businessModels: BusinessModel,
        proposal: Proposal,
        utils: Utils,
    });

export default combinedReducers;
