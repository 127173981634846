import { GA_LABEL_CFP } from 'constants/gAnalytics';
import { MODULES } from 'constants/products';

// * DEFAULT OBJECTS * \\
export const INPUTS_DEFAULTS = {
    battery_power: 10,
    extra_cost: 0,
    has_harmonics: false,
};

export const INPUT_NAMES = {
    HAS_HARMONICS: 'has_harmonics',
    BATTERY_POWER: 'battery_power',
    EXTRA_COST: 'extra_cost',
};

// QUESTIONS
export const QUESTIONS = {
    [INPUT_NAMES.HAS_HARMONICS]: {
        order: 1,
        modules: [MODULES.SIMPLE],
        name: INPUT_NAMES.HAS_HARMONICS,
        label: 'page.cfp.question.has_harmonics',
        subtitleLabel: null,
        tooltip: null,
        disabled: false,
        answers: [
            {
                label: 'label.yes',
                value: 'true',
                showHelperText: false,
                gAnalyticsEvt: GA_LABEL_CFP.FILTER_YES,
            },
            {
                label: 'label.no',
                value: 'false',
                showHelperText: false,
                gAnalyticsEvt: GA_LABEL_CFP.FILTER_NO,
            },
        ],
        options: {
            helperText: 'page.cfp.question.has_harmonics.helperText',
            inRow: true,
        },
    },
    [INPUT_NAMES.BATTERY_POWER]: {
        order: 2,
        modules: [MODULES.SIMPLE],
        name: INPUT_NAMES.BATTERY_POWER,
        label: 'page.cfp.question.battery_power',
        subtitleLabel: null,
        tooltip: null,
        disabled: false,
        answers: [], //find answers: batteryPowers
        options: {
            helperText: 'page.cfp.question.battery_power.helperText',
            warning: 'page.cfp.question.warning',
            warningCondition: false,
        },
    },
};
