import IntlMessages from 'components/util/IntlMessages';
import { IconButton } from '@save2compete/efz-design-system';

const GrantsTitle = ({ label, isOpen, collapseHandler }: { label: string; isOpen: boolean; collapseHandler: () => void }) => {
    return (
        <div className={`bm-grants-title ${!isOpen ? 'collapsed' : ''}`}>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <span>
                        <IntlMessages id={label} />
                    </span>
                    <hr />
                </div>
                <IconButton
                    variant="tertiary"
                    icon={isOpen ? 'arrowHeadUp' : 'arrowHeadDown'}
                    size="bg"
                    onClick={collapseHandler}
                    dataTestId="bm-grants-icon"
                />
            </div>
        </div>
    );
};

export default GrantsTitle;
