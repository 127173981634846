import IntlMessages from 'components/util/IntlMessages';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { useContext, useCallback, useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import FormatNumber from 'components/util/FormatNumber';
import { debounce } from 'services/util/auxiliaryUtils';
import { DEBOUNCE_TIME } from 'constants/endpoints';
import { PaPEvents, TPaP } from 'interfaces/businessModels/pap';

const PapMargin = () => {
    const { bmSelected, setBMEventHandler } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;
    const paMarginValues = bmSelected?.values?.kpis_per_margin;
    const defaultValue = paMarginValues?.find((margin) => margin?.is_default)?.saving_share ?? paMarginValues?.[0]?.saving_share;

    const [sliderValue, setSliderValue] = useState(defaultValue);

    const values = paMarginValues.map((kpi) => {
        return { value: kpi?.saving_share };
    });
    const handleSliderChange = useCallback((_, newValue) => setSliderValue(newValue), []);

    let sliderStep = parseFloat((paMarginValues?.[1]?.saving_share - paMarginValues?.[0]?.saving_share).toFixed(5));
    if (sliderStep < 0) sliderStep = -sliderStep;

    // eslint-disable-next-line
    const delayedNewMarginHandler = useCallback(
        debounce((payload) => setBMEventHandler(PaPEvents.SET_PAP_MARGIN, Number(payload)), DEBOUNCE_TIME),
        []
    ); // eslint-disable-line

    useEffect(() => {
        delayedNewMarginHandler(sliderValue);
    }, [sliderValue]); // eslint-disable-line

    return (
        <div className="bm-margin">
            <div className="bm-margin-label">
                <IntlMessages id="label.serviceAux.selectAdditionalPercentShared" />
            </div>
            <div className="bm-margin-slider">
                {bmSelected?.values?.kpis_per_margin?.length > 0 && (
                    <Slider
                        aria-label="margin-slider"
                        aria-labelledby="discrete-slider-restrict"
                        defaultValue={sliderValue}
                        min={values[0].value}
                        max={values[values.length - 1]?.value}
                        step={null}
                        marks={values}
                        valueLabelDisplay="on"
                        // @ts-ignore
                        valueLabelFormat={(value) => <FormatNumber styleFormat="percent" number={value} numberOfDecimalPlaces={2} />}
                        onChange={handleSliderChange}
                        value={sliderValue}
                    />
                )}
            </div>
        </div>
    );
};

export default PapMargin;
