import { Button } from '@save2compete/efz-design-system';
import IntlMessages from 'components/util/IntlMessages';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { ServiceV2Events, TServiceV2 } from 'interfaces/businessModels/serviceV2';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { removeEmptyInObj } from 'services/util/auxiliaryUtils';

const ProposalGenerationButtonServiceV2 = () => {
    const { setBMEventHandler } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TServiceV2>;

    const { watch } = useFormContext();

    const onProposalGenerationClick = () => {
        const formInputs = watch();

        const valuesToUpdate = {
            clientContactInfo: null,
            id_crm: null,
        };
        if (formInputs?.contact_info) valuesToUpdate.clientContactInfo = formInputs.contact_info;
        if (formInputs?.id_crm) valuesToUpdate.id_crm = formInputs.id_crm;

        const hasValuesToUpdate = !!valuesToUpdate.clientContactInfo || !!valuesToUpdate.id_crm;
        if (hasValuesToUpdate) setBMEventHandler(ServiceV2Events.UPDATE_SERVICEV2_ADDITIONAL_DATA, removeEmptyInObj(valuesToUpdate));

        setTimeout(() => {
            setBMEventHandler(ServiceV2Events.SET_SERVICEV2_PROPOSAL, null);
        }, 100);
    };

    return (
        <Button
            onClick={onProposalGenerationClick}
            className="bm-proposal-generation-button"
            dataTestId="bm-proposal-generation-service-v2"
        >
            <IntlMessages id="label.proposalGeneration" />
        </Button>
    );
};

export default ProposalGenerationButtonServiceV2;
