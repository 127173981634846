import { useEffect, useContext, useCallback } from 'react';

// Interfaces
import { IBusinessModel, IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { BusinessModelsActions } from 'interfaces/businessModels/reducer';
import { GrantsAmmountsType } from 'interfaces/businessModels/upfront';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { PPaEvents, TPPa } from 'interfaces/businessModels/ppa';

// Constants
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';

// Services
import { isDefined } from 'services/util/auxiliaryUtils';
import { grantCurrencyAmmount, grantPrcAmmount, updateTotalGrantsHandler } from 'services/businessModels/upfront';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';

const usePPaBM = (bm: IBusinessModel) => {
    const { state, bmEvent, dispatch, productPayload, setBMEventHandler } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TPPa
    >;

    const { setBMEventHandler: syncSetBMEventHandler } = useContext(OfferEditionContext);

    const ppAEvents = useCallback(() => {
        const costSelected = state.selected.values?.marginSelected?.cost ?? productPayload?.energy_price_irr_annual_savings?.[0];
        const currentGrants = state.selected.values?.grants?.grants;
        const baseCost = state?.simulation?.kpis?.cost;
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.SERVICE_PPA && isDefined(bmEvent?.name)) {
            const currentValues = state.selected.values;

            switch (bmEvent?.name) {
                case PPaEvents.SET_PPA_BM: {
                    const syncKpis = productPayload?.productInputs?.syncKpis?.find((bm) => bm.tipo_modelo_negocio_id === state.selected.id);

                    if (isDefined(syncKpis)) {
                        const businessModelSelected = state.businessModels.find(
                            (bm) => bm.payment_model_id === state.selected.paymentModelID
                        );
                        syncSetBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_SELECTED, {
                            businessModelSelected: businessModelSelected,
                            businessModelBody: productPayload,
                            kpis: syncKpis,
                            offerEdition: productPayload?.productInputs?.offerEdition,
                            elements: {
                                monthlyPayments:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'monthly_payment_option'
                                    ) ?? null,
                                omContractDuration:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'om_contract_duration'
                                    ) ?? null,
                                annualUpdateMonthlyFee:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'tariff_annual_update'
                                    ) ?? null,
                                uniqueAllowance:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'unique_allowance'
                                    ) ?? null,
                                yearlyAllowance:
                                    businessModelSelected?.financing_customization?.values?.find(
                                        (element) => element.tag === 'yearly_allowance'
                                    ) ?? null,
                            },
                        });
                        dispatch({
                            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                            payload: {
                                syncKpis: syncKpis,
                            },
                        });
                    } else {
                        const grantsPayload = {
                            hasGrants: state.selected.options?.has_subsidy ?? false,
                            totalGrants: {
                                currency: 0,
                                prc: 0,
                            },
                            grants: [
                                {
                                    id: 1,
                                    description: '',
                                    ammounts: {
                                        prc: 0,
                                        currency: 0,
                                    },
                                },
                            ],
                        };
                        const { energy_price_irr_annual_savings = [], cost } = productPayload;
                        const energyPriceArr = energy_price_irr_annual_savings.map((energyPrice, idx) => {
                            return { ...energyPrice, value: idx };
                        });
                        dispatch({
                            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                            payload: {
                                ...currentValues,
                                grants: grantsPayload,
                                energy_price_irr_annual_savings: energyPriceArr,
                                marginSelected: energyPriceArr[0],
                                totalCost: cost,
                            },
                        });
                    }
                    break;
                }
                case PPaEvents.SET_PPA_MARGIN:
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: { ...currentValues, marginSelected: bmEvent.payload },
                    });
                    break;
                case PPaEvents.ADD_PPA_GRANT: {
                    const higherGrantId =
                        currentGrants.length > 0 ?
                            currentGrants.reduce((acc, value) => {
                                return (acc = acc > value.id ? acc : value.id);
                            }, 0)
                        :   1;
                    const updatedGrants = [
                        ...currentGrants,
                        {
                            id: higherGrantId + 1,
                            description: '',
                            ammounts: {
                                prc: 0,
                                currency: 0,
                            },
                        },
                    ];
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state.selected.values.grants,
                                grants: updatedGrants,
                            },
                        },
                    });
                    break;
                }

                case PPaEvents.DELETE_PPA_GRANT: {
                    const grantsWithoutTheSelectedToDelete = currentGrants.filter((grant) => grant.id !== bmEvent.payload);
                    const updatedTotalGrantsWithoutTheSelectedToDelete = {
                        currency: grantsWithoutTheSelectedToDelete?.reduce(
                            (accumulator, currentValue) => accumulator + currentValue.ammounts.currency,
                            0
                        ),
                        prc: grantsWithoutTheSelectedToDelete?.reduce(
                            (accumulator, currentValue) => accumulator + currentValue.ammounts.prc,
                            0
                        ),
                    };
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state.selected.values.grants,
                                totalGrants: updatedTotalGrantsWithoutTheSelectedToDelete,
                                grants: grantsWithoutTheSelectedToDelete,
                            },
                        },
                    });
                    break;
                }

                case PPaEvents.SET_DESCRIPTION_GRANT: {
                    const grantsWithUpdatedDescriptions = currentGrants.map((grant) => {
                        if (grant.id === bmEvent.payload.id) grant.description = bmEvent.payload.description;
                        return grant;
                    });
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state.selected.values.grants,
                                grants: grantsWithUpdatedDescriptions,
                            },
                        },
                    });
                    break;
                }

                case PPaEvents.SET_AMMOUNT_GRANT: {
                    const currentInvestment = costSelected;
                    const grantsWithUpdatedAmmounts = currentGrants.map((grant) => {
                        if (grant.id === bmEvent.payload.id)
                            grant.ammounts =
                                bmEvent.payload.ammountType === GrantsAmmountsType.CURRENCY ?
                                    grantCurrencyAmmount(currentInvestment, bmEvent.payload.amount)
                                :   grantPrcAmmount(currentInvestment, bmEvent.payload.amount);
                        return grant;
                    });

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state.selected.values.grants,
                                totalGrants: updateTotalGrantsHandler(grantsWithUpdatedAmmounts),
                                grants: grantsWithUpdatedAmmounts,
                            },
                        },
                    });

                    break;
                }
                case PPaEvents.UPDATE_PPA_GRANTS: {
                    const currentInvestment = costSelected;
                    const newCurrentGrants = [...currentGrants];
                    const updatedGrants = newCurrentGrants.map((grant) => {
                        if (!(grant?.ammounts?.fixed ?? false)) grant.ammounts = grantPrcAmmount(currentInvestment, grant.ammounts.prc);
                        else grant.ammounts = grantCurrencyAmmount(currentInvestment, grant.ammounts.currency);

                        return grant;
                    });

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state.selected.values.grants,
                                totalGrants: updateTotalGrantsHandler(updatedGrants),
                                grants: updatedGrants,
                            },
                        },
                    });
                    break;
                }
                case PPaEvents.SET_EXTRA_COST: {
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            offerEdition: {
                                ...currentValues.offerEdition,
                                ...bmEvent.payload,
                            },
                            totalCost: baseCost + bmEvent.payload.extra_cost,
                        },
                    });
                    break;
                }
                case PPaEvents.SET_PPA_PROPOSAL: {
                    let currentSimulation = {
                        ...state.simulation,
                        inputs: {
                            ...state.simulation.inputs,
                            service_energy_price: state.selected.values.marginSelected.energy_price ?? 0,
                        },
                        tipo_modelo_negocio_id: state.selected.id,
                        register_simulation: false,
                    };

                    if (state.selected.values?.grants?.totalGrants?.currency > 0) {
                        currentSimulation = {
                            ...currentSimulation,
                            inputs: {
                                ...currentSimulation.inputs,
                                grants: state.selected.values.grants.grants.map((grant) => {
                                    return {
                                        description: grant.description,
                                        prc: grant.ammounts.prc / 100,
                                    };
                                }),
                            },
                        };
                    }

                    if (isDefined(currentSimulation.fromSpvV3))
                        currentSimulation.inputs.fe_version = currentSimulation.fromSpvV3 ? 'v3' : 'v2';

                    dispatch({
                        type: BusinessModelsActions.SET_PROPOSAL,
                        payload: currentSimulation,
                    });
                    break;
                }
            }
        }
    }, [bmEvent]); // eslint-disable-line

    useEffect(() => {
        ppAEvents();
    }, [ppAEvents]);

    useEffect(() => {
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.SERVICE_PPA) {
            setBMEventHandler(PPaEvents.SET_PPA_BM, null);
        }
    }, [state.selected.paymentModelID]); // eslint-disable-line

    return {
        pitch: bm.pitch,
        projectSummary: bm.kpis_previews,
        selected: bm.selected,
        id: bm.payment_model_id,
        sizingTypeId: bm.sizing_type_id,
        syncKpis: productPayload?.productInputs?.syncKpis?.find((sincBm) => sincBm.tipo_modelo_negocio_id === bm.business_model_id),
    };
};

export default usePPaBM;
