/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux-sagas - Sizing
 *
 * @version 20191113
 * @since 20191113 Initial release
 *
 */

import { all, call, put, takeLatest } from 'redux-saga/effects';
//actions
import { requestGetSizingSuccess, requestGetSizingFail } from 'redux/actions/sizing';
//constants
import { REQUEST_GET_SIZING } from 'constants/sizing';
import { OK, UNAUTHORIZED } from 'constants/statusCodeHttp';
//api
import { postSizing } from 'api/sizing';

/**
 * _callRequestGetSizing  request an API
 *
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 * @private
 */
const _callRequestGetSizing = async (payload) =>
    await postSizing(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callRequestGetSizing ', error);
            return error;
        });

/**
 * _handlerGetRequestSearchClients
 *
 * @param payload
 * @returns {IterableIterator<PutEffect<{payload: *, type: *}>|CallEffect>}
 */
function* _handlerRequestGetSizing({ payload }) {
    try {
        const result = yield call(_callRequestGetSizing, payload);

        if (result?.status === OK) {
            yield put(requestGetSizingSuccess({ data: result.data }));
        }
        if (![OK, UNAUTHORIZED].includes(result?.status)) yield put(requestGetSizingFail()); //criar label
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestGetSizing ', error);
        return error;
    }
}

export default function* rootSaga() {
    yield all([takeLatest(REQUEST_GET_SIZING, _handlerRequestGetSizing)]);
}
