import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { useContext } from 'react';
import SavingsSharePap from './SavingsShare';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { Tlec } from 'interfaces/businessModels/lec';
import GrantsLEC from './Grants';

const ProjectSummaryLECSimple = () => {
    const { negotiationProfile, bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, Tlec>;
    return (
        <>
            {negotiationProfile?.mostra_subsidios && isFieldDefined(bmSelected?.values?.grants) ?
                <GrantsLEC />
            :   <></>}
            {bmSelected?.values?.proposalReady && bmSelected?.values?.kpis_per_margin?.length > 0 && <SavingsSharePap />}
        </>
    );
};

export default ProjectSummaryLECSimple;
