import { useContext } from 'react';
import IconButton from '@mui/material/IconButton';

// Icons
import { ReactComponent as OpenIcon } from 'assets/@efz/icons/sidebar-open.svg';
import { ReactComponent as CloseIcon } from 'assets/@efz/icons/sidebar-close.svg';

// Components
import IntlMessages from 'components/util/IntlMessages';

// Contexts
import { SidebarContext } from 'contexts/sidebarContext';

const SidebarOpener = () => {
    const { setSidebarOpen, sidebarOpen } = useContext(SidebarContext);

    const clickHandler = () => {
        setSidebarOpen((sidebarOpen) => !sidebarOpen);
    };

    return (
        <IconButton className="sidebar-opener" onClick={clickHandler} disableRipple>
            <span>
                <IntlMessages id="label.menu" />
            </span>
            {sidebarOpen ?
                <CloseIcon />
            :   <OpenIcon />}
        </IconButton>
    );
};

export default SidebarOpener;
