import PropTypes from 'prop-types';
import IntlMessages from 'components/util/IntlMessages';

const CookieBody = ({ body, updatedDate }) => {
    return (
        <div className="cookie-consent-body">
            <div className="cookie-consent-body-content">
                {body}
                <p className="consent-updated">
                    <IntlMessages id="label.lastUpdate" /> {updatedDate}
                </p>
            </div>
        </div>
    );
};

CookieBody.propTypes = {
    body: PropTypes.object.isRequired,
    updatedDate: PropTypes.string.isRequired,
};

export default CookieBody;
