import { memo, useContext } from 'react';
import NoBatteryIcon from 'assets/images/products/spv-simple/solution/no-battery.png';
import BatteryIcon from 'assets/images/products/spv-simple/solution/battery.png';
import { ReactComponent as DiscountIcon } from 'assets/images/products/spv-simple/discount.svg';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { SIMULATION_CARDS_ENTRIES, SOLUTIONS_TO_PRESENT_B2B } from 'constants/products/solarpvSimple';
import IntlMessages from 'components/util/IntlMessages';
import FormatNumber from 'components/util/FormatNumber';
import { calcConvertionToUnit, isFieldDefined } from 'services/util/auxiliaryUtils';
import { REMUNERATION_MODELS_TYPE } from 'constants/products/spvPro';
import CompanyTitleB2B from '../../CompanyTitle';
import { Button } from '@save2compete/efz-design-system';

const SimulationCard = ({ solution, contactsRef }) => {
    const has_battery = isFieldDefined(solution.battery_id);
    const investmentDiscountPercentage = 0.9;
    const VAT = 24;
    let { title, description, entries, remuneration_type_id } = SOLUTIONS_TO_PRESENT_B2B.find(
        (el) => el.remuneration_type_id === solution.remuneration_type_id && el.has_battery === isFieldDefined(solution.battery_id)
    );

    const {
        spvSimpleState: {
            options: { battery },
        },
        isOArea,
        setSelectedCard,
    } = useContext(SPVSimpleContext);

    const SolutionIcon = has_battery ? BatteryIcon : NoBatteryIcon;

    const getEntryLabel = (entry) => {
        switch (entry) {
            case SIMULATION_CARDS_ENTRIES.PANELS:
                return !has_battery ?
                        <IntlMessages id="page.spvSimple.simulation.card.panels.title" />
                    :   <IntlMessages id="page.spvSimple.simulation.card.panelsAndBattery.title" />;
            case SIMULATION_CARDS_ENTRIES.FOOTPRINT:
                return (
                    <>
                        <IntlMessages id="page.spvSimple.simulation.card.carbon_footprint.title" whitespaceEnd />
                        {'CO'}
                        <sub>2</sub>
                    </>
                );
            case SIMULATION_CARDS_ENTRIES.PRODUCTION:
                return <IntlMessages id="label.annual_production" />;
            case SIMULATION_CARDS_ENTRIES.PAYBACK:
                return <IntlMessages id="page.spvSimple.simulation.card.payback.title" />;
            case SIMULATION_CARDS_ENTRIES.INVESTMENT:
                return (
                    <>
                        <div>
                            <IntlMessages id="page.spvSimple.simulation.card.investment.title" />
                        </div>
                        <div className="subtitle">
                            <IntlMessages id="page.spvSimple.simulation.card.investment.info" values={{ vat: VAT }} />
                        </div>
                    </>
                );
            case SIMULATION_CARDS_ENTRIES.SAVINGS:
                return remuneration_type_id === REMUNERATION_MODELS_TYPE.FEED_IN_TARIFF ?
                        <IntlMessages id={`page.spvSimple.simulation.card.revenues.title`} />
                    :   <IntlMessages id={`page.spvSimple.simulation.card.savings.title`} />;
            default:
                return;
        }
    };

    const getEntryValue = (entry) => {
        switch (entry) {
            case SIMULATION_CARDS_ENTRIES.PANELS:
                return (
                    <>
                        <div className="text-bold">
                            {solution?.n_paineis} <IntlMessages id="label.panels" /> (
                            <FormatNumber
                                number={solution?.panel_peak_power * solution?.n_paineis}
                                unit={'kwp'}
                                numberOfDecimalPlaces={2}
                            />
                            )
                        </div>
                        {has_battery && (
                            <div className="text-bold">
                                1 <IntlMessages id="label.battery" /> (
                                <FormatNumber number={battery?.battery_capacity_selected_kwh} unit={'kwh'} numberOfDecimalPlaces={0} />)
                            </div>
                        )}
                        <div>
                            <IntlMessages id="label.recommended" />
                        </div>
                    </>
                );
            case SIMULATION_CARDS_ENTRIES.FOOTPRINT:
                return (
                    <div className="text-bold">
                        <FormatNumber number={solution?.carbon_footprint_reduction * 100} numberOfDecimalPlaces={0} unit={'percentage'} />
                    </div>
                );
            case SIMULATION_CARDS_ENTRIES.PRODUCTION:
                return (
                    <div className="text-bold">
                        <FormatNumber
                            number={calcConvertionToUnit(solution?.production, 'currencyMWhTokWh', 3)}
                            numberOfDecimalPlaces={1}
                            unit={'mwh'}
                        />
                    </div>
                );
            case SIMULATION_CARDS_ENTRIES.PAYBACK:
                // margin: [payback - 20%, payback + 20%]
                return (
                    <div className="text-bold">
                        <IntlMessages whitespaceEnd id="label.from" />
                        <FormatNumber number={solution?.payback * 0.8} numberOfDecimalPlaces={1} />
                        <IntlMessages whitespaceStart whitespaceEnd id="label.until" />
                        <FormatNumber number={solution?.payback * 1.2} numberOfDecimalPlaces={1} unit={'year'} />
                    </div>
                );
            case SIMULATION_CARDS_ENTRIES.INVESTMENT:
                return (
                    <>
                        <div>
                            <s>
                                <FormatNumber
                                    number={solution?.investment / investmentDiscountPercentage}
                                    numberOfDecimalPlaces={0}
                                    unit={'currency'}
                                />
                            </s>
                        </div>
                        <div className="text-bold">
                            <DiscountIcon className="me-1" />
                            <FormatNumber number={solution?.investment} numberOfDecimalPlaces={0} unit={'currency'} />
                        </div>
                    </>
                );
            case SIMULATION_CARDS_ENTRIES.SAVINGS:
                // margin: [savings - 20%, savings + 20%]
                return (
                    <div className="text-bold">
                        <FormatNumber number={solution?.savings * 0.8} numberOfDecimalPlaces={0} unit={'currency'} />
                        <IntlMessages whitespaceStart whitespaceEnd id="label.until" />
                        <FormatNumber number={solution?.savings * 1.2} numberOfDecimalPlaces={0} unit={'currency'} />
                    </div>
                );
            default:
                return;
        }
    };

    const handleClick = () => {
        setSelectedCard(solution.id);
        if (isOArea && typeof contactsRef?.current?.scrollIntoView === 'function') {
            if (!contactsRef?.current)
                // debounce; we need the ref component to be mounted!
                setTimeout(() => {
                    contactsRef.current.scrollIntoView({ behavior: 'smooth' });
                }, 200);
            else contactsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="simulation-card" key={`simulation-card ${solution.id}`}>
            <div className="simulation-card-banner">
                <IntlMessages
                    id={`label.remunerationModel.${remuneration_type_id === REMUNERATION_MODELS_TYPE.FEED_IN_TARIFF ? 'feedin_tariff' : 'net_metering'}`}
                />
            </div>
            <div className="simulation-card-header">
                <div className="image">
                    <img alt="icon" src={SolutionIcon} />
                </div>
                <div className="title">
                    <CompanyTitleB2B fontSize={6} isBlack productName={title} />
                </div>
                <div className="description">
                    <IntlMessages id={description} />
                </div>
            </div>
            <div className="simulation-card-body">
                {entries.map((entry, ind) => (
                    <>
                        <div className="simulation-card-body-entry">
                            <span className="label">{getEntryLabel(entry)}</span>
                            <span className="value">{getEntryValue(entry)}</span>
                        </div>
                        {ind + 1 < entries.length && <hr />}
                    </>
                ))}
            </div>
            {/* <div className="simulation-card-footer">
                <div>{getEntryValue(SIMULATION_CARDS_ENTRIES.SAVINGS)}</div>
                <div>{getEntryLabel(SIMULATION_CARDS_ENTRIES.SAVINGS)}</div>
            </div> */}
            <Button onClick={handleClick} dataTestId="simulation-card">
                <IntlMessages id="page.spvSimple.simulation.card.button" />
            </Button>
        </div>
    );
};

export default memo(SimulationCard);
