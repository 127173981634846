import { IRedux } from 'interfaces/redux';
import { createContext /*useReducer*/ } from 'react';
import { useSelector } from 'react-redux';
// import { reduxReducer } from "./reduxReducer";

const ReduxContext = createContext<IRedux>({
    auth: {},
    bmPro: {},
    businessModels: {},
    client: {},
    facility: {},
    product: {},
    proposal: {},
    router: {},
    settings: {},
    sizing: {},
    user: {},
    utils: {},
});

const ReduxProvider: React.FC<React.ReactNode> = ({ children }) => {
    const reduxState = useSelector((state: any) => state); // render title
    // const [state, /*dispatch*/] = useReducer(reduxReducer, reduxState);

    const reduxContextValues: IRedux = reduxState;

    return <ReduxContext.Provider value={reduxContextValues}>{children}</ReduxContext.Provider>;
};

export { ReduxContext, ReduxProvider };
