/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Reducers - Utils - dados necesários que são pedidos a API para o preenchimento de algum componente
 *                                      por exemplo 'api/counties' || 'api/facility/type' ou se
 *
 * @version 20191023
 * @since 20191023 Initial release
 *
 */

import clone from 'fast-copy';
import {
    INITIAL_UTILS,
    REQUEST_GET_WORK_SCHEDULE,
    REQUEST_GET_WORK_SCHEDULE_SUCCESS,
    REQUEST_GET_FACILITY_GROUPS,
    REQUEST_GET_FACILITY_GROUPS_SUCCESS,
    REQUEST_GET_COUNTIES_WITH_SEARCH,
    REQUEST_GET_COUNTIES_WITH_SEARCH_SUCCESS,
    REQUEST_GET_SEGMENTS,
    REQUEST_GET_SEGMENTS_SUCCESS,
    REQUEST_GET_SEGMENTS_FAIL,
    REQUEST_GET_RISKS,
    REQUEST_GET_RISKS_SUCCESS,
    REQUEST_GET_RISKS_FAIL,
} from 'constants/utils';
import { STATE_IDS } from 'constants/user';
import { showStateCurrent } from 'services/user';
import { orderByAlphabet } from 'services/dropdown';

const initialState = clone(INITIAL_UTILS);

const reducersUtils = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_GET_FACILITY_GROUPS:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_GET_FACILITY_GROUPS_SUCCESS:
            return {
                ...state,
                loading: true,
                facilityGroups: action.payload.data,
            };

        case REQUEST_GET_COUNTIES_WITH_SEARCH:
            return {
                ...state,
                counties: {
                    ...state.counties,
                    requesting: true,
                },
            };
        case REQUEST_GET_COUNTIES_WITH_SEARCH_SUCCESS:
            return {
                ...state,
                counties: {
                    list: action.payload.data,
                    requesting: false,
                },
            };
        case REQUEST_GET_WORK_SCHEDULE:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_GET_WORK_SCHEDULE_SUCCESS: {
            //ajustar objecto api !!!pedir a equipa de BE que altere o nome do objecto!!!
            let workSchedule = action.payload.data.map((day) => {
                return {
                    value: day.id,
                    label: day.descricao,
                };
            });

            return {
                ...state,
                workSchedules: workSchedule,
                loading: false,
            };
        }

        //#region SEGMENTS
        case REQUEST_GET_SEGMENTS:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_GET_SEGMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                segmentsList: orderByAlphabet(action.payload, 'descricao'),
            };
        case REQUEST_GET_SEGMENTS_FAIL:
            return {
                ...state,
                loading: false,
            };
        //#endregion SEGMENTS

        //#region RISKS
        case REQUEST_GET_RISKS:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_GET_RISKS_SUCCESS:
            return {
                ...state,
                loading: false,
                risksList: action.payload,
            };
        case REQUEST_GET_RISKS_FAIL:
            return {
                ...state,
                loading: false,
            };
        //#endregion RISKS

        default:
            return (
                showStateCurrent(STATE_IDS.PLATFORM) ?
                    !state ? clone(INITIAL_UTILS)
                    :   state
                :   null
            );
    }
};

export default reducersUtils;
