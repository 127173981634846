// Components
import Loading from 'components/core/Loading';
import IntlMessages from 'components/util/IntlMessages';

const GenerateProposal = () => {
    return (
        <div className="proposal-generate">
            <Loading size={50} />
            <span className="requesting-title mt-3">
                <IntlMessages id="page.proposal.text.generator.proposal" />
            </span>
            <span className="requesting-sub">
                <IntlMessages id="label.waitPlease" />
            </span>
        </div>
    );
};

export default GenerateProposal;
