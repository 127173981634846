import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { StatusCodes } from 'http-status-codes';

// Actions
import { setModulesAndSubProducts, setProductInfo, setProductModules } from 'redux/actions/product';
import { resetAllProductStates } from 'redux/actions/utils';

// API
import { getSolutions, putSolutions } from 'api/solutions';

// Constants
import { PROPOSAL_STATUS_IDS } from 'constants/efficiencyMeasures';
import { productModulesSubProducts, PRODUCT_IDS, SIMPLE } from 'constants/products';
import { BASENAME_URL_SOLUTIONS, BASENAME_URL_FACILITY, URL_BUSINESS_MODELS } from 'constants/settings';
import { PRODUCTS_WITH_TEASER_GENERATION } from 'constants/facility';

// Contexts
import { UserContext } from 'contexts/userContext';
import { SolutionsContext } from 'contexts/solutions/solutionsContext';

// Hooks
import useQuickTeaser from 'hooks/quickTeaser';
// Services
import { isDefined } from 'services/util/auxiliaryUtils';

// Interfaces
import { IGoToFacilityWithParams, IPotentialSolutionHandler, ISolutionsContext } from 'interfaces/solutions';
import { getClient } from 'redux/selectors/client';
import { putUpdateUser } from 'api/user';
import { TProductProjectsConfigs, useProjects } from 'store/solutions/projects';
import { useFeatureFlags } from 'store/featureFlags';
import { solutionWithProjectFeature } from 'services/projects';
import { useUserStore } from 'store/user';
import { useBusinessModelsStore } from 'store/businessModels';
import { isEnvDevFlag } from 'services/settings';

let abortGetSolutions;
let abortPutSolutions;

const abortAllAPIsCalls = () => {
    abortGetSolutions && abortGetSolutions.abort();
    abortPutSolutions && abortPutSolutions.abort();
};

const useSolutions = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const projectPid = useMemo(() => new URLSearchParams(location.search).get('pid'), [location.search]);

    const clientRedux = useSelector<any>((state) => getClient(state?.client)) as any;
    const {
        actions: { setSelected, setProjects, setFeatures },
    } = useProjects();
    const { updateUserTerms } = useUserStore();

    const { featureFlags } = useFeatureFlags();
    const {
        actions: { resetBusinessModelsStore },
    } = useBusinessModelsStore();

    const {
        client,
        facility,
        solutions,
        setSolutions,
        isRequestingSolutions,
        setIsUpdatingSolutions,
        setIsRequestingSolutions,
        solutionsEvent,
        setSolutionEvent,
        resetClientHandler,
        resetFacilityHandler,
        resetSolutionsHandler,
        setClient,
        openHasProposals,
        setOpenHasProposals,
        mayPassOpenProposals,
    } = useContext(SolutionsContext) as ISolutionsContext;

    const { userTypeID, companyProfileId, hasTermsAgreed } = useContext(UserContext);
    const { user } = useUserStore();

    const [isQuickTeaserDialogOpen, setIsQuickTeaserDialogOpen] = useState(false);

    const {
        generateQuickTeaser,
        isRequestingQuickTeaser,
        showhasProposalWarning,
        proposalID,
        isRequestingSendCRM,
        isRequestingEM,
        resetQTData,
        sentToCRM,
    } = useQuickTeaser(setIsQuickTeaserDialogOpen);

    const getSolutionshandler = async () => {
        setIsRequestingSolutions(true);

        abortGetSolutions && abortGetSolutions.abort();
        abortGetSolutions = new AbortController();
        const solutionsRsp = await getSolutions(facility?.id, abortGetSolutions);
        if (solutionsRsp?.status === StatusCodes.OK) {
            const _solutions = solutionsRsp?.data?.data?.efficiency_measures;
            const projectFeaturePerProduct: TProductProjectsConfigs = {} as TProductProjectsConfigs;
            _solutions.forEach((solution) => {
                projectFeaturePerProduct[solution.id] = solutionWithProjectFeature(solution);
            });
            setFeatures(projectFeaturePerProduct as any);
            setSolutions(_solutions);
        }
        setIsRequestingSolutions(false);
    };

    const resetHandler = () => {
        abortAllAPIsCalls();
        resetClientHandler();
        resetFacilityHandler();
        resetSolutionsHandler();
    };

    const selectSolutionHandler = (solution, options = { isV3: false, featureFlags }) => {
        const { isV3 = false } = options;
        const hasProposal =
            solution?.sims_count?.length > 0 &&
            isDefined(solution?.sims_count?.find((item) => item.id !== PROPOSAL_STATUS_IDS.CANCELED && item.sims_count > 0));

        if (mayPassOpenProposals && hasProposal && !openHasProposals) {
            setOpenHasProposals(solution);
            return;
        }

        //Set Default Product Info
        dispatch(setProductInfo(solution));

        //Get Modules and SubProducts from Defaults
        const modulesSubProducts = productModulesSubProducts(parseInt(solution.id), userTypeID, featureFlags);
        // @ts-ignore
        if (!!modulesSubProducts && [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB].includes(modulesSubProducts.id) && !isV3) {
            modulesSubProducts.modules = [SIMPLE];
        }
        const { id, subProducts, modules } = modulesSubProducts as any;

        //Set Modules and SubProducts
        dispatch(setModulesAndSubProducts(modulesSubProducts));

        if (solution.id === PRODUCT_IDS.CFP && !modules && !subProducts)
            return history.push(BASENAME_URL_SOLUTIONS + '/' + id + '/' + URL_BUSINESS_MODELS);
        if (subProducts) return history.push(BASENAME_URL_SOLUTIONS + '/' + id + '/sub-products');
        if (!!modules && modules.length === 1) {
            //Set module <name>
            dispatch(setProductModules({ type: 'module', name: modules[0].name }));
            //Send to modules
            return history.push(BASENAME_URL_SOLUTIONS + '/' + id + '/modules/' + modules[0].name);
        }
        if (!!modules && modules.length === 2) return history.push(BASENAME_URL_SOLUTIONS + '/' + id + '/modules');
        if (!!modules && modules.length === 0) return history.push(BASENAME_URL_SOLUTIONS + '/' + id);
    };

    const getSolutionById = (solutionID) => {
        return solutions.find((s) => s.id === solutionID);
    };

    const selectSolutionById = (solutionID) => {
        const thisSolution = getSolutionById(solutionID);
        if (thisSolution)
            setSolutionEvent({
                type: 'selectSolution',
                payload: {
                    solution: thisSolution,
                    ...(isEnvDevFlag(useFeatureFlags.getState().featureFlags['fe-2374']) && {
                        isV3: [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB].includes(
                            Number(solutionID) as typeof PRODUCT_IDS.SPV | typeof PRODUCT_IDS.SPVSB
                        ),
                    }),
                },
            });
    };

    const changeSolutionPotentialHandler = async (payload: IPotentialSolutionHandler) => {
        setIsUpdatingSolutions(true);
        const requestPayload = {
            efficiencyMeasureId: payload?.id,
            facilityId: facility?.id,
            body: {
                tem_potencial: payload?.potential,
                ...(payload?.potential ?
                    {}
                :   {
                        motivo_sem_potencial: payload?.reason ?? null,
                        comentario_sem_potencial: payload?.comments ?? null,
                    }),
            },
        };

        abortPutSolutions && abortPutSolutions.abort();
        abortPutSolutions = new AbortController();
        const rspChangePotential = await putSolutions(requestPayload, abortPutSolutions);

        if (rspChangePotential?.status === StatusCodes.OK) {
            isDefined(payload?.closeDialog) && payload.closeDialog?.();
            const newSolutions = [...solutions];
            newSolutions.map((solution) => {
                if (solution.id === payload?.id) {
                    solution.tem_potencial = payload?.potential;
                    solution.motivo_sem_potencial = payload?.reason;
                    solution.comentario_sem_potencial = payload?.comments;
                }
                return solution;
            });
            setSolutions(newSolutions);
            isDefined(payload.closeDialog) && payload.closeDialog?.();
        }
        setIsUpdatingSolutions(false);
    };

    const generateQuickTeaserHandler = (checkActiveProposals: boolean, payload = null) => {
        generateQuickTeaser(payload, checkActiveProposals);
        setIsQuickTeaserDialogOpen(true);
    };

    const goToFacilityWithParams = (payload: IGoToFacilityWithParams = { params: '' }) => {
        history.push(BASENAME_URL_FACILITY + payload?.params);
    };

    const checkTermsHandler = async () => {
        try {
            // @ts-ignore
            if (hasTermsAgreed) {
                const putUserRsp = await putUpdateUser({
                    userID: user?.id,
                    body: {
                        aceitou_termos: true,
                    },
                });

                if (putUserRsp?.status === StatusCodes.OK) {
                    updateUserTerms();
                } else {
                    console.error(putUserRsp);
                }
            }
        } catch (err) {
            // TODO:
        }
    };

    useEffect(() => {
        if (
            isDefined(client?.id) &&
            isDefined(facility?.id) &&
            (!isDefined(projectPid) || (isDefined(projectPid) && solutions.length === 0))
        ) {
            getSolutionshandler();
        }
    }, [client, facility, projectPid]); // eslint-disable-line

    useEffect(() => {
        if (isDefined(solutionsEvent?.type)) {
            const payload: any = solutionsEvent?.payload;
            switch (solutionsEvent?.type) {
                case 'selectSolution':
                    selectSolutionHandler(payload.solution, { isV3: payload?.isV3, featureFlags });
                    break;
                case 'potentialChange':
                    changeSolutionPotentialHandler(payload);
                    break;
                case 'quickTeaserGenerate':
                    setIsQuickTeaserDialogOpen(true);
                    generateQuickTeaser(
                        {
                            facilityID: facility.id,
                            // @ts-ignore
                            busModelID: PRODUCTS_WITH_TEASER_GENERATION[Number(companyProfileId)][parseInt(payload.solutionID)].busModelID,
                        },
                        true
                    );
                    break;
                case 'redirectToFacility':
                    goToFacilityWithParams(payload);
                    break;
                case 'goToProjects':
                    history.push(BASENAME_URL_SOLUTIONS + '?pid=' + payload.solutionID);
                    break;
                    break;
                default:
                    break;
            }
        }
    }, [solutionsEvent]); // eslint-disable-line

    useEffect(() => {
        setSelected(null);
        setProjects([]);
        resetBusinessModelsStore();
        //Reset resetAllProductStates
        dispatch(resetAllProductStates());
        checkTermsHandler();
        return () => {
            setSolutionEvent({ type: null });

            // abort api calls
            abortAllAPIsCalls();
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        // this useEffect is necessary in order to update the selected
        // client name (in Dropdown) WHEN a new client is created
        if (isDefined(clientRedux?.id) && clientRedux?.id !== client?.id) {
            setClient({
                id: clientRedux?.id,
                descricao: clientRedux?.descricao ?? null, // B2B
                nome_proprio: clientRedux?.nome_proprio ?? null, // B2C
                apelido: clientRedux?.apelido ?? null, // B2C
                segmento_descricao: clientRedux?.segmento_descricao,
            });
            resetFacilityHandler();
            resetSolutionsHandler();
        }
    }, [clientRedux?.id]); // eslint-disable-line

    return {
        openHasProposals,
        setOpenHasProposals,
        isRequestingSolutions,
        resetHandler,
        userTypeID,
        selectSolutionHandler,
        getSolutionById,
        selectSolutionById,
        mayPassOpenProposals,
        changeSolutionPotentialHandler,
        solutionsEvent,
        quickTeaserValues: {
            isQuickTeaserDialogOpen,
            setIsQuickTeaserDialogOpen,
            isRequestingQuickTeaser,
            showhasProposalWarning,
            proposalID,
            isRequestingSendCRM,
            isRequestingEM,
            resetQTData,
            sentToCRM,
            generateQuickTeaserHandler,
        },
    };
};

export default useSolutions;
