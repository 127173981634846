import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Components
import CustomUnit from 'components/util/CustomUnit';
import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';
import PaPRubrics from '../Rubrics';

// Contexts
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { PaPEvents, TPaP } from 'interfaces/businessModels/pap';

// Services
import validate from 'services/util/validate';

const ProjectSummaryPaPDetailed = () => {
    const {
        state: {
            selected: {
                values: {
                    costs: { baseCost, totalCost },
                    rubrics,
                },
                options,
            },
        },
        setBMEventHandler,
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;

    const {
        bmState: { loadings },
        negotiationProfile,
    } = useContext(OfferEditionContext);

    const { control } = useFormContext();

    const updateValues = (value: number) => {
        setBMEventHandler(PaPEvents.SET_PAP_EXTRA_COST, {
            extra_cost: Number(value),
        });
    };

    const showRubrics = (rubrics?.values?.length > 0 && negotiationProfile?.edita_rubricas) ?? false;

    return (
        <div className="bm-detailed-offer-summary-costs w-100">
            <div className="input-container bold total-container">
                <label>
                    <IntlMessages id={`label.base${options?.show_capex ? 'Capex' : 'Package'}`} />
                </label>
                {/* @ts-ignore */}
                <div>
                    <FormatNumber unit={'currency'} numberOfDecimalPlaces={0} number={baseCost} />
                </div>
            </div>

            {negotiationProfile?.acesso_custo_extra && (
                <div className="input-container">
                    <label>
                        <IntlMessages id={`label.${options?.show_capex ? 'capexAdjustment' : 'costAdjustment'}`} />
                        {/* <Tooltip title={<IntlMessages id='page.businessModels.costAdjustment.info' />} /> */}
                    </label>
                    <Controller
                        name="extraCost"
                        control={control}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { ref, ...field } }) => {
                            return (
                                <OutlinedInput
                                    {...field}
                                    type="number"
                                    disabled={loadings.general}
                                    onChange={(evt) => {
                                        const value = evt.target.value;
                                        field.onChange(value);
                                        updateValues(Number(value));
                                    }}
                                    // @ts-ignore
                                    rules={validate(['required', 'number'])}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {/* @ts-ignore */}
                                            {<CustomUnit unit="currency" />}
                                        </InputAdornment>
                                    }
                                />
                            );
                        }}
                    />
                </div>
            )}

            {showRubrics && <PaPRubrics rubrics={rubrics?.values} />}

            <div className="bm-project-summary-offer-details-separator" />

            <div className="input-container bold total total-container">
                <label>
                    <IntlMessages id={`label.${options?.show_capex ? 'totalCapex' : 'totalCost'}`} />
                </label>
                {/* @ts-ignore */}
                <div>
                    <FormatNumber unit={'currency'} numberOfDecimalPlaces={0} number={totalCost} />
                </div>
            </div>
        </div>
    );
};

export default ProjectSummaryPaPDetailed;
