import { SPV_SUMMARY_TEMPLATE, SPV_SUMMARY_TEMPLATE_WITH_RUBRICS } from 'constants/businessModels';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { UpFrontEvents } from 'interfaces/businessModels/upfront';
import { useContext, Fragment, useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

//Our Components
import { isDefined, removeFieldEmptyInObj } from 'services/util/auxiliaryUtils';
import IntlMessages from 'components/util/IntlMessages';
import FormatNumber from 'components/util/FormatNumber';
import CustomUnit from 'components/util/CustomUnit';
import { TInstallments } from 'interfaces/businessModels/installments';
import Tooltip from 'components/@efz/Tooltip';
import { IconButton } from '@save2compete/efz-design-system';

const EdpPTRubrics = () => {
    const { state, setBMEventHandler, isRequestingBM, isReformulation } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TInstallments
    >;

    const hasRubricsCosts = state.simulation?.system?.rubricsCosts?.length > 0;
    const template = (hasRubricsCosts ? SPV_SUMMARY_TEMPLATE_WITH_RUBRICS : SPV_SUMMARY_TEMPLATE) as any;
    template[0].items = template?.[0]?.items?.filter((el) => !['base', 'extra_cost'].includes(el.attribute));

    const offer = state?.simulation?.offerEdition ?? null;
    const [duration, setDuration] = useState(offer?.duration ?? null);
    const [durationChanged, setDurationChanged] = useState(false);
    const annuity =
        removeFieldEmptyInObj({
            ...offer?.cost?.annuity,
            inverters_warranty_options: offer?.cost?.inverters_warranty_options,
        }) ?? null;

    const invertersWarrantyCost = (name) => {
        if (isDefined(annuity?.inverters_warranty_options)) {
            return annuity?.inverters_warranty_options.find((option) => duration?.[`${name}_years`]?.default === option.years_warranty)
                .cost;
        }
        return annuity?.[name] * (duration?.[name + '_years']?.default - duration?.[name + '_years']?.included);
    };

    const baseCost: number = state.selected.values.costs.baseCost;

    function handleChangeSummary(e) {
        //Initialize variables
        let newValue = 0;
        let newDuration = {};

        if (['add', 'decrease'].includes(e.target.value)) {
            let indexItem;

            //Get Index from change
            duration[e.target.name].values.forEach((element, index) => {
                if (element === duration[e.target.name].default) {
                    indexItem = index;
                }
            });

            //Make a change in the value
            newValue =
                e.target.value === 'add' ? duration[e.target.name].values[indexItem + 1] : duration[e.target.name].values[indexItem - 1];

            //Set duration
            newDuration = { ...duration, [e.target.name]: { ...duration[e.target.name], default: newValue } };

            //Make consumption follow maintenance number of years
            if (e.target.name === 'maintenance_years') {
                newDuration = { ...newDuration, gc_years: { ...duration['gc_years'], default: newValue } };
            }

            //Set Offer Hook
            setDuration(newDuration);
            setDurationChanged(true);
        }
    }

    useEffect(() => {
        if (durationChanged) {
            setBMEventHandler(UpFrontEvents.SET_EDP_PT_INPUTS, duration);
            setDurationChanged(false);
        }
    }, [durationChanged]); // eslint-disable-line

    return (
        <>
            {
                /* SUMMMARY COST*/
                (template?.filter((question) => question.type === 'offer').length === 0 || offer.has_client_discount !== undefined) &&
                    template
                        ?.filter((question) => question.type !== 'offer')
                        ?.map((question, index) => (
                            <div
                                key={index}
                                className={
                                    !!question.options && !!question.options.customStyles && !!question.options.customStyles.className ?
                                        question.options.customStyles.className
                                    :   ''
                                }
                            >
                                {question.type === 'cost' && (
                                    <Fragment>
                                        <Table>
                                            <TableBody>
                                                {!!question.items &&
                                                    question.items.map((row, index) => {
                                                        return (
                                                            row?.attribute !== 'rubricsCosts' && (
                                                                <TableRow key={index}>
                                                                    {row?.description ?
                                                                        <TableCell className="px-3">{row.description}</TableCell>
                                                                    :   <TableCell
                                                                            className={
                                                                                (
                                                                                    ![
                                                                                        'extra_cost',
                                                                                        'maintenanceAndWarranties',
                                                                                        'base',
                                                                                    ].includes(row?.attribute)
                                                                                ) ?
                                                                                    'px-3'
                                                                                :   'title-rubrics'
                                                                            }
                                                                        >
                                                                            <IntlMessages id={row.keyword} />
                                                                            {row?.tooltip && (
                                                                                // @ts-ignore
                                                                                <Tooltip
                                                                                    title={
                                                                                        <IntlMessages
                                                                                            id={'page.businessModels.costAdjustment.info'}
                                                                                        />
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </TableCell>
                                                                    }
                                                                    {row?.extraColumn && (
                                                                        <TableCell className="controller-cell">
                                                                            {row?.isInput &&
                                                                                row?.attribute === 'monitoring_cost' &&
                                                                                !duration?.monitoring.included && (
                                                                                    <Checkbox
                                                                                        checked={offer?.['monitoring']}
                                                                                        /* onChange={() =>
                                                                                            handleChangeSummary({
                                                                                                target: {
                                                                                                    name: row?.attribute,
                                                                                                    value: offer?.[row.attribute],
                                                                                                },
                                                                                            })
                                                                                        } */
                                                                                    />
                                                                                )}
                                                                            {row.isInput && !!duration?.[row.attribute + '_years'] && (
                                                                                <Fragment>
                                                                                    <div
                                                                                        className={
                                                                                            (
                                                                                                !row.uncontrolled &&
                                                                                                duration?.[row.attribute + '_years'].values
                                                                                                    .length > 1
                                                                                            ) ?
                                                                                                'controler-cell-input'
                                                                                            :   ''
                                                                                        }
                                                                                    >
                                                                                        <span>
                                                                                            {duration?.[row.attribute + '_years'].default +
                                                                                                ' '}
                                                                                            {/* @ts-ignore */}
                                                                                            <CustomUnit
                                                                                                unit="year"
                                                                                                number={
                                                                                                    duration?.[row.attribute + '_years']
                                                                                                        .default
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                        <span className="d-flex flex-row gap-2 mt-2">
                                                                                            {!row.uncontrolled &&
                                                                                                duration?.[row.attribute + '_years'].values
                                                                                                    .length > 1 && (
                                                                                                    <IconButton
                                                                                                        onClick={() =>
                                                                                                            handleChangeSummary({
                                                                                                                target: {
                                                                                                                    name:
                                                                                                                        row.attribute +
                                                                                                                        '_years',
                                                                                                                    value: 'decrease',
                                                                                                                },
                                                                                                            })
                                                                                                        }
                                                                                                        variant="action"
                                                                                                        size="mn"
                                                                                                        icon="minusSign"
                                                                                                        aria-label="minus"
                                                                                                        disabled={
                                                                                                            !!isRequestingBM ||
                                                                                                            duration?.[
                                                                                                                row.attribute + '_years'
                                                                                                            ]?.values[0] ===
                                                                                                                duration?.[
                                                                                                                    row.attribute + '_years'
                                                                                                                ].default
                                                                                                        }
                                                                                                        dataTestId="decrease"
                                                                                                    />
                                                                                                )}
                                                                                            {!row.uncontrolled &&
                                                                                                duration?.[row.attribute + '_years'].values
                                                                                                    .length > 1 && (
                                                                                                    <IconButton
                                                                                                        onClick={() =>
                                                                                                            handleChangeSummary({
                                                                                                                target: {
                                                                                                                    name:
                                                                                                                        row.attribute +
                                                                                                                        '_years',
                                                                                                                    value: 'add',
                                                                                                                },
                                                                                                            })
                                                                                                        }
                                                                                                        variant="action"
                                                                                                        size="mn"
                                                                                                        icon="plus"
                                                                                                        aria-label="plus"
                                                                                                        disabled={
                                                                                                            !!isRequestingBM ||
                                                                                                            !duration?.[
                                                                                                                row.attribute + '_years'
                                                                                                            ].values.find(
                                                                                                                (item) =>
                                                                                                                    item >
                                                                                                                    duration?.[
                                                                                                                        row.attribute +
                                                                                                                            '_years'
                                                                                                                    ].default
                                                                                                            )
                                                                                                        }
                                                                                                        dataTestId="add"
                                                                                                    />
                                                                                                )}
                                                                                        </span>
                                                                                    </div>
                                                                                </Fragment>
                                                                            )}
                                                                        </TableCell>
                                                                    )}
                                                                    <TableCell className="value-cell">
                                                                        <div className="value-cell-input">
                                                                            {row.editable ?
                                                                                <span>
                                                                                    <OutlinedInput
                                                                                        type="number"
                                                                                        className="value-cell-extra-cost"
                                                                                        name={row.attribute}
                                                                                        /* onChange={(e) => {
                                                                                            setExtraCost(e.target.value);
                                                                                            handleChangeSummary(e);
                                                                                        }} */
                                                                                        defaultValue={
                                                                                            offer[row.attribute] ?
                                                                                                offer[row.attribute]
                                                                                            :   '0'
                                                                                        }
                                                                                        //ref={inputNumber}
                                                                                        value={0}
                                                                                        // inputProps={[PRODUCT_IDS.SPV].includes(productID) ? {} : { min: 0 }}
                                                                                        endAdornment={
                                                                                            <InputAdornment position="end">
                                                                                                {/* @ts-ignore */}
                                                                                                <CustomUnit
                                                                                                    unit="currency"
                                                                                                    number={
                                                                                                        offer?.[row.attribute] ?
                                                                                                            offer?.[row.attribute]
                                                                                                        :   0
                                                                                                    }
                                                                                                />
                                                                                            </InputAdornment>
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            :   <span>
                                                                                    {!!offer?.[row.attribute] &&
                                                                                        row?.attribute !== 'monitoring_cost' && (
                                                                                            // @ts-ignore
                                                                                            <FormatNumber
                                                                                                styleFormat="currency"
                                                                                                number={
                                                                                                    !isReformulation ? baseCost : (
                                                                                                        offer?.[row.attribute]
                                                                                                    )
                                                                                                }
                                                                                                numberOfDecimalPlaces={0}
                                                                                            />
                                                                                        )}
                                                                                    {!offer?.[row.attribute] &&
                                                                                        row?.attribute !== 'monitoring_cost' &&
                                                                                        row?.attribute !== 'inverters_warranty' &&
                                                                                        row?.attribute !== 'maintenance' && (
                                                                                            // @ts-ignore
                                                                                            <FormatNumber
                                                                                                styleFormat="currency"
                                                                                                number={
                                                                                                    annuity?.[row.attribute] *
                                                                                                    (duration?.[row.attribute + '_years']
                                                                                                        ?.default -
                                                                                                        duration?.[row.attribute + '_years']
                                                                                                            ?.included)
                                                                                                }
                                                                                                numberOfDecimalPlaces={0}
                                                                                            />
                                                                                        )}
                                                                                    {!offer?.[row.attribute] &&
                                                                                        row?.attribute === 'maintenance' && (
                                                                                            // @ts-ignore
                                                                                            <FormatNumber
                                                                                                styleFormat="currency"
                                                                                                number={
                                                                                                    annuity?.[row.attribute] *
                                                                                                    (duration?.[row.attribute + '_years']
                                                                                                        ?.default -
                                                                                                        duration?.[row.attribute + '_years']
                                                                                                            ?.included +
                                                                                                        1)
                                                                                                }
                                                                                                numberOfDecimalPlaces={0}
                                                                                            />
                                                                                        )}
                                                                                    {!offer?.[row.attribute] &&
                                                                                        row?.attribute === 'inverters_warranty' && (
                                                                                            // @ts-ignore
                                                                                            <FormatNumber
                                                                                                styleFormat="currency"
                                                                                                number={invertersWarrantyCost(
                                                                                                    row?.attribute
                                                                                                )}
                                                                                                numberOfDecimalPlaces={0}
                                                                                            />
                                                                                        )}
                                                                                    {(!!offer?.[row.attribute] ||
                                                                                        offer?.[row.attribute] === 0) &&
                                                                                        row?.attribute === 'monitoring_cost' && (
                                                                                            // @ts-ignore
                                                                                            <FormatNumber
                                                                                                styleFormat="currency"
                                                                                                number={offer?.[row.attribute]}
                                                                                                numberOfDecimalPlaces={0}
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </Fragment>
                                )}
                            </div>
                        ))
            }
        </>
    );
};

export default EdpPTRubrics;
