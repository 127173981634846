import { TBMProjectSummary } from 'interfaces/businessModels';
import KPI from 'components/BusinessModels/List/BusinessModels/BusinessModelPaP/Card/Summary/Kpi';
import { eSolarProjectSummary } from 'services/businessModels/eSolar';

interface IProps {
    projectSummary: TBMProjectSummary[];
    syncKpis?: any;
}

const BusinessModelProjectSummaryESolar = ({ projectSummary, syncKpis }: IProps) => {
    const kpis = eSolarProjectSummary(projectSummary, syncKpis);

    return <>{kpis.length > 0 ? kpis.map((kpi) => <KPI {...kpi} />) : <></>}</>;
};

export default BusinessModelProjectSummaryESolar;
