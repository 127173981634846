import { memo, useContext, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ReactComponent as Step1ReservedArea } from 'assets/images/nav/7steps/1.svg';
import { ReactComponent as Step2ReservedArea } from 'assets/images/nav/7steps/2.svg';
import { ReactComponent as Step3ReservedArea } from 'assets/images/nav/7steps/3.svg';
import { ReactComponent as Step4ReservedArea } from 'assets/images/nav/7steps/4.svg';
import { ReactComponent as Step5ReservedArea } from 'assets/images/nav/7steps/5.svg';
import { ReactComponent as Step6ReservedArea } from 'assets/images/nav/7steps/6.svg';
import { ReactComponent as Step1OpenArea } from 'assets/images/nav/6steps/1.svg';
import { ReactComponent as Step2OpenArea } from 'assets/images/nav/6steps/2.svg';
import { ReactComponent as Step3OpenArea } from 'assets/images/nav/6steps/3.svg';
import { ReactComponent as Step4OpenArea } from 'assets/images/nav/6steps/4.svg';
import { ReactComponent as Step5OpenArea } from 'assets/images/nav/6steps/5.svg';
import { ReactComponent as Step1Desktop } from 'assets/images/nav/desktop/1.svg';
import { ReactComponent as Step2Desktop } from 'assets/images/nav/desktop/2.svg';
import { ReactComponent as Step3Desktop } from 'assets/images/nav/desktop/3.svg';
import { ReactComponent as Step4Desktop } from 'assets/images/nav/desktop/4.svg';
import { ReactComponent as Step5Desktop } from 'assets/images/nav/desktop/5.svg';
import { ReactComponent as Step6Desktop } from 'assets/images/nav/desktop/6.svg';
// import { ReactComponent as Step7Desktop } from 'assets/images/nav/desktop/7.svg';
import IntlMessages from 'components/util/IntlMessages';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { HOUSING_TYPES_B2C, spvSimpleActions, STEPS_OPEN_AREA, STEPS_RESERVED_AREA } from 'constants/products/solarpvSimple';
import StepsMenu from './StepsMenu';
import CompanyTitleB2C from '../CompanyTitle';
import { isOpenArea } from 'services/settings';
import { IconButton } from '@save2compete/efz-design-system';

const Nav = ({ forwardFormId }) => {
    const [showSteps, setShowSteps] = useState(false);

    const {
        spvSimpleState: {
            step,
            isOArea,
            inputs: { housing_type },
        },
    } = useContext(SPVSimpleContext);

    const getMenuSvg = () => {
        if (!isOArea) {
            switch (step) {
                case STEPS_RESERVED_AREA.HOUSING_TYPE:
                    return <Step1ReservedArea />;
                case STEPS_RESERVED_AREA.ADDRESS:
                    return <Step2ReservedArea />;
                case STEPS_RESERVED_AREA.ROOF_IDENTIFICATION:
                    return <Step3ReservedArea />;
                case STEPS_RESERVED_AREA.ROOF_DETAILS:
                    return <Step3ReservedArea />;
                case STEPS_RESERVED_AREA.SIMULATION:
                    if (housing_type === HOUSING_TYPES_B2C.GROUND) {
                        return <Step3ReservedArea />;
                    }
                    return <Step4ReservedArea />;
                case STEPS_RESERVED_AREA.BUSINESS_MODELS:
                    if (housing_type === HOUSING_TYPES_B2C.GROUND) {
                        return <Step4ReservedArea />;
                    }
                    return <Step5ReservedArea />;
                case STEPS_RESERVED_AREA.DOWNLOAD:
                    if (housing_type === HOUSING_TYPES_B2C.GROUND) {
                        return <Step4ReservedArea />;
                    }
                    return <Step6ReservedArea />;
                default:
                    return <></>;
            }
        } else {
            switch (step) {
                case STEPS_OPEN_AREA.HOUSING_TYPE:
                    return <Step1OpenArea />;
                case STEPS_OPEN_AREA.ADDRESS:
                    return <Step2OpenArea />;
                case STEPS_OPEN_AREA.ROOF_IDENTIFICATION:
                    return <Step3OpenArea />;
                case STEPS_OPEN_AREA.ROOF_DETAILS:
                    return <Step3OpenArea />;
                case STEPS_OPEN_AREA.CONSUMPTION_DETAILS:
                    if (housing_type === HOUSING_TYPES_B2C.GROUND) {
                        return <Step3OpenArea />;
                    }
                    return <Step4OpenArea />;
                case STEPS_OPEN_AREA.SIMULATION:
                    if (housing_type === HOUSING_TYPES_B2C.GROUND) {
                        return <Step4OpenArea />;
                    }
                    return <Step5OpenArea />;
                default:
                    return <></>;
            }
        }
    };

    const toggleSteps = () => {
        setShowSteps(!showSteps);
    };

    const togglePageScroll = (newState) => {
        const element = document.querySelector('.app-main-content-wrapper');

        if (newState) element.classList.remove('disable-scroll');
        else element.classList.add('disable-scroll');
    };

    return (
        <div className="nav">
            <div className="spv-simple-nav">
                <div className="spv-simple-nav-left d-flex">
                    <div className="step-id" disableRipple onClick={toggleSteps} role="button" tabindex="0">
                        {getMenuSvg()}
                        <span className="step-name">
                            <IntlMessages id={`page.spvSimple.${!isOArea ? 'reserved' : 'open'}.b2c.step.${step}`} />
                        </span>
                    </div>
                </div>
                <div className="spv-simple-nav-right d-flex align-items-center">
                    <span>
                        <IntlMessages id="label.steps" />
                    </span>
                    <IconButton
                        variant="tertiary"
                        icon={!showSteps ? 'burgerMenuBar' : 'xMarkExit'}
                        onClick={toggleSteps}
                        dataTestId="toggle-steps"
                    />
                </div>
            </div>
            <Collapse
                in={showSteps}
                timeout="auto"
                easing="ease-in-out"
                onEnter={() => togglePageScroll(false)}
                onExited={() => togglePageScroll(true)}
            >
                <StepsMenu
                    closeHandler={setShowSteps}
                    togglePageScroll={togglePageScroll}
                    forwardFormId={forwardFormId}
                    showSteps={showSteps}
                />
            </Collapse>
        </div>
    );
};

const NavDesktop = () => {
    const {
        spvSimpleState: {
            step,
            isOArea,
            inputs: { housing_type },
        },
        spvSimpleDispatchHandler: dispatch,
    } = useContext(SPVSimpleContext);

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const getMenuSvg = (el) => {
        if (!isOArea) {
            switch (el) {
                case STEPS_RESERVED_AREA.HOUSING_TYPE:
                    return <Step1Desktop />;
                case STEPS_RESERVED_AREA.ADDRESS:
                    return <Step2Desktop />;
                case STEPS_RESERVED_AREA.ROOF_IDENTIFICATION:
                    return <Step3Desktop />;
                case STEPS_RESERVED_AREA.ROOF_DETAILS:
                    return <Step3Desktop />;
                case STEPS_RESERVED_AREA.SIMULATION:
                    if (housing_type === HOUSING_TYPES_B2C.GROUND) {
                        return <Step3Desktop />;
                    }
                    return <Step4Desktop />;
                case STEPS_RESERVED_AREA.BUSINESS_MODELS:
                    if (housing_type === HOUSING_TYPES_B2C.GROUND) {
                        return <Step4Desktop />;
                    }
                    return <Step5Desktop />;
                case STEPS_RESERVED_AREA.DOWNLOAD:
                    if (housing_type === HOUSING_TYPES_B2C.GROUND) {
                        return <Step5Desktop />;
                    }
                    return <Step6Desktop />;
                default:
                    return <></>;
            }
        } else {
            switch (el) {
                case STEPS_OPEN_AREA.HOUSING_TYPE:
                    return <Step1Desktop />;
                case STEPS_OPEN_AREA.ADDRESS:
                    return <Step2Desktop />;
                case STEPS_OPEN_AREA.ROOF_IDENTIFICATION:
                    return <Step3Desktop />;
                case STEPS_OPEN_AREA.ROOF_DETAILS:
                    return <Step3Desktop />;
                case STEPS_OPEN_AREA.CONSUMPTION_DETAILS:
                    if (housing_type === HOUSING_TYPES_B2C.GROUND) {
                        return <Step3Desktop />;
                    }
                    return <Step4Desktop />;
                case STEPS_OPEN_AREA.SIMULATION:
                    if (housing_type === HOUSING_TYPES_B2C.GROUND) {
                        return <Step4Desktop />;
                    }
                    return <Step5Desktop />;
                default:
                    return <></>;
            }
        }
    };

    const setStep = (_, newValue) => {
        let increment = 1;
        if (newValue + 1 === (isOArea ? STEPS_OPEN_AREA.ROOF_IDENTIFICATION : STEPS_RESERVED_AREA.ROOF_IDENTIFICATION)) increment++;
        if (isOArea && newValue + 1 === STEPS_OPEN_AREA.ROOF_DETAILS) increment++;
        dispatch(spvSimpleActions.SET_STEP, newValue + increment);
    };

    const stepsToDisplay = Object.values(isOArea ? STEPS_OPEN_AREA : STEPS_RESERVED_AREA).filter((step) => {
        // OPEN_AREA
        if (isOArea && step === STEPS_OPEN_AREA.ROOF_IDENTIFICATION) {
            return false;
        }

        if (isOArea && housing_type === HOUSING_TYPES_B2C.GROUND && step === STEPS_OPEN_AREA.ROOF_DETAILS) {
            return false;
        }

        // RESERVED_AREA
        if (!isOArea && step === STEPS_RESERVED_AREA.ROOF_IDENTIFICATION) {
            return false;
        }

        if (!isOArea && housing_type === HOUSING_TYPES_B2C.GROUND && step === STEPS_RESERVED_AREA.ROOF_DETAILS) {
            return false;
        }

        return true;
    });

    return (
        <div className="nav-desktop">
            <div className="product-title">
                <CompanyTitleB2C isBlack fontSize={4} />
            </div>
            {step !== (isOpenArea() ? STEPS_OPEN_AREA.ROOF_IDENTIFICATION : STEPS_RESERVED_AREA.ROOF_IDENTIFICATION) && (
                <Tabs
                    value={step - 1}
                    aria-label="steps"
                    className="steps-bar-tabs"
                    onChange={setStep}
                    TabIndicatorProps={{
                        children: stepsToDisplay.map((el) => (
                            <span
                                className={`indicator ${
                                    el === step ? 'current'
                                    : el < step ? 'visited'
                                    : 'disabled'
                                }`}
                            />
                        )),
                    }}
                >
                    {stepsToDisplay.map((el, ind) => (
                        <Tab
                            className={`tab ${
                                el === step ? 'current'
                                : el < step ? 'visited'
                                : 'disabled'
                            } `}
                            icon={getMenuSvg(el)}
                            iconPosition="start"
                            label={<IntlMessages id={`page.spvSimple.${isOArea ? 'open.b2c' : 'reserved'}.step.${el}`} />}
                            disabled={
                                el >= step ||
                                [isOArea ? STEPS_OPEN_AREA.ROOF_IDENTIFICATION : STEPS_RESERVED_AREA.ROOF_IDENTIFICATION].includes(el)
                            }
                            disableTouchRipple
                            {...a11yProps(ind)}
                        />
                    ))}
                </Tabs>
            )}
        </div>
    );
};

const SPVSimpleNav = ({ forwardFormId }) => {
    const matches = useMediaQuery('(min-width:1366px)', { noSsr: true });

    return !matches ? <Nav forwardFormId={forwardFormId} /> : <NavDesktop forwardFormId={forwardFormId} />;
};

export default memo(SPVSimpleNav);
