import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPPa } from 'interfaces/businessModels/ppa';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';
import ProposalGenerationPPaDetailed from './detailed';
import ProposalGenerationButtonPPaSimple from './simple';

const ProposalGenerationServicePPa = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;

    if (isDefined(bmSelected.values?.syncKpis)) return <ProposalGenerationPPaDetailed />;
    return <ProposalGenerationButtonPPaSimple />;
};

export default ProposalGenerationServicePPa;
