/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Saga-redux Client
 * @inheritDoc o nome das funcoes e const no saga tem de começar com o '_" por exemplo
 * 		const _handlerName /.. || function* _callABC() /..
 *
 * @version 20190830
 * @since 20190830 Initial release
 *
 */

import { all, call, put, takeLatest } from 'redux-saga/effects';
//actions
import {
    requestGetClientSuccess,
    requestGetClientFail,
    requestPostCreateClientSuccess,
    requestPostCreateClientFail,
    requestPutClientInfoSuccess,
    requestPutClientInfoFail,
    requestPutDisableClientSuccess,
    requestPutDisableClientFail,
    setClientB2C,
    setClient,
    resetClientFacilities,
} from 'redux/actions/client';
//constants
import {
    REQUEST_PUT_DISABLE_CLIENT,
    REQUEST_GET_CLIENT,
    REQUEST_GET_CLIENT_DATA_BY_FACILITY,
    REQUEST_POST_CREATE_CLIENT,
    REQUEST_PUT_CLIENT_INFO,
} from 'constants/client';
import { OK, UNAUTHORIZED } from 'constants/statusCodeHttp';
//api
import { getClient, postClient, putClientInfo } from 'api/client';
import { resetFacility } from 'redux/actions/facility';
import { notify } from 'services/@efz/notify';
import { BASENAME_URL_FACILITY, BASENAME_URL_SOLUTIONS } from 'constants/settings';
import IntlMessages from 'components/util/IntlMessages';
import { getFacilitiesSearchByClient } from 'api/facilities';
import { StatusCodes } from 'http-status-codes';
import { putUserHistoryClients } from 'api/user';

/**
 * _callRequestGetSearchClients  request an API
 *
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 * @private
 */
const _callRequestGetClient = async (payload) =>
    await getClient(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callRequestGetSearchClients ', error);
            return error;
        });

// _callGetFacilitiesSearchByClient
const _callGetFacilitiesSearchByClient = async (payload) =>
    await getFacilitiesSearchByClient(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error getFacilitiesSearchByClient ', error);
            return error;
        });

/**
 * _handlerRequestGetClient
 *
 * @param payload
 * @returns {IterableIterator<PutEffect<{payload: *, type: *}>|CallEffect>}
 */
function* _handlerRequestGetClient({ payload }) {
    try {
        const response = yield call(_callRequestGetClient, payload);

        if (response?.status === OK) {
            if (payload) {
                // If clientID is specified, then we get data directly
                yield put(requestGetClientSuccess({ data: response.data }));
            } else {
                // If clientID isn't specified, then we get first element of response array (usually only one)
                yield put(requestGetClientSuccess({ data: response.data[0] }));
            }
        }
        if (![OK, UNAUTHORIZED].includes(response?.status)) yield put(requestGetClientFail()); //criar label
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestGetClient ', error);
        yield put(requestGetClientFail());
        return error;
    }
}

/**
 * _handlerRequestGetClientDataByFacility
 *
 * @param payload
 * @returns {IterableIterator<PutEffect<{payload: *, type: *}>|CallEffect>}
 */
function* _handlerRequestGetClientDataByFacility({ payload }) {
    try {
        let initialRequests = [];
        let { clientID, history } = payload;

        // Get Client Info
        initialRequests.push(call(_callRequestGetClient, clientID, '/rpsGetClient'));

        // Get Client's Facilities
        initialRequests.push(call(_callGetFacilitiesSearchByClient, { clientId: clientID }, '/rspFacilitiesSearchByClient'));

        let [rpsGetClient, rspFacilitiesSearchByClient] = yield all(initialRequests);

        if (rspFacilitiesSearchByClient?.status === StatusCodes.OK && rpsGetClient?.status === StatusCodes.OK) {
            let data = {
                ...rpsGetClient.data,
                facilities: rspFacilitiesSearchByClient?.data ?? [],
            };
            yield put(requestGetClientSuccess({ data }));
            yield history.push(BASENAME_URL_FACILITY);
        }

        if (![OK, UNAUTHORIZED].includes(rpsGetClient?.status)) yield put(requestGetClientFail()); //criar label
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestGetClientDataByFacility ', error);
        return error;
    }
}

/**
 * _callRequestPostCreateClient  request an API
 *
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 * @private
 */
const _callRequestPostCreateClient = async (payload) =>
    await postClient(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callRequestPostCreateClient ', error);
            return error;
        });

/**
 * _handlerRequestPostCreateClient
 *
 * @param payload
 * @returns {IterableIterator<PutEffect<{payload: *, type: *}>|CallEffect>}
 */
function* _handlerRequestPostCreateClient({ payload }) {
    try {
        const { body, history, resetAllHandler } = payload;
        const response = yield call(_callRequestPostCreateClient, body);

        if (response?.status === OK) {
            // If a client is created, then use it's id to get all of his data
            if (response.data.id) {
                putUserHistoryClients(response.data);
                resetAllHandler();
                yield put(resetClientFacilities());
                yield put(resetFacility());

                // We call a saga to get client data - we use call so that we can wait for it to finish
                // yield call(_handlerRequestGetClient, { payload: response.data.id });
                let salesSegment = localStorage.getItem('salesSegment');
                if (salesSegment === 'B2B') {
                    yield put(setClient(response.data));
                } else {
                    yield put(setClientB2C(response.data));
                }
                yield history.push(BASENAME_URL_SOLUTIONS);
            }

            yield put(requestPostCreateClientSuccess());
            notify(<IntlMessages id="page.client.clientCreatedSuccessfully" />, 'success');
        }
        if (![OK, UNAUTHORIZED].includes(response?.status)) yield put(requestPostCreateClientFail()); //criar label
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestPostCreateClient ', error);
        return error;
    }
}

/**
 * _callPutClientInfo  request an API
 *
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 * @private
 */
const _callPutClientInfo = async (payload) => {
    return await putClientInfo(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callPutClientInfo ', error);
            return error;
        });
};

/**
 * _handlerRequestPutClientInfo
 *
 * @param payload
 * @returns {IterableIterator<PutEffect<{payload: *, type: *}>|CallEffect>}
 */
function* _handlerRequestPutClientInfo({ payload }) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { targetLocation, companyCanAddUser, ...data } = payload;

        const response = yield call(_callPutClientInfo, data);

        if (response?.status === OK) {
            yield put(requestPutClientInfoSuccess(response.data));
            notify(<IntlMessages id="page.client.clientUpdatedSuccessfully" />, 'success');
        }
        if (![OK, UNAUTHORIZED].includes(response?.status)) yield put(requestPutClientInfoFail());
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestPutClientInfo ', error);
        yield put(requestPutClientInfoFail());
        return error;
    }
}

// Disable facility
const _callDisableClient = async (payload) => {
    return await putClientInfo(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callDisableClient ', error);
            return error;
        });
};

/**
 * _handlerRequestPutDisableClient
 *
 * @param payload
 * @returns {IterableIterator<PutEffect<{payload: *, type: *}>|CallEffect>}
 */
function* _handlerRequestPutDisableClient({ payload }) {
    try {
        const fetchResult = yield call(_callDisableClient, payload);

        if (fetchResult?.status === OK) {
            yield put(requestPutDisableClientSuccess({ facilityID: payload.id }));
            notify(<IntlMessages id="server.success.removeClient" />, 'success');
        } else {
            yield put(requestPutDisableClientFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestPutDisableClient ', error);
        yield put(requestPutDisableClientFail());
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(REQUEST_GET_CLIENT, _handlerRequestGetClient),
        takeLatest(REQUEST_GET_CLIENT_DATA_BY_FACILITY, _handlerRequestGetClientDataByFacility),
        takeLatest(REQUEST_POST_CREATE_CLIENT, _handlerRequestPostCreateClient),
        takeLatest(REQUEST_PUT_CLIENT_INFO, _handlerRequestPutClientInfo),
        takeLatest(REQUEST_PUT_DISABLE_CLIENT, _handlerRequestPutDisableClient),
    ]);
}
