import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Constants
import { QUESTIONS_SECTION_NAMES, QUESTIONS_SECTION_NAMES_B2C } from 'constants/facilityPro/main';

// Contexts
import { UserContext } from 'contexts/userContext';
import { SolutionsContext } from 'contexts/solutions/solutionsContext';

// Hooks
import useSolutions from 'hooks/solutions/useSolution';

// Services
import { isFieldDefined } from 'services/util/auxiliaryUtils';

const useFacilityMissingInfo = (allSectionsMounted) => {
    const { isB2C } = useContext(UserContext);
    const { solutions } = useContext(SolutionsContext);

    const {
        location: { search },
    } = useHistory();
    const { getSolutionById, selectSolutionById } = useSolutions();

    const [missingInfo, setMissingInfo] = useState(null);

    const buildMissingInfo = useCallback(() => {
        const _QUESTIONS_SECTION_NAMES = isB2C ? QUESTIONS_SECTION_NAMES : QUESTIONS_SECTION_NAMES_B2C;

        const query = new URLSearchParams(search);
        const solutionId = query.get('solution');
        const solutionName = getSolutionById(solutionId)?.tp_tag;
        const missingInfoRefsArray = query.get('ref')?.split(',');
        let _missingInfo = {};

        const handleGoTo = () => {
            selectSolutionById(solutionId);
        };

        missingInfoRefsArray?.forEach?.((section) => {
            switch (section) {
                case _QUESTIONS_SECTION_NAMES.FACILITY:
                    _missingInfo = {
                        ..._missingInfo,
                        [_QUESTIONS_SECTION_NAMES.FACILITY]: {
                            visible: true,
                            field: 'page.facility.error.label.tariffs', // TODO: compute the field that can have errors
                            solution: `product.name.${solutionName}`,
                            id: solutionId,
                            handleGoTo,
                        },
                    };
                    break;

                case _QUESTIONS_SECTION_NAMES.ELECTRICITY:
                    _missingInfo = {
                        ..._missingInfo,
                        [_QUESTIONS_SECTION_NAMES.ELECTRICITY]: {
                            visible: true,
                            field: 'page.facility.error.label.tariffs', // TODO: compute the field that can have errors
                            solution: `product.name.${solutionName}`,
                            id: solutionId,
                            handleGoTo,
                        },
                    };
                    break;

                case _QUESTIONS_SECTION_NAMES.GAS:
                    _missingInfo = {
                        ..._missingInfo,
                        [_QUESTIONS_SECTION_NAMES.GAS]: {
                            visible: true,
                            field: 'page.facility.error.label.tariffs', // TODO: compute the field that can have errors
                            solution: `product.name.${solutionName}`,
                            id: solutionId,
                            handleGoTo,
                        },
                    };
                    break;

                case _QUESTIONS_SECTION_NAMES.OTHER:
                    _missingInfo = {
                        ..._missingInfo,
                        [_QUESTIONS_SECTION_NAMES.OTHER]: {
                            visible: true,
                            field: 'page.facility.error.label.tariffs', // TODO: compute the field that can have errors
                            solution: `product.name.${solutionName}`,
                            id: solutionId,
                            handleGoTo,
                        },
                    };
                    break;

                default:
                    _missingInfo = null;
            }
        });

        return _missingInfo;
    }, [getSolutionById, isB2C, search, selectSolutionById]);

    const getFirstSectionWithMissingInfo = useCallback(() => {
        if (!isFieldDefined(missingInfo)) return;

        const missingInfoSections = Object.keys(missingInfo);

        if (missingInfoSections?.length <= 0 || missingInfoSections?.find((s) => s === 'firstSectionWithMissingInfo')) return;

        let firstSectionWithMissingInfo;

        if (isB2C) {
            if (missingInfoSections?.find((s) => s === QUESTIONS_SECTION_NAMES.FACILITY))
                firstSectionWithMissingInfo = QUESTIONS_SECTION_NAMES.FACILITY;
            else if (missingInfoSections?.find((s) => s === QUESTIONS_SECTION_NAMES.ELECTRICITY))
                firstSectionWithMissingInfo = QUESTIONS_SECTION_NAMES.ELECTRICITY;
            else if (missingInfoSections?.find((s) => s === QUESTIONS_SECTION_NAMES.GAS))
                firstSectionWithMissingInfo = QUESTIONS_SECTION_NAMES.GAS;
            else if (missingInfoSections?.find((s) => s === QUESTIONS_SECTION_NAMES.OTHER))
                firstSectionWithMissingInfo = QUESTIONS_SECTION_NAMES.OTHER;
        } else {
            if (missingInfoSections?.find((s) => s === QUESTIONS_SECTION_NAMES_B2C.FACILITY))
                firstSectionWithMissingInfo = QUESTIONS_SECTION_NAMES_B2C.FACILITY;
            else if (missingInfoSections?.find((s) => s === QUESTIONS_SECTION_NAMES_B2C.ELECTRICITY))
                firstSectionWithMissingInfo = QUESTIONS_SECTION_NAMES_B2C.ELECTRICITY;
        }

        return firstSectionWithMissingInfo;
    }, [isB2C, missingInfo]);

    const scrollToSection = useCallback((section) => {
        return setTimeout(function () {
            document.querySelector(`#${section}`)?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
        }, 250);
    }, []);

    // Builds missing info
    useEffect(() => {
        if (isFieldDefined(getSolutionById) && !isFieldDefined(missingInfo) && solutions?.length > 0) {
            const _missingInfo = buildMissingInfo(search, getSolutionById, selectSolutionById, isB2C);

            if (isFieldDefined(_missingInfo)) {
                setMissingInfo(() => _missingInfo);
            }
        }
    }, [buildMissingInfo, getSolutionById, isB2C, missingInfo, search, selectSolutionById, solutions?.length]);

    // scrolls to the first section that is missing some info
    useEffect(() => {
        if (!isFieldDefined(missingInfo)) return;

        if (allSectionsMounted) {
            const firstSectionWithMissingInfo = getFirstSectionWithMissingInfo(missingInfo, isB2C);

            if (isFieldDefined(firstSectionWithMissingInfo)) {
                scrollToSection(firstSectionWithMissingInfo);

                setMissingInfo(() => ({
                    ...missingInfo,
                    firstSectionWithMissingInfo,
                }));
            }
        }
    }, [allSectionsMounted, getFirstSectionWithMissingInfo, isB2C, missingInfo, scrollToSection]);

    return {
        missingInfo,
        buildMissingInfo,
        getFirstSectionWithMissingInfo,
        scrollToSection,
    };
};

export default useFacilityMissingInfo;
