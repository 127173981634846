import { useContext } from 'react';

// Components
import IntlMessages from 'components/util/IntlMessages';
import { Button } from '@save2compete/efz-design-system';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';

// Services
import { ServiceEvents } from 'interfaces/businessModels/service';
import { TService } from 'interfaces/businessModels/service';

const ProposalGenerationButtonService = () => {
    const { setBMEventHandler } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TService>;

    return (
        <Button
            className="bm-proposal-generation-button"
            onClick={() => setBMEventHandler(ServiceEvents.SET_SERVICE_PROPOSAL, null)}
            // disabled={conditionToDisable}
            dataTestId="bm-proposal-generation"
        >
            <IntlMessages id="page.bm.proposalGeneral" />
        </Button>
    );
};

export default ProposalGenerationButtonService;
