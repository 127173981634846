/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description ACTION TYPES AND DefaultObject, context -> Sizing
 *
 * @version 20191125 - added
 * @version 20191113
 * @since 20191113 Initial release
 *
 */

export const SIZING_MODULES = {
    SIMPLE: 'SIMPLES',
    DETAILED: 'DETALHADO',
};
// * DEFAULT OBJECTS * \\
export const SIZING_BODY = {
    facility: { id: null },
    is_scaling: true,
    tipo_produto_id: null,
    tipo_modulo: SIZING_MODULES.SIMPLE,
    inputs: null,
};
export const INITIAL_SIZING = {
    data: null,
    loading: false, // FIXME: remover este campo
    isRequesting: false,
};

// * ACTION TYPES * \\
export const RESET_SIZING = 'RESET_SIZING';
export const REQUEST_GET_SIZING = 'REQUEST_GET_SIZING';
export const REQUEST_GET_SIZING_SUCCESS = 'REQUEST_GET_SIZING_SUCCESS';
export const REQUEST_GET_SIZING_FAIL = 'REQUEST_GET_SIZING_FAIL';
export const SET_DATA_SIZING_BY_WEBSTOMP = 'SET_DATA_SIZING_BY_WEBSTOMP';
