import IconButton from '@mui/material/IconButton';
import { ReactComponent as ArrowUpIcon } from 'assets/images/products/spv-pro/svg/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/images/products/spv-pro/svg/arrow-down.svg';
import IntlMessages from 'components/util/IntlMessages';

const GrantsTitle = ({ label, isOpen, collapseHandler }: { label: string; isOpen: boolean; collapseHandler: () => void }) => {
    return (
        <div className={`bm-grants-title ${!isOpen ? 'collapsed' : ''}`}>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <span>
                        <IntlMessages id={label} />
                    </span>
                    <hr />
                </div>
                <IconButton disableRipple className="bm-grants-icon" onClick={collapseHandler}>
                    {isOpen ?
                        <ArrowUpIcon />
                    :   <ArrowDownIcon />}
                </IconButton>
            </div>
        </div>
    );
};

export default GrantsTitle;
