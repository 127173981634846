import { getHeadTitle } from 'services/settings';
import { Helmet } from 'react-helmet';
import LandingPageCards from './components/Cards';
import IntlMessages from 'components/util/IntlMessages';
import LandingPageTopSection from './components/Banner';

const OALangingPage = ({ isB2C }) => {
    return (
        <div className="landing-page-container">
            <Helmet>
                <title>{`${getHeadTitle()}`}</title>
            </Helmet>
            <LandingPageTopSection isB2C={isB2C} />
            <section className="landing-page-container-bottom">
                <h2>
                    <IntlMessages id="page.spvSimple.open.landing.card.title" />
                </h2>
                <LandingPageCards />
            </section>
        </div>
    );
};

export default OALangingPage;
