import { useState } from 'react';
import { toast } from 'react-toastify';
import { getResetPassword } from 'api/auth';
import { putRedefinePassword } from 'api/user';
import IntlMessages from 'components/util/IntlMessages';
import { OK, BAD_REQUEST } from 'constants/statusCodeHttp';
import { StatusCodes } from 'http-status-codes';
import { pbkdf2Sync } from 'pbkdf2';
import { useAuthStore } from 'store/auth';

const useRecoveryPassword = () => {
    const { logout } = useAuthStore();
    const [isRequesting, setIsRequesting] = useState(false);
    const [isRequestingRedefinePwd, setIsRequestingRedefinePwd] = useState(false);
    const [msgResetPassword, setMsgResetPassword] = useState(null);

    const fetchResetPassword = async (payload) => {
        setMsgResetPassword(null);

        setIsRequesting(true);
        const response = await getResetPassword(payload);

        if ([OK, BAD_REQUEST].includes(response?.status)) {
            setMsgResetPassword('page.resetPassword.confirmYourEmail');
        }

        setIsRequesting(false);
    };

    const fetchRedefinePassword = async (userID, body, onCloseDialog) => {
        setIsRequestingRedefinePwd(true);
        const passwordHashed = {
            password_: pbkdf2Sync(
                body.password,
                process.env.REACT_APP_PBKF2_SALT,
                parseInt(process.env.REACT_APP_PBKF2_ITERATIONS),
                parseInt(process.env.REACT_APP_PBKF2_KEYLEN),
                process.env.REACT_APP_PBKF2_DIGEST
            ).toString(process.env.REACT_APP_PBKF2_ENCODING),
        };

        const response = await putRedefinePassword(userID, passwordHashed);

        if (response?.status === StatusCodes.OK) {
            localStorage.removeItem('userToken');
            toast.success(<IntlMessages id="label.changeWasSuccessful" />);
            logout();
            onCloseDialog();
        }

        setIsRequestingRedefinePwd(false);
    };

    return {
        fetchResetPassword,
        isRequesting,
        isRequestingRedefinePwd,
        fetchRedefinePassword,
        msgResetPassword,
    };
};

export default useRecoveryPassword;
