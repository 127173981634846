import { GrantsAmmountsType, TUpFront, UpFrontEvents } from 'interfaces/businessModels/upfront';
import { TProductInputs } from './../../../interfaces/businessModels/index';
import { IBusinessModelsContext } from 'interfaces/businessModels';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { useContext, useEffect } from 'react';

const useUpFrontGrants = () => {
    const {
        setBMEventHandler,
        state: {
            selected: {
                values: {
                    grants,
                    costs: { marginCost },
                },
            },
        },
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const handleChangeDescription = (id: number, value: string) => {
        setBMEventHandler(UpFrontEvents.SET_DESCRIPTION_GRANT, { description: value, id: id });
    };

    const handleChangeAmmount = (ammountType: GrantsAmmountsType, id, value) => {
        setBMEventHandler(UpFrontEvents.SET_AMMOUNT_GRANT, {
            ammountType: ammountType,
            id: id,
            amount: Number(value),
        });
    };

    const handleDelete = (grantId: number) => {
        setBMEventHandler(UpFrontEvents.DELETE_UPFRONT_GRANT, grantId);
    };

    const handleAddGrant = () => {
        setBMEventHandler(UpFrontEvents.ADD_UPFRONT_GRANT, null);
    };

    const handleUpdateTotalGrants = () => {
        setBMEventHandler(UpFrontEvents.UPDATE_UPFRONT_GRANTS, null);
    };

    useEffect(() => {
        if (grants.totalGrants.prc > 0) {
            handleUpdateTotalGrants();
        }
    }, [marginCost]); // eslint-disable-line

    return {
        handleChangeDescription,
        handleChangeAmmount,
        handleDelete,
        handleAddGrant,
    };
};

export default useUpFrontGrants;
