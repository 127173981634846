import IconButton from '@mui/material/IconButton';
import { ReactComponent as ArrowUpIcon } from 'assets/images/products/spv-pro/svg/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/images/products/spv-pro/svg/arrow-down.svg';
import IntlMessages from 'components/util/IntlMessages';
import { useContext, useMemo } from 'react';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { PRODUCT_IDS } from 'constants/products';
import { GRANTS_INFO_URL } from 'constants/businessModelsPro/grants';
import { UserContext } from 'contexts/userContext';
import { TIntl } from 'types/utils';

const GrantsTitle = ({
    label,
    isOpen,
    collapseHandler,
}: {
    label: TIntl;
    isOpen: boolean;
    collapseHandler: () => void;
    openDialog: () => void;
}) => {
    const { productID } = useContext(BusinessModelsProContext);
    const { companyProfileId } = useContext(UserContext);
    const grantInfoUrl = useMemo(() => GRANTS_INFO_URL?.[companyProfileId]?.[productID] ?? '', [companyProfileId, productID]);

    return (
        <div className={`bm-grants-title ${!isOpen ? 'collapsed' : ''}`}>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <span>
                        <IntlMessages id={label} />
                    </span>
                    <hr />
                </div>
                <div>
                    {[PRODUCT_IDS.HP].includes(productID) && (
                        <a className="bm-grants-customAction" href={grantInfoUrl} target="_blank" rel="noopener noreferrer">
                            <IntlMessages id="page.bm.grants.hp.dialog.title" />
                        </a>
                    )}
                    <IconButton disableRipple className="bm-grants-icon" onClick={collapseHandler}>
                        {isOpen ?
                            <ArrowUpIcon />
                        :   <ArrowDownIcon />}
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default GrantsTitle;
