/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Reducers - Facility
 *
 * @version 20191114
 * @since 20191114 Initial release
 *
 */

import clone from 'fast-copy';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
    INITIAL_FACILITY,
    SET_FACILITY,
    REQUEST_GET_FACILITY_STATS,
    REQUEST_GET_FACILITY_STATS_SUCCESS,
    REQUEST_GET_FACILITY,
    REQUEST_GET_FACILITY_SUCCESS,
    REQUEST_GET_CREATE_FACILITY,
    REQUEST_GET_CREATE_FACILITY_SUCCESS,
    RESET_FACILITY,
    SET_IS_OPEN_FACILITY_CARD,
    REQUEST_SAVE_CHANGE_FACILITY_SUCCESS,
    REQUEST_SAVE_CHANGE_FACILITY,
    REQUEST_SAVE_CHANGE_FACILITY_FAIL,
    RESET_FACILITY_OPTIONS,
    REQUEST_GET_FACILITY_TYPES_SUCCESS,
    FACILITY_OPTIONS,
    REQUEST_SAVE_LOAD_CURVE_FACILITY_SUCCESS,
    REQUEST_SAVE_LOAD_CURVE_FACILITY_FAIL,
    REQUEST_SAVE_LOAD_CURVE_FACILITY,
    REQUEST_DISABLE_FACILITY,
    REQUEST_DISABLE_FACILITY_SUCCESS,
    REQUEST_DISABLE_FACILITY_FAIL,
    REQUEST_WEIGHTS_BY_CAE,
    REQUEST_WEIGHTS_BY_CAE_SUCCESS,
    REQUEST_WEIGHTS_BY_CAE_FAIL,
    REQUEST_WEIGHTS_BY_CAE_NO_INFORMATION,
    REQUEST_ANNUAL_CONSUMPTION,
    REQUEST_ANNUAL_CONSUMPTION_SUCCESS,
    REQUEST_ANNUAL_CONSUMPTION_FAIL,
    REQUEST_MONTHLY_CONSUMPTION,
    REQUEST_MONTHLY_CONSUMPTION_SUCCESS,
    REQUEST_MONTHLY_CONSUMPTION_FAIL,
    REQUEST_WEIGHTS_BY_CAE_WITH_PONDERADOR,
    REQUEST_UPLOAD_LOAD_CURVE,
    REQUEST_UPLOAD_LOAD_CURVE_SUCCESS,
    REQUEST_UPLOAD_LOAD_CURVE_FAIL,
    REQUEST_DOWNLOAD_LOAD_CURVE,
    REQUEST_DOWNLOAD_LOAD_CURVE_SUCCESS,
    REQUEST_DOWNLOAD_LOAD_CURVE_FAIL,
    SET_IS_DIRTY_FORM_FACILITY,
    REQUEST_GET_FACILITY_SIMPLE,
    REQUEST_GET_FACILITY_SIMPLE_SUCCESS,
    REQUEST_GET_FACILITY_SIMPLE_FAIL,
    SET_FACILITY_BY_TOKEN,
    REQUEST_DISTRIBUTORS_BY_NUT,
    REQUEST_DISTRIBUTORS_BY_NUT_SUCCESS,
    REQUEST_DISTRIBUTORS_BY_NUT_FAIL,
    REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_SUCCESS,
    REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS,
    REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_FAIL,
    SET_FACILITY_OPCAO_TARIFARIO_ID,
    REQUEST_SET_GRID_TARIFF_SOURCES,
    REQUEST_SET_GRID_TARIFF_SOURCES_SUCCESS,
    SET_CHANGE_ADDRESS_FACILITY,
} from 'constants/facility';
import { getStatsSetor } from 'services/util/auxiliaryUtils';
import { STATE_IDS } from 'constants/user';
import { showStateCurrent } from 'services/user';
import { calcWeightsSumAndMonthlyConsumption, savedInputsChanged } from 'services/facility';
import { orderByAlphabet, orderByNumber } from 'services/dropdown';

const initialState = clone(INITIAL_FACILITY);

const reducersFacility = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_GET_FACILITY_TYPES_SUCCESS:
            return {
                ...state,
                options: {
                    ...state.options,
                    types: action.payload,
                },
            };
        case RESET_FACILITY_OPTIONS:
            return {
                ...state,
                options: {
                    ...clone(FACILITY_OPTIONS),
                },
            };
        case REQUEST_SAVE_CHANGE_FACILITY:
            return {
                ...state,
                isRequestingSave: true,
            };
        case SET_FACILITY_BY_TOKEN:
            return {
                ...state,
                info: {
                    ...state.info,
                    ...action.payload.data,
                },
            };
        case REQUEST_SAVE_CHANGE_FACILITY_SUCCESS: {
            const inputsFacility = action.payload.facility.body;
            return {
                ...state,
                info: {
                    ...state.info,
                    ...inputsFacility,
                },
                isRequestingSave: false,
            };
        }
        case REQUEST_SAVE_CHANGE_FACILITY_FAIL:
            return {
                ...state,
                isRequestingSave: false,
            };
        case SET_IS_OPEN_FACILITY_CARD:
            return {
                ...state,
                isOpenFacilityCard: action.payload,
            };
        case SET_FACILITY:
            return {
                ...state,
                info: {
                    ...state?.info,
                    ...action?.payload,
                },
            };
        case REQUEST_GET_FACILITY:
            return {
                ...state,
                info: {
                    ...state.info,
                    id: action.payload?.facilityID ?? null,
                },
                isRequesting: true,
            };
        case REQUEST_GET_FACILITY_SUCCESS: {
            //Calculate Monthly Values
            const objectCalc = calcWeightsSumAndMonthlyConsumption(
                action?.payload?.facilityConsumptionWeights,
                action.payload.facility.consumo_anual
            );

            return {
                ...state,
                info: {
                    ...state?.info,
                    ...action?.payload.facility,
                    consumo_anual: action.payload.facility.consumo_anual,
                    consumo_anual_mwh: action.payload.facility.consumo_anual / 1000,
                    validCurveUploaded: action?.payload?.validCurveUploaded,
                },
                options: {
                    ...clone(FACILITY_OPTIONS),
                    ...state?.options,
                    types: action?.payload?.facilityType,
                    graphs: {
                        ...clone(FACILITY_OPTIONS).graphs,
                        monthly: action.payload.facilityConsumptionMonthly,
                        daily: action.payload.facilityConsumptionDaily,
                    },
                    monthlyConsumption: objectCalc?.facilityMonthlyConsumption,
                    consumptionWeightsSum: objectCalc?.facilityWeightsSum,
                    loadingEstimates: false,
                    voltageLevels: orderByAlphabet(action?.payload?.voltageLevels, 'descricao'),
                    conectionVoltages: orderByAlphabet(action?.payload?.conectionVoltages, 'description'),
                    distributors: orderByAlphabet(action?.payload?.distributors, 'description'),
                    gridTariffs: action.payload.gridTariffs,
                    gridTariffSources: action?.payload?.gridTariffSources,
                    tariffOptions: orderByAlphabet(action?.payload?.tariffOptions, 'description'),
                    hourlyOptions: orderByNumber(action?.payload?.hourlyOptions, 'id'),
                    suppliers: orderByAlphabet(action?.payload?.suppliers, 'contacto_nome'),
                    energyTariffs: action.payload.energyTariffs,
                    nuts: action.payload.nuts,
                    classes: action?.payload?.classes,
                    usedPowerTariffs: action?.payload?.usedPowerTariffs,
                },
                isRequesting: false,
            };
        }
        case REQUEST_SET_GRID_TARIFF_SOURCES:
            return {
                ...state,
                isRequestingGridTariffs: true,
            };
        case REQUEST_SET_GRID_TARIFF_SOURCES_SUCCESS:
            return {
                ...state,
                options: {
                    ...clone(FACILITY_OPTIONS),
                    ...state?.options,
                    gridTariffs: action.payload.gridTariffs,
                    usedPowerTariffs: action.payload.usedPowerTariffs,
                },
                isRequestingGridTariffs: false,
            };

        case REQUEST_GET_CREATE_FACILITY:
            return {
                ...state,
                isRequesting: true,
            };

        case REQUEST_GET_CREATE_FACILITY_SUCCESS:
            return {
                ...state,
                options: {
                    ...clone(FACILITY_OPTIONS),
                    ...state?.options,
                    types: action.payload.facilityType,
                    voltageLevels: orderByAlphabet(action.payload.voltageLevels, 'descricao'),
                    conectionVoltages: orderByAlphabet(action.payload.conectionVoltages, 'description'),
                    distributors: orderByAlphabet(action.payload.distributors, 'description'),
                    gridTariffs: action.payload.gridTariffs,
                    tariffOptions: orderByAlphabet(action.payload.tariffOptions, 'description'),
                    suppliers: orderByAlphabet(action.payload.suppliers, 'contacto_nome'),
                    energyTariffs: action.payload.energyTariffs,
                    nuts: orderByAlphabet(action.payload.nuts, 'description'),
                    classes: action.payload.classes,
                },
                isRequesting: false,
            };

        case REQUEST_GET_FACILITY_STATS:
            return {
                ...state,
                isRequesting: true,
            };
        case REQUEST_GET_FACILITY_STATS_SUCCESS:
            return {
                ...state,
                isRequesting: true,
                options: {
                    CE: {
                        ...getStatsSetor('consumption_in_sector_and_contracted_power_range', state.info.consumo_anual, action.payload.data),
                    },
                },
            };
        case REQUEST_SAVE_LOAD_CURVE_FACILITY: {
            let loadingObject =
                action?.payload?.action !== 'changeOptionLoadCurveId' ? { loadingEstimates: true } : { loadingInputsCurveLoad: true };

            let infoObj =
                action?.payload?.action === 'changeRadioPonderador' ?
                    {
                        ...state.info,
                        tem_dados_ponderador_estimativa_simples: action?.payload.body.tem_dados_ponderador_estimativa_simples,
                        tem_dados_ponderador_estimativa_simples_por_periodo:
                            action?.payload.body.tem_dados_ponderador_estimativa_simples_por_periodo,
                    }
                :   state.info;

            return {
                ...state,
                options: {
                    ...state.options,
                    ...loadingObject,
                },
                info: {
                    ...infoObj,
                },
            };
        }
        case REQUEST_SAVE_LOAD_CURVE_FACILITY_FAIL:
            return {
                ...state,
                options: {
                    ...state.options,
                    loadingEstimates: false,
                    loadingInputsCurveLoad: false,
                },
            };
        case REQUEST_SAVE_LOAD_CURVE_FACILITY_SUCCESS: {
            let {
                tem_dados_integracao_estatisticos,
                tem_dados_ponderador_estimativa_simples,
                tem_dados_ponderador_estimativa_simples_por_periodo,
                tem_dados_ponderador_integracao_personalizada,
                tem_telecontagem,
                tem_dados_integracao_reais,
            } = action.payload.facilityData;

            //Calculate Monthly Values
            const __annualConsumption =
                !!action?.payload?.annualConsumption || action?.payload?.annualConsumption === 0 ?
                    action?.payload?.annualConsumption
                :   state.info.consumo_anual;
            const __optionLoadCurve = action?.payload?.optionLoadCurve ? action?.payload?.optionLoadCurve : state.info.opcao_curva_carga_id;
            const __objectCalc = calcWeightsSumAndMonthlyConsumption(action?.payload?.facilityConsumptionWeights, __annualConsumption);
            let updFacilityData = action?.payload?.facilityData ?? state?.info;
            let __loadingObject =
                action?.payload?.type !== 'changeOptionLoadCurveId' ? { loadingEstimates: false } : { loadingInputsCurveLoad: false };
            //Complete with inputs
            let inputObject = savedInputsChanged(__optionLoadCurve, action, __annualConsumption, updFacilityData);

            return {
                ...state,
                info: {
                    ...state.info,
                    //
                    ...inputObject,
                    //
                    opcao_curva_carga_id: __optionLoadCurve,
                    tem_dados_integracao_estatisticos,
                    tem_dados_ponderador_estimativa_simples,
                    tem_dados_ponderador_estimativa_simples_por_periodo,
                    tem_dados_ponderador_integracao_personalizada,
                    tem_telecontagem,
                    tem_dados_integracao_reais,
                    validCurveUploaded: action?.payload?.validCurveUploaded,
                },
                options: {
                    ...clone(FACILITY_OPTIONS),
                    ...state?.options,
                    graphs: {
                        ...clone(FACILITY_OPTIONS).graphs,
                        monthly: action.payload.facilityConsumptionMonthly,
                        daily: action.payload.facilityConsumptionDaily,
                    },
                    monthlyConsumption: __objectCalc?.facilityMonthlyConsumption,
                    consumptionWeightsSum: __objectCalc?.facilityWeightsSum,
                    ...__loadingObject,
                },
                isCAEWithNoData: false,
            };
        }
        case REQUEST_DISABLE_FACILITY:
            return {
                ...state,
                isRequesting: true,
            };
        case REQUEST_DISABLE_FACILITY_SUCCESS:
            return {
                ...state,
                isRequesting: false,
            };
        case REQUEST_DISABLE_FACILITY_FAIL:
            return {
                ...state,
                isRequesting: false,
            };
        case RESET_FACILITY:
            return {
                ...clone(INITIAL_FACILITY),
            };
        case LOCATION_CHANGE:
            return {
                ...state,
                options: {
                    ...clone(FACILITY_OPTIONS),
                },
                isRequesting: true,
            };
        case REQUEST_WEIGHTS_BY_CAE:
            return {
                ...state,
                isRequestingCAEWeights: true,
                isCAEWithNoData: false,
            };
        case REQUEST_WEIGHTS_BY_CAE_SUCCESS:
            if (action?.payload?.facilityConsumptionWeights) {
                const objectWeights = calcWeightsSumAndMonthlyConsumption(
                    action?.payload?.facilityConsumptionWeights,
                    state.info.consumo_anual
                );
                return {
                    ...state,
                    info: {
                        ...state.info,
                        activity_code: action?.payload?.clientCAE5,
                    },
                    options: {
                        ...clone(FACILITY_OPTIONS),
                        ...state.options,
                        graphs: {
                            ...clone(FACILITY_OPTIONS).graphs,
                            monthly: action.payload.facilityConsumptionMonthly,
                            daily: action.payload.facilityConsumptionDaily,
                        },
                        monthlyConsumption: objectWeights?.facilityMonthlyConsumption,
                        consumptionWeightsSum: objectWeights?.facilityWeightsSum,
                    },
                    isRequestingCAEWeights: false,
                    isCAEWithNoData: false,
                };
            }

            return {
                ...state,
                info: {
                    ...state.info,
                    activity_code: action?.payload?.clientCAE5,
                },
                options: {
                    ...clone(FACILITY_OPTIONS),
                    ...state?.options,
                    graphs: {
                        ...clone(FACILITY_OPTIONS).graphs,
                        monthly: action.payload.facilityConsumptionMonthly,
                        daily: action.payload.facilityConsumptionDaily,
                    },
                },
                isRequestingCAEWeights: false,
                isCAEWithNoData: false,
            };

        case REQUEST_WEIGHTS_BY_CAE_WITH_PONDERADOR:
            return {
                ...state,
                info: {
                    ...state.info,
                    activity_code: action?.payload?.clientCAE5,
                },
                isRequestingCAEWeights: false,
                isCAEWithNoData: false,
            };
        case REQUEST_WEIGHTS_BY_CAE_FAIL:
            return {
                ...state,
                isRequestingCAEWeights: false,
                isCAEWithNoData: false,
            };
        case REQUEST_WEIGHTS_BY_CAE_NO_INFORMATION:
            return {
                ...state,
                isRequestingCAEWeights: false,
                isCAEWithNoData: true,
            };

        case REQUEST_ANNUAL_CONSUMPTION:
            return {
                ...state,
                isRequestingAnnualConsumption: true,
            };
        case REQUEST_ANNUAL_CONSUMPTION_SUCCESS:
            return {
                ...state,
                info: {
                    ...state.info,
                    consumo_anual: action?.payload?.consumo_anual,
                    consumo_anual_mwh: action?.payload?.consumo_anual_mwh,
                },
                options: {
                    ...clone(FACILITY_OPTIONS),
                    ...state?.options,
                    monthlyConsumption: action.payload.consumption_weights,
                    graphs: {
                        ...clone(FACILITY_OPTIONS).graphs,
                        monthly: action.payload.facilityConsumptionMonthly,
                        daily: action.payload.facilityConsumptionDaily,
                    },
                },
                isRequestingAnnualConsumption: false,
            };

        case REQUEST_ANNUAL_CONSUMPTION_FAIL:
            return {
                ...state,
                isRequestingAnnualConsumption: false,
            };

        case REQUEST_MONTHLY_CONSUMPTION:
            return {
                ...state,
                isRequestingMonthlyConsumption: true,
            };

        case REQUEST_MONTHLY_CONSUMPTION_SUCCESS:
            return {
                ...state,
                info: {
                    ...state.info,
                    consumo_anual: action?.payload?.consumo_anual,
                    consumo_anual_mwh: action?.payload?.consumo_anual_mwh,
                },
                options: {
                    ...clone(FACILITY_OPTIONS),
                    ...state?.options,
                    monthlyConsumption: action.payload.consumption_weights,
                    graphs: {
                        ...clone(FACILITY_OPTIONS).graphs,
                        monthly: action.payload.facilityConsumptionMonthly,
                        daily: action.payload.facilityConsumptionDaily,
                    },
                },
                isRequestingMonthlyConsumption: false,
            };
        case REQUEST_MONTHLY_CONSUMPTION_FAIL:
            return {
                ...state,
                isRequestingMonthlyConsumption: false,
            };

        case REQUEST_UPLOAD_LOAD_CURVE:
            return {
                ...state,
                // isRequestingMonthlyConsumption: true,
                isRequestUpload: true,
            };

        case REQUEST_UPLOAD_LOAD_CURVE_SUCCESS: {
            const objectWeights = calcWeightsSumAndMonthlyConsumption(
                action?.payload?.facilityConsumptionWeights,
                action?.payload?.annualConsumption
            );
            return {
                ...state,
                info: {
                    ...state.info,
                    consumo_anual: action?.payload?.annualConsumption * 1000,
                    consumo_anual_mwh: action?.payload?.annualConsumption,
                    nome_ficheiro_curva_carga: state?.info?.nome_ficheiro_curva_carga ?? `_Load_Curve_Uploaded.xlsx`,
                    validCurveUploaded: action?.payload?.validCurveUploaded,
                },
                options: {
                    ...clone(FACILITY_OPTIONS),
                    ...state?.options,
                    graphs: {
                        ...clone(FACILITY_OPTIONS).graphs,
                        monthly: action?.payload?.facilityConsumptionMonthly,
                        daily: action?.payload?.facilityConsumptionDaily,
                    },
                    monthlyConsumption: objectWeights?.facilityMonthlyConsumption,
                    consumptionWeightsSum: objectWeights?.facilityWeightsSum,
                },
                isRequestUpload: false,
                isCAEWithNoData: false,
            };
        }

        case REQUEST_UPLOAD_LOAD_CURVE_FAIL:
            return {
                ...state,
                // isRequestingMonthlyConsumption: false,
                isRequestUpload: false,
            };

        case REQUEST_DOWNLOAD_LOAD_CURVE:
            return {
                ...state,
                // isRequestingMonthlyConsumption: true,
                isRequestDownload: true,
            };

        case REQUEST_DOWNLOAD_LOAD_CURVE_SUCCESS: {
            let url = URL.createObjectURL(
                new Blob([action.payload.fileContent.data], {
                    type: action.payload.fileContent.headers['content-type'],
                })
            );
            let a = document.createElement('a');

            a.href = url;
            a.download = `consumption_load_curve_${state.info.id}.xlsx`;
            document.body.appendChild(a);
            a.click();

            return {
                ...state,
                // isRequestingMonthlyConsumption: false,
                isRequestDownload: false,
            };
        }

        case REQUEST_DOWNLOAD_LOAD_CURVE_FAIL:
            return {
                ...state,
                // isRequestingMonthlyConsumption: false,
                isRequestDownload: false,
            };

        case SET_IS_DIRTY_FORM_FACILITY:
            return {
                ...state,
                forwardFormID: action.payload,
            };

        case REQUEST_GET_FACILITY_SIMPLE:
            return {
                ...state,
                isRequesting: true,
            };
        case REQUEST_GET_FACILITY_SIMPLE_SUCCESS:
            return {
                ...state,
                isRequesting: false,
                info: {
                    ...state?.info,
                    ...action?.payload,
                },
            };
        case REQUEST_GET_FACILITY_SIMPLE_FAIL:
            return {
                ...state,
                isRequesting: false,
            };
        case REQUEST_DISTRIBUTORS_BY_NUT:
            return {
                ...state,
                isRequestingDistributors: true,
            };
        case REQUEST_DISTRIBUTORS_BY_NUT_SUCCESS:
            return {
                ...state,
                options: {
                    ...clone(FACILITY_OPTIONS),
                    ...state?.options,
                    distributors: orderByAlphabet(action?.payload?.distributors, 'description'),
                },
                isRequestingDistributors: false,
            };
        case REQUEST_DISTRIBUTORS_BY_NUT_FAIL:
            return {
                ...state,
                isRequestingDistributors: false,
            };
        case REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS:
            return {
                ...state,
                isRequestingPutWeghtsByCustomPeriods: true,
            };

        case REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_SUCCESS:
            return {
                ...state,
                info: {
                    ...state.info,
                    consumo_anual: action?.payload?.consumo_anual,
                    consumo_anual_mwh: action?.payload?.consumo_anual_mwh,
                },
                options: {
                    ...state?.options,
                    graphs: {
                        ...clone(FACILITY_OPTIONS).graphs,
                        monthly: action.payload.facilityConsumptionMonthly,
                        daily: action.payload.facilityConsumptionDaily,
                    },
                },
                isRequestingPutWeghtsByCustomPeriods: false,
            };
        case REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_FAIL:
            return {
                ...state,
                isRequestingPutWeghtsByCustomPeriods: false,
            };
        case SET_FACILITY_OPCAO_TARIFARIO_ID: {
            let { opcao_tarifario_id, monthly, daily } = action.payload;
            return {
                ...state,
                info: {
                    ...state.info,
                    opcao_tarifario_id,
                },
                options: {
                    ...state?.options,
                    graphs: {
                        ...clone(FACILITY_OPTIONS).graphs,
                        monthly,
                        daily,
                    },
                },
            };
        }
        case SET_CHANGE_ADDRESS_FACILITY: {
            const { id, body, isB2C } = action.payload;

            return isB2C ?
                    {
                        ...state,
                        info: {
                            ...state?.info,
                            location: {
                                ...state.info.location,
                                ...(body?.location ?? {}),
                            },
                            list: state?.info?.list?.map((item) => {
                                if (item.id === id) {
                                    return {
                                        ...item,
                                        ...(body?.location ?? {}),
                                    };
                                }
                                return item;
                            }),
                        },
                    }
                :   {
                        ...state,
                        info: {
                            ...state?.info,
                            ...body,
                            list: state?.info?.list?.map((item) => {
                                if (item.id === id) {
                                    return {
                                        ...item,
                                        ...body,
                                    };
                                }
                                return item;
                            }),
                        },
                    };
        }
        default:
            return (
                showStateCurrent(STATE_IDS.PLATFORM) ?
                    !state ? clone(INITIAL_FACILITY)
                    :   state
                :   null
            );
    }
};

export default reducersFacility;
