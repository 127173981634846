import { useMediaQuery } from '@mui/material';
import { createContext, useContext, useMemo, useReducer } from 'react';
// import { ZodSchema } from 'zod';

// Constants
import {
    HP_FAST_ACTIONS,
    HP_FAST_FORM_ID,
    HP_FAST_QUESTIONS,
    HP_FAST_QUESTIONS_SETTINGS /* , 
    HP_FAST_STEPS, */,
} from 'constants/products/hpFast';

// Contexts
import { BusinessModelsProContextProvider } from 'contexts/businessModelsPro/businessModelsContext';
import { UserContext } from 'contexts/userContext';
import { hpFastReducer, initialHPFastState } from './hpFastReducer';

// Interfaces
import { IHPFastContext, PayloadForType, THPFastAction } from 'interfaces/products/hp/fast';
// import { HP_FAST_FORM_SCHEMA, ALL_INPUT_NAMES } from 'interfaces/products/hp/fast/form';

// Services
import { addOptionsToContextQuestions /* , addRequiredValidationToQuestions, getFormInputName */ } from 'services/products/hp/fast';

export const HPFastContext = createContext<IHPFastContext>({
    hpState: initialHPFastState,
    dispatchHandler: function <T extends HP_FAST_ACTIONS>(type: T, payload?: PayloadForType<T>): void {
        throw new Error(
            `Function dispatchHandler not implemented. Probably tried to use before context initialization. type: ${type} payload: ${JSON.stringify(payload)}`
        );
    },
    forwardFormId: HP_FAST_FORM_ID,
    isDesktop: false,
    isMobileDevice: false,
    questions: HP_FAST_QUESTIONS,
});

export const HPFastProvider: React.FC<React.ReactNode> = ({ children }) => {
    const { userTypeID, companyProfileId } = useContext(UserContext);

    const [hpState, dispatch] = useReducer(hpFastReducer, initialHPFastState);

    const isDesktop = useMediaQuery('(min-width: 1366px)', { noSsr: true });
    const isMobileDevice = /Mobile|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|webOS/i.test(navigator.userAgent);

    function dispatchHandler<T extends HP_FAST_ACTIONS>(type: T, payload?: PayloadForType<T>) {
        dispatch({ type, payload } as THPFastAction);
    }

    // Function to recursively get keys of all required fields from a Zod schema
    // TODO: Refactor Zod schemas so that we can use this function
    // function getRequiredFields<T extends ZodSchema<any>>(schema: T): (keyof T['_input'])[] {
    //     const requiredFields: (keyof T['_input'])[] = [];

    //     // Helper function to recursively traverse schemas
    //     function traverseSchema(innerSchema: ZodSchema<any>) {
    //         const def = innerSchema._def;
    //         // @ts-ignore
    //         switch (def.typeName) {
    //             case 'ZodObject': {
    //                 // @ts-ignore
    //                 const entries = Object.entries(def.shape());

    //                 for (const [key, field] of entries) {
    //                     // @ts-ignore
    //                     if (!field.isOptional()) {
    //                         requiredFields.push(key as keyof T['_input']);
    //                     }
    //                     // @ts-ignore
    //                     traverseSchema(field);
    //                 }
    //                 break;
    //             }

    //             case 'ZodUnion':
    //             case 'ZodDiscriminatedUnion':
    //                 // @ts-ignore
    //                 def.options.forEach(traverseSchema);
    //                 break;
    //             case 'ZodArray':
    //                 // @ts-ignore
    //                 traverseSchema(def.type);
    //                 break;
    //             case 'ZodRecord':
    //                 // @ts-ignore
    //                 traverseSchema(def.valueType);
    //                 break;
    //             case 'ZodEffects':
    //                 // @ts-ignore
    //                 traverseSchema(def.schema);
    //                 break;
    //         }
    //     }

    //     traverseSchema(schema);

    //     return requiredFields;
    // }

    const questions = useMemo(() => {
        // TODO: Refactor Zod schemas so that we can set the required '*' through Zod schema and not QuestionConfig
        // const requiredFields = getRequiredFields(HP_FAST_FORM_SCHEMA).filter(
        //     (field) => !Object.values(HP_FAST_STEPS).includes(field)
        // ) as any as ALL_INPUT_NAMES[];
        // const requiredFieldNames = requiredFields.map((field) => getFormInputName(field));
        const baseQuestions = HP_FAST_QUESTIONS_SETTINGS?.[companyProfileId]?.[userTypeID] ?? HP_FAST_QUESTIONS;
        // return addOptionsToContextQuestions(addRequiredValidationToQuestions(baseQuestions, requiredFieldNames), hpState?.options);
        return addOptionsToContextQuestions(baseQuestions, hpState?.options);
    }, [hpState?.options, companyProfileId, userTypeID]);

    const HPFastContextValue: IHPFastContext = useMemo(
        () => ({
            hpState,
            dispatchHandler,
            forwardFormId: HP_FAST_FORM_ID,
            isDesktop,
            isMobileDevice,
            questions,
        }),
        [hpState, isDesktop, isMobileDevice, questions]
    );

    return (
        <HPFastContext.Provider value={HPFastContextValue}>
            <BusinessModelsProContextProvider>{children}</BusinessModelsProContextProvider>
        </HPFastContext.Provider>
    );
};
