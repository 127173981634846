import { USER_COUNTRY_LatLng } from './user';

export const DEFAULTS_COMMON_SETTINGS_MAP = {
    apiKey: process.env.REACT_APP_GMAP_API_KEY,
    libraries: ['places', 'drawing', 'geometry'],
    version: 'quarterly',
    language: 'en',
};
export const DEFAULTS_PROPS_MAP = {
    mapOptions: {
        id: 'efz-map',
        zoom: 7,
        tilt: 0,
        mapType: 'satellite',
        mapTypeId: 'hybrid',
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: true,
        // disableDefaultUI: false,
    },
    settings: DEFAULTS_COMMON_SETTINGS_MAP,
    country: USER_COUNTRY_LatLng,
    polygon: {
        styles: {
            strokeOpacity: 0.9,
            strokeWeight: 2,
            fillOpacity: 0.4,
            strokeColor: {
                selected: 'rgba(245, 234, 143, 1)',
                notSelected: 'rgba(72, 68, 71, 1)',
                panelSelected: 'rgba(255, 255, 255, 1)',
                panelNotSelected: 'rgba(255, 255, 255, 0.3)',
            },
            fillColor: {
                selected: 'rgba(214, 194, 20, 1)',
                notSelected: 'rgba(251, 247, 208, 0.5)',
                panelSelected: 'rgba(1, 23, 61, 1)',
                panelNotSelected: 'rgba(1, 23, 61, 0.3)',
                panelInvalid: 'rgba(238, 22, 45, 0.5)',
            },
        },
    },
};
