/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description ACTION TYPES AND DefaultObject, context -> Proposal
 *
 * @version 20191203
 * @version 20191202
 * @version 20191125
 * @since 20191113 Initial release
 *
 */

// * DEFAULT OBJECTS * \\
export const INITIAL_PROPOSAL = {
    contractAvailable: false,
    facility: {
        id: null,
    },
    id: null,
    inputs: null,
    payload: null,
    tipo_modelo_negocio_id: null,
    data: [],
    contractDownloaded: false,
};

export const STATUS_CRM = {
    NULL: null,
    SUBMITTED: 'SUBMITTED',
    COMPLETED: 'COMPLETED',
    ABORTED: 'ABORTED',
};

export const NO_ACCESS_TO_PROPOSAL_DELAY = 5000;

// * ACTION TYPES * \\
export const SET_PROPOSAL = 'SET_PROPOSAL';
export const SET_PROPOSAL_INPUTS = 'SET_PROPOSAL_INPUTS';
export const SET_PROPOSAL_PAYLOAD = 'SET_PROPOSAL_PAYLOAD';
export const SET_NO_ACCESS_TO_PROPOSAL = 'SET_NO_ACCESS_TO_PROPOSAL';
export const RESET_PROPOSAL = 'RESET_PROPOSAL';
export const REQUEST_GET_PROPOSAL_ID = 'REQUEST_GET_PROPOSAL_ID';
export const REQUEST_GET_PROPOSAL_ID_SUCCESS = 'REQUEST_GET_PROPOSAL_ID_SUCCESS';
export const REQUEST_GET_PROPOSAL_INFORMATION = 'REQUEST_GET_PROPOSAL_INFORMATION';
export const REQUEST_GET_PROPOSAL_INFORMATION_SUCCESS = 'REQUEST_GET_PROPOSAL_INFORMATION_SUCCESS';
export const REQUEST_HEAD_SUPPORT_DOCS = 'REQUEST_HEAD_SUPPORT_DOCS';
export const REQUEST_HEAD_SUPPORT_DOCS_SUCCESS = 'REQUEST_HEAD_SUPPORT_DOCS_SUCCESS';
export const REQUEST_GET_SUPPORT_DOCS = 'REQUEST_GET_SUPPORT_DOCS';
export const REQUEST_GET_SUPPORT_DOCS_SUCCESS = 'REQUEST_GET_SUPPORT_DOCS_SUCCESS';
export const REQUEST_HEAD_SUPPORT_DOCS_IS_NOT_AVAILABLE = 'REQUEST_HEAD_SUPPORT_DOCS_IS_NOT_AVAILABLE';
