import { TBMProjectSummary, TBMKpi } from './../../interfaces/businessModels/index';
import { parseNumberWithDecimals } from 'services/util/auxiliaryUtils';

export const serviceV2ProjectSummary = (kpis: TBMProjectSummary[]): TBMKpi[] => {
    return [
        {
            label: 'label.annualPrice',
            value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].annual_price, 0),
            unit: 'currency',
            numberOfDecimalPlaces: 0,
        },
        {
            label: 'label.annualSaving',
            value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].annual_saving, 0),
            unit: 'currency',
            numberOfDecimalPlaces: 0,
        },
    ];
};
