/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description redux Actions - Proposal
 *
 * @version 20191203
 * @version 20191202
 * @version 20191125
 * @since 20191113 Initial release
 *
 */

import {
    RESET_PROPOSAL,
    SET_PROPOSAL,
    SET_PROPOSAL_INPUTS,
    SET_PROPOSAL_PAYLOAD,
    REQUEST_GET_PROPOSAL_ID,
    REQUEST_GET_PROPOSAL_ID_SUCCESS,
    REQUEST_GET_PROPOSAL_INFORMATION,
    REQUEST_GET_PROPOSAL_INFORMATION_SUCCESS,
    REQUEST_HEAD_SUPPORT_DOCS,
    REQUEST_HEAD_SUPPORT_DOCS_SUCCESS,
    REQUEST_GET_SUPPORT_DOCS,
    REQUEST_GET_SUPPORT_DOCS_SUCCESS,
    REQUEST_HEAD_SUPPORT_DOCS_IS_NOT_AVAILABLE,
    SET_NO_ACCESS_TO_PROPOSAL,
} from 'constants/proposal';

export function setProposal(payload) {
    return {
        type: SET_PROPOSAL,
        payload,
    };
}
export function setProposalInputs(payload) {
    return {
        type: SET_PROPOSAL_INPUTS,
        payload,
    };
}
export function setNoAccessToProposal() {
    return {
        type: SET_NO_ACCESS_TO_PROPOSAL,
    };
}
export function setProposalPayload(payload) {
    return {
        type: SET_PROPOSAL_PAYLOAD,
        payload,
    };
}

export function resetProposal() {
    return {
        type: RESET_PROPOSAL,
    };
}

export function requestGetProposalID(payload) {
    return {
        type: REQUEST_GET_PROPOSAL_ID,
        payload: payload,
    };
}

export function requestGetProposalIDSuccess(payload) {
    return {
        type: REQUEST_GET_PROPOSAL_ID_SUCCESS,
        payload: payload,
    };
}

export function requestGetProposalInformation(payload) {
    return {
        type: REQUEST_GET_PROPOSAL_INFORMATION,
        payload: payload,
    };
}

export function requestGetProposalInformationSuccess(payload) {
    return {
        type: REQUEST_GET_PROPOSAL_INFORMATION_SUCCESS,
        payload: payload,
    };
}

export function requestHeadSupportDocs(payload) {
    return {
        type: REQUEST_HEAD_SUPPORT_DOCS,
        payload: payload,
    };
}

export function requestHeadSupportDocsSuccess(payload) {
    return {
        type: REQUEST_HEAD_SUPPORT_DOCS_SUCCESS,
        payload: payload,
    };
}

export function requestGetSupportDocs(payload) {
    return {
        type: REQUEST_GET_SUPPORT_DOCS,
        payload: payload,
    };
}

export function requestGetSupportDocsSuccess(payload) {
    let url = URL.createObjectURL(new Blob([payload.data]));
    let a = document.createElement('a');

    a.href = url;
    a.download = payload.file_name;
    document.body.appendChild(a);
    a.click();

    return {
        type: REQUEST_GET_SUPPORT_DOCS_SUCCESS,
        payload: payload,
    };
}

export function requestHeadSupportDocsIsNotAvailable() {
    return {
        type: REQUEST_HEAD_SUPPORT_DOCS_IS_NOT_AVAILABLE,
    };
}
