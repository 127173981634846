import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as DownloadIcon } from 'assets/@efz/icons/dowload-oa.svg';
export const USE_POLICY_BODY_OA = () => ({
    title: <IntlMessages id="footer.conditions" />,
    body: (
        <div className="consent-use-conditions">
            <div className="consent-title">
                <span>
                    <IntlMessages id="page.terms" />
                </span>
                <hr />
            </div>
            <>
                <section className="consent-section">
                    <div className="consent-section-title">
                        <span>
                            <IntlMessages id="page.OA.use" />
                        </span>
                        <hr />
                    </div>
                    <div className="consent-section-content">
                        <IntlMessages id="page.OA.use.1" />
                        <br />
                        <br />
                        <IntlMessages id="page.OA.use.2" />
                        <br />
                        <br />
                        <IntlMessages id="page.OA.use.3" />
                        <br />
                    </div>
                </section>

                <section className="consent-section">
                    <div className="consent-section-title">
                        <span>
                            <IntlMessages id="page.OA.intel" />
                        </span>
                        <hr />
                    </div>
                    <div className="consent-section-content">
                        <p>
                            <IntlMessages id="page.OA.intel.1" />
                        </p>
                        <p>
                            {' '}
                            <IntlMessages id="page.OA.intel.2" />
                        </p>
                        <p>
                            <IntlMessages id="page.OA.intel.3" />
                        </p>
                        <p>
                            <IntlMessages id="page.OA.intel.3" />
                        </p>
                    </div>
                </section>

                <section className="consent-section">
                    <div className="consent-section-title">
                        <span>
                            <IntlMessages id="page.OA.hyperLinks" />
                        </span>
                        <hr />
                    </div>
                    <div className="consent-section-content">
                        <IntlMessages id="page.OA.hyperLinks.1" />
                        <br />
                        <br />
                    </div>
                </section>

                <section className="consent-section">
                    <div className="consent-section-title">
                        <span>
                            <IntlMessages id="page.OA.hyperThird" />
                        </span>
                        <hr />
                    </div>
                    <div className="consent-section-content">
                        <IntlMessages id="page.OA.hyperThird.1" />
                        <br />
                        <br />
                    </div>
                </section>

                <section className="consent-section">
                    <div className="consent-section-title">
                        <span>
                            <IntlMessages id="page.OA.responsibility" />
                        </span>
                        <hr />
                    </div>
                    <div className="consent-section-content">
                        <IntlMessages id="page.OA.responsibility.1" />
                        <br />
                        <br />
                        <IntlMessages id="page.OA.responsibility.2" />
                    </div>
                </section>

                <section className="consent-section">
                    <div className="consent-section-title">
                        <span>
                            <IntlMessages id="page.OA.limitation" />
                        </span>
                        <hr />
                    </div>
                    <div className="consent-section-content">
                        <IntlMessages id="page.OA.limitation.1" />
                        <br />
                        <br />
                        <IntlMessages id="page.OA.limitation.2" />
                    </div>
                </section>

                <section className="consent-section">
                    <div className="consent-section-title">
                        <span>
                            <IntlMessages id="page.OA.personal" />
                        </span>
                        <hr />
                    </div>
                    <div className="consent-section-content">
                        <IntlMessages id="page.OA.personal.1" />
                        <br />
                        <br />
                        <IntlMessages id="page.OA.personal.2" />
                    </div>
                </section>

                <section className="consent-section">
                    <div className="consent-section-title">
                        <span>
                            <IntlMessages id="page.OA.applicable" />
                        </span>
                        <hr />
                    </div>
                    <div className="consent-section-content">
                        <IntlMessages id="page.OA.applicable.1" />
                        <br />
                        <br />
                        <IntlMessages id="page.OA.applicable.2" />
                    </div>
                </section>

                <section className="consent-section">
                    <div className="consent-section-title">
                        <span>
                            <IntlMessages id="page.OA.mods" />
                        </span>
                        <hr />
                    </div>
                    <div className="consent-section-content">
                        <p>
                            <IntlMessages id="page.OA.mods.1" />
                        </p>
                        <p>
                            <IntlMessages id="page.OA.mods.2" />
                        </p>
                    </div>
                </section>

                <section className="consent-section">
                    <div className="consent-section-title">
                        <span>
                            <IntlMessages id="page.OA.suggestions" />
                        </span>
                        <hr />
                    </div>
                    <div className="consent-section-content">
                        <IntlMessages id="page.OA.suggestions.1" />
                        <br />
                        <br />
                        <IntlMessages id="page.OA.suggestions.2" />
                    </div>
                </section>
            </>
        </div>
    ),
    updated: '13/08/2023',
});

export const PRIVACY_POLICY_BODY_OA = (link1, link2) => ({
    title: <IntlMessages id="footer.privacyPolicy" />,
    body: (
        <div className="consent-privacy-policy">
            <div className="consent-title">
                <span>
                    <IntlMessages id="page.privacy" />
                </span>
                <hr />
            </div>
            <section className="consent-section">
                <div className="consent-section-content">
                    <IntlMessages id="page.privacy.1" />
                    <br />
                    <br />
                    <IntlMessages id="page.privacy.2" />
                    <br />
                    <br />
                    <IntlMessages id="page.privacy.3" />
                    <br />
                    <br />
                    <IntlMessages id="page.privacy.4" />
                    <br />
                    <br />
                    <IntlMessages id="page.privacy.5.1" />{' '}
                    <span className="link" onClick={link1}>
                        <IntlMessages id="label.here" />
                    </span>{' '}
                    <IntlMessages id="page.privacy.5.2" />{' '}
                    <span className="link" onClick={link2}>
                        <IntlMessages id="label.here" />
                    </span>
                    .
                </div>
            </section>

            <section className="consent-section">
                <div className="consent-section-title">
                    <span>
                        1. <IntlMessages id="page.privacy.scope" />
                    </span>
                    <hr />
                </div>
                <div className="consent-section-content">
                    <IntlMessages id="page.privacy.scope.1" />
                    <br />
                    <br />
                    <IntlMessages id="page.privacy.scope.2" />
                </div>
            </section>

            <section className="consent-section">
                <div className="consent-section-title">
                    <span>
                        2. <IntlMessages id="page.privacy.purpose" />{' '}
                    </span>
                    <hr />
                </div>
                <div className="consent-section-content">
                    <IntlMessages id="page.privacy.purpose.1" />
                </div>
            </section>

            <section className="consent-section">
                <div className="consent-section-title">
                    <span>
                        3. <IntlMessages id="page.privacy.recipients" />
                    </span>
                    <hr />
                </div>
                <div className="consent-section-content">
                    <IntlMessages id="page.privacy.recipients.1" />
                    <br />
                    <br />
                    <IntlMessages id="page.privacy.recipients.2" />
                </div>
            </section>

            <section className="consent-section">
                <div className="consent-section-title">
                    <span>
                        4. <IntlMessages id="page.privacy.userBeahaviour" />
                    </span>
                    <hr />
                </div>
                <div className="consent-section-content">
                    <IntlMessages id="page.privacy.userBeahaviour.1" />
                </div>
            </section>

            <section className="consent-section">
                <div className="consent-section-title">
                    <span>
                        5. <IntlMessages id="page.privacy.datatransfer" />
                    </span>
                    <hr />
                </div>
                <div className="consent-section-content">
                    <IntlMessages id="page.privacy.datatransfer.1" />
                </div>
            </section>

            <section className="consent-section">
                <div className="consent-section-title">
                    <span>
                        6. <IntlMessages id="page.privacy.storage" />
                    </span>
                    <hr />
                </div>
                <div className="consent-section-content">
                    <IntlMessages id="page.privacy.storage.1" />
                </div>
            </section>

            <section className="consent-section">
                <div className="consent-section-title">
                    <span>
                        7. <IntlMessages id="page.privacy.security" />
                    </span>
                    <hr />
                </div>
                <div className="consent-section-content">
                    <IntlMessages id="page.privacy.security.1" />
                    <br />
                    <br />
                    <IntlMessages id="page.privacy.security.2" />
                </div>
            </section>

            <section className="consent-section">
                <div className="consent-section-title">
                    <span>
                        8. <IntlMessages id="page.privacy.datarights" />{' '}
                    </span>
                    <hr />
                </div>
                <div className="consent-section-content">
                    <IntlMessages id="page.privacy.datarights.1" />
                    <br />
                    <br />
                    <IntlMessages id="page.privacy.datarights.2" />
                    <br />
                    <br />
                    <table className="privacy-policy-table">
                        <tbody>
                            <tr>
                                <th className="text-center" colSpan="2">
                                    <IntlMessages id="page.privacy.datarights.table" />
                                </th>
                            </tr>
                            {privacyPolicyTable.map((row, index) => (
                                <tr key={`privacy-policy-td-${index}`}>
                                    <td className="first-cell text-left">{row.first}</td>
                                    <td className="second-cell">{row.second}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <IntlMessages id="page.privacy.datarights.3" />
                    <br />
                    <br />
                    <IntlMessages id="page.privacy.datarights.4" />
                </div>
            </section>

            <section className="consent-section">
                <div className="consent-section-title">
                    <span>
                        9. <IntlMessages id="page.privacy.changes" />
                    </span>
                    <hr />
                </div>
                <div className="consent-section-content">
                    <IntlMessages id="page.privacy.changes.1" />
                </div>
            </section>
        </div>
    ),
    updated: '26/01/2021',
});

export const COOKIES_POLICY_BODY_OA = (link1, isOArea = true) => ({
    title: <IntlMessages id="footer.cookiesPolicy" />,
    body: (
        <div className="consent-privacy-policy">
            <div className="consent-title">
                <span>
                    <IntlMessages id="page.cookies" />
                </span>
                <hr />
            </div>
            {!isOArea ?
                <>
                    <section className="consent-section">
                        <div className="consent-section-title">
                            <span>
                                <IntlMessages id="page.cookies.intro" />
                            </span>
                            <hr />
                        </div>
                        <div className="consent-section-content">
                            <IntlMessages id="page.cookies.intro.1.1" />{' '}
                            <span className="link" onClick={link1}>
                                <IntlMessages id="label.here" />
                            </span>
                            <IntlMessages id="page.cookies.intro.1.2" />
                            <br />
                            <br />
                            <IntlMessages id="page.cookies.intro.2" />
                        </div>
                    </section>

                    <section className="consent-section">
                        <div className="consent-section-title">
                            <span>
                                <IntlMessages id="page.cookies.what" />
                            </span>
                            <hr />
                        </div>
                        <div className="consent-section-content">
                            <IntlMessages id="page.cookies.what.1" />
                            <br />
                            <br />
                            <IntlMessages id="page.cookies.what.2" />
                            <br />
                            <br />
                            &emsp;(a) <IntlMessages id="page.cookies.what.2.a" />
                            <br />
                            <br />
                            &emsp;(b) <IntlMessages id="page.cookies.what.2.b" />
                            <br />
                            <br />
                            &emsp;(c) <IntlMessages id="page.cookies.what.2.c" />
                            <br />
                            <br />
                            &emsp;(d) <IntlMessages id="page.cookies.what.2.d" />
                            <br />
                            <br />
                            <IntlMessages id="page.cookies.what.3" />
                            <br />
                            <br />
                            &emsp;(a) <IntlMessages id="page.cookies.what.3.a" />
                            <br />
                            <br />
                            &emsp;(b) <IntlMessages id="page.cookies.what.3.b" />
                            <br />
                            <br />
                            <IntlMessages id="page.cookies.what.4" />
                            <br />
                            <br />
                            &emsp;(a) <IntlMessages id="page.cookies.what.4.a" />
                            <br />
                            <br />
                            &emsp;(b) <IntlMessages id="page.cookies.what.4.b" />
                        </div>
                    </section>

                    <section className="consent-section">
                        <div className="consent-section-title">
                            <span>
                                <IntlMessages id="page.cookies.used" />
                            </span>
                            <hr />
                        </div>
                        <div className="consent-section-content">
                            <IntlMessages id="page.cookies.used.1" />
                            <br />
                            <br />
                            <table className="cookie-policy-table">
                                <tbody>
                                    <tr className="head-row">
                                        <th className="cell-spacer" key={`cell-`}></th>
                                        {cookiePolicyTable.th.map((th, index) => (
                                            <th key={`cookie-policy-th-${index}`}>{th}</th>
                                        ))}
                                        <th className="cell-spacer"></th>
                                    </tr>
                                    {cookiePolicyTable.td.map((tr, i) => (
                                        <tr className="cookie-policy-row" key={`row-${i}`}>
                                            <td className="cell-spacer"></td>
                                            {tr.map((td, y) => (
                                                <td key={`cookie-policy-td-${i}-${y}`}>{td}</td>
                                            ))}
                                            <td className="cell-spacer"></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>

                    <section className="consent-section">
                        <div className="consent-section-title">
                            <span>
                                <IntlMessages id="page.cookies.accept" />
                            </span>
                            <hr />
                        </div>
                        <div className="consent-section-content">
                            <IntlMessages id="page.cookies.accept.1" />
                        </div>
                    </section>

                    <section className="consent-section">
                        <div className="consent-section-title">
                            <span>
                                <IntlMessages id="page.cookies.manage" />
                            </span>
                            <hr />
                        </div>
                        <div className="consent-section-content">
                            <IntlMessages id="page.cookies.manage.1" />
                            <br />
                            <br />
                            • <IntlMessages id="page.cookies.manage.1.1" whitespaceEnd />{' '}
                            <span>
                                <a className="link" href={browsersCookiePolicy.FIREFOX} target="_blank" rel="noopener noreferrer">
                                    <IntlMessages id="label.here" />
                                </a>
                            </span>
                            ;
                            <br />
                            <br />
                            • <IntlMessages id="page.cookies.manage.1.2" />{' '}
                            <span>
                                <a className="link" href={browsersCookiePolicy.CHROME} target="_blank" rel="noopener noreferrer">
                                    <IntlMessages id="label.here" />
                                </a>
                            </span>
                            ;
                            <br />
                            <br />
                            • <IntlMessages id="page.cookies.manage.1.3" />{' '}
                            <span>
                                <a className="link" href={browsersCookiePolicy.EDGE} target="_blank" rel="noopener noreferrer">
                                    <IntlMessages id="label.here" />
                                </a>
                            </span>
                            ;
                            <br />
                            <br />
                            • <IntlMessages id="page.cookies.manage.1.4" />{' '}
                            <span>
                                <a className="link" href={browsersCookiePolicy.SAFARI} target="_blank" rel="noopener noreferrer">
                                    <IntlMessages id="label.here" />
                                </a>
                            </span>
                            ;
                            <br />
                            <br />
                            • <IntlMessages id="page.cookies.manage.1.5" />{' '}
                            <span>
                                <a className="link" href={browsersCookiePolicy.OPERA} target="_blank" rel="noopener noreferrer">
                                    <IntlMessages id="label.here" />
                                </a>
                            </span>
                            ;
                            <br />
                            <br />
                            <IntlMessages id="page.cookies.manage.2" />
                        </div>
                    </section>

                    <section className="consent-section">
                        <div className="consent-section-title">
                            <span>
                                <IntlMessages id="page.cookies.changes" />
                            </span>
                            <hr />
                        </div>
                        <div className="consent-section-content">
                            <IntlMessages id="page.cookies.changes.1" />
                        </div>
                    </section>
                </>
            :   <>
                    <section className="consent-section">
                        <div className="consent-section-content">
                            <p>
                                <IntlMessages id="page.cookies.used.oa.1" />
                            </p>

                            <p>
                                <IntlMessages id="page.cookies.used.oa.2" />
                            </p>

                            <p>
                                <IntlMessages id="page.cookies.used.oa.3" />
                            </p>

                            <p>
                                <IntlMessages id="page.cookies.used.oa.4" />
                            </p>

                            <p>
                                {' '}
                                <IntlMessages id="page.cookies.used.oa.5" />
                            </p>

                            <p>
                                <IntlMessages id="page.cookies.used.oa.6" />
                            </p>

                            <table className="cookie-policy-table">
                                <tbody>
                                    <tr className="head-row">
                                        <th className="cell-spacer" key={`cell-`}></th>
                                        {cookiePolicyTableOArea.th.map((th, index) => (
                                            <th key={`cookie-policy-th-${index}`}>{th}</th>
                                        ))}
                                        <th className="cell-spacer"></th>
                                    </tr>
                                    {cookiePolicyTableOArea.td.map((tr, i) => (
                                        <tr className="cookie-policy-row" key={`row-${i}`}>
                                            <td className="cell-spacer"></td>
                                            {tr.map((td, y) => (
                                                <td key={`cookie-policy-td-${i}-${y}`}>{td}</td>
                                            ))}
                                            <td className="cell-spacer"></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </>
            }
        </div>
    ),
    updated: '30/07/2024',
});

export const PERSONAL_DATA_OA = (isoLocale) => ({
    title: <IntlMessages id="footer.personalData.1" />,
    body: (
        <div className="consent-use-conditions">
            <div className="consent-title">
                <span>
                    <IntlMessages id="footer.personalData.1" />
                </span>
                <hr />
            </div>
            <>
                <section className="consent-section w-100">
                    <ul className="personal_data-wrap">
                        <li className="personal_data-wrap-list">
                            <a
                                className="personal_data-wrap-list-item"
                                href={`${isoLocale === 'gr' ? 'https://www.dei.gr/media/xa0oucm5/privacy-statement-gr.pdf' : 'https://www.dei.gr/media/figd0tx2/privacy-statement-en.pdf'}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <p>
                                    <IntlMessages id="page.personalData.1" />
                                </p>
                                <DownloadIcon />
                            </a>
                        </li>
                        <li className="personal_data-wrap-list">
                            <a
                                className="personal_data-wrap-list-item"
                                href={`${isoLocale === 'gr' ? 'https://www.dei.gr/media/i5okqdto/politiki-prostasias-dedomenon-prosopikou-xaraktira.pdf' : 'https://www.dei.gr/media/bvblujo0/personal-data-protection-policy-en.pdf'}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <p>
                                    <IntlMessages id="page.personalData.2" />
                                </p>
                                <DownloadIcon />
                            </a>
                        </li>
                        <li className="personal_data-wrap-list">
                            <a
                                className="personal_data-wrap-list-item"
                                href={`${isoLocale === 'gr' ? 'https://www.dei.gr/media/ivoptuzp/entupo-askhshs-dikaiomaton-deh-3-dekembriou-2021.pdf' : 'https://www.dei.gr/media/q2knvmgx/entupo-askhshs-dikaiomaton-deh-26112021-en.pdf'}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <p>
                                    <IntlMessages id="page.personalData.3" />
                                </p>
                                <DownloadIcon />
                            </a>
                        </li>
                    </ul>
                </section>
            </>
        </div>
    ),
    updated: '13/08/2023',
});

const privacyPolicyTable = [
    {
        first: <IntlMessages id="page.privacy.datarights.table.1.1" />,
        second: <IntlMessages id="page.privacy.datarights.table.1.2" />,
    },
    {
        first: <IntlMessages id="page.privacy.datarights.table.2.1" />,
        second: <IntlMessages id="page.privacy.datarights.table.2.2" />,
    },
    {
        first: <IntlMessages id="page.privacy.datarights.table.3.1" />,
        second: <IntlMessages id="page.privacy.datarights.table.3.2" />,
    },
    {
        first: <IntlMessages id="page.privacy.datarights.table.4.1" />,
        second: <IntlMessages id="page.privacy.datarights.table.4.2" />,
    },
    {
        first: <IntlMessages id="page.privacy.datarights.table.5.1" />,
        second: <IntlMessages id="page.privacy.datarights.table.5.2" />,
    },
    {
        first: <IntlMessages id="page.privacy.datarights.table.6.1" />,
        second: <IntlMessages id="page.privacy.datarights.table.6.2" />,
    },
];

const cookiePolicyTable = {
    th: [
        <IntlMessages id="page.cookies.used.table.header.1" />,
        <IntlMessages id="page.cookies.used.table.header.2" />,
        <IntlMessages id="page.cookies.used.table.header.3" />,
        <IntlMessages id="page.cookies.used.table.header.4" />,
        <IntlMessages id="page.cookies.used.table.header.5" />,
        <IntlMessages id="page.cookies.used.table.header.6" />,
    ],
    td: [
        [
            <IntlMessages id="page.cookies.used.table.body.1.1" />,
            <IntlMessages id="page.cookies.used.table.body.1.2" />,
            <IntlMessages id="page.cookies.used.table.body.1.3" />,
            <IntlMessages id="page.cookies.used.table.body.1.4" />,
            <IntlMessages id="page.cookies.used.table.body.1.5" />,
            <IntlMessages id="page.cookies.used.table.body.1.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.2.1" />,
            <IntlMessages id="page.cookies.used.table.body.2.2" />,
            <IntlMessages id="page.cookies.used.table.body.2.3" />,
            <IntlMessages id="page.cookies.used.table.body.2.4" />,
            <IntlMessages id="page.cookies.used.table.body.2.5" />,
            <IntlMessages id="page.cookies.used.table.body.2.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.3.1" />,
            <IntlMessages id="page.cookies.used.table.body.3.2" />,
            <IntlMessages id="page.cookies.used.table.body.3.3" />,
            <IntlMessages id="page.cookies.used.table.body.3.4" />,
            <IntlMessages id="page.cookies.used.table.body.3.5" />,
            <IntlMessages id="page.cookies.used.table.body.3.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.3.1" />,
            <IntlMessages id="page.cookies.used.table.body.3.2" />,
            <IntlMessages id="page.cookies.used.table.body.3.3" />,
            <IntlMessages id="page.cookies.used.table.body.3.4" />,
            <IntlMessages id="page.cookies.used.table.body.3.5" />,
            <IntlMessages id="page.cookies.used.table.body.3.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.5.1" />,
            <IntlMessages id="page.cookies.used.table.body.5.2" />,
            <IntlMessages id="page.cookies.used.table.body.5.3" />,
            <IntlMessages id="page.cookies.used.table.body.5.4" />,
            <IntlMessages id="page.cookies.used.table.body.5.5" />,
            <IntlMessages id="page.cookies.used.table.body.5.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.6.1" />,
            <IntlMessages id="page.cookies.used.table.body.6.2" />,
            <IntlMessages id="page.cookies.used.table.body.6.3" />,
            <IntlMessages id="page.cookies.used.table.body.6.4" />,
            <IntlMessages id="page.cookies.used.table.body.6.5" />,
            <IntlMessages id="page.cookies.used.table.body.6.6" />,
        ],
    ],
};

const cookiePolicyTableOArea = {
    th: [
        <IntlMessages id="page.cookies.used.table.header.1" />,
        <IntlMessages id="page.cookies.used.table.header.2" />,
        <IntlMessages id="page.cookies.used.table.header.3" />,
        <IntlMessages id="page.cookies.used.table.header.4" />,
        <IntlMessages id="page.cookies.used.table.header.5" />,
        <IntlMessages id="page.cookies.used.table.header.6" />,
    ],
    td: [
        [
            <IntlMessages id="page.cookies.used.table.body.1.1" />,
            <IntlMessages id="page.cookies.used.table.body.1.2" />,
            <IntlMessages id="page.cookies.used.table.body.1.3" />,
            <IntlMessages id="page.cookies.used.table.body.1.4" />,
            <IntlMessages id="page.cookies.used.table.body.1.5" />,
            <IntlMessages id="page.cookies.used.table.body.1.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.2.1.1" />,
            <IntlMessages id="page.cookies.used.table.body.2.2" />,
            <IntlMessages id="page.cookies.used.table.body.2.3" />,
            <IntlMessages id="page.cookies.used.table.body.2.4" />,
            <IntlMessages id="page.cookies.used.table.body.2.5" />,
            <IntlMessages id="page.cookies.used.table.body.2.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.2.1.2" />,
            <IntlMessages id="page.cookies.used.table.body.2.2" />,
            <IntlMessages id="page.cookies.used.table.body.2.3" />,
            <IntlMessages id="page.cookies.used.table.body.2.4" />,
            <IntlMessages id="page.cookies.used.table.body.2.5" />,
            <IntlMessages id="page.cookies.used.table.body.2.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.3.1" />,
            <IntlMessages id="page.cookies.used.table.body.3.2" />,
            <IntlMessages id="page.cookies.used.table.body.3.3" />,
            <IntlMessages id="page.cookies.used.table.body.3.4" />,
            <IntlMessages id="page.cookies.used.table.body.3.5" />,
            <IntlMessages id="page.cookies.used.table.body.3.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.5.1.1" />,
            <IntlMessages id="page.cookies.used.table.body.5.2" />,
            <IntlMessages id="page.cookies.used.table.body.5.3" />,
            <IntlMessages id="page.cookies.used.table.body.5.4" />,
            <IntlMessages id="page.cookies.used.table.body.5.5" />,
            <IntlMessages id="page.cookies.used.table.body.5.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.5.1.2" />,
            <IntlMessages id="page.cookies.used.table.body.5.2" />,
            <IntlMessages id="page.cookies.used.table.body.5.3" />,
            <IntlMessages id="page.cookies.used.table.body.5.4" />,
            <IntlMessages id="page.cookies.used.table.body.5.5" />,
            <IntlMessages id="page.cookies.used.table.body.5.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.5.1.3" />,
            <IntlMessages id="page.cookies.used.table.body.5.2" />,
            <IntlMessages id="page.cookies.used.table.body.5.3" />,
            <IntlMessages id="page.cookies.used.table.body.5.4" />,
            <IntlMessages id="page.cookies.used.table.body.5.5" />,
            <IntlMessages id="page.cookies.used.table.body.5.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.5.1.4" />,
            <IntlMessages id="page.cookies.used.table.body.5.2" />,
            <IntlMessages id="page.cookies.used.table.body.5.3" />,
            <IntlMessages id="page.cookies.used.table.body.5.4" />,
            <IntlMessages id="page.cookies.used.table.body.5.5" />,
            <IntlMessages id="page.cookies.used.table.body.5.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.6.1.1" />,
            <IntlMessages id="page.cookies.used.table.body.6.2" />,
            <IntlMessages id="page.cookies.used.table.body.6.3" />,
            <IntlMessages id="page.cookies.used.table.body.6.4" />,
            <IntlMessages id="page.cookies.used.table.body.6.5" />,
            <IntlMessages id="page.cookies.used.table.body.6.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.6.1.2" />,
            <IntlMessages id="page.cookies.used.table.body.6.2" />,
            <IntlMessages id="page.cookies.used.table.body.6.3" />,
            <IntlMessages id="page.cookies.used.table.body.6.4" />,
            <IntlMessages id="page.cookies.used.table.body.6.5" />,
            <IntlMessages id="page.cookies.used.table.body.6.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.6.1.3" />,
            <IntlMessages id="page.cookies.used.table.body.6.2" />,
            <IntlMessages id="page.cookies.used.table.body.6.3" />,
            <IntlMessages id="page.cookies.used.table.body.6.4" />,
            <IntlMessages id="page.cookies.used.table.body.6.5" />,
            <IntlMessages id="page.cookies.used.table.body.6.6" />,
        ],
        [
            <IntlMessages id="page.cookies.used.table.body.7.1" />,
            <IntlMessages id="page.cookies.used.table.body.7.2" />,
            <IntlMessages id="page.cookies.used.table.body.7.3" />,
            <IntlMessages id="page.cookies.used.table.body.7.4" />,
            <IntlMessages id="page.cookies.used.table.body.7.5" />,
            <IntlMessages id="page.cookies.used.table.body.7.6" />,
        ],
    ],
};

const browsersCookiePolicy = {
    FIREFOX: 'https://support.mozilla.org/en-US/kb/private-browsing-use-firefox-without-history',
    CHROME: 'https://support.google.com/chromebook/answer/7440301?co=GENIE.Platform%3DDesktop&hl=en',
    EDGE: 'https://support.microsoft.com/en-us/microsoft-edge/browse-inprivate-in-microsoft-edge-cd2c9a48-0bc4-b98e-5e46-ac40c84e27e2',
    SAFARI: 'https://support.apple.com/guide/safari/use-private-browsing-ibrw1069/mac',
    OPERA: 'https://help.opera.com/en/opera36/be-safe-and-private/',
};
