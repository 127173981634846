import copy from 'fast-copy';

// Constants
import { DEFAULT_FORM_VALUES, KPIS, RRActions } from 'constants/products/rr';

// Interfaces
import { IRRReducerAction, TRRLoadingPayload } from 'interfaces/products/rr/index';
import { IRRState } from 'interfaces/products/rr/index';

// Services
import { isFieldDefined } from 'services/util/auxiliaryUtils';

export const initialRRReducer: IRRState = {
    mounted: false,
    isContractIDValid: false,
    inputs: DEFAULT_FORM_VALUES,
    options: {
        toRemove: [],
        toInstall: [],
        translucentPlate: [],
    },
    kpis: null,
    loadings: {
        general: true,
        contractID: false,
        sizing: false,
    },
};

export const rrReducer = (state = initialRRReducer, action: IRRReducerAction) => {
    switch (action.type) {
        case RRActions.SET_RR_MOUNT_DATA: {
            let newState = {
                ...state,
                ...action.payload,
                mounted: true,
                loadings: {
                    ...state.loadings,
                    general: false,
                },
            };

            if (isFieldDefined(action.payload?.inputs?.contract_id)) {
                newState = {
                    ...newState,
                    isContractIDValid: true,
                };
            }

            return newState;
        }

        case RRActions.SET_INPUTS_DATA:
            return {
                ...state,
                inputs: action.payload,
            };

        case RRActions.SET_RR_LOADING:
            if (Array.isArray(action.payload)) {
                const payload = action.payload as TRRLoadingPayload[];
                const _loadings = copy(state.loadings);
                payload.forEach((load) => {
                    _loadings[load.name] = load.value;
                });

                return {
                    ...state,
                    loadings: _loadings,
                };
            }

            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    [action.payload.name]: action.payload.value,
                },
            };

        case RRActions.SET_RR_CONTRACT_ID_VALIDITY:
            return {
                ...state,
                isContractIDValid: action.payload,
            };

        case RRActions.SET_RR_KPIS:
            return {
                ...state,
                kpis: {
                    ...action.payload,
                    [KPIS.COST]: isFieldDefined(action.payload?.[KPIS.COST]) ? parseFloat(action.payload?.[KPIS.COST]).toFixed(2) : null,
                    [KPIS.INSTALLMENTS_NUMBER]:
                        isFieldDefined(action.payload?.[KPIS.INSTALLMENTS_NUMBER]) ?
                            parseInt(action.payload?.[KPIS.INSTALLMENTS_NUMBER])
                        :   null,
                    [KPIS.INVESTMENT]:
                        isFieldDefined(action.payload?.[KPIS.INVESTMENT]) ? parseFloat(action.payload?.[KPIS.INVESTMENT]).toFixed(2) : null,
                    [KPIS.TOTAL_AREA]:
                        isFieldDefined(action.payload?.[KPIS.TOTAL_AREA]) ? parseFloat(action.payload?.[KPIS.TOTAL_AREA]).toFixed(2) : null,
                    [KPIS.TOTAL_ROOF_AREAS]:
                        isFieldDefined(action.payload?.[KPIS.TOTAL_ROOF_AREAS]) ? parseInt(action.payload?.[KPIS.TOTAL_ROOF_AREAS]) : null,
                },
                loadings: {
                    ...state.loadings,
                    general: false,
                    sizing: false,
                },
            };

        case RRActions.RESET: {
            if (!state.mounted) return state;

            return {
                ...state,
                inputs: DEFAULT_FORM_VALUES,
                kpis: null,
            };
        }

        default:
            return state;
    }
};
