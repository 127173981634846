import { useContext, useCallback, useState, useEffect } from 'react';
import IntlMessages from 'components/util/IntlMessages';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import Slider from '@mui/material/Slider';
import FormatNumber from 'components/util/FormatNumber';
import { debounce } from 'services/util/auxiliaryUtils';
import { DEBOUNCE_TIME } from 'constants/endpoints';
import { InstallmentsEvents, TInstallments } from 'interfaces/businessModels/installments';

const InstallmentsSimpleMarginMarks = () => {
    const { bmSelected, setBMEventHandler, isRequestingBM } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TInstallments
    >;

    const installmentsSimpleMarginValues = bmSelected?.values.kpis_per_margin;
    const searchValue = bmSelected.values?.margin_negotiation?.search ?? 'margin';
    const [sliderValue, setSliderValue] = useState(installmentsSimpleMarginValues?.find((kpi) => kpi.is_default)?.[searchValue]);

    const marks = installmentsSimpleMarginValues?.map((option) => ({
        value: option?.[searchValue],
        label: option?.[searchValue],
    }));

    const handleSliderChange = useCallback((_, newValue) => setSliderValue(newValue), []);

    // eslint-disable-next-line
    const delayedNewMarginHandler = useCallback(
        // eslint-disable-next-line
        debounce(
            (payload) =>
                setBMEventHandler(InstallmentsEvents.SET_INSTALLMENTS_MARGIN, marks?.find((mark) => mark.value === payload)?.label),
            DEBOUNCE_TIME
        ),
        []
    ); // eslint-disable-line

    useEffect(() => {
        //@ts-ignore
        delayedNewMarginHandler(sliderValue);
    }, [sliderValue]); // eslint-disable-line

    return (
        <div className="bm-margin">
            <div className="bm-margin-label">
                <IntlMessages id={bmSelected?.isDiscount ? 'label.selectDiscount' : 'label.selectMargin'} />
            </div>
            <div className="bm-margin-slider">
                {bmSelected?.values?.kpis_per_margin?.length > 0 && (
                    <Slider
                        disabled={isRequestingBM}
                        aria-label="margin-slider"
                        aria-labelledby="discrete-slider-restrict"
                        defaultValue={sliderValue}
                        min={marks[0].value}
                        max={marks[marks.length - 1]?.value}
                        step={null}
                        marks={marks}
                        valueLabelDisplay="on"
                        // @ts-ignore
                        valueLabelFormat={(value) => <FormatNumber styleFormat="percent" number={value} numberOfDecimalPlaces={2} />}
                        onChange={handleSliderChange}
                        value={sliderValue}
                    />
                )}
            </div>
        </div>
    );
};

export default InstallmentsSimpleMarginMarks;
