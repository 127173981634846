/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable  no-prototype-builtins */

import copy from 'fast-copy';
import { uniqBy } from 'lodash';

import { LatLng } from 'components/Products/solarpv/v3/map/Polygon/Coordinates';

// Constants
import { PRODUCT_IDS } from 'constants/products';
import { DISPATCH_EVT, MODO_KITS_IDS, SERVER_ERROR_TAGS, SPV_USEFUL_AREA_ACTIONS } from 'constants/products/spvPro';
import { USERS_TYPE_ID } from 'constants/user';
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { SIMPLE_MODE_STEPS, SolarPvToolbarOptions, SpvActions, SYSTEM_SETTINGS_GROUPS } from 'constants/products/solarpv';
import { IBuilding, TBuildingsDSPayload } from 'interfaces/products/solarpv/state/buildings';
import { ISpvInputs } from 'interfaces/products/solarpv/state/sims';
import { intlMessages, isDefined, isFieldDefined, removeEmptyInObj } from 'services/util/auxiliaryUtils';
import { Nullable } from 'types/utils';
import {
    adjustRecommendedPanelTypes,
    calcGlobalAvgGroupCoordinates,
    calcPerimeterAreaFromCoords,
    calcRegionId,
    calcTotalPanels,
    getGroupAreaByPolygonCoords,
    getStructureData,
    getSysLimitsTecPanels,
    validateNumberOfPanels,
} from '../solarpv';
import { getCompanyProfileIds } from 'services/user';
import {
    clearTheejsOverlay,
    closeStampModeHandler,
    exitModeAddPanels,
    exitModeAlignment,
    exitModeMove,
    exitModeOrientation,
    prepareAutoExclusionsBounds,
} from 'services/products/solarpv/map';
import { isEnvDevFlag } from 'services/settings';

export const getSpvInputs = (
    options: any,
    newInputs: ISpvInputs,
    solarPanelsData = [],
    putOnServer = true,
    onlySaveInputs = false,
    isChangeBattery = false,
    reducer
) => {
    const {
        ranges,
        pvOptions,
        pvKitsB2C,
        structures,
        pv_modo_kits_id,
        regionsGD,
        productID,
        setWarnDataBatteryDeleteDialog,
        userTypeID,
        fetchPutProductInputsOnHandler,
    } = reducer;
    //#region ranges
    if (options?.evt) {
        switch (options?.evt) {
            case DISPATCH_EVT.MOUNTING: {
                const defaultRange = ranges.find((item) => item.is_default);
                newInputs.range_id = defaultRange?.id; //default range_id
                break;
            }
            case DISPATCH_EVT.SET_RANGE_ID: {
                const { range_id } = newInputs;
                // Change the selected range panel type panel_id
                const rangeSelected = ranges.find((range) => range.id === range_id);
                if (rangeSelected?.paineis?.length > 0) {
                    newInputs.panel_id =
                        rangeSelected?.paineis?.find((el) => el.is_recommended)?.id ??
                        rangeSelected?.paineis?.find((el) => el.is_default)?.id;
                }

                break;
            }
            case DISPATCH_EVT.SET_RANGE_PANEL_ID: {
                const { range_id } = newInputs;
                // Change the selected range panel type panel_id
                const rangeSelected = ranges.find((range) => range.id === range_id);
                if (rangeSelected?.paineis?.length > 0) {
                    newInputs.panel_id =
                        rangeSelected?.paineis?.find((el) => el.is_recommended)?.id ??
                        rangeSelected?.paineis?.find((el) => el.is_default)?.id;
                }

                break;
            }
            case SpvActions.SET_ORIENTATION:
            case SpvActions.SET_MOUNTING_STRUCTURE_ID:
            case SpvActions.SET_ALIGNMENT: {
                newInputs.areas = newInputs.areas.map((area) =>
                    area.selected ? { ...area, manual_removed_idxs: [], manual_added_idxs: [] } : area
                );
                break;
            }
            default: {
                break;
            }
        }
    }
    //#endregion ranges

    //#region sysLimitsTecs
    const { minTecPanels, maxTecPanels } = getSysLimitsTecPanels(newInputs?.range_id, newInputs?.panel_id, {
        ranges,
        solarPanelsData,
        pvOptions: { ...pvOptions },
        remuneration_type_id: newInputs?.remuneration_type_id ?? null,
        pvKitsB2C,
        featureFlags: options?.featureFlags,
    });

    newInputs.min_tec_panels = minTecPanels;
    newInputs.max_tec_panels = maxTecPanels;
    //#endregion sysLimitsTecs

    const { mostra_pv_v2 } = pvOptions;

    const hasInputs = newInputs?.areas?.length > 0 || newInputs?.buildings?.length > 0;
    if (hasInputs && !mostra_pv_v2) {
        newInputs?.areas.forEach((group) => {
            const panels_number_possible = calcTotalPanels(group.area, group.slope, group.area_reduction_elements, {
                pvAreaReductionCoefficients: pvOptions?.pvAreaReductionCoefficients,
                settingsCompanyByRange: ranges?.find((el) => el.id === newInputs?.range_id),
                mStructureData: structures.find((el) => el.id === group.mounting_structure_id),
                panel_id: newInputs?.panel_id ?? null,
            });
            group.panels_number = panels_number_possible;
            group.panels_number_possible = panels_number_possible;
        });
    }

    // let minTecPanels = newInputs?.min_tec_panels ?? null;
    // let maxTecPanels = newInputs?.max_tec_panels ?? null;

    if (newInputs?.areas?.length > 0) {
        newInputs?.areas.forEach((group) => {
            const { structureData } = getStructureData(structures, group.mounting_structure_id);
            if (!isDefined(group?.mounting_structure_type_id)) group.mounting_structure_type_id = structureData.tipo_estrutura_id;
        });
    }
    if (!isDefined(newInputs?.prod_params)) newInputs.prod_params = pvOptions?.prod_params;

    if (hasInputs || !onlySaveInputs) {
        const is_kits = pvKitsB2C?.length > 0;

        // Get coordinates_avg for new array of groups
        newInputs = {
            ...newInputs,
            ...validateNumberOfPanels(
                newInputs.areas,
                newInputs?.range_id!,
                solarPanelsData,
                maxTecPanels,
                minTecPanels,
                newInputs?.panel_id,
                {
                    remuneration_type_id: newInputs?.remuneration_type_id ?? null,
                    pvKitsB2C: is_kits ? pvKitsB2C : [],
                    pv_modo_kits_id,
                }
            ),
        };

        //#region PV Kits
        if ([MODO_KITS_IDS.KIT_PER_STRUCTURE, MODO_KITS_IDS.SINGLE_KITS].includes(pv_modo_kits_id)) {
            newInputs.is_kits = is_kits;
            const mStructureIDs = [...new Set(newInputs.areas.map((el) => el.mounting_structure_id))];
            const kitsIDs = uniqBy(newInputs.areas, (el) => el.kit_id);
            const kits: { id: number; areas: Nullable<number>[] }[] = [];

            if (MODO_KITS_IDS.KIT_PER_STRUCTURE === pv_modo_kits_id) {
                mStructureIDs.forEach((ms) => {
                    // one entry per structure
                    kitsIDs.forEach((el) => {
                        if (el?.kit_id) {
                            const areasByKits = newInputs.areas
                                .filter((group) => group.mounting_structure_id === ms)
                                .filter((group) => group.kit_id === el?.kit_id);
                            if (areasByKits.length > 0) {
                                kits.push({
                                    id: el.kit_id,
                                    areas: [...new Set(areasByKits.map((item) => item.id))],
                                });
                            }
                        }
                    });
                });
            }
            if (MODO_KITS_IDS.SINGLE_KITS === pv_modo_kits_id) {
                kitsIDs.forEach((el) => {
                    if (el?.kit_id) {
                        const areasByKits = newInputs.areas.filter((group) => group.kit_id === el?.kit_id);
                        if (areasByKits.length > 0) {
                            kits.push({
                                id: el.kit_id,
                                areas: [...new Set(areasByKits.map((item) => item.id))],
                            });
                        }
                    }
                });
            }
            //@ts-ignores
            newInputs.kits = kits;
        }
        //#endregion PV Kits

        if (newInputs?.areas?.length > 0) {
            newInputs.coordinates_avg = calcGlobalAvgGroupCoordinates(newInputs.areas, regionsGD);
        }

        // validate area / perimeter area
        newInputs.areas.forEach((area) => {
            const _area = getGroupAreaByPolygonCoords(area.coordinates);
            const _perimeter_area = calcPerimeterAreaFromCoords(area.coordinates);

            if (isFieldDefined(_area)) area.area = _area;
            if (isFieldDefined(_perimeter_area)) area.perimeter_area = _perimeter_area;
        });

        //#region totalPanelsNumber
        newInputs.total_panels =
            newInputs?.areas.reduce(
                (accumulator, currentValue) =>
                    accumulator +
                    (isDefined(currentValue?.max_tec_area_panels) ? currentValue?.max_tec_area_panels! : currentValue.panels_number!),
                0
            ) ?? 0;
        newInputs.total_areas = newInputs?.areas.reduce((total, item) => (total += item.area!), 0);
        newInputs.total_perimeter_areas = newInputs?.areas.reduce((total, item) => (total += item.perimeter_area!), 0);
        //#endregion totalPanelsNumber

        //#region solarpv SB
        if (productID === PRODUCT_IDS.SPVSB && solarPanelsData?.length > 0) {
            const pv_kit: any =
                solarPanelsData?.find((kit: any) => kit.panels_number === newInputs.total_panels && kit.range_id === newInputs.range_id) ??
                null;
            newInputs.pv_kit_id = pv_kit?.id ? parseInt(pv_kit?.id) : null;
            // delete battery
            if (isFieldDefined(newInputs?.battery) && isFieldDefined(pv_kit) && !pv_kit?.has_battery) {
                delete newInputs.battery;
                setWarnDataBatteryDeleteDialog({
                    title: 'label.BatteryRemoved',
                    message: 'page.spv.battery.deleteBattery.pvKits.message',
                    values: { total_panels: newInputs.total_panels },
                });
            }
        }
        //#endregion
    }
    // range panel type
    if (ranges?.[0]?.paineis?.length > 0) {
        adjustRecommendedPanelTypes(
            newInputs.total_areas,
            ranges?.find((range) => range.id === newInputs?.range_id)
        );
        const rangeSelected = ranges.find((range) => range.id === newInputs?.range_id);
        const rangePanelRecommended = rangeSelected?.paineis?.find((el) => el.is_recommended);
        if (newInputs?.isFirstArea) {
            const hasRecommended = newInputs?.isFirstArea && isDefined(rangePanelRecommended?.id);
            newInputs.panel_id = hasRecommended ? rangePanelRecommended?.id : rangeSelected?.paineis?.find((el) => el.is_default)?.id;
            // trash
            delete newInputs?.isFirstArea;
        }

        if ([USERS_TYPE_ID.CLIENT, USERS_TYPE_ID.CHANNEL].includes(userTypeID)) {
            newInputs.panel_id = rangePanelRecommended?.id;
        }
    } else {
        delete newInputs.panel_id;
    }

    newInputs.isChangeBattery = isChangeBattery;

    if (!options?.hasProjectFeature && putOnServer) fetchPutProductInputsOnHandler(hasInputs ? newInputs : null);

    return { inputs: newInputs, ranges };
};

export const getRegionGDIdAndPointsPolygonV3 = (polygon, regionsGD) => {
    const polygon_points: { lat: number; lng: number }[] = [];
    const avg: { lat: number; long: number; lng?: number; region_gd_id: Nullable<number> } = {
        lat: 0.0,
        long: 0.0,
        region_gd_id: null,
    };

    polygon.getPaths().forEach(function (el) {
        const point = { lat: el.lat(), lng: el.lng() };
        //sum lat and lng
        avg.lat = avg.lat + el.lat();
        avg.long = (avg.lng ? avg.lng : avg.long) + el.lng();
        //save points
        polygon_points.push(point);
    });

    //avg lat and lng
    avg.lat = avg.lat / polygon_points.length;
    avg.long = (avg.lng ? avg.lng : avg.long) / polygon_points.length;
    avg.region_gd_id = calcRegionId(regionsGD, avg.lat, avg.lng ? avg.lng : avg.long)!;

    return {
        region_gd_id: avg.region_gd_id,
        lat: avg.lat,
        long: avg.long,
        coordinates: polygon_points,
        coordinates_avg: avg,
    };
};

export const getPayloadDAExclusions = (gMaxZoom, polygon, area) => {
    try {
        const boundsPolygon = new window.google.maps.LatLngBounds();
        polygon.getPaths().forEach(function (path) {
            boundsPolygon.extend(path.toJSON());
        });
        prepareAutoExclusionsBounds(boundsPolygon, gMaxZoom, polygon);

        const returnObj = removeEmptyInObj({
            center: {
                lat: boundsPolygon.getCenter().lat(),
                lng: boundsPolygon.getCenter().lng(),
                zoom: 20,
            },
            image_name: `exclusions-${polygon.id}-${new Date().getTime()}.png`,
            roof: [area?.coordinates],
            angle: area?.aspect ?? null,
        });

        return returnObj;
    } catch (error) {
        console.log('efz -> error', error);
    }
};

export const usefulAreaHandler = (usefulArea, action, options) => {
    let newUsefulArea;
    switch (action) {
        case SPV_USEFUL_AREA_ACTIONS.REMOVE_PANELS: {
            const { newGroupId } = options;
            newUsefulArea = !options?.onProject ? usefulArea.filter((area) => area.id !== newGroupId) : usefulArea;
            break;
        }
        case SPV_USEFUL_AREA_ACTIONS.ADD_FULL_PANELS: {
            const { newGroupId, data } = options;
            newUsefulArea = usefulArea.filter((area) => area.id !== newGroupId);

            const updUsefulArea = { id: newGroupId, ...data };
            newUsefulArea = [...newUsefulArea, updUsefulArea];
            break;
        }
        case SPV_USEFUL_AREA_ACTIONS.ADD_VALID_PANELS: {
            const { validPanels } = options;
            newUsefulArea = usefulArea.map((group) => {
                const newArea = validPanels.data.areas.find((area) => area.id === group.id);

                if (isDefined(newArea)) {
                    // Add valid_panels to each panel row
                    // Update panel_rows with the new info
                    const newGroup = { ...group, panel_rows: newArea.panel_rows };

                    return newGroup;
                } else {
                    // Remove valid_panels to each panel row
                    const newPanelRows = group.panel_rows.map(({ ...otherProps }) => otherProps);
                    // Update panel_rows with the new info
                    const newGroup = { ...group, panel_rows: newPanelRows };

                    return newGroup;
                }
            });
            break;
        }
        case SPV_USEFUL_AREA_ACTIONS.REMOVE_VALID_PANELS: {
            newUsefulArea = usefulArea.map((group) => {
                const newPanelRows = group.panel_rows.map(({ ...otherProps }) => otherProps);
                return { ...group, panel_rows: newPanelRows };
            });
            break;
        }
        case SPV_USEFUL_AREA_ACTIONS.ADD_GROUP_PANELS: {
            const areasInUse = options?.areas;
            const areasIDs = areasInUse?.map((el) => el.id) ?? [];
            newUsefulArea = usefulArea.filter((group) => areasIDs.includes(group.id));
            break;
        }
        default:
            newUsefulArea = usefulArea;
    }
    return newUsefulArea;
};

export function getKPIsOnDemand(state) {
    const { hasUpdatedKPIS, isComputeKPIs, hasAutoUpdateKPIs } = state;

    const _hasUpdatedKPIS = hasAutoUpdateKPIs ? hasUpdatedKPIS : false;

    return {
        hasUpdatedKPIS: _hasUpdatedKPIS,
        isComputeKPIs,
        hasAutoUpdateKPIs,
        simulations: _hasUpdatedKPIS ? state.simulations : null,
        standaloneSims: _hasUpdatedKPIS ? state.standaloneSims : null,
        bundleSims: _hasUpdatedKPIS ? state.bundleSims : null,
        productions: _hasUpdatedKPIS ? state.productions : null,
    };
}

export const buildExclusions = (exclusionGroups, newExclusion = null) => {
    if (newExclusion) {
        exclusionGroups.push(newExclusion);
    }

    return exclusionGroups
        .filter((el) => /* isDefined(el.id) &&  */ el?.visible || el?.point?.visible)
        .map((exclusion) => {
            return {
                id: exclusion.id,
                area: exclusion.computeArea() ?? 0,
                coordinates: exclusion.getPaths().map((path) => [path.lat(), path.lng()]),
                isPolygon: exclusion.isPolygon ?? true,
                height: exclusion.getHeight(),
            };
        });
};

export const parseBuildingPayload = (buildings, isThreeJSUpdate, options) => {
    const { id, polygon = {}, formPayload = null } = options;

    if (!isDefined(id) && !isDefined(polygon)) return [];

    let cBuildings = isDefined(buildings) ? copy(buildings) : [];
    const buildingToUpdate = cBuildings?.find((b) => b.id === id);

    const getProps = (polygon) => {
        const area = polygon.computeArea();
        const perimeter = polygon.computePerimeter();
        const anchorPointsNumber = !!area && !!perimeter && Math.ceil(perimeter / 4 + area / 30);
        return {
            area: area ?? null,
            perimeter: perimeter ?? null,
            anchorPoints: false,
            anchorPointsNumber: anchorPointsNumber ?? null,
            defaultAnchorPointsNumber: anchorPointsNumber ?? null,
        };
    };

    if (!isDefined(buildingToUpdate)) {
        cBuildings.push({
            id: polygon?.id,
            coordinates: polygon.getPaths(),
            description: `${intlMessages('page.spvPro.label.building')} ${polygon?.id}`,
            height: null,
            ...getProps(polygon),
        });
    } else {
        if (!isDefined(polygon)) {
            cBuildings = cBuildings.filter((building) => building.id !== id);
        } else {
            cBuildings = cBuildings.map((building) => {
                if (building.id === id) {
                    if (isThreeJSUpdate) {
                        return {
                            ...building,
                            ...getProps(polygon),
                        };
                    }
                    if (isDefined(formPayload)) {
                        return {
                            ...building,
                            ...formPayload,
                        };
                    }
                }
                return building;
            });
        }
    }

    return cBuildings;
};

export const parseDSBuildingPayload = (dsBuildings: TBuildingsDSPayload[]) => {
    return dsBuildings.map((b: TBuildingsDSPayload) => ({
        id: b.id,
        coordinates: b?.roof?.map((c) => new LatLng(c[0], c[1])),
        description: `${intlMessages('page.spvPro.label.building')} ${b.id}`,
        area: b?.area ?? null,
        perimeter: b?.perimeter ?? null,
        height: isDefined(b?.height) ? Math.ceil(Number(b?.height)) : null,
        anchorPoints: false,
        anchorPointsNumber: b.solar_panel_fixing_points ?? null,
        defaultAnchorPointsNumber: b.solar_panel_fixing_points ?? null,
    }));
};

export function isSomeModeSelected(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== false) {
            return true; // If any attribute has a value other than false, return false
        }
    }
    return false; // If none of the attributes have a value other than false, return true
}

export function getInvertersNumberByKits({ pvkits, currentKits, remuneration_id }) {
    const kitsByRemurationType = pvkits?.find((remun) => Number(remun.remuneration_id) === Number(remuneration_id))?.kits ?? [];
    let sumPotenciaNominal = 0;
    currentKits.forEach((elm) => {
        sumPotenciaNominal += kitsByRemurationType?.find((kit) => Number(kit.id) === Number(elm?.id))?.potencia_nominal ?? 0;
    });
    return sumPotenciaNominal;
}

export const nextStepSimpleMode = (step) => {
    switch (step) {
        case SIMPLE_MODE_STEPS.ADDRESS:
            return SIMPLE_MODE_STEPS.ROOF_IDENTIFICATION;
        case SIMPLE_MODE_STEPS.DRAW_ROOF_MANUALLY:
            return SIMPLE_MODE_STEPS.ROOF_IDENTIFICATION;
        case SIMPLE_MODE_STEPS.ROOF_DETAILS:
            return SIMPLE_MODE_STEPS.KPIS_DISPLAY;
        default:
            return SIMPLE_MODE_STEPS.ADDRESS;
    }
};

export const isSimpleForwardBtnDisabled = (step, loadings, options: { polygons: IBuilding[] }) => {
    switch (step) {
        case SIMPLE_MODE_STEPS.ADDRESS: {
            if (loadings?.changeAddress) return true;
            break;
        }
        case SIMPLE_MODE_STEPS.DRAW_ROOF_MANUALLY:
            return options?.polygons?.length === 0;
        case SIMPLE_MODE_STEPS.ROOF_IDENTIFICATION:
            if (loadings?.spvSimpleRoofIdentification) return true;
            //TODO: handle errors
            break;
        case SIMPLE_MODE_STEPS.KPIS_DISPLAY:
            if (loadings?.spvSimpleKpisRequests) return true;
            break;
        default:
            return false;
    }
};

export const handleNoAreasWithPanels = (setInfoTagsHandler) => {
    clearTheejsOverlay();
    setInfoTagsHandler([{ message: 'NO_AREAS_WITH_PANELS', type: 'error' }]);
};

export const arrangeOptimizerList = () => {
    const opimizationList = [
        {
            label: 'page.spvPro.label.solarPV',
            value: false,
            withBattery: false,
            options: [],
        },
        {
            label: 'page.spvPro.label.solarPVBattery',
            value: false,
            withBattery: false,
            options: [],
        },
    ];

    return opimizationList;
};

export const optimizerKpisCard = (kpis, withBattery) => {
    let kpisToDispay: {
        label: string;
        value: number;
        numberOfDecimalPlaces: number;
        unit: string;
        skeleton: { width: number; height: number };
    }[] = [];

    switch (kpis.payment_method_id) {
        case PAYMENT_MODELS_IDS.PAY_AS_PRODUCED: {
            kpisToDispay = [
                {
                    label: 'page.reformulateProposal.label.peakPower',
                    value: kpis?.peak_power,
                    numberOfDecimalPlaces: 2,
                    unit: 'kwp',
                    skeleton: {
                        width: 62,
                        height: 21,
                    },
                },
                {
                    label: 'label.estimatedAnnualNetSaving',
                    value: kpis?.net_savings,
                    numberOfDecimalPlaces: 0,
                    unit: 'currency',
                    skeleton: {
                        width: 56,
                        height: 21,
                    },
                },
                {
                    label: 'label.tariff',
                    value: kpis?.tariff,
                    numberOfDecimalPlaces: 2,
                    unit: 'cmwh',
                    skeleton: {
                        width: 70,
                        height: 21,
                    },
                },
                {
                    label: 'label.tir',
                    value: kpis?.irr,
                    numberOfDecimalPlaces: 0,
                    unit: 'percent',
                    skeleton: {
                        width: 21,
                        height: 21,
                    },
                },
            ];
            break;
        }
        case PAYMENT_MODELS_IDS.UP_FRONT: {
            kpisToDispay = [
                {
                    label: 'page.reformulateProposal.label.peakPower',
                    value: kpis?.peak_power,
                    numberOfDecimalPlaces: 2,
                    unit: 'kwp',
                    skeleton: {
                        width: 62,
                        height: 21,
                    },
                },
                {
                    label: 'page.spvSimple.simulation.card.savings.title',
                    value: kpis?.savings,
                    numberOfDecimalPlaces: 0,
                    unit: 'currency',
                    skeleton: {
                        width: 56,
                        height: 21,
                    },
                },
                {
                    label: 'label.investment',
                    value: kpis?.investment,
                    numberOfDecimalPlaces: 0,
                    unit: 'currency',
                    skeleton: {
                        width: 56,
                        height: 21,
                    },
                },
                {
                    label: 'label.payback',
                    value: kpis?.payback,
                    numberOfDecimalPlaces: 1,
                    unit: 'year',
                    skeleton: {
                        width: 62,
                        height: 21,
                    },
                },
            ];
            break;
        }
    }

    if (withBattery) {
        kpisToDispay.push({
            label: 'label.batteryCapacity',
            value: kpis?.solution?.kpis?.battery_capacity_kwh,
            numberOfDecimalPlaces: 0,
            unit: 'kwp',
            skeleton: {
                width: 21,
                height: 21,
            },
        });
    }

    return kpisToDispay;
};

export const findMountingStructureId = (roofTypeId, roofTypeStructures) => {
    const found = roofTypeStructures.find((item) => item.roof_type_id === roofTypeId);
    return found ? found.mounting_structure_id : null;
};

export const solarPvLimitPower = (
    companyProfileId: number,
    facilityInfo: { potencia_contratada: number; potencia_requisitada: number; nivel_tensao_descricao: string }
) => {
    let limitPower = facilityInfo.potencia_contratada;
    let limitPowerType = 'CONTRACTED';

    if ([getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
        const isBTN = facilityInfo.nivel_tensao_descricao === 'BTN';
        limitPower = isBTN ? facilityInfo.potencia_contratada : facilityInfo.potencia_requisitada;
        limitPowerType = isBTN ? 'CONTRACTED' : 'REQUIRED';
    }

    return { value: limitPower, tag: limitPowerType };
};

export const hideToolbarOptionsNonMode = (toolbarValue: SolarPvToolbarOptions, setToolbarValue) => {
    if ([SolarPvToolbarOptions.ZOOM, SolarPvToolbarOptions.VISIBILITY].includes(toolbarValue)) setToolbarValue(null);
};

export const optimizerSavingsType = (bm: number) => {
    switch (bm) {
        case PAYMENT_MODELS_IDS.PAY_AS_PRODUCED:
            return 'net_savings';
        case PAYMENT_MODELS_IDS.UP_FRONT:
            return 'savings';
        default:
            return 'savings';
    }
};
export function getRoofDatabyPaths(paths: google.maps.LatLng[]): {
    centroid: google.maps.LatLngLiteral;
    perimeter_area: number;
    area: number;
} {
    //centroid
    const bounds = new window.google.maps.LatLngBounds();
    paths?.forEach((path) => bounds.extend(new window.google.maps.LatLng(path.lat(), path.lng())));

    return {
        centroid: bounds.getCenter().toJSON(),
        perimeter_area: google.maps.geometry.spherical.computeLength(paths),
        area: google.maps.geometry.spherical.computeArea(paths),
    };
}

export const OPTS_CHOSE_BATTERY = {
    AUTOMATICALLY: 1,
    MANUALLY: 2,
};

export function getServerSpvTag(tag: string) {
    return SERVER_ERROR_TAGS?.find((el) => el === tag) ?? '500';
}

export const deleteGroup = ({
    productSelection,
    spvEventDispatchHandler,
    buildingGroups,
    polygonGroups,
    exclusionGroups,
    loadings,
    modes,
}) => {
    const polygonSelected =
        productSelection === SYSTEM_SETTINGS_GROUPS.EXCLUSIONS ? exclusionGroups?.find((p) => p.selected)?.id
        : productSelection === SYSTEM_SETTINGS_GROUPS.BUILDINGS ? buildingGroups?.find((p) => p.selected)?.id
        : polygonGroups?.find((p) => p.selected)?.id;

    if (!polygonSelected || loadings.usefulArea === true || !!Object.values(modes).find((m) => m === true)) {
        return;
    }
    switch (productSelection) {
        case SYSTEM_SETTINGS_GROUPS.EXCLUSIONS: {
            spvEventDispatchHandler(SpvActions.SET_DIALOG, { deleteExclusions: true });
            break;
        }
        case SYSTEM_SETTINGS_GROUPS.BUILDINGS: {
            spvEventDispatchHandler(SpvActions.SET_DIALOG, { deleteBuilding: true });

            break;
        }
        default:
            polygonGroups?.length === 1 ?
                spvEventDispatchHandler(SpvActions.SET_DIALOG, { deleteAllPanels: true })
            :   spvEventDispatchHandler(SpvActions.SET_DIALOG, { deletePanels: polygonSelected });
            break;
    }
};

const escapeAction = (vars) => {
    const { modes } = vars;
    if (vars.toolbarValue) vars.setToolbarValue(null);
    if (modes.move) exitModeMove(vars);
    if (modes.stamp) closeStampModeHandler(vars.setIsOverlayOpen, vars.spvEventDispatchHandler, vars.threejsOverlay);
    if (modes.panelEditor) exitModeAddPanels(vars);
    if (modes.alignmment) exitModeAlignment(vars);
    if (modes.orientation) exitModeOrientation(vars);
};

export const shortcutsListeners = (vars, removeEvents = false) => {
    const { abortController } = vars;

    if (abortController.signal.aborted) return;

    const ref = document.getElementById('solarpv-wrapper')!;

    if (!isDefined(ref)) return;
    const keydownWrapper = (e: KeyboardEvent) => keydownListenerShortcutsFunction(e, vars);

    if (!removeEvents) {
        window.addEventListener('keydown', keydownWrapper, { signal: abortController.signal });
        // window.addEventListener('keyup', keyupListenerFunction, { signal: abortController.signal });
    } else {
        abortController.abort();
        // trigger a fake event (these listeners won't be called again)
        window.dispatchEvent(new KeyboardEvent('keydown'));
    }
};

const keydownListenerShortcutsFunction = (e: KeyboardEvent, vars) => {
    switch (e.key) {
        case 'Delete': {
            if (isEnvDevFlag(vars.featureFlags['fe-2417'])) deleteGroup(vars);
            break;
        }
        case 'Escape': {
            if (isEnvDevFlag(vars.featureFlags['fe-2418'])) escapeAction(vars);
            break;
        }
        default:
            break;
    }
};
