import { useContext } from 'react';

// Components
import OfferDetailsServiceV2 from 'components/BusinessModels/ProjectSummary/ProjectSummaryServiceV2/OfferDetails';
import SavingsShareServiceV2 from 'components/BusinessModels/ProjectSummary/ProjectSummaryServiceV2/SavingsShare';
import GrantsServiceV2 from './Grants';
import ContactInfoServiceV2 from './ContactInfo';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TServiceV2 } from 'interfaces/businessModels/serviceV2';

const ProjectSummaryServiceV2 = () => {
    const { bmSelected, negotiationProfile } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TServiceV2>;

    const showsContactInfo = negotiationProfile?.mostra_formulario && bmSelected?.options?.has_form;

    return (
        <>
            <OfferDetailsServiceV2 />
            {/* bmSelected.values.proposalReady && */ bmSelected?.values?.kpis_per_margin?.length > 0 && <SavingsShareServiceV2 />}
            {negotiationProfile?.mostra_subsidios ?
                <GrantsServiceV2 />
            :   <></>}
            {showsContactInfo && <ContactInfoServiceV2 />}
        </>
    );
};

export default ProjectSummaryServiceV2;
