import { css } from 'styled-components';

const device = {
    mobile: '360px',
    tablet: '960px',
    desktop: '1360px',
    fhd: '1920px',
    _4k: '3840px',
};

export const media = {
    mobile: (...args) => css`
        @media (max-width: ${device.mobile}) {
            ${css(...args)};
        }
    `,
    tablet: (...args) => css`
        @media (max-width: ${device.tablet}) {
            ${css(...args)};
        }
    `,
    desktop: (...args) => css`
        @media (max-width: ${device.desktop}) {
            ${css(...args)};
        }
    `,
    fhd: (...args) => css`
        @media (max-width: ${device.fhd}) {
            ${css(...args)};
        }
    `,
    _4k: (...args) => css`
        @media (max-width: ${device._4k}) {
            ${css(...args)};
        }
    `,
};
