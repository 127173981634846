import { memo, useContext } from 'react';

import LanguageItem from './LanguageItem';
import { AVAILABLE_LANGUAGES } from 'constants/settings';
import { isDevelopment, isOpenArea } from 'services/settings';
import { AVAILABLE_USER_LOCALES } from 'constants/user';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { useUserStore } from 'store/user';
import { OAUserContext } from 'contexts/openArea/oaUserContext';

const LanguageSwitcher = ({ handleRequestClose, activeLanguage }) => {
    const { switchLanguage, user } = useUserStore();
    const { userLocale: oaUserLocale } = useContext(OAUserContext);
    let userLocale = user?.locale;
    if (isOpenArea()) userLocale = oaUserLocale;
    const userNativeLanguage = AVAILABLE_LANGUAGES.find((language) => language.appLocale === (userLocale ?? 'en-GB'));
    const englishLanguage = AVAILABLE_LANGUAGES.find((language) => language.appLocale === AVAILABLE_USER_LOCALES.enGB);
    const portugueseLanguage = AVAILABLE_LANGUAGES.find((language) => language.appLocale === AVAILABLE_USER_LOCALES.ptPT);

    return (
        <div className="language-list">
            <ul className="list-unstyled px-0 m-0">
                {/* PORTUGUESE - DEV */}
                {isDevelopment() && !!userLocale && userLocale !== AVAILABLE_USER_LOCALES.ptPT && (
                    <LanguageItem
                        key="language_list_1"
                        language={portugueseLanguage}
                        handleRequestClose={handleRequestClose}
                        switchLanguage={switchLanguage}
                        isActive={portugueseLanguage.icon === activeLanguage}
                    />
                )}
                {/* USER LOCALE */}
                {isFieldDefined(userNativeLanguage) && (
                    <LanguageItem
                        key="language_list_2"
                        language={userNativeLanguage}
                        handleRequestClose={handleRequestClose}
                        switchLanguage={switchLanguage}
                        isActive={userNativeLanguage?.icon === activeLanguage}
                    />
                )}

                {/*ENGLISH  */}
                {![
                    AVAILABLE_USER_LOCALES.enSG,
                    AVAILABLE_USER_LOCALES.enGB,
                    AVAILABLE_USER_LOCALES.enIE,
                    AVAILABLE_USER_LOCALES.enUS,
                ].includes(userNativeLanguage.userLocale) && (
                    <LanguageItem
                        key="language_list_3"
                        language={englishLanguage}
                        handleRequestClose={handleRequestClose}
                        switchLanguage={switchLanguage}
                        isActive={englishLanguage.icon === activeLanguage}
                    />
                )}
            </ul>
        </div>
    );
};

export default memo(LanguageSwitcher);
