import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
//Material UI
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

//Our Components
import LanguageSwitcher from '../../../core/LanguageSwitcher';
import { AVAILABLE_LANGUAGES } from 'constants/settings';
import { gAnalyticsEvt } from 'services/gAnalytics';
import { GA_LABEL_TOP_LEVEL_NAVIGATION, GA_TYPE_EVT } from 'constants/gAnalytics';

const LangSwitcherCard = ({ isOpen, handleToggle, handleSwitchLanguage, langCode, userLocale, uniqueItem }) => {
    const languageCurrent = useMemo(() => AVAILABLE_LANGUAGES.find((language) => language.icon === langCode), [langCode]);

    return (
        <Dropdown
            isOpen={isOpen}
            toggle={handleToggle}
            onMouseDown={() => gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.LANGUAGE_SELECTOR, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION)}
        >
            <DropdownToggle className="header-dropdown-toggle" tag="span" data-toggle="dropdown">
                <div className={`menu-item-content ${uniqueItem ? 'only-one-card' : ''}`}>
                    <span className="language-icon">{languageCurrent?.isoLocale}</span>
                </div>
            </DropdownToggle>

            <DropdownMenu end className="w-auto transition-none">
                <LanguageSwitcher
                    switchLanguage={handleSwitchLanguage}
                    handleRequestClose={handleToggle}
                    activeLanguage={langCode}
                    userLocale={userLocale}
                />
            </DropdownMenu>
        </Dropdown>
    );
};

//defaultProps
LangSwitcherCard.defaultProps = {
    uniqueItem: false,
};

//PropTypes
LangSwitcherCard.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleSwitchLanguage: PropTypes.func.isRequired,
    langCode: PropTypes.string.isRequired,
    userLocale: PropTypes.string.isRequired,
};
export default memo(LangSwitcherCard);
