import { getCompanyProfileIds } from 'services/user';
import { TBMProjectSummary, TBMKpi } from '../../interfaces/businessModels/index';
import { isFieldDefined, parseNumberWithDecimals, parseNumberWithToFixed } from 'services/util/auxiliaryUtils';

export const eSolarProjectSummary = (kpis: TBMProjectSummary[], syncKpis: any): TBMKpi[] => {
    if (isFieldDefined(syncKpis)) return syncESolarProjectSummary(syncKpis);

    return [
        {
            label: 'label.price',
            infoLabel: 'label.tariffDiscount',
            value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].pap_tariff, 1),
            unit: 'cmwp',
            numberOfDecimalPlaces: 1,
            values: {
                tariffDiscount: parseNumberWithToFixed(kpis[0].kpis_per_margin?.[0].pap_tariff_discount * 100, 1).toLocaleString('pt-PT'),
            },
        },
        {
            label: 'label.annualSaving',
            value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].annual_saving, 0),
            unit: 'currency',
            numberOfDecimalPlaces: 0,
        },
    ];
};

const syncESolarProjectSummary = (syncKpis) => {
    const step = `${syncKpis?.negotiation?.step ?? ''}`;
    const numberOfDecimalPlaces = step.split('.')?.[1]?.length ?? 4;
    return [
        {
            label: 'label.monthly',
            value: parseNumberWithDecimals(syncKpis?.negotiation.default, numberOfDecimalPlaces),
            unit: 'mc',
            numberOfDecimalPlaces,
        },
        {
            label: 'label.annualSaving',
            value: parseNumberWithDecimals(syncKpis.kpis.annual_saving, 0),
            unit: 'currency',
        },
    ];
};

export const eSolarKpis = (kpis, monthlyPayment, defaultTariff, companyProfileId) => {
    const discount = 1 - monthlyPayment / defaultTariff;
    const annualEstimatedPrice = monthlyPayment * 12; /* 12 meses */
    const annualNetSaving = kpis?.annual_saving - annualEstimatedPrice;

    let _kpis = [
        {
            label: 'label.comercialDiscount',
            value: typeof monthlyPayment === 'string' ? '-' : discount,
            numberOfDecimals: 2,
            unit: 'percent',
        },
        {
            label: 'label.annualEstimatedPrice',
            value: typeof monthlyPayment === 'string' ? '-' : annualEstimatedPrice,
            numberOfDecimals: 0,
            unit: 'currency',
        },
        {
            label: 'label.annualNetSavings',
            value: typeof monthlyPayment === 'string' ? '-' : annualNetSaving,
            numberOfDecimals: 0,
            unit: 'currency',
        },
    ];

    if ([getCompanyProfileIds().EDP_IT].includes(companyProfileId)) {
        _kpis = _kpis.filter((kpi) => !['label.comercialDiscount'].includes(kpi?.label));
    }

    return _kpis;
};

export const detailedESolarKpis = (negotiationProfile, finalKpis) => {
    return [
        {
            label: 'page.businessModels.tirWacc',
            value: finalKpis?.irr_wacc ?? 'N/A',
            numberOfDecimals: 2,
            visible: negotiationProfile?.slider_tir_wacc,
        },
        {
            label: 'page.businessModels.fixedComission',
            value: finalKpis?.fixed_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_fixa,
            unit: 'currency',
        },
        {
            label: 'page.businessModels.variableComission',
            value: finalKpis?.variable_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_variavel,
            unit: 'currency',
        },
        {
            label: 'page.businessModels.totalComission',
            value: finalKpis?.total_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_total,
            unit: 'currency',
        },
        {
            label: 'label.additionalValue',
            value: finalKpis?.additional_revenue ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.mostra_slider_valor_adicional,
            unit: 'currency',
        },
    ];
};

export const annualEvoFeeOptions = () => {
    const DEFAULT_FEE = 0;
    const step = 0.5;
    const maxFee = 2; //max 2.5 (2+0.5)
    let currentFee = 0;
    const options = [];

    while (currentFee <= maxFee + step) {
        // @ts-ignore
        options.push({
            key: `annual_evo_fee_${currentFee}`,
            default: currentFee === DEFAULT_FEE ? true : false,
            value: currentFee / 100,
        });
        currentFee = currentFee + step;
    }

    return options;
};
