import { ISPVCustomize } from 'interfaces/products/solarpv/customize';
import { createContext, useReducer } from 'react';
import { CustomizeLoadingssReducer, initialCustomizeLoadings } from './customizeLoadingsReducer';
import { customizeReducer, initialCustomizeReducer } from './customizeReducer';

const CustomizeContext = createContext<ISPVCustomize>({
    customizeState: initialCustomizeReducer,
    customizeDispatchHandler: () => {},
    customizeLoadings: initialCustomizeLoadings,
    customizeLoadingsDispatchHandler: () => {},
});

const CustomizeProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [customizeState, customizeDispatch] = useReducer(customizeReducer, initialCustomizeReducer);
    const [customizeLoadings, customizeLoadingsDispatch] = useReducer(CustomizeLoadingssReducer, initialCustomizeLoadings);

    const customizeDispatchHandler = (type, payload) => {
        customizeDispatch({
            type: type,
            payload: payload,
        });
    };

    const customizeLoadingsDispatchHandler = (type, payload) => {
        customizeLoadingsDispatch({
            type: type,
            payload: payload,
        });
    };

    const customizeValues: ISPVCustomize = {
        customizeState,
        customizeDispatchHandler,
        customizeLoadings,
        customizeLoadingsDispatchHandler,
    };
    return <CustomizeContext.Provider value={customizeValues}>{children}</CustomizeContext.Provider>;
};

export { CustomizeContext, CustomizeProvider };
