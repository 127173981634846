import { BACKOFFICE_CONFIGS, BACKOFFICE_SECTIONS } from 'constants/backoffice';
import { array, func, object, string } from 'prop-types';
import { createContext, useContext, useMemo, useReducer } from 'react';
import { UserContext } from '../userContext';
import { boReducer, initialBOReducer } from './backofficeReducer';
import { BOActions } from 'interfaces/backoffice';

const BackofficeContext = createContext({
    activeBackofficeProducts: array,
    solarConfigs: {
        sections: [
            {
                title: string,
                name: string,
                label: string,
                navigationLabel: string,
                visible: func,
            },
        ],
    },
    hpConfigs: object,
    boState: initialBOReducer,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    boDispatchHandler: (type: BOActions, payload: any) => {},
});

const BackofficeProvider = ({ children }) => {
    // Contexts
    const { configBackoffice, companyProfileId } = useContext(UserContext);

    const activeBackofficeProducts = useMemo(
        () =>
            BACKOFFICE_CONFIGS?.DEFAULT.filter(
                (product) => product.visible(configBackoffice?.[product.title]?.show_backoffice) && !product.disabled()
            ),
        [companyProfileId, configBackoffice] // eslint-disable-line
    );

    const solarConfigs = useMemo(
        () => activeBackofficeProducts.find((product) => product.name === BACKOFFICE_SECTIONS.SOLAR)!,
        [activeBackofficeProducts]
    );
    const hpConfigs = useMemo(
        () => activeBackofficeProducts.find((product) => product.name === BACKOFFICE_SECTIONS.HEATPUMPS)!,
        [activeBackofficeProducts]
    );

    const [boState, boDispatch] = useReducer(boReducer, initialBOReducer);
    const boDispatchHandler = (type: keyof typeof BOActions, payload: any) => {
        boDispatch({
            type: type,
            payload: payload,
        });
    };

    return (
        <BackofficeContext.Provider
            value={{
                activeBackofficeProducts,
                solarConfigs,
                hpConfigs,
                boState,
                boDispatchHandler,
            }}
        >
            {children}
        </BackofficeContext.Provider>
    );
};

export { BackofficeContext, BackofficeProvider };
