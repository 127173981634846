/**
 * @author Miguel Cervera <miguel.cervera@caixamagica.pt>,
 *
 * @description redux Reducers - Product
 *
 * @version 20191122
 * @since 20191122 Initial release
 *
 */

import clone from 'fast-copy';
import {
    INITIAL_PRODUCT,
    SET_MODULES_AND_SUB_PRODUCTS,
    RESET_PRODUCT,
    SET_PRODUCT_LOADING,
    SET_PRODUCT_INFO,
    SET_PRODUCT_MODULES,
    SET_PRODUCT_INPUTS,
    RESET_PRODUCT_BUILD,
    REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA,
    REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_SUCCESS,
    REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_FAIL,
    RESET_PRODUCT_OPTIONS,
    SET_INCREASE_POWER_OPTIONS,
    RESET_PRODUCT_INPUTS,
    SET_PRODUCT_BY_TOKEN,
    SET_IS_SPV_PRO,
} from 'constants/product';
import { REQUEST_GET_PRODUCT_INPUTS, REQUEST_GET_PRODUCT_INPUTS_SUCCESS, REQUEST_GET_PRODUCT_INPUTS_FAIL } from 'constants/product';
import { PRODUCT_IDS, SUB_PRODUCTS, TYPE_MODULES } from 'constants/products';
import { STATE_IDS } from 'constants/user';
import { showStateCurrent } from 'services/user';
import { AVAILABLE_FACILITY_TYPE } from 'constants/products/ce';

import { isDefined, getStatsSetor, isFieldDefined } from 'services/util/auxiliaryUtils';
import {
    REQUEST_POST_EVC_PRE_SIZING,
    REQUEST_POST_EVC_PRE_SIZING_SUCCESS,
    REQUEST_POST_EVC_PRE_SIZING_FAIL,
    REQUEST_POST_EVC_SIZING_FINANCE,
    REQUEST_POST_EVC_SIZING_FINANCE_SUCCESS,
    REQUEST_POST_EVC_SIZING_FINANCE_FAIL,
    RESET_EVC_CHARGERS_INFO,
} from 'constants/products/evc';

/**
 * resetOptions
 */
const resetOptions = () => ({
    spv: {
        // TODO: falta fazer uma actions para limpar este obj apos a criação da proposta.
        stepMount: 0,
        consumptions: null,
        pvAreaReductionCoefficients: null,
        solarSensibilityAnalysis: null,
        regionsGD: null,
        hasProductInputs: false,
        productions: null,
        regionRadiations: null,
        simulation: null,
        loadingSPVData: false,
        isRequestingSPVConsumption: false,
        isRequestingSPVSensibility: false,
        isRequestingSPVProduction: false,
        responseTags: [],
        ranges: [],
        // isPowerIncrease:false,
    },
    cfp: {
        base: null,
        batteryPowers: null,
    },
    ce: {
        facilityStats: null,
        facilityTypes: null,
        facilityGroups: null,
    },
    me: {
        isMounting: true,
    },
    gns: {
        isMounting: true,
    },
    utils: {
        workSchedules: null,
    },
    isRequesting: false,
});

const initialState = clone(INITIAL_PRODUCT);
const reducersProduct = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCT_BY_TOKEN:
            return {
                ...state,
                info: {
                    ...state.info,
                    ...action.payload,
                    module: 'simple',
                },
                modules: [
                    {
                        order: 1,
                        name: 'simple',
                        title: 'label.simplified',
                        subtitle: 'label.module',
                        description: 'page.modules.simplified.description',
                        background: require('assets/images/modules/modulo-rapido-select.jpg'),
                        isRecommended: true,
                    },
                ],
            };
        case REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA:
            return {
                ...state,
                isRequesting: true,
            };
        case REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_SUCCESS: {
            // If it is getting productInputs and not edit proposal
            let productInputsData = action.payload.hasProductInputs ? clone(action.payload.productInputs) : null;

            //#region CE
            let facilityTypes = null;
            if (state.info.id === PRODUCT_IDS.CE) {
                // CE - facilityGroups
                if (action.payload?.facilityGroups) {
                    action.payload.facilityGroups = action.payload.facilityGroups.map((item, index) => {
                        return {
                            annual_consumption: item.consumo_anual / 1000,
                            id: index,
                            cpe: item.cpe,
                            show_delete: index !== 0,
                            show_info: true,
                        };
                    });
                }
                // CE - facilityStats
                if (action.payload?.facilityStats) {
                    action.payload.facilityStats = getStatsSetor(
                        'consumption_in_sector_and_contracted_power_range',
                        action.payload?.facilityAnnualConsumption,
                        action.payload?.facilityStats
                    );
                }

                // CE - facilityTypes
                if (action.payload?.facilityTypes) {
                    facilityTypes = action.payload?.facilityTypes.filter((type) => AVAILABLE_FACILITY_TYPE.includes(parseInt(type.id)));
                }
            }
            //#endregion CE

            //#region MPT, TRE, INTEGRA
            if ([PRODUCT_IDS.MPT, PRODUCT_IDS.TRE, PRODUCT_IDS.INTEGRA].includes(state.info.id)) {
                action.payload.workSchedules.map((item) => {
                    item.tag = `WORK_SCHEDULE_ID_${item.id}`;
                    return item;
                });
            }
            //#endregion MPT, TRE, INTEGRA

            //#region EVC
            if ([PRODUCT_IDS.EVC, PRODUCT_IDS.EVCSB].includes(state.info.id)) {
                let cleanChargers = !!productInputsData?.SIMPLES_Dimensionamento?.inputs?.chargers_selected?.find(
                    (item) => !item?.metadata
                );

                // If any charger comes without metadata, return list with 0 chargers
                if (cleanChargers) {
                    productInputsData.SIMPLES_Dimensionamento.inputs.chargers_selected = [];
                }

                if (!isFieldDefined(productInputsData?.SIMPLES_Dimensionamento?.inputs)) {
                    productInputsData = {
                        SIMPLES_Dimensionamento: {
                            inputs: null,
                        },
                    };
                }
            }
            //#endregion

            return {
                ...state,
                options: {
                    ...state.options,
                    cfp: {
                        ...state.options.cfp,
                        batteryPowers: action.payload?.batteryPowers ?? null,
                        base: action.payload?.base ?? null,
                    },
                    ce: {
                        ...state.options.ce,
                        facilityStats: action.payload?.facilityStats ?? null,
                        facilityTypes: facilityTypes ?? null,
                        facilityGroups: action.payload?.facilityGroups ?? null,
                    },
                    me: {
                        ...state.options.me,
                        isMounting: ![PRODUCT_IDS.EVC, PRODUCT_IDS.EVCSB].includes(state.info.id) ?? true,
                        isRequesting: false,
                    },
                    gns: {
                        ...state.options.gns,
                        isMounting: !(state.info.id === PRODUCT_IDS.GNS) ?? true,
                    },
                    utils: {
                        ...state.options.utils,
                        workSchedules: action.payload?.workSchedules ?? null,
                    },
                },
                productInputs: productInputsData,
                hasProductInputs: action.payload.hasProductInputs,
                isRequesting: false,
            };
        }
        case REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA_FAIL:
            return {
                ...state,
                isRequesting: true,
            };
        case SET_IS_SPV_PRO:
            return {
                ...state,
                isSPVPro: action.payload,
            };

        case SET_PRODUCT_INPUTS: {
            let isEditProposal = isDefined(action.payload.proposal);

            return {
                ...state,
                inputs: isEditProposal ? null : action.payload.inputs,
                proposal:
                    isEditProposal ?
                        {
                            ...action.payload?.proposal,
                            inputs: action.payload.inputs,
                        }
                    :   null,
            };
        }
        case SET_PRODUCT_MODULES:
            if (TYPE_MODULES.SUB_PRODUCTS === action.payload.type) {
                return {
                    ...state,
                    info: {
                        ...state.info,
                        module: null,
                        subProduct: action.payload.name,
                        sub_product_id:
                            action.payload?.name === SUB_PRODUCTS.INSTALLATION.LABEL ?
                                SUB_PRODUCTS.INSTALLATION.ID
                            :   SUB_PRODUCTS.OPERATION.ID,
                    },
                };
            }
            return {
                ...state,
                info: {
                    ...state.info,
                    module: action.payload.name,
                    subProduct:
                        !!state?.info?.id && [PRODUCT_IDS.EVC, PRODUCT_IDS.EVCSB].includes(state.info.id) ? state.info.subProduct : null,
                },
            };
        case SET_PRODUCT_INFO:
            action.payload.id = parseInt(action.payload.id);
            return {
                ...state,
                info: {
                    ...state?.info,
                    ...action?.payload,
                },
            };
        case REQUEST_GET_PRODUCT_INPUTS:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_GET_PRODUCT_INPUTS_SUCCESS: {
            let _productInputsData = clone(action.payload.data);
            let _hasProductInputs = false;

            return {
                ...state,
                productInputs: _productInputsData,
                hasProductInputs: _hasProductInputs,
                loading: false,
            };
        }
        case REQUEST_GET_PRODUCT_INPUTS_FAIL:
            return {
                ...state,
                productInputs: null,
                hasProductInputs: false,
                loading: false,
            };

        case SET_MODULES_AND_SUB_PRODUCTS:
            return {
                ...state,
                modules: action.payload.modules,
                subProducts: action.payload.subProducts,
            };

        case SET_PRODUCT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case RESET_PRODUCT_BUILD:
            return {
                ...state,
                questions: null,
                inputs: null,
            };
        case SET_INCREASE_POWER_OPTIONS:
            return {
                ...state,
                options: {
                    ...state.options,
                    spv: {
                        ...state.options.spv,
                        isPowerIncrease: action.payload.is_power_increase,
                        oldProposalID: action.payload.proposal_id,
                        previousPeakPower: action.payload.previous_peak_power,
                        previousInverterPowerKw: action.payload.previous_inverter_power_kw,
                    },
                },
            };
        case RESET_PRODUCT:
            return {
                ...clone(INITIAL_PRODUCT),
            };
        case RESET_PRODUCT_OPTIONS:
            if (state?.options) {
                return {
                    ...state,
                    options: {
                        ...resetOptions(),
                        spv: {
                            ...resetOptions().spv,
                        },
                    },
                };
            }
            return {
                ...state,
            };

        // * START EVC * \\ -------------------------------
        /* STEP 1 */
        case REQUEST_POST_EVC_PRE_SIZING:
            return {
                ...state,
                options: {
                    ...state?.options,
                    me: {
                        ...state?.options?.me,
                        isRequesting: true,
                    },
                },
            };

        case REQUEST_POST_EVC_PRE_SIZING_SUCCESS:
            return {
                ...state,
                options: {
                    ...state?.options,
                    me: {
                        ...state?.options?.me,
                        isRequesting: false,
                        results: action?.payload?.results,
                        chargers: action?.payload?.setup,
                    },
                },
            };

        case REQUEST_POST_EVC_PRE_SIZING_FAIL:
            return {
                ...state,
                options: {
                    ...state?.options,
                    me: {
                        ...state?.options?.me,
                        isRequesting: false,
                    },
                },
            };

        /* STEP 2 */
        case REQUEST_POST_EVC_SIZING_FINANCE:
            return {
                ...state,
                options: {
                    ...state?.options,
                    me: {
                        ...state?.options?.me,
                        isRequesting: true,
                        isPowerLimit: {
                            warning: null,
                            message: null,
                            warningValues: null,
                        },
                    },
                },
            };

        case REQUEST_POST_EVC_SIZING_FINANCE_SUCCESS:
            return {
                ...state,
                options: {
                    ...state?.options,
                    me: {
                        ...state?.options?.me,
                        isRequesting: false,
                        chargers_selected: [...action.payload.chargers_selected],
                        isPowerLimit: {
                            warning: action?.payload?.warningPowerLimit,
                            message: action?.payload?.message,
                            warningValues: action?.payload?.warningValues,
                        },
                        totals: action?.payload?.totals,
                    },
                },
            };

        case REQUEST_POST_EVC_SIZING_FINANCE_FAIL:
            return {
                ...state,
                options: {
                    ...state?.options,
                    me: {
                        ...state?.options?.me,
                        isRequesting: false,
                    },
                },
            };

        /* REMOVE CHARGERS INFO */
        case RESET_EVC_CHARGERS_INFO:
            return {
                ...state,
                options: {
                    ...state?.options,
                    me: {
                        ...state?.options?.me,
                        isRequesting: false,
                        results: null,
                        chargers: null,
                    },
                },
            };
        case 'RESET_EDIT_PROPOSAL':
            return {
                ...state,
                proposal: null,
            };
        // * END EVC * \\ -------------------------------------

        case RESET_PRODUCT_INPUTS:
            return {
                ...state,
                productInputs: null,
                hasProductInputs: false,
                loading: true,
            };
        default:
            return (
                showStateCurrent(STATE_IDS.PLATFORM) ?
                    !state ? clone(INITIAL_PRODUCT)
                    :   state
                :   null
            );
    }
};

export default reducersProduct;
