import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';
import { BmDetailedActions } from 'constants/businessModelsPro/index';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { useContext } from 'react';
import { detailedESolarKpis } from 'services/businessModels/eSolar';
import { isDefined } from 'services/util/auxiliaryUtils';
import { TIntl } from 'types/utils';
import { Button } from '@save2compete/efz-design-system';

const DetailedKpisESolarDetailed = () => {
    const { bmState, negotiationProfile, setBMEventHandler } = useContext(OfferEditionContext);

    const kpisToShow = detailedESolarKpis(negotiationProfile, bmState?.kpis?.finalKpis)?.filter((kpi) => kpi.visible);
    return (
        <div className="bm-detailed-negotiation-kpis">
            <div className="action-container">
                <Button
                    variant="secondary"
                    onClick={() => setBMEventHandler(DetailedOfferEditionEvents.SET_DETAILED_ACTION, BmDetailedActions.DETAILED_SIMULATION)}
                    disabled={bmState.loadings.general || isDefined(bmState?.kpis?.finalKpis)}
                    loading={bmState.loadings.detailedSimulation && !bmState.loadings.getProposalNoKpis}
                    dataTestId="update-detailed-kpis"
                >
                    <IntlMessages id="page.businessModels.updateDetailedKpis" />
                </Button>
            </div>
            {isDefined(bmState.kpis?.finalKpis) && (
                <div className="kpis-table">
                    {kpisToShow.map((kpi, idx) => {
                        return (
                            <>
                                <div className="kpi">
                                    <div className="label">
                                        <IntlMessages id={kpi.label as TIntl} />
                                    </div>
                                    <div className="value">
                                        {/* @ts-ignore */}
                                        <FormatNumber
                                            number={kpi?.value}
                                            numberOfDecimalPlaces={kpi?.numberOfDecimals}
                                            unit={kpi.unit ?? null}
                                            styleFormat={kpi?.styleFormat ?? null}
                                        />
                                    </div>
                                </div>
                                {idx !== kpisToShow.length - 1 && <div className="divider" />}
                            </>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default DetailedKpisESolarDetailed;
