import axios from 'axios';
import { getMessageNetworkError } from 'services/statusCodeHttp';
import { isDefined } from 'services/util/auxiliaryUtils';
import { getEndpoint, CONFIG_HEADERS, URL_FACILITIES, URL_CONSUMPTIONS, URL_CLIENT } from 'constants/endpoints';

const CancelToken = axios.CancelToken;
// Cancel executor variables
let getFSCancelExecutor;
let getFCMCancelExecutor;
let getFCWCancelExecutor;
let getFEWCancelExecutor;

//Get Facility Stats
export const getFacilityStats = (payload) => {
    getFSCancelExecutor && getFSCancelExecutor();
    //#region sentry warn
    if (!isDefined(payload.facilityId)) return;
    //#endregion

    return axios
        .get(getEndpoint(URL_FACILITIES) + '/' + payload.facilityId + '/stats', {
            cancelToken: new CancelToken(function executor(c) {
                getFSCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

let getFacilityAbortController;
export const getFacilityInfo = (payload, abortController = getFacilityAbortController) => {
    //#region sentry warn
    if (!isDefined(payload.facilityID)) return;
    //#endregion
    abortController && abortController.abort();
    abortController = new AbortController();

    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES) + '/' + payload.facilityID}`,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getFacilityConsumptionMonthly = (payload) => {
    getFCMCancelExecutor && getFCMCancelExecutor();
    //#region sentry warn
    if (!isDefined(payload.facilityID)) return;
    //#endregion

    return axios
        .get(getEndpoint(URL_FACILITIES) + '/' + payload.facilityID + '/consumptions?agg_type=monthly', {
            cancelToken: new CancelToken(function executor(c) {
                getFCMCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getFacilityConsumptionWeights = (payload) => {
    getFCWCancelExecutor && getFCWCancelExecutor();
    return axios
        .get(getEndpoint(URL_FACILITIES) + '/' + payload.facilityID + '/consumption-weights', {
            cancelToken: new CancelToken(function executor(c) {
                getFCWCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getEstimateWeights = (payload) => {
    getFEWCancelExecutor && getFEWCancelExecutor();
    return axios
        .get(getEndpoint(URL_CONSUMPTIONS) + '/weights?activity_code=' + payload.clientCAE5, {
            cancelToken: new CancelToken(function executor(c) {
                getFEWCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getEstimateWeightsByActivityCode = (activity_code, abortController) => {
    if (abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    return axios
        .request({
            method: 'GET',
            url: `${getEndpoint(URL_CONSUMPTIONS)}/weights?activity_code=${activity_code}`,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getEstimateWeightsByCustom = (facilityID, abortController) => {
    if (abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    return axios
        .request({
            method: 'GET',
            url: `${getEndpoint(URL_FACILITIES)}/${facilityID}/consumption-weights`,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const putEstimateWeightsByCustom = (facilityID, body, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }
    return axios
        .request({
            method: 'PUT',
            url: `${getEndpoint(URL_FACILITIES)}/${facilityID}/consumption-weights`,
            data: body,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

let putFacilityAbortController;
export const putFacilityInfo = (payload, abortController = putFacilityAbortController) => {
    if (!isDefined(payload.id)) return;

    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }
    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/${payload.id}`,
            method: 'PUT',
            data: payload.body,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getFacilityTypes = (abort) => {
    abort && abort();
    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/types`,
            method: 'GET',
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const putFacilityWeights = (payload) => {
    //#region sentry warn
    if (!isDefined(payload.facilityID)) return;
    //#endregion

    return axios
        .put(getEndpoint(URL_FACILITIES) + '/' + payload.facilityID + '/consumption-weights', payload.body)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postUploadLoadCurve = (payload, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }
    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/${payload.facilityID}/consumption-array-file`,
            method: 'POST',
            headers: {
                ...CONFIG_HEADERS,
                'Content-Type': 'multipart/form-data',
            },
            data: payload.load_curve_file,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getDownloadLoadCurve = (payload, abortController) => {
    if (!abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES)}/${payload.facilityID}/consumption-array-file`,
            responseType: 'arraybuffer',
            method: 'GET',
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getTariffPeriods = (facilityId) => {
    return axios
        .get(`${getEndpoint(URL_FACILITIES)}/${facilityId}/tariff-periods`)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

let postAddFacilityAbortController;
export const postAddFacility = (payload, abortController = postAddFacilityAbortController) => {
    const { formData, clientId } = payload;
    abortController && abortController.abort();
    abortController = new AbortController();
    return axios
        .request({
            url: `${getEndpoint(URL_CLIENT)}/${clientId}/facilities`,
            method: 'POST',
            data: formData,
            signal: abortController?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
