import { all, call, put, takeLatest } from 'redux-saga/effects';
//actions
import { resetSizing } from 'redux/actions/sizing';
import { resetProduct } from 'redux/actions/product';
import {
    requestGetProductWorkScheduleSuccess,
    requestGetRisksFail,
    requestGetRisksSuccess,
    requestGetSegmentsFail,
    requestGetSegmentsSuccess,
} from 'redux/actions/utils';
import { resetBusinessModels } from 'redux/actions/businessModels';
import { resetProposal } from 'redux/actions/proposal';
import { requestGetFacilityGroupsSuccess, requestGetCountiesWithSearchSuccess, requestGetCountiesFail } from 'redux/actions/utils';
//constants
import {
    REQUEST_GET_COUNTIES_WITH_SEARCH,
    RESET_ALL_PRODUCT_STATES,
    REQUEST_GET_WORK_SCHEDULE,
    REQUEST_GET_FACILITY_GROUPS,
    REQUEST_GET_SEGMENTS,
    REQUEST_GET_RISKS,
} from 'constants/utils';
import { OK, UNAUTHORIZED } from 'constants/statusCodeHttp';
//api
import { getCounties, getProductWorkSchedules, getFacilityGroups, getSegments, getEnergyRisks } from 'api/utils';
import { orderByLocale } from 'services/dropdown';
import clone from 'fast-copy';

/**
 *  _callRequestGetCounties request an API
 *
 * @returns {Promise<AxiosResponse<T>>}
 * @private
 */
const _callRequestGetCounties = async (payload) =>
    await getCounties(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callRequestGetCounties ', error);
            return error;
        });

/**
 * _handlerGetCounties
 *
 * @returns {IterableIterator<PutEffect<{payload: *, type: *}>|CallEffect>}
 */
function* _handlerRequestGetCounties({ payload }) {
    try {
        const fetchResult = yield call(_callRequestGetCounties, payload);

        if (fetchResult?.status === OK) {
            yield put(requestGetCountiesWithSearchSuccess({ data: fetchResult.data }));
        }
        if (![OK, UNAUTHORIZED].includes(fetchResult?.status)) {
            yield put(requestGetCountiesFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerGetCounties ', error);
    }
}

function* _handlerResetAllProductStates() {
    try {
        yield put(resetProduct());
        yield put(resetSizing());
        yield put(resetBusinessModels());
        yield put(resetProposal());
    } catch (error) {
        console.log('[SAGA] catch error _handlerResetProductAllState ', error);
    }
}

/**
 * _callGetProductWorkSchedule  request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetProductWorkSchedule = async (payload) => {
    return await getProductWorkSchedules(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetProductWorkSchedule ', error);
            return error;
        });
};

/**
 *
 * @param payload
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>>}
 * @private
 */
function* _handlerGetProductWorkSchedule({ payload }) {
    try {
        const fetchResult = yield call(_callGetProductWorkSchedule, payload);

        if (fetchResult?.status === OK) yield put(requestGetProductWorkScheduleSuccess({ data: fetchResult.data }));
    } catch (error) {
        console.log('[SAGA] catch error _handlerGetProductWorkSchedule ', error);
    }
}

/**
 * _callGetFacilityGroups - request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetFacilityGroups = async (payload) => {
    return await getFacilityGroups(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetFacilityGroups ', error);
            return error;
        });
};

/**
 *
 * @param payload
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>>}
 * @private
 */
function* _handlerGetFacilityGroups({ payload }) {
    try {
        const fetchResult = yield call(_callGetFacilityGroups, payload);

        if (fetchResult?.status === OK) yield put(requestGetFacilityGroupsSuccess({ data: fetchResult.data }));
    } catch (error) {
        console.log('[SAGA] catch error _handlerGetFacilityGroups ', error);
    }
}

/**
 * _callGetSegments  request an API
 *
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 * @private
 */
const _callGetSegments = async (payload) =>
    await getSegments(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetSegments ', error);
            return error;
        });

/**
 * _handlerRequestGetSegments
 *
 * @param payload
 * @returns {IterableIterator<PutEffect<{payload: *, type: *}>|CallEffect>}
 */
function* _handlerRequestGetSegments() {
    try {
        const response = yield call(_callGetSegments);

        if (response?.status === OK) yield put(requestGetSegmentsSuccess(response.data));
        else yield put(requestGetSegmentsFail());
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestGetSegments ', error);
        return error;
    }
}

/**
 * _callGetRisks  request the API
 *
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 * @private
 */
const _callGetRisks = async (payload) =>
    await getEnergyRisks(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetRisks ', error);
            return error;
        });

/**
 * _handlerRequestGetRisks
 *
 * @param payload
 * @returns {IterableIterator<PutEffect<{payload: *, type: *}>|CallEffect>}
 */
function* _handlerRequestGetRisks({ payload: userLocale }) {
    try {
        const response = yield call(_callGetRisks);

        if (response?.status === OK) yield put(requestGetRisksSuccess(orderByLocale(clone(response.data), 'risk', userLocale)));
        else yield put(requestGetRisksFail());
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestGetRisks ', error);
        return error;
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(REQUEST_GET_FACILITY_GROUPS, _handlerGetFacilityGroups),
        takeLatest(REQUEST_GET_WORK_SCHEDULE, _handlerGetProductWorkSchedule),
        takeLatest(REQUEST_GET_COUNTIES_WITH_SEARCH, _handlerRequestGetCounties),
        takeLatest(RESET_ALL_PRODUCT_STATES, _handlerResetAllProductStates),
        takeLatest(REQUEST_GET_SEGMENTS, _handlerRequestGetSegments),
        takeLatest(REQUEST_GET_RISKS, _handlerRequestGetRisks),
    ]);
}
