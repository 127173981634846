import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPPa } from 'interfaces/businessModels/ppa';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';
import ProjectSummaryPPaDetailed from './detailed';
import ProjectSummaryPPaSimple from './simple';

const ProjectSummaryPPa = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;

    if (isDefined(bmSelected.values?.syncKpis)) return <ProjectSummaryPPaDetailed />;
    return <ProjectSummaryPPaSimple />;
};

export default ProjectSummaryPPa;
