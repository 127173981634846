import { TBMKpi } from 'interfaces/businessModels';
import IntlMessages from 'components/util/IntlMessages';
import FormatNumber from 'components/util/FormatNumber';
import { TIntl } from 'types/utils';

const KPI = ({ label, value, unit, numberOfDecimalPlaces, infoLabel, values }: TBMKpi) => {
    return (
        <div className="business-models-card-section">
            <label className="m-0">
                <IntlMessages id={label as TIntl} />
            </label>
            <span className="return">
                {/* @ts-ignore */}
                <FormatNumber unit={unit} number={value} numberOfDecimalPlaces={numberOfDecimalPlaces} />
                {infoLabel && (
                    <span className="info-label">
                        <IntlMessages id={infoLabel as TIntl} values={values} />
                    </span>
                )}
            </span>
        </div>
    );
};

export default KPI;
