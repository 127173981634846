import { memo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IntlMessages from 'components/util/IntlMessages';
import { IconButton } from '@save2compete/efz-design-system';

const HousingTypeDialog = ({ isOpen, setIsOpen }) => {
    const handleClose = (_, reason) => {
        if (['backdropClick', 'escapeKeyDown'].includes(reason)) return false;

        if (typeof onCloseHandler === 'function') setIsOpen();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} className="housing-type-dialog">
            <div className="dialog-title">
                <DialogTitle className="flex-fill">
                    <IntlMessages id="page.spvSimple.housingTypes.b2c.dialog.title" />
                </DialogTitle>
                <IconButton
                    variant="tertiary"
                    icon="xMarkExit"
                    size="bg"
                    className="close-button"
                    onClick={setIsOpen}
                    dataTestId="close-dialog"
                />
                <hr />
            </div>
            <DialogContent className="dialog-content">
                <>
                    <section>
                        <div className="subtitle">
                            <IntlMessages id="page.spvSimple.housingTypes.independent" />
                        </div>
                        <DialogContentText color="textPrimary">
                            <IntlMessages id="page.spvSimple.housingTypes.dialog.independent" />
                        </DialogContentText>
                    </section>
                    <section>
                        <div className="subtitle">
                            <IntlMessages id="page.spvSimple.housingTypes.town" />
                        </div>
                        <DialogContentText color="textPrimary">
                            <IntlMessages id="page.spvSimple.housingTypes.dialog.town.1" />
                            <br />
                            <IntlMessages id="page.spvSimple.housingTypes.dialog.town.2" />
                        </DialogContentText>
                    </section>
                    <section>
                        <div className="subtitle">
                            <IntlMessages id="page.spvSimple.housingTypes.building" />
                        </div>
                        <DialogContentText color="textPrimary">
                            <IntlMessages id="page.spvSimple.housingTypes.dialog.building" />
                        </DialogContentText>
                    </section>
                </>
            </DialogContent>
        </Dialog>
    );
};

export default memo(HousingTypeDialog);
