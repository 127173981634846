import { useContext } from 'react';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPPa } from 'interfaces/businessModels/ppa';

// Components
import GrantsKpiPPa from './Kpi';

const GrantsKpisPPa = () => {
    const { state, bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;

    // const currentInvestment = bmSelected?.values?.marginSelected.cost;
    const currentInvestment = bmSelected?.values?.totalCost;
    const annualSaving = state?.businessModels?.[0]?.kpis_previews?.[0]?.kpis_per_margin?.[0]?.annual_saving;
    const grants = state?.selected?.values?.grants;
    const investmentWithGrant = currentInvestment - grants?.totalGrants?.currency;
    const payback = investmentWithGrant / annualSaving;

    return (
        <div className="bm-kpis">
            <GrantsKpiPPa
                label="page.bm.grants.originalInvestment"
                value={{ number: currentInvestment, unit: 'currency', numberOfDecimalPlaces: 0 }}
            />
            <GrantsKpiPPa
                label="page.bm.grants.grant"
                value={{ number: grants.totalGrants.currency, unit: 'currency', numberOfDecimalPlaces: 2 }}
            />
            <GrantsKpiPPa
                label="page.bm.grants.investmentWithGrant"
                value={{ number: investmentWithGrant, unit: 'currency', numberOfDecimalPlaces: 0 }}
            />
            <GrantsKpiPPa label="label.payback" value={{ number: payback, unit: 'year', numberOfDecimalPlaces: 1 }} />
        </div>
    );
};

export default GrantsKpisPPa;
