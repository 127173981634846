import { useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import CookieHeader from './Header';
import CookieBody from './Body';

// Services
import { routeoLoogin, routeToCookiePolicy } from 'services/cookies';
import { isFieldDefined } from 'services/util/auxiliaryUtils';

// Constants
import { USE_POLICY_BODY_OA, PRIVACY_POLICY_BODY_OA, COOKIES_POLICY_BODY_OA, PERSONAL_DATA_OA } from '../ConstantsOArea/cookies';
import { isOpenArea } from 'services/settings';
import { OAUserContext } from 'contexts/openArea/oaUserContext';

const CookieConsentOArea = ({ type, showPolicyHandler }) => {
    const {
        locale: { isoLocale },
    } = useContext(OAUserContext);
    const isUserAuthenticated = false;
    const location = useLocation();
    const history = useHistory();
    const scrollRef = useRef(null);
    const scrollToTop = () => scrollRef.current.scrollIntoView();
    const isOArea = useMemo(() => isOpenArea(), []);

    let title;
    let body;
    let updatedDate;
    let clickMethods = {};

    switch (location?.state?.type ?? type) {
        case 'terms-conditions':
            isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
            clickMethods.link1 = () => routeToCookiePolicy(history, 'privacy-policy', isUserAuthenticated);
            clickMethods.link2 = () => routeToCookiePolicy(history, 'cookies-policy', isUserAuthenticated);
            title = USE_POLICY_BODY_OA(clickMethods.link1, clickMethods.link2).title;
            body = USE_POLICY_BODY_OA(clickMethods.link1, clickMethods.link2).body;
            updatedDate = USE_POLICY_BODY_OA(clickMethods.link1, clickMethods.link2).updated;
            break;
        case 'privacy-policy':
        default:
            isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
            clickMethods.link1 = () => routeToCookiePolicy(history, 'use-conditions', isUserAuthenticated);
            clickMethods.link2 = () => routeToCookiePolicy(history, 'cookies-policy', isUserAuthenticated);
            title = PRIVACY_POLICY_BODY_OA(clickMethods.link1, clickMethods.link2).title;
            body = PRIVACY_POLICY_BODY_OA(clickMethods.link1, clickMethods.link2).body;
            updatedDate = PRIVACY_POLICY_BODY_OA(clickMethods.link1, clickMethods.link2).updated;
            break;
        case 'cookies-policy':
            isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
            clickMethods.link1 = () => routeToCookiePolicy(history, 'privacy-policy', isUserAuthenticated);
            title = COOKIES_POLICY_BODY_OA(clickMethods.link1).title;
            body = COOKIES_POLICY_BODY_OA(clickMethods.link1).body;
            updatedDate = COOKIES_POLICY_BODY_OA(clickMethods.link1).updated;
            break;
        case 'personal-data':
            isFieldDefined(scrollRef.current) && scrollToTop(); // Scrolling when chaning policies
            clickMethods.link1 = () => routeToCookiePolicy(history, 'privacy-policy', isUserAuthenticated);
            title = PERSONAL_DATA_OA(isoLocale).title;
            body = PERSONAL_DATA_OA(isoLocale).body;
            updatedDate = PERSONAL_DATA_OA(isoLocale).updated;
            break;
    }

    const closeHandler = () => {
        if (showPolicyHandler) showPolicyHandler(null);
        routeoLoogin(history, isOArea);
    };

    return (
        <div className={`cookie-consent cookie-consent-not-authenticated `} ref={scrollRef}>
            {<CookieHeader title={title} close={closeHandler} />}
            <CookieBody body={body} updatedDate={updatedDate} />
        </div>
    );
};

CookieConsentOArea.propTypes = {
    type: PropTypes.string,
    close: PropTypes.func,
    showPolicyHandler: PropTypes.func,
};

export default CookieConsentOArea;
