import Collapse from '@mui/material/Collapse';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront } from 'interfaces/businessModels/upfront';
import { useContext, useState } from 'react';
import GrantsKpisUpfront from './Kpis';
import GrantsTableUpfront from './Table';
import GrantsTitle from './Title';
import GrantsDialog from './Dialog';
import { PRODUCT_IDS } from 'constants/products';

const GrantsUpfront = () => {
    const { bmSelected, productID } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDialog, setisOpenDialog] = useState(false);

    return (
        <>
            <div className="bm-grants" data-testid="bm_grants_id">
                <GrantsTitle
                    label="page.bm.grants.title"
                    isOpen={isOpen}
                    collapseHandler={() => setIsOpen(!isOpen)}
                    openDialog={() => {
                        setisOpenDialog(true);
                        if ([PRODUCT_IDS.HP].includes(productID)) {
                            // @ts-ignore
                            window._paq.push(['trackEvent', 'Business Models', 'Click', 'Scheme Eligibility']);
                        }
                    }}
                />
                <Collapse in={isOpen}>
                    <GrantsTableUpfront />
                    {bmSelected.values.grants.totalGrants.prc <= 100 && <GrantsKpisUpfront />}
                </Collapse>
            </div>
            {isOpenDialog && <GrantsDialog isOpen={isOpenDialog} onCloseHandler={() => setisOpenDialog(false)} />}
        </>
    );
};

export default GrantsUpfront;
