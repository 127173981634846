import { notify } from 'services/@efz/notify';

import { PRODUCT_IDS } from 'constants/products';

//constants
import {
    UNAUTHORIZED,
    INTERNAL_SERVER_ERROR,
    TOAST_MSG_SERVER_ERROR_ID,
    TOAST_CHECK_STATUS_ERROR_ID,
    TOAST_MSG_BAD_REQUEST_ID,
    BAD_REQUEST,
    getMessageError,
    OK,
} from 'constants/statusCodeHttp';
import { isDefined } from 'services/util/auxiliaryUtils';
import { getMessageServerError500 } from './statusCodeHttp';

const isNotProductPVOrPVSB = (productID) => isDefined(productID) && productID !== PRODUCT_IDS.SPVSB && productID !== PRODUCT_IDS.SPV;

export const checkResponseStatus = (payload) => {
    let status = payload?.status ?? null;

    if (isDefined(payload) && isDefined(status)) {
        switch (status) {
            case OK:
                return;
            case UNAUTHORIZED: //UNAUTHORIZE > LOGOUT
                localStorage.clear(); // Clearing LS will trigger a userSignOut in the App container, since it clears the token
                break;
            case INTERNAL_SERVER_ERROR: //INTERNAL SERVER ERROR > SHOW GENERIC ERROR
                // If product is not SPV
                if (isNotProductPVOrPVSB(payload?.productID)) notify(getMessageServerError500(), 'error', TOAST_MSG_SERVER_ERROR_ID);
                break;
            case BAD_REQUEST:
                // If product is not SPV
                if (isNotProductPVOrPVSB(payload?.productID)) notify(payload.data.message, 'error', TOAST_MSG_BAD_REQUEST_ID);
                break;
            default:
                notify(getMessageError(payload?.status), 'error', TOAST_CHECK_STATUS_ERROR_ID);
                break;
        }
    }
};
