import { postFileUploadImgContract } from 'api/utils';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect } from 'react';
import { SentryCaptureException } from 'services/@efz/sentry';
import { createFormDataWithImageBlob } from 'services/util/auxiliaryUtils';

//#region AbortControllers
let getStaticMapAbortController;
let postImgContractAbortController;

function cleanup() {
    getStaticMapAbortController && getStaticMapAbortController.abort();
    postImgContractAbortController && postImgContractAbortController.abort();
}
//#endregion AbortControllers

interface ICoordenate {
    lat: number;
    lng: number;
    zoom: number;
    image_name: string;
}

const useStaticMap = () => {
    const fetchStaticImage = useCallback(async (coordinate: ICoordenate) => {
        let linkToSend;
        try {
            let { image_name } = coordinate;
            const { lat, lng, zoom } = coordinate;

            let imageBase64 = '';

            linkToSend = `${process.env.REACT_APP_API_URL_STATIC_MAP}&center=${lat}%2c%20${lng}&zoom=${zoom}&size=640x640`;
            const rspStaticMap = await fetch(linkToSend)
                .then((response) => {
                    if (response?.status !== StatusCodes.OK) throw new Error(`${response?.status}`);
                    return response.blob();
                })
                .then(
                    (blob) =>
                        new Promise((callback) => {
                            const reader = new FileReader();
                            reader.onload = function () {
                                callback(this?.result);
                            };
                            reader.readAsDataURL(blob);
                        })
                );

            imageBase64 = typeof rspStaticMap === 'string' ? rspStaticMap : '';
            image_name = `${image_name}-${new Date().getTime()}.png`;

            return {
                //DS payload
                center: {
                    lat,
                    lng,
                    zoom,
                },
                image: imageBase64?.replace('data:', '').replace(/^.+,/, '')!,
                image_name,
                debugInfo: {
                    link: linkToSend,
                },
            };
        } catch (error: any) {
            const message = `Google Static ${error.message} Error`;
            SentryCaptureException({
                level: 3,
                message,
                fingerprint: message,
                extrasContext: {
                    link: linkToSend,
                },
                tags: {
                    fnc: 'handleAutoDetectRoof',
                },
            });
            console.log('gmx error ->', error);
            return Promise.reject(new Error(JSON.stringify(error)));
        }
    }, []); // eslint-disable-line

    const fetchPostFileUploadImgContract = useCallback(
        async (coordinate: ICoordenate, areas: [{ lat: number; lng: number }], isOArea: boolean) => {
            try {
                // create path
                let path = '&path=color:0x00a3e0|weight:2|fillcolor:0x00a3e0'; //color polygon
                for (let idx = 0; idx < areas.length; idx++) {
                    //only 1 polygon
                    path = path.concat(`|${areas[idx]?.lat},${areas[idx]?.lng}`);
                }

                const { lat, lng, zoom } = coordinate;

                const rspStaticMapBlob = await fetch(
                    `${process.env.REACT_APP_API_URL_STATIC_MAP}&center=${lat}%2c%20${lng}&zoom=${zoom}&size=640x640${path}`
                )
                    .then((response) => response.blob())
                    .then((blob) => blob);
                const formData = createFormDataWithImageBlob(rspStaticMapBlob, 'img-contract', 'gmap-img');

                postImgContractAbortController && postImgContractAbortController.abort();
                postImgContractAbortController = new AbortController();
                const rspUploadData = await postFileUploadImgContract(formData, isOArea, postImgContractAbortController);

                if (rspUploadData?.status !== StatusCodes.OK) {
                    throw new Error(JSON.stringify(rspUploadData));
                }

                if (Object.keys(rspUploadData?.data).length === 0 && rspUploadData?.data.constructor === Object) {
                    SentryCaptureException({
                        level: 4,
                        message: `Google Static Empty Object Error`,
                        extrasContext: {
                            rspUploadData: rspUploadData?.data,
                            formData: formData,
                            coordinate: coordinate,
                            rspStaticMapBlob: rspStaticMapBlob,
                        },
                        tags: {
                            fnc: 'fetchPostFileUploadImgContract',
                        },
                    });
                }

                return rspUploadData?.data ?? '';
            } catch (error) {
                // TODO: sentry
                return error;
            }
        },
        []
    ); // eslint-disable-line

    const fetchPostFileUploadImgContractMultiplePolygon = useCallback(
        async (coordinate: ICoordenate, areas: [{ id: number; coordinates: [{ lat: number; lng: number }] }], panels: Array<any>) => {
            try {
                /**
                 * Problemas quando temos muitos paineis e o pedidos retorna 413 ver link
                 *  docs:
                 * -  https://stackoverflow.com/questions/4278053/google-static-maps-url-length-limit
                 * -  https://developers.google.com/maps/documentation/maps-static/start#url-size-restriction
                 */

                // create paths
                let paths = '';
                // areas
                for (let idx = 0; idx < areas.length; idx++) {
                    const path = `&path=color:0xd6c214|weight:2|fillcolor:0xd6c214`; //color polygon
                    paths = paths.concat(path);
                    // eslint-disable-next-line
                    const coordinates = areas[idx]?.coordinates.map((el) => new google.maps.LatLng(el));
                    const encoded = google.maps.geometry.encoding.encodePath(coordinates);
                    paths = paths.concat(`|enc:${encoded}`);
                }

                // panels
                for (let idx = 0; idx < panels.length; idx++) {
                    const strokeColor = '0xffffff4d';
                    const fillColorSelected = '0x01173dff';
                    const fillColorUnselected = '0x01173d4d';
                    if (panels[idx].visible) {
                        const path = `&path=color:${strokeColor}|weight:1|fillcolor:${panels[idx].isValid ? fillColorSelected : fillColorUnselected}`; //color polygon
                        paths = paths.concat(path);
                        const encoded = google.maps.geometry.encoding.encodePath(panels[idx]?.getPath().getArray());
                        paths = paths.concat(`|enc:${encoded}`);
                    }
                }

                const { lat, lng, zoom } = coordinate;

                const rspStaticMapBlob = await fetch(
                    `${process.env.REACT_APP_API_URL_STATIC_MAP}&center=${lat}%2c%20${lng}&zoom=${zoom}&size=250x250${paths}`
                )
                    .then((response) => response.blob())
                    .then((blob) => blob);
                const formData = createFormDataWithImageBlob(rspStaticMapBlob, 'img-contract', 'gmap-img');

                postImgContractAbortController && postImgContractAbortController.abort();
                postImgContractAbortController = new AbortController();
                const rspUploadData = await postFileUploadImgContract(formData, false, postImgContractAbortController);

                if (rspUploadData?.status !== StatusCodes.OK) {
                    throw new Error(JSON.stringify(rspUploadData));
                }
                return rspUploadData?.data ?? '';
            } catch (error) {
                // TODO: sentry
                return Promise.reject(new Error(JSON.stringify(error)));
            }
        },
        []
    ); // eslint-disable-line

    // cleanup API requests
    useEffect(() => {
        return () => {
            cleanup();
        };
    }, []);

    return {
        fetchStaticImage,
        fetchPostFileUploadImgContract,
        fetchPostFileUploadImgContractMultiplePolygon,
    };
};

export default useStaticMap;
