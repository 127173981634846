import PropTypes from 'prop-types';
import { useUserStore } from 'store/user';
//Our Components
import IntlMessages from './IntlMessages';

//More Units Available
export const UNITS_AVAILABLE = {
    CURRENCY: 'currency', //Currency                              (100 €)
    PERCENTAGE: 'percentage', //Percentage                            (100 %)
    YEAR: 'year', //Years                                 (100 years)
    MONTH: 'month', //Months                                (100 months)
    YC: 'yc', //year & currency                       (100 €/year)
    SYMBOL: 'symbol', //Simbol                                (100 &)
    YP: 'yp', //year & percent                        (100 %/year)
    YPW: 'ypw', //year & power                          (100 €/kWh)
    C_YPW: 'cypw', //ct currency & power                   (100 ct€/kWh)
    YPP: 'ypp', //year & power peak                     (100 €/kWp)
    YPDC: 'ypdc', //year & power peak                     (100 €/kWDC)
    MC: 'mc', //month & currency                      (100 €/month)
    MC_SMALL: 'mc_small', //month & currency (initial only)       (100 €/m)
    W: 'w', //Watts                                 (100 W)
    KW: 'kw', //power in K   Quilowatt                (100 kW)
    WP: 'wp', //peak power							(100 Wp)
    WDC: 'wdc', //peak power							(100 WDC)
    KWP: 'kwp', //peak power                            (100 kWp)
    KWC: 'kwc', //peak power                            (100 kWc)
    KWDC: 'kwdc', //peak power                           (100 kWDC)
    CKWH: 'ckwh', //peak power                            (100 €/kWp)
    CMWH: 'cmwh', //peak power                            (100 €/MWp)
    CMWP: 'cmwp', //Currency peak Power (M)               (100 €/MWh)
    MWH: 'mwh', //MegaWatts H                           (100 MWh)
    KWH: 'kwh', //Killowatts H                          (100 kWh)
    YKWH: 'ykwh', //Killowatts H Year                     (100 kWh/year)
    M: 'm', //Meters                                (100 m)
    MM: 'mm', //Millimeters                           (100 mm)
    SM: 'sm', //Square Meters                         (100 m²)
    CM: 'cm', //Cubic Meter                           (100 m³)
    MCC: 'mcc', //€ Meter                               (100 €/m)
    PAN: 'pan', //Panels                                (100 panels)
    PANC: 'panc', //Panels & currency                     (100 €/panel)
    INV: 'inv', //Inverters                             (100 inverters)
    BAT: 'bat', //Batteries                             (100 batteries)
    DG: 'dg', //Degree                                (100 º)
    DGC: 'dgc', //Degree Celsius                        (100 ºC)
    TEP: 'tep', //TEP                                   (100 tep)
    KVAR: 'kvar', //kVAr                                  (100 kVAr)
    CAE: 'cae', //CAE                                   (100 CAE)
    BTUH: 'btuh', //BTU H                                 (100 BTU/h)
    CKWD: 'ckwd', //Currency && Killowatts*day            (100 €/kW.dia)
    KVA: 'kVA', //Kilovoltampere                        (100 kVA)
    DB: 'db', //Decibels                              (100 dB)
    KGS: 'kgs', //Kilograms per second                  (100 Kg/s)
    L: 'l', //Liters                                (100 l)
    LPD: 'lpd', //Liters per day                        (100 l/day)
    A: 'a', //Ampere                                (100 A)
    WSM: 'wsm', //Watts per sqare meter                 (100 W/m²)
    WSMK: 'wsmk', //Watts per sqare meter Kelvin          (100 W/m²K)
    CKW: 'ckw', //Currency/Killowatts                   (100 €/kW)
    CH: 'ch', //Currency/Hour                         (100 €/h)
    CL: 'cl', //Currency/Liter                        (100 €/liter)
    CCM: 'ccm', //Currency/Cubic Meter                  (100 €/m³)
    DP: 'dp', // percent per degree Celsius                   (100 %/°C)
    V: 'v', //Volts                                (100 V)
    VA: 'va', //Voltampere                            (100 VA)
    MB: 'mb', //MegaBytes                            (100 MB)
};

//Defaults
const DEFAULTS_PROPS = {
    SYMBOL: '€',
};

/**
 * Component
 * Examples:
 *  - <CustomUnit unit="tep" number={2} />
 *  - <CustomUnit unit="kwp" number={2} />
 * @description Component Used for custom Units not available in IntlPackage
 * @param {string} symbol - Currency Symbol
 * @param {string} unit - Unit desired
 * @returns {string|*}
 * @constructor
 */
const CustomUnit = (props) => {
    const { unit, number, style } = props;
    const { user } = useUserStore();
    if (!user) return <></>;
    const symbol = user?.currency?.symbol;
    switch (unit) {
        case UNITS_AVAILABLE.CAE:
            return (
                <span className="unit" style={style}>
                    &nbsp;CAE
                </span>
            );
        case UNITS_AVAILABLE.CURRENCY:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}
                </span>
            );
        case UNITS_AVAILABLE.YEAR:
            return (
                <span className="unit" style={style}>
                    &nbsp;
                    <IntlMessages id={number === 1 ? 'label.year' : 'label.years'} />
                </span>
            );
        case UNITS_AVAILABLE.MONTH:
            return (
                <span className="unit" style={style}>
                    &nbsp;
                    <IntlMessages id={number === 1 ? 'label.month' : 'label.months'} />
                </span>
            );
        case UNITS_AVAILABLE.YC:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/<IntlMessages id={'label.year'} />
                </span>
            );
        case UNITS_AVAILABLE.SYMBOL:
            return (
                <span className="unit" style={style}>
                    {number}
                </span>
            );
        case UNITS_AVAILABLE.YP:
            return (
                <span className="unit" style={style}>
                    &nbsp;%/
                    <IntlMessages id={'label.year'} />
                </span>
            );
        case UNITS_AVAILABLE.YPW:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/kWh
                </span>
            );
        case UNITS_AVAILABLE.C_YPW:
            return (
                <span className="unit" style={style}>
                    &nbsp;ct{symbol}/kWh
                </span>
            );
        case UNITS_AVAILABLE.MCC:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/m
                </span>
            );
        case UNITS_AVAILABLE.MC:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/<IntlMessages id={'label.month'} />
                </span>
            );
        case UNITS_AVAILABLE.MC_SMALL:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/<IntlMessages id={'label.month.minified'} />
                </span>
            );
        case UNITS_AVAILABLE.WP:
            return (
                <span className="unit" style={style}>
                    &nbsp;Wp
                </span>
            );
        case UNITS_AVAILABLE.WDC:
            return (
                <span className="unit" style={style}>
                    &nbsp;WDC
                </span>
            );
        case UNITS_AVAILABLE.KWP:
            return (
                <span className="unit" style={style}>
                    &nbsp;kWp
                </span>
            );
        case UNITS_AVAILABLE.KWC:
            return (
                <span className="unit" style={style}>
                    &nbsp;kWc
                </span>
            );
        case UNITS_AVAILABLE.KWDC:
            return (
                <span className="unit" style={style}>
                    &nbsp;kWDC
                </span>
            );
        case UNITS_AVAILABLE.W:
            return (
                <span className="unit" style={style}>
                    &nbsp;W
                </span>
            );
        case UNITS_AVAILABLE.KW:
            return (
                <span className="unit" style={style}>
                    &nbsp;kW
                </span>
            );
        case UNITS_AVAILABLE.MWH:
            return (
                <span className="unit" style={style}>
                    &nbsp;MWh
                </span>
            );
        case UNITS_AVAILABLE.KWH:
            return (
                <span className="unit" style={style}>
                    &nbsp;kWh
                </span>
            );
        case UNITS_AVAILABLE.YKWH:
            return (
                <span className="unit" style={style}>
                    &nbsp;kWh/
                    <IntlMessages id={'label.year'} />
                </span>
            );
        case UNITS_AVAILABLE.TEP:
            return (
                <span className="unit" style={style}>
                    &nbsp;tep
                </span>
            );
        case UNITS_AVAILABLE.CKWH:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/kWh
                </span>
            );
        case UNITS_AVAILABLE.CMWH:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/MWh
                </span>
            );
        case UNITS_AVAILABLE.CMWP:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/MWh
                </span>
            );
        case UNITS_AVAILABLE.YPP:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/kWp
                </span>
            );
        case UNITS_AVAILABLE.YPDC:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/kWDC
                </span>
            );
        case UNITS_AVAILABLE.M:
            return (
                <span className="unit" style={style}>
                    m
                </span>
            );
        case UNITS_AVAILABLE.SM:
            return (
                <span className="unit" style={style}>
                    m<sup>2</sup>
                </span>
            );
        case UNITS_AVAILABLE.CM:
            return (
                <span className="unit" style={style}>
                    m<sup>3</sup>
                </span>
            );
        case UNITS_AVAILABLE.MM:
            return (
                <span className="unit" style={style}>
                    &nbsp;mm
                </span>
            );
        case UNITS_AVAILABLE.PAN:
            return (
                <span className="unit text-lowercase">
                    <IntlMessages id={'label.panels'} />
                </span>
            );
        case UNITS_AVAILABLE.PANC:
            return (
                <span className="unit text-lowercase">
                    &nbsp;{symbol}/<IntlMessages id={'label.panel'} />
                </span>
            );
        case UNITS_AVAILABLE.INV:
            return (
                <span className="unit" style={style}>
                    &nbsp;
                    <IntlMessages id={!!number && number === 1 ? 'label.inverter' : 'label.inverters'} />
                </span>
            );
        case UNITS_AVAILABLE.BAT:
            return (
                <span className="unit" style={style}>
                    &nbsp;
                    <IntlMessages id={!!number && number === 1 ? 'label.battery' : 'label.batteries'} />
                </span>
            );
        case UNITS_AVAILABLE.DG:
            return <span className="unit degree-unit">º</span>;
        case UNITS_AVAILABLE.DGC:
            return <span className="unit degree-unit">ºC</span>;
        case UNITS_AVAILABLE.KVAR:
            return (
                <span className="unit" style={style}>
                    &nbsp;kVAr
                </span>
            );
        case UNITS_AVAILABLE.BTUH:
            return (
                <span className="unit" style={style}>
                    &nbsp;BTU/h
                </span>
            );
        case UNITS_AVAILABLE.PERCENTAGE:
            return (
                <span className="unit" style={style}>
                    %
                </span>
            );
        case UNITS_AVAILABLE.CKWD:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/kW · <IntlMessages id="label.day" />
                </span>
            );
        case UNITS_AVAILABLE.KVA:
            return (
                <span className="unit" style={style}>
                    &nbsp;kVA
                </span>
            );
        case UNITS_AVAILABLE.DB:
            return (
                <span className="unit" style={style}>
                    &nbsp;dB
                </span>
            );
        case UNITS_AVAILABLE.KGS:
            return (
                <span className="unit" style={style}>
                    &nbsp;Kg/s
                </span>
            );
        case UNITS_AVAILABLE.L:
            return (
                <span className="unit" style={style}>
                    &nbsp;l
                </span>
            );
        case UNITS_AVAILABLE.LPD:
            return (
                <span className="unit" style={style}>
                    &nbsp;l/
                    <IntlMessages id="label.day" />
                </span>
            );
        case UNITS_AVAILABLE.A:
            return (
                <span className="unit" style={style}>
                    &nbsp;A
                </span>
            );
        case UNITS_AVAILABLE.WSM:
            return (
                <span className="unit" style={style}>
                    &nbsp;W/m<sup>2</sup>
                </span>
            );
        case UNITS_AVAILABLE.WSMK:
            return (
                <span className="unit" style={style}>
                    &nbsp;W/m<sup>2</sup>K
                </span>
            );
        case UNITS_AVAILABLE.CKW:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/kW
                </span>
            );
        case UNITS_AVAILABLE.CH:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/h
                </span>
            );
        case UNITS_AVAILABLE.CL:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/liter
                </span>
            );
        case UNITS_AVAILABLE.CCM:
            return (
                <span className="unit" style={style}>
                    &nbsp;{symbol}/m<sup>3</sup>
                </span>
            );
        case UNITS_AVAILABLE.DP: {
            return (
                <span className="unit" style={style}>
                    &nbsp;% / ºC
                </span>
            );
        }
        case UNITS_AVAILABLE.V: {
            return (
                <span className="unit" style={style}>
                    &nbsp;V
                </span>
            );
        }
        case UNITS_AVAILABLE.VA: {
            return (
                <span className="unit" style={style}>
                    &nbsp;VA
                </span>
            );
        }
        case UNITS_AVAILABLE.MB: {
            return (
                <span className="unit" style={style}>
                    &nbsp;MB
                </span>
            );
        }
        default:
            return <span className="unit" style={style}></span>;
    }
};

// Set default props
CustomUnit.defaultProps = {
    symbol: DEFAULTS_PROPS.SYMBOL,
    style: {},
};

//Proptypes
CustomUnit.propTypes = {
    unit: PropTypes.string,
    number: PropTypes.any,
    symbol: PropTypes.string.isRequired,
};

export default CustomUnit;
