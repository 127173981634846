import * as z from 'zod';
import { FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';

// Constants
import {
    DHW_CALC_MODE_IDS,
    EXTRAS_CAT_IDS,
    FLOW_TEMPERATURE_LIMITS,
    HEATSOURCE_DT_LIMITS,
    HEAT_LOSS_CALCULATION_METHOD,
    HEAT_SOURCE_PRICE_UNITS,
    HEAT_SOURCE_TYPES_IDS,
    HP_FAST_STEPS,
    HP_FAST_SUB_STEPS,
    IMPACT_CAVITY_WALL_INSULATION_LIMITS,
    IMPACT_LOFT_INSULATION_LIMITS,
    IMPACT_SOLID_WALL_INSULATION_LIMITS,
    INPUT_NAMES_EXTRA,
    INPUT_NAMES_STEP_EXTRAS,
    INPUT_NAMES_STEP_HP_SELECTION,
    INPUT_NAMES_STEP_PROPERTY,
    INPUT_NAMES_STEP_PROPERTY_DEFINE_PROPERTY_PERSONALIZABLE,
    PROPERTY_DEFINE_PROPERTY_AREA_LIMITS,
    PROPERTY_MANUAL_HEAT_LOSS_VALUE_LIMITS,
    SPACE_HEATING_ENERGY_CONSUMPTION_LIMITS,
} from 'constants/products/hpFast';

// Interfaces
import { IQuestionTooltip } from 'interfaces/products/evcPro';
import {
    CheckBoxOption,
    IRadioWithImageOption,
    IRadioWithListOption,
    IUploadFileConfig,
    InputLabel,
    RadioOption,
    RadioWithLabelOption,
    SelectOption,
    SwitchOption,
} from 'interfaces/products/evcPro/inputs';
import { TIntl } from 'types/utils';

// Services
import { accessObjectProp } from 'services/util/auxiliaryUtils';

// #region form constants

export type THPFastSteps = (typeof HP_FAST_STEPS)[keyof typeof HP_FAST_STEPS];
export type THPFastSubSteps = (typeof HP_FAST_SUB_STEPS)[keyof typeof HP_FAST_SUB_STEPS];

export interface IHPFastStep<T = THPFastSteps> {
    label: TIntl;
    value: T;
    visible?: boolean;
    disabled?: boolean;
    finished?: boolean;
    subSteps?: THPFastSubStepsConfig;
    icon?: any;
    dataTestId?: string;
}

export type THPFastSubStep<T = THPFastSubSteps> = Omit<IHPFastStep<T>, 'subSteps'>;

export type THPFastStepsConfig = {
    [K in THPFastSteps]: IHPFastStep<K>;
};

export type THPFastSubStepsConfig = {
    [K in THPFastSubSteps]: THPFastSubStep<K>;
};

export const stepToNumberMap = {
    [HP_FAST_STEPS.PROPERTY]: 1,
    [HP_FAST_STEPS.HP_SELECTION]: 2,
    [HP_FAST_STEPS.EXTRAS]: 3,
    [HP_FAST_STEPS.BILL_OF_MATERIALS]: 4,
    [HP_FAST_STEPS.PROPOSAL]: 5,
};

export const subStepToNumberMap = {
    [HP_FAST_SUB_STEPS.PROPOSAL_BUSINESS_MODELS]: 1,
    [HP_FAST_SUB_STEPS.PROPOSAL_DOWNLOAD]: 2,
};

export const stepInputNamesMap = {
    [HP_FAST_STEPS.PROPERTY]: INPUT_NAMES_STEP_PROPERTY,
    [HP_FAST_STEPS.HP_SELECTION]: INPUT_NAMES_STEP_HP_SELECTION,
    [HP_FAST_STEPS.EXTRAS]: INPUT_NAMES_STEP_EXTRAS,
    [HP_FAST_STEPS.BILL_OF_MATERIALS]: INPUT_NAMES_STEP_HP_SELECTION,
    [HP_FAST_STEPS.PROPOSAL]: INPUT_NAMES_STEP_HP_SELECTION,
};

export type TInputNames = {
    [K in THPFastSteps]: {
        [P in keyof (typeof stepInputNamesMap)[K]]: (typeof stepInputNamesMap)[K][P];
    };
};

export type ALL_INPUT_NAMES = {
    [K in keyof TInputNames]: TInputNames[K][keyof TInputNames[K]];
}[keyof TInputNames];

export type TQuestionType =
    | { type: 'label-radio'; options: RadioOption[] }
    | { type: 'number'; options?: never; step?: number; unit?: string }
    | { type: 'radio'; options: RadioOption[] }
    | { type: 'radio-image'; options: IRadioWithImageOption[] }
    | { type: 'radio-label'; options: RadioWithLabelOption }
    | { type: 'radio-label-boolean'; options?: never }
    | { type: 'radio-list'; options: IRadioWithListOption[] }
    | { type: 'select'; options: SelectOption[] }
    | { type: 'switch'; options: SwitchOption }
    | { type: 'array'; options?: never }
    | { type: 'file'; options?: never; config: IUploadFileConfig }
    | { type: 'checkbox'; options?: CheckBoxOption[] };

export type IQuestionInfo =
    | {
          label: InputLabel | TIntl;
          children?: React.ReactElement;
          type?: 'info' /* | 'error' | 'warning' | 'default' | 'success' */;
          className?: string;
          iconClassName?: string;
          visible?: boolean;
          isComponent?: false;
      }
    | {
          component: React.ReactNode;
          visible?: boolean;
          isComponent?: true;
      };

export type IQuestion<N extends ALL_INPUT_NAMES> = {
    name: N;
    label?: InputLabel | TIntl | '';
    placeholder: TIntl | '';
    tooltip?: IQuestionTooltip;
    info?: IQuestionInfo;
    disabled?: boolean;
    visible: boolean;
    validation: Omit<RegisterOptions<FieldValues, FieldPath<FieldValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    validationDependencies?: ALL_INPUT_NAMES[];
    valueType?: 'number' | 'string' | 'boolean';
} & TQuestionType;

type ValuesAsKeys<T extends Record<any, PropertyKey>, K extends keyof T> = {
    [P in keyof T]: P extends K ?
        {
            [Key in T[P]]: IQuestion<Extract<T[P], ALL_INPUT_NAMES>>;
        }
    :   never;
}[keyof T];

export type StepQuestions<T extends keyof TInputNames> = ValuesAsKeys<TInputNames[T], keyof TInputNames[T]>;

export type TFormQuestions = { [K in keyof TInputNames]: StepQuestions<K> };

export type TFormQuestionsSettings = {
    [companyProfileId: number]: {
        [userTypeId: number]: TFormQuestions;
    };
};

// #endregion form constants

// #region form schema

z.setErrorMap((issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_type) {
        if (issue.received === undefined || issue.received === 'undefined') return { message: 'yup.message.required' };
        // if (ctx.defaultError === 'Required') {
        // }
    }

    if (issue.code === z.ZodIssueCode.too_big) {
        if (issue.type === 'number') {
            if (issue.inclusive) {
                return { message: JSON.stringify({ message: 'yup.message.number.max', values: { max: issue.maximum } }) };
            } else {
                return { message: JSON.stringify({ message: 'yup.message.number.maxNIncluded', values: { max: issue.maximum } }) };
            }
        } /* else if (issue.type === 'string') { // Não é necessário para este produto e não existem traduções configuradas. Apenas a titulo de exemplo
            if (issue.inclusive) {
                return { message: intlMessages('yup.message.string.max', { max: issue.maximum }) };
            } else {
                return { message: intlMessages('yup.message.string.maxNIncluded', { max: issue.maximum }) };
            }
        } */
    }

    if (issue.code === z.ZodIssueCode.too_small) {
        if (issue.type === 'number') {
            if (issue.inclusive) {
                return { message: JSON.stringify({ message: 'yup.message.number.min', values: { min: issue.minimum } }) };
            } else {
                return { message: JSON.stringify({ message: 'yup.message.number.minNIncluded', values: { min: issue.minimum } }) };
            }
        } /* else if (issue.type === 'string') { // Não é necessário para este produto e não existem traduções configuradas. Apenas a titulo de exemplo
            if (issue.inclusive) {
                return { message: intlMessages('yup.message.string.min', { max: issue.minimum }) };
            } else {
                return { message: intlMessages('yup.message.string.minNIncluded', { max: issue.minimum }) };
            }
        } */
    }
    return { message: ctx.defaultError };
});

// #region step 1: Property

const STEP_PROPERTY_HEAT_SOURCE_SCHEMA = z.object({
    [INPUT_NAMES_STEP_PROPERTY.heat_source]: z.boolean().optional(),
    heat_source: z
        .object({
            type_id: z.nativeEnum(HEAT_SOURCE_TYPES_IDS).optional(),
            price: z.object({
                unit: z.nativeEnum(HEAT_SOURCE_PRICE_UNITS).optional(),
                value: z.number().nonnegative().finite().safe().optional(),
            }),
        })
        .optional(),
});

const STEP_PROPERTY_ESTIMATED_HEAT_LOSS_SCHEMA = z.object({
    [INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method]: z.literal(HEAT_LOSS_CALCULATION_METHOD.EPC_DATA),
    [INPUT_NAMES_STEP_PROPERTY.space_heating_energy_consumption]: z
        .number()
        .positive()
        .min(SPACE_HEATING_ENERGY_CONSUMPTION_LIMITS.min)
        .max(SPACE_HEATING_ENERGY_CONSUMPTION_LIMITS.max),
    insulation_impact: z
        .object({
            loft: z.number().min(IMPACT_LOFT_INSULATION_LIMITS.min).max(IMPACT_LOFT_INSULATION_LIMITS.max).optional().default(0),
            cavity_wall: z
                .number()
                .min(IMPACT_CAVITY_WALL_INSULATION_LIMITS.min)
                .max(IMPACT_CAVITY_WALL_INSULATION_LIMITS.max)
                .optional()
                .default(0),
            solid_wall: z
                .number()
                .min(IMPACT_SOLID_WALL_INSULATION_LIMITS.min)
                .max(IMPACT_SOLID_WALL_INSULATION_LIMITS.max)
                .optional()
                .default(0),
        })
        .optional(),
    [INPUT_NAMES_STEP_PROPERTY.estimated_heat_loss]: z.number().optional(),
});

const STEP_PROPERTY_EPC_DATA_SCHEMA = STEP_PROPERTY_ESTIMATED_HEAT_LOSS_SCHEMA.merge(STEP_PROPERTY_HEAT_SOURCE_SCHEMA);

const STEP_PROPERTY_HEAT_SOURCE_WITH_VALIDATION_SCHEMA = STEP_PROPERTY_HEAT_SOURCE_SCHEMA.superRefine((data, ctx) => {
    if (data[INPUT_NAMES_STEP_PROPERTY.heat_source] === undefined) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'yup.message.required',
            path: [INPUT_NAMES_STEP_PROPERTY.heat_source],
        });
    }

    if (data[INPUT_NAMES_STEP_PROPERTY.heat_source] === true) {
        if (!accessObjectProp(data, INPUT_NAMES_STEP_PROPERTY.heat_source_type)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'yup.message.required',
                path: [INPUT_NAMES_STEP_PROPERTY.heat_source_type],
            });
        }

        const heat_source_type = accessObjectProp(data, INPUT_NAMES_STEP_PROPERTY.heat_source_type);

        if (heat_source_type && heat_source_type !== HEAT_SOURCE_TYPES_IDS.ELECTRIC) {
            if (!accessObjectProp(data, INPUT_NAMES_STEP_PROPERTY.heat_source_price_unit)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'yup.message.required',
                    path: [INPUT_NAMES_STEP_PROPERTY.heat_source_price_unit],
                });
            }
            if (!accessObjectProp(data, INPUT_NAMES_STEP_PROPERTY.heat_source_price_value)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'yup.message.required',
                    path: [INPUT_NAMES_STEP_PROPERTY.heat_source_price_value],
                });
            }
        }
    }
});

export const STEP_PROPERTY_EPC_DATA_SCHEMA_WITH_VALIDATION_FOR_PAYLOAD = STEP_PROPERTY_ESTIMATED_HEAT_LOSS_SCHEMA.superRefine(
    (data, ctx) => {
        // Applies rule that states that the sum of the insulation values (the value post insulation upgrade)
        // should not be greater than the value of the space heating energy consumption
        const afterUpgradesValue =
            accessObjectProp(data, INPUT_NAMES_STEP_PROPERTY.impact_loft_insulation) +
            accessObjectProp(data, INPUT_NAMES_STEP_PROPERTY.impact_cavity_wall_insulation) +
            accessObjectProp(data, INPUT_NAMES_STEP_PROPERTY.impact_solid_wall_insulation);

        if (afterUpgradesValue > data[INPUT_NAMES_STEP_PROPERTY.space_heating_energy_consumption]) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: ' ',
                path: [INPUT_NAMES_STEP_PROPERTY.impact_loft_insulation],
            });
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: ' ',
                path: [INPUT_NAMES_STEP_PROPERTY.impact_cavity_wall_insulation],
            });
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: ' ',
                path: [INPUT_NAMES_STEP_PROPERTY.impact_solid_wall_insulation],
            });
        }
    }
);

const STEP_PROPERTY_EPC_DATA_SCHEMA_WITH_VALIDATION = STEP_PROPERTY_EPC_DATA_SCHEMA_WITH_VALIDATION_FOR_PAYLOAD.superRefine((data, ctx) => {
    if (!data[INPUT_NAMES_STEP_PROPERTY.estimated_heat_loss]) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'yup.message.required',
            path: [INPUT_NAMES_STEP_PROPERTY.estimated_heat_loss],
        });
    }
});

const STEP_PROPERTY_INSERT_MANUALLY_SCHEMA = z
    .object({
        [INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method]: z.literal(HEAT_LOSS_CALCULATION_METHOD.INSERT_MANUALLY),
        [INPUT_NAMES_STEP_PROPERTY.manual_heat_loss]: z
            .number()
            .min(PROPERTY_MANUAL_HEAT_LOSS_VALUE_LIMITS.min)
            .max(PROPERTY_MANUAL_HEAT_LOSS_VALUE_LIMITS.max),
    })
    .merge(STEP_PROPERTY_HEAT_SOURCE_SCHEMA);

const STEP_PROPERTY_DEFINE_PROPERTY_ESTIMATED_HEAT_LOSS_SCHEMA = z.object({
    [INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method]: z.literal(HEAT_LOSS_CALCULATION_METHOD.DEFINE_PROPERTY),
    [INPUT_NAMES_STEP_PROPERTY.property_type]: z.number().int().positive(),
    [INPUT_NAMES_STEP_PROPERTY.property_age]: z.number().int().positive(),
    [INPUT_NAMES_STEP_PROPERTY.property_area]: z
        .number()
        .positive()
        .min(PROPERTY_DEFINE_PROPERTY_AREA_LIMITS.min)
        .max(PROPERTY_DEFINE_PROPERTY_AREA_LIMITS.max),
    [INPUT_NAMES_STEP_PROPERTY.insulation_walls]: z.number().int().positive().optional(),
    [INPUT_NAMES_STEP_PROPERTY.insulation_loft]: z.number().int().positive().optional(),
    [INPUT_NAMES_STEP_PROPERTY.insulation_windows]: z.number().int().positive().optional(),
    [INPUT_NAMES_STEP_PROPERTY.insulation_doors]: z.number().int().positive().optional(),
    answer_combination: z
        .object({
            id: z.number().int().positive().optional(),
        })
        .optional(),
    [INPUT_NAMES_STEP_PROPERTY.estimated_heat_loss]: z.number().optional(),
});

const STEP_PROPERTY_DEFINE_PROPERTY_SCHEMA =
    STEP_PROPERTY_DEFINE_PROPERTY_ESTIMATED_HEAT_LOSS_SCHEMA.merge(STEP_PROPERTY_HEAT_SOURCE_SCHEMA);

export const STEP_PROPERTY_DEFINE_PROPERTY_SCHEMA_WITH_VALIDATION_FOR_PAYLOAD =
    STEP_PROPERTY_DEFINE_PROPERTY_ESTIMATED_HEAT_LOSS_SCHEMA.superRefine((data, ctx) => {
        const visibleQuestionsNotAnswered = Object.values(INPUT_NAMES_STEP_PROPERTY_DEFINE_PROPERTY_PERSONALIZABLE).filter(
            (name) => Object.keys(data).includes(name) && data[name] === undefined
        );

        for (const questionName of visibleQuestionsNotAnswered) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'yup.message.required',
                path: [questionName],
            });
        }

        if (!accessObjectProp(data, INPUT_NAMES_STEP_PROPERTY.combination_id)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'yup.message.required',
                path: [INPUT_NAMES_STEP_PROPERTY.combination_id],
            });
        }
    });

const STEP_PROPERTY_DEFINE_PROPERTY_SCHEMA_WITH_VALIDATION = STEP_PROPERTY_DEFINE_PROPERTY_SCHEMA_WITH_VALIDATION_FOR_PAYLOAD.superRefine(
    (data, ctx) => {
        if (!data[INPUT_NAMES_STEP_PROPERTY.estimated_heat_loss]) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'yup.message.required',
                path: [INPUT_NAMES_STEP_PROPERTY.estimated_heat_loss],
            });
        }
    }
);

export const STEP_PROPERTY_SCHEMA = z
    .discriminatedUnion(INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method, [
        STEP_PROPERTY_EPC_DATA_SCHEMA,
        STEP_PROPERTY_INSERT_MANUALLY_SCHEMA,
        STEP_PROPERTY_DEFINE_PROPERTY_SCHEMA,
    ])
    .superRefine((data, ctx) => {
        if (data[INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method] === HEAT_LOSS_CALCULATION_METHOD.EPC_DATA) {
            const epcVal = STEP_PROPERTY_EPC_DATA_SCHEMA_WITH_VALIDATION.safeParse(data);
            if (epcVal.success === false) {
                epcVal.error.issues.forEach((i) => ctx.addIssue(i));
            }
        } else if (data[INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method] === HEAT_LOSS_CALCULATION_METHOD.DEFINE_PROPERTY) {
            const definePropertyVal = STEP_PROPERTY_DEFINE_PROPERTY_SCHEMA_WITH_VALIDATION.safeParse(data);
            if (definePropertyVal.success === false) {
                definePropertyVal.error.issues.forEach((i) => ctx.addIssue(i));
            }
        }

        const heatSourceValidation = STEP_PROPERTY_HEAT_SOURCE_WITH_VALIDATION_SCHEMA.safeParse(data);
        if (heatSourceValidation.success === false) heatSourceValidation.error.issues.forEach((issue) => ctx.addIssue(issue));
    });
// #endregion step 1: Property

// #region step 2: HP Selection

export const STEP_HP_SELECTION_SCHEMA = z.object({
    [INPUT_NAMES_STEP_HP_SELECTION.equipment_id]: z.number().positive(),
    [INPUT_NAMES_STEP_HP_SELECTION.heatsource_dt]: z.number().min(HEATSOURCE_DT_LIMITS.min).max(HEATSOURCE_DT_LIMITS.max),
    [INPUT_NAMES_STEP_HP_SELECTION.flow_temperature]: z.number().min(FLOW_TEMPERATURE_LIMITS.min).max(FLOW_TEMPERATURE_LIMITS.max),
    [INPUT_NAMES_STEP_HP_SELECTION.return_temperature]: z.number(),
});

// #endregion step 2: HP Selection

// #region step 3: Extras

const EXTRA_BASE_SCHEMA = z.object({
    [INPUT_NAMES_EXTRA.model_id]: z.number().positive(),
    [INPUT_NAMES_STEP_EXTRAS.quantity]: z.number().positive(),
});

const EXTRA_DHWT_SCHEMA = EXTRA_BASE_SCHEMA.extend({
    [INPUT_NAMES_EXTRA.calc_mode_id]: z.literal(DHW_CALC_MODE_IDS.CALCULATE),
    [INPUT_NAMES_EXTRA.cat_id]: z.literal(EXTRAS_CAT_IDS.DHW),
    [INPUT_NAMES_STEP_EXTRAS.hot_water_per_occupant]: z.number().positive(),
    [INPUT_NAMES_STEP_EXTRAS.n_occupants]: z.number().positive(),
    [INPUT_NAMES_STEP_EXTRAS.quantity]: z.literal(1),
});

const EXTRA_CIRCULATION_PUMP_SCHEMA = EXTRA_BASE_SCHEMA.extend({
    [INPUT_NAMES_EXTRA.cat_id]: z.literal(EXTRAS_CAT_IDS.CIRCULATION_PUMP),
});

const EXTRA_OTHERS_SCHEMA = EXTRA_BASE_SCHEMA.extend({
    [INPUT_NAMES_EXTRA.cat_id]: z.literal(EXTRAS_CAT_IDS.OTHERS),
});

const EXTRA_THERMOSTAT_SCHEMA = EXTRA_BASE_SCHEMA.extend({
    [INPUT_NAMES_EXTRA.cat_id]: z.literal(EXTRAS_CAT_IDS.THERMOSTAT),
});

const EXTRAS_INSTRUMENTATIONS_ARRAY_SCHEMA = z
    .array(
        z.discriminatedUnion(INPUT_NAMES_EXTRA.cat_id, [
            EXTRA_DHWT_SCHEMA,
            EXTRA_CIRCULATION_PUMP_SCHEMA,
            EXTRA_OTHERS_SCHEMA,
            EXTRA_THERMOSTAT_SCHEMA,
        ])
    )
    .optional()
    .superRefine((data, ctx) => {
        if (data) {
            const dhwItems = data.filter((i) => i?.[INPUT_NAMES_EXTRA.cat_id] === EXTRAS_CAT_IDS.DHW) ?? [];

            if (dhwItems?.length > 1) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'The maximum number of domestic hot water tanks as been exceeded. Maximum is: 1',
                    path: [INPUT_NAMES_STEP_EXTRAS.quantity],
                });
            }
        }
    });

export const STEP_EXTRAS_SCHEMA = z
    .object({
        [INPUT_NAMES_STEP_EXTRAS.has_domestic_hot_water_tank]: z.boolean(),
        [INPUT_NAMES_STEP_EXTRAS.hot_water_per_occupant]: z.number().nonnegative().optional(),
        [INPUT_NAMES_STEP_EXTRAS.n_occupants]: z.number().nonnegative().optional(),
        [INPUT_NAMES_STEP_EXTRAS.size]: z.number().positive().optional(),
        [INPUT_NAMES_STEP_EXTRAS.instrumentations]: EXTRAS_INSTRUMENTATIONS_ARRAY_SCHEMA,
    })
    .superRefine((data, ctx) => {
        if (data[INPUT_NAMES_STEP_EXTRAS.has_domestic_hot_water_tank]) {
            const positiveRequiredField = z.number().positive();

            const hot_water_per_occupant = positiveRequiredField.safeParse(data[INPUT_NAMES_STEP_EXTRAS.hot_water_per_occupant]);
            if (hot_water_per_occupant?.success === false) {
                hot_water_per_occupant.error.issues.forEach((i) =>
                    ctx.addIssue({ ...i, path: [INPUT_NAMES_STEP_EXTRAS.hot_water_per_occupant] })
                );
            }

            const n_occupants = positiveRequiredField.safeParse(data[INPUT_NAMES_STEP_EXTRAS.n_occupants]);
            if (n_occupants?.success === false) {
                n_occupants.error.issues.forEach((i) => ctx.addIssue({ ...i, path: [INPUT_NAMES_STEP_EXTRAS.n_occupants] }));
            }

            const size = positiveRequiredField.safeParse(data[INPUT_NAMES_STEP_EXTRAS.size]);
            if (size?.success === false) {
                size.error.issues.forEach((i) => ctx.addIssue({ ...i, path: [INPUT_NAMES_STEP_EXTRAS.size] }));
            }
        }
    });

// #endregion step 3: Extras

export const HP_FAST_FORM_SCHEMA = z.object({
    [HP_FAST_STEPS.PROPERTY]: STEP_PROPERTY_SCHEMA,
    [HP_FAST_STEPS.HP_SELECTION]: STEP_HP_SELECTION_SCHEMA,
    [HP_FAST_STEPS.EXTRAS]: STEP_EXTRAS_SCHEMA,
});

export type THPFastForm = z.infer<typeof HP_FAST_FORM_SCHEMA>;
export type THPFastFormProperty = z.infer<typeof STEP_PROPERTY_SCHEMA>;
export type THPFastFormHPSelection = z.infer<typeof STEP_HP_SELECTION_SCHEMA>;
export type THPFastFormExtras = z.infer<typeof STEP_EXTRAS_SCHEMA>;
export type THPFastFormExtrasInstrumentationsArray = z.infer<typeof EXTRAS_INSTRUMENTATIONS_ARRAY_SCHEMA>;
export type THPFastFormExtrasDHWT = z.infer<typeof EXTRA_DHWT_SCHEMA>;

// #endregion form schema
