import { TBMPitchSection } from 'interfaces/businessModels';
import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as CheckIcon } from 'assets/images/icons/svg/check.svg';

interface IProps {
    sections: TBMPitchSection[];
    ownership: string;
}

const BusinessModelBodyPaP = ({ ownership, sections }: IProps) => {
    return (
        <>
            {ownership ?
                <div className="business-models-card-section">
                    <label>
                        <IntlMessages id="label.assetProperty" />
                    </label>
                    <span className="owner">{ownership}</span>
                </div>
            :   <></>}
            {sections?.length > 0 ?
                sections.map((section) => {
                    return (
                        <div className="business-models-card-section">
                            <label>{section.header}</label>
                            {section.body.length > 0 &&
                                section.body.map((item, index) => {
                                    return (
                                        <div className="d-flex align-items-center" key={`bm_pro_card_body_id_${index}`}>
                                            <CheckIcon className="business-models-card-check" />
                                            <span className="item"> {item}</span>
                                        </div>
                                    );
                                })}
                        </div>
                    );
                })
            :   <></>}
        </>
    );
};

export default BusinessModelBodyPaP;
