/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Validação do routeamento e validação de acesso ao Contanier
 *
 * @version 20191001
 * @since 20191001 Initial release
 *
 */
import { useEffect, useState, lazy } from 'react';
import { withRouter, useParams } from 'react-router';
import { connect } from 'react-redux';
import Error403 from 'components/errors/Error403';
import PropTypes from 'prop-types';
import Loading from 'components/core/Loading';
import ProductRoute from '../Product';
import lazyLoader from 'services/lazyLoader';

//actions
import { requestGetProductInputs } from 'redux/actions/product';

//Selectors
import { getProduct } from 'redux/selectors/product';
import { getClientID } from 'redux/selectors/client';
import { getFacilityID } from 'redux/selectors/facility';

//constants
import { BASENAME_URL_APP } from 'constants/settings';
import { PRODUCT_IDS, ALLOWED_SUB_PRODUCTS, ALLOWED_MODULES, TYPE_MODULES } from 'constants/products';
import { isDefined } from 'services/util/auxiliaryUtils';

const ModulesContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Modules')));

/**
 * @context Route to Modules
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Modules = (props) => {
    let { module, subProduct } = useParams();
    let { product, clientID, facilityID } = props;
    const [loading, setLoading] = useState(true);

    /**
     * Falta ajustar quando nao é o proposal,
     * limpar os inputs/proposal quando acabar de gerar a mesma
     */

    //Verify Access according to conditions
    let hasAccess = isDefined(clientID) && isDefined(facilityID) && isDefined(product?.info.id);
    //validar o url params
    if (!!subProduct && !ALLOWED_SUB_PRODUCTS.includes(subProduct)) hasAccess = false;
    if (isDefined(module) && !ALLOWED_MODULES.includes(module)) hasAccess = false;

    //componentDidMountWithUseEffect
    useEffect(() => {
        if (
            ![
                PRODUCT_IDS.GNS,
                PRODUCT_IDS.EVC,
                PRODUCT_IDS.EVCSB,
                PRODUCT_IDS.INTEGRA,
                PRODUCT_IDS.MPT,
                PRODUCT_IDS.TRE,
                PRODUCT_IDS.CFP,
                PRODUCT_IDS.SPV,
                PRODUCT_IDS.CE,
                PRODUCT_IDS.IE_BR,
                PRODUCT_IDS.HP,
                PRODUCT_IDS.SPV_SIMPLE,
                // PRODUCT_IDS.RR,
            ].includes(parseInt(product?.info.id)) &&
            hasAccess
        )
            props.requestGetProductInputs({ productID: product?.info.id, facilityID: facilityID });
        else setLoading(false);
    }, []); //eslint-disable-line

    useEffect(() => {
        //quando recebemos a reposta do <requestGetProductInputs> faz set ao loading local
        if (hasAccess && !product?.loading && !!product?.productInputs) setLoading(false);
    }, [product?.loading]); //eslint-disable-line

    if (!hasAccess)
        return (
            <Error403
                props={{
                    url: BASENAME_URL_APP + 'solutions',
                    buttonText: 'page.404.button.goSolutions',
                    message: 'page.error.403.facilities',
                }}
            />
        );

    if (loading) {
        return <Loading />;
    } else {
        switch (product?.info.id) {
            case PRODUCT_IDS.CFP:
                return <ProductRoute />; //Enviar para o ProductRouter para que o componente envie ocliente para o container do produto
            default: //este default é para todos os produtos que não tem condições especiais ao tentar entrar no seu container.
                if (product?.subProducts) return <ModulesContainer type={TYPE_MODULES.SUB_PRODUCTS} />;
                if (!!product?.modules && product?.modules.length === 1) return <ProductRoute />;
                if (!!product?.modules && product?.modules.length > 1 && !module) return <ModulesContainer type={TYPE_MODULES.MODULES} />;
                if (!!product?.modules && product?.modules.length > 1 && !!module) return <ProductRoute />;
                if (!!product?.modules && product?.modules.length === 0) return <ProductRoute />;
                return (
                    <Error403
                        props={{
                            url: BASENAME_URL_APP + 'solutions',
                            buttonText: 'page.404.button.goSolutions',
                            message: 'page.error.403.facilities',
                        }}
                    />
                );
        }
    }
};
//PropTypes
Modules.propTypes = {
    clientID: PropTypes.string.isRequired,
    facilityID: PropTypes.string.isRequired,
    product: PropTypes.shape({
        info: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        modules: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
};
//Maping State to Props
const mapStateToProps = ({ client, facility, product }) => {
    return {
        clientID: getClientID(client),
        facilityID: getFacilityID(facility),
        product: getProduct(product),
    };
};

export default withRouter(
    connect(mapStateToProps, {
        requestGetProductInputs,
    })(Modules)
);
