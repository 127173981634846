import Collapse from '@mui/material/Collapse';
import { useContext, useState } from 'react';

// Components
import GrantsTitle from './Title';
import GrantsDialog from './Dialog';
import GrantsKpisLEC from './Kpis';
import GrantsTableLEC from './Table';

// Constants
import { PRODUCT_IDS } from 'constants/products';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { Tlec } from 'interfaces/businessModels/lec';

const GrantsLEC = () => {
    const { bmSelected, productID } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, Tlec>;

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDialog, setisOpenDialog] = useState(false);

    return (
        <>
            <div className="bm-grants" data-testid="bm_grants_id">
                <GrantsTitle
                    label="page.bm.grants.title"
                    isOpen={isOpen}
                    collapseHandler={() => setIsOpen(!isOpen)}
                    openDialog={() => {
                        setisOpenDialog(true);
                        if ([PRODUCT_IDS.HP].includes(productID)) {
                            // @ts-ignore
                            window._paq.push(['trackEvent', 'Business Models', 'Click', 'Scheme Eligibility']);
                        }
                    }}
                />
                <Collapse in={isOpen}>
                    <GrantsTableLEC />
                    {bmSelected?.values?.grants?.totalGrants?.prc <= 100 && <GrantsKpisLEC />}
                </Collapse>
            </div>
            {isOpenDialog && <GrantsDialog isOpen={isOpenDialog} onCloseHandler={() => setisOpenDialog(false)} />}
        </>
    );
};

export default GrantsLEC;
