import { IconButton } from '@save2compete/efz-design-system';
import PropTypes from 'prop-types';

const CookieHeader = ({ title, close }) => {
    return (
        <div className="cookie-consent-header">
            <IconButton
                variant="tertiary"
                icon="xMarkExit"
                size="bg"
                className="close-cookie"
                onClick={() => close()}
                dataTestId="close-cookie"
            />

            <span>{title}</span>
        </div>
    );
};

CookieHeader.propTypes = {
    close: PropTypes.func.isRequired,
};

export default CookieHeader;
