import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { ReactComponent as WarningBlueIcon } from 'assets/images/icons/svg/pap-warning-blue.svg';
import { ReactComponent as ArrowDown } from 'assets/images/products/spv-pro/svg/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'assets/images/products/spv-pro/svg/arrow-up.svg';
import ErrorMessage from 'components/errors/ErrorMessage';
import CustomUnit from 'components/util/CustomUnit';
import IntlMessages from 'components/util/IntlMessages';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { UserContext } from 'contexts/userContext';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { getCompanyProfileIds } from 'services/user';
import { generateNumberArray, intlMessages, isDefined } from 'services/util/auxiliaryUtils';
import validate from 'services/util/validate';

function getNextHighestIndex(arr, number) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] > number) {
            return arr[i];
        }
    }
}

function getLowerHighestIndex(arr, number) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] >= number) {
            return arr[i - 1];
        }
    }
}

const FinanceCustomizationLECDetailed = ({ showOfferSummary }) => {
    const {
        bmState,
        bmState: { loadings, elements },
    } = useContext(OfferEditionContext);

    const { companyProfileId } = useContext(UserContext);

    const financing_customization = bmState?.businessModelSelected?.financing_customization ?? null;
    const monthlyPaymentsOptions = elements?.monthlyPayments?.options?.options;
    const canEditPaymentMonthly = financing_customization?.edit_monthly_payments_number ?? true;

    const MAX_ANNUAL_FEE = Number(elements?.annualUpdateMonthlyFee?.options?.options?.[1]) * 100 ?? 0;
    const defaultAnnualFeeDefaults = generateNumberArray(
        Number(elements?.annualUpdateMonthlyFee?.options.options[0]) * 100,
        Number(elements.annualUpdateMonthlyFee?.options.options[1]) * 100,
        Number(elements.annualUpdateMonthlyFee?.options.step) * 100
    );

    const MAX_ITC_RATE = Number(elements?.itcRate?.options?.options?.[1]) * 100 ?? 0;
    const defaultITCRateDefaults = generateNumberArray(
        Number(elements?.itcRate?.options.options[0]) * 100,
        Number(elements.itcRate?.options.options[1]) * 100,
        Number(elements.itcRate?.options.step) * 100
    );

    const {
        control,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext();

    const watchAnnualFee = watch('annualEvoFee');
    const watchITCRate = watch('itcRate');

    const addAnnualEvo = () => {
        const actualFee = Number(watchAnnualFee);
        const next = getNextHighestIndex(defaultAnnualFeeDefaults, actualFee);
        if (!isDefined(next) || next > MAX_ANNUAL_FEE) return;
        setValue('annualEvoFee', Number(next));
    };

    const decreaseAnnualEvo = () => {
        const actualFee = Number(watchAnnualFee);
        const next = getLowerHighestIndex(defaultAnnualFeeDefaults, actualFee);
        if (next < 0) return;
        setValue('annualEvoFee', Number(next));
    };

    const disableAnnualFeeConditions =
        [getCompanyProfileIds().EDP_IT].includes(companyProfileId) && watchAnnualFee === 0 && loadings.general;

    const addITCRate = () => {
        const actualITCRate = Number(watchITCRate);
        const next = getNextHighestIndex(defaultITCRateDefaults, actualITCRate);
        if (!isDefined(next) || next > MAX_ITC_RATE) return;
        setValue('itcRate', Number(next));
    };

    const decreaseITCRate = () => {
        const actualITCRate = Number(watchITCRate);
        const next = getLowerHighestIndex(defaultITCRateDefaults, actualITCRate);
        if (next < 0) return;
        setValue('itcRate', Number(next));
    };

    return (
        <>
            <section className={`${showOfferSummary ? 'bm-detailed-offer-summary-finance' : ''} w-100`}>
                {/* show_monthly_payments_number */}
                {isDefined(elements.monthlyPayments) ?
                    <div className="input-container">
                        <label>
                            <IntlMessages id={'page.businessModels.editOffer.numberOfInstallments'} />
                        </label>
                        <Controller
                            name="monthly_payment_option_id"
                            control={control}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            render={({ field: { ref, ...field } }) => (
                                <Select
                                    {...field}
                                    inputProps={{ className: 'd-flex align-items-center' }}
                                    disabled={loadings.general || !canEditPaymentMonthly}
                                >
                                    <MenuItem value={''} disabled={true}>
                                        <IntlMessages id={'label.selectOption'} />
                                    </MenuItem>
                                    {monthlyPaymentsOptions?.map((month, index) => {
                                        return (
                                            <MenuItem key={`monthly_payment_options_${index}`} value={month}>
                                                <IntlMessages
                                                    id={`label.n${elements?.monthlyPayments?.options?.unity === 'years' ? 'Years' : 'Months'}`}
                                                    values={{
                                                        number: month / (elements?.monthlyPayments?.options?.unity === 'years' ? 12 : 1),
                                                    }}
                                                />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        />
                    </div>
                :   <></>}

                {/* show_interest_rate */}
                {isDefined(elements.annualUpdateMonthlyFee) ?
                    <div className="input-container">
                        <label>
                            <IntlMessages id={'page.businessModels.editOffer.annualEvoFee'} />
                        </label>
                        <div className="position-relative">
                            <Controller
                                name="annualEvoFee"
                                control={control}
                                rules={validate(['required', 'number', 'max'], { max: MAX_ANNUAL_FEE })}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ field, fieldState }) => {
                                    return (
                                        <NumberFormat
                                            {...field}
                                            customInput={OutlinedInput}
                                            disabled={[getCompanyProfileIds().EDP_IT].includes(companyProfileId) && watchAnnualFee === 0}
                                            placeholder={intlMessages('label.insertValue')}
                                            allowNegative={false}
                                            decimalScale={2}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {/* @ts-ignore */}
                                                    {<CustomUnit unit={elements?.annualUpdateMonthlyFee?.options?.unity} />}
                                                    <Stack
                                                        className={`input-container-decimal-buttons${disableAnnualFeeConditions ? '-disabled' : ''}`}
                                                    >
                                                        <IconButton disabled={disableAnnualFeeConditions} onClick={addAnnualEvo}>
                                                            <ArrowUp height={6} width={10} />
                                                        </IconButton>
                                                        <IconButton disabled={disableAnnualFeeConditions} onClick={decreaseAnnualEvo}>
                                                            <ArrowDown height={6} width={10} />
                                                        </IconButton>
                                                    </Stack>
                                                </InputAdornment>
                                            }
                                            isAllowed={(values) => {
                                                if (values?.floatValue && values?.floatValue > MAX_ANNUAL_FEE) {
                                                    return false;
                                                }
                                                return true;
                                            }}
                                            onValueChange={(values) => {
                                                field.onChange(values.floatValue);
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="input-container-info">
                                <WarningBlueIcon height={15} width={15} />
                                <IntlMessages id="yup.dynamic.valuesBetween" values={{ max: MAX_ANNUAL_FEE, min: 0 }} />
                            </div>
                            {/* @ts-ignore */}
                            {!!errors['annualEvoFee'] && <ErrorMessage error={errors['annualEvoFee']} values={{ max: MAX_ANNUAL_FEE }} />}
                        </div>
                    </div>
                :   <></>}

                {/* boolean_rec_ownership */}
                {isDefined(elements.recOwnership) ?
                    <div className="input-container">
                        <label>
                            <IntlMessages id={'page.businessModels.editOffer.recOwnership'} />
                        </label>
                        <div className="position-relative">
                            <Controller
                                name="recOwnership"
                                control={control}
                                //rules={validate(['required']}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ field, fieldState }) => {
                                    return (
                                        <Switch
                                            {...field}
                                            placeholder={intlMessages('label.insertValue')}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    );
                                }}
                            />
                            {/* @ts-ignore */}
                            {!!errors['recOwnership'] && <ErrorMessage error={errors['recOwnership']} />}
                        </div>
                    </div>
                :   <></>}

                {/* boolean_ownership_transfer */}
                {isDefined(elements.ownershipTransfer) ?
                    <div className="input-container">
                        <label>
                            <IntlMessages id={'page.businessModels.editOffer.ownershipTransfer'} />
                        </label>
                        <div className="position-relative">
                            <Controller
                                name="ownershipTransfer"
                                control={control}
                                //rules={validate(['required']}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ field, fieldState }) => {
                                    return (
                                        <Switch
                                            {...field}
                                            placeholder={intlMessages('label.insertValue')}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    );
                                }}
                            />
                            {/* @ts-ignore */}
                            {!!errors['ownershipTransfer'] && <ErrorMessage error={errors['ownershipTransfer']} />}
                        </div>
                    </div>
                :   <></>}

                {/* itc_rate_prc */}
                {isDefined(elements.itcRate) ?
                    <div className="input-container">
                        <label>
                            <IntlMessages id={'page.businessModels.editOffer.itcRate'} />
                        </label>
                        <div className="position-relative">
                            <Controller
                                name="itcRate"
                                control={control}
                                rules={validate(['required', 'number', 'max'], { max: MAX_ITC_RATE })}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ field, fieldState }) => {
                                    return (
                                        <NumberFormat
                                            {...field}
                                            customInput={OutlinedInput}
                                            placeholder={intlMessages('label.insertValue')}
                                            allowNegative={false}
                                            decimalScale={2}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {/* @ts-ignore */}
                                                    {<CustomUnit unit={elements?.itcRate?.options?.unity} />}
                                                    <Stack
                                                        className={`input-container-decimal-buttons${disableAnnualFeeConditions ? '-disabled' : ''}`}
                                                    >
                                                        <IconButton disabled={disableAnnualFeeConditions} onClick={addITCRate}>
                                                            <ArrowUp height={6} width={10} />
                                                        </IconButton>
                                                        <IconButton disabled={disableAnnualFeeConditions} onClick={decreaseITCRate}>
                                                            <ArrowDown height={6} width={10} />
                                                        </IconButton>
                                                    </Stack>
                                                </InputAdornment>
                                            }
                                            isAllowed={(values) => {
                                                if (values?.floatValue && values?.floatValue > MAX_ITC_RATE) {
                                                    return false;
                                                }
                                                return true;
                                            }}
                                            onValueChange={(values) => {
                                                field.onChange(values.floatValue);
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="input-container-info">
                                <WarningBlueIcon height={15} width={15} />
                                <IntlMessages id="yup.dynamic.valuesBetween" values={{ max: MAX_ITC_RATE, min: 0 }} />
                            </div>
                            {/* @ts-ignore */}
                            {!!errors['itcRate'] && <ErrorMessage error={errors['itcRate']} values={{ max: MAX_ITC_RATE }} />}
                        </div>
                    </div>
                :   <></>}
            </section>
        </>
    );
};

export default FinanceCustomizationLECDetailed;
