/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @see react-intl docs: https://github.com/formatjs/react-intl
 *
 * @version 20190715
 * @since 20190715 Initial release
 *
 */

import { AVAILABLE_USER_LOCALES } from 'constants/user';
import ptPTLang from './entries/pt-PT';
import esESLang from './entries/es-ES';
import enGBLang from './entries/en-GB';
import enUSLang from './entries/en-US';
import czCZLang from './entries/cz-CZ';
import itITLang from './entries/it-IT';
import plPLLang from './entries/pl-PL';
import ptBRLang from './entries/pt-BR';
import deCHLang from './entries/de-CH';
import enIELang from './entries/en-IE';
import elGRLang from './entries/el-GR';
import enSGLang from './entries/en-SG';
import frCHLang from './entries/fr-CH';
import frFRLang from './entries/fr-FR';
import deDELang from './entries/de-DE';

import { defineMessages } from 'react-intl';

const AppLocale = {
    [AVAILABLE_USER_LOCALES.ptPT]: ptPTLang,
    [AVAILABLE_USER_LOCALES.esES]: esESLang,
    [AVAILABLE_USER_LOCALES.enGB]: enGBLang,
    [AVAILABLE_USER_LOCALES.czCZ]: czCZLang,
    [AVAILABLE_USER_LOCALES.itIT]: itITLang,
    [AVAILABLE_USER_LOCALES.plPL]: plPLLang,
    [AVAILABLE_USER_LOCALES.ptBR]: ptBRLang,
    [AVAILABLE_USER_LOCALES.deCH]: deCHLang,
    [AVAILABLE_USER_LOCALES.enIE]: enIELang,
    [AVAILABLE_USER_LOCALES.elGR]: elGRLang,
    [AVAILABLE_USER_LOCALES.enSG]: enSGLang,
    [AVAILABLE_USER_LOCALES.frCH]: frCHLang,
    [AVAILABLE_USER_LOCALES.deDE]: deDELang,
    [AVAILABLE_USER_LOCALES.enUS]: enUSLang,
    [AVAILABLE_USER_LOCALES.frFR]: frFRLang,
};

defineMessages(AppLocale[AVAILABLE_USER_LOCALES.ptPT].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.esES].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.enGB].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.czCZ].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.itIT].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.plPL].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.plPL].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.ptBR].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.enIE].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.elGR].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.enSG].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.frCH].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.deDE].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.enUS].data);
defineMessages(AppLocale[AVAILABLE_USER_LOCALES.frFR].data);

export default AppLocale;
