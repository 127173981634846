import { USERS_TYPE_ID } from 'constants/user';
import { PRODUCT_IDS } from 'constants/products';
import { getCompanyProfileIds } from 'services/user';

export const INPUT_NAMES = {
    ENTRANCE_TYPE_ID: 'entrance_type_id',
    INSTALL_ON_COMPANY_CPE: 'installation_location_option_id',
    HAS_COSTS_BEARING: 'has_costs_bearing',
    HAS_USERS_MANAGEMENT: 'has_users_management',
    HAS_ONLINE_CHARGER: 'has_online_charger',
    MANAGEMENT_OPTION_ID: 'management_option_id',
    DISTANCE_TO_POWER_POLE: 'distance_to_power_pole',
    DISTANCE_TO_POWER_POLE_OPT: 'distance_to_power_pole_opt',
    PLACE_OF_INSTALLATION_ID: 'place_of_installation_id',
    APPLIANCE_TYPE_ID: 'appliance_type_id',
    IS_QUICK: 'is_quick',
    OCPP_FUTURE: 'ocpp_future',
    POWER_CONFIG_OPTIONS: 'power_config_options',
    NUMBER_OF_CHARGERS_OPTIONS: 'number_of_chargers_options',
    PLUG_DISPLAY_OPTIONS: 'plug_display_options',
    HAS_BUILD_STRUCTURE: 'has_build_structure',
    HAS_PARKING_PAINT: 'has_parking_paint',

    POWER_CONFIG: 'power_config',
    NUMBER_OF_CHARGERS: 'number_of_chargers',
    PLUG_DISPLAY: 'plug_display',

    HAS_CABLE: 'has_cable',
    LOAD_BALANCE: 'load_balance',

    // Partnership
    FACILITY_TYPE_ID: 'facility_type_id',
    IS_PRIVATE_DOMAIN: 'is_private_domain',
    HAS_MIN_PARKING_SPACES: 'has_min_parking_spaces',
    HAS_MIN_OPEN_SCHEDULE: 'has_min_open_schedule',
    NUMBER_OF_ROOMS_GREATER_THAN_20: 'number_of_rooms_greater_than_20',
    HAS_MIN_SEATED_POSITIONS: 'has_min_seated_positions',
    NUMBER_OF_ROOMS_BETWEEN_20_AND_100: 'number_of_rooms_between_20_and_100',
    NUMBER_OF_ROOMS_GREATER_THAN_100: 'number_of_rooms_greater_than_100',
    WANTS_PARTNERSHIP: 'wants_partnership',
    SHARING_MODEL_ID: 'sharing_model_id',

    // Chargers (new flow)
    INSTALL_CHARGER_POINTS: 'install_charger_points',
    CHARGER_POINTS_NUMBER: 'charger_points_number',
    CPE_DETAILS_FILE: 'cpe_details_file',
};

export const ME_INSTALL_ON_COMPANY_CPE_OPTIONS = {
    COMPANY_CPE: 1,
    OTHER_CPE: 2,
};

export const ME_ENTRANCE_TYPE = {
    PUBLIC: 1,
    PRIVATE: 2,
};

export const ME_MANAGEMENT_OPTION_ID = {
    EVCHARGE: 1,
    MOBIE: 2,
    MOVEON: 3,
    MOVEON_LITE: 4,
    SMART: 5,
    NO_MANAGEMENT: 99,
};

const FORM_QUESTIONS_NEW_CHARGER_DEFAULT = {
    [INPUT_NAMES.DISTANCE_TO_POWER_POLE]: {
        name: INPUT_NAMES.DISTANCE_TO_POWER_POLE,
        label: 'page.evc.question.distance_to_power_pole',
        tooltip: null,
        disabled: false,
        options: {
            min: 0,
            max: 100,
            step: 5,
        },
        visible: true,
        validation: {
            required: 'yup.message.required',
        },
    },
    [INPUT_NAMES.DISTANCE_TO_POWER_POLE_OPT]: {
        name: INPUT_NAMES.DISTANCE_TO_POWER_POLE_OPT,
        label: 'page.evc.question.distance_to_power_pole_opt',
        tooltip: null,
        disabled: false,
        answers: [
            {
                label: 'page.evc.question.distance_to_power_pole_opt.option_1',
                value: 1,
            },
            {
                label: 'page.evc.question.distance_to_power_pole_opt.option_2',
                value: 2,
            },
        ],
        visible: true,
        validation: {
            required: 'yup.message.required',
        },
    },
    [INPUT_NAMES.PLACE_OF_INSTALLATION_ID]: {
        name: INPUT_NAMES.PLACE_OF_INSTALLATION_ID,
        label: 'page.evc.question.place_of_installation_id',
        tooltip: null,
        disabled: false,
        answers: [
            {
                label: 'page.evc.question.place_of_installation_id.option_1',
                value: 1,
            },
            {
                label: 'page.evc.question.place_of_installation_id.option_2',
                value: 2,
            },
        ],
        options: null,
        visible: true,
        validation: {
            required: 'yup.message.required',
        },
    },
    [INPUT_NAMES.APPLIANCE_TYPE_ID]: {
        name: INPUT_NAMES.APPLIANCE_TYPE_ID,
        label: 'page.evc.question.appliance_type_id',
        tooltip: null,
        disabled: false,
        answers: [
            {
                label: 'page.evc.question.appliance_type_id.option_2',
                value: 2,
            },
            {
                label: 'page.evc.question.appliance_type_id.option_1',
                value: 1,
            },
        ],
        options: null,
        visible: true,
        validation: {
            required: 'yup.message.required',
        },
    },
    [INPUT_NAMES.IS_QUICK]: {
        name: INPUT_NAMES.IS_QUICK,
        label: 'page.evc.question.is_quick',
        tooltip: null,
        disabled: false,
        answers: [
            {
                label: 'page.evc.question.is_quick.option_1',
                value: false,
            },
            {
                label: 'page.evc.question.is_quick.option_2',
                value: true,
                allowDisable: true,
            },
        ],
        options: null,
        visible: true,
        validation: {
            required: 'yup.message.required',
        },
    },
    [INPUT_NAMES.OCPP_FUTURE]: {
        name: INPUT_NAMES.OCPP_FUTURE,
        label: 'page.evc.question.ocpp_future',
        tooltip: null,
        disabled: false,
        answers: [
            { value: true, label: 'label.yes' },
            { value: false, label: 'label.no', allowDisable: true },
        ],
        options: null,
        visible: true,
        validation: {
            required: 'yup.message.required',
        },
    },
};

export const FORM_QUESTIONS_NEW_CHARGER = {
    // * EDP PT * \\
    [getCompanyProfileIds().EDP_PT]: { ...FORM_QUESTIONS_NEW_CHARGER_DEFAULT },
    // * DEMOCLIENT * \\
    [getCompanyProfileIds().EFZ]: { ...FORM_QUESTIONS_NEW_CHARGER_DEFAULT },
    // * EDP ES * \\
    [getCompanyProfileIds().EDP_ES]: {
        [INPUT_NAMES.DISTANCE_TO_POWER_POLE]: {
            name: INPUT_NAMES.DISTANCE_TO_POWER_POLE,
            label: 'page.evc.question.distance_to_power_pole',
            tooltip: null,
            disabled: false,
            options: {
                min: 10,
                max: 50,
                step: 5,
            },
            visible: false,
            validation: {
                required: 'yup.message.required',
            },
        },
        [INPUT_NAMES.IS_QUICK]: {
            name: INPUT_NAMES.IS_QUICK,
            label: 'page.evc.question.is_quick',
            tooltip: null,
            disabled: false,
            answers: [
                {
                    label: 'page.evc.question.is_quick.option_3',
                    value: false,
                },
                {
                    label: 'page.evc.question.is_quick.option_4',
                    value: true,
                    allowDisable: true,
                },
            ],
            options: null,
            visible: true,
            validation: {
                required: 'yup.message.required',
            },
            allowDisableQuickOption: true,
        },
        [INPUT_NAMES.PLACE_OF_INSTALLATION_ID]: {
            name: INPUT_NAMES.PLACE_OF_INSTALLATION_ID,
            label: 'page.evc.question.place_of_installation_id',
            tooltip: null,
            disabled: false,
            answers: [
                {
                    label: 'page.evc.question.place_of_installation_id.option_1',
                    value: 1,
                },
                {
                    label: 'page.evc.question.place_of_installation_id.option_2',
                    value: 2,
                },
            ],
            options: null,
            visible: true,
            validation: {
                required: 'yup.message.required',
            },
        },
    },
    // * EDP BR * \\
    [getCompanyProfileIds().EDP_BR]: {
        ...FORM_QUESTIONS_NEW_CHARGER_DEFAULT,
        [INPUT_NAMES.OCPP_FUTURE]: {
            disabled: true,
            visible: false,
        },
        [INPUT_NAMES.HAS_BUILD_STRUCTURE]: {
            name: INPUT_NAMES.HAS_BUILD_STRUCTURE,
            label: 'page.evc.question.has_build_structure',
            tooltip: null,
            disabled: false,
            answers: [
                {
                    label: 'label.yes',
                    value: true,
                },
                {
                    label: 'label.no',
                    value: false,
                },
            ],
            options: null,
            visible: true,
            validation: {
                required: 'yup.message.required',
            },
        },
        [INPUT_NAMES.HAS_PARKING_PAINT]: {
            name: INPUT_NAMES.HAS_PARKING_PAINT,
            label: 'page.evc.question.has_parking_paint',
            tooltip: null,
            disabled: false,
            answers: [
                {
                    label: 'label.yes',
                    value: true,
                },
                {
                    label: 'label.no',
                    value: false,
                },
            ],
            options: null,
            visible: true,
            validation: {
                required: 'yup.message.required',
            },
        },
        [INPUT_NAMES.IS_QUICK]: {
            name: INPUT_NAMES.IS_QUICK,
            label: 'page.evc.question.is_quick',
            tooltip: null,
            disabled: false,
            answers: [
                {
                    label: 'page.evc.question.is_quick.option_1_EDP_BR',
                    value: false,
                },
                {
                    label: 'page.evc.question.is_quick.option_2_EDP_BR',
                    value: true,
                },
            ],
            options: null,
            visible: true,
            validation: {
                required: 'yup.message.required',
            },
        },
    },
    // * EDP PT && EVCSB * \\
    [getCompanyProfileIds().EDP_PT && PRODUCT_IDS.EVCSB]: {
        [INPUT_NAMES.DISTANCE_TO_POWER_POLE]: {
            name: INPUT_NAMES.DISTANCE_TO_POWER_POLE,
            label: 'page.evc.question.distance_to_power_pole',
            tooltip: null,
            disabled: false,
            options: {
                min: 10,
                max: 100,
                step: null,
                marks: [
                    { value: 10 },
                    { value: 15 },
                    { value: 20 },
                    { value: 25 },
                    { value: 30 },
                    { value: 35 },
                    { value: 40 },
                    { value: 45 },
                    { value: 50 },
                    { value: 60 },
                    { value: 70 },
                    { value: 80 },
                    { value: 90 },
                    { value: 100 },
                ],
            },
            visible: true,
            validation: {
                required: 'yup.message.required',
            },
        },
        [INPUT_NAMES.PLACE_OF_INSTALLATION_ID]: {
            name: INPUT_NAMES.PLACE_OF_INSTALLATION_ID,
            label: 'page.evc.question.place_of_installation_id',
            tooltip: null,
            disabled: false,
            answers: [
                {
                    label: 'page.evc.question.place_of_installation_id.option_1',
                    value: 1,
                },
                {
                    label: 'page.evc.question.place_of_installation_id.option_2',
                    value: 2,
                },
            ],
            options: null,
            visible: false,
            validation: {
                required: 'yup.message.required',
            },
        },
        [INPUT_NAMES.OCPP_FUTURE]: {
            name: INPUT_NAMES.OCPP_FUTURE,
            label: 'page.evc.question.ocpp_future',
            tooltip: null,
            disabled: false,
            answers: [
                { value: true, label: 'label.yes' },
                { value: false, label: 'label.no' },
            ],
            options: null,
            visible: true,
            validation: {
                required: 'yup.message.required',
            },
        },
        [INPUT_NAMES.HAS_CABLE]: {
            name: INPUT_NAMES.HAS_CABLE,
            label: 'page.evc.question.has_cable',
            tooltip: null,
            disabled: false,
            answers: [
                {
                    label: 'page.evc.question.has_cable.option_1',
                    value: true,
                },
                {
                    label: 'page.evc.question.has_cable.option_2',
                    value: false,
                },
            ],
            options: null,
            visible: true,
            validation: {
                required: 'yup.message.required',
            },
        },
        [INPUT_NAMES.LOAD_BALANCE]: {
            name: INPUT_NAMES.LOAD_BALANCE,
            label: 'page.evc.question.load_balance',
            tooltip: null,
            disabled: false,
            answers: [
                { value: true, label: 'label.yes' },
                { value: false, label: 'label.no' },
            ],
            options: null,
            visible: false,
            validation: {
                required: 'yup.message.required',
            },
        },
    },
};

export const PARTNERSHIP_FACILITY_TYPE_IDS = {
    HOTEL: 1,
    RESTAURANT: 2,
    OTHERESTABLISHMENTS: 3,
};

const FORM_QUESTIONS_PARTNERSHIP_DEFAULT = {
    [INPUT_NAMES.FACILITY_TYPE_ID]: {
        name: INPUT_NAMES.FACILITY_TYPE_ID,
        label: 'page.evc.question.facility_type_id',
        tooltip: null,
        disabled: false,
        answers: [
            { id: PARTNERSHIP_FACILITY_TYPE_IDS.HOTEL, description: 'label.hotel' },
            { id: PARTNERSHIP_FACILITY_TYPE_IDS.RESTAURANT, description: 'label.restaurant' },
            {
                id: PARTNERSHIP_FACILITY_TYPE_IDS.OTHERESTABLISHMENTS,
                description: 'label.otherEstablishments',
            },
        ],
        visible: true,
        validation: {
            required: 'yup.message.required',
        },
    },
    [INPUT_NAMES.IS_PRIVATE_DOMAIN]: {
        name: INPUT_NAMES.IS_PRIVATE_DOMAIN,
        label: 'page.evc.question.is_private_domain_only',
        tooltip: null,
        disabled: false,
        answers: null,
        visible: [
            PARTNERSHIP_FACILITY_TYPE_IDS.HOTEL,
            PARTNERSHIP_FACILITY_TYPE_IDS.RESTAURANT,
            PARTNERSHIP_FACILITY_TYPE_IDS.OTHERESTABLISHMENTS,
        ],
    },
    [INPUT_NAMES.HAS_MIN_PARKING_SPACES]: {
        name: INPUT_NAMES.HAS_MIN_PARKING_SPACES,
        label: 'page.evc.question.has_min_parking_spaces',
        tooltip: null,
        disabled: false,
        answers: null,
        visible: [PARTNERSHIP_FACILITY_TYPE_IDS.HOTEL, PARTNERSHIP_FACILITY_TYPE_IDS.RESTAURANT],
    },
    [INPUT_NAMES.HAS_MIN_OPEN_SCHEDULE]: {
        name: INPUT_NAMES.HAS_MIN_OPEN_SCHEDULE,
        label: 'page.evc.question.has_min_open_schedule',
        tooltip: null,
        disabled: false,
        answers: null,
        visible: [
            PARTNERSHIP_FACILITY_TYPE_IDS.HOTEL,
            PARTNERSHIP_FACILITY_TYPE_IDS.RESTAURANT,
            PARTNERSHIP_FACILITY_TYPE_IDS.OTHERESTABLISHMENTS,
        ],
    },
    [INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_20]: {
        name: INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_20,
        label: 'page.evc.question.number_of_rooms_greater_than_20',
        tooltip: null,
        disabled: false,
        answers: [
            {
                id: 1,
                description: 'page.evc.question.number_of_rooms_between_20_and_100',
                name: INPUT_NAMES.NUMBER_OF_ROOMS_BETWEEN_20_AND_100,
            },
            {
                id: 2,
                description: 'page.evc.question.number_of_rooms_greater_than_100',
                name: INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_100,
            },
        ],
        visible: [PARTNERSHIP_FACILITY_TYPE_IDS.HOTEL],
    },
    [INPUT_NAMES.HAS_MIN_SEATED_POSITIONS]: {
        name: INPUT_NAMES.HAS_MIN_SEATED_POSITIONS,
        label: 'page.evc.question.has_min_seated_positions',
        tooltip: null,
        disabled: false,
        answers: null,
        visible: [PARTNERSHIP_FACILITY_TYPE_IDS.RESTAURANT],
    },
    [INPUT_NAMES.WANTS_PARTNERSHIP]: {
        name: INPUT_NAMES.WANTS_PARTNERSHIP,
        label: 'page.evc.question.wants_partnership',
        tooltip: 'page.evc.question.wants_partnership.info',
        disabled: false,
        answers: [
            { value: true, label: 'label.yes' },
            { value: false, label: 'page.evc.question.wants_partnership.no' },
        ],
        visible: false,
    },
    [INPUT_NAMES.SHARING_MODEL_ID]: {
        name: INPUT_NAMES.SHARING_MODEL_ID,
        label: 'page.evc.question.sharing_model_id',
        tooltip: 'page.evc.question.sharing_model_id.info',
        disabled: false,
        answers: [
            { value: 1, label: 'page.evc.question.sharing_model_id.Id1' },
            { value: 2, label: 'page.evc.question.sharing_model_id.Id2' },
        ],
        visible: false,
    },
};

const FORM_QUESTIONS_PARTNERSHIP_ESP = {
    [INPUT_NAMES.FACILITY_TYPE_ID]: {
        name: INPUT_NAMES.FACILITY_TYPE_ID,
        label: 'page.evc.question.facility_type_id',
        tooltip: null,
        disabled: false,
        answers: [
            { id: PARTNERSHIP_FACILITY_TYPE_IDS.HOTEL, description: 'label.hotel' },
            { id: PARTNERSHIP_FACILITY_TYPE_IDS.RESTAURANT, description: 'label.restaurantAndClubes' },
            { id: PARTNERSHIP_FACILITY_TYPE_IDS.OTHERESTABLISHMENTS, description: 'label.gasStation' },
        ],
        visible: true,
        validation: {
            required: 'yup.message.required',
        },
    },
    [INPUT_NAMES.IS_PRIVATE_DOMAIN]: {
        name: INPUT_NAMES.IS_PRIVATE_DOMAIN,
        label: 'page.evc.question.is_private_domain_only',
        labelOtherEstablishments: 'page.evc.question.is_private_domain',
        tooltip: null,
        disabled: false,
        answers: null,
        visible: [
            PARTNERSHIP_FACILITY_TYPE_IDS.HOTEL,
            PARTNERSHIP_FACILITY_TYPE_IDS.RESTAURANT,
            PARTNERSHIP_FACILITY_TYPE_IDS.OTHERESTABLISHMENTS,
        ],
    },
    [INPUT_NAMES.HAS_MIN_PARKING_SPACES]: {
        name: INPUT_NAMES.HAS_MIN_PARKING_SPACES,
        label: 'page.evc.question.has_min_parking_spaces',
        tooltip: null,
        disabled: false,
        answers: null,
        visible: [PARTNERSHIP_FACILITY_TYPE_IDS.HOTEL],
    },
    [INPUT_NAMES.HAS_MIN_OPEN_SCHEDULE]: {
        name: INPUT_NAMES.HAS_MIN_OPEN_SCHEDULE,
        label: 'page.evc.question.has_min_open_schedule',
        tooltip: null,
        disabled: false,
        answers: null,
        visible: [PARTNERSHIP_FACILITY_TYPE_IDS.RESTAURANT, PARTNERSHIP_FACILITY_TYPE_IDS.OTHERESTABLISHMENTS],
    },
    [INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_20]: {
        name: INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_20,
        label: 'page.evc.question.number_of_rooms_greater_than_20',
        tooltip: null,
        disabled: false,
        answers: [
            {
                id: 1,
                description: 'page.evc.question.number_of_rooms_between_20_and_100',
                name: INPUT_NAMES.NUMBER_OF_ROOMS_BETWEEN_20_AND_100,
            },
            {
                id: 2,
                description: 'page.evc.question.number_of_rooms_greater_than_100',
                name: INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_100,
            },
        ],
        visible: [],
    },
    [INPUT_NAMES.HAS_MIN_SEATED_POSITIONS]: {
        name: INPUT_NAMES.HAS_MIN_SEATED_POSITIONS,
        label: 'page.evc.question.has_min_seated_positions',
        tooltip: null,
        disabled: false,
        answers: null,
        visible: [],
    },
    [INPUT_NAMES.WANTS_PARTNERSHIP]: {
        name: INPUT_NAMES.WANTS_PARTNERSHIP,
        label: 'page.evc.question.wants_partnership',
        tooltip: 'page.evc.question.wants_partnership.infoEs',
        disabled: false,
        answers: [
            { value: true, label: 'label.yes' },
            { value: false, label: 'page.evc.question.wants_partnership.no' },
        ],
        visible: false,
    },
    [INPUT_NAMES.SHARING_MODEL_ID]: {
        name: INPUT_NAMES.SHARING_MODEL_ID,
        label: 'page.evc.question.sharing_model_id',
        tooltip: 'page.evc.question.sharing_model_id.info',
        disabled: false,
        answers: [
            { value: 1, label: 'page.evc.question.sharing_model_id.Id1' },
            { value: 2, label: 'page.evc.question.sharing_model_id.Id2' },
        ],
        visible: false,
    },
};

export const FORM_QUESTIONS_PARTNERSHIP = {
    // * EDP PT * \\
    [getCompanyProfileIds().EDP_PT]: { ...FORM_QUESTIONS_PARTNERSHIP_DEFAULT },
    [getCompanyProfileIds().EFZ]: { ...FORM_QUESTIONS_PARTNERSHIP_DEFAULT },
    // * EDP ES * \\
    [getCompanyProfileIds().EDP_ES]: {
        ...FORM_QUESTIONS_PARTNERSHIP_ESP,
    },
};

const MANAGEMENT_OPTIONS_ID_DEFAULT = [
    {
        id: ME_MANAGEMENT_OPTION_ID.EVCHARGE,
        entranceTypeIDs: [ME_ENTRANCE_TYPE.PRIVATE],
        title: 'page.evc.managementOption.evCharge',
        bulletPoints: [
            'page.evc.managementOption.evCharge.bullet1',
            'page.evc.managementOption.evCharge.bullet2',
            'page.evc.managementOption.evCharge.bullet3',
        ],
        hideEditPlatformButton: true,
        //selectedOptionDescription: "page.evc.selectedOption.evCharge.description"
    },
    {
        id: ME_MANAGEMENT_OPTION_ID.MOBIE,
        entranceTypeIDs: [ME_ENTRANCE_TYPE.PUBLIC, ME_ENTRANCE_TYPE.PRIVATE],
        title: 'page.evc.managementOption.mobie',
        bulletPoints: [
            'page.evc.managementOption.mobie.bullet1',
            'page.evc.managementOption.mobie.bullet2',
            'page.evc.managementOption.mobie.bullet3',
        ],
        hideEditPlatformButton: true,
        //selectedOptionDescription: "page.evc.selectedOption.mobie.description"
    },
    {
        id: ME_MANAGEMENT_OPTION_ID.NO_MANAGEMENT,
        entranceTypeIDs: [ME_ENTRANCE_TYPE.PRIVATE],
        title: 'page.evc.managementOption.noManagement',
        bulletPoints: ['page.evc.managementOption.noManagement.bullet1', 'page.evc.managementOption.noManagement.bullet2'],
        hideEditPlatformButton: true,
        //selectedOptionDescription: "page.evc.selectedOption.noManagement.description"
    },
];
//Management options details
export const MANAGEMENT_OPTIONS_ID = {
    [getCompanyProfileIds().EDP_PT]: [...MANAGEMENT_OPTIONS_ID_DEFAULT],
    [getCompanyProfileIds().EFZ]: [...MANAGEMENT_OPTIONS_ID_DEFAULT],
    [getCompanyProfileIds().EDP_ES]: [
        {
            id: ME_MANAGEMENT_OPTION_ID.MOVEON,
            entranceTypeIDs: [ME_ENTRANCE_TYPE.PUBLIC, ME_ENTRANCE_TYPE.PRIVATE],
            title: 'page.evc.managementOption.moveOn',
            bulletPoints: [
                'page.evc.managementOption.moveOn.bullet1',
                'page.evc.managementOption.moveOn.bullet2',
                'page.evc.managementOption.moveOn.bullet3',
            ],
            bulletPointsPrivate: [
                'page.evc.managementOption.moveOn.bullet1',
                'page.evc.managementOption.moveOn.bullet2',
                'page.evc.managementOption.moveOn.bullet4',
            ],
            selectedOptionDescription: 'page.evc.selectedOption.moveOn.description',
        },
        {
            id: ME_MANAGEMENT_OPTION_ID.MOVEON_LITE,
            entranceTypeIDs: [ME_ENTRANCE_TYPE.PRIVATE],
            title: 'page.evc.managementOption.moveOnLite',
            bulletPoints: ['page.evc.managementOption.moveOnLite.bullet1', 'page.evc.managementOption.moveOnLite.bullet2'],
            selectedOptionDescription: 'page.evc.selectedOption.moveOnLite.description',
        },
        {
            id: ME_MANAGEMENT_OPTION_ID.NO_MANAGEMENT,
            entranceTypeIDs: [ME_ENTRANCE_TYPE.PRIVATE],
            title: 'page.evc.managementOption.noManagement',
            bulletPoints: ['page.evc.managementOption.noManagementEDPES.bullet1'],
            selectedOptionDescription: 'page.evc.selectedOption.noManagement.description',
        },
    ],
    /** EDP_BR */
    [getCompanyProfileIds().EDP_BR]: [
        {
            id: ME_MANAGEMENT_OPTION_ID.NO_MANAGEMENT,
            entranceTypeIDs: [ME_ENTRANCE_TYPE.PRIVATE],
            title: 'page.evc.managementOption.noManagement',
            bulletPoints: ['page.evc.managementOption.noManagementEDPBR.bullet1'],
            selectedOptionDescription: 'page.evc.selectedOption.noManagement.description',
        },
        {
            id: ME_MANAGEMENT_OPTION_ID.SMART,
            entranceTypeIDs: [ME_ENTRANCE_TYPE.PUBLIC, ME_ENTRANCE_TYPE.PRIVATE],
            title: 'page.evc.managementOption.smart',
            bulletPoints: [
                'page.evc.managementOption.smart.bullet1',
                'page.evc.managementOption.smart.bullet2',
                'page.evc.managementOption.smart.bullet3',
            ],
            selectedOptionDescription: 'page.evc.selectedOption.smart.description',
        },
    ],
    // * EDP PT && EVCSB * \\
    [(getCompanyProfileIds().EDP_PT, PRODUCT_IDS.EVCSB)]: [
        {
            id: ME_MANAGEMENT_OPTION_ID.EVCHARGE,
            entranceTypeIDs: [ME_ENTRANCE_TYPE.PRIVATE],
            title: 'page.evc.managementOption.edp_evcsb_Charge',
            bulletPoints: [
                'page.evc.managementOption.edp_evcsb_Charge.bullet1',
                'page.evc.managementOption.edp_evcsb_Charge.bullet2',
                'page.evc.managementOption.edp_evcsb_Charge.bullet3',
            ],
            hideEditPlatformButton: true,
            //selectedOptionDescription: "page.evc.selectedOption.evCharge.description"
        },
        {
            id: ME_MANAGEMENT_OPTION_ID.NO_MANAGEMENT,
            entranceTypeIDs: [ME_ENTRANCE_TYPE.PRIVATE],
            title: 'page.evc.managementOption.noManagement',
            bulletPoints: [
                'page.evc.managementOption.noManagementEDPPT_evcsb.bullet1',
                'page.evc.managementOption.noManagementEDPPT_evcsb.bullet2',
            ],
            hideEditPlatformButton: true,
            //selectedOptionDescription: "page.evc.selectedOption.noManagement.description"
        },
    ],
    // * EDP PT && NEW_FLOW * \\
    [`(${getCompanyProfileIds().EDP_PT}, ${ME_INSTALL_ON_COMPANY_CPE_OPTIONS.OTHER_CPE})`]: [
        {
            id: ME_MANAGEMENT_OPTION_ID.MOBIE,
            entranceTypeIDs: [ME_ENTRANCE_TYPE.PRIVATE],
            title: 'page.evc.managementOption.dpc',
            bulletPoints: [
                'page.evc.managementOption.dpc.bullet1',
                'page.evc.managementOption.dpc.bullet2',
                'page.evc.managementOption.dpc.bullet3',
            ],
            hideEditPlatformButton: true,
        },
    ],
};

export const SHOW_PRIVATE_MANAGEMENT_RADIO_OPTIONS = {
    [getCompanyProfileIds().EDP_PT]: true,
    [getCompanyProfileIds().EFZ]: true,
    [getCompanyProfileIds().EDP_ES]: false,
    [getCompanyProfileIds().EDP_BR]: false,
};

export const SHOW_PARTNERSHIPFORM = {
    // deprecated QC#1151
    [getCompanyProfileIds().EDP_PT]: {
        usersType: [USERS_TYPE_ID.CHANNEL, USERS_TYPE_ID.MANAGER],
    },
    [getCompanyProfileIds().EFZ]: {
        usersType: [USERS_TYPE_ID.CHANNEL, USERS_TYPE_ID.MANAGER],
    },
    [getCompanyProfileIds().EDP_ES]: {
        usersType: [USERS_TYPE_ID.CHANNEL, USERS_TYPE_ID.MANAGER],
    },
};

export const SHOW_DISTANCE_SLIDER_OUTSIDE_DIALOG = {
    [getCompanyProfileIds().EDP_PT]: false,
    [getCompanyProfileIds().EFZ]: false,
    [getCompanyProfileIds().EDP_ES]: true,
    [getCompanyProfileIds().EDP_BR]: false,
};

export const SEND_AAS_PRICING_IN_BM = {
    [getCompanyProfileIds().EDP_PT]: true,
    [getCompanyProfileIds().EFZ]: true,
    [getCompanyProfileIds().EDP_ES]: true,
    [getCompanyProfileIds().EDP_BR]: true,
};

export const SHOULD_REQUEST_SIZING_SERVICE_PREVIEW = {
    [getCompanyProfileIds().EDP_PT]: true,
    [getCompanyProfileIds().EFZ]: true,
    [getCompanyProfileIds().EDP_ES]: false,
    [getCompanyProfileIds().EDP_BR]: false,
};

export const SEND_CHARGERS_RANGES_IN_BM = {
    [getCompanyProfileIds().EDP_PT]: true,
    [getCompanyProfileIds().EFZ]: true,
    [getCompanyProfileIds().EDP_ES]: false,
    [getCompanyProfileIds().EDP_BR]: false,
};

export const MAX_SELECTED_CHARGERS_NUMBER = {
    [getCompanyProfileIds().EDP_PT]: 3,
    [getCompanyProfileIds().EFZ]: 3,
    [getCompanyProfileIds().EDP_ES]: 4,
    [getCompanyProfileIds().EDP_BR]: 4,
};

export const WARNING_MESSAGES = {
    SHOULD_INCREASE_CONTRACTED_POWER: 'SHOULD_INCREASE_CONTRACTED_POWER',
    REMOVE_ONE_OR_MORE_CHARGERS: 'REMOVE_ONE_OR_MORE_CHARGERS',
    NEED_MORE_FACILITY_INFORMATION: 'NEED_MORE_FACILITY_INFORMATION',
    WITHOUT_POTENTIAL: 'WITHOUT_POTENTIAL',
    REMOVE_ONE_OR_MORE_CHARGERS_TOTAL_POWER: 'REMOVE_ONE_OR_MORE_CHARGERS_TOTAL_POWER',
    REMOVE_ONE_OR_MORE_CHARGERS_CHARGERS_NUMBER: 'REMOVE_ONE_OR_MORE_CHARGERS_CHARGERS_NUMBER',
    PARTNERSHIP_NOT_AVAILABLE: 'PARTNERSHIP_NOT_AVAILABLE',
};

export const BUSINESS_MODEL_TYPE_ID_FOR_SERVICE = {
    [getCompanyProfileIds().EDP_PT]: 54,
    [getCompanyProfileIds().EFZ]: 132,
};

export const SHOULD_REQUEST_EVC_AR_MEASUREMENTS = {
    [getCompanyProfileIds().EFZ]: true,
};

export const NUMBER_OF_ROOMS_OPTS = {
    BETWEEN_20_AND_100: '1',
    GREATER_THAN_100: '2',
};

// * ACTIONS * \\
export const REQUEST_POST_EVC_PRE_SIZING = 'REQUEST_POST_EVC_PRE_SIZING';
export const REQUEST_POST_EVC_PRE_SIZING_SUCCESS = 'REQUEST_POST_EVC_PRE_SIZING_SUCCESS';
export const REQUEST_POST_EVC_PRE_SIZING_FAIL = 'REQUEST_POST_EVC_PRE_SIZING_FAIL';
export const REQUEST_POST_EVC_SIZING_FINANCE = 'REQUEST_POST_EVC_SIZING_FINANCE';
export const REQUEST_POST_EVC_SIZING_FINANCE_SUCCESS = 'REQUEST_POST_EVC_SIZING_FINANCE_SUCCESS';
export const REQUEST_POST_EVC_SIZING_FINANCE_FAIL = 'REQUEST_POST_EVC_SIZING_FINANCE_FAIL';
export const RESET_EVC_CHARGERS_INFO = 'RESET_EVC_CHARGERS_INFO';
