import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// This component will render the button for the more Actions, and will receive its options to dropdown
// actions will be an object with properties {text,icon,click}

const LegalRepresentativesTableOptions = ({ actions }) => {
    const [dropOpen, setDropOpen] = useState(false);

    const toggleDrop = () => {
        setDropOpen(!dropOpen);
    };

    return (
        <div className="d-flex w-100 h-100 justify-content-center align-items-center action-options">
            <Dropdown direction="down" isOpen={dropOpen} toggle={toggleDrop}>
                <DropdownToggle className="actions-dropdown-toggle">
                    <span>
                        <i className="zmdi zmdi-more" />
                    </span>
                </DropdownToggle>
                <DropdownMenu className="drop-menu" end>
                    {actions.map((action, index) => (
                        <DropdownItem onClick={action.click} key={index} className={`dropdown-item${action.disabled ? '-disabled' : ''}`}>
                            <div className="d-flex flex-row justify-content-start align-items-center action-option">
                                <div className="d-flex align-items-center me-2 icon-container">{action.icon}</div>
                                <span className="d-flex align-items-center justify-content-center options-text">
                                    <h5>{action.text}</h5>
                                </span>
                            </div>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

LegalRepresentativesTableOptions.propTypes = {
    actions: PropTypes.array.isRequired,
};

export default memo(LegalRepresentativesTableOptions);
