import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import DetailedKpisPPaDetailed from './DetailedKpis';
import MarginPPaDetailed from './Margin';
import MarginKpisPPaDetailed from './MarginKpis';
import { useContext } from 'react';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';

const NegotiationPPa = () => {
    const { lowNegotiationProfile, negotiationProfile } = useContext(OfferEditionContext);
    return (
        <div className="bm-detailed-negotiation">
            <ProjectSummaryTitle label="page.businessModels.negotiation.title.tariff" />
            <MarginPPaDetailed />
            {negotiationProfile?.mostra_negociacao_default_kpis && <MarginKpisPPaDetailed />}
            {!lowNegotiationProfile && <DetailedKpisPPaDetailed />}
        </div>
    );
};

export default NegotiationPPa;
