import { USERS_TYPE_ID } from 'constants/user';
import { PRODUCT_IDS } from './products';
import { getCompanyProfileIds } from 'services/user';

export const REFORMULATE_PROPOSAL_NAMES = {
    REAL_POWER_INSTALLED_PV: 'installed_power_kwp_pv_ss',
    REAL_POWER_INSTALLED_CFP: 'installed_power_kvar_cfp_ss',
    REAL_POWER_INSTALLED_IE: 'installed_power_kw_el_ss',
    REAL_AC_POWER_INSTALLED: 'installed_power_em_kva_me_ss',
    REAL_DC_POWER_INSTALLED: 'installed_power_em_kw_me_ss',
    TOTAL_POWER_ME: 'total_power_me',
    CABLE_METRES: 'cable_meters',
    REAL_INVERTERS_POWER: 'nominal_power_inverters_kw_pv_ss',
    EQUIPMENT_QUANTITY: 'equipment_quantity_ss',
    BATTERY_QUANTITY: 'battery_quantity_ss',
    CHARGER_QUANTITY: 'charger_quantity_ss',
    PANNEL_QUANTITY: 'pannel_quantity_ss',
    HARMONIC_FILTER: 'harmonic_filter_pfc_ss',
    EMS: 'ems_ss',
    CONCENTRATOR_QUANTITY: 'ems_concentrators_quantity_ss',
    ANNUAL_PRODUCTION: 'annual_estimated_production_kwh_ss',
    ANNUAL_PRODUCTION_MWH: 'annual_estimated_production_mwh_ss',
    NEW_ANNUAL_PRODUCTION_KWH: 'new_annual_production_kWh',
    NEW_INVERTER_POWER: 'new_inverter_power',
    /* FINANCIAL */
    TYPE_OF_COSTS: 'type_of_costs',
    REAL_COST_EQUIPMENT: 'equipment_cost',
    REAL_COST_FACILITY: 'installation_cost',
    TOTAL_COST: 'total_cost',
    ANNUAL_MAINTENANCE_COST: 'annual_maintenance_cost',
    PANEL_COST: 'panel_cost',
    INVERTER_COST: 'inverter_cost',
    BATTERY_COST: 'battery_cost',
    MONITORING_COST: 'monitoring_cost',
    CABLE_TOTAL: 'cable_total',
    HAS_HARMONICS: 'has_harmonics',
    BATTERY_POWER: 'battery_power',

    // REF
    REAL_POWER_INSTALLED_PV_REFORMULATE: 'installed_power_kwp_pv_ss_reformulate',
    REAL_POWER_INSTALLED_CFP_REFORMULATE: 'installed_power_kvar_cfp_ss_reformulate',
    REAL_AC_POWER_INSTALLED_REFORMULATE: 'installed_power_em_kva_me_ss_reformulate',
    REAL_DC_POWER_INSTALLED_REFORMULATE: 'installed_power_em_kw_me_ss_reformulate',
    TOTAL_POWER_ME_REFORMULATE: 'total_power_me_reformulate',
    CABLE_METRES_REFORMULATE: 'cable_meters_reformulate',
    REAL_INVERTERS_POWER_REFORMULATE: 'nominal_power_inverters_kw_pv_ss_reformulate',
    EQUIPMENT_QUANTITY_REFORMULATE: 'equipment_quantity_ss_reformulate',
    BATTERY_QUANTITY_REFORMULATE: 'battery_quantity_ss_reformulate',
    CHARGER_QUANTITY_REFORMULATE: 'charger_quantity_ss_reformulate',
    PANNEL_QUANTITY_REFORMULATE: 'pannel_quantity_ss_reformulate',
    HARMONIC_FILTER_REFORMULATE: 'harmonic_filter_pfc_ss_reformulate',
    EMS_REFORMULATE: 'ems_ss_reformulate',
    CONCENTRATOR_QUANTITY_REFORMULATE: 'ems_concentrators_quantity_ss_reformulate',
    ANNUAL_PRODUCTION_REFORMULATE: 'annual_estimated_production_kwh_ss_reformulate',
    ANNUAL_PRODUCTION_MWH_REFORMULATE: 'annual_estimated_production_mwh_ss_reformulate',
    NEW_ANNUAL_PRODUCTION_KWH_REFORMULATE: 'new_annual_production_kWh_reformulate',
    /* FINANCIAL */
    TYPE_OF_COSTS_REFORMULATE: 'type_of_costs_reformulate',
    REAL_COST_EQUIPMENT_REFORMULATE: 'equipment_cost_reformulate',
    REAL_COST_FACILITY_REFORMULATE: 'installation_cost_reformulate',
    MAINTENANCE_COST_REFORMULATE: 'maintenance_cost_reformulate',
    PANEL_COST_REFORMULATE: 'panel_cost_reformulate',
    INVERTER_COST_REFORMULATE: 'inverter_cost_reformulate',
    BATTERY_COST_REFORMULATE: 'battery_cost_reformulate',
    MONITORING_COST_REFORMULATE: 'monitoring_cost_reformulate',
    _REFORMULATE: '_reformulate',

    // REFORMULATION SUBMIT NAMES
    NEW_COST: 'new_cost',
    EQUIPMENT_COST: 'equipment_cost',
    INSTALATION_COST: 'instalation_cost',
    EXTRA_COST: 'extra_cost',
    PANELS_COST: 'panels_costs',
    // BATTERY_COST: 'battery_cost',
    // TYPE_OF_COSTS: 'type_of_costs',
    INVERTERS_COST: 'inverters_costs',
    // MONITORING_COST: 'monitoring_cost',
    ANNUAL_INVERTERS_WARRANTY_COST: 'annual_inverters_warranty_cost',
    NEW_PEAK_POWER: 'new_peak_power',
};

export const REFORMULATE_PROPOSAL_SECTIONS = {
    GENERAL: 'general',
    SYSTEM_DESIGN: 'system_design',
    COSTS: 'costs',
};

// PT IS THE DEFAULT CONFIGS!!
export const REFORMULATE_PROPOSAL_CONFIGS = {
    [getCompanyProfileIds().EDP_IT]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [REFORMULATE_PROPOSAL_SECTIONS.GENERAL]: {
                    visible: true,
                    order: 1,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.SYSTEM_DESIGN]: {
                    visible: true,
                    order: 2,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.COSTS]: {
                    visible: true,
                    order: 3,
                },
                edit: {
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_PV]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_PV,
                    label: 'page.reformulateProposal.label.peakPower',
                    unit: 'kwp',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_INVERTERS_POWER]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_INVERTERS_POWER,
                    label: 'page.reformulateProposal.label.nominalPower',
                    unit: 'kw',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY,
                    label: 'page.reformulateProposal.label.panelQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EMS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EMS,
                    label: 'page.reformulateProposal.label.ems',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.CONCENTRATOR_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CONCENTRATOR_QUANTITY,
                    label: 'page.reformulateProposal.label.concentratorsQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.ANNUAL_PRODUCTION_MWH]: {
                    name: REFORMULATE_PROPOSAL_NAMES.ANNUAL_PRODUCTION_MWH,
                    label: 'page.reformulateProposal.label.annualProduction',
                    unit: 'mwh',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY,
                    label: 'page.reformulateProposal.label.instalationCost',
                    unit: 'currency',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.ANNUAL_MAINTENANCE_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.ANNUAL_MAINTENANCE_COST,
                    label: 'page.reformulateProposal.label.annualMaintenanceCost',
                    visible: true,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.TYPE_OF_COSTS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.TYPE_OF_COSTS,
                    label: 'page.reformulateProposal.label.typeOfCost',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT,
                    label: 'page.reformulateProposal.label.equipmentsCost',
                    unit: 'currency',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.PANEL_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.PANEL_COST,
                    label: 'page.reformulateProposal.label.panelsCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.INVERTER_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.INVERTER_COST,
                    label: 'page.reformulateProposal.label.invertersCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.BATTERY_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.BATTERY_COST,
                    label: 'page.reformulateProposal.label.batteryCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.MONITORING_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.MONITORING_COST,
                    label: 'page.reformulateProposal.label.monitoringCost',
                    visible: true,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.TOTAL_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.TOTAL_COST,
                    label: 'page.reformulateProposal.label.totalCost',
                    visible: true,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                    name: REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                    name: REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.EXTRA_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EXTRA_COST,
                    label: 'page.reformulateProposal.label.extraCost',
                    validation: {
                        required: 'yup.message.required',
                    },
                    visible: true,
                    unit: 'currency',
                },
                [REFORMULATE_PROPOSAL_NAMES.UPLOAD_FILES]: {
                    name: REFORMULATE_PROPOSAL_NAMES.UPLOAD_FILES,
                    label: 'processUploadPricingDocs.label.uploadFiles',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.CURVE_LOAD]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CURVE_LOAD,
                    label: 'processUploadPricingDocs.label.uploadLoadCurve',
                    subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                    min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                    hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                    downloadLabel: 'processUploadPricingDocs.label.loadCurve',
                    invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                    visible: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                [REFORMULATE_PROPOSAL_SECTIONS.GENERAL]: {
                    visible: true,
                    order: 1,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.COSTS]: {
                    visible: true,
                    order: 2,
                },
                edit: {
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_CFP]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_CFP,
                    label: 'page.reformulateProposal.label.batteryCondensatorPower',
                    unit: 'kvar',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.HARMONIC_FILTER]: {
                    name: REFORMULATE_PROPOSAL_NAMES.HARMONIC_FILTER,
                    label: 'page.reformulateProposal.label.harmonicFilter',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY,
                    label: 'page.reformulateProposal.label.instalationCost',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                    unit: 'currency',
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT,
                    label: 'page.reformulateProposal.label.batteryCondensatorCost',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                    unit: 'currency',
                },
            },
            [PRODUCT_IDS.EVC]: {
                [REFORMULATE_PROPOSAL_SECTIONS.GENERAL]: {
                    visible: true,
                    order: 1,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.COSTS]: {
                    visible: true,
                    order: 2,
                },
                edit: {
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_DC_POWER_INSTALLED]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_DC_POWER_INSTALLED,
                    label: 'page.reformulateProposal.label.totalPower',
                    unit: 'kw',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY,
                    label: 'page.reformulateProposal.chargerQuantities',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.CABLE_METRES]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CABLE_METRES,
                    label: 'page.reformulateProposal.label.cableMeters',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT,
                    label: 'page.reformulateProposal.label.chargersCost',
                    unit: 'currency',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY,
                    label: 'page.reformulateProposal.label.instalationCost',
                    unit: 'currency',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
            },
        },
    },
    [getCompanyProfileIds().EDP_PL]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [REFORMULATE_PROPOSAL_SECTIONS.GENERAL]: {
                    visible: true,
                    order: 1,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.SYSTEM_DESIGN]: {
                    visible: true,
                    order: 2,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.COSTS]: {
                    visible: true,
                    order: 3,
                },
                edit: {
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_PV]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_PV,
                    label: 'page.reformulateProposal.label.peakPower',
                    unit: 'kwp',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_INVERTERS_POWER]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_INVERTERS_POWER,
                    label: 'page.reformulateProposal.label.nominalPower',
                    unit: 'kw',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY,
                    label: 'page.reformulateProposal.label.panelQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EMS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EMS,
                    label: 'page.reformulateProposal.label.ems',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.CONCENTRATOR_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CONCENTRATOR_QUANTITY,
                    label: 'page.reformulateProposal.label.concentratorsQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.ANNUAL_PRODUCTION_MWH]: {
                    name: REFORMULATE_PROPOSAL_NAMES.ANNUAL_PRODUCTION_MWH,
                    label: 'page.reformulateProposal.label.annualProduction',
                    unit: 'mwh',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY,
                    label: 'page.reformulateProposal.label.instalationCost',
                    unit: 'currency',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.ANNUAL_MAINTENANCE_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.ANNUAL_MAINTENANCE_COST,
                    label: 'page.reformulateProposal.label.annualMaintenanceCost',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                    unit: 'currency',
                },
                [REFORMULATE_PROPOSAL_NAMES.TYPE_OF_COSTS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.TYPE_OF_COSTS,
                    label: 'page.reformulateProposal.label.typeOfCost',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT,
                    label: 'page.reformulateProposal.label.equipmentsCost',
                    unit: 'currency',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.PANEL_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.PANEL_COST,
                    label: 'page.reformulateProposal.label.panelsCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.INVERTER_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.INVERTER_COST,
                    label: 'page.reformulateProposal.label.invertersCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.BATTERY_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.BATTERY_COST,
                    label: 'page.reformulateProposal.label.batteryCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.MONITORING_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.MONITORING_COST,
                    label: 'page.reformulateProposal.label.monitoringCost',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                    unit: 'currency',
                },
                [REFORMULATE_PROPOSAL_NAMES.TOTAL_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.TOTAL_COST,
                    label: 'page.reformulateProposal.label.totalCost',
                    visible: true,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                    name: REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                    name: REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.EXTRA_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EXTRA_COST,
                    label: 'page.reformulateProposal.label.extraCost',
                    validation: {
                        required: 'yup.message.required',
                    },
                    visible: true,
                    unit: 'currency',
                },
                [REFORMULATE_PROPOSAL_NAMES.UPLOAD_FILES]: {
                    name: REFORMULATE_PROPOSAL_NAMES.UPLOAD_FILES,
                    label: 'processUploadPricingDocs.label.uploadFiles',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.CURVE_LOAD]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CURVE_LOAD,
                    label: 'processUploadPricingDocs.label.uploadLoadCurve',
                    subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                    min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                    hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                    downloadLabel: 'processUploadPricingDocs.label.loadCurve',
                    invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                    visible: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                [REFORMULATE_PROPOSAL_SECTIONS.GENERAL]: {
                    visible: true,
                    order: 1,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.COSTS]: {
                    visible: true,
                    order: 2,
                },
                edit: {
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_CFP]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_CFP,
                    label: 'page.reformulateProposal.label.batteryCondensatorPower',
                    unit: 'kvar',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.HARMONIC_FILTER]: {
                    name: REFORMULATE_PROPOSAL_NAMES.HARMONIC_FILTER,
                    label: 'page.reformulateProposal.label.harmonicFilter',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY,
                    label: 'page.reformulateProposal.label.instalationCost',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                    unit: 'currency',
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT,
                    label: 'page.reformulateProposal.label.batteryCondensatorCost',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                    unit: 'currency',
                },
            },
            [PRODUCT_IDS.EVC]: {
                [REFORMULATE_PROPOSAL_SECTIONS.GENERAL]: {
                    visible: true,
                    order: 1,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.COSTS]: {
                    visible: true,
                    order: 2,
                },
                edit: {
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_DC_POWER_INSTALLED]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_DC_POWER_INSTALLED,
                    label: 'page.reformulateProposal.label.totalPower',
                    unit: 'kw',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY,
                    label: 'page.reformulateProposal.chargerQuantities',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.CABLE_METRES]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CABLE_METRES,
                    label: 'page.reformulateProposal.label.cableMeters',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT,
                    label: 'page.reformulateProposal.label.chargersCost',
                    unit: 'currency',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY,
                    label: 'page.reformulateProposal.label.instalationCost',
                    unit: 'currency',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
            },
        },
    },
    [getCompanyProfileIds().EFZ]: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [REFORMULATE_PROPOSAL_SECTIONS.GENERAL]: {
                    visible: true,
                    order: 1,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.SYSTEM_DESIGN]: {
                    visible: true,
                    order: 2,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.COSTS]: {
                    visible: true,
                    order: 3,
                },
                edit: {
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_PV]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_PV,
                    label: 'page.reformulateProposal.label.peakPower',
                    unit: 'kwp',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_INVERTERS_POWER]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_INVERTERS_POWER,
                    label: 'page.reformulateProposal.label.nominalPower',
                    unit: 'kw',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY,
                    label: 'page.reformulateProposal.label.panelQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EMS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EMS,
                    label: 'page.reformulateProposal.label.ems',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.CONCENTRATOR_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CONCENTRATOR_QUANTITY,
                    label: 'page.reformulateProposal.label.concentratorsQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.ANNUAL_PRODUCTION_MWH]: {
                    name: REFORMULATE_PROPOSAL_NAMES.ANNUAL_PRODUCTION_MWH,
                    label: 'page.reformulateProposal.label.annualProduction',
                    unit: 'mwh',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY,
                    label: 'page.reformulateProposal.label.instalationCost',
                    unit: 'currency',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.ANNUAL_MAINTENANCE_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.ANNUAL_MAINTENANCE_COST,
                    label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                    visible: false,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                    unit: 'currency',
                },
                [REFORMULATE_PROPOSAL_NAMES.TYPE_OF_COSTS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.TYPE_OF_COSTS,
                    label: 'page.reformulateProposal.label.typeOfCost',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT,
                    label: 'page.reformulateProposal.label.equipmentsCost',
                    unit: 'currency',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.PANEL_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.PANEL_COST,
                    label: 'page.reformulateProposal.label.panelsCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.INVERTER_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.INVERTER_COST,
                    label: 'page.reformulateProposal.label.invertersCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.BATTERY_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.BATTERY_COST,
                    label: 'page.reformulateProposal.label.batteryCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.MONITORING_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.MONITORING_COST,
                    label: 'page.reformulateProposal.label.monitoringCost',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                    unit: 'currency',
                },
                [REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                    name: REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                    name: REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.EXTRA_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EXTRA_COST,
                    label: 'page.reformulateProposal.label.extraCost',
                    validation: {
                        required: 'yup.message.required',
                    },
                    visible: true,
                    unit: 'currency',
                },
                [REFORMULATE_PROPOSAL_NAMES.UPLOAD_FILES]: {
                    name: REFORMULATE_PROPOSAL_NAMES.UPLOAD_FILES,
                    label: 'processUploadPricingDocs.label.uploadFiles',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.CURVE_LOAD]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CURVE_LOAD,
                    label: 'processUploadPricingDocs.label.uploadLoadCurve',
                    subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                    min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                    hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                    downloadLabel: 'processUploadPricingDocs.label.loadCurve',
                    invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                    visible: true,
                },
            },
        },
    },
    DEFAULT: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [REFORMULATE_PROPOSAL_SECTIONS.GENERAL]: {
                    visible: true,
                    order: 1,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.SYSTEM_DESIGN]: {
                    visible: true,
                    order: 2,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.COSTS]: {
                    visible: true,
                    order: 3,
                },
                edit: {
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_PV]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_PV,
                    label: 'page.reformulateProposal.label.peakPower',
                    unit: 'kwp',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_INVERTERS_POWER]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_INVERTERS_POWER,
                    label: 'page.reformulateProposal.label.nominalPower',
                    unit: 'kw',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY,
                    label: 'page.reformulateProposal.label.panelQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EMS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EMS,
                    label: 'page.reformulateProposal.label.ems',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.CONCENTRATOR_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CONCENTRATOR_QUANTITY,
                    label: 'page.reformulateProposal.label.concentratorsQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.ANNUAL_PRODUCTION_MWH]: {
                    name: REFORMULATE_PROPOSAL_NAMES.ANNUAL_PRODUCTION_MWH,
                    label: 'page.reformulateProposal.label.annualProduction',
                    unit: 'mwh',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY,
                    label: 'page.reformulateProposal.label.instalationCost',
                    unit: 'currency',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.ANNUAL_MAINTENANCE_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.ANNUAL_MAINTENANCE_COST,
                    label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                    visible: false,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.TYPE_OF_COSTS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.TYPE_OF_COSTS,
                    label: 'page.reformulateProposal.label.typeOfCost',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT,
                    label: 'page.reformulateProposal.label.equipmentsCost',
                    unit: 'currency',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.PANEL_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.PANEL_COST,
                    label: 'page.reformulateProposal.label.panelsCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.INVERTER_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.INVERTER_COST,
                    label: 'page.reformulateProposal.label.invertersCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.BATTERY_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.BATTERY_COST,
                    label: 'page.reformulateProposal.label.batteryCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.MONITORING_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.MONITORING_COST,
                    label: 'page.reformulateProposal.label.monitoringCost',
                    visible: true,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.TOTAL_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.TOTAL_COST,
                    label: 'page.reformulateProposal.label.totalCost',
                    visible: true,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                    name: REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                    name: REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.EXTRA_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EXTRA_COST,
                    label: 'page.reformulateProposal.label.extraCost',
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                    },
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.UPLOAD_FILES]: {
                    name: REFORMULATE_PROPOSAL_NAMES.UPLOAD_FILES,
                    label: 'processUploadPricingDocs.label.uploadFiles',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.CURVE_LOAD]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CURVE_LOAD,
                    label: 'processUploadPricingDocs.label.uploadLoadCurve',
                    subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                    min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                    hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                    downloadLabel: 'processUploadPricingDocs.label.loadCurve',
                    invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                    visible: true,
                },
            },
            [PRODUCT_IDS.SPVSB]: {
                [REFORMULATE_PROPOSAL_SECTIONS.GENERAL]: {
                    visible: true,
                    order: 1,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.SYSTEM_DESIGN]: {
                    visible: true,
                    order: 2,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.COSTS]: {
                    visible: true,
                    order: 3,
                },
                edit: {
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_PV]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_PV,
                    label: 'page.reformulateProposal.label.peakPower',
                    unit: 'kwp',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_INVERTERS_POWER]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_INVERTERS_POWER,
                    label: 'page.reformulateProposal.label.nominalPower',
                    unit: 'kw',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY,
                    label: 'page.reformulateProposal.label.panelQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EMS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EMS,
                    label: 'page.reformulateProposal.label.ems',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.CONCENTRATOR_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CONCENTRATOR_QUANTITY,
                    label: 'page.reformulateProposal.label.concentratorsQuantity',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.ANNUAL_PRODUCTION_MWH]: {
                    name: REFORMULATE_PROPOSAL_NAMES.ANNUAL_PRODUCTION_MWH,
                    label: 'page.reformulateProposal.label.annualProduction',
                    unit: 'mwh',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY,
                    label: 'page.reformulateProposal.label.instalationCost',
                    unit: 'currency',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.ANNUAL_MAINTENANCE_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.ANNUAL_MAINTENANCE_COST,
                    label: 'processUploadPricingDocs.label.annualMaintenanceCost',
                    visible: false,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.TYPE_OF_COSTS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.TYPE_OF_COSTS,
                    label: 'page.reformulateProposal.label.typeOfCost',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT,
                    label: 'page.reformulateProposal.label.equipmentsCost',
                    unit: 'currency',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.PANEL_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.PANEL_COST,
                    label: 'page.reformulateProposal.label.panelsCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.INVERTER_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.INVERTER_COST,
                    label: 'page.reformulateProposal.label.invertersCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.BATTERY_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.BATTERY_COST,
                    label: 'page.reformulateProposal.label.batteryCost',
                    visible: true,
                    unit: 'currency',
                    min: {
                        value: 0,
                        message: 'yup.message.number.positive',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.MONITORING_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.MONITORING_COST,
                    label: 'page.reformulateProposal.label.monitoringCost',
                    visible: true,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.TOTAL_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.TOTAL_COST,
                    label: 'page.reformulateProposal.label.totalCost',
                    visible: true,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.SHOW_VALID_PRODUCTION_WARNING]: {
                    name: REFORMULATE_PROPOSAL_NAMES.VALID_PRODUCTION_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.validProductionAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS]: {
                    name: REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptions',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE]: {
                    name: REFORMULATE_PROPOSAL_NAMES.COSTS_OUTSIDE_ASSUMPTIONS_ATTRIBUTABLE,
                    label: 'processUploadPricingDocs.label.costsOutsideAssumptionsAttributable',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.EXTRA_COST]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EXTRA_COST,
                    label: 'page.reformulateProposal.label.extraCost',
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                    },
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.UPLOAD_FILES]: {
                    name: REFORMULATE_PROPOSAL_NAMES.UPLOAD_FILES,
                    label: 'processUploadPricingDocs.label.uploadFiles',
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.CURVE_LOAD]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CURVE_LOAD,
                    label: 'processUploadPricingDocs.label.uploadLoadCurve',
                    subLabel: 'processUploadPricingDocs.label.loadCurveTemplates',
                    min15Label: 'processUploadPricingDocs.label.loadCurveTemplate15min',
                    hourlyLabel: 'processUploadPricingDocs.label.loadCurveTemplateHourly',
                    downloadLabel: 'processUploadPricingDocs.label.loadCurve',
                    invalidFormat: 'processUploadPricingDocs.label.invalidFormat',
                    visible: true,
                },
            },
            [PRODUCT_IDS.CFP]: {
                [REFORMULATE_PROPOSAL_SECTIONS.GENERAL]: {
                    visible: true,
                    order: 1,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.COSTS]: {
                    visible: true,
                    order: 2,
                },
                edit: {
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_CFP]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_POWER_INSTALLED_CFP,
                    label: 'page.reformulateProposal.label.batteryCondensatorPower',
                    unit: 'kvar',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.HARMONIC_FILTER]: {
                    name: REFORMULATE_PROPOSAL_NAMES.HARMONIC_FILTER,
                    label: 'page.reformulateProposal.label.harmonicFilter',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY,
                    label: 'page.reformulateProposal.label.instalationCost',
                    visible: true,
                    editable: true,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT,
                    label: 'page.reformulateProposal.label.batteryCondensatorCost',
                    visible: true,
                    unit: 'currency',
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
            },
            [PRODUCT_IDS.EVC]: {
                [REFORMULATE_PROPOSAL_SECTIONS.GENERAL]: {
                    visible: true,
                    order: 1,
                },
                [REFORMULATE_PROPOSAL_SECTIONS.COSTS]: {
                    visible: true,
                    order: 2,
                },
                edit: {
                    visible: true,
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_DC_POWER_INSTALLED]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_DC_POWER_INSTALLED,
                    label: 'page.reformulateProposal.label.totalPower',
                    unit: 'kw',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.EQUIPMENT_QUANTITY,
                    label: 'page.reformulateProposal.chargerQuantities',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.CABLE_TOTAL]: {
                    name: REFORMULATE_PROPOSAL_NAMES.CABLE_TOTAL,
                    label: 'page.reformulateProposal.label.cableMeters',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_EQUIPMENT,
                    label: 'page.reformulateProposal.label.chargersCost',
                    unit: 'currency',
                    visible: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
                [REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY]: {
                    name: REFORMULATE_PROPOSAL_NAMES.REAL_COST_FACILITY,
                    label: 'page.reformulateProposal.label.instalationCost',
                    unit: 'currency',
                    visible: true,
                    editable: true,
                    validation: {
                        required: 'yup.message.required',
                        min: {
                            value: 0,
                            message: 'yup.message.number.positive',
                        },
                    },
                },
            },
        },
    },
};

export const TYPES_OF_COSTS = {
    TOTAL: 'total',
    DETAILED: 'detailed',
};

export const DEFAULT_IMAGE_CONTRACT_DIMENSIONS = {
    WIDTH: 950,
    HEIGHT: 810,
};
