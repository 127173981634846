import { OfferEditionProvider } from 'contexts/businessModels/businessModelsContext';
import { lazy } from 'react';
//selectors
import lazyLoader from 'services/lazyLoader';
import { isEnvDevFlag } from 'services/settings';
import { withTracker } from 'services/withTracker';
import { useFeatureFlags } from 'store/featureFlags';

const BusinessModelsContainer = lazy(() => lazyLoader(() => import('pages/Restricted/BusinessModels')));
const BusinessModelsProContainer = lazy(() => lazyLoader(() => import('pages/Restricted/BusinessModelsPro')));

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */

const ReformulationBusinessModels = () => {
    const { featureFlags } = useFeatureFlags();
    return isEnvDevFlag(featureFlags['fe-2423']) ?
            <BusinessModelsProContainer />
        :   <OfferEditionProvider>
                <BusinessModelsContainer />
            </OfferEditionProvider>;
};
export default withTracker(ReformulationBusinessModels, 'Business Models');
