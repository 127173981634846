import { memo, useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router';
import GenericDialog from 'components/core/dialogs/GenericDialog';
import IntlMessages from 'components/util/IntlMessages';
// import Alert from "components/@efz/Alert";
import useCookies from 'hooks/cookies';
import { routeToCookiePolicy } from 'services/cookies';
import { isS2CPlatformVersion } from 'services/settings';
import { Alert, Switch } from '@mui/material';
import CookieConsentOArea from '../CookieConsentOArea';
import { useAuthStore } from 'store/auth';
import { useUserStore } from 'store/user';
import { Button } from '@save2compete/efz-design-system';

const CustomizeCookiesOAreaDialog = ({ isOpen, setIsOpen, cookieBannerHandler }) => {
    const history = useHistory();
    const isS2CVersion = isS2CPlatformVersion();
    const localeCode = useUserStore()?.LOCALE_CODE ?? 'gr';

    const {
        setCookie,
        getCookie,
        removeGACookies,
        CONSENT_COOKIE, //TODO: Este não é o ´Essential cookies´ e que deveria estar sempre on?
        PLATFORM_CUSTOMIZATION_COOKIE,
        PERSONALIZED_ADVERTISING_COOKIE,
    } = useCookies();
    const { isUserAuthenticated } = useAuthStore();

    const _platformCustomizationCookie = getCookie(PLATFORM_CUSTOMIZATION_COOKIE) ?? false;
    const _personalizedAdvertisingCookie = getCookie(PERSONALIZED_ADVERTISING_COOKIE) ?? false;
    const _consentCookie = getCookie(CONSENT_COOKIE);

    const [showPolicy, setShowPolicy] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [policyTypeToShow, setPolicyTypeToShow] = useState(null);

    const [platformCustomization, setPlatformCustomization] = useState(_platformCustomizationCookie);
    const [personalizedAdvertising, setPersonalizedAdvertising] = useState(_personalizedAdvertisingCookie);

    const showPolicyHandler = (type = null) => {
        if (type) {
            if (!isUserAuthenticated) {
                setPolicyTypeToShow(type);
            } else {
                setIsOpen(false);
                routeToCookiePolicy(history, type);
            }
        } else {
            setShowPolicy(false);
        }
    };

    useEffect(() => {
        if (policyTypeToShow) {
            setShowPolicy(true);
        } else {
            setShowPolicy(false);
        }
    }, [policyTypeToShow]);

    useEffect(() => {
        if (!showPolicy) {
            setPolicyTypeToShow(null);
        }
    }, [showPolicy]);

    const switchHandler = () => {
        setCookie('evergage-ppcsa', platformCustomization, 365);
        setCookie(PLATFORM_CUSTOMIZATION_COOKIE, platformCustomization, 365);
        setCookie(PERSONALIZED_ADVERTISING_COOKIE, personalizedAdvertising, 730);

        if (!personalizedAdvertising) {
            removeGACookies();
        }

        setIsOpen(false);
        cookieBannerHandler?.accept();
        window.location.reload();
    };

    useEffect(() => {
        if ((!personalizedAdvertising && _personalizedAdvertisingCookie) || (!platformCustomization && _platformCustomizationCookie)) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    }, [personalizedAdvertising, _personalizedAdvertisingCookie, platformCustomization, _platformCustomizationCookie]);

    const dialogTitle = <IntlMessages id="label.cookies.privacySettings" />;

    const dialogBody = (
        <Fragment>
            <section className="privacy-section">
                <div className="privacy-section-content">
                    <div>
                        <IntlMessages id="dialog.privacyOA.1" />
                        <br className={`${isS2CVersion ? 'isS2C' : ''}`} />
                        <ul>
                            <li>
                                <IntlMessages id="dialog.privacyOA.1.1" />
                            </li>
                            <li>
                                <IntlMessages id="dialog.privacyOA.1.2" />
                            </li>
                            <li>
                                <IntlMessages id="dialog.privacyOA.1.3" />
                            </li>
                        </ul>
                        <IntlMessages id="dialog.privacy.2.1" whitespaceEnd />
                        <a
                            className="link"
                            href={
                                localeCode === 'gr' ?
                                    'https://www.dei.gr/el/dedomena-prosopikou-xaraktira/'
                                :   'https://www.dei.gr/en/personal-data/'
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            <IntlMessages id="label.here" />
                        </a>
                        <IntlMessages id="dialog.privacy.2.2" whitespaceEnd />
                        <a
                            className="link"
                            href={localeCode === 'gr' ? 'https://www.dei.gr/el/politiki-cookies/' : 'https://www.dei.gr/en/cookie-policy/'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <IntlMessages id="label.here" />
                        </a>
                        .
                    </div>
                    <div className="label-category" />
                </div>
            </section>

            <section className="privacy-section">
                <div className="privacy-section-title">
                    <div className="privacy-section-title-first-line">
                        <span className={`${isS2CVersion ? 'isS2C' : ''}`}>
                            <IntlMessages id="dialog.privacy.essential" />
                        </span>
                        <span className="label-category">
                            <IntlMessages id="label.alwaysOn" />
                        </span>
                    </div>
                    <hr />
                </div>
                <div className="privacy-section-content">
                    <div>
                        <IntlMessages id="dialog.privacy.essentialOA.1" />
                    </div>
                    <div className="label-category" />
                </div>
            </section>

            <section className="privacy-section">
                <div className="privacy-section-title">
                    <div className="privacy-section-title-first-line">
                        <span className={`${isS2CVersion ? 'isS2C' : ''}`}>
                            <IntlMessages id="dialog.privacy.customization" />
                        </span>
                        <div className="label-category">
                            <Switch checked={platformCustomization} onChange={(e) => setPlatformCustomization(e.target.checked)} />
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="privacy-section-content">
                    <div>
                        <IntlMessages id="dialog.privacy.customizationOA.1" />
                        <br />
                        <ul>
                            <li>
                                <IntlMessages id="dialog.privacy.customizationOA.1.1" />
                            </li>
                            <li>
                                <IntlMessages id="dialog.privacy.customizationOA.1.2" />
                            </li>
                        </ul>
                        <IntlMessages id="dialog.privacy.customizationOA.2" />
                    </div>
                    <div className="label-category" />
                </div>
            </section>

            <section className="privacy-section">
                <div className="privacy-section-title">
                    <div className="privacy-section-title-first-line">
                        <span>
                            <IntlMessages id="dialog.privacy.addOA" />
                        </span>
                        <div className="label-category">
                            <Switch checked={personalizedAdvertising} onChange={(e) => setPersonalizedAdvertising(e.target.checked)} />
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="privacy-section-content">
                    <div>
                        <IntlMessages id="dialog.privacy.addOA.1" />
                        <br />
                        <ul>
                            <li>
                                <IntlMessages id="dialog.privacy.addOA.1.1" />
                            </li>
                            <li>
                                <IntlMessages id="dialog.privacy.addOA.1.2" />
                            </li>
                            <li>
                                <IntlMessages id="dialog.privacy.addOA.1.3" />
                            </li>
                            <li>
                                <IntlMessages id="dialog.privacy.addOA.1.4" />
                            </li>
                            <li>
                                <IntlMessages id="dialog.privacy.addOA.1.5" />
                            </li>
                        </ul>
                        <IntlMessages id="dialog.privacy.addOA.2" />
                    </div>
                    <div className="label-category" />
                </div>
                {showWarning && _consentCookie && (
                    <Alert severity="warning" className="mt-2">
                        <span>
                            <IntlMessages id="dialog.privacy.alert" />
                        </span>
                    </Alert>
                )}
            </section>
        </Fragment>
    );

    const dialogButtons = (
        <div className="w-100 d-flex justify-content-center privacy-dialog-actions">
            <Button type={'button'} onClick={switchHandler} dataTestId="privacy-dialog-consent">
                <IntlMessages id="label.consent" />
            </Button>
        </div>
    );

    return (
        <>
            {!showPolicy && (
                <GenericDialog
                    open={isOpen}
                    handleClose={() => setIsOpen(false)}
                    title={dialogTitle}
                    body={dialogBody}
                    buttons={dialogButtons}
                    hasLine
                    className={'cookies-dialog'}
                />
            )}
            {showPolicy && <CookieConsentOArea type={policyTypeToShow} close={showPolicyHandler} showPolicyHandler={showPolicyHandler} />}
        </>
    );
};

export default memo(CustomizeCookiesOAreaDialog);
