/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Proposal : Validação do routeamento e validação de acesso ao Contanier
 *
 * @version 20191202
 * @version 20191128
 * @since 20191113 Initial release
 *
 */

import { lazy } from 'react';
import { useParams, withRouter } from 'react-router';
import { connect } from 'react-redux';
import Error403 from 'components/errors/Error403';
import PropTypes from 'prop-types';

// Selectors
import { getFacilityID } from 'redux/selectors/facility';
import { getProductId } from 'redux/selectors/product';
import { getBusinessModelID } from 'redux/selectors/proposal';
import { PRODUCTS_IDS_IMPLEMENTED } from 'constants/products';
import { isReformulation } from 'redux/selectors/businessModels';

import lazyLoader from 'services/lazyLoader';
import { BASENAME_URL_APP } from 'constants/settings';
import { withTracker } from 'services/withTracker';

const ProposalContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Proposal')));

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */

/* Para aceder ao Container Proposta é necesário que exista um facilityID, productID e um businessModelID.
caso contrário o utilizador é reecaminhado para um erro 403. */

const Proposal = (props) => {
    let { id } = useParams();
    const { facilityID, productID, businessModelID, isReformulation } = props;
    const hasAccess =
        !isNaN(parseInt(id)) &&
        !!businessModelID &&
        (isReformulation || (PRODUCTS_IDS_IMPLEMENTED.includes(productID) && !!facilityID && !!productID));
    return hasAccess ?
            <ProposalContainer />
        :   <Error403
                props={{
                    url: BASENAME_URL_APP + 'solutions',
                    buttonText: 'page.solutions',
                    message: 'page.error.403.unexpected',
                }}
            />;
};

/* PropTypes */

Proposal.propTypes = {
    facilityID: PropTypes.string.isRequired,
    productID: PropTypes.number.isRequired,
    businessModelID: PropTypes.number.isRequired,
    isReformulation: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ facility, product, proposal, businessModels }) => {
    return {
        facilityID: getFacilityID(facility),
        productID: getProductId(product),
        businessModelID: getBusinessModelID(proposal),
        isReformulation: isReformulation(businessModels),
    };
};

export default withRouter(connect(mapStateToProps)(withTracker(Proposal, 'Proposal')));
