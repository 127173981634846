export const SOLUTION_TP_TAGS = {
    SOLAR_PHOTOVOLTAIC: 'SOLAR_PHOTOVOLTAIC',
    EFFICIENT_LIGHTING: 'EFFICIENT_LIGHTING',
    POWER_FACTOR_CORRECTION: 'POWER_FACTOR_CORRECTION',
    ENERGY_CERTIFICATIONS_AND_AUDITS: 'ENERGY_CERTIFICATIONS_AND_AUDITS',
    ELECTRIC_MOBILITY: 'ELECTRIC_MOBILITY',
    NATURAL_GAS_AND_AGGREGATED_SERVICES: 'NATURAL_GAS_AND_AGGREGATED_SERVICES',
    ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN: 'ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN',
    HEATING_VENTILATION_AND_AIR_CONDITIONING_2_0: 'HEATING_VENTILATION_AND_AIR_CONDITIONING_2_0',
    HIGH_EFFICIENCY_MOTORS: 'HIGH_EFFICIENCY_MOTORS',
    VARIABLE_SPEED_DRIVES: 'VARIABLE_SPEED_DRIVES',
    VOLTAGE_TRANSFORMERS_MAINTENANCE: 'VOLTAGE_TRANSFORMERS_MAINTENANCE',
    ENERGY_PROPOSALS: 'ENERGY_PROPOSALS',
};

export const INITIAL_SOLUTIONS_CLIENT = {
    id: null,
    descricao: null,
    nome_proprio: null,
    apelido: null,
    segmento_descricao: null,
};

export const INITIAL_SOLUTIONS_FACILITY = {
    id: null,
    client_id: null,
    morada: null,
    location: { address: null },
    cpe: null,
    total_tariffs: [],
};

export const INITIAL_SELECTED_SOLUTION = {
    tp_tag: null,
    id: null,
    estimativa_investimento: null,
    estimativa_poupanca: null,
    estimativa_aumento_produtividade_prc: null,
    tem_potencial: null,
    motivo_sem_potencial: null,
    comentario_sem_potencial: null,
};
/* default objects */
export const INPUT_NAMES = {
    REASON: 'reason',
    COMMENTS: 'comments',
};

// Inputs for the Create Client form
export const NO_POTENTIAL_FORM_QUESTIONS = {
    [INPUT_NAMES.REASON]: {
        name: INPUT_NAMES.REASON,
        label: 'page.noPotential.label.reason',
        placeholder: 'page.noPotential.placeholder.reason',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: {
            required: true,
        },
    },
    [INPUT_NAMES.COMMENTS]: {
        name: INPUT_NAMES.COMMENTS,
        label: 'page.noPotential.label.comments',
        placeholder: 'page.noPotential.placeholder.comments',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: {
            required: false,
        },
    },
};

export const NO_POTENTIAL_OPTIONS = [
    // {
    //     label: 'label.selectOption',
    //     value: ' '
    // },
    {
        label: 'label.dynamic.TECHNICAL_INCOMPATIBILITY',
        value: 'TECHNICAL_INCOMPATIBILITY',
    },
    {
        label: 'label.dynamic.ALREADY_IMPLEMENTED',
        value: 'ALREADY_IMPLEMENTED',
    },
];

export const INPUT_NAMES_INCREASE_POWER_FORM = {
    HAS_PROPOSAL_ID: 'has_proposal_id',
    PROPOSAL_ID: 'proposal_id',
    PREVIOUS_PEAK_POWER: 'previous_peak_power',
    PREVIOUS_INVERTER_POWER_KW: 'previous_inverter_power_kw',
    IS_POWER_INCREASE: 'is_power_increase',
};
