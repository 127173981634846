interface IProps {
    description: string;
    summary: string;
}
const BusinessModelTitleServiceV2 = ({ description, summary }: IProps) => {
    return (
        <>
            <span className="header">{description}</span>
            <span className="subheader">{summary}</span>
        </>
    );
};

export default BusinessModelTitleServiceV2;
