import { ReactComponent as SavingsIcon } from 'assets/images/products/spv-simple/landing-page/savings.svg';
import { ReactComponent as EarthIcon } from 'assets/images/products/spv-simple/landing-page/earth.svg';
import { ReactComponent as TechIcon } from 'assets/images/products/spv-simple/landing-page/tech.svg';
import { ReactComponent as EnergyIcon } from 'assets/images/products/spv-simple/landing-page/energy.svg';
import IntlMessages from 'components/util/IntlMessages';

const LandingPageCards = () => {
    const cards = [
        {
            label: <IntlMessages id="page.spvSimple.open.landing.card.savings" />,
            icon: <SavingsIcon />,
            background: 'savings',
        },
        {
            label: <IntlMessages id="page.spvSimple.open.landing.card.tech" />,
            icon: <TechIcon />,
            background: 'tech',
        },
        {
            label: <IntlMessages id="page.spvSimple.open.landing.card.earth" />,
            icon: <EarthIcon />,
            background: 'earth',
        },
        {
            label: <IntlMessages id="page.spvSimple.open.landing.card.energy" />,
            icon: <EnergyIcon />,
            background: 'energy',
        },
    ];
    return (
        <div className="bottom-grid">
            {cards.map((card) => {
                return (
                    <div className={`bottom-grid-card b2b ${card.background}`} key={`card_${card.background}`}>
                        {card.icon}
                        {card.label}
                    </div>
                );
            })}
        </div>
    );
};

export default LandingPageCards;
