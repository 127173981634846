import { useContext, useCallback, useState, useEffect } from 'react';

// Components
import IntlMessages from 'components/util/IntlMessages';
import Slider from '@mui/material/Slider';
import FormatNumber from 'components/util/FormatNumber';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPPa, PPaEvents } from 'interfaces/businessModels/ppa';

// Services
import { debounce } from 'services/util/auxiliaryUtils';

// Constants
import { DEBOUNCE_TIME } from 'constants/endpoints';
import { PPA_MARGIN_SEARCH_VALUE } from 'constants/businessModelsPro/ppa';

const PPaMargin = () => {
    const { bmSelected, setBMEventHandler } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;
    const pPaMarginValues = bmSelected.values.energy_price_irr_annual_savings;
    const searchValue = 'prc_of_current_price';

    const [sliderValue, setSliderValue] = useState(pPaMarginValues?.[0]?.value);

    const handleSliderChange = useCallback((_, newValue) => setSliderValue(newValue), []);

    let sliderStep = parseFloat(
        (pPaMarginValues?.[1]?.[PPA_MARGIN_SEARCH_VALUE] - pPaMarginValues?.[0]?.[PPA_MARGIN_SEARCH_VALUE]).toFixed(5)
    );
    if (sliderStep < 0) sliderStep = -sliderStep;

    // eslint-disable-next-line
    const delayedNewMarginHandler = useCallback(
        debounce((marginValue) => {
            const selectedMargin = pPaMarginValues.find((item) => item.value === marginValue) ?? pPaMarginValues[0];

            setBMEventHandler(PPaEvents.SET_PPA_MARGIN, selectedMargin), DEBOUNCE_TIME;
        }),
        []
    ); // eslint-disable-line

    useEffect(() => {
        delayedNewMarginHandler(sliderValue);
    }, [sliderValue]); // eslint-disable-line

    return (
        <div className="bm-margin">
            <div className="bm-margin-label">
                <IntlMessages id="label.selectMargin" />
            </div>
            <div className="bm-margin-slider">
                {bmSelected?.values?.energy_price_irr_annual_savings?.length > 0 && (
                    <Slider
                        // @ts-ignore
                        marks={pPaMarginValues}
                        aria-label="margin-slider"
                        aria-labelledby="discrete-slider-restrict"
                        min={pPaMarginValues[0]?.value ?? 0}
                        max={pPaMarginValues[pPaMarginValues.length - 1]?.value}
                        step={1}
                        valueLabelDisplay="on"
                        // @ts-ignore
                        valueLabelFormat={(value) => (
                            <FormatNumber
                                styleFormat="percent"
                                number={pPaMarginValues.find((item) => item.value === value)?.[searchValue]}
                                numberOfDecimalPlaces={2}
                            />
                        )}
                        onChange={handleSliderChange}
                        value={sliderValue}
                    />
                )}
            </div>
        </div>
    );
};

export default PPaMargin;
