import { IBusinessModelEntry, ICustomizeState } from 'interfaces/products/solarpv/customize/index';
import { CustomizeActions, CustomizeTabs } from 'constants/products/spvCustomize';
import { ICustomizeReducerAction } from 'interfaces/products/solarpv/customize/reducer';

export const initialCustomizeReducer: ICustomizeState = {
    dialogOpen: false,
    tab: CustomizeTabs.SIZING_PRODUCTION,
    sizing: {
        sources: [],
        option: null,
        productionEndpoint: null,
        designLayout: null,
        categories: [{ id: null, cost_type_id: null, description: '', cost_recurrence_id: null, tag: '' }],
        productionCurve: {
            annualProduction: null,
            filename: null,
            error: null,
        },
        defaults: {
            designLayout: null,
            productionCurve: [],
        },
        efzCosts: {
            configs: null,
            costs: null,
        },
        efzEditedCosts: {
            capex: [],
            opex: [],
        },
        customizedCosts: null,
        costsToUse: null,
        hasChanges: {
            efz: false,
            customized: false,
            designLayout: false,
        },
        reset: false,
        saved: false,
        financialModel: {
            businessModels: [],
            businessModelSelected: null,
            isCustomFinModel: false,
        },
    },
    simulation: null,
    saveDialog: null,
};

export const customizeReducer = (state = initialCustomizeReducer, action: ICustomizeReducerAction) => {
    switch (action.type) {
        case CustomizeActions.OPEN_DIALOG:
            return {
                ...state,
                dialogOpen: true,
            };
        case CustomizeActions.CLOSE_DIALOG:
            return {
                ...state,
                dialogOpen: false,
                // tab: initialCustomizeReducer.tab,
                tab: state.tab === CustomizeTabs.FINANCIAL_MODEL ? CustomizeTabs.COSTS : state.tab,
                sizing: {
                    ...state.sizing,
                    efzEditedCosts: initialCustomizeReducer.sizing.efzEditedCosts,
                    costsToUse: initialCustomizeReducer.sizing.costsToUse,
                    hasChanges: { ...initialCustomizeReducer.sizing.hasChanges },
                    reset: false,
                    saved: false,
                },
            };
        case CustomizeActions.SELECT_TAB:
            return {
                ...state,
                tab: action.payload,
                sizing: {
                    ...state.sizing,
                    efzEditedCosts: initialCustomizeReducer.sizing.efzEditedCosts,
                    costsToUse: initialCustomizeReducer.sizing.costsToUse,
                    // hasChanges: { ...initialCustomizeReducer.sizing.hasChanges },
                    reset: false,
                    saved: action.payload === CustomizeTabs.FINANCIAL_MODEL,
                },
            };
        case CustomizeActions.SET_PRODUCTION_SOURCES:
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    sources: action.payload,
                },
            };
        case CustomizeActions.SET_CATEGORIES: {
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    categories: action.payload,
                },
            };
        }
        case CustomizeActions.SELECT_SIZING_OPTION:
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    option: action.payload.option,
                    productionEndpoint: action.payload.endpoint,
                    productionCurve: initialCustomizeReducer.sizing.productionCurve,
                },
            };
        case CustomizeActions.SET_DESIGN_LAYOUT:
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    designLayout: action.payload.blob,
                    defaults: {
                        ...state.sizing.defaults,
                        designLayout: action.payload.default,
                    },
                    hasChanges: {
                        ...state.sizing.hasChanges,
                        designLayout: action.payload.hasChanges,
                    },
                },
            };
        case CustomizeActions.SET_PRODUCTION_CURVE:
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    productionCurve: {
                        filename: action.payload.file.filename,
                        annualProduction: action.payload.file.annual_production_kWh,
                    },
                    defaults: {
                        ...state.sizing.defaults,
                        productionCurve: action.payload.default,
                    },
                },
            };
        case CustomizeActions.SET_PRODUCTION_CURVE_ERROR:
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    productionCurve: {
                        ...state.sizing.productionCurve,
                        error: action.payload,
                    },
                    defaults: {
                        ...state.sizing.defaults,
                        productionCurve: initialCustomizeReducer.sizing.defaults.productionCurve,
                    },
                },
            };
        case CustomizeActions.RESET_PRODUCTION_CURVE:
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    productionCurve: initialCustomizeReducer.sizing.productionCurve,
                    defaults: {
                        ...state.sizing.defaults,
                        productionCurve: initialCustomizeReducer.sizing.defaults.productionCurve,
                    },
                },
            };
        case CustomizeActions.SET_SIMULATION:
            return {
                ...state,
                simulation: action.payload,
            };
        case CustomizeActions.SET_EFZ_COSTS:
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    efzCosts: {
                        ...state.sizing.efzCosts,
                        ...action.payload,
                    },
                },
            };
        case CustomizeActions.SET_CUSTOMIZED_COSTS:
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    customizedCosts: action.payload,
                },
            };
        case CustomizeActions.SET_COSTS_TO_USE:
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    costsToUse: action.payload,
                },
            };
        case CustomizeActions.SET_EDITED_COSTS_ARRAY: {
            let editedCosts = { ...state.sizing.efzEditedCosts };
            const { costType, payload } = action.payload;

            switch (costType) {
                case 1: // CAPEX
                    editedCosts = {
                        ...editedCosts,
                        capex: payload,
                    };
                    break;
                case 2: // OPEX
                    editedCosts = {
                        ...editedCosts,
                        opex: payload,
                    };
                    break;
                default:
                    break;
            }
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    efzEditedCosts: editedCosts,
                },
            };
        }
        case CustomizeActions.SET_HAS_CHANGES: {
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    hasChanges: {
                        ...state.sizing.hasChanges,
                        ...action.payload,
                    },
                    reset: false,
                    saved: false,
                },
            };
        }
        case CustomizeActions.SET_RESET:
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    reset: action.payload,
                    saved: false,
                    costsToUse: state.sizing.efzCosts.costs,
                    hasChanges: {
                        ...state.sizing.hasChanges,
                        efz: action.payload ? !action.payload : state.sizing.hasChanges.efz,
                    },
                },
            };
        case CustomizeActions.SET_IS_SAVED:
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    saved: action.payload,
                    hasChanges: initialCustomizeReducer.sizing.hasChanges,
                },
                saveDialog: null,
            };
        case CustomizeActions.RESET_INPUTS:
            return initialCustomizeReducer;
        case CustomizeActions.SET_SIZING_AND_PRODUCTION_VALUES_FROM_BD: {
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    option:
                        action.payload?.option ?
                            typeof action.payload?.option === 'number' ?
                                action.payload.option
                            :   state.sizing.option
                        :   initialCustomizeReducer.sizing.option,
                    productionEndpoint: action.payload?.productionEndpoint ?? initialCustomizeReducer.sizing.productionEndpoint,
                    productionCurve: {
                        filename: action.payload?.productionCurve?.filename ?? initialCustomizeReducer.sizing.productionCurve.filename,
                        annualProduction:
                            action.payload?.productionCurve?.annualProduction ??
                            initialCustomizeReducer.sizing.productionCurve.annualProduction,
                    },
                    financialModel: {
                        ...state.sizing.financialModel,
                        ...action.payload?.financialModel,
                    },
                },
            };
        }
        case CustomizeActions.SET_FINANCIAL_MODEL_UPLOAD: {
            let BMs = state.sizing.financialModel.businessModels;
            let selectedBM = state.sizing.financialModel.businessModelSelected!;

            BMs = BMs.map((bm) => {
                if (bm.business_model_id === state.sizing.financialModel.businessModelSelected?.business_model_id) {
                    return {
                        ...bm,
                        upload: {
                            ...bm.upload,
                            filename: action.payload,
                        },
                    };
                }
                return bm;
            });
            selectedBM = {
                ...selectedBM,
                upload: {
                    ...selectedBM.upload,
                    filename: action.payload,
                },
            };

            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    financialModel: {
                        ...state.sizing.financialModel,
                        businessModels: [...BMs],
                        businessModelSelected: selectedBM,
                    },
                },
            };
        }
        case CustomizeActions.SET_FINANCIAL_MODEL_UPLOAD_ERROR: {
            let BMs = state.sizing.financialModel.businessModels;
            let selectedBM = state.sizing.financialModel.businessModelSelected!;

            BMs = BMs.map((bm) => {
                if (bm.business_model_id === state.sizing.financialModel.businessModelSelected?.business_model_id) {
                    return {
                        ...bm,
                        upload: {
                            ...bm.upload,
                            error: action.payload,
                        },
                    };
                }
                return bm;
            });
            selectedBM = {
                ...selectedBM,
                upload: {
                    ...selectedBM.upload,
                    error: action.payload,
                },
            };

            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    financialModel: {
                        ...state.sizing.financialModel,
                        businessModels: [...BMs],
                        businessModelSelected: selectedBM,
                    },
                },
            };
        }
        case CustomizeActions.SET_BUSINESS_MODELS: {
            const comparator = state.sizing.financialModel.businessModels;
            const newBMs: IBusinessModelEntry[] = [];
            action.payload.forEach((bm: IBusinessModelEntry) => {
                if (comparator.map((c) => c.business_model_id).includes(bm.business_model_id)) {
                    // @ts-ignore
                    newBMs.push(comparator.find((c) => c.business_model_id === bm.business_model_id));
                } else {
                    newBMs.push(bm);
                }
            });

            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    financialModel: {
                        ...state.sizing.financialModel,
                        businessModels: newBMs,
                        businessModelSelected: newBMs.length === 1 ? newBMs[0] : state.sizing.financialModel.businessModelSelected,
                    },
                },
            };
        }
        case CustomizeActions.CHOOSE_BUSINESS_MODEL_ID: {
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    financialModel: {
                        ...state.sizing.financialModel,
                        businessModelID: action.payload,
                    },
                },
            };
        }
        case CustomizeActions.SET_USE_CUSTOM_FINANCIAL_MODEL: {
            return {
                ...state,
                sizing: {
                    ...state.sizing,
                    financialModel: {
                        ...state.sizing.financialModel,
                        isCustomFinModel: action.payload,
                    },
                },
            };
        }
        case CustomizeActions.SET_SAVE_DIALOG: {
            return {
                ...state,
                saveDialog: action.payload,
            };
        }
        default:
            return state;
    }
};
