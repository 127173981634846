import { getCompanyProfileIds } from 'services/user';
import validate from 'services/util/validate';

/* default objects */
export const INPUT_NAMES_FDATA = {
    ID: 'id',
    CPE: 'cpe',
    METER_ID: 'meter_id',
    DESCRICAO: 'descricao',
    TIPO_INSTALACAO_ID: 'tipo_instalacao_id',
    MORADA: 'morada',
    NUMERO: 'numero',
    CODIGO_POSTAL: 'codigo_postal',
    BAIRRO: 'bairro',
    LOCALIDADE: 'localidade',
    NUT: 'nuts_i_id',
    NUT_II: 'nuts_ii_id',
    NUT_III: 'nuts_iii_id',
    COMPLEMENTO: 'complemento',
    MUNICIPIO_DESCRICAO: 'municipio_descricao',
    MUNICIPIO_ID: 'municipio_id',
    NIVEL_TENSAO_ID: 'nivel_tensao_id',
    TENSAO_CONEXAO_ID: 'tensao_conexao_id',
    POTENCIA_CONTRATADA: 'potencia_contratada',
    POTENCIA_CONTRATADA_PONTA: 'potencia_ponta',
    POTENCIA_REQUISITADA: 'potencia_requisitada',
    CLASSE_ID: 'classe_id',
    //#region location
    LOCATION: 'location',
    ADDRESS: 'address',
    HAS_MANUAL_ADDRESS: 'has_manual_address',
    MANUAL_ADDRESS: 'manual_address',
    STREET: 'street',
    NUMBER: 'number',
    COMPLEMENT: 'complement',
    LOCALITY: 'locality',
    POSTAL_CODE: 'postal_code',
    DISTRICT: 'district',
    CITY: 'city',
    CITY_ID: 'city_id',
    NUTS_I_ID: 'nuts_i_id',
    NUTS_I: 'nuts_i',
    NUTS_II_ID: 'nuts_ii_id',
    NUTS_II: 'nuts_ii',
    NUTS_III_ID: 'nuts_iii_id',
    NUTS_III: 'nuts_iii',
    COUNTY_ID: 'county_id',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
    COUNTY_DESCRIPTION: 'county_description',
    NUMERO_FASES_ID: 'numero_fases_id', //B2C
    NUM_FASES: 'num_fases', //B2B
    MCB_RATING: 'mcb_rating',
    //#endregion location
};

export const EMPTY_NIVEL_TENSAO_ID = 19;
const CONTRACTED_POWER_LIMITS = {
    EDP_PT: { max: 1000000, min: 1.15 },
    DEFAULT: { max: 1000000, min: 0 },
};

//#region /** B2B **/
/* questions */
export const QUESTIONS_FDATA_B2B = {
    [INPUT_NAMES_FDATA.CPE]: {
        name: INPUT_NAMES_FDATA.CPE,
        label: 'label.cpe',
        placeholder: 'question.placeholder.cpe',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 30 }),
        optValidation: { maxLength: 50 },
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.DESCRICAO]: {
        name: INPUT_NAMES_FDATA.DESCRICAO,
        label: 'question.label.SocialDescription',
        placeholder: 'question.placeholder.SocialDescription',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 256 }),
        optValidation: { maxLength: 256 },
        isRequired: true,
    },
    // INSTALATION TYPE
    [INPUT_NAMES_FDATA.TIPO_INSTALACAO_ID]: {
        name: INPUT_NAMES_FDATA.TIPO_INSTALACAO_ID,
        label: 'question.label.facilityType',
        placeholder: 'question.placeholder.facilityType',
        tooltip: null,
        disabled: false,
        visible: false,
        options: [],
        type: 'select',
        validation: {},
        isRequired: false,
    },
    [INPUT_NAMES_FDATA.ADDRESS]: {
        name: INPUT_NAMES_FDATA.ADDRESS,
        label: 'label.addressGMap',
        placeholder: 'question.placeholder.address',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 256 }),
        optValidation: { maxLength: 256 },
        isRequired: true,
        autocomplete: true,
    },
    [INPUT_NAMES_FDATA.HAS_MANUAL_ADDRESS]: {
        name: INPUT_NAMES_FDATA.HAS_MANUAL_ADDRESS,
        label: 'label.hasManualAddress',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'checkbox',
        validation: {},
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.MANUAL_ADDRESS]: {
        name: INPUT_NAMES_FDATA.MANUAL_ADDRESS,
        label: 'label.manualAddress',
        placeholder: 'question.placeholder.address',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 256 }),
        optValidation: { maxLength: 256 },
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.POSTAL_CODE]: {
        name: INPUT_NAMES_FDATA.POSTAL_CODE,
        label: 'label.postalCode',
        placeholder: 'placeholder.postalCode',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 8 }),
        optValidation: { maxLength: 8 },
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.CITY]: {
        //locality
        name: INPUT_NAMES_FDATA.CITY,
        label: 'label.locality',
        placeholder: 'placeholder.locality',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        inputType: 'string',
        validation: validate(['required', 'maxLength'], { maxLength: 64 }),
        optValidation: { maxLength: 64 },
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.COUNTY_ID]: {
        name: INPUT_NAMES_FDATA.COUNTY_ID,
        label: 'label.county',
        placeholder: 'placeholder.countySearch',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'autocomplete',
        validation: validate(['required']),
        isRequired: true,
    },
    technicalInformationSection: { visible: true },
    [INPUT_NAMES_FDATA.POTENCIA_CONTRATADA]: {
        name: INPUT_NAMES_FDATA.POTENCIA_CONTRATADA,
        label: 'label.subscribedPower',
        placeholder: 'question.placeholder.subscribedPower',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'input',
        options: { limits: CONTRACTED_POWER_LIMITS.DEFAULT },
        type: 'input',
        step: 0.01,
        validation: validate(['required', 'number', 'min', 'maxNotIncluded'], CONTRACTED_POWER_LIMITS.DEFAULT),
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.POTENCIA_REQUISITADA]: {
        name: INPUT_NAMES_FDATA.POTENCIA_REQUISITADA,
        label: 'label.requestedPower2',
        placeholder: 'question.placeholder.requestedPower',
        tooltip: null,
        disabled: false,
        visible: false,
        inputType: 'input',
        options: { limits: CONTRACTED_POWER_LIMITS.DEFAULT },
        type: 'input',
        step: 0.01,
        validation: validate(['required', 'number', 'min', 'maxNotIncluded'], CONTRACTED_POWER_LIMITS.DEFAULT),
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.NIVEL_TENSAO_ID]: {
        name: INPUT_NAMES_FDATA.NIVEL_TENSAO_ID,
        label: 'question.label.voltageLevels',
        placeholder: 'question.placeholder.voltageLevels',
        tooltip: null,
        disabled: false,
        visible: false,
        options: null,
        type: 'select',
        validation: validate(['required'], { message: 'yup.message.required' }),
        isRequired: true,
    },

    [INPUT_NAMES_FDATA.NUM_FASES]: {
        name: INPUT_NAMES_FDATA.NUM_FASES,
        label: 'label.numberOfPhases',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: false,
        options: {
            [getCompanyProfileIds().PPC]: [
                {
                    id: '1',
                    description: 'label.monophasic',
                },
                {
                    id: '3',
                    description: 'label.threephasic',
                },
            ],
            [getCompanyProfileIds().EDP_PT]: [
                {
                    id: '1',
                    description: 'label.monophasic',
                },
                {
                    id: '3',
                    description: 'label.threephasic',
                },
            ],
        },
        type: 'radio',
        validation: validate(['required']),
    },
    [INPUT_NAMES_FDATA.MCB_RATING]: {
        name: INPUT_NAMES_FDATA.MCB_RATING,
        label: 'question.label.mcbRating',
        placeholder: 'question.placeholder.mcbRating',
        tooltip: null,
        disabled: false,
        visible: false,
        options: null,
        type: 'input',
        inputType: 'string',
        validation: null,
        isRequired: false,
    },
};

//#endregion /** B2B **/

//#region /** B2C **/
export const QUESTIONS_FDATA_B2C = {
    [INPUT_NAMES_FDATA.CPE]: {
        name: INPUT_NAMES_FDATA.CPE,
        label: 'label.cpe',
        placeholder: 'question.placeholder.cpe',
        tooltip: null,
        disabled: true,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 50 }),
        optValidation: { maxLength: 50 },
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.ADDRESS]: {
        name: INPUT_NAMES_FDATA.ADDRESS,
        label: 'label.addressGMap',
        placeholder: 'question.placeholder.address',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 256 }),
        optValidation: { maxLength: 256 },
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.HAS_MANUAL_ADDRESS]: {
        name: INPUT_NAMES_FDATA.HAS_MANUAL_ADDRESS,
        label: 'label.hasManualAddress',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'checkbox',
        validation: {},
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.MANUAL_ADDRESS]: {
        name: INPUT_NAMES_FDATA.MANUAL_ADDRESS,
        label: 'label.manualAddress',
        placeholder: 'question.placeholder.address',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 256 }),
        optValidation: { maxLength: 256 },
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.POSTAL_CODE]: {
        name: INPUT_NAMES_FDATA.POSTAL_CODE,
        label: 'label.postalCode',
        placeholder: 'placeholder.postalCode',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        validation: validate(['required', 'maxLength'], { maxLength: 8 }),
        optValidation: { maxLength: 8 },
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.CITY]: {
        //locality
        name: INPUT_NAMES_FDATA.CITY,
        label: 'label.locality',
        placeholder: 'placeholder.locality',
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        inputType: 'string',
        validation: validate(['required', 'maxLength'], { maxLength: 64 }),
        optValidation: { maxLength: 64 },
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.COUNTY_ID]: {
        name: INPUT_NAMES_FDATA.COUNTY_ID,
        label: 'label.county',
        placeholder: 'placeholder.countySearch',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'string',
        type: 'autocomplete',
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.POTENCIA_CONTRATADA]: {
        name: INPUT_NAMES_FDATA.POTENCIA_CONTRATADA,
        label: 'label.contractedPower',
        placeholder: 'question.placeholder.subscribedPower',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'input',
        options: { limits: CONTRACTED_POWER_LIMITS.DEFAULT },
        type: 'input',
        step: 0.01,
        validation: validate(['required', 'number', 'min', 'maxNotIncluded'], CONTRACTED_POWER_LIMITS.DEFAULT),
        isRequired: true,
    },
    [INPUT_NAMES_FDATA.NUMERO_FASES_ID]: {
        name: INPUT_NAMES_FDATA.NUMERO_FASES_ID,
        label: 'label.numberOfPhases',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: false,
        options: {
            [getCompanyProfileIds().PPC]: [
                {
                    id: '1',
                    description: 'label.monophasic',
                },
                {
                    id: '2',
                    description: 'label.threephasic',
                },
            ],
        },
        type: 'radio',
        validation: validate(['required']),
    },
};
//#endregion /** B2C **/
