import { getProductInputs, getPVEquipmentsWithPanels } from 'api/product';
import { getBatteryPowers } from 'api/products/solarpvSimple';
import { getRemunerationOptions } from 'api/products/solarpv';
import { getEquipments, getStructuresTree } from 'api/utils';
import { PRODUCT_IDS } from 'constants/products';
import { AREA_TYPES, HOUSING_TYPES_B2C, spvSimpleActions, STEPS_OPEN_AREA, STEPS_RESERVED_AREA } from 'constants/products/solarpvSimple';
import { BASENAME_URL_OAREA_PPC_B2B, BASENAME_URL_OAREA_PPC_B2C } from 'constants/settings';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { UserContext } from 'contexts/userContext';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFacilityContractedPower, getFacilityID, getFacilityNumFases } from 'redux/selectors/facility';
import { getProductId } from 'redux/selectors/product';
import { getLastStructureLevel } from 'services/products/solarpvSimple';
import { isOpenArea } from 'services/settings';
import { getCompanyProfileIds } from 'services/user';
import { isAllStatusOK } from 'services/util/auxiliaryUtils';

//#region AbortControllers
let getInputsAbortController;
let getRemunerationOptsAbortController;
let getBatteriesAbortController;
let getStructuresTreeAbortController;
let getPVRangesAbortController;

function cleanup() {
    getInputsAbortController && getInputsAbortController.abort();
    getRemunerationOptsAbortController && getRemunerationOptsAbortController.abort();
    getBatteriesAbortController && getBatteriesAbortController.abort();
    getPVRangesAbortController && getPVRangesAbortController.abort();
    getStructuresTreeAbortController && getStructuresTreeAbortController.abort();
}
//#endregion AbortControllers

const useSolarPVSimpleMain = () => {
    const history = useHistory();
    const {
        isOArea,
        isB2C,
        forwardFormId,
        spvSimpleState: {
            isMounting,
            step,
            inputs: { housing_type },
        },
        spvSimpleDispatchHandler: dispatch,
    } = useContext(SPVSimpleContext);
    const { companyProfileId: _companyProfileId } = useContext(UserContext);

    const facilityID = useSelector((state) => getFacilityID(state?.facility));
    const companyProfileId = _companyProfileId ?? getCompanyProfileIds().PPC;
    const contractedPower = useSelector((state) => getFacilityContractedPower(state?.facility));
    const phasesNumberId = useSelector((state) => getFacilityNumFases(state?.facility));
    const productID = useSelector((state) => getProductId(state?.product)) ?? PRODUCT_IDS.SPV_SIMPLE;

    const fetchMouting = useCallback(async (facilityID, productID, contractedPower, phasesNumberId, companyProfileId) => {
        try {
            let isOAreaInit = isOpenArea();
            if (isOAreaInit) history.push(isB2C ? BASENAME_URL_OAREA_PPC_B2C : BASENAME_URL_OAREA_PPC_B2B, {}); //reset only PPC OArea
            let productId = isOAreaInit ? PRODUCT_IDS.SPV_SIMPLE : productID;

            //#region AbortControllers
            getRemunerationOptsAbortController && getRemunerationOptsAbortController.abort();
            getRemunerationOptsAbortController = new AbortController();
            getBatteriesAbortController && getBatteriesAbortController.abort();
            getBatteriesAbortController = new AbortController();
            getInputsAbortController && getInputsAbortController.abort();
            getInputsAbortController = new AbortController();
            getPVRangesAbortController && getPVRangesAbortController.abort();
            getPVRangesAbortController = new AbortController();
            //#endregion AbortControllers

            let requests = [
                getRemunerationOptions({ pid: productID }, isOAreaInit, getRemunerationOptsAbortController),
                !isOAreaInit ? getProductInputs({ facilityID, productID: productId }, getInputsAbortController) : null,
                isOAreaInit ? getEquipments({ facilityID, productID: productId }, isOAreaInit, getInputsAbortController) : null,
                getStructuresTree(PRODUCT_IDS.SPV_SIMPLE, isOAreaInit, getStructuresTreeAbortController),
                // getPVOptions({ pid: PRODUCT_IDS.SPV_SIMPLE },isOArea),
            ];

            let responses = await Promise.all(requests);
            let errors = isAllStatusOK(responses);
            if (errors?.length > 0) {
                throw new Error(JSON.stringify(errors));
            }

            let rspRanges = await getPVEquipmentsWithPanels(contractedPower, isOAreaInit, productID, getPVRangesAbortController);

            let rspBatteries;
            if (rspRanges?.status === StatusCodes.OK) {
                const range_id = rspRanges?.data?.data.find((range) => range.is_default)?.id;
                rspBatteries = await getBatteryPowers(isOAreaInit, { productId, range_id, is_kits: true }, getBatteriesAbortController);
            } else {
                throw new Error(JSON.stringify(rspRanges?.data));
            }

            dispatch(spvSimpleActions.SET_INIT, {
                isOArea: isOAreaInit,
                facilityID,
                productID: productId,
                contractedPower,
                phasesNumberId,
                companyProfileId,
                remunerationOpts: responses[0]?.data?.data ?? [],
                battery: rspBatteries?.data?.data[0] ?? {},
                pInputs: responses[1]?.data?.data ?? {},
                equipmentsOpts: responses[2]?.data?.data ?? [],
                mountingStructures: getLastStructureLevel(responses[3]?.data?.data) ?? [],
                rangesOpts: rspRanges?.data?.data ?? [],
                pvOptions: responses[4]?.data?.data ?? {},
            });
        } catch (error) {
            // console.log('ERROR fetchMouting ');
        }
    }, []); //eslint-disable-line

    const goBack = async () => {
        dispatch(spvSimpleActions.SET_STEP, previousStep());
    };

    const goForward = async () => {
        dispatch(spvSimpleActions.SET_STEP, nextStep());
    };

    const previousStep = () => {
        if (!isOArea) {
            switch (step) {
                case STEPS_RESERVED_AREA.HOUSING_TYPE:
                case STEPS_RESERVED_AREA.ADDRESS:
                default:
                    return STEPS_RESERVED_AREA.HOUSING_TYPE;
                case STEPS_RESERVED_AREA.ROOF_IDENTIFICATION:
                    return STEPS_RESERVED_AREA.ADDRESS;
                case STEPS_RESERVED_AREA.ROOF_DETAILS:
                    return STEPS_RESERVED_AREA.ROOF_IDENTIFICATION;
                case STEPS_RESERVED_AREA.SIMULATION: {
                    if (housing_type === (isB2C ? HOUSING_TYPES_B2C.GROUND : AREA_TYPES.GROUND)) {
                        return STEPS_RESERVED_AREA.ADDRESS;
                    }
                    return STEPS_RESERVED_AREA.ROOF_DETAILS;
                }
                case STEPS_RESERVED_AREA.BUSINESS_MODELS:
                    return STEPS_RESERVED_AREA.SIMULATION;
                case STEPS_RESERVED_AREA.DOWNLOAD:
                    return STEPS_RESERVED_AREA.BUSINESS_MODELS;
            }
        } else {
            switch (step) {
                case STEPS_OPEN_AREA.HOUSING_TYPE:
                case STEPS_OPEN_AREA.ADDRESS:
                default:
                    return STEPS_OPEN_AREA.HOUSING_TYPE;
                case STEPS_OPEN_AREA.ROOF_IDENTIFICATION:
                    return STEPS_OPEN_AREA.ADDRESS;
                case STEPS_OPEN_AREA.ROOF_DETAILS:
                    return STEPS_OPEN_AREA.ROOF_IDENTIFICATION;
                case STEPS_OPEN_AREA.CONSUMPTION_DETAILS: {
                    if (housing_type === (isB2C ? HOUSING_TYPES_B2C.GROUND : AREA_TYPES.GROUND)) {
                        return STEPS_OPEN_AREA.ADDRESS;
                    }
                    return STEPS_OPEN_AREA.ROOF_DETAILS;
                }
                case STEPS_OPEN_AREA.SIMULATION:
                    return STEPS_OPEN_AREA.CONSUMPTION_DETAILS;
            }
        }
    };

    const nextStep = () => {
        if (!isOArea) {
            switch (step) {
                case STEPS_RESERVED_AREA.HOUSING_TYPE:
                    return STEPS_RESERVED_AREA.ADDRESS;
                case STEPS_RESERVED_AREA.ADDRESS:
                    return STEPS_RESERVED_AREA.ROOF_IDENTIFICATION;
                case STEPS_RESERVED_AREA.ROOF_IDENTIFICATION:
                    return STEPS_RESERVED_AREA.ROOF_DETAILS;
                case STEPS_RESERVED_AREA.ROOF_DETAILS:
                    return STEPS_RESERVED_AREA.SIMULATION;
                case STEPS_RESERVED_AREA.SIMULATION:
                    return STEPS_RESERVED_AREA.BUSINESS_MODELS;
                case STEPS_RESERVED_AREA.BUSINESS_MODELS:
                    return STEPS_RESERVED_AREA.DOWNLOAD;
                default:
                    return STEPS_RESERVED_AREA.HOUSING_TYPE;
            }
        } else {
            switch (step) {
                case STEPS_OPEN_AREA.HOUSING_TYPE:
                    return STEPS_OPEN_AREA.ADDRESS;
                case STEPS_OPEN_AREA.ADDRESS:
                    return STEPS_OPEN_AREA.ROOF_IDENTIFICATION;
                case STEPS_OPEN_AREA.ROOF_IDENTIFICATION:
                    return STEPS_OPEN_AREA.ROOF_DETAILS;
                case STEPS_OPEN_AREA.ROOF_DETAILS:
                    return STEPS_OPEN_AREA.CONSUMPTION_DETAILS;
                case STEPS_OPEN_AREA.CONSUMPTION_DETAILS:
                    return STEPS_OPEN_AREA.SIMULATION;
                default:
                    return STEPS_OPEN_AREA.HOUSING_TYPE;
            }
        }
    };

    //Mounting
    useEffect(() => {
        fetchMouting(facilityID, productID, contractedPower, phasesNumberId, companyProfileId);
        return () => {
            //cleanup
            cleanup();
        };
    }, [fetchMouting, facilityID, productID, contractedPower, phasesNumberId, companyProfileId]);

    return { forwardFormId, isMounting, goBack, goForward, isOArea, step };
};

export default useSolarPVSimpleMain;
