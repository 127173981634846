import { DEBOUNCE_TIME } from 'constants/endpoints';
import {
    BASENAME_URL,
    BASENAME_URL_FACILITIES,
    BASENAME_URL_FACILITY,
    BASENAME_URL_HOME,
    BASENAME_URL_PROCESSES,
    BASENAME_URL_USER,
    CPROFILE_IDS_WITH_DESCRIPTION,
    CPROFILE_IDS_WITH_SALES_WHITE_LOGO,
    MIN_CHARACTERES_TO_REQUEST,
} from 'constants/settings';
import useFacilities from 'hooks/facilities';
import useSearchClients from 'hooks/search/clients';
import useSearchFacilities from 'hooks/search/facilities';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'services/@efz/axios';
import { debounce, isDefined } from 'services/util/auxiliaryUtils';
import { useHistory } from 'react-router-dom';
import { resetFacility, setFacility } from 'redux/actions/facility';
import { requestGetClient, requestGetClientDataByFacility, resetClient, setClient, setClientHistory } from 'redux/actions/client';
import { HeaderContext } from 'contexts/headerContext';
import { UserContext } from 'contexts/userContext';
import { StatusCodes } from 'http-status-codes';
import { getFacilityInfo } from 'api/facility';
import { SolutionsContext } from 'contexts/solutions/solutionsContext';
import { getUserHistoryClients } from 'api/user';
import { useAuthStore } from 'store/auth';
import { useUserStore } from 'store/user';

const useHeader = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const { logout } = useAuthStore();
    const { switchLanguage } = useUserStore();

    const {
        facilityID,
        facilityCPE,
        detailedAddress,
        facilityAddress,
        clientID,
        forwardFormFacility,
        clientDescription,
        clientNIPC,
        clientFirstName,
        clientSurname,
        clientVAT,
        hasClientFacilitySearch,
        isOpenLangSwitcher,
        setIsOpenLangSwitcher,
        isOpenClientCard,
        setIsOpenClientCard,
        isOpenFacilityCard,
        setIsOpenFacilityCard,
        isOpenUserInfo,
        setIsOpenUserInfo,
    } = useContext(HeaderContext);

    const {
        userLocale,
        locale,
        userTypeID,
        companyProfileId,
        userUsername,
        userChannelIDGroup,
        salesSegmentsList,
        hasUniqueSalesSegment,
        isB2C,
    } = useContext(UserContext);

    const { resetAllHandler } = useContext(SolutionsContext);

    const { isRequestingSClients, handleFetchSearchClients, sClientsData, sClientsHistory, resetSearchClientsHistory } = useSearchClients();

    const { facilitiesList, handleFetchSearchFacilitiesByClient, isRequestingFacilities, handleFetchSearchFacilities } =
        useSearchFacilities();

    const { resetFacilitiesHandler } = useFacilities();

    const [facilityInfo, setFacilityInfo] = useState({});

    const handleToggleLangSwitcherCard = useCallback(() => setIsOpenLangSwitcher(!isOpenLangSwitcher), [isOpenLangSwitcher]); //eslint-disable-line
    const handleSwitchLanguage = useCallback((locale) => switchLanguage(locale), []); //eslint-disable-line

    const hasWhiteSalesLogo = useMemo(() => CPROFILE_IDS_WITH_SALES_WHITE_LOGO.includes(companyProfileId), [companyProfileId]);
    const hasCompanyDescription = useMemo(() => CPROFILE_IDS_WITH_DESCRIPTION.includes(companyProfileId), [companyProfileId]);

    //#region SalesSegment
    const handleSwitchSalesSegment = useCallback(async (newSegment, { setIsDialogOpen, setIsMenuOpen, setIsRequestingCHistory }) => {
        try {
            //TODO: devemos fazer o redirect para a pagina Solutions, (Apenas mantemos na mesma pagina quando estamos na lista de processo, tudo o resto deve ser encaminhado para a pagina de solutions e fazer reset a todos os states)
            const ssValue = salesSegmentsList?.find((el) => el.id === newSegment)?.description ?? 'B2B';
            localStorage.setItem('salesSegment', ssValue);
            axios.defaults.headers.common['Sales-Segment'] = ssValue;

            let rsp = await getUserHistoryClients();
            setIsRequestingCHistory(false);
            dispatch(setClientHistory(rsp?.length > 0 ? rsp : []));
            // pathnames in this array will reload;
            // otherwise, it will jump to '/solutions'
            const pathnamesWhitelist = [BASENAME_URL_PROCESSES];
            handlerClearClient(pathnamesWhitelist.includes(history.location.pathname));
            setIsDialogOpen(false);
            setIsMenuOpen(false);
        } catch (err) {
            // TODO sentry
        }
    }, []); //eslint-disable-line

    //#region UserCard
    const handleToggleUserCard = useCallback(() => setIsOpenUserInfo(!isOpenUserInfo), [isOpenUserInfo]); //eslint-disable-line
    const handleUserSignOut = useCallback(() => {
        window._paq.push(['deleteCustomDimension', userTypeID]);
        logout();
    }, []); //eslint-disable-line

    //#region ClientCard
    const handleToggleClientCard = useCallback(() => setIsOpenClientCard(!isOpenClientCard), [isOpenClientCard]); //eslint-disable-line
    const handlerSelectClient = useCallback(
        (_client) => {
            if (_client?.id && _client.id !== clientID) {
                resetFacilitiesHandler();
                dispatch(resetFacility());
                dispatch(requestGetClient(_client.id)); // Requests client full data and puts it into redux
                dispatch(setClient(_client));
                resetSearchClientsHistory(_client);
            }
            setIsOpenClientCard(false);
        },
        [clientID] //eslint-disable-line
    );
    const handlerClearClient = useCallback((stayOnLocation = false) => {
        dispatch(resetClient());
        dispatch(resetFacility());
        resetFacilitiesHandler();
        resetAllHandler();
        if (!stayOnLocation) history.replace(BASENAME_URL_HOME);
        if (stayOnLocation) {
            window.location.reload();
        }
    }, []); //eslint-disable-line
    //#endregion

    //#region FacilityCard
    const handleToggleFacilityCard = useCallback(() => setIsOpenFacilityCard(!isOpenFacilityCard), [isOpenFacilityCard]); //eslint-disable-line
    const handlerSelectFacilityWithClientSelected = useCallback((_facility) => {
        dispatch(setFacility(_facility)); // Set facility basic data - full data is fetched once inside its container
        setIsOpenFacilityCard(false);
        //Link
        history.push(BASENAME_URL_FACILITY);
    }, []); //eslint-disable-line
    const handlerSelectFacilityWithoutClientSelected = useCallback((_facility) => {
        setIsOpenFacilityCard(false);
        dispatch(setFacility(_facility)); // Set facility basic data - full data is fetched once inside its container
        dispatch(requestGetClientDataByFacility({ clientID: _facility?.cliente_id, history }));
    }, []); //eslint-disable-line
    const handlerSeeAllFacilities = useCallback(() => {
        setIsOpenFacilityCard(false);
        history.push(BASENAME_URL_FACILITIES);
    }, []); //eslint-disable-line
    const handlerGoToFacility = useCallback(() => {
        setIsOpenFacilityCard(false);
        history.push(BASENAME_URL_FACILITY);
    }, []); //eslint-disable-line
    const handlerCreateFacility = useCallback(() => {
        setIsOpenFacilityCard(false);
        history.push(`${BASENAME_URL_FACILITY}/create`);
    }, []); //eslint-disable-line
    const handlerGoToUser = useCallback(() => {
        setIsOpenUserInfo(false);
        history.push(BASENAME_URL_USER);
    }, []); //eslint-disable-line

    const handlerClearFacility = useCallback(() => {
        setIsOpenFacilityCard(false);
        history.replace(`${BASENAME_URL}app/facilities`);
        dispatch(resetFacility());
    }, []); //eslint-disable-line
    //#endregion

    //Debouncing the client search request
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedHandlerSearchRequestClient = useCallback(
        debounce((payload) => handleFetchSearchClients(payload), DEBOUNCE_TIME),
        []
    );
    //Debouncing the client facilities search request
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedHandlerFetchSearchFacilitiesByClient = useCallback(
        debounce((payload) => handleFetchSearchFacilitiesByClient(payload), DEBOUNCE_TIME),
        []
    );
    //Debouncing the all facilities search request
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedHandlerSearchFacilities = useCallback(
        debounce((payload) => handleFetchSearchFacilities(payload), DEBOUNCE_TIME),
        []
    );

    // Handle Change
    const handleChangeAutocomplete = useCallback(
        (data) => {
            //eslint-disable-line
            let inputName = data.name; //Get changed input field name
            let inputValue = data.value; //Get changed input field value

            let isValidToRequest = inputValue?.length >= MIN_CHARACTERES_TO_REQUEST; // requests the api only if searcg string has appropriate length

            //Flags Client
            const isClient = inputName.includes('client');
            const isClientSearch = inputName === 'clientSearch';
            //Flags Facility
            const isFacility = inputName.includes('facility');
            const isFacilitySearch = inputName === 'facilitySearch';

            if (isClient) {
                //Conditionals
                if (isClientSearch) {
                    if (!isValidToRequest) return;
                    //Loads Data
                    delayedHandlerSearchRequestClient({ page: 1, search: inputValue });
                } else {
                    //Select Option
                    handlerSelectClient(inputValue);
                    //Link
                    history.push(`${BASENAME_URL}app/facilities`);
                }
            } else if (isFacility) {
                // If a client is selected
                if (isFacilitySearch && isDefined(clientID)) {
                    if (!isValidToRequest) return;
                    //Loads Data
                    delayedHandlerFetchSearchFacilitiesByClient({
                        clientId: clientID,
                        filters: { page: 1, search: inputValue },
                    });
                }
                // If no client is selected - facility only search
                else if (isFacilitySearch && !isDefined(clientID)) {
                    if (!isValidToRequest) return;
                    // Request search facility without client ID
                    delayedHandlerSearchFacilities({ page: 1, search: inputValue });
                } else {
                    //Select Option
                    if (isDefined(clientID))
                        handlerSelectFacilityWithClientSelected(inputValue); // Has client selected
                    else handlerSelectFacilityWithoutClientSelected(inputValue); // Doesn't have client selected - facility only search
                }
            }
        },
        [clientID] //eslint-disable-line
    );

    const fetchFacilityInfo = async () => {
        if (isDefined(facilityID)) {
            const res = await getFacilityInfo({ facilityID: facilityID });
            if (res?.status === StatusCodes.OK) {
                setFacilityInfo(res?.data?.data[0]);
            }
        }
    };

    useEffect(() => {
        fetchFacilityInfo();
    }, [facilityID]); //eslint-disable-line

    return {
        isOpenLangSwitcher,
        isOpenUserInfo,
        handleToggleLangSwitcherCard,
        handleToggleUserCard,
        handleSwitchLanguage,
        userLocale,
        locale,
        isB2C,
        userTypeID,
        userUsername,
        facilityID,
        facilityCPE,
        isOpenFacilityCard,
        handleToggleFacilityCard,
        companyProfileId,
        detailedAddress,
        facilityAddress,
        facilitiesList,
        isRequestingFacilities,
        handleChangeAutocomplete,
        handlerSeeAllFacilities,
        handlerGoToFacility,
        handlerCreateFacility,
        clientID,
        handlerClearFacility,
        forwardFormFacility,
        userChannelIDGroup,
        isOpenClientCard,
        setIsOpenClientCard,
        handleToggleClientCard,
        sClientsData,
        sClientsHistory,
        isRequestingSClients,
        handlerClearClient,
        clientNIPC,
        clientDescription,
        clientFirstName,
        clientSurname,
        clientVAT,
        handleUserSignOut,
        handlerGoToUser,
        hasClientFacilitySearch,
        salesSegmentsList,
        hasUniqueSalesSegment,
        handleSwitchSalesSegment,
        facilityInfo,
        hasWhiteSalesLogo,
        hasCompanyDescription,
    };
};

export default useHeader;
