import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FacilitiesContext } from 'contexts/facilitiesContext';
import { intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';
import { StatusCodes } from 'http-status-codes';
import { getFacilitiesSearch, getFacilitiesSearchByClient, postAddFacility } from 'api/facilities';
import { setClientFacilities } from 'redux/actions/client';
import { QUERY_LIMIT_FACILITIES } from 'constants/search';
import { toast } from 'react-toastify';
import { requestGetFacilitySimple } from 'redux/actions/facility';
import { BASENAME_URL_SOLUTIONS } from 'constants/settings';
import { getFacilityInfo } from 'api/facility';
import { SolutionsContext } from 'contexts/solutions/solutionsContext';
import { setFacility as setFacilityRedux } from 'redux/actions/facility';

let getFacilitisAbortGetFacilities;
let getFInfoAbortController;

const useFacilities = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [isRequestingAddFacility, setIsRequestingAddFacility] = useState(false);

    const {
        facilitiesList,
        setFacilitiesList,
        isRequesting,
        setIsRequesting,
        isMounting,
        setIsMounting,
        hasMoreFacilities,
        setHasMoreFacilities,
        equipments,
        setEquipments,
        tariffsSelected,
        setTariffsSelected,
        setFacility,
        clientID,
    } = useContext(FacilitiesContext);

    const { resetFacilityHandler } = useContext(SolutionsContext);

    const handleFetchFacilities = useCallback(
        async (payload) => {
            const { clientId, filters, isMounting } = payload;
            setIsMounting(isMounting ?? false);
            setIsRequesting(true);
            let dataFetched = isFieldDefined(clientId) ? await getFacilitiesSearchByClient(payload) : await getFacilitiesSearch(filters);
            if (dataFetched?.status === StatusCodes.OK) {
                let facilities = payload?.clientChanged ? [...dataFetched.data.data] : [...facilitiesList, ...dataFetched.data.data] ?? [];
                setFacilitiesList(facilities);
                dispatch(setClientFacilities(facilities));
                setIsMounting(false);
            }
            setIsRequesting(false);
        },
        [facilitiesList] // eslint-disable-line
    );

    const handleAddFacility = async (payload) => {
        setIsRequestingAddFacility(true);
        const rspAddFacility = await postAddFacility(payload);

        if (rspAddFacility?.status === StatusCodes.OK) {
            dispatch(requestGetFacilitySimple({ facilityID: rspAddFacility.data?.data, history }));
            updateContextFacility(rspAddFacility.data?.data, clientID);
        } else {
            setIsRequestingAddFacility(false);
        }
    };

    const updateContextFacility = useCallback(async (facilityId, clientId) => {
        let requests = [
            getFacilityInfo({ facilityID: facilityId }, getFInfoAbortController),
            getFacilitiesSearchByClient(
                { clientId, filters: { page: 1, sort: '-saving_estimate_sum', limit: 20 } },
                getFacilitisAbortGetFacilities
            ),
        ];
        let responses = await Promise.all(requests);
        // Validate requests
        for (let idx = 0; idx < responses.length; idx++) {
            let responsesFail = responses.filter((rqs) => !!rqs?.data)?.filter((item) => item.status !== StatusCodes.OK);
            if (responsesFail?.length > 0) {
                let errors = [];
                responsesFail.forEach((el) => {
                    errors.push({
                        url: el.request?.responseURL,
                        response: el?.request?.response,
                    });
                });
                throw new Error(JSON.stringify(errors));
            }
        }

        let facilityData = responses[0]?.data?.data?.[0] ?? null;
        let facilities = responses[1]?.data?.data ?? [];
        resetFacilityHandler();
        setFacility(facilityData);
        setFacilitiesList(facilities);
        dispatch(setFacilityRedux({ ...facilityData, list: facilities }));
        dispatch(setClientFacilities(facilities));
        setIsRequestingAddFacility(false);
        history.push(BASENAME_URL_SOLUTIONS);
        toast.success(intlMessages('server.success.addFacility'));

        return true;
    }, []); //eslint-disable-line

    const handleGoTo = useCallback((facility) => {
        dispatch(setFacility(facility));
        history.push('facility');
    }, []); // eslint-disable-line

    const resetFacilitiesHandler = () => {
        setFacilitiesList([]);
    };

    useEffect(() => {
        setHasMoreFacilities(facilitiesList.length % QUERY_LIMIT_FACILITIES === 0);
    }, [facilitiesList, setHasMoreFacilities]);

    return {
        facilitiesList,
        setFacilitiesList,
        isRequesting,
        setIsRequesting,
        isMounting,
        setIsMounting,
        hasMoreFacilities,
        setHasMoreFacilities,
        equipments,
        setEquipments,
        tariffsSelected,
        setTariffsSelected,
        handleFetchFacilities,
        handleGoTo,
        resetFacilitiesHandler,
        handleAddFacility,
        isRequestingAddFacility,
    };
};

export default useFacilities;
