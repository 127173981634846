import copy from 'fast-copy';
import { Reducer } from 'react';

// Constants
import {
    DEFAULT_LOADINGS,
    HP_FAST_ACTIONS,
    /* HP_FAST_DEFAULT_FORM_VALUES, */
    HP_FAST_LOADINGS,
    HP_FAST_STEPS,
} from 'constants/products/hpFast';

// Interfaces
import { IHPFastGlobalDialogState, IHPFastProposal, IHPFastState, THPFastAction } from 'interfaces/products/hp/fast';
import { THPFastForm } from 'interfaces/products/hp/fast/form';

// Services
import { getPayloadKPIsStepExtras, mergeObjects } from 'services/products/hp/fast';

export const initialHPFastState: IHPFastState = {
    isMounting: DEFAULT_LOADINGS.mounting,
    inputs: {} as THPFastForm,
    step: HP_FAST_STEPS.PROPERTY,
    loadings: DEFAULT_LOADINGS,
    options: {
        extraEquipmentCatModel: [],
        propertyHeatSources: [],
        installationPrices: {
            installation_fixed_price: 0,
            installation_labor_price_per_hour: 0,
        },
        definePropertyActiveQuestions: [],
        definePropertyCombinationsArray: [],
    },
    kpis: {},
    functions: {
        fetchKPIsHandler: (_values, _step) => {
            return new Promise<any>(() => {});
        },
    },
    globalDialogState: {
        kpis: false,
        changesConfirmation: false,
    },
    proposal: {} as IHPFastProposal,
};

export const hpFastReducer: Reducer<IHPFastState, THPFastAction> = (state = initialHPFastState, action) => {
    switch (action.type) {
        case HP_FAST_ACTIONS.SET_MOUNT_DATA: {
            const { functions, inputs, loadings, options, step } = action.payload;

            return {
                ...state,
                functions,
                inputs: inputs as THPFastForm,
                isMounting: false,
                loadings: {
                    ...state.loadings,
                    ...loadings,
                    mounting: loadings.mounting,
                },
                options,
                step,
            };
        }

        case HP_FAST_ACTIONS.SET_KPIS: {
            return {
                ...state,
                kpis: mergeObjects(state?.kpis, action?.payload),
            };
        }

        case HP_FAST_ACTIONS.RESET_KPIS: {
            return {
                ...state,
                kpis: {},
            };
        }

        case HP_FAST_ACTIONS.SET_LOADINGS: {
            const isGeneralLoadingActive = Object.entries(action.payload).some(
                ([name, value]) => name !== HP_FAST_LOADINGS.GENERAL && value === true
            );

            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    ...action.payload,
                    [HP_FAST_LOADINGS.GENERAL]: isGeneralLoadingActive,
                },
            };
        }

        case HP_FAST_ACTIONS.SET_GLOBAL_DIALOG_STATE: {
            return {
                ...state,
                globalDialogState: {
                    ...(Object.fromEntries(
                        Object.keys(state.globalDialogState).map((key) => [key, false])
                    ) as any as IHPFastGlobalDialogState),
                    ...action.payload,
                },
            };
        }

        case HP_FAST_ACTIONS.NAVIGATE: {
            const { inputs, step, subStep } = action.payload;
            const isGeneralLoadingActive = Object.entries(state.loadings).some(
                ([name, value]) => ![HP_FAST_LOADINGS.GENERAL, HP_FAST_LOADINGS.NEXT].some((n) => n === name) && value === true
            );

            return {
                ...state,
                inputs: inputs as THPFastForm,
                loadings: {
                    ...state.loadings,
                    [HP_FAST_LOADINGS.NEXT]: false,
                    [HP_FAST_LOADINGS.GENERAL]: isGeneralLoadingActive,
                },
                step,
                subStep,
            };
        }

        case HP_FAST_ACTIONS.SET_BUSINESS_MODELS: {
            const {
                facility,
                currentFormValues,
                grants,
                installation_fixed_price,
                installation_labor_hours,
                installation_labor_price,
                margin,
                offer_edition,
                step,
                subStep,
                tipo_modelo_negocio_id,
            } = action.payload;

            const inputs_fe = currentFormValues;

            return {
                ...state,
                proposal: {
                    facility,
                    tipo_modelo_negocio_id,
                    inputs: {
                        ...getPayloadKPIsStepExtras(copy(currentFormValues), facility.id).inputs,
                        grants,
                        installation_fixed_price,
                        installation_labor_hours,
                        installation_labor_price,
                        margin,
                        offer_edition,
                        inputs_fe,
                    },
                },
                step,
                subStep,
            };
        }

        case HP_FAST_ACTIONS.RESET: {
            return {
                ...state,
                inputs: {} as THPFastForm,
                step: HP_FAST_STEPS.PROPERTY,
                subStep: undefined,
                kpis: {},
            };
        }
        case HP_FAST_ACTIONS.SET_EXTRA_EQUIPMENT_CAT_MODEL: {
            const { payload } = action;

            return {
                ...state,
                options: {
                    ...state.options,
                    extraEquipmentCatModel: payload,
                },
            };
        }
        default:
            return state;
    }
};
