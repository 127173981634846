import { createContext, useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { array, bool, func, string } from 'prop-types';

// Selectors
import { getClientID } from 'redux/selectors/client';
import { getBrand } from 'redux/selectors/settings';

import { setFacility } from 'redux/actions/facility';
import { requestGetCountiesWithSearch } from 'redux/actions/utils';
import { UserContext } from './userContext';

const FacilitiesContext = createContext({
    clientID: string,
    facilitiesList: array,
    isRequesting: bool,
    isMounting: bool,
    hasMoreFacilities: bool,
    equipments: array,
    companyProfileId: string,
    headBrand: string,
    userChannelIDGroup: string,
    handleSetFacilitiesByToken: func,
});
FacilitiesContext.displayName = 'efz-FacilitiesContext';

const FacilitiesProvider = ({ children }) => {
    const { companyProfileId, userChannelIDGroup } = useContext(UserContext);
    const clientID = useSelector((state) => getClientID(state?.client));
    const headBrand = useSelector((state) => getBrand(state?.settings));

    const [facilitiesList, setFacilitiesList] = useState([]);
    const [isRequesting, setIsRequesting] = useState(false);
    const [isMounting, setIsMounting] = useState(true);
    const [hasMoreFacilities, setHasMoreFacilities] = useState(false);
    // B2C
    const [equipments, setEquipments] = useState([]);
    const [tariffsSelected, setTariffsSelected] = useState([]);

    const handleSetFacilitiesByToken = useCallback(
        (list) => {
            setFacilitiesList(list);
        },
        [setFacilitiesList]
    );

    return (
        <FacilitiesContext.Provider
            value={{
                clientID,
                facilitiesList,
                setFacilitiesList,
                isRequesting,
                setIsRequesting,
                isMounting,
                setIsMounting,
                hasMoreFacilities,
                setHasMoreFacilities,
                equipments,
                setEquipments,
                tariffsSelected,
                setTariffsSelected,
                companyProfileId,
                headBrand,
                userChannelIDGroup,
                setFacility,
                requestGetCountiesWithSearch,
                handleSetFacilitiesByToken,
            }}
        >
            {children}
        </FacilitiesContext.Provider>
    );
};

export { FacilitiesContext, FacilitiesProvider };
