import { Button, IconButton } from '@save2compete/efz-design-system';

// Components
import LoadingAlert from 'components/core/dialogs/DialogsAlert/LoadingAlert';
import Loading from 'components/core/Loading';
import IntlMessages from 'components/util/IntlMessages';
import SendEmailDialog from './Dialogs/SendEmailDialog';

// Constants
import { PRODUCT_IDS } from 'constants/products';
import { STATUS_CRM } from 'constants/proposal';

// Icons
import { ReactComponent as HourglassIcon } from 'assets/images/icons/svg/hourglass.svg';
import { ReactComponent as CRMCheckIcon } from 'assets/images/icons/svg/round-checkmark.svg';
import { ReactComponent as CRMFailIcon } from 'assets/images/icons/svg/round-fail.svg';

// Interfaces
import { TIntl } from 'types/utils';

// Services / Hooks
import useEditProposalDescription from 'hooks/proposal/useEditProposalDescription';
import useSendLinkARtoEmail from 'hooks/sendLinkARtoEmail';
import useProposalDetails from 'hooks/sendToCRM';
import { getCompanyProfileIds } from 'services/user';
import { formatData, isDefined, isEmpty, isFieldDefined } from 'services/util/auxiliaryUtils';

const GenerateDocuments = ({
    proposalID,
    isHeadingSupportDocs,
    has_proposal_description_feature = false,
    errorSupportDocs,
    contractAvailable,
    getSupportDocsHandler,
    isGettingSupportDocs,
    companyProfileId,
    canSendToCRM,
    productID,
    hasAccessToProposals,
    fetchAgainHandler,
}) => {
    const { submit, input, handleSubmit, requesting, errors, isDirty } = useEditProposalDescription(proposalID, {});

    const { isRequesting, fetchSendToCRM, errorAutomatically, dataSendToCRM, sendWhenDocsDownload, isRequestingSimID } = useProposalDetails(
        proposalID,
        contractAvailable,
        productID
    );

    const { isOpenSendEmailDialog, setIsOpenSendEmailDialog, isSedingEmail, fetchSendARLinkToEmail } = useSendLinkARtoEmail();

    const headSuccess = !isHeadingSupportDocs && !errorSupportDocs && contractAvailable;

    const noButton = PRODUCT_IDS.SPV === Number(productID) && [getCompanyProfileIds().EDP_ES].includes(companyProfileId);

    let icon;
    let titleLabel;
    let subTitleLabel;

    if (isHeadingSupportDocs) {
        icon = <Loading size={50} />;
        titleLabel = 'page.proposal.text.generatingDocuments';
        subTitleLabel = 'page.proposal.text.subTitle';
    } else {
        if (headSuccess) {
            icon = (
                <div className="check-icon check-success animate">
                    <span className="line tip animateSuccessTip"></span>
                    <span className="line long animateSuccessLong"></span>
                    <div className="fix"></div>
                </div>
            );
            titleLabel = 'page.proposal.text.sucess';
            subTitleLabel = 'page.proposal.text.subTitle';
        } else {
            icon = <HourglassIcon className="icon-svg" />;
            titleLabel = 'page.proposal.text.error';
            subTitleLabel = 'page.proposal.text.subTitle.error';
        }
    }

    const onSubmitDescription = (formData) => {
        submit(formData);
    };

    return (
        <>
            <div className="proposal-documents-generate">
                {icon}
                <span className="proposal-documents-title">
                    <IntlMessages id={titleLabel} />
                </span>
                <div className="proposal-number">
                    <span>
                        <IntlMessages id="page.proposal.text.proposalID" />: {proposalID}
                    </span>
                </div>
                {has_proposal_description_feature && (
                    <div className="proposal-input-description">
                        <label>
                            <IntlMessages id="page.proposal.text.form.description" />
                        </label>
                        <div className="proposal-input-description-form">
                            <div className="proposal-input-description-form-input w-100">{input}</div>
                            <IconButton
                                variant="action"
                                icon="saveDisk"
                                disabled={!isEmpty(errors) || requesting || !isDirty}
                                onClick={handleSubmit(onSubmitDescription)}
                                dataTestId="save-description"
                            />
                        </div>
                    </div>
                )}

                {!isHeadingSupportDocs && (
                    <div className="proposal-documents-actions">
                        {headSuccess ?
                            <>
                                <p className="proposal-message">
                                    <IntlMessages id={subTitleLabel} />
                                </p>
                                {!hasAccessToProposals && (
                                    <div className="text-center mb-2">
                                        <b className="error-color">
                                            <IntlMessages id="page.proposal.noAccessToProposal" />
                                        </b>
                                    </div>
                                )}
                                <>
                                    {isGettingSupportDocs && <Loading isDownload size={180} />}
                                    {!isGettingSupportDocs && (
                                        <Button
                                            className="mx-3 proposal-documents-button"
                                            disabled={isGettingSupportDocs || isRequesting || isSedingEmail || !hasAccessToProposals}
                                            variant="secondary"
                                            type="submit"
                                            onClick={() => {
                                                getSupportDocsHandler();
                                                if ([PRODUCT_IDS.HP].includes(productID)) {
                                                    // @ts-ignore
                                                    window._paq.push(['trackEvent', 'Proposal', 'Click', 'Download']);
                                                }
                                            }}
                                            icon="startDownload"
                                            dataTestId="download-documents"
                                        >
                                            <IntlMessages id="page.proposal.button.download" />
                                        </Button>
                                    )}
                                    <LoadingAlert isOpen={isGettingSupportDocs} title="label.downloading" isDownload />
                                </>
                                {!sendWhenDocsDownload && (canSendToCRM || isFieldDefined(dataSendToCRM?.status_crm)) && (
                                    <>
                                        {!noButton ?
                                            <Button
                                                className={'mx-3 proposal-documents-button'}
                                                disabled={
                                                    isGettingSupportDocs ||
                                                    isRequesting ||
                                                    isDefined(dataSendToCRM?.status_crm) ||
                                                    dataSendToCRM?.disabled ||
                                                    isSedingEmail ||
                                                    !hasAccessToProposals
                                                }
                                                variant="secondary"
                                                type="submit"
                                                onClick={() => fetchSendToCRM(proposalID)}
                                                icon="startSendSimple"
                                                loading={isRequesting}
                                                dataTestId="send-to-crm"
                                            >
                                                <div>
                                                    <IntlMessages
                                                        id={`${isFieldDefined(dataSendToCRM?.status_crm) || dataSendToCRM?.disabled ? 'label.statusCRM' : 'label.sendToCRM'}`}
                                                    />

                                                    {!!dataSendToCRM?.status_crm && (
                                                        <p
                                                            className={`state-crm m-0 ${[STATUS_CRM.SUBMITTED, STATUS_CRM.COMPLETED].includes(dataSendToCRM?.status_crm) ? 'success' : 'fail'} `}
                                                        >
                                                            {[STATUS_CRM.SUBMITTED].includes(dataSendToCRM?.status_crm) ?
                                                                <>
                                                                    <CRMCheckIcon className="me-1 mb-1" />
                                                                    {dataSendToCRM?.status_crm === STATUS_CRM.COMPLETED && (
                                                                        <CRMCheckIcon className="me-1 mb-1" />
                                                                    )}
                                                                </>
                                                            :   <CRMFailIcon className="me-1 mb-1" />}
                                                            <IntlMessages
                                                                id={`label.stateCRM.${dataSendToCRM?.status_crm}` as TIntl}
                                                                values={{
                                                                    date: formatData(dataSendToCRM?.data_envio_para_crm),
                                                                }}
                                                            />
                                                        </p>
                                                    )}
                                                </div>
                                            </Button>
                                        :   <div className="generateDocument-autoSend">
                                                <div className="generateDocument-autoSend-label">
                                                    {isRequesting || isGettingSupportDocs || isRequestingSimID || !hasAccessToProposals ?
                                                        <>
                                                            <Loading size={14} />
                                                            <span>
                                                                <IntlMessages id="label.sendToCRM" />
                                                            </span>
                                                        </>
                                                    :   <>
                                                            <span>
                                                                <IntlMessages
                                                                    id={`${
                                                                        isDefined(errorAutomatically) ? 'label.failedSendToCRM'
                                                                        : (
                                                                            isFieldDefined(dataSendToCRM?.status_crm) ||
                                                                            dataSendToCRM?.disabled
                                                                        ) ?
                                                                            'label.statusCRM'
                                                                        :   'label.sentToCRM'
                                                                    }`}
                                                                />
                                                            </span>
                                                        </>
                                                    }
                                                </div>

                                                {dataSendToCRM?.status_crm ?
                                                    <p
                                                        className={`state-crm m-0 ${[STATUS_CRM.SUBMITTED, STATUS_CRM.COMPLETED].includes(dataSendToCRM?.status_crm) ? 'success' : 'fail'} `}
                                                    >
                                                        {[STATUS_CRM.SUBMITTED].includes(dataSendToCRM?.status_crm) ?
                                                            <>
                                                                <CRMCheckIcon className="me-1 mb-1" />
                                                                {dataSendToCRM?.status_crm === STATUS_CRM.COMPLETED && (
                                                                    <CRMCheckIcon className="me-1 mb-1" />
                                                                )}
                                                            </>
                                                        :   <CRMFailIcon className="me-1 mb-1" />}
                                                        <IntlMessages
                                                            id={`label.stateCRM.${dataSendToCRM?.status_crm}` as TIntl}
                                                            values={{
                                                                date: formatData(dataSendToCRM?.data_envio_para_crm),
                                                            }}
                                                        />
                                                    </p>
                                                :   <>
                                                        {isDefined(errorAutomatically) && (
                                                            <p className="state-crm m-0 fail">
                                                                <CRMFailIcon className="me-1 mb-1" />
                                                                <IntlMessages id={`${errorAutomatically}` as TIntl} />
                                                            </p>
                                                        )}
                                                    </>
                                                }
                                            </div>
                                        }
                                    </>
                                )}
                                {/*@ts-ignore+*/}
                                {[getCompanyProfileIds().EFZ].includes(companyProfileId) && [PRODUCT_IDS.EVC].includes(Number(productID)) ?
                                    <Button
                                        className={'mx-3 proposal-documents-button'}
                                        disabled={isGettingSupportDocs || isRequesting || isSedingEmail || !hasAccessToProposals}
                                        variant="secondary"
                                        type="submit"
                                        onClick={() => setIsOpenSendEmailDialog(true)}
                                        icon="startSendSimple"
                                        loading={isSedingEmail}
                                        dataTestId="send-email"
                                    >
                                        <IntlMessages id="page.proposal.button.SendARLinkToEmail" />
                                    </Button>
                                :   null}
                            </>
                        :   <>
                                <p className="proposal-message">
                                    <IntlMessages id={subTitleLabel} />
                                </p>
                                <Button
                                    className="mx-3 proposal-documents-button"
                                    variant="secondary"
                                    type="submit"
                                    onClick={fetchAgainHandler}
                                    icon="startRoundArrowReset"
                                    dataTestId="try-again"
                                >
                                    <IntlMessages id="label.tryAgain" />
                                </Button>
                            </>
                        }
                    </div>
                )}
            </div>
            {isOpenSendEmailDialog && (
                <SendEmailDialog
                    isOpen={isOpenSendEmailDialog}
                    onCloseHandler={() => setIsOpenSendEmailDialog(false)}
                    isRequesting={isSedingEmail}
                    onSubmitHandler={(data) => fetchSendARLinkToEmail(proposalID, data)}
                />
            )}
        </>
    );
};

export default GenerateDocuments;
