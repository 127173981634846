import { DISPATCH_EVT } from 'constants/facilityPro/main';
import clone from 'fast-copy';
import { getGasDataB2B, getQuestionsGasDataB2B, getGasDataB2C, getQuestionsGasDataB2C } from 'services/facilityPro/gasData';

export const initialGasDataReducer = clone({
    dispatchGasData: (_type = '', _payload = {}) => {},
    isMounting: true,
    isVisible: false,
    isAddFacility: false,
    isB2C: false,
    //#region inputs
    gas_supplied_by_company: false,
    consumo_anual_gas_mwh: '',
    fornecedor_gas_id: '',
    tarifa_fixa_gas_mwh: '',
    tarifa_media_gas_mwh: '',
    questions: {},
    //#endregion inputs
});

export const gasDataReducer = (state = initialGasDataReducer, action) => {
    switch (action.type) {
        case DISPATCH_EVT.SET_GAS: {
            let { payload } = action;
            let { inputs, options } = payload;
            let { isB2C, isAddFacility } = options;
            let newinputs = isB2C ? getGasDataB2C(inputs) : getGasDataB2B(inputs);
            let questions = isB2C ? getQuestionsGasDataB2C() : getQuestionsGasDataB2B(newinputs, options);
            return {
                ...state,
                ...newinputs,
                ...questions,
                isAddFacility,
                isB2C,
                isMounting: false,
            };
        }

        default: {
            throw new Error(`Error on Gas Data Reducer type: ${action.type}`);
        }
    }
};
