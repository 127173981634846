import { TBMProjectSummary, TBMKpi } from './../../interfaces/businessModels/index';
import { isFieldDefined, parseNumberWithDecimals, parseNumberWithToFixed } from 'services/util/auxiliaryUtils';
import { papTariffUnit } from 'services/businessModels/papKpis';
import { PRODUCT_IDS } from 'constants/products';

export const leCProjectSummary = (kpis: TBMProjectSummary[], syncKpis: any, companyProfileId: number, productID: number): TBMKpi[] => {
    if (isFieldDefined(syncKpis)) return syncLECProjectSummary(syncKpis, companyProfileId, productID);
    let projectSummary: TBMKpi[] = [];

    switch (productID) {
        case PRODUCT_IDS.SPV:
            projectSummary = [
                {
                    label: 'label.price',
                    infoLabel: 'label.tariffDiscount',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].lec_tariff, 1),
                    unit: 'cmwp',
                    numberOfDecimalPlaces: 1,
                    values: {
                        tariffDiscount: parseNumberWithToFixed(kpis[0].kpis_per_margin?.[0].lec_tariff_discount * 100, 1).toLocaleString(
                            'pt-PT'
                        ),
                    },
                },
                {
                    label: 'label.annualSaving',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].annual_saving, 0),
                    unit: 'currency',
                    numberOfDecimalPlaces: 0,
                },
            ];
            break;
        case PRODUCT_IDS.SPVSB:
            projectSummary = [
                {
                    label: 'label.price',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].lec_energy_tariff, 0),
                    unit: 'cmwp',
                },
                {
                    label: 'label.annualSaving',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].lec_annual_saving, 0),
                    unit: 'currency',
                },
            ];
            break;
        default:
            break;
    }

    return projectSummary;
};

const syncLECProjectSummary = (syncKpis, companyProfileId, productID) => {
    const step = `${syncKpis?.negotiation?.step ?? ''}`;
    const numberOfDecimalPlaces = step.split('.')?.[1]?.length ?? 4;
    const acTariff = syncKpis?.kpis?.ac_tariff * 1000;
    const discount = (acTariff - syncKpis?.negotiation.default) / acTariff;

    switch (productID) {
        case PRODUCT_IDS.SPV:
            return [
                {
                    label: 'label.price',
                    infoLabel: 'label.tariffDiscount',
                    value: parseNumberWithDecimals(syncKpis?.negotiation.default, numberOfDecimalPlaces),
                    unit: papTariffUnit(companyProfileId),
                    numberOfDecimalPlaces,
                    values: {
                        tariffDiscount: parseNumberWithToFixed(discount * 100, 2).toLocaleString('pt-PT'),
                    },
                },
                {
                    label: 'label.annualSaving',
                    value: parseNumberWithDecimals(syncKpis.kpis.annual_saving, 0),
                    unit: 'currency',
                },
            ];
            break;
        case PRODUCT_IDS.SPVSB:
            return [
                {
                    label: 'label.price',
                    value: parseNumberWithDecimals(syncKpis?.negotiation.default, numberOfDecimalPlaces),
                    unit: papTariffUnit(companyProfileId),
                    numberOfDecimalPlaces,
                },
                {
                    label: 'label.annualSaving',
                    value: parseNumberWithDecimals(syncKpis.kpis.annual_saving, 0),
                    unit: 'currency',
                },
            ];
            break;
        default:
            return [
                {
                    label: 'label.price',
                    infoLabel: 'label.tariffDiscount',
                    value: parseNumberWithDecimals(syncKpis?.negotiation.default, numberOfDecimalPlaces),
                    unit: papTariffUnit(companyProfileId),
                    numberOfDecimalPlaces,
                    values: {
                        tariffDiscount: parseNumberWithToFixed(discount * 100, 2).toLocaleString('pt-PT'),
                    },
                },
                {
                    label: 'label.annualSaving',
                    value: parseNumberWithDecimals(syncKpis.kpis.annual_saving, 0),
                    unit: 'currency',
                },
            ];
            break;
            break;
    }
};
