import { defineMessages } from 'react-intl';
import enMessages from '../locales/de_CH.json';

const chCHLang = {
    messages: {
        ...enMessages,
    },
    locale: 'de-CH',
    data: defineMessages,
};
export default chCHLang;
