import { useContext } from 'react';
import Skeleton from '@mui/material/Skeleton';
import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront } from 'interfaces/businessModels/upfront';

const ProjectSummaryTotalCost = ({ cost }: { cost: number }) => {
    const { negotiationProfile, isRequestingBM } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    return (
        <div className="bm-project-summary-total-cost">
            <span>
                <IntlMessages id="label.totalCost" />
            </span>
            {negotiationProfile?.acesso_valores_custo ?
                isRequestingBM ?
                    <Skeleton height={21} width={75} />
                :   <span>
                        {/* @ts-ignore */}
                        <FormatNumber number={cost} unit={'currency'} numberOfDecimalPlaces={0} />
                    </span>

            :   <></>}
        </div>
    );
};

export default ProjectSummaryTotalCost;
