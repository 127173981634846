import IntlMessages from 'components/util/IntlMessages';
import Tooltip from 'components/@efz/Tooltip';

const ProjectSummaryTitle = ({ label, tooltipTitle }: { label: string; tooltipTitle?: string | React.JSX.Element }) => {
    return (
        <div className="bm-common-title">
            <>
                <span>
                    <IntlMessages id={label} />
                </span>
                {tooltipTitle && <Tooltip title={tooltipTitle} />}
            </>
            <hr />
        </div>
    );
};

export default ProjectSummaryTitle;
