import { useContext, useState } from 'react';

// Components
import Collapse from '@mui/material/Collapse';
import GrantsKpisServicePPa from './Kpis';
import GrantsTableServicePPa from './Table';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPPa } from 'interfaces/businessModels/ppa';

import GrantsTitle from './Title';

const GrantsServicePPa = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="bm-grants">
            <GrantsTitle label="page.bm.grants.title" isOpen={isOpen} collapseHandler={() => setIsOpen(!isOpen)} />
            <Collapse in={isOpen}>
                <GrantsTableServicePPa />
                {bmSelected.values.grants.totalGrants.prc <= 100 && <GrantsKpisServicePPa />}
            </Collapse>
        </div>
    );
};

export default GrantsServicePPa;
