import Collapse from '@mui/material/Collapse';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { useContext, useEffect, useState } from 'react';
import GrantsKpisServiceV2 from './Kpis';
import GrantsTableServiceV2 from './Table';
import GrantsTitle from './Title';
import { getCompanyProfileIds } from 'services/user';
import { SPV_GRANT_NAME } from 'constants/businessModels';
import { UserContext } from 'contexts/userContext';
import { Nullable } from 'types/utils';
import { debounce, isDefined } from 'services/util/auxiliaryUtils';
import GrantsQuestionsServiceV2 from './Questions';
import { ServiceV2Events, TServiceV2 } from 'interfaces/businessModels/serviceV2';
import { DEBOUNCE_TIME } from 'constants/endpoints';

interface ISubsidy {
    [SPV_GRANT_NAME.SUBSIDY_PRC]: number;
    [SPV_GRANT_NAME.SUBSIDY]: Nullable<boolean>;
    [SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC]: number;
    [SPV_GRANT_NAME.BATTERY_SUBSIDY]: Nullable<boolean>;
}

const GrantsServiceV2 = () => {
    const { companyProfileId } = useContext(UserContext);

    const { bmSelected, setBMEventHandler, productPayload } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TServiceV2
    >;

    const [isOpen, setIsOpen] = useState(false);
    const [subsidy, setSubsidy] = useState<ISubsidy>();

    useEffect(() => {
        if ([getCompanyProfileIds().EDP_ES].includes(companyProfileId)) {
            setSubsidy({
                [SPV_GRANT_NAME.SUBSIDY_PRC]: 0,
                [SPV_GRANT_NAME.SUBSIDY]: isDefined(productPayload?.productInputs?.inputs?.battery) ? true : null,
                [SPV_GRANT_NAME.BATTERY_SUBSIDY_PRC]: 0,
                [SPV_GRANT_NAME.BATTERY_SUBSIDY]: isDefined(productPayload?.productInputs?.inputs?.battery) ? true : null,
            });
        }
    }, []); // eslint-disable-line

    const showGrantsTable = !!bmSelected.values?.grants?.grants;
    const showGrantsQuestions = !!subsidy;

    const delayedOnChangeSubsidyHandler = debounce(
        (payload) => setBMEventHandler(ServiceV2Events.UPDATE_SERVICEV2_GRANTS, payload),
        DEBOUNCE_TIME
    );

    useEffect(() => {
        delayedOnChangeSubsidyHandler(subsidy);
    }, [subsidy]); // eslint-disable-line

    return (
        <div className="bm-grants">
            <GrantsTitle label="page.bm.grants.title" isOpen={isOpen} collapseHandler={() => setIsOpen(!isOpen)} />
            <Collapse in={isOpen}>
                {showGrantsTable && !showGrantsQuestions ?
                    <>
                        <GrantsTableServiceV2 />
                        {bmSelected?.values?.grants?.totalGrants?.prc <= 100 && <GrantsKpisServiceV2 />}
                    </>
                :   <></>}
                {showGrantsQuestions && !showGrantsTable ?
                    <GrantsQuestionsServiceV2 subsidy={subsidy} setSubsidy={setSubsidy} />
                :   <></>}
            </Collapse>
        </div>
    );
};

export default GrantsServiceV2;
