import IntlMessages from 'components/util/IntlMessages';
import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';
import { IconButton } from '@save2compete/efz-design-system';

const StyledDialog = styled(Dialog)`
    & .MuiDialog-paper {
        padding: 3rem 2rem;
        width: 100%;
    }
    .DialogBank-close {
        padding: 0 !important;
        margin: 0 !important;
        position: absolute !important;
        top: 24px;
        right: 24px;
    }
    .DialogBank-content {
        margin-top: 1rem;
        p {
            font-size: 14px;
        }
    }
`;

const DialogBank = ({ isOpenDialogBank, setIsOpenDialogBank }) => {
    return (
        <StyledDialog open={isOpenDialogBank} maxWidth="sm" className={`DialogBank`}>
            <IconButton
                variant="tertiary"
                icon="xMarkExit"
                size="bg"
                aria-label="close"
                className={'DialogBank-close'}
                onClick={() => setIsOpenDialogBank(false)}
                dataTestId="close-dialog-bank"
            />

            <div className="DialogBank-content">
                <p>
                    <IntlMessages id="thankYou.financingPossibilities.BankDialog.1" />
                </p>
                <p>
                    <IntlMessages id="thankYou.financingPossibilities.BankDialog.2" />
                </p>
                <p>
                    <IntlMessages id="thankYou.financingPossibilities.BankDialog.3" />
                </p>
                <p>
                    <IntlMessages id="thankYou.financingPossibilities.BankDialog.4" />
                </p>
                <p>
                    <IntlMessages id="thankYou.financingPossibilities.BankDialog.5" />
                </p>
            </div>
        </StyledDialog>
    );
};

export default DialogBank;
