import { INPUT_NAMES, MANAGEMENT_OPTIONS_ID, ME_ENTRANCE_TYPE, ME_MANAGEMENT_OPTION_ID } from 'constants/products/evc';
import { getBoolean, isFieldDefined } from 'services/util/auxiliaryUtils';
import clone from 'fast-copy';
import { WARNING_MESSAGES } from 'constants/products/evc';
import { getCompanyProfileIds } from 'services/user';

// Given the selected charger options, find the charger details in the metadata list
export const getChargerMetadata = (chargerData, chargersMetadata) => {
    let chosenChargerMetadata = chargersMetadata?.find(
        (option) =>
            option.metadata.gama === chargerData.gama &&
            option.metadata.power_config === chargerData?.[INPUT_NAMES.POWER_CONFIG_OPTIONS] &&
            option.metadata.number_of_chargers === chargerData?.[INPUT_NAMES.NUMBER_OF_CHARGERS_OPTIONS] &&
            option.metadata.plug_display === chargerData?.[INPUT_NAMES.PLUG_DISPLAY_OPTIONS]
    );

    return chosenChargerMetadata;
};

export const getSelectedManagementOption = (companyProfileId, entranceType, hasCostsBearing = null, hasUsersManagement = null) => {
    switch (companyProfileId) {
        case getCompanyProfileIds().EFZ:
        case getCompanyProfileIds().EDP_PT: {
            if (entranceType === ME_ENTRANCE_TYPE.PUBLIC) {
                return ME_MANAGEMENT_OPTION_ID.MOBIE;
            }
            if (entranceType === ME_ENTRANCE_TYPE.PRIVATE) {
                let has_costs_bearing = getBoolean(clone(hasCostsBearing));
                let has_users_management = getBoolean(clone(hasUsersManagement));

                if (has_users_management) return ME_MANAGEMENT_OPTION_ID.EVCHARGE; // EV Charger
                if (has_costs_bearing)
                    return ME_MANAGEMENT_OPTION_ID.NO_MANAGEMENT; // No Management
                else return ME_MANAGEMENT_OPTION_ID.MOBIE; // MOBI.E
            }
            break;
        }

        case getCompanyProfileIds().EDP_ES: {
            if (entranceType === ME_ENTRANCE_TYPE.PUBLIC) {
                return ME_MANAGEMENT_OPTION_ID.MOVEON;
            }
            if (entranceType === ME_ENTRANCE_TYPE.PRIVATE) {
                return ME_MANAGEMENT_OPTION_ID.MOVEON_LITE;
            }
            break;
        }

        case getCompanyProfileIds().EDP_BR: {
            if (entranceType === ME_ENTRANCE_TYPE.PUBLIC) {
                return ME_MANAGEMENT_OPTION_ID.SMART;
            }
            if (entranceType === ME_ENTRANCE_TYPE.PRIVATE) {
                return ME_MANAGEMENT_OPTION_ID.NO_MANAGEMENT;
            }
            break;
        }

        default: {
            return MANAGEMENT_OPTIONS_ID?.[companyProfileId].find((option) => option.entranceTypeIDs.includes(entranceType)).id; // return first option that is available
        }
    }
};

export const hasMoreThanOneManagementOption = (entranceTypeID, companyProfileId) => {
    return MANAGEMENT_OPTIONS_ID?.[companyProfileId]?.filter((option) => option?.entranceTypeIDs?.includes(entranceTypeID))?.length > 1;
};

export const hasRedWarning = (message) =>
    [
        WARNING_MESSAGES.REMOVE_ONE_OR_MORE_CHARGERS,
        WARNING_MESSAGES.WITHOUT_POTENTIAL,
        WARNING_MESSAGES.REMOVE_ONE_OR_MORE_CHARGERS_CHARGERS_NUMBER,
        WARNING_MESSAGES.REMOVE_ONE_OR_MORE_CHARGERS_TOTAL_POWER,
    ].includes(message);

export function getInputWithTaxes(inputsName, companyProfileId, chargerInfo) {
    let price = 0;
    switch (companyProfileId) {
        case getCompanyProfileIds().EDP_ES:
            if (inputsName === 'upfront_price') {
                price = chargerInfo?.equipment_price_preview;
            } else {
                price = chargerInfo?.[inputsName];
            }
            break;
        case getCompanyProfileIds().EDP_BR:
            inputsName = `${inputsName}_after_taxes`;
            price = chargerInfo?.[inputsName];
            break;

        default:
            price = chargerInfo?.[inputsName];
            break;
    }

    return price;
}

export const getInputsPartnerShip = (inputs) => {
    return {
        [INPUT_NAMES.FACILITY_TYPE_ID]:
            isFieldDefined(inputs?.[INPUT_NAMES.FACILITY_TYPE_ID]) ? inputs?.[INPUT_NAMES.FACILITY_TYPE_ID] : undefined,
        [INPUT_NAMES.IS_PRIVATE_DOMAIN]:
            isFieldDefined(inputs?.[INPUT_NAMES.IS_PRIVATE_DOMAIN]) ? inputs?.[INPUT_NAMES.IS_PRIVATE_DOMAIN] : undefined,
        [INPUT_NAMES.HAS_MIN_PARKING_SPACES]:
            isFieldDefined(inputs?.[INPUT_NAMES.HAS_MIN_PARKING_SPACES]) ? inputs?.[INPUT_NAMES.HAS_MIN_PARKING_SPACES] : undefined,
        [INPUT_NAMES.HAS_MIN_OPEN_SCHEDULE]:
            isFieldDefined(inputs?.[INPUT_NAMES.HAS_MIN_OPEN_SCHEDULE]) ? inputs?.[INPUT_NAMES.HAS_MIN_OPEN_SCHEDULE] : undefined,
        [INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_20]:
            isFieldDefined(inputs?.[INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_20]) ?
                inputs?.[INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_20]
            :   undefined,
        [INPUT_NAMES.NUMBER_OF_ROOMS_BETWEEN_20_AND_100]:
            isFieldDefined(inputs?.[INPUT_NAMES.NUMBER_OF_ROOMS_BETWEEN_20_AND_100]) ?
                inputs?.[INPUT_NAMES.NUMBER_OF_ROOMS_BETWEEN_20_AND_100]
            :   undefined,
        [INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_100]:
            isFieldDefined(inputs?.[INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_100]) ?
                inputs?.[INPUT_NAMES.NUMBER_OF_ROOMS_GREATER_THAN_100]
            :   undefined,
        [INPUT_NAMES.HAS_MIN_SEATED_POSITIONS]:
            isFieldDefined(inputs?.[INPUT_NAMES.HAS_MIN_SEATED_POSITIONS]) ? inputs?.[INPUT_NAMES.HAS_MIN_SEATED_POSITIONS] : undefined,
        [INPUT_NAMES.WANTS_PARTNERSHIP]:
            isFieldDefined(inputs?.[INPUT_NAMES.WANTS_PARTNERSHIP]) ? inputs?.[INPUT_NAMES.WANTS_PARTNERSHIP] : undefined,
        [INPUT_NAMES.SHARING_MODEL_ID]:
            isFieldDefined(inputs?.[INPUT_NAMES.SHARING_MODEL_ID]) ? inputs?.[INPUT_NAMES.SHARING_MODEL_ID] : undefined,
    };
};

export const getUniqueChargersRangesArray = (chargersSelected) => {
    let ranges = [];

    chargersSelected.map((element) => ranges.push(element.metadata?.gama));

    return [...new Set(ranges)];
};
