import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

// Components
import IntlMessages from 'components/util/IntlMessages';
import { Button } from '@save2compete/efz-design-system';

// Constants
import { BUSINESS_MODEL_ELIGIBLE, SIZING_TYPE_IDS } from 'constants/businessModels';
import { BmDetailedActions } from 'constants/businessModelsPro/index';
import { USERS_TYPE_ID } from 'constants/user';

// Contexts
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { UserContext } from 'contexts/userContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { InstallmentsEvents, TInstallments } from 'interfaces/businessModels/installments';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';

// Services
import { isDefined, isEmpty, removeEmptyInObj } from 'services/util/auxiliaryUtils';

const ProposalGenerationButtonInstallments = () => {
    const { userTypeID } = useContext(UserContext);

    const { negotiationProfile, state, bmSelected, setBMEventHandler } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TInstallments
    >;

    const {
        bmState,
        bmState: { loadings },
        lowNegotiationProfile,
        setBMEventHandler: setBMOEEventHandler,
    } = useContext(OfferEditionContext);

    const {
        formState: { errors },
        watch,
    } = useFormContext();

    const hasErrorOnNetSavingsEligibility = state?.selected?.values?.syncKpis?.negotiation?.hasErrorOnNetSavingsEligibility;

    const auditProposalConditions =
        lowNegotiationProfile ?
            negotiationProfile?.audita_proposta
        :   isDefined(bmState?.kpis?.finalKpis) && negotiationProfile?.audita_proposta;

    const hasAuditProposal =
        (state?.selected?.options?.sizing_type_id === SIZING_TYPE_IDS.DETAILED_FULL &&
            state?.selected?.values?.syncKpis?.negotiation?.status_risk_premium_id === BUSINESS_MODEL_ELIGIBLE.NO) ||
        hasErrorOnNetSavingsEligibility;

    const isButtonDisabled =
        bmState?.loadings?.general ||
        loadings.getProposalNoKpis ||
        !bmState?.sliderReady ||
        (lowNegotiationProfile ? !bmState?.sliderReady : userTypeID === USERS_TYPE_ID.CLIENT) ||
        hasAuditProposal ||
        !isEmpty(errors);

    const onProposalGenerationClick = () => {
        const formInputs = watch();

        const valuesToUpdate = {
            clientContactInfo: null,
            id_crm: null,
        };
        if (formInputs?.contact_info) valuesToUpdate.clientContactInfo = formInputs.contact_info;
        if (formInputs?.id_crm) valuesToUpdate.id_crm = formInputs.id_crm;

        const hasValuesToUpdate = !!valuesToUpdate.clientContactInfo || !!valuesToUpdate.id_crm;
        if (hasValuesToUpdate) setBMEventHandler(InstallmentsEvents.UPDATE_INSTALLMENTS_ADDITIONAL_DATA, removeEmptyInObj(valuesToUpdate));

        if (isDefined(bmSelected.values?.syncKpis) && !isDefined(bmState?.kpis?.finalKpis)) {
            setBMOEEventHandler(DetailedOfferEditionEvents.SET_DETAILED_ACTION, BmDetailedActions.PROPOSAL);
            return;
        }

        setTimeout(() => {
            setBMEventHandler(InstallmentsEvents.SET_INSTALLMENTS_PROPOSAL, null);
        }, 100);
    };

    return (
        <div className="m-auto d-flex justify-content-center w-100 bm-detailed-proposal">
            {(auditProposalConditions || hasAuditProposal) && (
                <Button
                    variant="secondary"
                    onClick={() => setBMOEEventHandler(DetailedOfferEditionEvents.SET_DETAILED_ACTION, BmDetailedActions.AUDIT)}
                    disabled={!bmState?.sliderReady || bmState?.loadings.general}
                    loading={bmState?.loadings.auditFilesDownload}
                    dataTestId="bm-detailed-proposal-autit"
                >
                    <IntlMessages id="page.businessModels.auditProposal" />
                </Button>
            )}

            <Button
                onClick={onProposalGenerationClick}
                disabled={isButtonDisabled}
                loading={loadings.getProposalNoKpis}
                dataTestId="bm-detailed-proposal-generate"
            >
                <IntlMessages id="label.proposalGeneration" />
            </Button>
        </div>
    );
};

export default ProposalGenerationButtonInstallments;
