import { useContext, useEffect } from 'react';
import { getEditRisks, getEditTnw, putUpdateUser } from 'api/user';
import { UserContext } from 'contexts/userContext';
import { StatusCodes } from 'http-status-codes';
import { getBoolean } from 'services/util/auxiliaryUtils';
import { isDefined } from 'services/util/auxiliaryUtils';
import { useUserStore } from 'store/user';

const useUser = () => {
    const { setAppLocale, locale, setConfigs } = useContext(UserContext);
    const { user } = useUserStore();

    // @ts-ignore
    const currentLocale = locale?.locale ?? 'en';

    const updateUserLocale = async (lang) => {
        try {
            if (!user) return;
            const putUserRsp = await putUpdateUser({ userID: user.id, body: { idioma: lang } });

            if (putUserRsp?.status === StatusCodes.OK) setAppLocale(lang);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchUserConfigs = async () => {
        const responseTnw = await getEditTnw();

        const responseRisks = await getEditRisks();

        if (responseRisks?.status === StatusCodes.OK && responseTnw?.status === StatusCodes.OK) {
            const _responseRisks = responseRisks?.data?.data ?? null;
            const _responseTnw = responseTnw?.data?.data ?? null;

            setConfigs({ client: { ..._responseRisks, ..._responseTnw }, facility: null });
        }
    };

    useEffect(() => {
        if (isDefined(user?.id)) {
            updateUserLocale(currentLocale);
        }
    }, [currentLocale, user]); // eslint-disable-line

    useEffect(() => {
        fetchUserConfigs();

        //Store device type in local storage
        const viewportWidth = window.innerWidth;
        const isViewportTabletOrMobile = viewportWidth < 1080; //1080 resolution on test for change between Solar PV Advance and Simple.
        const storedDeviceType = localStorage.getItem('isTabletOrMobile');
        if (getBoolean(storedDeviceType) !== isViewportTabletOrMobile) {
            localStorage.setItem('isTabletOrMobile', JSON.stringify(isViewportTabletOrMobile));
        }
    }, []); // eslint-disable-line
};

export default useUser;
