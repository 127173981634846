import { isEnvDevFlag } from 'services/settings';
import { getCompanyProfileIds } from 'services/user';
import { useFeatureFlags } from 'store/featureFlags';

export const paPKpis = (kpis, monthlyPayment, defaultTariff, companyProfileId) => {
    const discount = 1 - monthlyPayment / defaultTariff;
    const annualEstimatedPrice = monthlyPayment * (kpis?.annual_prod / 1000);
    const annualNetSaving = kpis?.annual_saving - annualEstimatedPrice;

    let _kpis = [
        {
            label: 'label.annualSaving',
            value: kpis?.annual_saving,
            numberOfDecimals: 0,
            unit: 'currency',
        },
        {
            label: 'label.comercialDiscount',
            value: typeof monthlyPayment === 'string' ? '-' : discount,
            numberOfDecimals: 2,
            unit: 'percent',
        },
        {
            label: 'label.annualEstimatedPrice',
            value: typeof monthlyPayment === 'string' ? '-' : annualEstimatedPrice,
            numberOfDecimals: 0,
            unit: 'currency',
        },
        {
            label: 'label.annualNetSavings',
            value: typeof monthlyPayment === 'string' ? '-' : annualNetSaving,
            numberOfDecimals: 0,
            unit: 'currency',
        },
    ];

    let companyProfileIdToHideCommercialDiscount = [getCompanyProfileIds().PROFILE_BASE_IT];

    let companyProfileIdToShowCommercialDiscount = [
        ...(isEnvDevFlag(useFeatureFlags.getState().featureFlags['fe-2395']) ? [getCompanyProfileIds().PROFILE_BASE_DE] : []),
    ];

    if (isEnvDevFlag(useFeatureFlags.getState().featureFlags['fe-2361'])) {
        companyProfileIdToHideCommercialDiscount.push(getCompanyProfileIds().EDP_IT);
        companyProfileIdToHideCommercialDiscount.push(getCompanyProfileIds().PROFILE_BASE_DE);
    }

    if (companyProfileIdToHideCommercialDiscount.includes(companyProfileId)) {
        _kpis = _kpis.filter((kpi) => !['label.comercialDiscount'].includes(kpi?.label));
    }

    if (!companyProfileIdToShowCommercialDiscount.includes(companyProfileId)) {
        _kpis = _kpis.filter((kpi) => !['label.annualSaving'].includes(kpi?.label));
    }

    return _kpis;
};

export const detailedPaPKpis = (negotiationProfile, finalKpis) => {
    return [
        {
            label: 'page.businessModels.tirWacc',
            value: finalKpis?.irr_wacc ?? 'N/A',
            numberOfDecimals: 2,
            visible: negotiationProfile?.slider_tir_wacc,
        },
        {
            label: 'label.tir',
            value: finalKpis?.irr ?? 'N/A',
            numberOfDecimals: 1,
            visible: negotiationProfile?.mostra_slider_tir,
            unit: null,
            styleFormat: 'percent',
        },
        {
            label: 'page.businessModels.fixedComission',
            value: finalKpis?.fixed_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_fixa,
            unit: 'currency',
        },
        {
            label: 'page.businessModels.variableComission',
            value: finalKpis?.variable_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_variavel,
            unit: 'currency',
        },
        {
            label: 'page.businessModels.totalComission',
            value: finalKpis?.total_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_total,
            unit: 'currency',
        },
        {
            label: 'label.additionalValue',
            value: finalKpis?.additional_revenue ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.mostra_slider_valor_adicional,
            unit: 'currency',
        },
    ];
};

export const annualEvoFeeOptions = () => {
    const DEFAULT_FEE = 0;
    const step = 0.5;
    const maxFee = 2; //max 2.5 (2+0.5)
    let currentFee = 0;
    let options = [];

    while (currentFee <= maxFee + step) {
        options.push({
            key: `annual_evo_fee_${currentFee}`,
            default: currentFee === DEFAULT_FEE ? true : false,
            value: currentFee / 100,
        });
        currentFee = currentFee + step;
    }

    return options;
};

export const papTariffUnit = (companyProfileId) => {
    switch (companyProfileId) {
        case getCompanyProfileIds().PROFILE_BASE_USA: {
            return 'ckwh';
        }
        default: {
            return 'cmwp';
        }
    }
};
