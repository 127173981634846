import { bool, func, string } from 'prop-types';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    getClientDescription,
    getClientFirstName,
    getClientID,
    getClientNIPC,
    getClientSurname,
    getClientVAT,
} from 'redux/selectors/client';
import { getDetailedAddress, getFacilityAddress, getFacilityCPE, getFacilityID, getForwardFormFacility } from 'redux/selectors/facility';
import { useLocation } from 'react-router-dom';
import { BASENAME_URL_PROCESSES } from 'constants/settings';

const HeaderContext = createContext({
    facilityID: string,
    hasClientFacilitySearch: bool,
    isOpenLangSwitcher: bool,
    setIsOpenLangSwitcher: func,
    isOpenClientCard: bool,
    setIsOpenClientCard: func,
    isOpenFacilityCard: bool,
    setIsOpenFacilityCard: func,
    isOpenUserInfo: bool,
    setIsOpenUserInfo: func,
});

const HeaderProvider = ({ children }) => {
    const location = useLocation();
    const hasClientFacilitySearch = ![BASENAME_URL_PROCESSES].includes(location.pathname);
    const [isOpenLangSwitcher, setIsOpenLangSwitcher] = useState(false);
    const [isOpenUserInfo, setIsOpenUserInfo] = useState(false);
    const [isOpenFacilityCard, setIsOpenFacilityCard] = useState(false);
    const [isOpenClientCard, setIsOpenClientCard] = useState(false);
    return (
        <HeaderContext.Provider
            value={{
                facilityID: useSelector((state) => getFacilityID(state?.facility)),
                facilityCPE: useSelector((state) => getFacilityCPE(state?.facility)),
                detailedAddress: useSelector((state) => getDetailedAddress(state?.facility)),
                facilityAddress: useSelector((state) => getFacilityAddress(state?.facility)),
                clientID: useSelector((state) => getClientID(state?.client)),
                forwardFormFacility: useSelector((state) => getForwardFormFacility(state?.facility)),
                clientDescription: useSelector((state) => getClientDescription(state?.client) ?? null),
                clientNIPC: useSelector((state) => getClientNIPC(state?.client)),
                clientFirstName: useSelector((state) => getClientFirstName(state?.client)),
                clientSurname: useSelector((state) => getClientSurname(state?.client)),
                clientVAT: useSelector((state) => getClientVAT(state?.client)),
                hasClientFacilitySearch,
                isOpenLangSwitcher,
                setIsOpenLangSwitcher,
                isOpenClientCard,
                setIsOpenClientCard,
                isOpenFacilityCard,
                setIsOpenFacilityCard,
                isOpenUserInfo,
                setIsOpenUserInfo,
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
};

export { HeaderContext, HeaderProvider };
