import { IBusinessModelsContext, TBMProjectSummary, TProductInputs } from 'interfaces/businessModels';
import { leCProjectSummary } from 'services/businessModels/lec';
import { useContext } from 'react';
import { UserContext } from 'contexts/userContext';
import KPI from 'components/BusinessModels/List/BusinessModels/BusinessModelLEC/Card/Summary/Kpi';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { Tlec } from 'interfaces/businessModels/lec';

interface IProps {
    projectSummary: TBMProjectSummary[];
    syncKpis?: any;
}

const BusinessModelProjectSummaryLEC = ({ projectSummary, syncKpis }: IProps) => {
    const { companyProfileId } = useContext(UserContext);
    const { productID } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, Tlec>;

    const kpis = leCProjectSummary(projectSummary, syncKpis, companyProfileId, productID);

    return <>{kpis.length > 0 ? kpis.map((kpi) => <KPI {...kpi} />) : <></>}</>;
};

export default BusinessModelProjectSummaryLEC;
