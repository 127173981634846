import { useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { getSearchClients } from 'api/client';
import { useSelector } from 'react-redux';
import { getClientSearchHistory } from 'redux/selectors/client';

const useSearchClients = () => {
    // redux
    const sClientsHistory = useSelector((state) => getClientSearchHistory(state?.client) ?? null);
    const [clientsData, setClientsData] = useState([]);
    const [isRequesting, setIsRequesting] = useState(false);

    const handleFetchSearchClients = async (payload) => {
        setIsRequesting(true);
        const rps = await getSearchClients(payload);
        if (rps?.status === StatusCodes.OK) {
            setClientsData(rps?.data?.data);
        }
        setIsRequesting(false);
    };

    const resetSearchClientsHistory = (newClient) => {
        setClientsData([newClient, ...clientsData]);
    };

    return {
        sClientsData: clientsData,
        handleFetchSearchClients,
        isRequestingSClients: isRequesting,
        sClientsHistory,
        resetSearchClientsHistory,
    };
};

export default useSearchClients;
