import Collapse from '@mui/material/Collapse';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { useContext, useState } from 'react';
import GrantsTitle from './Title';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import GrantsTableLEC from './Table';
import { Tlec } from 'interfaces/businessModels/lec';
import GrantsKpisLEC from './Kpis';

const GrantsLEC = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, Tlec>;

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="bm-grants">
            <GrantsTitle label="page.bm.grants.title" isOpen={isOpen} collapseHandler={() => setIsOpen(!isOpen)} />
            <Collapse in={isOpen}>
                <GrantsTableLEC />
                {isFieldDefined(bmSelected?.values?.grants) && bmSelected?.values?.grants?.totalGrants?.prc <= 100 && <GrantsKpisLEC />}
            </Collapse>
        </div>
    );
};

export default GrantsLEC;
