import clone from 'fast-copy';
import { SIZING_MODULES } from 'constants/sizing';
import { INPUTS_DEFAULTS, QUESTIONS, INPUT_NAMES } from 'constants/products/cfp';
import { getBoolean } from 'services/util/auxiliaryUtils';
import FormatNumber from 'components/util/FormatNumber';

/**
 * buildInputs
 *
 * @private
 * @param options
 * @param productInputs
 * @returns {{work_schedule_id: null, installed_power: number, transformers_number: number}}
 */
function buildInputs(productInputs, options) {
    const { batteryPowers, proposalDetails, hasProductInputs } = options;
    let inputsDefault = clone(INPUTS_DEFAULTS);

    if (proposalDetails) {
        //Edit proposal: if there are proposal details (id, date or BM tag), we know we are editing proposal
        return {
            ...inputsDefault,
            ...proposalDetails.inputs,
        };
    } else {
        if (!hasProductInputs && !!batteryPowers)
            inputsDefault[INPUT_NAMES.BATTERY_POWER] = batteryPowers.filter(
                (item) => item[INPUT_NAMES.HAS_HARMONICS] === inputsDefault[INPUT_NAMES.HAS_HARMONICS]
            )[0].value;
        return {
            ...(hasProductInputs ? productInputs.SIMPLES_Dimensionamento.inputs : inputsDefault),
        };
    }
}

/**
 * buildQuestions
 *
 * @private
 * @param questions
 * @param inputs
 * @param options
 * @returns {({visible: boolean, handleChange: function(): void, answers: [{label: string, value: number}, {label: string, value: number}], name: string, step: number, label: string, type: string, value: null, validation: *}|{visible: boolean, handleChange: function(): void, answers: [{label: string, value: number}, {label: string, value: number}], name: string, showInput: boolean, step: number, label: string, type: string, value: null, validation: *}|{visible: boolean, handleChange: function(): void, answers: [], inRow: boolean, name: string, tooltip: string, label: string, type: string, value: null, validation: *}|{visible: boolean, name: string, disabled: boolean, label: string, type: string})[]}
 */
function buildQuestions(options) {
    const questions = clone(QUESTIONS);
    const { sizingBase } = options;

    // has_harmonics
    questions[INPUT_NAMES.HAS_HARMONICS].answers.map(
        (item) => (item.showHelperText = sizingBase[INPUT_NAMES.HAS_HARMONICS] === getBoolean(item.value))
    );
    questions[INPUT_NAMES.HAS_HARMONICS].options.base = sizingBase[INPUT_NAMES.HAS_HARMONICS];
    // battery_power
    questions[INPUT_NAMES.BATTERY_POWER].options.helperFormatNumber = (
        <FormatNumber unit="kvar" number={sizingBase[INPUT_NAMES.BATTERY_POWER]} numberOfDecimalPlaces={0} />
    );
    questions[INPUT_NAMES.BATTERY_POWER].options.base = sizingBase[INPUT_NAMES.BATTERY_POWER];

    return questions;
}

/**
 * buildProduct
 *
 * @public
 * @param options
 * @returns {{inputs: {work_schedule_id: null, installed_power: number, transformers_number: number}, questions: ({visible: boolean, handleChange: (function(): void), answers: {label: string, value: number}[], name: string, step: number, label: string, type: string, value: null, validation: *}|{visible: boolean, handleChange: (function(): void), answers: {label: string, value: number}[], name: string, showInput: boolean, step: number, label: string, type: string, value: null, validation: *}|{visible: boolean, handleChange: (function(): void), answers: *[], inRow: boolean, name: string, tooltip: string, label: string, type: string, value: null, validation: *}|{visible: boolean, name: string, disabled: boolean, label: string, type: string})[]}}
 */
export function buildProduct(options) {
    const { batteryPowers, productInputs, hasProductInputs, sizingBase, proposalDetails } = options;
    return {
        inputs: buildInputs(productInputs, { batteryPowers, proposalDetails, hasProductInputs }),
        questions: buildQuestions({ sizingBase }),
    };
}

/**
 * getPayload
 *
 * @export
 * @param {*} inputs
 * @param {*} options
 * @returns
 */
export const getPayload = (inputs, options) => {
    const { facilityID, productID, proposalDetails, hasProductInputs, productInputs } = options;
    return {
        facility: { id: facilityID },
        inputs: getInputs(inputs, { proposalDetails, hasProductInputs, productInputs }),
        is_scaling: true,
        tipo_produto_id: productID,
        tipo_modulo: SIZING_MODULES.SIMPLE,
    };
};

/**
 * getInputs
 *
 * @export
 * @param {*} inputs
 * @param {*} options
 * @returns
 */
export function getInputs(inputs, options) {
    if (!inputs) return null;
    let _inputs = null;

    if (options) {
        const { proposalDetails, hasProductInputs, productInputs } = options;
        //editProposal
        if (proposalDetails) {
            _inputs = clone(proposalDetails.inputs);
        } else {
            //productInputs
            if (hasProductInputs) _inputs = clone(productInputs.SIMPLES_Dimensionamento.inputs);
            else _inputs = clone(inputs); //sizingBase
        }
    } else {
        //sizingBase
        _inputs = clone(inputs);
    }

    return {
        [INPUT_NAMES.HAS_HARMONICS]: getBoolean(_inputs?.[INPUT_NAMES.HAS_HARMONICS]),
        [INPUT_NAMES.EXTRA_COST]: _inputs?.[INPUT_NAMES.EXTRA_COST] ? _inputs?.[INPUT_NAMES.EXTRA_COST] : 0,
        [INPUT_NAMES.BATTERY_POWER]: parseFloat(_inputs?.[INPUT_NAMES.BATTERY_POWER]),
    };
}

/**
 * handleShowWarning
 *
 * @param {*} inputs
 * @param {*} questions
 */
export const handleShowWarning = (inputs, questions) =>
    !(
        getBoolean(inputs[INPUT_NAMES.HAS_HARMONICS]) === questions[INPUT_NAMES.HAS_HARMONICS].options.base &&
        parseFloat(inputs[INPUT_NAMES.BATTERY_POWER]) === questions[INPUT_NAMES.BATTERY_POWER].options.base
    );
