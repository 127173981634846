/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Actions - Utils
 *
 * @version 20191023
 * @since 20191023 Initial release
 *
 */

import {
    RESET_ALL_PRODUCT_STATES,
    REQUEST_GET_FACILITY_GROUPS,
    REQUEST_GET_FACILITY_GROUPS_SUCCESS,
    REQUEST_GET_WORK_SCHEDULE,
    REQUEST_GET_WORK_SCHEDULE_SUCCESS,
    REQUEST_GET_COUNTIES_WITH_SEARCH,
    REQUEST_GET_COUNTIES_WITH_SEARCH_SUCCESS,
    REQUEST_GET_COUNTIES_FAIL,
    REQUEST_GET_SEGMENTS,
    REQUEST_GET_SEGMENTS_SUCCESS,
    REQUEST_GET_SEGMENTS_FAIL,
    REQUEST_GET_RISKS,
    REQUEST_GET_RISKS_SUCCESS,
    REQUEST_GET_RISKS_FAIL,
} from 'constants/utils';

export function requestGetFacilityGroups(payload) {
    return {
        type: REQUEST_GET_FACILITY_GROUPS,
        payload: payload,
    };
}

export function requestGetFacilityGroupsSuccess(payload) {
    return {
        type: REQUEST_GET_FACILITY_GROUPS_SUCCESS,
        payload: payload,
    };
}

export function requestGetCountiesWithSearch(payload) {
    return {
        type: REQUEST_GET_COUNTIES_WITH_SEARCH,
        payload: payload,
    };
}

export function requestGetCountiesFail() {
    return {
        type: REQUEST_GET_COUNTIES_FAIL,
    };
}

export function requestGetCountiesWithSearchSuccess(payload) {
    return {
        type: REQUEST_GET_COUNTIES_WITH_SEARCH_SUCCESS,
        payload: payload,
    };
}

export function requestGetProductWorkSchedule(payload) {
    return {
        type: REQUEST_GET_WORK_SCHEDULE,
        payload: payload,
    };
}

export function requestGetProductWorkScheduleSuccess(payload) {
    return {
        type: REQUEST_GET_WORK_SCHEDULE_SUCCESS,
        payload: payload,
    };
}

export function resetAllProductStates() {
    return {
        type: RESET_ALL_PRODUCT_STATES,
    };
}

export function requestGetSegments() {
    return {
        type: REQUEST_GET_SEGMENTS,
    };
}

export function requestGetSegmentsSuccess(payload) {
    return {
        type: REQUEST_GET_SEGMENTS_SUCCESS,
        payload,
    };
}

export function requestGetSegmentsFail() {
    return {
        type: REQUEST_GET_SEGMENTS_FAIL,
    };
}

export function requestGetRisks(userLocale) {
    return {
        type: REQUEST_GET_RISKS,
        payload: userLocale,
    };
}

export function requestGetRisksSuccess(payload) {
    return {
        type: REQUEST_GET_RISKS_SUCCESS,
        payload,
    };
}

export function requestGetRisksFail() {
    return {
        type: REQUEST_GET_RISKS_FAIL,
    };
}
