/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description API requests to the context of Sizing || Scaling || Dimensionamento
 *
 * @version 20191113
 * @since 20191113 Initial release
 *
 */

// import axios from 'axios';
import axios from 'services/@efz/axios';
import { getMessageNetworkError } from 'services/statusCodeHttp';
import { URL_SIMULATIONS, getEndpoint } from 'constants/endpoints';

const CancelToken = axios.CancelToken;
// Cancel executor variables
let postSizingCancelExecutor;

/**
 *
 * postSizing
 *
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postSizing = (payload) => {
    postSizingCancelExecutor && postSizingCancelExecutor();

    return axios
        .post(getEndpoint(URL_SIMULATIONS), payload.body, {
            cancelToken: new CancelToken(function executor(c) {
                postSizingCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * postSizing
 *
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postSimulationsToWebstomp = (payload) => {
    return axios
        .post(getEndpoint(URL_SIMULATIONS), payload, {
            cancelToken: new CancelToken(function executor(c) {
                postSizingCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
