/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Reducers - Sizing
 *
 * @version 20191113
 * @since 20191113 Initial release
 *
 */

import clone from 'fast-copy';
import {
    INITIAL_SIZING,
    RESET_SIZING,
    REQUEST_GET_SIZING,
    REQUEST_GET_SIZING_SUCCESS,
    REQUEST_GET_SIZING_FAIL,
    SET_DATA_SIZING_BY_WEBSTOMP,
} from 'constants/sizing';

import { handleResponseWebstomp } from 'services/util/auxiliaryUtils';
import { STATE_IDS } from 'constants/user';
import { showStateCurrent } from 'services/user';

const initialState = clone(INITIAL_SIZING);

const reducersSizing = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_SIZING_BY_WEBSTOMP:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
            };
        case REQUEST_GET_SIZING:
            return {
                ...state,
                loading: true,
                isRequesting: true,
            };
        case REQUEST_GET_SIZING_SUCCESS: {
            //Format price to int
            let data = handleResponseWebstomp(action.payload);
            let base = action.payload.base ? action.payload.base : state.base;
            if (action.payload.base) base = action.payload.base;
            if (data?.kpis?.battery_power) data = data.kpis;
            return {
                ...state,
                data: data,
                base: base,
                loading: false,
                isRequesting: false,
            };
        }
        case REQUEST_GET_SIZING_FAIL:
            return {
                ...state,
                loading: false,
                isRequesting: false,
            };
        case RESET_SIZING:
            return {
                ...INITIAL_SIZING,
            };
        default:
            return (
                showStateCurrent(STATE_IDS.PLATFORM) ?
                    !state ? clone(INITIAL_SIZING)
                    :   state
                :   null
            );
    }
};

export default reducersSizing;
