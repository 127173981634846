import { memo, useContext } from 'react';
import { HOUSING_TYPES_B2C, spvSimpleActions, STEPS_OPEN_AREA, STEPS_RESERVED_AREA } from 'constants/products/solarpvSimple';
import IntlMessages from 'components/util/IntlMessages';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { Button, IconButton } from '@save2compete/efz-design-system';

const StepsMenu = ({ closeHandler, togglePageScroll, showSteps }) => {
    const {
        spvSimpleState: {
            step,
            isOArea,
            inputs: { housing_type },
        },
        spvSimpleDispatchHandler: dispatch,
        handleStartOver,
    } = useContext(SPVSimpleContext);

    const startOverHandler = () => {
        handleStartOver();
        !isOArea ? closeHandler(false) : togglePageScroll(true);
    };

    const stepsToDisplay = Object.values(isOArea ? STEPS_OPEN_AREA : STEPS_RESERVED_AREA).filter((step) => {
        // OPEN_AREA
        if (isOArea && step === STEPS_OPEN_AREA.ROOF_IDENTIFICATION) {
            return false;
        }

        if (isOArea && housing_type === HOUSING_TYPES_B2C.GROUND && step === STEPS_OPEN_AREA.ROOF_DETAILS) {
            return false;
        }

        // RESERVED_AREA
        if (!isOArea && step === STEPS_RESERVED_AREA.ROOF_IDENTIFICATION) {
            return false;
        }

        if (!isOArea && housing_type === HOUSING_TYPES_B2C.GROUND && step === STEPS_RESERVED_AREA.ROOF_DETAILS) {
            return false;
        }

        return true;
    });

    return (
        <div className="steps-menu">
            {stepsToDisplay.map((el) => (
                <div key={`steps-menu-step-${el}`} className={`steps-menu-item ${el <= step ? 'visited' : ''}`}>
                    <span className="steps-menu-item-title">
                        {/* Step 3 hidden, Roof Details hiden on ground so adjust el */}
                        {el <= 3 ?
                            el
                        : housing_type === HOUSING_TYPES_B2C.GROUND ?
                            el - 2
                        :   el - 1}
                        .
                        <IntlMessages whitespaceStart id={`page.spvSimple.${isOArea ? 'open.b2c' : 'reserved'}.step.${el}`} />
                    </span>
                    {el < step &&
                        ![isOArea ? STEPS_OPEN_AREA.ROOF_IDENTIFICATION : STEPS_RESERVED_AREA.ROOF_IDENTIFICATION].includes(el) && (
                            <IconButton
                                variant="action"
                                icon="editPencil"
                                onClick={() => {
                                    dispatch(spvSimpleActions.SET_STEP, el);
                                    closeHandler(false);
                                }}
                                dataTestId={`edit-step-${el}`}
                            />
                        )}
                </div>
            ))}
            {showSteps && (
                <Button
                    variant="tertiary"
                    icon="startRoundArrowReset"
                    onClick={startOverHandler}
                    dataTestId="start-over"
                    className="start-over-btn"
                >
                    <IntlMessages id="label.restart" />
                </Button>
            )}
        </div>
    );
};

export default memo(StepsMenu);
