import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Nullable } from 'types/utils';

interface IOption {
    value: Nullable<string | number | boolean>;
    label: JSX.Element | string;
    disabled?: boolean;
}

interface ILabelRadio {
    options: IOption[];
    value: Nullable<string | number | boolean>;
    isRow?: boolean;
    className?: string;
    field: any;
    dataTestid?: string;
    onChange: (_, e) => any;
}

const LabelRadio = ({ options, value, isRow, field, className = '', dataTestid = '', onChange }: ILabelRadio) => {
    return (
        <FormControl>
            <RadioGroup {...field} row={isRow} className={`radio-with-label ${className}`} onChange={onChange} data-testid={dataTestid}>
                {options.map((option) => (
                    <FormControlLabel
                        {...field}
                        key={`option-${option.value}`}
                        value={option.value}
                        // @ts-ignore
                        control={<Radio inputProps={{ 'data-testid': `${dataTestid}-${option.value}` }} />}
                        disabled={option.disabled ?? false}
                        className={`radio-button-${value === option.value ? 'checked' : 'unchecked'}`}
                        label={option.label}
                        data-testid={`${dataTestid}-${option.value}`}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default LabelRadio;
