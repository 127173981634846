import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

/* Our Components */
import useBusinessModelsPro from 'hooks/businessModelsPro';
import BusinessModelsList from 'components/BusinessModels/List';
import ProjectSummary from 'components/BusinessModels/ProjectSummary';
import { BusinessModelsProContext, BusinessModelsProWebstompContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TBMs } from 'interfaces/businessModels/reducer';
import { isDefined } from 'services/util/auxiliaryUtils';
import useWebstomp from 'hooks/webstomp';
import ProposalGeneration from './ProposalGeneration';
import IntlMessages from 'components/util/IntlMessages';
import { Button } from '@save2compete/efz-design-system';

// Icons
import useOfferEdition from 'hooks/businessModels/useOfferEdition';
import copy from 'fast-copy';
import { FormProvider, useForm } from 'react-hook-form';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { TRubric } from 'interfaces/rubric';
import { goBackBModels } from 'services/settings';
import LoadingAlert from 'components/core/dialogs/DialogsAlert/LoadingAlert';

interface IProps {
    haveActions?: boolean;
}

export interface IBmDetailedForm {
    monthly_payment_option_id: number | null;
    annualEvoFee: number | null;
    extraCost: number;
    rubrics: TRubric[];
    opAndManDuration?: number | null;
    contact_info?: {
        name: string;
        email: string;
    };
    id_crm?: string;
    allowance?: string | null;
    unique_allowance_currency?: number | null;
    yearly_allowance_currency?: number | null;
    yearly_allowance_duration_years?: number | null;
    recOwnership?: boolean | null;
    ownershipTransfer?: boolean | null;
    itcRate?: number | null;
}
const BusinessModels = ({ haveActions = true }: IProps) => {
    const history = useHistory();

    const { isLoadingWebStomp, fetchSimulations, mqMessages } = useWebstomp();

    const webstompValues = {
        isLoadingWebStomp,
        fetchSimulations,
        mqMessages,
    };

    const { bmSelected, productID, isReformulation } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TBMs>;

    const {
        bmState,
        bmState: { elements, loadings },
    } = useContext(OfferEditionContext);

    const { isRequestingBM } = useBusinessModelsPro();
    const { postAuditHandler, cancelRequestHandler } = useOfferEdition();

    const defaultMonth = elements?.monthlyPayments?.options.default ?? null;
    const annualEvoFeeDefault = elements?.annualUpdateMonthlyFee?.options?.default ?? null;
    const recOwnershipDefault = elements?.recOwnership?.options?.default ?? null;
    const ownershipTransferDefault = elements?.ownershipTransfer?.options?.default ?? null;
    const itcRateDefault = elements?.itcRate?.options?.default ?? null;

    const rubrics = copy(bmState?.offerEdition?.system?.rubricsCosts);

    const methods = useForm<IBmDetailedForm>({
        defaultValues: {
            // @ts-ignore
            monthly_payment_option_id: defaultMonth ?? null,
            // @ts-ignore
            annualEvoFee: annualEvoFeeDefault ?? null,
            extraCost: 0,
            rubrics: rubrics,
            // @ts-ignore
            opAndManDuration: defaultMonth ?? -1,
            // @ts-ignore
            recOwnership: recOwnershipDefault ?? null,
            // @ts-ignore
            ownershipTransfer: ownershipTransferDefault ?? null,
            // @ts-ignore
            itcRate: itcRateDefault ?? null,
            contact_info: {
                name: '',
                email: '',
            },
            id_crm: '',
        },
        mode: 'onChange',
    });

    useEffect(() => {
        // @ts-ignore
        if (defaultMonth) methods.setValue('monthly_payment_option_id', defaultMonth);
        // @ts-ignore
        if (defaultMonth)
            methods.setValue(
                'opAndManDuration',
                elements.omContractDuration?.options.options?.find((el) => Number(el.contract_duration) === defaultMonth)
                    ?.om_durations_default
            );
    }, [defaultMonth]); // eslint-disable-line

    useEffect(() => {
        // @ts-ignore
        isDefined(annualEvoFeeDefault) &&
            !isDefined(methods.watch('annualEvoFee')) &&
            methods.setValue('annualEvoFee', Number(annualEvoFeeDefault));
    }, [annualEvoFeeDefault]); // eslint-disable-line

    useEffect(() => {
        // @ts-ignore
        isDefined(recOwnershipDefault) && methods.setValue('recOwnership', recOwnershipDefault);
        // @ts-ignore
        // @ts-ignore
        isDefined(itcRateDefault) && methods.setValue('itcRate', itcRateDefault);
    }, [recOwnershipDefault, ownershipTransferDefault, itcRateDefault]); // eslint-disable-line

    console.log(history, productID);
    return (
        <BusinessModelsProWebstompContext.Provider value={webstompValues}>
            {haveActions && (
                <div className="bm-proposal-generation back-btn">
                    <Button
                        variant="tertiary"
                        icon="startArrowLeft"
                        className="bm-proposal-generation-back"
                        disableRipple
                        onClick={() => {
                            goBackBModels(history, !isReformulation ? productID : null);
                        }}
                        dataTestId="bm-proposal-generation"
                    >
                        <IntlMessages id="label.previous" />
                    </Button>
                </div>
            )}
            <BusinessModelsList isRequestingBM={isRequestingBM} postAuditHandler={postAuditHandler} />
            <FormProvider {...methods}>
                {isDefined(bmSelected.values) && <ProjectSummary />}
                {isDefined(bmSelected.values) && haveActions && <ProposalGeneration />}
            </FormProvider>
            {loadings.detailedSimulation && (
                <LoadingAlert title="label.processing" isOpen={loadings.detailedSimulation} action={() => cancelRequestHandler()} />
            )}
        </BusinessModelsProWebstompContext.Provider>
    );
};

export default BusinessModels;
