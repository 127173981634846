import { DISPATCH_EVT } from 'constants/facilityPro/main';
import clone from 'fast-copy';
import {
    getFacilityDataB2B,
    getFacilityDataB2C,
    getOptions,
    getQuestionsFacilityDataB2B,
    getQuestionsFacilityDataB2C,
    initialFDataInputsB2B,
    initialFDataInputsB2C,
} from 'services/facilityPro/facilityData';

export const initialFacilityDataReducer = clone({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dispatchFacilityData: (type = '', payload = {}) => {},
    isAddFacility: false,
    isMounting: true,
    isB2C: false, //TODO: colocar no root FacilityPro
    countyAutocompleteLoading: false,
    markerLocation: {
        lat: 0,
        lng: 0,
        address: '',
        description: '', //for input autocomplete: '',
        postal_code: '',
        city: '',
        county: '',
        county_abrev: '',
        country: '',
    },
    setMarkerLocation: () => {},
    //#region inputs
    ...initialFDataInputsB2C(),
    ...initialFDataInputsB2B(),
    //#endregion inputs
    questions: {},
    //#region options dropdown
    classes: [],
    connectionVoltages: [],
    nuts: [],
    voltageLevels: [],
    counties: [],
    //#endregion options dropdown
});
export function facilityDataReducer(state = initialFacilityDataReducer, action) {
    switch (action.type) {
        case DISPATCH_EVT.SET_FACILITY_DATA: {
            let { payload } = action;
            let { inputs, options, responses } = payload;
            let { userLocale, companyProfileId, userChannelIDGroup, isAddFacility, isB2C, setMarkerLocation, configs } = options;

            let { facilityTypes, voltageLevels, connectionVoltages, nuts, classes, counties } = getOptions(responses, { userLocale });

            let newInputs =
                isB2C ?
                    getFacilityDataB2C(inputs, {
                        counties,
                        companyProfileId,
                        isAddFacility,
                        setMarkerLocation,
                        configs: configs?.b2c ?? {},
                    })
                :   getFacilityDataB2B(inputs, {
                        counties,
                        companyProfileId,
                        isAddFacility,
                        setMarkerLocation,
                        configs: configs?.b2b ?? {},
                    });

            let questions =
                isB2C ?
                    getQuestionsFacilityDataB2C({ isAddFacility, companyProfileId })
                :   getQuestionsFacilityDataB2B({
                        inputs: newInputs,
                        isAddFacility,
                        companyProfileId,
                        userChannelIDGroup,
                        voltageLevels,
                        connectionVoltages,
                        nuts,
                        classes,
                        counties,
                    });

            return {
                ...state,
                ...newInputs,
                ...questions,
                isAddFacility,
                companyProfileId,
                userChannelIDGroup,
                isB2C,
                facilityTypes,
                voltageLevels,
                connectionVoltages,
                nuts,
                classes,
                counties,
                isMounting: false,
            };
        }
        case DISPATCH_EVT.SET_COUNTY: {
            let { payload } = action;
            let { name, value } = payload;

            return {
                ...state,
                location: {
                    ...state.location,
                    [name]: value,
                },
            };
        }
        case DISPATCH_EVT.SET_POSTAL_CODE: {
            let { payload } = action;
            let { value } = payload;

            return {
                ...state,
                location: {
                    ...state.location,
                    postal_code: value,
                },
            };
        }
        default: {
            throw new Error(`Error on facilityData Reducer type: ${action.type}`);
        }
    }
}
