import { getCompanyProfileIds } from 'services/user';

export const installmentsKpis = (kpis, monthlyPaymentNumber, monthlyPayment, options) => {
    const { companyProfileId = null } = options ?? {};
    const investment = monthlyPaymentNumber * monthlyPayment;
    const annualSaving = kpis?.annual_saving;
    const payback = investment / annualSaving;

    let kpisToShow = [
        {
            label: 'label.investment',
            value: investment,
            numberOfDecimals: 0,
            unit: 'currency',
        },
        {
            label: 'label.annualSaving',
            value: typeof monthlyPayment === 'string' ? '-' : annualSaving,
            numberOfDecimals: 0,
            unit: 'currency',
        },
        {
            label: 'label.payback',
            value: payback,
            numberOfDecimals: 1,
            unit: 'year',
        },
        {
            label: 'label.monthlyPaymentsFee',
            value: monthlyPayment,
            numberOfDecimals: 0,
            unit: 'mc',
        },
        {
            label: 'label.monthlyPaymentsNumber',
            value: typeof monthlyPayment === 'string' ? '-' : monthlyPaymentNumber,
            numberOfDecimals: 0,
        },
    ];

    if (companyProfileId === getCompanyProfileIds().PPC)
        kpisToShow = kpisToShow.filter((kpi) => !['label.investment', 'label.annualSaving'].includes(kpi.label));
    return kpisToShow;
};

export const detailedInstallmentsKpis = (negotiationProfile, finalKpis) => {
    return [
        {
            label: 'page.businessModels.tirWacc',
            value: finalKpis?.irr_wacc ?? 'N/A',
            numberOfDecimals: 2,
            visible: negotiationProfile?.slider_tir_wacc,
        },
        {
            label: 'page.businessModels.fixedComission',
            value: finalKpis?.fixed_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_fixa,
            unit: 'currency',
        },
        {
            label: 'page.businessModels.downPayment',
            value: finalKpis?.downpayment ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.mostra_downpayment,
            unit: 'currency',
        },
        {
            label: 'page.businessModels.variableComission',
            value: finalKpis?.variable_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_variavel,
            unit: 'currency',
        },
        {
            label: 'page.businessModels.totalComission',
            value: finalKpis?.total_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_total,
            unit: 'currency',
        },
        {
            label: 'label.additionalValue',
            value: finalKpis?.additional_revenue ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.mostra_slider_valor_adicional,
            unit: 'currency',
        },
    ];
};
