import { useState } from 'react';

// Constants
import { RRMapActions } from 'constants/products/rr';

// Interfaces
import { IUseMap, TRRMapDispatcherValues } from 'interfaces/products/rr/map';

const useMap = (): IUseMap => {
    const [mainDrag, setMainDrag] = useState(false);

    function getPolygonBounds(polygon: google.maps.Polygon) {
        const bounds = new google.maps.LatLngBounds();
        polygon?.getPath().forEach((e) => bounds.extend(e));
        return bounds;
    }

    function getCenterId(polygon: google.maps.Polygon) {
        return getPolygonBounds(polygon).getCenter();
    }

    function handleAddListenerPolygon(
        polygon: google.maps.Polygon,
        options: { mapDispatch: (type: RRMapActions, payload: TRRMapDispatcherValues<RRMapActions>) => void }
    ) {
        const { mapDispatch } = options;
        //Quando começa a arrastar a zona
        polygon.addListener('dragstart', () => {
            setMainDrag(() => true);
        });

        //Quando acaba de arrastar a zona
        polygon.addListener('dragend', () => {
            setMainDrag(() => false);
        });

        //Quando é adicionado uma novo vertex no polygon selecionado
        google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
            mapDispatch(RRMapActions.EVT_INSERT_AT, {
                polygon,
            });
        });

        //Quando editamos o polygon (selecionado com o click do mouse)
        google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
            if (!mainDrag) {
                mapDispatch(RRMapActions.EVT_SET_AT, {
                    polygon,
                });
            }
        });

        //Quando criamos uma nova vertex e clicamos o undo "retroceder" no polygon selecionado
        google.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
            mapDispatch(RRMapActions.EVT_REMOVE_AT, {
                polygon,
            });
        });
    }

    return {
        mainDrag,
        setMainDrag,
        getPolygonBounds,
        getCenterId,
        handleAddListenerPolygon,
    };
};

export default useMap;
