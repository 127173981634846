import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { history } from '../redux/store';

// Constants
import { PRODUCT_IDS } from 'constants/products';
import { REFORMULATE_PROPOSAL_CONFIGS } from 'constants/reformulateProposal';

// Utils
import { getBoolean } from 'services/util/auxiliaryUtils';
import { getBrand } from 'services/settings';
import { UserContext } from './userContext';

const ReformulateProposalContext = createContext({
    headBrand: string,
    fieldProposal: object,
    setFieldProposal: func,
    configReformulateProposal: object,
    readyToReformulate: object,
    setReadyToReformulate: func,
    salesProposal: object,
    setSalesProposal: func,
    reformulateProposal: object,
    setReformulateProposal: func,
    reformulation: bool,
    setReformulation: func,
    fieldDataSS: object,
    hasBattery: bool,
    isRequestingReformulation: bool,
    setIsRequestingReformulation: func,
    isSPV: bool,
    companyProfileId: string,
    designImage: string,
    setDesignImage: func,
});
ReformulateProposalContext.displayName = 'efz-ReformulateProposal';

const ReformulateProposalProvider = ({ children }) => {
    const [salesProposal, setSalesProposal] = useState(null);
    const [fieldProposal, setFieldProposal] = useState(null);
    const [reformulateProposal, setReformulateProposal] = useState(null);
    const [readyToReformulate, setReadyToReformulate] = useState(false);
    const [reformulation, setReformulation] = useState(false);
    const [isRequestingReformulation, setIsRequestingReformulation] = useState(false);
    const [designImage, setDesignImage] = useState(null);

    const pathname = useMemo(() => history?.location?.pathname, [history?.location]); // eslint-disable-line

    const { userTypeID, companyProfileId } = useContext(UserContext);

    const configReformulateProposal = useMemo(
        () =>
            REFORMULATE_PROPOSAL_CONFIGS?.[companyProfileId]?.[userTypeID]?.[salesProposal?.tp_id] ??
            REFORMULATE_PROPOSAL_CONFIGS?.DEFAULT?.[userTypeID]?.[salesProposal?.tp_id],
        [companyProfileId, userTypeID, salesProposal]
    );
    const fieldDataSS = useMemo(() => fieldProposal?.field_data_ss, [fieldProposal]);

    useEffect(() => {
        if (!pathname.includes('reformulation')) {
            setSalesProposal(null);
            setFieldProposal(null);
            setIsRequestingReformulation(false);
        }
    }, [pathname]);

    return (
        <ReformulateProposalContext.Provider
            value={{
                headBrand: useSelector((state) => getBrand(state?.settings)) ?? null,
                fieldProposal,
                setFieldProposal,
                configReformulateProposal,
                readyToReformulate,
                setReadyToReformulate,
                salesProposal,
                setSalesProposal,
                reformulateProposal,
                setReformulateProposal,
                reformulation,
                setReformulation,
                fieldDataSS,
                hasBattery: getBoolean(salesProposal?.has_battery),
                isRequestingReformulation,
                setIsRequestingReformulation,
                isSPV: parseInt(salesProposal?.tp_id) === PRODUCT_IDS.SPV,
                companyProfileId,
                designImage,
                setDesignImage,
            }}
        >
            {children}
        </ReformulateProposalContext.Provider>
    );
};

export { ReformulateProposalProvider, ReformulateProposalContext };
