import { useContext, useEffect, useState } from 'react';
import { StatusCodes } from 'http-status-codes';

// API
import { postInstallmentsSimulation } from 'api/businessModels';

// Contexts / Hooks
import { isNumberDefined } from 'services/util/auxiliaryUtils';
import { computeRubricCostsPayload } from 'services/businessModels/index';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { BmLoadings } from 'constants/businessModelsPro/index';

let abortfetchSimulation;
const errorDefault = {
    text: null,
    newTariff: null,
    min_monthly_fee: null,
    max_monthly_fee: null,
};
const infoDefault = {
    text: null,
    min_monthly_fee: null,
    max_monthly_fee: null,
    new_min_monthly_fee: null,
    new_max_monthly_fee: null,
};

const useAddOnHelper = ({ onOpendialoHandler }) => {
    const {
        bmState: { businessModelBody, offerEditionValues, businessModelSelected, elements, offerEdition, addOnHelper, kpis },
        setBMEventHandler,
    } = useContext(OfferEditionContext);

    const [isRequesting, setIsRequesting] = useState(false);
    const [error, setError] = useState(errorDefault);

    const fetchSimulation = async (formData) => {
        try {
            setError(errorDefault);
            setIsRequesting(true);
            setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_LOADINGS, [{ name: BmLoadings.AddOnHelper, value: true }]);

            const bmBody = businessModelBody;
            const payload = {
                facility: {
                    id: bmBody?.facility_id,
                },
                inputs: {
                    ...bmBody?.inputs,
                    monthly_payment_option: Number(offerEditionValues?.monthly_payment_option_id),
                    annual_evo_fee: elements?.annualUpdateMonthlyFee ? Number(offerEditionValues?.annualUpdateFee) / 100 ?? 0 : undefined,
                    op_and_man_duration:
                        isNumberDefined(offerEditionValues?.opAndManDuration) ? offerEditionValues?.opAndManDuration : undefined,
                    offer_edition: {
                        ...bmBody?.inputs?.offer_edition,
                        extra_cost: Number(offerEditionValues?.extraCost),
                    },
                    allowance: offerEditionValues?.allowance,
                    add_on_value: {
                        ...formData,
                        value: Number(formData?.value),
                    },
                },
                tipo_modelo_negocio_id: businessModelSelected?.business_model_id,
                register_simulation: false,
            };

            if (offerEditionValues?.rubrics?.length > 0) {
                payload.inputs.offer_edition.costs = computeRubricCostsPayload(offerEdition.system.costs, offerEditionValues?.rubrics);
            }

            if (offerEdition?.is_customize) {
                payload.inputs.offer_edition.costs = offerEdition.system.costs;
            }

            abortfetchSimulation && abortfetchSimulation.abort();
            abortfetchSimulation = new AbortController();
            const simulationRsp = await postInstallmentsSimulation(payload, abortfetchSimulation);
            if (simulationRsp?.status === StatusCodes.OK) {
                const hasError =
                    simulationRsp?.data?.data?.negotiation?.add_on_values?.find((el) => el.simulator_var === formData?.simulator_var)
                        ?.limited_negotiation_bar &&
                    (simulationRsp?.data?.data?.negotiation?.pre_selected < kpis?.negotiation?.min_monthly_fee ||
                        simulationRsp?.data?.data?.negotiation?.pre_selected > kpis?.negotiation?.max_monthly_fee);
                const hasInfo = !simulationRsp?.data?.data?.negotiation?.add_on_values?.find(
                    (el) => el.simulator_var === formData?.simulator_var
                )?.limited_negotiation_bar;

                let info = infoDefault;
                if (hasInfo) {
                    info = {
                        // @ts-ignore
                        text: 'page.bm.addOnHelper.info',
                        min_monthly_fee: kpis?.negotiation?.min_monthly_fee,
                        max_monthly_fee: kpis?.negotiation?.max_monthly_fee,
                        new_min_monthly_fee:
                            simulationRsp?.data?.data?.negotiation?.efective_min_monthly_fee < kpis?.negotiation?.min_monthly_fee ?
                                simulationRsp?.data?.data?.negotiation?.efective_min_monthly_fee
                            :   kpis?.negotiation?.min_monthly_fee,
                        new_max_monthly_fee:
                            simulationRsp?.data?.data?.negotiation?.efective_min_monthly_fee > kpis?.negotiation?.max_monthly_fee ?
                                simulationRsp?.data?.data?.negotiation?.efective_min_monthly_fee
                            :   kpis?.negotiation?.max_monthly_fee,
                    };
                    // info alert cannot be shown if there are no changes to the margin
                    if (info?.min_monthly_fee === info?.new_min_monthly_fee && info?.max_monthly_fee === info?.new_max_monthly_fee) {
                        info = infoDefault;
                    }
                }
                setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER, {
                    kpis: simulationRsp?.data?.data,
                    reqsHash: simulationRsp?.data?.data?.req_id_arr,
                    formData: payload?.inputs?.add_on_value,
                    hasError,
                    info,
                });
                if (hasError) {
                    setError({
                        // @ts-ignore
                        text: 'page.bm.addOnHelper.error',
                        newTariff: simulationRsp?.data?.data?.negotiation?.pre_selected,
                        min_monthly_fee: kpis?.negotiation?.min_monthly_fee,
                        max_monthly_fee: kpis?.negotiation?.max_monthly_fee,
                    });
                } else {
                    onOpendialoHandler(false);
                }

                setIsRequesting(false);
            } else throw new Error('Error Simulation');
        } catch (error) {
            setIsRequesting(false);
            console.log('ERROR fetchSimulation ->', error);
        }
    };

    function resetValues() {
        setBMEventHandler(DetailedOfferEditionEvents.RESET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER, null);
    }

    useEffect(() => {
        return () => {
            abortfetchSimulation && abortfetchSimulation?.abort();
        };
    }, []);

    return {
        errorDefault,
        setError,
        error,
        onSubmitHandler: fetchSimulation,
        isRequesting,
        formData: addOnHelper?.formData ?? null,
        resetValues,
    };
};

export default useAddOnHelper;
