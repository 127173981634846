/**
 * @author Daniel Bastos <daniel.bastos@caixamagica.pt>
 *
 * @description Cria a condição de entrada num container e caso se verifiquem as condições envia o user para o container
 *
 * @version 20210105
 * @since 20210105 Initial release
 *
 */

import { lazy, useContext } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Error403 from 'components/errors/Error403';
//selectors
import PropTypes from 'prop-types';
import { BASENAME_URL_APP } from 'constants/settings';
import lazyLoader from 'services/lazyLoader';
import { withTracker } from 'services/withTracker';
import { isDefined } from 'services/util/auxiliaryUtils';
import { getClientID } from 'redux/selectors/client';
import { UserContext } from 'contexts/userContext';

const EditClientB2BPage = lazy(() => lazyLoader(() => import('pages/Restricted/client/edit/EditClientB2B')));
const EditClientB2CPage = lazy(() => lazyLoader(() => import('pages/Restricted/client/edit/EditClientB2C')));

/**
 * @context Route to EditClient
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EditClient = ({ clientID }) => {
    const hasAccess = isDefined(clientID);
    const { isB2C } = useContext(UserContext);

    const ClientPage = isB2C ? <EditClientB2CPage /> : <EditClientB2BPage />;

    return hasAccess ? ClientPage : (
            <Error403
                props={{
                    url: BASENAME_URL_APP + 'solutions',
                    buttonText: 'page.404.button.goSolutions',
                    message: 'page.error.403.unexpected',
                }}
            />
        );
};

EditClient.propTypes = {
    clientID: PropTypes.string.isRequired,
};

const mapStateToProps = ({ client }) => {
    return {
        clientID: getClientID(client),
    };
};

export default withRouter(connect(mapStateToProps)(withTracker(EditClient, 'Dados do Cliente')));
