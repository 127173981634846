import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { ENVIRONMENTS_FE } from 'constants/settings';
import FALSE_POSITIVE_REGEX_ENTRIES from 'constants/sentry';
import packageJson from '../../../package.json';
import { isOpenArea } from 'services/settings';
import { matchesAnyRegex, removeFieldEmptyInObj } from 'services/util/auxiliaryUtils';
import { useUserStore } from 'store/user';

/**
 * SentryInit
 * @constructor
 */
export const SentryInit = () => {
    if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
        const user = useUserStore.getState()?.user;
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DNS,
            // @ts-ignore
            environment: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SENTRY_ENVIROMENT : 'development',
            release: packageJson.name + '@' + packageJson.version,
            // @ts-ignore
            sampleRate: ['production', 'preproduction'].includes(process.env.REACT_APP_SENTRY_ENVIROMENT) ? 1 : 0.9,
            tracesSampleRate: process.env.REACT_APP_SENTRY_ENVIROMENT === 'production' ? 0.05 : 0.2,
            // This sets the sample rate to be 10%. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: 0.1,
            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_ENVIROMENT === 'production' ? 0.3 : 0.2,
            integrations: [
                new Sentry.Integrations.Breadcrumbs({
                    console: process.env.REACT_APP_SENTRY_ENVIROMENT === ENVIRONMENTS_FE.DEV,
                }),
                // new ProfilingIntegration(),
                new TracingIntegrations.BrowserTracing(),
                new Sentry.Replay({
                    maskAllText: true,
                    blockAllMedia: true,
                }),
            ],

            normalizeDepth: 5, //6 is too much information to send
            // @ts-ignore
            beforeSend: function (event, hint) {
                // filter out UnhandledRejection errors that have no information
                if (
                    event !== undefined &&
                    event.exception !== undefined &&
                    event.exception.values !== undefined &&
                    event.exception.values.length === 1
                ) {
                    const e = event.exception.values[0];
                    if (e.type === 'UnhandledRejection' && e.value === 'Non-Error promise rejection captured with value:') {
                        event.level = 'warning';
                    }
                }

                event.tags = {
                    ...event.tags,
                    is_false_positive: isFalsePositive(event, hint),
                };

                event.extra = {
                    ...event.extra,
                    evMsg: event?.message,
                    evExVal: event?.exception?.values?.[0]?.value,
                    // @ts-expect-error message
                    hintOriMsg: hint?.originalException?.message,
                    hintSyntMsg: hint?.syntheticException?.message,
                };

                return event;
            },
        });
        Sentry.setTag('company_code', user?.company_code);
        Sentry.setTag('perfil_id', user?.perfil_empresa_id);
        Sentry.setTag('empresa_id', user?.empresa_id);
    }
};

const SentryError = {
    1: 'fatal',
    2: 'error',
    3: 'warning',
    4: 'info',
    5: 'debug',
};

/**
 * SentryCaptureException
 * 
 * @param {
 *  level : 3,//Error 
 *  message : 'Something went wrong',
 *  context : { name: 'CacheBusterData',
                data:{  latestVersion: '1.2',
                        currentVersion: '1.0',
                        location: 'facility'
                        }
            }
 *  extrasContext : {facilityID: 123456},
 *  tags : {api: 'failities', component: 'header' },
 *  user : {userType:5, username: 'manager' },
 * } props 
 * 
 */
export function SentryCaptureException(props) {
    const scope = new Sentry.Scope();
    const user = useUserStore.getState()?.user;

    //#region props
    const message = props?.message ?? 'ALERT Something went wrong';
    const context = props?.context ?? message;
    const extrasContext = props?.extrasContext ?? null;
    const level = props?.level ?? 2;
    const fingerprint = props?.fingerprint ?? message;
    const tags = removeFieldEmptyInObj({
        ...props?.tags,
        company_code: user?.company_code,
        perfil_id: user?.perfil_empresa_id,
        empresa_id: user?.empresa_id,
        isOArea: isOpenArea(),
        is_customized: localStorage.getItem('is_customized') ?? null,
        pv_version: localStorage.getItem('pv_version') ?? null,
    });
    //#endregion

    // level
    // @ts-ignore
    scope.setLevel(SentryError[level]);
    scope.setFingerprint([fingerprint]);

    // context
    if (context) scope.setContext(context.name, context.data);

    // extrasContext
    if (extrasContext) scope.setExtras(extrasContext);

    // tags
    if (tags) scope.setTags(tags);

    // user
    // @ts-ignore
    if (user) scope.setUser(user);

    // @ts-ignore
    Sentry.captureMessage(message, scope);
}

const isFalsePositive = (event: Sentry.Event, hint: Sentry.EventHint) => {
    return (
        // @ts-expect-error message
        matchesAnyRegex(hint?.originalException?.name as string, FALSE_POSITIVE_REGEX_ENTRIES) ||
        (!!event?.message && matchesAnyRegex(event?.message, FALSE_POSITIVE_REGEX_ENTRIES)) ||
        (!!event?.exception?.values?.[0]?.value && matchesAnyRegex(event?.exception?.values?.[0]?.value, FALSE_POSITIVE_REGEX_ENTRIES)) ||
        // @ts-expect-error message
        (!!hint?.originalException?.message &&
            // @ts-expect-error message
            matchesAnyRegex(hint?.originalException?.message as string, FALSE_POSITIVE_REGEX_ENTRIES)) ||
        (!!hint?.syntheticException?.message && matchesAnyRegex(hint?.syntheticException?.message, FALSE_POSITIVE_REGEX_ENTRIES))
    );
};
