import PaPTitle from 'components/BusinessModels/ProjectSummary/Title';
import { FormProvider, useForm } from 'react-hook-form';
import Alert from 'components/@efz/Alert';
import IntlMessages from 'components/util/IntlMessages';
import { BusinessModelsProContext, BusinessModelsProWebstompContext } from 'contexts/businessModelsPro/businessModelsContext';
import { useContext } from 'react';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPaP } from 'interfaces/businessModels/pap';
import PaPOfferDetailsInputs from './OfferDetailsInputs';

const OfferDetailsPap = () => {
    const methods = useForm({
        defaultValues: {
            extraCost: 0,
        },
        mode: 'onChange',
    });

    const { state } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;

    const { isLoadingWebStomp } = useContext(BusinessModelsProWebstompContext);

    const isWarningGood = state?.selected?.values?.kpis_per_margin?.length > 0;

    return (
        <div className="bm-project-summary-card bm-project-summary-offer-details">
            <PaPTitle label={'label.offerDetails'} />
            <FormProvider {...methods}>
                <PaPOfferDetailsInputs />
            </FormProvider>
            {!isLoadingWebStomp && (
                <Alert severity={isWarningGood ? 'info' : 'error'} className="mt-4">
                    <IntlMessages id={`page.businessModels.papWarning.${isWarningGood ? 'good' : 'bad'}`} />
                </Alert>
            )}
        </div>
    );
};

export default OfferDetailsPap;
