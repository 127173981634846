import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';
import Skeleton from '@mui/material/Skeleton';
import { Nullable, TIntl } from 'types/utils';

interface IProps {
    label: TIntl;
    value: {
        number: Nullable<number>;
        unit?: string;
        numberOfDecimalPlaces: number;
    };
    isLoading: boolean;
}
const InstallmentsSimpleMarginKpi = ({ label, value, isLoading }: IProps) => {
    return (
        <div className="bm-kpis-margin-kpi">
            <label>
                <IntlMessages id={label} />
            </label>
            <span>
                {isLoading ?
                    <Skeleton height={20} width={100} /> // @ts-ignore
                :   <FormatNumber unit={value.unit} number={value.number} numberOfDecimalPlaces={value.numberOfDecimalPlaces} />}
            </span>
        </div>
    );
};

export default InstallmentsSimpleMarginKpi;
