import { useContext } from 'react';

// Constants
import { SIDEBAR_BUTTONS } from 'constants/sidebar';

// Contexts
import { SidebarContext } from 'contexts/sidebarContext';

// Components
import OpenSidebarButton from './OpenSidebarButton';
import SidebarButton from './SidebarButton';

const { SOLUTIONS, PROCESSES, BACKOFFICE } = SIDEBAR_BUTTONS;

const SidebarList = () => {
    const { isSidebarPermanent, sidebarConfig, showBackoffice } = useContext(SidebarContext);

    const solutionsButton = sidebarConfig.find((button) => button.name === SOLUTIONS);
    const processesButton = sidebarConfig.find((button) => button.name === PROCESSES);
    const backofficeButton = sidebarConfig.find((button) => button.name === BACKOFFICE);

    return (
        <>
            {isSidebarPermanent && <OpenSidebarButton />}
            {solutionsButton.visible && <SidebarButton button={solutionsButton} />}
            {processesButton.visible && <SidebarButton button={processesButton} />}
            {showBackoffice && <SidebarButton button={backofficeButton} />}
        </>
    );
};

export default SidebarList;
