import { intlMessages } from 'services/util/auxiliaryUtils';

//MESSAGE responses
export function getMessageServerError500() {
    return intlMessages('server.error.500');
}

export function getMessageServerError502() {
    return intlMessages('server.error.502');
}

export function getMessageSessionExpired() {
    return intlMessages('server.error.sessionExpired');
}

export function getMessageUnexpectedError() {
    return intlMessages('server.error.unexpectedError');
}

export function getMessageNetworkError() {
    return intlMessages('server.error.networkError');
}

export function getMessageIncorrectData() {
    return intlMessages('server.error.incorrectData');
}
