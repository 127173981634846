/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 * @description redux sagas - ALL
 *
 * @see redux-sagas docs: https://redux-saga.js.org/ || https://redux-saga.js.org/docs/basics/DeclarativeEffects.html
 *
 * @since 20190715 Initial release
 *
 */

import { all, spawn, call } from 'redux-saga/effects';
import client from './client';
import facility from './facility';
import businessModels from './businessModels';
import sizing from './sizing';
import product from './product';
import utils from './utils';
import proposal from './proposal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function* rootSaga(getState) {
    const sagas = [client, facility, sizing, businessModels, product, utils, proposal];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
        )
    );
}
