/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description redux Actions - Client
 *
 * @version 20191117
 * @since 20191117 Initial release
 *
 */

import {
    SET_CLIENT,
    SET_CLIENT_B2C,
    SET_IS_OPEN_CLIENT_CARD,
    REQUEST_GET_CLIENT,
    REQUEST_GET_CLIENT_SUCCESS,
    REQUEST_GET_CLIENT_FAIL,
    SET_CLIENT_CAE5,
    REQUEST_GET_CLIENT_DATA_BY_FACILITY,
    RESET_CLIENT,
    REQUEST_POST_CREATE_CLIENT,
    REQUEST_POST_CREATE_CLIENT_SUCCESS,
    REQUEST_POST_CREATE_CLIENT_FAIL,
    REQUEST_PUT_CLIENT_INFO,
    REQUEST_PUT_CLIENT_INFO_SUCCESS,
    REQUEST_PUT_CLIENT_INFO_FAIL,
    REQUEST_PUT_DISABLE_CLIENT,
    REQUEST_PUT_DISABLE_CLIENT_SUCCESS,
    REQUEST_PUT_DISABLE_CLIENT_FAIL,
    SET_CLIENT_BY_TOKEN,
    SET_CLIENT_FACILITIES,
    REQUEST_RESET_SUCCESSFULLY_CREATED_CLIENT,
    RESET_CLIENT_FACILITIES,
    SET_CLIENT_HISTORY,
} from 'constants/client';

export function setClientHistory(payload) {
    return {
        type: SET_CLIENT_HISTORY,
        payload,
    };
}

export function setClientFacilities(payload) {
    return {
        type: SET_CLIENT_FACILITIES,
        payload,
    };
}

export function setClientByToken(payload) {
    return {
        type: SET_CLIENT_BY_TOKEN,
        payload,
    };
}

export function requestGetClient(payload) {
    return {
        type: REQUEST_GET_CLIENT,
        payload,
    };
}

export function requestGetClientSuccess(payload) {
    return {
        type: REQUEST_GET_CLIENT_SUCCESS,
        payload,
    };
}

export function requestGetClientFail(payload) {
    return {
        type: REQUEST_GET_CLIENT_FAIL,
        payload,
    };
}

export function setIsOpenClientCard(payload) {
    return {
        type: SET_IS_OPEN_CLIENT_CARD,
        payload,
    };
}

export function setClient(payload) {
    return {
        type: SET_CLIENT,
        payload,
    };
}

export function setClientB2C(payload) {
    return {
        type: SET_CLIENT_B2C,
        payload,
    };
}

export function setClientCAE5(payload) {
    return {
        type: SET_CLIENT_CAE5,
        payload,
    };
}

export function requestGetClientDataByFacility(payload) {
    // Getting client data based on facility selected by facility search
    return {
        type: REQUEST_GET_CLIENT_DATA_BY_FACILITY,
        payload,
    };
}

//#region CREATE CLIENT
export function requestPostCreateClient(payload) {
    return {
        type: REQUEST_POST_CREATE_CLIENT,
        payload,
    };
}

export function requestPostCreateClientSuccess() {
    return {
        type: REQUEST_POST_CREATE_CLIENT_SUCCESS,
    };
}

export function requestPostCreateClientFail() {
    return {
        type: REQUEST_POST_CREATE_CLIENT_FAIL,
    };
}

export function requestResetSuccessfullyCreatedClient() {
    return {
        type: REQUEST_RESET_SUCCESSFULLY_CREATED_CLIENT,
    };
}
//#endregion CREATE CLIENT

//#region UPDATE CLIENT
export function requestPutClientInfo(payload) {
    return {
        type: REQUEST_PUT_CLIENT_INFO,
        payload,
    };
}

export function requestPutClientInfoSuccess(payload) {
    return {
        type: REQUEST_PUT_CLIENT_INFO_SUCCESS,
        payload,
    };
}

export function requestPutClientInfoFail() {
    return {
        type: REQUEST_PUT_CLIENT_INFO_FAIL,
    };
}
//#endregion UPDATE CLIENT

//#region DISABLE CLIENT
export function requestPutDisableClient(payload) {
    return {
        type: REQUEST_PUT_DISABLE_CLIENT,
        payload,
    };
}

export function requestPutDisableClientSuccess(payload) {
    return {
        type: REQUEST_PUT_DISABLE_CLIENT_SUCCESS,
        payload,
    };
}

export function requestPutDisableClientFail() {
    return {
        type: REQUEST_PUT_DISABLE_CLIENT_FAIL,
    };
}
//#endregion

export function resetClient(payload) {
    return {
        type: RESET_CLIENT,
        payload,
    };
}

export function resetClientFacilities() {
    return {
        type: RESET_CLIENT_FACILITIES,
    };
}
