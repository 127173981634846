import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { notify } from 'services/@efz/notify';
//Constants
import { CONFIG_HEADERS } from 'constants/endpoints';
import { BASENAME_URL_APP } from 'constants/settings';
import { TOAST_CHECK_STATUS_ERROR_ID, TOAST_MSG_SERVER_ERROR_ID, TOAST_SESSION_EXPIRED_ID } from 'constants/statusCodeHttp';
// Other
import { history } from '../../redux/store';
import { getMessageServerError500, getMessageSessionExpired } from '../statusCodeHttp';
import { isExistWord, isFieldDefined } from 'services/util/auxiliaryUtils';
import IntlMessages from 'components/util/IntlMessages';
import { StatusCodes } from 'http-status-codes';
import { SentryCaptureException } from './sentry';
import { TIntl } from 'types/utils';
import { TTokenUser } from 'interfaces/user';
import { ZodError } from 'zod';

//* AUTHENTICATION AXIOS INSTANCE *\\
export const axiosOpenAreaInstance = axios.create({
    headers: {
        Authorization: process.env.REACT_APP_BASIC_AUTH_PUB || '',
        'Content-Type': CONFIG_HEADERS['Content-Type'],
        cancellable: CONFIG_HEADERS['cancellable'],
        'Sales-Segment': localStorage.getItem('salesSegment') ?? '',
        timeout: CONFIG_HEADERS['timeout'],
    },
});

export const axiosSSOInstance = axios.create({
    headers: {
        Authorization: process.env.REACT_APP_SSO_AUTH_AUTHORIZATION ?? '',
        'X-Platform': 's2c',
        timeout: 15000,
    },
});

//#region agentkeepalive //TODO: disabled tmp  instalar a npm `agentkeepalive`
// const HttpAgentKeepAlive = require('agentkeepalive');
// const HttpsAgentKeepAlive = require('agentkeepalive').HttpsAgent;
// const agentkeepaliveConfig = {
//     httpAgent: new HttpAgentKeepAlive({
//         maxSockets: 512,
//         maxFreeSockets: 100,
//         timeout: 60000, // active socket keepalive for 60 seconds
//         freeSocketTimeout: 30000, // free socket keepalive for 30 def
//     }),
//     httpsAgent: new HttpsAgentKeepAlive({
//         maxSockets: 512,
//         maxFreeSockets: 100,
//         timeout: 60000, // active socket keepalive for 60 seconds
//         freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
//     })
// };
//#endregion agentkeepalive

//* AXIOS STATIC INSTANCE *\\

//#region Configure headers
axios.defaults.headers.common['Content-Type'] = CONFIG_HEADERS['Content-Type'];
axios.defaults.headers.common['cancellable'] = CONFIG_HEADERS['cancellable'];
axios.defaults.headers.common['timeout'] = CONFIG_HEADERS['timeout'];
// axios.defaults.httpAgent = agentkeepaliveConfig.httpAgent;
// axios.defaults.httpsAgent = agentkeepaliveConfig.httpsAgent;
//#endregion

// //#region Request interceptor
axios.interceptors.request.use(
    (config) => {
        config.headers = config.headers ?? {};
        //#region public requests
        const isAuth = isExistWord(config?.url, 'auth');
        const isResetPwd = isExistWord(config?.url, 'credentials-email');
        // let isLogout = (isExistWord(config?.url, 'logout'));
        if (isAuth) return config;
        if (isResetPwd) return config;
        //#endregion public requests

        //#region salesSegment
        config.headers['Sales-Segment'] = localStorage.getItem('salesSegment') ?? '';
        config.headers['Testperformance'] = localStorage.getItem('Testperformance') ?? ''; /* TMP */
        //#endregion salesSegment

        try {
            const token: string = localStorage.getItem('userToken') ?? '';
            const CPID = localStorage.getItem('CPID');
            const nowDate = Date.now() / 1000;
            const jwtDecodedToken: TTokenUser = jwt_decode(token);
            const tokenExpiryDate = jwtDecodedToken.exp;

            if (nowDate >= tokenExpiryDate || !isFieldDefined(CPID) || !isFieldDefined(token)) {
                // If token is valid but already expired
                history.push(BASENAME_URL_APP + 'logout');
                notify(getMessageSessionExpired(), 'warning', TOAST_SESSION_EXPIRED_ID);

                return Promise.reject('Logging out due to expired token');
            }

            config.headers['Authorization'] = 'JWT ' + localStorage.getItem('userToken');
            return config;
        } catch (error) {
            // If token is invalid
            history.push(BASENAME_URL_APP + 'logout');
            notify(getMessageSessionExpired(), 'warning', TOAST_SESSION_EXPIRED_ID);

            return Promise.reject('Logging out due to invalid token');
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosOpenAreaInstance.interceptors.request.use(
    (config) => {
        //#region salesSegment
        config.headers = config.headers ?? {};
        config.headers['Sales-Segment'] = localStorage.getItem('salesSegment') ?? '';
        //#endregion salesSegment

        return config;
    },
    (error) => {
        console.log('efz-> error', error);
    }
);
//#endregion

//#region Response interceptor
const errors500Msg = [
    'External API Error',
    'Could not get pv aux arrays',
    'no results for price zone',
    "Cannot read property 'map' of null",
    'External API error string indices must be integers',
];
axios.interceptors.response.use(undefined, (data) => {
    // console.log("Error", error.toJSON());
    // console.log("Error", error?.response);
    // let endpoint = data?.config?.url ?? null;;
    // console.log("interceptors error", error);
    // console.log("interceptors endpoint", endpoint);
    //#region Catch error CORS
    if (['ERR_NETWORK'].includes(data.code)) {
        notify(<IntlMessages id="server.error.networkError" />, 'error');
        return Promise.reject(data.response);
    }
    //#endregion Catch error CORS

    switch (data?.response?.status) {
        case StatusCodes.OK:
        case 515: //Service Temporarily Unavailable
        case StatusCodes.UNPROCESSABLE_ENTITY:
            break;
        case StatusCodes.UNAUTHORIZED: {
            //UNAUTHORIZE > LOGOUT
            const endpoint = data?.config?.url ?? null;
            if (!isExistWord(endpoint, 'auth') && !isExistWord(endpoint, 'info')) {
                history.push(BASENAME_URL_APP + 'logout');
                notify(getMessageSessionExpired(), 'warning', TOAST_SESSION_EXPIRED_ID);
            }
            break;
        }
        case StatusCodes.INTERNAL_SERVER_ERROR: {
            //#region monitorization 50X
            const endpoint = data?.config?.url ?? null;
            const payload = data?.response?.config?.data ?? null;
            const message = `API-Warning | status: ${StatusCodes.INTERNAL_SERVER_ERROR} | message: ${data?.response?.data?.message ?? data?.response?.data?.data}`;
            SentryCaptureException({
                level: 3,
                message,
                fingerprint: message,
                context: { endpoint },
                extrasContext: {
                    endpoint,
                    payload,
                    response: {
                        ...data?.response?.data,
                    },
                    axiosCode: data?.code ?? null,
                    axiosMessage: data?.message ?? null,
                    method: data?.config?.method ?? null,
                },
                tags: {
                    endpoint,
                    statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
                    axiosCode: data?.code ?? null,
                },
            });
            //#endregion
            if (isExistWord(endpoint, 'agg_type=monthly')) break;
            if (isExistWord(endpoint, 'crm')) break;

            if (errors500Msg.includes(data?.response?.data?.message)) {
                //Errors
                data.response.data.message = 500; //tag server.error.500
                break;
            }

            //#region not show notify
            if (isExistWord(endpoint, 'estimated-structures-aspect')) break;
            if (isExistWord(endpoint, 'solar-sensibility-analysis')) break;
            if (isExistWord(endpoint, 'simulation')) break;
            //#endregion

            notify(getMessageServerError500(), 'error', TOAST_MSG_SERVER_ERROR_ID);
            break;
        }
        case StatusCodes.BAD_REQUEST: {
            const endpoint = data?.config?.url ?? null;
            const payload = data?.response?.config?.data ?? null;
            if (['FISCAL_CODE_ALREADY_EXISTS'].includes(data?.response?.data?.message)) {
                notify(<IntlMessages id={('error.' + data.response.data.message) as TIntl} />, 'error', data.response.data.message);
                break;
            }
            const message = `API-Error | status: ${StatusCodes.BAD_REQUEST} | message: ${data?.response?.data?.message ?? data?.response?.data?.data}`;
            //#region monitorization status 400
            SentryCaptureException({
                level: 2,
                message,
                fingerprint: message,
                extrasContext: {
                    endpoint,
                    payload,
                    response: {
                        ...data?.response?.data,
                    },
                    axiosCode: data?.code ?? null,
                    axiosMessage: data?.message ?? null,
                    method: data?.config?.method ?? null,
                },
                tags: {
                    endpoint,
                    statusCode: StatusCodes.BAD_REQUEST,
                    axiosCode: data?.code ?? null,
                },
            });
            //#endregion

            if (isExistWord(endpoint, 'optimizer')) break;
            if (isExistWord(endpoint, 'credentials-email')) {
                break;
            }

            break;
        }
        default: {
            const endpoint = data?.config?.url ?? null;
            if ([StatusCodes.NOT_FOUND, StatusCodes.TOO_MANY_REQUESTS, StatusCodes.GATEWAY_TIMEOUT].includes(data?.response?.status)) {
                const payload = data?.response?.config?.data ?? null;
                const titleMessage =
                    StatusCodes.GATEWAY_TIMEOUT === data?.response?.status ?
                        'GATEWAY_TIMEOUT'
                    :   data?.response?.data?.message ?? data?.response?.data?.data ?? data?.response?.data;

                const message = `API-Warning | status: ${data?.response?.status} | message: ${titleMessage}`;
                SentryCaptureException({
                    level: 3,
                    message,
                    fingerprint: message,
                    extrasContext: {
                        endpoint,
                        payload,
                        method: data?.config?.method ?? null,
                        response: {
                            ...data?.response?.data,
                        },
                        axiosCode: data?.code ?? null,
                        axiosMessage: data?.message ?? null,
                    },
                    tags: {
                        endpoint,
                        statusCode: data?.response?.status,
                        axiosCode: data?.code ?? null,
                    },
                });
            }

            if (isExistWord(endpoint, 'crm')) return;
            if (isExistWord(endpoint, '/ar/') && !isExistWord(endpoint, 'code')) return; //EVC Measurements Info
            const isHeadSupportDocs = data?.response?.config?.method === 'head'; //only support-docsD
            if (!isHeadSupportDocs && data?.response) {
                notify(<IntlMessages id={'server.error.500' as TIntl} />, 'error', TOAST_CHECK_STATUS_ERROR_ID);
            }

            break;
        }
    }
    return data.response;
});
//#endregion

/**
 * Handles errors and logs them based on their type.
 *
 * @param options - The options object containing the error and tag.
 * @param options.error - The error object to be handled.
 * @param options.tag - The tag to identify the error.
 * @param options.fnc - The function name where the error was thrown.
 * @param options.isServer - (optional) Check if the request is server or client-side.
 */
export function errorCatchOnHandler(options: { error: unknown; tag: string; fnc: string }): void {
    const { error, tag, fnc } = options;
    // Quando o erro é do tipo cancelamento de request não é necessário mostrar mensagem de erro
    if (axios.isCancel(error)) {
        return;
    }

    const getErrorType = () => {
        if (error instanceof ZodError) return 'zod';
        else if (axios.isAxiosError(error)) return 'axios';
        return 'generic';
    };

    // Sentry handling
    SentryCaptureException({
        level: 3,
        message: `Warning | ${getErrorType().charAt(0).toUpperCase() + getErrorType().slice(1)} error: ${tag}`,
        tags: {
            fnc,
            error_type: getErrorType(),
        },
        extrasContext: {
            error,
        },
    });
}

export default axios;
