import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
export type TService = typeof PAYMENT_MODELS_IDS.SERVICE;

export type TServiceValues = {
    kpis_per_margin: {
        annual_saving: number;
        discount: number;
        margin: number;
        presentation_discount: number;
        presentation_margin: number;
        annual_price?: number;
        monthly_payments_fee: number;
        monthly_payments_number: number;
        monthly_fee?: number;
        total_platform_price_month: number;
        price?: number;
        investment: number;
        service_monthly_fee?: number;
        monthly_payments_fee_alt?: number;
    }[];
    margin_negotiation?: {
        default: number;
        search: string;
    };
    id_crm?: string;
};

export enum ServiceEvents {
    FETCH_SERVICE_BM = 'FETCH_SERVICE_BM',
    SET_SERVICE_BM = 'SET_SERVICE_BM',
    SET_PROPOSAL_READY = 'SET_PROPOSAL_READY',
    SET_SERVICE_PROPOSAL = 'SET_SERVICE_PROPOSAL',
}

export type TServiceEventsValues<Event> =
    Event extends typeof ServiceEvents.FETCH_SERVICE_BM ? null
    : Event extends typeof ServiceEvents.SET_SERVICE_BM ? TServiceValues
    : Event extends typeof ServiceEvents.SET_PROPOSAL_READY ? null
    : Event extends typeof ServiceEvents.SET_SERVICE_PROPOSAL ? null
    : any;
