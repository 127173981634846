import { useContext, useMemo } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from 'components/@efz/Tooltip';
import ErrorMessage from 'components/errors/ErrorMessage';
import IntlMessages from 'components/util/IntlMessages';
import { OFFER_DETAILS_INPUT_NAMES, OFFER_DETAILS_UPFRONT_CONFIGS } from 'constants/businessModelsPro';
import { PRODUCT_IDS } from 'constants/products';
import { Controller, useFormContext } from 'react-hook-form';
import { intlMessages } from 'services/util/auxiliaryUtils';
import ProjectSummarySubTitle from '../../SubTitle';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront, UpFrontEvents } from 'interfaces/businessModels/upfront';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import CustomUnit from 'components/util/CustomUnit';
import UpfrontRubrics from './Rubrics';
import { UserContext } from 'contexts/userContext';
import { getCompanyProfileIds } from 'services/user';
import EdpPTRubrics from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront/OfferDetails/EdpPTRubrics';

const UpFrontOfferDetailsInputs = () => {
    const { negotiationProfile, setBMEventHandler, state, productID } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TUpFront
    >;

    const { companyProfileId, userTypeID } = useContext(UserContext);

    const {
        control,
        // watch,
        formState: { errors },
    } = useFormContext();

    const configOfferDetailsUpFront = useMemo(
        () =>
            OFFER_DETAILS_UPFRONT_CONFIGS?.[companyProfileId]?.[userTypeID]?.[productID] ??
            OFFER_DETAILS_UPFRONT_CONFIGS?.DEFAULT?.[userTypeID]?.[productID],
        [companyProfileId, userTypeID, productID]
    );

    const updateValues = (value: number) => {
        setBMEventHandler(UpFrontEvents.SET_EXTRA_COST, {
            extra_cost: Number(value),
        });
    };

    const hasRubrics = useMemo(
        () => state?.selected?.values?.rubrics?.values?.length > 0 && negotiationProfile?.edita_rubricas,
        [negotiationProfile?.edita_rubricas, state?.selected?.values?.rubrics?.values?.length]
    );

    return (
        <>
            {[getCompanyProfileIds().EDP_PT].includes(companyProfileId) && [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB].includes(productID) && (
                <EdpPTRubrics />
            )}

            {hasRubrics && <UpfrontRubrics rubrics={state?.selected?.values?.rubrics?.values} />}

            {negotiationProfile?.acesso_custo_extra && (
                <div className="offer-details-inputs-optional">
                    {hasRubrics && <ProjectSummarySubTitle label={'label.costAdjustment'} />}

                    {/* COST ADJUSTMENT */}
                    {configOfferDetailsUpFront?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]?.visible && (
                        <div className={`adicional-cost-container ${!hasRubrics ? 'mt-4' : ''}`}>
                            {/* <div className="offer-details-container"> */}
                            <div className="label-container">
                                <label>
                                    <IntlMessages id="label.costAdjustment" />
                                </label>
                                <Tooltip title={<IntlMessages id="page.businessModels.costAdjustment.info" />} />
                            </div>
                            {negotiationProfile?.acesso_valores_custo && (
                                <div className="input-container">
                                    <Controller
                                        name={configOfferDetailsUpFront?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]?.name}
                                        control={control}
                                        rules={configOfferDetailsUpFront?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]?.rules ?? {}}
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        render={({ field: { ref, ...field } }) => (
                                            <OutlinedInput
                                                {...field}
                                                type={configOfferDetailsUpFront?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]?.inputType}
                                                placeholder={intlMessages('page.backoffice.solar.placeholder.warranty')}
                                                onChange={(evt) => {
                                                    const value = evt.target.value;
                                                    field.onChange(value);
                                                    updateValues(Number(value));
                                                }}
                                                endAdornment={
                                                    <InputAdornment className="ms-2" position="end">
                                                        {/* @ts-ignore */}
                                                        <CustomUnit unit={'currency'} />
                                                    </InputAdornment>
                                                }
                                                error={!!errors?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]}
                                            />
                                        )}
                                    />
                                    {errors?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT] && (
                                        <ErrorMessage
                                            // @ts-ignore
                                            error={errors[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]}
                                            values={{
                                                max: configOfferDetailsUpFront?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]?.optValidation
                                                    ?.max,
                                                min: configOfferDetailsUpFront?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]?.optValidation
                                                    ?.min,
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default UpFrontOfferDetailsInputs;
