/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description API requests to the context of Facilities
 *
 * @version 20191219
 * @version 20191216
 * @version 20191117
 * @since 20191117 Initial release
 *
 */

// import axios from 'axios';
import axios from 'services/@efz/axios';
import { getMessageNetworkError } from 'services/statusCodeHttp';
import { SentryCaptureException } from 'services/@efz/sentry';
import { URL_CLIENT, URL_FACILITIES, URL_IMPORT_EXTERNAL, getEndpoint } from 'constants/endpoints';

import { isDefined, removeEmptyInObj } from 'services/util/auxiliaryUtils';

const CancelToken = axios.CancelToken;
// Cancel executor variables
let postAFCancelExecutor;

/**
 * capturar os clientID = null
 * @param {*} fnc
 */
function SentryCapture(fnc) {
    SentryCaptureException({
        level: 3,
        message: 'clientID null',
        tags: {
            api: 'clients',
            fnc,
        },
    });
}

/**
 * getFacilitiesClient - Obtem todos os clientes de uma instalação
 *
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 */

export const getFacilitiesSearchByClient = (payload, abort) => {
    let qParams = '';
    if (payload?.qParams) {
        qParams = payload?.qParams;
    }
    //#region sentry warn
    if (!isDefined(payload.clientId)) {
        SentryCapture('getFacilitiesSearchByClient');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_CLIENT)}/${payload.clientId}/facilities${qParams}`,
            params: payload.filters,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * getFacilitiesSearch
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */

export const getFacilitiesSearch = (params, abort) => {
    return axios
        .request({
            url: getEndpoint(URL_FACILITIES),
            params: params,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * getExternalCompanies
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */

export const postExternalCompanies = (payload) => {
    return axios
        .post(getEndpoint(URL_IMPORT_EXTERNAL), payload)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const postAddFacility = (payload) => {
    postAFCancelExecutor && postAFCancelExecutor();
    const { clientID, body } = payload;

    //#region sentry warn
    if (!isDefined(clientID)) {
        SentryCapture('postAddFacility');
        return null;
    }
    //#endregion

    return axios
        .post(getEndpoint(URL_CLIENT) + '/' + clientID + '/facilities', removeEmptyInObj(body), {
            cancelToken: new CancelToken(function executor(c) {
                postAFCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
