import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import IntlMessages from 'components/util/IntlMessages';
import ProjectSummaryTotalCost from '../../../TotalCost';
import ProjectSummaryBaseCost from '../../../BaseCost';
import PPaOfferDetailsInputs from './OfferDetailsInputs';

// Constants
import { OFFER_DETAILS_INPUT_NAMES } from 'constants/businessModelsPro';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPPa } from 'interfaces/businessModels/ppa';

const OfferDetailsPPa = () => {
    const {
        state,
        negotiationProfile: { acesso_valores_custo },
        productPayload: { productInputs },
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;

    // useForm Here
    const methods = useForm({
        defaultValues: {
            [OFFER_DETAILS_INPUT_NAMES.MONITORING]: true,
            [OFFER_DETAILS_INPUT_NAMES.EQUIPMENT_WARRANTY_INVERTERS]: 3,
            [OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]: 0,
        },
    });
    const baseCost: number = productInputs?.kpis?.cost; /* SPVPro ?? SPVSimple */

    return (
        <>
            <div className="bm-project-summary-card bm-project-summary-offer-details">
                <div className="bm-project-summary-offer-details-header">
                    <h1>
                        <IntlMessages id="label.modelBusiness" />:
                    </h1>
                    <h2>{state.selected.options?.pitch.description ?? <IntlMessages id="product.typeBusinessModel.SERVICE_PPA" />}</h2>
                </div>
                <div className="bm-project-summary-offer-details-separator" />
                <ProjectSummaryTitle label={'label.SummaryOffer'} />
                <div className="bm-project-summary-offer-details-body">
                    <ProjectSummaryBaseCost cost={baseCost} />
                    <FormProvider {...methods}>
                        <PPaOfferDetailsInputs />
                    </FormProvider>
                </div>
                {acesso_valores_custo ?
                    <>
                        <div className="bm-project-summary-offer-details-separator" />
                        <ProjectSummaryTotalCost cost={state.selected.values.totalCost} />
                    </>
                :   <></>}
            </div>
        </>
    );
};

export default OfferDetailsPPa;
