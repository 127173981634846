import { BASENAME_URL, BASENAME_URL_COOKIES_POLICIES, BASENAME_URL_OAREA_PPC_B2B, BASENAME_URL_OAREA_PPC_B2C } from 'constants/settings';
import { BASENAME_URL_LOGIN } from 'constants/settings';

export const routeToCookiePolicy = (history, type, authenticated) => {
    history.push({
        pathname: authenticated ? BASENAME_URL_COOKIES_POLICIES : `${BASENAME_URL}cookie-policies`,
        state: { type: type },
    });
};

export const routeoLoogin = (history, isOArea) => {
    let OAreaURL;
    if (isOArea) {
        const segment = localStorage.getItem('salesSegment');
        OAreaURL = segment === 'B2C' ? BASENAME_URL_OAREA_PPC_B2C : BASENAME_URL_OAREA_PPC_B2B;
    }
    history.push({
        pathname: isOArea ? OAreaURL : BASENAME_URL_LOGIN,
    });
};

export const routeToCookiePolicyOA = (history, type) => {
    history.push({
        pathname: `${BASENAME_URL_OAREA_PPC_B2C}/cookie-policies`,
        state: { type: type },
    });
};
