import { MODULES } from 'constants/products';
//Our Components
//InputsDefault
export const INPUTS_DEFAULTS = {
    transformation_substations: [],
    work_schedule_id: null,
    interventions_number: 2,
    solution_option: 1,
};

export const INTERVENTIONS_NUMBER = {
    ANNUAL: 1,
    SEMIANNUAL: 2,
};

export const LIMITS_DEFAULTS = {
    TRANSFORMATION_SUBSTATIONS: {
        MIN: 0,
        MAX: 10,
    },
    TRANSFORMATION_SUBSTATIONS_DESCRIPTION: {
        MIN: 1,
        MAX: 100,
    },
    TRANSFORMATION_SUBSTATIONS_NUMBERS: {
        MIN: 1,
        MAX: 10,
    },
};

//Falta adicionar os limítes em formato constantes

export const INPUT_NAMES = {
    //Main Input Names
    WORK_SCHEDULE_ID: 'work_schedule_id',
    TRANSFORMATION_SUBSTATIONS: 'transformation_substations',
    //Transformation Names
    DESCRIPTION: 'description',
    HAS_VOLTAGE_WORK: 'has_voltage_work',
    TRANSFORMERS_NUMBER: 'transformers_number',
};

//QUESTIONS
export const QUESTIONS = {
    [INPUT_NAMES.TRANSFORMATION_SUBSTATIONS]: {
        order: 1,
        modules: [MODULES.SIMPLE],
        name: INPUT_NAMES.TRANSFORMATION_SUBSTATIONS,
        label: 'page.mpt.question.cards',
        tooltip: null,
        dependsOnInput: null,
        disabled: false,
        answers: null,
        visible: false,
        validation: null,
    },
    [INPUT_NAMES.WORK_SCHEDULE_ID]: {
        order: 3,
        name: INPUT_NAMES.WORK_SCHEDULE_ID,
        label: 'page.mpt.question.work_schedule_id',
        tooltip: 'page.mpt.question.work_schedule_id.tooltip',
        disabled: false,
        answers: null,
        options: null,
        visible: true,
        validation: { required: 'yup.message.required' },
    },
};

export const QUESTIONS_CARD = {
    [INPUT_NAMES.DESCRIPTION]: {
        label: 'page.mpt.question.description',
        name: INPUT_NAMES.DESCRIPTION,
        disabled: false,
        answers: null,
        options: null,
        visible: true,
        validation: {
            required: true,
            maxLength: LIMITS_DEFAULTS.TRANSFORMATION_SUBSTATIONS_DESCRIPTION.MAX,
            minLength: LIMITS_DEFAULTS.TRANSFORMATION_SUBSTATIONS_DESCRIPTION.MIN,
        },
    },
    [INPUT_NAMES.TRANSFORMERS_NUMBER]: {
        label: 'page.mpt.question.transformers_number',
        name: INPUT_NAMES.TRANSFORMERS_NUMBER,
        options: {
            max: LIMITS_DEFAULTS.TRANSFORMATION_SUBSTATIONS_NUMBERS.MAX,
            min: LIMITS_DEFAULTS.TRANSFORMATION_SUBSTATIONS_NUMBERS.MIN,
        },
        disabled: false,
        answers: null,
        visible: true,
        validation: {
            required: true,
            min: LIMITS_DEFAULTS.TRANSFORMATION_SUBSTATIONS_NUMBERS.MIN,
            max: LIMITS_DEFAULTS.TRANSFORMATION_SUBSTATIONS_NUMBERS.MAX,
        },
    },
    [INPUT_NAMES.HAS_VOLTAGE_WORK]: {
        name: INPUT_NAMES.HAS_VOLTAGE_WORK,
        label: 'page.mpt.question.has_voltage_work',
        notTranslateLabel: false,
        tooltip: 'page.mpt.question.has_voltage_work.tooltip',
        dependsOnInput: null,
        disabled: false,
        values: null,
        visible: true,
        validation: {},
        answers: [
            {
                label: 'label.yes',
                value: 'true',
            },
            {
                label: 'label.no',
                value: 'false',
            },
        ],
        options: { defaultValue: false, inRow: true },
    },
};
