import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
    requestGetFacilityStatsSuccess,
    requestGetFacilitySuccess,
    requestGetFacilityFail,
    requestGetCreateFacilitySuccess,
    requestGetCreateFacilityFail,
    requestSaveChangeFacilitySuccess,
    requestSaveChangeFacilityFail,
    requestGetFacilityTypesFail,
    requestGetFacilityTypesSuccess,
    requestSaveLoadCurveFacilityFail,
    requestSaveLoadCurveFacilitySuccess,
    requestDisableFacilityFail,
    requestDisableFacilitySuccess,
    requestWeightsByCAENoInformation,
    requestWeightsByCAEFail,
    requestWeightsByCAESuccess,
    requestSaveAnnualConsumptionSuccess,
    requestSaveAnnualConsumptionFail,
    requestSaveMonthlyConsumptionFail,
    requestSaveMonthlyConsumptionSuccess,
    requestUploadLoadCurveSuccess,
    requestUploadLoadCurveFail,
    requestDownloadLoadCurveSuccess,
    requestDownloadLoadCurveFail,
    requestGetFacilitySimpleFail,
    requestGetFacilitySimpleSuccess,
    requestDistributorsByNutSuccess,
    requestDistributorsByNutFail,
    requestPutWeightsByCustomPeriodsSuccess,
    requestPutWeightsByCustomPeriodsFail,
    requestSetGridTariffsSourcesSuccess,
} from 'redux/actions/facility';
//constants
import {
    REQUEST_GET_FACILITY_STATS,
    REQUEST_GET_FACILITY,
    REQUEST_GET_CREATE_FACILITY,
    REQUEST_SAVE_CHANGE_FACILITY,
    REQUEST_GET_FACILITY_TYPES,
    OPTIONS_LOAD_CURVE,
    REQUEST_SAVE_LOAD_CURVE_FACILITY,
    REQUEST_DISABLE_FACILITY,
    REQUEST_WEIGHTS_BY_CAE,
    REQUEST_ANNUAL_CONSUMPTION,
    REQUEST_MONTHLY_CONSUMPTION,
    REQUEST_UPLOAD_LOAD_CURVE,
    REQUEST_DOWNLOAD_LOAD_CURVE,
    REQUEST_DISTRIBUTORS_BY_NUT,
    REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS,
    REQUEST_SET_GRID_TARIFF_SOURCES,
    REQUEST_GET_FACILITY_SIMPLE,
} from 'constants/facility';
import { OK, UNAUTHORIZED } from 'constants/statusCodeHttp';
//api
import {
    getFacilityStats,
    getFacilityInfo,
    putFacilityInfo,
    getFacilityTypes,
    getEstimateWeights,
    putFacilityWeights,
    getFacilityConsumptionWeights,
    getFacilityConsumptionMonthly,
    postUploadLoadCurve,
    getDownloadLoadCurve,
} from 'api/facility';
import { getClient, putClientInfo } from 'api/client';
import { getFacilitiesSearchByClient } from 'api/facilities';
import { getConsumptions } from 'api/product';
import {
    getDistributors,
    getVoltageLevelsSearch,
    getGridTariffs,
    getGridTariffsSources,
    getTariffOptions,
    getSuppliers,
    getConnectionVoltagesSearch,
    getEnergyTariffs,
    getNuts,
    getClasses,
    getHourlyOptions,
    getUsedPowerTariffs,
} from 'api/utils';
import { requestGetClientSuccess, setClientCAE5 } from 'redux/actions/client';
import {
    calcWeightConsumptionArray,
    getMonthlyConsumptionFromDiff,
    getPayloadClient,
    getPayloadFacility,
    isInvalidCAE,
} from 'services/facility';
import { convertToUnit, intlMessages, isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';
import { notify } from 'services/@efz/notify';
import clone from 'fast-copy';
import { channelGroupRules, getCompanyProfileIds } from 'services/user';
import { GRUPO_CANAL_IDS } from 'constants/user';
import { orderByLocale } from 'services/dropdown';
import { StatusCodes } from 'http-status-codes';
import IntlMessages from 'components/util/IntlMessages';
import { ERROR_MESSAGES } from 'constants/facilityPro/main';

let getConsumptionsAController;

/**
 * _callGetFacilityStats   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetFacilityStats = async (payload) => {
    return await getFacilityStats(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetFacilityStats ', error);
            return error;
        });
};

/**
 *
 * @param payload
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>>}
 * @private
 */
function* _handlerGetFacilityStats({ payload }) {
    try {
        const fetchResult = yield call(_callGetFacilityStats, payload);

        if (fetchResult?.status === OK) yield put(requestGetFacilityStatsSuccess({ data: fetchResult.data }));
    } catch (error) {
        console.log('[SAGA] catch error _handlerGetFacilityStats ', error);
    }
}

const _callGetFacility = async (payload) => {
    return await getFacilityInfo(payload)
        .then((response) => {
            return response?.status !== OK ? response : response?.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetFacility ', error);
            return error;
        });
};

const _callGetFacilityConsumptionMonthly = async (payload) => {
    return await getFacilityConsumptionMonthly(payload)
        .then((response) => {
            return response?.status !== OK ? response?.data : response?.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetFacilityConsumptionMonthly ', error);
            return error;
        });
};

const _callGetFacilityConsumptionDaily = async (payload) => {
    return await getConsumptions(payload, payload.getConsumptionsAController)
        .then((response) => {
            return response?.status !== OK ? response?.data : response?.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetFacilityConsumptionDaily ', error);
            return error;
        });
};
const _callGetFacilityConsumptionWeights = async (payload) => {
    return await getFacilityConsumptionWeights(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetFacilityConsumptionWeights ', error);
            return error;
        });
};

const _callGetEstimateWeights = async (payload) => {
    return await getEstimateWeights(payload)
        .then((response) => {
            return response?.status !== OK ? response?.data : response?.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetEstimateWeights ', error);
            return error;
        });
};

/**
 * _callGetFacilityTypes   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetFacilityTypes = async () => {
    return await getFacilityTypes()
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetFacilityTypes ', error);
            return error;
        });
};

/**
 * _callGetDistributors   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetDistributors = async (payload) => {
    return await getDistributors(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetDistributors ', error);
            return error;
        });
};

/**
 * _callGetDistributors   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
function* _handlerGetDistributorsByNut({ payload }) {
    try {
        const fetchResult = yield call(_callGetDistributors, payload);

        if (fetchResult?.status === OK) yield put(requestDistributorsByNutSuccess({ distributors: fetchResult.data }));
    } catch (error) {
        yield put(requestDistributorsByNutFail());
        console.log('[SAGA] catch error _handlerGetDistributorsByNut ', error);
    }
}

/**
 * _callGetTariffOptions   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetTariffOptions = async () => {
    return await getTariffOptions()
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetTariffOptions ', error);
            return error;
        });
};

/**
 * _callGetGridTariffs   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetGridTariffs = async (params) => {
    return await getGridTariffs(params)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetGridTariffs ', error);
            return error;
        });
};

/**
 * _callGetGridTariffs   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetGridTariffsSources = async () => {
    return await getGridTariffsSources()
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetGridTariffsSources ', error);
            return error;
        });
};

/**
 * _callGetHourlyOptions   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetHourlyOptions = async () => {
    return await getHourlyOptions()
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetHourlyOptions ', error);
            return error;
        });
};

/**
 * _callGetUsedPowerTariffs   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetUsedPowerTariffs = async (params) => {
    return await getUsedPowerTariffs(params)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetUsedPowerTariffs ', error);
            return error;
        });
};

/**
 * _callGetEnergyTariffs   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetEnergyTariffs = async () => {
    return await getEnergyTariffs()
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetEnergyTariffs ', error);
            return error;
        });
};

/**
 * _callGetVoltageLevels   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetVoltageLevels = async () => {
    return await getVoltageLevelsSearch()
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetVoltageLevels ', error);
            return error;
        });
};

/**
 * _callGetConnectionVoltage   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetConnectionVoltage = async () => {
    return await getConnectionVoltagesSearch()
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetConnectionVoltage ', error);
            return error;
        });
};

/**
 * _callGetNuts   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetNuts = async () => {
    return await getNuts()
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetNuts ', error);
            return error;
        });
};

/**
 * _callGetClasses   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetClasses = async () => {
    return await getClasses()
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetClasses ', error);
            return error;
        });
};

/**
 * _callGetSuppliers   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetSuppliers = async () => {
    return await getSuppliers()
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetSuppliers ', error);
            return error;
        });
};

export function* _handlerRequestGetFacility({ payload }) {
    try {
        let getUserLanguage = (state) => state?.user?.idioma;
        let idioma = yield select(getUserLanguage);
        let initialRequests = [];

        const { companyProfileId } = payload;

        //1. Get Facility Information
        initialRequests.push(call(_callGetFacility, payload, '/resultFacility'));

        //2. Get Facility Types
        initialRequests.push(call(_callGetFacilityTypes, payload, '/resultFacilityType'));

        //3. Get Voltage Levels
        initialRequests.push(call(_callGetVoltageLevels, {}, '/resultVoltageLevels'));

        //5.Get Energy tariffs
        if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId)) {
            initialRequests.push(call(_callGetConnectionVoltage, {}, '/resultConnectionVoltages'));
            initialRequests.push(call(_callGetEnergyTariffs, {}, '/resultGetEnergyTariffs'));
        }

        //6. Get Grid Tariffs
        let resultTariffOptions;
        resultTariffOptions = yield call(_callGetTariffOptions);
        if ([getCompanyProfileIds().TELEFONICA].includes(companyProfileId)) {
            resultTariffOptions.data = resultTariffOptions.data.filter((item) => [78, 79, 80, 81, 82, 83].includes(parseInt(item.id))); //tmp US#1232
        }

        //8. Get suppliers
        let resultGetSuppliers;
        if (
            [
                getCompanyProfileIds().NRG,
                getCompanyProfileIds().ESB,
                getCompanyProfileIds().EDP_IT,
                getCompanyProfileIds().EDP_PL,
                getCompanyProfileIds().EFZ,
                getCompanyProfileIds().EDP_BR,
                getCompanyProfileIds().TELEFONICA,
                getCompanyProfileIds().PROFILE_BASE_SWISS,
                getCompanyProfileIds().PROFILE_BASE_USA,
                getCompanyProfileIds().PPC,
                getCompanyProfileIds().SUNSEAP,
                getCompanyProfileIds().ROMANDE,
            ].includes(companyProfileId)
        ) {
            resultGetSuppliers = yield call(_callGetSuppliers);
        }

        //9. Get nuts and Classes
        if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId)) {
            initialRequests.push(call(_callGetNuts, {}, '/resultGetNuts'));

            initialRequests.push(call(_callGetClasses, {}, '/resultGetClasses'));
        }

        //Wait for all requests to be done
        let [
            resultFacility,
            resultFacilityType,
            resultVoltageLevels,
            resultConnectionVoltages,
            resultGetEnergyTariffs,
            resultGetNuts,
            resultGetClasses,
        ] = yield all(initialRequests);

        //7. Get Grid Tariffs
        let resultGridTariffs;
        if ([getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
            resultGridTariffs = yield call(_callGetGridTariffs, {
                source_id: resultFacility?.data?.[0]?.fonte_tarifa_redes_id,
            });
        }
        if ([getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_PL, getCompanyProfileIds().EDP_BR].includes(companyProfileId)) {
            resultGridTariffs = yield call(_callGetGridTariffs);
        }

        let resultGridTariffSources;
        if ([getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
            resultGridTariffSources = yield call(_callGetGridTariffsSources);
        }

        //Fallback no CAE5
        if (!payload?.clientCAE5) {
            payload.clientCAE5 = payload?.clientCAE2;
        }
        //10. Requests from Simple Estimation or upload curve
        let resultFacilityConsumptionWeights = { data: null };
        let resultFacilityConsumptionMonthly = { data: null };
        let rspTariffPeriods = { data: [] };
        let opcao_curva_carga_id = resultFacility?.data?.[0]?.opcao_curva_carga_id;
        let isSimpleEstimation = resultFacility?.data?.[0]?.opcao_curva_carga_id === OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION;
        let tem_dados_ponderador_estimativa_simples = resultFacility?.data?.[0]?.tem_dados_ponderador_estimativa_simples;
        let tem_dados_ponderador_estimativa_simples_por_periodo =
            resultFacility?.data?.[0]?.tem_dados_ponderador_estimativa_simples_por_periodo;

        if (
            [OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION, OPTIONS_LOAD_CURVE.UPLOAD_CURVE, OPTIONS_LOAD_CURVE.INTEGRATION_DATA].includes(
                opcao_curva_carga_id
            )
        ) {
            if (
                !tem_dados_ponderador_estimativa_simples_por_periodo &&
                [OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION, OPTIONS_LOAD_CURVE.INTEGRATION_DATA].includes(opcao_curva_carga_id)
            ) {
                let isSimpleEstimationByCAE =
                    isSimpleEstimation && !tem_dados_ponderador_estimativa_simples && !tem_dados_ponderador_estimativa_simples_por_periodo;
                if (tem_dados_ponderador_estimativa_simples || resultFacility.data?.[0]?.tem_dados_ponderador_integracao_personalizada) {
                    //10.B Get Consumption Weights
                    resultFacilityConsumptionWeights = yield call(_callGetFacilityConsumptionWeights, payload);
                    //10.B Falllback call Estimates
                    if (
                        isSimpleEstimation &&
                        (resultFacilityConsumptionWeights?.data.length === 0 ||
                            resultFacilityConsumptionWeights?.data?.[0]?.weight === null)
                    ) {
                        //10.Fallback Get Estimate Weights
                        resultFacilityConsumptionWeights = yield call(_callGetEstimateWeights, payload);
                        //10.Fallback Check Status - Estimate Weights
                        if (resultFacilityConsumptionWeights?.status !== OK) {
                            yield put(requestWeightsByCAENoInformation());
                        }
                        //10. Check if has changes e Monthly consumptions
                        yield call(_callPutFacilityWeights, {
                            facilityID: payload?.facilityID,
                            body: resultFacilityConsumptionWeights.data,
                        });
                    }
                } else if (
                    !isSimpleEstimationByCAE &&
                    (!payload?.body?.tem_dados_ponderador_integracao_personalizada ||
                        !resultFacility.data?.[0]?.tem_dados_ponderador_integracao_personalizada)
                ) {
                    resultFacilityConsumptionWeights = yield call(_callGetFacilityConsumptionWeights, {
                        facilityID: payload.facilityID,
                    });
                }

                // CAE
                if (isSimpleEstimationByCAE) {
                    if (payload?.clientCAE5) {
                        //10.A Get Estimate Weights
                        resultFacilityConsumptionWeights = yield call(_callGetEstimateWeights, payload);
                        //10.A Check Status - Estimate Weights
                        if (resultFacilityConsumptionWeights?.status !== OK) {
                            yield put(requestWeightsByCAENoInformation());
                        }
                    } else {
                        notify(intlMessages('page.facility.error.undefinedCAE'), 'error');
                    }
                }
            }

            //10. Get Consumption Graph - Monthly
            resultFacilityConsumptionMonthly = yield call(_callGetFacilityConsumptionMonthly, payload);

            resultFacility.data[0].disabledIntegrationData =
                [getCompanyProfileIds().EDP_ES].includes(companyProfileId) &&
                resultFacilityConsumptionMonthly?.status === 500 &&
                [OPTIONS_LOAD_CURVE.INTEGRATION_DATA].includes(opcao_curva_carga_id);

            if (resultFacility.data[0].disabledIntegrationData) {
                //EDP_ES_disabledIntegrationData
                // put facility
                yield call(_callPutFacilityInfo, {
                    id: payload?.facilityID,
                    body: {
                        opcao_curva_carga_id: OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION,
                        tem_dados_ponderador_estimativa_simples: false,
                        tem_dados_integracao_estatisticos: false,
                    },
                });

                // update facility state
                resultFacility.data[0].opcao_curva_carga_id = OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION;
                resultFacility.data[0].tem_dados_ponderador_estimativa_simples = false;
                resultFacility.data[0].tem_dados_integracao_estatisticos = false;
                resultFacility.data[0].disabledIntegrationData = true;

                // try again _callGetFacilityConsumptionMonthly
                resultFacilityConsumptionMonthly = yield call(_callGetFacilityConsumptionMonthly, payload);
            }
        }

        //11. Get Consumption Graph - Daily
        getConsumptionsAController && getConsumptionsAController.abort();
        getConsumptionsAController = new AbortController();
        const resultFacilityConsumptionDaily = yield call(_callGetFacilityConsumptionDaily, {
            ...payload,
            getConsumptionsAController,
        });

        let resultDistributors;
        if (![getCompanyProfileIds().EDP_ES, getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
            resultDistributors = yield call(
                _callGetDistributors,
                [getCompanyProfileIds().EDP_BR].includes(companyProfileId) ?
                    {
                        nuts_id: resultFacility?.data?.[0]?.nuts_i_id,
                    }
                :   {}
            );
        }

        //12. Get Hourly Options
        let resultHourlyOptions;
        if ([getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
            resultHourlyOptions = yield call(_callGetHourlyOptions);
        }

        //13. Get Used Power Tariffs Options
        let resultUsedPowerTariffsOptions;
        if ([getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
            resultUsedPowerTariffsOptions = yield call(_callGetUsedPowerTariffs, {
                source_id: resultFacility?.data?.[0]?.fonte_tarifa_redes_id,
            });
        }

        if (resultFacility?.status === OK) {
            if (
                resultFacilityConsumptionMonthly?.message === 'MISSING_CONSUMPTION' ||
                resultFacilityConsumptionDaily?.message === 'MISSING_CONSUMPTION'
            ) {
                notify(<IntlMessages id={'server.error.spv.MISSING_CONSUMPTION'} />, 'error');
            }
            yield put(
                requestGetFacilitySuccess({
                    facilityType: orderByLocale(resultFacilityType?.data, 'facilityType', idioma) ?? [],
                    facility: resultFacility?.data?.[0] ?? null,
                    facilityConsumptionDaily: resultFacilityConsumptionDaily?.data ?? null,
                    facilityConsumptionMonthly: resultFacilityConsumptionMonthly?.data ?? null,
                    facilityConsumptionWeights: resultFacilityConsumptionWeights?.data ?? null,
                    distributors: resultDistributors?.data ?? null,
                    voltageLevels: resultVoltageLevels?.data ?? null,
                    conectionVoltages: resultConnectionVoltages?.data ?? null,
                    gridTariffs: resultGridTariffs?.data ?? null,
                    gridTariffSources: resultGridTariffSources?.data ?? null,
                    tariffOptions: resultTariffOptions?.data ?? [],
                    hourlyOptions: resultHourlyOptions?.data ?? [],
                    usedPowerTariffs: resultUsedPowerTariffsOptions?.data ?? [],
                    suppliers: resultGetSuppliers?.data ?? [],
                    energyTariffs: resultGetEnergyTariffs?.data ?? [],
                    nuts: resultGetNuts?.data ?? null,
                    classes: orderByLocale(resultGetClasses?.data, 'classes', idioma) ?? null,
                    tariffPeriods: rspTariffPeriods,
                    validCurveUploaded: !(
                        resultFacilityConsumptionMonthly?.message === ERROR_MESSAGES.WRONG_CONSUMPTION_LENGHT ||
                        resultFacilityConsumptionDaily?.message === ERROR_MESSAGES.WRONG_CONSUMPTION_LENGHT
                    ),
                })
            );
        }

        if (
            resultFacilityConsumptionMonthly?.message === 'MISSING_CONSUMPTION' ||
            resultFacilityConsumptionDaily?.message === 'MISSING_CONSUMPTION'
        ) {
            yield put(requestWeightsByCAENoInformation());
        }
        if (![OK, UNAUTHORIZED].includes(resultFacility?.status)) yield put(requestGetFacilityFail());
    } catch (error) {
        console.log('_handlerRequestGetFacilityTypes ERROR', error);
    }
}

function* _handlerRequestGetCreateFacility({ payload }) {
    try {
        let initialRequests = [];
        let getUserLanguage = (state) => state?.user?.idioma;
        let idioma = yield select(getUserLanguage);
        const { companyProfileId, userChannelIDGroup } = payload;
        const _isUserChannelGroup = channelGroupRules(userChannelIDGroup)?.isChannelGroup;

        //1. Get Facility Types
        initialRequests.push(call(_callGetFacilityTypes, payload, '/resultFacilityType'));

        //2. Get Voltage Levels
        initialRequests.push(call(_callGetVoltageLevels, {}, '/resultVoltageLevels'));

        //4.Get Energy tariffs
        if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId)) {
            initialRequests.push(call(_callGetConnectionVoltage, {}, '/resultConnectionVoltages'));
            initialRequests.push(call(_callGetEnergyTariffs, {}, '/resultGetEnergyTariffs'));
            initialRequests.push(call(_callGetNuts, {}, '/resultGetNuts'));
            initialRequests.push(call(_callGetClasses, {}, '/resultGetClasses'));
        }

        //5. Get Grid Tariffs
        let resultGridTariffs;
        if (
            [
                getCompanyProfileIds().EDP_IT,
                getCompanyProfileIds().EDP_PL,
                getCompanyProfileIds().EDP_BR,
                getCompanyProfileIds().EDP_PT,
            ].includes(companyProfileId)
        ) {
            resultGridTariffs = yield call(_callGetGridTariffs);
        }

        //7. Get Grid Tariffs
        let resultTariffOptions;
        resultTariffOptions = yield call(_callGetTariffOptions);
        if (
            [GRUPO_CANAL_IDS.BBVA, GRUPO_CANAL_IDS.LIBERBANK].includes(userChannelIDGroup) ||
            [getCompanyProfileIds().TELEFONICA].includes(companyProfileId)
        ) {
            resultTariffOptions.data = resultTariffOptions.data.filter((item) => [78, 79, 80, 81, 82, 83].includes(parseInt(item.id))); //tmp US#1232
        }

        //8. Get suppliers
        let resultGetSuppliers;
        if (
            [
                getCompanyProfileIds().NRG,
                getCompanyProfileIds().ESB,
                getCompanyProfileIds().EDP_IT,
                getCompanyProfileIds().EDP_PL,
                getCompanyProfileIds().EFZ,
                getCompanyProfileIds().EDP_BR,
                getCompanyProfileIds().TELEFONICA,
            ].includes(companyProfileId)
        ) {
            resultGetSuppliers = yield call(_callGetSuppliers);
        }

        //Wait for all requests to be done
        let [resultFacilityType, resultVoltageLevels, resultConnectionVoltages, resultGetEnergyTariffs, resultGetNuts, resultGetClasses] =
            yield all(initialRequests);

        // Get Distributors
        let resultDistributors;
        if (![getCompanyProfileIds().EDP_PT, getCompanyProfileIds().EDP_BR].includes(companyProfileId) || _isUserChannelGroup) {
            initialRequests.push((resultDistributors = yield call(_callGetDistributors, {})));
        }

        yield put(
            requestGetCreateFacilitySuccess({
                facilityType: orderByLocale(resultFacilityType?.data, 'facilityType', idioma) ?? [],
                facility: null,
                facilityConsumptionDaily: null,
                facilityConsumptionMonthly: null,
                facilityConsumptionWeights: null,
                distributors: resultDistributors?.data ?? null,
                voltageLevels: resultVoltageLevels?.data ?? null,
                conectionVoltages: resultConnectionVoltages?.data ?? null,
                gridTariffs: resultGridTariffs?.data ?? null,
                tariffOptions: resultTariffOptions?.data ?? [],
                suppliers: resultGetSuppliers?.data ?? [],
                energyTariffs: resultGetEnergyTariffs?.data ?? [],
                nuts: resultGetNuts?.data ?? null,
                classes: orderByLocale(resultGetClasses?.data, 'classes', idioma) ?? null,
            })
        );

        if (![OK, UNAUTHORIZED].includes(resultFacilityType?.status)) yield put(requestGetCreateFacilityFail());
    } catch (error) {
        console.log('_handlerRequestGetCreateFacility ERROR', error);
    }
}

// Save changes facility
const _callPutFacilityInfo = async (payload) => {
    return await putFacilityInfo(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callPutFacilityInfo ', error);
            return error;
        });
};

// Save changes facility
const _callPutFacilityWeights = async (payload) => {
    return await putFacilityWeights(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callPutFacilityInfo ', error);
            return error;
        });
};

const _callPutClientInfo = async (payload) => {
    return await putClientInfo(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callPutClientInfo ', error);
            return error;
        });
};

// _callGetFacilitiesSearchByClient
const _callGetFacilitiesSearchByClient = async (payload) => {
    return await getFacilitiesSearchByClient(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error getFacilitiesSearchByClient ', error);
            return error;
        });
};

//_callRequestGetSearchClients  request an API
const _callRequestGetClient = async (payload) =>
    await getClient(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callRequestGetSearchClients ', error);
            return error;
        });

function* _handlerRequestSaveChangeFacility({ payload }) {
    try {
        let initialRequests = [];
        const { clientID, facility, setFacilitiesList } = payload;
        let isRequestFail = false;

        //update facility
        if (facility.hasChanges && !isRequestFail) {
            const _facility = clone(facility);
            if (
                !_facility?.body?.consumo_anual &&
                !!_facility?.body?.consumo_anual_mwh &&
                !!parseFloat(_facility?.body?.consumo_anual_mwh)
            ) {
                _facility.body.consumo_anual = parseFloat(_facility?.body?.consumo_anual_mwh) * 1000;
            }

            const handlerPutFacility = yield call(_callPutFacilityInfo, _facility);

            if (handlerPutFacility?.status === StatusCodes.OK) {
                //1. Get Facilities Search
                initialRequests.push(call(_callGetFacilitiesSearchByClient, { clientId: clientID }, '/resultFacilitiesSearchByClient'));

                //2. Get Client Info
                initialRequests.push(call(_callRequestGetClient, clientID, '/rpsGetClient'));

                //Wait for all requests to be done
                let [resultFacilitiesSearchByClient, rpsGetClient] = yield all(initialRequests);

                if (resultFacilitiesSearchByClient?.status === StatusCodes.OK && rpsGetClient?.status === StatusCodes.OK) {
                    let data = {
                        ...rpsGetClient.data,
                        facilities: resultFacilitiesSearchByClient?.data ?? [],
                    };
                    setFacilitiesList(resultFacilitiesSearchByClient?.data);
                    yield put(requestGetClientSuccess({ data }));
                }

                //get facility
                if (!isRequestFail) {
                    const resultGetFacility = yield call(_callGetFacility, { facilityID: facility.id });
                    isRequestFail = ![OK, UNAUTHORIZED].includes(resultGetFacility?.status);
                }
            }
            if (!isRequestFail) {
                yield put(requestSaveChangeFacilitySuccess(payload));
            }
        }

        if (isRequestFail) yield put(requestSaveChangeFacilityFail());
    } catch (error) {
        console.log('_handlerRequestSaveChangeFacility ERROR', error);
    }
}

/**
 * _handlerRequestGetFacilityTypes
 *
 * @param payload
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>>}
 * @private
 */
function* _handlerRequestGetFacilityTypes({ payload }) {
    try {
        const response = yield call(_callGetFacilityTypes, payload);

        if (response?.status === OK) {
            yield put(requestGetFacilityTypesSuccess(response.data));
        } else {
            yield put(requestGetFacilityTypesFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestGetFacilityTypes ', error);
    }
}
//Get consumo anual
const getAnnualConsumption = (state) => state.facility.info.consumo_anual;
/**
 * _handlerRequestSaveLoadCurveInputs
 *
 * @param payload
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>>}
 * @private
 */
function* _handlerRequestSaveLoadCurveInputs({ payload }) {
    try {
        let resultFacilityConsumptionDaily = { data: null };
        let resultFacilityConsumptionMonthly = { data: null };
        let isRequestFail = false;
        let values = null;
        //0. Remove elements from payload
        const consumptionWeights = payload?.body?.consumption_weights;
        delete payload?.body?.consumption_weights;

        let activityCode = payload?.body?.activity_code ? payload?.body?.activity_code : payload?.clientCAE5;
        if ([OPTIONS_LOAD_CURVE.INTEGRATION_DATA].includes(payload?.body?.opcao_curva_carga_id)) {
            activityCode = null;
        }

        payload.body.activity_code = activityCode;
        payload.body.clientCAE5 = activityCode;

        let tem_dados_ponderador_estimativa_simples =
            isDefined(payload.body?.tem_dados_ponderador_estimativa_simples) && payload.body.tem_dados_ponderador_estimativa_simples;
        let tem_dados_ponderador_estimativa_simples_por_periodo =
            isDefined(payload.body?.tem_dados_ponderador_estimativa_simples_por_periodo) &&
            payload.body.tem_dados_ponderador_estimativa_simples_por_periodo;

        //No Estimate Client with data
        if (!!consumptionWeights && tem_dados_ponderador_estimativa_simples && !tem_dados_ponderador_estimativa_simples_por_periodo) {
            values = calcWeightConsumptionArray(consumptionWeights, payload?.facilityConsumptionWeightSum);
            payload.body.consumo_anual = convertToUnit(values.annualConsumption, 'fromMwToKw');
        }
        //With Estimate
        else if (!!consumptionWeights && !tem_dados_ponderador_estimativa_simples_por_periodo) {
            payload.body.consumo_anual = yield select(getAnnualConsumption);
        }

        //Delete unnecessary attributes
        let facilityPayload = clone(payload);
        delete facilityPayload.body.clientCAE5;
        if (OPTIONS_LOAD_CURVE.INTEGRATION_DATA === payload?.body?.opcao_curva_carga_id) {
            delete facilityPayload.body.activity_code;
        }
        if (!isFieldDefined(facilityPayload.body.consumo_anual)) delete facilityPayload.body.consumo_anual;

        //2. Save CAE in Client and Set Value
        if (!!activityCode && !isInvalidCAE(activityCode) && !tem_dados_ponderador_estimativa_simples_por_periodo) {
            //2. Change Client CAE - To detect changes
            yield put(setClientCAE5({ cae5: activityCode, cae2: activityCode.substring(0, 2) }));

            //3. Update client
            yield call(_callPutClientInfo, {
                id: payload.clientID,
                body: {
                    cae5: activityCode,
                    cae2: activityCode.substring(0, 2),
                },
            });
        }

        //1. Update Facility
        const resultPutFacility = yield call(_callPutFacilityInfo, facilityPayload);
        const resultGetFacility = yield call(_callGetFacility, { facilityID: payload.id });

        // return;
        //1. Verify status - Update Facility
        isRequestFail = ![OK, UNAUTHORIZED].includes(resultPutFacility?.status);

        let isSimpleEstimation = payload?.body?.opcao_curva_carga_id === OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION;
        let isIntegrationData = payload?.body?.opcao_curva_carga_id === OPTIONS_LOAD_CURVE.INTEGRATION_DATA;
        if (!!values && payload?.action !== 'changeRadioPonderador' && !isInvalidCAE(activityCode)) {
            //1.1. Check if has changes e Monthly consumptions
            yield call(_callPutFacilityWeights, {
                facilityID: payload.id,
                body: values.facilityMonthlyWeight,
            });
        }
        //4. Requests from Simple Estimation or upload curve
        let resultFacilityConsumptionWeights = { data: null };
        if (
            [OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION, OPTIONS_LOAD_CURVE.UPLOAD_CURVE, OPTIONS_LOAD_CURVE.INTEGRATION_DATA].includes(
                payload?.body?.opcao_curva_carga_id
            )
        ) {
            if ([OPTIONS_LOAD_CURVE.INTEGRATION_DATA, OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION].includes(payload?.body?.opcao_curva_carga_id)) {
                //4 Get Consumption Weights && !tem_dados_ponderador_estimativa_simples_por_periodo
                if (!tem_dados_ponderador_estimativa_simples_por_periodo) {
                    if (
                        (tem_dados_ponderador_estimativa_simples && !tem_dados_ponderador_estimativa_simples_por_periodo) ||
                        payload?.body?.tem_dados_ponderador_integracao_personalizada
                    ) {
                        //4.B Get Consumption Weights
                        resultFacilityConsumptionWeights = yield call(_callGetFacilityConsumptionWeights, {
                            facilityID: payload.id,
                        });

                        //SIMPLE_ESTIMATION
                        if (isSimpleEstimation) {
                            //4. Fallback call Estimates
                            if (resultFacilityConsumptionWeights?.data?.length === 0 && !isInvalidCAE(activityCode)) {
                                //4.Fallback Get Estimate Weights
                                resultFacilityConsumptionWeights = yield call(_callGetEstimateWeights, payload.body);
                                if (
                                    resultFacilityConsumptionWeights?.status !== OK &&
                                    payload?.body?.opcao_curva_carga_id === OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION
                                ) {
                                    yield put(requestWeightsByCAENoInformation());
                                }
                                //5. Check if has changes e Monthly consumptions
                                yield call(_callPutFacilityWeights, {
                                    facilityID: payload.id,
                                    body: resultFacilityConsumptionWeights.data,
                                });
                            }
                        }

                        //#region INTEGRATION_DATA (tem_dados_ponderador_integracao_personalizada)
                        if (
                            isIntegrationData &&
                            payload.body?.tem_dados_ponderador_integracao_personalizada &&
                            resultFacilityConsumptionWeights?.data?.length === 0
                        ) {
                            let getMonthlyConsumption = (state) => state?.facility?.options?.monthlyConsumption;
                            let monthlyConsumption = yield select(getMonthlyConsumption);
                            let _monthlyConsumption = monthlyConsumption.map((item) => ({
                                month: item.month,
                                weight: item?.weight,
                            }));

                            // //Check if has changes e Monthly consumptions
                            yield call(_callPutFacilityWeights, {
                                facilityID: payload.id,
                                body: _monthlyConsumption,
                            });
                            resultFacilityConsumptionWeights = yield call(_callGetFacilityConsumptionWeights, { facilityID: payload.id });
                        }
                        //#endregion
                    } else if (!activityCode && !payload?.body?.tem_dados_ponderador_integracao_personalizada) {
                        resultFacilityConsumptionWeights = yield call(_callGetFacilityConsumptionWeights, {
                            facilityID: payload.id,
                        });
                    } else {
                        if (!!activityCode && !isInvalidCAE(activityCode)) {
                            //4.A Get Estimate Weights
                            resultFacilityConsumptionWeights = yield call(_callGetEstimateWeights, payload.body);
                            //4.A Check Status - Estimate Weights
                            if (resultFacilityConsumptionWeights?.status !== OK) {
                                yield put(requestWeightsByCAENoInformation());
                            }
                        }
                    }
                }
            }

            //Get Requests
            let getRequests = [];
            //Get Monthly Graph
            getRequests.push(call(_callGetFacilityConsumptionMonthly, { facilityID: payload.id }));
            //Get Daily
            getConsumptionsAController && getConsumptionsAController.abort();
            getConsumptionsAController = new AbortController();
            getRequests.push(call(_callGetFacilityConsumptionDaily, { facilityID: payload.id, getConsumptionsAController }));
            [resultFacilityConsumptionMonthly, resultFacilityConsumptionDaily] = yield all(getRequests);
        } else {
            //7. Get Consumption Graph - Daily
            getConsumptionsAController && getConsumptionsAController.abort();
            getConsumptionsAController = new AbortController();
            resultFacilityConsumptionDaily = yield call(_callGetFacilityConsumptionDaily, {
                facilityID: payload.id,
                getConsumptionsAController,
            });
        }

        //Request Successfull
        if (!isRequestFail)
            yield put(
                requestSaveLoadCurveFacilitySuccess({
                    facilityConsumptionDaily: resultFacilityConsumptionDaily?.data ?? [],
                    facilityConsumptionMonthly: resultFacilityConsumptionMonthly?.data ?? [],
                    facilityConsumptionWeights: resultFacilityConsumptionWeights?.data ?? [],
                    optionLoadCurve: payload?.body?.opcao_curva_carga_id,
                    annualConsumption: payload?.body?.consumo_anual,
                    horarioTemImpacto: payload?.body?.horario_tem_impacto,
                    horarioDiasSemana: payload?.body?.horario_dias_semana,
                    horarioSabado: payload?.body?.horario_sabado,
                    horarioDomingo: payload?.body?.horario_domingo_e_feriado,
                    trabalha_2a: payload?.body?.trabalha_2a,
                    trabalha_3a: payload?.body?.trabalha_3a,
                    trabalha_4a: payload?.body?.trabalha_4a,
                    trabalha_5a: payload?.body?.trabalha_5a,
                    trabalha_6a: payload?.body?.trabalha_6a,
                    trabalha_dom: payload?.body?.trabalha_dom,
                    trabalha_feriado: payload?.body?.trabalha_feriado,
                    trabalha_sab: payload?.body?.trabalha_sab,
                    working_days: payload?.body?.working_days,
                    type: payload?.action,
                    facilityData: resultGetFacility?.data?.[0] ?? null,
                    validCurveUploaded: !(
                        resultFacilityConsumptionMonthly?.message === 'WRONG_CONSUMPTION_LENGHT' ||
                        resultFacilityConsumptionDaily?.message === 'WRONG_CONSUMPTION_LENGHT'
                    ),
                })
            );

        //Request Failed
        if (isRequestFail) yield put(requestSaveLoadCurveFacilityFail());

        if (
            resultFacilityConsumptionMonthly?.message === 'MISSING_CONSUMPTION' ||
            resultFacilityConsumptionDaily?.message === 'MISSING_CONSUMPTION'
        ) {
            yield put(requestWeightsByCAENoInformation());
        }

        if (
            resultFacilityConsumptionMonthly?.message === 'WRONG_CONSUMPTION_LENGHT' ||
            resultFacilityConsumptionDaily?.message === 'WRONG_CONSUMPTION_LENGHT'
        ) {
            notify(<IntlMessages id="page.facility.uploadCurve.wrongConsumptionLenght" />, 'error');
        }

        if (
            resultFacilityConsumptionMonthly?.message === 'WRONG_CONSUMPTION_LENGHT' ||
            resultFacilityConsumptionDaily?.message === 'WRONG_CONSUMPTION_LENGHT'
        ) {
            notify(<IntlMessages id="page.facility.uploadCurve.wrongConsumptionLenght" />, 'error');
        }

        if (
            resultFacilityConsumptionMonthly?.message === 'WRONG_CONSUMPTION_LENGHT' ||
            resultFacilityConsumptionDaily?.message === 'WRONG_CONSUMPTION_LENGHT'
        ) {
            notify(<IntlMessages id="page.facility.uploadCurve.wrongConsumptionLenght" />, 'error');
        }
    } catch (error) {
        console.log('_handlerRequestSaveLoadCurveInputs ERROR', error);
    }
}

// Disable facility
const _callDisableFacility = async (payload) => {
    return await putFacilityInfo(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callDisableFacility ', error);
            return error;
        });
};

function* _handlerRequestDisableFacility({ payload }) {
    try {
        const fetchResult = yield call(_callDisableFacility, payload);

        if (fetchResult?.status === OK) {
            yield put(requestDisableFacilitySuccess({ facilityID: payload.id }));
            notify(<IntlMessages id="server.success.removeFacility" />, 'success');
        } else {
            yield put(requestDisableFacilityFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestDisableFacility ', error);
        yield put(requestDisableFacilityFail());
    }
}

//Get consumo anual
const getTemDadosPonderador = (state) => state.facility.info.tem_dados_ponderador_estimativa_simples;

function* _handlerRequestWeightsByCAE({ payload }) {
    try {
        //Put client
        const resultPutClient = yield call(_callPutClientInfo, getPayloadClient(payload));
        if (resultPutClient?.status === OK) {
            yield put(setClientCAE5({ cae5: payload?.clientCAE5 }));

            let resultFacilityConsumptionWeights = {};

            const temDadosPonderador = yield select(getTemDadosPonderador);
            if (!temDadosPonderador) {
                //Get Estimates by CAE
                resultFacilityConsumptionWeights = yield call(_callGetEstimateWeights, payload);
            }
            //No data for desired CAE
            if (resultFacilityConsumptionWeights?.status === OK || !!payload?.temDadosPonderador) {
                //Get Requests
                let getRequests = [];
                //Get Monthly Graph
                getRequests.push(call(_callGetFacilityConsumptionMonthly, payload));
                //Get Daily
                getConsumptionsAController && getConsumptionsAController.abort();
                getConsumptionsAController = new AbortController();
                getRequests.push(call(_callGetFacilityConsumptionDaily, { ...payload, getConsumptionsAController }));
                let [resultFacilityConsumptionMonthly, resultFacilityConsumptionDaily] = yield all(getRequests);

                if (
                    resultFacilityConsumptionMonthly?.message === 'MISSING_CONSUMPTION' ||
                    resultFacilityConsumptionDaily?.message === 'MISSING_CONSUMPTION'
                ) {
                    yield put(requestWeightsByCAENoInformation());
                } else {
                    let annualConsumption = clone(payload.consumo_anual);

                    //Wait for responses
                    yield put(
                        requestWeightsByCAESuccess({
                            clientCAE5: payload?.clientCAE5,
                            annualConsumption: annualConsumption,
                            facilityConsumptionDaily: resultFacilityConsumptionDaily?.data,
                            facilityConsumptionMonthly: resultFacilityConsumptionMonthly?.data,
                            facilityConsumptionWeights: resultFacilityConsumptionWeights?.data,
                        })
                    );
                }
            }
            //CAE with no data
            else {
                yield put(requestWeightsByCAENoInformation());
            }
        } else {
            yield put(requestWeightsByCAEFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestWeightsByCAE ', error);
        yield put(requestWeightsByCAEFail());
    }
}

//Get consumo anual
const getMonthlyConsumption = (state) => state?.facility?.options?.monthlyConsumption;

function* _handlerRequestAnnualConsumption({ payload }) {
    try {
        let annualConsumption = clone(payload.consumo_anual);
        let annualConsumptionMWH = clone(payload.consumo_anual_mwh);
        //Update Facility
        const resultPutFacility = yield call(_callPutFacilityInfo, getPayloadFacility(payload));
        if (resultPutFacility?.status === OK) {
            //Get Requests
            let getRequests = [];
            //Get Monthly Graph
            getRequests.push(call(_callGetFacilityConsumptionMonthly, clone(payload)));
            //Get Daily
            getConsumptionsAController && getConsumptionsAController.abort();
            getConsumptionsAController = new AbortController();
            getRequests.push(call(_callGetFacilityConsumptionDaily, { ...payload, getConsumptionsAController }));

            const arrayConsumptions = yield select(getMonthlyConsumption);
            const oldAnnualConsumption = yield select(getAnnualConsumption);

            let diff = annualConsumption / (oldAnnualConsumption === 0 ? annualConsumption : oldAnnualConsumption);

            const _consumptionWeights = getMonthlyConsumptionFromDiff(diff, arrayConsumptions, {
                annualConsumption,
                oldAnnualConsumption,
            });

            let [resultFacilityConsumptionMonthly, resultFacilityConsumptionDaily] = yield all(getRequests);

            yield put(
                requestSaveAnnualConsumptionSuccess({
                    consumo_anual: annualConsumption,
                    consumo_anual_mwh: annualConsumptionMWH,
                    consumption_weights: _consumptionWeights,
                    facilityConsumptionDaily: resultFacilityConsumptionDaily?.data,
                    facilityConsumptionMonthly: resultFacilityConsumptionMonthly?.data,
                })
            );
        } else {
            yield put(requestSaveAnnualConsumptionFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestAnnualConsumption ', error);
        yield put(requestSaveAnnualConsumptionFail());
    }
}

function* _handlerRequestMonthlyConsumption({ payload }) {
    try {
        const values = calcWeightConsumptionArray(payload?.monthly_consumptions, payload?.facilityConsumptionWeightSum);

        //Error in formula
        if (values?.facilityMonthlyWeight?.find((item) => item.weight === null)) yield put(requestSaveMonthlyConsumptionFail());

        //Put Requests
        let putRequests = [];

        //Put weights
        putRequests.push(
            call(_callPutFacilityWeights, {
                facilityID: payload.facilityID,
                body: values.facilityMonthlyWeight,
            })
        );
        //Put facility
        putRequests.push(call(_callPutFacilityInfo, getPayloadFacility(payload)));

        //Wait for response
        let [resultPutFacilityWeights, resultPutFacility] = yield all(putRequests);

        //Check status
        if (resultPutFacilityWeights?.status === OK && resultPutFacility?.status === OK) {
            //Get Requests
            let getRequests = [];
            //Get Monthly Graph
            getRequests.push(call(_callGetFacilityConsumptionMonthly, payload));
            //Get Daily
            getConsumptionsAController && getConsumptionsAController.abort();
            getConsumptionsAController = new AbortController();
            getRequests.push(call(_callGetFacilityConsumptionDaily, { ...payload, getConsumptionsAController }));
            let [resultFacilityConsumptionMonthly, resultFacilityConsumptionDaily] = yield all(getRequests);

            yield put(
                requestSaveMonthlyConsumptionSuccess({
                    consumo_anual: payload.consumo_anual,
                    consumo_anual_mwh: payload.consumo_anual_mwh,
                    consumption_weights: values.facilityMonthlyWeight,
                    facilityConsumptionDaily: resultFacilityConsumptionDaily?.data,
                    facilityConsumptionMonthly: resultFacilityConsumptionMonthly?.data,
                })
            );
        } else {
            yield put(requestSaveMonthlyConsumptionFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestMonthlyConsumption ', error);
        yield put(requestSaveMonthlyConsumptionFail());
    }
}

// Upload Load Curve
const _callUploadLoadCurve = async (payload) => {
    return await postUploadLoadCurve(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callUploadLoadCurve ', error);
            return error;
        });
};

function* _handlerRequestUploadLoadCurve({ payload }) {
    try {
        const fetchResult = yield call(_callUploadLoadCurve, payload);

        if (fetchResult?.status === OK) {
            //Get Requests
            let getRequests = [];
            //Get Monthly Graph
            getRequests.push(call(_callGetFacilityConsumptionMonthly, payload));
            //Get Daily
            getConsumptionsAController && getConsumptionsAController.abort();
            getConsumptionsAController = new AbortController();
            getRequests.push(call(_callGetFacilityConsumptionDaily, { ...payload, getConsumptionsAController }));

            let [resultFacilityConsumptionMonthly, resultFacilityConsumptionDaily] = yield all(getRequests);

            if (
                resultFacilityConsumptionMonthly?.message === 'MISSING_CONSUMPTION' ||
                resultFacilityConsumptionDaily?.message === 'MISSING_CONSUMPTION'
            ) {
                yield put(requestUploadLoadCurveFail());
            } else {
                //Wait for responses
                yield put(
                    requestUploadLoadCurveSuccess({
                        annualConsumption: fetchResult.data.annual_consumption_kWh / 1000,
                        facilityConsumptionDaily: resultFacilityConsumptionDaily?.data,
                        facilityConsumptionMonthly: resultFacilityConsumptionMonthly?.data,
                        validCurveUploaded: !(
                            resultFacilityConsumptionMonthly?.message === 'WRONG_CONSUMPTION_LENGHT' ||
                            resultFacilityConsumptionDaily?.message === 'WRONG_CONSUMPTION_LENGHT'
                        ),
                    })
                );
            }

            if (
                resultFacilityConsumptionMonthly?.message === 'WRONG_CONSUMPTION_LENGHT' ||
                resultFacilityConsumptionDaily?.message === 'WRONG_CONSUMPTION_LENGHT'
            ) {
                notify(<IntlMessages id="page.facility.uploadCurve.wrongConsumptionLenght" />, 'error');
            }
        } else {
            yield put(requestUploadLoadCurveFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestUploadLoadCurve ', error);
        yield put(requestUploadLoadCurveFail());
    }
}

// Download Load Curve
const _callDownloadLoadCurve = async (payload) => {
    return await getDownloadLoadCurve(payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callDownloadLoadCurve ', error);
            return error;
        });
};

function* _handlerRequestDownloadLoadCurve({ payload }) {
    try {
        const fetchResult = yield call(_callDownloadLoadCurve, payload);

        if (fetchResult?.status === OK) yield put(requestDownloadLoadCurveSuccess({ fileContent: fetchResult }));
        else yield put(requestDownloadLoadCurveFail());
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestDownloadLoadCurve ', error);
        yield put(requestDownloadLoadCurveFail());
    }
}

// Get facility by ID, setting only the basic data
export function* _handlerRequestGetFacilitySimple({ payload }) {
    try {
        const fetchResult = yield call(_callGetFacility, payload);

        if (fetchResult?.status === OK) {
            yield put(requestGetFacilitySimpleSuccess(fetchResult.data?.[0]));
        } else yield put(requestGetFacilitySimpleFail());
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestGetFacilitySimple ', error);
        yield put(requestGetFacilitySimpleFail());
    }
}

function* _handlerRequestPutWeightsByCustomPeriods({ payload }) {
    try {
        let { weights, consumo_anual, facilityId } = payload;
        //Put Requests
        let putRequests = [];

        //Put weights
        putRequests.push(
            call(_callPutFacilityWeights, {
                facilityID: facilityId,
                body: weights,
            })
        );
        //Put facility
        putRequests.push(call(_callPutFacilityInfo, { id: facilityId, body: { consumo_anual } }));

        //Wait for response
        let [resultPutFacilityWeights, resultPutFacility] = yield all(putRequests);

        //Check status
        if (resultPutFacilityWeights?.status === OK && resultPutFacility?.status === OK) {
            //Get Requests
            let getRequests = [];
            //Get Monthly Graph
            getRequests.push(call(_callGetFacilityConsumptionMonthly, { facilityID: facilityId }));
            //Get Daily
            getConsumptionsAController && getConsumptionsAController.abort();
            getConsumptionsAController = new AbortController();
            getRequests.push(call(_callGetFacilityConsumptionDaily, { facilityID: facilityId, getConsumptionsAController }));
            let [resultFacilityConsumptionMonthly, resultFacilityConsumptionDaily] = yield all(getRequests);

            yield put(
                requestPutWeightsByCustomPeriodsSuccess({
                    consumo_anual,
                    consumo_anual_mwh: convertToUnit(consumo_anual, 'fromKwToMw'),
                    facilityConsumptionDaily: resultFacilityConsumptionDaily?.data,
                    facilityConsumptionMonthly: resultFacilityConsumptionMonthly?.data,
                })
            );
        } else {
            yield put(requestPutWeightsByCustomPeriodsFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerRequestUploadLoadCurve ', error);
        yield put(requestPutWeightsByCustomPeriodsFail());
    }
}

function* _handlerSetGridTariffSources({ payload }) {
    try {
        let sourcesRequests = [];
        sourcesRequests.push(call(_callGetGridTariffs, payload));
        sourcesRequests.push(call(_callGetUsedPowerTariffs, payload));
        let [resultGridTariffs, resultUsedPowerTariffs] = yield all(sourcesRequests);
        if (resultGridTariffs?.status === StatusCodes.OK && resultUsedPowerTariffs?.status === StatusCodes.OK) {
            yield put(
                requestSetGridTariffsSourcesSuccess({
                    gridTariffs: resultGridTariffs?.data ?? [],
                    usedPowerTariffs: resultUsedPowerTariffs?.data ?? [],
                })
            );
        }
    } catch (error) {
        console.log('_handlerSetGridTariffSources ERROR', error);
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(REQUEST_GET_FACILITY, _handlerRequestGetFacility),
        takeLatest(REQUEST_GET_CREATE_FACILITY, _handlerRequestGetCreateFacility),
        takeLatest(REQUEST_GET_FACILITY_STATS, _handlerGetFacilityStats),
        takeLatest(REQUEST_SAVE_CHANGE_FACILITY, _handlerRequestSaveChangeFacility),
        takeLatest(REQUEST_SAVE_LOAD_CURVE_FACILITY, _handlerRequestSaveLoadCurveInputs),
        takeLatest(REQUEST_GET_FACILITY_TYPES, _handlerRequestGetFacilityTypes),
        takeLatest(REQUEST_DISABLE_FACILITY, _handlerRequestDisableFacility),
        takeLatest(REQUEST_WEIGHTS_BY_CAE, _handlerRequestWeightsByCAE),
        takeLatest(REQUEST_ANNUAL_CONSUMPTION, _handlerRequestAnnualConsumption),
        takeLatest(REQUEST_MONTHLY_CONSUMPTION, _handlerRequestMonthlyConsumption),
        takeLatest(REQUEST_UPLOAD_LOAD_CURVE, _handlerRequestUploadLoadCurve),
        takeLatest(REQUEST_DOWNLOAD_LOAD_CURVE, _handlerRequestDownloadLoadCurve),
        takeLatest(REQUEST_DISTRIBUTORS_BY_NUT, _handlerGetDistributorsByNut),
        takeLatest(REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS, _handlerRequestPutWeightsByCustomPeriods),
        takeLatest(REQUEST_SET_GRID_TARIFF_SOURCES, _handlerSetGridTariffSources),
        takeLatest(REQUEST_GET_FACILITY_SIMPLE, _handlerRequestGetFacilitySimple),
    ]);
}
