/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description redux-sagas - Proposal
 *
 * @version 20191203
 * @version 20191202
 * @version 20191113
 * @since 20191113 Initial release
 *
 */
import { all, call, put, takeLatest, delay, select } from 'redux-saga/effects';
import {
    requestGetProposalIDSuccess,
    requestGetProposalInformationSuccess,
    requestHeadSupportDocsSuccess,
    requestGetSupportDocsSuccess,
    requestHeadSupportDocsIsNotAvailable,
    setNoAccessToProposal,
} from 'redux/actions/proposal';
import {
    REQUEST_GET_PROPOSAL_ID,
    REQUEST_GET_PROPOSAL_INFORMATION,
    REQUEST_GET_SUPPORT_DOCS,
    REQUEST_HEAD_SUPPORT_DOCS_IS_NOT_AVAILABLE,
    NO_ACCESS_TO_PROPOSAL_DELAY,
} from 'constants/proposal';
import { postProposalID, getProposalInformation, headSupportDocs, getSupportDocs } from 'api/proposal';

import { OK } from 'constants/statusCodeHttp';
import { isDefined } from 'services/util/auxiliaryUtils';

const _callRequestGetProposalID = async (payload) => {
    return await postProposalID(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            return error;
        });
};

// Selector. The query depends by the state shape
const getStateProposalID = (state) => state.proposal?.id;
const getStateHasAccessToProposals = (state) => state.user?.acede_a_propostas;

function* _handlerRequestGetProposalID({ payload }) {
    try {
        const proposalID = yield select(getStateProposalID);
        const hasAccessToProposals = yield select(getStateHasAccessToProposals);
        if (isDefined(proposalID)) {
            if (hasAccessToProposals) yield _handlerRequestHeadSupportDocs();
            else yield _handlerNoAccessToProposals();
        } else {
            const fetchResult = yield call(_callRequestGetProposalID, payload);

            if (fetchResult?.status === OK) {
                yield put(requestGetProposalIDSuccess({ data: fetchResult.data }));
                if (hasAccessToProposals) yield _handlerRequestHeadSupportDocs();
                else yield _handlerNoAccessToProposals();
            }
        }
        // if(![OK, UNAUTHORIZED].includes(response.payload?.status)) {yield put(requestGetProposalIDFail());}
    } catch (error) {
        console.log('_handlerRequestGetProposalID ERROR', error);
    }
}

function* _handlerNoAccessToProposals() {
    yield delay(NO_ACCESS_TO_PROPOSAL_DELAY);
    yield put(setNoAccessToProposal());
}

const _callRequestGetProposalInformation = async (payload) => {
    return await getProposalInformation(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            return error;
        });
};

function* _handlerRequestGetProposalInformation({ payload }) {
    try {
        const fetchResult = yield call(_callRequestGetProposalInformation, payload);

        if (fetchResult?.status === OK) yield put(requestGetProposalInformationSuccess({ data: fetchResult.data }));
        // if(![OK, UNAUTHORIZED].includes(response.payload?.status)) {yield put(requestGetProposalInformationFail());}
    } catch (error) {
        console.log('_handlerRequestGetProposalInformation ERROR', error);
    }
}

// Support-Docs
const _callRequestHeadSupportDocs = async (payload) => {
    return await headSupportDocs(payload)
        .then((response) => response)
        .catch((error) => error);
};

function* _handlerRequestHeadSupportDocs() {
    try {
        const proposalID = yield select(getStateProposalID);

        if (isDefined(proposalID)) {
            const fetchResult = yield call(_callRequestHeadSupportDocs, proposalID);

            yield delay(2000);
            if (fetchResult?.status === OK) {
                yield put(requestHeadSupportDocsSuccess({ data: fetchResult.data }));
            } else {
                yield put(requestHeadSupportDocsIsNotAvailable());
            }
        }
    } catch (error) {
        console.log('_handlerRequestHeadSupportDocs ERROR', error);
    }
}

const _callRequestGetSupportDocs = async (payload) => {
    return await getSupportDocs(payload)
        .then((response) => (response.state !== OK ? response : response.data))
        .catch((error) => error);
};

function* _handlerRequestGetSupportDocs({ payload }) {
    try {
        const fetchResult = yield call(_callRequestGetSupportDocs, payload);

        if (fetchResult?.status === OK)
            yield put(
                requestGetSupportDocsSuccess({
                    data: fetchResult.data,
                    file_name: payload.file_name,
                })
            );
    } catch (error) {
        console.log('_handlerRequestGetSupportDocs ERROR', error);
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(REQUEST_GET_PROPOSAL_ID, _handlerRequestGetProposalID),
        takeLatest(REQUEST_GET_PROPOSAL_INFORMATION, _handlerRequestGetProposalInformation),
        takeLatest(REQUEST_GET_SUPPORT_DOCS, _handlerRequestGetSupportDocs),
        takeLatest(REQUEST_HEAD_SUPPORT_DOCS_IS_NOT_AVAILABLE, _handlerRequestHeadSupportDocs),
    ]);
}
