import { createSelector } from 'reselect';
// import { PRODUCT_IDS } from 'constants/products';

export const getProduct = createSelector(
    (state) => state,
    (state) => state
);
export const getProductId = createSelector(
    (state) => state?.info?.id,
    (id) => id ?? null
);
export const getProductTagName = createSelector(
    (state) => state?.info.tp_tag,
    (tp_tag) => tp_tag
);
export const getProductModules = createSelector(
    (state) => state?.modules,
    (modules) => modules
);
export const getProductSubProducts = createSelector(
    (state) => state?.subProducts,
    (subProducts) => subProducts
);
export const getProductInfo = createSelector(
    (state) => state?.info,
    (info) => info
);
export const getProductProposalDetails = createSelector(
    (state) => state?.proposal,
    (proposal) => proposal
);
export const getProductModule = createSelector(
    (state) => state?.info.module,
    (module) => module
);

export const getIsRequesting = createSelector(
    (state) => state?.isRequesting,
    (isRequesting) => isRequesting
);
export const getProductInputs_BE = createSelector(
    (state) => state?.productInputs,
    (productInputs) => productInputs
);
export const getProductInputs = createSelector(
    (state) => state?.productInputs,
    (productInputs) => productInputs
);
// SPV @deprecated PV1.0
// export const getProductOptSPVIsInvalidRegionGD = createSelector(state => state?.options?.spv?.isInvalidRegionGD, isInvalidRegionGD => isInvalidRegionGD);
// export const getProductOptSPVIsRequestingProduction = createSelector(state => state?.options?.spv?.isRequestingSPVProduction, isRequestingSPVProduction => isRequestingSPVProduction);
// export const getProductOptSPVIsRequestingConsumption = createSelector(state => state?.options?.spv?.isRequestingSPVConsumption, isRequestingSPVConsumption => isRequestingSPVConsumption);
// export const getProductOptSPVIsRequestingSensibility = createSelector(state => state?.options?.spv?.isRequestingSPVSensibility, isRequestingSPVSensibility => isRequestingSPVSensibility);
// export const getProductOptSPVStepMount = createSelector(state => state?.options.spv.stepMount, stepMount => stepMount);
// export const getProductOptSPVLoadingSPVData = createSelector(state => state?.options.spv.loadingSPVData, loadingSPVData => loadingSPVData);
// export const getProductOptSPVSolarSensibility = createSelector(state => state?.options.spv.solarSensibility, solarSensibility => solarSensibility);
// export const getProductOptSPVConsumptions = createSelector(state => state?.options.spv.consumptions, consumptions => consumptions);
// export const getProductOptSPVAreaReductionCoefficients = createSelector(state => state?.options.spv.pvAreaReductionCoefficients, pvAreaReductionCoefficients => pvAreaReductionCoefficients);
// export const getProductOptSPVSensibilityAnalysis = createSelector(state => state?.options.spv.solarSensibility, solarSensibility => solarSensibility);
// export const getProductOptSPVRegionsGD = createSelector(state => state?.options.spv.regionsGD, regionsGD => regionsGD);
// export const getProductOptSPVHasProductInputs = createSelector(state => state?.options.spv.hasProductInputs, hasProductInputs => hasProductInputs);
// export const getProductOptSPVProductions = createSelector(state => state?.options?.productions, productions => productions);
// export const getProductOptSPVRegionRadiations = createSelector(state => state?.options.spv.regionRadiations, regionRadiations => regionRadiations);
// export const getProductOptSPVSimulation = createSelector(state => state?.options.spv.simulation, simulation => simulation);
// export const getIsSmallBusiness = createSelector(state => state?.info.id, id => id === PRODUCT_IDS.SPVSB);
// export const getResponseTagsSPV = createSelector(state => state?.options.spv.responseTags, responseTags => responseTags?.slice());
// export const getProductOptsSPVMountingStructures = createSelector(state => state?.options?.spv?.pvMountingStructures, pvMountingStructures => pvMountingStructures);
// export const getProductOptsSPVOptions = createSelector(state => state?.options?.spv?.pvOptions, pvOptions => pvOptions);
// PV2.0
export const getProductOptSPVEquipmentsByRanges = createSelector(
    (state) => state?.options?.spv?.ranges,
    (ranges) => ranges
);
export const getPreviousPeakPower = createSelector(
    (state) => state?.options.spv.previousPeakPower,
    (previousPeakPower) => previousPeakPower
);
export const getIsIncreasePower = createSelector(
    (state) => state?.options.spv.isPowerIncrease,
    (isPowerIncrease) => isPowerIncrease
);
export const getPreviousInverterPowerKw = createSelector(
    (state) => state?.options.spv.previousInverterPowerKw,
    (previousInverterPowerKw) => previousInverterPowerKw
);
export const getIsSPVPro = createSelector(
    (state) => state?.isSPVPro,
    (isSPVPro) => isSPVPro
);

//GNS
export const getProductOptGNSIsMounting = createSelector(
    (state) => state?.options.gns.isMounting,
    (isMounting) => isMounting
);

// CFP
export const getProductOptCFPBase = createSelector(
    (state) => state?.options.cfp.base,
    (base) => base
);
export const getProductOptCFPBatteryPowers = createSelector(
    (state) => state?.options.cfp.batteryPowers,
    (batteryPowers) => batteryPowers
);
export const getHasProductInputs = createSelector(
    (state) => state?.hasProductInputs,
    (hasProductInputs) => hasProductInputs
);

// CE
export const getProductOptCEFacilityStats = createSelector(
    (state) => state?.options.ce.facilityStats,
    (facilityStats) => facilityStats
);
export const getProductOptCEFacilityTypes = createSelector(
    (state) => state?.options.ce.facilityTypes,
    (facilityTypes) => facilityTypes
);
export const getProductOptCEFacilityGroups = createSelector(
    (state) => state?.options.ce.facilityGroups,
    (facilityGroups) => facilityGroups
);

//Utils
export const getWorkSchedules = createSelector(
    (state) => state?.options?.utils?.workSchedules,
    (workSchedules) => workSchedules
);
// EVC
export const getProductInputsME = createSelector(
    (state) => state?.productInputs?.SIMPLES_Dimensionamento,
    (SIMPLES_Dimensionamento) => SIMPLES_Dimensionamento?.inputs
);
export const getIsRequestingME = createSelector(
    (state) => state?.options?.me?.isRequesting,
    (isRequesting) => isRequesting
);
export const getMETotals = createSelector(
    (state) => state?.options?.me?.totals,
    (totals) => totals
);
export const getisPowerLimitME = createSelector(
    (state) => state?.options?.me?.isPowerLimit,
    (isPowerLimit) => isPowerLimit
);
export const getMEChargersOptions = createSelector(
    (state) => state?.options.me.chargers,
    (chargers) => chargers
);
export const getMEChargersMetadata = createSelector(
    (state) => state?.options.me.results,
    (metadata) => metadata
);

// SPVPro
export const getProposalDetails = createSelector(
    (state) => state?.proposal,
    (proposal) => proposal
);
export const getSPVIsIncreasePower = createSelector(
    (state) => state?.options.spv.isPowerIncrease,
    (isPowerIncrease) => isPowerIncrease
);
