/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description API requests to the context of Client
 *
 * @version 20191117
 * @since 20191117 Initial release
 *
 */

// import axios from 'axios';
import { URL_CLIENT, getEndpoint } from 'constants/endpoints';

import { isDefined } from 'services/util/auxiliaryUtils';
import { SentryCaptureException } from 'services/@efz/sentry';
import { getMessageNetworkError } from 'services/statusCodeHttp';
import axios from 'services/@efz/axios';

const CancelToken = axios.CancelToken;
// Cancel executor variables
let postClientCancelExecutor;
let getAllLegRepCancelExecutor;
let postAddLegRepCancelExecutor;
let putEditLegRepCancelExecutor;
let deleteLegRepCancelExecutor;

/**
 * capturar os clientID = null
 * @param {*} fnc
 */
function SentryCapture(fnc) {
    SentryCaptureException({
        level: 3,
        message: 'clientID null',
        tags: {
            api: 'clients',
            fnc,
        },
    });
}

/**
 *
 * getSearchClients
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getSearchClients = (params, abort) => {
    return axios
        .request({
            url: getEndpoint(URL_CLIENT),
            signal: abort?.signal,
            params: params,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * putClientInfo
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const putClientInfo = (payload, abortController) => {
    if (abortController) {
        abortController && abortController.abort();
        abortController = new AbortController();
    }

    //#region sentry warn
    if (!isDefined(payload.id)) {
        SentryCapture('putClientInfo');
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_CLIENT)}/${payload.id}`,
            method: 'PUT',
            signal: abortController?.signal,
            data: payload.body,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * getClient
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getClient = (payload = '', abort = undefined) => {
    let clientID = isDefined(payload) ? '/' + payload : '';
    return axios
        .request({
            url: `${getEndpoint(URL_CLIENT) + clientID}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * postClient
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postClient = (payload) => {
    postClientCancelExecutor && postClientCancelExecutor();

    return axios
        .post(getEndpoint(URL_CLIENT), payload, {
            cancelToken: new CancelToken(function executor(c) {
                postClientCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * getClientLegalRepresentatives
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getClientLegalRepresentatives = (clientID) => {
    getAllLegRepCancelExecutor && getAllLegRepCancelExecutor();

    return axios
        .get(getEndpoint(URL_CLIENT) + '/' + clientID + '/client-contacts', {
            cancelToken: new CancelToken(function executor(c) {
                getAllLegRepCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * postAddClientLegalRepresentative
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postAddClientLegalRepresentative = (clientID, payload) => {
    postAddLegRepCancelExecutor && postAddLegRepCancelExecutor();

    return axios
        .post(getEndpoint(URL_CLIENT) + '/' + clientID + '/client-contacts', payload, {
            cancelToken: new CancelToken(function executor(c) {
                postAddLegRepCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * putEditClientLegalRepresentative
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const putEditClientLegalRepresentative = (clientID, lrepID, payload) => {
    putEditLegRepCancelExecutor && putEditLegRepCancelExecutor();

    return axios
        .put(getEndpoint(URL_CLIENT) + '/' + clientID + '/client-contacts/' + lrepID, payload, {
            cancelToken: new CancelToken(function executor(c) {
                putEditLegRepCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * putEditClientLegalRepresentative
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const deleteClientLegalRepresentative = (clientID, lrepID) => {
    deleteLegRepCancelExecutor && deleteLegRepCancelExecutor();

    return axios
        .delete(getEndpoint(URL_CLIENT) + '/' + clientID + '/client-contacts/' + lrepID, {
            cancelToken: new CancelToken(function executor(c) {
                deleteLegRepCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
