import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPPa } from 'interfaces/businessModels/ppa';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';
import ProjectSummaryServicePPaDetailed from './detailed';
import ProjectSummaryServicePPaSimple from './simple';

const ProjectSummaryServicePPa = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;

    if (isDefined(bmSelected.values?.syncKpis)) return <ProjectSummaryServicePPaDetailed />;
    return <ProjectSummaryServicePPaSimple />;
};

export default ProjectSummaryServicePPa;
