import ServiceV2MarginKpi from 'components/BusinessModels/ProjectSummary/ProjectSummaryServiceV2/SavingsShare/MarginKpi';
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TServiceV2 } from 'interfaces/businessModels/serviceV2';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';

const ServiceV2MarginKpis = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TServiceV2>;

    const selectedMarginValues = bmSelected?.values?.kpis_per_margin.find((kpi) => kpi.saving_share === bmSelected?.values?.marginSelected);

    const annualPrice = selectedMarginValues?.annual_price ?? undefined;
    const annualSaving = selectedMarginValues?.annual_saving ?? undefined;
    const annualNetSaving = selectedMarginValues?.annual_net_saving ?? undefined;

    const isEDP_ESService = bmSelected?.paymentModelID === PAYMENT_MODELS_IDS.SERVICE;

    const additionalValue = selectedMarginValues?.additional_value ?? undefined;
    const prcOfMonthlyFeeIncrease = selectedMarginValues?.prc_of_monthly_fee_increase ?? undefined;
    const monthlyFee = selectedMarginValues?.monthly_fee ?? undefined;
    const monthlyNetSaving = selectedMarginValues?.monthly_net_saving ?? undefined;

    return (
        <div className="bm-kpis-margin">
            {isDefined(additionalValue) && isDefined(prcOfMonthlyFeeIncrease) && isEDP_ESService && (
                <ServiceV2MarginKpi
                    label="label.additionalAmount"
                    values={[
                        { unit: 'currency', number: additionalValue, numberOfDecimalPlaces: 0 },
                        { unit: 'percent', number: prcOfMonthlyFeeIncrease, numberOfDecimalPlaces: 0 },
                    ]}
                />
            )}
            {isDefined(monthlyFee) && isEDP_ESService && (
                <ServiceV2MarginKpi label="label.finalMonthlyFee" values={[{ unit: 'mc', number: monthlyFee, numberOfDecimalPlaces: 0 }]} />
            )}
            {isDefined(monthlyNetSaving) && isEDP_ESService && (
                <ServiceV2MarginKpi
                    label="label.monthlyNetSaving"
                    values={[{ unit: 'mc', number: monthlyNetSaving, numberOfDecimalPlaces: 0 }]}
                />
            )}
            {isDefined(annualPrice) && !isEDP_ESService && (
                <ServiceV2MarginKpi label="label.annualPrice" values={[{ unit: 'cmwp', number: annualPrice, numberOfDecimalPlaces: 0 }]} />
            )}
            {isDefined(annualSaving) && !isEDP_ESService && (
                <ServiceV2MarginKpi
                    label="label.annualSaving"
                    values={[{ unit: 'currency', number: annualSaving, numberOfDecimalPlaces: 0 }]}
                />
            )}
            {isDefined(annualNetSaving) && (
                <ServiceV2MarginKpi
                    label="label.annualNetSavings"
                    values={[{ unit: 'currency', number: annualNetSaving, numberOfDecimalPlaces: 0 }]}
                />
            )}
        </div>
    );
};

export default ServiceV2MarginKpis;
