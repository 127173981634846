import { DEFAULTS_PROPS_MAP, solarpvMapActions } from 'constants/products/solarpvSimple';
import { createLineHandler, createPolygonHandler, resetPolygon, resetVertexesAndLines } from 'services/products/solarpvSimple';
import { isDefined } from 'services/util/auxiliaryUtils';

export const initialSolarpvMapReducer = {
    mainMap: Map | undefined,
    mainDrawingManager: null,
    mainGoogle: null,
    polygon: null,
    isDrawing: false,
    vertexes: [],
    lines: [],
};

export const solarpvMapReducer = (state = initialSolarpvMapReducer, action) => {
    switch (action.type) {
        case solarpvMapActions.SET_INIT: {
            let {
                mainDrawingManager,
                mainGoogle,
                mainMap,
                polygon,
                // isDetectRoof,
            } = action.payload;

            if (polygon.getPath().getArray()?.length > 0) {
                polygon.setOptions({
                    id: 1,
                    area: mainGoogle.maps.geometry.spherical.computeArea(polygon.getPath()),
                    editable: true,
                    draggable: true,
                    zIndex: 1,
                    strokeOpacity: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeOpacity,
                    strokeWeight: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeWeight,
                    fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
                    fillColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.fillColor.selected,
                    strokeColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeColor.selected,
                });
            }

            return {
                ...state,
                mainGoogle,
                mainDrawingManager,
                mainMap,
                polygon,
            };
        }
        case solarpvMapActions.SET_IS_DRAWING: {
            let isDrawing = !state.isDrawing;

            // fallback when map is not ready
            if (typeof state.mainMap.setOptions !== 'function') return state;

            state.mainMap.setOptions({ draggableCursor: isDrawing ? 'crosshair' : 'default' });

            return {
                ...state,
                isDrawing,
                ...resetVertexesAndLines(state.vertexes, state.lines),
            };
        }
        case solarpvMapActions.EVT_INSERT_AT:
        case solarpvMapActions.EVT_REMOVE_AT:
        case solarpvMapActions.EVT_SET_AT: {
            let { mainGoogle } = state;
            let { polygon } = action.payload;

            polygon.setOptions({
                area: mainGoogle.maps.geometry.spherical.computeArea(polygon.getPath()),
            });

            return {
                ...state,
                polygon,
            };
        }

        case solarpvMapActions.SET_DRAW_AGAIN: {
            let { polygon, vertexes, lines } = state;

            // fallback when map is not ready
            if (typeof state.mainMap.setOptions !== 'function') return state;

            state.mainMap.setOptions({ draggableCursor: 'crosshair' });

            return {
                ...state,
                isDrawing: true,
                ...resetPolygon(polygon),
                ...resetVertexesAndLines(vertexes, lines),
            };
        }
        case solarpvMapActions.EVT_CLICK_MAP: {
            const {
                mapOptions: { map, google, e, mapDispatch, mainDrag, handleOnCreatePolygon },
                makerHandler,
            } = action.payload;

            if (state.isDrawing) {
                const vertex = new google.maps.Circle({
                    map,
                    strokeColor: 'rgba(0, 163, 224, 1)',
                    fillColor: '#fff',
                    fillOpacity: 1,
                    radius: 1,
                    strokeWeight: 1,
                    zIndex: 1001,
                    center: e.latLng,
                });

                vertex?.addListener('dblclick', () => {
                    mapDispatch(solarpvMapActions.SET_POLYGON_V2, {
                        mapDispatch: mapDispatch,
                        handleOnCreatePolygon,
                    });
                });

                if (state.vertexes.length === 0) {
                    google.maps.event.addListener(vertex, 'click', () => {
                        mapDispatch(solarpvMapActions.SET_POLYGON_V2, {
                            mainDrag,
                            mapDispatch,
                            handleOnCreatePolygon,
                        });
                    });
                }

                return {
                    ...state,
                    ...createLineHandler(vertex, state, {
                        map: state.mainMap,
                        google: state.mainGoogle,
                        mapDispatch,
                    }),
                };
            } else {
                makerHandler();
                return state;
            }
        }
        case solarpvMapActions.SET_POLYGON_V2: {
            const { mainMap, mainGoogle } = state;
            const { mapDispatch, handleOnCreatePolygon } = action.payload;
            const polygon = createPolygonHandler(state, {
                map: mainMap,
                google: mainGoogle,
                mapDispatch: mapDispatch,
                handleOnCreatePolygon,
            });

            // fallback when map is not ready
            if (typeof state.mainMap.setOptions !== 'function') return state;

            if (isDefined(polygon)) {
                state.mainMap.setOptions({ draggableCursor: 'default' });
                return {
                    ...state,
                    polygon: polygon,
                    isDrawing: false,
                    ...resetVertexesAndLines(state.vertexes, state.lines),
                };
            }
            return state;
        }
        default:
            return state;
    }
};
