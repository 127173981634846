/* import {
    GA_EVT_CLICK_CFP,
    GA_EVT_CLICK_FACILITY,
    GA_EVT_CLICK_HOMEPAGE,
    GA_EVT_CLICK_ME,
    GA_EVT_CLICK_OPORTUNITIES,
    GA_EVT_CLICK_PROCESS_LIST,
    GA_EVT_CLICK_SOLAR_PV,
    GA_EVT_CLICK_TOP_LEVEL_NAVIGATION,
    GA_TYPE_EVT
} from "constants/gAnalytics"; */

export function gAnalyticsEvt() {
    /* if (window.gtag instanceof Function) {
        switch (type) {

            case GA_TYPE_EVT.TOP_LEVEL_NAVIGATION:
                window.gtag('event', evt, GA_EVT_CLICK_TOP_LEVEL_NAVIGATION[label]);
                break;

            case GA_TYPE_EVT.HOMEPAGE:
                window.gtag('event', evt, GA_EVT_CLICK_HOMEPAGE[label]);
                break;

            case GA_TYPE_EVT.FACILITY:
                window.gtag('event', evt, GA_EVT_CLICK_FACILITY[label]);
                break;

            case GA_TYPE_EVT.PROCESS_LIST:
                window.gtag('event', evt, GA_EVT_CLICK_PROCESS_LIST[label]);
                break;

            case GA_TYPE_EVT.OPORTUNITIES:
                window.gtag('event', evt, GA_EVT_CLICK_OPORTUNITIES[label]);
                break;

            case GA_TYPE_EVT.SOLAR_PV:
                window.gtag('event', evt, GA_EVT_CLICK_SOLAR_PV[label]);
                break;

            case GA_TYPE_EVT.EVC:
                window.gtag('event', evt, GA_EVT_CLICK_ME[label]);
                break;

            case GA_TYPE_EVT.CFP:
                window.gtag('event', evt, GA_EVT_CLICK_CFP[label]);
                break;

            default:
                break;
        }
    } */
}
