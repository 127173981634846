import { getLogout } from 'api/auth';
import { useEffect } from 'react';
import { useAuthStore } from 'store/auth';
import { useUserStore } from 'store/user';

const useLogout = () => {
    const { user } = useUserStore();
    const { logout, token } = useAuthStore();

    const normalLogout = async () => {
        getLogout(token);
        logout({});
    };

    const adfsLogoutHandler = async () => {
        if (!user || !token) return;
        logout({
            fromADFS: {
                token: token,
                nameID: user.nameID!,
                sessionIndex: user.sessionIndex!,
                empresa_id: user.empresa_id,
            },
        });
    };
    useEffect(() => {
        if (user?.is_logged_by_sso) {
            adfsLogoutHandler();
        } else {
            normalLogout();
        }
    }, []); // eslint-disable-line

    return {};
};

export default useLogout;
