import { useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { getTheme } from 'api/theme';
import { isOpenArea } from 'services/settings';
import { getCompanyProfileIds } from 'services/user';
import { isSolarpvPPCOAreaB2C } from 'services/util/auxiliaryUtils';
import { COMPANY_IDS } from 'constants/settings';
import { useUserStore } from 'store/user';

let getThemeAbortController;

const useSettings = (isB2C = false) => {
    const { setUser, locale, user } = useUserStore();
    const [isRequesting, setIsRequesting] = useState(true);

    const getThemeHandler = async () => {
        getThemeAbortController && getThemeAbortController.abort();
        getThemeAbortController = new AbortController();
        const rspTheme = await getTheme(getThemeAbortController, true);
        if (rspTheme?.status === StatusCodes.OK) return rspTheme?.data?.data;
        return null;
    };

    const fetchThemeOnHandler = async () => {
        setIsRequesting(true);

        if (isOpenArea()) {
            localStorage.removeItem('userToken');
            localStorage.setItem('salesSegment', isSolarpvPPCOAreaB2C() || isB2C ? 'B2C' : 'B2B');
            const newTheme = await getThemeHandler();
            setUser({
                theme: newTheme,
                user: { ...user, perfil_empresa_id: getCompanyProfileIds().PPC, company_code: 'ppc', empresa_id: COMPANY_IDS.PPC },
                locale: locale,
            });
        }
        setIsRequesting(false);
    };

    return {
        fetchThemeOnHandler,
        isRequesting,
    };
};

export default useSettings;
