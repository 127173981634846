/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Selector - BusinessModels
 *
 * @version 20191120
 * @since 20191113 Initial release
 *
 */

import { createSelector } from 'reselect';
import { isFieldDefined } from 'services/util/auxiliaryUtils';

/* Recebem o estado global do reducer como argumento e retornam um novo objecto baseado em transformações nesse estado. */
export const getBusinessModels = createSelector(
    (state) => state,
    (state) => state
);
export const getBusinessModelsData = createSelector(
    (state) => state?.data,
    (data) => data
);
export const getBusinessModelsBody = createSelector(
    (state) => state?.body,
    (body) => body
);
export const getBusinessModelsOptionsME = createSelector(
    (state) => state?.options.me,
    (me) => me
);
export const getBusinessModelsBodyInputs = createSelector(
    (state) => state?.body.inputs,
    (inputs) => inputs
);
export const getBusinessModelsOfferEdition = createSelector(
    (state) => state?.offerEdition,
    (offerEdition) => offerEdition
);
export const getBusinessModelsLoading = createSelector(
    (state) => state?.loading,
    (loading) => loading
);
export const getBusinessModelsBodyFacilityID = createSelector(
    (state) => state?.body.facility_id,
    (facility_id) => facility_id
);
export const getBusinessModelsServicePPA = createSelector(
    (state) => state?.servicePPA,
    (servicePPA) => servicePPA
);
export const getBusinessRangeDescription = createSelector(
    (state) => state?.body?.rangeDescription,
    (rangeDescription) => rangeDescription
);
export const getBusinessModelsBodyRangeID = createSelector(
    (state) => state?.body?.range_id,
    (range_id) => range_id
);
export const getBusinessModelsStepsCompletedBatteryAddin = createSelector(
    (state) => state?.stepsCompletedBatteryAddin,
    (stepsCompletedBatteryAddin) => stepsCompletedBatteryAddin
);
export const getBusinessModelsNegotiations = createSelector(
    (state) => state?.negotiations,
    (negotiations) => negotiations ?? null
);
export const isReformulation = createSelector(
    (state) => state?.body?.reformulation,
    (reformulation) => isFieldDefined(reformulation)
);
