/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Actions - Facility
 *
 * @version 20200120 - Adicionadas funções: setFacilityLoading, setFacilityBuild e setFacilityInputs
 * @version 20191114
 * @since 20191114 Initial release
 *
 */

import {
    SET_FACILITY,
    REQUEST_GET_FACILITY_STATS,
    REQUEST_GET_FACILITY_STATS_SUCCESS,
    REQUEST_GET_FACILITY,
    REQUEST_GET_FACILITY_SUCCESS,
    REQUEST_GET_FACILITY_FAIL,
    REQUEST_GET_CREATE_FACILITY,
    REQUEST_GET_CREATE_FACILITY_SUCCESS,
    REQUEST_GET_CREATE_FACILITY_FAIL,
    RESET_FACILITY,
    SET_IS_OPEN_FACILITY_CARD,
    REQUEST_SAVE_CHANGE_FACILITY,
    REQUEST_SAVE_CHANGE_FACILITY_SUCCESS,
    REQUEST_SAVE_CHANGE_FACILITY_FAIL,
    RESET_FACILITY_OPTIONS,
    REQUEST_GET_FACILITY_TYPES,
    REQUEST_GET_FACILITY_TYPES_SUCCESS,
    REQUEST_GET_FACILITY_TYPES_FAIL,
    REQUEST_SAVE_LOAD_CURVE_FACILITY,
    REQUEST_SAVE_LOAD_CURVE_FACILITY_SUCCESS,
    REQUEST_SAVE_LOAD_CURVE_FACILITY_FAIL,
    REQUEST_DISABLE_FACILITY,
    REQUEST_DISABLE_FACILITY_SUCCESS,
    REQUEST_DISABLE_FACILITY_FAIL,
    REQUEST_WEIGHTS_BY_CAE,
    REQUEST_WEIGHTS_BY_CAE_SUCCESS,
    REQUEST_WEIGHTS_BY_CAE_FAIL,
    REQUEST_WEIGHTS_BY_CAE_NO_INFORMATION,
    REQUEST_ANNUAL_CONSUMPTION,
    REQUEST_ANNUAL_CONSUMPTION_SUCCESS,
    REQUEST_ANNUAL_CONSUMPTION_FAIL,
    REQUEST_MONTHLY_CONSUMPTION,
    REQUEST_MONTHLY_CONSUMPTION_SUCCESS,
    REQUEST_MONTHLY_CONSUMPTION_FAIL,
    REQUEST_WEIGHTS_BY_CAE_WITH_PONDERADOR,
    REQUEST_UPLOAD_LOAD_CURVE,
    REQUEST_UPLOAD_LOAD_CURVE_SUCCESS,
    REQUEST_UPLOAD_LOAD_CURVE_FAIL,
    REQUEST_DOWNLOAD_LOAD_CURVE,
    REQUEST_DOWNLOAD_LOAD_CURVE_SUCCESS,
    REQUEST_DOWNLOAD_LOAD_CURVE_FAIL,
    SET_IS_DIRTY_FORM_FACILITY,
    REQUEST_GET_FACILITY_SIMPLE,
    REQUEST_GET_FACILITY_SIMPLE_SUCCESS,
    REQUEST_GET_FACILITY_SIMPLE_FAIL,
    SET_FACILITY_BY_TOKEN,
    REQUEST_DISTRIBUTORS_BY_NUT,
    REQUEST_DISTRIBUTORS_BY_NUT_SUCCESS,
    REQUEST_DISTRIBUTORS_BY_NUT_FAIL,
    REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS,
    REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_SUCCESS,
    REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_FAIL,
    SET_FACILITY_OPCAO_TARIFARIO_ID,
    REQUEST_SET_GRID_TARIFF_SOURCES,
    REQUEST_SET_GRID_TARIFF_SOURCES_SUCCESS,
    REQUEST_SET_GRID_TARIFF_SOURCES_FAIL,
    SET_CHANGE_ADDRESS_FACILITY,
} from 'constants/facility';

export function setChangeAddressFacility(payload) {
    return {
        type: SET_CHANGE_ADDRESS_FACILITY,
        payload,
    };
}

export function setFacilityByToken(payload) {
    return {
        type: SET_FACILITY_BY_TOKEN,
        payload,
    };
}

export function requestGetFacilityTypes(payload) {
    return {
        type: REQUEST_GET_FACILITY_TYPES,
        payload: payload,
    };
}

export function requestGetFacilityTypesSuccess(payload) {
    return {
        type: REQUEST_GET_FACILITY_TYPES_SUCCESS,
        payload: payload,
    };
}

export function requestGetFacilityTypesFail() {
    return {
        type: REQUEST_GET_FACILITY_TYPES_FAIL,
    };
}

export function resetFacilityOptions() {
    return {
        type: RESET_FACILITY_OPTIONS,
    };
}

export function requestSaveChangeFacility(payload) {
    return {
        type: REQUEST_SAVE_CHANGE_FACILITY,
        payload,
    };
}

export function requestSaveChangeFacilitySuccess(payload) {
    return {
        type: REQUEST_SAVE_CHANGE_FACILITY_SUCCESS,
        payload,
    };
}

export function requestSaveChangeFacilityFail() {
    return {
        type: REQUEST_SAVE_CHANGE_FACILITY_FAIL,
    };
}

export function setIsOpenFacilityCard(payload) {
    return {
        type: SET_IS_OPEN_FACILITY_CARD,
        payload,
    };
}

export function setFacility(payload) {
    return {
        type: SET_FACILITY,
        payload,
    };
}

export function requestGetFacilityStats(payload) {
    return {
        type: REQUEST_GET_FACILITY_STATS,
        payload: payload,
    };
}

export function requestGetFacilityStatsSuccess(payload) {
    return {
        type: REQUEST_GET_FACILITY_STATS_SUCCESS,
        payload: payload,
    };
}

export function requestGetFacility(payload) {
    return {
        type: REQUEST_GET_FACILITY,
        payload: payload,
    };
}

export function requestGetFacilitySuccess(payload) {
    return {
        type: REQUEST_GET_FACILITY_SUCCESS,
        payload: payload,
    };
}

export function requestGetFacilityFail() {
    return {
        type: REQUEST_GET_FACILITY_FAIL,
    };
}

export function requestGetFacilitySimple(payload) {
    return {
        type: REQUEST_GET_FACILITY_SIMPLE,
        payload: payload,
    };
}

export function requestGetFacilitySimpleSuccess(payload) {
    return {
        type: REQUEST_GET_FACILITY_SIMPLE_SUCCESS,
        payload: payload,
    };
}

export function requestGetFacilitySimpleFail() {
    return {
        type: REQUEST_GET_FACILITY_SIMPLE_FAIL,
    };
}

export function requestGetCreateFacility(payload) {
    return {
        type: REQUEST_GET_CREATE_FACILITY,
        payload: payload,
    };
}

export function requestGetCreateFacilitySuccess(payload) {
    return {
        type: REQUEST_GET_CREATE_FACILITY_SUCCESS,
        payload: payload,
    };
}

export function requestGetCreateFacilityFail() {
    return {
        type: REQUEST_GET_CREATE_FACILITY_FAIL,
    };
}

export function resetFacility() {
    return {
        type: RESET_FACILITY,
    };
}

export function requestSaveLoadCurveFacility(payload) {
    return {
        type: REQUEST_SAVE_LOAD_CURVE_FACILITY,
        payload: payload,
    };
}

export function requestSaveLoadCurveFacilitySuccess(payload) {
    return {
        type: REQUEST_SAVE_LOAD_CURVE_FACILITY_SUCCESS,
        payload: payload,
    };
}

export function requestSaveLoadCurveFacilityFail() {
    return {
        type: REQUEST_SAVE_LOAD_CURVE_FACILITY_FAIL,
    };
}

export function requestDisableFacility(payload) {
    return {
        type: REQUEST_DISABLE_FACILITY,
        payload,
    };
}

export function requestDisableFacilitySuccess(payload) {
    return {
        type: REQUEST_DISABLE_FACILITY_SUCCESS,
        payload,
    };
}

export function requestDisableFacilityFail() {
    return {
        type: REQUEST_DISABLE_FACILITY_FAIL,
    };
}

export function requestWeightsByCAE(payload) {
    return {
        type: REQUEST_WEIGHTS_BY_CAE,
        payload,
    };
}

export function requestWeightsByCAESuccess(payload) {
    return {
        type: REQUEST_WEIGHTS_BY_CAE_SUCCESS,
        payload,
    };
}

export function requestWeightsByCAEFail() {
    return {
        type: REQUEST_WEIGHTS_BY_CAE_FAIL,
    };
}
export function requestWeightsByCAENoInformation() {
    return {
        type: REQUEST_WEIGHTS_BY_CAE_NO_INFORMATION,
    };
}

export function requestWeightsByCAEWithPonderador(payload) {
    return {
        type: REQUEST_WEIGHTS_BY_CAE_WITH_PONDERADOR,
        payload,
    };
}

export function requestSaveAnnualConsumption(payload) {
    return {
        type: REQUEST_ANNUAL_CONSUMPTION,
        payload,
    };
}

export function requestSaveAnnualConsumptionSuccess(payload) {
    return {
        type: REQUEST_ANNUAL_CONSUMPTION_SUCCESS,
        payload,
    };
}

export function requestSaveAnnualConsumptionFail() {
    return {
        type: REQUEST_ANNUAL_CONSUMPTION_FAIL,
    };
}

export function requestSaveMonthlyConsumption(payload) {
    return {
        type: REQUEST_MONTHLY_CONSUMPTION,
        payload,
    };
}

export function requestSaveMonthlyConsumptionSuccess(payload) {
    return {
        type: REQUEST_MONTHLY_CONSUMPTION_SUCCESS,
        payload,
    };
}

export function requestSaveMonthlyConsumptionFail() {
    return {
        type: REQUEST_MONTHLY_CONSUMPTION_FAIL,
    };
}

export function requestUploadLoadCurve(payload) {
    return {
        type: REQUEST_UPLOAD_LOAD_CURVE,
        payload,
    };
}

export function requestUploadLoadCurveSuccess(payload) {
    return {
        type: REQUEST_UPLOAD_LOAD_CURVE_SUCCESS,
        payload,
    };
}

export function requestUploadLoadCurveFail() {
    return {
        type: REQUEST_UPLOAD_LOAD_CURVE_FAIL,
    };
}

export function requestDownloadLoadCurve(payload) {
    return {
        type: REQUEST_DOWNLOAD_LOAD_CURVE,
        payload,
    };
}

export function requestDownloadLoadCurveSuccess(payload) {
    return {
        type: REQUEST_DOWNLOAD_LOAD_CURVE_SUCCESS,
        payload,
    };
}

export function requestDownloadLoadCurveFail() {
    return {
        type: REQUEST_DOWNLOAD_LOAD_CURVE_FAIL,
    };
}

export function setIsDirtyFormFacility(payload) {
    return {
        type: SET_IS_DIRTY_FORM_FACILITY,
        payload,
    };
}

export function requestDistributorsByNut(payload) {
    return {
        type: REQUEST_DISTRIBUTORS_BY_NUT,
        payload,
    };
}

export function requestDistributorsByNutSuccess(payload) {
    return {
        type: REQUEST_DISTRIBUTORS_BY_NUT_SUCCESS,
        payload,
    };
}

export function requestDistributorsByNutFail(payload) {
    return {
        type: REQUEST_DISTRIBUTORS_BY_NUT_FAIL,
        payload,
    };
}

export function requestPutWeightsByCustomPeriods(payload) {
    return {
        type: REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS,
        payload,
    };
}
export function requestPutWeightsByCustomPeriodsSuccess(payload) {
    return {
        type: REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_SUCCESS,
        payload,
    };
}
export function requestPutWeightsByCustomPeriodsFail() {
    return {
        type: REQUEST_PUT_WEIGHTS_BY_CUSTOM_PERIODS_FAIL,
    };
}

export function setFacilityOpcaoTarifarioId(payload) {
    return {
        type: SET_FACILITY_OPCAO_TARIFARIO_ID,
        payload,
    };
}

export function requestSetGridTariffsSources(payload) {
    return {
        type: REQUEST_SET_GRID_TARIFF_SOURCES,
        payload,
    };
}

export function requestSetGridTariffsSourcesSuccess(payload) {
    return {
        type: REQUEST_SET_GRID_TARIFF_SOURCES_SUCCESS,
        payload,
    };
}

export function requestSetGridTariffsSourcesFail(payload) {
    return {
        type: REQUEST_SET_GRID_TARIFF_SOURCES_FAIL,
        payload,
    };
}
