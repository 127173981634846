import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { useContext } from 'react';
import OfferSummaryActionsPaPDetailed from './Actions';
import FinanceCustomizationPaPDetailed from './FinanceCustomization';
import ProjectSummaryPaPDetailed from './ProjectSummary';

const OfferSummaryPaPDetailed = () => {
    const { bmState, negotiationProfile } = useContext(OfferEditionContext);

    const showFinanceCustomization = bmState?.businessModelSelected?.financing_customization?.enabled ?? false;
    const showOfferSummary = bmState?.businessModelSelected?.has_offer_edition && negotiationProfile?.acesso_resumo_custo;

    return (
        <div className="bm-project-summary-card bm-detailed-offer-summary">
            <div className="title-actions-container">
                <ProjectSummaryTitle label="label.SummaryOffer" />
                <OfferSummaryActionsPaPDetailed />
            </div>

            <div className="bm-project-summary-offer-details-body">
                {showFinanceCustomization && <FinanceCustomizationPaPDetailed showOfferSummary={showOfferSummary} />}
                {showOfferSummary && <ProjectSummaryPaPDetailed />}
            </div>
        </div>
    );
};

export default OfferSummaryPaPDetailed;
