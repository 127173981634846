import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import DetailedKpisPaPDetailed from './DetailedKpis';
import MarginPaPDetailed from './Margin';
import MarginKpisPaPDetailed from './MarginKpis';
import { useContext } from 'react';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';

const NegotiationPaP = () => {
    const { lowNegotiationProfile, negotiationProfile } = useContext(OfferEditionContext);
    return (
        <div className="bm-detailed-negotiation">
            <ProjectSummaryTitle label="page.businessModels.negotiation.title.tariff" />
            <MarginPaPDetailed />
            {negotiationProfile?.mostra_negociacao_default_kpis && <MarginKpisPaPDetailed />}
            {!lowNegotiationProfile && <DetailedKpisPaPDetailed />}
        </div>
    );
};

export default NegotiationPaP;
