import { memo, useContext, useEffect, useRef } from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { ReactComponent as Pin } from 'assets/images/products/solarpv/pin.svg';
import { ReactComponent as HousePin } from 'assets/images/products/spv-simple/house.svg';
import { ReactComponent as SearchIcon } from 'assets/@efz/icons/search.svg';

import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

import { useMediaQuery } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import Popper from '@mui/material/Popper';
import Card from '@mui/material/Card';
import { DEFAULTS_PROPS_MAP, SPV_SIMPLE_INPUTS } from 'constants/products/solarpvSimple';
import IntlMessages from 'components/util/IntlMessages';
import SolarpvSimpleMap from '../SolarpvSimpleMap';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorMessage from 'components/errors/ErrorMessage';
import useAddress from 'hooks/products/solarpvSimple/useAddress';
import { intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';
import DrawRoofArea from './DrawRoofArea';
import { Helmet } from 'react-helmet';
import { getHeadTitle } from 'services/settings';
import { IconButton } from '@save2compete/efz-design-system';

const PlacesAutocomplete = ({
    name,
    myRef,
    placeholder,
    disabled,
    control,
    error,
    setFocus,
    userLocale,
    defaultValue,
    handleOnChangeAutocomplete,
    hasLocationAddress,
}) => {
    const {
        init,
        clearCache,
        // ready,
        // value,
        setValue,
        suggestions: { /* status, */ data, loading },
        // clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            language: DEFAULTS_PROPS_MAP.country?.[userLocale]?.language ?? DEFAULTS_PROPS_MAP.country.default.language,
            region: DEFAULTS_PROPS_MAP.country?.[userLocale]?.region ?? DEFAULTS_PROPS_MAP.country.default.region,
            componentRestrictions: {
                country: DEFAULTS_PROPS_MAP.country?.[userLocale].searchRestriction ?? DEFAULTS_PROPS_MAP.country.default.searchRestriction,
            },
            types: [],
        },
        debounce: 300,
    });

    useEffect(() => {
        init();
        return () => {
            clearCache(null);
        };
    }, [clearCache, init]);

    const handleOnChangeMap = async (_, values) => {
        handleOnChangeAutocomplete(values);
    };

    return (
        <>
            <Controller
                name={name}
                control={control}
                rules={{ required: 'yup.message.required' }}
                disabled={disabled}
                defaultValue={defaultValue}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                render={({ field: { ref, ...field } }) => (
                    <Autocomplete
                        {...field}
                        popupIcon={null}
                        defaultValue={defaultValue}
                        clearText={intlMessages('label.clear')}
                        noOptionsText={<IntlMessages id="label.NoResults" />}
                        // isOptionEqualToValue={(option, value) => option?.place_id === value?.place_id}
                        blurOnSelect={'touch'}
                        loading={loading}
                        options={data}
                        PaperComponent={(props) => <Paper {...props} elevation={8} className="address-search-options" />}
                        PopperComponent={(props) => (
                            <Popper
                                {...props}
                                anchorEl={myRef}
                                style={{
                                    width: document.querySelector('.search-card')?.offsetWidth ?? 'auto',
                                }}
                            />
                        )}
                        onChange={(_, value) => {
                            field.onChange(value);
                            handleOnChangeMap(_, value);
                        }}
                        filterOptions={(x) => x}
                        getOptionLabel={(option) => option?.description ?? ''}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        renderInput={({ InputLabelProps, ...params }) => (
                            <Typography paragraph>
                                <OutlinedInput
                                    placeholder={placeholder}
                                    defaultValue={defaultValue}
                                    fullWidth
                                    variant="outlined"
                                    // disabled={question.disabled || isInputsDisabled}
                                    ref={params.InputProps.ref}
                                    inputProps={{ ...params.inputProps }}
                                    onChange={(e) => {
                                        setValue(e.target.value);
                                        setFocus('address', { shouldSelect: false });
                                    }}
                                    error={error}
                                    className="pe-3"
                                    startAdornment={
                                        hasLocationAddress && (
                                            <InputAdornment position="start">
                                                <Pin className="pin-icon" />
                                            </InputAdornment>
                                        )
                                    }
                                    endAdornment={
                                        !hasLocationAddress && (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )
                                    }
                                />
                            </Typography>
                        )}
                    />
                )}
            />
            {!!error && <ErrorMessage error={error} />}
        </>
    );
};

const Address = () => {
    const isMobileScreen = useMediaQuery('(max-width:575px)', { noSsr: true });

    const {
        userLocale,
        hasLocationAddress,
        locationRoof,
        spvSimpleState: {
            isDetectRoof,
            inputs: { housing_type },
        },
        mapState: { isDrawing, polygon },
        mapDispatchHandler: mapDispatch,
    } = useContext(SPVSimpleContext);

    // hooks
    let {
        handleOnChangeAutocomplete,
        handleOnChangeMarkerAddress,
        handleOnChangeMarkerRoof,
        handleOnCreatePolygon,
        showCollapsed,
        setShowCollapsed,
    } = useAddress({ getGeocode });

    const containerRef = useRef(null);

    //UseForm
    const {
        // watch,
        control,
        formState,
        setValue,
        setFocus,
        // trigger
    } = useFormContext();

    const { errors } = formState;

    // autocomplete by Geocode inputs
    useEffect(() => {
        if (isFieldDefined(locationRoof?.address)) {
            let { lat, lng, address, description, postal_code, city } = locationRoof;
            // autocomplete inputs
            setValue('address', {
                lat,
                lng,
                address,
                description,
                postal_code,
                city,
            });
            setFocus('address', { shouldSelect: false });
        }
    }, [locationRoof, setValue]); //eslint-disable-line

    return (
        <div
            className={`address-container ${
                hasLocationAddress ?
                    isDetectRoof ? 'clickable'
                    :   'drawable'
                :   ''
            }`}
            ref={containerRef}
        >
            <Helmet>
                <title>{`${getHeadTitle()} · ${intlMessages('page.spvSimple.open.b2c.step.2')}`}</title>
            </Helmet>
            <Wrapper
                apiKey={DEFAULTS_PROPS_MAP.settings.apiKey}
                render={(status) => {
                    return (
                        [Status.LOADING, Status.FAILURE].includes(status) && (
                            <Skeleton variant="rectangular" animation="wave" height={250} />
                        )
                    );
                }}
                version={DEFAULTS_PROPS_MAP.settings.version}
                id={DEFAULTS_PROPS_MAP.mapOptions.id}
                language={DEFAULTS_PROPS_MAP.country?.[userLocale].language}
                region={DEFAULTS_PROPS_MAP.country?.[userLocale].region}
                libraries={DEFAULTS_PROPS_MAP.settings.libraries}
            >
                <SolarpvSimpleMap
                    center={DEFAULTS_PROPS_MAP.country?.[userLocale]?.center ?? DEFAULTS_PROPS_MAP.country?.default}
                    // location={location}
                    handleOnChangeMarkerAddress={handleOnChangeMarkerAddress}
                    handleOnChangeMarkerRoof={handleOnChangeMarkerRoof}
                    handleOnCreatePolygon={handleOnCreatePolygon}
                    {...DEFAULTS_PROPS_MAP.mapOptions}
                    zoomControl={!isMobileScreen}
                />
                <Card
                    className={`search-card ${hasLocationAddress ? 'identify' : ''} ${hasLocationAddress && !showCollapsed ? 'collapsed' : ''}`}
                >
                    {!hasLocationAddress ?
                        <CardHeader
                            avatar={<Pin className="pin-icon" />}
                            title={<IntlMessages id="page.spvSimple.address.searchYourAddress" />}
                        />
                    :   <></>}
                    <CardContent className="card-content">
                        {showCollapsed ?
                            <PlacesAutocomplete
                                control={control}
                                setFocus={setFocus}
                                name={SPV_SIMPLE_INPUTS.ADDRESS}
                                myRef={containerRef.current}
                                placeholder={intlMessages('page.spvSimple.address.searchYourAddressHeare')}
                                disabled={false}
                                defaultValue={''}
                                error={errors[SPV_SIMPLE_INPUTS.ADDRESS]}
                                userLocale={userLocale}
                                handleOnChangeAutocomplete={handleOnChangeAutocomplete}
                                hasLocationAddress={hasLocationAddress}
                            />
                        : isDetectRoof ?
                            <Typography>
                                <CardHeader avatar={<HousePin />} title={<IntlMessages id="page.spvSimple.address.IdentifyRoof.title" />} />
                            </Typography>
                        :   <Typography>
                                <CardHeader avatar={<HousePin />} title={<IntlMessages id="page.spvSimple.address.DrawYourRoof.title" />} />
                            </Typography>
                        }
                        {hasLocationAddress && (
                            <IconButton
                                variant="tertiary"
                                icon={showCollapsed ? 'arrowHeadUp' : 'arrowHeadDown'}
                                size="bg"
                                className="close-button"
                                onClick={() => setShowCollapsed(!showCollapsed)}
                                dataTestId="collapse"
                            />
                        )}
                    </CardContent>
                    {hasLocationAddress && isDetectRoof && (
                        <Collapse in={showCollapsed} timeout={{ appear: 200, enter: 200, exit: 0 }}>
                            <Typography>
                                <CardHeader avatar={<HousePin />} title={<IntlMessages id="page.spvSimple.address.IdentifyRoof.title" />} />
                                <div className="card-subtitle">
                                    <IntlMessages
                                        id="page.spvSimple.address.IdentifyRoof.subtitle"
                                        values={{
                                            action: (
                                                <strong>
                                                    <IntlMessages id="label.drag" />
                                                </strong>
                                            ),
                                        }}
                                    />
                                </div>
                            </Typography>
                        </Collapse>
                    )}
                    {hasLocationAddress && !isDetectRoof && (
                        <Collapse in={showCollapsed} timeout={{ appear: 200, enter: 200, exit: 0 }}>
                            <DrawRoofArea
                                isDrawing={isDrawing}
                                mapDispatch={mapDispatch}
                                hasArea={isFieldDefined(polygon?.id)}
                                area={polygon?.area ?? 0}
                                housing_type={housing_type}
                            />
                        </Collapse>
                    )}
                </Card>
            </Wrapper>
        </div>
    );
};

export default memo(Address);
