/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @see https://reactjs.org/docs/hooks-custom.html
 *
 * @version 20210630
 * @since 20210630 Initial release
 *
 */

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { ALLOW_PATHNAME_REDICT_BY_TOKEN } from 'constants/settings';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { useAuthStore } from 'store/auth';

const useRedirectToken = (location) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const urlToken = () => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get('token');
    };
    const [isRedirectingByToken, setIsRedirectingByToken] = useState(
        isFieldDefined(urlToken()) && ALLOW_PATHNAME_REDICT_BY_TOKEN.includes(location.pathname)
    );
    const { loginOK } = useAuthStore();

    useEffect(() => {
        if (isRedirectingByToken) {
            urlTokenLogin();
        }
    }, []); //eslint-disable-line

    // const fetchData = async (token, pathname) => {
    //     await localStorage.setItem('userToken', token);
    //     let jwtDecodeToken = jwt_decode(token);
    //     const { user, context } = jwtDecodeToken;
    //     let userTypeID = user?.tipo_utilizador_id;
    //     await localStorage.setItem('userTypeId', userTypeID);
    //     localStorage.setItem('SCCode', user?.company_code ?? companyIdToCompanyCode(user?.empresa_id));
    //     localStorage.setItem('companyId', user?.empresa_id);

    //     let lngObj = AVAILABLE_LANGUAGES.find((obj) => obj.userLocale === user?.userLocale);
    //     if (!lngObj) {
    //         //fallback (en-GB)
    //         lngObj = AVAILABLE_LANGUAGES.find((obj) => obj.userLocale === 'en-GB');
    //         jwtDecodeToken.user.locale = lngObj.userLocale; //fallback
    //     }

    //     //Auth
    //     // await dispatch(setAuthByToken({ token, isUserClient: isUserClient(userTypeID) }));

    //     // User
    //     setUser({ user: { ...user, companyName: user?.short_name } });
    //     await switchLanguage(lngObj);
    //     await localStorage.setItem('theme', user.empresa_id);

    //     // theme
    //     let responseTheme = await getTheme();
    //     if (responseTheme?.status === OK) {
    //         dispatch(requestThemeSuccess(responseTheme?.data?.data));
    //     } else if (responseTheme?.status === UNAUTHORIZED) {
    //         logout();
    //         return; //logout
    //     }

    //     //Client
    //     let clientData = null;
    //     if (isUserClient(userTypeID)) {
    //         clientData = await getClient();
    //         if (!clientData?.status || clientData?.status !== OK) {
    //             logout();
    //             return; //logout
    //         }
    //         await dispatch(setClientByToken({ data: clientData?.data?.data?.[0] ?? null }));
    //     }

    //     //Facilities
    //     // let facilitiesData = null;
    //     // if (isDefined(context?.client_id) || isDefined(clientData?.data?.data?.[0]?.id)) {
    //     //     facilitiesData = await getFacilitiesSearchByClient({
    //     //         clientId: context?.client_id ?? clientData?.data?.data?.[0]?.id
    //     //     });
    //     // }
    //     // handleSetFacilitiesByToken({ data: facilitiesData?.data?.data ?? [] });//TODO: validar....colocar no context

    //     //facility
    //     if (isDefined(context?.facility_id)) {
    //         const facilityData = await getFacilityInfo({ facilityID: context?.facility_id });
    //         await dispatch(setFacilityByToken({ data: facilityData?.data?.data?.[0] ?? null }));
    //     }

    //     //product
    //     let isPV = isExistWord(pathname, 'solutions/1/modules/simple');
    //     let isIESimple = isExistWord(pathname, 'solutions/2/modules/simple');
    //     if (isDefined(context?.facility_id) && (isPV || isIESimple)) {
    //         //eMeasuresData
    //         const eMeasuresData = await getSolutions(context?.facility_id);

    //         //productsData
    //         let productID = isPV ? PRODUCT_IDS.SPV : PRODUCT_IDS.IE;
    //         let productsData = eMeasuresData?.data?.data?.efficiency_measures?.find((item) => item.id === productID);
    //         await dispatch(setProductByToken(productsData ?? null));

    //         setIsRedirectingByToken(false);
    //         history.push(`${BASENAME_URL_SOLUTIONS}/${productID}/modules/simple`);
    //     } else {
    //         setIsRedirectingByToken(false);

    //         history.push(BASENAME_URL_SOLUTIONS);
    //     }
    // };

    const urlTokenLogin = async () => {
        await loginOK({ token: urlToken(), history, dispatch });
        await setIsRedirectingByToken(false);
    };

    const isValidRedirectToken = (location) => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        let isValid = false;
        // PV & IE
        if (isFieldDefined(token) && ALLOW_PATHNAME_REDICT_BY_TOKEN.includes(location.pathname)) {
            isValid = true;
            loginOK({ token, history, dispatch });
        }

        setIsRedirectingByToken(isValid);
        return isValid;
    };

    return {
        isRedirectingByToken,
        isValidRedirectToken,
    };
};

export default useRedirectToken;
