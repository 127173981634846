import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';
import ProjectSummaryLECDetailed from './detailed';
import ProjectSummaryLECSimple from './simple';
import { Tlec } from 'interfaces/businessModels/lec';

const ProjectSummaryLEC = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, Tlec>;

    if (isDefined(bmSelected.values?.syncKpis)) return <ProjectSummaryLECDetailed />;
    return <ProjectSummaryLECSimple />;
};

export default ProjectSummaryLEC;
