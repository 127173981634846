import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Slider from '@mui/material/Slider';
import CustomUnit from 'components/util/CustomUnit';
import { DEBOUNCE_TIME } from 'constants/endpoints';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { debounce, decimalsHandler, intlMessages } from 'services/util/auxiliaryUtils';
import validate from 'services/util/validate';
//is DEv

/**************************/

// MUI Slider doesnt like decimal values when with marks, it makes the slider work buggy with keyboard arrow keys
// https://mui.com/material-ui/react-slider/ Restricted Values section
// You can try with in codebox https://codesandbox.io/s/k027g4?file=/demo.js and change the values in marks to decimals

// We are now using decimalsHandler to delete/add the decimals when needed

// Its need when we dispatch the BM events
// to show in the controlled input
// in the default values on _sliderValues

/**************************/

const MarginNoGoalSeek = () => {
    const { setBMEventHandler, bmState } = useContext(OfferEditionContext);

    const { loadings } = bmState;

    const { control } = useFormContext();

    const _sliderValues = bmState?.kpis?.negotiation;
    const sliderStep = _sliderValues?.step ?? 0.01;
    const sliderDecimalScale = sliderStep?.toString()?.split('.')?.[1]?.length ?? sliderStep ?? 1;

    const [sliderValue, setSliderValue] = useState(
        decimalsHandler('without', _sliderValues?.add_on_helper_pre_selected ?? _sliderValues?.default, sliderDecimalScale)
    );
    const handleSliderChange = useCallback((_, newValue) => setSliderValue(newValue), []);

    const marks = _sliderValues?.monthly_fee_options_arr
        ?.sort((a, b) => a - b)
        .map((option) => {
            return {
                value: decimalsHandler('without', option, sliderDecimalScale),
                label: option,
            };
        });

    // eslint-disable-next-line
    const delayedNewMarginHandler = useCallback(
        debounce(
            (payload) =>
                setBMEventHandler(
                    DetailedOfferEditionEvents.SET_BUSINESS_MODEL_MARGIN,
                    decimalsHandler('with', payload, sliderDecimalScale)!
                ),
            DEBOUNCE_TIME
        ),
        []
    ); // eslint-disable-line

    useEffect(() => {
        delayedNewMarginHandler(sliderValue);
    }, [sliderValue]); // eslint-disable-line

    return (
        <>
            <div className="margin-slider-container">
                {
                    <Slider
                        className="margin-marks"
                        aria-label="margin-slider"
                        aria-labelledby="discrete-slider-restrict"
                        defaultValue={decimalsHandler('without', _sliderValues?.default, sliderDecimalScale)}
                        onChange={handleSliderChange}
                        value={sliderValue}
                        disabled={loadings.general}
                        min={marks[0].value}
                        max={marks[marks.length - 1]?.value}
                        step={null}
                        valueLabelDisplay="off"
                        marks={marks}
                    />
                }
                <div className="margin-slider-container-controller">
                    <Controller
                        name="margin-slider-input"
                        rules={validate(['required'])}
                        control={control}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { ref, ...field } }) => {
                            return (
                                <NumberFormat
                                    {...field}
                                    defaultValue={decimalsHandler('with', sliderValue, sliderDecimalScale)}
                                    value={decimalsHandler('with', sliderValue, sliderDecimalScale)}
                                    decimalScale={sliderDecimalScale}
                                    placeholder={intlMessages('label.insertValue')}
                                    customInput={OutlinedInput}
                                    disabled
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {/* @ts-ignore */}
                                            <CustomUnit unit={'cmwp'} />
                                        </InputAdornment>
                                    }
                                />
                            );
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default MarginNoGoalSeek;
