import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { UserContext } from 'contexts/userContext';
import { IBusinessModelsContext, TBMProjectSummary, TProductInputs } from 'interfaces/businessModels';
import { useContext } from 'react';
import KPI from 'components/BusinessModels/List/BusinessModels/BusinessModelInstallments/Card/Summary/Kpi';
import { installmentsProjectSummary } from 'services/businessModels/installments';
import { TInstallments } from 'interfaces/businessModels/installments';
import { useFeatureFlags } from 'store/featureFlags';

interface IProps {
    projectSummary: TBMProjectSummary[];
    syncKpis: any;
}

const BusinessModelProjectSummaryInstallments = ({ projectSummary, syncKpis }: IProps) => {
    const { productID, state } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TInstallments>;

    const { companyProfileId } = useContext(UserContext);
    const { featureFlags } = useFeatureFlags();

    const kpis = installmentsProjectSummary({ kpis: projectSummary, syncKpis }, state, {
        companyProfileId,
        productID,
        featureFlags,
    });

    return <>{kpis.length > 0 ? kpis.map((kpi) => <KPI {...kpi} />) : <></>}</>;
};

export default BusinessModelProjectSummaryInstallments;
