import { DISPATCH_EVT } from 'constants/facilityPro/main';
import clone from 'fast-copy';
import {
    getQuestionsSupplyTariffsDataB2B,
    getSupplyTariffsDataB2B,
    getQuestionsSupplyTariffsDataB2C,
    getSupplyTariffsDataB2C,
    getDetailedTariffsB2B,
    getDetailedTariffsB2C,
} from 'services/facilityPro/supplyTariffsData';
import { getOptions } from 'services/facilityPro/supplyTariffsData';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import uniqby from 'lodash.uniqby';
import { INPUT_NAMES_STARIFFS } from 'constants/facilityPro/supplyTariffsData';
import { getCompanyProfileIds } from 'services/user';

export const initialSupplyTariffsDataReducer = clone({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dispatchSupplyTariffsData: (type = '', payload = {}) => {},
    isAddFacility: false,
    isMounting: true,
    isVisible: true,
    isB2C: false,
    //#region inputs
    supplied_by_company: '', //B2B
    fornecedor_id: '', //B2B
    opcao_horario_id: '', //B2B
    distribuidor_id: '',
    opcao_tarifario_id: '',
    fonte_tarifa_redes_id: '',
    tarifa_kw_dia: '',
    detailed_tariffs: [],
    power_fee: '', //B2B
    //#endregion inputs
    distributors: [],
    gridTariffs: [],
    gridTariffSources: [],
    hourlyOptions: [],
    suppliers: [],
    tariffOptions: [],
    usedPowerTariffs: [],
    questions: [],
    //#region request state
    isRequestingTariffOptions: false,
    //#endregion request state
});

export function supplyTariffsDataReducer(state = initialSupplyTariffsDataReducer, action) {
    switch (action.type) {
        case DISPATCH_EVT.SET_SUPPLY_TARIFFS: {
            let { payload } = action;
            let { inputs, options, responses } = payload;

            let { isAddFacility, companyProfileId, userTypeID, userChannelIDGroup, userLanguage, isB2C } = options;

            let { tariffOptions, gridTariffs, suppliers, distributors, hourlyOptions, usedPowerTariffs, gridTariffSources, energyTariffs } =
                getOptions(responses, {
                    companyProfileId,
                });

            let newInputs =
                isB2C ?
                    getSupplyTariffsDataB2C(inputs, {
                        ...options,
                        tariffOptions,
                        gridTariffs,
                        suppliers,
                        distributors,
                        hourlyOptions,
                        usedPowerTariffs,
                        gridTariffSources,
                        energyTariffs,
                    })
                :   getSupplyTariffsDataB2B(inputs, {
                        ...options,
                        tariffOptions,
                        distributors,
                        gridTariffs,
                        usedPowerTariffs,
                        isAddFacility,
                    });

            let questions =
                !isB2C ?
                    getQuestionsSupplyTariffsDataB2B({
                        ...options,
                        tariffOptions,
                        gridTariffs,
                        suppliers,
                        distributors,
                        hourlyOptions,
                        usedPowerTariffs,
                        gridTariffSources,
                    })
                :   getQuestionsSupplyTariffsDataB2C({
                        ...options,
                        tariffOptions,
                        gridTariffs,
                        suppliers,
                        distributors,
                        hourlyOptions,
                        usedPowerTariffs,
                        gridTariffSources,
                    });

            return {
                ...state,
                ...newInputs,
                ...questions,
                isAddFacility,
                isB2C,
                companyProfileId,
                userTypeID,
                userChannelIDGroup,
                userLanguage,
                tariffOptions,
                gridTariffs,
                suppliers,
                distributors,
                hourlyOptions,
                usedPowerTariffs,
                gridTariffSources,
                isMounting: false,
            };
        }

        case DISPATCH_EVT.IS_REQUESTING_TARIFF_OPTIONS: {
            const { companyProfileId, isRequestingTariffOptions } = state;
            return {
                ...state,
                isRequestingTariffOptions:
                    ![getCompanyProfileIds().EFZ].includes(companyProfileId) ? action.payload : isRequestingTariffOptions,
            };
        }
        case DISPATCH_EVT.SET_SUPPLY_TARIFFS_OPTS: {
            const { gridTariffs, usedPowerTariffs } = action.payload;
            return {
                ...state,
                gridTariffs,
                usedPowerTariffs,
            };
        }
        case DISPATCH_EVT.UPDATE_STDATA_ON_COUNTY_CHANGE_B2B: {
            const {
                countyID,
                options: { setValue, handleUpdateSTData },
            } = action.payload;

            handleUpdateSTData(action.type, { countyID, setValue });

            return {
                ...state,
                isRequestingTariffOptions: true,
            };
        }

        case DISPATCH_EVT.UPDATE_STDATA_ON_COUNTY_CHANGE_B2C: {
            const {
                countyID,
                options: { setValue, handleUpdateSTData },
            } = action.payload;

            handleUpdateSTData(action.type, { countyID, setValue });

            return {
                ...state,
                isRequestingTariffOptions: true,
            };
        }

        case DISPATCH_EVT.UPDATE_STDATA_ON_POSTAL_CODE_CHANGE_B2B: {
            const {
                postalCode,
                options: { setValue, handleUpdateSTData },
            } = action.payload;

            handleUpdateSTData(action.type, { postalCode, setValue });

            return {
                ...state,
                isRequestingTariffOptions: true,
            };
        }

        case DISPATCH_EVT.UPDATE_STDATA_ON_COUNTY_CHANGE_B2B_SUCCESS: {
            const { companyProfileId, distributors, isAddFacility } = state;

            const { setValue, tariffOptions } = action.payload;

            let uniqueDistributorsIds = uniqby(tariffOptions, 'd_id');

            let newDistributors = distributors.map((d) => ({
                ...d,
                visible:
                    (
                        [
                            getCompanyProfileIds().ROMANDE,
                            getCompanyProfileIds().PROFILE_BASE_SWISS,
                            getCompanyProfileIds().PROFILE_BASE_USA,
                        ].includes(companyProfileId)
                    ) ?
                        isFieldDefined(uniqueDistributorsIds.find((el) => `${el.d_id}` === `${d.id}`))
                    :   true,
            }));

            // if only has 1 option, set and disable input, otherwise reset selection
            setValue(INPUT_NAMES_STARIFFS.DISTRIBUIDOR_ID, `${uniqueDistributorsIds?.[0]?.d_id ?? ''}`);

            //set default
            let tarrifDefault = tariffOptions.find((el) => el.is_default);
            // reset
            setValue(INPUT_NAMES_STARIFFS.OPCAO_TARIFARIO_ID, `${tarrifDefault?.id ?? ''}`);
            setValue(
                INPUT_NAMES_STARIFFS.DETAILED_TARIFFS,
                getDetailedTariffsB2B(
                    {
                        energy_tariffs: [],
                        total_tariffs: [],
                        opcao_tarifario_id: tarrifDefault?.id ?? null,
                    },
                    {
                        tariffOptions,
                        isAddFacility,
                        companyProfileId,
                    }
                )
            );

            return {
                ...state,
                distributors: newDistributors,
                tariffOptions,
                isRequestingTariffOptions: false,
            };
        }

        case DISPATCH_EVT.UPDATE_STDATA_ON_COUNTY_CHANGE_B2C_SUCCESS: {
            const { setValue, tariffOptions } = action.payload;

            //set default
            let tarrifDefault = tariffOptions.find((el) => el.is_default);
            // reset
            setValue(INPUT_NAMES_STARIFFS.OPCAO_TARIFARIO_ID, `${tarrifDefault?.id ?? ''}`);
            setValue(
                INPUT_NAMES_STARIFFS.DETAILED_TARIFFS,
                getDetailedTariffsB2C(
                    {
                        energy_tariffs: [],
                        total_tariffs: [],
                        opcao_tarifario_id: tarrifDefault?.id ?? null,
                    },
                    {
                        tariffOptions,
                        isAddFacility: state.isAddFacility,
                        companyProfileId: state.companyProfileId,
                    }
                )
            );

            return {
                ...state,
                tariffOptions,
                isRequestingTariffOptions: false,
            };
        }

        default: {
            throw new Error(`Error on supplyTariffsDataReducer type: ${action.type}`);
        }
    }
}
