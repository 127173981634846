import React, { createContext, useState } from 'react';

const AutoCompleteContext = createContext({
    clients: [],
    facilities: [],
    users: [],
    clientManagers: [],
    servicesManagers: [],
    owners: [],
    groups: [],
    partners: [],
});

const AutoCompleteProvider = ({ children }) => {
    const [clients, setClients] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [users, setUsers] = useState([]);
    const [clientManagers, setClientManagers] = useState([]);
    const [servicesManagers, setServicesManagers] = useState([]);
    const [owners, setOwners] = useState([]);
    const [groups, setGroups] = useState([]);
    const [partners, setPartners] = useState([]);

    return (
        <AutoCompleteContext.Provider
            value={{
                clients,
                setClients,
                facilities,
                setFacilities,
                users,
                setUsers,
                clientManagers,
                setClientManagers,
                servicesManagers,
                setServicesManagers,
                owners,
                setOwners,
                groups,
                setGroups,
                partners,
                setPartners,
            }}
        >
            {children}
        </AutoCompleteContext.Provider>
    );
};

export { AutoCompleteContext, AutoCompleteProvider };
