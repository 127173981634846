const FALSE_POSITIVE_REGEX_ENTRIES: RegExp[] = [
    /Could not find a 3d context/,
    /InvalidValueError/,
    /undefined is not an object \(evaluating 'a\.L'\)/,
    /Objects are not valid as a React child/,
    /this\.Kg is not a function/,
    /a\.onContextLost is not a function/,
    /Failed to execute 'insertBefore' on 'Node'/,
    /Illegal invocation TypeError \/js\/widget\.js Object\.postMessage/,
    // eslint-disable-next-line no-useless-escape
    /Error sending request: Failed to fetch RestError .+\Ta Ta\(main\)/,
    /Failed to fetch TypeError \.\.\/node_modules\/@sentry\/src\/nstrument\.ts \?\(chrome-extension/,
    /Right-hand side of 'instanceof' is not an object/,
    /Cannot read properties of null \(reading 'getAt'\)/,
    /Cannot read properties of null \(reading 'zoom'\)/,
    /JSON\.parse: unexpected character/,
    /Loading CSS chunk/,
    /Error sending request: Failed to fetch RestError .+fetchHttpClient/,
    /No error message RestError .+deserializationPolicy/,
    /Object doesn't support property or method 'forEach'/,
    /a\.stopPropagation is not a function/,
    /Failed to execute 'replaceState' on 'History': A history state object with URL 'file:\/\/\/C:\/app\/solutions'/,
    /loaderCB/,
    /chrome-extension/,
    /Cannot read properties of undefined \(reading 'CE'\)/,
    /Cannot read properties of null \(reading 'zoom'\)/,
    /construct\(\[native code\]\)/,
    /blfcndbglccbobmkepikmbmobcehhkkf/,
    /maps-api-v3/,
    /maps\/api\/js/,
    /Target container is not a DOM element/,
    /updateAppStateWithAccountDataset/,
    /TypeError: Failed to fetch/,
    /Minified React error/,
    /Failed to fetch/,
    /JSON Parse error: Unexpected identifier "object"/,
    /"\[object Object\]" is not valid JSON/,
    /No error message/,
    /Object Not Found Matching Id:\d+, MethodName:simulateEvent, ParamCount:\d+/,
    /FF401/,
];

export default FALSE_POSITIVE_REGEX_ENTRIES;
