import { useEffect } from 'react';
import Grid from '@mui/material/Grid';

// Contexts
// Open Area \\
import { OAUserProvider } from 'contexts/openArea/oaUserContext';
import OASolarpvSimpleB2C from 'pages/openArea/products/OASolarpvSimple/b2c';
import OASolarpvSimpleB2B from 'pages/openArea/products/OASolarpvSimple/b2b';
import { SPVSimpleProvider } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import useSettings from 'hooks/useSettings';
import Loading from 'components/core/Loading';

import OAHeader from 'pages/openArea/OAHeader';

const OARoutes = ({ isB2C }) => {
    let { fetchThemeOnHandler, isRequesting } = useSettings(isB2C);

    const SPVSimpleComponent = isB2C ? <OASolarpvSimpleB2C isB2C={isB2C} /> : <OASolarpvSimpleB2B isB2C={isB2C} />;

    useEffect(() => {
        fetchThemeOnHandler();
    }, []); // eslint-disable-line

    return (
        <Grid container className="app-container">
            <div className="app-main-container">
                <OAUserProvider>
                    {' '}
                    {/* OA = Open Area / public = unrestricted */}
                    {!isRequesting ?
                        <>
                            <SPVSimpleProvider>
                                <OAHeader isB2c={isB2C} />
                                {SPVSimpleComponent}
                            </SPVSimpleProvider>
                        </>
                    :   <Loading />}
                </OAUserProvider>
            </div>
        </Grid>
    );
};
export default OARoutes;
