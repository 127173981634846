import { isDefined } from 'services/util/auxiliaryUtils';

export interface LatLngLiteral {
    lat: number;
    lng: number;
}

export const isLatLngLiteral = (objToValidate): objToValidate is LatLngLiteral => {
    if (
        'lat' in objToValidate &&
        typeof objToValidate?.lat === 'number' &&
        'lng' in objToValidate &&
        typeof objToValidate?.lng === 'number'
    ) {
        return true;
    }

    return false;
};

export class LatLng {
    private _lat: number = 0;
    private _lng: number = 0;

    constructor(latOrLatLngLiteralOrLatLng: number | LatLngLiteral | LatLng, lng?: number) {
        if (typeof latOrLatLngLiteralOrLatLng === 'number') {
            this._lat = latOrLatLngLiteralOrLatLng;

            if (!isDefined(lng)) throw new Error('Missing `lng` parameter');
            this._lng = Number(lng);
        } else if (latOrLatLngLiteralOrLatLng instanceof LatLng) {
            this._lat = latOrLatLngLiteralOrLatLng.lat();
            this._lng = latOrLatLngLiteralOrLatLng.lng();
        } else if (isLatLngLiteral(latOrLatLngLiteralOrLatLng)) {
            this._lat = latOrLatLngLiteralOrLatLng.lat;
            this._lng = latOrLatLngLiteralOrLatLng.lng;
        } else {
            throw new Error('Invalid LatLng constructor params');
        }
    }

    lat(): number {
        return this._lat;
    }

    lng(): number {
        return this._lng;
    }

    equals(other: LatLng): boolean {
        return this._lat === other.lat() && this._lng === other.lng();
    }

    toJSON(): LatLngLiteral {
        return {
            lat: this._lat,
            lng: this._lng,
        };
    }

    toString(): string {
        return `(${this._lat}, ${this._lng})`;
    }

    toLocaleString(): string {
        return `(${this._lat}, ${this._lng})`;
    }

    toUrlValue(): string {
        return `${this._lat},${this._lng}`;
    }
}
