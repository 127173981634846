// MUI
import IconButton from '@mui/material/IconButton';
// Icons
import { ReactComponent as CloseIcon } from 'assets/@efz/icons/x-close.svg';

const CloseButton = () => {
    return (
        <IconButton className="position-absolute toast-close-button" disableFocusRipple disableTouchRipple>
            <CloseIcon />
        </IconButton>
    );
};

export default CloseButton;
