import { defineMessages } from 'react-intl';
import enMessages from '../locales/en_US.json';

const enUSLang = {
    messages: {
        ...enMessages,
    },
    locale: 'en-US',
    data: defineMessages,
};
export default enUSLang;
