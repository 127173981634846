import { useCallback, useContext, useState } from 'react';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { getGeoCodeInfo } from 'services/gMap';
import { SentryCaptureException } from 'services/@efz/sentry';

const useAddress = ({ getGeocode }) => {
    const {
        setLocationAddress,
        setLocationRoof,
        spvSimpleState: { companyProfileId, isDetectRoof },
    } = useContext(SPVSimpleContext);

    const [showCollapsed, setShowCollapsed] = useState(true);

    function offsetCenter(lat, lng, map) {
        const offsetY = 0.15;
        const span = map.getBounds().toSpan();
        const center = {
            lat: lat + span.lat() * offsetY,
            lng: lng,
        };
        return center;
    }

    const handleOnChangeMarkerRoof = async (evt, markerRoof, map) => {
        try {
            const resultsGeoCode = await getGeocode({ location: evt.latLng });
            let newGeoCodeInfo = getGeoCodeInfo(resultsGeoCode, null, companyProfileId, { evt });
            const { address } = newGeoCodeInfo;
            markerRoof.setPosition({ lat: evt.latLng.lat(), lng: evt.latLng.lng() }); //to update position when location changes
            markerRoof.setTitle(`Location: ${address}`);
            setLocationRoof(newGeoCodeInfo);
            setLocationAddress(newGeoCodeInfo);
            // map.setZoom(map?.getZoom());
            markerRoof.setVisible(isDetectRoof); //visible
            map.panTo({ lat: evt.latLng.lat(), lng: evt.latLng.lng() }); //to center map with offset
        } catch (error) {
            let message = `Info: Geocode: NOT_FOUND (location)`;
            SentryCaptureException({
                level: 4,
                message,
                fingerprint: message,
                extrasContext: {
                    method: 'handleOnChangeMarkerRoof',
                    error: `${JSON.stringify(error)}`,
                    location: `${JSON.stringify({ location: evt.latLng, alt: { lat: evt.latLng.lat(), lng: evt.latLng.lng() } })}`,
                },
            });
        }
    };

    const handleOnChangeMarkerAddress = async (location, marker, map) => {
        if (!!map && !!marker) {
            let { lat, lng, address, description } = location;
            map.panTo({ lat, lng }); //to center map with offset
            marker.setPosition({ lat, lng }); //to update position when location changes
            marker.setTitle(`Location: ${address ? address : description}`);
            if (!marker.getVisible()) {
                marker.setVisible(true); //visible
                map.setZoom(19);
                map.setMapTypeId('satellite');
            }
        }
    };

    const handleOnCreatePolygon = async (centroid) => {
        try {
            const resultsGeoCode = await getGeocode({
                location: centroid,
            });
            let newGeoCodeInfo = getGeoCodeInfo(resultsGeoCode, null, companyProfileId, null);
            setLocationRoof(newGeoCodeInfo);
        } catch (error) {
            let message = `Info: Geocode NOT_FOUND (centoid)`;
            SentryCaptureException({
                level: 4,
                message,
                fingerprint: message,
                extrasContext: {
                    method: 'handleOnCreatePolygon',
                    error: `${JSON.stringify(error)}`,
                    centroid: `${JSON.stringify(centroid)}`,
                },
            });
        }
    };

    const handleOnChangeAutocomplete = useCallback(async (addressData) => {
        try {
            if (isFieldDefined(addressData)) {
                let results = await getGeocode({ placeId: addressData?.place_id });
                let newGeoCodeInfo = getGeoCodeInfo(results, null, companyProfileId);
                setLocationAddress(newGeoCodeInfo);
                setLocationRoof(newGeoCodeInfo);
            }
        } catch (err) {
            let message = `Info: Geocode NOT_FOUND (address)`;
            SentryCaptureException({
                level: 4,
                message,
                fingerprint: message,
                extrasContext: {
                    error: `${JSON.stringify(err)}`,
                    address: `${JSON.stringify(addressData)}`,
                    method: 'handleOnChangeAutocomplete',
                },
            });
        }
    }, []); //eslint-disable-line

    return {
        handleOnChangeAutocomplete,
        handleOnChangeMarkerAddress,
        handleOnChangeMarkerRoof,
        handleOnCreatePolygon,
        offsetCenter,
        showCollapsed,
        setShowCollapsed,
    };
};

export default useAddress;
