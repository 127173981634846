import { ICustomizeLoadings } from 'interfaces/products/solarpv/customize/loadings';

export const enum CustomizeLoadings {
    GENERAL = 'general',
    RESET = 'reset',
    OTHER_COSTS = 'otherCosts',
    EFZ_COSTS = 'efzCosts',
    GET_CUSTOMIZED = 'getCustomized',
    POST_CUSTOMIZED = 'postCustomized',
    FINAL_SIMULATION = 'finalSimulation',
    LOAD_VALUES_FROM_BD = 'loadValuesFromBD',
    BUSINESS_MODELS = 'businessModels',
    DOWNLOAD_FIN_MODEL = 'downloadFinModel',
    UPLOAD_FIN_MODEL = 'uploadFinModel',
    UPLOAD_PRODUCTION = 'uploadProduction',
    DOWNLOAD_PRODUCTION = 'downloadProduction',
}

export const initialCustomizeLoadings: ICustomizeLoadings = {
    general: false,
    initialSimulation: {
        otherCosts: false,
        efzCosts: false,
        getCustomized: false,
        loadValuesFromBD: false,
    },
    postCustomized: false,
    sizingProduction: {
        production: {
            upload: false,
            download: false,
        },
    },
    financial: {
        businessModels: false,
        standardModel: {
            download: false,
        },
        customModel: {
            upload: false,
            download: false,
        },
    },
    finalSimulation: false,
};

export const CustomizeLoadingssReducer = (state = initialCustomizeLoadings, action) => {
    switch (action.type) {
        case CustomizeLoadings.GENERAL:
            return {
                ...state,
                general: true,
            };
        case CustomizeLoadings.OTHER_COSTS:
            return {
                ...state,
                general: true,
                initialSimulation: {
                    ...state.initialSimulation,
                    otherCosts: action.payload ?? true,
                },
            };
        case CustomizeLoadings.EFZ_COSTS:
            return {
                ...state,
                general: true,
                initialSimulation: {
                    ...state.initialSimulation,
                    efzCosts: action.payload ?? true,
                },
            };
        case CustomizeLoadings.GET_CUSTOMIZED:
            return {
                ...state,
                general: true,
                initialSimulation: {
                    ...state.initialSimulation,
                    getCustomized: action.payload ?? true,
                },
            };
        case CustomizeLoadings.LOAD_VALUES_FROM_BD:
            return {
                ...state,
                general: true,
                initialSimulation: {
                    ...state.initialSimulation,
                    loadValuesFromBD: action.payload ?? true,
                },
            };
        case CustomizeLoadings.UPLOAD_PRODUCTION:
            return {
                ...state,
                sizingProduction: {
                    ...state.sizingProduction,
                    production: {
                        ...state.sizingProduction.production,
                        upload: action.payload ?? true,
                    },
                },
            };
        case CustomizeLoadings.DOWNLOAD_PRODUCTION:
            return {
                ...state,
                sizingProduction: {
                    ...state.sizingProduction,
                    production: {
                        ...state.sizingProduction.production,
                        download: action.payload ?? true,
                    },
                },
            };
        case CustomizeLoadings.POST_CUSTOMIZED:
            return {
                ...state,
                general: true,
                postCustomized: true,
            };
        case CustomizeLoadings.FINAL_SIMULATION:
            return {
                ...state,
                general: true,
                finalSimulation: true,
            };
        case CustomizeLoadings.BUSINESS_MODELS:
            return {
                ...state,
                financial: {
                    ...state.financial,
                    businessModels: action.payload ?? true,
                },
            };
        case CustomizeLoadings.DOWNLOAD_FIN_MODEL: {
            const { isCustomModel, flag = true } = action.payload;
            let financialState = { ...state.financial };
            if (!isCustomModel) {
                financialState = {
                    ...financialState,
                    standardModel: {
                        ...financialState.standardModel,
                        download: flag,
                    },
                };
            } else {
                financialState = {
                    ...financialState,
                    customModel: {
                        ...financialState.customModel,
                        download: flag,
                    },
                };
            }
            return {
                ...state,
                financial: { ...financialState },
            };
        }
        case CustomizeLoadings.UPLOAD_FIN_MODEL:
            return {
                ...state,
                financial: {
                    ...state.financial,
                    customModel: {
                        ...state.financial.customModel,
                        upload: action.payload ?? true,
                    },
                },
            };
        case CustomizeLoadings.RESET:
            return initialCustomizeLoadings;
        default:
            return initialCustomizeLoadings;
    }
};
