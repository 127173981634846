import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from 'components/@efz/Tooltip';
import CustomUnit from 'components/util/CustomUnit';
import IntlMessages from 'components/util/IntlMessages';
import { BusinessModelsProContext, BusinessModelsProWebstompContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { PaPEvents, TPaP } from 'interfaces/businessModels/pap';
import { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { intlMessages } from 'services/util/auxiliaryUtils';
import { Button } from '@save2compete/efz-design-system';

const PaPOfferDetailsInputs = () => {
    const { state, setBMEventHandler } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;

    const { isLoadingWebStomp } = useContext(BusinessModelsProWebstompContext);

    //UseForm
    const {
        control,
        watch,
        setValue,
        formState: { isDirty },
    } = useFormContext();

    const watchExtraCost = watch('extraCost');

    const updateValues = () => {
        setBMEventHandler(PaPEvents.UPDATE_PAP_VALUES_BM, {
            offerEdition: {
                extra_cost: parseFloat(watchExtraCost),
            },
        });
    };

    useEffect(() => {
        if (state.selected.values.proposalReady && isDirty) {
            setBMEventHandler(PaPEvents.SET_PAP_PROPOSAL_READY, false);
        }
    }, [isDirty]); // eslint-disable-line

    const defaultHandler = () => {
        setValue('extraCost', 0, { shouldDirty: false });
        setBMEventHandler(PaPEvents.UPDATE_PAP_VALUES_BM, {
            offerEdition: {
                extra_cost: 0,
            },
        });
    };

    return (
        <div className="bm-inputs-extra">
            <Button
                variant="tertiary"
                icon="startRoundArrowReset"
                onClick={defaultHandler}
                disableRipple
                disabled={isLoadingWebStomp || !isDirty}
                dataTestId="default-reset"
                className="bm-inputs-extra-default-button"
            >
                <IntlMessages id="label.defaultReset" />
            </Button>
            <div className="d-flex align-items-end">
                <div className="bm-inputs-extra-input">
                    <label className="d-flex align-items-center">
                        <IntlMessages id="label.costAdjustment" />
                        <Tooltip title={<IntlMessages id="page.businessModels.costAdjustment.info" />} />
                    </label>
                    <Controller
                        name="extraCost"
                        control={control}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { ref, ...field } }) => {
                            return (
                                <NumberFormat
                                    {...field}
                                    placeholder={intlMessages('label.insertValue')}
                                    customInput={OutlinedInput}
                                    disabled={isLoadingWebStomp}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {/* @ts-ignore */}
                                            <CustomUnit unit={'currency'} />
                                        </InputAdornment>
                                    }
                                />
                            );
                        }}
                    />
                </div>
                <Button
                    variant="secondary"
                    onClick={updateValues}
                    disabled={isLoadingWebStomp || !isDirty}
                    loading={isLoadingWebStomp}
                    dataTestId="update-values"
                >
                    <IntlMessages id="label.updateValues" />
                </Button>
            </div>
        </div>
    );
};

export default PaPOfferDetailsInputs;
