import { Nullable } from 'types/utils';
import { grantCurrencyAmmount, grantPrcAmmount, updateTotalGrantsHandler } from './../../../services/businessModels/upfront';
import { GrantsAmmountsType } from 'interfaces/businessModels/upfront';
import { computeRubricCostsPayload, fetchBMPayload, offerCost, simulationCost } from './../../../services/businessModels/index';
import { TProductInputs } from './../../../interfaces/businessModels/index';
import { isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';
import { StatusCodes } from 'http-status-codes';
import { getBusinessModelInfo } from 'api/businessModels';
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { TUpFront, TUpFrontValues } from 'interfaces/businessModels/upfront';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { useContext, useCallback, useEffect, useMemo } from 'react';
import { IBusinessModel, IBusinessModelsContext } from 'interfaces/businessModels';
import { UpFrontEvents } from 'interfaces/businessModels/upfront';
import { BusinessModelsActions } from 'interfaces/businessModels/reducer';
import { INSTALLATION_PRICES_INPUT_NAMES, OFFER_DETAILS_INPUT_NAMES, OFFER_DETAILS_UPFRONT_CONFIGS } from 'constants/businessModelsPro';
import { PRODUCT_IDS } from 'constants/products';
import { INITIAL_EVENT } from 'constants/businessModelsPro/grants';
import { ReduxContext } from 'contexts/redux/reduxContext';
import { TUpFrontElements } from 'interfaces/businessModels/detailed';
import { CostsTypeOptions } from 'constants/products/spvCustomize';
import { getCompanyProfileIds } from 'services/user';
import { UserContext } from 'contexts/userContext';

const rubricCostOMDurationByMonth = (system, element) => {
    const costData = system?.raw_costs
        ?.filter((item) => item?.category_id === element?.categoria_custos_id)
        ?.reduce((accumulator, item) => accumulator + item?.cost, 0);

    return costData / 12; //by month
};

const getUpdateSystemCosts = ({ system, omContractCostSelected, category_id }) => {
    const rubrics = Object.keys(system?.costs).filter((key) => !['opex', 'total_costs', 'capex', 'extra_cost'].includes(key));
    // sum capex and opex by rubrics
    let final_capex = 0;
    let final_opex = 0;
    rubrics.forEach((rubric) => {
        const rubricData =
            system?.costs?.[rubric]?.details?.length > 0 ?
                system?.costs[rubric]?.details?.find((el) => el?.category_id === category_id)
            :   null;

        if (isFieldDefined(rubricData)) {
            system.costs[rubric].details = system?.costs?.[rubric]?.details?.map((el) => {
                if (el.category_id === category_id) {
                    const omCostByMonth = (el?.cost / 12) * omContractCostSelected;
                    el.total_costs = omCostByMonth;
                    el.final_cost = omCostByMonth;
                    el.cost = omCostByMonth;
                }
                return el;
            });
            // update opex
            system.costs[rubric].opex = 0;
            system.costs[rubric].details.forEach((el) => {
                if (el.cost_recurrence_id === CostsTypeOptions.OPEX) {
                    system.costs[rubric].opex += el.total_costs;
                }
            });
            system.costs[rubric].total_costs = system.costs[rubric].opex + system.costs[rubric].capex;
        }

        // update values  capex & opex by rubric
        final_capex += system.costs[rubric].capex;
        final_opex += system.costs[rubric].opex;
    });

    // update values
    system.costs.capex = final_capex;
    system.costs.opex = final_opex;
    system.costs.total_costs = final_capex + final_opex;

    return system?.costs;
};

const useUpfrontBM = (bm: IBusinessModel) => {
    const {
        client: { contacto_email: clientEmail, contacto_nome: clientName },
    } = useContext(ReduxContext);

    const { companyProfileId, userTypeID } = useContext(UserContext);

    const {
        state,
        productID,
        productPayload,
        bmEvent,
        dispatch,
        setBMEventHandler,
        bmSelected,
        setBmEvent,
        negotiationProfile,
        setIsRequestingBM,
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const simulation = productPayload?.productInputs?.simulation ?? null;

    const configOfferDetailsUpFront = useMemo(
        () =>
            OFFER_DETAILS_UPFRONT_CONFIGS?.[companyProfileId]?.[userTypeID]?.[productID] ??
            OFFER_DETAILS_UPFRONT_CONFIGS?.DEFAULT?.[userTypeID]?.[productID],
        [companyProfileId, userTypeID, productID]
    );

    const getEDPPTBaseCost = (duration: any = null) => {
        const annuity =
            {
                ...state?.simulation?.offerEdition?.cost?.annuity,
                inverters_warranty_options: state?.simulation?.offerEdition?.cost?.inverters_warranty_options,
            } ?? null;
        let otherTotal = 0;
        const newOffer = offerCost(productID, state?.simulation);
        const newDuration = isDefined(duration) ? duration : state?.simulation?.offerEdition?.duration;

        if (annuity) {
            otherTotal = Object.keys(annuity).reduce(
                (accumulator, currentProperty) => {
                    if (typeof annuity[currentProperty] === 'number') {
                        if (currentProperty !== 'maintenance')
                            return {
                                total:
                                    accumulator?.total -
                                    annuity?.[currentProperty] *
                                        (newDuration?.[currentProperty + '_years'].default -
                                            newDuration?.[currentProperty + '_years']?.included),
                            };
                        else if (currentProperty === 'maintenance') {
                            return {
                                total:
                                    accumulator?.total -
                                    annuity?.[currentProperty] *
                                        (newDuration?.[currentProperty + '_years']?.default -
                                            newDuration?.[currentProperty + '_years']?.included +
                                            1),
                            };
                        }
                    } else if (currentProperty === 'inverters_warranty_options' && isDefined(annuity?.[currentProperty])) {
                        return {
                            total:
                                accumulator?.total -
                                annuity?.[currentProperty]?.find(
                                    (option) => newDuration?.['inverters_warranty_years']?.default === option?.years_warranty
                                )?.cost,
                        };
                    }
                    return { total: accumulator?.total + 0 };
                },
                { total: state.simulation?.system?.costs?.total_costs }
            ).total;
        }

        if (isNaN(otherTotal)) otherTotal = simulationCost(productID, state?.simulation);
        const offer = Object.keys(newOffer ?? []).filter(
            (attr) => !['base', 'base_service'].includes(attr) && typeof newOffer?.[attr] === 'number'
        );
        return newOffer ?
                offer?.reduce(
                    (accumulator, currentProperty) => {
                        return { total: accumulator?.total - newOffer?.[currentProperty] };
                    },
                    {
                        total: otherTotal,
                    }
                ).total
            :   0;
    };

    function getEDPPTTotalCost(duration: any = null) {
        const annuity =
            {
                ...state?.simulation?.offerEdition?.cost?.annuity,
                inverters_warranty_options: state?.simulation?.offerEdition?.cost?.inverters_warranty_options,
            } ?? null;
        let otherTotal = 0;
        const newOffer = offerCost(productID, state?.simulation);
        const newDuration = isDefined(duration) ? duration : state?.simulation?.offerEdition?.duration;

        const isReformulation = false;

        if (annuity) {
            otherTotal = Object.keys(annuity ?? []).reduce(
                (accumulator, currentProperty) => {
                    if (typeof annuity[currentProperty] === 'number') {
                        return {
                            total:
                                accumulator?.total +
                                annuity?.[currentProperty] *
                                    (newDuration?.[currentProperty + '_years']?.default -
                                        (!isReformulation ? newDuration?.[currentProperty + '_years']?.included : 0)),
                        };
                    } else if (currentProperty === 'inverters_warranty_options' && isDefined(annuity[currentProperty])) {
                        return {
                            total:
                                accumulator?.total +
                                annuity[currentProperty].find(
                                    (option) => newDuration?.['inverters_warranty_years']?.default === option?.years_warranty
                                )?.cost -
                                (!isReformulation ?
                                    annuity?.[currentProperty]?.find(
                                        (option) => newDuration?.['inverters_warranty_years']?.included === option?.years_warranty
                                    )?.cost
                                :   0),
                        };
                    }
                    return { total: accumulator?.total + 0 };
                },
                { total: 0 }
            ).total;
        }

        if (isNaN(otherTotal)) otherTotal = 0;

        return newOffer ?
                Object.keys(newOffer)
                    .filter((attr) => attr !== 'base_service' && typeof newOffer[attr] === 'number')
                    .reduce(
                        (accumulator, currentProperty) => {
                            return { total: accumulator?.total + newOffer?.[currentProperty] };
                        },
                        { total: 0 }
                    ).total + otherTotal
            :   0;
    }

    const upFrontBM = async () => {
        const payload = {
            businessModelId: state.selected.id,
            productID,
            body: {
                ...fetchBMPayload(productID, productPayload, { companyProfileId }),
            },
        };
        const rspBM = await getBusinessModelInfo(payload);

        if (rspBM?.status === StatusCodes.OK) {
            const omContractCostDefault = 0;
            const negotiationElements: TUpFrontElements = {
                [OFFER_DETAILS_INPUT_NAMES.OM_DURATION]:
                    rspBM.data.data[0]?.financing_customization?.values?.find(
                        (element) => element?.tag === OFFER_DETAILS_INPUT_NAMES.OM_DURATION
                    ) ?? null,
                [OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD]:
                    rspBM.data.data[0]?.financing_customization?.values?.find(
                        (element) => element?.tag === OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD
                    ) ?? null,
            };

            const rubrics = state?.simulation?.rubricsCosts.find((rubric) => !rubric.isSync);
            let baseCost =
                rubrics?.rubrics?.length > 0 ?
                    state?.simulation?.system?.costs?.total_costs - rubrics?.defaultRubricCost
                :   simulationCost(productID, state?.simulation);

            if (isDefined(negotiationElements?.[OFFER_DETAILS_INPUT_NAMES.OM_DURATION])) {
                const paymentModeDefault = negotiationElements?.[OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD]?.options?.default;
                // @ts-ignore
                const omDurationsDefault = negotiationElements?.[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]!.options?.options?.find(
                    (option) => option?.input_fixed_text === paymentModeDefault
                )!.om_durations_default;
                // @ts-ignore
                const category_id = negotiationElements?.[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]?.options?.categoria_custos_id;

                const costDataCat = state?.simulation?.system?.raw_costs
                    ?.filter((item) => item?.category_id === category_id)
                    ?.reduce((accumulator, item) => accumulator + item.total_costs, 0);
                const costData = costDataCat;

                // @ts-ignore
                const omContractCostSelected =
                    omDurationsDefault === 0 ?
                        -costData /* by default all cost include 1 year se o default for diff, temos de fazer o ajuste no baseCost */
                    :   omDurationsDefault * (costData / 12);

                baseCost = baseCost + omContractCostSelected;
            }
            let totalCost =
                [PRODUCT_IDS.HP].includes(productID) ?
                    simulation?.financial?.cost?.total_cost
                :   state?.simulation?.system?.costs?.total_costs;

            if (
                // FIXME: HAMMER TIME!
                [getCompanyProfileIds().EDP_PT].includes(companyProfileId)
                // [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB].includes(productID)
            ) {
                baseCost = getEDPPTBaseCost();
                totalCost = getEDPPTTotalCost();
            }

            const { kpis_per_margin, margin_negotiation } = rspBM.data.data[0].kpis_previews[0];
            const kpisPerMarginSelected =
                kpis_per_margin?.length > 0 ?
                    kpis_per_margin?.find((el) => el?.[margin_negotiation?.search] === margin_negotiation?.default)
                :   bm?.kpis_previews?.[0]?.kpis_per_margin?.[0];

            const kpis: TUpFrontValues = {
                ...rspBM?.data?.data?.[0]?.kpis_previews[0],
                grants: {
                    hasGrants: rspBM?.data?.data?.[0]?.has_subsidy ?? false,
                    totalGrants: {
                        currency: 0,
                        prc: 0,
                    },
                    grants: [
                        {
                            id: 1,
                            description: '',
                            ammounts: {
                                prc: 0,
                                currency: 0,
                            },
                        },
                    ],
                },
                rubrics: {
                    values: rubrics?.rubrics,
                    cost: rubrics?.defaultRubricCost ?? 0,
                },
                baseCost,
                totalCost,
                duration: state?.simulation?.offerEdition?.duration ?? null,
                costs: {
                    baseCost,
                    offerDetailsCost: totalCost,
                    marginCost: kpisPerMarginSelected?.investment ?? kpisPerMarginSelected?.price,
                    totalCost,
                    omDurationCost: omContractCostDefault,
                },
                clientContactInfo:
                    negotiationProfile?.mostra_formulario && state?.selected?.options?.has_form ?
                        {
                            name: clientName,
                            email: clientEmail,
                        }
                    :   null,
                elements: negotiationElements,
            };
            setBMEventHandler(UpFrontEvents.SET_UPFRONT_BM, kpis);
        }
    };
    const upFrontBMUpdateCost = async (
        newTotalCost,
        {
            newRubrics,
            newRubricsCost,
            newOfferEdition,
            newInstallationPrices,
            omCost = null,
        }: {
            newRubrics: any;
            newRubricsCost: number;
            newOfferEdition: any;
            newInstallationPrices: any;
            omCost?: Nullable<{ old: number; new: number }>;
        }
    ) => {
        try {
            setIsRequestingBM(true);
            const payload = {
                businessModelId: state.selected.id,
                productID,
                body: {
                    ...fetchBMPayload(productID, { ...productPayload, ...newInstallationPrices }, { newTotalCost, companyProfileId }),
                },
            };
            const rspBM = await getBusinessModelInfo(payload);

            if (rspBM?.status === StatusCodes.OK) {
                dispatch({
                    type: BusinessModelsActions.SET_BUSINESS_MODEL_BY_NEW_COST,
                    payload: {
                        offerDetailsCost: newTotalCost,
                        options: rspBM?.data?.data?.[0],
                        newOfferEdition,
                        newRubrics,
                        newRubricsCost,
                        newInstallationPrices,
                        omCost,
                    },
                });
                setIsRequestingBM(false);
            }
        } catch (error) {
            console.log('efz-> ', error);
            // ADD sentry
        }
    };

    const upFrontEvents = useCallback(() => {
        if (state?.selected?.paymentModelID === PAYMENT_MODELS_IDS.UP_FRONT && isDefined(bmEvent?.name)) {
            // console.log("efz-> bmEvent?.name", bmEvent?.name)
            const currentValues = state?.selected?.values;
            const currentGrants = state?.selected?.values?.grants?.grants;
            switch (bmEvent?.name) {
                case UpFrontEvents.FETCH_UPFRONT_BM: {
                    upFrontBM();
                    break;
                }
                case UpFrontEvents.SET_UPFRONT_BM: {
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: bmEvent?.payload,
                    });
                    break;
                }
                case UpFrontEvents.SET_EXTRA_COST: {
                    let newOfferDetailsCost =
                        currentValues?.costs?.baseCost + parseFloat(bmEvent?.payload?.extra_cost) + state?.selected?.values?.rubrics?.cost;
                    if (
                        [getCompanyProfileIds().EDP_PT].includes(companyProfileId) &&
                        [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB].includes(productID)
                    ) {
                        // @ts-ignore
                        const edpPTRubricsCost =
                            Number(getEDPPTTotalCost(currentValues?.offerEdition?.duration ?? state?.simulation?.offerEdition?.duration)) -
                            Number(getEDPPTBaseCost());
                        newOfferDetailsCost = currentValues?.costs?.baseCost + parseFloat(bmEvent?.payload?.extra_cost) + edpPTRubricsCost;
                    }
                    let newInstallationPrices = {};
                    const newOfferEdition = {
                        extra_cost: parseFloat(bmEvent?.payload?.extra_cost),
                    };

                    if (
                        isFieldDefined(state?.selected?.values?.installation_fixed_price) ||
                        isFieldDefined(state?.selected?.values?.installation_labor_price)
                    ) {
                        newInstallationPrices = {
                            // @ts-ignore
                            installation_fixed_price: state?.selected?.values?.installation_fixed_price ?? null,
                            installation_labor_price: state?.selected?.values?.installation_labor_price ?? null,
                        };
                    }

                    upFrontBMUpdateCost(newOfferDetailsCost, {
                        newRubrics: [],
                        newRubricsCost: 0,
                        newOfferEdition,
                        newInstallationPrices,
                    });
                    break;
                }
                case UpFrontEvents.SET_OM_DURATION: {
                    const newOpAndManCost =
                        Number(bmEvent.payload?.[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]) *
                        rubricCostOMDurationByMonth(
                            state?.simulation?.system,
                            bmSelected.elements?.[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]?.options
                        );
                    const newBaseCost = currentValues?.costs?.baseCost + newOpAndManCost;

                    const newOfferDetailsCost =
                        newBaseCost +
                        (state?.selected?.values?.offerEdition?.extra_cost ?? 0) +
                        (state?.selected?.values?.rubrics?.cost ?? 0);
                    const newOfferEdition = {
                        [OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD]: bmEvent?.payload?.[OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD],
                        [OFFER_DETAILS_INPUT_NAMES.OM_DURATION]: Number(bmEvent?.payload?.[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]),
                    };

                    let newInstallationPrices = {};
                    if (
                        isFieldDefined(state?.selected?.values?.installation_fixed_price) ||
                        isFieldDefined(state?.selected?.values?.installation_labor_price)
                    ) {
                        newInstallationPrices = {
                            // @ts-ignore
                            installation_fixed_price: state?.selected?.values?.installation_fixed_price ?? null,
                            installation_labor_price: state?.selected?.values?.installation_labor_price ?? null,
                        };
                    }

                    upFrontBMUpdateCost(newOfferDetailsCost, {
                        newRubrics: [],
                        newRubricsCost: 0,
                        newOfferEdition,
                        newInstallationPrices,
                        omCost: { old: currentValues?.costs?.omDurationCost, new: newOpAndManCost },
                    });
                    break;
                }
                case UpFrontEvents.SET_INSTALLATION_FIXED_PRICE:
                case UpFrontEvents.SET_INSTALLATION_HOURS_AND_PRICE: {
                    const isInstallationFixedPrice = bmEvent?.name === UpFrontEvents.SET_INSTALLATION_FIXED_PRICE;

                    //Add to state and update costs (investment in margin and total) #1763
                    const offerDetailsCost = state?.selected?.values?.costs?.offerDetailsCost;
                    const newOfferEdition = {};
                    const newInstallationPrices = {
                        [INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_PRICE]:
                            !isInstallationFixedPrice ?
                                bmEvent?.payload?.[INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_PRICE] ?? 0
                            :   currentValues?.[INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_PRICE] ?? 0,
                        [INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_HOURS]:
                            !isInstallationFixedPrice ?
                                bmEvent?.payload?.[INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_HOURS] ?? 0
                            :   currentValues?.[INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_HOURS] ?? 0,
                        [INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_FIXED_PRICE]:
                            isInstallationFixedPrice ?
                                bmEvent?.payload?.[INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_FIXED_PRICE] ?? 0
                            :   currentValues?.[INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_FIXED_PRICE] ?? 0,
                    };
                    upFrontBMUpdateCost(offerDetailsCost, {
                        newRubrics: [],
                        newRubricsCost: 0,
                        newOfferEdition,
                        newInstallationPrices,
                    });

                    break;
                }

                case UpFrontEvents.INIT_UFRONT_RUBRIC_COST: {
                    const newRubrics = state?.selected?.values?.rubrics?.values?.map((rubric) => {
                        if (rubric?.cost_id === bmEvent?.payload?.id) {
                            rubric.included_cost = bmEvent?.payload?.selected;
                        }
                        return rubric;
                    });

                    const rubricsCost = newRubrics?.reduce((acc, curr) => {
                        if (curr?.included_cost) return acc + parseInt(curr?.final_cost);
                        else return acc;
                    }, 0);

                    const newOfferDetailsCost =
                        currentValues?.costs?.baseCost + (state.selected.values?.offerEdition?.extra_cost ?? 0) + rubricsCost;

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            rubrics: {
                                values: newRubrics,
                                cost: rubricsCost,
                            },
                            offerEdition: {
                                ...currentValues?.offerEdition,
                                costs: computeRubricCostsPayload(state?.simulation?.system?.costs, newRubrics),
                            },
                            costs: {
                                ...currentValues.costs,
                                offerDetailsCost: newOfferDetailsCost,
                            },
                        },
                    });

                    break;
                }
                case UpFrontEvents.SET_UFRONT_RUBRIC_COST: {
                    const extra_cost = state?.selected?.values?.offerEdition?.extra_cost;
                    const newRubrics = state?.selected?.values?.rubrics?.values?.map((rubric) => {
                        if (rubric?.cost_id === bmEvent?.payload?.id) {
                            rubric.included_cost = bmEvent?.payload?.selected;
                        }
                        return rubric;
                    });

                    const newRubricsCost = newRubrics?.reduce((acc, curr) => {
                        if (curr?.included_cost) return acc + parseInt(curr?.final_cost);
                        else return acc;
                    }, 0);

                    const newOfferDetailsCost = currentValues?.costs?.baseCost + (extra_cost ?? 0) + newRubricsCost;

                    const newOfferEdition = {
                        costs: computeRubricCostsPayload(state?.simulation?.system?.costs, newRubrics),
                        extra_cost,
                    };
                    const newInstallationPrices = {};
                    upFrontBMUpdateCost(newOfferDetailsCost, {
                        newRubrics,
                        newRubricsCost,
                        newOfferEdition,
                        newInstallationPrices,
                    });

                    break;
                }
                case UpFrontEvents.SET_UPFRONT_MARGIN: {
                    if (!isFieldDefined(currentValues)) break;

                    const search = bmSelected?.values?.margin_negotiation?.search ?? 'margin';

                    const kpisPerMarginSelected = bmSelected?.values?.kpis_per_margin?.find((el) => el?.[search] === bmEvent.payload);

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            marginSelected: bmEvent?.payload,
                            costs: {
                                ...currentValues?.costs,
                                marginCost: kpisPerMarginSelected?.investment ?? kpisPerMarginSelected?.price ?? bm,
                            },
                        },
                    });
                    break;
                }
                case UpFrontEvents.ADD_UPFRONT_GRANT: {
                    const higherGrantId =
                        currentGrants?.length > 0 ?
                            currentGrants?.reduce((acc, value) => {
                                return (acc = acc > value?.id ? acc : value?.id);
                            }, 0)
                        :   1;
                    const updatedGrants = [
                        ...currentGrants,
                        {
                            id: higherGrantId + 1,
                            description: '',
                            ammounts: {
                                prc: 0,
                                currency: 0,
                            },
                        },
                    ];
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state?.selected?.values?.grants,
                                grants: updatedGrants,
                            },
                        },
                    });
                    break;
                }
                case UpFrontEvents.DELETE_UPFRONT_GRANT: {
                    const grantsWithoutTheSelectedToDelete = currentGrants.filter((grant) => grant?.id !== bmEvent?.payload);

                    const updatedTotalGrantsWithoutTheSelectedToDelete = {
                        currency: grantsWithoutTheSelectedToDelete?.reduce(
                            (accumulator, currentValue) => accumulator + currentValue?.ammounts?.currency,
                            0
                        ),
                        prc: grantsWithoutTheSelectedToDelete?.reduce(
                            (accumulator, currentValue) => accumulator + currentValue?.ammounts?.prc,
                            0
                        ),
                    };

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state?.selected?.values?.grants,
                                totalGrants: updatedTotalGrantsWithoutTheSelectedToDelete,
                                grants: grantsWithoutTheSelectedToDelete,
                            },
                            costs: {
                                ...currentValues.costs,
                                grantsCost:
                                    (state?.selected?.values?.costs?.marginCost ?? 0) -
                                    updatedTotalGrantsWithoutTheSelectedToDelete?.currency,
                            },
                        },
                    });
                    break;
                }
                case UpFrontEvents.SET_DESCRIPTION_GRANT: {
                    const grantsWithUpdatedDescriptions = currentGrants.map((grant) => {
                        if (grant?.id === bmEvent?.payload?.id) grant.description = bmEvent?.payload?.description;
                        return grant;
                    });
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...state?.selected?.values?.grants,
                                grants: grantsWithUpdatedDescriptions,
                            },
                        },
                    });
                    break;
                }
                case UpFrontEvents.SET_AMMOUNT_GRANT: {
                    const currentInvestment = state?.selected?.values?.costs?.marginCost ?? 0;
                    const grantsWithUpdatedAmmounts = currentGrants.map((grant) => {
                        if (grant.id === bmEvent?.payload?.id)
                            grant.ammounts =
                                bmEvent?.payload?.ammountType === GrantsAmmountsType.CURRENCY ?
                                    grantCurrencyAmmount(currentInvestment, bmEvent?.payload?.amount)
                                :   grantPrcAmmount(currentInvestment, bmEvent?.payload?.amount);
                        return grant;
                    });

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...currentValues?.grants,
                                totalGrants: updateTotalGrantsHandler(grantsWithUpdatedAmmounts),
                                grants: grantsWithUpdatedAmmounts,
                            },
                        },
                    });

                    break;
                }
                case UpFrontEvents.UPDATE_UPFRONT_GRANTS: {
                    const currentInvestment = state?.selected?.values?.costs?.marginCost ?? 0;
                    const newCurrentGrants = [...currentGrants];
                    const updatedGrants = newCurrentGrants.map((grant) => {
                        if (!(grant?.ammounts?.fixed ?? false)) grant.ammounts = grantPrcAmmount(currentInvestment, grant?.ammounts?.prc);
                        else grant.ammounts = grantCurrencyAmmount(currentInvestment, grant?.ammounts?.currency);

                        return grant;
                    });

                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...currentValues?.grants,
                                totalGrants: updateTotalGrantsHandler(updatedGrants),
                                grants: updatedGrants,
                            },
                            costs: {
                                ...currentValues?.costs,
                                grantsCost:
                                    (state?.selected?.values?.costs?.marginCost ?? 0) - updateTotalGrantsHandler(updatedGrants)?.currency,
                            },
                        },
                    });
                    break;
                }
                case UpFrontEvents.UPDATE_ADDITIONAL_DATA: {
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            clientContactInfo: {
                                ...currentValues?.clientContactInfo,
                                ...bmEvent?.payload?.clientContactInfo,
                            },
                            id_crm: bmEvent?.payload?.id_crm ? bmEvent.payload?.id_crm : currentValues?.id_crm ?? null,
                        },
                    });
                    break;
                }
                case UpFrontEvents.SET_EDP_PT_INPUTS: {
                    const newDuration = bmEvent?.payload;
                    let newTotalCost = getEDPPTTotalCost(newDuration);
                    if (isDefined(currentValues?.offerEdition?.extra_cost)) newTotalCost += currentValues?.offerEdition?.extra_cost;

                    const newOfferEdition = {
                        duration: newDuration,
                    };

                    let newInstallationPrices = {};
                    if (
                        isFieldDefined(state?.selected?.values?.installation_fixed_price) ||
                        isFieldDefined(state?.selected?.values?.installation_labor_price)
                    ) {
                        newInstallationPrices = {
                            // @ts-ignore
                            installation_fixed_price: state?.selected?.values?.installation_fixed_price ?? null,
                            installation_labor_price: state?.selected?.values?.installation_labor_price ?? null,
                        };
                    }

                    upFrontBMUpdateCost(newTotalCost, {
                        newRubrics: [],
                        newRubricsCost: 0,
                        newOfferEdition,
                        newInstallationPrices,
                    });
                    break;
                }
                case UpFrontEvents.SET_UPFRONT_PROPOSAL_READY: {
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            grants: {
                                hasGrants: false,
                            },
                        },
                    });
                    break;
                }
                case UpFrontEvents.SET_UPFRONT_PROPOSAL: {
                    const marginNegotiationSearch = bmSelected?.values?.margin_negotiation?.search ?? 'margin';
                    const marginSelected = bmSelected?.values?.kpis_per_margin?.find(
                        (kpi) => kpi?.[marginNegotiationSearch] === bmSelected?.values?.marginSelected
                    )?.margin;
                    let currentSimulation = {
                        ...state?.simulation,
                        inputs: {
                            ...state?.simulation?.inputs,
                            margin: marginSelected ?? undefined,
                            id_crm: state?.selected?.values?.id_crm,
                        },
                        tipo_modelo_negocio_id: bmSelected?.id,
                        register_simulation: false,
                    };

                    if (bmSelected.values?.grants?.totalGrants?.currency > 0) {
                        currentSimulation = {
                            ...currentSimulation,
                            inputs: {
                                ...currentSimulation?.inputs,
                                grants: bmSelected.values.grants.grants.map((grant) => {
                                    return {
                                        description: grant?.description,
                                        prc: grant?.ammounts?.prc / 100,
                                    };
                                }),
                            },
                        };
                    }

                    if (isFieldDefined(bmSelected.values?.offerEdition)) {
                        currentSimulation = {
                            ...currentSimulation,
                            inputs: {
                                ...currentSimulation.inputs,
                                offer_edition: {
                                    ...currentSimulation?.inputs?.offer_edition,
                                    ...bmSelected?.values?.offerEdition,
                                },
                            },
                        };
                    }

                    // @ts-ignore
                    if (isFieldDefined(bmSelected.values.offerEdition?.om_contract_duration)) {
                        currentSimulation = {
                            ...currentSimulation,
                            inputs: {
                                ...currentSimulation?.inputs,
                                // @ts-ignore
                                ...bmSelected?.values?.offerEdition,
                                offer_edition: {
                                    ...currentSimulation?.inputs?.offer_edition,
                                    costs: getUpdateSystemCosts({
                                        system: currentSimulation?.system,
                                        // @ts-ignore
                                        omContractCostSelected: bmSelected?.values?.offerEdition?.om_contract_duration,
                                        category_id:
                                            /* @ts-ignore */ state?.selected?.values?.elements?.[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]
                                                ?.options?.categoria_custos_id,
                                    }),
                                },
                            },
                        };
                    }

                    // @ts-ignore
                    if (isFieldDefined(bmSelected.values.duration)) {
                        let proposalDurations = {};
                        // @ts-ignore
                        const durationsArray = Object.entries(
                            currentValues?.offerEdition?.duration ?? state?.simulation?.offerEdition?.duration
                        );
                        durationsArray.forEach((dur: any) => {
                            proposalDurations = { ...proposalDurations, [dur?.[0]]: dur?.[1]?.default };
                        });

                        currentSimulation = {
                            ...currentSimulation,
                            inputs: {
                                ...currentSimulation?.inputs,
                                offer_edition: {
                                    ...currentSimulation?.inputs?.offer_edition,
                                    // @ts-ignore
                                    duration: proposalDurations,
                                },
                            },
                        };
                    }

                    if (isDefined(currentSimulation.fromSpvV3))
                        currentSimulation.inputs.fe_version = currentSimulation?.fromSpvV3 ? 'v3' : 'v2';

                    dispatch({
                        type: BusinessModelsActions.SET_PROPOSAL,
                        payload: currentSimulation,
                    });
                    break;
                }
            }
            setBmEvent(INITIAL_EVENT);
        }
    }, [bmEvent]); // eslint-disable-line

    useEffect(() => {
        upFrontEvents();
    }, [upFrontEvents]);

    useEffect(() => {
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.UP_FRONT) {
            if (state.selected.options?.margin_negotiable) setBMEventHandler(UpFrontEvents.FETCH_UPFRONT_BM, null);
            else setBMEventHandler(UpFrontEvents.SET_UPFRONT_PROPOSAL_READY, null);
        }
    }, [state.selected.paymentModelID]); // eslint-disable-line

    return {
        pitch: bm?.pitch,
        projectSummary: bm?.kpis_previews,
        selected: bm?.selected,
        id: bm?.business_model_id,
        paymentModelID: bm?.payment_model_id,
        configOfferDetailsUpFront,
    };
};

export default useUpfrontBM;
