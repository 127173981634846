import { createContext, useState, useReducer, useContext, useMemo } from 'react';
import clone from 'fast-copy';
import { useSelector } from 'react-redux';
import { bool, func, number, string } from 'prop-types';

// Selectors
import { getClientID } from 'redux/selectors/client';
import { getFacilityID } from 'redux/selectors/facility';
import { getLocaleCode } from 'redux/selectors/settings';

// Services
import { facilityDataReducer, initialFacilityDataReducer } from './facilityDataReducer';
import { initialSupplyTariffsDataReducer, supplyTariffsDataReducer } from './supplyTariffsDataReducer';
import { gasDataReducer, initialGasDataReducer } from './gasDataReducer';
import { initialOtherDataReducer, otherDataReducer } from './otherDataReducer';
import { initialLoadProfileDataReducer, loadProfileDataReducer } from './loadDataReducer';
import { GRAPH_INPUTS_DEFAULTS } from 'constants/graphs';
import { UserContext } from 'contexts/userContext';
import useFacilityMissingInfo from 'hooks/facilityPro/useFacilityMissingInfo';
import { useUserStore } from 'store/user';
import { AVAILABLE_USER_LOCALES } from 'constants/user';

const FacilityProContext = createContext({
    // reducers
    facilityDataState: initialFacilityDataReducer,
    facilityDataDispatchHandler: (_type, _payload) => {},
    supplyTariffsDataState: initialSupplyTariffsDataReducer,
    supplyTariffsDataDispatchHandler: (_type, _payload) => {},
    gasDataState: initialGasDataReducer,
    gasDataDispatchHandler: (_type, _payload) => {},
    otherDataState: initialOtherDataReducer,
    otherDataDispatchHandler: (_type, _payload) => {},
    loadProfileDataState: initialLoadProfileDataReducer,
    loadProfileDataDispatchHandler: (_type, _payload) => {},
    selectGraphOptions: {},
    setSelectGraphOptions: () => {},
    markerLocation: {
        lat: 0,
        lng: 0,
        address: '',
        description: '', //for input autocomplete: '',
        postal_code: '',
        city: '',
        county: '',
        county_abrev: '',
        country: '',
    },
    setMarkerLocation: () => {},
    isB2C: bool,
    is_efz: bool,
    isDemo: bool,
    isMounting: bool,
    setIsMouting: func,
    facilityId: number,
    companyProfileId: number,
    userTypeID: number,
    userLocale: string,
    settingsUserLocale: string,
    userChannelIDGroup: string,
    updateClientData: func,
    localeCode: string,
    dispatchFacilityPro: func,
    forwardFormID: string,
});
FacilityProContext.displayName = 'efz-facilityPro';

const FacilityProProvider = ({ children }) => {
    const { isB2C, is_efz, isDemo, companyProfileId, userTypeID, userChannelIDGroup, companyName } = useContext(UserContext);
    const { user, locale } = useUserStore();

    // useReducer
    const [facilityDataState, facilityDataDispatch] = useReducer(facilityDataReducer, initialFacilityDataReducer);
    const [supplyTariffsDataState, supplyTariffsDataDispatch] = useReducer(supplyTariffsDataReducer, initialSupplyTariffsDataReducer);
    const [gasDataState, gasDataDispatch] = useReducer(gasDataReducer, initialGasDataReducer);
    const [otherDataState, otherDataDispatch] = useReducer(otherDataReducer, initialOtherDataReducer);
    const [loadProfileDataState, loadProfileDataDispatch] = useReducer(loadProfileDataReducer, initialLoadProfileDataReducer);
    const [selectGraphOptions, setSelectGraphOptions] = useState(clone(GRAPH_INPUTS_DEFAULTS));

    const [markerLocation, setMarkerLocation] = useState({
        lat: null,
        lng: null,
        address: '',
        description: '', //for input autocomplete: '',
        postal_code: '',
        city: '',
        county: '',
        county_abrev: '',
        country: '',
    });

    const [isMounting, setIsMounting] = useState(true);
    const [isRequestingAddFacility, setIsRequestingAddFacility] = useState(false);
    const facilityId = useSelector((state) => getFacilityID(state?.facility));
    const clientID = useSelector((state) => getClientID(state?.client) ?? null);
    const localeCode = useSelector((state) => getLocaleCode(state?.settings) ?? null);
    const userCompanyName = companyName;

    const allSectionsMounted = useMemo(
        () =>
            !facilityDataState?.isMounting &&
            !supplyTariffsDataState?.isMounting &&
            !gasDataState?.isMounting &&
            !otherDataState?.isMounting,
        [facilityDataState?.isMounting, gasDataState?.isMounting, otherDataState?.isMounting, supplyTariffsDataState?.isMounting]
    );
    const { missingInfo } = useFacilityMissingInfo(allSectionsMounted);

    const facilityDataDispatchHandler = (type, payload) => {
        facilityDataDispatch({
            type: type,
            payload: payload,
        });
    };

    const supplyTariffsDataDispatchHandler = (type, payload) => {
        supplyTariffsDataDispatch({
            type: type,
            payload: payload,
        });
    };

    const gasDataDispatchHandler = (type, payload) => {
        gasDataDispatch({
            type: type,
            payload: payload,
        });
    };

    const otherDataDispatchHandler = (type, payload) => {
        otherDataDispatch({
            type: type,
            payload: payload,
        });
    };

    const loadProfileDataDispatchHandler = (type, payload) => {
        loadProfileDataDispatch({
            type: type,
            payload: payload,
        });
    };

    return (
        <FacilityProContext.Provider
            value={{
                //#region facilityData
                facilityDataState,
                facilityDataDispatchHandler,
                markerLocation,
                setMarkerLocation,
                //#endregion facilityData

                //#region supplyTariffsData
                supplyTariffsDataState,
                supplyTariffsDataDispatchHandler,
                //#endregion supplyTariffsData

                //#region gasData
                gasDataState,
                gasDataDispatchHandler,
                //#endregion gasData

                //#region gasData
                otherDataState,
                otherDataDispatchHandler,
                //#endregion gasData

                //#region loadProfileData
                loadProfileDataState,
                loadProfileDataDispatchHandler,
                selectGraphOptions,
                setSelectGraphOptions,
                //#endregion loadProfileData

                //#region common
                isB2C,
                is_efz,
                isDemo,
                userCompanyName,
                userLocale: user?.locale ?? AVAILABLE_USER_LOCALES.enGB,
                appLocale: locale?.appLocale ?? AVAILABLE_USER_LOCALES.enGB,
                isMounting,
                setIsMounting,
                isRequestingAddFacility,
                isInputsDisabled: isRequestingAddFacility,
                setIsRequestingAddFacility,
                facilityId,
                forwardFormID: 'facility-pro-form',
                companyProfileId,
                userTypeID,
                userChannelIDGroup,
                clientID,
                localeCode,
                missingInfo,
                //#endregion common
            }}
        >
            {children}
        </FacilityProContext.Provider>
    );
};

export { FacilityProContext, FacilityProProvider };
