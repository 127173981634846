// Constants
import IntlMessages from 'components/util/IntlMessages';
import { USERS_TYPE_ID } from 'constants/user';
import { getCompanyProfileIds } from 'services/user';

// Services
import { intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';
import validate from 'services/util/validate';
export const AVAILABLE_LINKS_CAE = {
    [getCompanyProfileIds().SUNSEAP]: 'https://www.singstat.gov.sg/standards/standards-and-classifications/ssic',
    [getCompanyProfileIds().ROMANDE]: 'https://business-monitor.ch/fr',
};

export const DEFAULT_CONSUMPTION_WEIGHTS = {
    1: {
        //jan
        inputName: 'jan',
        label: 'label.jan',
        month: 1,
        weight: 0,
        unit: 'mwh',
    },
    2: {
        //feb
        inputName: 'feb',
        label: 'label.feb',
        month: 2,
        weight: 0,
        unit: 'mwh',
    },
    3: {
        //mar
        inputName: 'mar',
        label: 'label.mar',
        month: 3,
        weight: 0,
        unit: 'mwh',
    },
    4: {
        //apr
        inputName: 'apr',
        label: 'label.apr',
        month: 4,
        weight: 0,
        unit: 'mwh',
    },
    5: {
        //may
        inputName: 'may',
        label: 'label.may',
        month: 5,
        weight: 0,
        unit: 'mwh',
    },
    6: {
        //jun
        inputName: 'jun',
        label: 'label.jun',
        month: 6,
        weight: 0,
        unit: 'mwh',
    },
    7: {
        //jul
        inputName: 'jul',
        label: 'label.jul',
        month: 7,
        weight: 0,
        unit: 'mwh',
    },
    8: {
        //aug
        inputName: 'aug',
        label: 'label.aug',
        month: 8,
        weight: 0,
        unit: 'mwh',
    },
    9: {
        //sep
        inputName: 'sep',
        label: 'label.sep',
        month: 9,
        weight: 0,
        unit: 'mwh',
    },
    10: {
        //oct
        inputName: 'oct',
        label: 'label.oct',
        month: 10,
        weight: 0,
        unit: 'mwh',
    },
    11: {
        //nov
        inputName: 'nov',
        label: 'label.nov',
        month: 11,
        weight: 0,
        unit: 'mwh',
    },
    12: {
        //dec
        inputName: 'dec',
        label: 'label.dec',
        month: 12,
        weight: 0,
        unit: 'mwh',
    },
};

export const MONTHS = [
    {
        month: 1,
        label: 'label.jan',
        value: 'jan',
    },
    {
        month: 2,
        label: 'label.feb',
        value: 'feb',
    },
    {
        month: 3,
        label: 'label.mar',
        value: 'mar',
    },
    {
        month: 4,
        label: 'label.apr',
        value: 'apr',
    },
    {
        month: 5,
        label: 'label.may',
        value: 'may',
    },
    {
        month: 6,
        label: 'label.jun',
        value: 'jun',
    },
    {
        month: 7,
        label: 'label.jul',
        value: 'jul',
    },
    {
        month: 8,
        label: 'label.aug',
        value: 'aug',
    },
    {
        month: 9,
        label: 'label.sep',
        value: 'sep',
    },
    {
        month: 10,
        label: 'label.oct',
        value: 'oct',
    },
    {
        month: 11,
        label: 'label.nov',
        value: 'nov',
    },
    {
        month: 12,
        label: 'label.dec',
        value: 'dec',
    },
];

export const ANNUAL_CONSUMPTION_LIMITS_B2B = {
    //Max Included and Min Not Included
    max: 1000000,
    min: 0,
};

export const ANNUAL_CONSUMPTION_LIMITS_B2C = {
    //Max Included and Min Not Included
    max: 1000000,
    min: 1,
};

export const CAE_LENGTH_LIMITS = {
    //Max and Min Included
    DEFAULT: { max: 8, min: 2 },
};

export const DEFAULT_DATA_TABLE = [
    { month: 1, label: 'label.jan' },
    { month: 2, label: 'label.feb' },
    { month: 3, label: 'label.mar' },
    { month: 4, label: 'label.apr' },
    { month: 5, label: 'label.may' },
    { month: 6, label: 'label.jun' },
    { month: 7, label: 'label.jul' },
    { month: 8, label: 'label.aug' },
    { month: 9, label: 'label.sep' },
    { month: 10, label: 'label.oct' },
    { month: 11, label: 'label.nov' },
    { month: 12, label: 'label.dec' },
];

export const TARIFF_PERIODS_TABLE_COLUMNS_DEFAULT = [
    {
        id: 'mothts',
        Header: '',
        accessor: (row) => (
            <div className="d-flex w-100 col-mothts">
                <IntlMessages id={row.label} />
            </div>
        ),
    },
    {
        id: 'total',
        Header: <IntlMessages id={`label.total`} />,
        accessor: 'total_mwh',
    },
];

export const OPTS_LOAD_CURVE_B2B = {
    INTEGRATION_DATA: 1,
    SIMPLE_ESTIMATION: 2,
    DETAILED_ESTIMATION: 3,
    WORKING_SCHEDULE: 4,
    UPLOAD_CURVE: 5,
};

export const SIMPLE_ESTIMATION_IDS = {
    ACTIVITY_CODE: 1,
    CUSTOM: 2,
    CUSTOM_PERIOD: 3,
};
export const INTEGRATION_DATA_VALUES = {
    OBTAINED_INTEGRATION: false,
    CUSTOM: true,
};

export const INPUT_NAMES_LPDATA = {
    ID: 'facilityID',
    ACTIVITY_CODE: 'activity_code',
    CONSUMO_ANUAL: 'consumo_anual',
    CONSUMPTION_WEIGHTS: 'consumption_weights',
    DAYS_OF_WORK: 'days_of_work',
    HORARIO_DIAS_SEMANA: 'horario_dias_semana',
    HORARIO_DOMINGO_E_FERIADO: 'horario_domingo_e_feriado',
    HORARIO_SABADO: 'horario_sabado',
    HORARIO_TEM_IMPACTO: 'horario_tem_impacto',
    OPCAO_CURVA_CARGA_ID: 'opcao_curva_carga_id',
    OPCAO_CURVA_CARGA_SELECIONADA: 'opcao_curva_carga_seleccionada',
    SIMPLE_ESTIMATION_ID: 'simple_estimation_id', //local
    TEM_DADOS_PONDERADOR_ESTIMATIVA: 'tem_dados_ponderador_estimativa_simples',
    TEM_DADOS_PONDERADOR_ESTIMATIVA_SIMPLES_POR_PERIODO: 'tem_dados_ponderador_estimativa_simples_por_periodo',
    TEM_DADOS_PONDERADOR_INTEGRACAO: 'tem_dados_ponderador_integracao_personalizada',
    TEM_TELECONTAGEM: 'tem_telecontagem',
    ADJUST_WORK_DAYS: 'adjust_work_days',
    TRABALHA_2A: 'trabalha_2a',
    TRABALHA_3A: 'trabalha_3a',
    TRABALHA_4A: 'trabalha_4a',
    TRABALHA_5A: 'trabalha_5a',
    TRABALHA_6A: 'trabalha_6a',
    TRABALHA_DOM: 'trabalha_dom',
    TRABALHA_FERIADO: 'trabalha_feriado',
    TRABALHA_SAB: 'trabalha_sab',
    WORKING_DAYS: 'working_days',
    NOME_FICHEIRO_CURVA_CARGA: 'nome_ficheiro_curva_carga',
    //#region B2C
    INPUTS_CONSUMO: 'inputs_consumo',
    EQUIPMENTS: 'equipments',
    WORK_DAYS_OCCUPIED: 'work_days_occupied',
    WORK_DAYS_OCCUPATION_PERIOD_ID: 'work_days_occupation_period_id',
    WORK_DAYS_FIRST_ARRIVALS_ID: 'work_days_first_arrivals_id',
    WEEKENDS_OCCUPIED: 'weekends_occupied',
    WEEKEND_OCCUPATION_ID: 'weekend_occupation_id',
    WEEKENDS_FIRST_ARRIVALS_ID: 'weekends_first_arrivals_id',
    //#endregionB2C
};

export const QUESTIONS_LPDATA_B2B = {
    [INPUT_NAMES_LPDATA.OPCAO_CURVA_CARGA_ID]: {
        name: INPUT_NAMES_LPDATA.OPCAO_CURVA_CARGA_ID,
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        options: [
            {
                value: OPTS_LOAD_CURVE_B2B.INTEGRATION_DATA,
                description: 'label.integrationData',
                subtitle: 'label.integrationData.subtitle',
                disabled: false,
                visible: false,
                options: [
                    {
                        value: INTEGRATION_DATA_VALUES.OBTAINED_INTEGRATION,
                        description: 'page.facility.tem_dados_ponderador_integracao_personalizada.false',
                        tooltip: null,
                        visible: true,
                        disabled: false,
                    },
                    {
                        value: INTEGRATION_DATA_VALUES.CUSTOM,
                        description: 'page.facility.tem_dados_ponderador_integracao_personalizada.true',
                        tooltip: null,
                        visible: true,
                        disabled: false,
                    },
                ],
            },
            {
                value: OPTS_LOAD_CURVE_B2B.SIMPLE_ESTIMATION,
                description: 'label.simpleEstimation',
                subtitle: 'label.simpleEstimation.subtitle',
                disabled: false,
                visible: true,
                options: [
                    {
                        id: SIMPLE_ESTIMATION_IDS.ACTIVITY_CODE,
                        description: 'page.facility.tem_dados_ponderador_estimativa_simples.byActivityCode',
                        tooltip: 'label.activityCode',
                        visible: true,
                        disabled: false,
                        rules: {
                            tem_dados_ponderador_estimativa_simples: false,
                            tem_dados_ponderador_estimativa_simples_por_periodo: false,
                        },
                    },
                    {
                        id: SIMPLE_ESTIMATION_IDS.CUSTOM,
                        description: 'page.facility.tem_dados_ponderador_estimativa_simples.byCustom',
                        tooltip: null,
                        visible: true,
                        disabled: false,
                        rules: {
                            tem_dados_ponderador_estimativa_simples: true,
                            tem_dados_ponderador_estimativa_simples_por_periodo: false,
                        },
                    },
                    {
                        id: SIMPLE_ESTIMATION_IDS.CUSTOM_PERIOD,
                        description: 'page.facility.tem_dados_ponderador_estimativa_simples.byCustomPeriod',
                        tooltip: null,
                        visible: false,
                        disabled: false,
                        rules: {
                            tem_dados_ponderador_estimativa_simples: false,
                            tem_dados_ponderador_estimativa_simples_por_periodo: true,
                        },
                    },
                ],
            },
            {
                value: OPTS_LOAD_CURVE_B2B.DETAILED_ESTIMATION,
                description: 'label.detailedEstimation',
                subtitle: 'label.detailedEstimation.subtitle',
                disabled: true,
                visible: false,
            },
            {
                value: OPTS_LOAD_CURVE_B2B.WORKING_SCHEDULE,
                description: 'label.workingSchedule',
                subtitle: 'label.workingSchedule.subtitle',
                disabled: false,
                visible: true,
            },
            {
                value: OPTS_LOAD_CURVE_B2B.UPLOAD_CURVE,
                description: 'label.uploadLoadCurve',
                subtitle: 'label.uploadLoadCurve.subtitle',
                disabled: false,
                visible: true,
            },
        ],
        type: 'radio',
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_LPDATA.CONSUMO_ANUAL]: {
        name: INPUT_NAMES_LPDATA.CONSUMO_ANUAL,
        label: 'question.label.annualConsumption',
        placeholder: 'question.placeholder.annualConsumption',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'number',
        validation: validate(['required', 'number', 'positive', 'max', 'minNotIncluded'], ANNUAL_CONSUMPTION_LIMITS_B2B),
        isRequired: true,
        unit: 'mwh',
    },
    //loadSection - Section Simple Estimation
    [INPUT_NAMES_LPDATA.NOME_FICHEIRO_CURVA_CARGA]: {
        name: INPUT_NAMES_LPDATA.NOME_FICHEIRO_CURVA_CARGA,
        label: null,
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'number',
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_LPDATA.ACTIVITY_CODE]: {
        name: INPUT_NAMES_LPDATA.ACTIVITY_CODE,
        label: 'label.activityCode',
        placeholder: 'question.placeholder.activity_code',
        tooltip: 'label.extraInformation',
        tooltipLink: '#',
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'string',
        validation: validate(['required', 'minLength', 'maxLength'], {
            minLength: CAE_LENGTH_LIMITS.DEFAULT.min,
            maxLength: CAE_LENGTH_LIMITS.DEFAULT.max,
        }),
        isRequired: false,
    },
    [INPUT_NAMES_LPDATA.DAYS_OF_WORK]: {
        name: INPUT_NAMES_LPDATA.DAYS_OF_WORK,
        label: 'question.label.workingDays',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'checkbox',
        options: [
            { inputName: INPUT_NAMES_LPDATA.WORKING_DAYS, label: 'label.workingDays' },
            { inputName: INPUT_NAMES_LPDATA.TRABALHA_SAB, label: 'label.dayWeek.sat' },
            { inputName: INPUT_NAMES_LPDATA.TRABALHA_DOM, label: 'label.dayWeek.sun' },
            { inputName: INPUT_NAMES_LPDATA.TRABALHA_FERIADO, label: 'label.holidays' },
        ],
        validation: null,
        isRequired: true,
        conditionToShowDaysOfWord: {
            [OPTS_LOAD_CURVE_B2B.INTEGRATION_DATA]: {
                [getCompanyProfileIds().EDP_PT]: {
                    rules: {
                        tem_telecontagem: false,
                        adjust_work_days: true,
                        userTypeId: USERS_TYPE_ID.MANAGER,
                    },
                    visible: false,
                },
                [getCompanyProfileIds().EDP_ES]: {
                    rules: {
                        tem_telecontagem: false,
                        adjust_work_days: true,
                    },
                    visible: false,
                },
            },
        },
    },
    [INPUT_NAMES_LPDATA.HORARIO_DIAS_SEMANA]: {
        name: INPUT_NAMES_LPDATA.HORARIO_DIAS_SEMANA,
        label: 'question.label.horario_dias_semana',
        placeholder: 'question.placeholder.horario_dias_semana',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'schedule',
        validation: validate(['required', 'schedule']),
        isRequired: true,
    },
    [INPUT_NAMES_LPDATA.HORARIO_SABADO]: {
        name: INPUT_NAMES_LPDATA.HORARIO_SABADO,
        label: 'question.label.horario_sabado',
        placeholder: 'question.placeholder.horario_sabado',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'schedule',
        validation: validate(['required', 'schedule']),
        isRequired: true,
    },
    [INPUT_NAMES_LPDATA.HORARIO_DOMINGO_E_FERIADO]: {
        name: INPUT_NAMES_LPDATA.HORARIO_DOMINGO_E_FERIADO,
        label: 'question.label.horario_domingo_e_feriado',
        placeholder: 'question.placeholder.horario_domingo_e_feriado',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'schedule',
        validation: validate(['required', 'schedule']),
        isRequired: true,
    },
};

export const CHART_CONSUMPTION_DAILY_DEFAULTS = {
    loadingConsumption: true,
    type: 'Line',
    data: {
        labels: [],
        datasets: [
            {
                data: [],
                fill: false,
                hidden: false,
                borderColor: 'rgba(0, 90, 139, 1)',
                backgroundColor: 'rgba(0, 90, 139, 1)',
                borderWidth: 2,
                label: intlMessages('label.consumption'),
                keyword: 'label.consumption',
            },
            {
                data: [],
                fill: 2,
                hidden: false,
                borderColor: 'rgba(0, 90, 139, 0)',
                backgroundColor: '#d4ecff',
                label: intlMessages('label.maximumConsumption'),
                keyword: 'label.maximumConsumption',
            },
            {
                data: [],
                fill: false,
                hidden: false,
                borderColor: 'rgba(0, 90, 139, 0)',
                label: intlMessages('label.minimumConsumption'),
                keyword: 'label.minimumConsumption',
            },
        ],
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 0,
            },
            line: {
                tension: 0,
            },
        },
        legend: {
            position: 'top',
            display: true,
            align: 'end',
            onClick: null,
            labels: {
                padding: 10,
                usePointStyle: true,
                boxWidth: 5,
                filter: function (legendItem) {
                    return legendItem?.datasetIndex === 0 || legendItem?.datasetIndex === 3;
                },
            },
        },
        hover: {
            onHover: function (e) {
                let point = this.getElementAtEvent(e);
                if (point.length) e.target.style.cursor = 'pointer';
                else e.target.style.cursor = 'default';
            },
        },
        scales: {
            yAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.optimalPower_kW'),
                        keyword: 'label.optimalPower_kW',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        suggestedMin: 0,
                        padding: 5,
                        fontSize: 10,
                        maxTicksLimit: 8,
                    },
                },
            ],
            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: intlMessages('label.hour'),
                        keyword: 'label.hour',
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        suggestedMin: 0,
                        padding: 5,
                        fontSize: 10,
                    },
                    scaleFontSize: 5,
                },
            ],
        },
        tooltips: {
            enabled: false,
        },
        plugins: {
            filler: {
                propagate: true,
            },
        },
    },
    plugins: [
        {
            beforeUpdate: (chart) => {
                let isHidden = !!chart.getDatasetMeta(0).hidden;
                chart.getDatasetMeta(1).hidden = isHidden;
                chart.getDatasetMeta(2).hidden = isHidden;
            },
            beforeInit: function (chart) {
                // Adds margin between legend and chart
                chart.legend.afterFit = function () {
                    this.height += 5; // must use `function` and not => because of `this`
                };
            },
        },
    ],
};

export const CHART_CONSUMPTION_MONTHLY_DEFAULTS = {
    loadingConsumption: true,
    type: 'horizontalBar',
    data: {
        datasets: [
            {
                data: [],
                fill: false,
                hidden: false,
                borderColor: 'rgba(0, 90, 139, 1)',
                backgroundColor: 'rgba(0, 90, 139, 1)',
                borderWidth: 2,
                label: intlMessages('label.consumptionMWh'),
                keyword: 'label.consumptionMWh',
            },
        ],
        labels: [],
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            onClick: null,
            display: false,
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let isMWh = isFieldDefined(data?.datasets?.[0]?.isMWh) ? data?.datasets?.[0]?.isMWh : true;
                    return intlMessages('label.consumption') + ': ' + tooltipItem.xLabel + ` ${isMWh ? 'MWh' : 'kWh'}`;
                },
            },
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        drawOnChartArea: false,
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        drawOnChartArea: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        override: true,
                        callback: function (value) {
                            return value;
                        },
                    },
                    scaleLabel: {
                        display: true,
                        keyword: 'label.consumptionkWh',
                        labelString: intlMessages('label.consumptionkWh'),
                    },
                },
            ],
        },
    },
};

//#region /** B2C **/
export const OPTS_LOAD_CURVE_B2C = {
    SIMPLE_ESTIMATION: 6,
    CUSTOMIZED_CURVES: 99999999, //TODO: não esta definido
};

export const QUESTIONS_LPDATA_B2C = {
    [INPUT_NAMES_LPDATA.OPCAO_CURVA_CARGA_ID]: {
        name: INPUT_NAMES_LPDATA.OPCAO_CURVA_CARGA_ID,
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        options: [
            {
                value: OPTS_LOAD_CURVE_B2C.SIMPLE_ESTIMATION,
                description: 'label.simpleEstimation',
                info: 'label.simpleEstimation.subtitle',
                disabled: false,
                visible: true,
                gAnalyticsEvt: null,
            },
            {
                value: OPTS_LOAD_CURVE_B2C.CUSTOMIZED_CURVES,
                description: 'label.customizedCurves',
                info: null,
                disabled: true,
                visible: true,
                gAnalyticsEvt: null,
            },
        ],
        type: 'input',
        step: 0.01,
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_LPDATA.CONSUMO_ANUAL]: {
        name: INPUT_NAMES_LPDATA.CONSUMO_ANUAL,
        label: 'question.label.annualConsumption',
        placeholder: 'question.placeholder.annualConsumption',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'number',
        validation: validate(['required', 'number', 'positive', 'max', 'min'], ANNUAL_CONSUMPTION_LIMITS_B2C),
        isRequired: true,
    },
    [INPUT_NAMES_LPDATA.WORK_DAYS_OCCUPIED]: {
        name: INPUT_NAMES_LPDATA.WORK_DAYS_OCCUPIED,
        label: `label.${INPUT_NAMES_LPDATA.WORK_DAYS_OCCUPIED}`,
        placeholder: 'question.placeholder.subscribedPower',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        options: null,
        answers: [
            { label: 'label.yes', value: true },
            { label: 'label.no', value: false },
        ],
        type: 'input',
        step: 0.01,
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_LPDATA.WORK_DAYS_OCCUPATION_PERIOD_ID]: {
        name: INPUT_NAMES_LPDATA.WORK_DAYS_OCCUPATION_PERIOD_ID,
        label: `label.${INPUT_NAMES_LPDATA.WORK_DAYS_OCCUPATION_PERIOD_ID}`,
        tooltip: `page.spv.question.${INPUT_NAMES_LPDATA.WORK_DAYS_OCCUPATION_PERIOD_ID}.tooltip`,
        disabled: false,
        answers: null,
        visible: true,
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_LPDATA.WORK_DAYS_FIRST_ARRIVALS_ID]: {
        name: INPUT_NAMES_LPDATA.WORK_DAYS_FIRST_ARRIVALS_ID,
        label: `label.${INPUT_NAMES_LPDATA.WORK_DAYS_FIRST_ARRIVALS_ID}`,
        tooltip: `page.spv.question.${INPUT_NAMES_LPDATA.WORK_DAYS_FIRST_ARRIVALS_ID}.tooltip`,
        disabled: false,
        visible: true,
        validation: validate(['required']),
        isRequired: true,
    },

    [INPUT_NAMES_LPDATA.WEEKEND_OCCUPATION_ID]: {
        name: INPUT_NAMES_LPDATA.WEEKEND_OCCUPATION_ID,
        label: `label.${INPUT_NAMES_LPDATA.WEEKENDS_OCCUPIED}`,
        disabled: false,
        answers: null,
        visible: true,
        validation: validate(['required']),
        isRequired: true,
    },
};

export const BASE_UK_B2C_DEFAULT_VALUES = {
    [INPUT_NAMES_LPDATA.WORK_DAYS_OCCUPIED]: 'false',
    [INPUT_NAMES_LPDATA.WORK_DAYS_FIRST_ARRIVALS_ID]: '5',
    [INPUT_NAMES_LPDATA.WEEKEND_OCCUPATION_ID]: '3',
};

export const CONSUMO_ANUAL_DEFAULT_VALUES_B2C = {
    DEFAULT: 7500,
    [getCompanyProfileIds().PPC]: 6000,
};

export const NUMERO_FASES_ID_DEFAULT_VALUES_B2C = {
    DEFAULT: null,
    [getCompanyProfileIds().PPC]: 2, //default value threephasic
};

//#endregion /** B2C **/
