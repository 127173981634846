/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Componente que tem como funcionalidade apresentar apresentar uma alerta de espera ao utilizador
 *
 * @version 20200416
 * @since 20200416 Initial release
 *
 */

import PropTypes from 'prop-types';
import { memo, useContext } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Button } from '@save2compete/efz-design-system';

import Loading from 'components/core/Loading';
import IntlMessages from 'components/util/IntlMessages';

import { UserContext } from 'contexts/userContext';

import { getCompanyProfileIds } from 'services/user';

import { TIntl } from 'types/utils';

interface IProps {
    isOpen: boolean;
    title: TIntl;
    subtitle?: TIntl;
    isDownload?: boolean;
    action?: () => void;
}
const LoadingAlert = ({ isOpen, title, subtitle, isDownload = false, action }: IProps) => {
    const { companyProfileId } = useContext(UserContext);
    const handleClose = (_, reason) => {
        if (['backdropClick', 'escapeKeyDown'].includes(reason)) return false;
    };

    // @ts-ignore
    const showNewLoading = [getCompanyProfileIds().EFZ].includes(companyProfileId);

    return (
        <Dialog onClose={handleClose} open={isOpen} PaperProps={{ className: 'facility-loading-dialog' }} className="loading-dialog">
            <DialogTitle className="text-center">
                <IntlMessages id={title} />
            </DialogTitle>
            <DialogContent className="container">
                <Loading
                    size={showNewLoading ? 180 : 64}
                    isCustomLoad={!isDownload && showNewLoading}
                    isDownload={isDownload}
                    color="primary"
                    className={`mb-3${showNewLoading ? ' efz' : ''}`}
                />
                {!!subtitle && (
                    <DialogContentText className={`text-center`}>
                        <IntlMessages id={subtitle} />
                    </DialogContentText>
                )}
                {!!action && (
                    <DialogActions className="justify-content-center p-0 pt-2">
                        <Button variant="secondary" onClick={action} dataTestId="cancel">
                            <IntlMessages id="label.cancel" />
                        </Button>
                    </DialogActions>
                )}
            </DialogContent>
        </Dialog>
    );
};

// PropTypes
LoadingAlert.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    isDownload: PropTypes.bool,
    action: PropTypes.func,
};

export default memo(LoadingAlert);
