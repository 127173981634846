import { createContext, useReducer, useRef, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { HpActions } from 'constants/products/hp';
import { BusinessModelsProContextProvider } from 'contexts/businessModelsPro/businessModelsContext';
import { hpLoadingsReducer, initialHPLoadings } from 'contexts/products/hp/hpLoadingsReducer';
import { hpReducer, initialHPReducer } from 'contexts/products/hp/hpReducer';
import { IHPContext } from 'interfaces/products/hp';

const HPContext = createContext<IHPContext>({
    hpState: initialHPReducer,
    forwardFormId: 'hp-form',
    _paq: [],
    hpDispatchHandler: () => {},
    hpLoadings: initialHPLoadings,
    setHpLoadings: () => {},
    isSaveClicked: Object,
    dispatchClearErrors: () => {},
    canvasRef: Object,
    isDesktop: false,
    isMobileDevice: false,
    scrollContainer: Node,
    canvasImages: [],
    setCanvasImages: (_blob: any) => {},
});
HPContext.displayName = 'efz-hp';

const HPProvider: React.FC<React.ReactNode> = ({ children }) => {
    // @ts-ignore
    const _paq = window._paq ?? [];
    const forwardFormId = 'hp-form';
    const canvasRef = useRef('');
    const isDesktop = useMediaQuery('(min-width: 1366px)', { noSsr: true });
    const isMobileDevice = /Mobile|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|webOS/i.test(navigator.userAgent);
    const [canvasImages, setCanvasImages] = useState([]);
    //@ts-ignore
    const [hpState, hpDispatch] = useReducer(hpReducer, initialHPReducer);
    const [hpLoadings, setHpLoadings] = useReducer(hpLoadingsReducer, initialHPLoadings);

    const scrollContainer = document.querySelector('.app-main-content-wrapper');

    const hpDispatchHandler = (type, payload) => {
        //@ts-ignore
        hpDispatch({
            type: type,
            payload: payload,
        });
    };

    const dispatchClearErrors = () => {
        //@ts-ignore
        hpDispatch({
            type: HpActions.SET_ERROR,
            payload: [],
        });
    };

    const isSaveClicked = useRef(null);

    const hpContextValues: IHPContext = {
        _paq,
        canvasRef,
        hpState,
        forwardFormId,
        isDesktop,
        isMobileDevice,
        scrollContainer,
        hpDispatchHandler,
        hpLoadings,
        setHpLoadings,
        isSaveClicked,
        dispatchClearErrors,
        canvasImages,
        setCanvasImages,
    };

    return (
        <HPContext.Provider value={hpContextValues}>
            <BusinessModelsProContextProvider>{children}</BusinessModelsProContextProvider>
        </HPContext.Provider>
    );
};

export { HPContext, HPProvider };
