import { memo, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import IntlMessages from 'components/util/IntlMessages';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { spvSimpleActions, STEPS_OPEN_AREA, STEPS_RESERVED_AREA } from 'constants/products/solarpvSimple';
import { BASENAME_URL_SOLUTIONS } from 'constants/settings';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { isDefined } from 'services/util/auxiliaryUtils';
import { Button } from '@save2compete/efz-design-system';

const SPVSimpleNavBottom = ({ goBack, forwardFormId }) => {
    const history = useHistory();
    const matches = useMediaQuery('(min-width:1366px)', { noSsr: true });

    const {
        hasLocationRoof,
        spvSimpleState: { isMounting, isDetectRoof, step, isOArea, isRequestingSims },
        spvSimpleDispatchHandler: dispatch,
        mapState: { polygon },
        selectedCard,
    } = useContext(SPVSimpleContext);

    const { state } = useContext(BusinessModelsProContext);

    const stepsWithoutNav = useMemo(
        () => [!isOArea ? STEPS_RESERVED_AREA.ROOF_IDENTIFICATION : STEPS_OPEN_AREA.ROOF_IDENTIFICATION],
        [isOArea]
    );

    const stepsWithoutNextBtn = useMemo(() => {
        const res = [...stepsWithoutNav];
        if (!isOArea) res.push(STEPS_RESERVED_AREA.DOWNLOAD);
        else res.push(STEPS_OPEN_AREA.SIMULATION);
        return res;
    }, [isOArea, stepsWithoutNav]);

    const goBackHandler = () => {
        if (step === (!isOArea ? STEPS_RESERVED_AREA.HOUSING_TYPE : STEPS_OPEN_AREA.HOUSING_TYPE))
            !isOArea ? history.push(BASENAME_URL_SOLUTIONS) : dispatch(spvSimpleActions.SET_STEP, 0 /* LandingPage */);
        else if (step === (!isOArea ? STEPS_RESERVED_AREA.ROOF_DETAILS : STEPS_OPEN_AREA.ROOF_DETAILS))
            dispatch(spvSimpleActions.SET_STEP, STEPS_RESERVED_AREA.ADDRESS);
        else goBack();
    };

    const condidAddressDisabled = () => {
        if (!isDetectRoof) {
            return !(polygon?.area > 0);
        } else {
            return !hasLocationRoof;
        }
    };

    return (
        <div
            className={`nav-bottom${matches ? '-desktop' : `${step === STEPS_RESERVED_AREA.ADDRESS ? '-mobile-map-address' : ''}`} ${stepsWithoutNav.includes(step) ? 'd-none' : ''} `}
        >
            <div className="spv-simple-nav">
                <div className="spv-simple-nav-left">
                    <Button variant="tertiary" tertiaryBack icon="startArrowHeadLeftPrimary" onClick={goBackHandler}>
                        <IntlMessages id="label.back" />
                    </Button>
                </div>
                <div className="spv-simple-nav-right">
                    {!stepsWithoutNextBtn.includes(step) && (
                        <Button
                            size="bg"
                            icon="endArrowHeadRightPrimary"
                            form={forwardFormId}
                            disabled={
                                isMounting ||
                                isRequestingSims ||
                                (step === STEPS_RESERVED_AREA.ADDRESS && condidAddressDisabled()) ||
                                (!isOArea && step === STEPS_RESERVED_AREA.SIMULATION && !isDefined(selectedCard)) ||
                                (step === STEPS_RESERVED_AREA.BUSINESS_MODELS && !isDefined(state.selected.id))
                            }
                            type="submit"
                        >
                            <IntlMessages id="label.next" />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(SPVSimpleNavBottom);
