// DEFAULT OBJECTS
export const INITIAL_EFFICIENCY_MEASURES = {
    efficiency_measures: [],
    investment_estimate_sum: null,
    saving_estimate_sum: null,
    loading: true,
};

// CONSTANTS
export const OPTIONS = [
    {
        label: 'label.selectOption',
        value: ' ',
    },
    {
        label: 'label.dynamic.TECHNICAL_INCOMPATIBILITY',
        value: 'TECHNICAL_INCOMPATIBILITY',
    },
    {
        label: 'label.dynamic.ALREADY_IMPLEMENTED',
        value: 'ALREADY_IMPLEMENTED',
    },
];

// No potential dialog default Inputs
export const INITIAL_PRODUCT_INPUTS_DIALOG = {
    motivo_sem_potencial: ' ',
    comentario_sem_potencial: null,
    tem_potencial: false,
};

// No potential dialog limits
export const MAX_INCREASE_POWER = {
    PREVIOUS_PEAK_POWER: 1000,
    PREVIOUS_INVERTER_POWER: 850,
    PROPOSAL_ID: 10000000,
};

// No potential dialog limits
export const MIN_INCREASE_POWER = {
    PREVIOUS_PEAK_POWER: 0,
    PREVIOUS_INVERTER_POWER: 0,
    PROPOSAL_ID: 99,
};

// No potential dialog inputs rules
export const INCREASE_POWER_RULES = {
    has_proposal_id: { required: 'yup.message.required' },
    proposal_id: {
        max: {
            value: MAX_INCREASE_POWER.PROPOSAL_ID,
            message: 'page.efficiencyMeasures.dialog.invalidProposalID',
        },
        min: {
            value: MIN_INCREASE_POWER.PROPOSAL_ID,
            message: 'page.efficiencyMeasures.dialog.invalidProposalID',
        },
    },
    previous_peak_power: {
        max: {
            value: MAX_INCREASE_POWER.PREVIOUS_PEAK_POWER,
            message: 'page.efficiencyMeasures.dialog.maxAllowedPeakPower',
        },
        min: {
            value: MIN_INCREASE_POWER.PREVIOUS_PEAK_POWER,
            message: 'yup.message.number.min',
        },
    },
    previous_inverter_power_kw: {
        max: {
            value: MAX_INCREASE_POWER.PREVIOUS_INVERTER_POWER,
            message: 'page.efficiencyMeasures.dialog.maxAllowedInverterPower',
        },
        min: {
            value: MIN_INCREASE_POWER.PREVIOUS_INVERTER_POWER,
            message: 'yup.message.number.min',
        },
    },
    is_power_increase: {
        required: 'yup.message.required',
        validate: (value) => value === 'true' || 'page.efficiencyMeasures.dialog.dateProduction.error',
    },
};

export const PROPOSAL_STATUS = {
    PENDENTE: 'PENDENTE',
    CANCELADA: 'CANCELADA',
    ADJUDICADA: 'ADJUDICADA',
};

export const PROPOSAL_STATUS_IDS = {
    PENDING: 1,
    NEGOTIATING: 2,
    GRANTED: 3,
    WON: 4,
    CANCELED: 5,
}; // Falta alguns IDS

// FACILITY EFFICIENCY MEASURES
export const RESET_EFFICIENCY_MEASURES = 'RESET_EFFICIENCY_MEASURES';
export const REQUEST_GET_EFFICIENCY_MEASURES = 'REQUEST_GET_EFFICIENCY_MEASURES';
export const REQUEST_GET_EFFICIENCY_MEASURES_SUCCESS = 'REQUEST_GET_EFFICIENCY_MEASURES_SUCCESS';

// FACILITY PATCH efficiency-measures
export const REQUEST_PUT_EFFICIENCY_MEASURES = 'REQUEST_PUT_EFFICIENCY_MEASURES';
export const REQUEST_PUT_EFFICIENCY_MEASURES_SUCCESS = 'REQUEST_PUT_EFFICIENCY_MEASURES_SUCCESS';
export const SET_EFFICIENCY_MEASURES_BY_TOKEN = 'SET_EFFICIENCY_MEASURES_BY_TOKEN';
export const SET_EFFICIENCY_MEASURES = 'SET_EFFICIENCY_MEASURES';
