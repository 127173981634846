import ServiceV2Margin from 'components/BusinessModels/ProjectSummary/ProjectSummaryServiceV2/SavingsShare/Margin';
import ServiceV2MarginKpis from 'components/BusinessModels/ProjectSummary/ProjectSummaryServiceV2/SavingsShare/MarginKpis';
import ServiceV2Title from 'components/BusinessModels/ProjectSummary/Title';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TServiceV2 } from 'interfaces/businessModels/serviceV2';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';

const SavingsShareServiceV2 = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TServiceV2>;
    return (
        <div className="bm-project-summary-card bm-project-summary-commercial-margin">
            <ServiceV2Title label={'label.serviceAux.sharedSavings'} />
            <ServiceV2Margin />
            {isDefined(bmSelected?.values?.marginSelected) && <ServiceV2MarginKpis />}
        </div>
    );
};

export default SavingsShareServiceV2;
