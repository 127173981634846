import { z } from 'zod';

export const structureForRoofTypeItemDataSchema = z.object({
    roof_type_id: z.number(),
    mounting_structure_id: z.number(),
});

export const structuresForRoofTypeDataSchema = z.array(structureForRoofTypeItemDataSchema);

export const latLngSchema = z.object({
    lat: z.number(),
    lng: z.number(),
});

export const coordinatesDSSchema = z.array(z.array(z.number()));

export const detectRooftopPayloadSchema = z.object({
    center: z.object({
        lat: latLngSchema.shape.lat,
        lng: latLngSchema.shape.lng,
        zoom: z.number(),
    }),
    image_name: z.string(),
});

export const detectRooftopDataSchema = z
    .array(
        z.object({
            lat: latLngSchema.shape.lat,
            lng: latLngSchema.shape.lng,
        })
    )
    .nonempty();

export const detectBuildingPayloadSchema = z.object({
    facilityID: z.string(),
    lat: latLngSchema.shape.lat,
    lng: latLngSchema.shape.lng,
    zoom: z.number(),
    locale: z.string(),
});

export const detectBuildingAPIRspSchema = z.object({
    roof_selected: z
        .record(
            z.object({
                roof: coordinatesDSSchema,
                height: z.number().nullable(),
                area: z.number(),
                perimeter: z.number(),
                solar_panel_fixing_points: z.number(),
                id: z.number(),
            })
        )
        .refine((value) => Object.keys(value).length > 0, {
            message: 'Record must not be empty',
        }),
});

export const coordsDataSchema = z.array(latLngSchema);

export const detectRoofTypePayloadSchema = z.object({
    facilityID: z.string(),
    buildingID: z.string(),
    lat: latLngSchema.shape.lat,
    lng: latLngSchema.shape.lng,
    coords: coordsDataSchema,
    zoom: z.number(),
    locale: z.string(),
    is_manual: z.boolean(),
    productID: z.number(),
});
export const detectRoofTypeDataSchema = z.object({
    roof_type: z.string(),
    roof_type_id: z.number(),
});

export const coordConvFactorPayloadSchema = z.object({
    facilityID: z.string(),
    lat: latLngSchema.shape.lat,
    lng: latLngSchema.shape.lng,
    userLocale: z.string(),
});

export const coordConvFactorDataSchema = z.object({
    one_meter_in_lon: z.number(),
    one_meter_in_lat: z.number(),
});

const coordinatesAvgSchema = z.object({
    lat: z.number(),
    long: z.number(),
});

export const estimatedStructureAspectPayloadSchema = z.object({
    convFactors: coordConvFactorDataSchema,
    facility_id: z.string(),
    country_alpha_2: z.string().length(2),
    areas: z.array(
        z.object({
            id: z.number(),
            coordinates: coordsDataSchema,
            centroid: latLngSchema,
            coordinates_avg: coordinatesAvgSchema,
            mounting_structure_type_id: z.number(),
        })
    ),
    get_best_aspect: z.boolean().default(true),
    use_carto: z.boolean().default(false),
});

export const estStructuresAspectDataSchema = z.object({
    aspect: z.number(),
    edges: z.array(
        z.object({
            aspect: z.number(),
            points: coordsDataSchema,
        })
    ),
});

export const panelItemSchema = z.object({
    id: z.number(),
    marca: z.string(),
    modelo: z.string(),
    potencia_pico: z.number(),
    area_desenhada_m2: z.array(z.number()).optional(),
    largura: z.number(),
    altura: z.number(),
    garantia_anos: z.number(),
    nome_img: z.string().nullable(),
    degradacao_anual_prc: z.number(),
    datasheet_name: z.string().nullable(),
    is_default: z.boolean(),
});

export const rangeDataItemSchema = z.object({
    id: z.number(),
    descricao: z.string(),
    is_default: z.boolean(),
    area_util_coplanar: z.string(),
    area_util_triangular: z.string(),
    area_util_triangular_apos_15_graus: z.string(),
    min_kwp: z.number(),
    max_kwp: z.number(),
    modo_calculo_estrutura_id: z.number(),
    kits: z.boolean(),
    modo_dimensionamento_inversores_id: z.number(),
    pv_modo_kits_id: z.number(),
    pv_modo_limite_potencia_id: z.number(),
    paineis: z.array(panelItemSchema),
});

const FEElementsArrSchema = z.object({
    id: z.number(),
    maximo: z.number().nullable(),
    minimo: z.number().nullable(),
    visivel: z.boolean(),
    editavel: z.boolean(),
    designacao_fe: z.string(),
    valor_default: z.union([z.string(), z.number(), z.boolean()]),
    elemento_fe_id: z.number(),
    pv_estrutura_empresa_id: z.number(),
    perfil_menu_representacao_paineis_id: z.number(),
});

export const structureItemSchema = z.object({
    id: z.number(),
    tipo_estrutura_id: z.number().nullable(),
    tag: z.string(),
    parent_id: z.number().nullable(),
    is_default: z.boolean(),
    img_name: z.string().nullable(),
    order_id: z.number(),
    default_aspect: z.number(),
    default_roof_slope: z.number(),
    min_roof_slope: z.number(),
    max_roof_slope: z.number(),
    default_panels_slope: z.number().nullable(),
    min_panels_slope: z.number(),
    max_panels_slope: z.number(),
    perfil_menu_representacao_paineis_id: z.number(),
    fe_elements_arr: z.array(FEElementsArrSchema),
    panels_wide: z.number(),
    panels_up: z.number(),
    space_btw_sets: z.number(),
    hourWindow: z.number(),
    roofSlope: z.number(),
    is_row_spacing_insert: z.boolean(),
    rowSpace: z.number(),
    setback: z.number(),
    panelFraming: z.number(),
    dayRank: z.string(),
});

const edgeSchema = z.object({
    aspect: z.number(),
    points: coordsDataSchema,
});

export const panelRepresentationSchema = z.object({
    dayRank: z.string(),
    hourWindow: z.number(),
    panelFraming: z.number(),
    panels_up: z.number(),
    panels_wide: z.number(),
    roofSlope: z.number(),
    setback: z.number(),
    space_btw_sets: z.number(),
    is_row_spacing_insert: z.boolean(),
    rowSpace: z.number(),
    alignment: z.enum(['left', 'right', 'center']),
    alignment_horizontal: z.enum(['left', 'right', 'center']),
    alignment_vertical: z.enum(['top', 'bottom', 'center']),
    reference_point: z.array(z.number()).default([]),
    panels_nr: z.number(),
    alignment_mode_id: z.number(),
    row_space: z.number(),
    // isDefaultRowRatio: z.boolean(),
    // coordinates_conversion_factor: z.object({
    //     one_meter_in_lon: z.number(),
    //     one_meter_in_lat: z.number(),
    // }),
});

export const areaItemSchema = z.object({
    id: z.number(),
    area: z.number().default(0),
    kit_id: z.number().optional(),
    aspect: z.number().default(0),
    coordinates: coordsDataSchema.default([]),
    coordinates_avg: coordinatesAvgSchema,
    edges: z.array(edgeSchema),
    lat: z.number(),
    long: z.number(),
    mounting_structure_id: z.number(),
    mounting_structure_type_id: z.number(),
    orientation: z.number().default(0),
    panels_number: z.number().default(0),
    peak_power: z.number().default(0),
    perimeter_area: z.number().default(0),
    panels_number_excluded: z
        .number()
        .int()
        .transform((val) => parseInt(val.toString(), 10))
        .default(0),
    panels_number_possible: z.number().default(0),
    slope: z.number().default(0),
    max_type_area_panels: z.string().optional(),
    max_tec_area_panels: z.number().optional(),
    max_kwp_area: z.number().nullable().optional(),
});

export const roofSplitPayloadSchema = z.object({
    facilityID: z.string(),
    buildingID: z.literal(1),
    isB2C: z.boolean(),
    locale: z.string(),
    convFactors: coordConvFactorDataSchema,
    coords: coordinatesDSSchema,
    panelDimensions: z.object({
        panel_w: z.number(),
        panel_l: z.number(),
    }),
    defaultRoofSlope: z.number(),
    defaultSlope: z.number(),
    zoom: z.literal(20),
});

export const roofDSSchema = z.array(coordsDataSchema);

export const exclusionAreaADetectedPayloadSchema = z.object({
    areaId: z.number(),
    center: latLngSchema.extend({ zoom: z.number() }),
    image_name: z.string(),
    roof: roofDSSchema,
    angle: z.number(),
});

export const exclusionAreaADetectedDataSchema = z.object({
    data: z.object({
        status: z.number(),
        data: z.array(coordsDataSchema),
        message: z.string(),
    }),
});

export const exclusionsDataSchema = z.object({
    area: z.number().optional(),
    coordinates: z.array(z.array(z.number())),
    id: z.number().int(),
    isPolygon: z.boolean(),
});

export const rowRatioPayloadSchema = z.object({
    dayRank: z.number(),
    lat: z.number(),
    long: z.number(),
    hourWindow: z.number(),
    roofSlope: z.number(),
    difPanelSlope: z.number(),
    panelOrientation: z.number(),
    elevation: z.number(),
});
export const rowRatioDataschema = z.object({
    status: z.number(),
    data: z.string().transform((value) => Number(value)),
    message: z.string(),
});

export const estimatedStructureItemDataSchema = z.object({
    aspect: z.number(),
    edges: z.array(edgeSchema),
});
const roofSplitFacetSchema = z.record(
    z.object({
        area: z.number(),
        facet_poly: coordinatesDSSchema,
        orientation_guess: z.array(estimatedStructureItemDataSchema),
    })
);

export const roofSplitAPIDataSchema = z.object({
    facets: roofSplitFacetSchema,
});

export const usefulAreaPayloadSchema = z.object({
    facility_id: z.string(),
    roof_coordinates: coordinatesDSSchema,
    panel_w: z.number(),
    panel_l: z.number(),
    row_space: z.number(),
    panelOrientation: z.number(),
    difPanelSlope: z.number(),
    holes_coordinates: z.array(coordinatesDSSchema),
    roofSlope: z.number(),
    setback: z.number(),
    panels_wide: z.number(),
    panels_up: z.number(),
    space_btw_sets: z.number(),
    // alignment: z.enum(['left', 'right', 'center']),
    // vertical_alignment: z.enum(['top', 'bottom', 'center']),
    // horizontal_alignment: z.enum(['left', 'right', 'center']),
    area: z.number(),
    draggabled: z.boolean(),
    reference_point: z.array(z.number()),
    coordinates_conversion_factor: z.object({
        one_meter_in_lat: z.number(),
        one_meter_in_lon: z.number(),
    }),
    output_on_cache: z.boolean(),
    area_id: z.number(),
    isV3: z.boolean(),
    manual_removed_idxs: z.array(z.number()),
    manual_added_idxs: z.array(z.number()),
    alignment_mode_id: z.number(),
    mounting_structure_type_id: z.number(),
});

const panelPosition = z.tuple([z.number(), z.number()]);

export const usefulAreaSchema = z.object({
    roof_area: z.number(),
    useful_area: z.number(),
    panels_number: z.number(),
    panel_rows: z.array(
        z.object({
            id: z.tuple([z.number(), z.number(), z.tuple([z.number(), z.number()])]),
            position: z.array(panelPosition),
            excluded: z.boolean(),
        })
    ),
    roof_centroid: panelPosition,
    reference_point: panelPosition,
    coordinates_conversion_factor: coordConvFactorDataSchema,
    coordinates_setbacks: z.array(z.array(panelPosition)),
    output_on_cache: z.boolean(),
    id: z.number(),
});

export const usefulAreaAPIDastaSchema = z.object({
    status: z.number(),
    data: usefulAreaSchema,
    message: z.string(),
});

const coordinateSchema = z.array(z.number());

export const exclusionsSchema = z.object({
    area: z.number().optional(),
    coordinates: z.array(coordinateSchema),
    id: z.number().int(),
    isPolygon: z.boolean(),
});

export const inputsKitsSchema = z
    .array(
        z.object({
            id: z.number(),
            areas: z.array(z.number()),
        })
    )
    .default([]);

export const inverterSchema = z.object({
    id: z.number(),
    qt: z.number(),
    cost: z.number(),
    brand: z.string(),
    model: z.string(),
    power: z.number(),
    hybrid: z.boolean(),
    pv_rubrica_custo_id: z.number(),
    max_ac_apparent_power: z.number(),
});

export const prodParamsSchema = z.object({
    param: z.string(),
    raddatabase: z.string(),
    pvtechchoice: z.string(),
    pvgis_ano_tipico: z.number(),
    factor_perda_inicial: z.number(),
    factor_perda_extra_coplanar: z.number(),
});
export const pvOptionsSchema = z.object({
    prod_params: prodParamsSchema,
    injection_tariff_default: z.number(),
    default_network_sale: z.boolean(),
    limit_network_sale: z.number().or(z.boolean()),
    default_limit_injection: z.boolean().optional(),
    default_limit_value: z.number().optional(),
    default_panel_alignment_mode_id: z.number(),
});

export const inputsSchema = z.object({
    areas: z.array(areaItemSchema).default([]),
    coordinates_conversion_factor: coordConvFactorDataSchema,
    coordinates_avg: coordinatesAvgSchema,
    range_id: z.number(),
    panel_id: z.number(),
    exclusions: z.array(exclusionsSchema).default([]),
    is_manual: z.boolean(),
    kits: inputsKitsSchema.optional(),
    inverters_combination: z.array(inverterSchema).default([]),
    limit_network_sale: z.boolean().or(z.number()),
    network_sale: z.boolean(),
    injection_tariff: z.number().optional(),
    total_areas: z.number().default(0),
    total_panels: z.number().default(0),
    max_tec_panels: z.number().min(1, { message: 'max_tec_panels must be greater than 0' }),
    min_tec_panels: z.number().min(1, { message: 'min_tec_panels must be greater than 0' }),
    total_perimeter_areas: z.number().default(0),
    prod_params: prodParamsSchema,
    is_kits: z.boolean(),
});

export const invertersCombPayloadSchema = inputsSchema.omit({
    inverters_combination: true,
});

export const invertersCombDataSchema = z.array(inverterSchema).nonempty();

export const inverterCombAPIDataSchema = z.object({
    data: z.object({
        data: z.object({
            recommended: invertersCombDataSchema,
        }),
    }),
});

export const kitsSchema = z
    .array(
        z.object({
            id: z.number(),
            panels_num: z.number(),
            potencia_nominal: z.number(),
        })
    )
    .min(0);

export const optimizationItemSchema = z.object({
    id: z.number(),
    tag: z.string(),
    limit_inj_prc: z.number(),
    sys_deg_an_prc: z.number(),
    orientation_range: z.string().nullable(),
});

export const optimizationsSchema = z.object({
    bm_id: z.number(),
    payment_method_id: z.number(),
    tag: z.string(),
    with_battery: z.literal(false),
    optimizations: z.array(optimizationItemSchema),
});

const inputsSimsSchema = z
    .object({
        ...inputsSchema.shape,
        areas: z.array(areaItemSchema.omit({ coordinates: true, edges: true, lat: true, long: true })).nonempty(),
    })
    .omit({ coordinates_conversion_factor: true, exclusions: true, is_manual: true });

export const optimizationInputsSchema = z.object({
    tipo_modelo_negocio_id: z.number(),
    payment_method_id: z.number(),
    tipo_produto_id: z.number(),
    contracted_power: z.number(),
    power_limit: z.number(),
    risco_energia_id: z.number().nullable(),
    tarifa_equivalente_ac: z.number().nullable(),
    tipo_optimizacao: z.number(),
    limit_inj_prc: z.number(),
    sys_deg_an_prc: z.number(),
    orientation_range: z.string().nullable(),
});

const inputsOptimizerSchema = z
    .object({
        ...inputsSchema.shape,
        areas: z.array(areaItemSchema.omit({ coordinates: true, edges: true, lat: true, long: true })).nonempty(),
    })
    .omit({ inverters_combination: true, exclusions: true });
export const optimizerPayloadSchema = z.object({
    facility: z.object({
        id: z.number(),
    }),
    inputs: inputsOptimizerSchema,
    ...optimizationInputsSchema.shape,
    register_simulation: z.boolean(),
});

export const optimizerWithKitsPayloadSchema = z.object({
    facility: z.object({
        id: z.number(),
    }),
    inputs: inputsOptimizerSchema,
    ...optimizationInputsSchema.shape,
    kits: kitsSchema.min(1),
    register_simulation: z.boolean(),
});

const kpisSchema = z.object({
    ac_tariff: z.number().optional(),
    peak_power: z.number(),
    annual_consumption_tariff: z.number(),
    inverter_power: z.number(),
    annual_prod: z.number(),
    payback: z.number(),
    annual_saving: z.number(),
    injection_tariff: z.number(),
    annual_injection: z.number().optional(),
    annual_cons: z.number().optional(),
    selfconsumption_perc: z.number(),
    injection_perc: z.number(),
    annual_ac: z.number().optional(),
    cons_reduction_perc: z.number(),
    cost: z.number().min(1),
    investment: z.number().min(1),
});

export const solutionDataSchema = z.object({
    inputs: inputsSchema,
    kpis: kpisSchema,
});

export const invertersCombSchema = z.array(inverterSchema).nonempty();

const optimizerSolutionSchema = z.object({
    solution: z.object({
        areas: z.array(
            z.object({
                panels_number: z.number(),
                panels_number_possible: z.number(),
                id: z.number(),
            })
        ),
        kpis: kpisSchema,
        inverters_combination: invertersCombSchema,
        total_panels: z.number(),
    }),
});

export const optimizerGeneralKpisSchema = z.object({
    peak_power: z.number(),
    savings: z.number(),
    investment: z.number().min(1),
    payback: z.number(),
});

export const optimizerAPIDataSchema = z.intersection(optimizerGeneralKpisSchema, optimizerSolutionSchema);

export const simulationDataSchema = z.object({
    kpis: kpisSchema,
});

export const simulationPayloadSchema = z.object({
    facility: z.object({
        id: z.number(),
    }),
    inputs: inputsSimsSchema,
    is_scaling: z.boolean(),
    tipo_produto_id: z.number(),
});

const roofTypeSchema = z.object({
    roof_type: z.string(),
    roof_type_id: z.number(),
    default: z
        .object({
            roof_type: z.string(),
            roof_type_id: z.number(),
        })
        .optional(), //apenas para controlo do FE
});

export const trainingDSSchema = z.object({
    user_id: z.number(),
    parameter: z.literal('roof_coordinates').or(z.literal('roof_type')),
    values: z.object({
        original_roof: z
            // case 'roof_coordinates'
            .array(
                z.object({
                    lat: z.number(),
                    lng: z.number(),
                })
            )
            .or(
                // case 'roof_type'
                z.object({
                    roof_type: z.string(),
                    roof_type_id: z.number(),
                })
            ),
        redrawn_roof: z
            .array(
                z.object({
                    lat: z.number(),
                    lng: z.number(),
                })
            )
            .optional(),
        selected_roof: z
            .object({
                roof_type: z.string(),
                roof_type_id: z.number(),
            })
            .optional(),
    }),
});

export const useSpvSimpleSchema = z.object({
    userData: z.object({
        userID: z.number(),
        companyProfileId: z.number(),
        userLocale: z.string(),
    }),
    clientData: z.object({ risco_energia_id: z.number().nullable() }),
    facilityData: z.object({
        facilityID: z.number(),
        isB2C: z.boolean(),
        potencia_contratada: z.number(),
        nivel_tensao_descricao: z.string(),
        potencia_requisitada: z.number(),
        tarifa_equivalente_ac: z.number().nullable(),
    }),
    options: z.object({
        productID: z.number(),
        facilityCoordinates: latLngSchema,
        sInputs: inputsSchema,
        ranges: z.array(rangeDataItemSchema),
        structures: z.array(structureItemSchema),
        pvOptions: pvOptionsSchema,
        optimizationsData: z.array(optimizationsSchema),
        isManualDrawing: z.boolean(),
        roofByDrawingManual: coordsDataSchema,
        roofType: roofTypeSchema,
        roof: coordsDataSchema,
        kits: kitsSchema,
        setInfoTagsHandler: z.function(
            z.tuple([
                z.array(
                    z.object({
                        message: z.string().nullable(),
                        data: z.object({}).optional(),
                        type: z.enum(['info', 'warning', 'error']).optional(),
                    })
                ),
            ]),
            z.void()
        ),
        solarpvDispatchHandler: z.function(z.tuple([z.string(), z.object({})]), z.void()),
    }),
});
