import { TBMProjectSummary } from 'interfaces/businessModels';
import KPI from 'components/BusinessModels/List/BusinessModels/BusinessModelPaP/Card/Summary/Kpi';
import { pPaProjectSummary } from 'services/businessModels/ppa2';
import { useContext } from 'react';
import { UserContext } from 'contexts/userContext';

interface IProps {
    projectSummary: TBMProjectSummary[];
    syncKpis?: any;
}

const BusinessModelProjectSummaryServicePPa = ({ projectSummary, syncKpis }: IProps) => {
    const { companyProfileId } = useContext(UserContext);
    const kpis = pPaProjectSummary(projectSummary, syncKpis, companyProfileId);

    return <>{kpis.length > 0 ? kpis.map((kpi) => <KPI {...kpi} />) : <></>}</>;
};

export default BusinessModelProjectSummaryServicePPa;
