import { TBMProjectSummary, TBMKpi } from './../../interfaces/businessModels/index';
import { isFieldDefined, parseNumberWithDecimals, parseNumberWithToFixed } from 'services/util/auxiliaryUtils';
import { papTariffUnit } from 'services/businessModels/papKpis';

export const paPProjectSummary = (kpis: TBMProjectSummary[], syncKpis: any, companyProfileId: number): TBMKpi[] => {
    if (isFieldDefined(syncKpis)) return syncPaPProjectSummary(syncKpis, companyProfileId);

    return [
        {
            label: 'label.price',
            infoLabel: 'label.tariffDiscount',
            value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].pap_tariff, 1),
            unit: 'cmwp',
            numberOfDecimalPlaces: 1,
            values: {
                tariffDiscount: parseNumberWithToFixed(kpis[0].kpis_per_margin?.[0].pap_tariff_discount * 100, 1).toLocaleString('pt-PT'),
            },
        },
        {
            label: 'label.annualSaving',
            value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].annual_saving, 0),
            unit: 'currency',
            numberOfDecimalPlaces: 0,
        },
    ];
};

const syncPaPProjectSummary = (syncKpis, companyProfileId) => {
    const step = `${syncKpis?.negotiation?.step ?? ''}`;
    const numberOfDecimalPlaces = step.split('.')?.[1]?.length ?? 4;
    return [
        {
            label: 'label.tariff',
            value: parseNumberWithDecimals(syncKpis?.negotiation.default, numberOfDecimalPlaces),
            unit: papTariffUnit(companyProfileId),
            numberOfDecimalPlaces,
        },
        {
            label: 'label.annualSaving',
            value: parseNumberWithDecimals(syncKpis.kpis.annual_saving, 0),
            unit: 'currency',
        },
    ];
};
