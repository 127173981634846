/**
 * @author Miguel Cervera <miguel.cervera@caixamagica.pt>, Daniel Bastos <daniel.bastos@caixamagica.pt>
 *
 * @description Component Context - Individual Module Component
 * @article about ContainerComponent https://medium.com/@learnreact/container-components-c0e67432e005#.1a9j3w1jl
 *
 * @version 20191119
 * @since 20191119 Adding Modules
 *
 */

import { useTheme } from '@mui/material';
import NProgress from 'nprogress';
import { useContext, useEffect } from 'react';

// Assets
import downloadAnimation from 'assets/lottie/Download';
import downloadAnimationBasic from 'assets/lottie/Download-basic';
import loadingAnimation from 'assets/lottie/Loading';
import loadingAnimationBasic from 'assets/lottie/Loading-basic';
import 'nprogress/nprogress.css';

// Components
import Lottie from 'components/@efz/Lottie';
import IntlMessages from 'components/util/IntlMessages';
import { getCompanyProfileIds } from 'services/user';
import { UserContext } from 'contexts/userContext';

// Services / Hooks

//Component
const Loading = ({
    size = 32,
    className = '',
    useTopLoading = false,
    label = null,
    color = 'primary',
    isCustomLoad = false,
    isDownload = false,
    ...rest
}) => {
    NProgress.configure({ showSpinner: false, trickleSpeed: 30 });

    if (!!useTopLoading && !NProgress.isStarted()) NProgress.start();

    useEffect(() => {
        return () => {
            if (useTopLoading) NProgress.done();
        };
    }, [useTopLoading]);

    const theme = useTheme();
    const { companyProfileId } = useContext(UserContext);
    const showNewLoading = [getCompanyProfileIds().EFZ].includes(companyProfileId);
    const computedColor = showNewLoading ? 'url(#efz-linear-gradient)' : theme?.palette?.[color]?.main ?? color;

    const EFZLinearGradient = (
        <svg width="0" height="0" viewBox="0 0 0 0">
            <title>EFZ Gradient</title>
            <defs>
                <linearGradient id="efz-linear-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#3885CD" />
                    <stop offset="100%" stopColor="#3EC09C" />
                </linearGradient>
            </defs>
        </svg>
    );

    return (
        <div className={`loading-component${className ? ' ' + className : ''}`} {...rest}>
            {
                // gradient with EFZ colors
                showNewLoading && !isDownload && !isCustomLoad && EFZLinearGradient
            }
            {isDownload ?
                showNewLoading ?
                    // Custom EFZ download (lottie)
                    <Lottie autoplay loop src={downloadAnimation} width={size ? size : 32} className="download" />
                    // Default download (lottie)
                :   <Lottie autoplay src={downloadAnimationBasic} width={size ? size : 32} className="download" />
            : isCustomLoad ?
                // Custom EFZ loading (lottie)
                <Lottie autoplay loop src={loadingAnimation} width={size ? size : 32} />
                // default Circular loading
            :   <Lottie
                    autoplay
                    loop
                    src={loadingAnimationBasic}
                    width={size ? size : 32}
                    style={{
                        '--efz-spinner-color': computedColor,
                    }}
                />
            }

            {label && (
                <label>
                    <IntlMessages id={label} />
                </label>
            )}
        </div>
    );
};

export default Loading;
