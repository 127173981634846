import IntlMessages from 'components/util/IntlMessages';
import { BmDetailedActions } from 'constants/businessModelsPro/index';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { Button } from '@save2compete/efz-design-system';

const OfferSummaryActionsPPaDetailed = () => {
    const {
        bmState,
        bmState: { loadings },
        setBMEventHandler,
    } = useContext(OfferEditionContext);

    const {
        formState: { errors },
    } = useFormContext();

    return (
        <div className="bm-detailed-offer-summary-actions">
            <Button
                className="btn-upt-values"
                variant="secondary"
                onClick={() => setBMEventHandler(DetailedOfferEditionEvents.SET_DETAILED_ACTION, BmDetailedActions.SIMPLE_SIMULATION)}
                disabled={
                    loadings.general ||
                    (bmState?.sliderReady && !['none'].includes(String(bmState?.offerEditionValues?.allowance?.selected_allowance))) ||
                    isFieldDefined(errors['extraCost']) ||
                    isFieldDefined(errors['annualEvoFee']) ||
                    //!['none', 'unique', 'yearly'].includes(String(bmState?.offerEditionValues?.allowance?.selected_allowance)) ||
                    isFieldDefined(errors['unique_allowance_currency']) ||
                    isFieldDefined(errors['yearly_allowance_currency']) ||
                    isFieldDefined(errors['yearly_allowance_duration_years'])
                }
                type="submit"
                loading={loadings.simpleSimulation}
                dataTestId="update-values"
            >
                <IntlMessages id="label.updateValues" />
            </Button>
        </div>
    );
};

export default OfferSummaryActionsPPaDetailed;
