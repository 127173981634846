import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { bool, array, number } from 'prop-types';
import { SPVProContext } from 'contexts/products/solarpv/spvProContext';
import {
    DISPATCH_EVT,
    INPUTS_NAME_SYS_COMPOSITION,
    REMOTE_DIALOG_TABLE_COLUMNS,
    REMOTE_TABS_INPUTS,
    RemoteActions,
} from 'constants/products/spvPro';
import { initialRemoteReducer, remoteReducer } from './remoteReducer';
import { ReduxContext } from 'contexts/redux/reduxContext';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { getRemotePayload } from 'services/products/solarpv';

const RemoteContext = createContext({
    isRequesting: bool,
    facilities: array,
    coefficientSum: number,
});

const RemoteProvider = ({ children }) => {
    const {
        facility: {
            info: { descricao: facilityDescription },
        },
    } = useContext(ReduxContext);

    const {
        solarpvState: { isMounting, inputs },
        setDispatchEvt,
        isEditingOrientation,
        isOverlayOpen,
        hasChangesInRemote,
        setHasChangesInRemote,
        isDisabledRemote,
    } = useContext(SPVProContext);

    const formID = 'remote-form';
    const tableColumns = useMemo(() => REMOTE_DIALOG_TABLE_COLUMNS, []);

    const [remoteState, remoteDispatch] = useReducer(remoteReducer, initialRemoteReducer);
    const [isSavedChanges, setIsSavedChanges] = useState(false);
    const [isDiscardedChanges, setIsDiscardedChanges] = useState(false);

    const { inputs: remoteInputs } = remoteState;
    const remoteDispatchHandler = (type, payload) => {
        remoteDispatch({
            type: type,
            payload: payload,
        });
    };

    // don't use 'coefficientSum' from remoteState; value doesn't always match
    const coefficientSum = useMemo(() => {
        let sum = 0;
        if (isFieldDefined(remoteInputs)) {
            // my facility
            sum +=
                !isNaN(parseFloat(remoteInputs?.[REMOTE_TABS_INPUTS.COEFFICIENT])) ?
                    parseFloat(remoteInputs?.[REMOTE_TABS_INPUTS.COEFFICIENT])
                :   0;
            // neighbours/enterprises
            const neighbours = remoteInputs?.[REMOTE_TABS_INPUTS.NEIGHBORS] ?? [];
            const enterprises = remoteInputs?.[REMOTE_TABS_INPUTS.ENTERPRISES] ?? [];
            sum += [...neighbours, ...enterprises]?.reduce(
                (acc, curr) =>
                    acc +
                    (!isNaN(parseFloat(curr?.[REMOTE_TABS_INPUTS.COEFFICIENT])) ? parseFloat(curr?.[REMOTE_TABS_INPUTS.COEFFICIENT]) : 0),
                0
            );
        }
        return sum;
    }, [remoteInputs]); // eslint-disable-line

    useEffect(() => {
        /* SET_INIT */
        // fill inputs onLoad (if they exist)
        if (!isMounting && isFieldDefined(inputs?.remote)) {
            remoteDispatchHandler(RemoteActions.SET_INIT, inputs?.remote);
            remoteDispatchHandler(RemoteActions.SET_COEFFICIENT_SUM, coefficientSum);
        }
    }, [isMounting]); // eslint-disable-line

    useEffect(() => {
        if (isSavedChanges) {
            setHasChangesInRemote(false);
            setIsSavedChanges(false);
        }
    }, [isSavedChanges]); // eslint-disable-line

    useEffect(() => {
        if (isDiscardedChanges) {
            setIsDiscardedChanges(false);
        }
    }, [isDiscardedChanges]); // eslint-disable-line

    const applyRemoteChangesHandler = (data) => {
        if (isFieldDefined(data)) {
            const payload = getRemotePayload(data);
            setDispatchEvt({
                evt: DISPATCH_EVT.SET_FACILITIES_REMOTE_SC,
                groupId: null,
                values: {
                    [INPUTS_NAME_SYS_COMPOSITION.REMOTE]: payload,
                },
            });
        }
        setIsSavedChanges(true);
    };

    const discardRemoteChangesHandler = () => {
        setDispatchEvt({
            evt: DISPATCH_EVT.DELETE_ALL_FACILITIES_REMOTE_SC,
            groupId: null,
            values: {
                [INPUTS_NAME_SYS_COMPOSITION.REMOTE]: null,
            },
        });
        setIsDiscardedChanges(true);
    };

    return (
        <RemoteContext.Provider
            value={{
                isEditingOrientation,
                isOverlayOpen,
                inputs,
                hasChangesInRemote,
                setDispatchEvt,
                applyRemoteChangesHandler,
                discardRemoteChangesHandler,
                remote: inputs?.remote ?? null,
                coefficientSum,
                // TODO: add new ones below this comment
                facilityDescription,
                formID,
                remoteState,
                remoteDispatchHandler,
                tableColumns,
                isDisabledRemote,
                isSavedChanges,
                setIsSavedChanges,
                isDiscardedChanges,
                setIsDiscardedChanges,
            }}
        >
            {children}
        </RemoteContext.Provider>
    );
};

export { RemoteContext, RemoteProvider };
