/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Selector - Proposal
 *
 * @version 20191202
 * @version 20191113
 * @since 20191113 Initial release
 *
 */

import { createSelector } from 'reselect';

export const getProposal = createSelector(
    (state) => state,
    (state) => state
);
export const getProposalPayload = createSelector(
    (state) => state?.payload,
    (payload) => payload
);
export const getProposalInformation = createSelector(
    (state) => state?.information,
    (information) => information
);
export const getProposalInputs = createSelector(
    (state) => state?.inputs,
    (inputs) => inputs
);
export const getBusinessModelID = createSelector(
    (state) => state?.tipo_modelo_negocio_id,
    (tipo_modelo_negocio_id) => tipo_modelo_negocio_id
);
