import { GrantsAmmountsType } from 'interfaces/businessModels/upfront';
import { TProductInputs } from '../../../interfaces/businessModels/index';
import { IBusinessModelsContext } from 'interfaces/businessModels';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { useContext, useEffect } from 'react';
import { LECEvents, Tlec } from 'interfaces/businessModels/lec';

const useLECGrants = () => {
    const {
        setBMEventHandler,
        state: {
            selected: {
                values: { costs, grants },
            },
        },
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, Tlec>;

    const handleChangeDescription = (id: number, value: string) => {
        setBMEventHandler(LECEvents.SET_DESCRIPTION_GRANT, { description: value, id: id });
    };

    const handleChangeAmmount = (ammountType: GrantsAmmountsType, id, value) => {
        setBMEventHandler(LECEvents.SET_AMMOUNT_GRANT, {
            ammountType: ammountType,
            id: id,
            amount: Number(value),
        });
    };

    const handleDelete = (grantId: number) => {
        setBMEventHandler(LECEvents.DELETE_LEC_GRANT, grantId);
    };

    const handleAddGrant = () => {
        setBMEventHandler(LECEvents.ADD_LEC_GRANT, null);
    };

    const handleUpdateTotalGrants = () => {
        setBMEventHandler(LECEvents.UPDATE_LEC_GRANTS, null);
    };

    useEffect(() => {
        if (grants.totalGrants.prc > 0) {
            handleUpdateTotalGrants();
        }
    }, [costs?.totalCost]); // eslint-disable-line

    return {
        handleChangeDescription,
        handleChangeAmmount,
        handleDelete,
        handleAddGrant,
    };
};

export default useLECGrants;
