import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

//Material UI
import { useTheme } from '@mui/material';
//Our Components
import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as ClientIcon } from 'assets/images/icons/svg/client.svg';
//import { ReactComponent as AddClientIcon } from 'assets/images/icons/svg/add-square-empty.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/svg/arrow-down.svg';
import { BASENAME_URL_CLIENT /* BASENAME_URL_CREATE_CLIENT, */ /* , BASENAME_URL_FACILITIES */ } from 'constants/settings';
import { useHistory } from 'react-router';
import SectionInfo from './SectionInfo';
import SectionSearch from './SectionSearch';
import { gAnalyticsEvt } from 'services/gAnalytics';
import { GA_LABEL_TOP_LEVEL_NAVIGATION, GA_TYPE_EVT } from 'constants/gAnalytics';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { useSelector } from 'react-redux';
import { getClientSegmentoDescricao } from 'redux/selectors/client';
import { UserContext } from 'contexts/userContext';
import { getCompanyProfileIds } from 'services/user';

const ClientCard = ({
    showInfo,
    title,
    subtitle,
    isOpen,
    handleToggle,
    handleChangeAutocomplete,
    searchData,
    userTypeID,
    companyProfileId,
    handlerClearClient,
    isThisPathActive,
}) => {
    const { isB2C /* allowedToCreateClient */ } = useContext(UserContext);

    const theme = useTheme();
    const history = useHistory();
    let segmento_descricao = useSelector((state) => getClientSegmentoDescricao(state?.client));

    //Conditions

    /* const handlerGoToCreateClient = () => {
        history.push(BASENAME_URL_CREATE_CLIENT);
        handleToggle();
    }; */

    const handleGoToClient = () => {
        history.push(BASENAME_URL_CLIENT);
        handleToggle();
    };

    return (
        <Dropdown isOpen={isOpen} toggle={handleToggle}>
            <DropdownToggle
                className="header-dropdown-toggle"
                tag="span"
                data-toggle="dropdown"
                onClick={() => gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION)}
            >
                {/* info header */}

                <div className="menu-item-content" onClick={handleToggle}>
                    <span className={`me-lg-2 default-svg${isOpen || isThisPathActive ? '--open' : ''}`}>
                        <ClientIcon height={24} fill={theme.palette.primary.main} />
                    </span>
                    <div className="menu-item-text">
                        <h5 className={`menu-item-label${isOpen || isThisPathActive ? '--open' : ''}`}>
                            <IntlMessages id="label.client" />
                        </h5>
                        {showInfo ?
                            <h6>{title}</h6>
                        :   null}
                    </div>
                    <div
                        className={`menu-item-arrow${isOpen ? '--open' : '--closed'} default-svg${isOpen || isThisPathActive ? '--open' : ''}`}
                    >
                        <ArrowIcon height={24} fill={theme.palette.primary.main} />
                    </div>
                </div>
            </DropdownToggle>

            <DropdownMenu end>
                {showInfo && (
                    <SectionInfo
                        title={isFieldDefined(title) ? title : ''}
                        subtitle={subtitle}
                        userTypeID={userTypeID}
                        handlerClearSelection={handlerClearClient}
                        handleGoTo={handleGoToClient}
                        isThisPathActive={isThisPathActive}
                        segmento_descricao={
                            (
                                ([getCompanyProfileIds().EDP_ES, getCompanyProfileIds().EDP_PT].includes(companyProfileId) || isB2C) &&
                                isFieldDefined(segmento_descricao)
                            ) ?
                                `| ${segmento_descricao}`
                            :   ''
                        }
                    />
                )}
                <SectionSearch
                    handleChangeAutocomplete={handleChangeAutocomplete}
                    searchData={searchData}
                    userTypeID={userTypeID}
                    companyProfileId={companyProfileId}
                    isB2C={isB2C}
                />

                {/*allowedToCreateClient &&
                    <div className="section-btn-add">
                        <Button className="tertiary-button m-3"
                            disableRipple={true}
                            onMouseDown={handlerGoToCreateClient}
                        >
                            <AddClientIcon className="me-1" />
                            <span><IntlMessages id="page.client.addClient" /></span>
                        </Button>
                    </div>
                */}
            </DropdownMenu>
        </Dropdown>
    );
};

//PropTypes
ClientCard.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleChangeAutocomplete: PropTypes.func.isRequired,
    searchData: PropTypes.object.isRequired,
    userTypeID: PropTypes.number.isRequired,
    companyProfileId: PropTypes.number.isRequired,
    handlerClearClient: PropTypes.func,
};
export default memo(ClientCard);
