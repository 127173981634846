import { getResetPassword } from 'api/auth';
import { MSG_RESETPW_ERROR, MSG_RESETPW_SUCCESS } from 'constants/auth';
import { getMessageError } from 'constants/statusCodeHttp';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { notify } from 'services/@efz/notify';

const useResetPawword = () => {
    const [requesting, setRequesting] = useState(false);

    const resetPassword = async (email: string) => {
        setRequesting(true);

        const rspReset = await getResetPassword(email);

        switch (rspReset?.status) {
            case StatusCodes.OK: {
                notify(MSG_RESETPW_SUCCESS, 'success');
                break;
            }
            case StatusCodes.BAD_REQUEST: {
                notify(MSG_RESETPW_ERROR, 'error');
                break;
            }
            default: {
                notify(getMessageError(rspReset), 'error');
            }
        }
        setRequesting(false);
    };
    return {
        requesting,
        resetPassword,
    };
};
export default useResetPawword;
