import { USERS_TYPE_ID } from 'constants/user';
import clone from 'fast-copy';
import { isDefined } from 'services/util/auxiliaryUtils';
import { Nullable } from 'types/utils';

// * DEFAULT OBJECTS * \\

export const TYPE_MODULES = {
    MODULES: 'modules',
    SUB_PRODUCTS: 'subProducts',
};

export const SUB_PRODUCTS = {
    INSTALLATION: {
        ID: 1,
        LABEL: 'installation',
    },
    OPERATION: {
        ID: 2,
        LABEL: 'operation',
    },
};
export const MODULES = {
    SIMPLE: 'simple',
    DETAILED: 'detailed',
    BASE: 'base',
    CUSTOM: 'custom',
    PORTFOLIO: 'portfolio',
    RECOMMENDATION: 'recommendation',
    FAST: 'fast',
};

export const TYPE_SIZING_MODULES = {
    SIMPLES: 'SIMPLES_Dimensionamento',
    DETAILED: 'DETAILED_Dimensionamento',
    BASE: 'BASE_SIMPLES_Dimensionamento',
    CUSTOM: 'CUSTOM_SIMPLES_Dimensionamento',
};

//Modules default Objects
const PORTFOLIO = {
    order: 1,
    name: MODULES.PORTFOLIO,
    title: 'label.portfolio',
    subtitle: 'label.module',
    description: 'page.modules.portfolio.description',
    isRecommended: false,
};
const RECOMMENDATION = {
    order: 2,
    name: MODULES.RECOMMENDATION,
    title: 'label.recommendationEDP',
    subtitle: 'label.module',
    description: 'page.modules.recommendation.description',
    isRecommended: true,
};
export const SIMPLE = {
    order: 1,
    name: MODULES.SIMPLE,
    title: 'label.simplified',
    subtitle: 'label.module',
    description: 'page.modules.simplified.description',
    background: require('assets/images/modules/modulo-rapido-select.jpg'),
    isRecommended: true,
};
const DETAILED = {
    order: 2,
    name: MODULES.DETAILED,
    title: 'label.detailed',
    subtitle: 'label.module',
    description: 'page.modules.detailed.description',
    background: require('assets/images/modules/modulo-detalhado-select.jpeg'),
    isRecommended: false,
};

export const PRODUCT_IDS = {
    SPV: 1, //SOLAR_PHOTOVOLTAIC
    IE: 2, //EFFICIENT_LIGHTING
    HP: 3, //HEAT_PUMPS
    INTEGRA: 4, //CORRECTIVE_MAINTENANCE_INSURANCE
    GBHP: 5, //GAS_BOILERS_AND_HEAT_PUMPS
    HVAC: 6, //HEATING_VENTILATION_AND_AIR_CONDITIONING
    CE: 7, //ENERGY_CERTIFICATIONS_AND_AUDITS
    CFP: 8, //POWER_FACTOR_CORRECTION
    SANTS: 9, //INCREASE_IN_VOLTAGE_LEVEL_SERVICE
    MAR: 10, //HIGH_EFFICIENCY_MOTORS
    VEV: 11, //VARIABLE_SPEED_DRIVES
    MPT: 14, //VOLTAGE_TRANSFORMERS_MAINTENANCE
    EVC: 16, //ELECTRIC VEHICLE CHARGER or/ ELECTRIC_MOBILITY
    GNS: 17, //NATURAL GAS AND AGGREGATED SERVICES
    CAS: 19, //COMPRESSED_AIR_SYSTEM
    TRE: 20, //ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN
    BP: 21, //BUILDING_PORTFOLIO
    BS: 22, //BUILDING_SCOPE
    SS: 23, //SOLAR_SUITE
    EO: 24, //ENERGY_OPTIX
    SANTT: 25, //INCREASE_IN_VOLTAGE_LEVEL_TARIFF
    EP: 26, //PROPOSAL ENERGY
    SPVSB: 27, //SOLAR PHOTOVOLTAIC SMALL BUSINESS
    IE_BR: 28, //EFFICIENT_LIGHTING BR
    HVAC_BR: 29, //HEATING_VENTILATION_AND_AIR_CONDITIONING_2_0 BR
    EVCSB: 30, //ELECTRIC VEHICLE CHARGER SMALL BUSINESS
    SPV_SIMPLE: 31, //SPV SIMPLE,
    RR: 32,
    SPV_MAINTENANCE: 33,
} as const;

//Products Object
export const DEFAULT_PRODUCTS = [
    //SPV
    {
        id: PRODUCT_IDS.SPV,
        subProducts: null,
        modules: [SIMPLE],
    },
    //IE
    {
        id: PRODUCT_IDS.IE,
        subProducts: null,
        modules: [
            //Custom Simple Module to become Quick
            {
                ...SIMPLE,
                title: SIMPLE.title.replace('simple', 'quick'),
            },
            DETAILED,
        ],
    },
    //Integra
    {
        id: PRODUCT_IDS.INTEGRA,
        subProducts: null,
        modules: [SIMPLE],
    },
    //GBHP
    {
        id: PRODUCT_IDS.GBHP,
        subProducts: null,
        modules: [SIMPLE],
    },
    //HVAC
    {
        id: PRODUCT_IDS.HVAC,
        subProducts: null,
        modules: [SIMPLE],
    },
    //CE
    {
        id: PRODUCT_IDS.CE,
        subProducts: null,
        modules: [
            {
                ...PORTFOLIO,
                isRecommended: true,
                description: 'page.modules.portfolio.ce.description',
                background: require('assets/images/modules/modulo-rapido-select.jpg'),
            },
            {
                ...RECOMMENDATION,
                isRecommended: false,
                description: 'page.modules.recommendation.ce.description',
                background: require('assets/images/modules/modulo-detalhado-select.jpeg'),
            },
        ],
    },
    //CFP
    {
        id: PRODUCT_IDS.CFP,
        subProducts: null,
        modules: [SIMPLE],
    },
    //SANTS
    {
        id: PRODUCT_IDS.SANTS,
        subProducts: null,
        modules: [SIMPLE],
    },
    //MAR
    {
        id: PRODUCT_IDS.MAR,
        subProducts: null,
        modules: [SIMPLE, DETAILED],
    },
    //VEV
    {
        id: PRODUCT_IDS.VEV,
        subProducts: null,
        modules: [SIMPLE, DETAILED],
    },
    //MPT
    {
        id: PRODUCT_IDS.MPT,
        subProducts: null,
        modules: [SIMPLE],
    },
    //EVC
    {
        id: PRODUCT_IDS.EVC,
        subProducts: null,
        modules: [SIMPLE],
    },
    //MESB
    {
        id: PRODUCT_IDS.EVCSB,
        subProducts: null,
        modules: [SIMPLE],
    },
    //MESB
    {
        // @ts-ignore
        id: PRODUCT_IDS.MESB,
        subProducts: null,
        modules: [SIMPLE],
    },
    //GNS
    {
        id: PRODUCT_IDS.GNS,
        subProducts: null,
        modules: [SIMPLE],
    },
    //TRE
    {
        id: PRODUCT_IDS.TRE,
        subProducts: null,
        modules: [SIMPLE],
    },
    //BP
    {
        id: PRODUCT_IDS.BP,
        subProducts: null,
        modules: [SIMPLE],
    },
    //BS
    {
        id: PRODUCT_IDS.BS,
        subProducts: null,
        modules: [SIMPLE],
    },
    //SS
    {
        id: PRODUCT_IDS.SS,
        subProducts: null,
        modules: [SIMPLE],
    },
    //EO
    {
        id: PRODUCT_IDS.EO,
        subProducts: null,
        modules: [SIMPLE],
    },
    //SANTT
    {
        id: PRODUCT_IDS.SANTT,
        subProducts: null,
        modules: [SIMPLE],
    },
    //SPVSB
    {
        id: PRODUCT_IDS.SPVSB,
        subProducts: null,
        modules: [SIMPLE],
    },
    //IE_BR
    {
        id: PRODUCT_IDS.IE_BR,
        subProducts: null,
        modules: [DETAILED],
    },
    //HVAC_BR
    {
        id: PRODUCT_IDS.HVAC_BR,
        subProducts: null,
        modules: [DETAILED],
    },
    //HP
    {
        id: PRODUCT_IDS.HP,
        subProducts: null,
        modules: [],
    },
    //SPV_SIMPLE
    {
        id: PRODUCT_IDS.SPV_SIMPLE,
        subProducts: null,
        modules: [],
    },
    //RR
    {
        id: PRODUCT_IDS.RR,
        subProducts: null,
        modules: [],
    },
    //SPV_MAINTENANCE
    {
        id: PRODUCT_IDS.SPV_MAINTENANCE,
        subProducts: null,
        modules: [],
    },
];

//Product BANNER according to image format and id
export const PRODUCTS_IN_PNG_BY_PRODUCTS_IDS = [
    PRODUCT_IDS.SPV,
    PRODUCT_IDS.IE,
    PRODUCT_IDS.IE_BR,
    PRODUCT_IDS.CE,
    PRODUCT_IDS.MPT,
    PRODUCT_IDS.TRE,
    PRODUCT_IDS.GNS,
    PRODUCT_IDS.EP,
    PRODUCT_IDS.IE_BR,
    PRODUCT_IDS.EVC,
    PRODUCT_IDS.EVCSB,
    PRODUCT_IDS.SPVSB,
    PRODUCT_IDS.HP,
    PRODUCT_IDS.INTEGRA,
    PRODUCT_IDS.GBHP,
    PRODUCT_IDS.HVAC,
    PRODUCT_IDS.CFP,
    PRODUCT_IDS.SANTS,
    PRODUCT_IDS.MAR,
    PRODUCT_IDS.CAS,
    PRODUCT_IDS.SANTT,
    PRODUCT_IDS.HVAC_BR,
    PRODUCT_IDS.SPV_SIMPLE,
    PRODUCT_IDS.SPV_MAINTENANCE,
];

export const PRODUCTS_IN_JPEG_BY_PRODUCTS_IDS = [PRODUCT_IDS.VEV];

export const ALLOWED_SUB_PRODUCTS = [SUB_PRODUCTS.INSTALLATION.LABEL, SUB_PRODUCTS.OPERATION.LABEL];
export const ALLOWED_MODULES = [
    MODULES.SIMPLE,
    MODULES.DETAILED,
    MODULES.PORTFOLIO,
    MODULES.RECOMMENDATION,
    MODULES.BASE,
    MODULES.CUSTOM,
    MODULES.FAST,
];

export const PRODUCTS_IDS_IMPLEMENTED = [
    PRODUCT_IDS.SPV,
    PRODUCT_IDS.IE,
    PRODUCT_IDS.INTEGRA,
    PRODUCT_IDS.HP, // TODO: @uncomment to show HP on BASE UK
    // PRODUCT_IDS.GBHP,
    // PRODUCT_IDS.HVAC,
    PRODUCT_IDS.CE,
    PRODUCT_IDS.CFP,
    // PRODUCT_IDS.SANTS,
    // PRODUCT_IDS.MAR,
    // PRODUCT_IDS.VEV,
    PRODUCT_IDS.MPT,
    PRODUCT_IDS.GNS,
    PRODUCT_IDS.EVC,
    PRODUCT_IDS.EVCSB,
    PRODUCT_IDS.TRE,
    // PRODUCT_IDS.BP,
    // PRODUCT_IDS.BS,
    // PRODUCT_IDS.SS,
    // PRODUCT_IDS.EO
    PRODUCT_IDS.SPVSB,
    PRODUCT_IDS.IE_BR,
    PRODUCT_IDS.HVAC_BR,
    PRODUCT_IDS.SPV_SIMPLE,
    PRODUCT_IDS.RR,
    PRODUCT_IDS.SPV_MAINTENANCE,
] as const;

//Get Product Modules and SubProducts
export const productModulesSubProducts = (
    productId,
    userTypeID,
    featureFlags,
    editing: { spvVersion: Nullable<'v3' | 'v2'> } = { spvVersion: null }
) => {
    //Get Product Default
    const product = clone(DEFAULT_PRODUCTS).find((product) => product.id === productId);

    if (!!product && [PRODUCT_IDS.SPV, PRODUCT_IDS.SPVSB].includes(product.id)) {
        product.modules = [];
        if (isDefined(editing?.spvVersion) && editing.spvVersion === 'v2') {
            product.modules.push(SIMPLE);
        }
    }
    if (productId === PRODUCT_IDS.CFP && userTypeID !== USERS_TYPE_ID.MANAGER) {
        //Client Exceptions
        // CFP
        // @ts-ignore
        product.modules = null;
    }
    // CE
    if (productId === PRODUCT_IDS.CE && userTypeID === USERS_TYPE_ID.CLIENT) {
        // @ts-ignore
        product.modules = [RECOMMENDATION];
    }
    //Return Object
    return product;
};

//Get Product Banner
export const productBanner = (tp_id) => {
    const bannerPath = `${process.env.PUBLIC_URL}/assets/images/banners/`;

    // not found
    // @ts-ignore
    if (!PRODUCTS_IN_PNG_BY_PRODUCTS_IDS.includes(parseInt(tp_id)) && !PRODUCTS_IN_JPEG_BY_PRODUCTS_IDS.includes(parseInt(tp_id)))
        return `${bannerPath}404.png`;

    // @ts-ignore
    return PRODUCTS_IN_PNG_BY_PRODUCTS_IDS.includes(parseInt(tp_id)) ? `${bannerPath}${tp_id}.png` : `${bannerPath}${tp_id}.jpeg`;
};

export const productImgName = (tp_id) => {
    // @ts-ignore
    return PRODUCTS_IN_PNG_BY_PRODUCTS_IDS.includes(parseInt(tp_id)) ? `${tp_id}.png` : `${tp_id}.jpeg`;
};
