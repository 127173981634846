import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Rubrics from 'components/BusinessModels/_old/OfferEditionDetailled/ProjectSummary/OfferSummary/Rubrics';
import CustomUnit from 'components/util/CustomUnit';
import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { UserContext } from 'contexts/userContext';
import { useContext, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getCompanyProfileIds } from 'services/user';
import validate from 'services/util/validate';

const ProjectSummaryServicePPaDetailed = () => {
    const { companyProfileId } = useContext(UserContext);

    const {
        bmState,
        bmState: { loadings },
        negotiationProfile,
    } = useContext(OfferEditionContext);

    const { control, watch } = useFormContext();

    const showCapex = bmState.businessModelSelected?.show_capex ?? false;
    const baseCost = useMemo(
        () =>
            showCapex ?
                // @ts-ignore
                bmState?.kpis?.kpis?.system?.costs?.original_capex ?? bmState?.kpis?.kpis?.system?.costs?.capex
            :   // @ts-ignore
                bmState.kpis?.kpis?.cost,
        [] //eslint-disable-line
    );
    const watchExtraCost = watch('extraCost');
    const watchRubricsCosts = useWatch({
        control,
        name: 'rubrics',
    });

    const totalCost = useMemo(() => {
        let totalCost = 0;
        const totalRubrics = watchRubricsCosts?.reduce((acc, curr) => {
            if (curr?.selected) return acc + curr?.default_cost;
            else return acc;
        }, 0);
        totalCost = baseCost + Number(watchExtraCost ?? 0) + Number(totalRubrics ?? 0);
        return totalCost;
    }, [baseCost, watchExtraCost, watchRubricsCosts]);

    return (
        <div className="bm-detailed-offer-summary-costs w-100">
            <div className="input-container bold">
                <label>
                    <IntlMessages id={`label.base${showCapex ? 'Capex' : 'Package'}`} />
                </label>
                {/* @ts-ignore */}
                <FormatNumber unit={'currency'} numberOfDecimalPlaces={0} number={baseCost} />
            </div>
            {negotiationProfile?.acesso_custo_extra && (
                <div className="input-container">
                    <label>
                        <IntlMessages id={`label.${showCapex ? 'capexAdjustment' : 'costAdjustment'}`} />
                    </label>
                    <label />
                    <Controller
                        name="extraCost"
                        control={control}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { ref, ...field } }) => {
                            return (
                                <OutlinedInput
                                    {...field}
                                    type="number"
                                    disabled={loadings.general}
                                    // @ts-ignore
                                    rules={validate(['required', 'number'])}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {/* @ts-ignore */}
                                            {<CustomUnit unit="currency" />}
                                        </InputAdornment>
                                    }
                                />
                            );
                        }}
                    />
                </div>
            )}
            {[getCompanyProfileIds().EDP_IT].includes(companyProfileId) && <Rubrics />}
            <div className="input-container bold total">
                <label>
                    <IntlMessages id={`label.${showCapex ? 'totalCapex' : 'totalCost'}`} />
                </label>
                {/* @ts-ignore */}
                <FormatNumber unit={'currency'} numberOfDecimalPlaces={0} number={totalCost} />
            </div>
        </div>
    );
};

export default ProjectSummaryServicePPaDetailed;
