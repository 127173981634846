import { useContext, useCallback, useState, useEffect } from 'react';
import IntlMessages from 'components/util/IntlMessages';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront, UpFrontEvents } from 'interfaces/businessModels/upfront';
import Slider from '@mui/material/Slider';
import FormatNumber from 'components/util/FormatNumber';
import { debounce, isFieldDefined } from 'services/util/auxiliaryUtils';
import { DEBOUNCE_TIME } from 'constants/endpoints';
import { PRODUCT_IDS } from 'constants/products';

function findMargin(newPerc, sliderStep, searchValue, upFrontMarginValues) {
    let percentValue = newPerc;
    let kpisValue = null;
    const lastPerc = upFrontMarginValues[upFrontMarginValues?.length - 1]?.[searchValue];

    do {
        // eslint-disable-next-line no-inner-declarations
        function findKpisMargin(percent) {
            const value = upFrontMarginValues?.find((item) => item?.[searchValue] === percent)?.[searchValue];
            return value;
        }

        kpisValue = findKpisMargin(percentValue);
        percentValue = parseFloat((percentValue + sliderStep).toFixed(5));
        if (percentValue > lastPerc) {
            kpisValue = findKpisMargin(lastPerc);
            break;
        }
    } while (!isFieldDefined(kpisValue));
    // @ts-ignore
    return kpisValue;
}

const UpFrontNonMarksMargin = () => {
    const { bmSelected, setBMEventHandler, productID, isRequestingBM } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TUpFront
    >;

    const upFrontMarginValues = bmSelected?.values.kpis_per_margin;
    const searchValue = bmSelected?.values?.margin_negotiation?.search ?? 'margin';
    let sliderStep = parseFloat((upFrontMarginValues?.[1]?.margin - upFrontMarginValues?.[0]?.margin).toFixed(5));
    if (sliderStep < 0) sliderStep = -sliderStep;
    const [sliderValue, setSliderValue] = useState(bmSelected?.values?.margin_negotiation?.default);

    const handleSliderChange = useCallback((_, newValue) => {
        // @ts-ignore
        return setSliderValue(findMargin(newValue, sliderStep, searchValue, upFrontMarginValues));
    }, []); //eslint-disable-line

    // eslint-disable-next-line
    const delayedNewMarginHandler = useCallback(
        // eslint-disable-next-line
        debounce(
            (payload) =>
                setBMEventHandler(
                    UpFrontEvents.SET_UPFRONT_MARGIN,
                    upFrontMarginValues?.find((value) => value?.[searchValue] === Number(payload))?.[searchValue]
                ),
            DEBOUNCE_TIME
        ),
        []
    ); // eslint-disable-line

    useEffect(() => {
        // @ts-ignore
        delayedNewMarginHandler(sliderValue);
    }, [sliderValue]); // eslint-disable-line

    //Handle Committed Changes of Slider
    const handleCommittedSliderChange = useCallback(
        (_, newValue) => {
            // @ts-ignore
            setSliderValue(findMargin(newValue, sliderStep, searchValue, upFrontMarginValues));
        },
        [upFrontMarginValues, searchValue] //eslint-disable-line
    );

    return (
        <div className="bm-margin">
            <div className="bm-margin-label">
                <IntlMessages id={bmSelected?.isDiscount ? 'label.selectDiscount' : 'label.selectMargin'} />
            </div>
            <div className="bm-margin-slider">
                {bmSelected?.values?.kpis_per_margin?.length > 0 && (
                    <Slider
                        disabled={isRequestingBM}
                        aria-label="margin-slider"
                        aria-labelledby="discrete-slider-restrict"
                        defaultValue={sliderValue}
                        min={upFrontMarginValues?.[0]?.[searchValue] ?? 0}
                        max={upFrontMarginValues?.[upFrontMarginValues?.length - 1]?.[searchValue]}
                        step={sliderStep}
                        valueLabelDisplay="on"
                        // @ts-ignore
                        valueLabelFormat={(value) => <FormatNumber styleFormat="percent" number={value} numberOfDecimalPlaces={2} />}
                        onChange={handleSliderChange}
                        onChangeCommitted={(evt, value) => {
                            handleCommittedSliderChange(evt, value);
                            if ([PRODUCT_IDS.HP].includes(productID)) {
                                // @ts-ignore
                                window._paq.push(['trackEvent', 'Business Models', 'Click', 'Margin']);
                            }
                        }}
                        value={sliderValue}
                    />
                )}
            </div>
        </div>
    );
};

export default UpFrontNonMarksMargin;
