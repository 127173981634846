import { getGrants } from 'api/products/solarpvSimple';
import { useState } from 'react';
import { SPV_SIMPLE_INPUTS as INPUT_NAMES } from 'constants/products/solarpvSimple';

const useGrants = () => {
    const [isRequestingGrant, setIsRequestingGrant] = useState(false);
    const [options, setOptions] = useState([]);

    const fetchGrants = async (PanelPeakPower, batteryCapacity, pv_station_price, battery_price) => {
        let params = {
            peak_power: PanelPeakPower,
            battery_capacity: batteryCapacity,
            pv_station_price: pv_station_price,
            battery_price: battery_price,
        };

        try {
            setIsRequestingGrant(true);
            let response = await getGrants(true, params);
            setIsRequestingGrant(false);
            setOptions(response.data.data);
        } catch (error) {
            setIsRequestingGrant(false);
        }
    };

    const getKpisGrants = ({ options, watchGrantId, setValue }) => {
        let grantValues = options?.find((option) => option?.grant_id === parseInt(watchGrantId)) ?? null;
        let finalgrantValues = {
            grant_id: grantValues?.grant_id,
            subsidy_investment_pv_station_prc: grantValues?.subsidy_details?.subsidy_prc?.pv_station,
            subsidy_investment_battery_prc: grantValues?.subsidy_details?.subsidy_prc?.battery,
            subsidy_investment_pv_station_value: grantValues?.subsidy_details?.subsidy_value?.pv_station,
            subsidy_investment_battery_value: grantValues?.subsidy_details?.subsidy_value?.battery,
        };

        setValue(INPUT_NAMES.GRANT_SP_BATTERY, finalgrantValues?.subsidy_investment_battery_prc);
        setValue(INPUT_NAMES.GRANT_SP_PV_STATION, finalgrantValues?.subsidy_investment_pv_station_prc);
        setValue(INPUT_NAMES.GRANT_SV_BATTERY, finalgrantValues?.subsidy_investment_pv_station_value);
        setValue(INPUT_NAMES.GRANT_SV_PV_STATION, finalgrantValues?.subsidy_investment_battery_value);
    };

    return {
        fetchGrants,
        isRequestingGrant,
        getKpisGrants,
        options,
    };
};

export default useGrants;
