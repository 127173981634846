import { createSelector } from 'reselect';
import { isFieldDefined } from 'services/util/auxiliaryUtils';

export const getFacility = createSelector(
    (state) => state,
    (state) => state
);
export const getFacilityInfo = createSelector(
    (state) => state?.info,
    (info) => info
);
export const getFacilityList = createSelector(
    (state) => state?.info?.list,
    (list) => list
);
export const getFacilityOptions = createSelector(
    (state) => state?.options,
    (options) => options
);
export const isRequesting = createSelector(
    (state) => state?.isRequesting,
    (isRequesting) => isRequesting
);
export const isRequestingGridTariffs = createSelector(
    (state) => state?.isRequestingGridTariffs,
    (isRequestingGridTariffs) => isRequestingGridTariffs
);
export const getFacilityID = createSelector(
    (state) => state?.info?.id,
    (id) => id
);
export const getFacilityRegionGD = createSelector(
    (state) => state?.info?.regiao_gd_id,
    (regiao_gd_id) => regiao_gd_id
);
export const getFacilityLatitude = createSelector(
    (state) => state?.info,
    (info) => {
        if (isFieldDefined(info.latitude)) return info.latitude; //B2B
        if (isFieldDefined(info.location?.latitude)) return info.location?.latitude; //B2C
        return 0; //fallback
    }
);
export const getFacilityLongitude = createSelector(
    (state) => state?.info,
    (info) => {
        if (isFieldDefined(info.longitude)) return info.longitude; //B2B
        if (isFieldDefined(info.location?.longitude)) return info.location?.longitude; //B2C
        return 0; //fallback
    }
);
export const isOpenFacilityCard = createSelector(
    (state) => state?.isOpenFacilityCard,
    (isOpenFacilityCard) => isOpenFacilityCard
);
export const isRequestingSave = createSelector(
    (state) => state?.isRequestingSave,
    (isRequestingSave) => isRequestingSave
);
export const isRequestingCAEWeights = createSelector(
    (state) => state?.isRequestingCAEWeights,
    (isRequestingCAEWeights) => isRequestingCAEWeights
);
export const isRequestingAnnualConsumption = createSelector(
    (state) => state?.isRequestingAnnualConsumption,
    (isRequestingAnnualConsumption) => isRequestingAnnualConsumption
);
export const isRequestingMonthlyConsumption = createSelector(
    (state) => state?.isRequestingMonthlyConsumption,
    (isRequestingMonthlyConsumption) => isRequestingMonthlyConsumption
);
export const isCAEWithNoData = createSelector(
    (state) => state?.isCAEWithNoData,
    (isCAEWithNoData) => isCAEWithNoData
);
export const isRequestUpload = createSelector(
    (state) => state?.isRequestUpload,
    (isRequestUpload) => isRequestUpload
);
export const isRequestDownload = createSelector(
    (state) => state?.isRequestDownload,
    (isRequestDownload) => isRequestDownload
);

export const getFacilityAddress = createSelector(
    (state) => state?.info?.morada,
    (morada) => morada
);
export const getDetailedAddress = createSelector(
    (state) => state?.info?.location,
    (location) => location
);
export const getFacilityCPE = createSelector(
    (state) => state?.info?.cpe,
    (cpe) => cpe
);
export const getFacilityNetArea = createSelector(
    (state) => state?.info?.area_util,
    (area_util) => area_util
);
export const getFacilityNumFases = createSelector(
    (state) => state?.info,
    (info) => {
        return (
            isFieldDefined(info?.inputs_consumo) ? parseInt(info?.numero_fases_id) /* B2C */
            : info?.num_fases === 1 ? 1
            : 2
        ); /* B2B  TODO: id=2 quando é trifásico*/
    }
);
// export const getFacilityContractedPower = createSelector(state => state?.info?.potencia_contratada, potencia_contratada => potencia_contratada);
export const getFacilityContractedPower = createSelector(
    (state) => state?.info,
    (info) => info?.potencia_contratada ?? ''
);
export const getFacilityAnnualConsumption = createSelector(
    (state) => state?.info?.consumo_anual,
    (consumo_anual) => consumo_anual
);
export const getFacilityAnnualConsumptionMWh = createSelector(
    (state) => state?.info?.consumo_anual_mwh,
    (consumo_anual_mwh) => consumo_anual_mwh
);
export const getFacilityVoltageLevels = createSelector(
    (state) => state?.options?.voltageLevels,
    (voltageLevels) => voltageLevels
);
export const getFacilityConectionVoltages = createSelector(
    (state) => state?.options?.conectionVoltages,
    (conectionVoltages) => conectionVoltages
);
export const getFacilityDistributors = createSelector(
    (state) => state?.options?.distributors,
    (distributors) => distributors
);
export const getFacilitySolutions = createSelector(
    (state) => state?.info?.efficiency_measures,
    (efficiency_measures) => efficiency_measures
);
export const getFacilityopcaoCurvaCargaID = createSelector(
    (state) => state?.info?.opcao_curva_carga_id,
    (opcao_curva_carga_id) => opcao_curva_carga_id
);
export const getFacilityHasLoadCurveFile = createSelector(
    (state) => state?.info?.nome_ficheiro_curva_carga,
    (nome_ficheiro_curva_carga) => nome_ficheiro_curva_carga
);
export const getForwardFormFacility = createSelector(
    (state) => state?.forwardFormID,
    (forwardFormID) => forwardFormID
);
export const getFacilityClasses = createSelector(
    (state) => state?.options?.classes,
    (classes) => classes
);
export const getFacilityVoltageLevelID = createSelector(
    (state) => state?.info?.nivel_tensao_id,
    (nivel_tensao_id) => (!isNaN(parseInt(nivel_tensao_id)) ? parseInt(nivel_tensao_id) : null)
);
export const getFacilityDistributorId = createSelector(
    (state) => state?.info?.distribuidor_id,
    (distribuidor_id) => distribuidor_id
);
export const isRequestingDistributors = createSelector(
    (state) => state?.options?.isRequestingDistributors,
    (isRequestingDistributors) => isRequestingDistributors
);
export const getFacilityPostalCode = createSelector(
    (state) => state?.info,
    (info) => {
        if (isFieldDefined(info.codigo_postal)) return info.codigo_postal; //B2B
        if (isFieldDefined(info.location?.postal_code)) return info.location?.postal_code; //B2C
        return 0; // fallback
    }
);
export const getFacilityValidCurveUploaded = createSelector(
    (state) => state?.info?.validCurveUploaded,
    (validCurveUploaded) => validCurveUploaded
);

//Options
export const getFacilityTypes = createSelector(
    (state) => state?.options?.types,
    (types) => types
);
export const getFacilityNuts = createSelector(
    (state) => state?.options?.nuts,
    (nuts) => nuts
);
export const getFacilityGraphDaily = createSelector(
    (state) => state?.options?.graphs?.daily,
    (daily) => daily
);
export const getFacilityGraphMonthly = createSelector(
    (state) => state?.options?.graphs?.monthly,
    (monthly) => monthly
);
export const isRequestingEstimates = createSelector(
    (state) => state?.options?.loadingEstimates,
    (loadingEstimates) => loadingEstimates
);
export const isRequestingInputsCurveLoad = createSelector(
    (state) => state?.options?.loadingInputsCurveLoad,
    (loadingInputsCurveLoad) => loadingInputsCurveLoad
);
export const getFacilityMonthlyConsumptions = createSelector(
    (state) => state?.options?.monthlyConsumption,
    (monthlyConsumption) => monthlyConsumption
);
export const getFacilityConsumptionWeightsSum = createSelector(
    (state) => state?.options?.consumptionWeightsSum,
    (consumptionWeightsSum) => consumptionWeightsSum
);
export const getFacilityGridTariffs = createSelector(
    (state) => state?.options?.gridTariffs,
    (gridTariffs) => gridTariffs
);
export const getFacilityGridTariffSources = createSelector(
    (state) => state?.options?.gridTariffSources,
    (gridTariffSources) => gridTariffSources
);
export const getFacilityTariffOptions = createSelector(
    (state) => state?.options?.tariffOptions,
    (tariffOptions) => tariffOptions
);
export const getFacilityHourlyOptions = createSelector(
    (state) => state?.options?.hourlyOptions,
    (hourlyOptions) => hourlyOptions
);
export const getFacilityUsedPowerTariffs = createSelector(
    (state) => state?.options?.usedPowerTariffs,
    (usedPowerTariffs) => usedPowerTariffs
);
export const getFacilitySuppliers = createSelector(
    (state) => state?.options?.suppliers,
    (suppliers) => suppliers
);
export const getFacilityEnergyTariffs = createSelector(
    (state) => state?.options?.energyTariffs,
    (energyTariffs) => energyTariffs
);

// ElectricityLoadProfile
export const getFacilityOpcaoTarifarioID = createSelector(
    (state) => state?.info?.opcao_tarifario_id,
    (opcao_tarifario_id) => (!isNaN(parseInt(opcao_tarifario_id)) ? parseInt(opcao_tarifario_id) : null)
);
export const getFacilityTemDadosPonderadorEstimativaSimples = createSelector(
    (state) => state?.info?.tem_dados_ponderador_estimativa_simples,
    (tem_dados_ponderador_estimativa_simples) => tem_dados_ponderador_estimativa_simples
);
export const getFacilityTemDadosPonderadorIntegracaoPersonalizada = createSelector(
    (state) => state?.info?.tem_dados_ponderador_integracao_personalizada,
    (tem_dados_ponderador_integracao_personalizada) => tem_dados_ponderador_integracao_personalizada
);
export const getFacilityTemDadosPonderadorEstimativaSimplesPorPeriodo = createSelector(
    (state) => state?.info?.tem_dados_ponderador_estimativa_simples_por_periodo,
    (tem_dados_ponderador_estimativa_simples_por_periodo) => tem_dados_ponderador_estimativa_simples_por_periodo
);
export const isRequestingPutWeghtsByCustomPeriods = createSelector(
    (state) => state?.isRequestingPutWeghtsByCustomPeriods,
    (isRequestingPutWeghtsByCustomPeriods) => isRequestingPutWeghtsByCustomPeriods
);
