import { getCompanyProfileIds } from 'services/user';

export const ppaKpis = (kpis, monthlyPayment, options) => {
    const { companyProfileId } = options;
    let annual_prod = 0;
    if (companyProfileId && [getCompanyProfileIds().SUNSEAP].includes(options?.companyProfileId)) {
        annual_prod = kpis?.annual_prod; //kwh
    } else {
        annual_prod = kpis?.annual_prod / 1000; //mwh
    }

    const annualEstimatedPrice = monthlyPayment * annual_prod;
    const annualNetSaving = kpis?.annual_saving - annualEstimatedPrice;

    return [
        {
            label: 'label.annualEstimatedPrice',
            value: typeof monthlyPayment === 'string' ? '-' : annualEstimatedPrice,
            numberOfDecimals: 0,
            unit: 'currency',
        },
        {
            label: 'label.annualNetSavings',
            value: typeof monthlyPayment === 'string' ? '-' : annualNetSaving,
            numberOfDecimals: 0,
            unit: 'currency',
        },
    ];
};

export const detailedPPaKpis = (negotiationProfile, finalKpis, options) => {
    const { companyProfileId } = options;
    return [
        {
            label: 'label.tariff',
            value: finalKpis?.monthly_fee ?? 'N/A',
            numberOfDecimals: [getCompanyProfileIds().ROMANDE, getCompanyProfileIds().SUNSEAP].includes(companyProfileId) ? 4 : 2,
            visible: negotiationProfile?.mostra_negociacao_tariff,
            styleFormat: 'decimal',
            unit: ppaTariffUnit(companyProfileId),
        },
        {
            label: 'page.businessModels.tirWacc',
            value: finalKpis?.irr_wacc ?? 'N/A',
            numberOfDecimals: 2,
            visible: negotiationProfile?.slider_tir_wacc,
        },
        {
            label: 'page.businessModels.fixedComission',
            value: finalKpis?.fixed_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_fixa,
            unit: 'currency',
        },
        {
            label: 'page.businessModels.variableComission',
            value: finalKpis?.variable_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_variavel,
            unit: 'currency',
        },
        {
            label: 'page.businessModels.totalComission',
            value: finalKpis?.total_comission ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.slider_comissao_total,
            unit: 'currency',
        },
        {
            label: 'label.tir',
            value: finalKpis?.irr ?? 'N/A',
            numberOfDecimals: 1,
            visible: negotiationProfile?.mostra_slider_tir,
            unit: null,
            styleFormat: 'percent',
        },
        {
            label: 'label.margin',
            value: finalKpis?.specific_margin ?? 'N/A',
            numberOfDecimals: 1,
            visible: negotiationProfile?.mostra_slider_margem_especifica,
            unit: 'currency',
        },
        {
            label: 'label.specificMargin',
            value: finalKpis?.margin_before_overhead ?? 'N/A',
            numberOfDecimals: 1,
            visible: negotiationProfile?.mostra_slider_margem_antes_oh,
            unit: 'ckw',
            styleFormat: 'decimal',
        },
        {
            label: 'label.annualSaving',
            value: finalKpis?.first_year_economics ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.mostra_slider_savings,
            unit: 'currency',
        },
        {
            label: 'label.additionalValue',
            value: finalKpis?.additional_revenue ?? 'N/A',
            numberOfDecimals: 0,
            visible: negotiationProfile?.mostra_slider_valor_adicional,
            unit: 'currency',
        },
        {
            label: 'label.CAPEX',
            value: finalKpis?.model_capex ?? 'N/A',
            numberOfDecimals: 0,
            visible: [getCompanyProfileIds().ROMANDE].includes(companyProfileId),
            unit: 'currency',
        },
        {
            label: 'label.OPEX',
            value: finalKpis?.model_opex ?? 'N/A',
            numberOfDecimals: 0,
            visible: [getCompanyProfileIds().ROMANDE].includes(companyProfileId),
            unit: 'yc',
            styleFormat: 'decimal',
        },
    ];
};

export const annualEvoFeeOptions = () => {
    const DEFAULT_FEE = 0;
    const step = 0.5;
    const maxFee = 2; //max 2.5 (2+0.5)
    let currentFee = 0;
    let options = [];

    while (currentFee <= maxFee + step) {
        options.push({
            key: `annual_evo_fee_${currentFee}`,
            default: currentFee === DEFAULT_FEE ? true : false,
            value: currentFee / 100,
        });
        currentFee = currentFee + step;
    }

    return options;
};

export const ppaTariffUnit = (companyProfileId) => {
    switch (companyProfileId) {
        case getCompanyProfileIds().ROMANDE: {
            return 'cypw';
        }
        case getCompanyProfileIds().SUNSEAP: {
            return 'ckwh';
        }
        case getCompanyProfileIds().PROFILE_BASE_UK:
        case getCompanyProfileIds().EVOLO: {
            return 'ypw';
        }
        default: {
            return 'cmwp';
        }
    }
};
