import { PRODUCT_IDS } from 'constants/products';
import { ISolution /*, TSolutionOptions*/ } from 'interfaces/solutions';

export type TSolutionOptions<id> = id extends typeof PRODUCT_IDS.SPV ? { show_advanced: boolean; show_simple: boolean } : never;

export const solutionWithOptionsProject = (solution: ISolution) => {
    if (!solution.has_project_feature) return false;

    switch (Number(solution.id)) {
        case PRODUCT_IDS.SPV:
        case PRODUCT_IDS.SPVSB: {
            const options: TSolutionOptions<typeof PRODUCT_IDS.SPV> = solution.options;
            return options.show_advanced;
        }
        default:
            return solution?.has_project_feature;
    }
};

export const solutionWithProjectFeature = (solution: ISolution) => {
    if (!solution.has_project_feature) return false;

    switch (Number(solution.id)) {
        case PRODUCT_IDS.SPV:
        case PRODUCT_IDS.SPVSB: {
            const options: TSolutionOptions<typeof PRODUCT_IDS.SPV> = solution.options;
            return solution?.has_project_feature && options.show_advanced;
        }
        default:
            return solution?.has_project_feature;
    }
};
