import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import DetailedKpisESolarDetailed from './DetailedKpis';
import MarginESolarDetailed from './Margin';
import MarginKpisESolarDetailed from './MarginKpis';
import { useContext } from 'react';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';

const NegotiationESolar = () => {
    const { lowNegotiationProfile, negotiationProfile } = useContext(OfferEditionContext);
    return (
        <div className="bm-detailed-negotiation">
            <ProjectSummaryTitle label="page.businessModels.negotiation.title" />
            <MarginESolarDetailed />
            {negotiationProfile?.mostra_negociacao_default_kpis && <MarginKpisESolarDetailed />}
            {!lowNegotiationProfile && <DetailedKpisESolarDetailed />}
        </div>
    );
};

export default NegotiationESolar;
