import { Nullable } from './../../types/utils';
export interface BOReducerAction {
    type: BOActions;
    payload: any;
}

export enum BOLoadings {
    templatesProductID = 'templatesProductID',
    templatesBMs = 'templatesBMs',
    templatesDocs = 'templatesDocs',
    templatesDownloadDocs = 'templatesDownloadDocs',
    templatesUploadDocs = 'templatesUploadDocs',
    templatesProposalDetails = 'templatesProposalDetails',
    templatesPostSimulation = 'templatesPostSimulation',
    templatesSupportDocs = 'templatesSupportDocs',
    templatesPutDoc = 'templatesPutDoc',
    templatesProposalList = 'templatesProposalList',
    templatesProposalClients = 'templatesProposalClients',
}

export type BOLoadingsType = {
    templatesProductID: boolean;
    templatesBMs: boolean;
    templatesDocs: boolean;
    templatesDownloadDocs: Nullable<number>;
    templatesUploadDocs: boolean;
    templatesProposalDetails: boolean;
    templatesPostSimulation: boolean;
    templatesSupportDocs: boolean;
    templatesPutDoc: boolean;
    templatesProposalList: boolean;
    templatesProposalClients: boolean;
};

export interface BOInitialState {
    product: {
        productID: number | null;
        tag: string | null;
    };
    templates: {
        businessModels: Nullable<any[]>;
        selectedBM: any | null;
        docs: any[];
        selectedDoc: any | null;
        originalUploadedDocName: any | null;
        uploadedDocAzureName: any | null;
        proposal: any | null;
        simId: any | null;
        proposalList: {
            clientList: any[];
            original: any[];
            filtered: any[];
        };
    };
    loadings: BOLoadingsType;
}

export enum BOActions {
    SET_LOADINGS = 'SET_LOADINGS',
    SET_PRODUCT_ID = 'SET_PRODUCT_ID',
    SET_BUSINESS_MODELS = 'SET_BUSINESS_MODELS',
    SET_SELECTED_BM = 'SET_SELECTED_BM',
    SET_DOCUMENT_TEMPLATES = 'SET_DOCUMENT_TEMPLATES',
    SET_SELECTED_DOC = 'SET_SELECTED_DOC',
    SET_UPLOAD_DOC_AZURE_NAME = 'SET_UPLOAD_DOC_AZURE_NAME',
    SET_TEMPLATE_PROPOSAL_DETAILS = 'SET_TEMPLATE_PROPOSAL_DETAILS',
    SET_SIMULATION_ID = 'SET_SIMULATION_ID',
    SET_PROPOSAL_LIST = 'SET_PROPOSAL_LIST',
    SET_FILTERED_PROPOSAL_LIST = 'SET_FILTERED_PROPOSAL_LIST',
    SET_PROPOSAL_CLIENT_LIST = 'SET_PROPOSAL_CLIENT_LIST',
    SET_RESET_TEMPLATES = 'SET_RESET_TEMPLATES',
    SET_RESET_ALL = 'SET_RESET_ALL',
}
