import { defineMessages } from 'react-intl';
import frMessages from '../locales/fr_FR.json';

const frFRLang = {
    messages: {
        ...frMessages,
    },
    locale: 'fr-FR',
    data: defineMessages,
};
export default frFRLang;
