//Libraries
import clone from 'fast-copy';
//Constants
import { PRODUCT_IDS, MODULES } from 'constants/products';
import { CERTIFICATION_TYPE } from 'constants/products/ce';
import {
    getParseNumber,
    parseNumberWithDecimals,
    intlMessages,
    parseNumberWithToFixed,
    isDefined,
    isExistWord,
    isFieldDefined,
} from 'services/util/auxiliaryUtils';
import {
    PAYMENT_MODELS_IDS,
    KPIS_PER_PAYMENT_MODEL,
    KPIS_PER_PAYMENT_MODEL_PV,
    KPIS_PER_PAYMENT_MODEL_ME,
    KPIS_PER_PAYMENT_MODEL_PPA,
    KPIS_PER_PAYMENT_MODEL_PV_SERVICE_AUX,
    INPUTS_NAME_NEGOCIATIONS,
    BUSINESS_MODEL_IDS,
    DEFAULT_MANAGEMENT_PLATFORM_MONTHLY_FEE_KPI_EVC_BR,
    SIZING_TYPE_IDS,
    BUSINESS_MODEL_ELIGIBLE,
    BUSINESS_MODEL_NON_ELIGIBLE_REASONS,
    KPIS_PER_PAYMENT_MODEL_RR,
    KPIS_PER_BUSINMESS_MODEL_SPV_MAINTENANCE,
} from 'constants/businessModels';
import { INTERVENTIONS_NUMBER } from 'constants/products/mpt';
import { getCompanyProfileIds } from 'services/user';
import {
    ME_INSTALL_ON_COMPANY_CPE_OPTIONS,
    ME_MANAGEMENT_OPTION_ID,
    SEND_AAS_PRICING_IN_BM,
    SEND_CHARGERS_RANGES_IN_BM,
} from 'constants/products/evc';
import { getInputsPartnerShip, getInputWithTaxes } from './products/evc';
import { USERS_TYPE_ID } from 'constants/user';
import { ppaTariffUnit } from './businessModels/ppaKpis';

//Build
export function buildCustomBusinessCard(module, product_id, options) {
    //Destructure options
    const { businessModelData, sizing } = options;
    let optionsAvailable = [];
    switch (product_id) {
        case PRODUCT_IDS.CE: {
            //Get Card
            let businessCard = businessModelData.pitch.find((pitch) => pitch.name_id === sizing.certification_name_id);
            //Make changes
            if (module === MODULES.RECOMMENDATION) {
                //Get Legal elements
                let summary = '';
                switch (sizing.certification_type_id) {
                    case CERTIFICATION_TYPE.RECS:
                        summary = sizing.options[0].is_mandatory ? intlMessages('page.ce.law_recs') : null;
                        break;
                    case CERTIFICATION_TYPE.AE:
                        summary =
                            sizing.options[0].is_mandatory ?
                                intlMessages('page.ce.law_ae_mandatory')
                            :   intlMessages('page.ce.law_ae_not_mandatory');
                        break;
                    case CERTIFICATION_TYPE.SGCIE:
                        summary = sizing.options[0].is_mandatory ? intlMessages('page.ce.law_sgcie') : null;
                        break;
                    default:
                        break;
                }
                if (!businessCard.sections.find((item) => item.header === 'Informações de Consumo')) {
                    businessCard.sections.push({
                        header: intlMessages('page.ce.consumptionInformation'),
                        order: 0,
                        body: [
                            intlMessages(`page.ce.businessModelCard.prefix_${options?.statistics?.mediana > 0 ? 'more' : 'less'}`, {
                                value: Math.abs(options?.statistics?.mediana),
                            }),
                            intlMessages(`page.ce.businessModelCard.percentil_prefix`, {
                                value: options?.statistics?.percentil,
                            }),
                        ],
                    });
                }
                return {
                    ...businessModelData,
                    pitch: {
                        ...businessCard,
                        recommended: true,
                        summary: summary,
                    },
                };
            }

            businessCard.sections.map((items) => (items.hasInputs = !!items.extra && items.extra[0].input_name === 'extra_picked_id'));
            return {
                ...businessModelData,
                pitch: {
                    ...businessCard,
                },
            };
        }
        case PRODUCT_IDS.MPT: {
            let sections = businessModelData.pitch.sections;
            //Add/Remove section according to number of interventions
            if (options?.interventions_number === INTERVENTIONS_NUMBER.SEMIANNUAL) {
                sections[0].body[3] = sections[0].extra;
            } else {
                if (sections[0].body.length === 4) sections[0].body.pop();
            }

            if (!Object.keys(sections).find((sectionName) => sections[sectionName].header === 'Número de Intervenções')) {
                optionsAvailable = [
                    {
                        label: '1 Intervenção (Anual)',
                        tag: 'page.mpt.bmodel.intervention.1',
                        value: 1,
                        disabled: isNaN(getParseNumber(sizing.solution_option['1'].interventions_number['1'].price)),
                    },
                    {
                        label: '2 Intervenções (Semestral)',
                        tag: 'page.mpt.bmodel.intervention.2',
                        value: 2,
                        disabled: isNaN(getParseNumber(sizing.solution_option['1'].interventions_number['2'].price)),
                    },
                ];
                sections.push({
                    hasInputs: true,
                    header: 'Número de Intervenções',
                    tag: 'page.mpt.bmodel.intervention',
                    order: 0,
                    body: [],
                    extra: [
                        {
                            type: 'radio',
                            label: 'Escolha o número de Intervenções',
                            tag: 'page.mpt.bmodel.intervention.choose',
                            options: optionsAvailable,
                            input_name: 'interventions_number',
                        },
                    ],
                });
            }

            return {
                optionsAvailable,
                ...businessModelData,
                pitch: {
                    ...businessModelData.pitch,
                    ...sections,
                },
            };
        }
        case PRODUCT_IDS.GNS: {
            let sectionsGNS = clone(businessModelData.pitch.sections);
            sectionsGNS = sectionsGNS.map((section) => {
                let body = clone(section.body);
                if (['Servicios incluidos', 'Included services'].includes(section.header)) {
                    body = body.filter((item) => sizing[item.key] !== 'N.A.');
                } else if (['Servicios no incluidos', 'Services not included'].includes(section.header)) {
                    body = body.filter((item) => sizing[item.key] === 'N.A.');
                }
                body = body.map((item) => {
                    switch (item.key) {
                        case 'new_natural_gas_price':
                            item.unit = 'ypw';
                            break;
                        case 'preventive_maintenance_price':
                        case 'meter_reading_price':
                        case 'natural_gas_fixed_term':
                        case 'full_guarantee_price':
                            item.unit = 'mc';
                            break;

                        case 'energy_certification_price':
                            item.unit = 'currency';
                            item.tooltip = (
                                <span>
                                    {intlMessages('page.gns.tooltip')}
                                    <img alt="img" src={require('assets/images/products/gns/TASAS_CE.jpg')} />
                                </span>
                            );
                            item.options = {
                                placement: 'left',
                                customStyles: {
                                    classNamePopover: 'popover-gc popover-gns',
                                },
                            };
                            item.options.isTranslatedTooltip = true;
                            break;

                        default:
                            break;
                    }

                    return item;
                });

                return {
                    ...section,
                    body: body,
                };
            });
            sectionsGNS = sectionsGNS.filter((section) => section.body.length !== 0);
            return { ...businessModelData, pitch: { ...businessModelData.pitch, sections: sectionsGNS } };
        }
        default:
            break;
    }
}
//Format Card KPI
export function buildProjectSummary(product_id, sizing, options) {
    let projectSummary = null;

    switch (product_id) {
        case PRODUCT_IDS.SPVSB:
        case PRODUCT_IDS.SPV:
            if (options.businessModelData.payment_model_id === PAYMENT_MODELS_IDS.LEC) {
                projectSummary = [
                    {
                        label: 'label.price',
                        value: parseNumberWithDecimals(
                            options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].lec_energy_tariff,
                            0
                        ),
                        unit: 'cmwp',
                    },
                    {
                        label: 'label.annualSaving',
                        value: parseNumberWithDecimals(
                            options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].lec_annual_saving,
                            0
                        ),
                        unit: 'currency',
                    },
                ];
            } else if (options.businessModelData.payment_model_id === PAYMENT_MODELS_IDS.SERVICE_PPA) {
                projectSummary = [
                    {
                        label: 'label.price',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].energy_price, 0),
                        unit: 'cmwp',
                    },
                    {
                        label: 'label.annualSaving',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_saving, 0),
                    },
                ];
            } else if (options.businessModelData.payment_model_id === PAYMENT_MODELS_IDS.SERVICE_SECOND_VERSION) {
                projectSummary = [
                    {
                        label: 'label.annualPrice',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_price, 0),
                    },
                    {
                        label: 'label.annualSaving',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_saving, 0),
                    },
                ];
            } else if (options.businessModelData.payment_model_id === PAYMENT_MODELS_IDS.SERVICE) {
                if (
                    [getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_PL, getCompanyProfileIds().EDP_ES].includes(
                        options?.companyProfileId
                    )
                ) {
                    projectSummary = [
                        {
                            label: 'label.monthlyFee',
                            value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].monthly_fee, 0),
                            unit: 'mc',
                        },
                        {
                            label: 'label.annualSaving',
                            value: parseNumberWithDecimals(
                                options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_saving,
                                0
                            ),
                            unit: 'yc',
                        },
                    ];
                } else {
                    projectSummary = [
                        {
                            label: 'label.annualPrice',
                            value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_price, 0),
                        },
                        {
                            label: 'label.annualSaving',
                            value: parseNumberWithDecimals(
                                options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_saving,
                                0
                            ),
                        },
                    ];
                }
            } else {
                projectSummary = [
                    {
                        label: 'label.investment',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].investment, 0),
                    },
                    {
                        label: 'label.payback',
                        value: parseNumberWithDecimals(
                            options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].investment /
                                options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_saving,
                            1
                        ),
                        unit: 'year',
                        numberOfDecimalPlaces: 1,
                    },
                ];
            }
            if (options.businessModelData.payment_model_id === PAYMENT_MODELS_IDS.PAY_AS_PRODUCED) {
                projectSummary = [
                    {
                        label: 'label.price',
                        infoLabel: 'label.tariffDiscount',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].pap_tariff, 1),
                        unit: 'cmwp',
                        numberOfDecimalPlaces: 1,
                        values: {
                            tariffDiscount: parseNumberWithToFixed(
                                options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].pap_tariff_discount * 100,
                                1
                            ).toLocaleString('pt-PT'),
                        },
                    },
                    {
                        label: 'label.annualSaving',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_saving, 0),
                    },
                ];
            }
            if (product_id === PRODUCT_IDS.SPV && options.businessModelData.payment_model_id === PAYMENT_MODELS_IDS.LEC) {
                projectSummary = [
                    {
                        label: 'label.price',
                        infoLabel: 'label.tariffDiscount',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].lec_tariff, 1),
                        unit: 'cmwp',
                        numberOfDecimalPlaces: 1,
                        values: {
                            tariffDiscount: parseNumberWithToFixed(
                                options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].lec_tariff_discount * 100,
                                1
                            ).toLocaleString('pt-PT'),
                        },
                    },
                    {
                        label: 'label.annualSaving',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_saving, 0),
                    },
                ];
            }

            if (
                [SIZING_TYPE_IDS.DETAILED_FULL, SIZING_TYPE_IDS.DETAILED_RISK_FREE, SIZING_TYPE_IDS.DETAILED_GOAL_SEEK_FREE].includes(
                    options.businessModelData.sizing_type_id
                )
            ) {
                if (options.businessModelData.payment_model_id === PAYMENT_MODELS_IDS.INSTALLMENTS) {
                    const investment = options.syncKpis?.kpis?.monthly_payments_number * options.syncKpis?.negotiation?.default;
                    const payback = investment / options.syncKpis?.kpis?.annual_saving;

                    projectSummary = [
                        { label: 'label.investment', value: parseNumberWithDecimals(investment, 0) },
                        {
                            label: 'label.payback',
                            value: parseNumberWithDecimals(payback, 1),
                            unit: 'year',
                            numberOfDecimalPlaces: 1,
                        },
                    ];
                }
                if (options.businessModelData.payment_model_id === PAYMENT_MODELS_IDS.PAY_AS_PRODUCED) {
                    let step = `${options?.syncKpis?.negotiation?.step ?? ''}`; //default 2 (numberOfDecimalPlaces)
                    let numberOfDecimalPlaces = step.split('.')?.[1]?.length ?? 2;
                    projectSummary = [
                        {
                            label: 'label.tariff',
                            value: parseNumberWithDecimals(options?.syncKpis?.negotiation?.default, numberOfDecimalPlaces),
                            unit: 'cmwp',
                            numberOfDecimalPlaces,
                        },
                        {
                            label: 'label.annualSaving',
                            value: parseNumberWithDecimals(options?.syncKpis?.kpis?.annual_saving, 0),
                        },
                    ];
                }
                if (options.businessModelData.payment_model_id === PAYMENT_MODELS_IDS.PPA) {
                    let monthlyPayment = options?.syncKpis?.negotiation?.pre_selected;
                    const discount =
                        1 - monthlyPayment / (options?.syncKpis?.kpis?.annual_saving / (options?.syncKpis?.kpis?.annual_prod / 1000)); //MWh

                    if (
                        [
                            getCompanyProfileIds().ROMANDE,
                            getCompanyProfileIds().SUNSEAP,
                            getCompanyProfileIds().PROFILE_BASE_UK,
                            getCompanyProfileIds().EVOLO,
                        ].includes(options?.companyProfileId)
                    ) {
                        let step = `${options?.syncKpis?.negotiation?.step ?? ''}`; //default 4 (numberOfDecimalPlaces)
                        let numberOfDecimalPlaces = step.split('.')?.[1]?.length ?? 4;
                        projectSummary = [
                            {
                                label: 'label.tariff',
                                value: parseNumberWithDecimals(options?.syncKpis?.negotiation?.default, numberOfDecimalPlaces),
                                numberOfDecimalPlaces,
                                unit: ppaTariffUnit(options?.companyProfileId),
                            },
                        ];

                        if ([getCompanyProfileIds().SUNSEAP].includes(options?.companyProfileId)) {
                            projectSummary.push({
                                label: 'label.annualSaving',
                                value: parseNumberWithDecimals(options?.syncKpis?.kpis.annual_saving, 0),
                                unit: 'currency',
                            });
                        }
                    } else {
                        projectSummary = [
                            {
                                label: 'page.businessModels.kpis.discountAgainstarget',
                                value: parseNumberWithDecimals(discount, 2),
                                unit: 'percent',
                            },
                            {
                                label: 'label.annualSaving',
                                value: parseNumberWithDecimals(options?.syncKpis?.kpis?.annual_saving, 0),
                            },
                        ];
                    }
                }

                if (options.businessModelData.payment_model_id === PAYMENT_MODELS_IDS.LEC) {
                    const acTariff = options?.syncKpis?.kpis?.ac_tariff * 1000;
                    const defaultTariff = options?.syncKpis?.negotiation?.default;
                    const discount = ((acTariff - defaultTariff) * 100) / acTariff;
                    projectSummary = [
                        {
                            label: 'label.price',
                            infoLabel: 'label.tariffDiscount',
                            value: parseNumberWithDecimals(defaultTariff, 2),
                            unit: 'cmwp',
                            numberOfDecimalPlaces: 2,
                            values: {
                                tariffDiscount: parseNumberWithToFixed(discount, 2).toLocaleString('pt-PT'),
                            },
                        },
                        {
                            label: 'label.annualSaving',
                            value: parseNumberWithDecimals(options?.syncKpis?.kpis?.annual_saving, 0),
                        },
                    ];
                }
            }
            break;
        case PRODUCT_IDS.TRE:
            projectSummary = [{ label: 'label.price', value: sizing.price }];
            break;
        case PRODUCT_IDS.EVC:
        case PRODUCT_IDS.EVCSB: {
            let tagCompanyID =
                [getCompanyProfileIds().EDP_PT, getCompanyProfileIds().EFZ].includes(options?.companyProfileId) ?
                    getCompanyProfileIds().EDP_PT
                :   options?.companyProfileId;

            const totalPlatformPriceMonth = getInputWithTaxes(
                'total_platform_price_month',
                options?.companyProfileId,
                options.businessModels.body.inputs
            );
            var optionWithManagement =
                (
                    parseInt(options.businessModels.body.inputs.management_option_id) !== 99 &&
                    isFieldDefined(totalPlatformPriceMonth) &&
                    totalPlatformPriceMonth !== 0
                ) ?
                    {
                        isServiceME_ES: false,
                        label: `page.evc.resume.total_platform_price_month_companyID${tagCompanyID}`,
                        value: totalPlatformPriceMonth,
                        unit: 'mc',
                        numberOfDecimalPlaces: [getCompanyProfileIds().EDP_PT].includes(options?.companyProfileId) ? 2 : 0,
                        tooltip:
                            [getCompanyProfileIds().EDP_PT].includes(options?.companyProfileId) ?
                                `page.evc.resume.total_platform_price_month_companyID${tagCompanyID}.tooltip`
                            :   null,
                    }
                :   null;

            switch (options.businessModelData.payment_model_id) {
                case PAYMENT_MODELS_IDS.UP_FRONT:
                    projectSummary = [
                        {
                            label: 'label.equipmentAndInstallation',
                            value: options.businessModelData.kpis_previews[0].kpis_per_margin?.[0]?.price ?? 'N/A',
                            isServiceME_ES: false,
                        },
                    ];
                    if (optionWithManagement) projectSummary.push(optionWithManagement);
                    projectSummary.push({}); //dummy para formatacao em linha
                    projectSummary.push({}); //dummy para formatacao em linha

                    //#region EDP_ES
                    if ([getCompanyProfileIds().EDP_ES].includes(options?.companyProfileId)) {
                        projectSummary[0].subLabel = 'page.evc.BModel.servicessubLabel3';
                        projectSummary[1].label = 'label.managementPlatforms';
                        projectSummary[1].subLabel = 'page.evc.BModel.servicessubLabel2';
                    }
                    //#endregion EDP_ES
                    break;
                case PAYMENT_MODELS_IDS.INSTALLMENTS:
                    projectSummary = [
                        {
                            label: 'label.equipmentAndInstallationTotal',
                            value: options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].price,
                        },
                        {
                            label: 'label.equipmentAndInstallationMonthlyFee',
                            value: options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].monthly_payments_fee,
                            operator_me: true,
                            isServiceME_ES: false,
                            monthly_payments_number:
                                options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].monthly_payments_number,
                        },
                    ];
                    if (optionWithManagement) projectSummary.push(optionWithManagement);
                    projectSummary.push({}); ///dummy para formatacao em linha
                    projectSummary.push({}); //dummy para formatacao em linha
                    //#region EDP_ES
                    if ([getCompanyProfileIds().EDP_ES].includes(options?.companyProfileId)) {
                        projectSummary[1].label = 'label.equipmentAndInstallation';
                        projectSummary[1].subLabel = 'page.evc.BModel.servicessubLabel1';
                        projectSummary[2].label = 'label.managementPlatforms';
                        projectSummary[2].subLabel = 'page.evc.BModel.servicessubLabel2';
                    }
                    //#endregion  EDP_ES

                    break;

                case PAYMENT_MODELS_IDS.SERVICE:
                    projectSummary = [
                        {
                            label: 'label.services',
                            value: options.businessModelData.kpis_previews[0].kpis_per_margin?.service_monthly_fee,
                            unit: 'mc',
                            isServiceME_ES: false,
                        },
                    ];
                    //#region EDP_ES
                    if (
                        [getCompanyProfileIds().EDP_ES].includes(options?.companyProfileId) &&
                        options.businessModelData.business_model_id === 105
                    ) {
                        //EVC SERVICE
                        projectSummary = [
                            {
                                isServiceME_ES: true,
                                label: 'label.services',
                                subLabel: 'page.evc.BModel.servicessubLabel1',
                                numberOfDecimalPlaces: 0,
                                value: options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].monthly_payments_fee,
                                unit: 'mc',
                            },
                            {
                                isServiceME_ES: true,
                                label: 'label.chargerRental',
                                subLabel: 'page.evc.BModel.servicessubLabel2',
                                numberOfDecimalPlaces: 0,
                                value: options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].monthly_payments_fee_alt,
                                unit: 'mc',
                            },
                        ];

                        if (options.businessModels?.body?.inputs?.total_platform_price_month > 0) {
                            projectSummary.push({
                                isServiceME_ES: true,
                                label: 'label.managementPlatforms',
                                subLabel: 'page.evc.BModel.servicessubLabel2',
                                numberOfDecimalPlaces: 0,
                                value: getInputWithTaxes(
                                    'total_platform_price_month',
                                    options?.companyProfileId,
                                    options.businessModels.body.inputs
                                ),
                                unit: 'mc',
                            });
                        } else {
                            projectSummary.push({}); //dummy para formatacao em linha
                        }
                    }
                    ////#endregion EDP_ES
                    break;

                default:
                    break;
            }
            break;
        }
        case PRODUCT_IDS.MPT:
            projectSummary = [
                {
                    label: 'label.price',
                    value: getParseNumber(
                        sizing.solution_option['1'].interventions_number[options.inputs.interventions_number.toString()].price,
                        '.'
                    ),
                },
            ];
            break;
        case PRODUCT_IDS.INTEGRA:
            projectSummary = [
                {
                    label: 'label.basePrice',
                    value: sizing.base_price,
                    tooltip: intlMessages('label.moneyPerMonth', {
                        M: sizing.monthly_fees_number,
                        P: sizing.monthly_fee,
                        CURRENCY_SYMBOL: options.symbol,
                    }),
                    isTranslatedTooltip: false,
                },
                { label: 'label.supplements', value: sizing.extra_price },
            ];
            break;
        case PRODUCT_IDS.IE:
        case PRODUCT_IDS.IE_BR:
            projectSummary = [
                {
                    label: 'label.investment',
                    value: options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].investment,
                },
                {
                    label: 'label.payback',
                    value: parseNumberWithDecimals(
                        options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].investment /
                            options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_saving,
                        1
                    ),
                    unit: 'year',
                    numberOfDecimalPlaces: 1,
                },
            ];
            break;
        case PRODUCT_IDS.CFP:
            if (options.businessModelData.payment_model_id !== PAYMENT_MODELS_IDS.SERVICE) {
                projectSummary = [
                    {
                        label: 'label.investment',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].investment, 0),
                    },
                    {
                        label: 'label.payback',
                        value: parseNumberWithDecimals(
                            options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].investment /
                                options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_saving,
                            1
                        ),
                        unit: 'year',
                        numberOfDecimalPlaces: 1,
                    },
                ];
            } else {
                projectSummary = [
                    {
                        label: 'label.price',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_price, 0),
                    },
                    {
                        label: 'label.annualSaving',
                        value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_saving, 0),
                    },
                ];
            }
            break;
        case PRODUCT_IDS.CE:
            //Project Summary Conditions
            projectSummary = sizing.options.find(
                (option) =>
                    option.extra_picked_id === options.inputs.extra_picked_id &&
                    option.arce_periodicity_id === options.inputs.arce_periodicity_id
            );

            if (!!projectSummary && !!projectSummary.price_ae) {
                projectSummary = [
                    { label: 'label.priceae', value: parseInt(projectSummary.price_ae) },
                    {
                        label: 'label.pricearce',
                        value: `${projectSummary.anual_installments_number} `,
                        operator: `x`,
                        annual_installments_price: projectSummary.annual_installments_price,
                    },
                ];
            } else {
                projectSummary = [{ label: 'label.price', value: sizing.options[0].price }];
            }
            break;
        case PRODUCT_IDS.HVAC_BR:
            projectSummary = [
                {
                    label: 'label.investment',
                    value: options.businessModelData.kpis_previews[0].kpis_per_margin?.[0]?.investment,
                },
                // { label: 'label.payback', value: parseNumberWithDecimals(options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].investment / options.businessModelData.kpis_previews[0].kpis_per_margin?.[0].annual_saving, 1), unit: 'year', numberOfDecimalPlaces: 1 }
            ];
            break;
        case PRODUCT_IDS.RR:
            projectSummary = [
                {
                    label: 'label.price',
                    value: options?.businessModelData?.kpis_previews?.[0]?.kpis_per_margin?.[0]?.price,
                },
            ];

            break;
        case PRODUCT_IDS.SPV_MAINTENANCE:
            projectSummary = [
                {
                    label: 'label.annualPrice',
                    value: options?.businessModelData?.kpis_previews?.[0]?.kpis_per_margin?.[0]?.annual_price,
                },
            ];
            break;
        default:
            break;
    }

    return projectSummary;
}
//Format Margin according to KPIs
export function buildMarginAccordingToKpis(margin_negotiation, loading, setMargin, searchBy, kpi_preview) {
    let isDiscount = isExistWord(searchBy, 'discount');
    let isMargin = isExistWord(searchBy, 'margin');

    //default
    let labels = {
        label: `label.NA`,
        subtitleLabel: `label.NA`,
    };
    //is Discount
    if (isDiscount) {
        labels = {
            label: `label.comercialDiscount`,
            subtitleLabel: `label.selectDiscount`,
        };
    }
    // is Margin
    if (isMargin) {
        labels = {
            label: `label.comercialMargin`,
            subtitleLabel: `label.selectMargin`,
        };
    }

    //Add elements to slider
    let answers = kpi_preview?.kpis_per_margin?.map((item) => ({
        value: item[kpi_preview?.margin_negotiation?.search],
    }));

    return {
        ...labels,
        loading,
        answers,
        options: {
            labelUnit: '%',
        },
        handleChange: (event) => {
            setMargin(event.target.value);
        },
    };
}
//Get KPIS according to preview
export function getCalculableKpis(kpi_preview, margin) {
    let kpis = {};
    let search = kpi_preview.margin_negotiation.search;
    const marginSelected = margin;

    //Get choosen Margin KPIS
    kpis = kpi_preview.kpis_per_margin.find((item) => item[search] === marginSelected);

    if (kpis) {
        kpis.payback = kpis?.investment / kpis?.annual_saving;
    }

    return kpis;
}
//Get Preview KPIS according to payment Model
export function getPreviewKpisAccordingToPaymentModel(options) {
    const {
        companyProfileId,
        payment_model_id,
        kpis,
        parseNumberWithDecimals,
        productID,
        typeBusinessModel,
        businessModelsBodyInputs,
        userTypeID,
        isUserSupervisor,
        businessModelBody,
    } = options;

    let array = clone(KPIS_PER_PAYMENT_MODEL);
    //Exceptions
    if ([PRODUCT_IDS.RR].includes(productID)) {
        array = clone(KPIS_PER_PAYMENT_MODEL_RR);
    }
    if ([PRODUCT_IDS.SPV_MAINTENANCE].includes(productID)) {
        array = clone(KPIS_PER_BUSINMESS_MODEL_SPV_MAINTENANCE);
    }

    if ([PRODUCT_IDS.EVC, PRODUCT_IDS.EVCSB].includes(productID)) {
        array = clone(KPIS_PER_PAYMENT_MODEL_ME);

        if (
            [getCompanyProfileIds().EDP_PT].includes(companyProfileId) &&
            ![PAYMENT_MODELS_IDS.PARTNERSHIP].includes(payment_model_id) &&
            businessModelsBodyInputs.management_option_id !== ME_MANAGEMENT_OPTION_ID.NO_MANAGEMENT
        ) {
            array[payment_model_id].push({
                decimals: 0,
                unit: 'mc',
                name: 'total_platform_price_month',
                label: `page.evc.resume.total_platform_price_month_companyID${companyProfileId}`,
            });
        }

        // EDP BR EVC
        if (
            isDefined(businessModelsBodyInputs?.[DEFAULT_MANAGEMENT_PLATFORM_MONTHLY_FEE_KPI_EVC_BR.name]) &&
            businessModelsBodyInputs[DEFAULT_MANAGEMENT_PLATFORM_MONTHLY_FEE_KPI_EVC_BR.name] > 0
        )
            array[PAYMENT_MODELS_IDS.UP_FRONT].push(DEFAULT_MANAGEMENT_PLATFORM_MONTHLY_FEE_KPI_EVC_BR);
    }
    // Servive service_aux_kpis_arr
    if (PRODUCT_IDS.SPV === productID && payment_model_id === PAYMENT_MODELS_IDS.SERVICE && !!kpis) {
        array = KPIS_PER_PAYMENT_MODEL_PV;
        kpis.net_savings = kpis.annual_saving - kpis.annual_price;
    }

    //~service pap_aux_kpis_arr
    if (PRODUCT_IDS.SPV === productID && payment_model_id === PAYMENT_MODELS_IDS.PAY_AS_PRODUCED && !!kpis) {
        array = clone(KPIS_PER_PAYMENT_MODEL_PV_SERVICE_AUX);

        // PL-ES-IT
        let visibleAdditionalValue = array[PAYMENT_MODELS_IDS.PAY_AS_PRODUCED].find(
            (item) => item?.availableToBusinessModelsID && item?.availableToBusinessModelsID.includes(typeBusinessModel)
        );
        if (!visibleAdditionalValue) {
            array[PAYMENT_MODELS_IDS.PAY_AS_PRODUCED] = array[PAYMENT_MODELS_IDS.PAY_AS_PRODUCED].filter(
                (item) => item.name !== 'additional_value'
            );
        }
    }
    //~service lec_aux_kpis_arr
    if (PRODUCT_IDS.SPV === productID && payment_model_id === PAYMENT_MODELS_IDS.LEC && !!kpis) {
        array = clone(KPIS_PER_PAYMENT_MODEL_PV_SERVICE_AUX);

        // PT
        let visibleAdditionalValue = array[PAYMENT_MODELS_IDS.LEC].find(
            (item) => item?.availableToBusinessModelsID && item?.availableToBusinessModelsID.includes(typeBusinessModel)
        );
        if (!visibleAdditionalValue) {
            array[PAYMENT_MODELS_IDS.LEC] = array[PAYMENT_MODELS_IDS.LEC].filter((item) => item.name !== 'additional_value');
        }
    }

    if ([PAYMENT_MODELS_IDS.SERVICE_PPA].includes(payment_model_id)) {
        array = KPIS_PER_PAYMENT_MODEL_PPA;
    }
    if (PRODUCT_IDS.HVAC_BR === productID) {
        //show only investment
        let kpis_per_payment_model = clone(KPIS_PER_PAYMENT_MODEL);
        kpis_per_payment_model[payment_model_id] = kpis_per_payment_model[payment_model_id].filter((item) => item.name === 'investment');
        array = clone(kpis_per_payment_model);
    }

    // SERVICE_AUX_ EDP_PL OR EDP_IT OR EDP_PT OR EDP_ES
    if (hasServiceAux(typeBusinessModel)) {
        array = clone(KPIS_PER_PAYMENT_MODEL_PV_SERVICE_AUX);
        if ([57].includes(typeBusinessModel)) {
            //BModel EDP_ES_SERVICES
            array[PAYMENT_MODELS_IDS.SERVICE].map((item) => {
                if (item.name === 'additional_value') {
                    item.options.visible = true;
                }
                return item;
            });
        }
    }

    if ([PRODUCT_IDS.TRE, PRODUCT_IDS.MPT].includes(productID)) {
        array = {
            [PAYMENT_MODELS_IDS.NOT_APPLICABLE]: [
                {
                    decimals: 0,
                    unit: 'currency',
                    name: 'price',
                    label: 'label.price',
                },
            ],
        };
    }

    if (
        PRODUCT_IDS.SPV === productID &&
        [getCompanyProfileIds().EDP_IT].includes(companyProfileId) &&
        ([USERS_TYPE_ID.MANAGER].includes(userTypeID) || ([USERS_TYPE_ID.CHANNEL].includes(userTypeID) && isUserSupervisor)) &&
        [
            PAYMENT_MODELS_IDS.PAY_AS_PRODUCED,
            PAYMENT_MODELS_IDS.UP_FRONT,
            PAYMENT_MODELS_IDS.INSTALLMENTS,
            PAYMENT_MODELS_IDS.SERVICE,
        ].includes(payment_model_id) &&
        !array[payment_model_id].some((el) => el.name === 'agency_commission')
    ) {
        array[payment_model_id].push({
            decimals: 0,
            unit: 'currency',
            name: 'agency_commission',
            label: 'label.agencyCommission',
        });
    }

    return array[[PRODUCT_IDS.SPV_MAINTENANCE].includes(productID) ? typeBusinessModel : payment_model_id] ?
            array[[PRODUCT_IDS.SPV_MAINTENANCE].includes(productID) ? typeBusinessModel : payment_model_id]
                .map((item, index) => {
                    let value = 0;
                    let _kpis = kpis;
                    if ([PRODUCT_IDS.SPV_MAINTENANCE].includes(productID))
                        _kpis = {
                            ...kpis,
                            ...(businessModelBody.inputs ?? {}),
                            // ...businessModelBody.kpis_prev ?? {},
                            // ...businessModelBody.kpis_prev_and_corr ?? {},
                        };
                    if (!!_kpis && !!_kpis[item.name] && item.unit !== 'percent')
                        value = parseNumberWithDecimals(_kpis[item.name], item.decimals);
                    else if (!!_kpis && !!_kpis[item.name] && item.unit === 'percent') value = _kpis[item.name];
                    else if (
                        !!businessModelsBodyInputs?.[item.name] &&
                        [getCompanyProfileIds().EDP_BR].includes(companyProfileId) &&
                        PRODUCT_IDS.EVC === productID
                    )
                        value = businessModelsBodyInputs?.[item.name]; // EVC EDP BR

                    let kpi = {
                        index: index,
                        label: item.label,
                        value: value,
                        unit: item.unit,
                        options: {
                            ...item.options,
                            delimiter: '.',
                            className: 'unit',
                            isLoading: false,
                            numberOfDecimalPlaces: item.decimals,
                        },
                    };
                    return kpi;
                })
                .filter(
                    (kpi) =>
                        // isInNewFlow removes kpis === 0
                        !(
                            isFieldDefined(businessModelsBodyInputs?.installation_location_option_id) &&
                            businessModelsBodyInputs.installation_location_option_id === ME_INSTALL_ON_COMPANY_CPE_OPTIONS.OTHER_CPE &&
                            kpi.value === 0
                        )
                )
        :   [];
}
//Get Body for BusinessModelKPI

//Handle Select Business Model
export function handleSelectBusinessModel(item, businessModels, product, facility, hooks, actions, options) {
    const hasDetailedKpis = [SIZING_TYPE_IDS.DETAILED_FULL, SIZING_TYPE_IDS.DETAILED_RISK_FREE].includes(item.sizing_type_id);

    //Set New BusinessModel
    hooks.setBusinessModelSelected(item);

    if (hasDetailedKpis) return;

    let body = {};
    let financing_customization = null;
    let services_extra_cost = null;
    let equipments_extra_cost = null;
    let bModelSelected = item;
    let isChangeBModel =
        isDefined(businessModels?.offerEdition?.cost?.extra) &&
        bModelSelected?.business_model_id !== businessModels?.kpisSelected?.business_model_id;

    //BusinessModel
    if (businessModels) {
        //#region  financing_customization
        if (isDefined(businessModels?.body?.financing_customization)) {
            financing_customization = businessModels?.body?.financing_customization;
        } else if (bModelSelected?.financing_customization?.enabled) {
            let monthly_payment_options_default =
                bModelSelected?.financing_customization?.monthly_payment_options?.find((mpo) => mpo.is_default) ?? null;
            financing_customization = {
                annual_interest_rate: monthly_payment_options_default?.annual_interest_rate ?? null,
                monthly_payment_option_id: monthly_payment_options_default?.id ?? null,
            };
        }
        //#endregion  financing_customization

        //#region  services_extra_cost
        if (isDefined(businessModels?.body?.services_extra_cost)) {
            services_extra_cost = businessModels?.body?.services_extra_cost;
        }
        //#endregion  equipments_extra_cost

        //#region  equipments_extra_cost
        if (isDefined(businessModels?.body?.equipments_extra_cost)) {
            equipments_extra_cost = businessModels?.body?.equipments_extra_cost;
        }
        //#endregion  equipments_extra_cost

        //#region  cost equipamts & services
        let equipments_cost = businessModels.body?.equipments_cost ?? undefined;
        let services_cost = businessModels.body?.services_cost ?? undefined;
        //#endregion  cost equipamts & services

        if (item.margin_negotiable) {
            //Check Negotiation
            switch (product.info.id) {
                case PRODUCT_IDS.SPV: {
                    let battery = businessModels.body?.inputs?.battery ?? undefined;
                    let reformulation_cost =
                        options?.isReformulation ?
                            businessModels?.offerEdition?.cost?.base +
                                Object.entries(businessModels.offerEdition?.annuity ?? [])?.reduce((acc, curr) => {
                                    if (typeof curr[1] === 'number') return acc + curr[1];
                                    else return acc;
                                }, 0) ?? (isChangeBModel ? businessModels?.offerEdition?.cost?.base : businessModels.body.cost)
                        :   null;

                    body = {
                        annual_saving: businessModels.body.annual_saving,
                        cost:
                            options?.isReformulation ? reformulation_cost
                            : isChangeBModel ? businessModels?.offerEdition?.cost?.base
                            : businessModels.body.cost,
                        default_offer_cost: businessModels.body.default_offer_cost,
                        facility_id: options?.isReformulation ? businessModels.body?.reformulation?.facility_id : facility.id,
                        has_client_discount: businessModels.body.has_client_discount,
                        range_id: businessModels.body.range_id,
                        panel_id: businessModels.body.inputs?.panel_id ?? null,
                        service_cost: businessModels.body.service_cost,
                        peak_power: businessModels.body.peak_power,
                        equipments_cost,
                        services_cost,
                        battery,
                        has_lecs: businessModels.body?.has_lecs ?? undefined,
                    };

                    // taker_facility_ids_arr
                    if (
                        businessModels.body?.inputs?.taker_facility_ids_arr &&
                        businessModels.body?.inputs?.taker_facility_ids_arr?.length > 0
                    )
                        body.taker_facility_ids_arr = businessModels.body?.inputs?.taker_facility_ids_arr;

                    // SERVICE_PPA
                    if ([PAYMENT_MODELS_IDS.SERVICE_PPA].includes(item.payment_model_id)) {
                        body.energy_price_irr_annual_savings = businessModels.body.energy_price_irr_annual_savings;
                    }

                    if (businessModels.body.subsidy === true || businessModels.body.subsidy === false) {
                        body.subsidy = businessModels.body.subsidy;
                        body.subsidy_prc = businessModels.body.subsidy_prc;
                    }

                    break;
                }
                case PRODUCT_IDS.IE:
                case PRODUCT_IDS.IE_BR:
                    body = {
                        target_investment: businessModels.body.target_investment,
                        annual_saving: businessModels.body.annual_saving,
                        module_type: businessModels.body.tipo_modulo,
                        facility_id: facility.id,
                    };
                    break;
                case PRODUCT_IDS.MPT:
                    body = {
                        target_investment: businessModels.body.target_investment,
                        module_type: businessModels.body.tipo_modulo,
                        facility_id: facility.id,
                    };
                    break;
                case PRODUCT_IDS.TRE:
                    body = {
                        cost: businessModels.body.cost,
                        module_type: businessModels.body.tipo_modulo,
                        facility_id: facility.id,
                    };
                    break;
                case PRODUCT_IDS.CE: {
                    let target_investmentObject = options.sizing.options.find(
                        (option) =>
                            option.extra_picked_id === options.inputs.extra_picked_id &&
                            option.arce_periodicity_id === options.inputs.arce_periodicity_id
                    );
                    if (!!target_investmentObject && !!target_investmentObject.price_ae) {
                        target_investmentObject = { target_investment: target_investmentObject.price };
                    } else {
                        target_investmentObject = {
                            target_investment: businessModels.body.target_investment,
                        };
                    }
                    body = {
                        target_investment: target_investmentObject.target_investment,
                        module_type: businessModels.body.tipo_modulo,
                        facility_id: facility.id,
                    };
                    break;
                }
                case PRODUCT_IDS.CFP:
                    body = {
                        cost: businessModels.body.cost,
                        annual_saving: businessModels.body.annual_saving,
                        module_type: businessModels.body.tipo_modulo,
                        facility_id: options?.isReformulation ? businessModels.body?.reformulation?.facility_id : facility.id,
                    };

                    if (!businessModels.body.cost) {
                        body.target_investment = businessModels.body.target_investment;
                    }

                    break;
                case PRODUCT_IDS.EVC:
                case PRODUCT_IDS.EVCSB:
                    body = {
                        facility_id: options?.isReformulation ? businessModels.body?.reformulation?.facility_id : facility.id,
                        entrance_type_id: businessModels.body.inputs.entrance_type_id,
                        management_option_id: businessModels.body.inputs.management_option_id,
                        equipments_cost,
                        services_cost,
                        // target_investment: businessModels?.body?.target_investment ?? undefined,
                        // total_upfront_price: businessModels?.body?.target_investment ?? undefined,
                    };

                    if (SEND_AAS_PRICING_IN_BM[options?.companyProfileId]) {
                        body.aas_pricing = businessModels.body.aas_pricing;
                    }
                    if (SEND_CHARGERS_RANGES_IN_BM[options?.companyProfileId]) {
                        body = {
                            ...body,
                            ranges: businessModels.body?.ranges ?? undefined,
                            required_power: businessModels.body?.required_power ?? undefined,
                            total_charger_power: businessModels.body?.total_charger_power ?? undefined,
                            total_charger_price: businessModels.body?.total_charger_price ?? undefined,
                            total_maintenance_price_year: businessModels.body?.total_maintenance_price_year ?? undefined,
                            total_platform_price_month: businessModels.body?.total_platform_price_month ?? undefined,
                            total_project_price: businessModels.body?.total_project_price ?? undefined,
                            total_upfront_price: businessModels.body?.total_upfront_price ?? undefined,
                            chargers_total_power: businessModels.body?.chargers_total_power ?? undefined,
                            contracted_power: businessModels.body?.contracted_power ?? undefined,
                            new_contracted_power: businessModels.body?.new_contracted_power ?? undefined,
                            energy_risk_id:
                                isFieldDefined(businessModels.body?.energy_risk_id) ? businessModels.body?.energy_risk_id : undefined,
                            ...getInputsPartnerShip(businessModels.body.inputs),
                        };
                    }

                    if (options?.companyProfileId === getCompanyProfileIds().EDP_PT) {
                        body.target_investment = businessModels.body.target_investment;
                    } else {
                        // has_offer_edition
                        if (bModelSelected?.has_offer_edition) {
                            body.cost = businessModels?.body?.cost ?? businessModels?.offerEdition?.cost?.base;
                        } else {
                            body.target_investment = businessModels.body.target_investment;
                        }
                    }

                    // is in new flow
                    if (
                        isFieldDefined(businessModels.body?.installation_location_option_id) &&
                        businessModels.body?.installation_location_option_id === ME_INSTALL_ON_COMPANY_CPE_OPTIONS.OTHER_CPE
                    ) {
                        body.installation_location_option_id = businessModels.body.installation_location_option_id;
                        body.target_investment = businessModels.body.target_investment ?? null;

                        delete body.cost;
                    }

                    break;
                case PRODUCT_IDS.GNS:
                    body = businessModels.body;
                    break;
                case PRODUCT_IDS.SPVSB:
                    body = {
                        cost: businessModels.body.cost,
                        facility_id: facility.id,
                        annual_saving: businessModels.body.annual_saving,
                        module_type: businessModels.body.module_type,
                        range_id: businessModels.body.range_id,
                        panels_number: businessModels.body.panels_number,
                        monitoring: businessModels.body.monitoring,
                        battery: businessModels.body?.inputs?.battery ?? undefined,
                    };

                    break;
                case PRODUCT_IDS.HVAC_BR:
                    body = {
                        target_investment: businessModels.body.target_investment,
                        module_type: businessModels.body.tipo_modulo,
                        facility_id: facility.id,
                    };
                    break;
                case PRODUCT_IDS.RR:
                    body = {
                        cost: businessModels?.body?.cost,
                        module_type: businessModels.body.tipo_modulo,
                        facility_id: facility.id,
                    };
                    break;

                case PRODUCT_IDS.SPV_MAINTENANCE:
                    body = {
                        module_type: businessModels.body.tipo_modulo,
                        facility_id: facility.id,
                    };
                    if ([/* EDP_PT: */ 174, /* EDP_ES: */ 177].includes(item.business_model_id)) {
                        body = {
                            ...body,
                            cost: businessModels.body.kpis_prev.total_cost,
                            kpis_prev: businessModels.body.kpis_prev,
                        };
                    }

                    if ([/* EDP_PT: */ 175, /* EDPT_ES: */ 178].includes(item.business_model_id)) {
                        body = {
                            ...body,
                            cost: businessModels.body.kpis_prev_and_corr.total_cost,
                            kpis_prev_and_corr: businessModels.body.kpis_prev_and_corr,
                        };
                    }
                    break;
                default:
                    break;
            }

            //#region  financing_customization
            if (isDefined(financing_customization)) {
                body = {
                    ...body,
                    ...financing_customization,
                };
            }
            //#endregion  financing_customization

            //#region  equipments_extra_cost
            if (isDefined(equipments_extra_cost)) {
                body = {
                    ...body,
                    equipments_extra_cost,
                };
            }
            //#endregion  equipments_extra_cost

            //#region  services_extra_cost
            if (isDefined(services_extra_cost)) {
                body = {
                    ...body,
                    services_extra_cost,
                };
            }
            //#endregion  services_extra_cost

            actions.requestGetBusinessModelKpisSelected({
                businessModelId: item.business_model_id,
                productID: product.info.id,
                body,
            });
        } else {
            body = {
                facility: {
                    id: options?.isReformulation ? businessModels.body?.reformulation?.facility_id : facility.id,
                },
                tipo_modelo_negocio_id: item.business_model_id,
                inputs: businessModels.body.inputs ? businessModels.body.inputs : null,
                is_fast_module: businessModels.body.is_fast_module,
            };

            //#region  financing_customization
            if (isDefined(financing_customization)) {
                body = {
                    ...body,
                    ...financing_customization,
                };
            }
            //#endregion  financing_customization

            //Send to proposal
            actions.setProposal(body);
        }
    }
}
//Get is margin_negotiable
export const isMarginNegotiable = (businessModelSelected) => !!businessModelSelected && businessModelSelected.margin_negotiable;

export const getBodyWithService = (companyProfileId, body, serviceArray) => {
    if ([getCompanyProfileIds().ESB, getCompanyProfileIds().NRG].includes(companyProfileId))
        return (body.energy_price_irr_annual_savings = serviceArray);

    if (
        [
            getCompanyProfileIds().EDP_IT,
            getCompanyProfileIds().EDP_ES,
            getCompanyProfileIds().EDP_PL,
            getCompanyProfileIds().EDP_PT,
        ].includes(companyProfileId)
    ) {
        delete body.energy_price_irr_annual_savings; //PPA trash

        return (body.service_aux_kpis_arr = serviceArray);
    }
};

export const getBodyWithNegociations = (companyProfileId, body, negotiations = []) => {
    if (
        [
            getCompanyProfileIds().EDP_PT,
            getCompanyProfileIds().EDP_PL,
            getCompanyProfileIds().EDP_IT,
            getCompanyProfileIds().EDP_ES,
            getCompanyProfileIds().NRG,
        ].includes(companyProfileId)
    ) {
        delete body.energy_price_irr_annual_savings; //trash
        body[INPUTS_NAME_NEGOCIATIONS.SERVICE_AUX_KPIS_ARR] = negotiations?.[INPUTS_NAME_NEGOCIATIONS.SERVICE_AUX_KPIS_ARR];
        body[INPUTS_NAME_NEGOCIATIONS.PAP_AUX_KPIS_ARR] = negotiations?.[INPUTS_NAME_NEGOCIATIONS.PAP_AUX_KPIS_ARR];
        body[INPUTS_NAME_NEGOCIATIONS.LEC_AUX_KPIS_ARR] = negotiations?.[INPUTS_NAME_NEGOCIATIONS.LEC_AUX_KPIS_ARR];

        const syncLec = negotiations?.syncKpis?.find((bm) => bm?.tipo_modelo_negocio_id === BUSINESS_MODEL_IDS?.[companyProfileId]?.LEC);
        if (isDefined(syncLec)) {
            delete body[INPUTS_NAME_NEGOCIATIONS.LEC_AUX_KPIS_ARR];
        }
        return body;
    }
};

export const hasServiceAux = (typeBusinessModel) =>
    [
        BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_IT]?.SERVICE_V2,
        BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_PL]?.SERVICE_V2,
        BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_ES]?.SERVICE_V2,
        BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_PT]?.SERVICE_V2,
    ].includes(typeBusinessModel);

export const hasServicePaP = (typeBusinessModel) =>
    [
        BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_IT]?.PAP,
        BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_PL]?.PAP,
        BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_PT]?.PAP,
        BUSINESS_MODEL_IDS[getCompanyProfileIds().EDP_ES]?.PAP,
    ].includes(typeBusinessModel);

export const hasServiceLEC = (typeBusinessModel) => [BUSINESS_MODEL_IDS?.[getCompanyProfileIds().EDP_PT]?.LEC].includes(typeBusinessModel);

export const hasServiceByCompanyID = (companyProfileId) =>
    [
        getCompanyProfileIds().EDP_ES,
        getCompanyProfileIds().NRG,
        getCompanyProfileIds().EDP_IT,
        getCompanyProfileIds().EDP_PL,
        getCompanyProfileIds().EDP_PT,
        getCompanyProfileIds().NRG,
        getCompanyProfileIds().SUNSEAP,
        getCompanyProfileIds().ROMANDE,
        getCompanyProfileIds().PPC,
        getCompanyProfileIds().PROFILE_BASE_UK,
        getCompanyProfileIds().EVOLO,
        getCompanyProfileIds().PROFILE_BASE_USA,
        getCompanyProfileIds().PROFILE_BASE_IT,
        getCompanyProfileIds().PROFILE_BASE_DE,
        getCompanyProfileIds().PROFILE_EDP_FR,
        getCompanyProfileIds().EFZ,
        getCompanyProfileIds().PROFILE_BASE_SWISS,
    ].includes(companyProfileId);

export const typeBusinessModelIDByService = (companyProfileId) => {
    // service_aux_kpis_arr
    switch (companyProfileId) {
        case getCompanyProfileIds().ESB:
            return 95;
        case getCompanyProfileIds().EDP_IT:
            return 106;
        case getCompanyProfileIds().EDP_PL:
            return 107;
        case getCompanyProfileIds().EDP_ES:
            return 57;
        case getCompanyProfileIds().EDP_PT:
            return 116;
        case getCompanyProfileIds().NRG:
            return 154;
        default:
    }
};

export const conditionsToDisableDetailedBM = (negotiation) => {
    let disabled = { condition: false };
    if (isFieldDefined(negotiation?.status_risk_premium_id) && negotiation?.status_risk_premium_id !== BUSINESS_MODEL_ELIGIBLE.YES)
        disabled.reason = BUSINESS_MODEL_NON_ELIGIBLE_REASONS.RISK;
    if (isDefined(negotiation?.can_negotiate) && !negotiation?.can_negotiate)
        disabled.reason = BUSINESS_MODEL_NON_ELIGIBLE_REASONS.CAN_NEGOTIATE;
    if (negotiation?.hasErrorOnNetSavingsEligibility) disabled.reason = BUSINESS_MODEL_NON_ELIGIBLE_REASONS.NOT_NET_SAVINGS_ELIGIBILITY;

    if (isDefined(disabled.reason)) disabled.condition = true;
    return disabled;
};
