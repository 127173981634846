import { defineMessages } from 'react-intl';
import enMessages from '../locales/en_IE.json';

const enIELang = {
    messages: {
        ...enMessages,
    },
    locale: 'en-IE',
    data: defineMessages,
};
export default enIELang;
