import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';

interface IProps {
    label: string;
    values: {
        number: number | undefined;
        unit: string;
        numberOfDecimalPlaces: number;
    }[];
}
const LecMarginKpi = ({ label, values }: IProps) => {
    return (
        <div className="bm-kpis-margin-kpi">
            <label>
                <IntlMessages id={label} />
            </label>
            <div className="d-flex">
                {values.map((value) => (
                    <span>
                        {/* @ts-ignore */}
                        <FormatNumber unit={value.unit} number={value.number} numberOfDecimalPlaces={value.numberOfDecimalPlaces} />
                    </span>
                ))}
            </div>
        </div>
    );
};

export default LecMarginKpi;
