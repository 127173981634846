import Switch from '@mui/material/Switch';
import ProjectSummarySubTitle from 'components/BusinessModels/ProjectSummary/SubTitle';
import IntlMessages from 'components/util/IntlMessages';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront, UpFrontEvents } from 'interfaces/businessModels/upfront';
import { Controller, useFormContext } from 'react-hook-form';
import { useContext, useEffect } from 'react';
import { TRubric } from 'interfaces/rubric';
import FormatNumber from 'components/util/FormatNumber';

interface IProps {
    rubrics: TRubric[];
}
const UpfrontRubrics = ({ rubrics }: IProps) => {
    const { negotiationProfile, setBMEventHandler } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TUpFront
    >;

    const { control } = useFormContext();

    const onChangeRubric = (id, selected) => {
        setBMEventHandler(UpFrontEvents.SET_UFRONT_RUBRIC_COST, { selected: selected, id: id });
        return selected;
    };

    useEffect(() => {
        rubrics.forEach((rubric) => {
            if (rubric.included_default_cost)
                setBMEventHandler(UpFrontEvents.INIT_UFRONT_RUBRIC_COST, {
                    selected: rubric.included_default_cost,
                    id: rubric.cost_id,
                });
        });
    }, []); // eslint-disable-line

    return (
        <div className="offer-details-inputs-additional-cost">
            <ProjectSummarySubTitle label={'label.additionalCosts'} />
            {rubrics.map((rubric) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" key={`rubric_id_${rubric.cost_id}`}>
                        <div className="offer-details-container">
                            <div className="label-container">
                                <label>
                                    <IntlMessages id={`${rubric.cost_tag}`} />
                                </label>
                            </div>
                            <div className="input-container">
                                <Controller
                                    name={`rubric_id_${rubric.cost_id}`}
                                    control={control}
                                    defaultValue={rubric.included_default_cost}
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    render={({ field: { ref, ...field } }) => (
                                        <Switch
                                            {...field}
                                            checked={field.value}
                                            onChange={(e) => field.onChange(onChangeRubric(rubric.cost_id, e.target.checked))}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {negotiationProfile?.acesso_valores_custo && rubric.included_cost && (
                            <div className="d-flex justify-content-between">
                                <span className="cost">
                                    {/* @ts-ignore */}
                                    <FormatNumber number={parseInt(rubric.final_cost)} unit={'currency'} numberOfDecimalPlaces={0} />
                                </span>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default UpfrontRubrics;
