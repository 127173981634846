import validate from 'services/util/validate';
import { PRODUCT_IDS } from './products';
import { USERS_TYPE_ID } from './user';

export const SET_BUSINESS_MODEL_PRO = 'SET_BUSINESS_MODEL_PRO';
export const RESET_BUSINESS_MODEL_PRO = 'RESET_BUSINESS_MODEL_PRO';
export const HAS_ACCES_BUSINESS_MODEL_PRO = 'HAS_ACCES_BUSINESS_MODEL_PRO';

export const INITIAL_BUSINESS_MODELS = {
    facility_id: null,
    module_type: null,
    annual_saving: null,
    target_investment: null,
    facility: {
        id: null,
    },
    inputs: {},
};

export const OFFER_DETAILS_INPUT_NAMES = {
    MONITORING: 'monitoring',
    EQUIPMENT_WARRANTY_INVERTERS: 'equipment_warranty_inverters',
    COST_ADJUSTMENT: 'cost_adjustment',
    PAYMENT_METHOD: 'general_input_text',
    OM_DURATION: 'om_contract_duration',
} as const;

export const OFFER_DETAILS_UPFRONT_CONFIGS = {
    DEFAULT: {
        [USERS_TYPE_ID.MANAGER]: {
            [PRODUCT_IDS.SPV]: {
                [OFFER_DETAILS_INPUT_NAMES.MONITORING]: {
                    name: OFFER_DETAILS_INPUT_NAMES.MONITORING,
                    visible: false,
                },
                [OFFER_DETAILS_INPUT_NAMES.EQUIPMENT_WARRANTY_INVERTERS]: {
                    name: OFFER_DETAILS_INPUT_NAMES.EQUIPMENT_WARRANTY_INVERTERS,
                    visible: false,
                    values: [
                        { value: 0, label: 'label.years' },
                        { value: 1, label: 'label.year' },
                        { value: 2, label: 'label.years' },
                        { value: 4, label: 'label.years' },
                        { value: 8, label: 'label.years' },
                    ],
                },
                [OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]: {
                    name: OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT,
                    visible: true,
                    inputType: 'number',
                    rules: validate(['required', 'number', 'min', 'max'], { min: 1, max: 5 }),
                    optValidation: { max: 5, min: 1 },
                },
                [OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD]: {
                    name: OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD,
                    visible: false,
                    rules: validate(['required']),
                },
                [OFFER_DETAILS_INPUT_NAMES.OM_DURATION]: {
                    name: OFFER_DETAILS_INPUT_NAMES.OM_DURATION,
                    visible: false,
                    inputType: 'number',
                    rules: validate(['required', 'number']),
                },
            },
            [PRODUCT_IDS.HP]: {
                [OFFER_DETAILS_INPUT_NAMES.MONITORING]: {
                    name: OFFER_DETAILS_INPUT_NAMES.MONITORING,
                    visible: false,
                },
                [OFFER_DETAILS_INPUT_NAMES.EQUIPMENT_WARRANTY_INVERTERS]: {
                    name: OFFER_DETAILS_INPUT_NAMES.EQUIPMENT_WARRANTY_INVERTERS,
                    visible: false,
                    values: [
                        { value: 0, label: 'label.years' },
                        { value: 1, label: 'label.year' },
                        { value: 2, label: 'label.years' },
                        { value: 4, label: 'label.years' },
                        { value: 8, label: 'label.years' },
                    ],
                },
                [OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]: {
                    name: OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT,
                    visible: true,
                    inputType: 'number',
                    rules: validate(['required', 'number', 'min', 'max'], { min: 1, max: 5 }),
                    optValidation: { max: 5, min: 1 },
                },
            },
            [PRODUCT_IDS.SPV_SIMPLE]: {
                [OFFER_DETAILS_INPUT_NAMES.MONITORING]: {
                    name: OFFER_DETAILS_INPUT_NAMES.MONITORING,
                    visible: false,
                },
                [OFFER_DETAILS_INPUT_NAMES.EQUIPMENT_WARRANTY_INVERTERS]: {
                    name: OFFER_DETAILS_INPUT_NAMES.EQUIPMENT_WARRANTY_INVERTERS,
                    visible: false,
                    values: [
                        { value: 0, label: 'label.years' },
                        { value: 1, label: 'label.year' },
                        { value: 2, label: 'label.years' },
                        { value: 4, label: 'label.years' },
                        { value: 8, label: 'label.years' },
                    ],
                },
                [OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]: {
                    name: OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT,
                    visible: true,
                    inputType: 'number',
                    rules: validate(['required', 'number', 'min', 'max'], { min: 1, max: 5 }),
                    optValidation: { max: 5, min: 1 },
                },
            },
            [PRODUCT_IDS.CFP]: {
                [OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]: {
                    name: OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT,
                    visible: true,
                    inputType: 'number',
                    rules: validate(['required', 'number', 'min', 'max'], { min: 1, max: 5 }),
                    optValidation: { max: 5, min: 1 },
                },
            },
        },
        [USERS_TYPE_ID.CHANNEL]: {
            [PRODUCT_IDS.SPV]: {
                [OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]: {
                    name: OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT,
                    visible: true,
                    inputType: 'number',
                    rules: validate(['required', 'number', 'min', 'max'], { min: 1, max: 5 }),
                    optValidation: { max: 5, min: 1 },
                },
            },
        },
    },
};

export const INSTALLATION_PRICES_INPUT_NAMES = {
    INSTALLATION_LABOR_PRICE: 'installation_labor_price',
    INSTALLATION_LABOR_HOURS: 'installation_labor_hours',
    INSTALLATION_FIXED_PRICE: 'installation_fixed_price',
    INSTALLATION_LABOR_PRICE_PER_HOUR: 'installation_labor_price_per_hour',
    INCLUDE_FIXED_PRICE: 'includeFixedPrice',
};

export const INSTALLATION_FIXED_PRICE_IDS = {
    INCLUDE: '1',
    NOT_INCLUDE: '2',
};

export const INSTALLATION_PRICES_QUESTIONS = {
    [INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_HOURS]: {
        name: INSTALLATION_PRICES_INPUT_NAMES.INSTALLATION_LABOR_HOURS,
        label: 'page.businessModels.installationPrices.label.installationLaborHours',
        placeholder: 'label.insertValue',
    },
    [INSTALLATION_PRICES_INPUT_NAMES.INCLUDE_FIXED_PRICE]: {
        name: INSTALLATION_PRICES_INPUT_NAMES.INCLUDE_FIXED_PRICE,
        label: 'page.businessModels.installationPrices.label.includeFixedPrice',
        options: [
            {
                value: INSTALLATION_FIXED_PRICE_IDS.INCLUDE,
                label: 'label.yes',
            },
            {
                value: INSTALLATION_FIXED_PRICE_IDS.NOT_INCLUDE,
                label: 'label.no',
            },
        ],
    },
};

export const INSTALLATION_PRICES_VALIDATIONS = {
    MIN_LABOR_HOURS: 0,
    MAX_LABOR_HOURS: 10000,
};
