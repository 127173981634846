import IntlMessages from 'components/util/IntlMessages';
import { SidebarContext } from 'contexts/sidebarContext';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'contexts/userContext';

const BackofficeSidebarButton = ({ icon, title, selected, disabled, buttonConfigs }) => {
    let history = useHistory();

    const { configBackoffice } = useContext(UserContext);

    const { setSecondarySidebarPosSectionSelected, secondarySidebarPosSectionSelected, setSecondarySidebarSectionSelected } =
        useContext(SidebarContext);

    const clickSectionHandler = (section) => {
        setSecondarySidebarPosSectionSelected(section.name);
        history.push(section.navigationLabel);
    };
    return (
        <div
            className={`backoffice-sidebar-button${selected ? '-selected' : ''}${disabled ? '-disabled' : ''}`}
            onClick={() => {
                setSecondarySidebarSectionSelected(buttonConfigs.name);
            }}
        >
            <div className="sidebar-button-title">
                {icon({ selected: selected })}
                <span id={title}>
                    <IntlMessages id={title} />
                </span>
            </div>
            {isDefined(buttonConfigs?.sections) && selected && (
                <div className="sidebar-button-sections">
                    {buttonConfigs?.sections
                        ?.filter((section) => section.visible(configBackoffice?.[buttonConfigs?.title]?.[`show_${section.title}_menu`]))
                        ?.map((section) => (
                            <span
                                key={`${section.label}`}
                                className={`section${secondarySidebarPosSectionSelected === section.name ? '-selected' : ''}`}
                                onClick={() => clickSectionHandler(section)}
                            >
                                <IntlMessages id={section.label} />
                            </span>
                        ))}
                </div>
            )}
        </div>
    );
};

export default BackofficeSidebarButton;
