import { defineMessages } from 'react-intl';
import enMessages from '../locales/en_GB.json';

const enGBLang = {
    messages: {
        ...enMessages,
    },
    locale: 'en-GB',
    data: defineMessages,
};
export default enGBLang;
