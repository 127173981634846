import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { TRubric } from 'interfaces/rubric';
import { TUpFrontElements } from './detailed';
import { OFFER_DETAILS_INPUT_NAMES } from 'constants/businessModelsPro';
export type TUpFront = typeof PAYMENT_MODELS_IDS.UP_FRONT;

export type TUpFrontValues = {
    kpis_per_margin: TMarginUpFront[];
    margin_negotiation: TMarginNegotiation;
    marginSelected?: number;

    grants: { totalGrants: { prc: number; currency: number }; grants: TGrants[]; hasGrants: boolean };
    offerEdition: { extra_cost: number; duration?: Record<string, any> };
    rubrics: {
        cost: number;
        values: TRubric[];
    };
    totalCost: number;
    baseCost: number;
    installation_labor_price: number;
    installation_labor_hours: number;
    installation_fixed_price: number;
    costs: {
        baseCost: number;
        offerDetailsCost: number;
        marginCost: number;
        grantsCost: number;
        totalCost: number;
        omDurationCost: number;
    };
    clientContactInfo?: {
        name: string;
        email: string;
    };
    id_crm?: string;
    elements: TUpFrontElements;
};

export type TMarginUpFront = {
    annual_saving: number;
    discount: number;
    investment: number;
    margin: number;
    presentation_discount: number;
    presentation_margin: number;
    is_default: boolean;
    price?: number;
    annual_price?: number;
    agency_commission?: number;
};

export type TMarginNegotiation = {
    default: number;
    search: string;
};

export type TGrants = {
    id: number;
    description: string;
    ammounts: {
        currency: number;
        prc: number;
        fixed?: boolean;
    };
};

export const enum GrantsAmmountsType {
    PERCENTAGE = 'PERCENTAGE',
    CURRENCY = 'CURRENCY',
}

export enum UpFrontEvents {
    FETCH_UPFRONT_BM = 'FETCH_UPFRONT_BM',
    SET_UPFRONT_BM = 'SET_UPFRONT_BM',
    SET_UPFRONT_MARGIN = 'SET_UPFRONT_MARGIN',
    SET_UPFRONT_PROPOSAL_READY = 'SET_UPFRONT_PROPOSAL_READY',
    SET_UPFRONT_PROPOSAL = 'SET_UPFRONT_PROPOSAL',
    ADD_UPFRONT_GRANT = 'ADD_UPFRONT_GRANT',
    DELETE_UPFRONT_GRANT = 'DELETE_UPFRONT_GRANT',
    SET_DESCRIPTION_GRANT = 'SET_DESCRIPTION_GRANT',
    SET_AMMOUNT_GRANT = 'SET_AMMOUNT_GRANT',
    UPDATE_UPFRONT_GRANTS = 'UPDATE_UPFRONT_GRANTS',
    SET_EXTRA_COST = 'SET_EXTRA_COST',
    INIT_UFRONT_RUBRIC_COST = 'INIT_UFRONT_RUBRIC_COST',
    SET_UFRONT_RUBRIC_COST = 'SET_UFRONT_RUBRIC_COST',
    SET_UPDATE_COST = 'SET_UPDATE_COST',
    SET_INSTALLATION_HOURS_AND_PRICE = 'SET_INSTALLATION_HOURS_AND_PRICE',
    SET_INSTALLATION_FIXED_PRICE = 'SET_INSTALLATION_FIXED_PRICE',
    SET_OM_DURATION = 'SET_OM_DURATION',
    UPDATE_ADDITIONAL_DATA = 'UPDATE_ADDITIONAL_DATA',
    SET_EDP_PT_INPUTS = 'SET_EDP_PT_INPUTS',
}

export type TUpFrontEventsValues<Event> =
    Event extends typeof UpFrontEvents.FETCH_UPFRONT_BM ? null
    : Event extends typeof UpFrontEvents.SET_UPFRONT_BM ? TUpFrontValues
    : Event extends typeof UpFrontEvents.SET_UPFRONT_MARGIN ? number
    : Event extends typeof UpFrontEvents.ADD_UPFRONT_GRANT ? null
    : Event extends typeof UpFrontEvents.DELETE_UPFRONT_GRANT ? number
    : Event extends typeof UpFrontEvents.SET_DESCRIPTION_GRANT ? { description: string; id: number }
    : Event extends typeof UpFrontEvents.SET_AMMOUNT_GRANT ? { amount: number; ammountType: GrantsAmmountsType; id: number }
    : Event extends typeof UpFrontEvents.UPDATE_UPFRONT_GRANTS ? null
    : Event extends typeof UpFrontEvents.SET_EXTRA_COST ? { extra_cost: number }
    : Event extends typeof UpFrontEvents.SET_UFRONT_RUBRIC_COST ? { selected: boolean; id: number }
    : Event extends typeof UpFrontEvents.SET_OM_DURATION ?
        {
            [OFFER_DETAILS_INPUT_NAMES.PAYMENT_METHOD]: string;
            [OFFER_DETAILS_INPUT_NAMES.OM_DURATION]: number;
        }
    :   /* Event extends typeof UpFrontEvents.SET_UPDATE_LABOR_HOURS_AND_PRICE ? { labor_hours: number, labor_price: number } : */ any;
