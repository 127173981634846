import Dialog from '@mui/material/Dialog';

// Components
import IntlMessages from 'components/util/IntlMessages';
import { IconButton } from '@save2compete/efz-design-system';

// Icons
import { ReactComponent as ArrowIcon } from 'assets/images/businessModels/item-arrow.svg';

const GrantsDialog = ({ isOpen, onCloseHandler }) => {
    const grantCriterias = ['homeowner', 'property', 'epc'];
    const grantEPCScenario = ['loft', 'insuflations', 'exception'];
    return (
        <Dialog open={isOpen} onClose={onCloseHandler} fullScreen className="hp-dialog">
            <div className="hp-dialog-title">
                <div className="hp-dialog-title-left">
                    <IconButton variant="tertiary" icon="xMarkExit" aria-label="close" onClick={onCloseHandler} dataTestId="close" />
                    <h1>
                        <IntlMessages id={`page.bm.grants.title`} />
                    </h1>
                </div>
                <div className="hp-dialog-title-right" />
            </div>
            <div className="bm-dialog-body">
                <label>
                    <IntlMessages id={`page.bm.grants.hp.criterias.label`} />
                </label>
                {grantCriterias.map((criteria) => {
                    return (
                        <div className="bm-dialog-grant-item">
                            <ArrowIcon />
                            <IntlMessages id={`page.bm.grants.hp.criterias.${criteria}`} />
                        </div>
                    );
                })}
                <label>
                    <IntlMessages id={`page.bm.grants.hp.criterias.label`} />
                </label>
                {grantEPCScenario.map((scenario) => {
                    return (
                        <div className="bm-dialog-grant-item">
                            <ArrowIcon />
                            <IntlMessages id={`page.bm.grants.hp.epc.${scenario}`} />
                        </div>
                    );
                })}
            </div>
        </Dialog>
    );
};

export default GrantsDialog;
