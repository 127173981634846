import { SolutionsProvider } from 'contexts/solutions/solutionsContext';
import { lazy } from 'react';
import { withRouter } from 'react-router';
import lazyLoader from 'services/lazyLoader';
import { withTracker } from 'services/withTracker';

const SolutionsContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Solutions')));

/**
 * @context Route to Solutions
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Solutions = () => (
    <SolutionsProvider>
        <SolutionsContainer />
    </SolutionsProvider>
);

export default withRouter(withTracker(Solutions, 'Solutions'));
