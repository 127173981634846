import { useContext } from 'react';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPPa } from 'interfaces/businessModels/ppa';

// Components
import CommercialMarginPPa from './CommercialMargin';
import GrantsPPa from './Grants';
import OfferDetailsPPa from './OfferDetails';

const ProjectSummaryPPaSimple = () => {
    const { negotiationProfile, state, bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;

    const has_offer_edition = state.businessModels.find((bm) => bm.business_model_id === bmSelected.id)?.has_offer_edition;

    return (
        <>
            {negotiationProfile?.acesso_resumo_custo && has_offer_edition ?
                <OfferDetailsPPa />
            :   <></>}
            {negotiationProfile?.negoceia_proposta ?
                <CommercialMarginPPa />
            :   <></>}
            {negotiationProfile?.mostra_subsidios ?
                <GrantsPPa />
            :   <></>}
        </>
    );
};

export default ProjectSummaryPPaSimple;
