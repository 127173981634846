import { useContext, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

// Components
import Alert from 'components/@efz/Alert';
import IntlMessages from 'components/util/IntlMessages';
import { IBmDetailedForm } from 'components/BusinessModels';
import Loading from 'components/core/Loading';
import OfferSummaryInstallmentsDetailed from './OfferSummary';
import NegotiationInstallmentsDetailed from './Negotiation';
import ContactInfoInstallments from './ContactInfo';

// Contexts
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Constants
import { BUSINESS_MODEL_ELIGIBLE, SIZING_TYPE_IDS } from 'constants/businessModels';

// Interfaces
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront } from 'interfaces/businessModels/upfront';

// Services
import { isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';

const ProjectSummaryInstallmentsDetailed = () => {
    const {
        negotiationProfile,
        bmSelected,
        state: {
            selected: {
                values: { rubrics },
            },
        },
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const { bmState, setBMEventHandler } = useContext(OfferEditionContext);

    const [fullDirty, setFullDirty] = useState(false);

    const methods = useFormContext<IBmDetailedForm>();

    const hasErrorOnNetSavingsEligibility = bmState?.kpis?.negotiation?.hasErrorOnNetSavingsEligibility;
    const hasAuditProposal =
        (isFieldDefined(bmState?.businessModelSelected) &&
            bmState?.businessModelSelected?.sizing_type_id === SIZING_TYPE_IDS.DETAILED_FULL &&
            bmState?.kpis?.negotiation.status_risk_premium_id === BUSINESS_MODEL_ELIGIBLE.NO) ||
        hasErrorOnNetSavingsEligibility;

    const watchAllFields = methods.watch();

    const updateValues = () => {
        if (methods.formState.isDirty && !fullDirty) setFullDirty(true);
        setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_VALUES, {
            // @ts-ignore
            values: methods.getValues(),
            sliderReady: methods.formState.isDirty || fullDirty ? false : true,
        });
    };

    useEffect(() => {
        updateValues(); // eslint-disable-next-line
    }, [
        watchAllFields?.monthly_payment_option_id,
        watchAllFields?.opAndManDuration,
        watchAllFields?.annualEvoFee,
        watchAllFields?.extraCost,
        rubrics.cost,
    ]);

    const showsContactInfo = negotiationProfile?.mostra_formulario && bmSelected?.options?.has_form;

    if (!bmState.businessModelSelected) return <Loading />;
    return (
        <div className="bm-detailed">
            {!hasErrorOnNetSavingsEligibility && <OfferSummaryInstallmentsDetailed />}
            {!hasAuditProposal && bmState?.sliderReady && isDefined(bmState.offerEditionValues.monthly_payment_option_id) && (
                <NegotiationInstallmentsDetailed />
            )}
            {bmState?.sliderReady && hasAuditProposal && (
                <Alert severity={'error'}>
                    <IntlMessages
                        id={
                            hasErrorOnNetSavingsEligibility ?
                                'page.businessModels.notEligible.reason.not_net_savings_eligibility'
                            :   'page.businessModels.auditProposal.info'
                        }
                    />
                </Alert>
            )}
            {showsContactInfo ?
                <ContactInfoInstallments />
            :   <></>}
        </div>
    );
};

export default ProjectSummaryInstallmentsDetailed;
