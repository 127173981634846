import { Nullable } from 'types/utils';
import { create } from 'zustand';
// import { devtools } from 'zustand/middleware';
export const FEATURE_FLAGS = [
    2005, 1894, 1961, 1992, 2168, 2186, 2159, 2262, 1871, 2243, 2290, 2337, 2336, 2339, 2345, 2347, 2376, 2371, 2356, 1182, 2365, 2363,
    2361, 2360, 2398, 2374, 2359, 2395, 2358, 2396, 2417, 2354, 2364, 2418, 2423, 2416,
] as const;

export const FEATURE_FLAGS_TST = [2168, 2262, 2243, 2290, 2337, 2336, 2345, 2371, 2365, 2398, 2359, 2358, 2396] as const;

type FeatureFlagKeys = (typeof FEATURE_FLAGS)[number] | (typeof FEATURE_FLAGS_TST)[number];

export type TFeatureFlags = {
    [K in `fe-${FeatureFlagKeys}`]: boolean;
};
interface IFeatureFlags {
    featureFlags: TFeatureFlags;
    id: Nullable<number>;
    setFeatureFlags: (featureFlags: TFeatureFlags, id: Nullable<number>) => void;
}

export const initialFeatureFlags = [...FEATURE_FLAGS, ...FEATURE_FLAGS_TST].reduce((acc, flag) => {
    acc[`fe-${flag}`] = false;
    return acc;
}, {} as TFeatureFlags);

export const useFeatureFlags = create<IFeatureFlags>()(
    // devtools(
    (set) => ({
        featureFlags: initialFeatureFlags,
        id: null,
        setFeatureFlags: (featureFlags: TFeatureFlags, id) => set({ featureFlags, id }),
    })
    // )
);
