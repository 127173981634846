import MarginInstallmentsSimple from 'components/BusinessModels/ProjectSummary/ProjectSummaryInstallments/simple/CommercialMargin/Margin';
import InstallmentsSimpleKpis from 'components/BusinessModels/ProjectSummary/ProjectSummaryInstallments/simple/CommercialMargin/MarginKpis';
import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TInstallments } from 'interfaces/businessModels/installments';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';

const CommercialMarginInstallmentsSimple = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TInstallments>;
    return (
        <div className="bm-project-summary-card bm-project-summary-commercial-margin">
            <ProjectSummaryTitle label={bmSelected?.isDiscount ? 'label.comercialDiscount' : 'label.comercialMargin'} />
            <MarginInstallmentsSimple />
            {isDefined(bmSelected?.values?.marginSelected) && <InstallmentsSimpleKpis />}
        </div>
    );
};

export default CommercialMarginInstallmentsSimple;
