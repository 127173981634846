/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Actions Sizing
 *
 * @version 20191113
 * @since 20191113 Initial release
 *
 */

import {
    REQUEST_GET_SIZING,
    REQUEST_GET_SIZING_SUCCESS,
    REQUEST_GET_SIZING_FAIL,
    RESET_SIZING,
    SET_DATA_SIZING_BY_WEBSTOMP,
} from 'constants/sizing';

export function setDataSizingByWebstomp(payload) {
    return {
        type: SET_DATA_SIZING_BY_WEBSTOMP,
        payload: payload,
    };
}

export function requestGetSizing(payload) {
    return {
        type: REQUEST_GET_SIZING,
        payload: payload,
    };
}

export function requestGetSizingSuccess(payload) {
    return {
        type: REQUEST_GET_SIZING_SUCCESS,
        payload,
    };
}

export function requestGetSizingFail() {
    return {
        type: REQUEST_GET_SIZING_FAIL,
    };
}

export function resetSizing() {
    return {
        type: RESET_SIZING,
    };
}
