export const info = {
    50: '#F1F8FF',
    100: '#D7E7F5',
    200: '#C3DAF0',
    300: '#9CC2E6',
    400: '#74AADC',
    500: '#609DD7',
    600: '#3885CD',
    700: '#2D6AA4',
    800: '#22507B',
    900: '#163552',
};

export const neutral = {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#E5E5E5',
    300: '#DCDCDC',
    400: '#C4C4C4',
    500: '#B1B1B1',
    600: '#030202',
    700: '#404040',
    800: '#202020',
    900: '#000000',
    950: '#0D0D0D',
};
