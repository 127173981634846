interface IProps {
    description: string;
    summary: string;
}
const BusinessModelTitleESolar = ({ description, summary }: IProps) => {
    return (
        <>
            <span className="header">{description}</span>
            <span className="subheader">{summary}</span>
        </>
    );
};

export default BusinessModelTitleESolar;
