import { SettingsActions } from 'constants/settings';
import { ISettingsAction, ISettingsState } from 'interfaces/settings';

export const initialSettingsState: ISettingsState = {
    isMoutingApp: true,
    featureFlags: [],
};

export const settingsReducer = (state = initialSettingsState, action: ISettingsAction) => {
    switch (action.type) {
        case SettingsActions.MOUNT_APP:
            return {
                ...state,
                isMoutingApp: action.payload.isMoutingApp,
                featureFlags: action.payload.featureFlags,
            };
        default:
            return state;
    }
};
