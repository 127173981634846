import { useContext, useEffect, useRef, useState } from 'react';

//Components
import IntlMessages from 'components/util/IntlMessages';

//Context
import { UserContext } from 'contexts/userContext';

// Constants
import { AVAILABLE_USER_LOCALES } from 'constants/user';
import { useUserStore } from 'store/user';

type ItranslateFacility = {
    plural?: boolean;
    styleLetter?: 'capitalize' | 'lowercase' | 'uppercase';
    enableStyle?: boolean;
};

const TranslateFacility: React.FC<ItranslateFacility> = ({ plural, styleLetter, enableStyle }) => {
    const { isB2C } = useContext(UserContext);

    const { locale } = useUserStore();

    const appLocale = locale?.appLocale ?? AVAILABLE_USER_LOCALES.enGB;
    let labelSelect;
    if (isB2C) labelSelect = !plural ? 'label.house' : 'label.house.plural';
    if (!isB2C) labelSelect = !plural ? 'label.facility' : 'label.facility.plural';

    const enableStyleRef = useRef(enableStyle);
    const styleLetterRef = useRef(styleLetter);

    const [isCheckFinished, setIsCheckFinished] = useState(false);

    useEffect(() => {
        // In German, all nouns are Capitalized. We need to ensure this rule is followed.
        if ([AVAILABLE_USER_LOCALES.deCH, AVAILABLE_USER_LOCALES.deDE].includes(appLocale) && styleLetterRef.current !== 'uppercase') {
            enableStyleRef.current = true;
            styleLetterRef.current = 'capitalize';
        }
        setIsCheckFinished(true);
    }, [appLocale]);

    return (
        <span className={`${!isCheckFinished ? 'd-none' : ''}`}>
            {enableStyleRef.current ?
                <span className={'text-' + styleLetterRef.current}>
                    <IntlMessages id={labelSelect} />
                </span>
            :   <IntlMessages id={labelSelect} />}
        </span>
    );
};

TranslateFacility.defaultProps = {
    plural: false,
    styleLetter: 'lowercase',
    enableStyle: true,
};

export default TranslateFacility;
