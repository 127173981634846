import IconButton from '@mui/material/IconButton';
import { useContext } from 'react';
import { useFlexLayout, useTable } from 'react-table';

// Components
import IntlMessages from 'components/util/IntlMessages';
import Alert from 'components/@efz/Alert';

// Constants
import { GRANTS_TABLE_CONFIG, MAX_GRANTS } from 'constants/businessModelsPro/grants';
import { PRODUCT_IDS } from 'constants/products';

// Copntexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Icons
import { ReactComponent as PlusIcon } from 'assets/@efz/icons/plus.svg';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import useLECGrants from 'hooks/businessModelsPro/LEC/useLECGrants';
import { Tlec } from 'interfaces/businessModels/lec';

// Services

const GrantsTableLEC = () => {
    const { bmSelected, productID } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, Tlec>;

    const columns = GRANTS_TABLE_CONFIG;
    const data = bmSelected.values.grants?.grants;
    const isRequesting = false;

    const { handleChangeAmmount, handleChangeDescription, handleDelete, handleAddGrant } = useLECGrants();

    const matomoDeleteHandler = () => {
        if ([PRODUCT_IDS.HP].includes(productID)) {
            // @ts-ignore
            window._paq.push(['trackEvent', 'Grants', 'Click', 'Remove Grant']);
        }
    };

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
        {
            columns,
            data,
            handleChangeDescription,
            handleChangeAmmount,
            handleDelete,
            matomoDeleteHandler,
        },
        useFlexLayout
    );

    const conditionsToDisableAddGrant =
        bmSelected.values.grants.grants.length === MAX_GRANTS || bmSelected.values.grants.totalGrants.prc >= 100;

    return (
        <div className="bm-grants-table">
            <div className="remote-dialog-table ">
                <div {...getTableProps()} className={isRequesting ? 'table-loading-overlay' : 'table-hover'}>
                    <div className="thead">
                        {headerGroups.map((headerGroup) => (
                            <div {...headerGroup.getHeaderGroupProps()} className="tr d-flex align-self-center">
                                {headerGroup.headers.map((column) => (
                                    <div {...column.getHeaderProps()} className={`th ${column.className ?? ''}`}>
                                        {column.render('Header')}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                    <div className="tbody" {...getTableBodyProps()}>
                        {data.length > 0 &&
                            rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <div {...row.getRowProps()} className={`tr d-flex align-self-center`}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <div {...cell.getCellProps()} className={`td d-flex ${cell.column.className ?? ''}`}>
                                                    {cell.render('Cell')}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
            <div className={`bm-grants-table-add ${conditionsToDisableAddGrant ? 'disabled' : ''}`}>
                <IconButton onClick={handleAddGrant} disableRipple disabled={conditionsToDisableAddGrant}>
                    <PlusIcon className={`bm-grants-table-add-icon me-3`} />
                    <span>
                        <IntlMessages id="page.bm.grants.add" />
                    </span>
                </IconButton>
            </div>
            {bmSelected.values.grants.totalGrants.prc > 100 && (
                <Alert severity="warning" className="mb-2 mt-2 w-50">
                    <IntlMessages id="page.bm.grants.prcExcceeded" />
                </Alert>
            )}
            {bmSelected.values.grants.grants.length === MAX_GRANTS && (
                <Alert severity="error" className="mb-2 mt-2 w-50">
                    <IntlMessages id="page.bm.grants.grantWarningLimit" values={{ max: MAX_GRANTS }} />
                </Alert>
            )}
        </div>
    );
};

export default GrantsTableLEC;
