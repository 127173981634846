import { isDefined, parseNumberWithDecimals } from 'services/util/auxiliaryUtils';
import { EVCWithManagementOption } from './index';
import { getInputWithTaxes } from 'services/products/evc';
import { PRODUCT_IDS } from 'constants/products';
import { IBusinessModelsState } from './../../interfaces/businessModels/reducer';
import { TBMKpi, TProductInputs } from './../../interfaces/businessModels/index';
import { TInstallments, TInstallmentsValues } from 'interfaces/businessModels/installments';
import { getCompanyProfileIds } from 'services/user';

export const installmentsProjectSummary = (
    { kpis, syncKpis }: { kpis: TInstallmentsValues[]; syncKpis: any },
    state: IBusinessModelsState<TProductInputs, TInstallments>,
    configs: { companyProfileId: number; productID: number; featureFlags }
) => {
    let projectSummary: TBMKpi[] = [];

    switch (configs.productID) {
        case PRODUCT_IDS.SPV:
        case PRODUCT_IDS.SPVSB: {
            let investment = kpis[0].kpis_per_margin?.[0].investment;
            let payback = investment / kpis[0].kpis_per_margin?.[0].annual_saving;
            if (isDefined(syncKpis)) {
                investment = syncKpis?.kpis?.monthly_payments_number * syncKpis?.negotiation?.default;
                payback = investment / syncKpis?.kpis?.annual_saving;
            }
            if (configs.companyProfileId === getCompanyProfileIds().PPC) {
                const monthlyValue = syncKpis?.negotiation?.default;
                projectSummary = [
                    { label: 'label.monthly', value: parseNumberWithDecimals(monthlyValue, 2), unit: 'mc', numberOfDecimalPlaces: 2 },
                ];
            } else {
                projectSummary = [
                    {
                        label: 'label.investment',
                        value: parseNumberWithDecimals(investment, 0),
                        unit: 'currency',
                        numberOfDecimalPlaces: 0,
                    },
                    {
                        label: 'label.payback',
                        value: parseNumberWithDecimals(payback, 1),
                        unit: 'year',
                        numberOfDecimalPlaces: 1,
                    },
                ];
            }
            break;
        }
        case PRODUCT_IDS.EVC:
        case PRODUCT_IDS.EVCSB:
            projectSummary = [
                {
                    label: 'label.equipmentAndInstallationTotal',
                    value: kpis[0].kpis_per_margin?.[0].price,
                    unit: 'currency',
                    numberOfDecimalPlaces: 0,
                },
                {
                    label:
                        [getCompanyProfileIds().EDP_ES].includes(configs.companyProfileId) ? 'label.equipmentAndInstallation' : (
                            'label.equipmentAndInstallationMonthlyFee'
                        ),
                    subLabel:
                        [getCompanyProfileIds().EDP_ES].includes(configs.companyProfileId) ? 'page.evc.BModel.servicessubLabel1' : (
                            undefined
                        ),
                    value: kpis[0].kpis_per_margin?.[0].monthly_payments_fee,
                    operator_me: true,
                    isServiceME_ES: false,
                    monthly_payments_number: kpis[0].kpis_per_margin?.[0].monthly_payments_number,
                    unit: 'currency',
                    numberOfDecimalPlaces: 0,
                },
            ];
            if (EVCWithManagementOption.visible(state.simulation.inputs.management_option_id)) {
                projectSummary.push({
                    isServiceME_ES: false,
                    label:
                        [getCompanyProfileIds().EDP_ES].includes(configs.companyProfileId) ? 'label.managementPlatforms' : (
                            `page.evc.resume.total_platform_price_month_companyID${EVCWithManagementOption.tag(state.simulation.inputs.management_option_id)}`
                        ),
                    subLabel:
                        [getCompanyProfileIds().EDP_ES].includes(configs.companyProfileId) ? 'page.evc.BModel.servicessubLabel2' : (
                            undefined
                        ),
                    value: getInputWithTaxes('total_platform_price_month', configs?.companyProfileId, state.simulation),
                    unit: 'mc',
                });
            }

            break;

        case PRODUCT_IDS.TRE:
            projectSummary = [{ label: 'label.price', value: kpis[0].kpis_per_margin?.[0].price }];
            break;

        case PRODUCT_IDS.CFP:
            projectSummary = [
                {
                    label: 'label.investment',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].investment, 0),
                },
                {
                    label: 'label.payback',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].investment / kpis[0].kpis_per_margin?.[0].annual_saving, 1),
                    unit: 'year',
                    numberOfDecimalPlaces: 1,
                },
            ];
            break;
        case PRODUCT_IDS.HVAC_BR:
            projectSummary = [{ label: 'label.investment', value: kpis[0].kpis_per_margin?.[0]?.investment }];
            break;
        case PRODUCT_IDS.IE:
        case PRODUCT_IDS.IE_BR:
            projectSummary = [
                { label: 'label.investment', value: kpis[0].kpis_per_margin?.[0].investment },
                {
                    label: 'label.payback',
                    value: parseNumberWithDecimals(kpis[0].kpis_per_margin?.[0].investment / kpis[0].kpis_per_margin?.[0].annual_saving, 1),
                    unit: 'year',
                    numberOfDecimalPlaces: 1,
                },
            ];
            break;
        default:
            break;
    }

    return projectSummary;
};
