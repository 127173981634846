import { postSendEmailMEAR } from 'api/proposal';
import { OK } from 'constants/statusCodeHttp';
import { useState } from 'react';
import { notify } from 'services/@efz/notify';
import IntlMessages from 'components/util/IntlMessages';

const useSendLinkARtoEmail = () => {
    const [isOpenSendEmailDialog, setIsOpenSendEmailDialog] = useState(false);
    const [isSedingEmail, setIsSedingEmail] = useState(false);

    const fetchSendARLinkToEmail = async (proposalID, payload) => {
        setIsSedingEmail(true);

        const rspData = await postSendEmailMEAR(proposalID, payload);

        if (rspData?.data?.status === OK) {
            notify(<IntlMessages id={'page.proposal.text.ARLinkSent'} values={{ email: payload.email }} />, 'success');
        } else {
            notify(<IntlMessages id={'page.error.403.unexpected'} />, 'error');
        }

        setIsOpenSendEmailDialog(false);

        setIsSedingEmail(false);
    };

    return {
        isOpenSendEmailDialog,
        setIsOpenSendEmailDialog,
        isSedingEmail,
        fetchSendARLinkToEmail,
    };
};

export default useSendLinkARtoEmail;
