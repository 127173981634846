import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { useContext } from 'react';
import OfferSummaryActionsESolarDetailed from './Actions';
import FinanceCustomizationESolarDetailed from './FinanceCustomization';
import ProjectSummaryESolarDetailed from './ProjectSummary';

const OfferSummaryESolarDetailed = () => {
    const { bmState, negotiationProfile } = useContext(OfferEditionContext);

    const showFinanceCustomization = bmState?.businessModelSelected?.financing_customization?.enabled ?? false;
    const showOfferSummary = bmState?.businessModelSelected?.has_offer_edition && negotiationProfile?.acesso_resumo_custo;

    return (
        <div className="bm-project-summary-card bm-detailed-offer-summary">
            <ProjectSummaryTitle label="label.SummaryOffer" />

            <div className="bm-project-summary-offer-details-body">
                {showFinanceCustomization && <FinanceCustomizationESolarDetailed showOfferSummary={showOfferSummary} />}
                {showOfferSummary && <ProjectSummaryESolarDetailed />}
            </div>

            <OfferSummaryActionsESolarDetailed />
        </div>
    );
};

export default OfferSummaryESolarDetailed;
