import { memo /* useMemo, useContext, useContext*/ } from 'react';
import PropTypes from 'prop-types';

//Material UI
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useTheme } from '@mui/material';
import { useHistory } from 'react-router';
//Our Components
import IntlMessages from 'components/util/IntlMessages';
import { ReactComponent as FacilityIcon } from 'assets/images/icons/svg/facility.svg';
import TranslateFacility from 'components/@efz/TranslateFacility';
import { Button } from '@save2compete/efz-design-system';
//import { ReactComponent as AddIcon } from 'assets/images/icons/svg/add-square-empty.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/svg/arrow-down.svg';
import SectionInfo from './SectionInfo';
import SectionSearch from './SectionSearch';
import { BASENAME_URL_FACILITY } from 'constants/settings';
//import { createFacilityIsAvaliable } from 'services/facility';
import { gAnalyticsEvt } from 'services/gAnalytics';
import { GA_LABEL_TOP_LEVEL_NAVIGATION, GA_TYPE_EVT } from 'constants/gAnalytics';
import { isFieldDefined } from 'services/util/auxiliaryUtils';

const FacilityCard = ({
    showInfo,
    title,
    subtitle,
    isOpen,
    handleToggle,
    handleChangeAutocomplete,
    handlerSeeAll,
    handlerOpenFacility,
    searchData,
    facilitiesList,
    isClientSelected,
    handlerClearFacility,
    isActiveViewEdit,
    isThisPathActive,
}) => {
    const theme = useTheme();
    const history = useHistory();
    //const { allowedToCreateFacility } = useContext(UserContext);

    /* const _createFacilityIsAvaliable = useMemo(() => createFacilityIsAvaliable(allowedToCreateFacility, isClientSelected),
        [isClientSelected, allowedToCreateFacility]); */

    const handleGoToFacility = () => {
        history.push(BASENAME_URL_FACILITY, { href: history.location.pathname });
        handleToggle();
    };

    return (
        <Dropdown isOpen={isOpen} toggle={handleToggle}>
            <DropdownToggle
                className="header-dropdown-toggle"
                tag="span"
                data-toggle="dropdown"
                onClick={() => gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION)}
            >
                <div className={`menu-item-content`} onClick={handleToggle}>
                    <span className={`me-lg-2 default-svg${isOpen || isThisPathActive ? '--open' : ''}`}>
                        <FacilityIcon height={24} fill={theme.palette.primary.main} />
                    </span>
                    <div className="menu-item-text">
                        <h5 className={`menu-item-label${isOpen || isThisPathActive ? '--open' : ''}`}>
                            <TranslateFacility styleLetter="capitalize" />
                        </h5>
                        {(
                            showInfo // Facility selected
                        ) ?
                            <h6>{title}</h6>
                        :   null}
                    </div>
                    <div
                        className={`menu-item-arrow${isOpen ? '--open' : '--closed'} default-svg${isOpen || isThisPathActive ? '--open' : ''}`}
                    >
                        <ArrowIcon height={24} fill={theme.palette.primary.main} />
                    </div>
                </div>
            </DropdownToggle>

            <DropdownMenu end>
                {showInfo && (
                    <SectionInfo
                        title={isFieldDefined(title) ? title : ''}
                        subtitle={subtitle}
                        handlerOpenToggle={handlerOpenFacility}
                        handlerClearSelection={handlerClearFacility}
                        handleGoTo={handleGoToFacility}
                        isActiveViewEdit={isActiveViewEdit}
                    />
                )}
                <SectionSearch
                    handleChangeAutocomplete={handleChangeAutocomplete}
                    searchData={searchData}
                    handlerSeeAll={handlerSeeAll}
                    facilitiesList={facilitiesList}
                />
                {isClientSelected && (
                    <div className="facility-dropdown-buttons">
                        <Button
                            variant="tertiary"
                            onMouseDown={handlerSeeAll}
                            onClick={() => gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_SEE_ALL, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION)}
                            dataTestId="facility-see-all"
                        >
                            <IntlMessages id="label.seeAll" />
                        </Button>
                    </div>
                )}
                {/*_createFacilityIsAvaliable &&
                    <div className="section-btn-add">
                        <Button className="tertiary-button m-3"
                            disableRipple={true}
                            onMouseDown={handlerCreateFacility}
                        >
                            <AddIcon className="me-1" />
                            <span><IntlMessages id="label.addFacility" /></span>
                        </Button>
                    </div>


                */}
            </DropdownMenu>
        </Dropdown>
    );
};

//PropTypes
FacilityCard.defaultProps = {
    isOpen: false,
};

FacilityCard.propTypes = {
    subtitle: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleChangeAutocomplete: PropTypes.func.isRequired,
    handlerSeeAll: PropTypes.func.isRequired,
    searchData: PropTypes.object.isRequired,
    facilitiesList: PropTypes.array.isRequired,
    isClientSelected: PropTypes.bool.isRequired,
    companyProfileId: PropTypes.number.isRequired,
    handlerCreateFacility: PropTypes.func.isRequired,
};
export default memo(FacilityCard);
