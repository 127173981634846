import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TInstallments } from 'interfaces/businessModels/installments';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';
import ProjectSummaryInstallmentsSimple from './simple';
import ProjectSummaryInstallmentsDetailed from './detailed';

const ProjectSummaryInstallments = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TInstallments>;

    if (isDefined(bmSelected.values?.syncKpis)) return <ProjectSummaryInstallmentsDetailed />;
    return <ProjectSummaryInstallmentsSimple />;
};

export default ProjectSummaryInstallments;
