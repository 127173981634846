import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

//components
import IntlMessages from 'components/util/IntlMessages';
import { Checkbox, FormControlLabel } from '@mui/material';

//hooks and actions
import { getStaticFiles } from 'constants/endpoints';
import { useAuthStore } from 'store/auth';
import { Button } from '@save2compete/efz-design-system';

const TermsBanner = ({ userTerms, userTypeID, setUserTerms }) => {
    const [isTermsMaster, setTermsMaster] = useState(false);
    const { logout, loginOK } = useAuthStore();

    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = () => {
        loginOK({ token: userTerms, dispatch, history, fromURL: false });
    };

    const handleUserSignOut = () => {
        window._paq.push(['deleteCustomDimension', userTypeID]);
        logout({});
        setUserTerms(null);
    };

    const termsPdf = 'Effizency-You_Master-Customer-Agreement.pdf';

    return (
        <div className="terms-banner">
            <div className="terms-banner-content">
                <form className="form">
                    <div className="terms-banner-content-text">
                        <FormControlLabel
                            control={<Checkbox onChange={() => setTermsMaster(!isTermsMaster)} />}
                            label={
                                <>
                                    {' '}
                                    <IntlMessages id="label.terms.content" whitespaceEnd />
                                    <a
                                        href={getStaticFiles(termsPdf, 'terms/')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="download-link"
                                    >
                                        <IntlMessages id="label.termsAgreement" />
                                    </a>
                                    .
                                </>
                            }
                            className="m-0"
                        />
                    </div>
                    <div className="terms-banner-content-options">
                        <Button variant="tertiary" onClick={handleUserSignOut} dataTestId="cancel">
                            <IntlMessages id="label.cancel" whitespaceEnd />
                        </Button>
                        <Button disabled={!isTermsMaster} onClick={onSubmit} dataTestId="confirm">
                            <IntlMessages id="label.confirm" />
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TermsBanner;
