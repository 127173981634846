// Constants
import { DEFAULTS_PROPS_MAP, RRMapActions } from 'constants/products/rr';

// Interfaces
import {
    IRRMapPolygonEventPayload,
    IRRMapReducerAction,
    IRRMapSetCurrentAreaIndexPayload,
    IRRMapSetInitPayload,
    MapReducer,
} from 'interfaces/products/rr/map';

// Services
import { isFieldDefined } from 'services/util/auxiliaryUtils';

export const initialRRMapReducerState: MapReducer = {
    mainMap: null,
    mainDrawingManager: null,
    mainGoogle: null,
    polygon: null,
    polygonArray: [],
    currentAreaIndex: 0,
    isDrawing: false,
};

export const polygonStyle: google.maps.PolygonOptions = {
    strokeOpacity: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeOpacity,
    strokeWeight: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeWeight,
    fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
    fillColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.fillColor.selected,
    strokeColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeColor.selected,
};

const disableMap = (drawingManager: google.maps.drawing.DrawingManager, map: google.maps.Map) => {
    disableDrawing(drawingManager);

    map.setOptions(DEFAULTS_PROPS_MAP.mapDisabledOption);
};

const enableMap = (drawingManager: google.maps.drawing.DrawingManager, map: google.maps.Map) => {
    disableDrawing(drawingManager);

    map.setOptions(DEFAULTS_PROPS_MAP.mapEnabledOption);
};

const disablePolygon = (polygon: google.maps.Polygon) => {
    // polygon?.setMap(null)
    polygon.setOptions({
        clickable: false,
        editable: false,
        draggable: false,
        strokeOpacity: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeOpacity,
        strokeWeight: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeWeight,
        fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
        fillColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.fillColor.notSelected,
        strokeColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeColor.notSelected,
    });
};

const enablePolygon = (polygon: google.maps.Polygon) => {
    // polygon?.setMap(null)
    polygon.setOptions({
        clickable: false,
        editable: false,
        draggable: false,
        strokeOpacity: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeOpacity,
        strokeWeight: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeWeight,
        fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
        fillColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.fillColor.selected,
        strokeColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeColor.selected,
    });
};

const disableDrawing = (drawingManager: google.maps.drawing.DrawingManager) => {
    drawingManager.setOptions({
        drawingMode: null,
    });
};

const enableDrawing = (drawingManager: google.maps.drawing.DrawingManager) => {
    drawingManager.setOptions({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        polygonOptions: {
            ...polygonStyle,
        },
    });
};

const panToPolygon = (
    polygon: google.maps.Polygon,
    map: google.maps.Map,
    getCenterId: (polygon: google.maps.Polygon) => google.maps.LatLng,
    getPolygonBounds: (polygon: google.maps.Polygon) => google.maps.LatLngBounds
) => {
    map.panTo(getCenterId(polygon));
    map.fitBounds(getPolygonBounds(polygon));
};

const rrMapReducer = (state = initialRRMapReducerState, action: IRRMapReducerAction<RRMapActions>) => {
    switch (action.type) {
        case RRMapActions.SET_INIT: {
            const { mainDrawingManager, mainGoogle, mainMap, polygon, polygonArray, getCenterId, getPolygonBounds } =
                action.payload as IRRMapSetInitPayload;

            if (!mainGoogle || !mainDrawingManager || !mainMap || !getCenterId || !getPolygonBounds) return state;

            if (polygonArray && Array.isArray(polygonArray) && polygonArray?.length > 0) {
                polygonArray.forEach((polygon, index) => {
                    polygon.setMap(mainMap);
                    disableMap(mainDrawingManager, mainMap);

                    if (index === 0) {
                        enablePolygon(polygon);
                        panToPolygon(polygon, mainMap, getCenterId, getPolygonBounds);
                    } else {
                        disablePolygon(polygon);
                    }
                });
            }

            mainMap.set('original_center', mainMap.getCenter());

            return {
                ...state,
                mainGoogle,
                mainDrawingManager,
                mainMap,
                polygon,
                polygonArray,
            };
        }

        case RRMapActions.SET_CURRENT_AREA: {
            const {
                currentAreaIndex: newCurrentAreaIndex,
                getCenterId,
                getPolygonBounds,
                isDeleting = false,
            } = action.payload as IRRMapSetCurrentAreaIndexPayload;

            const { polygon, polygonArray, mainMap, mainDrawingManager, currentAreaIndex } = state;

            if (!mainDrawingManager || !mainMap) return state;
            if (!getCenterId || !getPolygonBounds) return state;
            if (!isFieldDefined(newCurrentAreaIndex) || newCurrentAreaIndex < 0) return state;

            let newState = {
                ...state,
                currentAreaIndex: newCurrentAreaIndex,
                isDrawing: false,
            };

            if (isDeleting) {
                polygon?.setMap(null);
                polygonArray?.splice(currentAreaIndex, 1);

                newState = {
                    ...newState,
                    polygon: null,
                    polygonArray,
                };
                enableMap(mainDrawingManager, mainMap);
            }

            if (
                !!polygonArray &&
                polygonArray?.length - 1 >= newCurrentAreaIndex &&
                isFieldDefined(polygonArray?.at(newCurrentAreaIndex))
            ) {
                // change to new areas' polygon
                const _polygon = polygonArray.at(newCurrentAreaIndex);

                if (!_polygon) return state;

                // polygon?.setMap(null)
                // _polygon.setMap(mainMap)

                if (polygon) disablePolygon(polygon);

                enablePolygon(_polygon);

                disableMap(mainDrawingManager, mainMap);

                panToPolygon(_polygon, mainMap, getCenterId, getPolygonBounds);

                newState = {
                    ...newState,
                    polygon: _polygon,
                };
            } else {
                // new area doesnt have a polygon, change map mode
                if (polygon) {
                    disablePolygon(polygon);
                    newState = {
                        ...newState,
                        polygon: null,
                    };
                }

                enableMap(mainDrawingManager, mainMap);
            }

            return newState;
        }

        case RRMapActions.SET_IS_DRAWING: {
            const { mainDrawingManager, mainGoogle } = state;
            const isDrawing = !state.isDrawing;

            if (!mainDrawingManager || !mainGoogle) return state;

            if (isDrawing) {
                enableDrawing(mainDrawingManager);
            } else {
                disableDrawing(mainDrawingManager);
            }

            return {
                ...state,
                isDrawing,
            };
        }

        case RRMapActions.EVT_POLYGONCOMPLETE: {
            const { mainGoogle, mainDrawingManager, mainMap, polygonArray } = state;
            const { polygon, getCenterId, getPolygonBounds } = action.payload as IRRMapPolygonEventPayload;

            if (!polygon || !mainDrawingManager || !mainGoogle || !mainMap || !getCenterId || !getPolygonBounds) return state;

            polygon.setOptions({
                clickable: false,
                editable: false,
                draggable: false,
                strokeOpacity: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeOpacity,
                strokeWeight: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeWeight,
                fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
                fillColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.fillColor.selected,
                strokeColor: DEFAULTS_PROPS_MAP.polygonOnDraw.styles.strokeColor.selected,
            });

            polygon.setValues({
                id: 1,
                area: mainGoogle.maps.geometry.spherical.computeArea(polygon.getPath()),
            });

            disableMap(mainDrawingManager, mainMap);

            panToPolygon(polygon, mainMap, getCenterId, getPolygonBounds);

            polygonArray?.push(polygon);

            return {
                ...state,
                isDrawing: false,
                polygon,
            };
        }

        case RRMapActions.EVT_INSERT_AT:
        case RRMapActions.EVT_REMOVE_AT:
        case RRMapActions.EVT_SET_AT: {
            const { mainGoogle } = state;
            const { polygon } = action.payload as IRRMapPolygonEventPayload;

            if (!polygon || !mainGoogle) return state;

            // polygon.setOptions({
            //     area: mainGoogle.maps.geometry.spherical.computeLength(polygon.getPath()),
            // });

            polygon.setValues({
                area: mainGoogle.maps.geometry.spherical.computeArea(polygon.getPath()),
            });

            return {
                ...state,
                polygon,
            };
        }

        case RRMapActions.DRAW_AGAIN: {
            const { mainMap, mainGoogle, mainDrawingManager, polygon, polygonArray, currentAreaIndex } = state;

            if (!mainGoogle || !mainDrawingManager || !polygon || !polygonArray || !mainMap) return state;

            polygon.setMap(null);
            const newPolygonArray = polygonArray?.splice(currentAreaIndex, 1);

            enableMap(mainDrawingManager, mainMap);
            enableDrawing(mainDrawingManager);

            return {
                ...state,
                isDrawing: true,
                polygon: null,
                polygonArray: newPolygonArray,
            };
        }

        case RRMapActions.RESET: {
            const { mainDrawingManager, mainGoogle, mainMap, polygonArray } = state;

            if (!mainGoogle || !mainMap || !mainDrawingManager) return state;

            polygonArray?.forEach?.((polygon) => polygon.setMap(null));

            enableMap(mainDrawingManager, mainMap);
            disableDrawing(mainDrawingManager);

            mainMap.setCenter(mainMap.get('original_center'));
            mainMap.setZoom(DEFAULTS_PROPS_MAP.mapOptions.zoom);

            return {
                ...state,
                currentAreaIndex: 0,
                isDrawing: false,
                polygon: null,
                polygonArray: [],
            };
        }

        default:
            return state;
    }
};

export default rrMapReducer;
