import { memo, useContext, useState } from 'react';
import IntlMessages from 'components/util/IntlMessages';
import { intlMessages, isDefined } from 'services/util/auxiliaryUtils';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { ReactComponent as ExportIcon } from 'assets/images/products/spv-simple/export.svg';
import { Helmet } from 'react-helmet';
import { getHeadTitle } from 'services/settings';
import DialogBank from '../dialogs/DialogBank';
import { Button } from '@save2compete/efz-design-system';

const SuccessfulRequest = () => {
    const {
        handleStartOver,
        spvSimpleState: { proposalDetails },
    } = useContext(SPVSimpleContext);

    const [isOpenDialogBank, setIsOpenDialogBank] = useState(false);

    return (
        <>
            <div className="successful-container">
                <Helmet>
                    <title>{`${getHeadTitle()} · ${intlMessages('page.spvSimple.open.b2c.step.6')}`}</title>
                </Helmet>

                <h2>
                    <IntlMessages id={'thankYou.title'} />
                    <hr />
                </h2>

                <div className="successful-container-card">
                    <div className="successful-container-card-content">
                        <div className="container-check">
                            <div className="container-central-animation">
                                <div className="check-icon check-success animate">
                                    <span className="line tip animateSuccessTip"></span>
                                    <span className="line long animateSuccessLong"></span>
                                    <div className="fix"></div>
                                </div>
                            </div>
                        </div>
                        <h3>
                            <IntlMessages id={'thankYou.label.1'} />
                        </h3>
                        <p>
                            <IntlMessages id={'thankYou.label.2'} />
                        </p>
                    </div>
                </div>
                {isDefined(proposalDetails?.bank_url) && (
                    <div className="successful-container-link">
                        <p>
                            <IntlMessages id="thankYou.financingPossibilities.b2c.1" />
                        </p>
                        <p>
                            <IntlMessages id="thankYou.financingPossibilities.b2c.2" />
                            <a href={proposalDetails.bank_url} target="_blank" rel="noopener noreferrer">
                                <IntlMessages id="label.here" />
                                <ExportIcon className="me-3" />
                            </a>
                        </p>
                        <p>
                            <IntlMessages id="thankYou.financingPossibilities.b2c.3" />
                            <span onClick={() => setIsOpenDialogBank(true)}>
                                <IntlMessages id="label.here" />
                            </span>
                            <IntlMessages id="thankYou.financingPossibilities.b2c.3.5" />
                        </p>
                    </div>
                )}
                <div className="successful-container-restart">
                    <Button variant="secondary" icon="startRoundArrowReset" onClick={handleStartOver} dataTestId="restart-button">
                        <IntlMessages id="label.restart" />
                    </Button>
                </div>
            </div>
            <DialogBank isOpenDialogBank={isOpenDialogBank} setIsOpenDialogBank={setIsOpenDialogBank} />
        </>
    );
};

export default memo(SuccessfulRequest);
