import validate from 'services/util/validate';

export const ANNUAL_CONSUMPTION_LIMITS = {
    //Max Included and Min Not Included
    DEFAULT: { max: 1000000, minNI: 0 },
};

export const NUMBER_DECIMALS_TARIFFS = 6;

/* default objects */
export const INPUT_NAMES_STARIFFS = {
    SUPPLIED_BY_COMPANY: 'supplied_by_company', //(B2B)
    FORNECEDOR_ID: 'fornecedor_id', //(B2B)
    DISTRIBUIDOR_ID: 'distribuidor_id', //(B2B)
    OPCAO_TARIFARIO_ID: 'opcao_tarifario_id', //(B2B)
    OPCAO_HORARIO_ID: 'opcao_horario_id', //(B2B)
    FONTE_TARIFA_REDES_ID: 'fonte_tarifa_redes_id', //(B2B)
    TARIFA_KW_DIA: 'tarifa_kw_dia', //(B2B)
    POWER_FEE: 'power_fee', //(B2B)
    DETAILED_TARIFFS: 'detailed_tariffs', //criar locarmente (B2B & B2C)
    ENERGY_TARIFFS: 'energy_tariffs', //(B2B & B2C)
    TOTAL_TARIFFS: 'total_tariffs', //(B2B & B2C)
};

//#region /** B2B **/
export const QUESTIONS_SUPPLY_TARIFFS_B2B = {
    [INPUT_NAMES_STARIFFS.SUPPLIED_BY_COMPANY]: {
        name: INPUT_NAMES_STARIFFS.SUPPLIED_BY_COMPANY,
        label: 'question.label.suppliedByCompany',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: false,
        options: [
            { value: true, label: 'label.yes' },
            { value: false, label: 'label.no' },
        ],
        type: 'select',
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_STARIFFS.FORNECEDOR_ID]: {
        name: INPUT_NAMES_STARIFFS.FORNECEDOR_ID,
        label: 'question.label.supplier',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: false,
        type: 'select',
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_STARIFFS.DISTRIBUIDOR_ID]: {
        name: INPUT_NAMES_STARIFFS.DISTRIBUIDOR_ID,
        label: 'question.label.distributor',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: false,
        options: null,
        type: 'select',
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_STARIFFS.OPCAO_TARIFARIO_ID]: {
        name: INPUT_NAMES_STARIFFS.OPCAO_TARIFARIO_ID,
        label: 'question.label.tariffOption',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'select',
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_STARIFFS.OPCAO_HORARIO_ID]: {
        name: INPUT_NAMES_STARIFFS.OPCAO_HORARIO_ID,
        label: 'question.label.hourlyOption',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: true,
        visible: false,
        type: 'select',
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_STARIFFS.FONTE_TARIFA_REDES_ID]: {
        name: INPUT_NAMES_STARIFFS.FONTE_TARIFA_REDES_ID,
        label: 'question.label.gridTarifSources',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: false,
        type: 'select',
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_STARIFFS.TARIFA_KW_DIA]: {
        name: INPUT_NAMES_STARIFFS.TARIFA_KW_DIA,
        label: 'question.label.usedPowerTariff',
        placeholder: 'question.placeholder.usedPowerTariff',
        tooltip: 'question.tooltip.usedPowerTariff',
        disabled: true,
        visible: false,
        type: 'input',
        inputType: 'number',
        step: null,
        validation: null,
        isRequired: false,
        unit: 'ckwd',
    },
    [INPUT_NAMES_STARIFFS.POWER_FEE]: {
        name: INPUT_NAMES_STARIFFS.POWER_FEE,
        label: 'question.label.powerFee',
        placeholder: 'question.placeholder.powerFee',
        tooltip: 'question.tooltip.powerFee',
        disabled: true,
        visible: false,
        type: 'input',
        step: null,
        validation: null,
        isRequired: true,
        unit: 'cmwh',
    },
    [INPUT_NAMES_STARIFFS.DETAILED_TARIFFS]: {
        name: INPUT_NAMES_STARIFFS.DETAILED_TARIFFS,
        label: null,
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        inputType: 'input',
        step: 0.01,
        unit: 'cmwh',
        validation: null,
        isRequired: true,
    },
};
//#endregion /** B2B **/

//#region /** B2C **/
export const QUESTIONS_SUPPLY_TARIFFS_B2C = {
    [INPUT_NAMES_STARIFFS.DISTRIBUIDOR_ID]: {
        name: INPUT_NAMES_STARIFFS.DISTRIBUIDOR_ID,
        label: 'question.label.distributor',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: false,
        options: null,
        type: 'select',
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_STARIFFS.OPCAO_TARIFARIO_ID]: {
        name: INPUT_NAMES_STARIFFS.OPCAO_TARIFARIO_ID,
        label: 'question.label.tariffOption',
        placeholder: 'label.selectOption',
        tooltip: null,
        disabled: false,
        visible: false,
        options: null,
        type: 'select',
        validation: validate(['required']),
        isRequired: true,
    },
    [INPUT_NAMES_STARIFFS.DETAILED_TARIFFS]: {
        name: INPUT_NAMES_STARIFFS.DETAILED_TARIFFS,
        label: null,
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: true,
        options: null,
        type: 'input',
        inputType: 'input',
        step: 0.01,
        validation: null,
        unit: 'ckwh',
    },
};

//#endregion /** B2C **/
