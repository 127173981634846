import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { TGrants } from './upfront';
import { Nullable } from 'types/utils';
export type TServiceV2 = typeof PAYMENT_MODELS_IDS.SERVICE_SECOND_VERSION;

export type TServiceV2Values = {
    kpis_per_margin: TMarginServiceV2[];
    marginSelected?: number;
    offerEdition: TServiceV2OfferEdition;
    proposalReady: boolean;
    grants: {
        totalGrants: {
            prc: number;
            currency: number;
        };
        grants: TGrants[];
        subsidy: Nullable<boolean>;
        subsidy_prc: number;
        battery_subsidy: Nullable<boolean>;
        battery_subsidy_prc: number;
    };
    totalCost: number;
    clientContactInfo?: {
        name: string;
        email: string;
    };
    id_crm?: string;
};

export type TMarginServiceV2 = {
    annual_saving: number;
    additional_value: number;
    annual_net_saving: number;
    annual_price: number;
    is_default: boolean;
    monthly_fee: number;
    monthly_net_saving: number;
    prc_of_monthly_fee_increase: number;
    saving_share: number;
};

export type TServiceV2OfferEdition = {
    extra_cost?: number;
};

export enum ServiceV2Events {
    FETCH_SERVICEV2_BM = 'FETCH_SERVICEV2_BM',
    SET_SERVICEV2_BM = 'SET_SERVICEV2_BM',
    SET_SERVICEV2_MARGIN = 'SET_SERVICEV2_MARGIN',
    UPDATE_SERVICEV2_VALUES_BM = 'UPDATE_SERVICEV2_VALUES_BM',
    SET_SERVICEV2_PROPOSAL_READY = 'SET_SERVICEV2_PROPOSAL_READY',
    SET_SERVICEV2_PROPOSAL = 'SET_SERVICEV2_PROPOSAL',
    ADD_SERVICEV2_GRANT = 'ADD_SERVICEV2_GRANT',
    DELETE_SERVICEV2_GRANT = 'DELETE_SERVICEV2_GRANT',
    SET_DESCRIPTION_GRANT = 'SET_DESCRIPTION_GRANT',
    SET_AMMOUNT_GRANT = 'SET_AMMOUNT_GRANT',
    UPDATE_SERVICEV2_GRANTS = 'UPDATE_SERVICEV2_GRANTS',
    UPDATE_SERVICEV2_ADDITIONAL_DATA = 'UPDATE_SERVICEV2_ADDITIONAL_DATA',
}

export type TServiceV2EventsValues<Event> =
    Event extends typeof ServiceV2Events.SET_SERVICEV2_BM ? { kpis_per_margin: TMarginServiceV2[]; proposalReady: true }
    : Event extends typeof ServiceV2Events.SET_SERVICEV2_MARGIN ? number
    : Event extends typeof ServiceV2Events.UPDATE_SERVICEV2_VALUES_BM ?
        {
            offerEdition: {
                extra_cost: number;
            };
        }
    : Event extends typeof ServiceV2Events.SET_SERVICEV2_PROPOSAL_READY ? boolean
    : Event extends typeof ServiceV2Events.SET_SERVICEV2_PROPOSAL ? null
    : any;
