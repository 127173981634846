/**
 * @author Daniel Bastos <daniel.bastos@caixamagica.pt>,
 *
 * @description API requests to the context of UserManagement
 *
 * @version 20200318
 * @since 20200318 Initial release
 *
 */

// import axios from 'axios';
import axios, { axiosSSOInstance } from 'services/@efz/axios';
import {
    URL_USERS,
    URL_CLIENT,
    getEndpoint,
    URL_USERS_INFO,
    URL_USERS_HISTORY,
    URL_EDIT_RISKS,
    URL_EDIT_TNW,
    URL_AUTH_MODE,
} from 'constants/endpoints';
import { getMessageNetworkError } from 'services/statusCodeHttp';
import { MAX_HISTORY_LIST_CLIENT } from 'constants/client';
import { TGetUserAuthMode } from 'interfaces/user';

const CancelToken = axios.CancelToken;
// Cancel executor variables
let getAllUsersCancelExecutor;
let getUserInfoCancelExecutor;
let putUserInfoCancelExecutor;
let putResetPwdCancelExecutor;

/**
 * Get All Users
 *
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getAllUsers = (params) => {
    getAllUsersCancelExecutor && getAllUsersCancelExecutor();

    return axios
        .get(getEndpoint(URL_USERS), {
            cancelToken: new CancelToken(function executor(c) {
                getAllUsersCancelExecutor = c; // An executor function receives a cancel function as a parameter
            }),
            params: params,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * Put Update User
 *
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const putUpdateUser = (payload) => {
    return axios
        .put(getEndpoint(URL_USERS) + '/' + payload.userID, payload.body)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * Get User Credentials Email
 *
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getUserCredentialsEmail = (payload) => {
    return axios
        .get(getEndpoint(URL_USERS) + '/' + payload.userID + '/credentials-email')
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * Post Create User
 *
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const postCreateUser = (payload) => {
    return axios
        .post(getEndpoint(URL_USERS), payload)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * getClients
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getSearchClients = (params) => {
    return axios
        .get(URL_CLIENT, {
            params: params,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * getUserHistoryClients
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getUserHistoryClients = () => {
    return axios
        .get(getEndpoint(URL_USERS_HISTORY) + `?limit=${MAX_HISTORY_LIST_CLIENT}`)
        .then((response) => response.data.data)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * getUser
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getUser = () => {
    getUserInfoCancelExecutor && getUserInfoCancelExecutor();
    return axios
        .get(getEndpoint(URL_USERS_INFO), {
            cancelToken: new CancelToken(function executor(c) {
                getUserInfoCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const putUserHistoryClients = (payload) => {
    return axios
        .put(getEndpoint(URL_USERS_HISTORY) + '/push', payload)
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 *
 * putUserInfo
 *
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const putUserInfo = (payload) => {
    putUserInfoCancelExecutor && putUserInfoCancelExecutor();

    return axios
        .put(getEndpoint(URL_USERS_INFO), payload, {
            cancelToken: new CancelToken(function executor(c) {
                putUserInfoCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * Put Redefine Password
 */
export const putRedefinePassword = (userID, body) => {
    putResetPwdCancelExecutor && putResetPwdCancelExecutor();
    return axios
        .put(getEndpoint(URL_USERS) + '/' + userID, body, {
            cancelToken: new CancelToken(function executor(c) {
                putResetPwdCancelExecutor = c;
            }),
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get edit Risks
export const getEditRisks = () => {
    return axios
        .get(getEndpoint(URL_EDIT_RISKS))
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

//Get TNW
export const getEditTnw = () => {
    return axios
        .get(getEndpoint(URL_EDIT_TNW))
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getADFSUser = async (user, AbortController) => {
    try {
        const requestCfg = {
            url: `${getEndpoint(URL_USERS)}/${URL_AUTH_MODE}/${user}`,
            method: 'GET',
            signal: AbortController?.signal,
        };
        const response = await axiosSSOInstance.request(requestCfg);
        return response.data as TGetUserAuthMode;
    } catch (error) {
        return Promise.reject(error);
    }
};
