import { useContext } from 'react';

import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import MarginInstallmentsDetailed from './Margin';
import MarginKpisInstallmentsDetailed from './MarginKpis';
import DetailedKpisInstallmentsDetailed from './DetailedKpis';

import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';

const NegotiationInstallmentsDetailed = () => {
    const { lowNegotiationProfile, negotiationProfile } = useContext(OfferEditionContext);
    return (
        <div className="bm-detailed-negotiation">
            <ProjectSummaryTitle label="page.businessModels.negotiation.title" />
            <MarginInstallmentsDetailed />
            {negotiationProfile?.mostra_negociacao_default_kpis && <MarginKpisInstallmentsDetailed />}
            {!lowNegotiationProfile && <DetailedKpisInstallmentsDetailed />}
        </div>
    );
};

export default NegotiationInstallmentsDetailed;
