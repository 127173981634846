import IntlMessages from 'components/util/IntlMessages';
import { useContext, useMemo } from 'react';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { PRODUCT_IDS } from 'constants/products';
import { UserContext } from 'contexts/userContext';
import { GRANTS_INFO_URL } from 'constants/businessModelsPro/grants';
import { IconButton } from '@save2compete/efz-design-system';

const GrantsTitle = ({
    label,
    isOpen,
    collapseHandler,
}: {
    label: string;
    isOpen: boolean;
    collapseHandler: () => void;
    openDialog: () => void;
}) => {
    const { productID } = useContext(BusinessModelsProContext);
    const { companyProfileId } = useContext(UserContext);
    const grantInfoUrl = useMemo(() => GRANTS_INFO_URL?.[companyProfileId]?.[productID] ?? '', [companyProfileId, productID]);

    return (
        <div className={`bm-grants-title ${!isOpen ? 'collapsed' : ''}`}>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <span>
                        <IntlMessages id={label} />
                    </span>
                    <hr />
                </div>
                <div>
                    {[PRODUCT_IDS.HP].includes(productID) && (
                        <a className="bm-grants-customAction" href={grantInfoUrl} target="_blank" rel="noopener noreferrer">
                            <IntlMessages id="page.bm.grants.hp.dialog.title" />
                        </a>
                    )}
                    <IconButton
                        variant="tertiary"
                        icon={isOpen ? 'arrowHeadUp' : 'arrowHeadDown'}
                        size="bg"
                        onClick={collapseHandler}
                        dataTestId="bm-grants-icon"
                    />
                </div>
            </div>
        </div>
    );
};

export default GrantsTitle;
