//Libraries
import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

// Material UI
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';

// Utils
import IntlMessages from 'components/util/IntlMessages';
import { EMAIL_REGEXP, intlMessages } from 'services/util/auxiliaryUtils';
import ErrorMessage from 'components/errors/ErrorMessage';
import { Button } from '@save2compete/efz-design-system';
import { IconButton } from '@save2compete/efz-design-system';

const SendEmailDialog = ({ isOpen, onCloseHandler, isRequesting, onSubmitHandler }) => {
    const {
        handleSubmit,
        control,

        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        onSubmitHandler(data);
    };

    const handleClose = useCallback(
        (_, reason) => {
            if (['backdropClick', 'escapeKeyDown'].includes(reason)) return false;
            if (typeof onCloseHandler === 'function') onCloseHandler();
        },
        [onCloseHandler]
    );

    return (
        <Dialog open={isOpen} onClose={handleClose} className="send-email-dialog">
            <div className="d-flex">
                <DialogTitle className="flex-fill">
                    <IntlMessages id="page.proposal.button.SendARLinkToEmail" />
                </DialogTitle>
                <IconButton
                    variant="tertiary"
                    icon="xMarkExit"
                    size="bg"
                    className="close-btn"
                    onClick={onCloseHandler}
                    dataTestId="close-dialog"
                />
            </div>

            <DialogContent className="email-dialog-content">
                <div className="inputs-container">
                    <div className="input-element">
                        <Controller
                            name={'email'}
                            control={control}
                            rules={{
                                required: 'yup.message.required',
                                pattern: {
                                    value: EMAIL_REGEXP,
                                    message: 'yup.message.email',
                                },
                            }}
                            disabled={isRequesting}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            render={({ field: { ref, ...field } }) => (
                                <>
                                    <OutlinedInput
                                        {...field}
                                        disabled={isRequesting}
                                        placeholder={intlMessages('placeholder.email')}
                                        type={'text'}
                                    />
                                </>
                            )}
                        />
                        {errors?.['email'] && <ErrorMessage error={errors['email']} />}
                    </div>
                </div>
            </DialogContent>
            <div className="actions-container">
                <Button variant="tertiary" onClick={onCloseHandler} disabled={isRequesting}>
                    <IntlMessages id="label.cancel" />
                </Button>
                <Button onClick={handleSubmit(onSubmit)} disabled={isRequesting} loading={isRequesting}>
                    <IntlMessages id="page.proposal.button.SendLink" />
                </Button>
            </div>
            {/* </form> */}
        </Dialog>
    );
};

//PropTypes
SendEmailDialog.propTypes = {
    onCloseHandler: PropTypes.func.isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
};
export default memo(SendEmailDialog);
