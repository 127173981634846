import IntlMessages from 'components/util/IntlMessages';
import { SIZING_TYPE_IDS } from 'constants/businessModels';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { useContext, useState } from 'react';
import MarginGoalSeek from './MarginGoalSeek';
import MarginNoGoalSeek from './MarginNoGoalSeek';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import Tooltip from 'components/@efz/Tooltip';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Alert from 'components/@efz/Alert';
import { TIntl } from 'types/utils';

// Icons
import { ReactComponent as RefreshIcon } from 'assets/images/products/spv-pro/svg/refresh.svg';
import { ReactComponent as EditIcon } from 'assets/images/products/spv-pro/svg/pencil-edit.svg';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { BmLoadings } from 'constants/businessModelsPro/index';
import AddOnHelperDialog from 'components/BusinessModels/ProjectSummary/common/AddOnHelperDialog';

const MarginLECDetailed = () => {
    const {
        bmState: {
            loadings: { addOnHelper: loadingAddOnHelper, general: loadingGeneral },
            businessModelSelected,
            kpis,
            addOnHelper: { info },
        },
        setBMEventHandler,
    } = useContext(OfferEditionContext);
    const [tariffHelperOpenDialog, setTariffHelperOpenDialog] = useState(false);

    const marginToDisplay = () => {
        switch (businessModelSelected?.sizing_type_id) {
            case SIZING_TYPE_IDS.DETAILED_FULL:
            case SIZING_TYPE_IDS.DETAILED_RISK_FREE:
                return <MarginGoalSeek />;
            case SIZING_TYPE_IDS.DETAILED_GOAL_SEEK_FREE:
                return <MarginNoGoalSeek />;
            default:
                return <div>ERROR</div>;
        }
    };

    return (
        <div className="bm-detailed-negotiation-margin new">
            {/* add_on_values */}
            {isFieldDefined(kpis?.negotiation?.add_on_values) && (
                <>
                    <label className="text-bold margin-slider-title">
                        <IntlMessages id={`page.businessModels.selectInstallments.tariff`} />
                        <Tooltip title={<IntlMessages id="label.edit" />} type="info">
                            <IconButton disableRipple onClick={() => setTariffHelperOpenDialog(true)}>
                                <EditIcon height={18} width={18} className={`pencil-edit`} />
                            </IconButton>
                        </Tooltip>
                        {isFieldDefined(kpis?.negotiation?.add_on_helper_pre_selected) && (
                            <Tooltip
                                title={
                                    // @ts-ignore
                                    <IntlMessages id="label.resetValue" />
                                }
                                type="info"
                            >
                                <IconButton
                                    disableRipple
                                    disabled={loadingAddOnHelper || loadingGeneral}
                                    onClick={(e) => {
                                        setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_LOADINGS, [
                                            { name: BmLoadings.AddOnHelper, value: true },
                                        ]);
                                        setTimeout(() => {
                                            setBMEventHandler(DetailedOfferEditionEvents.RESET_BUSINESS_MODEL_KPIS_ADD_ON_HELPER, null);
                                        }, 200);
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    <RefreshIcon height={24} width={24} className={`injection-kpi-button-refresh`} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </label>
                    {tariffHelperOpenDialog && (
                        <AddOnHelperDialog
                            isOpen={isFieldDefined(kpis?.negotiation?.add_on_values)}
                            addOnHelperData={kpis?.negotiation?.add_on_values}
                            onOpendialoHandler={() => setTariffHelperOpenDialog(false)}
                        />
                    )}
                </>
            )}
            {loadingAddOnHelper ?
                <Skeleton variant="rounded" height={100} sx={{ width: '100%', borderRadius: '4px', marginBottom: '10px' }} />
            :   marginToDisplay()}
            {info?.text && (
                <Alert severity={'info'} className="mt-3">
                    <IntlMessages
                        id={info?.text as TIntl}
                        values={{
                            min_monthly_fee: <strong>{info?.min_monthly_fee}</strong>,
                            max_monthly_fee: <strong>{info?.max_monthly_fee}</strong>,
                            new_min_monthly_fee: <strong>{info?.new_min_monthly_fee}</strong>,
                            new_max_monthly_fee: <strong>{info?.new_max_monthly_fee}</strong>,
                        }}
                    />
                </Alert>
            )}
        </div>
    );
};

export default MarginLECDetailed;
