import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import IntlMessages from 'components/util/IntlMessages';

import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import validate from 'services/util/validate';
import { isDefined } from 'services/util/auxiliaryUtils';

const FinanceCustomizationInstallmentsDetailed = ({ showOfferSummary }) => {
    const {
        bmState,
        bmState: { loadings, elements },
    } = useContext(OfferEditionContext);

    const {
        control,
        formState: { errors },
        setValue,
        trigger,
        watch,
    } = useFormContext();

    const financing_customization = bmState?.businessModelSelected?.financing_customization ?? null;
    const monthlyPaymentsOptions = elements?.monthlyPayments?.options?.options;
    const omContractDurationOpts = elements?.omContractDuration?.options?.options ?? null;
    const canEditPaymentMonthly = financing_customization?.edit_monthly_payments_number ?? true;
    const canEditOpAndMan = financing_customization?.edit_op_and_man ?? false;

    const watchContractDuration = watch('monthly_payment_option_id');

    let omContractDurationData = null;
    // @ts-ignore
    omContractDurationData =
        omContractDurationOpts?.find((om) => parseInt(om.contract_duration) === parseInt(watchContractDuration)) ?? null;

    /* When user selects a monthlyPayment lower than the opAndManDuration selected, sets the opAndManDuration to the same value */
    const opAndManDurationOnHandler = (monthlyPayment) => {
        // @ts-ignore
        setValue(
            'opAndManDuration',
            omContractDurationOpts?.find((om) => parseInt(om.contract_duration) === parseInt(monthlyPayment))?.om_durations_default ?? null
        );

        trigger('opAndManDuration');
    };

    return (
        <>
            <section className={`${showOfferSummary ? 'bm-detailed-offer-summary-finance' : ''} w-100`}>
                {/* show_monthly_payments_number */}
                {isDefined(elements.monthlyPayments) ?
                    <div className="input-container">
                        <label>
                            <IntlMessages id={'page.businessModels.editOffer.numberOfInstallments'} />
                        </label>
                        <Controller
                            name="monthly_payment_option_id"
                            control={control}
                            rules={validate(['required', 'number', 'positive'])}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            render={({ field: { ref, ...field } }) => (
                                <Select
                                    {...field}
                                    inputProps={{ className: 'd-flex align-items-center' }}
                                    disabled={loadings.general || !canEditPaymentMonthly}
                                    error={!!errors['monthly_payment_option_id']}
                                    onChange={(event) => {
                                        field.onChange(event);
                                        opAndManDurationOnHandler(Number(event.target.value));
                                    }}
                                >
                                    <MenuItem value="" disabled={true}>
                                        <IntlMessages id={'label.selectOption'} />
                                    </MenuItem>
                                    {monthlyPaymentsOptions?.map((month, index) => {
                                        return (
                                            <MenuItem key={`monthly_payment_options_${index}`} value={month}>
                                                <IntlMessages
                                                    id={`label.n${elements?.monthlyPayments?.options?.unity === 'years' ? 'Years' : 'Months'}`}
                                                    values={{
                                                        number: month / (elements?.monthlyPayments?.options?.unity === 'years' ? 12 : 1),
                                                    }}
                                                />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        />
                    </div>
                :   <></>}

                {/* show_op_and_man */}
                {financing_customization?.show_op_and_man ?
                    <div className="input-container">
                        <label>
                            <IntlMessages id={'page.businessModels.editOffer.o&m'} />
                        </label>
                        <Controller
                            name="opAndManDuration"
                            control={control}
                            rules={validate(['required', 'number', 'positive'])}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            render={({ field: { ref, ...field } }) => (
                                <Select
                                    {...field}
                                    inputProps={{ className: 'd-flex align-items-center' }}
                                    disabled={loadings.general || !canEditOpAndMan}
                                    error={!!errors['opAndManDuration']}
                                    onChange={(event) => {
                                        field.onChange(event);
                                        trigger('opAndManDuration');
                                    }}
                                >
                                    <MenuItem value={-1} disabled={true}>
                                        <IntlMessages id={'label.selectOption'} />
                                    </MenuItem>
                                    {/* @ts-ignore */}
                                    {omContractDurationData?.om_durations?.map((month, index) => {
                                        return (
                                            <MenuItem key={`monthly_payment_options_${index}`} value={month}>
                                                <IntlMessages
                                                    id={`label.n${elements.omContractDuration?.options?.unity === 'years' ? 'Years' : 'Months'}`}
                                                    values={{
                                                        number: month / (elements?.omContractDuration?.options?.unity === 'years' ? 12 : 1),
                                                    }}
                                                />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        />
                    </div>
                :   <></>}
            </section>
        </>
    );
};

export default FinanceCustomizationInstallmentsDetailed;
