import Moment from 'moment';
import PropTypes from 'prop-types';

const TimestampToDate = ({ timestamp, format }) => Moment(parseInt(timestamp)).format(format ? format : 'DD-MM-YY H:mm');

TimestampToDate.propTypes = {
    timestamp: PropTypes.string.isRequired,
    format: PropTypes.string,
};

export default TimestampToDate;
