import { defineMessages } from 'react-intl';
import ptMessages from '../locales/pt_BR.json';

const ptBRLang = {
    messages: {
        ...ptMessages,
    },
    locale: 'pt-BR',
    data: defineMessages,
};
export default ptBRLang;
