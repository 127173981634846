/**
 * @author Daniel Bastos <daniel.bastos@caixamagica.pt>,
 *
 * @description EVC Actions
 *
 * @version 20201113
 * @since 20201113
 *
 */

import {
    REQUEST_POST_EVC_PRE_SIZING,
    REQUEST_POST_EVC_PRE_SIZING_SUCCESS,
    REQUEST_POST_EVC_PRE_SIZING_FAIL,
    REQUEST_POST_EVC_SIZING_FINANCE,
    REQUEST_POST_EVC_SIZING_FINANCE_SUCCESS,
    REQUEST_POST_EVC_SIZING_FINANCE_FAIL,
    RESET_EVC_CHARGERS_INFO,
} from 'constants/products/evc';

/* STEP 1 */
export function requestPostMEPreSizing(payload) {
    return {
        type: REQUEST_POST_EVC_PRE_SIZING,
        payload,
    };
}

export function requestPostMEPreSizingSuccess(payload) {
    return {
        type: REQUEST_POST_EVC_PRE_SIZING_SUCCESS,
        payload,
    };
}

export function requestPostMEPreSizingFail(payload) {
    return {
        type: REQUEST_POST_EVC_PRE_SIZING_FAIL,
        payload,
    };
}

/* STEP 2 */
export function requestPostMESizingFinance(payload) {
    return {
        type: REQUEST_POST_EVC_SIZING_FINANCE,
        payload,
    };
}

export function requestPostMESizingFinanceSuccess(payload) {
    return {
        type: REQUEST_POST_EVC_SIZING_FINANCE_SUCCESS,
        payload,
    };
}

export function requestPostMESizingFinanceFail(payload) {
    return {
        type: REQUEST_POST_EVC_SIZING_FINANCE_FAIL,
        payload,
    };
}

// * RESET CHARGERS INFO * \\
export function resetMEChargersInfo() {
    return {
        type: RESET_EVC_CHARGERS_INFO,
    };
}
