import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player';
import { bool, func, node, number, object, oneOf, oneOfType, string } from 'prop-types';

// Services
import { isFieldDefined } from 'services/util/auxiliaryUtils';

const Lottie = ({
    width,
    height,
    playerRef,
    children,
    onLoad,
    onError,
    onReady,
    onPlay,
    onPause,
    onStop,
    onFreeze,
    onLoop,
    onComplete,
    onFrame,
    style,
    ...rest
}) => {
    const eventHandler = (evt) => {
        switch (evt) {
            case PlayerEvent.Load:
                if (isFieldDefined(onLoad)) onLoad();
                break;
            case PlayerEvent.Error:
                if (isFieldDefined(onError)) onError();
                break;
            case PlayerEvent.Ready:
                if (isFieldDefined(onReady)) onReady();
                break;
            case PlayerEvent.Play:
                if (isFieldDefined(onPlay)) onPlay();
                break;
            case PlayerEvent.Pause:
                if (isFieldDefined(onPause)) onPause();
                break;
            case PlayerEvent.Stop:
                if (isFieldDefined(onStop)) onStop();
                break;
            case PlayerEvent.Freeze:
                if (isFieldDefined(onFreeze)) onFreeze();
                break;
            case PlayerEvent.Loop:
                if (isFieldDefined(onLoop)) onLoop();
                break;
            case PlayerEvent.Complete:
                if (isFieldDefined(onComplete)) onComplete();
                break;
            case PlayerEvent.Frame:
                if (isFieldDefined(onFrame)) onFrame();
                break;
            default:
                break;
        }
    };

    return (
        <Player
            ref={playerRef}
            onEvent={(evt) => eventHandler(evt)}
            style={{
                ...style,
                width: isFieldDefined(width) ? width : '100%',
                height: isFieldDefined(height) ? height : '100%',
            }}
            {...rest}
        >
            {children}
        </Player>
    );
};

Lottie.propTypes = {
    width: oneOfType([number, string]),
    height: oneOfType([number, string]),
    //#region library props
    id: string,
    lottieRef: func,
    playerRef: func,
    //#region events
    onLoad: func,
    onError: func,
    onReady: func,
    onPlay: func,
    onPause: func,
    onStop: func,
    onFreeze: func,
    onLoop: func,
    onComplete: func,
    onFrame: func,
    onStateChange: func,
    onBackgroundChange: func,
    //#endregion events
    autoplay: bool,
    background: string,
    children: node,
    className: string,
    controls: bool,
    direction: oneOf([-1, 1]),
    hover: bool,
    keepLastFrame: bool,
    loop: oneOfType([bool, number]),
    renderer: oneOf(['svg', 'canvas']),
    rendererSettings: object,
    speed: number,
    src: oneOfType([object, string]).isRequired,
    style: object,
    //#endregion library props
};

Lottie.defaultProps = {
    autoplay: false,
    controls: false,
    direction: 1,
    hover: false,
    keepLastFrame: false,
    loop: false,
    renderer: 'svg',
    speed: 1,
};

export default Lottie;
