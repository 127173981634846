import clone from 'fast-copy';
import { INPUT_NAMES_GDATA as INPUT_NAMES, QUESTIONS_GDATA_B2B, QUESTIONS_GDATA_B2C } from 'constants/facilityPro/gasData';
import { getParseNumber, isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';
import { GRUPO_CANAL_IDS } from 'constants/user';
import { channelGroupRules, getCompanyProfileIds } from 'services/user';

//#region /** B2B **/

/* Methods Private */
function applyChannelGroupRulesB2B(questions, userChannelIDGroup) {
    if (channelGroupRules(userChannelIDGroup)?.isChannelGroup) {
        switch (userChannelIDGroup) {
            case GRUPO_CANAL_IDS.ESE:
                // eslint-disable-next-line no-prototype-builtins
                if (questions[INPUT_NAMES.GAS_SUPPLIED_BY_COMPANY]?.hasOwnProperty('visible')) {
                    questions[INPUT_NAMES.GAS_SUPPLIED_BY_COMPANY].visible = false; //GAS
                    questions[INPUT_NAMES.CONSUMO_ANUAL_GAS_MWH].visible = false; //GAS
                    questions[INPUT_NAMES.TARIFA_FIXA_GAS_MWH].visible = false; //GAS
                    questions[INPUT_NAMES.TARIFA_MEDIA_GAS_MWH].visible = false; //GAS
                }
                break;
            default:
                break;
        }
    }
    return questions;
}

/* Methods Public */

export function getGasDataB2B(inputs) {
    //#region conversionTariffGasMeanMWh
    //consumo_anual_gas_mwh
    if (!isDefined(inputs.consumo_anual_gas_mwh)) inputs.consumo_anual_gas_mwh = getParseNumber(inputs.consumo_anual_gas / 1000);
    inputs.consumo_anual_gas_mwh = getParseNumber(inputs.consumo_anual_gas_mwh); //parseNumber
    inputs.consumo_anual_gas = inputs.consumo_anual_gas_mwh * 1000; //update field

    //tarifa_media_gas_mwh
    if (!isDefined(inputs.tarifa_media_gas_mwh)) inputs.tarifa_media_gas_mwh = getParseNumber(inputs.tarifa_media_gas * 1000);
    inputs.tarifa_media_gas_mwh = getParseNumber(inputs.tarifa_media_gas_mwh); //parseNumber
    inputs.tarifa_media_gas = inputs.tarifa_media_gas_mwh / 1000; //update field

    //tarifa_fixa_gas_mwh
    if (!isDefined(inputs.tarifa_fixa_gas_mwh)) inputs.tarifa_fixa_gas_mwh = getParseNumber(inputs.tarifa_fixa_gas / 1000);
    inputs.tarifa_fixa_gas_mwh = getParseNumber(inputs.tarifa_fixa_gas_mwh); //parseNumber
    inputs.tarifa_fixa_gas = inputs.tarifa_fixa_gas_mwh * 1000; //update field
    //#endregion conversionTariffGasMeanMWh

    return clone({
        facilityId: inputs?.[INPUT_NAMES.ID] ?? null,
        [INPUT_NAMES.FORNECEDOR_GAS_ID]: inputs?.[INPUT_NAMES.FORNECEDOR_GAS_ID],
        [INPUT_NAMES.GAS_SUPPLIED_BY_COMPANY]: inputs?.[INPUT_NAMES.GAS_SUPPLIED_BY_COMPANY],
        [INPUT_NAMES.TARIFA_MEDIA_GAS_MWH]: inputs?.[INPUT_NAMES.TARIFA_MEDIA_GAS_MWH] ?? '',
        [INPUT_NAMES.TARIFA_FIXA_GAS_MWH]: inputs?.[INPUT_NAMES.TARIFA_FIXA_GAS_MWH] ?? '',
        [INPUT_NAMES.CONSUMO_ANUAL_GAS_MWH]: inputs?.[INPUT_NAMES.CONSUMO_ANUAL_GAS_MWH] ?? '',
    });
}

export function getQuestionsGasDataB2B(inputs, options) {
    let questions = clone(QUESTIONS_GDATA_B2B);

    const { userChannelIDGroup, companyProfileId } = options;

    let hasSupplierGas = isFieldDefined(inputs?.[INPUT_NAMES.FORNECEDOR_GAS_ID]);

    questions[INPUT_NAMES.CONSUMO_ANUAL_GAS_MWH].visible = hasSupplierGas;
    questions[INPUT_NAMES.TARIFA_FIXA_GAS_MWH].visible = hasSupplierGas;
    questions[INPUT_NAMES.TARIFA_MEDIA_GAS_MWH].visible = hasSupplierGas;

    questions = applyChannelGroupRulesB2B(questions, userChannelIDGroup);

    return { questions, isVisible: hasSupplierGas && isGasVisible(companyProfileId) };
}

export function isGasVisible(companyProfileId) {
    switch (companyProfileId) {
        case getCompanyProfileIds().EDP_ES:
        case getCompanyProfileIds().EDP_PT:
        case getCompanyProfileIds().EFZ:
            return true;
        default:
            return false;
    }
}

//#endregion /** B2B **/

//#region /** B2C **/
/* Methods Private */

/* Methods Public */
export function getGasDataB2C(inputs) {
    return clone({
        facilityId: inputs?.[INPUT_NAMES.ID] ?? null,
    });
}

export function getQuestionsGasDataB2C() {
    let questions = clone(QUESTIONS_GDATA_B2C);
    return { questions, isVisible: false };
}

//#endregion /** B2C **/
