import { useContext } from 'react';

// Contexts
import { SidebarContext } from 'contexts/sidebarContext';
import BackofficeSidebarButton from './SidebarButton';
import { UserContext } from 'contexts/userContext';

// Components

const BackofficeSidebar = () => {
    const { backofficeSidebarConfig, secondarySidebarSectionSelected } = useContext(SidebarContext);
    const { configBackoffice } = useContext(UserContext);

    return (
        <div className="backoffice-sidebar">
            {backofficeSidebarConfig
                ?.filter((product) => product.visible(configBackoffice?.[product.title]?.show_backoffice ?? false))
                ?.map((button, index) => {
                    return (
                        <BackofficeSidebarButton
                            key={`${button.label}_${index}`}
                            icon={button.icon}
                            title={button.label}
                            selected={secondarySidebarSectionSelected === button.name}
                            disabled={button.disabled()}
                            buttonConfigs={button}
                        />
                    );
                })}
        </div>
    );
};

export default BackofficeSidebar;
