import axios, { axiosOpenAreaInstance } from 'services/@efz/axios';
import { getEndpoint, URL_THEME } from 'constants/endpoints';
import { getMessageNetworkError } from 'services/statusCodeHttp';

/**
 * getTheme
 * \
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getTheme = (abortController, isOArea = false) => {
    let requestCfg = {
        url: `${getEndpoint(URL_THEME)}`,
        method: 'GET',
        signal: abortController?.signal,
    };

    if (isOArea) {
        return axiosOpenAreaInstance
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    } else {
        return axios
            .request(requestCfg)
            .then((response) => response)
            .catch((error) => (error.response ? error.response : getMessageNetworkError()));
    }
};
