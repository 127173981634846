import { useContext } from 'react';

// Components
import IntlMessages from 'components/util/IntlMessages';
import { Button } from '@save2compete/efz-design-system';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { PPaEvents, TPPa } from 'interfaces/businessModels/ppa';

// Services
import { isFieldDefined } from 'services/util/auxiliaryUtils';

const ProposalGenerationButtonServicePPa = () => {
    const { setBMEventHandler, bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;

    const conditionToDisable =
        bmSelected.values?.grants?.totalGrants.currency > 0 ?
            bmSelected.values?.grants?.grants.some((grant) => !isFieldDefined(grant.description)) ||
            bmSelected.values?.grants?.totalGrants.prc > 100
        :   false;

    return (
        <Button
            className="bm-proposal-generation-button"
            onClick={() => setBMEventHandler(PPaEvents.SET_PPA_PROPOSAL, null)}
            disabled={conditionToDisable}
            dataTestId="bm-proposal-generation"
        >
            <IntlMessages id="page.bm.proposalGeneral" />
        </Button>
    );
};

export default ProposalGenerationButtonServicePPa;
