import { INITIAL_PROCESSES_FILTERS } from 'constants/processes';
import { createContext, useState } from 'react';

const FiltersContext = createContext();

const FiltersProvider = ({ children }) => {
    const [processesFilters, setProcessesFilters] = useState(INITIAL_PROCESSES_FILTERS);

    // stores the entire object of each <Autocomplete> input
    const [processesFiltersComplete, setProcessesFiltersComplete] = useState({
        cid: {},
        gcid: {},
        uid: {},
        fid: {},
        gsid: {},
        oid: {},
        pid: {},
        gbm_ids: [],
    });

    // Global filter
    const [globalFilter, setGlobalFilter] = useState('');

    // used for <PaymentModelsFilter>
    const [selected, setSelected] = useState([]);

    const [filterOptions, setFilterOptions] = useState({
        cid: [],
        fid: [],
        uid: [],
        gcid: [],
        gsid: [],
        oid: [],
        pid: [],
        tpid: [],
        sid: [],
        ntid: [],
        state: [],
        gbm_ids: [],
        send_status_crm: [
            {
                id: 1,
                descricao: 'page.processes.filters.sendToCrmState.submitted',
                value: 'SUBMITTED',
            },
            {
                id: 2,
                descricao: 'page.processes.filters.sendToCrmState.aborted',
                value: 'ABORTED',
            },
            {
                id: 3,
                descricao: 'page.processes.filters.sendToCrmState.completed',
                value: 'COMPLETED',
            },
            {
                id: 4,
                descricao: 'page.processes.filters.sendToCrmState.null',
                value: 'NULL',
            },
        ],
    });

    const resetProcessesFiltersComplete = () => {
        setProcessesFiltersComplete({
            cid: {},
            gcid: {},
            uid: {},
            fid: {},
            gsid: {},
            oid: {},
            pid: {},
        });
    };

    return (
        <FiltersContext.Provider
            value={{
                processesFilters,
                setProcessesFilters,
                processesFiltersComplete,
                setProcessesFiltersComplete,
                globalFilter,
                setGlobalFilter,
                selected,
                setSelected,
                filterOptions,
                setFilterOptions,
                resetProcessesFiltersComplete,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
};

export { FiltersContext, FiltersProvider };
