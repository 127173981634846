import EfzIcon from 'assets/@efz/logos/customize/efz.svg';
import HelioscopeIcon from 'assets/@efz/logos/customize/helioscope.svg';
import PvSystIcon from 'assets/@efz/logos/customize/pvsyst.png';
import PvSolIcon from 'assets/@efz/logos/customize/pvsol.svg';
import IntlMessages from 'components/util/IntlMessages';
import { intlMessages, parseNumberWithToFixed } from 'services/util/auxiliaryUtils';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import CustomUnit from 'components/util/CustomUnit';
import NumberFormat from 'react-number-format';
import { efzCustomizeMappedUnits } from 'services/products/solarpv';
import { isEnvDevFlag } from 'services/settings';
import { IconButton } from '@save2compete/efz-design-system';

export enum CustomizeActions {
    OPEN_DIALOG = 'OPEN_DIALOG',
    CLOSE_DIALOG = 'CLOSE_DIALOG',
    SELECT_TAB = 'SELECT_TAB',
    SET_PRODUCTION_SOURCES = 'SET_PRODUCTION_SOURCES',
    SELECT_SIZING_OPTION = 'SELECT_SIZING_OPTION',
    SET_DESIGN_LAYOUT = 'SET_DESIGN_LAYOUT',
    SET_PRODUCTION_CURVE = 'SET_PRODUCTION_CURVE',
    SET_PRODUCTION_CURVE_ERROR = 'SET_PRODUCTION_CURVE_ERROR',
    RESET_PRODUCTION_CURVE = 'RESET_PRODUCTION_CURVE',
    SET_SIMULATION = 'SET_SIMULATION',
    SET_EFZ_COSTS = 'SET_EFZ_COSTS',
    SET_EDITED_COSTS_ARRAY = 'SET_EDITED_COSTS_ARRAY',
    SET_CUSTOMIZED_COSTS = 'SET_CUSTOMIZED_COSTS',
    SET_COSTS_TO_USE = 'SET_COSTS_TO_USE',
    SET_HAS_CHANGES = 'SET_HAS_CHANGES',
    SET_RESET = 'SET_RESET',
    SET_IS_SAVED = 'SET_IS_SAVED',
    RESET_INPUTS = 'RESET_INPUTS',
    SET_SIZING_AND_PRODUCTION_VALUES_FROM_BD = 'SET_SIZING_AND_PRODUCTION_VALUES_FROM_BD',
    SET_BUSINESS_MODELS = 'SET_BUSINESS_MODELS',
    CHOOSE_BUSINESS_MODEL_ID = 'CHOOSE_BUSINESS_MODEL_ID',
    SET_FINANCIAL_MODEL_UPLOAD = 'SET_FINANCIAL_MODEL_UPLOAD',
    SET_FINANCIAL_MODEL_UPLOAD_ERROR = 'SET_FINANCIAL_MODEL_UPLOAD_ERROR',
    SET_USE_CUSTOM_FINANCIAL_MODEL = 'SET_USE_CUSTOM_FINANCIAL_MODEL',
    SET_CATEGORIES = 'SET_CATEGORIES',
    SET_SAVE_DIALOG = 'SET_SAVE_DIALOG',
}

export enum CustomizeTabs {
    SIZING_PRODUCTION = 'SIZING_PRODUCTION',
    COSTS = 'COSTS',
    FINANCIAL_MODEL = 'FINANCIAL_MODEL',
}

export const CUSTOMIZE_TABS_CONFIGS = [
    {
        name: CustomizeTabs.SIZING_PRODUCTION,
        label: 'page.spvPro.customize.sizingProduction',
        visible: (value) => value,
        disabled: (value) => value,
    },
    {
        name: CustomizeTabs.COSTS,
        label: 'page.spvPro.customize.costs',
        visible: (value) => value,
        disabled: (value) => value,
    },
    {
        name: CustomizeTabs.FINANCIAL_MODEL,
        label: 'page.spvPro.customize.financialModel',
        visible: (value) => value,
        disabled: (value) => value,
    },
];

export const customizeDialogSections = [
    {
        name: 'sizingProduction',
        visible: true,
        label: 'page.spvPro.customize.sizingProduction',
    },
    {
        name: 'costs',
        visible: true,
        label: 'page.spvPro.customize.costs',
    },
    {
        name: 'financialModel',
        visible: true,
        label: 'page.spvPro.customize.financialModel',
    },
];

export enum CustomizeOptions {
    EFZ = 1,
    MANUALLY = 2,
    HELIOSCOPE = 'HELIOSCOPE',
    PVSYST = 'PVSyst',
    PVSOL = 'PVSol',
    OTHER = 'OTHER',
}

export enum ProductionEndpoints {
    EFZ = 'EFZ',
    HELIOSCOPE = 'HELIOSCOPE',
    PVSYST = 'PVSyst',
    PVSOL = 'PVSol',
    OTHER = 'OTHER',
}

export const customizeOptionsConfig = [
    {
        id: CustomizeOptions.EFZ,
        endpoint: ProductionEndpoints.EFZ,
        name: 'efz',
        logo: EfzIcon,
    },
    {
        id: CustomizeOptions.MANUALLY,
        endpoint: ProductionEndpoints.EFZ,
        name: 'manually',
        label: 'label.manually',
    },
    {
        id: CustomizeOptions.HELIOSCOPE,
        endpoint: ProductionEndpoints.HELIOSCOPE,
        name: 'helioscope',
        logo: HelioscopeIcon,
    },
    {
        id: CustomizeOptions.PVSYST,
        endpoint: ProductionEndpoints.PVSYST,
        name: 'pvsyst',
        logo: PvSystIcon,
    },
    {
        id: CustomizeOptions.PVSOL,
        endpoint: ProductionEndpoints.PVSOL,
        name: 'pvsol',
        logo: PvSolIcon,
    },
    {
        id: CustomizeOptions.OTHER,
        endpoint: ProductionEndpoints.OTHER,
        name: 'other',
        label: 'label.OTHER',
    },
];

// Production curve templates
export const URL_TEMPLATE_15MIN_CURVE_PRODUCTION = 'template_15min_curve_production';
export const URL_TEMPLATE_30MIN_CURVE_PRODUCTION = 'template_30min_curve_production';
export const URL_TEMPLATE_HOURLY_CURVE_PRODUCTION = 'template_hourly_curve_production';

export const CostsTypeOptions = {
    CAPEX: 1,
    OPEX: 2,
};

export const CostsOptions = {
    PANELS: 'PANELS',
    INVERTERS: 'INVERTERS',
    GENERAL: 'GENERAL',
    PREVENTIVE_MAINTENANCE: 'PREV_MAINTENANCE',
};
export const RUBICS_BY_CATEGORY = {
    /* TODO:  remover fe-1894 */ [CostsOptions.PANELS]: 'panels_costs',
    [CostsOptions.INVERTERS]: 'inverters_costs',
    [CostsOptions.GENERAL]: 'others_costs',
    [CostsOptions.PREVENTIVE_MAINTENANCE]: 'maintenance_costs',
};

export const costsCategories = [
    /* TODO:  remover fe-1894 */
    {
        id: CostsOptions.PANELS,
        type: CostsTypeOptions.CAPEX,
        name: 'panels',
        label: 'label.panels',
        visible: true,
        disabled: true,
    },
    {
        id: CostsOptions.INVERTERS,
        type: CostsTypeOptions.CAPEX,
        name: 'inverters',
        label: 'label.inverters.capitalize',
        visible: true,
        disabled: true,
    },
    {
        id: CostsOptions.GENERAL,
        type: CostsTypeOptions.CAPEX,
        name: 'general',
        label: 'label.generalCapex',
        visible: true,
        disabled: false,
    },
    {
        id: CostsOptions.PREVENTIVE_MAINTENANCE,
        type: CostsTypeOptions.OPEX,
        name: 'preventive_maintenance',
        label: 'label.PREV_MAINTENANCE',
        visible: true,
        disabled: false,
    },
];

export const EFZ_COSTS_TABLE_CONFIG = [
    {
        id: 'category',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={'label.category'} />
                </span>
            </div>
        ),
        minWidth: 25,
        width: 25,
        accessor: 'category',
        Cell: ({ row, costType, categories, featureFlags, handleChange, customizeLoadings: { finalSimulation } }) => {
            const {
                original: {
                    configs: { editable_value },
                },
            } = row;

            return isEnvDevFlag(featureFlags['fe-1894']) ?
                    <Select
                        className="table-input w-100"
                        value={row.original.category_id}
                        displayEmpty
                        disabled={!editable_value || finalSimulation}
                        onChange={(e) => {
                            const category = categories?.find((cat) => Number(cat.id) === Number(e.target.value));
                            return handleChange(row.id, {
                                category_id: Number(category?.id) ?? null,
                                cost_type_id: category?.cost_type_id ?? null,
                                category_description: category.description ?? null,
                            });
                        }}
                        placeholder={intlMessages('label.insertValue')}
                    >
                        <MenuItem value={''} disabled={true}>
                            <IntlMessages id={'label.selectOption'} />
                        </MenuItem>
                        {categories
                            .filter((cat) => cat?.cost_recurrence_id === costType)
                            .map((cat, index) => (
                                <MenuItem key={`efz-costs-category-${index}`} value={cat.id} disabled={cat?.disabled}>
                                    {/* {cat?.tag
                            ? <IntlMessages id={cat?.tag} />
                            : cat.descricao
                        } */}
                                    {cat.description}
                                </MenuItem>
                            ))}
                    </Select>
                :   <Select
                        className="table-input w-100"
                        value={row.original.category}
                        displayEmpty
                        disabled={!editable_value || finalSimulation}
                        onChange={(e) => handleChange(row.id, { category: e.target.value })}
                        placeholder={intlMessages('label.insertValue')}
                    >
                        <MenuItem value={''} disabled={true}>
                            <IntlMessages id={'label.selectOption'} />
                        </MenuItem>
                        {costsCategories
                            .filter((cat) => cat.type === costType)
                            .map((cat, index) => (
                                <MenuItem key={`efz-costs-category-${index}`} value={cat.id} disabled={cat.disabled}>
                                    <IntlMessages id={cat.label} />
                                </MenuItem>
                            ))}
                    </Select>;
        },
    },
    {
        id: 'description',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={'label.description'} />
                </span>
            </div>
        ),
        minWidth: 40,
        width: 40,
        accessor: 'description',
        Cell: ({ row, handleChange, customizeLoadings: { finalSimulation } }) => {
            const {
                original: {
                    configs: { editable_description },
                },
            } = row;
            return (
                <OutlinedInput
                    className="table-input w-100"
                    value={row.original.description}
                    disabled={!editable_description || finalSimulation}
                    onChange={(e) => handleChange(row.id, { description: e.target.value })}
                    placeholder={intlMessages('label.insertValue')}
                />
            );
        },
    },
    {
        id: 'cost',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={'label.cost'} />
                </span>
            </div>
        ),
        minWidth: 15,
        width: 15,
        accessor: 'cost',
        Cell: ({ row, handleChange, customizeLoadings: { finalSimulation } }) => {
            const {
                original: {
                    configs: { editable_value },
                },
            } = row;
            return (
                <NumberFormat
                    value={row.original.cost}
                    placeholder={intlMessages('label.value')}
                    customInput={OutlinedInput}
                    decimalScale={2}
                    allowNegative={false}
                    className="outlined-input-custom-adornment position-relative table-input w-100"
                    disabled={!editable_value || finalSimulation}
                    onChange={(e) => handleChange(row.id, { cost: e.target.value })}
                    endAdornment={
                        <InputAdornment position="end">
                            <Select
                                defaultValue={'currency'}
                                value={row.original.unit}
                                onChange={(e) => handleChange(row.id, { unit: e.target.value })}
                                disabled={!editable_value || finalSimulation}
                                className="position-absolute pe-0 me-0 adornment-select select-units"
                            >
                                {efzCustomizeMappedUnits.map((unitToShow, index) => {
                                    return (
                                        <MenuItem key={`efz-costs-unit-${index}`} value={unitToShow}>
                                            {/* @ts-ignore */}
                                            <CustomUnit unit={unitToShow} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </InputAdornment>
                    }
                />
            );
        },
    },
    {
        id: 'totalCost',
        Header: (
            <div className="w-100">
                <span>
                    <IntlMessages id={'label.totalCost'} />
                </span>
            </div>
        ),
        minWidth: 15,
        width: 15,
        accessor: 'totalCost',
        Cell: ({ row }) => {
            return (
                <NumberFormat
                    value={parseNumberWithToFixed(row.original.totalCost, 2)}
                    placeholder={intlMessages('label.value')}
                    customInput={OutlinedInput}
                    decimalScale={2}
                    allowNegative={false}
                    // @ts-ignore
                    format={(value) =>
                        new Intl.NumberFormat(row.original.locale, {
                            style: 'currency',
                            currency: row.original.currencyCode,
                            currencyDisplay: 'code',
                        })
                            .formatToParts(parseNumberWithToFixed(value, 2))
                            .filter((x) => x.type !== 'currency')
                            .map((x) => x.value)
                            .join('')
                            .trim()
                    }
                    className="table-input w-100"
                    disabled
                    //onChange={(e) => handleChange(row.id, { totalCost: e.target.value })}
                    // @ts-ignore
                    endAdornment={
                        <InputAdornment className="ms-2" position="end">
                            <CustomUnit unit={'currency'} />
                        </InputAdornment>
                    }
                />
            );
        },
    },
    {
        id: 'delete',
        Header: <></>,
        minWidth: 5,
        width: 5,
        accessor: 'description',
        Cell: ({ row, handleDelete, customizeLoadings: { finalSimulation } }) => {
            const {
                original: {
                    configs: { removable },
                },
            } = row;
            return (
                <IconButton
                    variant="tertiary"
                    icon="deleteBin"
                    className="w-100 justify-content-end"
                    disabled={!removable || finalSimulation}
                    onClick={() => handleDelete(row.id)}
                    dataTestId="delete-cost"
                />
            );
        },
    },
];

export const CUSTOMIZE_DUMMY_AREA = (panelsNumbers, rangeId) => {
    return {
        areas: [
            {
                id: 1,
                area: 402.54548901216094,
                orientation: 0,
                slope: 20,
                coordinates: [
                    {
                        lat: 41.208584057872486,
                        lng: -8.660014335538316,
                    },
                    {
                        lat: 41.20833636394275,
                        lng: -8.659932528163361,
                    },
                    {
                        lat: 41.20847862411477,
                        lng: -8.65982591035502,
                    },
                    {
                        lat: 41.208644593924575,
                        lng: -8.659664307261872,
                    },
                ],
                coordinates_avg: {
                    lat: 41.20851090996365,
                    long: -8.659859270329642,
                    region_gd_id: 126,
                },
                lat: 41.20851090996365,
                long: -8.659859270329642,
                panels_number: panelsNumbers,
                region_gd_id: 126,
                region_radiation: null,
                mounting_structure_id: 25,
                area_reduction_elements: {
                    others: false,
                    platbands: false,
                    chillers: false,
                    surrounding: false,
                },
                selected: true,
                panels_number_possible: panelsNumbers,
                panels_number_excluded: 0,
                range_id: rangeId,
                total_loss: 0.0834,
            },
        ],
        coordinates_avg: {
            lat: 41.20851090996365,
            long: -8.659859270329642,
            region_gd_id: 126,
        },
        network_sale: true,
        range_id: 1,
        panel_id: 1,
        exclusions: [],
        max_tec_panels: 240,
        min_tec_panels: 12,
        total_areas: 402.54548901216094,
        total_panels: 170,
        total_perimeter_areas: 100,
        isChangeBattery: true,
        margin: 0.25,
    };
};
