import { useContext, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

// Components
import OfferSummaryLEC from './OfferSummary';
import NegotiationLEC from './Negotiation';
import Loading from 'components/core/Loading';
import IntlMessages from 'components/util/IntlMessages';
import Alert from 'components/@efz/Alert';
import { IBmDetailedForm } from 'components/BusinessModels';
import GrantsLEC from './Grants';

// Constants
import { BUSINESS_MODEL_ELIGIBLE, SIZING_TYPE_IDS } from 'constants/businessModels';

// Contexts
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';

// Services
import { isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';
import ContactInfoLEC from './ContactInfo';
import { Tlec } from 'interfaces/businessModels/lec';

const ProjectSummaryLECDetailed = () => {
    const {
        negotiationProfile,
        bmSelected,
        state: {
            selected: {
                values: { rubrics },
            },
        },
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, Tlec>;

    const { bmState, setBMEventHandler } = useContext(OfferEditionContext);

    const [fullDirty, setFullDirty] = useState(false);

    const methods = useFormContext<IBmDetailedForm>();

    const hasErrorOnNetSavingsEligibility = bmState?.kpis?.negotiation?.hasErrorOnNetSavingsEligibility;
    const hasAuditProposal =
        (isFieldDefined(bmState?.businessModelSelected) &&
            bmState?.businessModelSelected?.sizing_type_id === SIZING_TYPE_IDS.DETAILED_FULL &&
            bmState?.kpis?.negotiation.status_risk_premium_id === BUSINESS_MODEL_ELIGIBLE.NO) ||
        hasErrorOnNetSavingsEligibility;

    const watchAllFields = methods.watch();

    const updateValues = () => {
        if (methods.formState.isDirty && !fullDirty) setFullDirty(true);
        setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_VALUES, {
            // @ts-ignore
            values: methods.getValues(),
            sliderReady: methods.formState.isDirty || fullDirty ? false : true,
        });
    };

    useEffect(
        () => {
            updateValues();
        },
        /* eslint-disable */
        [
            watchAllFields?.monthly_payment_option_id,
            watchAllFields?.annualEvoFee,
            watchAllFields?.extraCost,
            watchAllFields?.recOwnership,
            watchAllFields?.ownershipTransfer,
            watchAllFields?.itcRate,
            rubrics.cost,
        ]
        /* eslint-enable */
    );

    const showsContactInfo = negotiationProfile?.mostra_formulario && bmSelected?.options?.has_form;

    if (!bmState.businessModelSelected) return <Loading />;
    return (
        <div className="bm-detailed">
            {!hasErrorOnNetSavingsEligibility && <OfferSummaryLEC />}
            {!hasAuditProposal && bmState?.sliderReady && isDefined(bmState.offerEditionValues.monthly_payment_option_id) && (
                <NegotiationLEC />
            )}
            {negotiationProfile?.mostra_subsidios && bmSelected?.values?.grants?.hasGrants ?
                <GrantsLEC />
            :   <></>}
            {bmState?.sliderReady && hasAuditProposal && (
                <Alert severity={'error'}>
                    <IntlMessages
                        id={
                            hasErrorOnNetSavingsEligibility ?
                                'page.businessModels.notEligible.reason.not_net_savings_eligibility'
                            :   'page.businessModels.auditProposal.info'
                        }
                    />
                </Alert>
            )}

            {showsContactInfo ?
                <ContactInfoLEC />
            :   <></>}
        </div>
    );
};

export default ProjectSummaryLECDetailed;
