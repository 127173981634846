import { useContext } from 'react';

// Components
import CommercialMarginInstallmentsSimple from 'components/BusinessModels/ProjectSummary/ProjectSummaryInstallments/simple/CommercialMargin';
import OfferDetailsInstallmentsSimple from 'components/BusinessModels/ProjectSummary/ProjectSummaryInstallments/simple/OfferDetails';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront } from 'interfaces/businessModels/upfront';

const ProjectSummaryInstallmentsSimple = () => {
    const {
        negotiationProfile,
        state: { businessModels },
        bmSelected,
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;
    const has_offer_edition = businessModels?.find((bm) => bm.business_model_id === bmSelected.id)?.has_offer_edition;

    return (
        <>
            {negotiationProfile?.acesso_resumo_custo && has_offer_edition ?
                <OfferDetailsInstallmentsSimple />
            :   <></>}
            {negotiationProfile?.negoceia_proposta ?
                <CommercialMarginInstallmentsSimple />
            :   <></>}
        </>
    );
};

export default ProjectSummaryInstallmentsSimple;
