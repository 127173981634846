import { StatusCodes } from 'http-status-codes';
import { putProposalDescription } from 'api/processes';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { notify } from 'services/@efz/notify';
import { Nullable } from 'types/utils';
import { intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';
import ErrorMessage from 'components/errors/ErrorMessage';
import OutlinedInput from '@mui/material/OutlinedInput';
import Alert from 'components/@efz/Alert';
import IntlMessages from 'components/util/IntlMessages';

const VALUE_EDIT_PROPOSAL = 150;

const useEditProposalDescription = (id, defaultData) => {
    const {
        handleSubmit,
        control,
        formState: { errors, isDirty },
        reset,
    } = useForm({
        defaultValues: {
            descricao: defaultData?.descricao ?? '',
        },
        mode: 'onChange',
    });

    const [requesting, setRequesting] = useState(false);
    const [showAlert, setShowAlert] = useState(defaultData?.descricao?.length === VALUE_EDIT_PROPOSAL);

    const editProposalDescriptionHandler = async (payload, close?: Nullable<() => void>) => {
        try {
            setRequesting(true);
            const rspData = await putProposalDescription(id, payload);
            if (rspData?.data?.status === StatusCodes.OK) {
                notify(rspData?.data?.message, 'success');
                !!close && close();
            } else notify(rspData?.data?.message, 'error');
            setRequesting(false);
        } catch (error) {
            setRequesting(false);
            console.error(error);
        }
    };

    const submit = async (formData: any, close?: Nullable<() => void>) => {
        await editProposalDescriptionHandler(formData['descricao'], close);
        reset({}, { keepValues: true });
    };

    const input = (
        <>
            <Controller
                name={`descricao`}
                control={control}
                rules={{
                    required: !isFieldDefined(defaultData?.descricao) ? 'yup.message.required' : false,
                    maxLength: {
                        value: VALUE_EDIT_PROPOSAL,
                        message: 'yup.message.number.max',
                    },
                }}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                render={({ field: { ref, onChange, ...field } }) => (
                    <OutlinedInput
                        {...field}
                        disabled={requesting}
                        onChange={(e) => {
                            if (e.target.value.length === VALUE_EDIT_PROPOSAL) setShowAlert(true);
                            else if (showAlert && e.target.value.length < VALUE_EDIT_PROPOSAL) setShowAlert(false);
                            onChange(e);
                        }}
                        inputProps={{ maxLength: VALUE_EDIT_PROPOSAL }}
                        multiline
                        rows="2"
                        className="text-area"
                        fullWidth
                        placeholder={intlMessages('label.insertDescription')}
                    />
                )}
            />
            {!!errors['descricao'] && <ErrorMessage error={errors['descricao']} values={{ max: VALUE_EDIT_PROPOSAL }} />}
            {showAlert && (
                <Alert severity="warning" className="mt-2">
                    <IntlMessages id="utils.inputs.maxLengthReached" values={{ maxLength: VALUE_EDIT_PROPOSAL }} />
                </Alert>
            )}
        </>
    );

    return {
        errors,
        handleSubmit,
        control,
        requesting,
        submit,
        input,
        isDirty,
    };
};
export default useEditProposalDescription;
