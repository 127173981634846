import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';

interface IProps {
    label: string;
    values: {
        number: number | undefined;
        unit: string;
        numberOfDecimalPlaces: number;
    }[];
}
const ServiceV2MarginKpi = ({ label, values }: IProps) => {
    return (
        <div className="bm-kpis-margin-kpi">
            <label>
                <IntlMessages id={label} />
            </label>
            <div className="d-flex">
                {values.map((value, i) => (
                    <>
                        <span>
                            {/* @ts-ignore */}
                            <FormatNumber unit={value.unit} number={value.number} numberOfDecimalPlaces={value.numberOfDecimalPlaces} />
                        </span>
                        {i < values?.length - 1 && <span className="mx-1"> | </span>}
                    </>
                ))}
            </div>
        </div>
    );
};

export default ServiceV2MarginKpi;
