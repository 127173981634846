//Libraries
import PropTypes from 'prop-types';
import { useState } from 'react';
//Our Components
import { intlMessages } from 'services/util/auxiliaryUtils';
import IntlMessages from 'components/util/IntlMessages';
import Loading from 'components/core/Loading';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Autocomplete from '@mui/material/Autocomplete';
import { gAnalyticsEvt } from 'services/gAnalytics';
import { GA_LABEL_TOP_LEVEL_NAVIGATION, GA_TYPE_EVT } from 'constants/gAnalytics';
import { useSelector } from 'react-redux';
import { getClientFacilities } from 'redux/selectors/client';
import TranslateFacility from 'components/@efz/TranslateFacility';

const SectionSearch = ({ handleChangeAutocomplete, searchData }) => {
    const [showHistory, setShowHistory] = useState(true);
    let searchHistory = useSelector((state) => getClientFacilities(state?.client));

    const handleChange = (data) => {
        setShowHistory(false);
        handleChangeAutocomplete(data);
    };

    return (
        <>
            <div className={`header-search-card-text ms-3 mt-3`}>
                <h5 className="mb-0">
                    <IntlMessages id="header.facilityCard.search" values={{ value: <TranslateFacility styleLetter="capitalize" /> }} />
                </h5>
            </div>
            {/* autocomplete */}
            <div className="autocomplete-header">
                <Autocomplete
                    openOnFocus
                    PaperComponent="div"
                    disablePortal={true}
                    onChange={(_, valueSelected) => handleChange({ name: 'facility', value: valueSelected })}
                    getOptionLabel={(option) => option?.['morada'] ?? ''}
                    renderOption={(props, option) => (
                        <li {...props} key={`${option?.['id']}_${option?.['descricao']}`}>
                            <div className="autocomplete-header-option-wrapper">
                                <h5>
                                    {!!option?.location && option?.location?.street ?
                                        option?.location?.street ?? ''
                                    :   option?.['morada'] ?? ''}
                                </h5>
                                <h6 className="mt-1">{option?.['cpe'] ?? ''}</h6>
                            </div>
                        </li>
                    )}
                    options={
                        showHistory ?
                            searchHistory?.length > 0 ?
                                searchHistory
                            :   []
                        : searchData?.list?.length > 0 ?
                            searchData?.list
                        :   []
                    }
                    filterOptions={(options) => (options?.length > 0 ? options : [])}
                    loading={searchData?.loader}
                    noOptionsText={<IntlMessages id="label.NoResults" />}
                    popupIcon={null}
                    clearText={intlMessages('label.clear')}
                    loadingText={`${intlMessages('label.loading')}`}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    renderInput={({ InputLabelProps, InputProps, ...params }) => {
                        return (
                            <OutlinedInput
                                {...params}
                                placeholder={intlMessages('header.searchFacility.placeholder')}
                                autoFocus={true}
                                fullWidth
                                onChange={(event) => handleChange({ name: `facilitySearch`, value: event.target.value })}
                                className="pe-3"
                                ref={InputProps.ref}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {searchData?.loader ?
                                            <Loading size={20} color="inherit" className="autocomplete-loader" />
                                        :   <></>}
                                        {!searchData?.loader && <i className="zmdi zmdi-search" />}
                                    </InputAdornment>
                                }
                                onClick={() =>
                                    gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_SEARCH_BAR, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION)
                                }
                            />
                        );
                    }}
                />
            </div>
        </>
    );
};

SectionSearch.propTypes = {
    handleChangeAutocomplete: PropTypes.func.isRequired,
    searchData: PropTypes.shape({
        loader: PropTypes.bool.isRequired,
        list: PropTypes.array.isRequired,
        history: PropTypes.array,
    }),
    userTypeID: PropTypes.number,
};
export default SectionSearch;
