import clone from 'fast-copy';
import { ReactComponent as IndependentHouse } from 'assets/images/products/solarpv/independent-house.svg';
import { ReactComponent as TownHouse } from 'assets/images/products/solarpv/town-house.svg';
import { ReactComponent as Building } from 'assets/images/products/solarpv/building.svg';
import { ReactComponent as BuildingSelected } from 'assets/images/products/solarpv/building-selected.svg';
import { ReactComponent as PPCLogo } from 'assets/images/logos/svg/ppc-logo.svg';
import { ReactComponent as Ground } from 'assets/images/products/solarpv/ground.svg';
import { ReactComponent as GroundB2B } from 'assets/images/products/solarpv/ground-b2b.svg';
import { ReactComponent as Roof } from 'assets/images/products/solarpv/roof.svg';
import { ReactComponent as RoofSelected } from 'assets/images/products/solarpv/roof-selected.svg';
import IntlMessages from 'components/util/IntlMessages';
import validate from 'services/util/validate';
import { USER_COUNTRY_LatLng } from 'constants/user';
import { REMUNERATION_MODELS_TYPE } from './spvPro';
import { getCompanyProfileIds } from 'services/user';
import { DEFAULTS_COMMON_SETTINGS_MAP } from 'constants/gmapSettings';

export const STEPS_RESERVED_AREA = {
    HOUSING_TYPE: 1,
    ADDRESS: 2,
    ROOF_IDENTIFICATION: 3,
    ROOF_DETAILS: 4,
    SIMULATION: 5,
    BUSINESS_MODELS: 6,
    DOWNLOAD: 7,
};

export const PERIOD_TYPE_IDS = {
    [getCompanyProfileIds().PPC]: 48,
    default: 48, //PPC
};

export const STEPS_OPEN_AREA = {
    HOUSING_TYPE: 1,
    ADDRESS: 2,
    ROOF_IDENTIFICATION: 3,
    ROOF_DETAILS: 4,
    CONSUMPTION_DETAILS: 5,
    SIMULATION: 6,
};

export const spvSimpleActions = {
    SET_INIT: 'set_init',
    SET_RESET_FORM: 'set_reset_form',
    SET_STEP: 'set_step',
    SET_STEP_POPSTATE: 'set_step_popstate',
    SET_HOUSING_TYPE: 'set_housing_type',
    SET_ADDRESS: 'set_address',
    SET_ROOF_IDENTIFICATION: 'set_roof_identification',
    SET_ROOF_IDENTIFICATION_SUCCESS: 'set_roof_identification_success',
    SET_STRUCTURES_OPTIONS: 'set_structures_options',
    SET_ROOF_DETAILS: 'set_roof_details',
    SET_COORDINATES_AVG: 'set_coordinates_avg',
    SET_CONSUMPTION_DETAILS: 'set_consumption_details',
    SET_EQUIPMENTS: 'set_equipments',
    SET_SIMULATION: 'set_simulation',
    SET_REDUX_INPUTS: 'set_redux_inputs',
    SET_IS_REQUESTING_SIMS: 'set_is_requesting_sims',
    SET_SIMULATION_SUCCESS: 'set_simulation_success',
    SET_SIMULATION_KIT: 'set_simulation_kit',
    SET_BUSINESS_MODELS: 'set_business_models',
    SET_DOWNLOAD: 'set_download',
    RESET_PROPOSAL: 'reset_proposal',
    START_OVER: 'start_over',
    DRAW_ROOF_MANUALLY: 'draw_roof_manually',
    SUCCESSFUL_REQUEST: 'successful_request',
};
export const solarpvMapActions = {
    SET_MAP: 'set_map',
    SET_INIT: 'set_init',
    SET_IS_DRAWING: 'set_is_drawing',
    SET_DRAW_AGAIN: 'set_draw_again',
    EVT_POLYGONCOMPLETE: 'evt_polygoncomplete',
    EVT_SET_AT: 'evt_set_at',
    EVT_REMOVE_AT: 'evt_remove_at',
    EVT_INSERT_AT: 'evt_insert_at',
    SET_POLYGON_V2: 'set_polygon_v2',
    EVT_CLICK_MAP: 'click_map',
};

export const SPV_SIMPLE_INPUTS = {
    HOUSING_TYPE: 'housing_type',
    AREA_TYPE: 'area_type',
    ADDRESS: 'address',
    MOUNTING_STRUCTURE_ID: 'mounting_structure_id',
    PHASES_NUMBER_ID: 'phases_number_id',
    ENERGY_PRICE: 'energy_price',
    ANNUAL_CONSUMPTION: 'annual_consumption',
    EQUIPMENTS: 'equipments',
    REMUNERATION_TYPE_ID: 'remuneration_type_id',
    INVESTMENT_TYPE_ID: 'investment_type_id',
    MONTHLY_PAYMENTS_NUMBER: 'monthly_payments_number',
    UPFRONT_INSTALLMENTS_VALUE: 'upfront_installments_value',
    INSTALLMENTS_MONTHLY_FEE: 'installments_monthly_fee',
    GRANT_ID: 'grant_id',
    GRANT_SP_BATTERY: 'subsidy_investment_battery_prc',
    GRANT_SP_PV_STATION: 'subsidy_investment_pv_station_prc',
    GRANT_SV_BATTERY: 'subsidy_investment_battery_value',
    GRANT_SV_PV_STATION: 'subsidy_investment_pv_station_value',
    CLIENT_NAME: 'name',
    CLIENT_EMAIL: 'email',
    CLIENT_PHONE: 'phone',
};
export const HOUSING_TYPES_B2C = {
    INDEPENDENT: 1,
    TOWN: 2,
    BUILDING: 3,
    GROUND: 4,
};

export const HOUSING_TYPES_B2B = {
    INDEPENDENT: 1,
    ADJACENT: 2,
};

export const AREA_TYPES = {
    ROOF: 1,
    GROUND: 2,
};

export const HOUSING_TYPES_CONFIGS_B2C = [
    {
        id: HOUSING_TYPES_B2C.INDEPENDENT,
        label: <IntlMessages id="page.spvSimple.housingTypes.independent" />,
        icon: <IndependentHouse />,
    },
    {
        id: HOUSING_TYPES_B2C.TOWN,
        label: <IntlMessages id="page.spvSimple.housingTypes.town" />,
        icon: <TownHouse />,
    },
    {
        id: HOUSING_TYPES_B2C.BUILDING,
        label: <IntlMessages id="page.spvSimple.housingTypes.building" />,
        icon: <Building />,
    },
    {
        id: HOUSING_TYPES_B2C.GROUND,
        label: <IntlMessages id="page.spvSimple.housingTypes.ground" />,
        icon: <Ground />,
    },
];

export const HOUSING_TYPES_CONFIGS_B2B = [
    {
        id: HOUSING_TYPES_B2B.INDEPENDENT,
        label: <IntlMessages id="page.spvSimple.housingTypes.businessBuilding" />,
        icon: <Building />,
        iconSelected: <BuildingSelected className="selected" />,
    },
    {
        id: HOUSING_TYPES_B2B.ADJACENT,
        label: <IntlMessages id="page.spvSimple.housingTypes.businessShop" />,
        icon: <TownHouse />,
        iconSelected: <TownHouse />,
    },
];

export const AREA_TYPES_CONFIGS = [
    {
        id: AREA_TYPES.ROOF,
        label: <IntlMessages id="page.spvSimple.areaTypes.roof" />,
        icon: <Roof />,
        iconSelected: <RoofSelected className="selected" />,
    },
    {
        id: AREA_TYPES.GROUND,
        label: <IntlMessages id="page.spvSimple.areaTypes.ground" />,
        icon: <GroundB2B />,
        iconSelected: <GroundB2B />,
    },
];

export const ROOF_ID_BULLETS_NUMBER = 4;

export const ROOF_TYPES = {
    TILE: 1,
    FLAT: 2,
};

export const ROOF_DETAILS_CONFIGS = [
    {
        id: ROOF_TYPES.TILE,
        label: <IntlMessages id="page.spvSimple.roofDetails.roofType.tile" />,
    },
    {
        id: ROOF_TYPES.FLAT,
        label: <IntlMessages id="page.spvSimple.roofDetails.roofType.flat" />,
    },
];

export const NUMBER_PHASES_IDS = {
    MONOPHASIC: 1,
    THREEPHASIC: 2,
};

export const NUMBER_PHASES_CONFIGS = [
    {
        id: NUMBER_PHASES_IDS.MONOPHASIC,
        label: <IntlMessages id="label.monophasic" />,
    },
    {
        id: NUMBER_PHASES_IDS.THREEPHASIC,
        label: <IntlMessages id="label.threephasic" />,
    },
];

export const SIMULATION_CARDS_ENTRIES = {
    PANELS: 'panels',
    FOOTPRINT: 'footprint',
    PRODUCTION: 'production',
    PAYBACK: 'payback',
    INVESTMENT: 'investment',
    SAVINGS: 'savings',
};

export const GRANTS_TAGS = {
    CAT_A: 1,
    CAT_B: 2,
    CAT_C: 3,
    CAT_D: 4,
};

export const SOLUTIONS_TO_PRESENT_B2C = [
    // cards to be presented in Simulations page
    {
        order: 1,
        visible: () => true,
        remuneration_type_id: REMUNERATION_MODELS_TYPE.NET_METERING,
        has_battery: false,
        title: 'myEnergy SolarNet+',
        description: 'page.spvSimple.simulation.card.description.netMeteringNoBattery',
        entries: [
            SIMULATION_CARDS_ENTRIES.PANELS,
            SIMULATION_CARDS_ENTRIES.FOOTPRINT,
            SIMULATION_CARDS_ENTRIES.PRODUCTION,
            // SIMULATION_CARDS_ENTRIES.PAYBACK,
            SIMULATION_CARDS_ENTRIES.INVESTMENT,
        ],
    },
    {
        order: 2,
        visible: () => true,
        remuneration_type_id: REMUNERATION_MODELS_TYPE.NET_METERING,
        has_battery: true,
        title: 'myEnergy SolarNet Save',
        description: 'page.spvSimple.simulation.card.description.netMeteringBattery',
        entries: [
            SIMULATION_CARDS_ENTRIES.PANELS,
            SIMULATION_CARDS_ENTRIES.FOOTPRINT,
            SIMULATION_CARDS_ENTRIES.PRODUCTION,
            // SIMULATION_CARDS_ENTRIES.PAYBACK,
            SIMULATION_CARDS_ENTRIES.INVESTMENT,
        ],
    },
    {
        order: 3,
        visible: () => false,
        remuneration_type_id: REMUNERATION_MODELS_TYPE.FEED_IN_TARIFF,
        has_battery: false,
        title: 'myEnergy SolarShare',
        description: 'page.spvSimple.simulation.card.description.feedIn',
        entries: [
            SIMULATION_CARDS_ENTRIES.PANELS,
            SIMULATION_CARDS_ENTRIES.FOOTPRINT,
            SIMULATION_CARDS_ENTRIES.PRODUCTION,
            // SIMULATION_CARDS_ENTRIES.PAYBACK,
            SIMULATION_CARDS_ENTRIES.INVESTMENT,
        ],
    },
];

export const SOLUTIONS_TO_PRESENT_B2B = [
    // cards to be presented in Simulations page
    {
        order: 1,
        remuneration_type_id: REMUNERATION_MODELS_TYPE.NET_METERING,
        has_battery: false,
        title: 'SolarNet+',
        description: 'page.spvSimple.simulation.card.description.netMeteringNoBatteryB2B',
        entries: [
            SIMULATION_CARDS_ENTRIES.PANELS,
            SIMULATION_CARDS_ENTRIES.FOOTPRINT,
            SIMULATION_CARDS_ENTRIES.PRODUCTION,
            // SIMULATION_CARDS_ENTRIES.PAYBACK,
            SIMULATION_CARDS_ENTRIES.INVESTMENT,
        ],
    },
    {
        order: 2,
        remuneration_type_id: REMUNERATION_MODELS_TYPE.NET_METERING,
        has_battery: true,
        title: 'SolarNetSave',
        description: 'page.spvSimple.simulation.card.description.netMeteringBatteryB2B',
        entries: [
            SIMULATION_CARDS_ENTRIES.PANELS,
            SIMULATION_CARDS_ENTRIES.FOOTPRINT,
            SIMULATION_CARDS_ENTRIES.PRODUCTION,
            // SIMULATION_CARDS_ENTRIES.PAYBACK,
            SIMULATION_CARDS_ENTRIES.INVESTMENT,
        ],
    },
];

export const INVESTMENT_OPTIONS = {
    CASH: { id: 1, name: 'cash' },
    INSTALLMENTS: { id: 2, name: 'installments' },
};

export const INVESTMENT_OPTIONS_B2C = [
    {
        order: INVESTMENT_OPTIONS.CASH.id,
        id: INVESTMENT_OPTIONS.CASH.id,
        name: INVESTMENT_OPTIONS.CASH.name,
        label: `page.spvSimple.simulation.card.investment.type.${INVESTMENT_OPTIONS.CASH.name}`,
        default: true,
    },
    {
        order: INVESTMENT_OPTIONS.INSTALLMENTS.id,
        id: INVESTMENT_OPTIONS.INSTALLMENTS.id,
        name: INVESTMENT_OPTIONS.INSTALLMENTS.name,
        label: `page.spvSimple.simulation.card.investment.type.b2c.${INVESTMENT_OPTIONS.INSTALLMENTS.name}`,
        default: false,
    },
];

export const INVESTMENT_OPTIONS_B2B = [
    {
        order: INVESTMENT_OPTIONS.CASH.id,
        id: INVESTMENT_OPTIONS.CASH.id,
        name: INVESTMENT_OPTIONS.CASH.name,
        label: `page.spvSimple.simulation.card.investment.type.${INVESTMENT_OPTIONS.CASH.name}`,
        default: true,
    },
    {
        order: INVESTMENT_OPTIONS.INSTALLMENTS.id,
        id: INVESTMENT_OPTIONS.INSTALLMENTS.id,
        name: INVESTMENT_OPTIONS.INSTALLMENTS.name,
        label: `page.spvSimple.simulation.card.investment.type.${INVESTMENT_OPTIONS.INSTALLMENTS.name}`,
        default: false,
    },
];

// simulations PVGPIS

export const DEFAULT_AREA_PVGPI = clone({
    panelNumber: 8,
    peakPower: 4.3,
    slope: 30,
    orientation: 0,
});
export const DEFAULT_PVGPI_PAYLOAD = clone({
    parameters: {
        mode: 'realTime',
        referenceTimeSeries: {
            start: 1451606400000,
            end: 1483227900000,
        },
        refYear: 2013,
        pvtechchoice: 'crystSi',
        raddatabase: 'PVGIS-SARAH',
        totalLoss: 0.17250000000000001,
    },
    areas: [
        {
            ...DEFAULT_AREA_PVGPI,
            lat: null,
            lon: null,
        },
    ],
});

export const DEFAULT_LOCATION = clone({
    lat: null,
    lng: null,
    address: '',
    locality: '',
    region: '',
    postal_code: '',
    country: '',
    kpis: {
        ...DEFAULT_AREA_PVGPI,
        regionId: null,
        production: null,
        usedMode: '',
    },
});

export const DEFAULTS_PROPS_MAP = {
    mapOptions: {
        id: 'efz-map',
        zoom: 7,
        tilt: 0,
        mapType: 'satellite',
        mapTypeId: 'hybrid',
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        // disableDefaultUI: false,
    },
    settings: DEFAULTS_COMMON_SETTINGS_MAP,
    country: USER_COUNTRY_LatLng,
    polygon: {
        styles: {
            strokeOpacity: 0,
            strokeWeight: 2,
            fillOpacity: 0.4,
            strokeColor: {
                selected: 'rgba(245, 234, 143, 1)',
                notSelected: 'rgba(72, 68, 71, 1)',
                panelSelected: 'rgba(255, 255, 255, 1)',
                panelNotSelected: 'rgba(255, 255, 255, 0.3)',
            },
            fillColor: {
                selected: 'rgba(0, 163, 224, 1)',
                notSelected: 'rgba(251, 247, 208, 0.5)',
                panelSelected: 'rgba(1, 23, 61, 1)',
                panelNotSelected: 'rgba(1, 23, 61, 0.3)',
                panelInvalid: 'rgba(238, 22, 45, 0.5)',
            },
        },
    },
    polygonOnDraw: {
        styles: {
            strokeOpacity: 1,
            strokeWeight: 4,
            fillOpacity: 0.4,
            strokeColor: {
                selected: 'rgba(0, 163, 224, 1)',
                notSelected: 'rgba(72, 68, 71, 1)',
                panelSelected: 'rgba(255, 255, 255, 1)',
                panelNotSelected: 'rgba(255, 255, 255, 0.3)',
            },
            fillColor: {
                selected: 'rgba(0, 163, 224, 1)',
                notSelected: 'rgba(251, 247, 208, 0.5)',
                panelSelected: 'rgba(1, 23, 61, 1)',
                panelNotSelected: 'rgba(1, 23, 61, 0.3)',
                panelInvalid: 'rgba(238, 22, 45, 0.5)',
            },
        },
    },
};

export const SPV_SIMPLE_QUESTIONS = {
    [SPV_SIMPLE_INPUTS.MOUNTING_STRUCTURE_ID]: {
        name: SPV_SIMPLE_INPUTS.MOUNTING_STRUCTURE_ID,
        label: 'page.spvSimple.roofDetails.roofType.label',
        placeholder: 'page.spvSimple.roofDetails.roofType.placeholder',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required']),
    },
    [SPV_SIMPLE_INPUTS.PHASES_NUMBER_ID]: {
        name: SPV_SIMPLE_INPUTS.PHASES_NUMBER_ID,
        label: 'label.numberOfPhases',
        tooltip: 'label.numberOfPhases.tooltip',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required']),
    },
    [SPV_SIMPLE_INPUTS.ENERGY_PRICE]: {
        name: SPV_SIMPLE_INPUTS.ENERGY_PRICE,
        label: 'page.spvSimple.consumptionDetails.energyPrice.label',
        placeholder: 'placeholder.general',
        tooltip: 'page.spvSimple.consumptionDetails.energyPrice.tooltip',
        unit: 'ypw',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required']),
    },
    [SPV_SIMPLE_INPUTS.ANNUAL_CONSUMPTION]: {
        name: SPV_SIMPLE_INPUTS.ANNUAL_CONSUMPTION,
        label: 'label.annualConsumption',
        placeholder: 'placeholder.general',
        tooltip: 'page.spvSimple.consumptionDetails.annualConsumption.tooltip',
        unit: 'kwh',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required']),
    },
    [SPV_SIMPLE_INPUTS.EQUIPMENTS]: {
        name: SPV_SIMPLE_INPUTS.EQUIPMENTS,
        label: 'page.spvSimple.consumptionDetails.equipments.label',
        defaultValue: [],
        visible: true,
        validation: validate(['required']),
    },
    [SPV_SIMPLE_INPUTS.REMUNERATION_TYPE_ID]: {
        name: SPV_SIMPLE_INPUTS.REMUNERATION_TYPE_ID,
        label: 'page.spvSimple.simulation.remunerationModel.label',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required']),
    },
    [SPV_SIMPLE_INPUTS.INVESTMENT_TYPE_ID]: {
        name: SPV_SIMPLE_INPUTS.INVESTMENT_TYPE_ID,
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required']),
    },
    [SPV_SIMPLE_INPUTS.MONTHLY_PAYMENTS_NUMBER]: {
        name: SPV_SIMPLE_INPUTS.MONTHLY_PAYMENTS_NUMBER,
        type: 'string',
        defaultValue: '',
        visible: true,
    },
    [SPV_SIMPLE_INPUTS.UPFRONT_INSTALLMENTS_VALUE]: {
        name: SPV_SIMPLE_INPUTS.UPFRONT_INSTALLMENTS_VALUE,
        type: 'string',
        defaultValue: '',
        visible: true,
    },
    [SPV_SIMPLE_INPUTS.INSTALLMENTS_MONTHLY_FEE]: {
        name: SPV_SIMPLE_INPUTS.INSTALLMENTS_MONTHLY_FEE,
        type: 'string',
        defaultValue: '',
        visible: true,
    },
    [SPV_SIMPLE_INPUTS.CLIENT_NAME]: {
        name: SPV_SIMPLE_INPUTS.CLIENT_NAME,
        placeholder: 'label.name',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required', 'maxLength'], { maxLength: 50 }),
        optValidation: { maxLength: 50 },
    },
    [SPV_SIMPLE_INPUTS.GRANT_ID]: {
        name: SPV_SIMPLE_INPUTS.GRANT_ID,
        placeholder: 'label.name',
        type: 'string',
        defaultValue: '',
        visible: true,
    },
    [SPV_SIMPLE_INPUTS.GRANT_SP_BATTERY]: {
        name: SPV_SIMPLE_INPUTS.GRANT_SP_BATTERY,
        placeholder: 'label.name',
        type: 'string',
        defaultValue: '',
        visible: true,
    },
    [SPV_SIMPLE_INPUTS.GRANT_SP_PV_STATION]: {
        name: SPV_SIMPLE_INPUTS.GRANT_SP_PV_STATION,
        placeholder: 'label.name',
        type: 'string',
        defaultValue: '',
        visible: true,
    },
    [SPV_SIMPLE_INPUTS.GRANT_SV_BATTERY]: {
        name: SPV_SIMPLE_INPUTS.GRANT_SV_BATTERY,
        placeholder: 'label.name',
        type: 'string',
        defaultValue: '',
        visible: true,
    },
    [SPV_SIMPLE_INPUTS.GRANT_SV_PV_STATION]: {
        name: SPV_SIMPLE_INPUTS.GRANT_SV_BATTERY,
        placeholder: 'label.name',
        type: 'string',
        defaultValue: '',
        visible: true,
    },
    [SPV_SIMPLE_INPUTS.CLIENT_EMAIL]: {
        name: SPV_SIMPLE_INPUTS.CLIENT_EMAIL,
        placeholder: 'label.email',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required', 'email']),
    },
    [SPV_SIMPLE_INPUTS.CLIENT_PHONE]: {
        name: SPV_SIMPLE_INPUTS.CLIENT_PHONE,
        placeholder: 'label.phone',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required']),
    },
};

export const CompanyDescriptionB2C = {
    [getCompanyProfileIds().PPC]: { title: 'myEnergySolar', logo: <PPCLogo /> },
};

export const CompanyDescriptionB2B = {
    [getCompanyProfileIds().PPC]: {
        title: 'myEnergySolar',
        shortenedTitle: 'myEnergy',
        superscript: 'Pro',
        logo: <PPCLogo />,
    },
};

export const SpvSimpleError = {
    DETECT_ROOF: 'DS Detect Roof Analyser',
    ANALYSE_ROOF: 'DS Analyse Roof',
    NO_ROOF: 'DS No Roof',
};
