import { useContext } from 'react';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPPa } from 'interfaces/businessModels/ppa';

// Components
import ServicePPaMarginKpi from './MarginKpi';

const ServicePPaMarginKpis = () => {
    const {
        bmSelected: {
            values: { marginSelected },
        },
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;

    const energyPrice = marginSelected?.energy_price ?? 0;
    const tir = marginSelected?.irr_prc ?? 0;
    const annualSaving = marginSelected?.annual_saving ?? 0;
    const capex = marginSelected?.cost ?? 0;

    return (
        <div className="bm-kpis-margin">
            <ServicePPaMarginKpi label="label.price" value={{ unit: 'cmwp', number: energyPrice, numberOfDecimalPlaces: 0 }} />
            <ServicePPaMarginKpi label="label.tir" value={{ unit: 'percent', number: tir, numberOfDecimalPlaces: 2 }} />
            <ServicePPaMarginKpi label="label.annualSaving" value={{ unit: 'currency', number: annualSaving, numberOfDecimalPlaces: 0 }} />
            <ServicePPaMarginKpi label="label.CAPEX" value={{ unit: 'currency', number: capex, numberOfDecimalPlaces: 0 }} />
        </div>
    );
};

export default ServicePPaMarginKpis;
