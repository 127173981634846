import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Slider from '@mui/material/Slider';
import { debounce, intlMessages } from 'services/util/auxiliaryUtils';
import { DEBOUNCE_TIME } from 'constants/endpoints';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';
import { Controller, useFormContext } from 'react-hook-form';
import validate from 'services/util/validate';
import NumberFormat from 'react-number-format';
import CustomUnit from 'components/util/CustomUnit';
import ErrorMessage from 'components/errors/ErrorMessage';

const MarginGoalSeek = () => {
    const { setBMEventHandler, bmState } = useContext(OfferEditionContext);

    const { loadings } = bmState;
    const {
        control,
        formState: { errors },
        setError,
        clearErrors,
    } = useFormContext();

    const _sliderValues = bmState?.kpis?.negotiation;

    const sliderStep = _sliderValues?.step ?? 0.01;
    const sliderDecimalScale = sliderStep?.toString()?.split('.')?.[1]?.length ?? sliderStep ?? 1;
    const minValueDecimal = _sliderValues?.min_monthly_fee?.toString()?.split('.')?.[1]?.length ?? null;
    const maxValueDecimal = _sliderValues?.max_monthly_fee?.toString()?.split('.')?.[1]?.length ?? null;

    // The min and max value are rounded to the same number of decimal places, but are rounded down
    // Example: 181.996 with 2 decimal places should be 181.99, because if it was 182 it would return no KPI values
    const minValue =
        sliderDecimalScale === minValueDecimal ?
            _sliderValues?.min_monthly_fee
        :   Math.floor(_sliderValues?.min_monthly_fee * 10 ** sliderDecimalScale) / 10 ** sliderDecimalScale;
    const maxValue =
        sliderDecimalScale === maxValueDecimal ?
            _sliderValues?.max_monthly_fee
        :   Math.floor(_sliderValues?.max_monthly_fee * 10 ** sliderDecimalScale) / 10 ** sliderDecimalScale;

    const showSlider = minValue !== maxValue;

    const [sliderValue, setSliderValue] = useState(_sliderValues?.add_on_helper_pre_selected ?? _sliderValues?.pre_selected);

    const handleSliderChange = useCallback((_, newValue) => {
        setSliderValue(newValue);
        clearErrors('margin-slider-input');
    }, []); // eslint-disable-line

    // eslint-disable-next-line
    const delayedNewMarginHandler = useCallback(
        debounce((payload) => setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_MARGIN, payload), DEBOUNCE_TIME),
        []
    ); // eslint-disable-line

    useEffect(() => {
        delayedNewMarginHandler(sliderValue);
    }, [sliderValue]); // eslint-disable-line

    return (
        <>
            <div className="margin-slider-container">
                {showSlider && (
                    <Slider
                        aria-label="margin-slider"
                        aria-labelledby="discrete-slider-restrict"
                        defaultValue={_sliderValues?.pre_selected}
                        min={minValue}
                        max={maxValue}
                        step={sliderStep}
                        valueLabelDisplay="off"
                        marks={[
                            { label: minValue, value: minValue },
                            { label: maxValue, value: maxValue },
                        ]}
                        onChange={handleSliderChange}
                        value={sliderValue}
                        disabled={loadings.general}
                    />
                )}
                <div className="margin-slider-container-controller">
                    <Controller
                        name="margin-slider-input"
                        rules={validate(['number', 'max', 'min', 'required'], { max: maxValue, min: minValue })}
                        control={control}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { ref, ...field } }) => {
                            return (
                                <NumberFormat
                                    {...field}
                                    defaultValue={sliderValue}
                                    value={sliderValue}
                                    decimalScale={sliderDecimalScale}
                                    placeholder={intlMessages('label.insertValue')}
                                    customInput={OutlinedInput}
                                    disabled={!showSlider || loadings.general}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {/* @ts-ignore */}
                                            <CustomUnit unit={'mc'} />
                                        </InputAdornment>
                                    }
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } = values;
                                        if (!!floatValue && floatValue < minValue) {
                                            setError('margin-slider-input', {
                                                type: 'manual',
                                                message: 'yup.message.number.min',
                                            });
                                        } else if (!!floatValue && floatValue > maxValue) {
                                            setError('margin-slider-input', {
                                                type: 'manual',
                                                message: 'yup.message.number.max',
                                            });
                                        } else {
                                            clearErrors('margin-slider-input');
                                        }
                                        return formattedValue === '' || (!!floatValue && floatValue <= maxValue);
                                    }}
                                    onValueChange={(values) => {
                                        setSliderValue(values.floatValue);
                                    }}
                                />
                            );
                        }}
                    />
                    {errors?.['margin-slider-input'] && (
                        <ErrorMessage
                            // @ts-ignore
                            error={errors['margin-slider-input']}
                            values={{
                                min: minValue,
                                max: maxValue,
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default MarginGoalSeek;
