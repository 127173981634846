import { StrictMode } from 'react';
import * as Sentry from '@sentry/react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import configureStore, { history } from './redux/store';
import App from './pages/App';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from 'components/errors/ErrorBoundary';
import { BASENAME_URL_HOME } from 'constants/settings';
import { SettingsProvider } from 'contexts/settingsContext';
import { useUserStore } from 'store/user';

export const { store, persistor } = configureStore();

const MainApp = () => (
    <StrictMode>
        <Sentry.ErrorBoundary
            fallback={({ resetError }) => <ErrorBoundary resetError={resetError} />}
            onReset={() => history.push(BASENAME_URL_HOME)}
            beforeCapture={(scope) => {
                const user = useUserStore.getState()?.user;
                scope.setTag('company_code', user?.company_code);
                scope.setTag('perfil_id', user?.perfil_empresa_id);
                scope.setTag('empresa_id', user?.empresa_id);
            }}
        >
            <Router>
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <ConnectedRouter history={history}>
                            <Switch>
                                <SettingsProvider>
                                    <Route path={`*`} component={App} />
                                </SettingsProvider>
                            </Switch>
                        </ConnectedRouter>
                    </PersistGate>
                </Provider>
            </Router>
        </Sentry.ErrorBoundary>
    </StrictMode>
);

export default Sentry.withProfiler(MainApp);
