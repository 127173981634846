import { useState, useRef, useEffect } from 'react';
import { intlMessages } from 'services/util/auxiliaryUtils';
// import { BusinessModelsProContext } from "contexts/businessModelsPro/businessModelsContext";
// import { IBusinessModelsContext, TProductInputs } from "interfaces/businessModels";
// @ts-ignore
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { TBMs } from "interfaces/businessModels/reducer";
import { IconButton } from '@save2compete/efz-design-system';

const Caroussel = ({ children }) => {
    // const {
    //     bmCards
    // } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TBMs>

    const carouselRef = useRef();
    const carousel = carouselRef.current;
    const [, /*slideIndex*/ setSlideIndex] = useState(0);
    const [, /*visibleSlides*/ setVisibleSlides] = useState(2);
    // const defaultValue = slideIndex;
    // const maxValue = bmCards.length - visibleSlides;
    // const widthValue = Math.ceil(100 / (maxValue + 1));

    // slick settings
    const carouselSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: (
            <IconButton
                variant="tertiary"
                icon="arrowHeadRight"
                size="bg"
                dataTestId="next-arrow"
                aria-label={`${intlMessages('label.next')}`}
            />
        ),
        prevArrow: (
            <IconButton
                variant="tertiary"
                icon="arrowHeadLeft"
                size="bg"
                dataTestId="prev-arrow"
                aria-label={`${intlMessages('label.previous')}`}
            />
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        className: 'business-models-cards-caroussel',
        beforeChange: (current, next) => setSlideIndex(next),
    };
    useEffect(() => {
        let slidesValue;
        if (carousel) {
            // @ts-ignore
            slidesValue = carousel?.innerSlider?.props?.slidesToShow;
        }
        const resizeHandler = () => {
            // @ts-ignore
            slidesValue = carousel?.innerSlider?.props?.slidesToShow;
            setVisibleSlides(slidesValue);
        };
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [carousel]);

    return (
        <>
            <Slider ref={carouselRef} {...carouselSettings}>
                {children}
            </Slider>
        </>
    );
};

export default Caroussel;
