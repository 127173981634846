//Libraries
import PropTypes from 'prop-types';
//Our Components
import { intlMessages } from 'services/util/auxiliaryUtils';
import IntlMessages from 'components/util/IntlMessages';
import { USERS_TYPE_ID } from 'constants/user';
import { getCompanyProfileIds } from 'services/user';
import Loading from 'components/core/Loading';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Autocomplete from '@mui/material/Autocomplete';
import { GA_LABEL_TOP_LEVEL_NAVIGATION, GA_TYPE_EVT } from 'constants/gAnalytics';
import { gAnalyticsEvt } from 'services/gAnalytics';
import { useSelector } from 'react-redux';
import { getClientList } from 'redux/selectors/client';

const SectionSearch = ({ handleChangeAutocomplete, searchData, userTypeID, companyProfileId, isB2C }) => {
    let searchHistory = useSelector((state) => getClientList(state?.client));
    const handleChange = (data) => {
        handleChangeAutocomplete(data);
    };

    return (
        <div>
            {userTypeID !== USERS_TYPE_ID.CLIENT && (
                <>
                    <div className={`header-search-card-text ms-3 mt-3`}>
                        <h5 className="mb-0">
                            <IntlMessages id="header.clientCard.search" />
                        </h5>
                    </div>
                    {/* autocomplete */}
                    <div className="autocomplete-header">
                        <Autocomplete
                            //open={true}
                            openOnFocus
                            PaperComponent="div"
                            disablePortal={true}
                            onChange={(_, valueSelected) => handleChange({ name: 'client', value: valueSelected })}
                            getOptionLabel={(option) => option?.['descricao'] ?? ''}
                            renderOption={(props, option) => {
                                let description =
                                    isB2C ? `${option?.['nome_proprio']}  ${option?.['apelido']}` : option?.['descricao'] ?? '';
                                let nipc = isB2C ? option?.['numero_fiscal'] : option?.['nipc'] ?? '';

                                return (
                                    <li {...props} key={`${option?.['descricao']}_${option?.['id']}`}>
                                        <div className="autocomplete-header-option-wrapper">
                                            <h5>{description ?? ''}</h5>
                                            <h6 className="mt-1">
                                                {nipc ?? ''}{' '}
                                                {[getCompanyProfileIds().EDP_ES, getCompanyProfileIds().EDP_PT].includes(
                                                    companyProfileId
                                                ) && `| ${option?.['segmento_descricao'] ?? ''}`}
                                            </h6>
                                        </div>
                                    </li>
                                );
                            }}
                            options={searchData?.list?.length > 0 ? searchData?.list : searchHistory}
                            filterOptions={(options) => (options?.length > 0 ? options : searchHistory)}
                            loading={searchData?.loader}
                            noOptionsText={
                                <IntlMessages
                                    id={`${[getCompanyProfileIds().EDP_ES].includes(companyProfileId) ? 'label.NoClients' : 'label.NoResults'}`}
                                />
                            }
                            popupIcon={null}
                            clearText={intlMessages('label.clear')}
                            loadingText={`${intlMessages('label.loading')}`}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            renderInput={({ InputLabelProps, InputProps, ...params }) => {
                                return (
                                    <OutlinedInput
                                        {...params}
                                        placeholder={intlMessages('header.searchClient.placeholder')}
                                        autoFocus={true}
                                        fullWidth
                                        onChange={(event) => handleChange({ name: `clientSearch`, value: event.target.value })}
                                        className="pe-3"
                                        ref={InputProps.ref}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {searchData?.loader ?
                                                    <Loading size={20} color="inherit" className="autocomplete-loader" />
                                                :   <></>}
                                                {!searchData?.loader && <i className="zmdi zmdi-search" />}
                                                {/* {InputProps.endAdornment} */}
                                            </InputAdornment>
                                        }
                                        onClick={() =>
                                            gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT_SEARCH_BAR, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION)
                                        }
                                    />
                                );
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

SectionSearch.propTypes = {
    handleChangeAutocomplete: PropTypes.func.isRequired,
    searchData: PropTypes.shape({
        loader: PropTypes.bool.isRequired,
        list: PropTypes.array.isRequired,
        history: PropTypes.array,
    }),
    userTypeID: PropTypes.number,
};
export default SectionSearch;
