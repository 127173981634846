export enum BmLoadings {
    General = 'general',
    SimpleSimulation = 'simpleSimulation',
    DetailedSimulation = 'detailedSimulation',
    AuditFilesDownload = 'auditFilesDownload',
    Proposal = 'proposal',
    GetProposalNoKpis = 'getProposalNoKpis',
    AddOnHelper = 'addOnHelper',
}

export enum BmDetailedActions {
    SIMPLE_SIMULATION = 'SIMPLE_SIMULATION',
    DETAILED_SIMULATION = 'DETAILED_SIMULATION',
    AUDIT = 'AUDIT',
    NOT_ELIGIBLE_AUDIT = 'NOT_ELIGIBLE_AUDIT',
    PROPOSAL = 'PROPOSAL',
}

export enum FixedInputOptions {
    DIRECT_PAYMENT = 'DIRECT_PAYMENT',
    OPERATION_PAYMENT = 'OPERATION_PAYMENT',
    LEASING_PAYMENT = 'LEASING_PAYMENT',
    LEASING_ALBA_PAYMENT = 'LEASING_ALBA_PAYMENT',
}
