import UpFrontMarginKpis from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront/CommercialMargin/MarginKpis';
import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront } from 'interfaces/businessModels/upfront';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';
import MarginUpFrontSimple from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront/CommercialMargin/Margin';

const CommercialMarginUpFront = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;
    return (
        <div className="bm-project-summary-card bm-project-summary-commercial-margin">
            <ProjectSummaryTitle label={bmSelected?.isDiscount ? 'label.comercialDiscount' : 'label.comercialMargin'} />
            <MarginUpFrontSimple />
            {isDefined(bmSelected?.values?.marginSelected) && <UpFrontMarginKpis />}
        </div>
    );
};

export default CommercialMarginUpFront;
