import { useState, memo, useRef, useEffect, useContext } from 'react';
import MarkerRoofIcon from 'assets/images/icons/svg/marker-roof.svg';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { DEFAULTS_PROPS_MAP, solarpvMapActions, STEPS_OPEN_AREA, STEPS_RESERVED_AREA } from 'constants/products/solarpvSimple';

interface ILocation {
    lat: number;
    lng: number;
    address: string;
}

function hasLocation(location: ILocation) {
    return isFieldDefined(location?.lat) && isFieldDefined(location?.lng);
}

interface MapProps extends google.maps.MapOptions {
    setMarkerLocation: (location: ILocation) => void;
    location: ILocation;
    locationAddress: {
        lat: number;
        lng: number;
        address: string;
    };
    style: { [key: string]: string };
    handleOnChangeMarkerRoof: (e: google.maps.MapMouseEvent, markerRoof: google.maps.Marker, map: google.maps.Map) => void;
    handleOnChangeMarkerAddress: (location: any, markerAddress: google.maps.Marker, map: google.maps.Map) => void;
    handleOnCreatePolygon: (centoid: google.maps.LatLng, map: google.maps.Map) => void;
    isDrawing: boolean;
}

export function getCentroid(polygon) {
    const bounds = new google.maps.LatLngBounds();
    polygon?.getPath().forEach((e) => bounds.extend(e));
    return bounds.getCenter();
}

let mainDrag: boolean = false;

function handleAddListenerPolygon(polygon: google.maps.Polygon, options: { mapDispatch: (type: string, payload: any) => void }) {
    const { mapDispatch } = options;
    //Quando começa a arrastar a zona
    polygon.addListener('dragstart', () => {
        mainDrag = true;
    });

    //Quando acaba de arrastar a zona
    polygon.addListener('dragend', () => {
        mainDrag = false;
    });

    //Quando é adicionado uma novo vertex no polygon selecionado
    google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
        mapDispatch(solarpvMapActions.EVT_INSERT_AT, {
            polygon,
        });
    });

    //Quando editamos o polygon (selecionado com o click do mouse)
    google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        if (!mainDrag) {
            mapDispatch(solarpvMapActions.EVT_SET_AT, {
                polygon,
            });
        }
    });

    //Quando criamos uma nova vertex e clicamos o undo "retroceder" no polygon selecionado
    google.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
        mapDispatch(solarpvMapActions.EVT_REMOVE_AT, {
            polygon,
        });
    });
}

const SolarpvSimpleMap: React.FC<MapProps> = ({
    style,
    handleOnChangeMarkerRoof,
    handleOnChangeMarkerAddress,
    handleOnCreatePolygon,
    ...options
}) => {
    const {
        locationRoof,
        spvSimpleState: { isOArea, step, inputs, isDetectRoof },
        mapDispatchHandler: mapDispatch,
    } = useContext(SPVSimpleContext);

    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [markerRoof, setMarkerRoof] = useState<google.maps.Marker>();

    useEffect(() => {
        //mounting
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, options));
        }
    }, [ref, map, setMap, options]);

    useEffect(() => {
        if (map) {
            if (map?.getZoom() !== DEFAULTS_PROPS_MAP.mapOptions.zoom) map.setMapTypeId('satellite');
            // mounting
            google.maps.event.addListenerOnce(map, 'idle', function () {
                let polygon = new google.maps.Polygon();
                const mainDrawingManager = new google.maps.drawing.DrawingManager({
                    drawingControl: false,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                    },
                    map,
                });

                if (step === STEPS_OPEN_AREA.ADDRESS && !isDetectRoof && inputs.roofCoordinates.length > 0) {
                    polygon = new google.maps.Polygon({
                        map,
                        editable: true,
                        draggable: true,
                        paths: [inputs.roofCoordinates],
                        strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.strokeOpacity,
                        strokeWeight: DEFAULTS_PROPS_MAP.polygon.styles.strokeWeight,
                        fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
                        fillColor: DEFAULTS_PROPS_MAP.polygon.styles.fillColor.selected,
                        strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected,
                        clickable: !isDetectRoof,
                    });

                    handleAddListenerPolygon(polygon, { mapDispatch });
                    // Center map on polygon
                    map.panTo(getCentroid(polygon));
                    map.setMapTypeId('satellite');
                }

                mapDispatch(solarpvMapActions.SET_INIT, {
                    mainDrawingManager,
                    mainGoogle: google,
                    mainMap: map,
                    polygon,
                    isDetectRoof,
                });

                const stepsToShowPolygon =
                    isOArea ?
                        [STEPS_OPEN_AREA.ROOF_DETAILS, STEPS_OPEN_AREA.SIMULATION]
                    :   [STEPS_RESERVED_AREA.ROOF_DETAILS, STEPS_RESERVED_AREA.SIMULATION];

                if (stepsToShowPolygon.includes(step) && inputs?.areas?.length > 0) {
                    const polygon = new google.maps.Polygon({
                        map,
                        zIndex: 1,
                        paths: [inputs?.roofCoordinates],
                        editable: false,
                        draggable: false,
                        strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.strokeOpacity,
                        strokeWeight: DEFAULTS_PROPS_MAP.polygon.styles.strokeWeight,
                        fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
                        fillColor: DEFAULTS_PROPS_MAP.polygon.styles.fillColor.selected,
                        strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected,
                        clickable: !isDetectRoof,
                    });
                    // Center map on polygon
                    map.panTo(getCentroid(polygon));
                }

                const gMarkerRoof = new google.maps.Marker({
                    // position: location
                    map,
                    // label: 'marker-roof',
                    draggable: true,
                    crossOnDrag: false,
                    icon: MarkerRoofIcon,
                    // title: `Location: ${location.address}`,
                    visible: false,
                });

                map?.addListener('click', (e: google.maps.MapMouseEvent) =>
                    mapDispatch(solarpvMapActions.EVT_CLICK_MAP, {
                        mapOptions: {
                            google: google,
                            map: map,
                            e: e,
                            mapDispatch: mapDispatch,
                            handleOnCreatePolygon: handleOnCreatePolygon,
                        },
                        makerHandler: () => handleOnChangeMarkerRoof(e, gMarkerRoof, map),
                    })
                );

                //listen to marker dragging
                gMarkerRoof?.addListener('dragend', (e: google.maps.MapMouseEvent) => handleOnChangeMarkerRoof(e, gMarkerRoof, map));

                setMarkerRoof(gMarkerRoof!);
            });
        }
    }, [map]); //eslint-disable-line

    useEffect(() => {
        //locationRoof
        if (hasLocation(locationRoof)) {
            //create markerRoof
            handleOnChangeMarkerAddress(locationRoof, markerRoof!, map!);
        }
    }, [locationRoof, map, markerRoof]); //eslint-disable-line

    useEffect(() => {
        if (isFieldDefined(markerRoof) && !isDetectRoof) {
            // don't show marker when the user needs to draw the roof manually
            markerRoof?.setMap(null);
        }
    }, [markerRoof, isDetectRoof]);

    return <div ref={ref} style={style} className="map-container" id="efz-map" />;
};

export default memo(SolarpvSimpleMap);
