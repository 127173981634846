import { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import copy from 'fast-copy';
import OutlinedInput from '@mui/material/OutlinedInput';

// Components
import IntlMessages from 'components/util/IntlMessages';
import ErrorMessage from 'components/errors/ErrorMessage';
import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';

// Constants
import { INPUT_NAMES_CONTACT_INFO, QUESTIONS_CONTACT_INFO } from 'constants/businessModels';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TServiceV2 } from 'interfaces/businessModels/serviceV2';

// Services
import { intlMessages } from 'services/util/auxiliaryUtils';
const ContactInfoLEC = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TServiceV2>;

    const {
        control,
        formState: { errors },
        setValue,
    } = useFormContext();

    const questions = copy(QUESTIONS_CONTACT_INFO);

    useEffect(() => {
        setValue('contact_info.name', bmSelected?.values?.clientContactInfo?.name ?? '');
        setValue('contact_info.email', bmSelected?.values?.clientContactInfo?.email ?? '');
    }, [bmSelected]); //eslint-disable-line

    return (
        <div className="bm-project-summary-card bm-project-summary-contact-info">
            <ProjectSummaryTitle label={'label.additionalData'} />

            <div className="info-details">
                {/* CONTACT_INFO (both) */}
                {questions[INPUT_NAMES_CONTACT_INFO.CONTACT_INFO].options.map((option, index) => (
                    <div key={index} className="m-0 p-0">
                        <label className="row m-0 mb-2">
                            <IntlMessages id={option.label} />
                        </label>
                        <Controller
                            // disabled={isRequesting}
                            name={`${INPUT_NAMES_CONTACT_INFO.CONTACT_INFO}.${option.name}`}
                            control={control}
                            // type={questions[INPUT_NAMES_CONTACT_INFO.CONTACT_INFO].inputType}
                            rules={questions?.[INPUT_NAMES_CONTACT_INFO.CONTACT_INFO]?.validation?.[option.name] ?? {}}
                            render={({ field: { onChange, value } }) => (
                                <OutlinedInput
                                    value={value}
                                    type={option.type}
                                    error={errors?.[INPUT_NAMES_CONTACT_INFO.CONTACT_INFO]?.[option.name]}
                                    // disabled={isRequesting}
                                    onChange={(value) => onChange(value)}
                                    placeholder={intlMessages(option?.placeholder)}
                                />
                            )}
                        />
                        {!!errors?.[INPUT_NAMES_CONTACT_INFO.CONTACT_INFO]?.[option.name] && (
                            <ErrorMessage error={errors?.[INPUT_NAMES_CONTACT_INFO.CONTACT_INFO]?.[option.name]} />
                        )}
                    </div>
                ))}
                {/* IDSalesforce */}
                <div key={'IDSalesforce'} className="m-0 p-0">
                    <label className="row m-0 mb-2">
                        <IntlMessages id={'label.IDSalesforce'} />
                    </label>
                    <Controller
                        // disabled={isRequesting}
                        name={`id_crm`}
                        control={control}
                        rules={questions?.[INPUT_NAMES_CONTACT_INFO.CONTACT_INFO]?.validation?.IDSalesforce ?? {}}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedInput
                                value={value}
                                error={!!errors?.id_crm}
                                // disabled={isRequesting}
                                type={'text'}
                                placeholder={intlMessages('placeholder.Id')}
                                onChange={(value) => onChange(value)}
                            />
                        )}
                    />
                    {!!errors?.id_crm && (
                        <ErrorMessage
                            /* @ts-ignore */
                            error={errors?.id_crm}
                            values={{ min: 18, max: 18 }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContactInfoLEC;
