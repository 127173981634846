import { useContext } from 'react';
import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { paPKpis } from 'services/businessModels/papKpis';
import { TIntl } from 'types/utils';

const MarginKpisPaPDetailed = () => {
    const { bmState, companyProfileId } = useContext(OfferEditionContext);

    const kpis = bmState?.kpis?.kpis;
    const monthlyPayment = bmState?.offerEditionValues?.monthlyPayment;
    const defaultTariff = bmState?.kpis?.negotiation?.default;

    const kpisToShow = paPKpis(kpis, monthlyPayment, defaultTariff, companyProfileId);
    return (
        <div className="bm-detailed-negotiation-kpis">
            <div className="kpis-table">
                {kpisToShow.map((kpi, idx) => {
                    return (
                        <>
                            <div className="kpi">
                                <div className="label">
                                    <IntlMessages id={kpi.label as TIntl} />
                                </div>
                                <div className="value">
                                    {/* @ts-ignore */}
                                    <FormatNumber number={kpi.value} numberOfDecimalPlaces={kpi.numberOfDecimals} unit={kpi.unit ?? null} />
                                </div>
                            </div>
                            {idx !== kpisToShow.length - 1 && <div className="divider" />}
                        </>
                    );
                })}
            </div>
        </div>
    );
};

export default MarginKpisPaPDetailed;
