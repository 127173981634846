import { isDefined, isExistWord } from 'services/util/auxiliaryUtils';
import { TProductInputs } from './../../interfaces/businessModels/index';
import { TBMs } from './../../interfaces/businessModels/reducer';
import { INITIAL_BUSINESS_MODELS } from 'constants/businessModelsPro';
import { BusinessModelsActions, IBusinessModelsAction, IBusinessModelsState } from 'interfaces/businessModels/reducer';
// import { computeRubricCostsPayload } from 'services/businessModelss';

export const initiaBusinessModelslState: IBusinessModelsState<TProductInputs, null> = {
    businessModels: [],
    simulation: INITIAL_BUSINESS_MODELS,
    // @ts-ignore
    selected: {
        id: undefined,
        paymentModelID: null,
        values: null,
        options: null,
        isDiscount: false,
    },
    proposal: null,
};

export const businessModelReducer = (
    state: IBusinessModelsState<TProductInputs, TBMs>,
    action: IBusinessModelsAction
): IBusinessModelsState<TProductInputs, TBMs> => {
    // console.log('efz->  businessModelReducer action.type', action.type);
    switch (action.type) {
        case BusinessModelsActions.SET_INITIAL_PAYLOAD:
            return {
                ...state,
                simulation: action.payload,
            };

        case BusinessModelsActions.SET_BUSINESS_MODELS:
            return {
                ...state,
                businessModels: action.payload,
                // @ts-ignore
                selected: {
                    id: undefined,
                    paymentModelID: null,
                    values: null,
                    options: null,
                    isDiscount: null,
                },
            };

        case BusinessModelsActions.SELECT_BUSINESS_MODEL: {
            const newBmState = state.businessModels.map((bm) => {
                return { ...bm, selected: bm.payment_model_id === action.payload ? true : false };
            });

            return {
                ...state,
                businessModels: newBmState,
                // @ts-ignore
                selected: {
                    id: state.businessModels.find((bm) => bm.payment_model_id === action.payload)?.business_model_id,
                    paymentModelID: action.payload,
                    values: null,
                    options: newBmState.find((bm) => bm.selected) ?? null,
                    isDiscount: null,
                },
            };
        }

        case BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    values: {
                        ...state.selected?.values,
                        ...action.payload,
                    },
                    elements: {
                        ...state.selected?.elements,
                        ...(action.payload?.elements ?? {}),
                    },
                    isDiscount: isExistWord(action.payload?.margin_negotiation?.search, 'discount'),
                },
            };
        }
        case BusinessModelsActions.SET_PROPOSAL:
            return {
                ...state,
                proposal: {
                    ...action.payload,
                },
            };
        case BusinessModelsActions.SET_BUSINESS_MODEL_BY_NEW_COST: {
            const { offerDetailsCost, options, newOfferEdition, newInstallationPrices, omCost } = action.payload;

            const { kpis_per_margin } = options.kpis_previews[0];

            const {
                selected: {
                    values: {
                        // @ts-ignorex
                        margin_negotiation,
                        // @ts-ignore
                        marginSelected,
                    },
                },
            } = state;

            const newRubrics = {
                // @ts-ignore
                ...(state?.selected?.values?.rubrics ?? {}),
                values:
                    action?.payload?.newRubrics?.length > 0 ?
                        action?.payload?.newRubrics
                    :   // @ts-ignore
                        state?.selected?.values?.rubrics?.values,
                // @ts-ignore
                cost:
                    action?.payload?.newRubrics?.length > 0 ?
                        action?.payload?.newRubricsCost
                    :   // @ts-ignore
                        state?.selected?.values?.rubrics?.cost,
            };

            const kpisPerMarginSelected = kpis_per_margin.find((el) => el?.[margin_negotiation?.search] === marginSelected);

            return {
                ...state,
                selected: {
                    ...state.selected,
                    options,
                    // @ts-ignore
                    values: {
                        ...state.selected?.values,
                        costs: {
                            // @ts-ignore
                            ...state.selected?.values?.costs, // @ts-ignore
                            baseCost: state.selected.values.costs.baseCost,
                            marginCost: kpisPerMarginSelected?.investment ?? kpisPerMarginSelected?.price,
                            offerDetailsCost: offerDetailsCost /* base+rubrics+custosextras */,
                            totalCost: offerDetailsCost /* total de todos os custos de todos os cartoes */,
                            // @ts-ignore
                            omDurationCost: isDefined(omCost) ? omCost.new : state.selected?.values?.costs?.omDurationCost ?? 0,
                        },
                        kpis_per_margin,
                        rubrics: {
                            ...newRubrics,
                        },
                        offerEdition: {
                            // @ts-ignore
                            ...state.selected?.values.offerEdition,
                            ...newOfferEdition,
                        },
                        ...newInstallationPrices,
                    },
                },
                simulation: {
                    ...state.simulation,
                },
            };
        }

        default:
            return state;
    }
};
