/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Validação do Routeamento e acessos ao Container no contexto BusinessModels
 *
 * @version 20191120
 * @since 20191113 Initial release
 *
 */

import { lazy, useContext } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Error403 from 'components/errors/Error403';
import PropTypes from 'prop-types';
//selectors
import { getFacilityID } from 'redux/selectors/facility';
import { getProductId } from 'redux/selectors/product';
import { BASENAME_URL_APP } from 'constants/settings';
import lazyLoader from 'services/lazyLoader';
import { withTracker } from 'services/withTracker';
import { OfferEditionProvider } from 'contexts/businessModels/businessModelsContext';
import { businessModelProAccess } from 'services/businessModels';
import { UserContext } from 'contexts/userContext';

const BusinessModelsContainer = lazy(() => lazyLoader(() => import('pages/Restricted/BusinessModels')));
const BusinessModelsProContainer = lazy(() => lazyLoader(() => import('pages/Restricted/BusinessModelsPro')));
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */

/* Para aceder ao Container BusinessModels é necesário que exista um facilityID (props.facilityID) e um productID (props.productID),
caso contrário o utilizador é reecaminhado para um erro 403. */

const BusinessModels = (props) => {
    const { companyProfileId } = useContext(UserContext);
    const hasbmProAccess = businessModelProAccess({ productID: props.productID, companyProfileId });

    const hasAccess = !!props.facilityID && !!props.productID;
    return (
        hasAccess ?
            hasbmProAccess ? <BusinessModelsProContainer />
            :   <OfferEditionProvider>
                    <BusinessModelsContainer />
                </OfferEditionProvider>
        :   <Error403
                props={{
                    url: BASENAME_URL_APP + 'solutions',
                    buttonText: 'page.404.button.goSolutions',
                    message: 'page.error.403.BusinessModels',
                }}
            />
    );
};

/* PropTypes */

BusinessModels.propTypes = {
    facilityID: PropTypes.string.isRequired,
    productID: PropTypes.number.isRequired,
};

const mapStateToProps = ({ facility, product }) => {
    return { facilityID: getFacilityID(facility), productID: getProductId(product) };
};
export default withRouter(connect(mapStateToProps)(withTracker(BusinessModels, 'Business Models')));
