import { useContext, useMemo } from "react"
import { useFormContext } from "react-hook-form"

// Components
import Alert from "components/@efz/Alert"
import IntlMessages from "components/util/IntlMessages"
import FormInput from "components/Products/EVCPro/Form/FormInput"

// Constants
import { INPUT_NAMES_INSTALLATION_DETAILS, SECTION_NAMES } from "constants/products/evcPro"

// Context
import { EVCProContext } from "contexts/products/evcPro/evcProContext"
import { UserContext } from "contexts/userContext"

// Interfaces
import { IEVCProInputsCharging_OnCompany_Public } from "interfaces/products/evcPro"

// Services
import { getFormInputName } from "services/products/evcPro";
import { getCompanyProfileIds } from "services/user"

export const InfoOPCSharing = (
    <div className="container">
        <div className="container-inner left">
            <span className="title"><IntlMessages id='page.evc.question.sharing_model_id.Id2.info1' /></span>
            <p>10%</p>
            <p>35%</p>
            <p>55%</p>
        </div>
        <div className="container-inner right">
            <span className="title"><IntlMessages id='page.evc.question.sharing_model_id.Id2.info2' /></span>
            <p>T &#60;&#61; 20%</p>
            <p>20% &#60; T &#60;&#61; 30%</p>
            <p>T &#62; 30%</p>
        </div>
    </div>
)

const InstallationDetailsOnCompanyPublicPartnership = ({ locked = false }) => {
    const {
        companyName,
        companyProfileId
    } = useContext(UserContext)

    const {
        questions,
    } = useContext(EVCProContext)

    const {
        watch,
    } = useFormContext<IEVCProInputsCharging_OnCompany_Public>()

    const wants_partnership = watch(getFormInputName<IEVCProInputsCharging_OnCompany_Public>(INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership)) as any as boolean

    const isSharingModelVisible = useMemo(() => !!wants_partnership && wants_partnership === true, [wants_partnership])

    return (
        <>
            <Alert severity='info'>
                <IntlMessages id={'page.evc.question.info.establishmentMeetsRequirements'}
                    values={{ companyName: `${companyName}${[getCompanyProfileIds().EDP_PT].includes(companyProfileId) ? ' Comercial' : ''}` }} />
            </Alert>

            <FormInput<typeof INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership>
                question={{
                    ...questions?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership],
                    label: {
                        label: typeof questions?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership].label === 'string'
                            ? questions?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership].label
                            : questions?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership].label?.label,
                        values: {
                            ...questions?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership].label?.values,
                            companyName: `${companyName}${[getCompanyProfileIds().EDP_PT].includes(companyProfileId) ? ' Comercial' : ''}`
                        }
                    },
                    tooltip: {
                        label: {
                            label: typeof questions?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership].tooltip === 'string'
                                ? questions?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership].tooltip
                                : questions?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership].tooltip?.label,
                            values: {
                                ...questions?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.wants_partnership].tooltip?.values,
                                companyName: `${companyName}${[getCompanyProfileIds().EDP_PT].includes(companyProfileId) ? ' Comercial' : ''}`
                            }
                        }
                    },
                }}
                className="input-container-2"
                disabled={locked}
            />

            {  //TODO: FIXME: Talvez passar isto para um novo fluxo estilo "is_partnership" e colocar na visibilidade das questions
                isSharingModelVisible ?
                    <FormInput<typeof INPUT_NAMES_INSTALLATION_DETAILS.sharing_model_id>
                        question={questions?.[SECTION_NAMES.INSTALLATION_DETAILS]?.[INPUT_NAMES_INSTALLATION_DETAILS.sharing_model_id]}
                        className="input-container-2"
                        disabled={locked}
                    /> : <></>
            }
        </>
    )
}

export default InstallationDetailsOnCompanyPublicPartnership