import clone from 'fast-copy';
import validate from 'services/util/validate';
import _sortby from 'lodash.sortby';
import {
    getParseNumber,
    isDefined,
    isEmptyArray,
    isFieldDefined,
    visibleSections,
    convertToUnit,
    isBoolean,
    removeFieldEmptyInObj,
    calcConvertionToUnit,
    parseNumberWithToFixed,
    removePunctuation,
} from 'services/util/auxiliaryUtils';
import { USERS_TYPE_ID, GRUPO_CANAL_IDS, GRUPO_CANAL_IDS_PREFIXOS } from 'constants/user';
import { isUserChannel, hasCompanyProfileId, getCompanyProfileIds } from 'services/user';
import {
    QUESTIONS_FACILITY,
    FACILITY_QUESTIONS_SECTIONS,
    OPTIONS_LOAD_CURVE,
    DAYS_OF_WORK,
    NUMBER_DECIMALS_TARIFFS,
    CONTRACTED_POWER_LIMITS,
    ANNUAL_CONSUMPTION_LIMITS,
    CAE_LENGTH_LIMITS,
    VALID_CAES,
    AVAILABLE_LINKS_CAE,
    QUESTIONS_FACILITY_B2C,
} from 'constants/facility';
import { INPUT_NAMES } from 'constants/facility';
import { channelGroupRules } from 'services/user';
import { companiesWithDetailledAddress } from 'services/client';
import escape from 'sql-escape';

export function getInputs(inputs) {
    const {
        cpe,
        descricao,
        supplied_by_company,
        morada,
        tipo_instalacao_id,
        data_idade_resposta_cliente /*  */,
        municipio_id,
        municipio_descricao,
        nuts_i_id,
        localidade,
        location,
        codigo_postal,
        num_trabalhadores,
        area,
        area_coberta,
        area_util,
        working_days,
        trabalha_sab,
        trabalha_dom,
        trabalha_feriado,
        horario_dias_semana,
        horario_sabado,
        horario_domingo_e_feriado,
        consumo_anual,
        consumo_anual_mwh,
        total_tariffs,
        energy_tariffs,
        gas_supplied_by_company,
        tarifa_media_gas,
        consumo_anual_gas,
        tarifa_fixa_gas,
        nivel_tensao_id,
        tensao_conexao_id,
        num_fases,
        potencia_contratada,
        potencia_ponta,
        potencia_requisitada,
        fornecedor_gas_id,
        distribuidor_id,
        opcao_tarifario_id,
        fonte_tarifa_redes_id,
        opcao_curva_carga_id,
        opcao_horario_id,
        facilityWeights,
        facilityEstimates,
        facilityGraphDaily,
        facilityGraphMonthly,
        horario_tem_impacto,
        tem_telecontagem,
        tem_dados_integracao_reais,
        tem_dados_integracao_estatisticos,
        tipo_ciclo_id,
        adjust_work_days,
        tem_dados_ponderador_estimativa_simples,
        tem_dados_ponderador_integracao_personalizada,
        nome_ficheiro_curva_carga,
        classe_id,
        disabledIntegrationData,
        power_fee,
        tarifa_kw_dia,
        tem_dados_ponderador_estimativa_simples_por_periodo,
    } = inputs;

    // workingDays
    let _notWorkingDays = notWorkingDays(inputs);

    return {
        cpe,
        descricao,
        supplied_by_company,
        morada: location ? location?.street : morada,
        numero: location?.number,
        complemento: location?.complement,
        codigo_postal: location ? location?.postal_code : codigo_postal,
        bairro: location?.district,
        localidade: location ? location?.city : localidade,
        nuts_i_id: location ? location?.nuts_i_id : nuts_i_id,
        municipio_id: location ? location?.county_id : municipio_id,
        municipio_descricao: location ? location?.county_description : municipio_descricao,
        tipo_instalacao_id: tipo_instalacao_id ?? '',
        data_idade_resposta_cliente,
        num_trabalhadores: isFieldDefined(num_trabalhadores) ? parseInt(num_trabalhadores) : 0,
        area,
        area_coberta,
        area_util,
        trabalha_2a: isFieldDefined(working_days) ? working_days : !_notWorkingDays,
        trabalha_3a: isFieldDefined(working_days) ? working_days : !_notWorkingDays,
        trabalha_4a: isFieldDefined(working_days) ? working_days : !_notWorkingDays,
        trabalha_5a: isFieldDefined(working_days) ? working_days : !_notWorkingDays,
        trabalha_6a: isFieldDefined(working_days) ? working_days : !_notWorkingDays,
        working_days: isFieldDefined(working_days) ? working_days : !_notWorkingDays,
        trabalha_sab: isFieldDefined(trabalha_sab) ? trabalha_sab : false,
        trabalha_dom: isFieldDefined(trabalha_dom) ? trabalha_dom : false,
        trabalha_feriado: isFieldDefined(trabalha_feriado) ? trabalha_feriado : false,
        horario_dias_semana,
        horario_sabado,
        horario_domingo_e_feriado,
        consumo_anual,
        consumo_anual_mwh,
        energy_tariffs,
        total_tariffs,
        gas_supplied_by_company,
        tarifa_media_gas,
        consumo_anual_gas,
        tarifa_fixa_gas,
        nivel_tensao_id: isFieldDefined(nivel_tensao_id) ? parseInt(nivel_tensao_id) : '',
        tensao_conexao_id: isFieldDefined(tensao_conexao_id) ? parseInt(tensao_conexao_id) : '',
        num_fases: parseInt(num_fases),
        potencia_contratada: parseFloat(potencia_contratada),
        potencia_ponta: parseFloat(potencia_ponta),
        potencia_requisitada: parseFloat(potencia_requisitada),
        fornecedor_gas_id,
        distribuidor_id: parseInt(distribuidor_id),
        opcao_tarifario_id: parseInt(opcao_tarifario_id),
        opcao_curva_carga_id,
        opcao_horario_id: parseInt(opcao_horario_id),
        fonte_tarifa_redes_id: parseInt(fonte_tarifa_redes_id),
        consumption_weights: !!facilityWeights && facilityWeights.length > 0 ? facilityWeights : facilityEstimates,
        facilityGraphDaily,
        facilityGraphMonthly,
        horario_tem_impacto,
        tem_telecontagem,
        tem_dados_integracao_reais,
        tem_dados_integracao_estatisticos,
        tipo_ciclo_id: parseInt(tipo_ciclo_id),
        adjust_work_days,
        tem_dados_ponderador_estimativa_simples,
        tem_dados_ponderador_estimativa_simples_por_periodo,
        tem_dados_ponderador_integracao_personalizada,
        nome_ficheiro_curva_carga,
        classe_id,
        disabledIntegrationData,
        power_fee: parseFloat(power_fee) * 1000, // Convert to currency/MWh
        tarifa_kw_dia,
    };
}

export const notWorkingDays = (inputs) => {
    const { trabalha_2a, trabalha_3a, trabalha_4a, trabalha_5a, trabalha_6a } = inputs;
    return (
        !(isFieldDefined(trabalha_2a) ? trabalha_2a : false) &&
        !(isFieldDefined(trabalha_3a) ? trabalha_3a : false) &&
        !(isFieldDefined(trabalha_4a) ? trabalha_4a : false) &&
        !(isFieldDefined(trabalha_5a) ? trabalha_5a : false) &&
        !(isFieldDefined(trabalha_6a) ? trabalha_6a : false)
    );
};

/**
 * calcMonthlyConsumption
 * @param {*} weightValue       //in Weight
 * @param {*} weightSum         //in Weight
 * @param {*} annualConsumption //in KWh
 */
export const calcMonthlyConsumption = (weightValue, weightSum, annualConsumption) => {
    annualConsumption = !annualConsumption && annualConsumption === -1 ? 0 : annualConsumption;

    return (weightValue / weightSum) * convertToUnit(annualConsumption, 'fromKwToMw');
};
/**
 * calcWeightConsumption
 * @param {*} mensalConsumption // in MWh
 * @param {*} weightSum         // in Weight
 * @param {*} annualConsumption // in Mwh
 */
export const calcWeightConsumption = (mensalConsumption, weightSum, annualConsumption) => {
    annualConsumption = !annualConsumption && annualConsumption === -1 ? 0 : annualConsumption;

    return (mensalConsumption * weightSum) / annualConsumption;
};

export const calcWeightConsumptionByCustomByPeriod = (tariffPeriods, facilityConsumptionWeights, annualConsumption_mwh) => {
    let cTariffPeriods = clone(tariffPeriods);
    let annualConsumption_kwh = convertToUnit(annualConsumption_mwh, 'fromMwToKw');

    let weightsSum = 0;
    let consumptionWeights = clone(facilityConsumptionWeights);

    if (consumptionWeights?.length === 0) {
        //create default array
        // let defaultWeight = (annualConsumption_kwh / numPeriodsAvailable);
        for (let i = 0; i < cTariffPeriods.length; i++) {
            cTariffPeriods[i].weight = 0;
            cTariffPeriods[i].monthly_consumption_by_period_mwh = 0;
            cTariffPeriods[i].monthly_consumption_by_period_kwh = 0;
        }
    } else {
        for (let i = 0; i < cTariffPeriods.length; i++) {
            let period = cTariffPeriods[i];
            let weight =
                cTariffPeriods[i].exists ?
                    consumptionWeights.find((item) => item.period_id === period.period_id && item.month === period.month)?.weight
                :   0;
            cTariffPeriods[i].weight = weight;
        }

        //Constant Weight Sum
        weightsSum = consumptionWeights.reduce((accumulator, currentValue) => ({ weight: accumulator.weight + currentValue.weight }), {
            weight: 0,
        }).weight;

        for (let i = 0; i < cTariffPeriods.length; i++) {
            let monthly_consumption_by_period_kwh = (cTariffPeriods[i].weight / weightsSum) * annualConsumption_kwh;
            cTariffPeriods[i].monthly_consumption_by_period_mwh = convertToUnit(monthly_consumption_by_period_kwh, 'fromKwToMw');
            cTariffPeriods[i].monthly_consumption_by_period_kwh = monthly_consumption_by_period_kwh;
        }
    }

    return cTariffPeriods;
};

/**
 * addWeightConsumptionArray
 * @param {*} facilityConsumptionValues
 * @param {*} facilityWeightsSum
 */
export const calcWeightConsumptionArray = (facilityConsumptionValues, facilityWeightsSum) => {
    //
    if (!!facilityConsumptionValues && Array.isArray(facilityConsumptionValues)) {
        let array = clone(facilityConsumptionValues);

        let annualConsumption = array.reduce(
            (accumulator, currentValue) => ({
                monthly_consumption: accumulator.monthly_consumption + currentValue.monthly_consumption,
            }),
            { monthly_consumption: 0 }
        ).monthly_consumption;

        //Make necessary changes
        array = array.map((item, index) => {
            //Mudar para service para manter em concordância
            let weight = calcWeightConsumption(item.monthly_consumption, facilityWeightsSum, annualConsumption);
            return {
                ...item,
                month: index + 1,
                weight,
            };
        });

        return {
            facilityWeightsSum,
            annualConsumption,
            facilityMonthlyWeight: array,
        };
    }
    return null;
};

export const calcWeightsSumAndMonthlyConsumption = (facilityConsumptionWeights, annualConsumption) => {
    if (!Array.isArray(facilityConsumptionWeights)) return null;

    let consumptionWeights = clone(facilityConsumptionWeights);

    if (consumptionWeights.length === 0) {
        //create default array
        for (let month = 1; month <= 12; month++) {
            consumptionWeights.push({ month, weight: 0 });
        }
    }

    //Constant Weight Sum
    let weightsSum = consumptionWeights.reduce((accumulator, currentValue) => ({ weight: accumulator.weight + currentValue.weight }), {
        weight: 0,
    }).weight;

    consumptionWeights = consumptionWeights.map((item) => {
        //Mudar para service para manter em concordância
        let monthly_consumption = calcMonthlyConsumption(item.weight, weightsSum, annualConsumption);

        return {
            ...item,
            monthly_consumption: monthly_consumption,
            monthly_consumption_kwh: convertToUnit(monthly_consumption, 'fromMwToKw'),
        };
    });

    return {
        facilityWeightsSum: weightsSum,
        facilityMonthlyConsumption: consumptionWeights,
        annualConsumption,
    };
};

export function conversionTariffGasMeanMWh(inputs) {
    //** gasSection **\\
    //consumo_anual_gas_mwh
    if (!isDefined(inputs.consumo_anual_gas_mwh)) inputs.consumo_anual_gas_mwh = getParseNumber(inputs.consumo_anual_gas / 1000);
    inputs.consumo_anual_gas_mwh = getParseNumber(inputs.consumo_anual_gas_mwh); //parseNumber
    inputs.consumo_anual_gas = inputs.consumo_anual_gas_mwh * 1000; //update field

    //tarifa_media_gas_mwh
    if (!isDefined(inputs.tarifa_media_gas_mwh)) inputs.tarifa_media_gas_mwh = getParseNumber(inputs.tarifa_media_gas * 1000);
    inputs.tarifa_media_gas_mwh = getParseNumber(inputs.tarifa_media_gas_mwh); //parseNumber
    inputs.tarifa_media_gas = inputs.tarifa_media_gas_mwh / 1000; //update field

    //tarifa_fixa_gas_mwh
    if (!isDefined(inputs.tarifa_fixa_gas_mwh)) inputs.tarifa_fixa_gas_mwh = getParseNumber(inputs.tarifa_fixa_gas / 1000);
    inputs.tarifa_fixa_gas_mwh = getParseNumber(inputs.tarifa_fixa_gas_mwh); //parseNumber
    inputs.tarifa_fixa_gas = inputs.tarifa_fixa_gas_mwh * 1000; //update field

    return inputs;
}

/**
 * buildInputs
 */
function buildInputs(inputs, options) {
    const { companyProfileId, gridTariffs, tariffOptions, energyTariffs, clientICMS } = options;

    let _inputs = getInputs(inputs);
    _inputs = conversionTariffGasMeanMWh(_inputs);

    //para BR é necessario especificar ENERGY_TARIFFS
    if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId)) _inputs[INPUT_NAMES.ENERGY_TARIFFS] = energyTariffs;
    let detailed_tariffs = getDetailedTariffs(
        companyProfileId,
        parseInt(_inputs[INPUT_NAMES.DISTRIBUIDOR_ID]),
        parseInt(_inputs[INPUT_NAMES.OPCAO_TARIFARIO_ID]),
        _inputs[INPUT_NAMES.ENERGY_TARIFFS],
        _inputs[INPUT_NAMES.TOTAL_TARIFFS],
        gridTariffs,
        tariffOptions,
        {
            [INPUT_NAMES.POTENCIA_CONTRATADA]: getParseNumber(inputs[INPUT_NAMES.POTENCIA_CONTRATADA], null, 4),
            [INPUT_NAMES.NIVEL_TENSAO_ID]: parseInt(inputs[INPUT_NAMES.NIVEL_TENSAO_ID]),
            [INPUT_NAMES.CLASSE_ID]: parseInt(inputs[INPUT_NAMES.CLASSE_ID]),
            [INPUT_NAMES.OPCAO_HORARIO_ID]: parseInt(inputs[INPUT_NAMES.OPCAO_HORARIO_ID]),
            clientICMS,
        }
    );

    //#region SUPPLIED_BY_COMPANY
    let supplier_id = '';
    if (
        [
            getCompanyProfileIds().ESB,
            getCompanyProfileIds().NRG,
            getCompanyProfileIds().EDP_BR,
            getCompanyProfileIds().EDP_IT,
            getCompanyProfileIds().EDP_PL,
            getCompanyProfileIds().EFZ,
            getCompanyProfileIds().TELEFONICA,
        ].includes(companyProfileId)
    ) {
        if (options?.suppliers?.find((opt) => parseInt(opt.id) === parseInt(inputs[INPUT_NAMES.FORNECEDOR_ID]))) {
            supplier_id = inputs[INPUT_NAMES.FORNECEDOR_ID];
        }
    } else {
        supplier_id = inputs[INPUT_NAMES.SUPPLIED_BY_COMPANY];
    }
    //#endregion SUPPLIED_BY_COMPANY

    return {
        ..._inputs,
        [INPUT_NAMES.DETAILED_TARIFFS]: detailed_tariffs,
        //para ESB deve-se usar o supplier associado
        [INPUT_NAMES.SUPPLIED_BY_COMPANY]: supplier_id,
    };
}

/**
 * buildQuestions
 */
function buildQuestions(inputs, options) {
    const {
        facilityTypes,
        userTypeID,
        handleChangeLoadCurve,
        companyProfileId,
        voltageLevels,
        conectionVoltages,
        distributors,
        tariffOptions,
        suppliers,
        isCreateFacility,
        userChannelIDGroup,
        nuts,
        classes,
        hourlyOptions,
        gridTariffSources,
        segmentID,
        detailedAddress,
    } = options;

    const questions = clone(QUESTIONS_FACILITY);

    const _isUserChannel = isUserChannel(userTypeID);
    const _isUserChannelGroup = channelGroupRules(userChannelIDGroup)?.isChannelGroup;
    const hasSupplierGas = !!inputs?.[INPUT_NAMES.FORNECEDOR_GAS_ID];
    const ruleDisableEditChannelPT =
        [getCompanyProfileIds().EDP_PT].includes(companyProfileId) && _isUserChannel && parseInt(segmentID) === 7;

    /* ***** facilitySection ***** */
    //CPE
    if (isCreateFacility) {
        questions[INPUT_NAMES.CPE].disabled = false;
    } else {
        //View/Edit
        questions[INPUT_NAMES.CPE].disabled = ![getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_PL].includes(companyProfileId);
    }

    // METER_ID

    //DESCRICAO
    if (isCreateFacility) {
        questions[INPUT_NAMES.DESCRICAO].disabled = false;
    }

    // TIPO_INSTALACAO_ID
    if (isCreateFacility) {
        questions[INPUT_NAMES.TIPO_INSTALACAO_ID].validation = validate(['required']);
    }
    questions[INPUT_NAMES.TIPO_INSTALACAO_ID].options = facilityTypes ?? [];

    // MORADA

    // NUTS
    questions[INPUT_NAMES.NUT].visible = [getCompanyProfileIds().EDP_BR].includes(companyProfileId);
    questions[INPUT_NAMES.NUT].options = nuts ?? [];

    //LOCALIDADE
    questions[INPUT_NAMES.LOCALIDADE].visible = ![getCompanyProfileIds().EDP_BR].includes(companyProfileId);

    //CODIGO_POSTAL
    if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId)) {
        questions[INPUT_NAMES.CODIGO_POSTAL].validation = [
            validate(['required', 'maxLength', 'minLength'], { minLength: 9, maxLength: 9 }),
            validate(['required', 'maxLength', 'minLength'], { minLength: 8, maxLength: 8 }),
        ];
        questions[INPUT_NAMES.CODIGO_POSTAL].optValidation = { minLength: 8, maxLength: 8 };
        questions[INPUT_NAMES.CODIGO_POSTAL].mask = '99999-999';
    }

    // MUNICIPIO_DESCRICAO

    if (!!detailedAddress || (isCreateFacility && companiesWithDetailledAddress(companyProfileId))) {
        questions[INPUT_NAMES.NUMERO].visible = true;

        questions[INPUT_NAMES.COMPLEMENTO].visible = true;

        questions[INPUT_NAMES.BAIRRO].visible = true;

        questions[INPUT_NAMES.LOCALIDADE].visible = true;
        questions[INPUT_NAMES.LOCALIDADE].validation = validate(['required']);
    }

    // NUM_FASES
    questions[INPUT_NAMES.NUM_FASES].visible = [getCompanyProfileIds().EDP_PT, getCompanyProfileIds().EDP_BR].includes(companyProfileId);

    //CLASSE_ID
    questions[INPUT_NAMES.CLASSE_ID].visible = [getCompanyProfileIds().EDP_BR].includes(companyProfileId);
    questions[INPUT_NAMES.CLASSE_ID].options = classes ?? [];

    // NIVEL_TENSAO_ID
    if (isCreateFacility) {
        questions[INPUT_NAMES.NIVEL_TENSAO_ID].validation = validate(['required']);
    } else {
        //View/Edit
        if (![getCompanyProfileIds().EDP_IT].includes(companyProfileId)) questions[INPUT_NAMES.NIVEL_TENSAO_ID].validation = null;
    }
    if ([getCompanyProfileIds().TELEFONICA].includes(companyProfileId)) {
        questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = false;
    } else {
        questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = !!voltageLevels && voltageLevels.length > 0;
    }

    questions[INPUT_NAMES.NIVEL_TENSAO_ID].options = voltageLevels ?? [];
    questions[INPUT_NAMES.NIVEL_TENSAO_ID].disabled = [
        getCompanyProfileIds().EDP_PT,
        getCompanyProfileIds().EDP_ES,
        getCompanyProfileIds().NRG,
        getCompanyProfileIds().ESB,
    ].includes(companyProfileId);

    // TENSAO_CONEXAO_ID
    questions[INPUT_NAMES.TENSAO_CONEXAO_ID].visible = !!conectionVoltages && conectionVoltages.length > 0;
    questions[INPUT_NAMES.TENSAO_CONEXAO_ID].disabled =
        isFieldDefined(inputs?.[INPUT_NAMES.TENSAO_CONEXAO_ID]) && !!conectionVoltages && conectionVoltages.length === 1;
    questions[INPUT_NAMES.TENSAO_CONEXAO_ID].options = conectionVoltages ?? [];

    //#region POTENCIA_CONTRATADA
    questions[INPUT_NAMES.POTENCIA_CONTRATADA].validation =
        [getCompanyProfileIds().EDP_PT].includes(companyProfileId) ?
            validate(['required', 'number', 'min', 'maxNotIncluded'], CONTRACTED_POWER_LIMITS.EDP_PT)
        :   validate(['required', 'number', 'min', 'maxNotIncluded'], CONTRACTED_POWER_LIMITS.DEFAULT);
    questions[INPUT_NAMES.POTENCIA_CONTRATADA].options.limits =
        [getCompanyProfileIds().EDP_PT].includes(companyProfileId) ? CONTRACTED_POWER_LIMITS.EDP_PT : CONTRACTED_POWER_LIMITS.DEFAULT;

    //#region POTENCIA_CONTRATADA_PONTA
    questions[INPUT_NAMES.POTENCIA_CONTRATADA_PONTA].visible =
        [getCompanyProfileIds().EDP_BR].includes(companyProfileId) && parseInt(inputs?.[INPUT_NAMES.OPCAO_TARIFARIO_ID]) === 74;

    // #region POTENCIA_REQUISITADA
    if (!isCreateFacility) questions[INPUT_NAMES.POTENCIA_REQUISITADA].visible = [getCompanyProfileIds().EDP_PT].includes(companyProfileId);

    // EDP_BR TarifaB3(ID=35)
    if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId) && parseInt(inputs?.[INPUT_NAMES.NIVEL_TENSAO_ID]) === 35)
        questions[INPUT_NAMES.POTENCIA_CONTRATADA].visible = false;
    //#endregion POTENCIA_CONTRATADA

    /* ***** electricitySection ***** */
    // Tariffs \\
    //#region CONSUMO_ANUAL_MWH
    questions[INPUT_NAMES.CONSUMO_ANUAL_MWH].visible = true;
    questions[INPUT_NAMES.CONSUMO_ANUAL_MWH].disabled = ruleDisableEditChannelPT;
    //#endregion

    //#region DISTRIBUIDOR_ID

    if (isCreateFacility) {
        questions[INPUT_NAMES.DISTRIBUIDOR_ID].validation = validate(['required']);
        questions[INPUT_NAMES.DISTRIBUIDOR_ID].disabled = false;
    } else {
        //View/Edit
        let ruleDisableDistributors =
            !!distributors &&
            distributors.length === 1 &&
            inputs?.[INPUT_NAMES.DISTRIBUIDOR_ID] &&
            !!distributors.find((item) => parseInt(item.id) === parseInt(inputs?.[INPUT_NAMES.DISTRIBUIDOR_ID]));

        let ruleVisibleDistributors =
            questions[INPUT_NAMES.DISTRIBUIDOR_ID].visible &&
            [getCompanyProfileIds().EDP_PL, getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_BR].includes(companyProfileId);
        questions[INPUT_NAMES.DISTRIBUIDOR_ID].visible = ruleVisibleDistributors;
        questions[INPUT_NAMES.DISTRIBUIDOR_ID].disabled = ruleDisableDistributors;
    }
    questions[INPUT_NAMES.DISTRIBUIDOR_ID].options = distributors;
    //#endregion

    //#region OPCAO_TARIFARIO_ID
    if (isCreateFacility) {
        questions[INPUT_NAMES.OPCAO_TARIFARIO_ID].validation = validate(['required']);
        questions[INPUT_NAMES.OPCAO_TARIFARIO_ID].disabled = false;
    } else {
        //View/Edit
        let ruleDisableTariffOptions =
            !!tariffOptions &&
            tariffOptions.length === 1 &&
            inputs?.[INPUT_NAMES.OPCAO_TARIFARIO_ID] &&
            !!tariffOptions.find((item) => parseInt(item.id) === parseInt(inputs?.[INPUT_NAMES.OPCAO_TARIFARIO_ID]));

        questions[INPUT_NAMES.OPCAO_TARIFARIO_ID].visible =
            tariffOptions &&
            tariffOptions.length > 0 &&
            [
                getCompanyProfileIds().EDP_PL,
                getCompanyProfileIds().EDP_IT,
                getCompanyProfileIds().EDP_BR,
                getCompanyProfileIds().TELEFONICA,
                getCompanyProfileIds().EDP_PT,
                getCompanyProfileIds().EDP_ES,
            ].includes(companyProfileId);

        questions[INPUT_NAMES.OPCAO_TARIFARIO_ID].disabled =
            [getCompanyProfileIds().EDP_ES, getCompanyProfileIds().EDP_PT].includes(companyProfileId) || ruleDisableTariffOptions;
    }
    questions[INPUT_NAMES.OPCAO_TARIFARIO_ID].options = getTariffOptionsByDistributorID(
        companyProfileId,
        inputs[INPUT_NAMES.DISTRIBUIDOR_ID] ? inputs[INPUT_NAMES.DISTRIBUIDOR_ID] : '',
        questions[INPUT_NAMES.DISTRIBUIDOR_ID].visible,
        tariffOptions
    );

    if (!isCreateFacility) {
        questions[INPUT_NAMES.FONTE_TARIFA_REDES_ID].visible =
            [getCompanyProfileIds().EDP_PT].includes(companyProfileId) &&
            [USERS_TYPE_ID.MANAGER, USERS_TYPE_ID.CHANNEL].includes(userTypeID);
        questions[INPUT_NAMES.FONTE_TARIFA_REDES_ID].options = gridTariffSources;
    } else {
        questions[INPUT_NAMES.FONTE_TARIFA_REDES_ID].visible = false;
    }

    // #region OPCAO_HORARIO_ID
    if (isCreateFacility) {
        questions[INPUT_NAMES.OPCAO_HORARIO_ID].visible = false;
    } else {
        questions[INPUT_NAMES.OPCAO_HORARIO_ID].visible = [getCompanyProfileIds().EDP_PT].includes(companyProfileId);
        questions[INPUT_NAMES.OPCAO_HORARIO_ID].options = hourlyOptions;
        questions[INPUT_NAMES.OPCAO_HORARIO_ID].disabled = true;
    }
    //#endregion
    questions[INPUT_NAMES.TARIFA_KW_DIA].visible = [getCompanyProfileIds().EDP_PT].includes(companyProfileId) && !isCreateFacility;

    //#endregion isCreateFacility
    //#endregion

    //#region SUPPLIED_BY_COMPANY
    if (
        ![getCompanyProfileIds().EDP_PT, getCompanyProfileIds().EDP_ES].includes(companyProfileId) ||
        (_isUserChannelGroup && [GRUPO_CANAL_IDS.LIBERBANK, GRUPO_CANAL_IDS.BBVA].includes(userChannelIDGroup))
    ) {
        questions[INPUT_NAMES.SUPPLIED_BY_COMPANY].label = 'question.label.supplier';
        questions[INPUT_NAMES.SUPPLIED_BY_COMPANY].validation = validate(['required']);
        questions[INPUT_NAMES.SUPPLIED_BY_COMPANY].options = suppliers ?? [];
    }

    if (isCreateFacility) {
        questions[INPUT_NAMES.SUPPLIED_BY_COMPANY].disabled = false;
    } else {
        //View/Edit
        if (
            [
                getCompanyProfileIds().ESB,
                getCompanyProfileIds().NRG,
                getCompanyProfileIds().EDP_BR,
                getCompanyProfileIds().EDP_IT,
                getCompanyProfileIds().EDP_PL,
            ].includes(companyProfileId) ||
            (_isUserChannelGroup && [GRUPO_CANAL_IDS.LIBERBANK, GRUPO_CANAL_IDS.BBVA].includes(userChannelIDGroup))
        ) {
            let ruleDisableSUpplierOptions =
                !!suppliers &&
                suppliers.length === 1 &&
                isDefined(inputs?.[INPUT_NAMES.SUPPLIED_BY_COMPANY]) &&
                !!suppliers.find((item) => parseInt(item.id) === parseInt(inputs?.[INPUT_NAMES.SUPPLIED_BY_COMPANY]));
            questions[INPUT_NAMES.SUPPLIED_BY_COMPANY].disabled = ruleDisableSUpplierOptions;
        }
    }
    //#endregion

    if ([getCompanyProfileIds().EDP_PL].includes(companyProfileId) && !isCreateFacility) questions[INPUT_NAMES.POWER_FEE].visible = true;

    //#region DETAILED_TARIFFS
    let rulesHideEnergyTariff =
        !hasCompanyProfileId(companyProfileId) ||
        [
            getCompanyProfileIds().EFZ,
            getCompanyProfileIds().EDP_ES,
            getCompanyProfileIds().ESB,
            getCompanyProfileIds().NRG,
            getCompanyProfileIds().TELEFONICA,
        ].includes(companyProfileId);
    questions[INPUT_NAMES.DETAILED_TARIFFS].disabled =
        !hasCompanyProfileId(companyProfileId) || ruleDisableEditChannelPT || [getCompanyProfileIds().EDP_BR].includes(companyProfileId);
    questions[INPUT_NAMES.DETAILED_TARIFFS].options = {};
    questions[INPUT_NAMES.DETAILED_TARIFFS].options.hideEnergyTariff = rulesHideEnergyTariff;

    // tooltip
    if ([getCompanyProfileIds().EDP_PL].includes(companyProfileId))
        questions[INPUT_NAMES.DETAILED_TARIFFS].tooltip = {
            grid_tariff: 'question.detailed_tariffs.tooltip.grid_tariff.comapnyID8',
        };
    //#endregion

    /* ***** gasSection ***** */

    // GAS_SUPPLIED_BY_COMPANY
    questions[INPUT_NAMES.CONSUMO_ANUAL_GAS_MWH].visible = hasSupplierGas;
    questions[INPUT_NAMES.TARIFA_FIXA_GAS_MWH].visible = hasSupplierGas;
    questions[INPUT_NAMES.TARIFA_MEDIA_GAS_MWH].visible = hasSupplierGas;

    //#region  section Electricity load profile
    // HORARIO_DIAS_SEMANA
    questions[INPUT_NAMES.HORARIO_DIAS_SEMANA].disabled = !inputs[INPUT_NAMES.WORKING_DAYS];
    // HORARIO_SABADO
    questions[INPUT_NAMES.HORARIO_SABADO].disabled = !inputs[INPUT_NAMES.TRABALHA_SAB];
    // HORARIO_DOMINGO_E_FERIADO
    questions[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO].disabled = !inputs[INPUT_NAMES.TRABALHA_DOM] && !inputs[INPUT_NAMES.TRABALHA_FERIADO];

    //#region  Section Load Curve (OPCAO_CURVA_CARGA)
    questions[INPUT_NAMES.OPCAO_CURVA_CARGA_ID].handleChange = handleChangeLoadCurve ?? (() => {});
    questions[INPUT_NAMES.OPCAO_CURVA_CARGA_ID].disabled = ruleDisableEditChannelPT;
    questions[INPUT_NAMES.CONSUMPTION_WEIGHTS].options = {
        ...questions[INPUT_NAMES.CONSUMPTION_WEIGHTS].options,
    };

    // remove CNAE estimation and only shows custom
    if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId)) questions[INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA].options.shift();

    if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId))
        questions[INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA].tooltip = 'page.facility.tem_dados_ponderador_estimativa_simples.tooltip';

    /* OPCAO_CURVA_CARGA */

    //Tooltip Link CAE
    let hasTooltipLinkCAE = Object.keys(AVAILABLE_LINKS_CAE).includes(`${companyProfileId}`);
    questions[INPUT_NAMES.ACTIVITY_CODE].tooltipLink = hasTooltipLinkCAE ? AVAILABLE_LINKS_CAE[companyProfileId] : null;

    //Disable first options Condition
    questions[INPUT_NAMES.OPCAO_CURVA_CARGA_ID].options[0].disabled =
        !inputs?.tem_dados_integracao_estatisticos && !inputs?.tem_dados_integracao_reais;
    // options visible by companyProfileId
    questions[INPUT_NAMES.OPCAO_CURVA_CARGA_ID].options.map((option) => {
        // hide options INTEGRATION_DATA
        if (
            [
                getCompanyProfileIds().EDP_PL,
                getCompanyProfileIds().EDP_IT,
                getCompanyProfileIds().ESB,
                getCompanyProfileIds().NRG,
                getCompanyProfileIds().EDP_BR,
            ].includes(companyProfileId) &&
            [OPTIONS_LOAD_CURVE.INTEGRATION_DATA].includes(option.value)
        )
            option.visible = false;

        // hide options DETAILED_ESTIMATION && DETAILED_ESTIMATION all companyProfileId
        if ([OPTIONS_LOAD_CURVE.DETAILED_ESTIMATION].includes(option.value)) option.visible = false;

        if (isCreateFacility && [OPTIONS_LOAD_CURVE.UPLOAD_CURVE].includes(option.value)) option.visible = false;

        return option;
    });
    //#endregion
    //#endregion

    //#region SectionOtherData
    if ([getCompanyProfileIds().EDP_BR].includes(companyProfileId)) {
        //create & edit
        questions[INPUT_NAMES.DATA_IDADE_RESPOSTA_CLIENTE].visible = false; //OTHER
        questions[INPUT_NAMES.NUM_TRABALHADORES].visible = false; //OTHER
        questions[INPUT_NAMES.AREA].visible = false; //OTHER
        questions[INPUT_NAMES.AREA_COBERTA].visible = false; //OTHER
        questions[INPUT_NAMES.AREA_UTIL].visible = false; //OTHER
    }

    //#endregion SectionOtherData

    //#region channelGroupRules
    if (channelGroupRules(userChannelIDGroup)?.isChannelGroup) {
        // Gestão do tamanho do campo CPE de acordo com os prefixos para os grupo canal ID 1-5
        if (
            isCreateFacility &&
            [
                GRUPO_CANAL_IDS.LIBERBANK,
                GRUPO_CANAL_IDS.TELEFONICA,
                GRUPO_CANAL_IDS.BBVA,
                GRUPO_CANAL_IDS.ESE,
                GRUPO_CANAL_IDS.SELECTRA,
                GRUPO_CANAL_IDS.BANCOSABADELL,
                GRUPO_CANAL_IDS.OUTBOUND,
            ].includes(userChannelIDGroup) &&
            isDefined(GRUPO_CANAL_IDS_PREFIXOS[userChannelIDGroup])
        ) {
            questions[INPUT_NAMES.CPE].validation = validate(['maxLength'], {
                maxLength: 30 - GRUPO_CANAL_IDS_PREFIXOS[userChannelIDGroup].length,
            });
            questions[INPUT_NAMES.CPE].optValidation = {
                minLength: 2,
                maxLength: 30 - GRUPO_CANAL_IDS_PREFIXOS[userChannelIDGroup].length,
            };
        }

        switch (userChannelIDGroup) {
            case GRUPO_CANAL_IDS.BBVA:
            case GRUPO_CANAL_IDS.LIBERBANK:
            case GRUPO_CANAL_IDS.TELEFONICA:
            case GRUPO_CANAL_IDS.SELECTRA:
            case GRUPO_CANAL_IDS.BANCOSABADELL:
            case GRUPO_CANAL_IDS.OUTBOUND:
                if (isCreateFacility) {
                    //Create
                    questions[INPUT_NAMES.MORADA].visible = false;
                    questions[INPUT_NAMES.TIPO_INSTALACAO_ID].visible = false;
                    questions[INPUT_NAMES.LOCALIDADE].visible = false;
                    questions[INPUT_NAMES.CODIGO_POSTAL].visible = false;
                    questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = false;
                    questions[INPUT_NAMES.POTENCIA_CONTRATADA].visible = false;
                    questions[INPUT_NAMES.SUPPLIED_BY_COMPANY].visible = ![GRUPO_CANAL_IDS.LIBERBANK, GRUPO_CANAL_IDS.BBVA].includes(
                        userChannelIDGroup
                    ); //ELECTRICITY
                } else {
                    // Edit/View
                    questions[INPUT_NAMES.MORADA].validation = null;
                    questions[INPUT_NAMES.SUPPLIED_BY_COMPANY].visible = ![GRUPO_CANAL_IDS.LIBERBANK, GRUPO_CANAL_IDS.BBVA].includes(
                        userChannelIDGroup
                    ); //ELECTRICITY
                }
                break;
            case GRUPO_CANAL_IDS.ESE:
                if (isCreateFacility) {
                    //Create
                    // Visibility
                    questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = false;
                    questions[INPUT_NAMES.POTENCIA_CONTRATADA].visible = false;
                } else {
                    //Edit/View
                    // Validation
                    questions[INPUT_NAMES.CPE].validation = null;
                    // Visibility
                    questions[INPUT_NAMES.POTENCIA_CONTRATADA].visible = false;
                    questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = false;
                    questions[INPUT_NAMES.SUPPLIED_BY_COMPANY].visible = false; //ELECTRICITY
                    questions[INPUT_NAMES.DISTRIBUIDOR_ID].visible = false; //ELECTRICITY
                    questions[INPUT_NAMES.CONSUMO_ANUAL_MWH].visible = false; //ELECTRICITY
                    questions[INPUT_NAMES.DETAILED_TARIFFS].visible = false; //ELECTRICITY
                    questions[INPUT_NAMES.OPCAO_CURVA_CARGA_ID].visible = false; //CURVE_LOAD
                    questions[INPUT_NAMES.HORARIO_TEM_IMPACTO].visible = false; //CURVE_LOAD
                    questions[INPUT_NAMES.ACTIVITY_CODE].visible = false; //CURVE_LOAD
                    questions[INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA].visible = false; //CURVE_LOAD
                    questions[INPUT_NAMES.TEM_DADOS_PONDERADOR_INTEGRACAO].visible = false; //CURVE_LOAD
                    questions[INPUT_NAMES.CONSUMPTION_WEIGHTS].visible = false; //CURVE_LOAD
                    questions[INPUT_NAMES.DATA_IDADE_RESPOSTA_CLIENTE].visible = false; //OTHER
                    questions[INPUT_NAMES.NUM_TRABALHADORES].visible = false; //OTHER
                    questions[INPUT_NAMES.AREA].visible = false; //OTHER
                    questions[INPUT_NAMES.AREA_COBERTA].visible = false; //OTHER
                    questions[INPUT_NAMES.AREA_UTIL].visible = false; //OTHER
                    questions[INPUT_NAMES.GAS_SUPPLIED_BY_COMPANY].visible = false; //GAS
                    questions[INPUT_NAMES.CONSUMO_ANUAL_GAS_MWH].visible = false; //GAS
                    questions[INPUT_NAMES.TARIFA_FIXA_GAS_MWH].visible = false; //GAS
                    questions[INPUT_NAMES.TARIFA_MEDIA_GAS_MWH].visible = false; //GAS
                    // Disabled
                    questions[INPUT_NAMES.CPE].disabled = false;
                    questions[INPUT_NAMES.DESCRICAO].disabled = false;
                }
                break;

            default:
                break;
        }
    }
    return questions;
}

export function buildQuestionsB2C() {
    const questions = clone(QUESTIONS_FACILITY_B2C);

    return questions;
}

/**
 * Have the Inputs been changed
 */
export function inputsChanged(inputs, watchLoadCurveInputs) {
    let inputsChanged = false;

    switch (watchLoadCurveInputs[INPUT_NAMES.OPCAO_CURVA_CARGA_ID]) {
        case OPTIONS_LOAD_CURVE.INTEGRATION_DATA:
            return false;

        case OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION:
            return false;

        case OPTIONS_LOAD_CURVE.WORKING_SCHEDULE:
            // CONSUMO_ANUAL_MWH
            if (
                !!watchLoadCurveInputs[INPUT_NAMES.CONSUMO_ANUAL_MWH] &&
                parseInt(inputs[INPUT_NAMES.CONSUMO_ANUAL_MWH]) !== parseInt(watchLoadCurveInputs[INPUT_NAMES.CONSUMO_ANUAL_MWH])
            ) {
                inputsChanged = true;
            }
            // HORARIO_DIAS_SEMANA
            if (
                inputs?.[INPUT_NAMES.HORARIO_DIAS_SEMANA]?.[0] !==
                    convertDateTimeToString(watchLoadCurveInputs[INPUT_NAMES.HORARIO_DIAS_SEMANA]?.[0]) ||
                inputs?.[INPUT_NAMES.HORARIO_DIAS_SEMANA]?.[1] !==
                    convertDateTimeToString(watchLoadCurveInputs[INPUT_NAMES.HORARIO_DIAS_SEMANA]?.[1])
            ) {
                inputsChanged = true;
            }
            if (
                inputs?.[INPUT_NAMES.HORARIO_SABADO]?.[0] !==
                    convertDateTimeToString(watchLoadCurveInputs[INPUT_NAMES.HORARIO_SABADO]?.[0]) ||
                inputs?.[INPUT_NAMES.HORARIO_SABADO]?.[1] !== convertDateTimeToString(watchLoadCurveInputs[INPUT_NAMES.HORARIO_SABADO]?.[1])
            ) {
                inputsChanged = true;
            }
            if (
                inputs?.[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]?.[0] !==
                    convertDateTimeToString(watchLoadCurveInputs[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]?.[0]) ||
                inputs?.[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]?.[1] !==
                    convertDateTimeToString(watchLoadCurveInputs[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]?.[1])
            ) {
                inputsChanged = true;
            }
            //HORARIO_DIAS_SEMANA
            !!watchLoadCurveInputs[INPUT_NAMES.DAYS_OF_WORK] &&
                watchLoadCurveInputs[INPUT_NAMES.DAYS_OF_WORK].map((item, index) => {
                    if (!(item === inputs[DAYS_OF_WORK[index]])) {
                        inputsChanged = true;
                    }
                    return item;
                });
            return inputsChanged;

        case OPTIONS_LOAD_CURVE.UPLOAD_CURVE:
            return false;

        default:
            return inputsChanged;
    }
}
/**
 * Save just inputs Changed
 */
export function savedInputsChanged(__optionLoadCurve, action, __annualConsumption, facilityData) {
    let inputObject = {};
    switch (__optionLoadCurve) {
        case OPTIONS_LOAD_CURVE.INTEGRATION_DATA: {
            if (__annualConsumption) {
                inputObject = {
                    consumo_anual_mwh: convertToUnit(__annualConsumption, 'fromKwToMw'),
                    consumo_anual: __annualConsumption,
                    tem_dados_ponderador_integracao_personalizada: facilityData?.tem_dados_ponderador_integracao_personalizada,
                };
            }
            if (isBoolean(action?.payload?.horarioTemImpacto)) {
                inputObject = {
                    ...inputObject,
                    horario_tem_impacto: action?.payload?.horarioTemImpacto,
                    trabalha_2a: action?.payload?.trabalha_2a,
                    trabalha_3a: action?.payload?.trabalha_3a,
                    trabalha_4a: action?.payload?.trabalha_4a,
                    trabalha_5a: action?.payload?.trabalha_5a,
                    trabalha_6a: action?.payload?.trabalha_6a,
                    trabalha_dom: action?.payload?.trabalha_dom,
                    trabalha_feriado: action?.payload?.trabalha_feriado,
                    trabalha_sab: action?.payload?.trabalha_sab,
                };
            }
            break;
        }
        case OPTIONS_LOAD_CURVE.WORKING_SCHEDULE:
            if (action?.payload?.horarioDiasSemana) {
                inputObject = {
                    horario_dias_semana: action?.payload?.horarioDiasSemana,
                    horario_sabado: action?.payload?.horarioSabado,
                    horario_domingo_e_feriado: action?.payload?.horarioDomingo,
                    working_days: action?.payload?.working_days,
                    trabalha_2a: action?.payload?.trabalha_2a,
                    trabalha_3a: action?.payload?.trabalha_3a,
                    trabalha_4a: action?.payload?.trabalha_4a,
                    trabalha_5a: action?.payload?.trabalha_5a,
                    trabalha_6a: action?.payload?.trabalha_6a,
                    trabalha_dom: action?.payload?.trabalha_dom,
                    trabalha_feriado: action?.payload?.trabalha_feriado,
                    trabalha_sab: action?.payload?.trabalha_sab,
                };
            }
            if (!!__annualConsumption || __annualConsumption === 0) {
                inputObject = {
                    ...inputObject,
                    consumo_anual_mwh: convertToUnit(__annualConsumption, 'fromKwToMw'),
                    consumo_anual: __annualConsumption,
                };
            }
            break;
        default:
            break;
    }
    return inputObject;
}

/**
 * buildFacility
 */
export function buildFacility(inputs, options) {
    let _inputs = clone(buildInputs(inputs, options));
    let _questions = clone(buildQuestions(_inputs, options));

    return {
        inputs: _inputs,
        questions: _questions,
        visibleSections: visibleSections(_questions),
    };
}

export const convertDateTimeToString = (date) => {
    let myDate = new Date(date);

    let minutes = myDate.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes.toString();

    let hours = myDate.getHours();
    hours = hours < 10 ? '0' + hours : hours.toString();

    return hours + ':' + minutes + ':00';
};

/**
 * getPayload
 *
 * @export
 * @param {*} formInputs
 * @param {*} options
 * @return {*}
 */
export async function getPayload(formInputs, options) {
    const { visibleSections, detailedTariffs, companyProfileId } = options;
    let payload = clone(formInputs);

    // Normal facility form
    payload = {
        ...payload,
        [INPUT_NAMES.MUNICIPIO_ID]:
            isFieldDefined(formInputs[INPUT_NAMES.MUNICIPIO_DESCRICAO].id) ? formInputs?.[INPUT_NAMES.MUNICIPIO_DESCRICAO]?.id : null,
        [INPUT_NAMES.MUNICIPIO_DESCRICAO]:
            isFieldDefined(formInputs[INPUT_NAMES.MUNICIPIO_DESCRICAO].descricao) ?
                formInputs?.[INPUT_NAMES.MUNICIPIO_DESCRICAO]?.descricao
            :   null,
        ...toFormatSchedule(formInputs),
        ...toFormatDaysOfWork(formInputs?.[INPUT_NAMES.DAYS_OF_WORK]),
        [INPUT_NAMES.AREA]: isFieldDefined(formInputs[INPUT_NAMES.AREA]) ? parseFloat(formInputs[INPUT_NAMES.AREA]) : null,
        [INPUT_NAMES.AREA_COBERTA]:
            isFieldDefined(formInputs[INPUT_NAMES.AREA_COBERTA]) ? parseFloat(formInputs[INPUT_NAMES.AREA_COBERTA]) : null,
        [INPUT_NAMES.AREA_UTIL]: isFieldDefined(formInputs[INPUT_NAMES.AREA_UTIL]) ? parseFloat(formInputs[INPUT_NAMES.AREA_UTIL]) : null,
        [INPUT_NAMES.TIPO_INSTALACAO_ID]:
            isFieldDefined(formInputs[INPUT_NAMES.TIPO_INSTALACAO_ID]) ? parseInt(formInputs[INPUT_NAMES.TIPO_INSTALACAO_ID]) : null,
        [INPUT_NAMES.NUM_TRABALHADORES]:
            isFieldDefined(formInputs[INPUT_NAMES.NUM_TRABALHADORES]) ? parseInt(formInputs[INPUT_NAMES.NUM_TRABALHADORES]) : null,
        [INPUT_NAMES.DATA_IDADE_RESPOSTA_CLIENTE]:
            isFieldDefined(formInputs[INPUT_NAMES.DATA_IDADE_RESPOSTA_CLIENTE]) ?
                parseInt(formInputs[INPUT_NAMES.DATA_IDADE_RESPOSTA_CLIENTE])
            :   null,
        [INPUT_NAMES.NIVEL_TENSAO_ID]:
            !isNaN(parseInt(formInputs[INPUT_NAMES.NIVEL_TENSAO_ID])) ? parseInt(formInputs[INPUT_NAMES.NIVEL_TENSAO_ID]) : null,
        [INPUT_NAMES.POTENCIA_CONTRATADA]:
            !isNaN(parseFloat(formInputs?.[INPUT_NAMES.POTENCIA_CONTRATADA])) ?
                parseFloat(formInputs?.[INPUT_NAMES.POTENCIA_CONTRATADA])
            :   null,
        [INPUT_NAMES.POTENCIA_CONTRATADA_PONTA]:
            !isNaN(parseFloat(formInputs?.[INPUT_NAMES.POTENCIA_CONTRATADA_PONTA])) ?
                parseFloat(formInputs?.[INPUT_NAMES.POTENCIA_CONTRATADA_PONTA])
            :   null,
        [INPUT_NAMES.POTENCIA_REQUISITADA]:
            !isNaN(parseFloat(formInputs?.[INPUT_NAMES.POTENCIA_REQUISITADA])) ?
                parseFloat(formInputs?.[INPUT_NAMES.POTENCIA_REQUISITADA])
            :   null,
        [INPUT_NAMES.NUM_FASES]: !isNaN(parseInt(formInputs[INPUT_NAMES.NUM_FASES])) ? parseInt(formInputs[INPUT_NAMES.NUM_FASES]) : null,
    };

    //rules fields

    /* electricitySection */

    //#region SUPPLIED_BY_COMPANY && FORNECEDOR_ID
    if (![getCompanyProfileIds().EDP_PT, getCompanyProfileIds().EDP_ES].includes(companyProfileId)) {
        payload[INPUT_NAMES.FORNECEDOR_ID] = clone(payload[INPUT_NAMES.SUPPLIED_BY_COMPANY]);
        delete payload[INPUT_NAMES.SUPPLIED_BY_COMPANY];
    }

    //#endregion

    if (visibleSections.includes(FACILITY_QUESTIONS_SECTIONS.ELECTRICITY)) {
        let _detailedTariffs = await getPayloadDetailedTariffs(companyProfileId, detailedTariffs);
        payload = {
            ...payload,
            ..._detailedTariffs,
        };

        // DETAILED_TARIFFS
        if (payload?.[INPUT_NAMES.DETAILED_TARIFFS]) {
            delete payload[INPUT_NAMES.DETAILED_TARIFFS];
        }
    }

    /* gasSection */
    if (visibleSections.includes(FACILITY_QUESTIONS_SECTIONS.GAS)) {
        payload = {
            ...payload,
            ...conversionTariffGasMeanMWh({
                [INPUT_NAMES.CONSUMO_ANUAL_GAS_MWH]: formInputs[INPUT_NAMES.CONSUMO_ANUAL_GAS_MWH],
                [INPUT_NAMES.TARIFA_FIXA_GAS_MWH]: formInputs[INPUT_NAMES.TARIFA_FIXA_GAS_MWH],
                [INPUT_NAMES.TARIFA_MEDIA_GAS_MWH]: formInputs[INPUT_NAMES.TARIFA_MEDIA_GAS_MWH],
            }),
        };
    }

    // Delete trash
    delete payload[INPUT_NAMES.COUNTY];
    delete payload[INPUT_NAMES.DAYS_OF_WORK];

    //Fallback to consumption when no annual_consumption field
    if (
        (formInputs.opcao_curva_carga_id === OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION ||
            formInputs.opcao_curva_carga_id === OPTIONS_LOAD_CURVE.INTEGRATION_DATA) &&
        !!formInputs?.consumption_weights
    ) {
        payload.consumo_anual_mwh = formInputs?.consumption_weights?.reduce(
            (accumulator, currentValue) => ({
                monthly_consumption: accumulator.monthly_consumption + parseFloat(currentValue.monthly_consumption),
            }),
            { monthly_consumption: 0 }
        ).monthly_consumption;
    }
    // consumo_anual
    payload.consumo_anual = convertToUnit(payload.consumo_anual_mwh, 'fromMwToKw');

    if (isNaN(payload.consumo_anual)) delete payload.consumo_anual;

    // Prego temporariamente -  remover as flag que são atualizadas na altura que ~e feita a alteração do input
    delete payload.tem_dados_ponderador_estimativa_simples;
    delete payload.tem_dados_ponderador_estimativa_simples_CAE;
    delete payload.tem_dados_ponderador_estimativa_simples_personalizada;
    delete payload.tem_dados_ponderador_estimativa_simples_personalizada_por_periodo;
    delete payload.tem_dados_ponderador_estimativa_simples_por_periodo;
    delete payload.tem_dados_ponderador_integracao_personalizada;

    return removeFieldEmptyInObj(payload);
}

/**
 * getCreatePayload
 *
 * @export
 * @param {*} formInputs
 * @param {*} options
 * @return {*}
 */
export async function getCreatePayload(formInputs, options) {
    const { visibleSections, detailedTariffs, companyProfileId, distributors } = options;
    let payload = clone(formInputs);

    // Normal facility form
    payload = {
        ...payload,
        ...toFormatSchedule(formInputs),
        [INPUT_NAMES.CPE]: escape(payload?.[INPUT_NAMES.CPE]),
        [INPUT_NAMES.NIVEL_TENSAO_ID]: parseInt(payload?.[INPUT_NAMES.NIVEL_TENSAO_ID]),
        [INPUT_NAMES.POTENCIA_CONTRATADA]: parseFloat(payload?.[INPUT_NAMES.POTENCIA_CONTRATADA]),
        [INPUT_NAMES.POTENCIA_CONTRATADA_PONTA]:
            isFieldDefined(payload?.[INPUT_NAMES.POTENCIA_CONTRATADA_PONTA]) ?
                parseFloat(payload?.[INPUT_NAMES.POTENCIA_CONTRATADA_PONTA])
            :   undefined,
        [INPUT_NAMES.TIPO_INSTALACAO_ID]: parseInt(payload?.[INPUT_NAMES.TIPO_INSTALACAO_ID]),
        [INPUT_NAMES.OPCAO_TARIFARIO_ID]:
            payload?.[INPUT_NAMES.OPCAO_TARIFARIO_ID] ? parseInt(payload?.[INPUT_NAMES.OPCAO_TARIFARIO_ID]) : null,
        [INPUT_NAMES.CONSUMO_ANUAL]: convertToUnit(payload.consumo_anual_mwh, 'fromMwToKw'),
        [INPUT_NAMES.MUNICIPIO_ID]:
            (
                isFieldDefined(formInputs?.[INPUT_NAMES.MUNICIPIO_DESCRICAO]) &&
                isFieldDefined(formInputs?.[INPUT_NAMES.MUNICIPIO_DESCRICAO].id)
            ) ?
                formInputs?.[INPUT_NAMES.MUNICIPIO_DESCRICAO]?.id
            :   null,
        [INPUT_NAMES.MUNICIPIO_DESCRICAO]:
            (
                isFieldDefined(formInputs?.[INPUT_NAMES.MUNICIPIO_DESCRICAO]) &&
                isFieldDefined(formInputs?.[INPUT_NAMES.MUNICIPIO_DESCRICAO].descricao)
            ) ?
                formInputs?.[INPUT_NAMES.MUNICIPIO_DESCRICAO]?.descricao
            :   null,
    };
    //rules fields

    if (companiesWithDetailledAddress(companyProfileId)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { morada, numero, complemento, codigo_postal, bairro, nuts_i_id, municipio_id, municipio_descricao, ...rest } = payload;
        payload = {
            ...rest,
            location: {
                street: payload?.[INPUT_NAMES.MORADA],
                number: payload?.[INPUT_NAMES.NUMERO] ?? '',
                complement: payload?.[INPUT_NAMES.COMPLEMENTO] ?? '',
                postal_code: removePunctuation(payload.codigo_postal),
                district: payload?.[INPUT_NAMES.BAIRRO] ?? '',
                city: payload?.[INPUT_NAMES.LOCALIDADE],
                nuts_i_id: payload?.[INPUT_NAMES.NUT],
                county_id: payload?.[INPUT_NAMES.MUNICIPIO_ID],
                county_description: payload?.[INPUT_NAMES.MUNICIPIO_DESCRICAO],
            },
        };
    }

    /* electricitySection */

    //#region SUPPLIED_BY_COMPANY && FORNECEDOR_ID && DISTRIBUIDOR_ID
    if (![getCompanyProfileIds().EDP_PT, getCompanyProfileIds().EDP_ES].includes(companyProfileId)) {
        payload[INPUT_NAMES.FORNECEDOR_ID] = clone(payload[INPUT_NAMES.SUPPLIED_BY_COMPANY]);
        delete payload[INPUT_NAMES.SUPPLIED_BY_COMPANY];
    }

    //Add distributor to payload when field is hidden (distributors.length === 1)
    payload = {
        ...payload,
        [INPUT_NAMES.DISTRIBUIDOR_ID]: distributors.length === 1 ? parseInt(distributors[0].id) : payload[INPUT_NAMES.DISTRIBUIDOR_ID],
    };

    //#endregion

    if (visibleSections.includes(FACILITY_QUESTIONS_SECTIONS.ELECTRICITY)) {
        let _detailedTariffs = await getPayloadDetailedTariffs(companyProfileId, detailedTariffs);
        payload = {
            ...payload,
            ..._detailedTariffs,
        };

        // DETAILED_TARIFFS
        if (payload?.[INPUT_NAMES.DETAILED_TARIFFS]) {
            delete payload[INPUT_NAMES.DETAILED_TARIFFS];
        }
    }

    // Delete trash
    delete payload[INPUT_NAMES.CONSUMO_ANUAL_MWH];
    delete payload[INPUT_NAMES.COUNTY];
    delete payload[INPUT_NAMES.DAYS_OF_WORK];
    return removeFieldEmptyInObj(payload);
}

export const getLoadCurvesPayload = (inputs, totalAnnualConsumption) => {
    switch (inputs[INPUT_NAMES.OPCAO_CURVA_CARGA_ID]) {
        case OPTIONS_LOAD_CURVE.INTEGRATION_DATA:
            return removeFieldEmptyInObj({
                [INPUT_NAMES.OPCAO_CURVA_CARGA_ID]: inputs[INPUT_NAMES.OPCAO_CURVA_CARGA_ID],
                [INPUT_NAMES.CONSUMPTION_WEIGHTS]: inputs[INPUT_NAMES.CONSUMPTION_WEIGHTS],
                [INPUT_NAMES.TEM_DADOS_PONDERADOR_INTEGRACAO]: inputs[INPUT_NAMES.TEM_DADOS_PONDERADOR_INTEGRACAO],
                [INPUT_NAMES.ACTIVITY_CODE]: inputs[INPUT_NAMES.ACTIVITY_CODE],
                [INPUT_NAMES.CONSUMO_ANUAL]: inputs[[INPUT_NAMES.CONSUMO_ANUAL]],
                ...toFormatDaysOfWork(inputs[INPUT_NAMES.DAYS_OF_WORK]),
                working_days: inputs?.[INPUT_NAMES.DAYS_OF_WORK]?.[0],
                [INPUT_NAMES.NIVEL_TENSAO_ID]: inputs[INPUT_NAMES.NIVEL_TENSAO_ID],
                [INPUT_NAMES.OPCAO_TARIFARIO_ID]: inputs[INPUT_NAMES.OPCAO_TARIFARIO_ID],
            });

        case OPTIONS_LOAD_CURVE.SIMPLE_ESTIMATION:
            if (inputs[INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA_SIMPLES_POR_PERIODO]) {
                return removeFieldEmptyInObj({
                    [INPUT_NAMES.OPCAO_CURVA_CARGA_ID]: inputs[INPUT_NAMES.OPCAO_CURVA_CARGA_ID],
                    [INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA]: inputs[INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA],
                    [INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA_SIMPLES_POR_PERIODO]:
                        inputs[INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA_SIMPLES_POR_PERIODO],

                    [INPUT_NAMES.NIVEL_TENSAO_ID]: inputs[INPUT_NAMES.NIVEL_TENSAO_ID],
                    [INPUT_NAMES.OPCAO_TARIFARIO_ID]: inputs[INPUT_NAMES.OPCAO_TARIFARIO_ID],
                });
            } else {
                return removeFieldEmptyInObj({
                    [INPUT_NAMES.OPCAO_CURVA_CARGA_ID]: inputs[INPUT_NAMES.OPCAO_CURVA_CARGA_ID],
                    [INPUT_NAMES.CONSUMPTION_WEIGHTS]: inputs[INPUT_NAMES.CONSUMPTION_WEIGHTS],
                    [INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA]: inputs[INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA],
                    [INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA_SIMPLES_POR_PERIODO]:
                        inputs[INPUT_NAMES.TEM_DADOS_PONDERADOR_ESTIMATIVA_SIMPLES_POR_PERIODO],
                    [INPUT_NAMES.ACTIVITY_CODE]: inputs[INPUT_NAMES.ACTIVITY_CODE],
                    [INPUT_NAMES.CONSUMO_ANUAL]: totalAnnualConsumption,
                    [INPUT_NAMES.NIVEL_TENSAO_ID]: inputs[INPUT_NAMES.NIVEL_TENSAO_ID],
                    [INPUT_NAMES.OPCAO_TARIFARIO_ID]: inputs[INPUT_NAMES.OPCAO_TARIFARIO_ID],
                });
            }

        case OPTIONS_LOAD_CURVE.WORKING_SCHEDULE:
            return removeFieldEmptyInObj({
                [INPUT_NAMES.CONSUMO_ANUAL]: convertToUnit(inputs[INPUT_NAMES.CONSUMO_ANUAL_MWH], 'fromMwToKw'),
                ...toFormatDaysOfWork(inputs[INPUT_NAMES.DAYS_OF_WORK]),
                ...toFormatSchedule(inputs),
                working_days: inputs?.[INPUT_NAMES.DAYS_OF_WORK]?.[0],
                [INPUT_NAMES.NIVEL_TENSAO_ID]: inputs[INPUT_NAMES.NIVEL_TENSAO_ID],
                [INPUT_NAMES.OPCAO_CURVA_CARGA_ID]: inputs[INPUT_NAMES.OPCAO_CURVA_CARGA_ID],
                [INPUT_NAMES.NIVEL_TENSAO_ID]: inputs[INPUT_NAMES.NIVEL_TENSAO_ID],
                [INPUT_NAMES.OPCAO_TARIFARIO_ID]: inputs[INPUT_NAMES.OPCAO_TARIFARIO_ID],
            });

        case OPTIONS_LOAD_CURVE.UPLOAD_CURVE:
            return removeFieldEmptyInObj({
                [INPUT_NAMES.OPCAO_CURVA_CARGA_ID]: inputs[INPUT_NAMES.OPCAO_CURVA_CARGA_ID],
                [INPUT_NAMES.CONSUMO_ANUAL]: totalAnnualConsumption,
                [INPUT_NAMES.NIVEL_TENSAO_ID]: inputs[INPUT_NAMES.NIVEL_TENSAO_ID],
                [INPUT_NAMES.OPCAO_TARIFARIO_ID]: inputs[INPUT_NAMES.OPCAO_TARIFARIO_ID],
            });

        default:
            return removeFieldEmptyInObj({
                ...inputs,
            });
    }
};

/**
 * getTariffOptionsByDistributorID
 *
 * @param {*} input
 * @param {*} isVisible
 * @param {*} distributors
 */
export const getTariffOptionsByDistributorID = (companyProfileId, distributorID, isVisible, tariffOptions) => {
    let _tariffOptions = [];
    //#region EDP_PL
    if ([getCompanyProfileIds().EDP_PL].includes(companyProfileId)) {
        _tariffOptions =
            !!tariffOptions &&
            isFieldDefined(distributorID) &&
            isVisible &&
            tariffOptions.filter((item) => parseInt(item.d_id) === parseInt(distributorID));
    }
    //#endregion

    //region EDP_IT && ESB  && EDP_BR
    if (
        [
            getCompanyProfileIds().EDP_IT,
            getCompanyProfileIds().ESB,
            getCompanyProfileIds().NRG,
            getCompanyProfileIds().EDP_BR,
            getCompanyProfileIds().TELEFONICA,
            getCompanyProfileIds().EDP_ES,
            getCompanyProfileIds().EDP_PT,
        ].includes(companyProfileId)
    ) {
        _tariffOptions = tariffOptions;
    }
    //#endregion

    return _tariffOptions ? _tariffOptions : [];
};

/**
 * getDetailedTariffs
 *
 * @param {*} companyProfileId
 * @param {*} distribuidor_id
 * @param {*} opcao_tarifario_id
 * @param {*} energy_tariffs
 * @param {*} total_tariffs
 * @param {*} gridTariffs
 * @param {*} tariffOptions
 * @param {*} options
 */
export const getDetailedTariffs = (
    companyProfileId,
    distribuidor_id,
    opcao_tarifario_id,
    energy_tariffs = [],
    total_tariffs = [],
    gridTariffs,
    tariffOptions,
    options
) => {
    let decimalPlaces = 2;
    let detailedTariffs = [];
    let periods = null;
    let prc_periodo = null;
    let _gridTariffs = null;
    const { potencia_contratada, nivel_tensao_id, classe_id, clientICMS, opcao_horario_id } = options;

    let isBuild = !isEmptyArray(energy_tariffs); //aplica-se apenas para IT e PL e PT
    const hasTotalTariffs = total_tariffs.length > 0;

    switch (companyProfileId) {
        case getCompanyProfileIds().EDP_PT:
            if (isNaN(opcao_tarifario_id)) return detailedTariffs;

            //#region 1. get periods
            periods =
                !isEmptyArray(tariffOptions) ?
                    tariffOptions?.find((item) => parseInt(item.id) === parseInt(opcao_tarifario_id))?.periods ?? []
                :   { periods: [] };
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 2. get gridTariff
            //filter gridTariffs by nivel_tensao_id and opcao_horario_id and potencia_contratada
            _gridTariffs = gridTariffs?.filter(
                (gt) =>
                    gt.nivel_tensao_id === nivel_tensao_id &&
                    gt.opcao_horario_id === opcao_horario_id &&
                    (gt.potencia_contratada[0]?.inclusive ?
                        potencia_contratada >= parseFloat(gt.potencia_contratada[0].value)
                    :   potencia_contratada > parseFloat(gt.potencia_contratada[0].value)) &&
                    (gt.potencia_contratada[1]?.inclusive ?
                        potencia_contratada <= parseFloat(gt.potencia_contratada[1].value)
                    :   potencia_contratada < parseFloat(gt.potencia_contratada[1].value))
            );
            //find gridTariff by range potencia_contratada

            //#endregion

            //#region 3. build detailedTariffs
            periods?.map((period) => {
                // 1. get energyTariffData
                let energyTariffData =
                    isBuild ? energy_tariffs.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id)) : null;

                //#region 2. get energy_tariff && energy_tariff_mwh
                let energy_tariff = energyTariffData?.tarifa ?? '';
                let energy_tariff_mwh =
                    !isFieldDefined(energy_tariff) ? '' : calcConvertionToUnit(energy_tariff, 'currencykWhToMWh', decimalPlaces);
                //#endregion

                //#region 3. get grid_tariff && grid_tariff_mwh
                let grid = _gridTariffs?.find((item) => parseInt(item.tipo_periodo_id) === parseInt(period.id));

                let grid_tariff = grid?.tarifa ?? '';
                let grid_tariff_mwh =
                    !isFieldDefined(grid_tariff) ? '' : calcConvertionToUnit(grid_tariff, 'currencykWhToMWh', decimalPlaces);
                //#endregion

                //#region 4. get total_tariff_mwh && total_tariff
                let total_tariff_mwh = '';

                if (isFieldDefined(energy_tariff_mwh) && isFieldDefined(grid_tariff_mwh)) {
                    total_tariff_mwh = parseNumberWithToFixed(energy_tariff_mwh + grid_tariff_mwh, decimalPlaces);
                } else if (isFieldDefined(period.default_tariff)) {
                    total_tariff_mwh = calcConvertionToUnit(period.default_tariff, 'currencykWhToMWh', decimalPlaces);
                    if (isFieldDefined(grid_tariff_mwh) && total_tariff_mwh - grid_tariff_mwh > 0) {
                        energy_tariff_mwh = (total_tariff_mwh - grid_tariff_mwh).toFixed(decimalPlaces);
                        energy_tariff = calcConvertionToUnit(energy_tariff_mwh, 'currencyMWhTokWh', decimalPlaces);
                    } else {
                        energy_tariff = '';
                        energy_tariff_mwh = '';
                    }
                }

                let total_tariff = !isFieldDefined(total_tariff_mwh) ? '' : calcConvertionToUnit(total_tariff_mwh, 'currencyMWhTokWh');
                //#endregion

                //#region  5. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff,
                    energy_tariff_mwh,
                    grid_tariff,
                    grid_tariff_mwh,
                    total_tariff_mwh,
                    total_tariff,
                });
                //#endregion
                return period;
            });
            //#endregion

            return _sortby(detailedTariffs, ['id']);
        case getCompanyProfileIds().EDP_ES: {
            isBuild = !isEmptyArray(total_tariffs);

            //#region 1. get periods
            let hasOpcaoTarifarioID = !isNaN(opcao_tarifario_id) && isFieldDefined(opcao_tarifario_id);
            if (hasOpcaoTarifarioID) {
                periods = tariffOptions?.find((option) => parseInt(option?.id) === parseInt(opcao_tarifario_id))?.periods ?? [];
            } else {
                periods = tariffOptions?.find((option) => option.is_default)?.periods ?? [];
            }

            if (isBuild && total_tariffs.length > 0) {
                const defaultPrcPeriodo = 1 / total_tariffs.length; // fallback -> Quando não recebemos % de periods na matriz
                periods.map((period) => {
                    let totalTariff = total_tariffs.find((item) => item.tipo_periodo_id === period.id);
                    if (totalTariff?.tarifa) {
                        period.descricao = totalTariff.descricao;
                        period.tarifa = isFieldDefined(totalTariff?.tarifa) ? totalTariff.tarifa : period.tarifa;
                        period.prc_periodo =
                            isFieldDefined(totalTariff?.prc_periodo) ? totalTariff.prc_periodo : period.prc_periodo ?? defaultPrcPeriodo;
                        period.tipo_periodo_id = totalTariff.tipo_periodo_id;
                    }
                    return period;
                });
            }

            //#region 2. build detailedTariffs
            periods.map((period) => {
                //#region  3. add detailedTariffs
                // get total_tariff_mwh && total_tariff
                if (!isFieldDefined(period?.tarifa) && isFieldDefined(period?.default_tariff)) {
                    // fallback
                    period.tarifa = period?.default_tariff;
                    period.tipo_periodo_id = period?.id;
                    period.prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
                    period.descricao = period.description;
                } else {
                    period.tarifa = isFieldDefined(period?.tarifa) ? period?.tarifa : '';
                }
                let total_tariff = period?.tarifa;
                let total_tariff_mwh =
                    isFieldDefined(total_tariff) ? calcConvertionToUnit(total_tariff, 'currencykWhToMWh', decimalPlaces) : '';

                //#endregion
                return detailedTariffs.push({
                    id: period.tipo_periodo_id,
                    visible: true,
                    tipo_periodo_id: period.tipo_periodo_id,
                    descricao: period.descricao,
                    prc_periodo: period.prc_periodo,
                    energy_tariff: '',
                    energy_tariff_mwh: '',
                    grid_tariff: '',
                    grid_tariff_mwh: '',
                    total_tariff_mwh,
                    total_tariff,
                });
                //#endregion
            });

            //#endregion
            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().TELEFONICA:
        case getCompanyProfileIds().NRG:
        case getCompanyProfileIds().ESB:
            if (isNaN(opcao_tarifario_id) || isEmptyArray(tariffOptions)) return detailedTariffs;

            //#region 1. get periods
            periods = tariffOptions?.find((option) => parseInt(option?.id) === opcao_tarifario_id)?.periods ?? [];
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 3. build detailedTariffs
            periods?.map((period) => {
                //#region 1. get currentTariffDetail
                let currentTariffDetail;
                if (!isEmptyArray(total_tariffs))
                    currentTariffDetail = total_tariffs?.find((tariff) => parseInt(tariff?.tipo_periodo_id) === parseInt(period.id));
                //#endregion

                //#region 2. get total_tariff_mwh && total_tariff
                let total_tariff = currentTariffDetail?.tarifa ?? (period.default_tariff ? period.default_tariff : '');
                let total_tariff_mwh = total_tariff ? calcConvertionToUnit(total_tariff, 'currencykWhToMWh', decimalPlaces) : '';
                //#endregion

                //#region  3. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff: '',
                    energy_tariff_mwh: '',
                    grid_tariff: '',
                    grid_tariff_mwh: '',
                    total_tariff_mwh,
                    total_tariff,
                });
                //#endregion
                return period;
            });
            //#endregion

            return _sortby(detailedTariffs, ['descricao']);
        case getCompanyProfileIds().EDP_IT: {
            if (isNaN(opcao_tarifario_id)) return detailedTariffs;

            //#region 1. get periods
            periods =
                !isEmptyArray(tariffOptions) ?
                    tariffOptions?.find((item) => parseInt(item.id) === parseInt(opcao_tarifario_id))?.periods ?? []
                :   { periods: [] };
            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 2. get gridTariff
            //filter gridTariffs by nivel_tensao_id
            _gridTariffs = gridTariffs?.filter((tariff) => tariff.nivel_tensao_id === nivel_tensao_id);
            //find gridTariff by range potencia_contratada
            let gridTariffIT = _gridTariffs?.find(
                (gt) =>
                    potencia_contratada >= parseFloat(gt.potencia_contratada[0].value) &&
                    potencia_contratada < parseFloat(gt.potencia_contratada[1].value)
            );
            //#endregion

            //#region 3. build detailedTariffs
            periods?.map((period) => {
                // 1. get energyTariffData
                let energyTariffData =
                    isBuild ? energy_tariffs.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id)) : null;

                //#region 2. get energy_tariff && energy_tariff_mwh
                let energy_tariff = energyTariffData?.tarifa ?? '';
                let energy_tariff_mwh =
                    !isFieldDefined(energy_tariff) ? '' : calcConvertionToUnit(energy_tariff, 'currencykWhToMWh', decimalPlaces);
                //#endregion

                //#region 3. get grid_tariff && grid_tariff_mwh
                let grid_tariff = gridTariffIT?.tarifa ?? '';
                let grid_tariff_mwh = !gridTariffIT ? '' : calcConvertionToUnit(grid_tariff, 'currencykWhToMWh', decimalPlaces);
                //#endregion

                //#region 4. get total_tariff_mwh && total_tariff
                let total_tariff_mwh = '';

                if (isFieldDefined(energy_tariff_mwh) && isFieldDefined(grid_tariff_mwh)) {
                    total_tariff_mwh = parseNumberWithToFixed(energy_tariff_mwh + grid_tariff_mwh, decimalPlaces);
                } else if (isFieldDefined(period.default_tariff)) {
                    total_tariff_mwh = calcConvertionToUnit(period.default_tariff, 'currencykWhToMWh', decimalPlaces);
                    if (isFieldDefined(grid_tariff_mwh) && total_tariff_mwh - grid_tariff_mwh > 0) {
                        energy_tariff_mwh = (total_tariff_mwh - grid_tariff_mwh).toFixed(decimalPlaces);
                        energy_tariff = calcConvertionToUnit(energy_tariff_mwh, 'currencyMWhTokWh', decimalPlaces);
                    } else {
                        energy_tariff = '';
                        energy_tariff_mwh = '';
                    }
                }

                let total_tariff = !isFieldDefined(total_tariff_mwh) ? '' : calcConvertionToUnit(total_tariff_mwh, 'currencyMWhTokWh');
                //#endregion

                //#region  5. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff,
                    energy_tariff_mwh,
                    grid_tariff,
                    grid_tariff_mwh,
                    total_tariff_mwh,
                    total_tariff,
                });
                //#endregion
                return period;
            });
            //#endregion

            return _sortby(detailedTariffs, ['descricao']);
        }
        case getCompanyProfileIds().EDP_PL:
            if (isNaN(opcao_tarifario_id)) return detailedTariffs;

            //#region 1. get periods
            //find periods by distribuidor_id && opcao_tarifario_id
            periods =
                !isEmptyArray(tariffOptions) && isFieldDefined(distribuidor_id) && isFieldDefined(opcao_tarifario_id) ?
                    tariffOptions?.find(
                        (item) => parseInt(item.d_id) === parseInt(distribuidor_id) && parseInt(item.id) === parseInt(opcao_tarifario_id)
                    )?.periods ?? []
                :   { periods: [] };

            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 2. get gridTariffs
            //filter gridTariffs by distribuidor_id && opcao_tarifario_id
            _gridTariffs = gridTariffs.filter(
                (grid) =>
                    parseInt(grid.distribuidor_id) === parseInt(distribuidor_id) &&
                    parseInt(grid.opcao_tarifario_id) === parseInt(opcao_tarifario_id)
            );
            //#endregion

            //#region 3. build detailedTariffs
            periods?.map((period) => {
                // 1. get energyTariffData
                let energyTariffData =
                    isBuild ? energy_tariffs.find((tariff) => parseInt(tariff.tipo_periodo_id) === parseInt(period.id)) : null;

                // 2. get grid by tipo_periodo_id
                let grid = _gridTariffs?.find((item) => parseInt(item.tipo_periodo_id) === parseInt(period.id));

                //#region 3. get energy_tariff && energy_tariff_mwh
                let energy_tariff = energyTariffData?.tarifa ?? '';
                let energy_tariff_mwh =
                    !isFieldDefined(energy_tariff) ? '' : calcConvertionToUnit(energy_tariff, 'currencykWhToMWh', decimalPlaces);
                //#endregion

                //#region 4. get grid_tariff && grid_tariff_mwh
                let grid_tariff = grid?.tarifa ?? '';
                let grid_tariff_mwh =
                    !isFieldDefined(grid_tariff) ? '' : calcConvertionToUnit(grid_tariff, 'currencykWhToMWh', decimalPlaces);
                //#endregion

                //#region 5. get total_tariff_mwh && total_tariff
                let total_tariff_mwh =
                    isFieldDefined(energy_tariff_mwh) && isFieldDefined(grid_tariff_mwh) ?
                        parseNumberWithToFixed(energy_tariff_mwh + grid_tariff_mwh, decimalPlaces)
                    :   '';
                let total_tariff = !isFieldDefined(total_tariff_mwh) ? '' : calcConvertionToUnit(total_tariff_mwh, 'currencyMWhTokWh');
                //#endregion

                //#region  6. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff,
                    energy_tariff_mwh,
                    grid_tariff,
                    grid_tariff_mwh,
                    total_tariff_mwh,
                    total_tariff,
                });
                //#endregion
                return period;
            });
            //#endregion

            return _sortby(detailedTariffs, ['descricao']);
        case getCompanyProfileIds().EDP_BR:
            // inputs mandatory
            if (!(!isNaN(distribuidor_id) && !isNaN(opcao_tarifario_id) && !isNaN(nivel_tensao_id))) return detailedTariffs;

            //#region 1. get periods
            //find periods by opcao_tarifario_id
            periods =
                !isEmptyArray(tariffOptions) && isFieldDefined(opcao_tarifario_id) ?
                    tariffOptions?.find(
                        (item) => parseInt(item.id) === parseInt(opcao_tarifario_id) && parseInt(item.vl_id) === parseInt(nivel_tensao_id)
                    )?.periods ?? []
                :   { periods: [] };

            // 1.1 calc % period
            prc_periodo = parseNumberWithToFixed(1 / periods?.length, 4);
            //#endregion

            //#region 2. get gridTariffs
            //filter gridTariffs by distribuidor_id && opcao_tarifario_id
            _gridTariffs = gridTariffs.filter(
                (grid) =>
                    parseInt(grid.distribuidor_id) === parseInt(distribuidor_id) &&
                    parseInt(grid.opcao_tarifario_id) === parseInt(opcao_tarifario_id) &&
                    parseInt(grid.nivel_tensao_id) === parseInt(nivel_tensao_id)
            );
            //#endregion

            //#region 3. build detailedTariffs
            periods?.map((period) => {
                // 1. get energyTariffData
                let energyTariffData =
                    isBuild ?
                        energy_tariffs.find(
                            (tariff) =>
                                parseInt(tariff.tipo_periodo_id) === parseInt(period.id) &&
                                parseInt(tariff.distribuidor_id) === parseInt(distribuidor_id) &&
                                parseInt(tariff.opcao_tarifario_id) === parseInt(opcao_tarifario_id) &&
                                parseInt(tariff.nivel_tensao_id) === parseInt(nivel_tensao_id)
                        )
                    :   null;

                // 2. get grid by tipo_periodo_id
                let grid = _gridTariffs?.find((item) => parseInt(item.tipo_periodo_id) === parseInt(period.id));

                //#region 3. get energy_tariff && energy_tariff_mwh
                let energy_tariff = getBRTariffs(energyTariffData, clientICMS, classe_id) ?? '';
                let energy_tariff_mwh =
                    !isFieldDefined(energy_tariff) ? '' : calcConvertionToUnit(energy_tariff, 'currencykWhToMWh', decimalPlaces);
                //#endregion

                //#region 4. get grid_tariff && grid_tariff_mwh
                let grid_tariff = getBRTariffs(grid, clientICMS, classe_id) ?? '';
                let grid_tariff_mwh =
                    !isFieldDefined(grid_tariff) ? '' : calcConvertionToUnit(grid_tariff, 'currencykWhToMWh', decimalPlaces);
                //#endregion

                //#region 5. get total_tariff_mwh && total_tariff
                let total_tariff_mwh = '';

                if (isFieldDefined(energy_tariff_mwh) && isFieldDefined(grid_tariff_mwh)) {
                    total_tariff_mwh = parseNumberWithToFixed(energy_tariff_mwh + grid_tariff_mwh, decimalPlaces);
                } else if (isFieldDefined(period.default_tariff)) {
                    total_tariff_mwh = calcConvertionToUnit(period.default_tariff, 'currencykWhToMWh', decimalPlaces);
                    if (isFieldDefined(grid_tariff_mwh) && total_tariff_mwh - grid_tariff_mwh > 0) {
                        energy_tariff_mwh = (total_tariff_mwh - grid_tariff_mwh).toFixed(decimalPlaces);
                        energy_tariff = calcConvertionToUnit(energy_tariff_mwh, 'currencyMWhTokWh', decimalPlaces);
                    } else {
                        energy_tariff = '';
                        energy_tariff_mwh = '';
                    }
                }

                let total_tariff = !isFieldDefined(total_tariff_mwh) ? '' : calcConvertionToUnit(total_tariff_mwh, 'currencyMWhTokWh');
                //#endregion

                //#region  6. add detailedTariffs
                detailedTariffs.push({
                    id: period.id,
                    visible: true,
                    tipo_periodo_id: period.id,
                    descricao: period.description,
                    prc_periodo,
                    energy_tariff,
                    energy_tariff_mwh,
                    grid_tariff,
                    grid_tariff_mwh,
                    total_tariff_mwh,
                    total_tariff,
                });
                //#endregion
                return period;
            });
            //#endregion
            return _sortby(detailedTariffs, ['descricao']);
        default: //deauflt tariffsBy ES // New companies
            //#region 1. get periods
            periods = tariffOptions?.find((option) => parseInt(option?.id) === opcao_tarifario_id)?.periods;

            if (!hasTotalTariffs && periods?.length > 0) {
                const prcPeriod = parseNumberWithToFixed(1 / periods?.length, 4);
                periods.map((period) => {
                    return detailedTariffs.push({
                        id: period.id,
                        visible: true,
                        tipo_periodo_id: period.id,
                        descricao: period.description,
                        prc_periodo: prcPeriod,
                        energy_tariff: '',
                        energy_tariff_mwh: '',
                        grid_tariff: '',
                        grid_tariff_mwh: '',
                        total_tariff_mwh: '',
                        total_tariff: '',
                    });
                });
            }

            //#region 2. build detailedTariffs
            total_tariffs.map((period) => {
                const defaultTariff =
                    period.tarifa ?? periods?.find((p) => parseInt(p.id) === parseInt(period.tipo_periodo_id)).default_tariff;
                //#region  3. add detailedTariffs
                return detailedTariffs.push({
                    id: period.tipo_periodo_id,
                    visible: true,
                    tipo_periodo_id: period.tipo_periodo_id,
                    descricao: period.descricao,
                    prc_periodo: period.prc_periodo,
                    energy_tariff: '',
                    energy_tariff_mwh: '',
                    grid_tariff: '',
                    grid_tariff_mwh: '',
                    total_tariff_mwh: calcConvertionToUnit(defaultTariff, 'currencykWhToMWh', decimalPlaces),
                    total_tariff: defaultTariff,
                });
                //#endregion
            });
            //#endregion
            return _sortby(detailedTariffs, ['descricao']);
    }
};

/**
 * get tarrifs to BR, accodrding to ICMS and class
 * @param {object} tariff
 * @param {boolean} isICMS
 * @param {number} classID
 * @returns
 */
const getBRTariffs = (tariff, isICMS, classID) => {
    let tariffToDisplay;
    if (isICMS) {
        switch (classID) {
            case 1: // Comercial
                tariffToDisplay = tariff?.tarifa;
                break;
            case 2: // Industrial
                tariffToDisplay = tariff?.tarifa_isenta;
                break;
            default:
                break;
        }
    } else {
        tariffToDisplay = tariff?.tarifa;
    }
    return tariffToDisplay;
};

/**
 * getEnergyTariffs obter os valores da matriz detailedTariffs
 *      quando é alterado outros inputs que depende da matriz
 *
 * @param {*} companyProfileId
 * @param {*} tariffOptions
 * @param {*} detailedTariffs
 * @param {*} options
 */
export const getEnergyTariffs = (companyProfileId, tariffOptions, detailedTariffs, options) => {
    let energyTariffsData = [];
    const { opcao_tarifario_id } = options;
    let periods =
        !isEmptyArray(tariffOptions) ?
            _sortby(tariffOptions?.find((item) => parseInt(item.id) === parseInt(opcao_tarifario_id))?.periods, ['id'])
        :   { periods: [] };

    if ([getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
        // get change values
        periods?.map((period, idx) =>
            energyTariffsData.push({
                tipo_periodo_id: parseInt(period.id),
                tarifa:
                    isFieldDefined(detailedTariffs[idx].energy_tariff_mwh) ?
                        calcConvertionToUnit(detailedTariffs[idx].energy_tariff_mwh, 'currencyMWhTokWh')
                    :   '',
            })
        );
        return _sortby(energyTariffsData, ['tipo_periodo_id']);
    }
};

/**
 * Method to get the match between parameters and get usePower Tariff
 * @param {*} usedPowerTariffs
 * @param {*} options
 * @returns
 */
export const getUsedPowerTariff = (usedPowerTariffs, options) => {
    const { watchOpcaoTarifarioID, watchNivelTensaoID } = options;

    let usedPowerTariff = usedPowerTariffs?.find(
        (tariff) => watchNivelTensaoID === tariff?.nivel_tensao_id && watchOpcaoTarifarioID === tariff.opcao_tarifario_id
    );

    return parseNumberWithToFixed(usedPowerTariff?.tarifa_kw_dia, 4);
};

/**
 * calcRowPeriodTariffsElectricityMeanMWh
 * @description calcula os campos campos en faltas com base ao input alterado
 *
 * @param {*} inputName
 * @param {*} inputValue
 * @param {*} tariffs (row)
 * @param {*} companyProfileId
 * @param {*} isValidValue
 *
 */
export const calcRowPeriodTariffsElectricityMeanMWh = (inputName, inputValue, tariffs, companyProfileId) => {
    let isValidValue = !isNaN(inputValue);
    let decimalPlaces = 2;

    let onlyTotalTariff = !isFieldDefined(tariffs?.grid_tariff_mwh);
    // se não for input valido, reset values
    if (!isValidValue) {
        tariffs.energy_tariff_mwh = tariffs.energy_tariff = tariffs.total_tariff_mwh = tariffs.total_tariff = '';
        return tariffs;
    }

    //#region inputName === energy_tariff_mwh
    if (inputName === 'energy_tariff_mwh') {
        //#region energy_tariff
        let energy_tariff_mwh = !isNaN(inputValue) ? parseNumberWithToFixed(inputValue, decimalPlaces) : '';
        tariffs.energy_tariff_mwh = energy_tariff_mwh;
        let energy_tariff = !isNaN(energy_tariff_mwh) ? calcConvertionToUnit(energy_tariff_mwh, 'currencyMWhTokWh') : '';
        tariffs.energy_tariff = energy_tariff;
        //#endregion

        //#region total_tariff
        let total_tariff_mwh =
            !isNaN(energy_tariff_mwh) && !isNaN(tariffs.grid_tariff_mwh) ?
                parseNumberWithToFixed(energy_tariff_mwh + tariffs.grid_tariff_mwh, decimalPlaces)
            :   '';
        tariffs.total_tariff_mwh = total_tariff_mwh;
        let total_tariff = !isNaN(energy_tariff_mwh) ? calcConvertionToUnit(total_tariff_mwh, 'currencyMWhTokWh') : '';
        tariffs.total_tariff = total_tariff;
        //#endregion
    }
    //#endregion

    //#region inputName === total_tariff_mwh
    if (inputName === 'total_tariff_mwh') {
        //#region total_tariff
        let total_tariff_mwh = !isNaN(inputValue) ? parseNumberWithToFixed(inputValue, decimalPlaces) : '';
        tariffs.total_tariff_mwh = total_tariff_mwh;
        let total_tariff = !isNaN(total_tariff_mwh) ? total_tariff_mwh : '';
        tariffs.total_tariff = total_tariff;
        //#endregion

        //#region energy_tariff
        //apenas para PL e IT e PT
        if ([getCompanyProfileIds().EDP_PL, getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
            let calcEnergyTariff_mwh =
                !isNaN(total_tariff_mwh) && !isNaN(tariffs.grid_tariff_mwh) ? total_tariff_mwh - tariffs.grid_tariff_mwh : '';
            if (!isNaN(total_tariff_mwh) && calcEnergyTariff_mwh >= 0) {
                // energy_tariff_mwh
                tariffs.energy_tariff_mwh = parseNumberWithToFixed(calcEnergyTariff_mwh, decimalPlaces);
                tariffs.energy_tariff = calcConvertionToUnit(calcEnergyTariff_mwh, 'currencyMWhTokWh');
            } else {
                //se o calc for < 0, reset input energy_tariff_mwh
                tariffs.energy_tariff_mwh = tariffs.energy_tariff = '';
            }
        }
        //#endregion
    }
    //#endregion

    if (onlyTotalTariff) {
        tariffs.energy_tariff_mwh = tariffs?.total_tariff_mwh;
        tariffs.energy_tariff = tariffs.total_tariff;
    }
    return tariffs;
};

/**
 * getPayloadDetailedTariffs
 * @description obter o payload das tarifas com base ao companyProfileId
 *
 * @param {*} companyProfileId
 * @param {*} detailedTariffs
 */
export const getPayloadDetailedTariffs = async (companyProfileId, detailedTariffs) => {
    let energy_tariffs = [];
    let total_tariffs = [];

    switch (companyProfileId) {
        case getCompanyProfileIds().EDP_PT:
        case getCompanyProfileIds().ESB:
        case getCompanyProfileIds().NRG:
        case getCompanyProfileIds().EDP_BR:
        case getCompanyProfileIds().EDP_ES:
        case getCompanyProfileIds().EDP_IT:
        case getCompanyProfileIds().EDP_PL:
            detailedTariffs.map((row) => {
                let descricao = row.descricao;
                let prc_periodo = row.prc_periodo;
                let tipo_periodo_id = row.tipo_periodo_id;
                if (
                    [getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_PL, getCompanyProfileIds().EDP_PT].includes(companyProfileId)
                ) {
                    energy_tariffs.push({
                        descricao,
                        tarifa: calcConvertionToUnit(row.energy_tariff_mwh, 'currencyMWhTokWh', NUMBER_DECIMALS_TARIFFS),
                        tipo_periodo_id,
                    });
                }

                total_tariffs.push({
                    descricao,
                    prc_periodo,
                    tarifa: calcConvertionToUnit(row.total_tariff_mwh, 'currencyMWhTokWh', NUMBER_DECIMALS_TARIFFS),
                    tipo_periodo_id,
                });
                return row;
            });
            return {
                energy_tariffs,
                total_tariffs,
            };
        default: //default
            detailedTariffs.map((row) => {
                let descricao = row.descricao;
                let prc_periodo = row.prc_periodo;
                let tipo_periodo_id = row.tipo_periodo_id;
                total_tariffs.push({
                    descricao,
                    prc_periodo,
                    tarifa: calcConvertionToUnit(row.total_tariff_mwh, 'currencyMWhTokWh', NUMBER_DECIMALS_TARIFFS),
                    tipo_periodo_id,
                });
                return row;
            });
            return {
                energy_tariffs,
                total_tariffs,
            };
    }
};
/**
 * Conversion to format days of work
 *
 * @param {*} daysOfWord
 */
export const toFormatDaysOfWork = (daysOfWord) => {
    return {
        [INPUT_NAMES.TRABALHA_2A]: daysOfWord?.[0],
        [INPUT_NAMES.TRABALHA_3A]: daysOfWord?.[0],
        [INPUT_NAMES.TRABALHA_4A]: daysOfWord?.[0],
        [INPUT_NAMES.TRABALHA_5A]: daysOfWord?.[0],
        [INPUT_NAMES.TRABALHA_6A]: daysOfWord?.[0],
        [INPUT_NAMES.TRABALHA_SAB]: daysOfWord?.[1],
        [INPUT_NAMES.TRABALHA_DOM]: daysOfWord?.[2],
        [INPUT_NAMES.TRABALHA_FERIADO]: daysOfWord?.[3],
    };
};

/**
 * Conversion to format schedule
 *
 * @param {*} inputs
 */
export const toFormatSchedule = (inputs) => {
    if (!inputs?.[INPUT_NAMES.HORARIO_DIAS_SEMANA]) return inputs?.[INPUT_NAMES.HORARIO_DIAS_SEMANA];

    return {
        [INPUT_NAMES.HORARIO_DIAS_SEMANA]: [
            convertDateTimeToString(inputs?.[INPUT_NAMES.HORARIO_DIAS_SEMANA]?.[0]),
            convertDateTimeToString(inputs?.[INPUT_NAMES.HORARIO_DIAS_SEMANA]?.[1]),
        ],
        [INPUT_NAMES.HORARIO_SABADO]: [
            convertDateTimeToString(inputs?.[INPUT_NAMES.HORARIO_SABADO]?.[0]),
            convertDateTimeToString(inputs?.[INPUT_NAMES.HORARIO_SABADO]?.[1]),
        ],
        [INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]: [
            convertDateTimeToString(inputs?.[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]?.[0]),
            convertDateTimeToString(inputs?.[INPUT_NAMES.HORARIO_DOMINGO_E_FERIADO]?.[1]),
        ],
    };
};

/**
 * validateDetailedPeriodTariff
 *
 * @param {*} setError
 * @param {*} detailedTariffs
 */
export function validateDetailedPeriodTariff(detailedTariffs, setError, clearErrors, companyProfileId) {
    let isValid = true;

    detailedTariffs.map((row, idx) => {
        if ([getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_PL, getCompanyProfileIds().EDP_PT].includes(companyProfileId)) {
            // energy_tariff
            let invalidEnergyTariff = isNaN(parseFloat(row.energy_tariff_mwh));
            if (invalidEnergyTariff) {
                setError(`detailed_tariffs[${idx}].energy_tariff_mwh`, {
                    type: 'manual',
                    message: 'yup.message.required',
                });
                isValid = false;
            } else if (parseFloat(row.energy_tariff_mwh) <= 0) {
                setError(`detailed_tariffs[${idx}].energy_tariff_mwh`, {
                    type: 'manual',
                    message: 'yup.message.number.min',
                });
                isValid = false;
            } else {
                // clearErrors(`detailed_tariffs[${idx}].energy_tariff_mwh`)
            }
            let invalidGridTariff = !isFieldDefined(row.grid_tariff_mwh);
            if (invalidGridTariff) {
                setError(`detailed_tariffs[${idx}].grid_tariff_mwh`, {
                    type: 'manual',
                    message: 'error.detailedTariffs.grid_tariff',
                });
                isValid = false;
            }
        }

        // total_tariff
        let invalidTotalTariff = isNaN(parseFloat(row.total_tariff_mwh));
        if (invalidTotalTariff || parseFloat(row.total_tariff_mwh) < parseFloat(row.grid_tariff_mwh)) {
            setError(`detailed_tariffs[${idx}].total_tariff_mwh`, {
                type: 'manual',
                message:
                    (
                        [getCompanyProfileIds().EDP_IT, getCompanyProfileIds().EDP_PL, getCompanyProfileIds().EDP_PT].includes(
                            companyProfileId
                        )
                    ) ?
                        'error.detailedTariffs.total_tariff'
                    :   'yup.message.required',
            });
            isValid = false;
        } else {
            // clearErrors(`detailed_tariffs[${idx}].total_tariff_mwh`)
        }

        return row;
    });

    if (isValid) {
        clearErrors('detailed_tariffs');
    }

    return isValid;
}

export function hasDetailedTariffsVisible(companyProfileId, watchNivelTensaoID, watchPotenciaContratada, watchOpcaoTarifarioID) {
    switch (companyProfileId) {
        case getCompanyProfileIds().EDP_IT:
            return isFieldDefined(watchNivelTensaoID) && isFieldDefined(watchPotenciaContratada) && isFieldDefined(watchOpcaoTarifarioID);
        default:
            return true;
    }
}

/**
 * isInvalidCAE
 *
 * @param {*} cae
 */
export function isInvalidCAE(cae) {
    return (
        !isFieldDefined(cae) ||
        cae?.length < CAE_LENGTH_LIMITS.DEFAULT.min ||
        cae?.length > CAE_LENGTH_LIMITS.DEFAULT.max ||
        !VALID_CAES.includes(cae?.substring(0, 2))
    );
}

/**
 * isInvalidAnnualConsumption
 *
 * @param {*} annualConsumption
 */
export function isInvalidAnnualConsumption(annualConsumption) {
    return (
        ANNUAL_CONSUMPTION_LIMITS.DEFAULT.max < parseFloat(annualConsumption) ||
        ANNUAL_CONSUMPTION_LIMITS.DEFAULT.minNI >= parseFloat(annualConsumption)
    );
}

/**
 * getPayloadClient
 *
 * @param {*} payload
 */
export function getPayloadClient(payload) {
    const _payload = {};
    //Format payload
    _payload.id = _payload.id = payload.clientID;
    _payload.body = clone(payload);
    //Fallback no CAE5
    if (!_payload?.body?.cae5) {
        _payload.body.cae5 = _payload.body.cae2;
    }
    if (_payload?.body?.cae5 === '') {
        delete _payload?.body?.cae5;
    }
    if (_payload?.body?.cae2 === '') {
        delete _payload?.body?.cae2;
    }
    //Remove no need attributes
    delete _payload?.body?.clientCAE5;
    delete _payload?.body?.consumo_anual;
    delete _payload?.body?.clientID;
    delete _payload?.body?.facilityID;

    return _payload;
}

/**
 * getPayloadFacility
 *
 * @param {*} payload
 */
export function getPayloadFacility(payload) {
    const _payload = {};
    //Format payload
    _payload.id = _payload.id = payload.facilityID;
    _payload.body = clone(payload);

    //Remove no need attributes
    delete _payload?.body?.consumo_anual_mwh;
    delete _payload?.body?.facilityID;
    delete _payload?.body?.facilityConsumptionWeightSum;
    delete _payload?.body?.monthly_consumptions;
    delete _payload?.body?.consumption_weights;
    if (!_payload?.body?.consumo_anual) {
        delete _payload?.body?.consumo_anual;
    }
    return _payload;
}

/**
 * invalidDataForCAERequest
 *
 * @param {*} payload
 */
export function invalidDataForCAERequest(inputs, value) {
    return isInvalidCAE(value);
}

/**
 * getMonthlyConsumptionFromDiff
 *
 * @param {*} diff
 * @param {*} monthlyConsumption
 * @param {*} annualConsumption
 */
export function getMonthlyConsumptionFromDiff(diff, monthlyConsumption, options) {
    const { annualConsumption, oldAnnualConsumption } = options;
    if (!isDefined(diff)) diff = 1;

    if (oldAnnualConsumption === 0)
        monthlyConsumption = clone(calcWeightsSumAndMonthlyConsumption(monthlyConsumption, annualConsumption)?.facilityMonthlyConsumption);

    //Increase or decrease consumption values
    return monthlyConsumption?.map((item) => {
        return {
            ...item,
            monthly_consumption: item.monthly_consumption * diff,
        };
    });
}

/**
 * createFacilityIsAvaliable
 *
 * @param {*} isClientSelected
 * @param {*} companyProfileId
 */

export function createFacilityIsAvaliable(userPermission, isClientSelected) {
    return isClientSelected && userPermission;
}
