import { useCallback } from 'react';
import FormResetPassword from 'components/Forms/ResetPassword';
import IntlMessages from 'components/util/IntlMessages';
import { isS2CPlatformVersion } from 'services/settings';

import { ReactComponent as PlatformLogo } from 'assets/@efz/logos/logo-sales-vertical.svg';
import { ReactComponent as NGBackground } from 'assets/images/auth/ng/login.svg';

// Containers
import FooterContainer from 'pages/Restricted/shared/Footer';
import ImageCustom from 'components/core/ImageCustom';
import useResetPawword from 'hooks/auth/useResetPassword';
import { StyledLogin, StyledLoginContainer, StyledLoginContent, StyledLoginSVGBG } from 'pages/auth/LoginStyles';

const ResetPassword = () => {
    const { requesting, resetPassword } = useResetPawword();
    const isS2CVersion = isS2CPlatformVersion();

    // Send reset password request
    const handleOnSubmit = useCallback((payload) => {
        resetPassword(payload?.email ?? '');
    }, []); //eslint-disable-line

    return (
        <StyledLogin $isS2CVersion={!!isS2CVersion}>
            <StyledLoginSVGBG>
                {!isS2CVersion ?
                    <div className="login-svg-ng">
                        <NGBackground />
                    </div>
                :   <div className="login-svg-s2c" />}
            </StyledLoginSVGBG>

            <StyledLoginContainer>
                <StyledLoginContent>
                    {/* Header */}
                    {!isS2CVersion ?
                        <div className="login-header">
                            <PlatformLogo />
                        </div>
                    :   <div className="login-header-s2c">
                            <ImageCustom alt="save-to-compete-logo" src={`assets/images/auth/s2c/save-to-compete-logo.svg`} />
                        </div>
                    }
                    {/* Body */}
                    <div className="login-body">
                        <h4>
                            <IntlMessages id={'page.login.subtitle'} />
                        </h4>
                        <FormResetPassword handleOnSubmit={handleOnSubmit} isRequesting={requesting} isS2CVersion={isS2CVersion} />
                    </div>
                </StyledLoginContent>
            </StyledLoginContainer>
            <FooterContainer isUserAuthenticated={false} />
        </StyledLogin>
    );
};

export default ResetPassword;
