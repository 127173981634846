import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import { ReactComponent as WarningBlueIcon } from 'assets/images/icons/svg/pap-warning-blue.svg';
import ErrorMessage from 'components/errors/ErrorMessage';
import CustomUnit from 'components/util/CustomUnit';
import IntlMessages from 'components/util/IntlMessages';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { UserContext } from 'contexts/userContext';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { getCompanyProfileIds } from 'services/user';
import { generateNumberArray, intlMessages, isDefined } from 'services/util/auxiliaryUtils';
import validate from 'services/util/validate';
import { IconButton } from '@save2compete/efz-design-system';

function getNextHighestIndex(arr, number) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] > number) {
            return arr[i];
        }
    }
}

function getLowerHighestIndex(arr, number) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] >= number) {
            return arr[i - 1];
        }
    }
}

const FinanceCustomizationPPaDetailed = ({ showOfferSummary }) => {
    const {
        bmState,
        // @ts-ignore
        bmState: {
            loadings,
            elements,
            kpis: {
                kpis: {
                    system: { configs },
                },
            },
        },
    } = useContext(OfferEditionContext);

    const { companyProfileId } = useContext(UserContext);

    const financing_customization = bmState?.businessModelSelected?.financing_customization ?? null;
    const monthlyPayments = elements?.monthlyPayments?.options?.options;
    const canEditPaymentMonthly = financing_customization?.edit_monthly_payments_number ?? true;

    const MAX_ANNUAL_FEE = Number(elements?.annualUpdateMonthlyFee?.options?.options?.[1]) * 100 ?? 0;
    const defaultAnnualFeeDefaults = generateNumberArray(
        Number(elements?.annualUpdateMonthlyFee?.options.options[0]) * 100,
        Number(elements.annualUpdateMonthlyFee?.options.options[1]) * 100,
        Number(elements.annualUpdateMonthlyFee?.options.step) * 100
    );

    const MAX_UNIQUE_ALLOWANCE = Number(elements?.uniqueAllowance?.options?.options?.[1]);
    const MAX_YEARLY_ALLOWANCE = Number(elements?.yearlyAllowance?.options?.[0]?.options?.[1]);
    const MAX_YEARLY_ALLOWANCE_YEARS = Number(elements?.yearlyAllowance?.options?.[1]?.options?.[1]) / 12;

    const {
        control,
        formState: { errors },
        watch,
        setValue,
        setError,
        clearErrors,
    } = useFormContext();

    const watchAnnualFee = watch('annualEvoFee');
    const watchAllowance = watch('allowance');

    const addAnnualEvo = () => {
        const actualFee = Number(watchAnnualFee);
        const next = getNextHighestIndex(defaultAnnualFeeDefaults, actualFee);
        if (!isDefined(next) || next > MAX_ANNUAL_FEE) return;
        setValue('annualEvoFee', Number(next));
    };

    const decreaseAnnualEvo = () => {
        const actualFee = Number(watchAnnualFee);
        const next = getLowerHighestIndex(defaultAnnualFeeDefaults, actualFee);
        if (next < 0) return;
        setValue('annualEvoFee', Number(next));
    };

    const disableAnnualFeeConditions =
        [getCompanyProfileIds().EDP_IT].includes(companyProfileId) && watchAnnualFee === 0 && loadings.general;

    const onChangeAllowance = (field, e) => {
        field.onChange(e);
        switch (e.target.value) {
            case 'unique':
                //setValue('yearly_allowance_currency', '');
                !!errors['yearly_allowance_currency'] && clearErrors('yearly_allowance_currency');
                //setValue('yearly_allowance_duration_years', '');
                !!errors['yearly_allowance_duration_years'] && clearErrors('yearly_allowance_duration_years');
                break;
            case 'yearly':
                //setValue('unique_allowance_currency', '');
                !!errors['unique_allowance_currency'] && clearErrors('unique_allowance_currency');
                break;
            default:
                break;
        }
    };

    const onClickRadioBtn = (e) => {
        if (e.target.value === watch('allowance')) {
            setValue('allowance', '');
            !!errors['unique_allowance_currency'] && clearErrors('unique_allowance_currency');
            !!errors['yearly_allowance_currency'] && clearErrors('yearly_allowance_currency');
            !!errors['yearly_allowance_duration_years'] && clearErrors('yearly_allowance_duration_years');
            return;
        }
    };

    return (
        <>
            <section className={`${showOfferSummary ? 'bm-detailed-offer-summary-financee' : ''} w-100`}>
                {/* show_monthly_payments_number */}
                {isDefined(elements.monthlyPayments) ?
                    <div className="input-container">
                        <label>
                            <IntlMessages id={'page.businessModels.editOffer.numberOfInstallments'} />
                        </label>
                        <Controller
                            name="monthly_payment_option_id"
                            control={control}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            render={({ field: { ref, ...field } }) => (
                                <Select
                                    {...field}
                                    inputProps={{ className: 'd-flex align-items-center' }}
                                    disabled={loadings.general || !canEditPaymentMonthly}
                                >
                                    <MenuItem value={''} disabled={true}>
                                        <IntlMessages id={'label.selectOption'} />
                                    </MenuItem>
                                    {monthlyPayments?.map((month, index) => {
                                        return (
                                            <MenuItem key={`monthly_payment_options_${index}`} value={month}>
                                                <IntlMessages
                                                    id={`label.n${elements?.monthlyPayments?.options?.unity === 'years' ? 'Years' : 'Months'}`}
                                                    values={{
                                                        number: month / (elements?.monthlyPayments?.options?.unity === 'years' ? 12 : 1),
                                                    }}
                                                />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        />
                    </div>
                :   <></>}

                {/* show_interest_rate */}
                {isDefined(elements.annualUpdateMonthlyFee) ?
                    <div className="input-container">
                        <label>
                            <IntlMessages id={'page.businessModels.editOffer.annualEvoFee'} />
                        </label>
                        <div className="position-relative">
                            <Controller
                                name="annualEvoFee"
                                control={control}
                                rules={validate(['required', 'number', 'max'], { max: MAX_ANNUAL_FEE })}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ field, fieldState }) => {
                                    return (
                                        <NumberFormat
                                            {...field}
                                            customInput={OutlinedInput}
                                            disabled={[getCompanyProfileIds().EDP_IT].includes(companyProfileId) && watchAnnualFee === 0}
                                            placeholder={intlMessages('label.insertValue')}
                                            allowNegative={false}
                                            decimalScale={2}
                                            className="w-100"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {/* @ts-ignore */}
                                                    <CustomUnit unit={elements?.annualUpdateMonthlyFee?.options?.unity} />
                                                    <div className="input-container-decimal-buttons">
                                                        <IconButton
                                                            variant="tertiary"
                                                            icon="arrowHeadUp"
                                                            size="mn"
                                                            disabled={disableAnnualFeeConditions}
                                                            onClick={addAnnualEvo}
                                                            dataTestId="arrow-up-increase-annual-fee"
                                                        />

                                                        <IconButton
                                                            variant="tertiary"
                                                            icon="arrowHeadDown"
                                                            size="mn"
                                                            disabled={disableAnnualFeeConditions}
                                                            onClick={decreaseAnnualEvo}
                                                            dataTestId="arrow-down-decrease-annual-fee"
                                                        />
                                                    </div>
                                                </InputAdornment>
                                            }
                                            isAllowed={(values) => {
                                                if (values?.floatValue && values?.floatValue > MAX_ANNUAL_FEE) {
                                                    return false;
                                                }
                                                return true;
                                            }}
                                            onValueChange={(values) => {
                                                field.onChange(values.floatValue);
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className="input-container-info">
                            <WarningBlueIcon height={15} width={15} />
                            <IntlMessages id="yup.dynamic.valuesBetween" values={{ max: MAX_ANNUAL_FEE, min: 0 }} />
                        </div>
                        {/* @ts-ignore */}
                        {!!errors['annualEvoFee'] && <ErrorMessage error={errors['annualEvoFee']} values={{ max: MAX_ANNUAL_FEE }} />}
                    </div>
                :   <></>}

                {/* show_allowance */}
                {isDefined(elements.uniqueAllowance) && isDefined(elements.yearlyAllowance) ?
                    <div className="input-container-allowance">
                        <label>
                            <IntlMessages id={'page.businessModels.editOffer.allowance'} />
                            <span className="mini-label ms-1">
                                <IntlMessages id={'label.optional'} />
                            </span>
                        </label>
                        <div className="position-relative w-100">
                            <Controller
                                name="allowance"
                                control={control}
                                //rules={validate(['max'], { max: Math.max(MAX_UNIQUE_ALLOWANCE, MAX_YEARLY_ALLOWANCE) })}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ field: { ref, ...field } }) => {
                                    return (
                                        <FormControl>
                                            <RadioGroup
                                                {...field}
                                                defaultValue=""
                                                name="allowance"
                                                onChange={(e) => onChangeAllowance(field, e)}
                                            >
                                                <div className="d-flex flex-row justify-content-between align-items-start">
                                                    <FormControlLabel
                                                        value="unique"
                                                        control={
                                                            <Radio checked={watch('allowance') === 'unique'} onClick={onClickRadioBtn} />
                                                        }
                                                        label={<IntlMessages id="page.businessModels.editOffer.allowance.unique" />}
                                                        className="text-nowrap"
                                                    />
                                                    {watchAllowance === 'unique' && (
                                                        <div className="input-container-allowance-inner">
                                                            <Controller
                                                                name="unique_allowance_currency"
                                                                control={control}
                                                                // defaultValue = value x nr_panels x each panel area
                                                                defaultValue={Number(
                                                                    parseFloat(
                                                                        String(elements?.uniqueAllowance?.options?.default)?.split(' ')?.[0]
                                                                    ) *
                                                                        configs?.panels_number *
                                                                        (configs?.panel?.altura * configs?.panel?.largura)
                                                                )}
                                                                rules={validate(['max', 'required'], {
                                                                    max: MAX_UNIQUE_ALLOWANCE,
                                                                })}
                                                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                                                render={({ field: { ref, ...field } }) => (
                                                                    <NumberFormat
                                                                        {...field}
                                                                        customInput={OutlinedInput}
                                                                        placeholder={intlMessages('label.insertValue')}
                                                                        allowNegative={false}
                                                                        decimalScale={2}
                                                                        className="unique-input"
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                {/* @ts-ignore */}
                                                                                <CustomUnit unit="currency" />
                                                                            </InputAdornment>
                                                                        }
                                                                        onValueChange={(values) => {
                                                                            setValue('unique_allowance_currency', values.floatValue);
                                                                            if (
                                                                                values?.floatValue &&
                                                                                values?.floatValue > MAX_UNIQUE_ALLOWANCE
                                                                            ) {
                                                                                setError('unique_allowance_currency', {
                                                                                    type: 'manual',
                                                                                    message: 'yup.message.number.max',
                                                                                });
                                                                            } else if (!values?.floatValue) {
                                                                                setError('unique_allowance_currency', {
                                                                                    type: 'manual',
                                                                                    message: 'yup.message.required',
                                                                                });
                                                                            } else {
                                                                                !!errors['unique_allowance_currency'] &&
                                                                                    clearErrors('unique_allowance_currency');
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {/* @ts-ignore */}
                                                            {!!errors['unique_allowance_currency'] && (
                                                                <ErrorMessage
                                                                    error={errors['unique_allowance_currency']}
                                                                    values={{ max: MAX_UNIQUE_ALLOWANCE }}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-start">
                                                    <FormControlLabel
                                                        value="yearly"
                                                        control={
                                                            <Radio checked={watch('allowance') === 'yearly'} onClick={onClickRadioBtn} />
                                                        }
                                                        label={<IntlMessages id="page.businessModels.editOffer.allowance.yearly" />}
                                                        className="text-nowrap"
                                                    />
                                                    {watchAllowance === 'yearly' && (
                                                        <div className="d-flex flex-row column-gap-1">
                                                            <div className="input-container-allowance-inner">
                                                                <Controller
                                                                    name="yearly_allowance_currency"
                                                                    control={control}
                                                                    defaultValue={Number(elements?.yearlyAllowance?.options?.[0]?.default)}
                                                                    rules={validate(['max', 'required'], {
                                                                        max: MAX_YEARLY_ALLOWANCE,
                                                                    })}
                                                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                                                    render={({ field: { ref, ...field } }) => (
                                                                        <NumberFormat
                                                                            {...field}
                                                                            customInput={OutlinedInput}
                                                                            placeholder={intlMessages('label.insertValue')}
                                                                            allowNegative={false}
                                                                            decimalScale={2}
                                                                            className="yearly-value-input"
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    {/* @ts-ignore */}
                                                                                    <CustomUnit unit="currency" />
                                                                                </InputAdornment>
                                                                            }
                                                                            onValueChange={(values) => {
                                                                                setValue('yearly_allowance_currency', values.floatValue);
                                                                                if (
                                                                                    values?.floatValue &&
                                                                                    values?.floatValue > MAX_YEARLY_ALLOWANCE
                                                                                ) {
                                                                                    setError('yearly_allowance_currency', {
                                                                                        type: 'manual',
                                                                                        message: 'yup.message.number.max',
                                                                                    });
                                                                                } else if (!values?.floatValue) {
                                                                                    setError('yearly_allowance_currency', {
                                                                                        type: 'manual',
                                                                                        message: 'yup.message.required',
                                                                                    });
                                                                                } else {
                                                                                    !!errors['yearly_allowance_currency'] &&
                                                                                        clearErrors('yearly_allowance_currency');
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {/* @ts-ignore */}
                                                                {!!errors['yearly_allowance_currency'] && (
                                                                    <ErrorMessage
                                                                        error={errors['yearly_allowance_currency']}
                                                                        values={{ max: MAX_YEARLY_ALLOWANCE }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="input-container-allowance-inner">
                                                                <Controller
                                                                    name="yearly_allowance_duration_years"
                                                                    control={control}
                                                                    defaultValue={
                                                                        Number(elements?.yearlyAllowance?.options?.[1]?.default) / 12
                                                                    }
                                                                    rules={validate(['max', 'required'], {
                                                                        max: MAX_YEARLY_ALLOWANCE_YEARS,
                                                                    })}
                                                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                                                    render={({ field: { ref, ...field } }) => (
                                                                        <NumberFormat
                                                                            {...field}
                                                                            customInput={OutlinedInput}
                                                                            placeholder={intlMessages('label.insertValue')}
                                                                            allowNegative={false}
                                                                            decimalScale={0}
                                                                            className="yearly-years-input w-100"
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    {/* @ts-ignore */}
                                                                                    <CustomUnit unit="year" />
                                                                                </InputAdornment>
                                                                            }
                                                                            onValueChange={(values) => {
                                                                                setValue(
                                                                                    'yearly_allowance_duration_years',
                                                                                    values.floatValue
                                                                                );
                                                                                if (
                                                                                    values?.floatValue &&
                                                                                    values?.floatValue > MAX_YEARLY_ALLOWANCE_YEARS
                                                                                ) {
                                                                                    setError('yearly_allowance_duration_years', {
                                                                                        type: 'manual',
                                                                                        message: 'yup.message.number.max',
                                                                                    });
                                                                                } else if (!values?.floatValue) {
                                                                                    setError('yearly_allowance_duration_years', {
                                                                                        type: 'manual',
                                                                                        message: 'yup.message.required',
                                                                                    });
                                                                                } else {
                                                                                    !!errors['yearly_allowance_duration_years'] &&
                                                                                        clearErrors('yearly_allowance_duration_years');
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {/* @ts-ignore */}
                                                                {!!errors['yearly_allowance_duration_years'] && (
                                                                    <ErrorMessage
                                                                        error={errors['yearly_allowance_duration_years']}
                                                                        values={{
                                                                            max: MAX_YEARLY_ALLOWANCE_YEARS,
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                    );
                                }}
                            />
                        </div>
                    </div>
                :   <></>}
            </section>
        </>
    );
};

export default FinanceCustomizationPPaDetailed;
