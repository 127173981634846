import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { TGrants } from './upfront';
import { TRubric } from 'interfaces/rubric';

export type Tlec = typeof PAYMENT_MODELS_IDS.LEC;
export type TLECValues = {
    kpis_per_margin: TMarginLEC[];
    marginSelected?: number;
    grants: { totalGrants: { prc: number; currency: number }; grants: TGrants[]; hasGrants: boolean };
    offerEdition: TLECOfferEdition;
    proposalReady: boolean;
    costs: {
        baseCost: number;
        offerDetailsCost: number;
        marginCost: number;
        grantsCost: number;
        totalCost: number;
    };
    rubrics: {
        cost: number;
        values: TRubric[];
    };
    syncKpis?: {
        [key: string]: any;
        negotiation: {
            [key: string]: any;
            hasErrorOnNetSavingsEligibility: boolean;
            status_risk_premium_id: number;
        };
    };
    clientContactInfo?: {
        name: string;
        email: string;
    };
    id_crm?: string;
};

export type TLECOfferEdition = {
    extra_cost?: number;
};

export type TMarginLEC = {
    annual_estimated_price: number;
    annual_net_saving: number;
    is_default: false;
    lec_tariff: number;
    lec_tariff_discount: number;
    saving_share: number;
    annual_saving: number;
    additional_value?: number;
    additional_value_prc?: number;
    lec_energy_tariff?: number;
    lec_annual_saving?: number;
};

export enum LECEvents {
    SET_LEC_BM = 'SET_LEC_BM',
    SET_LEC_MARGIN = 'SET_LEC_MARGIN',
    SET_LEC_EXTRA_COST = 'SET_LEC_EXTRA_COST',
    UPDATE_LEC_VALUES_BM = 'UPDATE_LEC_VALUES_BM',
    SET_LEC_PROPOSAL_READY = 'SET_LEC_PROPOSAL_READY',
    SET_LEC_PROPOSAL = 'SET_LEC_PROPOSAL',
    UPDATE_LEC_ADDITIONAL_DATA = 'UPDATE_LEC_ADDITIONAL_DATA',
    ADD_LEC_GRANT = 'ADD_LEC_GRANT',
    DELETE_LEC_GRANT = 'DELETE_LEC_GRANT',
    SET_DESCRIPTION_GRANT = 'SET_DESCRIPTION_GRANT',
    SET_AMMOUNT_GRANT = 'SET_AMMOUNT_GRANT',
    UPDATE_LEC_GRANTS = 'UPDATE_LEC_GRANTS',
    SET_LEC_RUBRIC_COST = 'SET_LEC_RUBRIC_COST',
}

export type TLECEventsValues<Event> =
    Event extends typeof LECEvents.SET_LEC_BM ? { kpis_per_margin: TMarginLEC[]; proposalReady: true }
    : Event extends typeof LECEvents.SET_LEC_MARGIN ? number
    : Event extends typeof LECEvents.UPDATE_LEC_VALUES_BM ?
        {
            offerEdition: {
                extra_cost: number;
            };
        }
    : Event extends typeof LECEvents.SET_LEC_PROPOSAL_READY ? boolean
    : Event extends typeof LECEvents.SET_LEC_PROPOSAL ? null
    : any;
