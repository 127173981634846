/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Validação do routeamento e validação de acesso ao Contanier
 *
 * @version 20191001
 * @since 20191001 Initial release
 *
 */
import PropTypes from 'prop-types';
import { lazy, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// contexts
import { SPVProContextProvider } from 'contexts/products/solarpv/spvProContext';

//Actions
import { resetBusinessModels } from 'redux/actions/businessModels';
import { resetProductInputs, resetProductOptions, setProductModules } from 'redux/actions/product';
import { resetProposal } from 'redux/actions/proposal';
import { resetSizing } from 'redux/actions/sizing';

//Constants
import { ALLOWED_MODULES, ALLOWED_SUB_PRODUCTS, PRODUCT_IDS } from 'constants/products';
import { BASENAME_URL_APP } from 'constants/settings';

//Selectors
import { getClientID } from 'redux/selectors/client';
import { getFacilityID } from 'redux/selectors/facility';
import { getIsIncreasePower, getIsSPVPro, getProductId } from 'redux/selectors/product';

import { EVCProProvider } from 'contexts/products/evcPro/evcProContext';
import { RRProvider } from 'contexts/products/rr/rrContext';
import { SPVSimpleProvider } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { SPVMaintenanceProvider } from 'contexts/products/spvMaintenance/spvMaintenanceContext';
import { CustomizeProvider } from 'contexts/products/solarpv/customize/customizeContext';
import lazyLoader from 'services/lazyLoader';
import { getCompanyProfileIds } from 'services/user';
import { isDefined } from 'services/util/auxiliaryUtils';
import { UserContext } from 'contexts/userContext';
import { useBusinessModelsStore } from 'store/businessModels';

//Components
const Error403 = lazy(() => lazyLoader(() => import('components/errors/Error403')));
//Containers
const CEContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Products/CE')));
const CFPContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Products/CFP')));
const IEContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Products/IE')));
const INTEGRAContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Products/INTEGRA')));
const MPTContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Products/MPT')));
const EVCContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Products/EVC')));
const EVCSBContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Products/EVCSB')));
const TREContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Products/TRE')));
const GNSContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Products/GNS')));
const IEBRContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Products/IEBR')));
const HVACBRContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Products/HVACBR')));
const SolarPVPage = lazy(() => lazyLoader(() => import('pages/Restricted/Products/Solarpv')));
const HPPage = lazy(() => lazyLoader(() => import('pages/Restricted/Products/HP')));
const SPVSimplePage = lazy(() => lazyLoader(() => import('pages/Restricted/Products/SolarpvSimple')));
const RoofReplacementPage = lazy(() => lazyLoader(() => import('pages/Restricted/Products/RR')));
const EVCProPage = lazy(() => lazyLoader(() => import('pages/Restricted/Products/EVCPro')));
const SPVMaintenancePage = lazy(() => lazyLoader(() => import('pages/Restricted/Products/SPVMaintenance')));

/**
 * @context Route to Modules
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Product = (props) => {
    let { module, subProduct } = useParams();
    const { clientID, facilityID, isIncreasePower, productID } = props;
    const {
        actions: { resetBusinessModelsStore },
    } = useBusinessModelsStore();

    const { companyProfileId } = useContext(UserContext);
    //componentDidMountWithUseEffect
    useEffect(() => {
        if ([PRODUCT_IDS.EVC, PRODUCT_IDS.EVCSB].includes(productID)) props.resetProductInputs();
        props.resetSizing();
        props.resetProposal();
        props.resetBusinessModels();
        resetBusinessModelsStore();
        if (!isIncreasePower) props.resetProductOptions();
        props.setProductModules({ type: 'module', name: module });
    }, []); //eslint-disable-line

    //Verify Access according to conditions
    let hasAccess = !!clientID && !!facilityID && isDefined(productID);
    if (!!module && !ALLOWED_MODULES.includes(module)) hasAccess = false;
    if (!!subProduct && !ALLOWED_SUB_PRODUCTS.includes(subProduct)) hasAccess = false;

    if (!hasAccess)
        return (
            <Error403
                props={{
                    url: BASENAME_URL_APP + 'solutions',
                    buttonText: 'page.solutions',
                    message: 'page.error.403.facilities',
                }}
            />
        );

    const hasAccessToEVCProPro =
        [PRODUCT_IDS.EVC].includes(productID) && !!companyProfileId && [getCompanyProfileIds().EDP_PT].includes(companyProfileId);

    //Set Container according to Product ID
    switch (productID) {
        case PRODUCT_IDS.SPV: //SPV SB
        case PRODUCT_IDS.SPVSB: //SPV SB
            return (
                <SPVProContextProvider>
                    <CustomizeProvider>
                        <SolarPVPage />
                    </CustomizeProvider>
                </SPVProContextProvider>
            );

        case PRODUCT_IDS.IE: //IE
            return <IEContainer />;

        case PRODUCT_IDS.INTEGRA: //INTEGRA
            return <INTEGRAContainer />;

        case PRODUCT_IDS.CE: //CE
            return <CEContainer />;

        case PRODUCT_IDS.CFP: //CFP
            return <CFPContainer />;

        case PRODUCT_IDS.MPT: //MAN PT
            return <MPTContainer />;

        case PRODUCT_IDS.EVC: //EVC
            return hasAccessToEVCProPro ?
                    <EVCProProvider>
                        <EVCProPage />
                    </EVCProProvider>
                :   <EVCContainer />;

        case PRODUCT_IDS.EVCSB: //EVCSB
            return <EVCSBContainer />;

        case PRODUCT_IDS.GNS: //GNS
            return <GNSContainer />;

        case PRODUCT_IDS.TRE: //TRE
            return <TREContainer />;

        case PRODUCT_IDS.IE_BR: //IE-BR
            return <IEBRContainer />;

        case PRODUCT_IDS.HVAC_BR: //HVAC-BR
            return <HVACBRContainer />;

        case PRODUCT_IDS.HP: //HP
            return <HPPage />;

        case PRODUCT_IDS.SPV_SIMPLE: //SPV_SIMPLE
            return (
                <SPVSimpleProvider>
                    <SPVSimplePage />
                </SPVSimpleProvider>
            );

        case PRODUCT_IDS.RR:
            return (
                <RRProvider>
                    <RoofReplacementPage />
                </RRProvider>
            );

        case PRODUCT_IDS.SPV_MAINTENANCE:
            return (
                <SPVMaintenanceProvider>
                    <SPVMaintenancePage />
                </SPVMaintenanceProvider>
            );

        default:
            return (
                <Error403
                    props={{
                        url: BASENAME_URL_APP + 'solutions',
                        buttonText: 'page.404.button.goSolutions',
                        message: 'page.error.403.facilities',
                    }}
                />
            );
    }
};
//PropTypes
Product.propTypes = {
    clientID: PropTypes.string.isRequired,
    facilityID: PropTypes.string.isRequired,
    productID: PropTypes.number.isRequired,
    isIncreasePower: PropTypes.bool,
    companyProfileId: PropTypes.number,
};
//Maping State to Props
const mapStateToProps = ({ client, facility, product }) => {
    return {
        clientID: getClientID(client),
        facilityID: getFacilityID(facility),
        productID: getProductId(product),
        isIncreasePower: getIsIncreasePower(product),
        isSPVPro: getIsSPVPro(product),
    };
};

export default connect(mapStateToProps, {
    resetBusinessModels,
    setProductModules,
    resetProductOptions,
    resetSizing,
    resetProposal,
    resetProductInputs,
})(Product);
