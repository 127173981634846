import { useCallback, useContext, useEffect } from 'react';

// Constants
import { PAYMENT_MODELS_IDS } from './../../../constants/businessModels';

// Contexts
import { BusinessModelsProContext, BusinessModelsProWebstompContext } from 'contexts/businessModelsPro/businessModelsContext';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { ReduxContext } from 'contexts/redux/reduxContext';

// Interfaces
import { BusinessModelsActions } from './../../../interfaces/businessModels/reducer';
import { IBusinessModel, IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { ServiceV2Events, TServiceV2 } from 'interfaces/businessModels/serviceV2';
import { DetailedOfferEditionEvents } from 'interfaces/businessModels/detailed';

// Services
import { isDefined, removeEmptyInObj } from 'services/util/auxiliaryUtils';

const useServiceV2BM = (bm: IBusinessModel) => {
    const {
        client: { contacto_email: clientEmail, contacto_nome: clientName },
        product: { productInputs },
    } = useContext(ReduxContext);

    const { state, bmEvent, dispatch, productPayload, setBMEventHandler, negotiationProfile } = useContext(
        BusinessModelsProContext
    ) as IBusinessModelsContext<TProductInputs, TServiceV2>;

    const { isLoadingWebStomp, fetchSimulations, mqMessages } = useContext(BusinessModelsProWebstompContext);

    const { setBMEventHandler: syncSetBMEventHandler } = useContext(OfferEditionContext);

    const fetchNewPaPSimulationHandler = (offerEdition) => {
        dispatch({
            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
            payload: { kpis_per_margin: [], offerEdition: offerEdition, marginSelected: undefined },
        });

        const newSimulation = {
            ...state.simulation,
            inputs: {
                ...state.simulation.inputs,
                offer_edition: offerEdition,
            },
        };

        const simulation = {
            ...newSimulation,
            register_simulation: false,
            tipo_modelo_negocio_id: state.selected.id,
        };
        //@ts-ignore
        fetchSimulations([simulation]);
    };

    const updateServiceV2Values = (newKpis) => {
        dispatch({
            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
            payload: {
                ...state.selected.values,
                kpis_per_margin: newKpis,
                proposalReady: true,
            },
        });
    };

    const serviceV2Events = useCallback(() => {
        if (
            [PAYMENT_MODELS_IDS.SERVICE_SECOND_VERSION, PAYMENT_MODELS_IDS.SERVICE].includes(state.selected.paymentModelID! as 3 | 8) &&
            isDefined(bmEvent?.name)
        ) {
            const currentValues = state.selected.values;

            switch (bmEvent?.name) {
                case ServiceV2Events.SET_SERVICEV2_BM: {
                    const syncKpis = productPayload?.productInputs?.syncKpis?.find(
                        (bm) => bm.tipo_modelo_negocio_id === state.selected.id || bm.id === state.selected.id
                    );

                    let clientContactInfo;
                    if (negotiationProfile?.mostra_formulario && state?.selected?.options?.has_form) {
                        clientContactInfo =
                            (
                                !isDefined(productInputs?.SIMPLES_Dimensionamento?.inputs?.contact_info) &&
                                !isDefined(productInputs?.SIMPLES_Dimensionamento?.inputs?.contact_info?.name)
                            ) ?
                                {
                                    name: clientName,
                                    email: clientEmail,
                                }
                            :   productInputs?.SIMPLES_Dimensionamento?.inputs?.contact_info;
                    }

                    if (clientContactInfo) bmEvent.payload.clientContactInfo = clientContactInfo;

                    if (isDefined(syncKpis)) {
                        // detailed
                        syncSetBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_SELECTED, {
                            businessModelSelected: state.businessModels.find((bm) => bm.payment_model_id === state.selected.paymentModelID),
                            businessModelBody: productPayload,
                            kpis: syncKpis,
                            offerEdition: productPayload?.productInputs?.offerEdition,
                        });

                        dispatch({
                            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                            payload: removeEmptyInObj({
                                kpis_per_margin: syncKpis?.data,
                                clientContactInfo,
                            }),
                        });
                    } else {
                        // simple
                        dispatch({
                            type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                            payload: bmEvent.payload,
                        });
                    }
                    break;
                }
                case ServiceV2Events.SET_SERVICEV2_MARGIN:
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: { ...currentValues, marginSelected: bmEvent.payload },
                    });
                    break;
                case ServiceV2Events.UPDATE_SERVICEV2_VALUES_BM:
                    fetchNewPaPSimulationHandler(bmEvent.payload?.offerEdition ? bmEvent.payload?.offerEdition : bmEvent.payload);
                    break;
                case ServiceV2Events.SET_SERVICEV2_PROPOSAL_READY:
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: { kpis_per_margin: [], proposalReady: bmEvent.payload },
                    });
                    break;
                case ServiceV2Events.SET_SERVICEV2_PROPOSAL:
                    dispatch({
                        type: BusinessModelsActions.SET_PROPOSAL,
                        payload: {
                            ...state.simulation,
                            inputs: removeEmptyInObj({
                                ...state.simulation.inputs,
                                offer_edition: {
                                    ...state.selected.values?.offerEdition,
                                },
                                monthly_fee: state.selected.values.kpis_per_margin.find(
                                    (kpi) => kpi.saving_share === state.selected.values.marginSelected
                                )?.monthly_fee,
                                battery_subsidy: state.selected.values?.grants?.battery_subsidy,
                                battery_subsidy_prc:
                                    state.selected.values?.grants?.battery_subsidy ?
                                        state.selected.values?.grants?.battery_subsidy_prc
                                    :   null,
                                subsidy: state.selected.values?.grants?.subsidy,
                                subsidy_prc: state.selected.values?.grants?.subsidy ? state.selected.values?.grants?.subsidy_prc : null,
                                contact_info: state.selected.values?.clientContactInfo,
                                id_crm: state.selected.values?.id_crm,
                            }),
                            tipo_modelo_negocio_id: state.selected.id,
                        },
                    });
                    break;
                case ServiceV2Events.UPDATE_SERVICEV2_GRANTS: //TODO: FIXME: Só está feito para EDP ES
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            grants: {
                                ...currentValues?.grants,
                                ...bmEvent.payload,
                            },
                        },
                    });
                    break;
                case ServiceV2Events.UPDATE_SERVICEV2_ADDITIONAL_DATA:
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: {
                            ...currentValues,
                            clientContactInfo: {
                                ...currentValues?.clientContactInfo,
                                ...bmEvent.payload?.clientContactInfo,
                            },
                            id_crm: bmEvent.payload?.id_crm ? bmEvent.payload?.id_crm : currentValues?.id_crm ?? null,
                        },
                    });
                    break;
            }
        }
    }, [bmEvent]); // eslint-disable-line

    useEffect(() => {
        serviceV2Events();
    }, [serviceV2Events]);

    useEffect(() => {
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.SERVICE_SECOND_VERSION) {
            setBMEventHandler(ServiceV2Events.SET_SERVICEV2_BM, {
                kpis_per_margin: productPayload.service_aux_kpis_arr,
                proposalReady: true,
            });
        } else if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.SERVICE) {
            const syncKpis = productPayload?.productInputs?.syncKpis?.find(
                (bm) => bm.tipo_modelo_negocio_id === state.selected.id || bm.id === state.selected.id
            );

            if (isDefined(syncKpis)) {
                // detailed
                setBMEventHandler(ServiceV2Events.SET_SERVICEV2_BM, {
                    kpis_per_margin: productPayload.service_aux_kpis_arr,
                    proposalReady: true,
                });
            } else {
                // simple
                setBMEventHandler(ServiceV2Events.FETCH_SERVICEV2_BM, null);
            }
        }
    }, [state.selected.paymentModelID]); // eslint-disable-line

    useEffect(() => {
        if (!isLoadingWebStomp && mqMessages.length > 0) {
            // @ts-ignore
            if (mqMessages[0]?.data.length > 0) {
                // @ts-ignore
                updateServiceV2Values(mqMessages[0].data);
            }
        }
    }, [isLoadingWebStomp, mqMessages]); // eslint-disable-line

    return {
        pitch: bm.pitch,
        projectSummary: bm.kpis_previews,
        selected: bm.selected,
        id: bm.payment_model_id,
    };
};

export default useServiceV2BM;
