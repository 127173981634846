/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Redux no conceito BusinessModels
 *
 * @version 20191120
 * @since 20191113 Initial release
 *
 */

import clone from 'fast-copy';
import {
    REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED,
    REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_FAIL,
    REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_SUCCESS,
    INITIAL_BUSINESS_MODELS,
    REQUEST_GET_BUSINESS_MODELS,
    REQUEST_GET_BUSINESS_MODELS_SUCCESS,
    RESET_BUSINESS_MODELS,
    RESET_BUSINESS_MODEL_INFO,
    SET_BUSINESS_MODEL_BODY,
    SET_BUSINESS_MODEL_OFFER_EDITION,
    SET_BUSINESS_MODEL_LOADER,
    SET_BUSINESS_MODEL,
    SET_BUSINESS_MODEL_BODY_INPUTS,
    SET_BUSINESS_MODEL_OPTIONS_ME,
    SET_BUSINESS_MODEL_BODY_COST,
    SET_BUSINESS_MODEL_SERVICE_PPA,
    SET_BUSINESS_MODEL_BODY_BATTERY_ADDIN,
    RESET_STEPS_COMPLETED_BATTERY_ADDIN,
    SET_BUSINESS_MODEL_NEGOTIATIONS,
} from 'constants/businessModels';
import { STATE_IDS } from 'constants/user';
import { showStateCurrent } from 'services/user';
import { isDefined } from 'services/util/auxiliaryUtils';
import { PRODUCT_IDS } from 'constants/products';

const initialState = clone(INITIAL_BUSINESS_MODELS);

const reducersBusinessModels = (state = initialState, action) => {
    switch (action.type) {
        case RESET_STEPS_COMPLETED_BATTERY_ADDIN:
            return {
                ...state,
                stepsCompletedBatteryAddin: false,
            };
        case SET_BUSINESS_MODEL_BODY_BATTERY_ADDIN:
            return {
                ...state,
                body: {
                    ...state.body,
                    ...action.payload.kpis,
                    inputs: action.payload.inputs,
                },
                stepsCompletedBatteryAddin: true,
            };
        case SET_BUSINESS_MODEL:
            return {
                ...state,
                body: clone(action.payload.body),
                offerEdition: clone(action.payload.offerEdition),
            };
        case SET_BUSINESS_MODEL_BODY: {
            let _offerEdition = clone(state?.offerEdition) ?? null;

            // EDP_BR && EVC
            if (isDefined(action?.payload?.inputs?.offer_edition)) {
                _offerEdition = action?.payload?.inputs?.offer_edition;
            }
            return {
                ...state,
                body: {
                    ...state?.body,
                    ...action?.payload,
                },
                offerEdition: _offerEdition,
            };
        }
        case SET_BUSINESS_MODEL_BODY_COST:
            return {
                ...state,
                body: {
                    ...state?.body,
                    cost: action.payload,
                },
            };
        case SET_BUSINESS_MODEL_OPTIONS_ME:
            return {
                ...state,
                options: {
                    ...state.options,
                    me: action.payload,
                },
            };
        case SET_BUSINESS_MODEL_BODY_INPUTS:
            return {
                ...state,
                body: {
                    ...state.body,
                    inputs: {
                        ...state.body.inputs,
                        ...action.payload,
                    },
                },
            };
        case SET_BUSINESS_MODEL_OFFER_EDITION:
            if ([PRODUCT_IDS.SPV].includes(action?.payload?.productID) && isDefined(action?.payload?.system?.costs)) {
                let { system } = action.payload;
                let { costs } = system;
                let costRubricsAll = Object.keys(costs);
                let costRubrics = [];
                costRubricsAll.forEach((item) => {
                    let rubrics = costs?.[item]?.details?.filter((el) => el?.editable_cost) ?? [];
                    if (rubrics?.length > 0) {
                        rubrics = rubrics.map((elm, key) => {
                            elm.key = key;
                            elm.name = item;
                            elm.default_selected = elm.included_cost; //o costo esta incluido no custo base?
                            elm[item] = elm.included_cost ? elm.included_default_cost : 0;
                            return elm;
                        });
                        costRubrics = [...costRubrics, ...rubrics];
                    }
                    system.rubricsCosts = costRubrics;
                });
            }

            return {
                ...state,
                offerEdition: action.payload,
            };
        case REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED:
            return {
                ...state,
                loadingPreviewKpis: true,
            };
        case REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_FAIL:
            return {
                ...state,
                loadingPreviewKpis: false,
                kpisSelected: {
                    ...state.kpisSelected,
                    isFail: true,
                },
            };
        case REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED_SUCCESS:
            if (state.kpisSelected?.isFail) delete state.kpisSelected.isFail;

            return {
                ...state,
                loadingPreviewKpis: false,
                kpisSelected: action.payload.data?.[0] ?? null,
            };
        case REQUEST_GET_BUSINESS_MODELS:
            return {
                ...state,
                body: {
                    ...(state?.body ?? null),
                    ...(action?.payload?.body ?? null),
                },
                loading: true,
            };
        case REQUEST_GET_BUSINESS_MODELS_SUCCESS: {
            let dataList = action.payload.data.filter((item) => isDefined(item.pitch));
            //Sorting Elements from API
            let data = dataList
                .sort(({ order: previousOrder }, { order: currentOrder }) => previousOrder - currentOrder)
                .map((item) => {
                    if (item?.pitch?.sections) {
                        item.pitch.sections = item.pitch.sections.sort(
                            ({ order: previousOrder }, { order: currentOrder }) => previousOrder - currentOrder
                        );
                    }
                    return item;
                });

            //IF Has options from EVC replace
            if (state?.options?.me) {
                data.map((item) => {
                    if (item.payment_model_id === 3) item.kpis_previews[0].kpis_per_margin = state?.options?.me;
                    return item;
                });
            }
            return {
                ...state,
                data: data,
                loading: false,
            };
        }
        case SET_BUSINESS_MODEL_LOADER:
            return {
                ...state,
                loader: {
                    ...state.loader,
                    ...action.payload,
                },
            };
        case RESET_BUSINESS_MODELS:
            return {
                ...clone(INITIAL_BUSINESS_MODELS),
            };
        case RESET_BUSINESS_MODEL_INFO:
            return {
                ...state,
                kpisSelected: null,
            };
        case SET_BUSINESS_MODEL_SERVICE_PPA:
            return {
                ...state,
                servicePPA: action.payload,
            };
        case SET_BUSINESS_MODEL_NEGOTIATIONS: {
            let syncKpis = action.payload?.negotiations?.syncKpis ?? [];
            let newCostRubrics = state?.offerEdition?.system?.rubricsCosts ?? [];

            if (syncKpis?.length > 0 && newCostRubrics.length > 0) {
                syncKpis.forEach((bModel) => {
                    let kpis = bModel.kpis ?? null;
                    let costRubrics = [];

                    if (kpis) {
                        let { system } = kpis;
                        let { costs } = system;
                        let costRubricsAll = Object.keys(costs);
                        costRubricsAll.forEach((item) => {
                            let rubrics = costs?.[item]?.details?.filter((el) => el?.edicao_custo) ?? [];
                            if (rubrics?.length > 0) {
                                rubrics = rubrics.map((elm, key) => {
                                    elm.key = key;
                                    elm.name = item;
                                    elm.default_selected = elm.selected; //o costo esta incluido no custo base?
                                    elm[item] = elm.selected ? elm.default_cost : 0;
                                    return elm;
                                });
                                costRubrics = [...costRubrics, ...rubrics];
                            }
                        });
                    }

                    //set costRubrics
                    newCostRubrics.forEach((el, idx) => {
                        let rubricByBmodel = costRubrics?.find((rbc) => rbc.id === el.id) ?? null;

                        if (rubricByBmodel) {
                            //add business_model_id
                            newCostRubrics[idx].business_model_ids = [
                                ...newCostRubrics[idx].business_model_ids,
                                ...rubricByBmodel.business_model_ids,
                            ];
                            // add payment_model_ids
                            newCostRubrics[idx].payment_model_ids = [
                                ...newCostRubrics[idx].payment_model_ids,
                                ...rubricByBmodel.payment_model_ids,
                            ];
                        }
                    });
                });
            }
            return {
                ...state,
                body: {
                    ...state?.body,
                    ...action?.payload.body,
                },
                negotiations: action.payload?.negotiations ?? null,
                offerEdition: {
                    ...(state?.offerEdition ?? {}),
                    system: {
                        ...(state?.offerEdition?.system ?? {}),
                        rubricsCosts: newCostRubrics,
                    },
                },
            };
        }
        default:
            return (
                showStateCurrent(STATE_IDS.PLATFORM) ?
                    !state ? clone(INITIAL_BUSINESS_MODELS)
                    :   state
                :   null
            );
    }
};

export default reducersBusinessModels;
