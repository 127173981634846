/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Saga-redux Solutions
 * @inheritDoc o nome das funcoes e const no saga tem de começar com o '_" por exemplo
 * 		const _handlerName /.. || function* _callABC() /..
 *
 * @version 20191115
 * @since 20191115 Initial release
 *
 */

import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
//actions
import {
    requestPostMEPreSizingSuccess,
    requestPostMEPreSizingFail,
    requestPostMESizingFinanceSuccess,
    requestPostMESizingFinanceFail,
} from 'redux/actions/products/evc';
import {
    requestGetProductInputsSuccess,
    requestGetProductInputsFail,
    requestsGetAllInitialsProductDataSuccess,
} from 'redux/actions/product';

//constants
import {
    REQUEST_GET_PRODUCT_INPUTS,
    REQUESTS_PUT_FACILITY_PRODUCT_INPUTS,
    REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA,
} from 'constants/product';
import { BAD_REQUEST, OK } from 'constants/statusCodeHttp';
//api
import { getProductInputs, postSimulation, putFacilityAndProductInputs } from 'api/product';
import { hasProductInputs, isDefined } from 'services/util/auxiliaryUtils';
import { getPayload as getPayloadCFP } from 'services/products/cfp';
import { checkResponseStatus } from 'services/checkResponseStatus';
import { PRODUCT_IDS } from 'constants/products';
import { REQUEST_POST_EVC_PRE_SIZING, REQUEST_POST_EVC_SIZING_FINANCE } from 'constants/products/evc';
import { getBatteryPowers, getFacilityGroups, getProductWorkSchedules } from 'api/utils';
import { getFacilityStats, getFacilityTypes } from 'api/facility';

/**
 * _callGetProductInputs  request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetProductInputs = async (payload) => {
    return await getProductInputs(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetProductInputs ', error);
            return error;
        });
};

/**
 *
 * @param payload
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>>}
 * @private
 */
function* _handlerGetProductInputs({ payload }) {
    try {
        const { facilityID, productID } = payload;
        if (isDefined(facilityID) && isDefined(productID)) {
            const fetchResult = yield call(_callGetProductInputs, payload);
            yield checkResponseStatus(fetchResult);

            if (fetchResult?.status === OK) yield put(requestGetProductInputsSuccess({ data: fetchResult.data }));
            else yield put(requestGetProductInputsFail());
        } else {
            yield put(requestGetProductInputsFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerGetProductInputs ', error);
    }
}

/**
 * _callPutFacilityAndProductInputs  request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callPutFacilityAndProductInputs = async (payload) => {
    return await putFacilityAndProductInputs(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error requestPutFacilityAndProductInputs ', error);
            return error;
        });
};

/**
 * _handlerPutFacilityAndProductInputs
 *
 * @param payload
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>>}
 * @private
 */
function* _handlerPutFacilityAndProductInputs({ payload }) {
    try {
        yield delay(1500);
        const fetchResult = yield call(_callPutFacilityAndProductInputs, payload);
        yield checkResponseStatus(fetchResult);

        //if (fetchResult?.status === OK) yield put(requestPutFacilityAndProductInputsSuccess(payload));
    } catch (error) {
        console.log('[SAGA] catch error _handlerPutFacilityAndProductInputs ', error);
    }
}

/**
 * _handleGetAllInitialsProductData
 *
 * @param payload
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>>}
 * @private
 */
function* _handleGetAllInitialsProductData({ payload }) {
    try {
        const { facilityID, productID, isEditProposal } = payload;

        switch (productID) {
            case PRODUCT_IDS.CFP: {
                //ProductInputs
                let fetchProductInputs = !isEditProposal ? yield call(_callGetProductInputs, { facilityID, productID }) : null;
                yield !isEditProposal ? checkResponseStatus(fetchProductInputs) : null;
                let _hasProductInputs = false;

                _hasProductInputs =
                    !isEditProposal ?
                        hasProductInputs(fetchProductInputs.data) &&
                        isDefined(fetchProductInputs.data.SIMPLES_Dimensionamento.inputs.battery_power)
                    :   false;
                const fetchBatteryPowers = yield call(_callRequestGetBatteryPower, { productID });
                yield checkResponseStatus(fetchBatteryPowers);
                let fetchSimulationBase = yield call(_callPostSimulation, {
                    payload: getPayloadCFP(null, { facilityID, productID }),
                });
                yield put(
                    requestsGetAllInitialsProductDataSuccess({
                        productInputs: _hasProductInputs ? { ...fetchProductInputs.data } : null,
                        batteryPowers: fetchBatteryPowers.data,
                        base: fetchSimulationBase?.data?.kpis,
                        hasProductInputs: _hasProductInputs,
                    })
                );
                break;
            }
            case PRODUCT_IDS.INTEGRA:
            case PRODUCT_IDS.MPT:
            case PRODUCT_IDS.TRE: {
                //ProductInputs
                let fetchProductInputs = !isEditProposal ? yield call(_callGetProductInputs, { facilityID, productID }) : null;
                yield !isEditProposal ? checkResponseStatus(fetchProductInputs) : null;
                let _hasProductInputs = false;

                _hasProductInputs =
                    hasProductInputs(fetchProductInputs?.data) && isDefined(fetchProductInputs.data?.SIMPLES_Dimensionamento);
                const fetchtWorkSchedules = yield call(_callGetProductWorkSchedules, {
                    productId: productID,
                });
                yield checkResponseStatus(fetchtWorkSchedules);
                yield put(
                    requestsGetAllInitialsProductDataSuccess({
                        productInputs: _hasProductInputs ? { ...fetchProductInputs.data } : null,
                        workSchedules: fetchtWorkSchedules.data,
                        hasProductInputs: _hasProductInputs,
                    })
                );
                break;
            }
            case PRODUCT_IDS.CE: {
                //ProductInputs

                let initialRequests = [];

                //Get Facility Groups
                initialRequests.push(call(_callGetFacilityGroups, { facilityId: facilityID }, '/fetchFacilityGroups'));

                //Get Facility Types
                initialRequests.push(call(_callGetFacilityTypes, payload, '/fetchFacilityTypes'));

                //Get Facility Stats
                initialRequests.push(call(_callGetFacilityStats, { facilityId: facilityID }, '/fetchFacilityStats'));

                //Get Product Inputs
                if (!isEditProposal && isDefined(facilityID) && isDefined(productID)) {
                    initialRequests.push(call(_callGetProductInputs, { facilityID, productID }, '/fetchProductInputs'));
                }

                //Wait for all requests to be done
                let [fetchFacilityGroups, fetchFacilityTypes, fetchFacilityStats, fetchProductInputs] = yield all(initialRequests);

                //Validate Inputs
                let _hasProductInputs = false;

                _hasProductInputs =
                    hasProductInputs(fetchProductInputs?.data) && isDefined(fetchProductInputs.data?.SIMPLES_Dimensionamento);

                yield checkResponseStatus(fetchFacilityGroups);
                yield checkResponseStatus(fetchFacilityTypes);
                yield checkResponseStatus(fetchFacilityStats);
                yield !isEditProposal ? checkResponseStatus(fetchProductInputs) : null;

                yield put(
                    requestsGetAllInitialsProductDataSuccess({
                        productInputs: _hasProductInputs ? { ...fetchProductInputs.data } : null,
                        hasProductInputs: _hasProductInputs,
                        facilityTypes: fetchFacilityTypes.data,
                        facilityStats: fetchFacilityStats.data,
                        facilityGroups: fetchFacilityGroups.data,
                        facilityID,
                        facilityAnnualConsumption: payload?.facilityAnnualConsumption,
                    })
                );
                break;
            }

            case PRODUCT_IDS.GNS: {
                //ProductInputs
                let fetchProductInputs =
                    !isEditProposal && isDefined(facilityID) && isDefined(productID) ?
                        yield call(_callGetProductInputs, { facilityID, productID })
                    :   null;
                yield !isEditProposal ? checkResponseStatus(fetchProductInputs) : null;
                let _hasProductInputs = false;

                _hasProductInputs =
                    hasProductInputs(fetchProductInputs?.data) && isDefined(fetchProductInputs.data?.SIMPLES_Dimensionamento);
                yield put(
                    requestsGetAllInitialsProductDataSuccess({
                        productInputs: _hasProductInputs ? { ...fetchProductInputs.data } : null,
                        hasProductInputs: _hasProductInputs,
                    })
                );
                break;
            }
            default: {
                //ProductInputs
                let fetchProductInputs =
                    !isEditProposal && isDefined(facilityID) && isDefined(productID) ?
                        yield call(_callGetProductInputs, { facilityID, productID })
                    :   null;
                yield !isEditProposal ? checkResponseStatus(fetchProductInputs) : null;
                let _hasProductInputs = false;

                _hasProductInputs = hasProductInputs(fetchProductInputs.data);
                yield put(
                    requestsGetAllInitialsProductDataSuccess({
                        productInputs: _hasProductInputs ? { ...fetchProductInputs.data } : null,
                        hasProductInputs: _hasProductInputs,
                    })
                );
                break;
            }
        }
    } catch (error) {
        console.log('[SAGA] catch error _handleGetAllInitialsProductData ', error);
    }
}

/**
 * _callGetFacilityStats   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetFacilityStats = async (payload) => {
    return await getFacilityStats(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetFacilityStats ', error);
            return error;
        });
};

/**
 * _callGetFacilityGroups - request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetFacilityGroups = async (payload) => {
    return await getFacilityGroups(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetFacilityGroups ', error);
            return error;
        });
};

/**
 * _callGetFacilityTypes   request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetFacilityTypes = async () => {
    return await getFacilityTypes()
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetFacilityTypes ', error);
            return error;
        });
};

/**
 * _callGetProductInputs  request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callGetProductWorkSchedules = async (payload) => {
    return await getProductWorkSchedules(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callGetProductWorkSchedules ', error);
            return error;
        });
};

/**
 * _callRequestGetBatteryPower
 *
 * @returns {Promise<AxiosResponse<T>>}
 * @private
 */
const _callRequestGetBatteryPower = async ({ productID }) =>
    await getBatteryPowers(productID)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callRequestGetCounties ', error);
            return error;
        });

/**
 * _callPostSimulation - request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callPostSimulation = async (payload) => {
    return await postSimulation(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callPostSimulation ', error);
            return error;
        });
};

/**
 * _handlerPostMEPreSizing
 *
 * @param payload
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>>}
 * @private
 */
function* _handlerPostMEPreSizing({ payload }) {
    try {
        const response = yield call(_callPostSimulation, { payload });

        if (response?.status === OK) {
            yield put(requestPostMEPreSizingSuccess(response?.data));
        } else {
            yield put(requestPostMEPreSizingFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerPostMEPreSizing ', error);
    }
}

/**
 * _handlerPostMESizingFinance
 *
 * @param payload
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>>}
 * @private
 */
function* _handlerPostMESizingFinance({ payload }) {
    try {
        const response = yield call(_callPostSimulation, { payload });

        if (response?.status === OK) {
            yield put(
                requestPostMESizingFinanceSuccess({
                    chargers_selected: payload.inputs.chargers_selected,
                    totals: response?.data,
                    warningPowerLimit: false,
                })
            );
        } else if (response?.status === BAD_REQUEST) {
            yield put(
                requestPostMESizingFinanceSuccess({
                    chargers_selected: payload.inputs.chargers_selected,
                    warningValues: response?.data?.data,
                    totals: response?.data?.data,
                    warningPowerLimit: true,
                    message: response?.data?.message,
                })
            );
        } else {
            yield put(requestPostMESizingFinanceFail());
        }
    } catch (error) {
        console.log('[SAGA] catch error _handlerPostMESizingFinance ', error);
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(REQUEST_GET_PRODUCT_INPUTS, _handlerGetProductInputs),
        takeLatest(REQUESTS_PUT_FACILITY_PRODUCT_INPUTS, _handlerPutFacilityAndProductInputs),
        takeLatest(REQUESTS_GET_ALL_INITIALS_PRODUCT_DATA, _handleGetAllInitialsProductData),
        takeLatest(REQUEST_POST_EVC_PRE_SIZING, _handlerPostMEPreSizing),
        takeLatest(REQUEST_POST_EVC_SIZING_FINANCE, _handlerPostMESizingFinance),
    ]);
}
