import { COUNTRY_ID } from 'constants/user';

export const caeTranslation = (pais_id) => {
    switch (Number(pais_id)) {
        case COUNTRY_ID.PT:
            return 'CAE';
        case COUNTRY_ID.ES:
            return 'CNAE';
        case COUNTRY_ID.IT:
            return 'ATECO';
        case COUNTRY_ID.PL:
            return 'PKD';
        case COUNTRY_ID.UK:
            return 'SIC';
        case COUNTRY_ID.SG:
            return 'SSIC';
        case COUNTRY_ID.FR:
            return 'NAF';
        case COUNTRY_ID.CH:
            return 'NOGA';
        case COUNTRY_ID.US:
            return 'SIC code';
        // case COUNTRY_ID.DE:
        // case COUNTRY_ID.GR:
        default:
            return 'CAE';
    }
};

export const cpeTranslation = (pais_id) => {
    switch (Number(pais_id)) {
        case COUNTRY_ID.PT:
            return 'CPE';
        case COUNTRY_ID.ES:
            return 'CUPS';
        case COUNTRY_ID.IT:
            return 'POD';
        case COUNTRY_ID.PL:
            return 'PPE';
        case COUNTRY_ID.UK:
            return 'MPAN';
        case COUNTRY_ID.SG:
            return 'UEN';
        case COUNTRY_ID.GR:
            return ' Αριθμός Παροχής';
        case COUNTRY_ID.CH:
            return 'ID';
        // case COUNTRY_ID.DE:
        // case COUNTRY_ID.FR:
        default:
            return 'CPE';
    }
};
