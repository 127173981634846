import { createContext, useReducer } from 'react';

import { SpvMaintenanceReducer, initialSpvMaintenanceReducer } from './spvMaintenanceReducer';

const SPVMaintenanceContext = createContext({
    rrState: initialSpvMaintenanceReducer,
    spvMaintenanceDispatchHandler: () => {},
});

const SPVMaintenanceProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [spvMaintenanceState, spvMaintenanceDispatch] = useReducer(SpvMaintenanceReducer, initialSpvMaintenanceReducer);

    const spvMaintenanceDispatchHandler = (type: any, payload: any) => {
        spvMaintenanceDispatch({
            type: type,
            payload: payload,
        });
    };

    const rrContextValues: any = {
        spvMaintenanceState,
        spvMaintenanceDispatchHandler,
    };

    return <SPVMaintenanceContext.Provider value={rrContextValues}>{children}</SPVMaintenanceContext.Provider>;
};

export { SPVMaintenanceContext, SPVMaintenanceProvider };
