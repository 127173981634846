/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Selector Settings
 *
 * @version 20190729
 * @since 20190729 Initial release
 *
 */
import { createSelector } from 'reselect';

export const getTheme = createSelector(
    (state) => state.theme,
    (theme) => theme
);
export const getLocale = createSelector(
    (state) => state.locale,
    (locale) => locale
);
export const getLocaleCode = createSelector(
    (state) => state.locale.locale,
    (locale) => locale
);
export const getDrawerType = createSelector(
    (state) => state.drawerType,
    (drawerType) => drawerType
);
export const getWidth = createSelector(
    (state) => state.width,
    (width) => width
);
export const getWriteDirectionRTL = createSelector(
    (state) => state.isDirectionRTL,
    (isDirectionRTL) => isDirectionRTL
);
export const getNavigationStyle = createSelector(
    (state) => state?.navigationStyle,
    (navigationStyle) => navigationStyle
);
export const getIsDarkTheme = createSelector(
    (state) => state?.darkTheme,
    (darkTheme) => darkTheme
);
export const getHeadTitle = createSelector(
    (state) => state.headTitle,
    (headTitle) => headTitle
);
export const getBrand = createSelector(
    (state) => state.brand,
    (brand) => brand
);
export const getMapLocaleUser = createSelector(
    (state) => state?.mapLocaleUser,
    (mapLocaleUser) => mapLocaleUser ?? null
);

export const getLocalePickers = createSelector(
    (state) => state?.locale.localePickers,
    (localePickers) => localePickers ?? 'en-gb'
);
