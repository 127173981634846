/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Selector Client
 *
 * @version 20190729
 * @since 20190729 Initial release
 *
 */

import { createSelector } from 'reselect';

export const getClientIsRequesting = createSelector(
    (state) => state?.isRequesting,
    (isRequesting) => isRequesting
);
export const getClientIsRequestingCreateClient = createSelector(
    (state) => state?.isRequestingCreateClient,
    (isRequestingCreateClient) => isRequestingCreateClient
);
export const getHasSuccessfullyCreatedClient = createSelector(
    (state) => state?.hasSuccessfullyCreatedClient,
    (hasSuccessfullyCreatedClient) => hasSuccessfullyCreatedClient
);

export const getClientSearch = createSelector(
    (state) => state?.search,
    (search) => search
);
export const getSearchList = createSelector(
    (state) => state?.search,
    (search) => search.list
);
export const getClientSearchHistory = createSelector(
    (state) => state?.list,
    (list) => list
);
export const getClient = createSelector(
    (state) => state,
    (state) => state
);
export const getClientList = createSelector(
    (state) => state?.list,
    (list) => list ?? []
);

export const getClientContactEmail = createSelector(
    (state) => state?.contacto_email,
    (contacto_email) => contacto_email
);
export const getClientContactName = createSelector(
    (state) => state?.contacto_nome,
    (contacto_nome) => contacto_nome
);
export const getClientContactNumber = createSelector(
    (state) => state?.contacto_numero,
    (contacto_numero) => contacto_numero
);

export const getClientID = createSelector(
    (state) => state?.id,
    (id) => id
);
export const isOpenClientCard = createSelector(
    (state) => state?.isOpenClientCard,
    (isOpenClientCard) => isOpenClientCard
);
export const getClientDescription = createSelector(
    (state) => state?.descricao,
    (descricao) => descricao
);
export const getClientNIPC = createSelector(
    (state) => state?.nipc,
    (nipc) => nipc
);

export const getClientCAE2 = createSelector(
    (state) => state?.cae2,
    (cae2) => cae2
);
export const getClientCAE5 = createSelector(
    (state) => state?.cae5,
    (cae5) => cae5
);

export const getIsHighEnergyRisk = createSelector(
    (state) => state?.risco_energia_id,
    (risco_energia_id) => [5].includes(parseInt(risco_energia_id))
);
export const getClientRisk = createSelector(
    (state) => state?.risco_energia_id,
    (risco_energia_id) => risco_energia_id
);
export const getClientAddress = createSelector(
    (state) => state?.morada,
    (morada) => morada
);
export const getClientPostCode = createSelector(
    (state) => state?.codigo_postal,
    (codigo_postal) => codigo_postal
);
export const getClientLocality = createSelector(
    (state) => state?.localidade,
    (localidade) => localidade
);
export const getDetailedAddress = createSelector(
    (state) => state?.location,
    (location) => location
);
export const getClientTNW = createSelector(
    (state) => state?.tnw,
    (tnw) => tnw
);

export const getClientICMS = createSelector(
    (state) => state?.contribuinte_icms,
    (contribuinte_icms) => contribuinte_icms
);
export const getClientSegmentoDescricao = createSelector(
    (state) => state?.segmento_descricao,
    (segmento_descricao) => segmento_descricao ?? null
);
export const getClientSegmentoID = createSelector(
    (state) => state?.segmento_id,
    (segmento_id) => segmento_id ?? null
);

export const getClientFacilities = createSelector(
    (state) => state?.facilities,
    (facilities) => facilities ?? []
);
export const getRedirectToken = createSelector(
    (state) => state?.redirectToken,
    (redirectToken) => redirectToken ?? null
);

// B2C
export const getClientFirstName = createSelector(
    (state) => state?.nome_proprio,
    (nome_proprio) => nome_proprio
);
export const getClientSurname = createSelector(
    (state) => state?.apelido,
    (apelido) => apelido
);
export const getClientEmail = createSelector(
    (state) => state?.email,
    (email) => email
);
export const getClientVAT = createSelector(
    (state) => state?.numero_fiscal,
    (numero_fiscal) => numero_fiscal
);
export const getClientPhoneNumber = createSelector(
    (state) => state?.numero_telefone,
    (numero_telefone) => numero_telefone
);
export const getClientAddressB2C = createSelector(
    (state) => state?.morada,
    (morada) => morada
);
export const getClientPostalCode = createSelector(
    (state) => state?.codigo_postal,
    (codigo_postal) => codigo_postal
);
export const getClientCity = createSelector(
    (state) => state?.cidade,
    (cidade) => cidade
);
