//Libraries
import { Button } from '@save2compete/efz-design-system';

//Constants

//Components
import Lottie2 from 'components/@efz/Lottie/Lottie2';

//Assets
import RobotAnimation from 'assets/lottie/Error_Boundary_Robot.json';
import { ReactComponent as EfzLogo } from 'assets/@efz/icons/efz-logo.svg';
import { ReactComponent as ErrorBackground } from 'assets/@efz/backgrounds/error-background.svg';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { getCompanyProfileIds } from 'services/user';
import { useUserStore } from 'store/user';

interface IErrorBoundary {
    resetError: () => void;
}

const ErrorBoundary = ({ resetError }: IErrorBoundary) => {
    //Cant get IntlMessages to work
    const { user } = useUserStore();
    //@ts-ignore
    const companyProfileId = isFieldDefined(user?.perfil_empresa_id) ? Number(user?.perfil_empresa_id) : getCompanyProfileIds().EFZ;
    const isDemo = companyProfileId === getCompanyProfileIds().EFZ ? true : false;

    return (
        <div className="error-boundary">
            <ErrorBackground className="error-boundary-background" preserveAspectRatio="none" />
            <div className="error-boundary-wrapper">
                {isDemo ?
                    <EfzLogo />
                :   null}
                <Lottie2
                    options={{
                        animationData: RobotAnimation,
                    }}
                    width={258}
                    height={295.5}
                    isPaused={false}
                />
                <h1>Something went wrong... we’re working to fix the problem.</h1>
                <p>Thank you for your patience.</p>
                <Button
                    variant="primary"
                    icon="startRoundArrowReset"
                    onClick={() => window.location.reload()}
                    className="mb-3 d-flex align-items-center"
                    dataTestId="general-error-reload"
                >
                    <span>Reload</span>
                </Button>
                <Button variant="secondary" icon="startArrowHeadLeftPrimary" onClick={resetError} dataTestId="general-error-go-back">
                    <span>Go back home</span>
                </Button>
            </div>
        </div>
    );
};

export default ErrorBoundary;
