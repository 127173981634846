import { FormattedMessage } from 'react-intl';
import { caeTranslation, cpeTranslation } from 'services/user/translations';
import { useUserStore } from 'store/user';
import { TIntl } from 'types/utils';

type TProps = {
    id: TIntl;
    values?: any;
    whitespaceStart?: boolean;
    whitespaceEnd?: boolean;
    currencySymbol?: string;
};

const IntlMessages = (props: TProps) => {
    const { whitespaceStart, whitespaceEnd, currencySymbol } = props;
    const { user } = useUserStore();
    let _currencySymbol = currencySymbol;
    let _cae;
    let _cpe;
    // obter o currency no userState
    if (user && user?.currency?.symbol) _currencySymbol = user?.currency.symbol;

    if (user) {
        _cae = caeTranslation(user.pais_id);
        _cpe = cpeTranslation(user.pais_id);
    }

    const newProps = {
        ...props,
        values: {
            ...props.values,
            CURRENCY_SYMBOL: _currencySymbol, //add currency symbol from redux to tag CURRENCY_SYMBOL
            CAE: _cae,
            CPE: _cpe,
        },
    };

    return (
        <>
            {whitespaceStart && ' '}
            <FormattedMessage {...newProps} />
            {whitespaceEnd && ' '}
        </>
    );
};

export default IntlMessages;
