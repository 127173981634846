import { Nullable } from 'types/utils';
import { LatLng, LatLngLiteral } from './Coordinates';
import { computePolygonStyle, distance } from 'services/products/solarpv/map';

export interface IPolygon {
    center?: LatLng | LatLngLiteral;
    clickable?: boolean;
    draggable?: boolean;
    editable?: boolean;
    fillColor?: string;
    fillOpacity?: number;
    id: number | string;
    isValid?: boolean;
    isExcluded?: boolean;
    map?: google.maps.Map;
    paths?: Array<LatLng | LatLngLiteral>;
    selected?: boolean;
    strokeColor?: string;
    strokeOpacity?: number;
    strokeWeight?: number;
    visible?: boolean;
    zIndex?: number;
    height?: number;
}

export interface PolygonStyles {
    fillColor?: string;
    fillOpacity?: number;
    strokeColor?: string;
    strokeOpacity?: number;
    strokeWeight?: number;
    zIndex?: number;
}

export class Polygon {
    private center: LatLng;
    private clickable: boolean;
    private draggable: boolean;
    private editable: boolean;
    private fillColor: string;
    private fillOpacity: number;
    public _id: string | number;
    public isValid: boolean;
    public isExcluded: boolean;
    private map: Nullable<google.maps.Map>;
    public paths: Array<LatLng>;
    public selected: boolean;
    private strokeColor: string;
    private strokeOpacity: number;
    private strokeWeight: number;
    public visible: boolean;
    private zIndex: number;
    private height: number;

    constructor(options: IPolygon) {
        this.center =
            options.center ?
                options.center instanceof LatLng ?
                    options.center
                :   new LatLng(options?.center)
            :   new LatLng(0, 0);
        this.clickable = options?.clickable ?? true;
        this.draggable = options?.draggable ?? false;
        this.editable = options?.editable ?? false;
        this.fillColor = options?.fillColor ?? 'var(--primary-color-bleached)';
        this.fillOpacity =
            options.fillOpacity ?
                options?.fillOpacity < 0 ? 0
                : options.fillOpacity > 1 ? 1
                : options.fillOpacity
            :   1;
        this._id = options.id;
        this.isValid = options.isValid ?? true;
        this.isExcluded = options.isExcluded ?? false;
        this.map = options?.map ?? null;

        this.paths = [];
        if (options?.paths) {
            if (options?.paths?.length < 3) options.paths = [];

            options.paths.forEach((path) => {
                if (path instanceof LatLng) this.paths.push(path);
                else this.paths.push(new LatLng(path));
            });
        }

        this.selected = options?.selected ?? true;
        this.strokeColor = options?.strokeColor ?? 'var(--primary-color)';
        this.strokeOpacity =
            options.strokeOpacity ?
                options?.strokeOpacity < 0 ? 0
                : options.strokeOpacity > 1 ? 1
                : options.strokeOpacity
            :   1;

        this.strokeWeight = options?.strokeWeight ?? 1;
        this.visible = options?.visible ?? true;
        this.zIndex = options?.zIndex ?? 1;
        this.height = options?.height ?? 0;
    }

    computeArea() {
        const pointsCount = this.paths.length;
        const d2r = Math.PI / 180.0;
        const radius = 6378137.0;
        let area = 0.0,
            p1,
            p2;

        if (pointsCount <= 2) return 0;

        for (let i = 0; i < pointsCount; i++) {
            p1 = this.paths[i];
            p2 = this.paths[(i + 1) % pointsCount];
            area += (p2.lng() - p1.lng()) * d2r * (2 + Math.sin(p1.lat() * d2r) + Math.sin(p2.lat() * d2r));
        }
        area = (area * radius * radius) / 2.0;
        return Math.abs(area);
    }

    computePerimeter() {
        let perimeter = 0;
        for (let index = 0; index < this.paths.length; index++) {
            const p1 = this.paths[index];
            const p2 = this.paths[(index + 1) % this.paths.length];
            perimeter += distance(p1.toJSON(), p2.toJSON());
        }
        return perimeter;
    }

    getCenter() {
        return this.center;
    }

    getDraggable() {
        return this.draggable;
    }

    getEditable() {
        return this.editable;
    }

    getHeight(): number {
        return this.height;
    }

    get id() {
        return this._id;
    }

    getMap(): google.maps.Map | null {
        return this.map;
    }

    getPaths(): Array<LatLng> {
        return this.paths;
    }

    getSelected() {
        return this.selected;
    }

    getStyles(): PolygonStyles {
        return {
            fillColor: this.fillColor,
            fillOpacity: this.fillOpacity,
            strokeColor: this.strokeColor,
            strokeOpacity: this.strokeOpacity,
            strokeWeight: this.strokeWeight,
        };
    }

    getVisible() {
        return this.visible;
    }

    getZIndex() {
        return this.zIndex;
    }

    select(): PolygonStyles {
        this.selected = true;
        this.setStyles(computePolygonStyle(true));
        return this.getStyles();
    }

    setCenter(center: LatLng | LatLngLiteral) {
        this.center = center instanceof LatLng ? center : new LatLng(center);
    }

    setDraggable(draggable: boolean) {
        this.draggable = draggable;
    }

    setEditable(editable: boolean) {
        this.editable = editable;
    }

    setHeight(height: number) {
        if (!Number.isFinite(height) || height < 0) return;
        this.height = height;
    }

    setMap(map: google.maps.Map) {
        this.map = map;
    }

    setOptions(options: IPolygon) {
        this.clickable = options?.clickable ?? this.clickable;
        this.draggable = options?.draggable ?? this.draggable;
        this.editable = options?.editable ?? this.editable;
        this.fillColor = options?.fillColor ?? this.fillColor;
        this.fillOpacity =
            options.fillOpacity ?
                options?.fillOpacity < 0 ? 0
                : options.fillOpacity > 1 ? 1
                : options.fillOpacity
            :   this.fillOpacity;
        this.map = options?.map ?? null;

        this.paths = options?.paths ? [] : this.paths;
        if (options?.paths) {
            if (options?.paths?.length < 3) return;

            options.paths.forEach((path) => {
                if (path instanceof LatLng) this.paths.push(path);
                else this.paths.push(new LatLng(path));
            });
        }

        this.strokeColor = options?.strokeColor ?? this.strokeColor;
        this.strokeOpacity =
            options.strokeOpacity ?
                options?.strokeOpacity < 0 ? 0
                : options.strokeOpacity > 1 ? 1
                : options.strokeOpacity
            :   this.strokeOpacity;

        this.strokeWeight = options?.strokeWeight ?? this.strokeWeight;
        this.visible = options?.visible ?? this.visible;
        this.zIndex = options?.zIndex ?? this.zIndex;
    }

    setPaths(paths: Array<LatLng | LatLngLiteral>) {
        if (paths?.length < 3) return;

        this.paths = [];
        paths.forEach((path) => {
            if (path instanceof LatLng) this.paths.push(path);
            else this.paths.push(new LatLng(path));
        });
    }

    setSelected(selected: boolean) {
        this.selected = selected;
    }

    setStyles(styles: PolygonStyles) {
        this.fillColor = styles.fillColor ?? this.fillColor;
        this.fillOpacity = styles.fillOpacity ?? this.fillOpacity;
        this.strokeColor = styles.strokeColor ?? this.strokeColor;
        this.strokeOpacity = styles.strokeOpacity ?? this.strokeOpacity;
        this.strokeWeight = styles.strokeWeight ?? this.strokeWeight;
    }

    setVisible(visible: boolean) {
        this.visible = visible;
    }

    setZIndex(zIndex: number) {
        this.zIndex = zIndex;
    }

    unselect(): PolygonStyles {
        this.selected = false;
        this.setStyles(computePolygonStyle(false));
        return this.getStyles();
    }
}
