import { getTranslation } from 'services/util/auxiliaryUtils';
import { isDevelopment } from './settings';

export function orderByLocale(arrToOrder, dropdownType, userLanguage) {
    const newArrToOrder = arrToOrder?.map((element) => {
        let messageID;
        switch (dropdownType) {
            case 'tpid':
                messageID = 'product.name.' + element.tag;
                break;
            case 'state':
                messageID = element.messageID;
                break;
            case 'risk':
                messageID = `label.energyRisks.${element?.tag}`;
                break;
            case 'facilityType':
                messageID = 'label.' + element.value;
                break;
            case 'classes':
                messageID = `label.${element.tag}`;
                break;
            case 'substatus_tag':
                messageID = `page.processes.substatus.${element.substatus_tag}`;
                break;
            case 'gbm_ids':
                if (element.tag === 'NOT_APPLICABLE') {
                    messageID = 'label.NA';
                } else {
                    messageID = `product.typeBusinessModel.${element.tag}`;
                }
                break;

            default:
                break;
        }

        if (isDevelopment()) element['pt-PT'] = getTranslation(messageID, 'pt');

        return {
            ...element,
            [userLanguage]: getTranslation(messageID, userLanguage),
            'en-GB': getTranslation(messageID, 'en'),
        };
    });

    //without orderby
    if (['gbm_ids'].includes(dropdownType)) return newArrToOrder;

    return orderByAlphabet(newArrToOrder ?? [], userLanguage);
}

export function orderByAlphabet(collection, iteratees) {
    return collection?.sort((a, b) => a?.[iteratees]?.localeCompare(b?.[iteratees]));
}

export const orderByNumber = (arrOfObjects, prop) => arrOfObjects.sort((a, b) => parseFloat(a?.[prop]) - parseFloat(b?.[prop]));
