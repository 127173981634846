import clone from 'fast-copy';
import cz_CZ from 'lngProvider/locales/cz_CZ.json';
import de_DE from 'lngProvider/locales/de_DE.json';
import el_GR from 'lngProvider/locales/el_GR.json';
import en_GB from 'lngProvider/locales/en_GB.json';
import en_IE from 'lngProvider/locales/en_IE.json';
import en_SG from 'lngProvider/locales/en_SG.json';
import es_ES from 'lngProvider/locales/es_ES.json';
import fr_CH from 'lngProvider/locales/fr_CH.json';
import fr_FR from 'lngProvider/locales/fr_FR.json';
import it_IT from 'lngProvider/locales/it_IT.json';
import pl_PL from 'lngProvider/locales/pl_PL.json';
import pt_BR from 'lngProvider/locales/pt_BR.json';
import pt_PT from 'lngProvider/locales/pt_PT.json';
import en_US from 'lngProvider/locales/en_US.json';

import axios from 'axios';
import { TYPE_SIZING_MODULES } from 'constants/products';
import {
    BASENAME_URL_OAREA_PPC_B2B,
    BASENAME_URL_OAREA_PPC_B2C,
    ENVIRONMENTS_FE,
    MYENERGYSOLAR_B2B_URL_PROD,
    MYENERGYSOLAR_B2B_URL_TST,
    MYENERGYSOLAR_B2C_URL_PROD,
    MYENERGYSOLAR_B2C_URL_TST,
    SUBDOMAINS,
} from 'constants/settings';
import { AVAILABLE_USER_LOCALES } from 'constants/user';
import { StatusCodes } from 'http-status-codes';
import { caeTranslation, cpeTranslation } from 'services/user/translations';
import { useUserStore } from 'store/user';
export const handleResponseWebstomp = (payload) =>
    payload?.data?.price ? { ...payload.data, price: parseInt(payload?.data?.price.replace('.', '')) } : payload.data;
export const getLetterAvatar = (type, lettersNumber) => type.substr(-type.length, lettersNumber).toUpperCase();
export const isInteger = (value) => /^\d+$/.test(value);
export const isString = (value) => typeof value === 'string';
export const isNumber = (value) => typeof value === 'number';
export const isBoolean = (value) => typeof value === 'boolean';
export const isBooleanAlike = (value) => typeof value === 'boolean' || value === 'false' || value === 'true';
export const getBoolean = (value) => (typeof value === 'boolean' ? value : value === 'true' || value === 'True');
export const isEqualsInputs = (newInputs, oldInputs) => JSON.stringify(newInputs) === JSON.stringify(oldInputs);
export const isObject = (value) => !!value && typeof value === 'object';
export const EMAIL_REGEXP =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const TELEPHONE_REGEXP = /^\d{10}$/;
export const FLOAT_REGEXP = /^(?:[0-9]\d*|0)?(?:\.\d+)?$/;
export const isValidEmail = (email) => EMAIL_REGEXP.test(email);
export function isBlob(value) {
    return value instanceof Blob;
}
export const PASSWORD_CHECK = {
    PASSWORD_HAS_LOWERCASE_REGEXP: /(?=.*[a-z])/, // Matches if password has at least 1 lower case character
    PASSWORD_HAS_UPPERCASE_REGEXP: /(?=.*[A-Z])/, // Matches if password has at least 1 upper case character
    PASSWORD_HAS_DIGIT_REGEXP: /(?=(.*[\d]){1,})/, // Matches if password has at least 1 digit
    PASSWORD_HAS_SPECIAL_CHARACTER: /(?=(.*[-+_!@#$%^&*.,?]){1,})/, // Matches if password has at least 1 special character
    PASSWORD_IS_VALID: /(?=.*[a-z])(?=.*[A-Z])(?=(.*\d){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}/, // All the above checks in one and minimum 8 characters
};
export const removePunctuation = (string) => string?.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').replace(/\s{2,}/g, ' ');

export const zeroPad = (num, places) => String(num).padStart(places, '0');

/**
 * Block scroll when entering the element ID
 *
 * @param id
 */
export const blockScroll = (id) => {
    if (document.getElementById(id)) document.getElementById(id)!.style.overflowX = 'auto';
};

/**
 * Scroll Elements between left and right
 *
 * @param id
 * @param direction
 * @param onRight
 */
export const scrollToLeftRight = (id, direction, onRight) => {
    const top = document.getElementById(id);
    // @ts-ignore
    top.scrollLeft = direction === 'left' ? 0 : 1200;

    switch (direction) {
        case 'left': // @ts-ignore
            top.scrollLeft = 0;
            onRight(false);
            break;
        case 'right': // @ts-ignore
            top.scrollLeft = 1200;
            onRight(true);
            break;
        default:
            break;
    }
};

/**
 * Convert from a unit to another only product CE
 *
 * @param value
 * @param rule
 * @returns {number}
 */
export const convertToUnit = (value, rule) => {
    switch (rule) {
        //From Kilowatt to Megawatt
        case 'fromKwToMw':
            return value / 1000;
        //From Megawatt to Kilowatt
        case 'fromMwToKw':
            return value * 1000;
        //Default
        default:
            return 0;
    }
};

export const formatDate = (date) => {
    const now = new Date();

    if (!date) date = '00:00:00';

    if (date) {
        const [hour, minute] = date.split(':');

        now.setHours(hour);
        now.setMinutes(minute);
        now.setSeconds(0);
        return now;
    }

    return date;
};

export const formatDateArrayBD = (dateArray) => {
    const returnDateArray: string[] = [];

    dateArray.forEach((date) => {
        const _date = new Date(date);
        let hours = _date.getHours().toString();
        let minutes = _date.getMinutes().toString();
        let seconds = _date.getSeconds().toString();

        if (hours.length === 1) hours = '0' + hours;
        if (minutes.length === 1) minutes = '0' + minutes;
        if (seconds.length === 1) seconds = '0' + seconds;

        returnDateArray.push(`${hours}:${minutes}:${seconds}`);
    });

    return returnDateArray;
};

/**
 * toUpper
 *
 * @param value
 * @returns {*|string}
 */
export const toUpper = (value) => value && value.toUpperCase();

/**
 * calculateConsumptionInTOE
 *
 * @param energy_source
 * @param value
 * @param unit
 * @returns {number}
 */
export const calculateConsumptionInTOE = (energy_source, value, unit) => {
    let result = -1;
    switch (energy_source) {
        case 'electric':
            if (unit === 'MWh') result = value * 0.000215 * 1000;
            else if (unit === 'kWh') result = value * 0.000215;
            break;
        case 'fueloil':
            if (unit === 'ton') result = value * 0.984;
            else if (unit === 'kg') result = (value * 0.984) / 1000;
            break;
        case 'diesel':
            if (unit === 'ton') result = value * 1.022;
            else if (unit === 'kg') result = (value * 1.022) / 1000;
            else if (unit === 'L') result = value * ((0.82 + 0.845) / 2 / 1000) * 1.022;
            break;
        case 'pellets':
            if (unit === 'ton') result = value * 0.401;
            else if (unit === 'kg') result = (value * 0.401) / 1000;
            break;
        case 'natural_gas':
            if (unit === 'MWh') result = value * 0.086;
            else if (unit === 'kWh') result = (value * 0.086) / 1000;
            break;
        case 'propane':
            if (unit === 'ton') result = value * 1.115;
            else if (unit === 'kg') result = (value * 1.115) / 1000;
            break;
        case 'lpg':
            if (unit === 'ton') result = value * 1.115;
            else if (unit === 'kg') result = (value * 1.115) / 1000;
            break;
        default:
            break;
    }

    return Math.round(result * 1000) / 1000;
};

/**
 * calculateTotalTep
 *
 * @param energy_sources_annual_consumptions
 * @returns {number}
 */
export const calculateTotalTep = (energy_sources_annual_consumptions) => {
    let tep_total = 0;
    for (const i in energy_sources_annual_consumptions) {
        const tep_value = calculateConsumptionInTOE(
            energy_sources_annual_consumptions[i].source,
            energy_sources_annual_consumptions[i].annual_consumption,
            energy_sources_annual_consumptions[i].unit
        );
        tep_total = tep_total + tep_value;
    }
    return tep_total;
};

/**
 * getStatsSetor
 *
 * @param name
 * @param value
 * @param gStats
 * @returns {null|{mediana: number, percentil: *}}
 */
export const getStatsSetor = (name, value, gStats) => {
    const stats_obj = ['age', 'area', 'cons', 'consumption_in_sector_and_contracted_power_range', 'county', 'type', 'workers'];
    if (!stats_obj.includes(name)) return null;

    return {
        mediana: Math.round((value / parseFloat(gStats[name][49]) - 1) * 100),
        percentil:
            gStats[name].indexOf(
                gStats[name].reduce(function (curr, prev) {
                    return Math.abs(curr - value) > Math.abs(prev - value) ? prev : curr;
                })
            ) + 1,
    };
};
/**
 * formatData
 *
 * @description Format data according to rules
 * @param timestamp
 * @returns {number}
 */
export const formatData = (timestamp) => {
    const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    const date = new Date(timestamp);
    let date_format = date.getDate() + '/' + months[date.getMonth()] + '/' + date.getFullYear() + ' ' + date.getHours() + ':';
    const minutes = date.getMinutes().toString();
    date_format += minutes.length === 1 ? '0' + minutes : minutes;
    return date_format;
};
/**
 * getParseNumber
 *
 * @description Get Integer from a formatted string
 * @param value
 * @param delimiter
 * @param numberOfDecimalPlaces
 * @returns {number}
 */
export const getParseNumber = (value, delimiter, numberOfDecimalPlaces = 1) => {
    if (isNumber(value)) {
        return parseFloat(value.toFixed(numberOfDecimalPlaces));
    } else {
        if (delimiter === '.' && !!value) {
            value = value.split(delimiter).join('');
            value = value.replace(',', '.');
        }
        if (delimiter === ',' && !!value) {
            value = value.split(delimiter).join('');
        }
    }

    return parseFloat(parseFloat(value).toFixed(numberOfDecimalPlaces));
};

//Get translations
const getTranslationsByUser = (options) => {
    switch (options.user?.idioma) {
        case 'pt':
            return pt_PT;
        case 'es':
            return es_ES;
        default:
            return pt_PT;
    }
};

/**
 * translateKeyword FIXME: remover este metodo dos components
 */
export const translateKeyword = (keyword, options) => {
    if (!keyword) return '_PAGE_ERRORS_';
    //Get translations by user
    const keywords = getTranslationsByUser(options);
    return keywords[keyword];
};

/**
 * translateAndJoin
 *
 * @description Translate and join two strings
 * @param strings
 * @param options
 * @returns {string}
 */
export const translateAndJoin = (strings, options) => {
    //Get translations by user
    const keywords = getTranslationsByUser(options);
    let accumulator = '';
    //Translate Values
    for (let i = 0; i < strings.length; i++) {
        options.numbers[i] = options.numbers[i] ? options.numbers[i] : '';
        accumulator = accumulator + keywords[strings[i]] + options.numbers[i];
    }
    return accumulator;
};

/**
 * getParseDate
 *
 * @returns {string}
 */
export const getParseDate = () => {
    const date = new Date(Date.now());
    let day: number | string = date.getDate();
    let month: number | string = date.getMonth() + 1;
    const year = date.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    return year + '-' + month + '-' + day;
};

/**
 * isDefined
 *
 * @description verifia apenas se é um inputs !== undefined e null
 * @param input
 * @returns {boolean|boolean}
 */
export const isDefined = (input) => input !== undefined && input !== null;

/**
 * getValue Only checks if the value is set, otherwise returns null
 *
 * @param value
 * @returns {*}
 */
export const getValue = (value) => (isDefined(value) ? value : null);

/**
 * isAttributeOfObject
 *
 * @description verifica se o attr faz parte do object
 * @param obj
 * @param attr
 * @returns {boolean}
 */
export const isAttributeOfObject = (obj, attr) => {
    if (!obj) return false;
    return Object.keys(obj).includes(attr);
};

/**
 * parseBoolean
 *
 * @param value
 * @returns {boolean}
 */
export const parseBoolean = (value) => value == 'true'; //eslint-disable-line

/**
 * getNextID
 *
 * @param _array
 * @param id
 * @param findBy
 * @returns {number}
 */
export const getNextID = (_array, id, findBy) => {
    if (!_array || _array?.length === 0) return 1;

    let next = id === 0 ? 1 : id;

    for (id; id <= _array.length; id++) {
        const value = _array.find(function (x) {
            return x[findBy] === next;
        }); //eslint-disable-line
        if (value === undefined) {
            break;
        }
        next++;
    }
    return next;
};

/**
 * isEmpty array
 * @param array
 * @returns {boolean}
 */
export const isEmptyArray = (array) => array?.length === 0;

export const getDaysOfWeekValues = (inputs) => ({
    working_days: inputs.working_days,
    trabalha_sab: inputs.trabalha_sab,
    trabalha_dom: inputs.trabalha_dom,
    trabalha_feriado: inputs.trabalha_feriado,
});

/**
 * intlMessages
 *
 * @param id - string (keyword)
 * @param values - {*}
 * @returns {*}
 */
export function intlMessages(id, values = {}) {
    const pais_id = useUserStore.getState()?.user?.pais_id ?? '1';

    // @ts-ignore
    const CURRENCY_SYMBOL = values?.CURRENCY_SYMBOL ? values.CURRENCY_SYMBOL : '##';
    // @ts-ignore
    const CPE = values?.CPE ? values.CPE : cpeTranslation(pais_id);

    // @ts-ignore
    const CAE = values?.CAE ? values.CAE : caeTranslation(pais_id);

    let translation = getTranslations()[id];

    //fix for currency symbol - get user currency symbol
    const indexSymbol = translation?.indexOf(`{CURRENCY_SYMBOL}`);
    if (!!indexSymbol && indexSymbol > 0) {
        values =
            values ?
                { ...values, CURRENCY_SYMBOL: CURRENCY_SYMBOL, CPE: CPE, CAE: CAE }
            :   { CURRENCY_SYMBOL: CURRENCY_SYMBOL, CPE: CPE, CAE: CAE };
    }

    const indexCPE = translation?.indexOf(`{CPE}`);
    if (!!indexCPE && indexCPE > 0) {
        values =
            values ?
                { ...values, CURRENCY_SYMBOL: CURRENCY_SYMBOL, CPE: CPE, CAE: CAE }
            :   { CURRENCY_SYMBOL: CURRENCY_SYMBOL, CPE: CPE, CAE: CAE };
    }

    const indexCAE = translation?.indexOf(`{CAE}`);
    if (!!indexCAE && indexCAE > 0) {
        values =
            values ?
                { ...values, CURRENCY_SYMBOL: CURRENCY_SYMBOL, CPE: CPE, CAE: CAE }
            :   { CURRENCY_SYMBOL: CURRENCY_SYMBOL, CPE: CPE, CAE: CAE };
    }

    //replacements
    if (values) {
        for (const prop in values) {
            for (let i = 0; i < translation.length; i++) {
                if (Object.prototype.hasOwnProperty.call(values, prop)) {
                    translation = translation.replace(`{${prop}}`, values[prop]);
                }
            }
        }
    }
    return translation ? translation : `PLEASE TRANSLATE THIS (${id}) KEYWORD`;
}

/**
 * intlMessages
 *
 * @param b2c - Bollean (keyword)
 * @param plural - true False (keyword)
 * @returns {*}
 */
export function intlTranslate(b2c, plural) {
    const labelSelect =
        !plural ?
            b2c ? 'label.house'
            :   'label.facility'
        : b2c ? 'label.house.plural'
        : 'label.facility.plural';

    const translation = getTranslations()[labelSelect];

    return translation;
}

const getTranslations = () => {
    const language = useUserStore.getState().LOCALE_CODE;
    switch (language ?? 'en') {
        case 'pt':
        case AVAILABLE_USER_LOCALES.ptPT:
            return pt_PT;
        case 'es':
        case AVAILABLE_USER_LOCALES.esES:
            return es_ES;
        case 'en':
        case AVAILABLE_USER_LOCALES.enGB:
            return en_GB;
        case 'cz':
        case AVAILABLE_USER_LOCALES.czCZ:
            return cz_CZ;
        case 'it':
        case AVAILABLE_USER_LOCALES.itIT:
            return it_IT;
        case 'pl':
        case AVAILABLE_USER_LOCALES.plPL:
            return pl_PL;
        case 'br':
        case AVAILABLE_USER_LOCALES.ptBR:
            return pt_BR;
        case 'ch':
        case AVAILABLE_USER_LOCALES.deCH:
        case 'de':
        case AVAILABLE_USER_LOCALES.deDE:
            return de_DE;
        case 'ie':
        case AVAILABLE_USER_LOCALES.enIE:
            return en_IE;
        case 'gr':
        case AVAILABLE_USER_LOCALES.elGR:
            return el_GR;
        case 'sg':
        case AVAILABLE_USER_LOCALES.enSG:
            return en_SG;
        case 'fr':
        case AVAILABLE_USER_LOCALES.frCH:
            return fr_CH;
        default:
            return en_GB;
    }
};

export function getTranslation(id = 'label.NA', language = 'en') {
    if (!isDefined(language)) language = useUserStore.getState().LOCALE_CODE;

    let _lngProvider: any = null;

    switch (language ?? 'en') {
        case AVAILABLE_USER_LOCALES.enUS:
            _lngProvider = en_US;
            break;
        case 'pt':
        case AVAILABLE_USER_LOCALES.ptPT:
            _lngProvider = pt_PT;
            break;
        case 'es':
        case AVAILABLE_USER_LOCALES.esES:
            _lngProvider = es_ES;
            break;
        case 'en':
        case AVAILABLE_USER_LOCALES.enGB:
            _lngProvider = en_GB;
            break;
        case 'cz':
        case AVAILABLE_USER_LOCALES.czCZ:
            _lngProvider = cz_CZ;
            break;
        case 'it':
        case AVAILABLE_USER_LOCALES.itIT:
            _lngProvider = it_IT;
            break;
        case 'pl':
        case AVAILABLE_USER_LOCALES.plPL:
            _lngProvider = pl_PL;
            break;
        case 'br':
        case AVAILABLE_USER_LOCALES.ptBR:
            _lngProvider = pt_BR;
            break;
        case 'ch':
        case AVAILABLE_USER_LOCALES.deDE:
        case 'de':
        case AVAILABLE_USER_LOCALES.deCH:
            _lngProvider = de_DE;
            break;
        case 'sg':
        case AVAILABLE_USER_LOCALES.enSG:
            _lngProvider = en_SG;
            break;
        case 'ie':
        case AVAILABLE_USER_LOCALES.enIE:
            _lngProvider = en_IE;
            break;
        case 'fr':
        case AVAILABLE_USER_LOCALES.frFR:
            _lngProvider = fr_FR;
            break;
        case AVAILABLE_USER_LOCALES.frCH:
            _lngProvider = fr_CH;
            break;
        case 'gr':
        case AVAILABLE_USER_LOCALES.elGR:
            _lngProvider = el_GR;
            break;
        default:
            _lngProvider = en_GB;
            break;
    }
    return _lngProvider[id];
}

export const checkTranslationExistence = (id) => {
    return !!getTranslations()[id];
};

/**
 * Round x decimal places
 *
 * @param value
 * @param decimalPlaces
 * @returns {*}
 */
export function parseNumberWithDecimals(value, decimalPlaces) {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(value * multiplier) / multiplier;
}

/**
 * setRequestingAction
 *
 * @param list
 * @param itemID
 * @param action
 * @param value
 * @returns {*}
 */
export function setRequestingAction(list, itemID, action, value) {
    return list.map((item) => {
        if (item.id === itemID) item.isRequestingAction = { [action]: value };
        return item;
    });
}

export function sortOrder(items, fieldOrder = 'order') {
    // @ts-ignore
    return items.sort(({ [fieldOrder]: previousOrder }, { [fieldOrder]: currentOrder }) => previousOrder - currentOrder);
}

export const isFieldDefined = (field) => isDefined(field) && field !== '';
export const isNumberDefined = (number) => !isNaN(number) && isFieldDefined(number);

/**
 * getFieldValue
 *
 * @param {*} value
 */
export const getFieldValue = (value) => (isFieldDefined(value) ? value : null);

export const isFloat = (n) => Number(n) === n && n % 1 !== 0;

export const isExistWord = (string, word) => {
    return string?.search(word) !== -1;
};

/**
 * hasProductInputs
 *
 * @param {*} inputs
 * @param {*} _module TYPE_SIZING_MODULES.<module>
 */
export const hasProductInputs = (inputs, _module = TYPE_SIZING_MODULES.SIMPLES) => !!inputs && !!inputs[_module];

export const generateRandomString = () =>
    Math.random().toString(36).substring(2, 4) + Math.random().toString(36).substring(2, 4).toUpperCase();

// we have to do some additional check
export const isEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
export const isEqualsLengths = (obj1, obj2) =>
    Object.keys(obj1).length === Object.keys(obj2).length && obj1.constructor === Object && obj2.constructor === Object;

/**
 * trimAll removes all whitespace
 *
 * @param {*} str
 */
export const trimAll = (str) => str.replace(/\s/g, '');

/**
 * removeEmptyInObj
 *
 * @param {} obj
 */
export const removeEmptyInObj = function (obj) {
    for (const key in obj) if (!isFieldDefined(obj?.[key])) delete obj[key];
    return clone(obj);
};

/**
 * visibleSections
 *
 * @param {*} questions
 */
export const visibleSections = (questions) => {
    const visibleSections: any[] = [];
    for (const question in questions) {
        // Mark section as visible if there is a visible input in that section
        if (questions[question].visible && !visibleSections.includes(questions[question].section))
            visibleSections.unshift(questions[question].section);
    }
    return visibleSections;
};

export const dateFormatted = (date) => {
    let date_formatted = date;

    if (date_formatted) {
        date_formatted = new Date(date);
        date_formatted.setHours(0, 0, 0);
        date_formatted = date_formatted.getTime();
    }

    return date_formatted;
};

const removeFieldNaNObj = function (obj) {
    const INPUTS_NUMBER = [
        'consumo_anual',
        'power_limit',
        'contracted_power',
        'consumo_anual_mwh',
        'consumo_anual_gas_mwh',
        'fornecedor_gas_id',
        'area',
        'area_util',
        'area_coberta',
        'num_trabalhadores',
        'tarifa_fixa_gas_mwh',
        'potencia_requisitada',
        'tarifa_media_gas_mwh',
        'opcao_curva_carga_id',
        'distribuidor_id',
        'nivel_tensao_id',
        'opcao_tarifario_id',
        'potencia_contratada',
        'tipo_instalacao_id',
        'potencia_ponta',
        'battery_subsidy_prc',
        'power_fee',
        'num_fases',
        'tipo_ciclo_id',
        'battery_capacity_selected_kwh',
        'battery_power_selected_kw',
        'injection_decrease_selected_perc',
        'monthly_fee',
    ];

    for (const key in obj) if (INPUTS_NUMBER.includes(key) && isNaN(obj[key])) delete obj[key];
    return clone(obj);
};

/**
 * removeFieldNaNInObj
 *
 * remove number fields set as NaN in obj
 *
 * @param {*} obj
 */
export const removeFieldNaNInObj = (obj) => {
    for (const key in obj) if (isFieldDefined(obj[key]) && typeof obj[key] === 'number' && isNaN(obj[key])) delete obj[key];
    return clone(obj);
};

/**
 * removeFieldEmptyInObj
 *
 * remove fields with null our undefined
 *
 * @param {} obj only null or undefined
 */
export const removeFieldEmptyInObj = function (obj, exceptions: string[] = []) {
    // Recursively iterate through the object
    for (const key in obj) {
        if (isObject(obj?.[key])) {
            // If the value is an object, recursively call the function
            obj[key] = removeFieldEmptyInObj(obj[key], exceptions);
            // After processing nested properties, if the object is empty, delete it
            if (isEmpty(obj[key]) && !exceptions.includes(key)) {
                delete obj[key];
            }
        } else {
            // If the value is not an object, delete empty fields
            if (!isFieldDefined(obj?.[key]) && !exceptions.includes(key)) {
                delete obj[key];
            }
        }
    }

    return clone(removeFieldNaNObj(obj));
};

/**
 * parseNumberWithToFixed
 *
 * @param {*} value
 * @param {*} decimalPlaces
 */
export const parseNumberWithToFixed = (value, decimalPlaces) =>
    isFieldDefined(decimalPlaces) ? parseFloat(parseFloat(value).toFixed(decimalPlaces)) : value;

/**
 * calcConvertionToUnit
 *
 * @param {*} value
 * @param {*} rule ex. currencykWhToMWh
 * @param {*} decimalPlaces
 */
export const calcConvertionToUnit = (value, rule, decimalPlaces) => {
    switch (rule) {
        //From currency KiloWatt hours to MegaWatt hours e
        case 'currencykWhToMWh':
        case 'meterToMilimeter':
            value = value * 1000;
            break;
        //From currency MegaWatt hours to kiloWatt hours
        case 'currencyMWhTokWh':
        case 'milimeterToMeter':
            value = value / 1000;
            break;
        //Default
        default:
            break;
    }

    return parseNumberWithToFixed(value, decimalPlaces);
};

/**
 * mathRadians
 * @param {*} degrees
 */
export function mathRadians(degrees) {
    return (degrees * Math.PI) / 180;
}

/**
 * mathRound
 * @param {*} value
 * @param {*} precision
 */
export function mathRound(value, precision) {
    if (Number.isInteger(precision)) {
        const shift = Math.pow(10, precision);
        // Limited preventing decimal issue
        return Math.round(value * shift + 0.00000000000001) / shift;
    } else {
        return Math.round(value);
    }
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate = null) {
    let timeout;
    return function () {
        // @ts-ignore
        // eslint-disable-next-line no-var, @typescript-eslint/no-this-alias
        var context = this,
            // eslint-disable-next-line prefer-rest-params
            args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

//remove os duplicados
export function getUniqueValuesInObjectArr(arr) {
    return arr.filter((v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i);
}

/**
 * editElemtInArray
 *
 * @param {[]} array
 * @param {number} index
 * @param {*} data
 * @returns
 */
export function editElemtInArray(array, index, data) {
    array[index] = data;
    return array;
}

/**
 * addElemtInArray
 *
 * @param {[]} array
 * @param {number} index
 * @param {*} element
 * @param {number} deleteCount
 * @returns
 */
export function addElemtInArray(array, index, element, deleteCount = 0) {
    array.splice(index, deleteCount, element);
    return array;
}

// Loading while try to send Email or update State
export const setChangeIconState = (array, processID, state) => {
    return array.map((item) => {
        if (item.id === processID) {
            switch (state) {
                case 'loadingSendCRM':
                    item.loadingSendCRM = !item.loadingSendCRM;
                    break;
                case 'disabledSendCRM':
                    item.disabledSendCRM = !item.disabledSendCRM;
                    break;
                default:
                    break;
            }
        }
        return item;
    });
};

export const handleCloseDialog = (_, reason, closeHandler) => {
    if (['backdropClick'].includes(reason)) return false;
    if (typeof closeHandler === 'function') closeHandler();
};

/**
 * createFormDataWithImageBlob
 *
 * @param {*} imageBlob
 * @param {string=} inputName
 * @param {string=} fileName
 * @param {string=} folder
 * @returns  formData
 */
export const createFormDataWithImageBlob = (imageBlob, inputName = '', fileName = 'image', folder?: string) => {
    const filename = `${fileName}-${new Date().getTime()}.png`;
    const file = new File([imageBlob], filename, { type: 'image/png' });
    const formData = new FormData();
    formData.append(inputName, file);

    if (!!folder && isDefined(folder)) formData.append('folder', folder);

    return formData;
};

// Get the angle that summed with the one provided adds up to 180º
export const getSupplementaryAngle = (angle) => (angle <= 0 ? angle + 180 : angle - 180);

/**
 * fieldLink
 * @param {*} env
 */
export const fieldLink = () => {
    const env = process.env.REACT_APP_ENV!.toLowerCase();
    const isProduction = process.env.REACT_APP_ENV === ENVIRONMENTS_FE.PRD;
    return `https://${isProduction ? '' : `${env}.`}${process.env.REACT_APP_FIELD_URL}`;
};
/**
 * check if all elements from are equal
 * @param {*} arr
 * @returns boolean
 */
export const allEqual = (arr) => arr.every((val) => val === arr[0]);

/**
 * Method to get a sum of all costs of an array
 * @param {array} costs
 * @returns
 */
export const costsSum = (costs) => costs.reduce((sumCost, cost) => (parseFloat(cost) || 0) + sumCost, 0);

/**
 * Method to check if array of strings has part of that string
 * @param {array} array
 * @param {string} string
 * @returns
 */
export const containString = (array, string) =>
    array.some((element) => {
        if (string.includes(element)) return true;
        return false;
    });

export const isAllStatusOK = (rsps, isTwoDimensional = false) => {
    const errors: { url: string; response: any }[] = [];

    if (isTwoDimensional) {
        for (let idx = 0; idx < rsps.length; idx++) {
            const responsesFail = rsps[idx].filter((rqs) => !!rqs?.data)?.filter((item) => item?.status !== StatusCodes.OK);
            if (responsesFail?.length > 0) {
                responsesFail.forEach((el) => {
                    errors.push({
                        url: el.request?.responseURL,
                        response: el?.request?.response,
                    });
                });
            }
        }
    } else {
        const responses = rsps.filter((el) => isFieldDefined(el));
        responses.forEach((el) => {
            if (el?.status !== StatusCodes.OK) {
                errors.push({
                    url: el.request?.responseURL,
                    response: el?.request?.response,
                });
            }
        });
    }

    return errors;
};

/**
 * Recursive method to access any object property (nested or not), given its full path.
 * (as seen in https://stackoverflow.com/a/26407251)
 * For example, given:
 *      -> object = { a: 1, b: 2, c: {x: 999, y:998, z: 997}};
 *      -> prop: 'c.x';
 * calling this function with these args returns 999 (the value of object.c.x).
 * @param {object} object
 * @param {string} prop
 * @returns
 */
export const accessObjectProp = (object, prop: string) => {
    if (typeof object === 'undefined' || typeof prop !== 'string' || prop === '') {
        return undefined;
    }

    const _index = prop.indexOf('.');
    if (_index > -1) {
        return accessObjectProp(object[prop.substring(0, _index)], prop.substr(_index + 1));
    }

    return object[prop];
};

export const decimalsHandler = (type, value, sliderDecimalScale = 1) => {
    switch (type) {
        case 'with':
            return Math.floor((value / 100) * 10 ** sliderDecimalScale) / 10 ** sliderDecimalScale;
        case 'without':
            return Math.floor(Math.round(value * 100) * 10 ** sliderDecimalScale) / 10 ** sliderDecimalScale;
        default:
            break;
    }
};

function hasGTM() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (
            cookie.startsWith('_pk') ||
            cookie.startsWith('_dd') ||
            cookie.startsWith('_gtm') ||
            cookie.startsWith('_clck') ||
            cookie.startsWith('_gcl') ||
            cookie.startsWith('_fb') ||
            cookie.startsWith('_clsk') ||
            cookie.startsWith('_uet')
        ) {
            return true;
        }
    }
    return false;
}

//#region GTM cleanup
function clearAllCookies() {
    const cookies = document.cookie.split(';');

    cookies.forEach((cookie) => {
        const [name] = cookie.split('=').map((c) => c.trim());
        if (!['CookieConsent', 'PersonalizedAdvertisingCookie', 'PlatformCustomizationCookie'].includes(name)) {
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        }
    });
}

function clearAllSessionStorage() {
    const keys = Object.keys(sessionStorage);
    keys.forEach((key) => {
        if (![''].includes(key)) sessionStorage.removeItem(key);
    });
}

function clearAllLocalStorageKeys() {
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
        if (!['efz-cleanup-notify', 'efz-cleanup', 'user', 'auth'].includes(key)) localStorage.removeItem(key);
    });
}

export function forceCleanup(ffDescription: string) {
    // cleanup cookies
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

    // cleanup  sessionStorage
    sessionStorage.clear();

    //  cleanup localStorage
    localStorage.clear();
    localStorage.setItem('efz-cleanup', `efz:${ffDescription}`);
    localStorage.setItem('efz-cleanup-notify', `${true}`);
}

export function cleanupGTM() {
    if (hasGTM()) {
        clearAllCookies();
        clearAllSessionStorage();
        clearAllLocalStorageKeys();
    }
}

//#endregion

// Convert the Data URL to a Blob object
export function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}

export const hasAtLeastOneTrueValue = (obj) => {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === true) {
            return true;
        }
    }
    return false;
};

export const isSolarpvPPCOAreaB2B = () => {
    const urlWithoutProtocol = document.location.href.split('://');
    const domain = ENVIRONMENTS_FE.PRD === process.env.REACT_APP_ENV ? MYENERGYSOLAR_B2B_URL_PROD : MYENERGYSOLAR_B2B_URL_TST;

    // URL
    const regexURL = new RegExp('\\b' + domain + '\\b', 'i'); // "i" flag for case-insensitive search
    let isSolarpvB2B = regexURL.test(urlWithoutProtocol[1]);
    if (isSolarpvB2B) return isSolarpvB2B;

    // subdomain
    const pathname = document.location.pathname;
    const subdomain = BASENAME_URL_OAREA_PPC_B2B;
    const regexSubdomain = new RegExp('\\b' + subdomain + '\\b', 'i'); // "i" flag for case-insensitive search

    isSolarpvB2B = regexSubdomain.test(pathname);

    return isSolarpvB2B;
};

export const isSolarpvPPCOAreaB2C = () => {
    const locationHost = document.location.host.split('.');
    const pathname = document.location.pathname;
    const subdomainOArea = [SUBDOMAINS.PPC];
    const isSubdomainOArea = isDefined(locationHost.find((item) => subdomainOArea.includes(item)));
    if (['', '/'].includes(pathname) && isSubdomainOArea) {
        return true;
    }

    const urlWithoutProtocol = document.location.href.split('://');
    const domain = ENVIRONMENTS_FE.PRD === process.env.REACT_APP_ENV ? MYENERGYSOLAR_B2C_URL_PROD : MYENERGYSOLAR_B2C_URL_TST;

    // URL
    const regexURL = new RegExp('\\b' + domain + '\\b', 'i'); // "i" flag for case-insensitive search
    let isSolarpvB2C = regexURL.test(urlWithoutProtocol[1]);
    if (isSolarpvB2C) return isSolarpvB2C;

    // subdomain
    const subdomain = BASENAME_URL_OAREA_PPC_B2C;
    const regexSubdomain = new RegExp('\\b' + subdomain + '\\b', 'i'); // "i" flag for case-insensitive search

    isSolarpvB2C = regexSubdomain.test(pathname);

    return isSolarpvB2C;
};

export function generateNumberArray(minValue, maxValue, step) {
    const result: number[] = [];
    for (let i = minValue; i <= maxValue; i += step) {
        result.push(i);
    }
    return result;
}

export function removeDuplicates(array, attribute) {
    const uniqueValues: any[] = [];
    return array.filter((item) => {
        if (!uniqueValues.includes(item[attribute])) {
            uniqueValues.push(item[attribute]);
            return true;
        }
        return false;
    });
}

export const makeRequestsInChunks = async (requestDataArray: Promise<any>[], chunkSize = 15) => {
    const responses: any[] = [];

    // Loop through the requestDataArray in chunks
    for (let i = 0; i < requestDataArray.length; i += chunkSize) {
        const chunk = requestDataArray.slice(i, i + chunkSize);

        // Wait for all promises in the chunk to resolve
        const chunkResponses: Promise<any>[] = await axios.all(chunk);

        // Add the responses from the current chunk to the overall responses array
        responses.push(...chunkResponses);
    }

    return responses;
};
// Função que retorna uma promessa que resolve após um tempo determinado
export function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export function getRandomDelay(time: number) {
    return Math.floor(Math.random() * time + 1) + time;
}

export const matchesAnyRegex = (string: string, regexArray: RegExp[]) => regexArray.some((r) => !!string?.match(r));

//#region RGBA opacity to solid color

export const convertRgbaToSolidColor = (rgba: string): string => {
    // Convert any RGB or RGBA to solid color
    const rgbaValues = rgba.match(/\d+(\.\d+)?/g);
    if (!rgbaValues || rgbaValues.length < 4) {
        throw new Error('Invalid RGBA color format');
    }
    const [r, g, b, a] = rgbaValues.map(Number);
    const rSolid = Math.round(r * a + (1 - a) * 255);
    const gSolid = Math.round(g * a + (1 - a) * 255);
    const bSolid = Math.round(b * a + (1 - a) * 255);
    return `rgb(${rSolid}, ${gSolid}, ${bSolid})`;
};

// convert Theme bleached colors to solid colors
export const convertThemeBleachedToSolid = (themePalette: any): any => {
    // Clone the theme object to avoid mutating the original
    const newTheme = JSON.parse(JSON.stringify(themePalette));

    // Iterate over the theme colors and convert bleached colors
    Object.keys(newTheme).forEach((colorKey) => {
        const color = newTheme[colorKey];
        if (color.bleached) {
            color.bleached = convertRgbaToSolidColor(color.bleached);
        }
    });

    return newTheme;
};
//#endregion simplepolygon
function equalArrays(array1: any, array2: any): boolean {
    // if the other array is a falsy value, return
    if (!array1 || !array2) return false;

    // compare lengths - can save a lot of time
    if (array1.length != array2.length) return false;

    for (let i = 0, l = array1.length; i < l; i++) {
        // Check if we have nested arrays
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            // recurse into the nested arrays
            if (!equalArrays(array1[i], array2[i])) return false;
        } else if (array1[i] != array2[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}

// Function to check if array is unique (i.e. all unique elements, i.e. no duplicate elements)
function isUnique(array: any[]): boolean {
    const u: Record<string, number> = {};
    let isUnique = true;
    for (let i = 0, l = array.length; i < l; ++i) {
        // eslint-disable-next-line no-prototype-builtins
        if (u.hasOwnProperty(array[i])) {
            isUnique = false;
            break;
        }
        u[array[i]] = 1;
    }
    return isUnique;
}

export function hasDuplicateVertices(coordinates: [number, number][]): boolean {
    // Process input
    const numRings = coordinates.length;
    const vertices = [] as any;
    for (let i = 0; i < numRings; i++) {
        const ring = coordinates[i];
        if (!equalArrays(ring[0], ring[ring.length - 1])) {
            ring.push(ring[0]); // Close input ring if it is not
        }

        vertices.push(...ring.slice(0, ring.length - 1));
    }

    return !isUnique(vertices) || numRings < 3;
}
//#endregion simplepolygon

export const valueWithNo0OnDecimals = (value: number): number =>
    (value.toString().split('.')[1] || '').replace(/0+$/, '').slice(0, 2).length;
