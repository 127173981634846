import { useContext } from 'react';

// Components
import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import FinanceCustomizationInstallmentsDetailed from './FinanceCustomization';
import ProjectSummaryInstallmentsDetailed from './ProjectSummary';
import OfferSummaryActionsInstallmentsDetailed from './Actions';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TInstallments } from 'interfaces/businessModels/installments';
// import IntlMessages from "components/util/IntlMessages";

const OfferSummaryInstallmentsDetailed = () => {
    const { negotiationProfile, state } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TInstallments>;

    const showFinanceCustomization = state?.selected?.options?.financing_customization?.enabled ?? false;
    const showOfferSummary = (state?.selected?.options?.has_offer_edition && negotiationProfile?.acesso_resumo_custo) ?? false;

    return (
        <div className="bm-project-summary-card bm-detailed-offer-summary">
            {/* <div className="bm-project-summary-offer-details-header">
                <h1><IntlMessages id="label.modelBusiness" />:</h1>
                <h2>{state.selected.options?.pitch.description ?? <IntlMessages id="product.typeBusinessModel.UP_FRONT" />}</h2>
            </div>

            <div className="bm-project-summary-offer-details-separator" /> */}

            <div className="title-actions-container">
                <ProjectSummaryTitle label={'label.SummaryOffer'} />
                <OfferSummaryActionsInstallmentsDetailed />
            </div>

            <div className="bm-project-summary-offer-details-body">
                {showFinanceCustomization && <FinanceCustomizationInstallmentsDetailed showOfferSummary={showOfferSummary} />}
                {showOfferSummary && <ProjectSummaryInstallmentsDetailed />}
            </div>
        </div>
    );
};

export default OfferSummaryInstallmentsDetailed;
