import { useContext } from 'react';

// Components
import ProposalGenerationButtonPaP from 'components/BusinessModels/ProposalGeneration/ProposalGenerationButtonPaP';
import ProposalGenerationButtonServiceV2 from 'components/BusinessModels/ProposalGeneration/ProposalGenerationButtonServiceV2';
import ProposalGenerationButtonServicePPa from './ProposalGenerationButtonServicePPa';
import ProposalGenerationButtonUpFront from './ProposalGenerationButtonUpFront';
import ProposalGenerationButtonPPa from './ProposalGenerationButtonPPa/index';
import ProposalGenerationButtonInstallments from './ProposalGenerationButtonInstallments';
import ProposalGenerationButtonLEC from 'components/BusinessModels/ProposalGeneration/ProposalGenerationButtonLEC';

// Constants
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TBMs } from 'interfaces/businessModels/reducer';

// Services
import { isDefined } from 'services/util/auxiliaryUtils';
import ProposalGenerationButtonESolar from './ProposalGenerationButtonESolar';
import ProposalGenerationButtonService from 'components/BusinessModels/ProposalGeneration/ProposalGenerationButtonService';

const ProposalGeneration = () => {
    const { state } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TBMs>;

    const proposalGenerationButton = () => {
        switch (state.selected.paymentModelID) {
            case PAYMENT_MODELS_IDS.UP_FRONT:
                return <ProposalGenerationButtonUpFront />;
            case PAYMENT_MODELS_IDS.INSTALLMENTS:
                return <ProposalGenerationButtonInstallments />;
            case PAYMENT_MODELS_IDS.PAY_AS_PRODUCED:
                return <ProposalGenerationButtonPaP />;
            case PAYMENT_MODELS_IDS.LEC:
                return <ProposalGenerationButtonLEC />;
            case PAYMENT_MODELS_IDS.SERVICE:
                return <ProposalGenerationButtonService />;
            case PAYMENT_MODELS_IDS.SERVICE_SECOND_VERSION:
                return <ProposalGenerationButtonServiceV2 />;
            case PAYMENT_MODELS_IDS.SERVICE_PPA:
                return <ProposalGenerationButtonServicePPa />;
            case PAYMENT_MODELS_IDS.PPA:
                return <ProposalGenerationButtonPPa />;
            case PAYMENT_MODELS_IDS.EASY_SOLAR:
                return <ProposalGenerationButtonESolar />;
            default:
                return;
        }
    };
    return <div className="bm-proposal-generation">{isDefined(state.selected.paymentModelID) && proposalGenerationButton()}</div>;
};

export default ProposalGeneration;
