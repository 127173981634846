import { createContext, useReducer, useContext, useMemo, useState, useEffect } from 'react';
import clone from 'fast-copy';
import { UserContext } from 'contexts/userContext';
import { initialSpvSimpleReducerB2B, spvSimpleReducerB2B } from './solarpvSimpleReducerB2B';
import { initialSpvSimpleReducerB2C, spvSimpleReducerB2C } from './solarpvSimpleReducerB2C';
import { isOpenArea } from 'services/settings';
import { OAUserContext } from 'contexts/openArea/oaUserContext';
import { func } from 'prop-types';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { BusinessModelsProContextProvider } from 'contexts/businessModelsPro/businessModelsContext';
import { initialSolarpvMapReducer, solarpvMapReducer } from './solarpvMapReducer';
import { useHistory } from 'react-router-dom';
import { setURLByPPCHandler } from 'services/products/solarpvSimple';
import { spvSimpleActions } from 'constants/products/solarpvSimple';

const SPVSimpleContext = createContext({
    isOArea: false,
    forwardFormId: 'solarpv-simple-form',
    spvSimpleState: initialSpvSimpleReducerB2C,
    spvSimpleDispatchHandler: (_type, _payload) => {},
    mapState: initialSolarpvMapReducer,
    mapDispatchHandler: (_type, _payload) => {},
    // Marker
    locationAddress: {
        lat: 0,
        lng: 0,
        address: '', //for input autocomplete: '',
        description: '', //for input autocomplete: '',
        image_name: '',
    },
    hasLocationAddress: false,
    setLocationAddress: func,
    locationRoof: {
        lat: 0,
        lng: 0,
        address: '', //for input autocomplete: '',
        description: '', //for input autocomplete: '',
        image_name: '',
    },
    setLocationRoof: func,
    hasLocationRoof: false,
    userLocale: '',
    selectedCard: null,
    setSelectedCard: func,
    isB2C: true,
});

const MARKER_DEAFULT = {
    lat: null,
    lng: null,
    address: '',
    description: '', //for input autocomplete: '',
    image_name: '',
};

const SPVSimpleProvider = ({ children }) => {
    const history = useHistory();

    const forwardFormId = 'solarpv-simple-form';
    let isOArea = useMemo(() => isOpenArea(), []); //eslint-disable-line

    const { userLocale, isB2C } = useContext(isOArea ? OAUserContext : UserContext);

    const [spvSimpleState, spvSimpleDispatch] = useReducer(
        isB2C ? spvSimpleReducerB2C : spvSimpleReducerB2B,
        isB2C ? initialSpvSimpleReducerB2C : initialSpvSimpleReducerB2B
    );
    const [mapState, mapDispatch] = useReducer(solarpvMapReducer, initialSolarpvMapReducer);

    const [locationAddress, setLocationAddress] = useState(clone(MARKER_DEAFULT));
    const [locationRoof, setLocationRoof] = useState(clone(MARKER_DEAFULT));

    const [selectedCard, setSelectedCard] = useState(null); //solution step simulation
    const [solutionCardProps, setSolutionCardProps] = useState(null); //solution card inputs

    const spvSimpleDispatchHandler = (type, payload) => {
        if (isOArea)
            setURLByPPCHandler({
                type,
                payload,
                history,
                isB2C,
                housing_type: spvSimpleState?.inputs?.housing_type ?? null,
            });
        spvSimpleDispatch({ type, payload });
    };

    const mapDispatchHandler = (type, payload) => {
        mapDispatch({
            type: type,
            payload: payload,
        });
    };

    //#region btn back browser handler
    const handlePopstate = () => {
        if (isOArea) {
            spvSimpleDispatchHandler(spvSimpleActions.SET_STEP_POPSTATE, {
                goBackStep: history?.location?.state?.goBackStep ?? 0,
                history,
            });
        }
    };

    const handleStartOver = () => {
        spvSimpleDispatchHandler(spvSimpleActions.START_OVER);
        setLocationAddress(clone(MARKER_DEAFULT));
        setLocationRoof(clone(MARKER_DEAFULT));
    };

    useEffect(() => {
        window.addEventListener('popstate', handlePopstate);
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []); // eslint-disable-line
    //#endregion

    const spvSimpleContextValues = {
        isOArea,
        isB2C,
        forwardFormId,
        spvSimpleState,
        spvSimpleDispatchHandler,
        mapState,
        mapDispatchHandler,
        userLocale,
        locationAddress,
        setLocationAddress,
        hasLocationAddress: isFieldDefined(locationAddress.lat) && isFieldDefined(locationAddress.lng),
        locationRoof,
        setLocationRoof,
        hasLocationRoof: isFieldDefined(locationRoof.lat) && isFieldDefined(locationRoof.lng),
        // solution
        selectedCard,
        setSelectedCard,
        solutionCardProps,
        setSolutionCardProps,
        handleStartOver,
    };

    return (
        <SPVSimpleContext.Provider value={spvSimpleContextValues}>
            <BusinessModelsProContextProvider>{children}</BusinessModelsProContextProvider>
        </SPVSimpleContext.Provider>
    );
};

export { SPVSimpleContext, SPVSimpleProvider };
