import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import DetailedKpisServicePPaDetailed from './DetailedKpis';
import MarginServicePPaDetailed from './Margin';
import MarginKpisServicePPaDetailed from './MarginKpis';
import { useContext } from 'react';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';

const NegotiationServicePPa = () => {
    const { lowNegotiationProfile, negotiationProfile } = useContext(OfferEditionContext);
    return (
        <div className="bm-detailed-negotiation">
            <ProjectSummaryTitle label="page.businessModels.negotiation.title.tariff" />
            <MarginServicePPaDetailed />
            {negotiationProfile?.mostra_negociacao_default_kpis && <MarginKpisServicePPaDetailed />}
            {!lowNegotiationProfile && <DetailedKpisServicePPaDetailed />}
        </div>
    );
};

export default NegotiationServicePPa;
