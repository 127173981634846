import validate from 'services/util/validate';

/* default objects */
export const INPUT_NAMES_GDATA = {
    ID: 'id',
    GAS_SUPPLIED_BY_COMPANY: 'gas_supplied_by_company',
    CONSUMO_ANUAL_GAS: 'consumo_anual_gas',
    CONSUMO_ANUAL_GAS_MWH: 'consumo_anual_gas_mwh',
    TARIFA_FIXA_GAS: 'tarifa_fixa_gas',
    TARIFA_FIXA_GAS_MWH: 'tarifa_fixa_gas_mwh',
    TARIFA_MEDIA_GAS: 'tarifa_media_gas',
    TARIFA_MEDIA_GAS_MWH: 'tarifa_media_gas_mwh',
    FORNECEDOR_GAS_ID: 'fornecedor_gas_id',
};

//#region /** B2B **/

export const QUESTIONS_GDATA_B2B = {
    [INPUT_NAMES_GDATA.GAS_SUPPLIED_BY_COMPANY]: {
        name: INPUT_NAMES_GDATA.GAS_SUPPLIED_BY_COMPANY,
        label: 'question.label.suppliedByCompany',
        placeholder: null,
        tooltip: null,
        disabled: false,
        visible: false,
        options: [
            { value: true, label: 'label.yes' },
            { value: false, label: 'label.no' },
        ],
        type: 'input',
        validation: null,
    },
    [INPUT_NAMES_GDATA.TARIFA_MEDIA_GAS_MWH]: {
        name: INPUT_NAMES_GDATA.TARIFA_MEDIA_GAS_MWH,
        label: 'question.label.avgTariff',
        placeholder: 'question.placeholder.avgTariff',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'number',
        type: 'input',
        validation: validate(['required', 'number', 'positive']),
    },
    [INPUT_NAMES_GDATA.CONSUMO_ANUAL_GAS_MWH]: {
        name: INPUT_NAMES_GDATA.CONSUMO_ANUAL_GAS_MWH,
        label: 'question.label.annualConsumption',
        placeholder: 'question.placeholder.annualConsumption',
        tooltip: null,
        disabled: false,
        visible: true,
        inputType: 'input',
        type: 'input',
        validation: validate(['required', 'number', 'positive']),
    },
    [INPUT_NAMES_GDATA.TARIFA_FIXA_GAS_MWH]: {
        name: INPUT_NAMES_GDATA.TARIFA_FIXA_GAS_MWH,
        label: 'question.label.fixedPrice',
        placeholder: 'question.placeholder.fixedPrice',
        tooltip: null,
        disabled: false,
        visible: true,
        type: 'input',
        inputType: 'input',
        validation: validate(['required', 'number', 'positive']),
    },
};

//#endregion /** B2B **/

//#region /** B2C **/

export const QUESTIONS_GDATA_B2C = {};
//#endregion /** B2C **/
