import { defineMessages } from 'react-intl';
import itMessages from '../locales/it_IT.json';

const itITLang = {
    messages: {
        ...itMessages,
    },
    locale: 'it-IT',
    data: defineMessages,
};
export default itITLang;
