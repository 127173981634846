import { useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, FieldValues, GlobalError, Path, useFormContext } from 'react-hook-form';
import { InputAdornmentTypeMap, SelectProps } from '@mui/material';

// Interfaces
import { ALL_INPUT_NAMES, TEVCProInputs, TQuestionType } from 'interfaces/products/evcPro';
import { IQuestion } from 'interfaces/products/evcPro';
import { IRadioInputProps, ISwitchInputProps, InputLabel } from 'interfaces/products/evcPro/inputs';
// Services
import { getFieldError, getFormInputName, isDisabledOnFlow, isVisibleOnFlow } from 'services/products/evcPro';
import { intlMessages, parseBoolean } from 'services/util/auxiliaryUtils';
// Constants
import { EVCProFlows } from 'constants/products/evcPro';
// Components
import RadioWithLabel from 'components/@efz/RadioWithLabel';
import IntlMessages from 'components/util/IntlMessages';
import ConfirmationDialog from 'components/core/dialogs/ConfirmationDialog';
import Tooltip from 'components/@efz/Tooltip';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';

type InputTypeProps<T extends TQuestionType['type']> =
    T extends 'number' ? { inputProps?: NumberFormatProps }
    : T extends 'radio' ? { inputProps?: IRadioInputProps }
    : T extends 'radio-label' | 'radio-label-boolean' ? { inputProps?: undefined }
    : T extends 'select' ? { inputProps?: SelectProps }
    : T extends 'switch' ? { inputProps?: ISwitchInputProps }
    : never;

interface IConfirmationProps {
    onCloseHandler?(): void;
    onConfirmationHandler?(data: any): void;
    onCancelHandler?(): void;
    title: InputLabel;
    contentText?: InputLabel;
    confirmButtonText?: string;
    className?: string;
    hideButtonCancel?: boolean;
}

type ConfirmationBeforeChange =
    | {
          needsConfirmation: true;
          confirmationProps: IConfirmationProps;
      }
    | {
          needsConfirmation?: false;
          confirmationProps?: IConfirmationProps | undefined;
      };

// TODO: FIXME: types
type CurrentFlow = {
    currentFlow?: any;
};

type FormInputProps<N extends ALL_INPUT_NAMES> = {
    question: IQuestion<N>;
    error?: (GlobalError & { values?: { [key: string]: string | number | boolean } }) | undefined;
    className?: string;
    disabled?: boolean;
    endAdornment?: OverridableComponent<InputAdornmentTypeMap<object, 'div'>> & typeof InputAdornment;
} & InputTypeProps<IQuestion<N>['type']> &
    ConfirmationBeforeChange &
    CurrentFlow;

const FormInput = <N extends ALL_INPUT_NAMES, T extends FieldValues = TEVCProInputs>({
    question,
    error,
    inputProps,
    disabled,
    endAdornment,
    className = '',
    needsConfirmation = false,
    confirmationProps,
    currentFlow = undefined,
}: FormInputProps<N>) => {
    if (needsConfirmation && !confirmationProps) {
        throw new Error('Confirmation props are required when needsConfirmation is true.');
    }

    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext<T>();

    const fieldName = getFormInputName<T>(question.name);
    const _error = error ? error : getFieldError(fieldName, errors);
    currentFlow = currentFlow ? currentFlow : (currentFlow = watch('currentFlow' as Path<T>) as EVCProFlows);

    const defaultNeedsVaidationValue = { value: undefined, field: undefined };
    const [needsConfirmationValue, setNeedsConfirmationValue] = useState<{
        value?: any;
        field?: ControllerRenderProps<T, Path<T>>;
    }>(defaultNeedsVaidationValue);

    const onClickHandler = (field: ControllerRenderProps<T, Path<T>>, event: /* React.ChangeEvent<HTMLInputElement> */ any) => {
        if (needsConfirmation && field.value !== event.target.value) {
            event.preventDefault();
            event.stopPropagation();
            setNeedsConfirmationValue({ value: event.target.value, field });
        }
    };

    const parseValue = (value: string) => {
        switch (question?.valueType) {
            case 'number':
                return parseFloat(`${value}`);

            case 'boolean':
                return parseBoolean(`${value}`);

            case 'string':
            default:
                return value;
        }
    };

    const onChangeHandler = (field: ControllerRenderProps<T, Path<T>>, event: /* React.ChangeEvent<HTMLInputElement> */ any) => {
        const value = question?.type === 'checkbox' ? event?.target?.checked : event?.target?.value;

        if (!question?.valueType) {
            field?.onChange(value);
            return;
        }

        if (question?.type === 'switch') {
            field?.onChange(value);
            return;
        }

        field?.onChange(parseValue(value));
    };

    const isInputLabel = (label: string | InputLabel | React.ElementType): label is InputLabel => {
        if (typeof label === 'string') return false;

        if (Object.hasOwn(label, 'label')) return true;

        return false;
    };

    const getOptionLabel = (label: string | InputLabel) => {
        const Icon = typeof label === 'string' ? undefined : label?.icon;
        const _label = typeof label === 'string' ? label : label.label;
        const values = typeof label === 'string' ? undefined : label?.values;

        return (
            <>
                {Icon ?
                    <Icon />
                :   <></>}
                <IntlMessages id={_label} values={values} />
            </>
        );
    };

    const getRenderComponent = (field: ControllerRenderProps<T, Path<T>>) => {
        let typedInputProps;
        switch (question.type) {
            case 'number':
                typedInputProps = inputProps as NumberFormatProps;
                return question.visible ?
                        <NumberFormat
                            {...field}
                            // @ts-ignore when using customInput, this is the type passed to that input and not the type expected from number-format
                            type="text"
                            disabled={isDisabledOnFlow(currentFlow, question.disabled) || disabled}
                            inputProps={{ step: question?.step ?? 1 }}
                            customInput={OutlinedInput}
                            placeholder={intlMessages(question.placeholder)}
                            error={!!_error}
                            allowNegative={typedInputProps?.allowNegative ?? false}
                            decimalScale={typedInputProps?.decimalScale ?? 2}
                            endAdornment={endAdornment}
                            aria-labelledby={question.name}
                            // onClick={(e) => onClickHandler(field, e)}
                            onChange={(e) => onChangeHandler(field, e)}
                        />
                    :   <></>;

            case 'radio':
                typedInputProps = inputProps as IRadioInputProps;

                return question.visible ?
                        <RadioGroup
                            {...typedInputProps?.radioGroup}
                            {...field}
                            aria-labelledby={question.name}
                            name={question.name}
                            onChange={(e, v) => {
                                typedInputProps?.radioGroup?.onChange?.(e, v);
                                onChangeHandler(field, e);
                            }}
                        >
                            {question.options?.map(
                                (option, index) =>
                                    isVisibleOnFlow(currentFlow, option?.visible ?? true) && (
                                        <FormControlLabel
                                            {...typedInputProps?.formControlLabel}
                                            key={`${question.name}_${index}`}
                                            value={option.value}
                                            control={
                                                <Radio
                                                    disableRipple={true}
                                                    {...typedInputProps?.radio}
                                                    onClick={(e) => onClickHandler(field, e)}
                                                />
                                            }
                                            label={
                                                <>
                                                    {isInputLabel(option.label) ?
                                                        <IntlMessages id={option.label.label} values={option.label?.values} />
                                                    :   option.label}
                                                    {!!option?.tooltip?.label || option?.tooltip?.label === '' ?
                                                        <Tooltip
                                                            {...option.tooltip}
                                                            title={
                                                                option.tooltip?.title ? option.tooltip.title
                                                                : typeof option.tooltip.label === 'string' ?
                                                                    <IntlMessages id={option.tooltip.label} />
                                                                :   <IntlMessages
                                                                        id={option.tooltip.label.label}
                                                                        values={option.tooltip.label?.values}
                                                                    />

                                                            }
                                                        />
                                                    :   <></>}
                                                </>
                                            }
                                            disabled={isDisabledOnFlow(currentFlow, option.disabled) || disabled}
                                        />
                                    )
                            )}
                        </RadioGroup>
                    :   <></>;

            case 'radio-label':
                return question.visible ?
                        <RadioWithLabel
                            disabled={isDisabledOnFlow(currentFlow, question.disabled) || disabled}
                            row={true}
                            field={
                                {
                                    ...field,
                                    ...question.options?.field,
                                } ?? field
                            }
                            watchableValue={question.options?.watchableValue ?? `${field.value}`}
                            aria-labelledby={question.name}
                            labelYes={question.options.labelYes}
                            labelNo={question.options.labelNo}
                            valueYes={`${question.options.valueYes}`}
                            valueNo={`${question.options.valueNo}`}
                            disableYes={isDisabledOnFlow(currentFlow, question.options?.disableYes)}
                            disableNo={isDisabledOnFlow(currentFlow, question.options?.disableNo)}
                            onClick={(e) => onClickHandler(field, e)}
                            onChange={(e) => onChangeHandler(field, e)}
                        />
                    :   <></>;

            case 'select':
                typedInputProps = inputProps as SelectProps;

                return question.visible ?
                        <Select
                            {...field}
                            {...typedInputProps}
                            error={!!_error}
                            disabled={isDisabledOnFlow(currentFlow, question.disabled) || disabled}
                            displayEmpty={true}
                            inputProps={{
                                ...typedInputProps?.inputProps,
                                className:
                                    typedInputProps?.inputProps?.className?.concat?.(' ', 'd-flex align-items-center') ??
                                    'd-flex align-items-center',
                            }}
                            variant={typedInputProps?.variant ?? 'outlined'}
                            aria-labelledby={question.name}
                            onChange={(e) => onChangeHandler(field, e)}
                        >
                            <MenuItem value={undefined} disabled={true}>
                                <IntlMessages id={question?.placeholder ?? 'page.evc.label.select'} />
                            </MenuItem>
                            {question.options?.map(
                                (option, index) =>
                                    isVisibleOnFlow(currentFlow, option?.visible ?? true) && (
                                        <MenuItem
                                            key={`${question.name}_${index}`}
                                            value={option.value}
                                            disabled={isDisabledOnFlow(currentFlow, option.disabled) || disabled}
                                            onClick={(e) => onClickHandler(field, e)}
                                        >
                                            <IntlMessages id={`label.${option.label}`} />
                                        </MenuItem>
                                    )
                            )}
                        </Select>
                    :   <></>;

            case 'switch':
                typedInputProps = inputProps as ISwitchInputProps;

                return question.visible ?
                        <FormControlLabel
                            {...field}
                            {...typedInputProps?.formControlLabel}
                            aria-labelledby={question.name}
                            value={question.options.value}
                            control={
                                <Switch {...typedInputProps?.switch} onClick={(e) => onClickHandler(field, e)} checked={field.value} />
                            }
                            label={
                                isInputLabel(question.options.label) ?
                                    <IntlMessages id={question.options.label.label} values={question.options.label?.values} />
                                :   question.options.label
                            }
                            disabled={isDisabledOnFlow(currentFlow, question.options.disabled) || disabled}
                            // onChange={(e) => onChangeHandler(field, e)}
                        />
                    :   <></>;

            case 'checkbox':
                typedInputProps = inputProps;

                return question.visible ?
                        <FormGroup aria-labelledby={question.name} onChange={(e) => onChangeHandler(field, e)}>
                            {question.options?.map?.((option) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value} // Ensure the Checkbox is controlled
                                            disabled={isDisabledOnFlow(currentFlow, option.disabled) || disabled}
                                        />
                                    }
                                    name={question.name}
                                    label={getOptionLabel(option.label)}
                                />
                            ))}
                        </FormGroup>
                    :   <></>;

            default:
                return <></>;
        }
    };

    const hasVisibleOptions = useMemo(() => {
        if (!Object.hasOwn(question, 'options') || !question?.options) return true;

        if (!Array.isArray(question.options)) return isVisibleOnFlow(currentFlow, question?.options?.visible);

        for (const option of question.options) {
            if (isVisibleOnFlow(currentFlow, option.visible)) return true;
        }

        return false;
    }, [currentFlow, question]);

    return isVisibleOnFlow(currentFlow, question.visible) && hasVisibleOptions ?
            <FormControl
                className={`input-container ${className}`}
                disabled={isDisabledOnFlow(currentFlow, question.disabled) || disabled}
                error={!!_error}
                required={(question?.validation?.required as boolean) ?? false}
            >
                {!!question?.label && (
                    <FormLabel id={question.name}>
                        {typeof question.label === 'string' ?
                            <IntlMessages id={question.label} />
                        :   <IntlMessages id={question.label.label} values={question.label?.values} />}

                        {question?.tooltip?.label ?
                            <Tooltip
                                {...question.tooltip}
                                title={
                                    question.tooltip?.title ? question.tooltip.title
                                    : typeof question.tooltip.label === 'string' ?
                                        <IntlMessages id={question.tooltip.label} />
                                    :   <IntlMessages id={question.tooltip.label.label} values={question.tooltip.label?.values} />
                                }
                            />
                        :   <></>}
                    </FormLabel>
                )}

                <Controller<T>
                    name={fieldName}
                    control={control}
                    rules={question?.validation ?? {}}
                    // @ts-ignore
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    render={({ field: { ref, ...field } }) => getRenderComponent(field)}
                />

                <FormHelperText className={`Mui-error`}>
                    {!!_error?.message && <IntlMessages id={_error?.message} values={_error?.values} />}
                </FormHelperText>

                {needsConfirmationValue?.field && needsConfirmationValue?.value && confirmationProps && (
                    <ConfirmationDialog
                        forwardingData={needsConfirmationValue.value}
                        onCloseHandler={() => {
                            setNeedsConfirmationValue(defaultNeedsVaidationValue);
                            confirmationProps?.onCloseHandler?.();
                        }}
                        onConfirmationHandler={() => {
                            needsConfirmationValue.field && onChangeHandler(needsConfirmationValue.field, needsConfirmationValue.value);
                            confirmationProps?.onConfirmationHandler?.(parseValue(needsConfirmationValue.value));
                            setNeedsConfirmationValue(defaultNeedsVaidationValue);
                        }}
                        onCancelHandler={confirmationProps.onCancelHandler ?? undefined}
                        title={confirmationProps.title.label}
                        titleValues={confirmationProps.title?.values}
                        contentText={confirmationProps.contentText?.label}
                        contentTextValues={confirmationProps.contentText?.values}
                        className={`evcPro-warning-dialog ${confirmationProps?.className ?? ''}`}
                        confirmButtonText={confirmationProps.confirmButtonText}
                    />
                )}
            </FormControl>
        :   <></>;
};

export default FormInput;
