import { memo, useContext, useEffect, useState } from 'react';
import Fade from '@mui/material/Fade';
import { ReactComponent as CheckIcon } from 'assets/images/icons/svg/success.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icons/svg/error.svg';
import IntlMessages from 'components/util/IntlMessages';
import { SPVSimpleContext } from 'contexts/products/solarpvSimple/solarpvSimpleContext';
import { HOUSING_TYPES_B2C, ROOF_ID_BULLETS_NUMBER } from 'constants/products/solarpvSimple';

const Bullet = ({ id, conditionToShow, bulletRendering, setBulletRendering, isRequestRoofIFail, housing_type }) => {
    const {
        spvSimpleState: { isDetectRoof },
    } = useContext(SPVSimpleContext);

    const transitionDuration = 3000; // in milisseconds
    const [rendered, setRendered] = useState(id === (isDetectRoof ? 1 : 2));

    useEffect(() => {
        if (bulletRendering === id && !rendered) {
            const timer = setTimeout(() => {
                setRendered(true);
                if (id < ROOF_ID_BULLETS_NUMBER) {
                    setBulletRendering((prev) => prev + 1);
                }
            }, transitionDuration);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [bulletRendering]); // eslint-disable-line

    useEffect(() => {
        if (isRequestRoofIFail) setRendered(true);
    }, [isRequestRoofIFail]);

    return (
        conditionToShow && (
            <Fade in={rendered}>
                <div className="roof-id-list-element">
                    {isRequestRoofIFail ?
                        <ErrorIcon />
                    :   <CheckIcon />}
                    <IntlMessages
                        id={
                            housing_type !== HOUSING_TYPES_B2C.GROUND ?
                                `page.spvSimple.roofId.list.${id}`
                            :   `page.spvSimple.groundId.list.${id}`
                        }
                    />
                </div>
            </Fade>
        )
    );
};

export default memo(Bullet);
