/**
 * @author Daniel Bastos <daniel.bastos@caixamagica.pt>
 *
 * @description Cria a condição de entrada num container e caso se verifiquem as condições envia o user para o container
 *
 * @version 20210105
 * @since 20210105 Initial release
 *
 */

import { lazy, useContext } from 'react';
import Error403 from 'components/errors/Error403';
//selectors
import PropTypes from 'prop-types';
import { BASENAME_URL_APP } from 'constants/settings';
import lazyLoader from 'services/lazyLoader';
import { UserContext } from 'contexts/userContext';

const CreateClientB2BPage = lazy(() => lazyLoader(() => import('pages/Restricted/client/create/CreateClientB2B')));
const CreateClientB2CPage = lazy(() => lazyLoader(() => import('pages/Restricted/client/create/CreateClientB2C')));

/**
 * @context Route to CreateClient
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CreateClient = () => {
    const { isB2C, allowedToCreateClient } = useContext(UserContext);

    const CreateClientPage = isB2C ? <CreateClientB2CPage /> : <CreateClientB2BPage />;

    return allowedToCreateClient ? CreateClientPage : (
            <Error403
                props={{
                    url: BASENAME_URL_APP + 'solutions',
                    buttonText: 'page.404.button.goSolutions',
                    message: 'page.error.403.unexpected',
                }}
            />
        );
};

CreateClient.propTypes = {
    userTypeID: PropTypes.number.isRequired,
};

export default CreateClient;
