import { USER_COUNTRY_LatLng } from '../user';
import { getCompanyProfileIds } from 'services/user';
import { DEFAULTS_COMMON_SETTINGS_MAP } from 'constants/gmapSettings';
export const DEBOUNCE_TIME_ONCHANGE = 500;
export const DEBOUNCE_TIME_ONREQUESTS = 500;

export const FACILITY_TABS = {
    DATA: 'data',
    LOAD: 'load',
};

export const FACILITY_TABS_CONFIGS = [
    {
        name: FACILITY_TABS.DATA,
        label: 'page.facility.tab.data',
    },
    {
        name: FACILITY_TABS.LOAD,
        label: 'label.consumption',
    },
];

export const DISPATCH_EVT = {
    SET_FACILITY: 'SET_FACILITY',
    UPDATE: 'UPDATE',
    SET_FACILITY_DATA: 'SET_FACILITY_DATA',
    SET_COUNTY: 'SET_COUNTY',
    SET_SUPPLY_TARIFFS: 'SET_SUPPLY_TARIFFS',
    SET_LOAD_DATA: 'SET_LOAD_DATA',
    SET_GAS: 'SET_GAS',
    SET_OTHER: 'SET_OTHER',
    SET_LOAD_DATA_OPTS: 'SET_LOAD_DATA_OPTS',
    SET_IS_LOADING_CONSUMO_ANUAL_B2B: 'SET_IS_LOADING_CONSUMO_ANUAL_B2B',
    SET_IS_REQUESTINGCWEIGHTS_B2B: 'SET_IS_REQUESTINGCWEIGHTS_B2B',
    SET_CONSUMPTION_DATA_B2B: 'SET_CONSUMPTION_DATA_B2B',
    SET_CONSUMPTION_WEIGHTS_B2B: 'SET_CONSUMPTION_WEIGHTS_B2B',
    SET_OPCAO_CURVA_CARGA_ID_B2B: 'SET_OPCAO_CURVA_CARGA_ID_B2B',
    SET_TEM_DADOS_PONDERADOR_INTEGRACAO_PERSONALIZADA_B2B: 'SET_TEM_DADOS_PONDERADOR_INTEGRACAO_PERSONALIZADA_B2B',
    SET_CONSUMO_ANUAL_B2B: 'SET_CONSUMO_ANUAL_B2B',
    SET_SIMPLE_ESTIMATION_ID_B2B: 'SET_SIMPLE_ESTIMATION_ID_B2B',
    SET_ACTIVITY_CODE_B2B: 'SET_ACTIVITY_CODE_B2B',
    SET_WORKING_SCHEDULE_B2B: 'SET_WORKING_SCHEDULE_B2B',
    NEW_CONSUMPTION_WEIGHTS_B2B: 'NEW_CONSUMPTION_WEIGHTS_B2B',
    SET_CONSUMPTIONS_GRAPH_DAILY: 'SET_CONSUMPTIONS_GRAPH_DAILY',
    SET_CONSUMPTIONS_GRAPH_MONTHLY: 'SET_CONSUMPTIONS_GRAPH_MONTHLY',
    SET_IS_REQUESTING_GRAPHS: 'SET_IS_REQUESTING_GRAPHS',
    SET_REQUESTING_CURVE_LOAD: 'SET_REQUESTING_CURVE_LOAD',
    SET_REQUESTING_GET_ACONSUMPTION_CURVE: 'SET_REQUESTING_GET_ACONSUMPTION_CURVE',
    SET_CURVE_LOAD_DATA_B2B: 'SET_CURVE_LOAD_DATA_B2B',
    SET_REQUESTING_CURVE_UPLOAD: 'SET_REQUESTING_CURVE_UPLOAD',
    SET_CONSUMPTION_DATA_B2C: 'SET_CONSUMPTION_DATA_B2C',
    UPDATE_STDATA_ON_COUNTY_CHANGE_B2B: 'UPDATE_STDATA_ON_COUNTY_CHANGE_B2B',
    UPDATE_STDATA_ON_COUNTY_CHANGE_B2B_SUCCESS: 'UPDATE_STDATA_ON_COUNTY_CHANGE_B2B_SUCCESS',
    UPDATE_STDATA_ON_COUNTY_CHANGE_B2C: 'UPDATE_STDATA_ON_COUNTY_CHANGE_B2C',
    UPDATE_STDATA_ON_COUNTY_CHANGE_B2C_SUCCESS: 'UPDATE_STDATA_ON_COUNTY_CHANGE_B2C_SUCCESS',
    IS_REQUESTING_TARIFF_OPTIONS: 'IS_REQUESTING_TARIFF_OPTIONS',
    SET_IS_VALID_CURVE_UPLOADED: 'SET_IS_VALID_CURVE_UPLOADED',
    SET_CONSUMO_ANUAL_CURVE: 'SET_CONSUMO_ANUAL_CURVE',
    SET_SUPPLY_TARIFFS_OPTS: 'SET_SUPPLY_TARIFFS_OPTS',
    SET_POSTAL_CODE: 'SET_POSTAL_CODE',
    UPDATE_STDATA_ON_POSTAL_CODE_CHANGE_B2B: 'UPDATE_STDATA_ON_POSTAL_CODE_CHANGE_B2B',
};

export const DEFAULTS_PROPS_MAP = {
    mapOptions: {
        id: 'efz-map',
        zoom: 7,
        tilt: 0,
        mapType: 'satellite',
        mapTypeId: 'hybrid',
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: true,
        clickableIcons: true,
        dragable: true,
    },
    onlyReadMapOptions: {
        id: 'efz-map',
        zoom: 7,
        tilt: 0,
        mapType: 'satellite',
        mapTypeId: 'hybrid',
        rotateControl: false,
        clickableIcons: false,
        dragable: false,
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        gestureHandling: 'none',
        disableDefaultUI: true,
    },
    settings: DEFAULTS_COMMON_SETTINGS_MAP,
    country: USER_COUNTRY_LatLng,
};

export const FACILITY_TYPES_REQUEST = [
    getCompanyProfileIds().EDP_PT,
    getCompanyProfileIds().EDP_ES,
    getCompanyProfileIds().EDP_IT,
    getCompanyProfileIds().EDP_PL,
    getCompanyProfileIds().EDP_BR,
    getCompanyProfileIds().ESB,
    getCompanyProfileIds().EFZ,
    getCompanyProfileIds().TELEFONICA,
    getCompanyProfileIds().NRG,
    getCompanyProfileIds().PROFILE_BASE_SWISS,
    getCompanyProfileIds().PROFILE_BASE_USA,
    getCompanyProfileIds().PROFILE_BASE_IT,
    getCompanyProfileIds().PPC,
];

export const VOLTAGE_LEVELS_REQUEST = [
    getCompanyProfileIds().EDP_PT,
    getCompanyProfileIds().EDP_ES,
    getCompanyProfileIds().EDP_IT,
    getCompanyProfileIds().EDP_PL,
    getCompanyProfileIds().EDP_BR,
    getCompanyProfileIds().ESB,
    getCompanyProfileIds().EFZ,
    getCompanyProfileIds().TELEFONICA,
    getCompanyProfileIds().NRG,
    getCompanyProfileIds().PROFILE_BASE_SWISS,
    getCompanyProfileIds().PROFILE_BASE_IT,
    getCompanyProfileIds().SUNSEAP,
    getCompanyProfileIds().PPC,
    getCompanyProfileIds().PROFILE_BASE_UK,
    getCompanyProfileIds().EVOLO,
    getCompanyProfileIds().PROFILE_BASE_USA,
];

export const GRID_TARIFFS_REQUEST = [
    getCompanyProfileIds().EDP_PT,
    getCompanyProfileIds().EDP_IT,
    getCompanyProfileIds().EDP_PL,
    getCompanyProfileIds().EDP_BR,
    getCompanyProfileIds().PPC,
    getCompanyProfileIds().PROFILE_BASE_IT,
];

export const SUPPLIERS_REQUEST = [
    getCompanyProfileIds().EDP_IT,
    getCompanyProfileIds().EDP_PL,
    getCompanyProfileIds().EDP_BR,
    getCompanyProfileIds().ESB,
    getCompanyProfileIds().EFZ,
    getCompanyProfileIds().TELEFONICA,
    getCompanyProfileIds().NRG,
    getCompanyProfileIds().PROFILE_BASE_SWISS,
    getCompanyProfileIds().PROFILE_BASE_USA,
    getCompanyProfileIds().PROFILE_BASE_IT,
    getCompanyProfileIds().PPC,
];

export const DISTRIBUTORS_REQUEST = [
    getCompanyProfileIds().EDP_IT,
    getCompanyProfileIds().EDP_PL,
    getCompanyProfileIds().EDP_BR,
    getCompanyProfileIds().ESB,
    getCompanyProfileIds().EFZ,
    getCompanyProfileIds().TELEFONICA,
    getCompanyProfileIds().NRG,
    getCompanyProfileIds().PROFILE_BASE_SWISS,
    getCompanyProfileIds().PROFILE_BASE_USA,
    getCompanyProfileIds().PROFILE_BASE_IT,
    getCompanyProfileIds().PPC,
    getCompanyProfileIds().ROMANDE,
    getCompanyProfileIds().ESB,
    getCompanyProfileIds().NRG,
];

export const HOURLY_OPTS_REQUEST = [getCompanyProfileIds().EDP_PT, getCompanyProfileIds().EFZ];

export const USED_POWER_TARIFFS_OPTS_REQUEST = [getCompanyProfileIds().EDP_PT];

export const GRID_TARIFF_SOURCES_REQUEST = [getCompanyProfileIds().EDP_PT];

export const COUNTIES_REQUEST = [
    getCompanyProfileIds().SUNSEAP,
    getCompanyProfileIds().ROMANDE,
    getCompanyProfileIds().PROFILE_BASE_SWISS,
    getCompanyProfileIds().PPC,
    getCompanyProfileIds().EFZ,
    getCompanyProfileIds().PROFILE_BASE_UK,
    getCompanyProfileIds().EVOLO,
    getCompanyProfileIds().PROFILE_BASE_USA,
    getCompanyProfileIds().PROFILE_BASE_DE,
    getCompanyProfileIds().PROFILE_EDP_FR,
    getCompanyProfileIds().PROFILE_BASE_IT,
    getCompanyProfileIds().ESB,
    getCompanyProfileIds().NRG,
    getCompanyProfileIds().EDP_ES,
    getCompanyProfileIds().EDP_IT,
    getCompanyProfileIds().EDP_PL,
    getCompanyProfileIds().EDP_PT,
];

export const QUESTIONS_SECTION_NAMES = {
    FACILITY: 'facility',
    ELECTRICITY: 'electric',
    GAS: 'gas',
    OTHER: 'other',
    CURVE_LOAD: 'curveload',
};

export const QUESTIONS_SECTION_NAMES_B2C = {
    FACILITY: 'facility',
    ELECTRICITY: 'electric',
    CURVE_LOAD: 'curveload',
};
export const ERROR_MESSAGES = {
    WRONG_CONSUMPTION_LENGHT: 'WRONG_CONSUMPTION_LENGHT',
};
