import { fabric } from 'fabric';
import { buildCoords, getCornerPositions, getPolygonLines } from '../hp';
import { isDefined } from 'services/util/auxiliaryUtils';
import { HpCanvasColors } from 'constants/products/hp';
export const Keys: <T = Record<string, any>>(obj: T) => (keyof T)[] = (obj) => {
    // @ts-ignore
    return Object.keys(obj) as any;
};

export function omit<T extends Record<string, unknown>, K extends keyof T>(obj: T, fields: K[]): Omit<T, K> {
    const clone = { ...obj };

    if (Array.isArray(fields)) {
        fields.forEach((key) => {
            delete clone[key];
        });
    }

    return clone;
}

type VerticalLineCoords = {
    x: number;
    y1: number;
    y2: number;
};

type HorizontalLineCoords = {
    y: number;
    x1: number;
    x2: number;
};

type ACoordsAppendCenter = NonNullable<fabric.Object['aCoords']> & {
    c: fabric.Point;
};

const aligningLineWidth = 2;
const aligningLineColor = '#39FF14';
const aligningLineMargin = 4;

// #region MOVE
export const move = (canvas, { horizontalLines, verticalLines, setEditDimensionOpen }) => {
    canvas.on('object:moving', (e) => {
        //close edit dimensions component
        setEditDimensionOpen(false);

        verticalLines.length = horizontalLines.length = 0;

        // this.clearLinesMeta();
        const activeObject = e.target as fabric.Object;
        const canvasObjects = canvas.getObjects().filter((obj) => obj.isPolygon);
        activeObject.set({
            lockScalingY: false,
            lockScalingX: false,
            lockScaling: [],
        });
        // @ts-ignore
        const transform = canvas._currentTransform;
        if (!transform) return;

        const CANVAS_MARGIN_REGULAR = 5;
        const CANVAS_MARGIN_IRREGULAR = 7;

        if (activeObject.left < aligningLineMargin) {
            activeObject.set('left', activeObject.isRegular ? CANVAS_MARGIN_REGULAR : CANVAS_MARGIN_IRREGULAR);
        }

        if (activeObject.top < aligningLineMargin) {
            activeObject.set('top', activeObject.isRegular ? CANVAS_MARGIN_REGULAR : CANVAS_MARGIN_IRREGULAR);
        }

        if (activeObject.getScaledWidth() + activeObject.left > canvas.width - aligningLineMargin) {
            activeObject.set(
                'left',
                canvas.width - activeObject.getScaledWidth() - (activeObject.isRegular ? CANVAS_MARGIN_REGULAR : CANVAS_MARGIN_IRREGULAR)
            );
        }

        if (activeObject.getScaledHeight() + activeObject.top > canvas.height - aligningLineMargin) {
            activeObject.set(
                'top',
                canvas.height - activeObject.getScaledHeight() - (activeObject.isRegular ? CANVAS_MARGIN_REGULAR : CANVAS_MARGIN_IRREGULAR)
            );
        }

        // Update the position by the rounded differences
        activeObject.set({
            left: Math.round(activeObject.left / ALIGNING_STEP) * ALIGNING_STEP,
            top: Math.round(activeObject.top / ALIGNING_STEP) * ALIGNING_STEP,
        });

        if (activeObject.top === activeObject.previousTop && activeObject.left === activeObject.previousLeft) return;

        // Update previous position for the next event
        activeObject.previousLeft = activeObject.left;
        activeObject.previousTop = activeObject.top;

        activeObject?.setCoords();

        verticalLines.length = horizontalLines.length = 0;
        traversAllObjects(activeObject, canvasObjects, canvas, { horizontalLines, verticalLines });
    });
};

function sortPointsClockwise(points) {
    // Encontrar o ponto central
    let centerX = 0;
    let centerY = 0;
    for (let i = 0; i < points.length; i++) {
        centerX += points[i].x;
        centerY += points[i].y;
    }
    centerX /= points.length;
    centerY /= points.length;

    // Função de comparação para ordenar os pontos
    function comparePoints(a, b) {
        if (a.x - centerX >= 0 && b.x - centerX < 0) return 1;
        if (a.x - centerX < 0 && b.x - centerX >= 0) return -1;
        if (a.x - centerX === 0 && b.x - centerX === 0) {
            if (a.y - centerY >= 0 || b.y - centerY >= 0) return a.y > b.y ? 1 : -1;
            return b.y > a.y ? 1 : -1;
        }

        // Calcula o produto cruzado dos vetores (center -> a) e (center -> b)
        const det = (a.x - centerX) * (b.y - centerY) - (b.x - centerX) * (a.y - centerY);
        if (det < 0) return -1;
        if (det > 0) return 1;
        return 0;
    }

    // Ordenar os pontos utilizando a função de comparação
    points.sort(comparePoints);

    return points;
}

const traversAllObjects = (activeObject: fabric.Object, canvasObjects: fabric.Object[], canvas, { horizontalLines, verticalLines }) => {
    const objCoordsByMovingDistance = getObjDraggingObjCoords(activeObject);

    const relevantCanvasObjects = canvasObjects.filter((obj) => obj !== activeObject && obj.floorId === activeObject.floorId);
    const activeObjHorizontalLines: Map<number, Array<{ x: number; y: number }>> = new Map(),
        activeObjVerticalLines: Map<number, Array<{ x: number; y: number }>> = new Map(),
        relevantCanvasObjectsHorizontalLines: Map<number, Array<{ x: number; y: number }>> = new Map(),
        relevantCanvasObjectsVerticalLines: Map<number, Array<{ x: number; y: number }>> = new Map();

    const activeObjectPoints = (activeObject?.irregularRegular ?
        sortPointsClockwise(getCornerPositions(activeObject))
    :   Object.values(activeObject?.oCoords)) as any as { x: number; y: number }[];

    for (let i = 0; i < activeObjectPoints.length; i++) {
        const currPoint = activeObjectPoints[i];
        const nextPoint = activeObjectPoints[(i + 1) % activeObjectPoints.length];

        if (Number(currPoint?.x) === Number(nextPoint?.x)) activeObjVerticalLines.set(currPoint.x, [currPoint, nextPoint]);

        if (Number(currPoint?.y) === Number(nextPoint?.y)) activeObjHorizontalLines.set(currPoint.y, [currPoint, nextPoint]);
    }

    relevantCanvasObjects.forEach((obj) => {
        const points = obj?.irregularRegular ? sortPointsClockwise(getCornerPositions(obj)) : Object.values(obj?.oCoords);

        for (let i = 0; i < points.length; i++) {
            const currPoint = points[i];
            const nextPoint = points[(i + 1) % points.length];

            if (Number(currPoint?.x) === Number(nextPoint?.x)) relevantCanvasObjectsVerticalLines.set(currPoint.x, [currPoint, nextPoint]);

            if (Number(currPoint?.y) === Number(nextPoint?.y))
                relevantCanvasObjectsHorizontalLines.set(currPoint.y, [currPoint, nextPoint]);
        }
    });

    const snapXPoints: number[] = [];
    const snapYPoints: number[] = [];

    for (const [key, value] of activeObjHorizontalLines) {
        for (const [keyR, valueR] of relevantCanvasObjectsHorizontalLines) {
            if (isInRange(Number(key), Number(keyR), canvas)) {
                snapYPoints.push(activeObject.getCenterPoint().y - (key - keyR));
                // @ts-ignore
                const allPoints: number[] = [...value, ...valueR]?.map((p) => p?.x);
                horizontalLines.push({
                    y: Number(keyR),
                    x1: Math.min(...allPoints),
                    x2: Math.max(...allPoints),
                });
            }
        }
    }

    for (const [key, value] of activeObjVerticalLines) {
        for (const [keyR, valueR] of relevantCanvasObjectsVerticalLines) {
            if (isInRange(Number(key), Number(keyR), canvas)) {
                snapXPoints.push(activeObject.getCenterPoint().x - (key - keyR));

                // @ts-ignore
                const allPoints: number[] = [...value, ...valueR]?.map((p) => p?.y);
                verticalLines.push({
                    x: Number(keyR),
                    y1: Math.min(...allPoints),
                    y2: Math.max(...allPoints),
                });
            }
        }
    }

    snap({
        activeObject,
        draggingObjCoords: objCoordsByMovingDistance,
        snapXPoints,
        snapYPoints,
    });
};

const pointsOffset = ({ activeRegular, targetRegular }, { activeAngle, targetAngle }) => {
    let offset = 0;
    if (activeRegular && targetRegular) {
        offset = HpCanvasColors.STROKE_WIDTH;
        // XX
        if (activeAngle === 180 && targetAngle === 0) return -offset;
        if (activeAngle === 0 && targetAngle === 180) return offset;
        if (activeAngle === 0 && targetAngle === 0) return 0;
        if (activeAngle === 180 && targetAngle === 180) return 0;
        // YY
        if (activeAngle === 90 && targetAngle === -90) return offset;
        if (activeAngle === -90 && targetAngle === 90) return -offset;
        if (activeAngle === 90 && targetAngle === 90) return 0;
        if (activeAngle === -90 && targetAngle === -90) return 0;
    }

    if (activeRegular && !targetRegular) {
        offset = HpCanvasColors.STROKE_WIDTH / 2;
        //XX
        if (activeAngle === 0 && targetAngle === 0) return offset;
        if (activeAngle === 180 && targetAngle === 0) return -offset;
        if (activeAngle === 180 && targetAngle === 180) return -offset;
        // YY
        if (activeAngle === -90 && targetAngle === -90) return -offset;
        if (activeAngle === -90 && targetAngle === 90) return -offset;
    }

    if (!activeRegular && targetRegular) {
        offset = HpCanvasColors.STROKE_WIDTH / 2;
        // XX
        if (activeAngle === 0 && targetAngle === 180) return offset;
        if (activeAngle === 0 && targetAngle === 0) return -offset;
        if (activeAngle === 180 && targetAngle === 0) return -offset;

        // YY
        if (activeAngle === -90 && targetAngle === 90) return -offset;
        if (activeAngle === 90 && targetAngle === 90) return -offset;
    }
    return offset;
};

const snap = ({
    activeObject,
    snapXPoints,
    draggingObjCoords,
    snapYPoints,
}: {
    activeObject: fabric.Object;
    snapXPoints: number[];
    draggingObjCoords: ACoordsAppendCenter;
    snapYPoints: number[];
}) => {
    const sortPoints = (list: number[], originPoint: number) => {
        if (!list.length) return originPoint;
        return list
            .map((val) => ({
                abs: Math.abs(originPoint - val),
                val,
            }))
            .sort((a, b) => a.abs - b.abs)[0].val;
    };

    activeObject.setPositionByOrigin(
        // auto snap nearest object, record all the snap points, and then find the nearest one
        new fabric.Point(sortPoints(snapXPoints, draggingObjCoords.c.x), sortPoints(snapYPoints, draggingObjCoords.c.y)),
        'center',
        'center'
    );
    activeObject.previousLeft = activeObject.left;
    activeObject.previousTop = activeObject.top;
};

// #endregion

// #region SCALING
export const ALIGNING_STEP = 1;

interface IScaleValues {
    update: boolean;
    top: number;
    left: number;
    scaleX: number;
    scaleY: number;
}

const alignScaleToGrid = (
    transformMatrix: {
        action: 'scale' | 'scaleX' | 'scaleY';
        corner: 'tl' | 'mt' | 'tr' | 'mr' | 'br' | 'mb' | 'bl' | 'ml';
        target: fabric.Polygon;
    },
    mousePosition: { x: number; y: number }
): IScaleValues => {
    const {
        action,
        corner,
        target: { height, width, oCoords },
    } = transformMatrix;
    const offsetStroke = HpCanvasColors.STROKE_WIDTH / 2;
    const MIN_SIDE_LENGTH = Math.min(2 * ALIGNING_STEP, 2 * offsetStroke + 1); // min size of edge, so it prevents "flips"

    const previousTop = oCoords?.['mt']?.y;
    const previousLeft = oCoords?.['ml']?.x;
    const previousBottom = oCoords?.['mb']?.y;
    const previousRight = oCoords?.['mr']?.x;

    const previousScaledHeight = previousBottom - previousTop;
    const previousScaleY = (previousScaledHeight - 2 * offsetStroke) / height;

    const previousScaledWidth = previousRight - previousLeft;
    const previousScaleX = (previousScaledWidth - 2 * offsetStroke) / width;

    const previousValues: IScaleValues = {
        update: false,
        top: previousTop,
        left: previousLeft,
        scaleX: previousScaleX,
        scaleY: previousScaleY,
    };

    function computeTop(): IScaleValues | Pick<IScaleValues, 'top' | 'scaleY'> {
        const top = mousePosition?.y;
        const newHeight = previousBottom - top;
        const scaleY = (newHeight - 2 * offsetStroke) / height;

        if (newHeight <= MIN_SIDE_LENGTH) return previousValues;

        return { top, scaleY };
    }

    function computeBottom(): IScaleValues | Pick<IScaleValues, 'scaleY'> {
        const bottom = mousePosition?.y;
        const newHeight = bottom - previousTop;
        const scaleY = (newHeight - 2 * offsetStroke) / height;

        if (newHeight <= MIN_SIDE_LENGTH) return previousValues;

        return { scaleY };
    }

    function computeLeft(): IScaleValues | Pick<IScaleValues, 'left' | 'scaleX'> {
        const left = mousePosition?.x;
        const newWidth = previousRight - left;
        const scaleX = (newWidth - 2 * offsetStroke) / width;

        if (newWidth <= MIN_SIDE_LENGTH) return previousValues;

        return { left, scaleX };
    }

    function computeRight(): IScaleValues | Pick<IScaleValues, 'scaleX'> {
        const right = mousePosition?.x;
        const newWidth = right - previousLeft;
        const scaleX = (newWidth - 2 * offsetStroke) / width;

        if (newWidth <= MIN_SIDE_LENGTH) return previousValues;

        return { scaleX };
    }

    switch (action) {
        case 'scale':
            // scale on corner

            if (
                Math.round(mousePosition.x / ALIGNING_STEP) * ALIGNING_STEP !== mousePosition.x ||
                Math.round(mousePosition.y / ALIGNING_STEP) * ALIGNING_STEP !== mousePosition.y
            ) {
                return previousValues;
            }

            switch (corner) {
                case 'tl': {
                    // scalling from top-left corner
                    const { top, scaleY } = computeTop();
                    const { left, scaleX } = computeLeft();

                    return {
                        update: true,
                        top,
                        left,
                        scaleX,
                        scaleY,
                    };
                }
                case 'tr': {
                    // scalling from top-right corner
                    const { top, scaleY } = computeTop();
                    const { scaleX } = computeRight();

                    return {
                        update: true,
                        top,
                        left: previousLeft,
                        scaleX,
                        scaleY,
                    };
                }
                case 'br': {
                    // scalling from top-left corner
                    const { scaleY } = computeBottom();
                    const { scaleX } = computeRight();

                    return {
                        update: true,
                        top: previousTop,
                        left: previousLeft,
                        scaleX,
                        scaleY,
                    };
                }
                case 'bl': {
                    // scalling from top-left corner
                    const { scaleY } = computeBottom();
                    const { left, scaleX } = computeLeft();

                    return {
                        update: true,
                        top: previousTop,
                        left,
                        scaleX,
                        scaleY,
                    };
                }
                default:
                    return previousValues;
            }
        case 'scaleX':
            // scale horizontally

            if (Math.round(mousePosition.x / ALIGNING_STEP) * ALIGNING_STEP !== mousePosition.x) {
                return previousValues;
            }

            if (corner === 'ml') {
                // scalling from left edge

                const { left, scaleX } = computeLeft();

                return {
                    update: true,
                    top: previousTop,
                    left,
                    scaleX,
                    scaleY: previousScaleY,
                };
            } else if (corner === 'mr') {
                // scalling from right edge
                const { scaleX } = computeRight();

                return {
                    update: true,
                    top: previousTop,
                    left: previousLeft,
                    scaleX,
                    scaleY: previousScaleY,
                };
            }
            break;
        case 'scaleY':
            // scale vertically

            if (Math.round(mousePosition.y / ALIGNING_STEP) * ALIGNING_STEP !== mousePosition.y) {
                return previousValues;
            }

            if (corner === 'mt') {
                // scalling from top edge
                const { top, scaleY } = computeTop();

                return {
                    update: true,
                    top,
                    left: previousLeft,
                    scaleX: previousScaleX,
                    scaleY,
                };
            } else if (corner === 'mb') {
                // scalling from bottom edge
                const { scaleY } = computeBottom();

                return {
                    update: true,
                    top: previousTop,
                    left: previousLeft,
                    scaleX: previousScaleX,
                    scaleY,
                };
            }
            break;
        default:
            return previousValues;
    }
    return previousValues;
};

export const scaling = (canvas: fabric.canvas, { horizontalLines, verticalLines }) => {
    canvas.on('object:scaling', (e) => {
        const activeObject = e.target;
        const canvasObjects = canvas.getObjects().filter((obj) => obj.isPolygon);
        const mousePosition = canvas.getPointer(e.e);
        mousePosition.x = Math.round(mousePosition.x);
        mousePosition.y = Math.round(mousePosition.y);

        const values = alignScaleToGrid(e?.transform, mousePosition);

        activeObject.set({
            top: values?.top,
            left: values?.left,
            scaleX: values?.scaleX,
            scaleY: values?.scaleY,
        });
        activeObject?.setCoords();

        // debugObj(activeObject)

        if (values?.update) {
            // green lines
            verticalLines.length = horizontalLines.length = 0;
            traversAllObjectsScaling(activeObject, canvasObjects, canvas, {
                horizontalLines,
                verticalLines,
                mouse: { x: mousePosition.x, y: mousePosition.y },
            });
        }

        // if (!isDefined(activeObject?.previousLeft)) {
        //     activeObject.previousLeft = Math.round(activeObject.left / ALIGNING_STEP) * ALIGNING_STEP
        // }
        // if (!isDefined(activeObject?.previousTop)) {
        //     activeObject.previousTop = Math.round(activeObject.top / ALIGNING_STEP) * ALIGNING_STEP
        // }
        // if (!isDefined(activeObject?.previousScaleX)) {
        //     activeObject.previousScaleX = Math.round(activeObject.scaleX) ?? 1
        // }
        // if (!isDefined(activeObject?.previousScaleY)) {
        //     activeObject.previousScaleY = Math.round(activeObject.scaleY) ?? 1
        // }
    });
};

// const debugObj = (obj) => {
//     console.log('DEBUG OBJ')
//     for (const [key, value] of Object.entries(obj?.oCoords)) {
//         if (['tl', 'br'].includes(key)) {
//             // @ts-ignore
//             console.log(`- ${key}: (${value.x}, ${value?.y})`)
//         }
//     }
// }

const traversAllObjectsScaling = (
    activeObject: fabric.Object,
    canvasObjects: fabric.Object[],
    canvas,
    { horizontalLines, verticalLines, mouse }
) => {
    // @ts-ignore
    const activeCoords = buildCoords(
        getPolygonLines(
            Object.entries(activeObject.isRegular ? activeObject.lineCoords : activeObject.oCoords),
            activeObject.irregularRegular,
            activeObject
        )
    );
    const activeMovingCoords = getActiveMovingCoords(activeObject, activeCoords);

    const snapXPoints: number[] = [];
    const snapYPoints: number[] = [];

    for (let i = canvasObjects.length; i--; ) {
        if (canvasObjects[i] === activeObject || canvasObjects[i].floorId !== activeObject.floorId) continue;
        // @ts-ignore
        const targCoords = buildCoords(
            getPolygonLines(
                Object.entries(canvasObjects[i].isRegular ? canvasObjects[i].lineCoords : canvasObjects[i].oCoords),
                canvasObjects[i].irregularRegular,
                canvasObjects[i]
            )
        );

        activeMovingCoords.coords.forEach((activeCoord) => {
            targCoords.forEach((targCoord) => {
                function calcHorizontalLineCoords(objPoint, activeObjCoords) {
                    const activeLine = activeMovingCoords?.coords?.find(
                        (line) => line.id === (activeObjCoords.id === activeMovingCoords?.coords.length - 1 ? 0 : activeObjCoords.id + 1)
                    );
                    const targLine = targCoords?.find((line) => line.id === (objPoint.id === 0 ? targCoords.length - 1 : objPoint.id - 1));

                    const x1 = Math.min(targLine.points.x1, targLine.points.x2, activeLine.points.x1, activeLine.points.x2);
                    const x2 = Math.max(targLine.points.x1, targLine.points.x2, activeLine.points.x1, activeLine.points.x2);
                    return { x1, x2 };
                }

                function calcVerticalLineCoords(objPoint, activeObjCoords) {
                    const activeLine = activeMovingCoords?.coords?.find(
                        (line) => line.id === (activeObjCoords.id === activeMovingCoords?.coords.length - 1 ? 0 : activeObjCoords.id + 1)
                    );
                    const targLine = targCoords?.find((line) => line.id === (objPoint.id === 0 ? targCoords.length - 1 : objPoint.id - 1));

                    const y1 = Math.min(targLine.points.y1, targLine.points.y2, activeLine.points.y1, activeLine.points.y2);
                    const y2 = Math.max(targLine.points.y1, targLine.points.y2, activeLine.points.y1, activeLine.points.y2);
                    return { y1, y2 };
                }

                const topScaling = () => {
                    const offset = activeCoord.y - targCoord.y;
                    snapYPoints.push(activeMovingCoords.center.y - offset);
                    // removeLock(activeObject, isInRange(activeCoord.x, targCoord.x, canvas) && [90, -90].includes(activeCoord.angle) && [90, -90].includes(targCoord.angle))

                    const { x1, x2 } = calcHorizontalLineCoords(targCoord, activeCoord);
                    horizontalLines.push({ y: targCoord.y, x1, x2 });
                    // drawHorizontalLine(canvas, { y: targCoord.y, x1, x2 });
                };

                const leftScaling = () => {
                    const offset = activeCoord.x - targCoord.x;
                    snapXPoints.push(activeMovingCoords.center.x - offset);
                    // removeLock(activeObject, isInRange(activeCoord.y, targCoord.y, canvas) && [180, 0].includes(activeCoord.angle) && [0, 180].includes(targCoord.angle))

                    const { y1, y2 } = calcVerticalLineCoords(targCoord, activeCoord);
                    verticalLines.push({ x: targCoord.x, y1, y2 });
                    // drawVerticalLine(canvas, { x: targCoord.x, y1, y2 });
                };

                const isTargRegular = canvasObjects[i].isRegular;
                if (
                    isInRangeScaling(activeCoord.y, targCoord.y, isTargRegular, targCoord.angle, targCoord, activeCoord.angle) &&
                    [90, -90].includes(activeCoord.angle) &&
                    [90, -90].includes(targCoord.angle)
                ) {
                    topScaling();
                    if (isInRangeScaling(mouse.y, targCoord.y, isTargRegular, targCoord.angle, targCoord, activeCoord.angle)) {
                        activeObject.lockScalingY = true;
                    }
                }
                if (
                    isInRangeScaling(activeCoord.x, targCoord.x, isTargRegular, targCoord.angle, targCoord, activeCoord.angle) &&
                    [180, 0].includes(activeCoord.angle) &&
                    [180, 0].includes(targCoord.angle)
                ) {
                    leftScaling();
                    if (isInRangeScaling(mouse.y, targCoord.y, isTargRegular, targCoord.angle, targCoord, activeCoord.angle)) {
                        activeObject.lockScalingX = true;
                    }
                }
            });
        });
    }
};

// #endregion

// #region COMMON
const getObjDraggingObjCoords = (activeObject: fabric.Object) => {
    const aCoords = activeObject.aCoords as NonNullable<fabric.Object['aCoords']>;
    const centerPoint = new fabric.Point((aCoords.tl.x + aCoords.br.x) / 2, (aCoords.tl.y + aCoords.br.y) / 2);

    const offsetX = centerPoint.x - activeObject.getCenterPoint().x;
    const offsetY = centerPoint.y - activeObject.getCenterPoint().y;

    return Keys(aCoords).reduce(
        (acc, key) => {
            return {
                ...acc,
                [key]: {
                    x: aCoords[key].x - offsetX,
                    y: aCoords[key].y - offsetY,
                },
            };
        },
        {
            c: activeObject.getCenterPoint(),
        } as ACoordsAppendCenter
    );
};

const getActiveMovingCoords = (obj, coords) => {
    const aCoords = obj.aCoords as NonNullable<fabric.Object['aCoords']>;
    const centerPoint = new fabric.Point((aCoords.tl.x + aCoords.br.x) / 2, (aCoords.tl.y + aCoords.br.y) / 2);

    const offsetX = centerPoint.x - obj.getCenterPoint().x;
    const offsetY = centerPoint.y - obj.getCenterPoint().y;

    return {
        coords: coords.map((coord) => ({
            x: coord.x - offsetX,
            y: coord.y - offsetY,
            angle: coord.angle,
            id: coord.id,
            polygonId: coord.polygonId,
            points: {
                x1: coord.points.x1,
                y1: coord.points.y1,
                x2: coord.points.x2,
                y2: coord.points.y2,
            },
        })),
        center: obj.getCenterPoint(),
    };
};
const isInRange = (value1: number, value2: number, canvas) => {
    return Math.abs(Math.round(value1) - Math.round(value2)) <= aligningLineMargin / canvas.getZoom();
};

const isInRangeScaling = (value1, value2, isTargRegular, angle, targCoord, activeAngle) => {
    const activeRegular = false;
    return (
        Math.round(value1) ===
        Math.round(value2 + pointsOffset({ activeRegular, targetRegular: isTargRegular }, { activeAngle, targetAngle: angle }))
    );
};

export const drawHorizontalLine = (canvas, coords: HorizontalLineCoords) => {
    const activeObject = canvas.getActiveObject();
    // const movingCoords = getObjDraggingObjCoords(activeObj);
    if (activeObject) {
        // @ts-ignore
        drawLine(Math.min(coords.x1, coords.x2), coords.y, Math.max(coords.x1, coords.x2), coords.y, canvas);
    }
};

export const drawVerticalLine = (canvas, coords: VerticalLineCoords) => {
    const activeObject = canvas.getActiveObject();
    // const movingCoords = getObjDraggingObjCoords(activeObj);
    if (activeObject) {
        // @ts-ignore
        drawLine(coords.x, Math.min(coords.y1, coords.y2), coords.x, Math.max(coords.y1, coords.y2), canvas);
    }
};

export const drawLine = (x1: number, y1: number, x2: number, y2: number, canvas) => {
    const ctx = canvas.getSelectionContext();
    const point1 = fabric.util.transformPoint(new fabric.Point(x1, y1), canvas.viewportTransform as any);
    const point2 = fabric.util.transformPoint(new fabric.Point(x2, y2), canvas.viewportTransform as any);

    // use origin canvas api to draw guideline
    if (!isDefined(ctx)) return;
    ctx.save();
    ctx.lineWidth = aligningLineWidth;
    ctx.strokeStyle = aligningLineColor;
    ctx.beginPath();

    ctx.moveTo(point1.x, point1.y);
    ctx.lineTo(point2.x, point2.y);

    ctx.stroke();

    drawSign(point1.x, point1.y, canvas);
    drawSign(point2.x, point2.y, canvas);

    ctx.restore();
};

const drawSign = (x: number, y: number, canvas) => {
    const ctx = canvas.getSelectionContext();

    ctx.lineWidth = 0.5;
    ctx.strokeStyle = aligningLineColor;
    ctx.beginPath();

    const size = 2;
    ctx.moveTo(x - size, y - size);
    ctx.lineTo(x + size, y + size);
    ctx.moveTo(x + size, y - size);
    ctx.lineTo(x - size, y + size);
    ctx.stroke();
};
// #endregion
