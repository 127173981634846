import { useContext } from 'react';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPPa } from 'interfaces/businessModels/ppa';

// Components
import CommercialMarginServicePPa from './CommercialMargin';
import GrantsServicePPa from './Grants';
import OfferDetailsServicePPa from './OfferDetails';

const ProjectSummaryServicePPa = () => {
    const {
        negotiationProfile,
        state,
        bmSelected,
        state: {
            selected: {
                values: {
                    grants: { hasGrants },
                },
            },
        },
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPPa>;

    const has_offer_edition = state.businessModels?.find((bm) => bm.business_model_id === bmSelected.id)?.has_offer_edition;

    return (
        <>
            {negotiationProfile?.acesso_resumo_custo && has_offer_edition ?
                <OfferDetailsServicePPa />
            :   <></>}
            {negotiationProfile?.negoceia_proposta ?
                <CommercialMarginServicePPa />
            :   <></>}
            {negotiationProfile?.mostra_subsidios && hasGrants ?
                <GrantsServicePPa />
            :   <></>}
        </>
    );
};

export default ProjectSummaryServicePPa;
