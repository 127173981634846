import ReactDOM from 'react-dom';
import { SentryInit } from 'services/@efz/sentry';
import MainApp from './MainApp';
import * as serviceWorker from './serviceWorker';
require('intersection-observer');
//Sentryy
SentryInit();

// replace console.* for disable log on production
if (process.env.NODE_ENV !== 'development') {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
}

const root = document.getElementById('efz-app');

ReactDOM.render(<MainApp />, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
