import { styled } from 'styled-components';
import { Dialog } from '@mui/material';
import { neutral } from 'constants/styles/colors';

export const StyledTariffHelperDialog = styled(Dialog)`
    .MuiIconButton-root.close-button {
        &:hover {
            background-color: initial;

            svg path {
                fill: var(--primary-color);
            }
        }
        svg path {
            fill: var(--primary-color);
        }
    }
    .MuiDialog-paper {
        min-width: 250px;
        min-height: 315px;
    }
    .efz-alert-error {
        max-width: 450px;
    }
    .add-on-helper-dialog-title {
        padding: 1.5rem 3rem;

        h2 {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 0.4rem;
            color: var(--text-color);
        }

        hr {
            opacity: 1;
            margin: 0;
            width: 70px;
            height: 2px;
            background: var(--primary-color);
        }

        .close-button {
            position: absolute;
            right: 0.75rem;
            top: 0.75rem;
        }
    }
    .add-on-helper-dialog-content {
        /* min-width: 32rem; */
        overflow-y: hidden;
        padding: 0 3rem;
        .dialog-input {
            /* max-width: 75%; */
            .MuiFormHelperText-root {
                margin-left: 0px;
            }
            display: flex;
            flex-direction: column;
            margin-bottom: 1.5rem;
            label {
                display: flex;
                align-items: center;
                color: ${neutral[800]};
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 19px;
                margin-bottom: 0.5rem;
            }
            .icon-reset {
                padding: 0 0.5rem;
                &-disabled {
                    svg rect {
                        stroke: var(--disabled-color);
                    }
                    svg path {
                        fill: var(--disabled-color);
                    }
                    cursor: auto;
                    pointer-events: none;
                }
            }
        }
        .dialog-input:last-child {
            margin-bottom: 0rem;
        }
    }
`;
