/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description Redux-Saga no conceito BusinessModels
 *
 * @version 20191120
 * @since 20191113 Initial release
 *
 */

import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
    requestGetBusinessModelKpisSelectedFail,
    requestGetBusinessModelsSuccess,
    requestGetBusinessModelKpisSelectedSuccess,
} from 'redux/actions/businessModels';
import { REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED, REQUEST_GET_BUSINESS_MODELS } from 'constants/businessModels';
import { postBusinessModels, getBusinessModelInfo } from 'api/businessModels';

import { OK } from 'constants/statusCodeHttp';

// Selectors
const getCurrentLocale = (state) => state.settings.locale.locale;

/***
 * _callRequestGetBusinessModels
 *
 * @param payload
 * @returns {Promise<void>}
 * @private
 */
const _callRequestGetBusinessModels = async (payload) => {
    return await postBusinessModels(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            return error;
        });
};

/**
 * _handlerRequestGetBusinessModels
 *
 * @param payload
 * @returns {Generator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<{payload: *, type: string}>>, void, ?>}
 * @private
 */
function* _handlerRequestGetBusinessModels({ payload }) {
    try {
        const language = yield select(getCurrentLocale);
        let fetchResult = yield call(_callRequestGetBusinessModels, { ...payload, language });

        if (fetchResult?.status === OK && !!payload?.body?.businessModelsToIgnore && payload.body.businessModelsToIgnore?.length > 0)
            fetchResult.data = fetchResult?.data?.filter((bm) => !payload.body.businessModelsToIgnore.includes(bm?.business_model_id));

        if (fetchResult?.status === OK) yield put(requestGetBusinessModelsSuccess({ data: fetchResult.data }));
    } catch (error) {
        console.log('_handlerRequestGetBusinessModels ERROR', error);
    }
}

/**
 * _callRequestGetBusinessModelKpisSelected  request an API
 *
 * @param payload
 * @returns {Promise<postcss.Result|any|void>}
 * @private
 */
const _callRequestGetBusinessModelKpisSelected = async (payload) => {
    return await getBusinessModelInfo(payload)
        .then((response) => {
            return response?.status !== OK ? response : response.data;
        })
        .catch((error) => {
            console.log('[SAGA] catch error _callRequestGetBusinessModelKpisSelected ', error);
            return error;
        });
};

/**
 *
 * @param payload
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|<"CALL", CallEffectDescriptor>>}
 * @private
 */
function* _handleRequestGetBusinessModelKpisSelected({ payload }) {
    try {
        const fetchResult = yield call(_callRequestGetBusinessModelKpisSelected, payload);

        if (fetchResult?.status === OK) yield put(requestGetBusinessModelKpisSelectedSuccess({ data: fetchResult.data }));
        if (fetchResult?.status !== OK) yield put(requestGetBusinessModelKpisSelectedFail());
    } catch (error) {
        console.log('[SAGA] catch error _handleRequestGetBusinessModelKpisSelected ', error);
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(REQUEST_GET_BUSINESS_MODELS, _handlerRequestGetBusinessModels),
        takeLatest(REQUEST_GET_BUSINESS_MODEL_KPIS_SELECTED, _handleRequestGetBusinessModelKpisSelected),
    ]);
}
