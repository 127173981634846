/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Actions - Setting
 *
 * @version 20190715
 * @since 20190715 Initial release
 *
 */

import { LOGOUT_USER } from 'constants/auth';
import {
    DARK_THEME,
    SWITCH_LANGUAGE,
    WINDOW_WIDTH,
    REQUEST_THEME_SUCCESS,
    REQUEST_THEME_FAIL,
    MAP_LANGUAGE as SET_MAP_LANGUAGE,
} from 'constants/settings';

export function setDarkTheme() {
    return { type: DARK_THEME };
}

export function switchLanguage(locale) {
    return {
        type: SWITCH_LANGUAGE,
        payload: locale,
    };
}
export function setWindowWidth(width) {
    return { type: WINDOW_WIDTH, width };
}

export function requestThemeSuccess(payload) {
    return {
        type: REQUEST_THEME_SUCCESS,
        payload: payload,
    };
}

export function requestThemeFail(payload) {
    return {
        type: REQUEST_THEME_FAIL,
        payload: payload,
    };
}

export function setMapLanguage(payload) {
    return {
        type: SET_MAP_LANGUAGE,
        payload: payload,
    };
}

export function resetState() {
    return {
        type: LOGOUT_USER,
    };
}
