import { TService, ServiceEvents, TServiceValues } from './../../../interfaces/businessModels/service';
import { UserContext } from 'contexts/userContext';
import { fetchBMPayload } from './../../../services/businessModels/index';
import { TProductInputs } from './../../../interfaces/businessModels/index';
import { isDefined } from 'services/util/auxiliaryUtils';
import { StatusCodes } from 'http-status-codes';
import { getBusinessModelInfo } from 'api/businessModels';
import { PAYMENT_MODELS_IDS } from 'constants/businessModels';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { useContext, useCallback, useEffect } from 'react';
import { IBusinessModel, IBusinessModelsContext } from 'interfaces/businessModels';
import { BusinessModelsActions } from 'interfaces/businessModels/reducer';
import { CANCELED_REQUEST } from 'constants/endpoints';

const useServiceBM = (bm: IBusinessModel) => {
    const { state, productID, productPayload, bmEvent, dispatch, setBMEventHandler } = useContext(
        BusinessModelsProContext
    ) as IBusinessModelsContext<TProductInputs, TService>;

    const { companyProfileId } = useContext(UserContext);

    const serviceBM = async () => {
        try {
            const payload = {
                businessModelId: state.selected.id,
                productID,
                body: {
                    ...fetchBMPayload(productID, productPayload, { companyProfileId }),
                },
            };

            const rspBM = await getBusinessModelInfo(payload);
            if (!isDefined(rspBM?.status)) throw new Error(CANCELED_REQUEST);

            if (rspBM?.status === StatusCodes.OK) {
                const kpis: TServiceValues = rspBM.data.data[0]?.kpis_previews;
                setBMEventHandler(ServiceEvents.SET_SERVICE_BM, kpis);
            }
        } catch (error: any) {
            if (error?.message !== CANCELED_REQUEST) {
                console.error(error);
            }
        }
    };

    const serviceEvents = useCallback(() => {
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.SERVICE && isDefined(bmEvent?.name)) {
            switch (bmEvent?.name) {
                case ServiceEvents.FETCH_SERVICE_BM:
                    serviceBM();
                    break;
                case ServiceEvents.SET_SERVICE_BM:
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: bmEvent.payload,
                    });
                    break;
                case ServiceEvents.SET_PROPOSAL_READY:
                    dispatch({
                        type: BusinessModelsActions.SET_SELECTED_BUSINESS_MODEL,
                        payload: { proposalReady: true },
                    });
                    break;
                case ServiceEvents.SET_SERVICE_PROPOSAL: {
                    const currentSimulation = {
                        ...state.simulation,
                        inputs: {
                            ...state.simulation.inputs,
                        },
                        tipo_modelo_negocio_id: state.selected.id,
                    };
                    dispatch({
                        type: BusinessModelsActions.SET_PROPOSAL,
                        payload: currentSimulation,
                    });
                    break;
                }
            }
        }
    }, [bmEvent.name]); // eslint-disable-line

    useEffect(() => {
        serviceEvents();
    }, [serviceEvents]);

    useEffect(() => {
        if (state.selected.paymentModelID === PAYMENT_MODELS_IDS.SERVICE) {
            if (state.selected.options?.margin_negotiable) setBMEventHandler(ServiceEvents.FETCH_SERVICE_BM, null);
            else setBMEventHandler(ServiceEvents.SET_PROPOSAL_READY, null);
        }
    }, [state.selected.paymentModelID]); // eslint-disable-line

    return {
        pitch: bm.pitch,
        projectSummary: bm.kpis_previews,
        selected: bm.selected,
        id: bm.business_model_id,
        paymentModelID: bm.payment_model_id,
    };
};

export default useServiceBM;
