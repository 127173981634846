import { defineMessages } from 'react-intl';
import ptMessages from '../locales/pt_PT.json';

const ptPTLang = {
    messages: {
        ...ptMessages,
    },
    locale: 'pt-PT',
    data: defineMessages,
};
export default ptPTLang;
