export const GA_TYPE_EVT = {
    TOP_LEVEL_NAVIGATION: 'top_level_navigation',
    HOMEPAGE: 'homepage',
    FACILITY: 'facility',
    PROCESS_LIST: 'process_list',
    OPORTUNITIES: 'oportunities',
    SOLAR_PV: 'solar_pv',
    EVC: 'me',
    EVCSB: 'evcsb',
    CFP: 'cfp',
};

//#region TOP LEVEL NAVIGATION
export const GA_LABEL_TOP_LEVEL_NAVIGATION = {
    LOGO_HOMEPAGE: 'Logo Homepage',
    CLIENT: 'Client',
    CLIENT_DATA: 'Client Data',
    CLIENT_CLEAN_SELECTION: 'Client Clean Selection',
    CLIENT_SEARCH_BAR: 'Client Search Bar',
    FACILITY: 'Facility',
    FACILITY_DATA: 'Facility Data',
    FACILITY_CLEAN_SELECTION: 'Facility Clean Selection',
    FACILITY_SEARCH_BAR: 'Facility Search Bar',
    FACILITY_SEE_ALL: 'Facility See All',
    OPPORTUNITIES: 'Opportunities',
    PROCESSES_LIST: 'Processes List',
    LANGUAGE_SELECTOR: 'Language Selector',
    PROFILE: 'Profile',
};
export const GA_EVT_CLICK_TOP_LEVEL_NAVIGATION = {
    [GA_LABEL_TOP_LEVEL_NAVIGATION.LOGO_HOMEPAGE]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.LOGO_HOMEPAGE,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT_DATA]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT_DATA,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT_CLEAN_SELECTION]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT_CLEAN_SELECTION,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT_SEARCH_BAR]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.CLIENT_SEARCH_BAR,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_DATA]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_DATA,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_CLEAN_SELECTION]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_CLEAN_SELECTION,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_SEARCH_BAR]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_SEARCH_BAR,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_SEE_ALL]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.FACILITY_SEE_ALL,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.OPPORTUNITIES]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.OPPORTUNITIES,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.PROCESSES_LIST]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.PROCESSES_LIST,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.LANGUAGE_SELECTOR]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.LANGUAGE_SELECTOR,
    },
    [GA_LABEL_TOP_LEVEL_NAVIGATION.PROFILE]: {
        event_category: 'Top-Level Navigation',
        event_label: GA_LABEL_TOP_LEVEL_NAVIGATION.PROFILE,
    },
};
//#endregion TOP LEVEL NAVIGATION

//#region HOMEPAGE
export const GA_LABEL_HOMEPAGE = {
    SELECT_CLIENT_BUTTON: 'Select Client Button',
};
export const GA_EVT_CLICK_HOMEPAGE = {
    [GA_LABEL_HOMEPAGE.SELECT_CLIENT_BUTTON]: {
        event_category: 'Homepage',
        event_label: GA_LABEL_HOMEPAGE.SELECT_CLIENT_BUTTON,
    },
};
//#endregion HOMEPAGE

//#region FACILITY
export const GA_CATEGORY_FACILITY = {
    DATA: 'Facility Details - Data',
    TARIFFS: 'Facility Details - Tariffs',
    PROFILE: 'Facility Details - Profile',
    PROFILE_INTEGRATION: 'Facility Details - Profile Integration',
    PROFILE_ESTIMATION: 'Facility Details - Profile Estimation',
    PROFILE_SCHEDULE: 'Facility Details - Profile Schedule',
    PROFILE_UPLOAD: 'Facility Details - Profile Upload',
    GAS: 'Facility Details - Gas',
    OTHER: 'Facility Details - Other',
};
export const GA_LABEL_FACILITY = {
    //DATA
    ADDRESS: 'Address',
    BUILDING_TYPE: 'Building Type',
    COUNTY: 'County',
    LOCATION: 'Location',
    POSTAL_CODE: 'Postal Code',
    VOLTAGE_LEVEL: 'Voltage Level',
    CONTRACTED_POWER: 'Contracted Power',
    DEMANDED_POWER: 'Demanded Power',
    NUMBER_PHASES: 'Number Phases',
    MCB_RATING: 'MCB rating',

    //TARIFFS
    EDP_SUPPLIER: 'EDP Supplier',
    PERIOD_TARIFF_1: 'Period Tariff 1',
    PERIOD_TARIFF_2: 'Period Tariff 2',
    PERIOD_TARIFF_3: 'Period Tariff 3',
    PERIOD_TARIFF_4: 'Period Tariff 4',

    //PROFILE
    INTEGRATION_DATA_OPTION: 'Integration Data Option',
    SIMPLE_ESTIMATION_OPTION: 'Simple Estimation Option',
    WORKING_SCHEDULE_OPTION: 'Working Schedule Option',
    UPLOAD_FILE_OPTION: 'Upload File Option',

    //PROFILE_INTEGRATION
    ANNUAL_CONSUMPTION_PI: 'Annual Consumption',
    CUSTOMIZE: 'Customize',
    MONTHLY_DISTRIBUTION: 'Monthly Distribution',
    OPERATING_DAYS_PI: 'Operating Days',

    //PROFILE_ESTIMATION
    ANNUAL_CONSUMPTION_PE: 'Annual Consumption',
    ACTIVITY_CODE: 'Activity Code',
    CUSTOM_DISTRIBUTION: 'Custom Distribution',
    MONTHLY_CONSUMPTION: 'Monthly Consumption',

    //PROFILE_SCHEDULE
    ANNUAL_CONSUMPTION_PS: 'Annual Consumption',
    OPERATING_DAYS_PS: 'Operating Days',
    WORKING_HOURS: 'Working Hours',
    UPDATE_GRAPHICS: 'Update Graphics',

    //PROFILE_UPLOAD
    ANNUAL_CONSUMPTION_PU: 'Annual Consumption',
    TEMPLATE_15_DOWNLOAD: 'Template 15 Download',
    TEMPLATE_HOUR_DOWNLOAD: 'Template Hour Download',
    DOWNLOAD_PREVIOUSLY: 'Download Previously',
    UPLOAD: 'Upload',
    RE_UPLOAD: 'Re-Upload',

    //GAS
    AVG_ELECTRICITY_TARIFF: 'Avg Electricity Tariff',
    ANNUAL_CONSUMPTION_GAS: 'Annual Consumption',
    FIXED_PRICE: 'Fixed Price',

    //OTHER
    FACILITY_AGE: 'Facility Age',
    N_WORKERS: 'No. Workers',
    GROSS_FLOOR: 'Gross Floor',
    ROOF_AREA: 'Roof Area',
    FLOOR_AREA: 'Floor Area',
};
export const GA_EVT_CLICK_FACILITY = {
    //#region DATA
    [GA_LABEL_FACILITY.ADDRESS]: {
        event_category: GA_CATEGORY_FACILITY.DATA,
        event_label: GA_LABEL_FACILITY.ADDRESS,
    },
    [GA_LABEL_FACILITY.BUILDING_TYPE]: {
        event_category: GA_CATEGORY_FACILITY.DATA,
        event_label: GA_LABEL_FACILITY.BUILDING_TYPE,
    },
    [GA_LABEL_FACILITY.COUNTY]: {
        event_category: GA_CATEGORY_FACILITY.DATA,
        event_label: GA_LABEL_FACILITY.COUNTY,
    },
    [GA_LABEL_FACILITY.LOCATION]: {
        event_category: GA_CATEGORY_FACILITY.DATA,
        event_label: GA_LABEL_FACILITY.LOCATION,
    },
    [GA_LABEL_FACILITY.POSTAL_CODE]: {
        event_category: GA_CATEGORY_FACILITY.DATA,
        event_label: GA_LABEL_FACILITY.POSTAL_CODE,
    },
    [GA_LABEL_FACILITY.VOLTAGE_LEVEL]: {
        event_category: GA_CATEGORY_FACILITY.DATA,
        event_label: GA_LABEL_FACILITY.VOLTAGE_LEVEL,
    },
    [GA_LABEL_FACILITY.CONTRACTED_POWER]: {
        event_category: GA_CATEGORY_FACILITY.DATA,
        event_label: GA_LABEL_FACILITY.CONTRACTED_POWER,
    },
    [GA_LABEL_FACILITY.DEMANDED_POWER]: {
        event_category: GA_CATEGORY_FACILITY.DATA,
        event_label: GA_LABEL_FACILITY.DEMANDED_POWER,
    },
    [GA_LABEL_FACILITY.NUMBER_PHASES]: {
        event_category: GA_CATEGORY_FACILITY.DATA,
        event_label: GA_LABEL_FACILITY.NUMBER_PHASES,
    },
    [GA_LABEL_FACILITY.MCB_RATING]: {
        event_category: GA_CATEGORY_FACILITY.DATA,
        event_label: GA_LABEL_FACILITY.MCB_RATING,
    },
    //#endregion DATA

    //#region TARIFFS
    [GA_LABEL_FACILITY.EDP_SUPPLIER]: {
        event_category: GA_CATEGORY_FACILITY.TARIFFS,
        event_label: GA_LABEL_FACILITY.EDP_SUPPLIER,
    },
    [GA_LABEL_FACILITY.PERIOD_TARIFF_1]: {
        event_category: GA_CATEGORY_FACILITY.TARIFFS,
        event_label: GA_LABEL_FACILITY.PERIOD_TARIFF_1,
    },
    [GA_LABEL_FACILITY.PERIOD_TARIFF_2]: {
        event_category: GA_CATEGORY_FACILITY.TARIFFS,
        event_label: GA_LABEL_FACILITY.PERIOD_TARIFF_2,
    },
    [GA_LABEL_FACILITY.PERIOD_TARIFF_3]: {
        event_category: GA_CATEGORY_FACILITY.TARIFFS,
        event_label: GA_LABEL_FACILITY.PERIOD_TARIFF_3,
    },
    [GA_LABEL_FACILITY.PERIOD_TARIFF_4]: {
        event_category: GA_CATEGORY_FACILITY.TARIFFS,
        event_label: GA_LABEL_FACILITY.PERIOD_TARIFF_4,
    },
    //#endregion TARIFFS

    //#region PROFILE
    [GA_LABEL_FACILITY.INTEGRATION_DATA_OPTION]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE,
        event_label: GA_LABEL_FACILITY.INTEGRATION_DATA_OPTION,
    },
    [GA_LABEL_FACILITY.SIMPLE_ESTIMATION_OPTION]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE,
        event_label: GA_LABEL_FACILITY.SIMPLE_ESTIMATION_OPTION,
    },
    [GA_LABEL_FACILITY.WORKING_SCHEDULE_OPTION]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE,
        event_label: GA_LABEL_FACILITY.WORKING_SCHEDULE_OPTION,
    },
    [GA_LABEL_FACILITY.UPLOAD_FILE_OPTION]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE,
        event_label: GA_LABEL_FACILITY.UPLOAD_FILE_OPTION,
    },
    //#endregion PROFILE

    //#region PROFILE_INTEGRATION
    [GA_LABEL_FACILITY.ANNUAL_CONSUMPTION_PI]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_INTEGRATION,
        event_label: GA_LABEL_FACILITY.ANNUAL_CONSUMPTION_PI,
    },
    [GA_LABEL_FACILITY.CUSTOMIZE]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_INTEGRATION,
        event_label: GA_LABEL_FACILITY.CUSTOMIZE,
    },
    [GA_LABEL_FACILITY.MONTHLY_DISTRIBUTION]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_INTEGRATION,
        event_label: GA_LABEL_FACILITY.MONTHLY_DISTRIBUTION,
    },
    [GA_LABEL_FACILITY.OPERATING_DAYS_PI]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_INTEGRATION,
        event_label: GA_LABEL_FACILITY.OPERATING_DAYS_PI,
    },
    //#endregion PROFILE_INTEGRATION

    //#region PROFILE_ESTIMATION
    [GA_LABEL_FACILITY.ANNUAL_CONSUMPTION_PE]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_ESTIMATION,
        event_label: GA_LABEL_FACILITY.ANNUAL_CONSUMPTION_PE,
    },
    [GA_LABEL_FACILITY.ACTIVITY_CODE]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_ESTIMATION,
        event_label: GA_LABEL_FACILITY.ACTIVITY_CODE,
    },
    [GA_LABEL_FACILITY.CUSTOM_DISTRIBUTION]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_ESTIMATION,
        event_label: GA_LABEL_FACILITY.CUSTOM_DISTRIBUTION,
    },
    [GA_LABEL_FACILITY.MONTHLY_CONSUMPTION]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_ESTIMATION,
        event_label: GA_LABEL_FACILITY.MONTHLY_CONSUMPTION,
    },
    //#endregion PROFILE_ESTIMATION

    //#region PROFILE_SCHEDULE
    [GA_LABEL_FACILITY.ANNUAL_CONSUMPTION_PS]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_SCHEDULE,
        event_label: GA_LABEL_FACILITY.ANNUAL_CONSUMPTION_PS,
    },
    [GA_LABEL_FACILITY.OPERATING_DAYS_PS]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_SCHEDULE,
        event_label: GA_LABEL_FACILITY.OPERATING_DAYS_PS,
    },
    [GA_LABEL_FACILITY.WORKING_HOURS]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_SCHEDULE,
        event_label: GA_LABEL_FACILITY.WORKING_HOURS,
    },
    [GA_LABEL_FACILITY.UPDATE_GRAPHICS]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_SCHEDULE,
        event_label: GA_LABEL_FACILITY.UPDATE_GRAPHICS,
    },
    //#endregion PROFILE_SCHEDULE

    //#region PROFILE_UPLOAD
    [GA_LABEL_FACILITY.ANNUAL_CONSUMPTION_PU]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_UPLOAD,
        event_label: GA_LABEL_FACILITY.ANNUAL_CONSUMPTION_PU,
    },
    [GA_LABEL_FACILITY.TEMPLATE_15_DOWNLOAD]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_UPLOAD,
        event_label: GA_LABEL_FACILITY.TEMPLATE_15_DOWNLOAD,
    },
    [GA_LABEL_FACILITY.TEMPLATE_HOUR_DOWNLOAD]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_UPLOAD,
        event_label: GA_LABEL_FACILITY.TEMPLATE_HOUR_DOWNLOAD,
    },
    [GA_LABEL_FACILITY.DOWNLOAD_PREVIOUSLY]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_UPLOAD,
        event_label: GA_LABEL_FACILITY.DOWNLOAD_PREVIOUSLY,
    },
    [GA_LABEL_FACILITY.UPLOAD]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_UPLOAD,
        event_label: GA_LABEL_FACILITY.UPLOAD,
    },
    [GA_LABEL_FACILITY.RE_UPLOAD]: {
        event_category: GA_CATEGORY_FACILITY.PROFILE_UPLOAD,
        event_label: GA_LABEL_FACILITY.RE_UPLOAD,
    },
    //#endregion PROFILE_UPLOAD

    //#region GAS
    [GA_LABEL_FACILITY.AVG_ELECTRICITY_TARIFF]: {
        event_category: GA_CATEGORY_FACILITY.GAS,
        event_label: GA_LABEL_FACILITY.AVG_ELECTRICITY_TARIFF,
    },
    [GA_LABEL_FACILITY.ANNUAL_CONSUMPTION_GAS]: {
        event_category: GA_CATEGORY_FACILITY.GAS,
        event_label: GA_LABEL_FACILITY.ANNUAL_CONSUMPTION_GAS,
    },
    [GA_LABEL_FACILITY.FIXED_PRICE]: {
        event_category: GA_CATEGORY_FACILITY.GAS,
        event_label: GA_LABEL_FACILITY.FIXED_PRICE,
    },
    //#endregion GAS

    //#region OTHER
    [GA_LABEL_FACILITY.FACILITY_AGE]: {
        event_category: GA_CATEGORY_FACILITY.OTHER,
        event_label: GA_LABEL_FACILITY.FACILITY_AGE,
    },
    [GA_LABEL_FACILITY.N_WORKERS]: {
        event_category: GA_CATEGORY_FACILITY.OTHER,
        event_label: GA_LABEL_FACILITY.N_WORKERS,
    },
    [GA_LABEL_FACILITY.GROSS_FLOOR]: {
        event_category: GA_CATEGORY_FACILITY.OTHER,
        event_label: GA_LABEL_FACILITY.GROSS_FLOOR,
    },
    [GA_LABEL_FACILITY.ROOF_AREA]: {
        event_category: GA_CATEGORY_FACILITY.OTHER,
        event_label: GA_LABEL_FACILITY.ROOF_AREA,
    },
    [GA_LABEL_FACILITY.FLOOR_AREA]: {
        event_category: GA_CATEGORY_FACILITY.OTHER,
        event_label: GA_LABEL_FACILITY.FLOOR_AREA,
    },
    //#endregion OTHER
};
//#endregion FACILITY

//#region PROCESS_LIST
export const GA_CATEGORY_PROCESS_LIST = {
    PROCESSES_LIST_ACTIONS: 'Processes List Actions',
    PROCESSES_LIST_PAGINATION: 'Processes List Pagination',
    PROCESSES_LIST_FILTERS: 'Processes List Filters',
};
export const GA_LABEL_PROCESS_LIST = {
    //PROCESSES_LIST_ACTIONS
    PROCESS_DETAILS: 'Process Details',
    DOWNLOAD_PROPOSAL: 'Download Proposal',
    SEND_TO_CRM: 'Send To CRM',
    UPDATE_STATUS: 'Update Status',
    SEND_TO_FIELD: 'Send to Field',
    EDIT_OWNER: 'Edit Owner',
    EDIT_DESCRIPTION: 'Edit Description',
    UPLOAD_DOCUMENTS: 'Upload Documents',
    EXPORT_XLS: 'Export XLS',
    DOWNLOAD_SIGNED_CONTRACT: 'Download Signed Contract',
    BOOK_MEEETING: 'Book Meeting',

    //PROCESSES_LIST_PAGINATION
    NEXT_PAGE: 'Next Page',
    PREVIOUS_PAGE: 'Previous Page',
    BEGINNING: 'Beginning',
    END: 'End',
    PROCESSES_PER_PAGE: 'Processes Per Page',

    //PROCESSES_LIST_FILTERS
    SHOW_FILTERS: 'Show Filters',
    APPLY_FILTERS: 'Apply Filters',
    CLEAN_FILTERS: 'Clean Filters',
    REFRESH_TABLE_DATA: 'Refresh Table Data',
    SEARCH_BAR: 'Search Bar',
    DATE_FROM: 'Date From',
    DATE_TO: 'Date To',
    STATUS: 'Status',
    PRODUCT: 'Product',
    FACILITY: 'Facility',
    PAYMENT_MODELS: 'Payment models',
    VOLTAGE_LEVEL: 'Voltage Level',
    ACCOUNT_MANAGER: 'Account Manager',
    USER: 'User',
    SERVICE_MANAGER: 'Service Manager',
    SEGMENT: 'Segment',
    GROUP: 'Group',
    SENT_TO_FIELD: 'Sent to Field',
    PARTNER: 'Partner',

    CLIENT: 'Client',
    CLIENT_MANAGER: 'Client Manager',
    OWNER: 'Owner',
};
export const GA_EVT_CLICK_PROCESS_LIST = {
    //#region PROCESSES_LIST_ACTIONS
    [GA_LABEL_PROCESS_LIST.PROCESS_DETAILS]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_ACTIONS,
        event_label: GA_LABEL_PROCESS_LIST.PROCESS_DETAILS,
    },
    [GA_LABEL_PROCESS_LIST.DOWNLOAD_PROPOSAL]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_ACTIONS,
        event_label: GA_LABEL_PROCESS_LIST.DOWNLOAD_PROPOSAL,
    },
    [GA_LABEL_PROCESS_LIST.SEND_TO_CRM]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_ACTIONS,
        event_label: GA_LABEL_PROCESS_LIST.SEND_TO_CRM,
    },
    [GA_LABEL_PROCESS_LIST.UPDATE_STATUS]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_ACTIONS,
        event_label: GA_LABEL_PROCESS_LIST.UPDATE_STATUS,
    },
    [GA_LABEL_PROCESS_LIST.SEND_TO_FIELD]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_ACTIONS,
        event_label: GA_LABEL_PROCESS_LIST.SEND_TO_FIELD,
    },
    [GA_LABEL_PROCESS_LIST.EDIT_OWNER]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_ACTIONS,
        event_label: GA_LABEL_PROCESS_LIST.EDIT_OWNER,
    },
    [GA_LABEL_PROCESS_LIST.EDIT_DESCRIPTION]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_ACTIONS,
        event_label: GA_LABEL_PROCESS_LIST.EDIT_DESCRIPTION,
    },
    [GA_LABEL_PROCESS_LIST.UPLOAD_DOCUMENTS]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_ACTIONS,
        event_label: GA_LABEL_PROCESS_LIST.UPLOAD_DOCUMENTS,
    },
    [GA_LABEL_PROCESS_LIST.EXPORT_XLS]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_ACTIONS,
        event_label: GA_LABEL_PROCESS_LIST.EXPORT_XLS,
    },
    [GA_LABEL_PROCESS_LIST.DOWNLOAD_SIGNED_CONTRACT]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_ACTIONS,
        event_label: GA_LABEL_PROCESS_LIST.DOWNLOAD_SIGNED_CONTRACT,
    },
    //#endregion PROCESSES_LIST_ACTIONS

    //#region PROCESSES_LIST_PAGINATION
    [GA_LABEL_PROCESS_LIST.NEXT_PAGE]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_PAGINATION,
        event_label: GA_LABEL_PROCESS_LIST.NEXT_PAGE,
    },
    [GA_LABEL_PROCESS_LIST.PREVIOUS_PAGE]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_PAGINATION,
        event_label: GA_LABEL_PROCESS_LIST.PREVIOUS_PAGE,
    },
    [GA_LABEL_PROCESS_LIST.BEGINNING]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_PAGINATION,
        event_label: GA_LABEL_PROCESS_LIST.BEGINNING,
    },
    [GA_LABEL_PROCESS_LIST.END]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_PAGINATION,
        event_label: GA_LABEL_PROCESS_LIST.END,
    },
    [GA_LABEL_PROCESS_LIST.PROCESSES_PER_PAGE]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_PAGINATION,
        event_label: GA_LABEL_PROCESS_LIST.PROCESSES_PER_PAGE,
    },
    //#endregion PROCESSES_LIST_PAGINATION

    //#region PROCESSES_LIST_FILTERS
    [GA_LABEL_PROCESS_LIST.SHOW_FILTERS]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.SHOW_FILTERS,
    },
    [GA_LABEL_PROCESS_LIST.APPLY_FILTERS]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.APPLY_FILTERS,
    },
    [GA_LABEL_PROCESS_LIST.CLEAN_FILTERS]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.CLEAN_FILTERS,
    },
    [GA_LABEL_PROCESS_LIST.REFRESH_TABLE_DATA]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.REFRESH_TABLE_DATA,
    },
    [GA_LABEL_PROCESS_LIST.SEARCH_BAR]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.SEARCH_BAR,
    },
    [GA_LABEL_PROCESS_LIST.DATE_FROM]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.DATE_FROM,
    },
    [GA_LABEL_PROCESS_LIST.DATE_TO]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.DATE_TO,
    },
    [GA_LABEL_PROCESS_LIST.STATUS]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.STATUS,
    },
    [GA_LABEL_PROCESS_LIST.PRODUCT]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.PRODUCT,
    },
    [GA_LABEL_PROCESS_LIST.FACILITY]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.FACILITY,
    },
    [GA_LABEL_PROCESS_LIST.VOLTAGE_LEVEL]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.VOLTAGE_LEVEL,
    },
    [GA_LABEL_PROCESS_LIST.ACCOUNT_MANAGER]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.ACCOUNT_MANAGER,
    },
    [GA_LABEL_PROCESS_LIST.USER]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.USER,
    },
    [GA_LABEL_PROCESS_LIST.SERVICE_MANAGER]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.SERVICE_MANAGER,
    },
    [GA_LABEL_PROCESS_LIST.SEGMENT]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.SEGMENT,
    },
    [GA_LABEL_PROCESS_LIST.GROUP]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.GROUP,
    },
    [GA_LABEL_PROCESS_LIST.SENT_TO_FIELD]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.SENT_TO_FIELD,
    },
    [GA_LABEL_PROCESS_LIST.CLIENT]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.CLIENT,
    },
    [GA_LABEL_PROCESS_LIST.CLIENT_MANAGER]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.CLIENT_MANAGER,
    },
    [GA_LABEL_PROCESS_LIST.OWNER]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.OWNER,
    },
    [GA_LABEL_PROCESS_LIST.PARTNER]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.PARTNER,
    },
    [GA_LABEL_PROCESS_LIST.PAYMENT_MODELS]: {
        event_category: GA_CATEGORY_PROCESS_LIST.PROCESSES_LIST_FILTERS,
        event_label: GA_LABEL_PROCESS_LIST.PAYMENT_MODELS,
    },
    //#endregion PROCESSES_LIST_FILTERS
};
//#endregion PROCESS_LIST

//#region  OPORTUNITIES
export const GA_LABEL_OPORTUNITIES = {
    SOLAR_PHOTOVOLTAIC: 'Solar PV',
    SOLAR_PHOTOVOLTAIC_OFF: 'Solar PV - Switch Off',
    SOLAR_PHOTOVOLTAIC_ON: 'Solar PV - Switch On',
    VARIABLE_SPEED_DRIVES: 'Variable Speed Drives',
    VARIABLE_SPEED_DRIVES_OFF: 'Variable Speed Drives - Switch Off',
    VARIABLE_SPEED_DRIVES_ON: 'Variable Speed Drives - Switch On',
    HIGH_EFFICIENCY_MOTORS: 'High Efficiency Motors',
    HIGH_EFFICIENCY_MOTORS_OFF: 'High Efficiency Motors - Switch Off',
    HIGH_EFFICIENCY_MOTORS_ON: 'High Efficiency Motors - Switch On',
    EFFICIENT_LIGHTING: 'Efficient Lighting',
    EFFICIENT_LIGHTING_OFF: 'Efficient Lighting - Switch Off',
    EFFICIENT_LIGHTING_ON: 'Efficient Lighting - Switch On',
    POWER_FACTOR_CORRECTION: 'Power Factor Correction',
    POWER_FACTOR_CORRECTION_OFF: 'Power Factor Correction - Switch Off',
    POWER_FACTOR_CORRECTION_ON: 'Power Factor Correction - Switch on',
    ENERGY_CERTIFICATIONS_AND_AUDITS: 'Energy Certifications',
    ENERGY_CERTIFICATIONS_AND_AUDITS_OFF: 'Energy Certifications - Switch Off',
    ENERGY_CERTIFICATIONS_AND_AUDITS_ON: 'Energy Certifications - Switch on',
    VOLTAGE_TRANSFORMERS_MAINTENANCE: 'Voltage Transformers',
    VOLTAGE_TRANSFORMERS_MAINTENANCE_OFF: 'Voltage Transformers - Switch Off',
    VOLTAGE_TRANSFORMERS_MAINTENANCE_ON: 'Voltage Transformers - Switch On',
    ELECTRIC_MOBILITY: 'Electrical Mobility',
    ELECTRIC_MOBILITY_SMALL_BUSINESS: 'Electric Mobility Small Business',
    ELECTRIC_MOBILITY_OFF: 'Electrical Mobility - Switch Off',
    ELECTRIC_MOBILITY_ON: 'Electrical Mobility - Switch On',
    ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN: 'Responsible Technician',
    ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN_OFF: 'Responsible Technician - Switch Off',
    ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN_ON: 'Responsible Technician - Switch On',
    ENERGY_PROPOSALS: 'Energy Proposals',
    ENERGY_PROPOSALS_ON: 'Energy Proposals - Switch On',
    ENERGY_PROPOSALS_OFF: 'Energy Proposals - Switch Off',
    COMPRESSED_AIR_SYSTEM: 'Compressed Air System',
    COMPRESSED_AIR_SYSTEM_OFF: 'Compressed Air System - Switch Off',
    COMPRESSED_AIR_SYSTEM_ON: 'Compressed Air System - Switch On',
    MONITORING_SYSTEM: 'Monitoring System',
    MONITORING_SYSTEM_OFF: 'Monitoring System - Switch Off',
    MONITORING_SYSTEM_ON: 'Monitoring System - Switch On',
    HVAC: 'HVAC',
    HVAC_OFF: 'HVAC - Switch Off',
    HVAC_ON: 'HVAC - Switch On',
};
export const GA_EVT_CLICK_OPORTUNITIES = {
    [GA_LABEL_OPORTUNITIES.SOLAR_PHOTOVOLTAIC]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.SOLAR_PHOTOVOLTAIC,
    },
    [GA_LABEL_OPORTUNITIES.SOLAR_PHOTOVOLTAIC_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.SOLAR_PHOTOVOLTAIC_OFF,
    },
    [GA_LABEL_OPORTUNITIES.SOLAR_PHOTOVOLTAIC_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.SOLAR_PHOTOVOLTAIC_ON,
    },
    [GA_LABEL_OPORTUNITIES.VARIABLE_SPEED_DRIVES]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.VARIABLE_SPEED_DRIVES,
    },
    [GA_LABEL_OPORTUNITIES.VARIABLE_SPEED_DRIVES_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.VARIABLE_SPEED_DRIVES_OFF,
    },
    [GA_LABEL_OPORTUNITIES.VARIABLE_SPEED_DRIVES_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.VARIABLE_SPEED_DRIVES_ON,
    },
    [GA_LABEL_OPORTUNITIES.HIGH_EFFICIENCY_MOTORS]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.HIGH_EFFICIENCY_MOTORS,
    },
    [GA_LABEL_OPORTUNITIES.HIGH_EFFICIENCY_MOTORS_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.HIGH_EFFICIENCY_MOTORS_OFF,
    },
    [GA_LABEL_OPORTUNITIES.HIGH_EFFICIENCY_MOTORS_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.HIGH_EFFICIENCY_MOTORS_ON,
    },
    [GA_LABEL_OPORTUNITIES.EFFICIENT_LIGHTING]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.EFFICIENT_LIGHTING,
    },
    [GA_LABEL_OPORTUNITIES.EFFICIENT_LIGHTING_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.EFFICIENT_LIGHTING_OFF,
    },
    [GA_LABEL_OPORTUNITIES.EFFICIENT_LIGHTING_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.EFFICIENT_LIGHTING_ON,
    },
    [GA_LABEL_OPORTUNITIES.POWER_FACTOR_CORRECTION]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.POWER_FACTOR_CORRECTION,
    },
    [GA_LABEL_OPORTUNITIES.POWER_FACTOR_CORRECTION_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.POWER_FACTOR_CORRECTION_OFF,
    },
    [GA_LABEL_OPORTUNITIES.POWER_FACTOR_CORRECTION_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.POWER_FACTOR_CORRECTION_ON,
    },
    [GA_LABEL_OPORTUNITIES.ENERGY_CERTIFICATIONS_AND_AUDITS]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ENERGY_CERTIFICATIONS_AND_AUDITS,
    },
    [GA_LABEL_OPORTUNITIES.ENERGY_CERTIFICATIONS_AND_AUDITS_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ENERGY_CERTIFICATIONS_AND_AUDITS_OFF,
    },
    [GA_LABEL_OPORTUNITIES.ENERGY_CERTIFICATIONS_AND_AUDITS_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ENERGY_CERTIFICATIONS_AND_AUDITS_ON,
    },
    [GA_LABEL_OPORTUNITIES.VOLTAGE_TRANSFORMERS_MAINTENANCE]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.VOLTAGE_TRANSFORMERS_MAINTENANCE,
    },
    [GA_LABEL_OPORTUNITIES.VOLTAGE_TRANSFORMERS_MAINTENANCE_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.VOLTAGE_TRANSFORMERS_MAINTENANCE_OFF,
    },
    [GA_LABEL_OPORTUNITIES.VOLTAGE_TRANSFORMERS_MAINTENANCE_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.VOLTAGE_TRANSFORMERS_MAINTENANCE_ON,
    },
    [GA_LABEL_OPORTUNITIES.ELECTRIC_MOBILITY]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ELECTRIC_MOBILITY,
    },
    [GA_LABEL_OPORTUNITIES.ELECTRIC_MOBILITY_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ELECTRIC_MOBILITY_OFF,
    },
    [GA_LABEL_OPORTUNITIES.ELECTRIC_MOBILITY_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ELECTRIC_MOBILITY_ON,
    },
    [GA_LABEL_OPORTUNITIES.ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN,
    },
    [GA_LABEL_OPORTUNITIES.ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN_OFF,
    },
    [GA_LABEL_OPORTUNITIES.ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ELECTRICAL_INSTALLATIONS_RESPONSIBLE_TECHNICIAN_ON,
    },
    [GA_LABEL_OPORTUNITIES.ENERGY_PROPOSALS]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ENERGY_PROPOSALS,
    },
    [GA_LABEL_OPORTUNITIES.ENERGY_PROPOSALS_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ENERGY_PROPOSALS_OFF,
    },
    [GA_LABEL_OPORTUNITIES.ENERGY_PROPOSALS_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.ENERGY_PROPOSALS_ON,
    },
    [GA_LABEL_OPORTUNITIES.COMPRESSED_AIR_SYSTEM]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.COMPRESSED_AIR_SYSTEM,
    },
    [GA_LABEL_OPORTUNITIES.COMPRESSED_AIR_SYSTEM_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.COMPRESSED_AIR_SYSTEM_OFF,
    },
    [GA_LABEL_OPORTUNITIES.COMPRESSED_AIR_SYSTEM_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.COMPRESSED_AIR_SYSTEM_ON,
    },
    [GA_LABEL_OPORTUNITIES.MONITORING_SYSTEM]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.MONITORING_SYSTEM,
    },
    [GA_LABEL_OPORTUNITIES.MONITORING_SYSTEM_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.MONITORING_SYSTEM_OFF,
    },
    [GA_LABEL_OPORTUNITIES.MONITORING_SYSTEM_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.MONITORING_SYSTEM_ON,
    },
    [GA_LABEL_OPORTUNITIES.HVAC]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.HVAC,
    },
    [GA_LABEL_OPORTUNITIES.HVAC_OFF]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.HVAC_OFF,
    },
    [GA_LABEL_OPORTUNITIES.HVAC_ON]: {
        event_category: 'Opportunities',
        event_label: GA_LABEL_OPORTUNITIES.HVAC_ON,
    },
};
//#endregion OPORTUNITIES

//#region SOLAR_PV
export const GA_CATEGORY_SOLAR_PV = {
    LEFT_PANEL: 'Solar PV - Left Panel',
    RIGHT_PANEL: 'Solar PV - Right Panel',
    PANEL_REPRESENTATION: 'Solar PV - Panel Representation',
    BUSINESS_MODELS: 'Solar PV - Business Models',
    BATTERY: 'Solar PV - Battery',
};
export const GA_LABEL_SOLAR_PV = {
    //LEFT_PANEL
    SELL_TO_GRID_NO: 'Sell To Grid No',
    SELL_TO_GRID_YES: 'Sell To Grid Yes',
    DRAW_PANEL_GROUP: 'Draw Panel Group',
    STOP_DRAWING_PANEL_GROUP: 'Stop Drawing Panel Group',
    PANEL_GROUPS_TAB: 'Panel Groups Tab',
    EDIT_STRUCTURE: 'Edit Structure',
    EDIT_ROOF_AND_PANEL_SLOPE: 'Edit Roof & Panel Slope',
    EDIT_PANEL_ORIENTATION: 'Edit Panel Orientation',
    EDIT_PANEL_REPRESENTATION: 'Edit Panel Representation',
    DRAW_NEW_GROUP: 'Draw New Group',
    DELETE_ALL_GROUPS: 'Delete All Groups',
    EXCLUSIONS_TAB: 'Exclusions Tab',
    DRAW_EXCLUSION: 'Draw Exclusion',
    STOP_DRAWING_EXCLUSION: 'Stop Drawing Exclusion',
    NEW_EXCLUSION: 'New Exclusion',
    DELETE_ALL_EXCLUSIONS: 'Delete All Exclusions',
    DELETE_EXCLUSION: 'Delete Exclusion',
    SELECT_EXCLUSION: 'Select Exclusion',
    APPLY_EXCLUSIONS: 'Apply Exclusions',
    BATTERY_TAB: 'Battery Tab',
    ADD_BATTERY: 'Add battery',
    TECHNICAL_SHEET: 'Technical Sheet',

    //RIGHT_PANEL
    SELECT_RANGE: 'Select Range',
    CONSUMPTION_PRODUCTION_TAB: 'Consumption - Production Tab',
    SENSITIVITY_ANALYSIS: 'Sensitivity Analysis',
    WORKING_DAYS_FILTER: 'Working Days Filter',
    MONTH_FILTER: 'Month Filter',
    NEXT: 'Next',

    //PANEL_REPRESENTATION
    PANEL_SET_N_BY_WIDTH: 'Panel Set - No. By Width',
    PANEL_SET_N_BY_HEIGHT: 'Panel Set - No. By Height',
    PANEL_SET_SPACE_BETWEEN_PANELS: 'Panel Set - Space Between Panels',
    ROW_DISTANCING_ESTIMATION: 'Row Distancing - Estimation',
    ROW_DISTANCING_INSERT: 'Row Distancing - Insert',
    ROW_DISTANCING_REFERENCE_DAY: 'Row Distancing - Reference Day',
    ROW_DISTANCING_UPDATE: 'Row Distancing - Update',
    ROW_DISTANCING_HOURS: 'Row Distancing - Hours',
    FRAMING_LANDSCAPE: 'Framing - Landscape',
    FRAMING_PORTRAIT: 'Framing - Portrait',
    SETBACK: 'Setback',
    OFFSET: 'Offset',
    SAVE: 'Save',

    //BUSINESS_MODELS
    SERVICE: 'Service',
    INSTALLMENTS: 'Installments',
    UPFRONT: 'Upfront',
    PAY_AS_PRODUCED: 'Pay As Produced',
    LEC: 'LECS',
    MAINTENANCE_ADJUSTMENT: 'Maintenance Adjustment',
    WARRANTY_ADJUSTMENT: 'Warranty Adjustment',
    EXTRA_COST: 'Extra Cost',
    MARGIN_ADJUSTMENT: 'Margin Adjustment',
    DISCOUNT_ADJUSTMENT: 'Discount Adjustment',
    SHARED_SAVINGS_ADJUSTMENT: 'Shared Savings Adjustment',
    GROWTH_RATE_ADJUSTMENT: 'Growth Rate Adjustment',
    GENERATE_PROPOSAL: 'Generate Proposal',

    //BATTERY SELECTION
    OPTIMIZE_SELECTION: 'Optimize',
    MANUAL_SELECTION: 'Manual Selection',
};
export const GA_EVT_CLICK_SOLAR_PV = {
    //#region LEFT_PANEL
    [GA_LABEL_SOLAR_PV.SELL_TO_GRID_NO]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.SELL_TO_GRID_NO,
    },
    [GA_LABEL_SOLAR_PV.SELL_TO_GRID_YES]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.SELL_TO_GRID_YES,
    },
    [GA_LABEL_SOLAR_PV.DRAW_PANEL_GROUP]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.DRAW_PANEL_GROUP,
    },
    [GA_LABEL_SOLAR_PV.STOP_DRAWING_PANEL_GROUP]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.STOP_DRAWING_PANEL_GROUP,
    },
    [GA_LABEL_SOLAR_PV.PANEL_GROUPS_TAB]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.PANEL_GROUPS_TAB,
    },
    [GA_LABEL_SOLAR_PV.EDIT_STRUCTURE]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.EDIT_STRUCTURE,
    },
    [GA_LABEL_SOLAR_PV.EDIT_ROOF_AND_PANEL_SLOPE]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.EDIT_ROOF_AND_PANEL_SLOPE,
    },
    [GA_LABEL_SOLAR_PV.EDIT_PANEL_ORIENTATION]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.EDIT_PANEL_ORIENTATION,
    },
    [GA_LABEL_SOLAR_PV.EDIT_PANEL_REPRESENTATION]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.EDIT_PANEL_REPRESENTATION,
    },
    [GA_LABEL_SOLAR_PV.DRAW_NEW_GROUP]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.DRAW_NEW_GROUP,
    },
    [GA_LABEL_SOLAR_PV.DELETE_ALL_GROUPS]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.DELETE_ALL_GROUPS,
    },
    [GA_LABEL_SOLAR_PV.EXCLUSIONS_TAB]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.EXCLUSIONS_TAB,
    },
    [GA_LABEL_SOLAR_PV.DRAW_EXCLUSION]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.DRAW_EXCLUSION,
    },
    [GA_LABEL_SOLAR_PV.STOP_DRAWING_EXCLUSION]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.STOP_DRAWING_EXCLUSION,
    },
    [GA_LABEL_SOLAR_PV.NEW_EXCLUSION]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.NEW_EXCLUSION,
    },
    [GA_LABEL_SOLAR_PV.DELETE_ALL_EXCLUSIONS]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.DELETE_ALL_EXCLUSIONS,
    },
    [GA_LABEL_SOLAR_PV.DELETE_EXCLUSION]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.DELETE_EXCLUSION,
    },
    [GA_LABEL_SOLAR_PV.SELECT_EXCLUSION]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.SELECT_EXCLUSION,
    },
    [GA_LABEL_SOLAR_PV.APPLY_EXCLUSIONS]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.APPLY_EXCLUSIONS,
    },
    [GA_LABEL_SOLAR_PV.BATTERY_TAB]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.BATTERY_TAB,
    },
    [GA_LABEL_SOLAR_PV.ADD_BATTERY]: {
        event_category: GA_CATEGORY_SOLAR_PV.LEFT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.ADD_BATTERY,
    },
    [GA_LABEL_SOLAR_PV.TECHNICAL_SHEET]: {
        event_category: GA_CATEGORY_SOLAR_PV.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_SOLAR_PV.TECHNICAL_SHEET,
    },
    //#endregion LEFT_PANEL

    //#region RIGHT_PANEL
    [GA_LABEL_SOLAR_PV.SELECT_RANGE]: {
        event_category: GA_CATEGORY_SOLAR_PV.RIGHT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.SELECT_RANGE,
    },
    [GA_LABEL_SOLAR_PV.CONSUMPTION_PRODUCTION_TAB]: {
        event_category: GA_CATEGORY_SOLAR_PV.RIGHT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.CONSUMPTION_PRODUCTION_TAB,
    },
    [GA_LABEL_SOLAR_PV.SENSITIVITY_ANALYSIS]: {
        event_category: GA_CATEGORY_SOLAR_PV.RIGHT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.SENSITIVITY_ANALYSIS,
    },
    [GA_LABEL_SOLAR_PV.WORKING_DAYS_FILTER]: {
        event_category: GA_CATEGORY_SOLAR_PV.RIGHT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.WORKING_DAYS_FILTER,
    },
    [GA_LABEL_SOLAR_PV.MONTH_FILTER]: {
        event_category: GA_CATEGORY_SOLAR_PV.RIGHT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.MONTH_FILTER,
    },
    [GA_LABEL_SOLAR_PV.NEXT]: {
        event_category: GA_CATEGORY_SOLAR_PV.RIGHT_PANEL,
        event_label: GA_LABEL_SOLAR_PV.NEXT,
    },
    //#endregion RIGHT_PANEL

    //#region PANEL_REPRESENTATION
    [GA_LABEL_SOLAR_PV.PANEL_SET_N_BY_WIDTH]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.PANEL_SET_N_BY_WIDTH,
    },
    [GA_LABEL_SOLAR_PV.PANEL_SET_N_BY_HEIGHT]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.PANEL_SET_N_BY_HEIGHT,
    },
    [GA_LABEL_SOLAR_PV.PANEL_SET_SPACE_BETWEEN_PANELS]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.PANEL_SET_SPACE_BETWEEN_PANELS,
    },
    [GA_LABEL_SOLAR_PV.ROW_DISTANCING_ESTIMATION]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.ROW_DISTANCING_ESTIMATION,
    },
    [GA_LABEL_SOLAR_PV.ROW_DISTANCING_INSERT]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.ROW_DISTANCING_INSERT,
    },
    [GA_LABEL_SOLAR_PV.ROW_DISTANCING_REFERENCE_DAY]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.ROW_DISTANCING_REFERENCE_DAY,
    },
    [GA_LABEL_SOLAR_PV.ROW_DISTANCING_UPDATE]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.ROW_DISTANCING_UPDATE,
    },
    [GA_LABEL_SOLAR_PV.ROW_DISTANCING_HOURS]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.ROW_DISTANCING_HOURS,
    },
    [GA_LABEL_SOLAR_PV.FRAMING_LANDSCAPE]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.FRAMING_LANDSCAPE,
    },
    [GA_LABEL_SOLAR_PV.FRAMING_PORTRAIT]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.FRAMING_PORTRAIT,
    },
    [GA_LABEL_SOLAR_PV.SETBACK]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.SETBACK,
    },
    [GA_LABEL_SOLAR_PV.OFFSET]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.OFFSET,
    },
    [GA_LABEL_SOLAR_PV.SAVE]: {
        event_category: GA_CATEGORY_SOLAR_PV.PANEL_REPRESENTATION,
        event_label: GA_LABEL_SOLAR_PV.SAVE,
    },
    //#endregion PANEL_REPRESENTATION

    //#region BUSINESS_MODELS
    [GA_LABEL_SOLAR_PV.SERVICE]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.SERVICE,
    },
    [GA_LABEL_SOLAR_PV.INSTALLMENTS]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.INSTALLMENTS,
    },
    [GA_LABEL_SOLAR_PV.UPFRONT]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.UPFRONT,
    },
    [GA_LABEL_SOLAR_PV.PAY_AS_PRODUCED]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.PAY_AS_PRODUCED,
    },
    [GA_LABEL_SOLAR_PV.MAINTENANCE_ADJUSTMENT]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.MAINTENANCE_ADJUSTMENT,
    },
    [GA_LABEL_SOLAR_PV.WARRANTY_ADJUSTMENT]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.WARRANTY_ADJUSTMENT,
    },
    [GA_LABEL_SOLAR_PV.EXTRA_COST]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.EXTRA_COST,
    },
    [GA_LABEL_SOLAR_PV.MARGIN_ADJUSTMENT]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.MARGIN_ADJUSTMENT,
    },
    [GA_LABEL_SOLAR_PV.DISCOUNT_ADJUSTMENT]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.DISCOUNT_ADJUSTMENT,
    },
    [GA_LABEL_SOLAR_PV.SHARED_SAVINGS_ADJUSTMENT]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.SHARED_SAVINGS_ADJUSTMENT,
    },
    [GA_LABEL_SOLAR_PV.GROWTH_RATE_ADJUSTMENT]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.GROWTH_RATE_ADJUSTMENT,
    },
    [GA_LABEL_SOLAR_PV.GENERATE_PROPOSAL]: {
        event_category: GA_CATEGORY_SOLAR_PV.BUSINESS_MODELS,
        event_label: GA_LABEL_SOLAR_PV.GENERATE_PROPOSAL,
    },
    //#endregion BUSINESS_MODELS

    //#region BATTERY
    [GA_LABEL_SOLAR_PV.OPTIMIZE_SELECTION]: {
        event_category: GA_CATEGORY_SOLAR_PV.BATTERY,
        event_label: GA_LABEL_SOLAR_PV.OPTIMIZE_SELECTION,
    },
    [GA_LABEL_SOLAR_PV.MANUAL_SELECTION]: {
        event_category: GA_CATEGORY_SOLAR_PV.BATTERY,
        event_label: GA_LABEL_SOLAR_PV.MANUAL_SELECTION,
    },
    //#endregion BATTERY
};
//#endregion SOLAR_PV

//#region EVC
export const GA_CATEGORY_ME = {
    ELECTRICAL_MOBILITY: 'Electrical Mobility',
    ELECTRICAL_MOBILITY_ADD_CHARGER: 'Electrical Mobility - Add Charger',
    ELECTRICAL_MOBILITY_BM: 'Electrical Mobility - Business Models',
};
export const GA_LABEL_ME = {
    //ELECTRICAL_MOBILITY
    ACCESS_PUBLIC: 'Access - Public',
    ACCESS_PRIVATE: 'Access - Private',
    SEE_PLATFORM_DETAILS: 'See Platform Details',
    INITIAL_ADD_CHARGER: 'Initial Add Charger',
    SMALL_ADD_CHARGER: 'Small Add Charger',
    START_OVER: 'Start Over',
    DELETE_ALL_CHARGERS: 'Delete All Chargers',
    EDIT_CHARGER: 'Edit Charger',
    DUPLICATE_CHARGER: 'Duplicate Charger',
    DELETE_CHARGER: 'Delete Charger',
    TECHNICAL_SHEET: 'Technical Sheet',
    NEXT_BUSINESS_MODELS: 'Next - Business Models',
    CHANGE_ACCESS_TYPE: 'Change Access Type',
    CHANGE_PLATFORM: 'Change Platform',

    //ELECTRICAL_MOBILITY_ADD_CHARGER
    TECHNICAL_SHEET_ADD: 'Technical Sheet',

    //ELECTRICAL_MOBILITY_BM
    SERVICE: 'Service',
    INSTALLMENTS: 'Installments',
    UPFRONT: 'Upfront',
    MARGIN_ADJUSTMENT: 'Margin Adjustment',
    EXECUTIVE_PROJECT: 'Executive Project',
    N_INSTALLMENTS_ADJUSTMENT: 'No. Installments Adjustment',
    GENERATE_PROPOSAL: 'Generate Proposal',
};
export const GA_EVT_CLICK_ME = {
    //#region ELECTRICAL_MOBILITY
    [GA_LABEL_ME.ACCESS_PUBLIC]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.ACCESS_PUBLIC,
    },
    [GA_LABEL_ME.ACCESS_PRIVATE]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.ACCESS_PRIVATE,
    },
    [GA_LABEL_ME.SEE_PLATFORM_DETAILS]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.SEE_PLATFORM_DETAILS,
    },
    [GA_LABEL_ME.INITIAL_ADD_CHARGER]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.INITIAL_ADD_CHARGER,
    },
    [GA_LABEL_ME.SMALL_ADD_CHARGER]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.SMALL_ADD_CHARGER,
    },
    [GA_LABEL_ME.START_OVER]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.START_OVER,
    },
    [GA_LABEL_ME.DELETE_ALL_CHARGERS]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.DELETE_ALL_CHARGERS,
    },
    [GA_LABEL_ME.EDIT_CHARGER]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.EDIT_CHARGER,
    },
    [GA_LABEL_ME.DUPLICATE_CHARGER]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.DUPLICATE_CHARGER,
    },
    [GA_LABEL_ME.DELETE_CHARGER]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.DELETE_CHARGER,
    },
    [GA_LABEL_ME.TECHNICAL_SHEET]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.TECHNICAL_SHEET,
    },
    [GA_LABEL_ME.NEXT_BUSINESS_MODELS]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.NEXT_BUSINESS_MODELS,
    },
    [GA_LABEL_ME.CHANGE_ACCESS_TYPE]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.CHANGE_ACCESS_TYPE,
    },
    [GA_LABEL_ME.CHANGE_PLATFORM]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY,
        event_label: GA_LABEL_ME.CHANGE_PLATFORM,
    },
    //#endregion ELECTRICAL_MOBILITY

    //#region ELECTRICAL_MOBILITY_ADD_CHARGER
    [GA_LABEL_ME.TECHNICAL_SHEET_ADD]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY_ADD_CHARGER,
        event_label: GA_LABEL_ME.TECHNICAL_SHEET_ADD,
    },
    //#endregion ELECTRICAL_MOBILITY_ADD_CHARGER

    //#region ELECTRICAL_MOBILITY_BM
    [GA_LABEL_ME.SERVICE]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY_BM,
        event_label: GA_LABEL_ME.SERVICE,
    },
    [GA_LABEL_ME.INSTALLMENTS]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY_BM,
        event_label: GA_LABEL_ME.INSTALLMENTS,
    },
    [GA_LABEL_ME.UPFRONT]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY_BM,
        event_label: GA_LABEL_ME.UPFRONT,
    },
    [GA_LABEL_ME.MARGIN_ADJUSTMENT]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY_BM,
        event_label: GA_LABEL_ME.MARGIN_ADJUSTMENT,
    },
    [GA_LABEL_ME.EXECUTIVE_PROJECT]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY_BM,
        event_label: GA_LABEL_ME.EXECUTIVE_PROJECT,
    },
    [GA_LABEL_ME.N_INSTALLMENTS_ADJUSTMENT]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY_BM,
        event_label: GA_LABEL_ME.N_INSTALLMENTS_ADJUSTMENT,
    },
    [GA_LABEL_ME.GENERATE_PROPOSAL]: {
        event_category: GA_CATEGORY_ME.ELECTRICAL_MOBILITY_BM,
        event_label: GA_LABEL_ME.GENERATE_PROPOSAL,
    },
    //#endregion ELECTRICAL_MOBILITY_BM
};
//#endregion EVC

//#region CFP
// export const GA_CATEGORY_CFP = {}
export const GA_LABEL_CFP = {
    FILTER_YES: 'Filter Yes',
    FILTER_NO: 'Filter No',
    BATTERY_POWER: 'Battery Power',
    RECOMMENDATION: 'Recommendation',
    CUSTOMIZED: 'Customized',
    UPFRONT: 'Upfront',
    INSTALLMENTS: 'Installments',
    SERVICE: 'Service',
    EXTRA_COST: 'Extra Cost',
    MARGIN: 'Margin',
    GENERATE_PROPOSAL: 'Generate proposal',
};
export const GA_EVT_CLICK_CFP = {
    [GA_LABEL_CFP.FILTER_YES]: {
        event_category: 'Power Factor Correction',
        event_label: GA_LABEL_CFP.FILTER_YES,
    },
    [GA_LABEL_CFP.FILTER_NO]: {
        event_category: 'Power Factor Correction',
        event_label: GA_LABEL_CFP.FILTER_NO,
    },
    [GA_LABEL_CFP.BATTERY_POWER]: {
        event_category: 'Power Factor Correction',
        event_label: GA_LABEL_CFP.BATTERY_POWER,
    },
    [GA_LABEL_CFP.RECOMMENDATION]: {
        event_category: 'Power Factor Correction',
        event_label: GA_LABEL_CFP.RECOMMENDATION,
    },
    [GA_LABEL_CFP.CUSTOMIZED]: {
        event_category: 'Power Factor Correction',
        event_label: GA_LABEL_CFP.CUSTOMIZED,
    },
    [GA_LABEL_CFP.UPFRONT]: { event_category: 'Power Factor Correction', event_label: GA_LABEL_CFP.UPFRONT },
    [GA_LABEL_CFP.INSTALLMENTS]: {
        event_category: 'Power Factor Correction',
        event_label: GA_LABEL_CFP.INSTALLMENTS,
    },
    [GA_LABEL_CFP.SERVICE]: { event_category: 'Power Factor Correction', event_label: GA_LABEL_CFP.SERVICE },
    [GA_LABEL_CFP.EXTRA_COST]: {
        event_category: 'Power Factor Correction',
        event_label: GA_LABEL_CFP.EXTRA_COST,
    },
    [GA_LABEL_CFP.MARGIN]: { event_category: 'Power Factor Correction', event_label: GA_LABEL_CFP.MARGIN },
    [GA_LABEL_CFP.GENERATE_PROPOSAL]: {
        event_category: 'Power Factor Correction',
        event_label: GA_LABEL_CFP.GENERATE_PROPOSAL,
    },
};
//#endregion CFP
