/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Validação do routeamento e validação de acesso ao Contanier
 *
 * @version 20191001
 * @since 20191001 Initial release
 *
 */
import { lazy } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Error403 from 'components/errors/Error403';
import PropTypes from 'prop-types';
import { withTracker } from 'services/withTracker';

//selectors
import { getClientID } from 'redux/selectors/client';
import { BASENAME_URL_APP } from 'constants/settings';
import lazyLoader from 'services/lazyLoader';

const FacilitiesContainer = lazy(() => lazyLoader(() => import('pages/Restricted/Facilities')));

/**
 * @context Route to Facilities
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Facilities = (props) =>
    props.clientID ?
        <FacilitiesContainer />
    :   <Error403
            props={{
                url: BASENAME_URL_APP + 'solutions',
                buttonText: 'page.404.button.goSolutions',
                message: 'page.error.403.facilities',
            }}
        />;

Facilities.propTypes = {
    clientID: PropTypes.string.isRequired,
};

const mapStateToProps = ({ client }) => {
    return { clientID: getClientID(client) };
};

export default withRouter(connect(mapStateToProps)(withTracker(Facilities, 'Facilities')));
