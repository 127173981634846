// Constants
import { USER_COUNTRY_LatLng } from 'constants/user';
import { UNITS_AVAILABLE } from 'components/util/CustomUnit';

// Icons
import { ReactComponent as RemoveIcon } from 'assets/images/icons/svg/trash-2.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/svg/edit-2.svg';
import { ReactComponent as DuplicateIcon } from 'assets/images/icons/svg/duplicate.svg';

// Interfaces
import {
    IQuestionArea,
    IQuestionBase,
    IQuestionsRR,
    IQuestionToInstall,
    IQuestionToRemove,
    IQuestionTranslucentPlate,
    IRRInputs,
    IRRInputsArea,
    TAreaActionsConfigs,
} from 'interfaces/products/rr';

// Services
import validate from 'services/util/validate';
import { DEFAULTS_COMMON_SETTINGS_MAP } from 'constants/gmapSettings';

export enum RRActions {
    SET_RR_MOUNT_DATA = 'SET_RR_MOUNT_DATA',
    SET_INPUTS_DATA = 'SET_INPUTS_DATA',
    SET_RR_KPIS = 'SET_RR_KPIS',
    SET_RR_LOADING = 'SET_RR_LOADING',
    SET_RR_CONTRACT_ID_VALIDITY = 'SET_RR_CONTRACT_ID_VALIDITY',
    RESET = 'RESET',
}

export enum RRLoadings {
    General = 'general',
    ContractID = 'contractID',
    Sizing = 'sizing',
}

export const INPUT_NAMES = {
    contract_id: 'contract_id',
    proximity_to_sea: 'proximity_to_sea',
    building_higher_than_12m: 'building_higher_than_12m',
    areas: 'areas',
} as const;

export const INPUT_NAMES_AREAS = {
    name: 'name',
    coordinates: 'coordinates',
    total_area: 'total_area',
    to_remove: 'to_remove',
    to_install: 'to_install',
} as const;

export const INPUT_NAMES_AREAS_TO_REMOVE = {
    material_id: 'material_id',
    has_asbestos: 'has_asbestos',
} as const;

export const INPUT_NAMES_AREAS_TO_INSTALL = {
    material_id: 'material_id',
    aggravations: 'aggravations',
    thickness: 'thickness',
    translucent_plate: 'translucent_plate',
} as const;

export const INPUT_NAMES_AREAS_TO_INSTALL_TRANSLUCENT = {
    material_id: 'material_id',
    has_translucent_plate: 'has_translucent_plate',
    area: 'area',
} as const;

export const QUESTIONS = {
    DEAFULT: {
        [INPUT_NAMES.contract_id]: {
            name: INPUT_NAMES.contract_id,
            label: 'page.rr.label.pvContractID',
            placeholder: 'page.rr.label.pvContractID.placeholder',
            tooltip: null,
            disabled: false,
            visible: true,
            type: 'number',
            validation: validate(['required']),
        } as IQuestionBase,
        [INPUT_NAMES.proximity_to_sea]: {
            name: INPUT_NAMES.proximity_to_sea,
            label: 'page.rr.label.proximityToSea',
            placeholder: 'page.rr.label.proximityToSea.placeholder',
            tooltip: null,
            disabled: false,
            visible: true,
            type: 'radio',
            validation: validate(['required']),
        } as IQuestionBase,
        [INPUT_NAMES.building_higher_than_12m]: {
            name: INPUT_NAMES.building_higher_than_12m,
            label: 'page.rr.label.buildingHeigherThan12m',
            placeholder: 'page.rr.label.buildingHeigherThan12m.placeholder',
            tooltip: null,
            disabled: false,
            visible: true,
            type: 'radio',
            validation: validate(['required']),
        } as IQuestionBase,
        [INPUT_NAMES.areas]: {
            [INPUT_NAMES_AREAS.name]: {
                name: INPUT_NAMES_AREAS.name,
                label: 'page.rr.label.name',
                placeholder: 'page.rr.label.name.placeholder',
                tooltip: null,
                disabled: false,
                visible: true,
                type: 'string',
                validation: validate(['required']),
            } as IQuestionArea,
            [INPUT_NAMES_AREAS.coordinates]: {
                name: INPUT_NAMES_AREAS.coordinates,
                label: 'page.rr.label.coordinates',
                placeholder: 'page.rr.label.coordinates.placeholder',
                tooltip: null,
                disabled: false,
                visible: true,
                type: 'object',
                validation: validate(['required', 'notEmptyArray']),
            } as IQuestionArea,
            [INPUT_NAMES_AREAS.total_area]: {
                name: INPUT_NAMES_AREAS.total_area,
                label: 'page.rr.label.totalArea',
                placeholder: 'page.rr.label.totalArea.placeholder',
                tooltip: null,
                disabled: false,
                visible: true,
                type: 'number',
                validation: validate(['required', 'positive']),
            } as IQuestionArea,
            [INPUT_NAMES_AREAS.to_remove]: {
                [INPUT_NAMES_AREAS_TO_REMOVE.material_id]: {
                    name: INPUT_NAMES_AREAS_TO_REMOVE.material_id,
                    label: 'page.rr.label.materialID',
                    placeholder: 'page.rr.label.materialID.placeholder',
                    tooltip: null,
                    disabled: false,
                    visible: true,
                    type: 'number',
                    validation: validate(['required', 'positive'], {
                        positive: { message: 'yup.message.required' },
                    }),
                } as IQuestionToRemove,
                [INPUT_NAMES_AREAS_TO_REMOVE.has_asbestos]: {
                    name: INPUT_NAMES_AREAS_TO_REMOVE.has_asbestos,
                    label: 'page.rr.label.hasAsbestos',
                    placeholder: 'page.rr.label.hasAsbestos.placeholder',
                    tooltip: null,
                    disabled: false,
                    visible: true,
                    type: 'boolean',
                    validation: validate([]),
                } as IQuestionToRemove,
            },
            [INPUT_NAMES_AREAS.to_install]: {
                [INPUT_NAMES_AREAS_TO_INSTALL.material_id]: {
                    name: INPUT_NAMES_AREAS_TO_INSTALL.material_id,
                    label: 'page.rr.label.materialID',
                    placeholder: 'page.rr.label.materialID.placeholder',
                    tooltip: null,
                    disabled: false,
                    visible: true,
                    type: 'number',
                    validation: validate(['required', 'positive'], {
                        positive: { message: 'yup.message.required' },
                    }),
                } as IQuestionToInstall,
                [INPUT_NAMES_AREAS_TO_INSTALL.aggravations]: {
                    name: INPUT_NAMES_AREAS_TO_INSTALL.aggravations,
                    label: 'page.rr.label.aggravations',
                    placeholder: 'page.rr.label.aggravations.placeholder',
                    tooltip: null,
                    disabled: false,
                    visible: true,
                    type: 'boolean',
                    validation: validate([]),
                } as IQuestionToInstall,
                [INPUT_NAMES_AREAS_TO_INSTALL.thickness]: {
                    name: INPUT_NAMES_AREAS_TO_INSTALL.thickness,
                    label: 'page.rr.label.thicknessID',
                    placeholder: 'page.rr.label.thicknessID.placeholder',
                    tooltip: null,
                    disabled: false,
                    visible: true,
                    type: 'number',
                    validation: validate(['required', 'positive'], {
                        positive: { message: 'yup.message.required' },
                    }),
                } as IQuestionToInstall,
                [INPUT_NAMES_AREAS_TO_INSTALL.translucent_plate]: {
                    [INPUT_NAMES_AREAS_TO_INSTALL_TRANSLUCENT.has_translucent_plate]: {
                        name: INPUT_NAMES_AREAS_TO_INSTALL_TRANSLUCENT.has_translucent_plate,
                        label: 'page.rr.label.hasTranslucentPlate',
                        placeholder: 'page.rr.label.hasTranslucentPlate.placeholder',
                        tooltip: null,
                        disabled: false,
                        visible: true,
                        type: 'boolean',
                        validation: validate([]),
                    } as IQuestionTranslucentPlate,
                    [INPUT_NAMES_AREAS_TO_INSTALL_TRANSLUCENT.material_id]: {
                        name: INPUT_NAMES_AREAS_TO_INSTALL_TRANSLUCENT.material_id,
                        label: 'page.rr.label.materialID',
                        placeholder: 'page.rr.label.materialID.placeholder',
                        tooltip: null,
                        disabled: false,
                        visible: true,
                        type: 'number',
                        validation: validate(['required', 'positive'], {
                            positive: { message: 'yup.message.required' },
                        }),
                    } as IQuestionTranslucentPlate,
                    [INPUT_NAMES_AREAS_TO_INSTALL_TRANSLUCENT.area]: {
                        name: INPUT_NAMES_AREAS_TO_INSTALL_TRANSLUCENT.area,
                        label: 'page.rr.label.area',
                        placeholder: 'page.rr.label.area.placeholder',
                        tooltip: 'page.rr.label.area.tooltip',
                        disabled: false,
                        visible: true,
                        type: 'number',
                        validation: validate(['required', 'positive', 'max'], { max: 100 }),
                    } as IQuestionTranslucentPlate,
                },
            },
        },
    } as IQuestionsRR,
} as const;

export const DEFAULT_AREA: IRRInputsArea = {
    [INPUT_NAMES_AREAS.name]: `Cobertura 1`, //TODO:
    [INPUT_NAMES_AREAS.coordinates]: [],
    [INPUT_NAMES_AREAS.total_area]: 0,
    [INPUT_NAMES_AREAS.to_remove]: {
        [INPUT_NAMES_AREAS_TO_REMOVE.material_id]: -1,
        [INPUT_NAMES_AREAS_TO_REMOVE.has_asbestos]: false,
    },
    [INPUT_NAMES_AREAS.to_install]: {
        [INPUT_NAMES_AREAS_TO_INSTALL.material_id]: -1,
        [INPUT_NAMES_AREAS_TO_INSTALL.aggravations]: [],
        [INPUT_NAMES_AREAS_TO_INSTALL.thickness]: -1,
        [INPUT_NAMES_AREAS_TO_INSTALL.translucent_plate]: {
            [INPUT_NAMES_AREAS_TO_INSTALL_TRANSLUCENT.has_translucent_plate]: false,
            [INPUT_NAMES_AREAS_TO_INSTALL_TRANSLUCENT.material_id]: -1,
            [INPUT_NAMES_AREAS_TO_INSTALL_TRANSLUCENT.area]: 5,
        },
    },
};

export const DEFAULT_FORM_VALUES: IRRInputs = {
    [INPUT_NAMES.contract_id]: null,
    [INPUT_NAMES.proximity_to_sea]: true,
    [INPUT_NAMES.building_higher_than_12m]: true,
    [INPUT_NAMES.areas]: [DEFAULT_AREA],
    currentAreaIndex: '0',
};

export const DEFAULTS_PROPS_MAP = {
    mapOptions: {
        id: 'efz-map',
        zoom: 20,
        tilt: 0,
        mapType: 'satellite',
        mapTypeId: 'hybrid',
        // controls
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: true,
        clickableIcons: true,
        disableDefaultUI: false,
        draggableCursor: 'grab',
        draggingCursor: 'grabbing',
        gestureHandling: 'cooperative',
        keyboardShortcuts: true,
        noClear: false,
        panControl: true,
        scaleControl: true,
        scrollwheel: true,
    },
    mapEnabledOption: {
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: true,
        clickableIcons: true,
        disableDefaultUI: false,
        draggableCursor: 'grab',
        draggingCursor: 'grabbing',
        gestureHandling: 'cooperative',
        keyboardShortcuts: true,
        noClear: false,
        panControl: true,
        scaleControl: true,
        scrollwheel: true,
    },
    mapDisabledOption: {
        clickableIcons: false,
        disableDefaultUI: true,
        draggableCursor: 'default',
        draggingCursor: 'default',
        fullscreenControl: false,
        gestureHandling: 'none',
        keyboardShortcuts: false,
        mapTypeControl: false,
        noClear: true,
        panControl: false,
        rotateControl: false,
        scaleControl: false,
        scrollwheel: false,
        streetViewControl: false,
        zoomControl: false,
    },
    settings: DEFAULTS_COMMON_SETTINGS_MAP,
    country: USER_COUNTRY_LatLng,
    polygon: {
        styles: {
            strokeOpacity: 0,
            strokeWeight: 2,
            fillOpacity: 0.4,
            strokeColor: {
                selected: 'rgba(245, 234, 143, 1)',
                notSelected: 'rgba(72, 68, 71, 1)',
                panelSelected: 'rgba(255, 255, 255, 1)',
                panelNotSelected: 'rgba(255, 255, 255, 0.3)',
            },
            fillColor: {
                selected: 'rgba(0, 163, 224, 1)',
                notSelected: 'rgba(0, 163, 224, 0.5)',
                panelSelected: 'rgba(1, 23, 61, 1)',
                panelNotSelected: 'rgba(1, 23, 61, 0.3)',
                panelInvalid: 'rgba(238, 22, 45, 0.5)',
            },
        },
    },
    polygonOnDraw: {
        styles: {
            strokeOpacity: 1,
            strokeWeight: 4,
            fillOpacity: 0.4,
            strokeColor: {
                selected: 'rgba(0, 163, 224, 1)',
                notSelected: 'rgba(72, 68, 71, 1)',
                panelSelected: 'rgba(255, 255, 255, 1)',
                panelNotSelected: 'rgba(255, 255, 255, 0.3)',
            },
            fillColor: {
                selected: 'rgba(0, 163, 224, 1)',
                notSelected: 'rgba(251, 247, 208, 0.5)',
                panelSelected: 'rgba(1, 23, 61, 1)',
                panelNotSelected: 'rgba(1, 23, 61, 0.3)',
                panelInvalid: 'rgba(238, 22, 45, 0.5)',
            },
        },
    },
};

export enum RRMapActions {
    SET_INIT = 'set_init',
    SET_IS_DRAWING = 'set_is_drawing',
    SET_CURRENT_AREA = 'set_current_area',
    EVT_POLYGONCOMPLETE = 'evt_polygoncomplete',
    EVT_SET_AT = 'evt_set_at',
    EVT_REMOVE_AT = 'evt_remove_at',
    EVT_INSERT_AT = 'evt_insert_at',
    DRAW_AGAIN = 'draw_again',
    RESET = 'reset',
}

export enum AreaActions {
    DUPLICATE = 'DUPLICATE',
    REMOVE = 'REMOVE',
    RENAME = 'RENAME',
}

export const AreaActionsConfigs: TAreaActionsConfigs = {
    [AreaActions.RENAME]: {
        className: 'rename',
        visible: true,
        disabled: true,
        icon: EditIcon,
        label: 'label.rename',
    },
    [AreaActions.DUPLICATE]: {
        className: 'duplicate',
        visible: true,
        disabled: true,
        icon: DuplicateIcon,
        label: 'label.duplicate',
    },
    [AreaActions.REMOVE]: {
        className: 'remove',
        visible: true,
        disabled: false,
        icon: RemoveIcon,
        label: 'label.remove',
    },
};

export enum KPIS {
    TOTAL_ROOF_AREAS = 'total_roof_areas',
    TOTAL_AREA = 'total_area',
    INVESTMENT = 'investment',
    COST = 'cost',
    INSTALLMENTS_NUMBER = 'installments_number',
}

export const KPIS_CONFIG = {
    [KPIS.TOTAL_ROOF_AREAS]: {
        visible: true,
        unit: null,
        numberOfDecimalPlaces: 0,
    },
    [KPIS.TOTAL_AREA]: {
        visible: true,
        unit: UNITS_AVAILABLE.SM,
        numberOfDecimalPlaces: 0,
    },
    [KPIS.INVESTMENT]: {
        visible: true,
        unit: UNITS_AVAILABLE.CURRENCY,
        numberOfDecimalPlaces: 2,
    },
    [KPIS.COST]: {
        visible: false,
        unit: UNITS_AVAILABLE.CURRENCY,
        numberOfDecimalPlaces: 2,
    },
    [KPIS.INSTALLMENTS_NUMBER]: {
        visible: false,
        unit: null,
        uninumberOfDecimalPlacest: 0,
    },
} as const;
