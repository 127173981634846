import { UserContext } from 'contexts/userContext';
import { IDetailedOfferEdition } from 'interfaces/businessModels/detailed';
import { createContext, useContext, useReducer } from 'react';
import { useUserStore } from 'store/user';
import { bmReducer, initialBmState } from './businessModelsReducer';
import { useSelector } from 'react-redux';

const OfferEditionContext = createContext<IDetailedOfferEdition>({
    bmState: initialBmState,
    setBMEventHandler: () => {},
    negotiationProfile: {},
    lowNegotiationProfile: false,
    companyProfileId: 0,
    productID: 0,
    bmDispatch: () => {},
});

const OfferEditionProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [bmState, bmDispatch] = useReducer(bmReducer, initialBmState);

    const { companyProfileId } = useContext(UserContext);
    const { user } = useUserStore();
    const productID = useSelector((state: any) => state?.product?.info?.id) ?? null;

    const negotiationProfile = user?.negotiation_profile;

    // @ts-ignore
    const lowNegotiationProfile =
        !!negotiationProfile &&
        !negotiationProfile.slider_tir_wacc &&
        !negotiationProfile.slider_comissao_variavel &&
        !negotiationProfile.slider_comissao_total &&
        !negotiationProfile.slider_comissao_fixa &&
        !negotiationProfile.mostra_negociacao_tariff &&
        !negotiationProfile.mostra_slider_tir &&
        !negotiationProfile.mostra_downpayment &&
        !negotiationProfile.mostra_slider_savings;

    const setBMEventHandler = (type, payload) => {
        bmDispatch({
            type: type,
            payload: payload,
        });
    };

    const OfferEditionValues: IDetailedOfferEdition = {
        bmState,
        negotiationProfile,
        lowNegotiationProfile,
        setBMEventHandler,
        companyProfileId,
        productID,
        bmDispatch,
    };
    return <OfferEditionContext.Provider value={OfferEditionValues}>{children}</OfferEditionContext.Provider>;
};

export { OfferEditionContext, OfferEditionProvider };
