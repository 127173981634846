/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>
 *
 * @description API requests to the context of Solutions
 *
 * @version 20191114
 * @since 20191114 Initial release
 *
 */

// import axios from 'axios';
import axios from 'services/@efz/axios';
import { getMessageNetworkError } from 'services/statusCodeHttp';
import { isDefined } from 'services/util/auxiliaryUtils';
import { getEndpoint, URL_FACILITIES } from 'constants/endpoints';

export const getSolutions = (payload, abort) => {
    //#region sentry warn
    if (!isDefined(payload)) {
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES) + '/' + payload + '/efficiency-measures'}`,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const putSolutions = (payload, abort) => {
    //#region sentry warn
    if (!isDefined(payload.facilityId)) {
        return null;
    }
    //#endregion

    return axios
        .request({
            url: `${getEndpoint(URL_FACILITIES) + '/' + payload.facilityId + '/efficiency-measures/' + payload.efficiencyMeasureId}`,
            method: 'PUT',
            data: payload.body,
            signal: abort?.signal,
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};
