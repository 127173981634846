import { intlMessages } from 'services/util/auxiliaryUtils';

export const INIT_STATE = {
    allowResetPassword: true,
    isRequesting: false,
    isRequestingAuthSSO: false,
    isUserAuthenticated: false,
};

// * ACTION TYPES * \\
export const SET_IS_REQUESTING_AUTH = 'SET_IS_REQUESTING_AUTH';
export const REQUEST_POST_REGISTRY = 'REQUEST_POST_REGISTRY';
export const REQUEST_POST_REGISTRY_SUCCESS = 'REQUEST_POST_REGISTRY_SUCCESS';
export const REQUEST_GET_LOGIN = 'REQUEST_GET_LOGIN';
export const REQUEST_GET_LOGIN_SUCCESS = 'REQUEST_GET_LOGIN_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REQUEST_AUTH_FAIL = 'REQUEST_AUTH_FAIL';
export const MSG_RESETPW_SUCCESS = intlMessages('server.success.resetPassword');
export const MSG_RESETPW_ERROR = intlMessages('server.error.resetPassword');
export const REQUEST_AUTH_SSO = 'REQUEST_AUTH_SSO';
export const REQUEST_AUTH_SSO_SUCCESS = 'REQUEST_AUTH_SSO_SUCCESS';
export const SET_USER_AUTHENTICATED_FALSE = 'SET_USER_AUTHENTICATED_FALSE';
export const MSG_REGISTRY_SUCCESS = intlMessages('server.success.registry');
export const MSG_REGISTRY_FAIL = intlMessages('server.error.registry');
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const RESET_LOGIN = 'RESET_LOGIN';
