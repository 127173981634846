import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import { number, object, oneOfType, string } from 'prop-types';

const Lottie2 = ({ width, height, style, className, isPaused, options }) => {
    return (
        <Lottie
            className={className}
            options={{
                ...options,
                loop: true,
                autoplay: true,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            }}
            height={height}
            width={width}
            isClickToPauseDisabled={true}
            isPaused={isPaused}
            style={{
                ...style,
                cursor: 'auto',
            }}
        />
    );
};

Lottie2.propTypes = {
    width: oneOfType([number, string]),
    height: oneOfType([number, string]),
    options: PropTypes.shape({
        animationData: object,
    }).isRequired,
    style: object,
    className: PropTypes.string,
};

Lottie2.defaultProps = {
    style: {
        cursor: 'auto',
    },
    className: '',
    options: {
        animationData: {},
    },
};

export default Lottie2;
