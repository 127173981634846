import PaPTitle from 'components/BusinessModels/ProjectSummary/Title';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPaP } from 'interfaces/businessModels/pap';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';
import PapMargin from './Margin';
import PapMarginKpis from './MarginKpis';

const SavingsSharePap = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;
    return (
        <div className="bm-project-summary-card bm-project-summary-commercial-margin">
            <PaPTitle label={'label.serviceAux.sharedSavings'} />
            <PapMargin />
            {isDefined(bmSelected?.values?.marginSelected) && <PapMarginKpis />}
        </div>
    );
};

export default SavingsSharePap;
