// React
import { memo, useContext, useRef, useState } from 'react';
// MUI
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// Assets
import { ReactComponent as ArrowIcon } from 'assets/images/icons/svg/arrow-down.svg';
// Components
import SalesSegmentDialog from './SalesSegmentDialog';
// Contexts/Hooks
import { UserContext } from 'contexts/userContext';
import useHeader from 'hooks/header';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { getCompanyProfileIds } from 'services/user';

const DEFAULT_SEGMENT = {
    id: 1,
    description: 'B2B',
};

const SalesSegment = () => {
    const { salesSegmentsList, companyProfileId } = useContext(UserContext);

    const { handleSwitchSalesSegment } = useHeader();

    const salesSegmentName = localStorage.getItem('salesSegment');
    const [salesSegmentChosen, setSalesSegmentChosen] = useState(salesSegmentName);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    // used for menu selection
    const salesSegmentSelected = useRef({
        id: salesSegmentsList?.find((el) => el.description === salesSegmentName)?.id ?? DEFAULT_SEGMENT.id,
        description: isFieldDefined(salesSegmentName) ? salesSegmentName : DEFAULT_SEGMENT.description,
    });
    const btnRef = useRef();

    const handleMenuChange = (el) => {
        salesSegmentSelected.current = el;
        // only opens the dialog if the selected sales segment is
        // different from the current one; the menu is closed otherwise
        if (salesSegmentSelected.current.description === salesSegmentName) {
            setIsMenuOpen(false);
        } else {
            setIsDialogOpen(true);
        }
    };

    const handleOnDiscardChanges = () => {
        setIsDialogOpen(false);
        setIsMenuOpen(false);
    };

    const handleOnConfirmChanges = (setIsRequestingCHistory) => {
        setIsRequestingCHistory(true);
        if (salesSegmentSelected.current.description !== salesSegmentChosen) {
            setSalesSegmentChosen(salesSegmentSelected.current.description);
            handleSwitchSalesSegment(salesSegmentSelected.current.id, {
                setIsDialogOpen,
                setIsMenuOpen,
                setIsRequestingCHistory,
            });
        }
    };

    return (
        <div className="sales-segment">
            <Button
                className={`sales-segment-button ${[getCompanyProfileIds().PPC].includes(companyProfileId) ? 'bg-white' : ''}`}
                ref={btnRef}
                disableRipple
                disableElevation
                disableFocusRipple
                disableTouchRipple
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                endIcon={<ArrowIcon className={`arrow-icon ${isMenuOpen ? 'selected' : 'unselected'}`} />}
            >
                <span></span>
                {salesSegmentChosen}
            </Button>
            <Menu className="sales-segment-menu" open={isMenuOpen} onClose={() => setIsMenuOpen(false)} anchorEl={btnRef.current}>
                {salesSegmentsList?.map((el) => (
                    <MenuItem
                        key={el.id}
                        onClick={() => handleMenuChange(el)}
                        disableRipple
                        selected={el.id === salesSegmentSelected.current.id ? true : false}
                    >
                        {el.description}
                    </MenuItem>
                ))}
            </Menu>
            {isDialogOpen && (
                <SalesSegmentDialog
                    open={isDialogOpen}
                    onCloseHandler={handleOnDiscardChanges}
                    onConfirmDiscardChanges={handleOnDiscardChanges}
                    onConfirmSaveChanges={handleOnConfirmChanges}
                />
            )}
        </div>
    );
};

export default memo(SalesSegment);
