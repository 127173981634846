import { bool, func, number, string } from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { getClientID } from 'redux/selectors/client';
import { getFacilityID } from 'redux/selectors/facility';
import { useLocation } from 'react-router-dom';
import {
    BASENAME_URL_CLIENT,
    BASENAME_URL_CREATE_CLIENT,
    BASENAME_URL_FACILITIES,
    BASENAME_URL_FACILITY,
    BASENAME_URL_PROCESSES,
    BASENAME_URL_SOLUTIONS,
} from 'constants/settings';
import { SIDEBAR_BUTTONS, SIDEBAR_BUTTONS_CONFIG } from 'constants/sidebar';
import { UserContext } from './userContext';
import { containString, isDefined } from 'services/util/auxiliaryUtils';
import { BACKOFFICE_CONFIGS } from 'constants/backoffice';

const SidebarContext = createContext({
    clientID: number,
    facilityID: number,
    buttonSelected: string,
    setButtonSelected: func,
    isSidebarPermanent: bool,
    currentbuttonSelect: string,
    sidebarOpen: bool,
    setSidebarOpen: func,
    hasSecondarySidebar: bool,
    showErrorDevice: bool,
    setShowErrorDevice: func,
    sethasSecondarySidebar: func,
    secondarySidebarSectionSelected: bool,
    setSecondarySidebarSectionSelected: func,
    secondarySidebarPosSectionSelected: bool,
    setSecondarySidebarPosSectionSelected: func,
    showBackoffice: bool,
});

const SidebarProvider = ({ children }) => {
    const location = useLocation();
    const matches = useMediaQuery('(min-width:1366px)', { noSsr: true });
    const isMobileScreen = useMediaQuery('(max-width:575px)', { noSsr: true });

    // Contexts
    const { companyProfileId, configBackoffice } = useContext(UserContext);

    // Selectors
    const clientID = useSelector((state) => getClientID(state?.client));
    const facilityID = useSelector((state) => getFacilityID(state?.facility));

    // States
    const [sidebarOpen, setSidebarOpen] = useState(matches ? true : false);
    const [sidebarState, setSidebarState] = useState(null); // When changing from permanent and non and get previous state
    const [isSidebarPermanent, setIsSidebarPermanent] = useState(false);
    const [buttonSelected, setButtonSelected] = useState(null);
    const [hasSecondarySidebar, sethasSecondarySidebar] = useState(true);
    const [showErrorDevice, setShowErrorDevice] = useState(null);

    // Secondary Sidebar States
    const [secondarySidebarSectionSelected, setSecondarySidebarSectionSelected] = useState(null);
    const [secondarySidebarPosSectionSelected, setSecondarySidebarPosSectionSelected] = useState(null);

    const hasSolutionsButton = useMemo(() => isDefined(clientID) && isDefined(facilityID), [clientID, facilityID]);

    const sidebarStateHandler = (isPermanent, buttonSelected, withSecondarySidebar = false) => {
        setButtonSelected(buttonSelected);
        setIsSidebarPermanent(isPermanent);
        sethasSecondarySidebar(withSecondarySidebar);

        if (isPermanent) {
            setSidebarOpen(sidebarState ?? sidebarOpen);
            setSidebarState(null);
        } else {
            setSidebarState(sidebarOpen);
            setSidebarOpen(false);
        }
    };

    const sidebarConfig = useMemo(
        () => SIDEBAR_BUTTONS_CONFIG?.[companyProfileId] ?? SIDEBAR_BUTTONS_CONFIG.DEFAULT,
        [companyProfileId, hasSolutionsButton] // eslint-disable-line
    );
    const backofficeSidebarConfig = useMemo(() => BACKOFFICE_CONFIGS?.[companyProfileId] ?? BACKOFFICE_CONFIGS.DEFAULT, [companyProfileId]);

    useEffect(() => {
        switch (location.pathname) {
            case BASENAME_URL_CLIENT:
            case BASENAME_URL_CREATE_CLIENT:
            case BASENAME_URL_FACILITY:
            case BASENAME_URL_FACILITIES:
                sidebarStateHandler(true, null);
                break;
            case BASENAME_URL_SOLUTIONS:
                sidebarStateHandler(!isMobileScreen ? true : false, SIDEBAR_BUTTONS.SOLUTIONS);
                break;
            case BASENAME_URL_PROCESSES:
                sidebarStateHandler(false, SIDEBAR_BUTTONS.PROCESSES);
                break;
            // case BASENAME_URL_COOKIES_POLICIES:
            //     sidebarStateHandler(false, null);
            //     break;
            default:
                if (containString(['proposal'], location.pathname)) {
                    sidebarStateHandler(true, null);
                    break;
                } else if (containString(['backoffice'], location.pathname)) {
                    sidebarStateHandler(true, SIDEBAR_BUTTONS.BACKOFFICE, true);
                } else {
                    // Products, and rest of app
                    sidebarStateHandler(false, SIDEBAR_BUTTONS.SOLUTIONS);
                    break;
                }
        }
    }, [location.pathname]); // eslint-disable-line

    const hasAtLeastOneBOProduct = (obj) => {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key].show_backoffice === true) {
                return true;
            }
        }
        return false;
    };

    const hasOneBOProduct = useMemo(() => hasAtLeastOneBOProduct(configBackoffice), [configBackoffice]); // eslint-disable-line

    return (
        <SidebarContext.Provider
            value={{
                buttonSelected,
                setButtonSelected,
                isSidebarPermanent,
                sidebarConfig,
                hasSolutionsButton: hasOneBOProduct,
                sidebarOpen,
                setSidebarOpen,
                showBackoffice: hasOneBOProduct,
                showErrorDevice,
                setShowErrorDevice,
                // Secondary Sidebar
                hasSecondarySidebar,
                sethasSecondarySidebar,
                backofficeSidebarConfig,
                secondarySidebarSectionSelected,
                setSecondarySidebarSectionSelected,
                secondarySidebarPosSectionSelected,
                setSecondarySidebarPosSectionSelected,
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

export { SidebarContext, SidebarProvider };
