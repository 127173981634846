import { createContext, useContext, useMemo, useReducer } from 'react';

// Constants
import { EVCProActionTypes, QUESTIONS, QUESTIONS_SETTINGS } from 'constants/products/evcPro';

// Contexts
import { evcProReducer, initialEVCProReducer } from './evcProReducer';
import { UserContext } from 'contexts/userContext';

// Interfaces
import { IEVCProContext, PayloadForType, TEVCProAction } from 'interfaces/products/evcPro';

// Services
import { addOptionsToContextQuestions } from 'services/products/evcPro';

const EVCProContext = createContext<IEVCProContext>({
    state: initialEVCProReducer,
    dispatchHandler: () => {},
    questions: QUESTIONS,
});
EVCProContext.displayName = 'efz-EVCProContext';

const EVCProProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [state, dispatch] = useReducer(evcProReducer, initialEVCProReducer);

    function dispatchHandler<T extends EVCProActionTypes>(type: T, payload: PayloadForType<T>) {
        dispatch({ type, payload } as TEVCProAction);
    }

    const { userTypeID, companyProfileId } = useContext(UserContext);

    const questions = useMemo(() => {
        const baseQuestions = QUESTIONS_SETTINGS?.[companyProfileId]?.[userTypeID] ?? QUESTIONS;
        return addOptionsToContextQuestions(baseQuestions, state?.options);
    }, [state?.options, companyProfileId, userTypeID]);

    const EVCProContextValue: IEVCProContext = {
        state,
        dispatchHandler,
        questions,
    };

    return <EVCProContext.Provider value={EVCProContextValue}>{children}</EVCProContext.Provider>;
};

export { EVCProContext, EVCProProvider };
