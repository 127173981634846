import { useContext, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Components
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from 'components/@efz/Tooltip';
import ErrorMessage from 'components/errors/ErrorMessage';
import IntlMessages from 'components/util/IntlMessages';
import ProjectSummarySubTitle from '../../../SubTitle';
import CustomUnit from 'components/util/CustomUnit';

// Constants
import { OFFER_DETAILS_INPUT_NAMES, OFFER_DETAILS_UPFRONT_CONFIGS } from 'constants/businessModelsPro';

// Services
import { intlMessages } from 'services/util/auxiliaryUtils';

// Interfaces
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { PPaEvents, TPPa } from 'interfaces/businessModels/ppa';

// Contexts
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { UserContext } from 'contexts/userContext';

const PPaOfferDetailsInputs = () => {
    const { negotiationProfile, productID, setBMEventHandler } = useContext(BusinessModelsProContext) as IBusinessModelsContext<
        TProductInputs,
        TPPa
    >;

    const { companyProfileId, userTypeID } = useContext(UserContext);

    const {
        control,
        formState: { errors },
    } = useFormContext();

    const configOfferDetailsPPa = useMemo(
        () =>
            OFFER_DETAILS_UPFRONT_CONFIGS?.[companyProfileId]?.[userTypeID]?.[productID] ??
            OFFER_DETAILS_UPFRONT_CONFIGS?.DEFAULT?.[userTypeID]?.[productID],
        [companyProfileId, userTypeID, productID]
    );

    const updateValues = (value: number) => {
        setBMEventHandler(PPaEvents.SET_EXTRA_COST, {
            extra_cost: Number(value),
        });
    };

    return (
        <>
            {/* {state?.selected?.values?.rubrics.length > 0 && negotiationProfile?.edita_rubricas && <UpfrontRubrics rubrics={state?.selected?.values?.rubrics} />} */}
            {negotiationProfile?.acesso_custo_extra && (
                <div className="offer-details-inputs-optional">
                    <ProjectSummarySubTitle label={'label.costAdjustment'} />
                    {/* COST ADJUSTMENT */}
                    {
                        <div className="d-flex justify-content-between">
                            {/* <div className="offer-details-container"> */}
                            <div className="label-container">
                                <label>
                                    <IntlMessages id="label.costAdjustment" />
                                </label>
                                <Tooltip title={<IntlMessages id="page.businessModels.costAdjustment.info" />} />
                            </div>
                            {negotiationProfile?.acesso_valores_custo && (
                                <div className="input-container">
                                    <Controller
                                        name={configOfferDetailsPPa?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]?.name}
                                        control={control}
                                        rules={configOfferDetailsPPa?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]?.rules ?? {}}
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        render={({ field: { ref, ...field } }) => (
                                            <OutlinedInput
                                                {...field}
                                                type={configOfferDetailsPPa?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]?.inputType}
                                                placeholder={intlMessages('page.backoffice.solar.placeholder.warranty')}
                                                onChange={(evt) => {
                                                    const value = evt.target.value;
                                                    field.onChange(value);
                                                    updateValues(Number(value));
                                                }}
                                                endAdornment={
                                                    <InputAdornment className="ms-2" position="end">
                                                        {/* @ts-ignore */}
                                                        <CustomUnit unit={'currency'} />
                                                    </InputAdornment>
                                                }
                                                error={!!errors?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]}
                                            />
                                        )}
                                    />
                                    {errors?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT] && (
                                        <ErrorMessage
                                            // @ts-ignore
                                            error={errors[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]}
                                            values={{
                                                max: configOfferDetailsPPa?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]?.optValidation?.max,
                                                min: configOfferDetailsPPa?.[OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]?.optValidation?.min,
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    }
                </div>
            )}
        </>
    );
};

export default PPaOfferDetailsInputs;
