import { defineMessages } from 'react-intl';
import frMessages from '../locales/fr_CH.json';

const frCHLang = {
    messages: {
        ...frMessages,
    },
    locale: 'fr-CH',
    data: defineMessages,
};
export default frCHLang;
