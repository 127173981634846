import { TBMProjectSummary } from 'interfaces/businessModels';
import KPI from 'components/BusinessModels/List/BusinessModels/BusinessModelServiceV2/Card/Summary/Kpi';
import { serviceV2ProjectSummary } from 'services/businessModels/serviceV2';

interface IProps {
    projectSummary: TBMProjectSummary[];
}

const BusinessModelProjectSummaryServiceV2 = ({ projectSummary }: IProps) => {
    const kpis = serviceV2ProjectSummary(projectSummary);

    return <>{kpis.length > 0 ? kpis.map((kpi) => <KPI {...kpi} />) : <></>}</>;
};

export default BusinessModelProjectSummaryServiceV2;
