import { PRODUCT_IDS } from 'constants/products';
import { ReactComponent as SolarIcon } from 'assets/@efz/icons/products/solar.svg';
import { ReactComponent as EVCIcon } from 'assets/@efz/icons/products/evc.svg';
// import { ReactComponent as BatteryIcon } from 'assets/@efz/icons/products/battery.svg';
import { ReactComponent as GBHPIcon } from 'assets/@efz/icons/products/gbhp.svg';
import { ReactComponent as IEIcon } from 'assets/@efz/icons/products/ie.svg';
import { ReactComponent as CFPIcon } from 'assets/@efz/icons/products/cfp.svg'; // CSS
import { ReactComponent as CEIcon } from 'assets/@efz/icons/products/ce.svg';
import { ReactComponent as HVACIcon } from 'assets/@efz/icons/products/hvac.svg';
// import { ReactComponent as MSIcon } from 'assets/@efz/icons/products/ms.svg';
import { ReactComponent as SANTSIcon } from 'assets/@efz/icons/products/ms.svg';
import { ReactComponent as MARIcon } from 'assets/@efz/icons/products/mar.svg';
import { ReactComponent as INTEGRAIcon } from 'assets/@efz/icons/products/mar.svg';
import { ReactComponent as CASIcon } from 'assets/@efz/icons/products/cas.svg';
import { ReactComponent as TREIcon } from 'assets/@efz/icons/products/tre.svg';
import { ReactComponent as GNSIcon } from 'assets/@efz/icons/products/gns.svg';
import { ReactComponent as VEVIcon } from 'assets/@efz/icons/products/vev.svg';
import { ReactComponent as EPIcon } from 'assets/@efz/icons/products/ep.svg';
import { ReactComponent as HeatpumpsIcon } from 'assets/@efz/icons/products/heatpumps.svg';
import { ReactComponent as RRIcon } from 'assets/@efz/icons/products/rr.svg';
import { ReactComponent as SPVMaintenanceIcon } from 'assets/@efz/icons/products/spvmaintenance.svg';

export const efzProductIcon = (id, isSelected, isDisabled, height = 30, width = 30) => {
    const iconClassName = (iconName) => `efz-${iconName}${isSelected ? '-selected' : ''}${isDisabled ? '-disabled' : ''}`;

    switch (id) {
        case PRODUCT_IDS.SPV:
        case PRODUCT_IDS.SPVSB:
        case PRODUCT_IDS.SPV_SIMPLE:
            return <SolarIcon className={iconClassName('solar')} height={height} width={width} />;
        case PRODUCT_IDS.IE:
        case PRODUCT_IDS.IE_BR:
            return <IEIcon className={iconClassName('ie')} height={height} width={width} />;
        case PRODUCT_IDS.GBHP: // FALTA
            return <GBHPIcon className={iconClassName('gbhp')} height={height} width={width} />;
        case PRODUCT_IDS.HVAC:
        case PRODUCT_IDS.HVAC_BR:
            return <HVACIcon className={iconClassName('hvac')} height={height} width={width} />;
        case PRODUCT_IDS.CE:
            return <CEIcon className={iconClassName('ce')} height={height} width={width} />;
        case PRODUCT_IDS.CFP:
            return <CFPIcon className={iconClassName('cfp')} height={height} width={width} />;
        case PRODUCT_IDS.MAR:
            return <MARIcon className={iconClassName('battery')} height={height} width={width} />;
        case PRODUCT_IDS.EVC:
        case PRODUCT_IDS.EVCSB:
            return <EVCIcon className={iconClassName('evc')} height={height} width={width} />;
        case PRODUCT_IDS.HP:
            return <HeatpumpsIcon className={iconClassName('heatpumps')} height={height} width={width} />;
        case PRODUCT_IDS.INTEGRA:
        case PRODUCT_IDS.MPT:
            return <INTEGRAIcon className={iconClassName('integra')} height={height} width={width} />;
        case PRODUCT_IDS.SANTS:
        case PRODUCT_IDS.SANTT:
            return <SANTSIcon className={iconClassName('sants')} height={height} width={width} />;
        case PRODUCT_IDS.CAS:
            return <CASIcon className={iconClassName('cas')} height={height} width={width} />;
        case PRODUCT_IDS.TRE:
            return <TREIcon className={iconClassName('tre')} height={height} width={width} />;
        case PRODUCT_IDS.GNS:
            return <GNSIcon className={iconClassName('gns')} height={height} width={width} />;
        case PRODUCT_IDS.VEV:
            return <VEVIcon className={iconClassName('vev')} height={height} width={width} />;
        case PRODUCT_IDS.EP:
            return <EPIcon className={iconClassName('ep')} height={height} width={width} />;
        case PRODUCT_IDS.RR:
            return <RRIcon className={iconClassName('rr')} height={height} width={width} />;
        case PRODUCT_IDS.SPV_MAINTENANCE:
            return <SPVMaintenanceIcon className={iconClassName('spv-maintenance')} height={height} width={width} />;
        default:
            break;
    }
};
