import UpFrontMarginMarks from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront/CommercialMargin/MarkMargin';
import UpFrontNonMarksMargin from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront/CommercialMargin/NonMarkMargin';
import { PRODUCT_IDS } from 'constants/products';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { useContext } from 'react';

const MarginUpFrontSimple = () => {
    const { productID } = useContext(BusinessModelsProContext);

    const marginToDisplay = () => {
        switch (productID) {
            case PRODUCT_IDS.SPVSB:
                return <UpFrontMarginMarks />;
            default:
                return <UpFrontNonMarksMargin />;
        }
    };

    return marginToDisplay();
};

export default MarginUpFrontSimple;
