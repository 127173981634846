import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';
import { useContext } from 'react';
import OfferSummaryActionsPPaDetailed from './Actions';
import FinanceCustomizationPPaDetailed from './FinanceCustomization';
import ProjectSummaryPPaDetailed from './ProjectSummary';

const OfferSummaryPPa = () => {
    const { bmState, negotiationProfile } = useContext(OfferEditionContext);

    const showFinanceCustomization = bmState?.businessModelSelected?.financing_customization?.enabled ?? false;
    const showOfferSummary = bmState?.businessModelSelected?.has_offer_edition && negotiationProfile?.acesso_resumo_custo;

    return (
        <div className="bm-detailed-offer-summary">
            <ProjectSummaryTitle label="label.SummaryOffer" />
            {showFinanceCustomization && <FinanceCustomizationPPaDetailed showOfferSummary={showOfferSummary} />}
            {showOfferSummary && <ProjectSummaryPPaDetailed />}
            <OfferSummaryActionsPPaDetailed />
        </div>
    );
};

export default OfferSummaryPPa;
