import { useState, useMemo } from 'react';

import IntlMessages from 'components/util/IntlMessages';
import CookieConsentOArea from 'components/OArea/CookieConsentOArea';
import CustomizeCookiesOAreaDialog from 'components/OArea/CustomizeCookiesOAreaDialog';

const Footer = () => {
    const yearCurrent = useMemo(() => new Date().getFullYear(), []);

    const [policyTypeToShow, setPolicyTypeToShow] = useState(null);
    const [showPrivacySettings, setShowPrivacySettings] = useState(false);

    const showPolicyHandler = (type = null) => {
        if (type) {
            setPolicyTypeToShow(type);
        } else {
            setPolicyTypeToShow(null);
        }
    };
    return (
        <>
            <footer className="app-footer">
                <div className="container d-flex align-items-center justify-content-center">
                    <span className="d-inline-block">
                        &copy; <IntlMessages id="footer.copyright" values={{ year: yearCurrent, company: 'PPC' }} />
                    </span>
                    <div className="footer-policy-links h-100">
                        <span onClick={() => showPolicyHandler('terms-conditions')}>
                            <IntlMessages id="footer.conditions" />
                        </span>
                        <span onClick={() => showPolicyHandler('personal-data')}>
                            <IntlMessages id="footer.personalData" />
                        </span>
                        <span onClick={() => showPolicyHandler('cookies-policy')}>
                            <IntlMessages id="footer.cookiesPolicy" />
                        </span>
                        <span onClick={() => setShowPrivacySettings(true)}>
                            <IntlMessages id="footer.cookieSettings" />
                        </span>
                    </div>
                </div>
            </footer>
            {!!policyTypeToShow && (
                <CookieConsentOArea type={policyTypeToShow} close={showPolicyHandler} showPolicyHandler={showPolicyHandler} />
            )}
            {showPrivacySettings && <CustomizeCookiesOAreaDialog isOpen={showPrivacySettings} setIsOpen={setShowPrivacySettings} />}
        </>
    );
};

export default Footer;
