import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TPaP } from 'interfaces/businessModels/pap';
import { useContext } from 'react';
import { isDefined } from 'services/util/auxiliaryUtils';
import ProjectSummaryPaPDetailed from './detailed';
import ProjectSummaryPaPSimple from './simple';

const ProjectSummaryPaP = () => {
    const { bmSelected } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TPaP>;

    if (isDefined(bmSelected.values?.syncKpis)) return <ProjectSummaryPaPDetailed />;
    return <ProjectSummaryPaPSimple />;
};

export default ProjectSummaryPaP;
